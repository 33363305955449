import React from "react";
import { IZone, IPropertyDetails, ISpotFeature, IAdditionalFeature, getScheduleSummaryText, ISpotBlockData, ISpotBlockDataV2 } from "./Interfaces";
import { Row, Col } from "reactstrap";
import ImageGrid from "../../Components/ImageGridComponent";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Select, MenuItem, Icon, Button } from "@material-ui/core";
import SpotService from "../../Services/SpotService";
import PropertyService from "../../Services/PropertyService";
import ArrayHelper from "../../Helpers/ArrayHelper";
import { ISingleScheduleData, IScheduleLimitation } from "../../Components/ScheduleComponent";
import LabelControl from "../../Components/BasicComponents/LabelControl";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { IFileInfo } from "../../Components/BasicComponents/FileUploadModal";
import { GetUserDetailsService } from "../../Services/GetUserDetailsService";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { format } from "date-fns";
import { EnumRepeatType, ISpotBlockMetadata } from "../BookingsCalendar/interfaces";
import { DateTimeHelper } from "../../Helpers/DateTimeHelper";
import ZoneService from "../../Services/ZoneService";
import { extractScheduleLimitationsFromProperty } from "../Spots/Interfaces";


export interface Days {

    sunday: any,
    monday: any,
    tuesday: any,
    wednesday: any,
    thursday: any,
    friday: any,
    saturday: any,
    allDays: any

}


export default class ZoneDetailView extends React.Component<IZoneDetailViewProps, IZoneDetailViewState> {
    private _propertyService: PropertyService;
    private _spotService: SpotService;
    private _zoneService: ZoneService;
    private _isTenantUser: boolean;
    private repeatList: string[] = ["Don't repeat", "Repeat Daily", "Repeat Weekly", "Repeat Monthly"];

    constructor(props_: IZoneDetailViewProps) {
        super(props_);

        this._isTenantUser = GetUserDetailsService.getUserDetails().userRoleID[0] == 6;
        this.state = {
            features: [],
            property: null,
            schedules: null,
            scheduleLimitation: extractScheduleLimitationsFromProperty(null),
        };

        this._propertyService = new PropertyService();
        this._spotService = new SpotService();
        this._zoneService = new ZoneService();
    }

    componentDidMount() {
        this._zoneService.getZoneInformation(this.props.zone.zoneGuid, true)
            .then(r => r.json())
            .then(p => {
                if (p) {
                    this.setState({
                        zone: p
                    });
                    // console.log(p);
                }
            });

        /*this._propertyService.getPropertyDetails(this.props.zone.propertyGuid)
            .then(r => r.json())
            .then(p => {
                if (p) {
                    this.setState({
                        property: p,
                        // images: p.images,
                        scheduleLimitation: extractScheduleLimitationsFromProperty(p)
                    });
                }
            });
        this._spotService.getZoneSchedules(this.props.zone.zoneGuid)
            .then(r => r.json())
            .then(s => {
                this.setState({
                    schedules: s
                });
            });*/

        this._spotService.getFeatureList()
            .then(r => r.json())
            .then(f => {
                this.setState({
                    features: f
                });
            });
    }

    render() {
        let otherFeatureList: string[] = [];
        let otherFeatureIcons: string[] = [];
        let spotDisabledFeatures: string[] = [];
        if (this.state.zone) {
            try {
                spotDisabledFeatures = this.state.zone.otherFeatures.split(",");
                console.log(spotDisabledFeatures)
            }
            catch (ex) { }
        }

        if (this.state.zone) {
            for (let f of this.state.zone.selectedFeatures) {
                if (spotDisabledFeatures.length > 0 && spotDisabledFeatures.indexOf(f.toString()) === -1) {
                    let feature = ArrayHelper.findObject(this.state.features, "spotFeatureId", f);
                    if (feature) {
                        otherFeatureList.push(feature.featureName);
                        otherFeatureIcons.push(feature.featureDescription as string);
                        console.log(otherFeatureList)
                    }
                }
            }

            if (this.state.zone.otherFeatures) {
                try {
                    let otherPropertyFeatures = JSON.parse(this.state.zone.otherFeatures) as IAdditionalFeature[];
                    otherFeatureList = otherFeatureList.concat(otherPropertyFeatures.filter(f => {
                        if (!f.a) {
                            return false;
                        }
                        if (spotDisabledFeatures.indexOf(f.i) > -1) {
                            return false;
                        }

                        return true;
                    }).map(f => f.n));
                }
                catch (ex) { }
            }
        }
        let obj: Days = { sunday: [], monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [], allDays: [] }
        let sortedDays: number[] = []
        let sortedSchedules: ISingleScheduleData[] = []
        this.state.zone?.schedules.forEach((s) => {
            if (s.selectedDays !== undefined && s.selectedTimeRange !== undefined) {
                sortedDays.push(+(s.selectedDays[0].toString() + s.selectedTimeRange.start.toString()))
            }
        })
        sortedDays = sortedDays.sort()
        sortedDays.forEach((sortedDayTime) => {
            this.state.zone?.schedules?.forEach((s) => {
                if (s.selectedDays !== undefined && s.selectedTimeRange !== undefined) {
                    if (s.selectedDays[0] === + sortedDayTime.toString()[0] && s.selectedTimeRange.start === +sortedDayTime.toString().substring(1)) {
                        sortedSchedules.push(s)
                    }
                }
            })
        })

        if (this.state.zone == null) return null

        return (
            <div className="spot-detail-view p-3 detailed-view">
                <Row>
                    <Col xs={12}>
                        <div className="zone-address">
                            <LocationOnIcon /> {this.state.zone?.propertyName} &bull; {this.state.zone?.address}
                        </div>
                    </Col>
                </Row>
                {/* <Row>
                    <Col>
                        <ImageGrid
                            imageData={this.state.images}
                            CarouselTitle={this.props.spot.friendlySpotId}
                        />
                    </Col>
                </Row> */}
                <Row>
                    <Col>
                        {
                            (this.state.zone != null && this.state.zone?.schedules != null) &&
                            <div className="mt-2">
                                <div>
                                    <Row className="text-center">
                                        <Col xs={6}>Zone Schedule</Col>
                                        <Col></Col>
                                        <Col xs={3}>Price</Col>
                                    </Row>

                                    <div>
                                        {
                                            sortedSchedules.map((s, i) => {

                                                let scheduleSummary = getScheduleSummaryText(s);

                                                return (
                                                    <Row className="mx-0 py-2 border-bottom" key={i}>
                                                        <Col className="px-0" xs={9}>{scheduleSummary[0]}&bull;{scheduleSummary[1]}</Col>
                                                        <Col className="px-0 text-right">${s.selectedPriceRange?.start.toFixed(2)}</Col>
                                                    </Row>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                                <hr className="my-4" />
                                <LabelControl label="Amenities">
                                    {this.state.features.length > 0 &&
                                        <div className="amenities-list">
                                            {
                                                otherFeatureList.map((a, i) => {
                                                    return (<div className="amenity-item" key={a}>
                                                        <Icon>{otherFeatureIcons[i] ? otherFeatureIcons[i] : "bookmark"}</Icon> <span className="icon-text">{a}</span>
                                                    </div>);
                                                })
                                            }
                                        </div>
                                    }
                                </LabelControl>
                            </div>
                        }
                    </Col>
                </Row>
            </div>
        );
    }

    private setSuffixesToNumber(n: number) {
        return n + (["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th");
    }
}

interface IZoneDetailViewProps {
    zone: IZone;
}

interface IZoneDetailViewState {
    // images: IFileInfo[];
    property: IPropertyDetails | null;
    schedules: ISingleScheduleData[] | null;
    scheduleLimitation: IScheduleLimitation;
    features: ISpotFeature[];
    zone?: IZone;
}
