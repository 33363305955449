import React from "react";
import { Grid, Button, TextField } from "@material-ui/core";
import { Col, Row } from "reactstrap";
import CloseIcon from '@material-ui/icons/Close';
import { ShowAlert } from "../../../Common/ShowAlert";
import { CheckCircle } from "@material-ui/icons";
import Error from "@material-ui/icons//Error";
import VisitorBookingService from "../../../Services/VisitorBookingService";


export default class UpdateConfigurationParking extends React.Component<IUpdateConfigurationParkingProps, IUpdateConfigurationParkingState> {


    private _visitorBookingService: VisitorBookingService;

    constructor(props_: IUpdateConfigurationParkingProps) {
        super(props_);

        this.state = {
            numberOfPasses: 0,
            oldNumberOfPasses: 0,
            propertyGuid: "",
            isOverride: false,
            isSuccess: false,
            showConfigureDetail: true
        };
        this._visitorBookingService = new VisitorBookingService();
    }

    componentDidMount() {
        const { numberOfPasses, propertyGuid } = this.props;
        this.setState({
            numberOfPasses,
            oldNumberOfPasses: numberOfPasses,
            propertyGuid
        });
    }

    componentDidUpdate(prevProps: Readonly<IUpdateConfigurationParkingProps>) {
        const { numberOfPasses, propertyGuid } = prevProps;
        if (propertyGuid !== this.props.propertyGuid || numberOfPasses !== this.props.numberOfPasses) {
            this.setState({
                numberOfPasses,
                oldNumberOfPasses: numberOfPasses,
                propertyGuid
            });
        }
    }

    render() {
        const { onClose } = this.props;
        let { numberOfPasses } = this.state;
        return (
            <>
                <div className="mx-3">
                    <Grid container className={"right-panel-header p-2"}>
                        <Grid item xs></Grid>
                        <Grid item>
                            <Button
                                onClick={() => { onClose() }}>
                                <CloseIcon />
                            </Button>
                        </Grid>
                        <Grid item xs={12} className="mt-px-8">
                            <h4 className={`padd-enforcement-tow-heading`}>Configure Parking</h4>
                        </Grid>
                    </Grid>
                </div>
                <>
                    <div className='padd-tenant-container'>
                        {
                            this.state.isOverride &&
                                <>
                                    <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                                        <div>
                                            <Error className="error-icon-size" />
                                        </div>
                                        <div className="bulk-upload-header mt-3">
                                            Custom Passes
                                        </div>
                                        <div className="mt-3 bulk-upload-sub-content">
                                            Some units have custom passes
                                        </div>
                                        <div className="bulk-upload-sub-content">
                                            applied, did you want to override
                                        </div>
                                        <div className="bulk-upload-sub-content">
                                            these?
                                        </div>

                                    </div>
                                    <div className="d-flex pr-70-pl-60-px align-items-center mt-4">
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className="ml-2"
                                            onClick={() => {
                                                this._updateConfigurePasses(1);
                                            }}
                                        >
                                            Yes
                                        </Button>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            className="ml-2"
                                            onClick={() => {
                                                this._updateConfigurePasses(2);
                                            }}
                                        >
                                            No
                                        </Button>
                                    </div>
                                </>
                        }
                        {
                            this.state.showConfigureDetail && <>
                            <div className="add-tenant-pl-panel-body">
                                <div className="add-tenant-block-pl">
                                    Default number of parking passes for all units is <b>{this.props.numberOfPasses}</b>.
                                </div>
                                <div className="add-tenant-block-pl">
                                    <div className="panel-body-field-label">Number of Passes</div>
                                    <TextField
                                        fullWidth
                                        placeholder="Enter Number of Passes"
                                        variant="outlined"
                                        type="number"
                                        required={true}
                                        margin="normal"
                                        className="mt-0"
                                        value={numberOfPasses}
                                        onInput={(e: any) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
                                        }}
                                        onChange={(e_: any) => {
                                            this.setState({
                                                numberOfPasses: parseInt(e_.target.value)
                                            }, () => {
                                                if (this.props.onChange) {
                                                    this.props.onChange(this._hasChanges());
                                                }
                                            });
                                        }}
                                        autoComplete="off"
                                        error={numberOfPasses === 0 || isNaN(numberOfPasses)}
                                    />
                                </div>
                            </div>
                            <div>
                                <Row className="mt-3 mx-0">
                                    <Col>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                this._updateConfigurePasses(0);
                                            }}
                                        >Save</Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => {
                                                onClose()
                                            }}
                                        >Cancel</Button>
                                    </Col>
                                </Row>
                            </div>
                        </>
                        }
                        {
                            this.state.isSuccess && <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                            <div>
                                <CheckCircle className="check-icon-size" />
                            </div>
                            <div className="bulk-upload-header mt-3">
                                Parking Passes Updated
                            </div>
                            <div className="mt-3 bulk-upload-sub-content">
                                Parking Passes have been
                            </div>
                            <div className="bulk-upload-sub-content">
                                successfully updated.
                            </div>
                        </div>
                        }
                        <div></div>
                    </div>
                </>
            </>
        )
    }

    private _hasChanges(): boolean {
        if (this.state.oldNumberOfPasses !== this.state.numberOfPasses) {
            return true;
        }

        return false;
    }

    private _updateConfigurePasses = (overrideFlag: number) => {
        const { numberOfPasses, propertyGuid } = this.state;

        if (numberOfPasses === 0 || isNaN(numberOfPasses)) {
            ShowAlert("", 'Number of passes should be greater than zero', "error");
            this.setState({
                showConfigureDetail: true,
                isOverride: false
            });
            return;
        }

        const request = {
            propertyGuid,
            defaultPasses: parseInt(numberOfPasses.toString()),
            overrideFlag
        }

        this._visitorBookingService.updateConfigurePasses(request)
        .then(response => {
            if(response.ok){
                this.setState({
                    isSuccess: true,
                    isOverride: false,
                    showConfigureDetail: false
                }, () => {
                    this.props.onSaved(numberOfPasses);
                });
            }
            else{
                this.setState({
                    isOverride: true,
                    showConfigureDetail: false
                });
            }
        }).catch(error => {
            ShowAlert("", error, "error");
        })
    }

}

interface IUpdateConfigurationParkingProps {
    numberOfPasses: number,
    onClose: () => void,
    onChange: (hasUnsavedChanges: boolean) => void,
    onSaved: (numberOfPasses: number) => void,
    propertyGuid: string
}

interface IUpdateConfigurationParkingState {
    numberOfPasses: number,
    oldNumberOfPasses: number,
    propertyGuid: string,
    isOverride: boolean,
    isSuccess: boolean,
    showConfigureDetail: boolean
}
