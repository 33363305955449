import { Button, Grid, Link, TextField } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import Error from "@material-ui/icons//Error";
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { Col, Row } from "reactstrap";
import { ShowAlert } from "../../../Common/ShowAlert";
import { RightPanelState } from "../../../Models/CommonInterfaces";
import VisitorBookingService from "../../../Services/VisitorBookingService";
import { IPropertyBasics } from "../../Spots/Interfaces";
import { IBulkEditRequest } from "../interface";
import { IUnitItem } from "./interface";


export default class AddEditVisitorParking extends React.Component<IAddEditVisitorParkingProps, IAddEditVisitorParkingState> {


    private _visitorBookingService: VisitorBookingService;

    constructor(props_: IAddEditVisitorParkingProps) {
        super(props_);

        this.state = {
            unitNumber: "",
            numberOfPasses: 0,
            tenantBuilding: null,
            oldUnitNumber: "",
            oldNumberOfPasses: 0,
            oldTenantBuilding: null,
            isExistingUnit: false,
            isOverWriteExistingUnit: false,
            unitGuid: [],
            isSuccess: false
        };
        this._visitorBookingService = new VisitorBookingService();
    }

    componentDidMount() {
        const { requestType } = this.props;
        if (requestType === RightPanelState.Add) {
            this.addUnit();
        }
        else {
            this.editUnit();
        }
    }

    addUnit = () => {
        const { selectedProperty, defaultVisitorParkingPasses } = this.props;
        let tenantBuilding = null;
        if (selectedProperty != null && !selectedProperty.isYardiProperty) {
            tenantBuilding = selectedProperty;
        }

        this.setState({
            tenantBuilding,
            numberOfPasses: defaultVisitorParkingPasses
        });
    }

    editUnit = () => {
        const { selectedUnits, selectedProperty, defaultVisitorParkingPasses } = this.props;
        let unitNumber = "";
        let unitGuid: string[] = [];
        let numberOfPasses = 0;
        let tenantBuilding = null;
        if (selectedProperty != null) {
            tenantBuilding = selectedProperty;
        }
        selectedUnits && selectedUnits.length > 0 && selectedUnits.map(unit => {
            unitNumber += `${unit.unitNumber},`;
            unitGuid.push(unit.unitGuid);
            numberOfPasses = unit.passCount;
            return unit;
        });
        unitNumber = unitNumber.length > 0 ? unitNumber.slice(0, -1) : unitNumber;
        unitNumber = unitNumber.length > 20 ? unitNumber.slice(0, 20) + "..." : unitNumber;
        numberOfPasses = selectedUnits.length === 1 ? numberOfPasses : 0;
        this.setState({
            unitNumber,
            numberOfPasses,
            tenantBuilding,
            unitGuid,
            oldUnitNumber: unitNumber,
            oldNumberOfPasses: numberOfPasses,
            oldTenantBuilding: tenantBuilding,
        });
    }

    render() {
        const { requestType, onClose } = this.props;
        let { numberOfPasses } = this.state;
        return (
            <>
                <div className="mx-3">
                    <Grid container className={"right-panel-header p-2"}>
                        <Grid item xs></Grid>
                        <Grid item>
                            <Button
                                onClick={() => { onClose() }}>
                                <CloseIcon />
                            </Button>
                        </Grid>
                        <Grid item xs={12} className="mt-px-8">
                            <h4 className={`padd-enforcement-tow-heading`}>{requestType === RightPanelState.Add ? "Add Unit" : "Edit Unit"}</h4>
                        </Grid>
                    </Grid>
                </div>
                {this.state.isExistingUnit ?
                    <>
                        <div className="d-flex flex-coloumn justify-content-center mt-5 pt-4">
                            <div className="d-flex flex-column text-center" style={{ flex: 1 }}>
                                {this.state.isOverWriteExistingUnit && (
                                    <>
                                        <div>
                                            <CheckCircle className="check-icon-size" />
                                        </div>
                                        <div className="padd-header mt-3">
                                            Unit Updated
                                        </div>
                                        <div className="mt-3 padd-tenant-exisiting-msg">
                                            Unit has been successfully 
                                        </div>
                                        <div className="padd-tenant-exisiting-msg">
                                            updated.
                                        </div>
                                    </>
                                )}
                                {!this.state.isOverWriteExistingUnit && (
                                    <>
                                        <div>
                                            <Error className="error-icon-size" />
                                        </div>
                                        <div className="padd-header mt-3">
                                            Existing Unit
                                        </div>
                                        <div className="mt-3 padd-tenant-exisiting-msg">
                                            This unit already exists in the
                                        </div>
                                        <div className="padd-tenant-exisiting-msg">
                                            database. Do you want to overwrite
                                        </div>
                                        <div className="padd-tenant-exisiting-msg">
                                            the information?
                                        </div>
                                        <div className="mt-4 d-flex override-btn">
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                className="ml-2"
                                                onClick={() => {
                                                    this.setState({
                                                        isOverWriteExistingUnit: false
                                                    }, () => {
                                                        this._overrideExistingUnits();
                                                    });
                                                }}
                                            >
                                                Overwrite Existing
                                            </Button>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="secondary"
                                                className="ml-2"
                                                onClick={() => {
                                                    this.setState({
                                                        isExistingUnit: false,
                                                        isOverWriteExistingUnit: false
                                                    }, () => {
                                                        onClose();
                                                    });
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </>

                                )}
                            </div>
                        </div>

                    </>
                    :
                    <> <div className='padd-tenant-container'>
                        {!this.state.isSuccess &&
                            <>
                                <div className="add-tenant-pl-panel-body">
                                    <div className="add-tenant-block-pl">
                                        <div className="panel-body-field-label">Unit Number</div>
                                        <TextField
                                            fullWidth
                                            placeholder="Unit Number"
                                            variant="outlined"
                                            required={true}
                                            margin="normal"
                                            className="mt-0"
                                            inputProps={{ maxLength: 20 }}
                                            value={this.state.unitNumber}
                                            onChange={(e_: any) => {
                                                this.setState({
                                                    unitNumber: e_.target.value
                                                }, () => {
                                                    if (this.props.onChange) {
                                                        this.props.onChange(this._hasChanges());
                                                    }
                                                });
                                            }}
                                            autoComplete="off"
                                            error={
                                                this.props.requestType === RightPanelState.Add &&
                                                this.state.unitNumber.trim().length === 0}
                                            disabled={this.props.requestType === RightPanelState.Edit}
                                        />
                                    </div>
                                    <div className="add-tenant-block-pl">
                                        <div className="panel-body-field-label">Tenant Building</div>
                                        <Autocomplete
                                            className="cl-repeat-dropdown"
                                            options={this.props.properties.filter(x => x.propertyGuid !== 'blank' && !x.isYardiProperty)}
                                            value={this.state.tenantBuilding}
                                            openOnFocus={true}
                                            selectOnFocus={true}
                                            getOptionLabel={(option) => option.propertyName}
                                            renderInput={(params) => <TextField {...params}
                                                placeholder="Select Tenant Building"
                                                variant="outlined"
                                                required={true}
                                                error={this.state.tenantBuilding === null}
                                            />}
                                            onChange={(e_: any, property_: IPropertyBasics | null) => {
                                                this.setState({
                                                    tenantBuilding: property_
                                                }, () => {
                                                    if (this.props.onChange) {
                                                        this.props.onChange(this._hasChanges());
                                                    }
                                                });
                                            }}
                                            disabled={this.props.requestType === RightPanelState.Edit}
                                        />
                                    </div>
                                    <div className="add-tenant-block-pl">
                                        <div className="panel-body-field-label">Number of Passes</div>
                                        <TextField
                                            fullWidth
                                            placeholder="Enter Number of Passes"
                                            variant="outlined"
                                            type="number"
                                            required={true}
                                            margin="normal"
                                            className="mt-0"
                                            value={numberOfPasses}
                                            onInput={(e: any) => {
                                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
                                            }}
                                            onChange={(e_: any) => {
                                                this.setState({
                                                    numberOfPasses: parseInt(e_.target.value)
                                                }, () => {
                                                    if (this.props.onChange) {
                                                        this.props.onChange(this._hasChanges());
                                                    }
                                                });
                                            }}
                                            autoComplete="off"
                                            error={numberOfPasses === 0 || isNaN(numberOfPasses)}
                                        />
                                    </div>
                                    {this.props.requestType === RightPanelState.Edit && !this.props.selectedProperty?.isYardiProperty &&
                                        <div className="add-tenant-block-pl">
                                            <div className="delete-unit">
                                                <Link href="javascript:void(0)" onClick={() => {
                                                    this.props.onDelete(this.props.selectedUnits);
                                                }}>Delete Unit</Link>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div>
                                    <Row className="mt-3 mx-0">
                                        <Col>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    if (!this.props.isBulkSaveEdit)
                                                        this._saveOrUpdateUnits();
                                                    else
                                                        this._bulkSaveOrUpdateUnits();
                                                }}
                                            >Save</Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => {
                                                    onClose()
                                                }}
                                            >Cancel</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        }
                        {
                            this.state.isSuccess && <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                                <div>
                                    <CheckCircle className="check-icon-size" />
                                </div>
                                <div className="bulk-upload-header mt-3">
                                    {this.props.requestType === RightPanelState.Edit ? "Unit Updated" : "Unit Added"}
                                </div>
                                <div className="mt-3 bulk-upload-sub-content">
                                    Unit{this.props.requestType === RightPanelState.Edit ?"(s)": ""} has been successfully
                                </div>
                                <div className="bulk-upload-sub-content">
                                    {this.props.requestType === RightPanelState.Edit ? "updated" : "added"}.
                                </div>
                            </div>
                        }
                        <div></div>
                    </div>
                    </>
                }
            </>
        )
    }

    private _overrideExistingUnits = () => {
        const { unitNumber, numberOfPasses, tenantBuilding } = this.state;
        const { onSaved, allUnits } = this.props;

        if (tenantBuilding === null
            || unitNumber === "" || numberOfPasses === 0 || isNaN(numberOfPasses)) {
                if (numberOfPasses === 0)  {
                    ShowAlert("", 'Number of passes should be greater than zero', "error");
                }
                else {
                    ShowAlert("", 'Please fill the mandatory fields', "error");
                }
            this.setState({
                isExistingUnit: false,
                isOverWriteExistingUnit: false
            });
            return;
        }

        const unitGuid = allUnits.find(x => x.unitNumber === unitNumber)?.unitGuid ?? "00000000-0000-0000-0000-000000000000";

        const request: IBulkEditRequest = {
            unitGuids: [unitGuid],
            passessCount: parseInt(numberOfPasses.toString()),
        }

        this._callBulkEditVisitor(request, onSaved);
    }

    private _saveOrUpdateUnits = () => {
        const { unitNumber, numberOfPasses, tenantBuilding } = this.state;
        const { requestType, onSaved } = this.props;

        if ((requestType === RightPanelState.Edit && this.state.unitGuid.length === 0) || tenantBuilding === null
            || unitNumber === "" || numberOfPasses === 0 || isNaN(numberOfPasses)) {
                if (numberOfPasses === 0)  {
                    ShowAlert("", 'Number of passes should be greater than zero', "error");
                }
                else {
                    ShowAlert("", 'Please fill the mandatory fields', "error");
                }
            return;
        }

        let requestAdd = null;
        let requestEdit = null;

        if (requestType === RightPanelState.Add) {
            requestAdd = {
                unitNumber,
                passCount: parseInt(numberOfPasses.toString()),
                propertyGuid: tenantBuilding.propertyGuid
            }
        }
        if (requestType === RightPanelState.Edit) {
            requestEdit = {
                unitGuids: [this.state.unitGuid[0]],
                passessCount: parseInt(numberOfPasses.toString()),
            }
        }

        if (requestType === RightPanelState.Add) {
            this._visitorBookingService.addVisitor(requestAdd).then(r => {
                if (r.ok) {
                    this.setState({
                        isSuccess: true,
                        isExistingUnit: false,
                        isOverWriteExistingUnit: false
                    });
                    onSaved();
                }
                else {
                    r.json().then(response => {
                        if (response === "Unit number is already exists") {
                            this.setState({
                                isExistingUnit: true,
                                isOverWriteExistingUnit: false
                            });
                        }
                        else {
                            ShowAlert("", response, "error");
                        }
                    }).catch(error => {
                        ShowAlert("", error, "error");
                    });
                }
            }).catch(error => {
                ShowAlert("", error, "error");
            })
        }
        else {
            this._callBulkEditVisitor(requestEdit, onSaved);
        }
    }

    private _bulkSaveOrUpdateUnits = () => {
        const { numberOfPasses, unitGuid } = this.state;
        const { onSaved, selectedUnits } = this.props;

        if (numberOfPasses === 0 || isNaN(numberOfPasses)) {
            ShowAlert("", 'Number of passes should be greater than zero', "error");
            return;
        }

        const request: IBulkEditRequest = {
            unitGuids: unitGuid,
            passessCount: parseInt(numberOfPasses.toString()),
        }

        this._callBulkEditVisitor(request, onSaved);

        // var hasDifferentPass = selectedUnits.filter(x => x.passCount !== numberOfPasses).length !== 0;

        // if (!hasDifferentPass) {
        //     this._callBulkEditVisitor(request, onSaved);
        // }
        // else{
        //     ShowAlertwithConfirm(
        //         "Override All PassCount?", 
        //         `<div class="mt-3">Are you sure you want to override all passcounts? click on yes to continue.</div>`,
        //         "error",
        //     "Yes",
        //     "No",
        //     "gryd-custom-popup").then(r=>{
        //         //if(r){
        //             this._callBulkEditVisitor(request, onSaved);                    
        //         //}
        //     })
        // }
    }

    private _callBulkEditVisitor = (request: any, onSaved: (response?: any) => void) => {
        this._visitorBookingService.bulkEditVisitor(request).then(r => {
            if (r.ok) {
                r.json().then(response => {
                    this.setState({
                        isExistingUnit: true,
                        isOverWriteExistingUnit: true
                    });
                    onSaved(response);
                });
            }
        });
    }

    private _hasChanges(): boolean {
        if (this.state.oldUnitNumber !== this.state.unitNumber ||
            this.state.oldNumberOfPasses !== this.state.numberOfPasses ||
            JSON.stringify(this.state.oldTenantBuilding) !== JSON.stringify(this.state.tenantBuilding)) {
            return true;
        }

        return false;
    }

}

interface IAddEditVisitorParkingProps {
    requestType: RightPanelState,
    properties: IPropertyBasics[],
    onClose: () => void,
    onChange: (hasChange: boolean) => void,
    selectedProperty: IPropertyBasics | null,
    onSaved: (response?: any, isUpdate?: boolean) => void,
    selectedUnits: IUnitItem[],
    isBulkSaveEdit: boolean,
    defaultVisitorParkingPasses: number,
    allUnits: IUnitItem[],
    onDelete: (selectedUnits: IUnitItem[]) => void
}

interface IAddEditVisitorParkingState {
    unitNumber: string,
    numberOfPasses: number,
    tenantBuilding: IPropertyBasics | null,
    oldUnitNumber: string,
    oldNumberOfPasses: Number,
    oldTenantBuilding: IPropertyBasics | null,
    isExistingUnit: boolean,
    isOverWriteExistingUnit: boolean,
    unitGuid: string[],
    isSuccess: boolean
}
