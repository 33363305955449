import React from 'react';
import { FaParking, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ReactDOM from 'react-dom';
import { NavbarModel } from '../Models/NavbarModel';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Popper, Grow, Paper, ClickAwayListener, MenuList, Avatar, Drawer, Grid, Button } from '@material-ui/core';
import classes from '*.module.css';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { UserDetailsModel } from '../Models/UserDetails';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import SettingsIcon from '@material-ui/icons/Settings';
import NotificationComponents from '../Components/NotificationComponents';
import CloseIcon from '@material-ui/icons/Close';
import ScheduleSetting from '../Components/ScheduleSetting';

interface NavBarTopProps { }
interface NavBarTopState {
    userDetails: UserDetailsModel,
    isNotificationOpened: boolean,
    drawerId: number,
    isScheduleOpened: boolean
}


class NavBarTop extends React.Component<NavBarTopProps, NavBarTopState> {
    constructor(props: NavBarTopProps) {
        super(props);

        this.state = {
            userDetails: {
                accessToken: '',
                emailId: '',
                fullName: '',
                mobileNumber: '',
                userRoleID: [],
                isPlaidVerified: false
            },
            isNotificationOpened: false,
            drawerId: 0,
            isScheduleOpened: false
        };
    }

    componentDidMount() {
        var _localData = localStorage.getItem('userdata');

        if (_localData != null && _localData !== '') {
            var _data = JSON.parse(_localData);

            let _userDetails: UserDetailsModel = _data;
            // let _userDetails: UserDetailsModel = {
            //     accessToken: _data.accessToken,
            //     fullName: _data.fullName,
            //     emailId: _data.emailId,
            //     mobileNumber: _data.mobileNumber,
            //     userRoleID: _data.userRoleID
            // }

            this.setState({
                userDetails: _userDetails,
            });
        }
    }

    public render() {
        return (
            <div>
                <Drawer
                    anchor="right"
                    open={this.state.isNotificationOpened == true}
                    onClose={() => {
                        this.setState({
                            isNotificationOpened: false
                        })
                    }}
                >
                    <div className="mx-3 data-grid-right-panel">
                        <Grid container className="right-panel-header p-2 border-bottom">
                            <Grid item xs>
                                <h4 className="right-panel-title m-0 ml-3">"Notifications"</h4>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            isNotificationOpened: false
                                        });
                                    }}>
                                    <CloseIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    {
                        < div className="notification-body">
                            <p>This is under construction</p>
                        </div>
                    }
                </Drawer>
                {/* <ScheduleSetting
                    onClose={(s_: boolean) => {
                        this.setState({
                            isScheduleOpened: s_
                        });
                    }}
                    openState={this.state.isScheduleOpened}
                /> */}
                <TopHeaderSource
                    userInfo={this.state.userDetails}
                    popupState={(s_, id_) => {
                        this.setState({
                            isNotificationOpened: true,
                            drawerId: id_
                        })
                    }}
                    scheduleState={(s_) => {
                        this.setState({
                            isScheduleOpened: s_
                        })
                    }}
                />
            </div >
        )
    }
}

function TopHeaderSource(props: { userInfo: UserDetailsModel, popupState: (s_: boolean, drawer_id: number) => void, scheduleState: (s_: boolean) => void }) {
    // console.log(props.userInfo)
    // const [userDetails, setUserDetails] = React.useState<UserDetailsModel>(props.userInfo);
    const [auth, setAuth] = React.useState(true);
    const [notificationState, setNotificationState] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleNotification = (id: number) => {
        if (props.popupState) {
            props.popupState(true, id);
        }
    };

    const handleSchedule = () => {
        if (props.scheduleState) {
            props.scheduleState(true);
        }
        setAnchorEl(null);
    }

    const getUpdate = () => {
        return notificationState;
    }

    const handleLogOut = () => {
        localStorage.clear();
        window.location.href = "/";
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfil = () => {
        window.location.href = "/settings";
    };


    const getUserProfileText = () => {
        var userAlpha = '';
        if (props.userInfo.fullName !== null && props.userInfo.fullName != '') {
            let userNameArray = props.userInfo.fullName.split(' ');
            let userAlphaList: any[] = [];

            for (var i = 0; i < userNameArray.length; i++) {
                userAlphaList.push(userNameArray[i][0]);
            }

            userAlpha = userAlphaList.toString().replace(/,/g, '');
        }

        return userAlpha;
    }

    // console.log(notificationState);
    return (
        <AppBar position="static">
            <Toolbar className='header-container'>
                <div className="header-container-sub">
                    {/* <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => handleNotification(1)}
                        color="default"
                    >
                        <SettingsIcon />
                    </IconButton> */}
                    {/* <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => handleNotification(2)}
                        color="default"
                    >
                        <NotificationsNoneIcon />
                    </IconButton> */}
                    {/* {
                        (props.userInfo.userRoleID.indexOf(1) != -1 || props.userInfo.userRoleID.indexOf(2) != -1) &&
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleSchedule}
                            color="default"
                        >
                            <SettingsIcon />
                        </IconButton>
                    } */}
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="default"
                    >
                        {/* <AccountCircle /> */}
                        <Avatar className='profile-icon'>{getUserProfileText()}</Avatar>
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        className="profile-popup"
                        anchorEl={anchorEl}
                        elevation={0}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleProfil}>Settings</MenuItem>
                        {/* {
                            (props.userInfo.userRoleID.indexOf(1) != -1 || props.userInfo.userRoleID.indexOf(2) != -1) && <MenuItem onClick={handleSchedule}>Schedule</MenuItem>
                        } */}
                        <hr />
                        <MenuItem onClick={handleClose}>Go to Gryd website</MenuItem>
                        <MenuItem onClick={handleLogOut}>Logout</MenuItem>
                    </Menu>

                </div>
            </Toolbar>
        </AppBar>
    )
}

export default NavBarTop;