import { INumberRange } from "../Components/DataGrid/Interfaces";

export default class NumberHelper {
    public static doNumberRangesConflict(slotDatum1_: INumberRange, slotDatum2_: INumberRange): boolean {
        if (slotDatum1_.start != undefined && slotDatum1_.end != undefined && slotDatum2_.start != undefined && slotDatum2_.end != undefined) {
            return (slotDatum1_.start < slotDatum2_.start && slotDatum1_.end > slotDatum2_.start) ||
                (slotDatum1_.start < slotDatum2_.end && slotDatum1_.end > slotDatum2_.end) ||
                (slotDatum2_.start < slotDatum1_.start && slotDatum2_.end > slotDatum1_.start) ||
                (slotDatum2_.start < slotDatum1_.end && slotDatum2_.end > slotDatum1_.end) ||
                (slotDatum1_.start == slotDatum2_.start && slotDatum1_.end == slotDatum2_.end);
        }
        return false;
    }
}