import {
  Badge,
  Button,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Snackbar,
  TextField
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import {ChevronLeft, NotificationsOutlined} from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import MapIcon from '@material-ui/icons/Map';
import SearchIcon from '@material-ui/icons/Search';
import {format} from "date-fns";
import moment from 'moment';
import React from 'react';
import {withRouter} from 'react-router-dom';
import AddBlockSpotView from '../../Common/AddBlockSpotView';
import {CheckScreenPermission} from '../../Common/CheckScreenPermission';
import {PreserveFilters} from '../../Common/PreserveFilters';
import {ShowAlert, ShowAlertwithConfirm, ShowAlertWithConfirmCustomIcon} from '../../Common/ShowAlert';
import DataGrid from "../../Components/DataGrid/DataGrid";
import {IDataGridColumn} from '../../Components/DataGrid/Interfaces';
import {
  EnumInteractiveMapStatus,
  EnumMapEditStatus,
  IFigmaChildNodes,
  IFigmaNodes,
  IFigmaNodesMappedWithSpot,
  IFloor,
  IMappedSpotDetails,
  IMapResetOption,
  IPrivateLotZone,
  IProgressDetails,
  ISelectedSpotDetails
} from '../../Components/SpotMap/interface';
import SpotMap from '../../Components/SpotMap/SpotMap';
import SpotMapLoader from '../../Components/SpotMap/SpotMapLoader';
import ArrayHelper from '../../Helpers/ArrayHelper';
import {DateTimeHelper} from '../../Helpers/DateTimeHelper';
import ObjectHelper from '../../Helpers/ObjectHelper';
import StringHelper from '../../Helpers/StringHelper';
import UtilHelper from '../../Helpers/UtilHelper';
import CloseSVG from '../../Images/close.svg';
import SearchLens from '../../Images/SearchLens.svg';
import {RightPanelState} from '../../Models/CommonInterfaces';
import PrivateLotService from '../../Services/PrivateLotService';
import SpotService from '../../Services/SpotService';
import {IDailySpotAggregation, IUspGetBookingBlockingStatus} from '../BookingsCalendar/interfaces';
import AddTenant from './AddTenant';
import AssignedTenant from './AssignedTenant';
import AssignedVisitorTenant from './AssignedVisitorTenant';
import AssignTenant from './AssignTenant';
import "./AssignTenant.css";
import AssignVisitor from './AssignVisitor';
import BulkUploadPrivateLots from "./BulkUploadPrivateLots";
import {
  IEditTenantLease,
  IMapBookingModel,
  IPrivateLotNotification,
  IPrivateLotProperty,
  IPrivateLotsDetailsItem,
  IPrivateLotsItem,
  IRemoveAccess,
  IRemoveAccessUndo,
  IRenewResponseModel,
  ISaveEditTenantRespone,
  ISpotBooking,
  PrivateLotPropertyDetails,
  ZoneActiveSpot
} from "./interface";
import CustomRenewParkingPass from './ParkingPasses/CustomRenewParkingPass';
import "./PrivateLots.css";
import PrivateLotsMapDetails from './PrivateLotsMapDetails';
import PrivateLotsProperty from "./PrivateLotsProperty";
import PrivateLotsTenantDetails from "./PrivateLotsTenantDetails";
import ResolveTenant from './ResolveTenant';
import SpotMapPopup from './SpotMapPopup';
import YardiSync from './YardiSync';
import {SyncContext} from '../../Contexts/BatchSyncContext';
import CalendarProperty from "../BookingsCalendar/CalendarProperty";
import {IPropertyBasics} from "../Spots/Interfaces";
import {StatusCodes} from "http-status-codes";

class PrivateLots extends React.Component<any, IPrivateLotsState> {
  static contextType = SyncContext;
  private _privateLotService: PrivateLotService;
  private _searchInput: HTMLElement | null;
  private _searchByUnit: HTMLElement | null;
  private interval: any;
  private _spotService: SpotService;
  private _privateLotsListColumns: IDataGridColumn<IPrivateLotsItem>[] = [
    {
      key: "unitNumber",
      name: "Unit #",
    },
    {
      key: "name",
      name: "Name"
    },
    {
      key: "phoneNumber",
      name: "Phone Number",
      contentProvider: ({phoneNumber}: IPrivateLotsItem) => {
        const areaCode = phoneNumber?.slice(0, 3);
        const phone1 = phoneNumber?.slice(3, 6);
        const phone2 = phoneNumber?.slice(6);

        phoneNumber = phoneNumber ? `(${areaCode}) ${phone1}-${phone2}` : 'N/A';

        return (<div className='p-license-plate'>{phoneNumber}</div>);
      }
    },
    {
      key: "licensePlate",
      name: "License plate(s)",
      contentProvider: (row_: IPrivateLotsItem) => {
        let licensePlate = row_.propertyDetails[0]?.licensePlate;
        return (<div className='p-license-plate'>{licensePlate || 'N/A'}</div>);
      }
    },
    {
      key: "spotNumber",
      name: "SPOT NUMBER",
      contentProvider: (row_: IPrivateLotsItem) => {
        let spotNumber = row_.propertyDetails[0]?.spotNumber;
        return (<div className='p-license-plate'>{spotNumber || 'N/A'}</div>);
      },
    },
    {
      key: "leaseExpiration",
      name: "LEASE EXPIRATION",
      contentProvider: (row_: IPrivateLotsItem) => {
        let leaseExpiration: Date | undefined = row_.propertyDetails[0]?.leaseExpiration ? new Date(row_.propertyDetails[0]?.leaseExpiration) : undefined;
        return (<div className='p-license-plate'>{leaseExpiration?.toLocaleDateString('en-CA', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })}</div>);
      },
    }

  ];

  private _privateLotsDetailsColumns: IDataGridColumn<IPrivateLotsDetailsItem>[] = [
    {
      key: "propertyName",
      name: "ASSIGNED PROPERTY",
    },
    {
      key: "leaseStartDateLocal",
      name: "START DATE",
      contentProvider: (row_: IPrivateLotsDetailsItem) => {
        let date = DateTimeHelper.dateStringToDate(row_.leaseStartDateLocal);
        return (<span><span className="no-wrap-text">{format(date, "dd MMM yyyy")}</span><br/><span
          className="no-wrap-text">{format(date, "hh:mm aa")}</span></span>);
      }
    },
    {
      key: "leaseEndDateLocal",
      name: "END DATE",
      contentProvider: (row_: IPrivateLotsDetailsItem) => {
        let date = DateTimeHelper.dateStringToDate(row_.leaseEndDateLocal);
        return (<span><span className="no-wrap-text">{format(date, "dd MMM yyyy")}</span><br/><span
          className="no-wrap-text">{format(date, "hh:mm aa")}</span></span>);
      }
    },
    // {
    //     key: "rate",
    //     name: "RATE",
    // },
    {
      key: "zoneName",
      name: "ZONE"
    },
    {
      key: "spotName",
      name: "SPOT #",
      getComparator: ArrayHelper.getNumberInStringComparator
    },
    // {
    //     key: "renew",
    //     name: "RENEW",
    //     contentProvider: (row_: IPrivateLotsDetailsItem) => {
    //         const { autoRenewAnchorE1 } = this.state;
    //         const rowIdLength = autoRenewAnchorE1 ? autoRenewAnchorE1.id.split('_').length : 0;
    //         const rowId = autoRenewAnchorE1 ? autoRenewAnchorE1.id.split('_')[rowIdLength - 1] : "";

    //         const anchorEl = rowId && rowId === row_.spotGuid ? autoRenewAnchorE1 : null;
    //         const isAddMenuOpen = Boolean(anchorEl);
    //         return (
    //             <>
    //                 <IconButton id={`basic-menu_button_${row_.spotGuid}`}
    //                     onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
    //                         this.handleAutoRenewListOpen(event, row_)
    //                     }}>
    //                     <Autorenew fontSize="small" />
    //                 </IconButton>
    //                 {/* <span>{row_.reservationGuid}</span> */}


    //                 <Menu
    //                     id={`basic-menu_${row_.spotGuid}`}
    //                     anchorEl={autoRenewAnchorE1}
    //                     open={isAddMenuOpen}
    //                     onClose={this.handleAutoRenewListClose}
    //                     MenuListProps={{
    //                         'aria-labelledby': 'basic-button',
    //                     }}
    //                 >
    //                     <MenuItem onClick={() => {
    //                         this.setState({
    //                             autoRenewAnchorE1: null
    //                         }, () => {
    //                             this.renewLease(this.state.selectedTenantDetails, "1 Year");
    //                         });
    //                     }}>1 Year</MenuItem>
    //                     <MenuItem
    //                         onClick={() => {
    //                             this.setState({
    //                                 autoRenewAnchorE1: null
    //                             }, () => {
    //                                 this.renewLease(this.state.selectedTenantDetails, "1 Month");
    //                             });
    //                         }}>1 Month</MenuItem>
    //                     <MenuItem
    //                         onClick={() => {
    //                             let parkingPasses_ = [];
    //                             parkingPasses_.push(row_);
    //                             this.setState({
    //                                 autoRenewAnchorE1: null,
    //                                 isCustomRenew: true,
    //                                 rightPanelState: RightPanelState.Add,
    //                                 SelectedParkingPassess: parkingPasses_
    //                             });
    //                         }}>Custom</MenuItem>
    //                 </Menu>
    //             </>
    //         );
    //     }
    // }

  ];


  constructor(props: any) {
    super(props);
    this.state = {
      isTenantViewDetails: false,
      allTenantDetails: [],
      filteredTenantDetails: [],
      subFilteredTenantDetails: [],
      allViewTenantDetailsList: [],
      filteredViewTenantDetailsList: [],
      subFilteredViewTenantDetailsList: [],
      isMultiSelectedProperty: false,
      isNotificationEnabled: false,
      notifications: [],
      selectedProperties: [],
      properties: [],
      propertyBlocker: true,
      rightPanelState: RightPanelState.None,
      isAddNewTenant: false,
      isAddMenuOpen: false,
      anchorEl: null,
      isBulkUpload: false,
      selectedPrivateLot: null,
      autoRenewAnchorE1: null,
      isBlockSpotOpened: false,
      selectedSpotAggregation: null,
      refreshDailyView: false,
      searchTerm: "",
      selectedTenant: null,
      selectedReservation: "",
      selectedTenantDetails: null,
      isCustomRenew: false,
      SelectedParkingPassess: [],
      hasUnsavedChanges: false,
      removeAccessUndoList: [],
      toastNotifyType: "",
      isUndoNotification: false,
      editSavedResponse: [],
      undoRenewLeaseData: null,
      isFocused: false,
      selectedTenantList: [],
      privateLotNotifications: [],
      isWidgetRefreshed: true,
      isScreenAccessible: false,
      isMapVisible: false,
      viewType: '',
      figmaNodeId: '',
      isRenew: false,
      isParkingLeasesUpdated: false,
      isMapLoaded: false,
      mapUploadingStatus: {
        progress: EnumInteractiveMapStatus.UploadMap,
        selectedSpotInfo: null,
        selectedZone: null
      },
      mappedSpotInfo: null,
      mappedZoneInfo: null,
      mapResetState: {
        Cancel: false,
        ResetAllSpotAttributes: false,
        ResetMap: false
      },
      mapSelectedSpotDetails: null,
      isRenewUpcoming: false,
      isResolveTenant: false,
      clearedSpot: null,
      mapSpotGuid: "",
      isAssignTenantDetailView: false,
      isAssignVisitorBTVisible: false,
      isAssignTenantBTVisible: false,
      isAssignTenant: false,
      unitNumberSearch: "",
      visitorSpotNumbers: [],
      unitNumbers: [],
      // unitNumbers: ["1110", "1111", "1112", "1113", "1114", "1115", "1116", "1117", "1118", "1119", "1120", "1121", "1122"],
      filterUnitNumber: [],
      showUnitNumberList: false,
      teantUnitNumber: "",
      teantSpotNo: "",
      teantName: "TestParker",
      teantEmail: "testparker@gryd.com",
      teantPhone: "9121231233",
      teantDuration: "June 21, 2022 - June 21,2023",
      vistorDuration: "",
      teantLicencePlate: ["AAA 111"],
      isAssignVisitor: false,
      unitGuid: "",
      assignVisitorTitle: "Assign Visitor to Tenant",
      isVisitorBookingConfirm: false,
      isAssignedTenanView: false,
      isAssignedVisitorTenantView: false,
      spotNumbers: [],
      mapPropertyGuid: "",
      mapSelectedSpotGuid: "",
      mapSelectedZoneGuid: "",
      assignSelectFlag: false,
      assignSelectedUnitNumber: "",
      selectedMapFloor: null,
      selectedMapProperty: null,
      mapStatus: 0,
      floorLevel: [],
      uspGetBookingBlockingStatus: [],
      showMapLoader: false,
      mapAssignSelectedSpot: "",
      deleteMapPopup: false,
      bookingMappedJson: null,
      showBlocker: false,
      isCommonMapUpload: false,
      selectedUnitObj: {},
      isYardiUser: false,
      isYardiSyncView: false,
      unitObj: {},
      clearPrivatelotSelectedItem: false,
      isScanRefreshed: false,
      isSpotActive: false,
      isUploadMapActive: false
    }

    this._privateLotService = new PrivateLotService();
    this._searchInput = null;
    this._searchByUnit = null;
    this._spotService = new SpotService();
  }

  async componentDidMount() {
    var isAccessible = await CheckScreenPermission("user-interface-lot-management");

    this.setState({
      isScreenAccessible: isAccessible
    }, () => {
      if (this.state.isScreenAccessible) {
        this.interval = setInterval(() => this.getStatusOfBookings(), 5000);
        this.loadProperties();
      }
    });

    window.addEventListener('resize', this.handleResize);
    window.addEventListener('beforeunload', this.beforeUnload);
    this.setState({
      filterUnitNumber: this.state.unitNumbers
    })
  }

  beforeUnload = (event: any) => {
    return UtilHelper.AlertBeforeWindowCloses(event, "Do you want close all popup?");
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    UtilHelper.CloseAllWindows();
    window.removeEventListener('beforeunload', this.beforeUnload);
    PreserveFilters.setSearchTerm('privateLotSearch', {
      searchTerm: this.state.searchTerm,
      selectedProperties: this.state.selectedProperties
    });
  }

  clearSearch = () => {
    this.setState({isFocused: true}, () => {
      if (this._searchInput)
        this._searchInput.focus();

      this._searchTermChange('');
    });
  }

  focusIn = () => {
    if (this._searchInput)
      this._searchInput.focus();


    this.setState({isFocused: true});
  }

  focusOut = () => {
    this.setState({isFocused: false});
  }

  render() {
    if (this.context.info.syncing) {
      this._privateLotService.GetBatchProcessSyncYardi(this.context.info.batchGuid, true)
        .then(response => {
          if (response.ok) {
            response.json().then(() => {
              this.context.setInfo({syncing: false, batchGuid: null});
            }).catch(() => {
              console.debug("There was an error processing your request.");
            });
          } else if (response.status === StatusCodes.NO_CONTENT) {
            console.debug("Sync in progress");
          } else if (response.status === StatusCodes.BAD_REQUEST) {
            this.context.setInfo({syncing: false, batchGuid: null});
            console.debug("There was an error processing your request.");
            console.debug(response.body);
          }
        }).catch(() => {
        console.debug("There was an error processing your request.");
      });
    }
    if (!this.state.isScreenAccessible) return null;

    // const isAddMenuOpen=this.state.anchorEl;
    const isAddMenuOpen = Boolean(this.state.anchorEl);
    const {selectedPrivateLot, viewType, isMultiSelectedProperty} = this.state;
    // console.log("=======viewtype1====" + viewType)
    const licensePlate = selectedPrivateLot &&
    selectedPrivateLot.propertyDetails &&
    selectedPrivateLot.propertyDetails.length > 0 ?
      selectedPrivateLot.propertyDetails[0].licensePlate : "";

    return (
      <React.Fragment>
        {this.state.isUndoNotification && (
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.isUndoNotification}
            message={this.commonToastNotificationMsg()}
            className="common-toaster-pl"
            onClose={this.closeCommonNotificationMsg}
            autoHideDuration={30000}
          />
        )}
        {
          this.state.showMapLoader && <SpotMapLoader
                onCancel={() => {
                }}
                isCancelVisible={false}
                innerMessage="Please wait"
            />
        }
        {this.state.propertyBlocker && !this.state.showBlocker && this.getPropertyDetais()}
        <div className="data-grid-container private-lots-page without-checkbox-container">
          <Grid container className="mb-6 common-header">
            <Grid container item
                  className={(this.state.isTenantViewDetails ? 'private-lot-header-container t-view' : 'common-lf-header-container')}>
              {!this.state.isTenantViewDetails ? (
                <>
                  <PrivateLotsProperty
                    properties={this.state.properties}
                    onPropertyChange={(property_: IPrivateLotProperty[], floorValue?: IFloor | null) => {
                      // this.updateSelectedPropertyDetails(undefined, property_ as IPropertyBasics);

                      let properties = property_.filter(x => x.propertyGuid !== "blank");

                      this.setState({
                        selectedProperties: property_,
                        isMultiSelectedProperty: properties.length > 1,
                        isResolveTenant: false,
                        selectedMapFloor: floorValue ? floorValue : null,
                        showMapLoader: this.state.isMapVisible,
                        clearPrivatelotSelectedItem: true
                      }, () => {
                        this.getFloorLevels(this.state.isMapVisible, null, () => {
                          this.getTenantPrivateLot(true);
                          if (!this.state.isMapVisible) {
                            this.filterTenantPrivateLot();
                          } else {
                            this.RetrievePropertyMap(true);
                          }
                        });
                      })
                    }}
                    selectedProperties={this.state.selectedProperties}
                    id="top-property"
                    allPropertyNotRequired={true}
                    isMultiSelectNotRequired={true}
                    floorLevel={this.state.floorLevel}
                    selectedMapFloor={this.state.selectedMapFloor}
                    isMapView={this.state.isMapVisible}
                  />
                  <Grid item xs>
                    <Grid container>
                      <Grid item className='p-button-container-grid' xs>
                        {
                          !this.state.isMapVisible &&
                            <div className={`p-button-container ${isMultiSelectedProperty ? "disble-control" : ""}`}
                                 onClick={isMultiSelectedProperty ? () => {
                                 } : () => {
                                   const {selectedProperties} = this.state;

                                   let filteredProperties = selectedProperties.filter(x => x.propertyGuid !== "blank");

                                   if (filteredProperties.length === 1) {
                                     this.setState({
                                       // isMapVisible: !this.state.isMapVisible,
                                       viewType: "map",
                                       isAssignTenant: false,
                                       isResolveTenant: false
                                     }, () => {
                                       this.getFloorLevels();
                                     });
                                   } else {
                                     this.setState({
                                       isMapVisible: !this.state.isMapVisible,
                                       mapUploadingStatus: {
                                         progress: EnumInteractiveMapStatus.UploadMap,
                                         selectedSpotInfo: null,
                                         selectedZone: null
                                       },
                                       viewType: "map",
                                       isResolveTenant: false
                                     }, () => {
                                       /*this.setState({
                                                                        figmaNodeId: ""
                                                                    })*/
                                     });
                                   }
                                 }}>
                                <div className='p-button-icon'><MapIcon/></div>
                                <div>Map View</div>
                            </div>
                        }

                        {
                          this.state.isMapVisible && <div className='p-button-container' onClick={() => {
                            this.setState({
                              isMapVisible: !this.state.isMapVisible,
                              viewType: "list",
                              mapSpotGuid: "", isResolveTenant: false, isAssignTenantDetailView: false,
                              isAssignedTenanView: false, isAssignedVisitorTenantView: false
                            }, () => {
                              this.filterTenantPrivateLot();
                            })
                          }}>
                                <div className='p-button-icon'><FormatListBulletedIcon/></div>
                                <div>List View</div>
                            </div>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <div className='mt-2 d-flex flex-column px-4'>
                  {this.state.selectedPrivateLot && (
                    <>
                      <div className='d-flex align-items-center'>
                        <div onClick={() => {
                          this.setState({
                            selectedPrivateLot: null,
                            selectedTenant: null,
                            selectedReservation: "",
                            isTenantViewDetails: false,
                            subFilteredViewTenantDetailsList: [],
                            allViewTenantDetailsList: [],
                            filteredViewTenantDetailsList: [],
                            isUndoNotification: false,
                            removeAccessUndoList: [],
                            toastNotifyType: "",
                            editSavedResponse: [],
                            SelectedParkingPassess: []
                          }, () => {
                            this.getTenantPrivateLot();
                          })
                        }}>
                          <ChevronLeft/>
                        </div>
                        <div className='details-tenant-name'>
                          {this.state.selectedPrivateLot.unitNumber}
                        </div>
                      </div>
                      <div className='details-tenant-email px-4'>
                        {
                          licensePlate
                        }
                        {/* {this.state.selectedPrivateLot.email} . {this.state.selectedPrivateLot.phoneNumber} */}
                      </div>
                    </>
                  )}
                </div>
              )}
            </Grid>
            <Grid container item
                  className={`private-rt-header-container ${(viewType === "list" || viewType === "") ? "private-rt-header-container-25" : ""}`}
                  style={{marginBottom: 5}}>
              <Grid item className="d-flex align-items-center">
                {(viewType === "list" || viewType === "") &&
                    <Paper onFocus={this.focusIn} onBlur={this.focusOut} variant="outlined" style={{width: 175}}
                           className={`table-search-input-container privatelotPage ${this.state.isFocused ? "privatelotPagesFocus" : ""}`}>
                        <TextField
                            id="outlined-basic"
                            placeholder='Search'
                          //variant="outlined"
                            className="mt-2"
                            style={{width: 120}}
                            autoComplete='off'
                            size="small"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start" className='InputAdornment'>
                                  <SearchIcon style={{color: "#6B7278"}}/>
                                </InputAdornment>
                              ),
                              'aria-label': 'search tenant',
                              autoComplete: 'off'
                            }}
                            value={this.state.searchTerm}
                            onChange={(e) => {
                              this._searchTermChange(e.target.value);
                            }}
                            inputRef={refElm => {
                              this._searchInput = refElm;
                            }}
                        />
                      {
                        this.state.searchTerm.length > 0 &&
                          <Tooltip title="Clear">
                              <IconButton type="submit" aria-label="Clear" onClick={this.clearSearch}>
                                  <ClearIcon/>
                              </IconButton>
                          </Tooltip>
                      }
                    </Paper>
                }
              </Grid>
              <Grid item className="d-flex align-items-center pr-3">
                <div className={(this.state.isNotificationEnabled ? "notify-active" : "") + " btn-notification"}
                     onClick={() => this.setState({isNotificationEnabled: !this.state.isNotificationEnabled})}>
                  {
                    <Badge color="primary" overlap="circle" variant="dot"
                           invisible={!(this.state.privateLotNotifications && this.state.privateLotNotifications.length > 0)}>
                      <NotificationsOutlined/>
                    </Badge>
                  }
                </div>
              </Grid>
              <Grid item className="d-flex align-items-center">
                <IconButton className='add-lit-button'
                            onClick={this.handleAddIconButton}>
                  <AddIcon fontSize="small"/>
                </IconButton>
                {/* <Button
                                    id="basic-button"
                                    className='add-lit-button'
                                    aria-controls={this.state.isAddMenuOpen ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={this.state.isAddMenuOpen ? 'true' : undefined}
                                    onClick={this.handleAddIconButton}
                                >
                                     <AddIcon fontSize="small" />
                                </Button> */}
                <Menu
                  id="basic-menu"
                  anchorEl={this.state.anchorEl}
                  open={isAddMenuOpen}
                  onClose={this.handleAddIconButtonClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={() => {
                    const {selectedProperties} = this.state;

                    let selectedProperty = selectedProperties.filter(x => x.propertyGuid !== "blank");

                    let errorMessage = "";
                    let isError = false;
                    if (selectedProperty?.length === 1 && selectedProperty[0].isYardiProperty) {
                      errorMessage = "Not allowed for third party managed property";
                      isError = true;
                    }

                    if (isError) {
                      ShowAlert("", errorMessage, "warning");
                      return;
                    }

                    this.setState({
                      isAddNewTenant: true,
                      isBulkUpload: false,
                      anchorEl: null,
                      assignSelectFlag: false,
                      rightPanelState: RightPanelState.Add
                    })
                  }}>Add New Tenant</MenuItem>
                  <MenuItem onClick={() => {
                    this.setState({
                      isAddNewTenant: false,
                      isBulkUpload: true,
                      anchorEl: null,
                    })
                  }}>Bulk Upload</MenuItem>
                  <MenuItem onClick={() => {
                    this.setState({
                      isAddNewTenant: false,
                      anchorEl: null,
                      isCommonMapUpload: true
                    }, () => {
                      this.mapUpload();
                    })
                  }}>Upload Map</MenuItem>
                </Menu>
              </Grid>
            </Grid>
            {this.state.isMultiSelectedProperty && (
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={this.state.isMultiSelectedProperty}
                message={this.multiSelectedPropertyMsg()}
                className="notify-toaster-pl"
              />
            )}
          </Grid>
          {this.context.info.syncing ?
            <div style={{backgroundColor: '#ffffff', paddingLeft: '20px', paddingTop: '10px'}}>
              <div style={{
                borderWidth: '1px',
                borderColor: 'blue',
                borderRadius: '5px',
                backgroundColor: 'lightblue',
                width: 'fit-content',
                padding: '10px',
              }}>
                <InfoIcon style={{paddingRight: '5px'}}/>
                <span>Your lot is now being synced, this may take a few minutes.</span>
              </div>
            </div> : []}

          <div className='private-lots-body p-map-body'>

            <div className='private-lots-parent-container p-3'>
              {
                this.state.isMapVisible ?
                  <>
                    <SpotMap
                      isToolTipRequired={false}
                      tooltipFilter={(spotGuid: string) => {
                        const {
                          isOverDue, isUpComingSpot, illegalParking, spotBooking,
                          noOfDays, isIllegalParking, isUnAvailable, isVisitorParking, existingUser
                        } = this.getSpotBookingStatus(spotGuid);

                        let toolTip: any = isIllegalParking ? illegalParking : spotBooking;
                        let existingUnitNumber: string = existingUser?.unitNumber ?? "";
                        let tooltipContent = '<div class=""><span class=""><div className="toolTip-f-w-400">No tenant assigned</div></span></div>';
                        if (toolTip && Object.keys(toolTip).length > 0) {
                          tooltipContent = '<div class=""><span class="">';
                          tooltipContent += existingUnitNumber ? '<div class="toolTip-f-w-700">' + existingUnitNumber + '</div>' : "";
                          tooltipContent += isIllegalParking ? '<div class="toolTip-f-w-700 red-color">' + illegalParking?.reportReason + '</div>' : "";
                          tooltipContent += !isIllegalParking ? '<div class="toolTip-f-w-700">' + (toolTip.unitNumber ?? "") + '</div>' : "";
                          tooltipContent += (!isIllegalParking && isOverDue) ? '<div class="toolTip-f-w-400">This lease is <b> ' + noOfDays + ' days overdue</b>. Please resolve this immediately.</div>' : "";
                          tooltipContent += (!isIllegalParking && isUpComingSpot) ? '<div class="toolTip-f-w-400">This spot will become available on ' +
                            '<b> ' + (toolTip ? DateTimeHelper.GetMonthYearFormat(new Date(toolTip.endTimeLocal), 1) : "") + '</b>, ' +
                            'please renew if you need to.</div>' : "";
                          tooltipContent += isUnAvailable ? '<div class="toolTip-f-w-400">Spot: ' + toolTip.friendlySpotId + ' is blocked </div>' : "";
                          if ((!isOverDue && !isUnAvailable && !isUpComingSpot) || isIllegalParking) {
                            let toolTipLicenseNo: string[] = [];
                            let illegalParkingLicensePlate: string[] = [];
                            if (!isIllegalParking && toolTip.licenseNo?.split(',').length > 0) {
                              toolTipLicenseNo = toolTip.licenseNo.split(',').filter((x: string) => x !== "");
                            }
                            if (isIllegalParking && illegalParking?.licensePlate && illegalParking.licensePlate.split(',').length > 0) {
                              illegalParkingLicensePlate = illegalParking.licensePlate.split(',').filter(x => x !== "");
                            }
                            if (toolTipLicenseNo.length > 0 || illegalParkingLicensePlate.length > 0) {
                              tooltipContent += '<div class="toolTip-f-w-400 mt-2">' + (isVisitorParking ? "Visitor " : "") + ' License Plate(s)</div><div class="grid toolTip-item-container">';
                              toolTipLicenseNo.map((item: any) => {
                                tooltipContent += '<div class="toolTip-item" style={{}}><label class="toolTip-item-label"> ' + StringHelper.truncate(item, 9) + '</label></div>';
                                return item;
                              });
                              illegalParkingLicensePlate.map((item: any) => {
                                tooltipContent += '<div class="toolTip-item" style={{}}><label class="toolTip-item-label"> ' + StringHelper.truncate(item, 9) + '</label></div>';
                                return item;
                              });
                              tooltipContent += '</div>';
                            }
                            tooltipContent += '</div>';
                          }

                          tooltipContent += '</span></div>';
                        }
                        return tooltipContent;
                      }}
                      selectedFloor={this.state.selectedMapFloor}
                      figmaNodeId={this.state.figmaNodeId}
                      onDone={(isLoadedFromServer: boolean, mappedJson: IFigmaNodes | null) => {
                        if (!isLoadedFromServer) {
                          this.setState({
                            toastNotifyType: "Map has uploaded successfully.",
                            isUndoNotification: true,
                            isMapLoaded: true,
                            bookingMappedJson: mappedJson,
                            showMapLoader: false
                          }, () => {
                            setTimeout(() => {
                              this.setState({
                                toastNotifyType: "",
                                isUndoNotification: false,
                              }, () => {
                                let selectedProperties_: IPrivateLotProperty[] = this.state.selectedProperties.filter(x => x.propertyGuid !== "blank");
                                let propertyGuid = selectedProperties_.length > 0 ? selectedProperties_[0].propertyGuid : "";

                                if (propertyGuid !== "" && this.state.mapUploadingStatus.progress === EnumInteractiveMapStatus.MappingCompleted) {
                                  this.getSpotStatus(propertyGuid, true, true);
                                }
                              });
                            }, 3000);
                          })
                        } else {
                          this.getFloorLevels(this.state.isMapVisible, null, () => {
                            var selectedFloor_ = this.state.selectedMapFloor;
                            let isFloorMapUploaded = this.state.floorLevel.filter(x => x.hasMap === true && x.levelId === selectedFloor_?.levelId).length;

                            if (isFloorMapUploaded === 0) {
                              selectedFloor_ = this.state.selectedMapFloor;
                            }

                            this.setState({
                              isMapLoaded: true,
                              bookingMappedJson: mappedJson,
                              showMapLoader: false,
                              selectedMapFloor: selectedFloor_
                            }, () => {
                              let selectedProperties_: IPrivateLotProperty[] = this.state.selectedProperties.filter(x => x.propertyGuid !== "blank");

                              let propertyGuid = selectedProperties_.length > 0 ? selectedProperties_[0].propertyGuid : "";

                              if (propertyGuid !== "" && this.state.mapUploadingStatus.progress === EnumInteractiveMapStatus.MappingCompleted) {
                                this.getSpotStatus(propertyGuid, true, true);
                              }
                            })
                          });

                        }
                      }}
                      isSpotMapLoaded={this.state.isMapLoaded}
                      propertyGuid={this.state.selectedProperties.length > 0 ? this.state.selectedProperties.filter(x => x.propertyGuid !== "blank")[0].propertyGuid : ''}
                      selectedProperty={this.state.selectedProperties.length > 0 ? this.state.selectedProperties.filter(x => x.propertyGuid !== "blank")[0] : null}
                      progressDetails={this.state.mapUploadingStatus}
                      onProgressUpdate={(status: EnumInteractiveMapStatus, selectedSpotInfo?: ZoneActiveSpot,
                                         resetOption?: IMapResetOption, spotDetails?: ISelectedSpotDetails) => {
                        let newState = {
                          mapUploadingStatus: {
                            progress: status,
                            selectedSpotInfo: selectedSpotInfo ? selectedSpotInfo : null,
                            selectedZone: this.state.mapUploadingStatus.selectedZone
                          },
                          mapResetState: {
                            Cancel: false,
                            ResetAllSpotAttributes: false,
                            ResetMap: false
                          },
                          mapSpotGuid: "",
                          isResolveTenant: false,
                          isAssignTenantDetailView: false,
                          isAssignedTenanView: false
                        } as any;

                        if (spotDetails) {
                          newState.mapSelectedSpotDetails = spotDetails
                        }


                        this.setState(newState, () => {
                          let selectedProperties_: IPrivateLotProperty[] = this.state.selectedProperties.filter(x => x.propertyGuid !== "blank");

                          let propertyGuid = selectedProperties_.length > 0 ? selectedProperties_[0].propertyGuid : "";

                          if (this.state.mapUploadingStatus.progress === EnumInteractiveMapStatus.UploadMap) {
                            this.RetrievePropertyMap();
                          } else if (propertyGuid !== "" && this.state.mapUploadingStatus.progress === EnumInteractiveMapStatus.MappingCompleted) {
                            this.getSpotStatus(propertyGuid, true, true);
                          }

                        })
                      }}
                      onSpotUpdate={(spotDetails: ISelectedSpotDetails) => {
                        this.setState({
                          mapSelectedSpotDetails: spotDetails
                        })
                      }}
                      resetState={this.state.mapResetState}
                      clearedSpot={this.state.clearedSpot}
                      onCancelUpload={() => {
                        this.setState({
                          isMapVisible: false
                        }, () => {
                          this.setState({
                            isMapVisible: true,
                            figmaNodeId: "",
                            mapUploadingStatus: {
                              progress: EnumInteractiveMapStatus.UploadMap,
                              selectedSpotInfo: null,
                              selectedZone: null
                            }
                          })
                        })

                      }}
                      onSpotSelect={(spot: IMappedSpotDetails) => {
                        let selectedProperties_: IPrivateLotProperty[] = this.state.selectedProperties.filter(x => x.propertyGuid !== "blank");

                        this.setState({
                          mapSelectedSpotGuid: spot.spotGuid,
                          mapPropertyGuid: selectedProperties_.length > 0 ? selectedProperties_[0].propertyGuid : '',
                          mapSelectedZoneGuid: spot.zoneGuid,
                          teantSpotNo: spot.FriendlySpotId,
                          mapAssignSelectedSpot: spot.FriendlySpotId
                        }, () => {
                          const status = this.getSpotBookingStatus(this.state.mapSelectedSpotGuid);
                          if (status.isBookedSpot || status.isAvailable || status.isVisitorParking) {
                            const assignedDetails = this.getAssignedDetails(spot.spotGuid);
                            if (status.isBookedSpot) {
                              this.setState({
                                ...assignedDetails,
                                isAssignedTenanView: false,
                              }, () => {
                                this.setState({
                                  isResolveTenant: false,
                                  isMultiSelectedProperty: false,
                                  isAssignTenantDetailView: false,
                                  isAssignedTenanView: true,
                                  isAssignedVisitorTenantView: false
                                })
                              })

                            } else if (status.isAvailable) {
                              var showAssignTenant = false;
                              var showAssignVisitor = false;
                              this.setState({
                                ...assignedDetails,
                                isAssignTenantDetailView: false,
                              }, () => {
                                this.setState({
                                  isResolveTenant: false,
                                  isMultiSelectedProperty: false,
                                  isAssignTenantDetailView: true,
                                  isAssignedTenanView: false,
                                  isAssignedVisitorTenantView: false,
                                  isAssignVisitorBTVisible: false,
                                  isAssignTenantBTVisible: false,
                                  showBlocker: true
                                }, () => {
                                  this._privateLotService.get_interactive_map_features_enabled(spot.zoneGuid, spot.spotGuid)
                                    .then(r => {
                                      if (r.ok) {
                                        r.json().then(response => {
                                          if (Array.isArray(response)) {
                                            showAssignTenant = response.findIndex(feature => feature.featureId === 8) !== -1;
                                            showAssignVisitor = response.findIndex(feature => feature.featureId === 13) !== -1;
                                            this.setState({
                                              isAssignVisitorBTVisible: showAssignVisitor,
                                              isAssignTenantBTVisible: showAssignTenant,
                                              showBlocker: false,
                                              isSpotActive: true
                                            });
                                          } else {
                                            this.setState({
                                              showBlocker: false
                                            })
                                          }
                                        })
                                      } else {
                                        this.setState({
                                          showBlocker: false,
                                          isAssignVisitorBTVisible: false,
                                          isAssignTenantBTVisible: false,
                                          isSpotActive: false
                                        });
                                      }
                                    })
                                })
                              })
                            } else if (status.isVisitorParking) {
                              this.setState({
                                ...assignedDetails,
                                isAssignedVisitorTenantView: false
                              }, () => {
                                this.setState({
                                  isResolveTenant: false,
                                  isMultiSelectedProperty: false,
                                  isAssignTenantDetailView: false,
                                  isAssignedTenanView: false,
                                  isAssignedVisitorTenantView: true
                                })
                              })
                            }
                          } else if (status.isUnAvailable) {
                            this.setState({
                              mapSpotGuid: "", isResolveTenant: false, isAssignTenantDetailView: false,
                              isAssignedTenanView: false,
                              isAssignedVisitorTenantView: false
                            });
                          } else {
                            this.setState({
                              mapSpotGuid: spot.spotGuid, isResolveTenant: true, isAssignTenantDetailView: false,
                              isAssignedTenanView: false, isAssignedVisitorTenantView: false
                            });
                          }

                        });
                      }}
                      onSpotDeselect={() => {
                        this.setState({
                          mapSpotGuid: "", isResolveTenant: false, isAssignTenantDetailView: false,
                          isAssignedTenanView: false,
                          isAssignedVisitorTenantView: false
                        });
                      }}
                      updatedMappedJson={this.state.bookingMappedJson}
                    />
                  </>
                  :
                  <>
                    {this.state.isTenantViewDetails ?
                      (<>
                        <DataGrid
                          key="1"
                          title="View Tenant Details"
                          data={this.state.subFilteredViewTenantDetailsList}
                          selectedItems={this.state.isRenew ? [] : this.state.SelectedParkingPassess}
                          columns={this._privateLotsDetailsColumns}
                          isNoMoreLink={false}
                          onMoreClick={(item_) => {
                            return item_.yardiRedirectURL !== "" ? [{
                              label: "Renew",
                              key: "renew"
                            }, {
                              label: "Remove Access",
                              key: "removeAccess",
                              textStyle: "remove-access-item"
                            }] : [
                              {
                                label: "Renew",
                                key: "renew"
                              }, {
                                label: "Edit Tenant",
                                key: "editTenant"

                              }, {
                                label: "Remove Access",
                                key: "removeAccess",
                                textStyle: "remove-access-item"
                              }
                            ]

                          }
                          }
                          multiPopoverItems={[
                            {
                              label: "Renew",
                              key: "renew"
                            },
                            {
                              label: "Remove Access",
                              key: "removeAccess",
                              textStyle: "remove-access-item"
                            }
                          ]}
                          onMultiPopoverClick={(actionKey_, items_) => {
                            if (actionKey_ === "removeAccess") {
                              this.setState({removedLicensePlate: ''}, () => {
                                this.bulkRemoveAccessTenantDetails(items_);
                              });
                            } else if (actionKey_ === "renew") {
                              const hasNonYardiItem = items_.filter(item => item.yardiRedirectURL === "");
                              if (hasNonYardiItem.length === 0) {
                                this.setState({
                                  isCustomRenew: false,
                                  rightPanelState: RightPanelState.None,
                                  SelectedParkingPassess: [],
                                  isParkingLeasesUpdated: true,
                                  isWidgetRefreshed: true
                                }, () => {
                                  this.bulkRenewLease(items_, "custom");
                                });
                              } else {
                                this.setState({
                                  isCustomRenew: true,
                                  rightPanelState: RightPanelState.Add,
                                  SelectedParkingPassess: items_,
                                  isRenew: false,
                                  isRenewUpcoming: false
                                });
                              }
                            }
                          }}
                          onPopoverClick={(key_, lots_) => {
                            if (key_ === "editTenant") {
                              this.setState({
                                rightPanelState: RightPanelState.Edit,
                                selectedReservation: lots_.reservationGuid,
                                selectedTenant: this.state.selectedPrivateLot,
                                isAddNewTenant: true
                              })
                            } else if (key_ === "removeAccess") {
                              this.setState({removedLicensePlate: ''}, () => {
                                if (lots_.yardiRedirectURL !== "") {
                                  UtilHelper.lanuchPopup(lots_.yardiRedirectURL, "Popup", true, () => {
                                    this.refreshSyncYardi(lots_.propertyGuid, () => {
                                      this.setState({
                                        toastNotifyType: "Tenant details updated successfully.",
                                        isUndoNotification: true,
                                        isParkingLeasesUpdated: true,
                                        mapSpotGuid: "", isResolveTenant: false,
                                        isAssignTenantDetailView: false,
                                        isAssignedTenanView: false,
                                        isAssignedVisitorTenantView: false,
                                        isWidgetRefreshed: true,
                                      }, () => {
                                        this.getTenantLeaseInDetail();
                                      });
                                    });
                                  });
                                } else {
                                  this.removeAccessTenantDetails(lots_);
                                }
                              });
                            } else if (key_ === "renew") {
                              let parkingPasses_ = [];
                              parkingPasses_.push(lots_);
                              if (lots_.yardiRedirectURL !== "") {
                                UtilHelper.lanuchPopup(lots_.yardiRedirectURL, `Popup`, true, () => {
                                  this.refreshSyncYardi(lots_.propertyGuid, () => {
                                    this.setState({
                                      toastNotifyType: "Tenant details updated successfully.",
                                      isUndoNotification: true,
                                      SelectedParkingPassess: [],
                                      isParkingLeasesUpdated: true,
                                      isWidgetRefreshed: true,
                                    }, () => {
                                      this.getTenantLeaseInDetail();
                                    });
                                  });
                                });
                              } else {
                                this.setState({
                                  isCustomRenew: true,
                                  rightPanelState: RightPanelState.Add,
                                  SelectedParkingPassess: parkingPasses_,
                                  isRenew: false,
                                  isRenewUpcoming: false
                                })
                              }

                            }
                          }}
                          onRowClick={() => {
                            this.setState({
                              isTenantViewDetails: true
                            });
                          }}
                        />
                      </>)
                      : (<>
                        <DataGrid
                          key="2"
                          title="Lot Management"
                          data={this.state.subFilteredTenantDetails}
                          columns={this._privateLotsListColumns}
                          clearSelectedItem={(_selectAllRows: () => void, selectedItems: IPrivateLotsItem[]) => {
                            if (this.state.clearPrivatelotSelectedItem) {
                              this.setState({clearPrivatelotSelectedItem: false});
                              selectedItems.length > 0 && _selectAllRows();
                            }
                          }}
                          isNoMoreLink={false}
                          multiPopoverItems={[
                            {
                              label: "Remove Access",
                              key: "removeAccess",
                              textStyle: "remove-access-item"
                            }
                          ]}
                          onMultiPopoverClick={(actionKey_, items_) => {
                            const yardiItem = items_.findIndex(item => item.yardiRedirectURL !== "") !== -1;
                            const selectProperty = this.state.selectedProperties.filter(x => x.propertyGuid !== "blank");
                            if (actionKey_ === "removeAccess") {
                              const privateLotPropertyDetails: PrivateLotPropertyDetails[] = items_.map(x => x.propertyDetails).flat(1);
                              const propertyGuids: string[] = privateLotPropertyDetails.map((property: PrivateLotPropertyDetails) => property.propertyGuid)
                                .filter((propertyGuid: string, index: number, self: string[]) => self.indexOf(propertyGuid) === index);
                              if (yardiItem) {
                                if (selectProperty.length > 1) {
                                  ShowAlert("Remove Tenant", "Please select only one property to remove yardi unit(s).", "warning");
                                  return false;
                                } else if (propertyGuids.length > 1) {
                                  ShowAlert("Remove Tenant", "Yardi unit(s) has multiple different properties.", "warning");
                                  return false;
                                }
                              }
                              this.bulkRemoveAccess(items_, yardiItem);
                            }
                          }}
                          onMoreClick={(item_) => {
                            return item_.yardiRedirectURL !== "" ? [{
                              label: "Remove Access",
                              key: "removeAccess",
                              textStyle: "remove-access-item"
                            }] : [
                              {
                                label: "Edit Tenant",
                                key: "editTenant"
                              },
                              {
                                label: "Remove Access",
                                key: "removeAccess",
                                textStyle: "remove-access-item"
                              }
                            ]
                          }}
                          onPopoverClick={(key_, lots_) => {
                            if (key_ === "editTenant") {
                              this.setState({
                                rightPanelState: RightPanelState.Edit,
                                selectedTenant: lots_,
                                isAddNewTenant: true
                              })
                            } else if (key_ === "removeAccess") {
                              if (lots_.yardiRedirectURL !== "") {
                                const propertyGuids = lots_.propertyDetails.map(x => x.propertyGuid)
                                  .filter((propertyGuid: string, index: number, self: string[]) => self.indexOf(propertyGuid) === index);
                                if (propertyGuids.length > 1) {
                                  ShowAlert("Remove Unit", "This yardi unit has multiple different properties.", "warning");
                                  return false;
                                }
                                UtilHelper.lanuchPopup(lots_.yardiRedirectURL, "Popup", true, () => {
                                  this.refreshSyncYardi(propertyGuids[0], () => {
                                    this.setState({
                                      toastNotifyType: "Yardi unit(s) updated successfully.",
                                      isUndoNotification: true,
                                      isParkingLeasesUpdated: true,
                                      mapSpotGuid: "", isResolveTenant: false,
                                      isAssignTenantDetailView: false,
                                      isAssignedTenanView: false,
                                      isAssignedVisitorTenantView: false,
                                      isWidgetRefreshed: true,
                                    }, () => {
                                      this.getTenantPrivateLot();
                                    })
                                  });
                                });
                              } else {
                                this.removeAccess(lots_);
                              }
                            }
                          }}
                          onRowClick={pLots_ => {
                            this.setState({
                              selectedPrivateLot: pLots_,
                              selectedTenant: pLots_,
                              isTenantViewDetails: true
                            }, () => {
                              this.getTenantLeaseInDetail();
                            });
                          }}
                        />
                      </>)}
                  </>
              }
            </div>
            <div className="private-lots-parent-right p-map-screen">
              {
                this.loadRightPanel()
              }

              {this.state.isNotificationEnabled &&
                (<div className="notification-container">
                  <div className='d-flex flex-column pt-4 px-4'>
                    <div className='d-flex justify-content-between'>
                      <div className='notify-header'>
                        Notifications
                      </div>
                      <div className='text-right'>
                        <CloseIcon className='close-btn'
                                   onClick={() => this.setState({isNotificationEnabled: !this.state.isNotificationEnabled})}/>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex flex-column pt-3 common-notification-body'>
                    <div className='common-notification-sub-body'>
                      {this.state.privateLotNotifications.map((n_, i_) => {
                        return <div className={"notification-border mx-4"} key={i_}>
                          <div className='pt-2'>
                            {format(n_.endTime, "MM/dd/yyyy")} at {format(n_.endTime, "h:mm aa")}
                          </div>
                          <div className='' dangerouslySetInnerHTML={{__html: n_.message}}/>
                          <div className='pt-1 pb-2'>
                            <a
                              className="common-more-details n-setup-bg-color"
                              onClick={() => {
                                let pLotList: IPrivateLotsItem[] = this.state.allTenantDetails.filter(x => x.privateLotUnitGuid === n_.privateLotUnitGuid);
                                let pLots_ = null;
                                if (pLotList.length > 0) {
                                  pLots_ = pLotList[0];
                                }
                                this.setState({
                                  selectedPrivateLot: pLots_,
                                  selectedTenant: pLots_,
                                  isTenantViewDetails: true,
                                  isNotificationEnabled: false
                                }, () => {
                                  this.getTenantLeaseInDetail();
                                });
                              }}
                            >View Tenant</a>
                          </div>
                        </div>
                      })}
                    </div>
                  </div>
                </div>)}
            </div>
          </div>
          {
            this.state.isBulkUpload && this.getBlukUploadContent()
          }
          {
            this.state.deleteMapPopup && this.renderUploadMap()
          }

          <Drawer
            anchor="right"
            variant={this.state.rightPanelState === RightPanelState.None ? "persistent" : "temporary"}
            open={this.state.rightPanelState !== RightPanelState.None}
            className="data-grid-right-panel"
            onClose={this.onCloseDrawerPanel}
          >
            {this.state.rightPanelState !== RightPanelState.TriggerYardiSync && <div className="mx-3">
                <Grid container
                      className={"right-panel-header p-2 " + ((this.state.isBlockSpotOpened || this.state.isCustomRenew) ? "" : " border-bottom")}>
                  {(this.state.isBlockSpotOpened || this.state.isCustomRenew) ? (
                    <Grid item xs>
                    </Grid>
                  ) : (
                    <Grid item xs>
                      <h4 className="right-panel-title m-0 ml-3">
                        {this._getRightPanelTitle()}
                      </h4>
                    </Grid>
                  )}

                    <Grid item>
                        <Button
                            onClick={this.onCloseDrawerPanel}>
                            <CloseIcon/>
                        </Button>
                    </Grid>
                </Grid>
            </div>
            }
            {this._getRightPanelContent()}
          </Drawer>
        </div>
      </React.Fragment>
    )
  }

  getBookingStatus = (spotGuid: string) => {
    let spotBooking = this.state.mapBookingModel?.bookings.find(pspot => pspot.spotGuid === spotGuid);
    let noOfDays = spotBooking ? DateTimeHelper.GetDays(new Date(new Date().toUTCString()), new Date(spotBooking.endTimeLocal)).toString() : "";
    let isOverDue = false;
    let isUpComingSpot = false;
    let isBookedSpot = false;
    let isAvailable = true;
    let isYardiUser = spotBooking?.yardiRedirectURL !== "";

    if (!isNaN(parseInt(noOfDays))) {
      if (!spotBooking?.isLeaseOnNotice && parseInt(noOfDays) < 0) {
        isOverDue = true;
      } else if ((isYardiUser && spotBooking?.isLeaseOnNotice && parseInt(noOfDays) >= 0) ||
        (!spotBooking?.isLeaseOnNotice && parseInt(noOfDays) >= 0 && parseInt(noOfDays) < 30)) {
        isUpComingSpot = true;
      } else {
        isBookedSpot = true;
      }
    }

    if (isOverDue || isUpComingSpot || isBookedSpot) {
      isAvailable = false;
    }

    return {
      "isAvailable": isAvailable,
      "isBookedSpot": isBookedSpot,
      "isOverDue": isOverDue,
      "isUpComingSpot": isUpComingSpot
    }
  }

  getAssignedDetails = (selectedSpotGuid: string) => {
    const {spotBooking} = this.getSpotBookingStatus(selectedSpotGuid);
    var startTime = moment(spotBooking?.spotStartTimeLocal)
    var endTime = moment(spotBooking?.spotEndTimeLocal)
    var DurationInMinutes = endTime.diff(startTime, 'minutes')
    var DurationInhours = endTime.diff(startTime, 'hours')


    var fromNdtoDate = `${moment(spotBooking?.startTimeLocal).format('MMM DD, YYYY')} - ${moment(spotBooking?.endTimeLocal).format('MMM DD, YYYY')}`
    var visitorDurationFormat = `${DurationInMinutes < 60 ? `${DurationInMinutes} Minutes` : `${DurationInhours} Hours`} | ${moment(spotBooking?.startTimeLocal).format('MMMM DD, YYYY')} `
    return {
      teantSpotNo: String(spotBooking?.friendlySpotId),
      teantUnitNumber: String(spotBooking?.unitNumber),
      teantName: `${spotBooking?.parkerFirstName} ${spotBooking?.parkerLastName}`,
      teantLicencePlate: String(spotBooking?.licenseNo).split(','),
      teantDuration: fromNdtoDate,
      vistorDuration: visitorDurationFormat,
      teantPhone: spotBooking?.parkerPhoneNumber ?? "",
      teantEmail: spotBooking?.parkerEmail ?? "",
      isYardiUser: spotBooking?.yardiRedirectURL !== ""
    }

  }

  getSpotStatus = (propertyGuid: string, isLoader: boolean = false, isFromMapDone: boolean = false) => {
    this._privateLotService.getBookings(propertyGuid, isLoader)
      .then(res => {
        if (res.ok) {
          res.json().then(response => {
            const mapBookingModel: IMapBookingModel = response;
            if (JSON.stringify(this.state.mapBookingModel) !== JSON.stringify(mapBookingModel) || isFromMapDone) {
              const spotBookings = this.priorityBooking(mapBookingModel);
              let updatedMappedJson = this.checkBookingStatus(this.state.bookingMappedJson, spotBookings);
              this.setState({
                mapSpotGuid: "",
                isAssignTenantDetailView: false,
                isAssignedTenanView: false,
                isAssignedVisitorTenantView: false,
                mapBookingModel: mapBookingModel,
                isResolveTenant: false,
                bookingMappedJson: updatedMappedJson,
              });
            }
          });
        }
      });
  }

  assignPrivateLotsDetailsItem = (spotBooking: ISpotBooking) => {
    const items_: IPrivateLotsDetailsItem = {
      tenant: "",
      email: "",
      phoneNumber: "",
      propertyName: "",
      leaseStartDateLocal: spotBooking.startTimeLocal,
      leaseEndDateLocal: spotBooking.endTimeLocal,
      rate: 0,
      zoneName: "",
      spotName: "",
      propertyGuid: spotBooking.propertyGuid,
      spotGuid: spotBooking.spotGuid,
      zoneGuid: "",
      reservationGuid: spotBooking.reservationGuid,
      userGuid: "",
      privateLotUnitGuid: spotBooking.unitGuid,
      unitNumber: spotBooking.unitNumber,
      yardiRedirectURL: spotBooking.yardiRedirectURL
    }
    return items_;
  }

  loadRightPanel = () => {
    if (!this.state.isNotificationEnabled) {
      const {
        viewType,
        isResolveTenant,
        isMapVisible,
        mapUploadingStatus,
        isAssignTenantDetailView,
        isAssignedTenanView,
        isAssignVisitor
      } = this.state;

      let selectedProperties_ = this.state.selectedProperties.filter(x => x.propertyGuid !== "blank");

      if (!isAssignVisitor && !isAssignedTenanView &&
        !isAssignTenantDetailView && !isResolveTenant
        && isMapVisible
        && (mapUploadingStatus.progress === EnumInteractiveMapStatus.EditMap
          || mapUploadingStatus.progress !== EnumInteractiveMapStatus.MappingCompleted)) {
        return (<PrivateLotsMapDetails
          selectedProperty={selectedProperties_.length > 0 ? selectedProperties_[0] : null}
          selectedSpot={this.state.mapUploadingStatus.selectedSpotInfo}
          mapSelectedSpotDetails={this.state.mapSelectedSpotDetails}
          isMultiSelectedProperty={this.state.isMultiSelectedProperty}
          isMapLoaded={this.state.isMapLoaded}
          properties={this.state.properties}
          onUpdate={(mapUrl: string, selectedProperty: IPrivateLotProperty | null, selectedFloor: IFloor | null) => {
            if (mapUrl === "") {
              this.RetrievePropertyMap();
            } else {
              this.setState({
                isCommonMapUpload: false,
                figmaNodeId: mapUrl,
                selectedMapFloor: selectedFloor,
                selectedMapProperty: selectedProperty,
                selectedProperties: selectedProperty ? [selectedProperty] : [],
                mapUploadingStatus: {
                  progress: EnumInteractiveMapStatus.CompleteLotSetup,
                  selectedSpotInfo: null,
                  selectedZone: null
                }
              })
            }
          }}
          progress={this.state.mapUploadingStatus.progress}
          progressUpdate={(status: EnumInteractiveMapStatus, zone?: IPrivateLotZone | null, spot?: ZoneActiveSpot, updateLevels?: boolean) => {
            this.setState({
              mappedSpotInfo: spot ? spot : null,
              mapUploadingStatus: {
                progress: status,
                selectedSpotInfo: spot ? spot : null,
                selectedZone: zone ? zone : null
              }
            }, () => {
              if (updateLevels) {
                this.getFloorLevels();
              } else if (status === EnumInteractiveMapStatus.UploadMap) {
                this.RetrievePropertyMap();
              }
            })
          }}
          onResetDone={(options: IMapResetOption) => {
            this.setState({
              mapResetState: options
            })
          }}
          onClearSpot={(spot_: ZoneActiveSpot | null) => {
            this.setState({
              clearedSpot: spot_
            })
          }}
          floorLevel={this.state.floorLevel}
          selectedFloor={this.state.selectedMapFloor}
        />)
      } else if (this.state.isResolveTenant) {
        return (<ResolveTenant
          mapBookingModel={this.state.mapBookingModel}
          spotGuid={this.state.mapSpotGuid}
          getSpotBookingStatus={this.getSpotBookingStatus}
          onRenew={(spotBooking: ISpotBooking, isRenewUpcoming: boolean) => {
            if (spotBooking.yardiRedirectURL !== "") {
              UtilHelper.lanuchPopup(spotBooking.yardiRedirectURL, `Popup`, true, () => {
                this.setState({
                  toastNotifyType: "Tenant details updated successfully.",
                  isUndoNotification: true,
                  SelectedParkingPassess: [],
                  isParkingLeasesUpdated: true,
                  isWidgetRefreshed: true,
                  mapSpotGuid: "", isResolveTenant: false,
                  isAssignTenantDetailView: false,
                  isAssignedTenanView: false,
                  isAssignedVisitorTenantView: false,
                }, () => {
                  this.getTenantPrivateLot();
                })
              });
            } else {
              const items_ = this.assignPrivateLotsDetailsItem(spotBooking);
              this.setState({
                isCustomRenew: true,
                rightPanelState: RightPanelState.Add,
                SelectedParkingPassess: [items_],
                isRenew: true,
                isRenewUpcoming
              });
            }
          }}
          onResolve={(reportParkerGuid: string, spotNo: string) => {
            this._privateLotService.resolveIllegalParking(reportParkerGuid)
              .then(response => {
                if (response.ok) {
                  this.setState({
                    toastNotifyType: `Spot no. ${spotNo} illegal parking has been resolved`,
                    isUndoNotification: true,
                    isResolveTenant: false
                  }, () => {
                    this.getFloorLevels()
                  })
                }
              })
          }}
          removeAccess={(spotBooking: ISpotBooking) => {
            if (spotBooking.yardiRedirectURL !== "") {
              UtilHelper.lanuchPopup(spotBooking.yardiRedirectURL, "Popup", true, () => {
                this.setState({
                  toastNotifyType: "Tenant details updated successfully.",
                  isUndoNotification: true,
                  isParkingLeasesUpdated: true,
                  mapSpotGuid: "", isResolveTenant: false,
                  isAssignTenantDetailView: false,
                  isAssignedTenanView: false,
                  isAssignedVisitorTenantView: false,
                  isWidgetRefreshed: true,
                }, () => {
                  this.getTenantPrivateLot();
                })
              });
            } else {
              const pLots_ = this.assignPrivateLotsDetailsItem(spotBooking);
              this.removeAccessTenantDetails(pLots_);
            }
          }}
        />);
      } else if (this.state.isAssignedTenanView) {

        return (
          <AssignedTenant
            spotNo={this.state.teantSpotNo}
            teantUnitNumber={this.state.teantUnitNumber}
            teantName={this.state.teantName}
            teantDuration={this.state.teantDuration}
            teantEmail={this.state.teantEmail}
            teantPhone={this.state.teantPhone}
            teantLicencePlate={this.state.teantLicencePlate}
            isYardiUser={this.state.isYardiUser}
            handelEdit={() => {
              var spotBooking = this.state.mapBookingModel?.bookings.find(pspot => pspot.spotGuid === this.state.mapSelectedSpotGuid)
              if (spotBooking) {
                var propertDetail: PrivateLotPropertyDetails = {
                  propertyGuid: spotBooking?.propertyGuid ?? "",
                  propertyName: `${spotBooking?.parkerFirstName} ${spotBooking?.parkerLastName}`,
                  spotGuid: [String(spotBooking?.spotGuid)],
                  reservationGuid: [String(spotBooking?.reservationGuid)],
                  licensePlate: String(spotBooking?.licenseNo)
                }

                var _lot: IPrivateLotsItem = {
                  "licensePlate": String(spotBooking?.licenseNo),
                  "privateLotUnitGuid": String(spotBooking?.unitGuid),
                  "propertyDetails": [propertDetail],
                  "unitNumber": String(spotBooking?.unitNumber),
                  "spots": 1,
                  "yardiRedirectURL": ""
                }
                this.setState({
                  rightPanelState: RightPanelState.Edit,
                  selectedReservation: spotBooking?.reservationGuid ?? "",//should come from select
                  selectedTenant: _lot,
                  isAddNewTenant: true
                })
              }

            }}
          ></AssignedTenant>
        )
      } else if (this.state.isAssignedVisitorTenantView) {
        const {spotBooking} = this.getSpotBookingStatus(this.state.mapSelectedSpotGuid);
        return (
          <AssignedVisitorTenant
            spotNo={this.state.teantSpotNo}
            teantUnitNumber={this.state.teantUnitNumber}
            teantName={this.state.teantName}
            teantDuration={this.state.vistorDuration}
            teantEmail={this.state.teantEmail}
            teantPhone={this.state.teantPhone}
            teantLicencePlate={this.state.teantLicencePlate}
            startUTCTime={spotBooking?.startTimeUtc ? spotBooking?.startTimeUtc : null}
            handelEdit={() => {
            }}
            handelEndSession={() => {
              const {spotBooking} = this.getSpotBookingStatus(this.state.mapSelectedSpotGuid);
              if (spotBooking?.reservationGuid) {
                this._privateLotService.getVisitorEndSession(spotBooking?.reservationGuid)
                  .then(res => {
                    if (res.ok) {
                      this.setState({
                        isAssignedVisitorTenantView: false,
                      })
                    } else {
                      res.json().then(error => {
                        ShowAlert('', error, "info")
                      })
                    }
                  });

              }
            }}
          ></AssignedVisitorTenant>
        )

      } else if (this.state.isAssignTenantDetailView) {
        return (
          <AssignTenant
            showBlocker={this.state.showBlocker}
            spotNo={this.state.mapAssignSelectedSpot}
            isVisitorBTVisible={this.state.isAssignVisitorBTVisible}
            isTenantBTVisible={this.state.isAssignTenantBTVisible}
            isSpotActive={this.state.isSpotActive}
            handelAssignTenant={() => {
              this.setState({
                isAddNewTenant: false,
                isBulkUpload: false,
                isAssignTenant: true,
                anchorEl: null,
                rightPanelState: RightPanelState.AssignTenant
              }, () => {
                this._privateLotService.get_units_Assign_tenant_obj(this.state.mapPropertyGuid)
                  .then(r => r.json())
                  .then(r => {
                    if (Array.isArray(r)) {
                      this.setState({
                        unitNumbers: r.map((obj) => obj.unitNumber),
                        filterUnitNumber: r.map((obj) => obj.unitNumber),
                        unitObj: r,
                        unitNumberSearch: ""
                      })
                    }
                  })
              })
            }
            }
            handelAssignVisitor={() => {
              this.setState({
                isAddNewTenant: false,
                isBulkUpload: false,
                isAssignTenant: false,
                isAssignVisitor: true,
                anchorEl: null,
                unitGuid: "",
                rightPanelState: RightPanelState.AssignVisitor
              })
            }
            }

          >

          </AssignTenant>
        )

      } else {
        return (
          <PrivateLotsTenantDetails
            selectedProperty={selectedProperties_?.length > 0 ? selectedProperties_[0] : null}
            viewType={viewType}
            propertyGuid={selectedProperties_?.length === 1 ? selectedProperties_[0].propertyGuid : "00000000-0000-0000-0000-000000000000"}
            onTriggerYardi={() => {
              this.setState({
                isYardiSyncView: true,
                isAssignTenant: false,
                rightPanelState: RightPanelState.None,
              }, () => {
                this.setState({
                  rightPanelState: RightPanelState.TriggerYardiSync,
                })
              })
            }
            }
            onAddSpotViewOpen={() => {
              this.setState({
                rightPanelState: RightPanelState.Add,
                isBlockSpotOpened: true
              })
            }}
            onRefreshed={(s_: boolean) => {
              this.setState({
                isWidgetRefreshed: s_
              })
            }}

            onScanRefreshed={(s_: boolean) => {
              this.setState({
                isScanRefreshed: s_
              })
            }}
            isScanRefreshed={this.state.isScanRefreshed}
            refresh={this.state.isWidgetRefreshed}
            isMultiSelectedProperty={this.state.isMultiSelectedProperty}
            onRenew={(renewResponseModel: IRenewResponseModel[]) => {
              const items_ = renewResponseModel.map((item) => {
                const privateLotsDetailsItem: IPrivateLotsDetailsItem = {
                  tenant: "",
                  email: "",
                  phoneNumber: "",
                  propertyName: "",
                  leaseStartDateLocal: item.leaseStartDateLocal,
                  leaseEndDateLocal: item.leaseEndDateLocal,
                  rate: 0,
                  zoneName: "",
                  spotName: "",
                  propertyGuid: item.propertyGuid,
                  spotGuid: item.spotGuid,
                  zoneGuid: item.zoneGuid,
                  reservationGuid: item.reservationGuid,
                  userGuid: "",
                  privateLotUnitGuid: item.privateLotUnitGuid,
                  unitNumber: item.unitNumber,
                  yardiRedirectURL: ""
                }
                return privateLotsDetailsItem;
              });
              PreserveFilters.setSearchTerm('renewOrder', {
                sortColumn: "leaseEndDateLocal",
                reservationGuids: items_.map((item) => item.reservationGuid)
              }); //privateLotsDetailsItem: items_,
              this.props.history && this.props.history.push("/parking-passes");
            }}
            isParkingLeasesUpdated={this.state.isParkingLeasesUpdated}
            updateParkingLeasesFlag={(isParkingLeasesUpdated: boolean) => {
              this.setState({
                isParkingLeasesUpdated
              })
            }}
          />
        );
      }
    }
  }

  multiSelectedPropertyMsg = () => {
    return (

      <div className='d-flex flex-column'>
        <div className='py-3'>
          <b>Not available on multi-select.</b>
        </div>
        <div className=''>
          To view statistics and lot overview,
        </div>
        <div className=''>
          please select only <b>one</b> property.
        </div>
      </div>

    )
  }

  commonToastNotificationMsg = () => {
    if (this.state.toastNotifyType == "removeAccess"
      || this.state.toastNotifyType == "bulkRemoveAccess"
      || this.state.toastNotifyType == "editTenant"
      || this.state.toastNotifyType == "removeParkingPass"
      || this.state.toastNotifyType == "1 Year"
      || this.state.toastNotifyType == "1 Month"
      || this.state.toastNotifyType == "custom"
    ) {
      return (
        <div className='d-flex align-items-center'>
          <div className='d-flex flex-column'>
            <div className=''>
              {this.commonNotificationContent(this.state.toastNotifyType)}
              <a className='cursor-pointer' style={{textDecoration: "underline"}} onClick={(e) => {
                e.preventDefault();
                if (this.state.toastNotifyType == "removeAccess" || this.state.toastNotifyType == "removeParkingPass"
                  || this.state.toastNotifyType == "bulkRemoveAccess") {
                  this._privateLotService.undoRemoveAccess(this.state.removeAccessUndoList)
                    .then(r => {
                      if (r.ok) {
                        if (this.state.toastNotifyType == "removeParkingPass") {
                          const {selectedPrivateLot, isTenantViewDetails, removedLicensePlate} = this.state;
                          if (isTenantViewDetails && selectedPrivateLot &&
                            selectedPrivateLot.propertyDetails &&
                            selectedPrivateLot.propertyDetails.length > 0 && removedLicensePlate) {
                            selectedPrivateLot.propertyDetails[0].licensePlate += `,${removedLicensePlate}`;
                            this.setState({
                              removedLicensePlate: "",
                              selectedPrivateLot
                            });
                          }
                          this.getTenantLeaseInDetail();
                        } else {
                          this.getTenantPrivateLot();
                        }

                        this.setState({
                          isWidgetRefreshed: true,
                        }, () => {
                          this.closeCommonNotificationMsg(undefined, undefined, true);
                          if (this.state.isMapVisible && this.state.mapUploadingStatus.progress === EnumInteractiveMapStatus.MappingCompleted) {
                            let selectedProperties_: IPrivateLotProperty[] = this.state.selectedProperties.filter(x => x.propertyGuid != "blank");
                            selectedProperties_.length === 1 && this.getSpotStatus(selectedProperties_[0].propertyGuid, true);
                          }
                        });
                      }
                    });
                } else if (this.state.toastNotifyType == "editTenant"
                  || this.state.toastNotifyType == "1 Year"
                  || this.state.toastNotifyType == "1 Month"
                  || this.state.toastNotifyType == "custom") {
                  this._privateLotService.undoUpdateTenant(this.state.editSavedResponse)
                    .then(r => {
                      if (r.ok) {
                        if (this.state.isTenantViewDetails
                          || this.state.toastNotifyType == "1 Year"
                          || this.state.toastNotifyType == "1 Month"
                          || this.state.toastNotifyType == "custom") {
                          this.getTenantLeaseInDetail();
                        } else {
                          this.getTenantPrivateLot();
                        }
                        this.setState({
                          isWidgetRefreshed: true,
                        }, () => {
                          this.closeCommonNotificationMsg(undefined, undefined, true);
                          if (this.state.isMapVisible && this.state.mapUploadingStatus.progress === EnumInteractiveMapStatus.MappingCompleted) {
                            let selectedProperties_: IPrivateLotProperty[] = this.state.selectedProperties.filter(x => x.propertyGuid != "blank");
                            selectedProperties_.length === 1 && this.getSpotStatus(selectedProperties_[0].propertyGuid, true);
                          }
                        });
                      }
                    });
                }
              }}>&nbsp;Undo</a>
            </div>
          </div>
          <div className='d-flex n-toast-close pl-4'>
            <IconButton
              size="small"
              aria-label="close"
              onClick={this.closeCommonNotificationMsg}
            >
              <CloseIcon fontSize="small" htmlColor='#fff'/>
            </IconButton>
          </div>
        </div>

      );
    } else {
      return (
        <div className='d-flex align-items-center'>
          <div className='d-flex flex-column'>
            <div className=''>
              {this.state.toastNotifyType}
            </div>
          </div>
          <div className='d-flex pl-4'>
            <IconButton
              size="small"
              aria-label="close"
              onClick={this.closeCommonNotificationMsg}
            >
              <CloseIcon fontSize="small" htmlColor='#fff'/>
            </IconButton>
          </div>
        </div>
      );
    }
  }

  closeCommonNotificationMsg = (event?: React.SyntheticEvent | Event, reason?: string, isParkingLeasesUpdated: boolean = false) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      isUndoNotification: false,
      removeAccessUndoList: [],
      toastNotifyType: "",
      editSavedResponse: [],
      SelectedParkingPassess: [],
      isParkingLeasesUpdated
    })
  }

  commonNotificationContent = (type_: string) => {
    switch (type_) {
      case "removeAccess":
        return "Tenant successfully removed.";
      case "removeParkingPass":
        return "Parking pass removed.";
      case "editTenant":
        return "Tenant successfully updated.";
      case "1 Month":
        return `${this.state.editSavedResponse.length > 1 ? this.state.editSavedResponse.length : ""} Parking pass renewed for 1 month.`;
      case "1 Year":
        return `${this.state.editSavedResponse.length > 1 ? this.state.editSavedResponse.length : ""} Parking pass renewed for 1 year.`;
      case "custom":
        return `${this.state.editSavedResponse.length > 1 ? this.state.editSavedResponse.length : ""} Parking pass renewed for custom date.`;
      case "bulkRemoveAccess":
        return `${this.state.selectedTenantList.length == 0 ? "" : this.state.selectedTenantList.length} Tenant successfully removed.`;
      case "bulkYardiRemoveAccess":
        return `Tenant successfully removed.`
      default:
        return type_;
    }
  }

  getSpotBookingStatus = (spotGuid: string) => {
    const {mapBookingModel} = this.state;
    const {visitorParkingBookings, illegalBookings, bookings, existingTenant} = mapBookingModel ??
    {visitorParkingBookings: [], illegalBookings: [], bookings: [], existingTenant: []};
    const visitorPrivateLotBookings = bookings.concat(visitorParkingBookings);
    let illegalParking = illegalBookings.find(spot => spot.spotGuid === spotGuid);
    let spotBooking = visitorPrivateLotBookings.find(spot => spot.spotGuid === spotGuid);
    let isIllegalParking = illegalParking !== null && illegalParking !== undefined;
    let existingUser = isIllegalParking ? existingTenant.find(user => user.spotGuid === spotGuid) ?? null : null;
    let noOfDays = spotBooking ? (DateTimeHelper.GetDays(new Date(new Date().toUTCString()), new Date(spotBooking.endTimeLocal)) + 1).toString() : "";
    let isOverDue = false;
    let isUpComingSpot = false;
    let isBookedSpot = false;
    let isAvailable = true;
    let isVisitorParking = false;
    let isUnAvailable = false;
    let isYardiUser = spotBooking?.yardiRedirectURL !== "";

    if (!isNaN(parseInt(noOfDays)) && spotBooking?.bookedType === 1) {
      if ((!isYardiUser && !spotBooking?.isLeaseOnNotice && parseInt(noOfDays) >= 1
          && parseInt(noOfDays) <= 30) ||
        (isYardiUser && spotBooking?.isLeaseOnNotice && parseInt(noOfDays) >= 0)) {
        isUpComingSpot = true;
      } else if (parseInt(noOfDays) < 1) {
        noOfDays = noOfDays.replace("-", "");
        isOverDue = true;
      }//(isYardiUser && spotBooking?.isLeaseOnNotice && parseInt(noOfDays) >= 0) ||
      else {
        if (isIllegalParking) {
          isBookedSpot = false
        } else {
          isBookedSpot = true;
        }
      }
    }
    if (spotBooking && spotBooking.bookedType === 2 && spotBooking.blockedDetails && !isIllegalParking) {
      isUnAvailable = true;
    }
    if (spotBooking && spotBooking.bookedType === 3 && !isIllegalParking) {
      isVisitorParking = true;
    }
    if (isOverDue || isUpComingSpot || isBookedSpot || isVisitorParking || isUnAvailable || isIllegalParking) {
      isAvailable = false;
    }

    return {
      "isAvailable": isAvailable,
      "isBookedSpot": isBookedSpot,
      "isOverDue": isOverDue,
      "isUpComingSpot": isUpComingSpot,
      "existingUser": existingUser,
      "illegalParking": illegalParking,
      "spotBooking": spotBooking,
      "noOfDays": !isNaN(parseInt(noOfDays)) ? `${parseInt(noOfDays) + 1}` : noOfDays,
      "isIllegalParking": isIllegalParking,
      "isVisitorParking": isVisitorParking,
      "isUnAvailable": isUnAvailable,
      "isYardiUser": isYardiUser
    }
  }

  protected _getRightPanelTitle() {
    switch (this.state.rightPanelState) {
      case RightPanelState.Filter:
        return <span>Filter</span>;
      case RightPanelState.Edit:
        return <span>Edit Tenant</span>;
      case RightPanelState.Add:
        return <span>Add Tenant</span>;
      case RightPanelState.AssignTenant:
        return <div style={{'marginTop': '0px'}}>
          <span>Assign Tenant</span>
        </div>;
      case RightPanelState.AssignVisitor:
        return <span>{this.state.assignVisitorTitle}</span>
      case RightPanelState.TriggerYardiSync:
        return <span>Sync Property</span>
    }
  }

  private priorityBooking(mapBookingModel: IMapBookingModel) {
    let spotBookings: any = ObjectHelper.deepClone(mapBookingModel.bookings);
    spotBookings = spotBookings.concat(mapBookingModel.visitorParkingBookings);
    if (mapBookingModel.illegalBookings) {
      const illegalSpotGuid = mapBookingModel.illegalBookings.map(spot => spot.spotGuid);
      spotBookings = spotBookings.filter((spot: any) => illegalSpotGuid.indexOf(spot.spotGuid) === -1);
    }
    spotBookings = spotBookings.concat(mapBookingModel.illegalBookings);
    return spotBookings.sort((a: any, b: any) => b.isLeaseOnNotice - a.isLeaseOnNotice);
  }

  private checkBookingStatus(mappedJson: IFigmaNodes | null, spotBookings: any) {
    let mappedJson_ = mappedJson;
    if (mappedJson_) {
      let spots: IFigmaNodesMappedWithSpot = mappedJson_.Spots;
      let spotsList = Object.keys(spots);
      if (Object.keys(spots).length > 0) {
        spotsList.forEach((spotGuid: any) => {
          const findSpot = spotBookings?.find((bookings: any) => bookings && bookings.spotGuid === spotGuid);
          const boxElement: IFigmaChildNodes = spots[spotGuid].BoxElement[0];
          if (findSpot) {
            let days = this.statusUpdate(findSpot.endTimeLocal) + 1;
            let isYardiUser = findSpot.yardiRedirectURL !== "";

            if (((!isYardiUser && days >= 1 && days <= 30) ||
              (isYardiUser && findSpot.isLeaseOnNotice && days >= 0)) && findSpot.bookedType === 1) {
              boxElement.BgColor = 'yellow';
              boxElement.SpotColor = "yellowColor";
            } else if ((isYardiUser && findSpot.isLeaseOnNotice && days < 1) //On Notice and days less than current date - overdue
              || findSpot.hasOwnProperty("reportReason") ||
              (findSpot.bookedType === 1 && ((!isYardiUser && days <= 0) ||
                (!findSpot.isLeaseOnNotice && days < 0)))) {
              boxElement.BgColor = '#DC2626';
              boxElement.SpotColor = "redColor";
            } else if (findSpot.bookedType !== 2 && ((isYardiUser && findSpot.isLeaseOnNotice && days >= 1) || //On Notice and days grater than current date - upcoming
              (!findSpot.isLeaseOnNotice && (((days > 30 && findSpot.bookedType === 1) ||
                  (isYardiUser && days >= 1)) //Not On Notice and days grater than current date - Taken
                || findSpot.bookedType === 3)))) {
              boxElement.BgColor = '';
              boxElement.SpotColor = "";
            } else if (!findSpot.isLeaseOnNotice && findSpot.bookedType === 2) {
              boxElement.BgColor = 'grey';
              boxElement.SpotColor = "greyColor";
            } else {
              boxElement.BgColor = '#084D3D';
              boxElement.SpotColor = 'greenColor';
            }
          } else {
            boxElement.BgColor = '#084D3D';
            boxElement.SpotColor = 'greenColor';
          }
        });
      }
      mappedJson_.Spots = spots;
    }
    return mappedJson_;
  }

  private statusUpdate(date: string) {
    let bookedDate = new Date(date)
    return this.getDays(bookedDate, this.currentDate())
  }

  private currentDate() {
    return new Date(new Date().toUTCString());
  }

  private getDays(currentDate: any, bookedDate: any) {
    var Difference_In_Time: any = currentDate.getTime() - bookedDate.getTime();
    // To calculate the no. of days between two dates
    return Math.round(Difference_In_Time / (1000 * 60 * 60 * 24));//Math.round(Difference_In_Time / (1000 * 3600 * 24));
  }

  private removeAccess = async (pLots_: IPrivateLotsItem) => {
    let _apiData: IRemoveAccess[] = [];
    pLots_.propertyDetails.forEach(() => {
      _apiData.push({
        "privateLotUnitGuid": pLots_?.privateLotUnitGuid,
        "isDelete": false
      })
    });
    const _removeAccessData = await this.bulkRemoveAccessApi(_apiData);
    const isActiveParking = _removeAccessData && _removeAccessData.length > 0;
    if (isActiveParking) {
      let _endDate = DateTimeHelper.dateStringToDate(_removeAccessData[0].endDateTime);
      ShowAlertwithConfirm(
        "Active Parking",
        `<div class="mt-3">This tenant has active parking lease(s) which will<br/>
                expire <b>${format(_endDate, "dd MMM yyyy")}</b>. Do you want to delete<br/>
                tenant and cancel parking lease?<br/>
                This cannot be undone.
                </div>`,
        undefined,
        "Yes",
        "No",
        "gryd-custom-popup"
      )
        .then(r_ => {
          if (r_) {
            _apiData = [];
            pLots_.propertyDetails.forEach(() => {
              _apiData.push({
                "privateLotUnitGuid": pLots_.privateLotUnitGuid,
                "isDelete": true
              })
            });
            this.bulkRemoveAccessApi(_apiData).then((undoValue_) => {
                ShowAlert(
                  "",
                  `
                                <div class="mt-3"> 
                                    <b>Tenant Deleted</b>
                                </div>
                                <div class="mt-3"> 
                                    Tenant has been successfully deleted and all
                                    <br/>
                                    active parking has been cancelled.
                                </div>`,
                  "success"
                ).then(() => {
                  this.setState({
                    toastNotifyType: "removeAccess",
                    removeAccessUndoList: undoValue_,
                    isUndoNotification: true,
                    isParkingLeasesUpdated: true,
                    isWidgetRefreshed: true,
                    clearPrivatelotSelectedItem: true
                  }, () => {
                    this.getTenantPrivateLot();
                  })
                });
              }
            );
          } else {
            ShowAlert(
              "",
              `<div class="mt-3"> 
                                <b>Tenant Cannot Be Deleted</b>
                            </div>
                            <div class="mt-3"> 
                                Tenant has active parking lease and cannot be
                                <br/>
                                deleted until active parking has been cancelled
                                <br/>
                                or expired.
                            </div>`,
              "warning"
            );
          }
        })
    } else {
      ShowAlertwithConfirm(
        "Delete This Unit?",
        `<div class="mt-3">Do you want to delete this unit?<br/>
                This cannot be undone.<br/>
                </div>
                <div class="mt-3">
                <b>${pLots_.unitNumber}</b><br/>
                <b>${pLots_.propertyDetails[0].propertyName}</b><br/>
                </div>`,
        undefined,
        "Yes, Delete",
        "Cancel",
        "gryd-custom-popup"
      )
        .then(r_ => {
          if (r_) {
            _apiData = [];
            pLots_.propertyDetails.forEach(() => {
              _apiData.push({
                "privateLotUnitGuid": pLots_.privateLotUnitGuid,
                "isDelete": true
              })
            });
            this.bulkRemoveAccessApi(_apiData).then((d_) => {
              this.setState({
                toastNotifyType: "removeAccess",
                removeAccessUndoList: d_,
                isUndoNotification: true,
                isParkingLeasesUpdated: true,
                isWidgetRefreshed: true,
                clearPrivatelotSelectedItem: true
              }, () => {
                this.getTenantPrivateLot();
              })
            });
          }
        })
    }
  }

  private bulkRemoveAccess = async (pLots_: IPrivateLotsItem[], hasYardiItem?: boolean) => {
    let originalItems = ObjectHelper.deepClone(pLots_);

    pLots_ = hasYardiItem ? pLots_.filter(x => x.yardiRedirectURL === "") : pLots_;

    const yardiRedirectURL: string[] = hasYardiItem ? originalItems
        .filter((item: IPrivateLotsItem) => item.yardiRedirectURL !== "")
        .map(x => x.yardiRedirectURL)
        .filter((item: string, index: number, self: string[]) => self.indexOf(item) === index)
      : [];

    const _removeAccessData = (pLots_: IPrivateLotsItem[], _isDelete: boolean) => {
      let _apiData: IRemoveAccess[] = [];
      pLots_.forEach(p_ => {
        p_.propertyDetails.forEach(() => {
          _apiData.push({
            "privateLotUnitGuid": p_.privateLotUnitGuid,
            "isDelete": _isDelete
          })
        })
      });
      return _apiData;
    }
    const _bulkRemoveAccessData = _removeAccessData(pLots_, false);
    const _getBulkRemoveResponseData = _bulkRemoveAccessData.length > 0 ? await this.bulkRemoveAccessApi(_bulkRemoveAccessData) : [];

    if (hasYardiItem) {
      this.removeTeantAccessWithYardi(_removeAccessData, pLots_,
        _getBulkRemoveResponseData, yardiRedirectURL, originalItems);
    } else {
      this.removeTeantAccess(_removeAccessData, pLots_, _getBulkRemoveResponseData);
    }
  }

  private removeTeantAccessWithYardi(_removeAccessData:
                                       (pLots_: IPrivateLotsItem[], _isDelete: boolean) => IRemoveAccess[],
                                     pLots_: IPrivateLotsItem[], _getBulkRemoveResponseData: any,
                                     yardiRedirectURL: string[], originalItems: IPrivateLotsItem[]) {
    if (_getBulkRemoveResponseData.length > 0) {
      ShowAlertwithConfirm(
        "Active Parking",
        `<div class="mt-3">Some tenants you are trying to remove still have<br/>
                    active parking. Please review and confirm you<br/>
                    want to continue:<br/>
                    <div class="d-flex justify-content-between px-3 mt-3">
                    <div><b>Unit</b></div>
                    <div class="pr-3"><b>End Date</b></div>
                    </div>
                    <div class="mt-3">
                    ${_getBulkRemoveResponseData.map((p_: any) => {
          return (
            `<div class="d-flex justify-content-between px-3 multi-remove-access-data">
                                <div>${p_.unitNumber}</div>
                                <div>${format(DateTimeHelper.dateStringToDate(p_.endDateTime), "dd MMM yyyy")}</div>
                            </div>`
          )
        }).join('')}
                    </div>
                </div>`,
        undefined,
        "Yes, Delete",
        "Cancel",
        "gryd-custom-popup"
      )
        .then(r_ => {
          if (r_) {
            this.removeAccessWithYardiApi(_removeAccessData, pLots_, yardiRedirectURL, originalItems);
          } else {
            this.yardiCompletedCallBack(yardiRedirectURL, pLots_, undefined, false, originalItems);
          }
        })
    } else {
      this.removeAccessWithYardiApi(_removeAccessData, pLots_, yardiRedirectURL, originalItems);
    }
  }

  private removeAccessWithYardiApi(_removeAccessData:
                                     (pLots_: IPrivateLotsItem[], _isDelete: boolean) => IRemoveAccess[],
                                   pLots_: IPrivateLotsItem[], yardiRedirectURL: string[],
                                   originalItems: IPrivateLotsItem[]) {
    if (pLots_.length > 0) {
      const _acceptBulkRemoveAccessData = _removeAccessData(pLots_, true);
      this.bulkRemoveAccessApi(_acceptBulkRemoveAccessData).then(response_ => {
        this.yardiCompletedCallBack(yardiRedirectURL, pLots_, response_, true, originalItems);
      });
    } else {
      this.yardiCompletedCallBack(yardiRedirectURL, pLots_, undefined, false, originalItems);
    }
  }

  private yardiCompletedCallBack(yardiRedirectURL: string[], pLots_: IPrivateLotsItem[],
                                 response_: any, removeOtherUser: boolean, originalItems: IPrivateLotsItem[]) {
    const selectProperty = this.state.selectedProperties.filter(x => x.propertyGuid !== "blank");
    ShowAlertwithConfirm(
      "Yardi Unit",
      `<div class="mt-3">Some of the tenants are belong to third party site.<br/>
                Do you want to continue?
            </div>`,
      undefined,
      "Yes",
      "Cancel",
      "gryd-custom-popup"
    ).then(res_ => {
      if (res_) {
        let popup = 1;
        if (removeOtherUser) {
          yardiRedirectURL.forEach(x => {
            UtilHelper.lanuchPopup(x,
              `Popup_${popup++}`,
              true,
              () => {
                this.removeAccessWithYardiApiSuccess(pLots_, response_)
              });
          });
        } else {
          yardiRedirectURL.forEach(x => {
            UtilHelper.lanuchPopup(x,
              `Popup_${popup++}`,
              true,
              () => {
                this.refreshSyncYardi(selectProperty[0].propertyGuid, () => {
                  this.setState({
                    toastNotifyType: "Yardi unit(s) updated successfully.",
                    isUndoNotification: true,
                    selectedTenantList: originalItems,
                    isParkingLeasesUpdated: true,
                    isWidgetRefreshed: true,
                    clearPrivatelotSelectedItem: true
                  }, () => {
                    this.getTenantPrivateLot();
                  });
                });
              });
          });
        }
      } else {
        if (response_)
          this.removeAccessWithYardiApiSuccess(pLots_, response_);
      }
    });
  }

  private removeTeantAccess(_removeAccessData: (pLots_: IPrivateLotsItem[], _isDelete: boolean) => IRemoveAccess[],
                            pLots_: IPrivateLotsItem[], _getBulkRemoveResponseData: any) {
    if (_getBulkRemoveResponseData.length > 0) {
      ShowAlertwithConfirm(
        "Active Parking",
        `<div class="mt-3">Some tenants you are trying to remove still have<br/>
                    active parking. Please review and confirm you<br/>
                    want to continue:<br/>
                    <div class="d-flex justify-content-between px-3 mt-3">
                    <div><b>Unit</b></div>
                    <div class="pr-3"><b>End Date</b></div>
                    </div>
                    <div class="mt-3">
                    ${_getBulkRemoveResponseData.map((p_: any) => {
          return (
            `<div class="d-flex justify-content-between px-3 multi-remove-access-data">
                                <div>${p_.unitNumber}</div>
                                <div>${format(DateTimeHelper.dateStringToDate(p_.endDateTime), "dd MMM yyyy")}</div>
                            </div>`
          )
        }).join('')}
                    </div>
                </div>`,
        undefined,
        "Yes, Delete",
        "Cancel",
        "gryd-custom-popup"
      )
        .then(r_ => {
          if (r_) {
            this.removeBulkRemoveAccessApi(_removeAccessData, pLots_);
          }
        })
    } else {
      this.removeBulkRemoveAccessApi(_removeAccessData, pLots_);
    }
  }

  private removeBulkRemoveAccessApi(_removeAccessData: (pLots_: IPrivateLotsItem[], _isDelete: boolean) => IRemoveAccess[],
                                    pLots_: IPrivateLotsItem[]) {
    const _acceptBulkRemoveAccessData = _removeAccessData(pLots_, true);
    this.bulkRemoveAccessApi(_acceptBulkRemoveAccessData).then(res_ => {
      ShowAlert(
        "",
        `<div class="mt-3"> 
                        <b>Tenant Deleted</b>
                    </div>
                    <div class="mt-3"> 
                        All selected tenants have been successfully deleted
                        <br/>
                        and all active parking has been cancelled.
                    </div>`,
        "success"
      ).then(() => {
        this.setState({
          toastNotifyType: "bulkRemoveAccess",
          removeAccessUndoList: res_,
          isUndoNotification: true,
          selectedTenantList: pLots_,
          isParkingLeasesUpdated: true,
          isWidgetRefreshed: true,
          clearPrivatelotSelectedItem: true
        }, () => {
          this.getTenantPrivateLot();
        })
      });
    });
  }

  private removeAccessWithYardiApiSuccess(pLots_: IPrivateLotsItem[], res_: any) {
    const selectProperty = this.state.selectedProperties.filter(x => x.propertyGuid !== "blank");
    ShowAlert(
      "",
      `<div class="mt-3"> 
                <b>Tenant Deleted</b>
            </div>
            <div class="mt-3"> 
                All selected tenants have been successfully deleted
                <br/>
                and all active parking has been cancelled.
            </div>`,
      "success"
    ).then(() => {
      this.refreshSyncYardi(selectProperty[0].propertyGuid, () => {
        this.setState({
          toastNotifyType: "bulkRemoveAccess",
          removeAccessUndoList: res_,
          isUndoNotification: true,
          selectedTenantList: pLots_,
          isParkingLeasesUpdated: true,
          isWidgetRefreshed: true,
          clearPrivatelotSelectedItem: true
        }, () => {
          this.getTenantPrivateLot();
        })
      });
    });
  }

  private removeAccessTenantDetails = async (pLots_: IPrivateLotsDetailsItem) => {
    let _endDate = DateTimeHelper.dateStringToDate(pLots_.leaseEndDateLocal);
    ShowAlertwithConfirm(
      "Active Lease",
      `<div class="mt-3">You are attempting to delete an active parking<br/>
            lease(s) which will expire <b>${format(_endDate, "dd MMM yyyy")}</b>. Do you<br/>
            want to delete and cancel parking lease?<br/>
                This cannot be undone.
                </div>`,
      undefined,
      "Yes",
      "No",
      "gryd-custom-popup"
    )
      .then(r_ => {
        if (r_) {
          this.bulkRemoveAccessApi([{
            "privateLotUnitGuid": pLots_.privateLotUnitGuid,
            "reservationGuid": pLots_.reservationGuid,
            "isDelete": true
          }]).then(d_ => {
            ShowAlert(
              "",
              `<div class="mt-3"> 
                                    <b>Lease Deleted</b>
                                </div>
                                <div class="mt-3"> 
                                Pass has been successfully deleted from tenant's 
                                    <br/>
                                    active passes.
                                </div>`,
              "success"
            ).then(() => {
              const {selectedPrivateLot} = this.state;
              if (this.state.isTenantViewDetails && selectedPrivateLot && selectedPrivateLot.propertyDetails && selectedPrivateLot.propertyDetails.length > 0) {
                const licensePlate = selectedPrivateLot.propertyDetails[0].licensePlate;
                const licensePlates = licensePlate.split(",").filter(x => !pLots_.licensePlate.split(",").includes(x)).join();
                selectedPrivateLot.propertyDetails[0].licensePlate = licensePlates;
              }
              this.setState({
                toastNotifyType: "removeParkingPass",
                removeAccessUndoList: d_,
                isUndoNotification: true,
                selectedPrivateLot,
                removedLicensePlate: pLots_.licensePlate,
                isParkingLeasesUpdated: true,
                isWidgetRefreshed: true,
                isResolveTenant: false,
                mapSpotGuid: "",
                isAssignTenantDetailView: false,
                isAssignedTenanView: false,
                isAssignedVisitorTenantView: false
              }, () => {
                if (this.state.isTenantViewDetails)
                  this.getTenantLeaseInDetail();
                else
                  this.getTenantPrivateLot();
              });
            });
          });
        }
      })


  }

  private bulkRemoveAccessTenantDetails = async (pLots_: IPrivateLotsDetailsItem[]) => {
    let originalItems = ObjectHelper.deepClone(pLots_);

    const yardiRedirectURL: string[] = originalItems
      .filter((item: IPrivateLotsDetailsItem) => item.yardiRedirectURL !== "")
      .map(x => x.yardiRedirectURL)
      .filter((item: string, index: number, self: string[]) => self.indexOf(item) === index);

    const hasYardiItem = yardiRedirectURL.length > 0;

    pLots_ = hasYardiItem ? pLots_.filter(x => x.yardiRedirectURL === "") : pLots_;

    if (hasYardiItem) {
      this.bulkRemoveAccessTenantDetailYardiCallBack(pLots_, yardiRedirectURL);
    } else {
      this.bulkRemoveAccessTenantDetailCallBack(pLots_);
    }
  }

  private bulkRemoveAccessTenantDetailYardiCallBack(pLots_: IPrivateLotsDetailsItem[], yardiRedirectURL: string[]) {
    if (pLots_.length > 0) {
      ShowAlertwithConfirm(
        "Active Lease(s)",
        `<div class="mt-3">You are attempting to delete an active parking<br/>
                lease(s). Do you want to delete and cancel parking lease?<br/>
                    </div>`,
        undefined,
        "Yes",
        "No",
        "gryd-custom-popup"
      )
        .then(r_ => {
          if (r_) {
            let apiData_: IRemoveAccess[] = [];
            pLots_.forEach(p_ => {
              apiData_.push({
                "privateLotUnitGuid": p_.privateLotUnitGuid,
                "reservationGuid": p_.reservationGuid,
                "isDelete": true
              });
            })
            this.bulkRemoveAccessApi(apiData_).then(d_ => {
              this.yardiCompletedTenantDetailCallBack(yardiRedirectURL, pLots_, d_, true);
            });
          } else {
            this.yardiCompletedTenantDetailCallBack(yardiRedirectURL, pLots_, undefined, false);
          }
        })
    } else {
      this.yardiCompletedTenantDetailCallBack(yardiRedirectURL, pLots_, undefined, false);
    }
  }

  private bulkRemoveAccessTenantDetailYardiSuccess(pLots_: IPrivateLotsDetailsItem[], response_: any) {
    ShowAlert(
      "",
      `<div class="mt-3"> 
                    <b>Lease(s) Deleted</b>
                </div>
                <div class="mt-3"> 
                Pass has been successfully deleted from tenant's 
                    <br/>
                    active passes.
                </div>`,
      "success"
    ).then(() => {
      const {selectedPrivateLot} = this.state;
      let removedLicensePlate = "";
      if (this.state.isTenantViewDetails && selectedPrivateLot &&
        selectedPrivateLot.propertyDetails && selectedPrivateLot.propertyDetails.length > 0) {
        const licensePlate = selectedPrivateLot.propertyDetails[0].licensePlate;
        removedLicensePlate = pLots_.map(x => x.licensePlate).join();
        let licensePlates = licensePlate.split(",").filter((el) => !removedLicensePlate.split(",").includes(el)).join();
        selectedPrivateLot.propertyDetails[0].licensePlate = licensePlates;
      }
      this.setState({
        toastNotifyType: "removeParkingPass",
        removeAccessUndoList: response_,
        isUndoNotification: true,
        selectedPrivateLot,
        removedLicensePlate,
        isParkingLeasesUpdated: true,
        isWidgetRefreshed: true,
      }, () => {
        this.getTenantLeaseInDetail();
      })

    });
  }

  private yardiCompletedTenantDetailCallBack(yardiRedirectURL: string[], pLots_: IPrivateLotsDetailsItem[],
                                             response_: any, removeOtherUser: boolean) {
    const selectProperty = this.state.selectedProperties.filter(x => x.propertyGuid !== "blank");
    ShowAlertwithConfirm(
      "Yardi Unit",
      `<div class="mt-3">Some of the tenants are belong to third party site.<br/>
                Do you want to continue?
            </div>`,
      undefined,
      "Yes",
      "Cancel",
      "gryd-custom-popup"
    ).then(res_ => {
      if (res_) {
        let popup = 1;
        if (removeOtherUser) {
          yardiRedirectURL.forEach(x => {
            UtilHelper.lanuchPopup(x,
              `Popup_${popup++}`,
              true,
              () => {
                this.refreshSyncYardi(selectProperty[0].propertyGuid, () => {
                  this.bulkRemoveAccessTenantDetailYardiSuccess(pLots_, response_);
                });
              });
          });
        } else {
          yardiRedirectURL.forEach(x => {
            UtilHelper.lanuchPopup(x,
              `Popup_${popup++}`,
              true,
              () => {
                this.refreshSyncYardi(selectProperty[0].propertyGuid, () => {
                  this.setState({
                    toastNotifyType: "Tenant details updated successfully.",
                    isUndoNotification: true,
                    isParkingLeasesUpdated: true,
                    isWidgetRefreshed: true,
                  }, () => {
                    this.getTenantLeaseInDetail();
                  });
                });
              });
          });
        }
      }
    });
  }

  private bulkRemoveAccessTenantDetailCallBack(pLots_: IPrivateLotsDetailsItem[]) {
    ShowAlertwithConfirm(
      "Active Lease(s)",
      `<div class="mt-3">You are attempting to delete an active parking<br/>
            lease(s). Do you want to delete and cancel parking lease?<br/>
                </div>`,
      undefined,
      "Yes",
      "No",
      "gryd-custom-popup"
    )
      .then(r_ => {
        if (r_) {
          let apiData_: IRemoveAccess[] = [];
          pLots_.forEach(p_ => {
            apiData_.push({
              "privateLotUnitGuid": p_.privateLotUnitGuid,
              "reservationGuid": p_.reservationGuid,
              "isDelete": true
            });
          })
          this.bulkRemoveAccessApi(apiData_).then(d_ => {
            ShowAlert(
              "",
              `<div class="mt-3"> 
                                    <b>Lease(s) Deleted</b>
                                </div>
                                <div class="mt-3"> 
                                Pass has been successfully deleted from tenant's 
                                    <br/>
                                    active passes.
                                </div>`,
              "success"
            ).then(() => {
              const {selectedPrivateLot} = this.state;
              let removedLicensePlate = "";
              if (this.state.isTenantViewDetails && selectedPrivateLot &&
                selectedPrivateLot.propertyDetails && selectedPrivateLot.propertyDetails.length > 0) {
                const licensePlate = selectedPrivateLot.propertyDetails[0].licensePlate;
                removedLicensePlate = pLots_.map(x => x.licensePlate).join();
                let licensePlates = licensePlate.split(",").filter((el) => !removedLicensePlate.split(",").includes(el)).join();
                selectedPrivateLot.propertyDetails[0].licensePlate = licensePlates;
              }
              this.setState({
                toastNotifyType: "removeParkingPass",
                removeAccessUndoList: d_,
                isUndoNotification: true,
                selectedPrivateLot,
                removedLicensePlate,
                isParkingLeasesUpdated: true,
                isWidgetRefreshed: true
              }, () => {
                this.getTenantLeaseInDetail();
              })

            });
          });
        }
      })
  }

  private handleAddIconButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  private handleAddIconButtonClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  private handleAutoRenewListOpen = (event: React.MouseEvent<HTMLButtonElement>, item_: IPrivateLotsDetailsItem) => {
    this.setState({
      autoRenewAnchorE1: event.currentTarget,
      selectedTenantDetails: item_
    })
  }

  private handleAutoRenewListClose = () => {
    this.setState({
      autoRenewAnchorE1: null
    })
  }

  private handleResize = () => {
    this.setState({
      rightPanelState: this.state.rightPanelState
    })
  }

  private toastNotificationClose = () => {
    this.setState({isMultiSelectedProperty: false});
  }

  private _getRightPanelContent = () => {
    let filteredProperties = this.state.selectedProperties.filter(x => x.propertyGuid !== "blank");

    if (this.state.isAddNewTenant && this.state.assignSelectFlag) {
      const properties = this.state.properties?.filter((property: IPrivateLotProperty) => property.propertyGuid !== "blank");
      var IPrivateLotProperty = {
        propertyGuid: "",
        propertyName: "",
        openTimeString: "12:00 AM",
        closeTimeString: "11:59 PM",
        zoneInfo: ""

      }
      var IPrivateLotZone = {
        propertyGuid: "",
        zoneGuid: "",
        zoneName: ""

      }
      var IBlockSpot = {
        spotGuid: "",
        friendlySpotId: ""
      }
      var lease = {
        tenantBuilding: IPrivateLotProperty,
        zone: IPrivateLotZone,
        spots: IBlockSpot,
        filterDateRange: null,
        numberOfPasses: 1,
        licensePlate: "",
        index: 0,
        reservationGuid: "",
        isActive: false,
        unitNumber: "",
        privateLotUnitGuid: "",
      }
      const selectedStuff = {
        "selectedSpotGuid": this.state.mapSelectedSpotGuid,
        "selectedZoneGuid": this.state.mapSelectedZoneGuid,
        "selectedUnitGuid": "",
        "selectedPropertyGuid": this.state.mapPropertyGuid,
        "selectedUnitNumber": this.state.assignSelectedUnitNumber,
        "lease": lease,
        "selectedFromDate": this.state.selectedUnitObj.leaseFromDate,
        "selectedToDate": this.state.selectedUnitObj.leaseToDate,
        "isYardiSyncedUnit": this.state.selectedUnitObj.isYardiSyncedUnit
      }
      return (
        <AddTenant
          comefromFlag='Assign_tenant'
          selectedStuff={selectedStuff}
          properties={properties}
          onClose={() => {
            this.onCloseDrawerPanel();
          }}
          onChange={(hasChange: boolean) => {
            this.setState({
              hasUnsavedChanges: hasChange
            })
          }}
          requestType={this.state.rightPanelState}
          onSaved={(isPanelClosed?: boolean, editSavedResponse?: ISaveEditTenantRespone[], isPartiallySaved_?: boolean) => {
            // remove flag
            this.setState({
              assignSelectFlag: false
            })
            if (isPanelClosed === true) {
              let isUndoNotification_: boolean = false;
              let editSavedTenant_: ISaveEditTenantRespone[] = [];
              let toastNotifyType_: string = "";

              if (editSavedResponse && editSavedResponse.length > 0) {
                isUndoNotification_ = true;
                editSavedTenant_ = editSavedResponse;
                toastNotifyType_ = "editTenant";
              }

              if (editSavedResponse && editSavedResponse.length === 0) {
                isUndoNotification_ = true;
                toastNotifyType_ = "Tenant successfully updated.";
              }

              if (this.state.rightPanelState === RightPanelState.Add) {
                isUndoNotification_ = true;
                toastNotifyType_ = "Tenant added successfully.";
              }

              this.setState({
                selectedTenant: null,
                selectedReservation: "",
                rightPanelState: RightPanelState.None,
                isAddNewTenant: false,
                hasUnsavedChanges: false,
                isUndoNotification: isUndoNotification_,
                toastNotifyType: toastNotifyType_,
                editSavedResponse: editSavedTenant_,
                isParkingLeasesUpdated: true,
                isWidgetRefreshed: true
              }, () => {
                if (this.state.isTenantViewDetails) {
                  this.getTenantLeaseInDetail();
                } else {
                  this.getTenantPrivateLot();
                }
              })
            } else {
              if (this.state.isTenantViewDetails) {
                this.getTenantLeaseInDetail();
              } else {
                this.getTenantPrivateLot();
              }
              if (this.state.rightPanelState === RightPanelState.Add && !isPartiallySaved_) {
                this.setState({
                  isUndoNotification: true,
                  toastNotifyType: "Tenant added successfully.",
                  isParkingLeasesUpdated: true,
                  isWidgetRefreshed: true
                });
              }
            }

            if (this.state.isMapVisible && this.state.mapUploadingStatus.progress === EnumInteractiveMapStatus.MappingCompleted) {
              let selectedProperties_ = this.state.selectedProperties.filter(x => x.propertyGuid !== "blank");
              selectedProperties_.length === 1 && this.getSpotStatus(selectedProperties_[0].propertyGuid, true);
            }
          }}
          updateSelectedTenant={(licensePlate: string) => {
            const {selectedTenant} = this.state;
            if (this.state.isTenantViewDetails && selectedTenant && selectedTenant.propertyDetails && selectedTenant.propertyDetails.length > 0) {
              selectedTenant.propertyDetails[0].licensePlate += `,${licensePlate}`;
              this.setState({selectedTenant});
            }
          }}
          selectedTenant={this.state.selectedTenant}
          selectedReservation={this.state.selectedReservation}
        />
      );

    } else if (this.state.isAddNewTenant) {
      const properties = this.state.properties?.filter((property: IPrivateLotProperty) => property.propertyGuid !== "blank");
      return (
        <AddTenant
          properties={properties}
          onClose={() => {

            this.setState({
              assignSelectFlag: false,
              isAssignTenantDetailView: false,
              isAssignedTenanView: false
            })
            this.onCloseDrawerPanel()
          }


          }
          onChange={(hasChange: boolean) => {
            this.setState({
              hasUnsavedChanges: hasChange
            })
          }}
          requestType={this.state.rightPanelState}
          onSaved={(isPanelClosed?: boolean, editSavedResponse?: ISaveEditTenantRespone[], isPartiallySaved_?: boolean) => {

            if (isPanelClosed === true) {
              let isUndoNotification_: boolean = false;
              let editSavedTenant_: ISaveEditTenantRespone[] = [];
              let toastNotifyType_: string = "";

              if (editSavedResponse && editSavedResponse.length > 0) {
                isUndoNotification_ = true;
                editSavedTenant_ = editSavedResponse;
                toastNotifyType_ = "editTenant";
              }

              if (editSavedResponse && editSavedResponse.length === 0) {
                isUndoNotification_ = true;
                toastNotifyType_ = "Tenant successfully updated.";
              }

              if (this.state.rightPanelState === RightPanelState.Add) {
                isUndoNotification_ = true;
                toastNotifyType_ = "Tenant added successfully.";
              }

              this.setState({
                selectedTenant: null,
                selectedReservation: "",
                rightPanelState: RightPanelState.None,
                isAddNewTenant: false,
                hasUnsavedChanges: false,
                isUndoNotification: isUndoNotification_,
                toastNotifyType: toastNotifyType_,
                editSavedResponse: editSavedTenant_,
                isParkingLeasesUpdated: true,
                isWidgetRefreshed: true,
                isAssignTenantDetailView: false,
                isAssignedTenanView: false,
                assignSelectFlag: false
              }, () => {
                if (this.state.isTenantViewDetails) {
                  this.getTenantLeaseInDetail();
                } else {
                  this.getTenantPrivateLot();
                }
              })
            } else {
              if (this.state.isTenantViewDetails) {
                this.getTenantLeaseInDetail();
              } else {
                this.getTenantPrivateLot();
              }
              if (this.state.rightPanelState === RightPanelState.Add && !isPartiallySaved_) {
                this.setState({
                  isUndoNotification: true,
                  toastNotifyType: "Tenant added successfully.",
                  isParkingLeasesUpdated: true,
                  isWidgetRefreshed: true
                });
              }
            }

            if (this.state.isMapVisible && this.state.mapUploadingStatus.progress === EnumInteractiveMapStatus.MappingCompleted) {
              let selectedProperties_: IPrivateLotProperty[] = this.state.selectedProperties.filter(x => x.propertyGuid !== "blank");
              this.state.selectedProperties?.length === 1 && this.getSpotStatus(selectedProperties_[0].propertyGuid, true);
            }
          }}
          updateSelectedTenant={(licensePlate: string) => {
            const {selectedTenant} = this.state;
            if (this.state.isTenantViewDetails && selectedTenant && selectedTenant.propertyDetails && selectedTenant.propertyDetails.length > 0) {
              selectedTenant.propertyDetails[0].licensePlate += `,${licensePlate}`;
              this.setState({selectedTenant});
            }
          }}
          selectedTenant={this.state.selectedTenant}
          selectedReservation={this.state.selectedReservation}
        />
      );
    } else if (this.state.isBlockSpotOpened && filteredProperties.length === 1) {
      const selectedProperty = filteredProperties[0]
      return (
        <>
          <AddBlockSpotView
            rightPanelState={this.state.rightPanelState}
            selectedSpotAggregation={this.state.selectedSpotAggregation}
            propertyGuid={selectedProperty?.propertyGuid}
            selectedProperty={selectedProperty}
            onClose={(rState: boolean) => {
              this.setState({
                rightPanelState: this.state.rightPanelState === RightPanelState.Edit ? RightPanelState.Details : RightPanelState.None,
                isBlockSpotOpened: false,
                //isScheduledSpotsOpened: (this.state.rightPanelState == RightPanelState.Edit && this.state.selectedSpotAggregation != null),
                refreshDailyView: rState,
                hasUnsavedChanges: false,
                isWidgetRefreshed: true
              }, () => {
                if (this.state.isTenantViewDetails) {
                  this.getTenantLeaseInDetail();
                } else {
                  this.getTenantPrivateLot();
                }
              });
            }}
          />
        </>
      )
    } else if (this.state.isCustomRenew && this.state.SelectedParkingPassess.length > 0) {
      return (
        <CustomRenewParkingPass
          updateParkingPasses={(parkingPasses: IPrivateLotsDetailsItem[]) => {
            this.setState({SelectedParkingPassess: parkingPasses});
          }}
          isRenew={this.state.isRenew}
          onClose={this.onCloseDrawerPanel}
          parkingPasses={this.state.SelectedParkingPassess}
          onSaveCustomRenew={(eDate_: Date, renewType?: string) => {
            if (this.state.SelectedParkingPassess.length === 1) {
              const selectedTenantDetails = this.state.SelectedParkingPassess[0];
              this.setState({
                rightPanelState: RightPanelState.None,
                isCustomRenew: false,
                hasUnsavedChanges: false,
                autoRenewAnchorE1: null
              }, () => {
                this.renewLease(selectedTenantDetails, renewType ?? "custom", eDate_);
              })
            } else {
              const selectedPasses_ = this.state.SelectedParkingPassess;
              this.setState({
                rightPanelState: RightPanelState.None,
                isCustomRenew: false,
                hasUnsavedChanges: false
              }, () => {
                this.bulkRenewLease(selectedPasses_, renewType ?? "custom", eDate_);
              })
            }
          }}
          isRenewUpcoming={this.state.isRenewUpcoming}
        />
      )
    } else if (this.state.isAssignTenant) {
      const {selectedProperties} = this.state;

      let selectedProperty = selectedProperties.filter(x => x.propertyGuid !== "blank");

      if (selectedProperty?.length === 1 && selectedProperty[0].isYardiProperty) {
        this.setState({isAssignTenant: false, rightPanelState: RightPanelState.None}, () => {
          UtilHelper.lanuchPopup(selectedProperty[0].yardiRedirectURL, `Popup`, true, () => {
            this.refreshSyncYardi(selectedProperty[0].propertyGuid, () => {
              this.setState({
                toastNotifyType: "Tenant added successfully.",
                isUndoNotification: true,
                SelectedParkingPassess: [],
                isParkingLeasesUpdated: true,
                isWidgetRefreshed: true,
                mapSpotGuid: "", isResolveTenant: false,
                isAssignTenantDetailView: false,
                isAssignedTenanView: false,
                isAssignedVisitorTenantView: false
              }, () => {
                this.getTenantPrivateLot();
              });
            }, true, true);
          });
        });
      } else {
        return (
          <div>
            <div className='row-center'>
              <div style={{'width': '90%', 'marginTop': '30px'}}>
                <TextField
                  fullWidth id="outlined-basic" size="medium" label="" variant="outlined" color="primary"
                  autoComplete="off" value={this.state.unitNumberSearch} placeholder={"Search by unit number"}
                  inputProps={{maxLength: 20}}
                  onChange={(e: any) => {
                    if (e.target.value.length <= 20) {
                      this.setState({
                        unitNumberSearch: e.target.value
                      }, () => {
                        var foundValue = this.state.unitNumbers.filter((item: any) => {
                          var a: string = item;

                          if (a.toUpperCase().includes(this.state.unitNumberSearch.toUpperCase())) {
                            return item;
                          }

                        });
                        this.setState({
                          filterUnitNumber: foundValue,
                        })

                      })

                    }

                  }}
                  onFocus={
                    () => {
                      this.setState({
                        showUnitNumberList: true
                      })
                    }
                  }
                  onBlur={
                    () => {
                      setTimeout(() => {
                        this.setState({
                          showUnitNumberList: false
                        })
                      }, 500);


                    }
                  }
                  inputRef={refElm => {
                    this._searchByUnit = refElm;
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img className="Arrow-Image" src={SearchLens}></img>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {
                          this.state.unitNumberSearch && (
                            <img className="Arrow-Image" src={CloseSVG} onClick={() => {
                              this.setState({
                                unitNumberSearch: ""
                              }, () => {
                                this.setState({
                                  filterUnitNumber: this.state.unitNumbers
                                })
                              })

                            }}></img>
                          )
                        }
                      </InputAdornment>
                    )
                  }}

                />
                {this.state.showUnitNumberList && (
                  <>
                    <div style={{'maxHeight': '200px', overflowY: 'scroll', margin: '5px', borderRadius: '2px'}}
                         onClick={() => {

                           setTimeout(() => {
                             this.setState({
                               showUnitNumberList: true
                             })
                           }, 500);
                         }}>
                      <div>{this.state.filterUnitNumber.map((val, index) =>
                        <div style={{
                          'borderBottom': '1px solid #F8F8F8',
                          'height': '40px',
                          justifyContent: 'center',
                          'display': 'flex',
                          'flexDirection': 'column'
                        }}>
                          <div style={{'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'space-between'}}>
                            <label style={{'margin': '8px',}}>{val}</label>
                            <Button style={{'color': "#00AF85",}} onClick={() => {
                              this.setState({
                                // isAssignTenant: false,
                                rightPanelState: RightPanelState.None,
                                assignSelectedUnitNumber: val,
                                unitGuid: ""
                              }, () => {
                                // this.state.unitObj?.filter((property: any) => property.unitNumber == val)

                                let selectedValue = this.state.unitObj?.filter((property: any) => property.unitNumber == val)

                                this.setState({
                                  rightPanelState: RightPanelState.Add,
                                  selectedTenant: null,
                                  isAddNewTenant: true,
                                  assignSelectFlag: true,
                                  selectedUnitObj: selectedValue.length > 0 ? selectedValue[0] : val
                                })

                              })
                            }}>Assign</Button>
                          </div>
                        </div>
                      )}
                      </div>
                    </div>
                    {
                      this.state.selectedProperties.length > 0 && (
                        <>
                          {
                            this.state.selectedProperties[0].isYardiProperty && (
                              <></>
                            ) || (
                              <Button style={{'color': "#00AF85",}} onClick={() => {
                                this.setState({
                                  isAssignTenantDetailView: false,
                                  rightPanelState: RightPanelState.None
                                }, () => {
                                  this.setState({
                                    isAddNewTenant: true,
                                    isBulkUpload: false,
                                    anchorEl: null,
                                    assignSelectFlag: true,
                                    assignSelectedUnitNumber: "",
                                    rightPanelState: RightPanelState.Add,
                                    selectedUnitObj: {}
                                  })
                                })
                              }}>+ add new tenant</Button>
                            )
                          }
                        </>
                      )
                    }

                  </>
                )}
              </div>
            </div>
          </div>
        )
      }
    } else if (this.state.isAssignVisitor) {
      return (
        <AssignVisitor
          spotNumber={this.state.mapAssignSelectedSpot}
          isBookingConfirm={this.state.isVisitorBookingConfirm}
          loginGuid={this.state.unitGuid}
          spotNumbers={this.state.visitorSpotNumbers}

          handelBook={
            (selectedSpotGuid, minutes, licensePlate, dateRange) => {
              if (selectedSpotGuid) {
                if (licensePlate) {
                  if (minutes) {
                    var totalPrice = "";
                    let postdata = {
                      "DurationInMinutes": minutes,
                      "UnitGuid": this.state.unitGuid,
                      "PropertyGuid": this.state.mapPropertyGuid,
                      "StartTime": DateTimeHelper.convertIntoUtc(dateRange.startDate),
                      "EndTime": DateTimeHelper.convertIntoUtc(dateRange.endDate),
                      "SpotGuid": selectedSpotGuid
                    }
                    this._privateLotService.visitor_property_availability(postdata)
                      .then(r => {
                        if (r.status == 200) {
                          r.json().then(resp => {
                            if (resp?.booking?.isBookable) {
                              totalPrice = resp.booking.bestRate.totalPrice
                              let postdata = {
                                "VehicleLicense": licensePlate,
                                "AccessToken": localStorage.getItem('Stored-Visitor-AccessToken') ? localStorage.getItem('Stored-Visitor-AccessToken') : "",
                                "vehicleGuid": ""
                              }
                              this._privateLotService.visitor_vehicle_accesstoken(postdata)
                                .then(r => {
                                  if (r.status == 200) {
                                    r.json().then(resp => {
                                      var AccessToken = "";
                                      var VehicleGuid = "";
                                      if (resp != null) {
                                        AccessToken = resp.accessToken;
                                        VehicleGuid = resp.vehicleGuid;
                                        // console.log("visitor_vehicle_accesstoken", resp);
                                        localStorage.setItem('Stored-Visitor-AccessToken', AccessToken)
                                        let postdata = {
                                          "VehicleGuid": VehicleGuid,
                                          "UnitGuid": this.state.unitGuid,
                                          "PropertyGuid": this.state.mapPropertyGuid,
                                          "DurationInMinutes": minutes,
                                          "BookingTotalPrice": totalPrice,
                                          "SpotGuid": selectedSpotGuid,
                                          "FreeVisitorPassKey": "C8A1E852-C51B-4976-A0FA-55CB9871A69A",
                                          "StartTime": DateTimeHelper.convertIntoUtc(dateRange.startDate),
                                          "EndTime": DateTimeHelper.convertIntoUtc(dateRange.endDate)
                                        }
                                        this._privateLotService.visitor_hold_spot(postdata, AccessToken)
                                          .then(r => {
                                            if (r.status == 200) {
                                              r.json().then(resp => {
                                                if (!resp.hasAvailableSpots) {
                                                  ShowAlert("", `This lot is for visit parking only.<br/>
                                                                                                    Search your location to find available parking near you.`, "error");
                                                } else {
                                                  var TemporaryBlockGuid = resp.blockGuid
                                                  let postdata = {
                                                    "PaymentCardId": "",
                                                    "TemporaryBlockGuid": TemporaryBlockGuid,
                                                  }
                                                  this._privateLotService.visitor_initiate_payment(postdata, AccessToken)
                                                    .then(r => {
                                                        if (r.status == 200) {
                                                          r.json().then(resp => {
                                                            // console.log("visitor_initiate_payment-200", resp);
                                                            this._privateLotService.visitor_payment_status(resp)
                                                              .then(r => {
                                                                if (r.status == 200) {
                                                                  r.json().then(resp => {
                                                                      localStorage.setItem('Stored-Visitor-reservationGuid', resp.reservationGuid);

                                                                      this.setState({
                                                                        isVisitorBookingConfirm: true
                                                                      })

                                                                    }
                                                                  )
                                                                }
                                                              })
                                                          })
                                                        } else {
                                                          r.json().then(resp => {
                                                            ShowAlert("", resp, "error");
                                                          })

                                                        }

                                                      }
                                                    )

                                                }

                                              })
                                            }
                                          })
                                      }
                                    })
                                  }
                                })


                            } else {
                              ShowAlert("", `Spot not available`, "error");
                            }
                          });


                        } else if (r.status == 409) {
                          r.json().then(resp => {
                            ShowAlert("", resp, "error");
                          })


                        }
                      });
                  } else {
                    ShowAlert("", 'Kindly enter valid date and time ', "error");
                  }

                } else {
                  // console.log("no license number");
                  ShowAlert("", 'Kindly enter valid license number', "error");
                }

              } else {
                // console.log("no spot number");
                ShowAlert("", 'Kindly enter spot number', "error");
              }

              //console.log("handle booking", selectedSpotGuid, minutes, licensePlate);


              // this.setState({
              //     isVisitorBookingConfirm: true
              // })
            }
          }
          handelCancle={
            () => {
              this.setState({
                  isAssignVisitor: false,
                  rightPanelState: RightPanelState.None,
                  unitGuid: "",
                  isVisitorBookingConfirm: false
                }
              )
            }
          }
          handelNext={

            (unitNumber, passcode) => {
              this.setState({
                isVisitorBookingConfirm: false
              })

              let postdata = {
                "UnitNumber": unitNumber,
                "PassCode": passcode,
                "PropertyGuid": this.state.mapPropertyGuid
              }
              this._privateLotService.visitor_verify_tenant(postdata)
                .then(r => {
                  if (r.status == 200) {
                    r.json().then(resp => {

                      this.setState({
                        unitGuid: resp,
                        assignVisitorTitle: "Book Visitor Parking"
                      })

                    });
                    this._privateLotService.get_spots_Assign_tenant(this.state.mapPropertyGuid)
                      .then(r => {
                        if (r.status == 200) {
                          r.json().then(resp => {
                            if (Array.isArray(resp)) {
                              // var spotNumber: string[] = []
                              // for (var i = 0; i < resp.length; i++) {
                              //     console.log("friendlySpotName", resp[i].friendlySpotName)
                              //     spotNumber.push(resp[i].friendlySpotName)
                              // }
                              // console.log("SpotName", spotNumber);
                              this.setState({
                                  visitorSpotNumbers: resp
                                }
                              )
                            }
                          })

                        }
                      })
                  } else if (r.status == 400) {
                    r.json().then(resp => {
                      if (resp == 'Received Maximum') {
                        ShowAlert("", 'Sorry, this unit has already utilized all the visitor parking passes for this month.', "error");

                      } else {
                        ShowAlert("", 'Kindly enter valid unit number and passcode', "error");
                      }
                    })

                  } else {
                    ShowAlert("", 'Kindly enter valid unit number and passcode', "error");
                    // console.log("non-200 123");
                  }
                });

            }
          }
          handelSendReceipt={
            (emailAddress, phoneNumber) => {
              if (emailAddress !== "") {
                let postData = {
                  "ReservationGuid": localStorage.getItem('Stored-Visitor-reservationGuid'),
                  "RecipientMail": emailAddress
                }
                this._privateLotService.visitor_send_receipt_email(postData, String(localStorage.getItem('Stored-Visitor-AccessToken')))
                  .then(r => {
                    // if (r.status == 200) {

                    // } else {
                    //     console.log("non-200 123");
                    // }
                  });

              } else if (phoneNumber !== "") {
                let postData = {
                  "ReservationGuid": localStorage.getItem('Stored-Visitor-reservationGuid'),
                  "MobileNumber": Number(phoneNumber)
                }
                this._privateLotService.visitor_send_receipt_sms(postData, String(localStorage.getItem('Stored-Visitor-AccessToken')))
                  .then(r => {
                    // if (r.status == 200) {

                    // } else {
                    //     console.log("non-200 123");
                    // }
                  });

              }

            }
          }

        >
        </AssignVisitor>
      )

    } else if (this.state.isYardiSyncView) {
      const properties = this.state.properties?.filter((property: IPrivateLotProperty) => property.propertyGuid !== "blank");
      let selectedProperties_ = this.state.selectedProperties.filter(x => x.propertyGuid !== "blank")[0];
      return (
        <>
          {
            this.state.selectedProperties && (
              <YardiSync
                properties={properties}
                selectedProperty={selectedProperties_}
                handelYardiAssign={(property_) => {

                }}
                onCloseDrawerPanel={(showSyncSuccess?: boolean, choosedProperty?: IPrivateLotProperty | null, onPropertyChange?: (property_: IPrivateLotProperty[], floorValue?: IFloor | null) => void) => {
                  this.onCloseDrawerPanel();
                  if (showSyncSuccess) {
                    if (this.state.isMapVisible && onPropertyChange && choosedProperty) {
                      onPropertyChange([choosedProperty], this.state.selectedMapFloor);
                    } else {
                      this.loadProperties(true);
                    }
                  }
                }}
                updateSyncDate={() => {
                  this.setState({isScanRefreshed: true});
                }}
                onPropertyChange={(property_: IPrivateLotProperty[], floorValue?: IFloor | null) => {
                  let properties = property_.filter(x => x.propertyGuid != "blank");
                  let selectedMapFloor = this.state.selectedMapFloor ? this.state.selectedMapFloor : null;
                  selectedMapFloor = floorValue ? floorValue : selectedMapFloor;
                  this.setState({
                    selectedProperties: property_,
                    isMultiSelectedProperty: properties.length > 1,
                    isResolveTenant: false,
                    selectedMapFloor,
                    showMapLoader: this.state.isMapVisible,
                    isTenantViewDetails: false
                  }, () => {
                    this.loadProperties(true, true);
                    if (this.state.isMapVisible) {
                      let propertyGuid = this.state.selectedProperties.length > 0 ? this.state.selectedProperties[0].propertyGuid : "";
                      if (propertyGuid != "") {
                        this.getSpotStatus(propertyGuid, true);
                      } else {
                        // console.log("RetrievePropertyMap");
                        this.RetrievePropertyMap();
                      }
                    }
                    this.setState({
                      isMapVisible: !this.state.isMapVisible,
                      viewType: "map",
                      isAssignTenant: false,
                      isResolveTenant: false
                    }, () => {
                      this.getFloorLevels();
                    });
                  })
                }
                }

              />
            )
          }

        </>

      )
    }
  }

  private getBlukUploadContent = () => {
    const {selectedProperties} = this.state;

    let properties = selectedProperties.filter(x => x.propertyGuid != "blank");

    let errorMessage = "";
    let isError = false;
    if (!properties || properties?.length === 0 ||
      (properties?.length !== 0 &&
        properties[0].propertyGuid === "00000000-0000-0000-0000-000000000000")) {
      errorMessage = "Property is not selected";
      isError = true;
    } else if (properties?.length > 1) {
      errorMessage = "Please select only one property";
      isError = true;
    } else if (properties?.length == 1 && properties[0].isYardiProperty) {
      errorMessage = "Not allowed for third party managed property";
      isError = true;
    }

    if (isError) {
      ShowAlert("", errorMessage, "warning");
      this.setState({
        isBulkUpload: false
      });
      return;
    }

    return (
      <div className="bulk-upload-blocker" onClick={(event: any) => {
        if (event.target && event.target.classList.toString().indexOf("bulk-upload-blocker") !== -1) {
          this.setState({
            isBulkUpload: false
          })
        }
      }}>
        <div className="bulk-upload-popup">
          <BulkUploadPrivateLots propertyGuid=
                                   {properties?.length === 1 ?
                                     properties[0].propertyGuid :
                                     "00000000-0000-0000-0000-000000000000"
                                   }
                                 onModelClose={(isBulkUpload: boolean) => this.setState(
                                   {
                                     isBulkUpload: isBulkUpload,

                                   })}
                                 getTenantPrivateLot={() => {
                                   this.setState({
                                     isUndoNotification: true,
                                     toastNotifyType: "Bulk upload tenant(s) added successfully."
                                   }, () => {
                                     this.getTenantPrivateLot();
                                   })
                                 }}
                                 getTenantLeaseInDetail={() => {
                                   this.setState({
                                     isUndoNotification: true,
                                     toastNotifyType: "Bulk upload tenant(s) added successfully."
                                   }, () => {
                                     this.getTenantLeaseInDetail()
                                   })
                                 }}
                                 isTenantViewDetails={this.state.isTenantViewDetails}
                                 isMapVisible={this.state.isMapVisible}
          />
        </div>
      </div>
    )
  }

  private getPropertyDetais() {
    return (
      <div
        className="calendar-property-blocker"
        onClick={(event: any) => {
          if (
            event.target &&
            event.target.classList
              .toString()
              .indexOf("calendar-property-blocker") !== -1
          ) {
            this.setState({
              propertyBlocker: false,
            });
          }
        }}
      >
        <div className="calendar-property-popup">
          <CalendarProperty
            properties={this.state.properties}
            open={true}
            onPropertyChange={(property_: IPropertyBasics | null) => {
              this.setState(
                {
                  selectedProperties: property_ ? [property_] as IPrivateLotProperty[] : [],
                  propertyBlocker: property_ == null,
                },
                () => {
                  this.getTenantPrivateLot();
                }
              );
            }}
            selectedProperty={this.state.selectedProperties[0]}
            id="blocker-property"
          />
        </div>
      </div>
    );
  }

  private loadProperties(isFromSyncYardi?: boolean, hideLoader?: boolean) {
    this.setState({
      showBlocker: true,
    });
    this._privateLotService.getPrivateLotProperties(hideLoader)
      .then(r => r.json())
      .then(r => {
        let properties_: IPrivateLotProperty[] = r;
        properties_ = ArrayHelper.sortProperty(properties_);

        const privateLotSearch = PreserveFilters.getSearchTerm('privateLotSearch');

        let selectedProperties: IPrivateLotProperty[] = [];
        let searchTerm = "";

        if (privateLotSearch?.selectedProperties && !isFromSyncYardi)
          selectedProperties = privateLotSearch?.selectedProperties;

        if (privateLotSearch?.searchTerm && !isFromSyncYardi)
          searchTerm = privateLotSearch?.searchTerm;

        if (isFromSyncYardi && this.state.selectedProperties.length > 0) {
          properties_.filter(x => x.propertyGuid !== "blank").map(x => {
            const selectedProperty = this.state.selectedProperties.find(y => y.propertyGuid === x.propertyGuid);
            if (selectedProperty) {
              x.openTimeString = selectedProperty.openTimeString;
              x.closeTimeString = selectedProperty.closeTimeString;
              selectedProperties.push(x);
            }
            return x;
          });
        }

        let filteredProperties = selectedProperties.length > 0 ? [selectedProperties[0]] : [];

        this.setState({
          properties: properties_,
          selectedProperties: filteredProperties,
          isMultiSelectedProperty: filteredProperties.length > 1,
          isFocused: false,
          searchTerm: searchTerm ? searchTerm : "",
          showBlocker: false,
          propertyBlocker: filteredProperties.length === 0,
        }, () => {
          // this.updateNotificationData();

          if (!this.state.isMapVisible || isFromSyncYardi) {
            this.getTenantPrivateLot(hideLoader);
          } else {
            this.setState({
              selectedProperties: [properties_[2]]
            })
          }
        });
      });
  }

  private async updateNotificationData() {
    let PrivateLotNotificationList: IPrivateLotNotification[] = (await this._privateLotService.getPrivateLotNotification());
    // let _oldNotifications = localStorage.getItem("PrivateLotNotifications");
    // _oldNotifications = _oldNotifications ? JSON.parse(_oldNotifications) : [];

    this.setState({
      privateLotNotifications: PrivateLotNotificationList.map((x: IPrivateLotNotification) => {
        x.message = x.message.replace("in &ldquo;1&rdquo; days", "&ldquo;Today&rdquo;").replaceAll("&ldquo;", "<b>").replaceAll("&rdquo;", "</b>");
        x.endTime = new Date(x.endTime);

        return x;
      })
    }, () => {
      // localStorage.setItem("PrivateLotNotifications", JSON.stringify(this.state.privateLotNotifications));
    });
  }

  private getTenantPrivateLot(noLoader?: boolean) {
    this._privateLotService.getPrivatlotTenantuser(noLoader, this.state.selectedProperties[0]?.propertyGuid)
      .then(r => r.json())
      .then(r => {
        let updatedList_ = r.map((e: IPrivateLotsItem) => {
          let spotCount_: number = 0;

          e.propertyDetails.forEach(x => {
            e.spots = spotCount_ += x.reservationGuid.length;
          });

          return e;
        });

        if (this.state.properties?.length > 0 && this.state.selectedProperties?.length > 0 && this.state.properties?.length === this.state.selectedProperties?.length) {
          const hasAllInSelectProperty = this.state.selectedProperties?.find((property: IPrivateLotProperty) => property.propertyName === "All Properties");
          const hasAllInProperties = this.state.properties?.find((property: IPrivateLotProperty) => property.propertyName === "All Properties");

          let properties_: IPrivateLotProperty[] = hasAllInProperties ? this.state.properties.slice(1) : this.state.properties;
          let selectedProperties_: IPrivateLotProperty[] = hasAllInSelectProperty ? this.state.selectedProperties.slice(1) : this.state.selectedProperties;
          let selectAll: IPrivateLotProperty = {
            propertyName: "All Properties",
            propertyGuid: "blank",
            openTimeString: "",
            closeTimeString: "",
            zoneInfo: [],
            isYardiProperty: false,
            yardiRedirectURL: ""
          }
          if (properties_?.length > 0) {
            properties_.unshift(selectAll);
          }

          if (selectedProperties_?.length > 0) {
            selectedProperties_.unshift(selectAll);
          }

          properties_.map(e => {
            e.openTimeString = "12:00 AM";
            e.closeTimeString = "11:59 PM";

            return e;
          });

          this.setState({
            selectedProperties: selectedProperties_,
            properties: properties_,
            allTenantDetails: updatedList_,
            filteredTenantDetails: updatedList_.slice(0),
            subFilteredTenantDetails: updatedList_.slice(0),
            isWidgetRefreshed: false
          }, () => {
            this.filterTenantPrivateLot();
            // this.updateNotificationData();
          });
        } else {
          this.setState({
            allTenantDetails: updatedList_,
            filteredTenantDetails: updatedList_.slice(0),
            subFilteredTenantDetails: updatedList_.slice(0),
            isWidgetRefreshed: false
          }, () => {
            this.filterTenantPrivateLot();
            // this.updateNotificationData();
          });
        }
      });
  }

  private getTenantLeaseInDetail(noLoader?: boolean) {
    let privateLotUnitGuid_: string = this.state.selectedPrivateLot ? this.state.selectedPrivateLot.privateLotUnitGuid : "";
    this._privateLotService.getPrivatlotTenantInDetail(`spot/get-tenant-spot-details/${privateLotUnitGuid_}`, noLoader)
      .then(r => r.json())
      .then(r => {
        let update = r.map((x: IPrivateLotsDetailsItem) => {
          x.leaseStartDateLocal = new Date(x.leaseStartDateLocal);
          x.leaseEndDateLocal = new Date(x.leaseEndDateLocal);

          return x;
        });

        this.setState({
          allViewTenantDetailsList: update,
          filteredViewTenantDetailsList: update.slice(0),
          subFilteredViewTenantDetailsList: update.slice(0),
          isWidgetRefreshed: false
        }, () => {
          this.filterTenantLeaseInDetail();
          // this.updateNotificationData();
        })

      });
  }

  private onCloseDrawerPanel = () => {

    if (this.state.hasUnsavedChanges) {
      ShowAlertwithConfirm("You have unsaved changes!", "Are you sure you want to close this window?", "warning")
        .then(r_ => {
          if (r_) {
            this.updateCloseState();
          }
        })
    } else {
      this.updateCloseState()
    }
  }

  private updateCloseState() {
    if (this.state.isCustomRenew) {
      this.setState({
        rightPanelState: RightPanelState.None,
        isCustomRenew: false,
        SelectedParkingPassess: [],
        hasUnsavedChanges: false,
        isBlockSpotOpened: false,
        isRenew: false,
        clearPrivatelotSelectedItem: true
      })
    } else {
      this.setState({
        selectedTenant: null,
        selectedReservation: "",
        rightPanelState: RightPanelState.None,
        hasUnsavedChanges: false,
        isAddNewTenant: false,
        isBlockSpotOpened: false,
        isRenew: false,
        clearPrivatelotSelectedItem: true
      })
    }
  }

  private filterTenantPrivateLot() {
    let filtered_ = this.state.allTenantDetails.filter(x => {
      let f_ = x.propertyDetails.filter(p => JSON.stringify(this.state.selectedProperties).indexOf(p.propertyGuid) != -1);
      return f_.length > 0;
    });

    this.setState({
      subFilteredTenantDetails: filtered_,
      showMapLoader: false
    }, () => {
      this._searchTermChange(this.state.searchTerm, true);
    });
  }

  private filterTenantLeaseInDetail() {
    let filtered_ = this.state.allViewTenantDetailsList.filter(x => {
      return JSON.stringify(this.state.selectedProperties).indexOf(x.propertyGuid) != -1;
    });

    this.setState({
      subFilteredViewTenantDetailsList: filtered_,
      SelectedParkingPassess: this.state.SelectedParkingPassess
    }, () => {
      this._searchTermChange(this.state.searchTerm, true);
    });
  }

  private renewLease(item_: IPrivateLotsDetailsItem | null, option_: string, expirationDate_?: Date) {
    if (item_ != null) {
      let startDate_ = item_.leaseStartDateLocal;
      let endDate_ = item_.leaseEndDateLocal;
      const endDate = new Date(endDate_);
      if (option_ == "1 Year") {
        endDate_ = new Date(endDate.setFullYear(endDate.getFullYear() + 1));
      }

      if (option_ == "1 Month") {
        endDate_ = new Date(endDate.setMonth(endDate.getMonth() + 1));
      }

      if (option_ == "custom") {
        endDate_ = new Date(expirationDate_ ? expirationDate_ : new Date());
      }


      let leasedetails_: IEditTenantLease = {
        startDate: DateTimeHelper.convertIntoUtc(startDate_),
        endDate: DateTimeHelper.convertIntoUtc(endDate_),
        reservationGuid: item_.reservationGuid,
        spotGuid: item_.spotGuid,
        IsActive: true
      }

      this._privateLotService.editTenantLease([leasedetails_])
        .then(r => {
          if (r.ok) {
            r.json().then(res_ => {
              let resp_: ISaveEditTenantRespone[] = res_;

              let errorItems = resp_.filter(x => x.isError == true);

              if (errorItems.length == 0) {
                ShowAlert('', 'Tenant lease updated successfully.', 'success').then(() => {
                  this.getTenantLeaseInDetail();
                  const {selectedProperties} = this.state;
                  let selectedProperties_: IPrivateLotProperty[] = selectedProperties.filter(x => x.propertyGuid != "blank");
                  if (this.state.isRenew && !this.state.isRenewUpcoming) {
                    option_ = `Parking pass has been renewed until ${DateTimeHelper.GetMonthYearFormat(endDate_)}`;
                    selectedProperties_.length === 1 && this.getSpotStatus(selectedProperties_[0].propertyGuid);
                  } else if (this.state.isRenewUpcoming) {
                    switch (option_) {
                      case "1 Year":
                        option_ = `Parking pass has been renewed for 1 year`;
                        break;
                      default:
                        option_ = `Parking pass has been renewed for 1 month`;
                        break;
                    }
                    selectedProperties_.length === 1 && this.getSpotStatus(selectedProperties_[0].propertyGuid);
                  }
                  this.setState({
                    toastNotifyType: option_,
                    isUndoNotification: true,
                    editSavedResponse: res_,
                    isParkingLeasesUpdated: true,
                    isRenewUpcoming: false,
                    isResolveTenant: false,
                    isWidgetRefreshed: true,
                    mapSpotGuid: "",
                    isAssignTenantDetailView: false,
                    isAssignedTenanView: false,
                    isAssignedVisitorTenantView: false
                  })
                });
              } else {
                ShowAlert('Lease Cannot Be Updated', 'Spot has active parking lease and cannot be updated until active parking has been cancelled or expired.', 'error');
              }
            });
          }
        });

      // console.log(item_, option_);
    }
  }

  private bulkRenewLease(item_: IPrivateLotsDetailsItem[] | null, option_: string, expirationDate_?: Date) {
    if (item_ != null && item_.length > 0) {
      let bulkRenewItems_ = ObjectHelper.deepClone(item_);
      let leasedetails_: IEditTenantLease[] = [];

      const yardiRedirectURL: string[] = bulkRenewItems_
        .filter((item: IPrivateLotsDetailsItem) => item.yardiRedirectURL !== "")
        .map(x => x.yardiRedirectURL)
        .filter((item: string, index: number, self: string[]) => self.indexOf(item) === index);

      const hasYardiItem = yardiRedirectURL.length > 0;

      bulkRenewItems_ = hasYardiItem ? bulkRenewItems_.filter((item: IPrivateLotsDetailsItem) => item.yardiRedirectURL === "") : bulkRenewItems_;

      bulkRenewItems_.forEach(pass_ => {
        let startDate_ = pass_.leaseStartDateLocal;
        let endDate_ = pass_.leaseEndDateLocal;
        if (option_ == "1 Year") {
          endDate_ = new Date(endDate_.setFullYear(endDate_.getFullYear() + 1));
        }

        if (option_ == "1 Month") {
          endDate_ = new Date(endDate_.setMonth(endDate_.getMonth() + 1));
        }

        if (option_ == "custom") {
          endDate_ = new Date(expirationDate_ ? expirationDate_ : new Date());
        }
        leasedetails_.push({
          startDate: DateTimeHelper.convertIntoUtc(startDate_),
          endDate: DateTimeHelper.convertIntoUtc(endDate_),
          reservationGuid: pass_.reservationGuid,
          spotGuid: pass_.spotGuid,
          IsActive: true
        })
      });

      if (hasYardiItem) {
        this.renewCustomYardiCallBack(leasedetails_, option_, yardiRedirectURL);
      } else {
        this.renewCustomCallBack(leasedetails_, option_);
      }
    }
  }

  private renewCustomYardiCallBack(leasedetails_: IEditTenantLease[], option_: string,
                                   yardiRedirectURL: string[]) {
    const selectProperty = this.state.selectedProperties.filter(x => x.propertyGuid !== "blank");
    if (leasedetails_.length > 0) {
      this._privateLotService.editTenantLease(leasedetails_)
        .then(r => {
          if (r.ok) {
            r.json().then(res_ => {
              let resp_: ISaveEditTenantRespone[] = res_;
              let errorItems = resp_.filter(x => x.isError == true);
              let popup = 1;
              yardiRedirectURL.forEach(x => {
                UtilHelper.lanuchPopup(x, `Popup_${popup++}`, true, () => {
                  this.refreshSyncYardi(selectProperty[0].propertyGuid, () => {
                    this.renewCustomCallBackSuccess(errorItems,
                      option_, res_, resp_);
                  });
                });
              });

            });
          }
        });
    } else {
      let popup = 1;
      yardiRedirectURL.forEach(x => {
        UtilHelper.lanuchPopup(x, `Popup_${popup++}`, true, () => {
          this.refreshSyncYardi(selectProperty[0].propertyGuid, () => {
            this.setState({
              toastNotifyType: "Tenant lease updated successfully.",
              isUndoNotification: true,
              SelectedParkingPassess: [],
              isParkingLeasesUpdated: true,
              isWidgetRefreshed: true,
            }, () => {
              if (this.state.isTenantViewDetails) {
                this.getTenantLeaseInDetail();
              } else {
                this.getTenantPrivateLot();
              }
            });
          });
        });
      });
    }
  }

  private renewCustomCallBack(leasedetails_: IEditTenantLease[], option_: string) {
    this._privateLotService.editTenantLease(leasedetails_)
      .then(r => {
        if (r.ok) {
          r.json().then(res_ => {
            let resp_: ISaveEditTenantRespone[] = res_;
            let errorItems = resp_.filter(x => x.isError == true);
            this.renewCustomCallBackSuccess(errorItems,
              option_, res_, resp_);
          });
        }
      });
  }

  private renewCustomCallBackSuccess(errorItems: ISaveEditTenantRespone[],
                                     option_: string, res_: any, resp_: ISaveEditTenantRespone[]) {
    if (errorItems.length == 0) {
      ShowAlert('', 'Tenant lease(s) updated successfully.', 'success').then(() => {
        if (this.state.isTenantViewDetails) {
          this.getTenantLeaseInDetail();
        } else {
          this.getTenantPrivateLot();
        }
        this.setState({
          toastNotifyType: option_,
          isUndoNotification: true,
          editSavedResponse: res_,
          SelectedParkingPassess: [],
          isParkingLeasesUpdated: true,
          isWidgetRefreshed: true,
        })
      });
    } else {
      let undoItems_ = resp_.filter(x => x.isError == false);
      let selectedItems_: IPrivateLotsDetailsItem[] = []
      for (let s_ of this.state.SelectedParkingPassess) {
        if ((undoItems_.filter(u_ => u_.reservationGuid == s_.reservationGuid && u_.spotGuid == s_.spotGuid)).length == 0) {
          selectedItems_.push(s_);
        }
      }
      ShowAlert('Lease(s) Cannot Be Updated', 'Some of the leases were not updated. please modiy expiration date for selected lease(s) and try again', 'error').then(() => {

        if (undoItems_.length > 0) {
          //this.getTenantLeaseInDetail();
          this.setState({
            toastNotifyType: option_,
            isUndoNotification: true,
            editSavedResponse: undoItems_,
            SelectedParkingPassess: selectedItems_,
            isParkingLeasesUpdated: true,
            isWidgetRefreshed: true,
          }, () => {
            if (this.state.isTenantViewDetails) {
              this.getTenantLeaseInDetail();
            } else {
              this.getTenantPrivateLot();
            }
          })
        }
      });
    }
  }

  private removeAccessApi = async (data_: IRemoveAccess) => {
    return await this._privateLotService.removeAccess(data_)
      .then(r => r.json())
      .then(r => {
        return r;
      })
  }

  private bulkRemoveAccessApi = async (data_: IRemoveAccess[]) => {
    return await this._privateLotService.bulkRemoveAccess(data_)
      .then(r => r.json())
      .then(r => {
        return r;
      })
  }

  private _searchTermChange = (searchTerm_: string, isDataRefreshed?: boolean): void => {
    this.setState({
      searchTerm: searchTerm_,
      subFilteredTenantDetails: this._filterDataBySearch(this.state.filteredTenantDetails, searchTerm_)
    }, () => {
      if (isDataRefreshed) {
        this.updateNotificationData();
      }
    });
  }

  private _filterDataBySearch = (data_: IPrivateLotsItem[], searchTerm_: string): IPrivateLotsItem[] => {
    let filteredData = data_.filter(d => {
      return (d.propertyDetails && d.propertyDetails.length > 0
          && d.propertyDetails[0].licensePlate.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1) ||
        d.unitNumber.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
        d.spots.toString().toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
        (d.yardiRedirectURL !== "" ? "Yardi" : "Gryd").toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1;
    });

    const selectedProperties = this.state.selectedProperties;

    filteredData = filteredData.filter(d => {
      return d.propertyDetails?.find(property => selectedProperties?.find(selectProperty => selectProperty.propertyGuid === property.propertyGuid))
    });

    return filteredData;
  }

  private RetrievePropertyMap(isLoader: boolean = false, newUnmappedFloor?: any) {
    let selectedProperties = this.state.selectedProperties.filter(x => x.propertyGuid != "blank");
    if (selectedProperties.length == 1) {
      let selectedFloor = this.state.selectedMapFloor ? this.state.selectedMapFloor.levelId : 0;

      if (newUnmappedFloor != null) {
        selectedFloor = newUnmappedFloor;
      }

      let zoneData = {
        "propertyGuid": selectedProperties[0].propertyGuid,
        "levelId": selectedFloor
      } as any;

      // Clear localstorage to avoid 5MB
      var keys = Object.keys(localStorage).filter(x => x.indexOf("asset_property") != -1);

      if (keys.length > 0) {
        keys.forEach(asset_property => {
          localStorage.removeItem(asset_property);
        });
      }


      this._privateLotService.RetrievePropertyMap(zoneData, isLoader)
        .then(r => r.json())
        .then(r => {
          let progress_: number = EnumInteractiveMapStatus.UploadMap;
          let loader_ = false;

          if (r['mapStatus'] == EnumMapEditStatus.Uploaded) {
            progress_ = EnumInteractiveMapStatus.CompleteLotSetup;
            loader_ = true;
          } else if (r['mapStatus'] == EnumMapEditStatus.Configured) {
            progress_ = EnumInteractiveMapStatus.MappingCompleted;
            loader_ = true;

            localStorage.removeItem(`asset_property_${this.state.selectedMapFloor ? this.state.selectedMapFloor.levelId : 0}_${selectedProperties[0].propertyGuid}`);
          }


          if (r['otherElements'] != null && r['otherElements'] != "") {
            let mappedJson: IFigmaNodes = {
              OtherElements: [],
              Spots: {}
            };
            mappedJson = JSON.parse(r['otherElements']) as IFigmaNodes;

            let localData = localStorage.getItem(`asset_property_${this.state.selectedMapFloor ? this.state.selectedMapFloor.levelId : 0}_${selectedProperties[0].propertyGuid}`);

            if (progress_ == EnumInteractiveMapStatus.MappingCompleted || localData == null) {
              localStorage.setItem(`asset_property_${this.state.selectedMapFloor ? this.state.selectedMapFloor.levelId : 0}_${selectedProperties[0].propertyGuid}`, JSON.stringify(mappedJson));
            }
          }


          let mapUrl_: string = "";

          if ((r['externalMapUrl'] != null && r['externalMapUrl'] != "")) {
            mapUrl_ = r['externalMapUrl'];
          } else if (r['mapStatus'] == EnumMapEditStatus.Uploaded && r['externalMapUrl'] == "") {
            mapUrl_ = "blankUrl";
          }

          if (this.state.isCommonMapUpload && progress_ == EnumInteractiveMapStatus.UploadMap) {
            progress_ = EnumInteractiveMapStatus.PropertySelection;
          }

          this.setState({
            isMapVisible: false
          }, () => {
            this.setState({
              isMapVisible: true,
              isCommonMapUpload: false,
              mapUploadingStatus: {
                progress: progress_,
                selectedSpotInfo: null,
                selectedZone: null
              },
              viewType: "map",
              isResolveTenant: false,
              figmaNodeId: mapUrl_,
              // showMapLoader: isLoader ? false : isLoader,
              showMapLoader: loader_,
              mapSpotGuid: "",
              isAssignTenantDetailView: false,
              isAssignedTenanView: false,
              isAssignedVisitorTenantView: false
            });
          })

        });
    }
  }

  // private getFloorLevel() {
  //     this._privateLotService.getFloorLevel(true)
  //         .then(r => r.json())
  //         .then(r => {
  //             let floors: IFloor[] = [];

  //             floors = r;

  //             this.setState({
  //                 floorLevel: floors,
  //                 selectedMapFloor: floors.length > 0 ? floors[0] : null
  //             }, () => {
  //                 this.RetrieveMapProperty(true);
  //             })
  //         });
  // }

  // private RetrieveMapProperty(isLoader: boolean = false) {
  //     let selectedProperties = this.state.selectedProperties.filter(x => x.propertyGuid != "blank");
  //     if (selectedProperties.length == 1) {
  //         let zoneData = {
  //             "propertyGuid": selectedProperties[0].propertyGuid,
  //             "levelId": 0
  //         } as any;

  //         this._privateLotService.RetrievePropertyMap(zoneData, isLoader)
  //             .then(r => r.json())
  //             .then(r => {
  //                 let progress_: number = EnumInteractiveMapStatus.UploadMap;

  //                 if (r['mapStatus'] == EnumMapEditStatus.Uploaded) {
  //                     progress_ = EnumInteractiveMapStatus.CompleteLotSetup;
  //                 } else if (r['mapStatus'] == EnumMapEditStatus.Configured) {
  //                     progress_ = EnumInteractiveMapStatus.MappingCompleted;

  //                     localStorage.removeItem(`asset_property_${selectedProperties[0].propertyGuid}`);
  //                 }


  //                 if (r['otherElements'] != null && r['otherElements'] != "") {
  //                     let mappedJson: IFigmaNodes = {
  //                         OtherElements: [],
  //                         Spots: {}
  //                     };
  //                     mappedJson = JSON.parse(r['otherElements']) as IFigmaNodes;

  //                     let localData = localStorage.getItem(`asset_property_${selectedProperties[0].propertyGuid}`);

  //                     if (progress_ == EnumInteractiveMapStatus.MappingCompleted || localData == null) {
  //                         localStorage.setItem(`asset_property_${selectedProperties[0].propertyGuid}`, JSON.stringify(mappedJson));
  //                     }
  //                 }


  //                 let mapUrl_: string = "";

  //                 if ((r['externalMapUrl'] != null && r['externalMapUrl'] != "")) {
  //                     mapUrl_ = r['externalMapUrl'];
  //                 } else if (r['mapStatus'] == EnumMapEditStatus.Uploaded && r['externalMapUrl'] == "") {
  //                     mapUrl_ = "blankUrl";
  //                 }

  //                 if (this.state.isCommonMapUpload && progress_ == EnumInteractiveMapStatus.UploadMap) {
  //                     progress_ = EnumInteractiveMapStatus.PropertySelection;
  //                 }

  //                 this.setState({
  //                         isMapVisible: false,
  //                         isCommonMapUpload: false,
  //                         mapUploadingStatus: {
  //                             progress: progress_,
  //                             selectedSpotInfo: null,
  //                             selectedZone: null
  //                         },
  //                         isResolveTenant: false,
  //                         figmaNodeId: mapUrl_,
  //                         mapSpotGuid: "",
  //                         isAssignTenantDetailView: false,
  //                         isAssignedTenanView: false,
  //                         isAssignedVisitorTenantView: false
  //                 });

  //             });
  //     }
  // }

  private getFloorLevels(isLoaderVisible: boolean = true, newUnmappedFloor?: any, callback_?: () => void) {
    this.setState({
      showMapLoader: isLoaderVisible
    }, () => {
      let selectedProperties = this.state.selectedProperties;
      if (this.state.selectedProperties.length > 0) {
        selectedProperties = this.state.selectedProperties.filter(x => x.propertyGuid !== 'blank');
      }
      this._privateLotService.getFloorLevel(selectedProperties.length > 0 ? selectedProperties[0].propertyGuid : undefined, true)
        .then(r => r.json())
        .then((r: IFloor[]) => {
          let floors: IFloor[] = [];

          floors = r;//.filter(f => f.hasMap);

          let mappedFloor = floors.filter(f => f.hasMap);

          // if (floors.length == 0) {
          //     floors = r;
          // }

          const {selectedMapFloor} = this.state;

          let selectMapFloor = floors.length > 0 ? floors[0] : null;

          if (mappedFloor.length > 0) {
            selectMapFloor = mappedFloor[0];
          }

          selectMapFloor = selectedMapFloor ? selectedMapFloor : selectMapFloor;
          // selectMapFloor = selectMapFloor;

          this.setState({
            floorLevel: floors,
            selectedMapFloor: selectMapFloor
          }, () => {
            if (callback_) {
              callback_();
            } else {
              this.RetrievePropertyMap(true, newUnmappedFloor);
            }
          })
        });
    });

  }

  private getStatusOfBookings() {
    if (this.state.isMapVisible && this.state.mapUploadingStatus.progress === EnumInteractiveMapStatus.MappingCompleted) {
      this._spotService.getBookingBlockingStatus()
        .then(r => r.json())
        .then(r => {
          let status: IUspGetBookingBlockingStatus[] = r;

          status = status.map(s => {
            s.lastUserScan = null;
            s.lastUserSeek = null;
            return s;
          });

          if (JSON.stringify(status) !== JSON.stringify(this.state.uspGetBookingBlockingStatus)) {
            const {selectedProperties} = this.state;
            let selectedProperties_: IPrivateLotProperty[] = selectedProperties.filter(x => x.propertyGuid != "blank");
            const propertyGuid = selectedProperties_.length > 0 ? selectedProperties_[0].propertyGuid : "";
            this.setState({
              uspGetBookingBlockingStatus: status,
              isWidgetRefreshed: true
            }, () => {
              propertyGuid && this.getSpotStatus(propertyGuid, true);
            });
          }
        });
    }
  }

  private mapUpload() {
    const {selectedProperties} = this.state;

    let properties = selectedProperties.filter(x => x.propertyGuid != "blank");

    let errorMessage = "";
    let isError = false;
    if (!properties || properties?.length === 0 ||
      (properties?.length !== 0 &&
        properties[0].propertyGuid === "00000000-0000-0000-0000-000000000000")) {
      errorMessage = "Property is not selected";
      isError = true;
    } else if (properties?.length > 1) {
      errorMessage = "Please select only one property";
      isError = true;
    }

    if (isError) {
      ShowAlert("", errorMessage, "warning");
      this.setState({
        isBulkUpload: false
      });
      return;
    }

    if (properties.length == 1) {

      let selectedFloor = this.state.selectedMapFloor ? this.state.selectedMapFloor.levelId : 0;

      if (this.state.selectedMapFloor) {
        var unmappedFloors = this.state.floorLevel.filter(x => x.hasMap != true);

        if (unmappedFloors.length > 0) {
          selectedFloor = unmappedFloors[0].levelId;
        }
      }

      let zoneData = {
        "propertyGuid": properties[0].propertyGuid,
        "levelId": selectedFloor
      } as any;


      this._privateLotService.CheckMapStatus(zoneData, false)
        .then(r => r.json())
        .then(r => {
          if (r['mapStatus'] != 0) {
            // this.setState({
            //     deleteMapPopup: true
            // })

            ShowAlertWithConfirmCustomIcon(
              "Delete Existing Map?",
              `<div class="mt-3">Are you sure you want to delete existing map?<br/>
                            This cannot be undone.
                            </div>`,
              "error",
              "Yes",
              "No",
              "gryd-custom-popup",
              '<div><svg class="MuiSvgIcon-root error-icon-size" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg></div>'
            )
              .then(agreed_ => {
                if (agreed_) {
                  let propertyGuid_ = selectedProperties[0].propertyGuid;
                  let floor_ = selectedFloor;
                  let zoneData = {
                    "propertyGuid": propertyGuid_,
                    "levelId": floor_
                  } as any;


                  this._privateLotService.ResetMap(zoneData)
                    .then(r => {

                      localStorage.removeItem(`asset_property_${floor_}_${propertyGuid_}`);

                      this.setState({
                        isMapVisible: true,
                        viewType: "map",
                        isAssignTenant: false,
                        isResolveTenant: false,
                        // deleteMapPopup: false,
                      }, () => {
                        this.getFloorLevels();
                      });
                    });
                }
              });
          } else {
            localStorage.removeItem(`asset_property_${selectedFloor}_${properties[0].propertyGuid}`);

            this.setState({
              deleteMapPopup: false,
              isMapVisible: true,
              viewType: "map",
              isAssignTenant: false,
              isResolveTenant: false
            }, () => {
              this.getFloorLevels(false, selectedFloor);
            });
          }
        });
    }
  }

  private renderUploadMap() {
    let selectedProperties = this.state.selectedProperties.filter(x => x.propertyGuid != "blank");
    return (
      <SpotMapPopup
        bodyText_="Are you sure you want to delete existing map?<br/>This cannot be undone."
        title_="Delete Existing Map?"
        confirmButtonText_="Yes"
        cancelButtonColor_="No"
        onDone={(isConfirmed: Boolean) => {
          if (isConfirmed) {
            let propertyGuid_ = selectedProperties[0].propertyGuid;
            let floor_ = this.state.selectedMapFloor ? this.state.selectedMapFloor.levelId : 0;
            let zoneData = {
              "propertyGuid": propertyGuid_,
              "levelId": floor_
            } as any;


            this._privateLotService.ResetMap(zoneData)
              .then(r => {

                localStorage.removeItem(`asset_property_${floor_}_${propertyGuid_}`);

                this.setState({
                  isMapVisible: true,
                  viewType: "map",
                  isAssignTenant: false,
                  isResolveTenant: false,
                  deleteMapPopup: false,
                }, () => {
                  this.getFloorLevels();
                });
              });
          } else {
            this.setState({
              deleteMapPopup: false
            })
          }
        }}
      />
    )
  }

  private refreshSyncYardi(propertyGuid: string, callback?: () => void, hideLoader?: boolean, showCustomLoader?: boolean) {
    this._privateLotService.getSyncYardiRefresh(propertyGuid, true, hideLoader, showCustomLoader)
      .then(response => {
        if (response.ok) {
          callback && callback();
        } else {
          response.json().then(res => {
            ShowAlert('', res, "error");
          }).catch(error => {
            ShowAlert("", error, "error");
          });
        }
      }).catch(error => {
      ShowAlert("", error, "error");
    });
  }
}

export default withRouter(PrivateLots);


interface IPrivateLotsProps {

}

interface IPrivateLotsState {
  viewType: string;
  isMapVisible: boolean;
  isTenantViewDetails: boolean;
  allTenantDetails: IPrivateLotsItem[];
  filteredTenantDetails: IPrivateLotsItem[];
  subFilteredTenantDetails: IPrivateLotsItem[];
  allViewTenantDetailsList: IPrivateLotsDetailsItem[];
  filteredViewTenantDetailsList: IPrivateLotsDetailsItem[];
  subFilteredViewTenantDetailsList: IPrivateLotsDetailsItem[];
  isMultiSelectedProperty: boolean;
  isNotificationEnabled: boolean;
  notifications: [];
  selectedProperties: IPrivateLotProperty[];
  properties: IPrivateLotProperty[];
  propertyBlocker: boolean,
  rightPanelState: RightPanelState;
  isAddMenuOpen: boolean;
  anchorEl: HTMLButtonElement | null;
  isAddNewTenant: boolean;
  isBulkUpload: boolean;
  selectedPrivateLot: IPrivateLotsItem | null;
  autoRenewAnchorE1: HTMLButtonElement | null;
  selectedTenant: IPrivateLotsItem | null;
  selectedTenantDetails: IPrivateLotsDetailsItem | null;
  selectedReservation: string;
  isBlockSpotOpened: boolean;
  selectedSpotAggregation: IDailySpotAggregation | null;
  refreshDailyView: boolean;
  searchTerm: string;
  isCustomRenew: boolean;
  SelectedParkingPassess: IPrivateLotsDetailsItem[];
  hasUnsavedChanges: boolean;
  removeAccessUndoList: IRemoveAccessUndo[];
  editSavedResponse: ISaveEditTenantRespone[];
  toastNotifyType: string;
  isUndoNotification: boolean;
  undoRenewLeaseData: IPrivateLotsDetailsItem | null;
  selectedTenantList: IPrivateLotsItem[];
  isFocused: boolean;
  privateLotNotifications: IPrivateLotNotification[];
  isWidgetRefreshed: boolean;
  isScreenAccessible: boolean;
  removedLicensePlate?: string;
  figmaNodeId: string;
  isRenew: boolean;
  isParkingLeasesUpdated: boolean;
  isMapLoaded: boolean;
  mapUploadingStatus: IProgressDetails;
  mappedSpotInfo: ZoneActiveSpot | null;
  mappedZoneInfo: IPrivateLotZone | null;
  mapResetState: IMapResetOption;
  mapSelectedSpotDetails: ISelectedSpotDetails | null;
  clearedSpot: ZoneActiveSpot | null;
  mapBookingModel?: IMapBookingModel | null;
  isRenewUpcoming: boolean,
  isResolveTenant: boolean,
  mapSpotGuid: string,
  isAssignTenantDetailView: boolean;
  isAssignVisitorBTVisible: boolean;
  isAssignTenantBTVisible: boolean;
  isAssignTenant: boolean;
  unitNumberSearch: string;
  unitNumbers: any[];
  // filterUnitNumber: string[];
  filterUnitNumber: any[];
  showUnitNumberList: boolean;
  teantUnitNumber: string,
  teantSpotNo: string,
  teantName: string,
  teantEmail: string,
  teantPhone: string,
  teantDuration: string;
  vistorDuration: string;
  teantLicencePlate: string[];
  isAssignVisitor: boolean;
  unitGuid: string;
  assignVisitorTitle: string;
  isVisitorBookingConfirm: boolean;
  isAssignedTenanView: boolean;
  isAssignedVisitorTenantView: boolean;
  visitorSpotNumbers: string[];
  spotNumbers: any[];
  mapPropertyGuid: string;
  mapSelectedSpotGuid: string;
  mapSelectedZoneGuid: string;
  assignSelectFlag: boolean;
  assignSelectedUnitNumber: string;
  selectedMapProperty: IPrivateLotProperty | null;
  selectedMapFloor: IFloor | null;
  mapStatus: number;
  floorLevel: IFloor[];
  mapAssignSelectedSpot: string;
  uspGetBookingBlockingStatus: IUspGetBookingBlockingStatus[];
  showMapLoader: boolean;
  deleteMapPopup: boolean;
  bookingMappedJson: IFigmaNodes | null;
  showBlocker: boolean;
  isCommonMapUpload: boolean;
  isYardiSyncView: boolean;
  selectedUnitObj: any;
  isYardiUser: boolean;
  unitObj: any;
  clearPrivatelotSelectedItem: boolean;
  isScanRefreshed: boolean;
  isSpotActive: boolean;
  isUploadMapActive: boolean;
}
