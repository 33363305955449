// import { Button } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import React, { useContext } from "react";
import WidgetLoaderComponent from "../../Components/WidgetLoaderComponent";
import "./AssignTenant.css";
import { SyncContext } from "../../Contexts/BatchSyncContext";
export default class AssignTenant extends React.Component<IAssignTenantProps, IAssignTenantState> {

    static contextType = SyncContext;
    constructor(props_: IAssignTenantProps) {
        super(props_);
        this.state = {
            spotNo: this.props.spotNo,
            isVisitorBTVisible: this.props.isVisitorBTVisible,
            isTenantBTVisible: this.props.isTenantBTVisible
        }

    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps: Readonly<IAssignTenantProps>): void {
        if (prevProps.spotNo != this.props.spotNo) {
            this.setState({
                spotNo: this.props.spotNo,
            })
        }
        if (prevProps.showBlocker !== this.props.showBlocker) {
            this.setState({
                isTenantBTVisible: this.props.isTenantBTVisible,
                isVisitorBTVisible: this.props.isVisitorBTVisible
            });
        }
    }


    render() {
        return (
            <div className="base-root-view">
                <div style={{ 'width': '75%', 'backgroundColor': 'white', 'alignSelf': 'center', 'borderRadius': '10px', margin: '10px' }}>
                    <div style={{ 'width': '100%', 'height': '50px', 'backgroundColor': '#00AF85', borderTopLeftRadius: '10px', 'borderTopRightRadius': '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div style={{ 'display': 'flex', 'flexDirection': 'row', 'width': '100%', justifyContent: 'space-between' }}>
                            <div>
                                <label style={{ 'marginLeft': '20px', color: 'white', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '14px' }}>Spot No.</label>
                                <label style={{ color: 'white', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '14px' }}>{this.state.spotNo}</label>
                            </div>
                            <div>

                            </div>
                        </div>
                    </div>


                    <div style={{ marginTop: '10px' }}>
                        <label style={{ 'marginLeft': '10px', color: 'black', 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '15px' }}>No tenant assigned</label>
                    </div>
                    {
                        !this.props.isSpotActive && <div style={{ marginTop: '10px' }}>
                            <label style={{ 'marginLeft': '10px', color: 'black', 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '15px' }}>This spot is inactive</label>
                        </div>
                    }
                    <div style={{ 'marginBottom': '20px' }}>
                        {
                            <WidgetLoaderComponent loaderVisible={this.props.showBlocker} />
                        }
                        {!this.props.showBlocker && this.state.isTenantBTVisible && (
                            <div style={{ width: '100%', 'alignItems': 'center', 'display': 'flex', flexDirection: 'row', 'justifyContent': 'center', 'marginTop': '10px' }}>
                                <Button style={{ width: '90%', height: '40px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: 700, borderRadius: '8px', color: 'white', borderColor: '#00AF85' }}
                                    variant="outlined"
                                    disabled={this.context.info.syncing}
                                    onClick={() => {
                                        this.props.handelAssignTenant()
                                    }}>
                                    Assign Tenant
                                </Button>
                            </div>
                        )
                        }

                        {
                            !this.props.showBlocker && this.state.isVisitorBTVisible && (
                                <div style={{ width: '100%', 'alignItems': 'center', 'display': 'flex', flexDirection: 'row', 'justifyContent': 'center', 'marginTop': '10px', 'marginBottom': '20px' }}>
                                    <Button style={{ width: '90%', height: '40px', backgroundColor: 'white', fontSize: "15px", fontFamily: 'Manrope', fontWeight: 400, borderRadius: '8px', 'color': '#00AF85', borderColor: 'white', 'textDecoration': 'underline' }}
                                        variant="outlined"
                                        disabled={this.context.info.syncing}
                                        onClick={() => {
                                            this.props.handelAssignVisitor()
                                        }}>
                                        Assign Visitor
                                        {/* <label style={{ 'textDecoration': 'underline', 'color': '#00AF85' }}>Assign Visitor</label> */}
                                    </Button>

                                </div>

                            )
                        }
                    </div>
                </div>
            </div >
        )
    }
}

interface IAssignTenantState {
    spotNo: string,
    isVisitorBTVisible: boolean,
    isTenantBTVisible: boolean

}

interface IAssignTenantProps {
    spotNo: string,
    isVisitorBTVisible: boolean,
    isTenantBTVisible: boolean,
    isSpotActive: boolean,
    handelAssignTenant(): void,
    handelAssignVisitor(): void,
    showBlocker: boolean
}