import BaseService from "./BaseService";
import { ConfigService } from './ConfigService';

export default class XeroService extends BaseService {
    public getXeroLoginURL() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + 'disbursement/Xero/GetXeroLoginURL', {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public updateXeroToken(code: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `disbursement/Xero/GenerateandRecordAccessInfo/${code}`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }
}