import React from "react";
import { IAppliedFilter } from "../../Components/DataGrid/Interfaces";
import { Grid, Button } from "@material-ui/core";
import ObjectHelper from "../../Helpers/ObjectHelper";

export default class BaseFilterView<P extends IBaseFilterViewProps<any, any>, S extends IBaseFilterViewState<any>>
    extends React.Component<P, S> {
    protected _isFiltered: boolean = false;
    protected _defaultFilter: IAppliedFilter;

    protected filterItems = (): any[] => { return []; }
    protected serverFilterItems = (onComplete_: (items_: any[]) => void): void => { }
    protected renderFilterOptions = () => { }

    constructor(props_: P, defaultFilter_: IAppliedFilter) {
        super(props_);

        this._defaultFilter = defaultFilter_;
        (this.state as any) = {
            appliedFilter: ObjectHelper.deepClone(props_.appliedFilter ? props_.appliedFilter : this._defaultFilter),
            autoApplyFilterKey: 0
        };
    }

    componentDidUpdate(oldProps_: P) {
        if (this.props.autoApplyFilterKey != 0 && oldProps_.autoApplyFilterKey != this.props.autoApplyFilterKey) {
            this.setState({
                appliedFilter: ObjectHelper.deepClone(this.props.appliedFilter ? this.props.appliedFilter : this._defaultFilter),
                autoApplyFilterKey: this.props.autoApplyFilterKey
            }, () => {
                this._applyFilter(false);
            });
        }
        else if (oldProps_.appliedFilter != this.props.appliedFilter) {
            this.setState({
                appliedFilter: ObjectHelper.deepClone(this.props.appliedFilter ? this.props.appliedFilter : this._defaultFilter)
            });
        }
    }

    render() {
        if (!this.props.isVisible) {
            return null;
        }
        return (
            <div className="filter-view px-3">
                <div className="text-right">
                    <Button onClick={this._clearFilter}>Clear All</Button>
                </div>
                {
                    this.renderFilterOptions()
                }
                <Grid container spacing={1} className="button-container">
                    <Grid item xs>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => this._applyFilter(true)}
                        >Apply</Button>
                    </Grid>
                    <Grid item xs>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={this._cancelFilter}
                        >Cancel</Button>
                    </Grid>
                </Grid>
            </div>
        );
    }

    private _applyFilter = (autoClose_: boolean) => {
        if (this.props.isServerSideFilter) {
            this.serverFilterItems(items_ => {
                this.props.onFilterChange(ObjectHelper.deepClone(this.state.appliedFilter), items_, this._isFiltered);
                if (autoClose_) {
                    this.props.onClose();
                }
            });
        } else {
            this.props.onFilterChange(ObjectHelper.deepClone(this.state.appliedFilter), this.filterItems(), this._isFiltered);
            if (autoClose_) {
                this.props.onClose();
            }
        }
    }

    private _cancelFilter = () => {
        this.setState({
            appliedFilter: ObjectHelper.deepClone(this.props.appliedFilter ? this.props.appliedFilter : this._defaultFilter)
        });
        this.props.onClose();
    }

    private _clearFilter = () => {
        // Doesn't seem to work when applied once
        this.setState({
            appliedFilter: ObjectHelper.deepClone(this._defaultFilter)
        }, () => {
            this.setState({
                autoApplyFilterKey: this.state.autoApplyFilterKey
            });
        });
    }
}

export interface IBaseFilterViewProps<D, F> {
    data: D[];
    autoApplyFilterKey: number;
    isVisible: boolean;
    appliedFilter?: F;
    isServerSideFilter?: boolean;
    onFilterChange: (appliedFilter_: F, filteredData_: D[], isFiltered_: boolean) => void;
    onClose: () => void;
    customFilter?: any;
}

export interface IBaseFilterViewState<F> {
    appliedFilter: F;
    autoApplyFilterKey: number;
}