import { Button, Checkbox, Grid, TextField } from "@material-ui/core";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Autocomplete } from "@material-ui/lab";
import { DateRange } from "materialui-daterange-picker";
import React from "react";
import { ShowAlertwithConfirm } from "../../Common/ShowAlert";
import VirtualizeAutocomplete from "../../Common/VirtualizeWindowAutoComplete";
import ArrayHelper from "../../Helpers/ArrayHelper";
import { RightPanelState } from "../../Models/CommonInterfaces";
import PrivateLotService from "../../Services/PrivateLotService";
import GrydCalendarPicker from "../BookingsCalendar/GrydCalendarPicker";
import GrydDateRangePicker from "../BookingsCalendar/GrydDateRangePicker";
import { EnumCalendarPosition, IBlockSpot } from "../BookingsCalendar/interfaces";
import { IPrivateLotProperty, IPrivateLotZone, ITenantSpotDetails } from "./interface";

export default class AssignProperty extends React.Component<IAssignPropertyProps, IAssignPropertyState> {
    private _privateLotService: PrivateLotService;

    constructor(props_: IAssignPropertyProps) {
        super(props_);

        this.state = {
            selectedTenantBuilding: null,
            zoneList: [],
            selectedSpots: [],
            filterDateRange: {},
            spots: [],
            selectedZone: null,
            numberOfPasses: 0,
            licencePlate: "",
            leases: [],
            disableForms: false,
            selectedStuff: this.props.selectedStuff,
        }

        this._privateLotService = new PrivateLotService();
    }

    componentDidMount() {
       // console.log("selectedStuff Assign", this.state.selectedStuff);
        this.setState({
            leases: this.props.leases
        }, () => {
            
            if(this.props.selectedStuff?.isYardiSyncedUnit){
                this.zoneForProperty(undefined,()=>{
                    this.prefiledLeaseForm();
                });
            }else{
                this.zoneForProperty();
            }
            })
    }

    componentDidUpdate() {
        if (JSON.stringify(this.props.leases) != JSON.stringify(this.state.leases)) {
            this.setState({
                leases: this.props.leases
            }, () => {
                this.zoneForProperty();
            })
        }
    }

    prefiledLeaseForm = () =>{
        let leases_ = this.state.leases;
          
            const { selectedPropertyGuid, selectedZoneGuid } = this.props.selectedStuff;
            const { properties } = this.props;
            const selectedPropety = properties.find((property) => {
                return property.propertyGuid === selectedPropertyGuid
            })
            if (selectedPropety) {

                let propertyDetail: IPrivateLotProperty = {
                    propertyGuid: selectedPropety.propertyGuid,
                    propertyName: selectedPropety.propertyName,
                    openTimeString: "12:00 AM",
                    closeTimeString: "11:59 PM",
                    zoneInfo: selectedZoneGuid,
                    isYardiProperty: selectedPropety.isYardiProperty,
                    yardiRedirectURL: selectedPropety.yardiRedirectURL

                }
                let zone_: IPrivateLotZone[] = selectedPropety.zoneInfo;

                let selectedZone = zone_.find((z) => z.zoneGuid === selectedZoneGuid) ?? {

                    propertyGuid: propertyDetail.propertyGuid,
                    zoneGuid: selectedZoneGuid,
                    zoneName: ""

                }
                    leases_[this.props.currentIndex].tenantBuilding = selectedPropety;
                    leases_[this.props.currentIndex].zone = selectedZone;
                    leases_[this.props.currentIndex].spots = [];
                    leases_[this.props.currentIndex].numberOfPasses = 1;
                this.zoneForProperty(leases_);
            }

    }
    render() {
        const { disableForms } = this.state;
        
        return (
            <React.Fragment>
                {
                    this.props.leases[this.props.currentIndex].index != 0 && <div className="del-box-right">
                        <div className="multi-schedule-right-panel">
                            <Button onClick={() => {
                                ShowAlertwithConfirm("Delete", "Are you sure you want to delete this?", "warning")
                                    .then(agreed_ => {
                                        if (agreed_) {
                                            if (this.props.onDelete) {
                                                this.props.onDelete(this.props.currentIndex);
                                            }
                                        }
                                    });
                            }}>
                                <DeleteForeverIcon htmlColor="#dc3545" />
                            </Button>
                        </div>
                    </div>
                }
                <div className="add-tenant-block-pl">
                    <div className="panel-body-field-label">Duration</div>
                    {
                        this.props.requestType == RightPanelState.Add && <GrydDateRangePicker
                            placeHolder="Date"
                            initialDateRange={this.props.leases[this.props.currentIndex].filterDateRange}
                            disableDateTF = {this.props.selectedStuff?.isYardiSyncedUnit}
                            onDateChange={(dates_: DateRange) => {
                                let leases_ = this.state.leases;
                                if (!this.props.selectedStuff) {
                                    if (JSON.stringify(leases_[this.props.currentIndex].filterDateRange) !== JSON.stringify(dates_)) {
                                        leases_[this.props.currentIndex].tenantBuilding = null;
                                        leases_[this.props.currentIndex].zone = null;
                                        leases_[this.props.currentIndex].spots = [];
                                        leases_[this.props.currentIndex].numberOfPasses = 0;
                                    }
                                    leases_[this.props.currentIndex].filterDateRange = dates_;

                                    const filterDateRange = leases_[this.props.currentIndex].filterDateRange

                                    this.setState({
                                        leases: leases_,
                                        disableForms: Object.keys(filterDateRange).length !== 0
                                    }, () => {
                                        if (this.props.onChange) {
                                            this.props.onChange(this.state.leases);
                                        }
                                    });

                                } else {
                                    const { selectedPropertyGuid, selectedZoneGuid } = this.props.selectedStuff;
                                    const { properties } = this.props;
                                    const selectedPropety = properties.find((property) => {
                                        return property.propertyGuid === selectedPropertyGuid
                                    })
                                    if (selectedPropety) {

                                        let propertyDetail: IPrivateLotProperty = {
                                            propertyGuid: selectedPropety.propertyGuid,
                                            propertyName: selectedPropety.propertyName,
                                            openTimeString: "12:00 AM",
                                            closeTimeString: "11:59 PM",
                                            zoneInfo: selectedZoneGuid,
                                            isYardiProperty: selectedPropety.isYardiProperty,
                                            yardiRedirectURL: selectedPropety.yardiRedirectURL

                                        }
                                        let zone_: IPrivateLotZone[] = selectedPropety.zoneInfo;

                                        let selectedZone = zone_.find((z) => z.zoneGuid === selectedZoneGuid) ?? {

                                            propertyGuid: propertyDetail.propertyGuid,
                                            zoneGuid: selectedZoneGuid,
                                            zoneName: ""

                                        }
                                        if (JSON.stringify(leases_[this.props.currentIndex].filterDateRange) !== JSON.stringify(dates_)) {
                                            leases_[this.props.currentIndex].tenantBuilding = selectedPropety;
                                            leases_[this.props.currentIndex].zone = selectedZone;
                                            leases_[this.props.currentIndex].spots = [];
                                            leases_[this.props.currentIndex].numberOfPasses = 1;
                                        }
                                        leases_[this.props.currentIndex].filterDateRange = dates_;
                                        this.zoneForProperty(leases_);




                                        // {
                                        //     propertyGuid: propertyDetail.propertyGuid,
                                        //     zoneGuid: x.zoneGuid,
                                        //     zoneName: x.zoneName
                                        // }




                                    }

                                }

                                // this.setState({
                                //     filterDateRange: dates_
                                // })
                            }}
                            required={true}
                        //minDate={this.getMinDateRange()}
                        />
                    }
                    {
                        this.props.requestType == RightPanelState.Edit &&
                        <Grid container>
                            <Grid item xs>
                                Start Date:
                                <GrydCalendarPicker
                                    placeHolder="Start Date"
                                    defaultDate={this.getStartDate()}

                                    onDateChange={(date: Date | null) => {
                                        let leases_ = this.state.leases;
                                        // leases_[this.props.currentIndex].filterDateRange = dates_;
                                        // let endDate_ = leases_[this.props.currentIndex].filterDateRange.endDate;

                                        if (date != null) {
                                            leases_[this.props.currentIndex].filterDateRange.startDate = date;
                                        }

                                        /*if ((endDate_ != null && date != null) && (date.getTime() > endDate_.getTime())) {
                                            leases_[this.props.currentIndex].filterDateRange.endDate = date;
                                        }*/

                                        this.setState({
                                            leases: leases_
                                        }, () => {
                                            if (this.props.onChange) {
                                                this.props.onChange(this.state.leases);
                                            }
                                        })
                                    }}
                                    isMandate={true}
                                    calendarPosition={EnumCalendarPosition.Bottom}
                                //disabled={true}
                                />
                            </Grid>
                            <Grid item><div style={{ marginRight: 10 }}></div></Grid>
                            <Grid item xs>
                                End Date:
                                <GrydCalendarPicker
                                    placeHolder="End Date"
                                    defaultDate={this.getEndDate()}
                                    onDateChange={(date: Date | null) => {
                                        let leases_ = this.state.leases;
                                        // leases_[this.props.currentIndex].filterDateRange = dates_;
                                        // let endDate_ = leases_[this.props.currentIndex].filterDateRange.endDate;

                                        if (date != null) {
                                            leases_[this.props.currentIndex].filterDateRange.endDate = date;
                                        }

                                        /*if ((endDate_ != null && date != null) && (date.getTime() > endDate_.getTime())) {
                                            leases_[this.props.currentIndex].filterDateRange.endDate = date;
                                        }*/

                                        this.setState({
                                            leases: leases_
                                        }, () => {
                                            if (this.props.onChange) {
                                                this.props.onChange(this.state.leases);
                                            }
                                        })
                                    }}
                                    //minDate={this.getMinDate()}
                                    isMandate={true}
                                    calendarPosition={EnumCalendarPosition.Bottom}
                                    calendarClass={'cl-block-right'}
                                    
                                />
                            </Grid>
                        </Grid>
                    }
                </div>
                <div className="add-tenant-block-pl">
                    <div className="panel-body-field-label">Tenant Building</div>
                    <Autocomplete
                        className="cl-repeat-dropdown"
                        options={this.props.comefromFlag ? this.props.properties : this.props.properties.filter(x => !x.isYardiProperty)}
                        value={this.props.leases[this.props.currentIndex].tenantBuilding}

                        openOnFocus={true}
                        selectOnFocus={true}
                        getOptionLabel={(option) => option.propertyName}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <div className="cl-dropdown-list">
                                    <Checkbox
                                        icon={<div className="repeat-b-check" />}
                                        checkedIcon={<div className="repeat-b-check selected" />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                        color="primary"
                                    />
                                    {option.propertyName}
                                </div>
                            </React.Fragment>
                        )}
                        renderInput={(params) => <TextField {...params}
                            placeholder="Select Tenant Building"
                            variant="outlined"
                            required={true}
                            error={this.props.leases[this.props.currentIndex].tenantBuilding == null}
                        />}
                        onChange={(e_: any, property_: IPrivateLotProperty | null) => {
                            let zones_: IPrivateLotZone[] = [];

                            if (property_ != null) {
                                zones_ = property_.zoneInfo;
                            }

                            let leases_ = this.state.leases;
                            leases_[this.props.currentIndex].tenantBuilding = property_;
                            leases_[this.props.currentIndex].zone = null;
                            leases_[this.props.currentIndex].spots = [];
                            leases_[this.props.currentIndex].numberOfPasses = 0;

                            this.setState({
                                leases: leases_,
                                zoneList: zones_,
                                spots: []
                            }, () => {
                                if (this.props.onChange) {
                                    this.props.onChange(this.state.leases);
                                }
                            });

                            // this.setState({
                            //     selectedTenantBuilding: property_,
                            //     zoneList: zones_,
                            //     selectedZone: null
                            // });
                        }}
                        disabled={this.props.requestType == RightPanelState.Edit ||
                            (this.props.requestType === RightPanelState.Add && !disableForms)}
                    />
                </div>
                <div className="add-tenant-block-pl">
                    <div className="panel-body-field-label">Select Zone</div>
                    <Autocomplete
                        className="cl-repeat-dropdown"
                        value={this.props.leases[this.props.currentIndex].zone}
                        options={this.state.zoneList}
                        openOnFocus={true}
                        selectOnFocus={true}
                        getOptionLabel={(option) => option.zoneName}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <div className="cl-dropdown-list">
                                    <Checkbox
                                        icon={<div className="repeat-b-check" />}
                                        checkedIcon={<div className="repeat-b-check selected" />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                        color="primary"
                                    />
                                    {option.zoneName}
                                </div>
                            </React.Fragment>
                        )}
                        onChange={(e_: any, zone_: IPrivateLotZone | null) => {
                            let leases_ = this.state.leases;
                            leases_[this.props.currentIndex].zone = zone_;
                            leases_[this.props.currentIndex].spots = [];
                            leases_[this.props.currentIndex].numberOfPasses = 0;

                            this.setState({
                                leases: leases_,
                                selectedZone: zone_,
                                spots: []
                            }, () => {
                                if (this.props.onChange) {
                                    this.props.onChange(this.state.leases);
                                }
                                if (this.state.selectedZone != null) {
                                    this.zoneForProperty();
                                } else {
                                    this.setState({
                                        spots: []
                                    })
                                }
                            });
                        }}
                        renderInput={(params) => <TextField {...params}
                            placeholder="Select Zone"
                            variant="outlined"
                            required={true}
                            error={this.props.leases[this.props.currentIndex].zone == null}
                        />}
                        disabled={this.props.requestType == RightPanelState.Edit ||
                            (this.props.requestType === RightPanelState.Add && !disableForms)}

                    />
                </div>
                <div className="add-tenant-block-pl">
                    <div className="panel-body-field-label">Number of Passes</div>
                    <TextField
                        fullWidth
                        placeholder="Enter Number of Passes"
                        variant="outlined"
                        type="number"
                        required={true}
                        margin="normal"
                        className="mt-0"
                        error={this.props.leases[this.props.currentIndex].numberOfPasses == 0}
                        value={this.props.leases[this.props.currentIndex].numberOfPasses}
                        onPaste={() => { return false }}
                        onChange={(e_: any) => {
                            let leases_ = this.state.leases;

                            let value: number = e_.target.value as number;

                            if (value < 0 || value > this.state.spots.length) {
                                value = this.props.leases[this.props.currentIndex].numberOfPasses;
                            }

                            leases_[this.props.currentIndex].numberOfPasses = value;
                            leases_[this.props.currentIndex].spots = [];

                            this.setState({
                                leases: leases_
                            }, () => {
                                if (this.props.onChange) {
                                    this.props.onChange(this.state.leases);
                                }
                            });
                        }}
                        autoComplete="off"
                        disabled={this.props.requestType == RightPanelState.Edit ||
                            (this.props.requestType === RightPanelState.Add && !disableForms)}
                    />
                </div>
                <div className="add-tenant-block-pl">
                    <div className="panel-body-field-label">Spot Number (optional)</div>
                    {
                        this.props.requestType == RightPanelState.Add && 
                        <VirtualizeAutocomplete
                            className="cl-repeat-dropdown"
                            multiple={true}
                            value={this.props.leases[this.props.currentIndex].spots}
                            options={this.state.spots}
                            openOnFocus={true}
                            selectOnFocus={true}
                            getOptionLabel={(option) => option.friendlySpotId}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <div className="cl-dropdown-list">
                                        <Checkbox
                                            icon={<div className="repeat-b-check" />}
                                            checkedIcon={<div className="repeat-b-check selected" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                            color="primary"
                                        />
                                        {option.friendlySpotId}
                                    </div>
                                </React.Fragment>
                            )}
                            onChange={(e_: any, spots_: IBlockSpot[]) => {
                                let leases_ = this.state.leases;

                                if (spots_.length <= this.props.leases[this.props.currentIndex].numberOfPasses) {
                                    leases_[this.props.currentIndex].spots = spots_;

                                    this.setState({
                                        leases: leases_
                                    }, () => {
                                        if (this.props.onChange) {
                                            this.props.onChange(this.state.leases);
                                        }
                                    });
                                }
                            }}
                            renderInput={(params) => <TextField {...params}
                                placeholder="Select Spot"
                                variant="outlined"
                            />}
                            disabled={!disableForms}
                        />
                    }

                    {
                        this.props.requestType == RightPanelState.Edit && <VirtualizeAutocomplete
                            className="cl-repeat-dropdown"
                            value={this.getSelectedSpot()}
                            options={this.state.spots}
                            openOnFocus={true}
                            selectOnFocus={true}
                            getOptionLabel={(option) => option.friendlySpotId}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <div className="cl-dropdown-list">
                                        <Checkbox
                                            icon={<div className="repeat-b-check" />}
                                            checkedIcon={<div className="repeat-b-check selected" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                            color="primary"
                                        />
                                        {option.friendlySpotId}
                                    </div>
                                </React.Fragment>
                            )}
                            onChange={(e_: any, spot_: IBlockSpot | null) => {
                                let leases_ = this.state.leases;

                                if (spot_ != null) {
                                    leases_[this.props.currentIndex].spots = [spot_];
                                }

                                this.setState({
                                    leases: leases_
                                }, () => {
                                    if (this.props.onChange) {
                                        this.props.onChange(this.state.leases);
                                    }
                                });
                            }}
                            renderInput={(params) => <TextField {...params}
                                placeholder="Select Spot"
                                variant="outlined"
                                error={this.props.leases[this.props.currentIndex].spots.length == 0}
                            />}
                        />
                    }


                    {/* <AutoCompleteBlockSpots
                        onBlockSpotSelected={(values: IBlockSpot[]) => {
                            let leases_ = this.state.leases;
                            leases_[this.props.currentIndex].spots = values;

                            this.setState({
                                leases: leases_
                            }, () => {
                                if (this.props.onChange) {
                                    this.props.onChange(this.state.leases);
                                }
                            });
                        }}
                        spots={this.state.spots}
                        selectedSpot={this.props.leases[this.props.currentIndex].spots}
                        enableSelectAll={false}
                        onRefreshDone={(doneState: boolean, values: IBlockSpot[]) => {
                        }}
                    // disabled={this.props.requestType == RightPanelState.Edit}
                    /> */}
                </div>
                {/* <div className="add-tenant-block-pl">
                    <div className="panel-body-field-label">Licence Plate(s)</div>
                    <TextField
                        fullWidth
                        placeholder="example: ABC123, ABC456"
                        variant="outlined"
                        required={true}
                        margin="normal"
                        className="mt-0"
                        value={this.props.leases[this.props.currentIndex].licencePlate}
                        onChange={(e_: any) => {
                            let leases_ = this.state.leases;
                            leases_[this.props.currentIndex].licencePlate = e_.target.value;

                            this.setState({
                                leases: leases_
                            });
                        }}
                        autoComplete="off"
                        error={this.props.leases[this.props.currentIndex].licencePlate.trim().length == 0}
                        disabled={this.props.requestType == RightPanelState.Edit}
                    />
                </div> */}
            </React.Fragment>
        )
    }

    private zoneForProperty(leases_?: ITenantSpotDetails[], onload?: () => void) {
        // let zoneData = {
        //     "propertyGuid": this.state.selectedTenantBuilding?.propertyGuid,
        //     "zoneGuid": this.state.selectedZone?.zoneGuid,
        //     "startDate": this.state.filterDateRange.startDate,
        //     "endDate": this.state.filterDateRange.endDate
        // } as any;


        let zoneData = leases_ ? {
            "propertyGuid": leases_[this.props.currentIndex].tenantBuilding?.propertyGuid,
            "zoneGuid": leases_[this.props.currentIndex].zone?.zoneGuid,
            "startDate": leases_[this.props.currentIndex].filterDateRange.startDate,
            "endDate": leases_[this.props.currentIndex].filterDateRange.endDate
        } as any : {
            "propertyGuid": this.props.leases[this.props.currentIndex].tenantBuilding?.propertyGuid,
            "zoneGuid": this.props.leases[this.props.currentIndex].zone?.zoneGuid,
            "startDate": this.props.leases[this.props.currentIndex].filterDateRange.startDate,
            "endDate": this.props.leases[this.props.currentIndex].filterDateRange.endDate
        } as any;

        this._privateLotService.getPrivatlotSpots(zoneData)
            .then(r => r.json())
            .then(r => {
                let spots_: IBlockSpot[] = r;

                if (this.props.requestType == RightPanelState.Edit &&
                    this.props.leases.length > 0 &&
                    this.props.leases[this.props.currentIndex].spots.length > 0) {
                    spots_.push(this.props.leases[this.props.currentIndex].spots[0]);
                }
                if (leases_ && leases_.length > 0) {
                    leases_[this.props.currentIndex].spots = spots_.filter(s => s.spotGuid === this.props.selectedStuff.selectedSpotGuid)
                }

                spots_ = ArrayHelper.sortSpots(spots_);
                if (this.props.selectedStuff && leases_ && !this.props.selectedStuff.isYardiSyncedUnit) {
                    const filterDateRange = leases_[this.props.currentIndex].filterDateRange

                    this.setState({
                        leases: leases_,
                        disableForms: Object.keys(filterDateRange).length !== 0,
                        spots: spots_
                    }, () => {
                        if (this.props.onChange) {
                            this.props.onChange(this.state.leases);
                        }
                    });
                } else {
                    this.setState({
                        spots: spots_
                    },()=>{
                        onload && onload();
                    });

                }


            });
    }

    private getStartDate() {
        let startDate_ = this.props.leases[this.props.currentIndex].filterDateRange.startDate;

        if (!startDate_) {
            startDate_ = new Date();
        }

        return startDate_;
    }

    private getEndDate() {
        let endDate_ = this.props.leases[this.props.currentIndex].filterDateRange.endDate;

        if (!endDate_) {
            endDate_ = new Date();
        }

        return endDate_;
    }

    private getMinDate() {
        let minDate_ = this.props.leases[this.props.currentIndex].filterDateRange.startDate;
        let currentDate = new Date();

        if (!minDate_ || minDate_.getTime() < currentDate.getTime()) {
            minDate_ = new Date();
        }

        return minDate_;
    }

    private getSelectedSpot() {
        let spots_ = this.props.leases[this.props.currentIndex].spots;
        let selectedSpot_: IBlockSpot | null = null;

        if (spots_.length > 0) {
            let filtedSpot = this.state.spots.filter(x => x.friendlySpotId == spots_[0].friendlySpotId);
            if (filtedSpot.length > 0) {
                selectedSpot_ = filtedSpot[0];
            }
        }

        return selectedSpot_;
    }

    private getMinDateRange() {
        let currentDate = new Date();
        // return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    }
}

interface IAssignPropertyProps {
    properties: IPrivateLotProperty[];
    leases: ITenantSpotDetails[];
    currentIndex: number;
    onChange(leases_: ITenantSpotDetails[]): void;
    onDelete(index: number): void;
    requestType: RightPanelState;
    selectedStuff?: any;
    comefromFlag?: string;
}

interface IAssignPropertyState {
    selectedTenantBuilding: IPrivateLotProperty | null;
    zoneList: IPrivateLotZone[];
    selectedZone: IPrivateLotZone | null;
    selectedSpots: IBlockSpot[];
    filterDateRange: DateRange;
    spots: IBlockSpot[];
    numberOfPasses: number;
    licencePlate: string;
    leases: ITenantSpotDetails[];
    disableForms: boolean;
    selectedStuff: any
}
