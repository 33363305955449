import { Grid, TextField, Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { IPropertyBasics } from "../Spots/Interfaces";

export default class CalendarProperty extends React.Component<
  ICalendarPropertyProps,
  ICalendarPropertyState
> {
  private autoCompleteInput: any = null;
  constructor(props_: ICalendarPropertyProps) {
    super(props_);

    this.state = {
      selectedProperty: null,
      open: this.props.open,
    };
  }

  componentDidUpdate() {
    if (
      this.props.selectedProperty != null &&
      this.state.selectedProperty == null
    ) {
      this.setState({
        selectedProperty: this.props.selectedProperty,
        open: false,
      });
    }
  }

  componentDidMount() {
    if (this.autoCompleteInput && this.props.id === "blocker-property") {
      this.autoCompleteInput.querySelector("input").focus();
    }
  }

  render() {
    return (
      <React.Fragment>
        <Grid className="px-3">
          <Autocomplete
            open={this.state.open}
            ref={(input) => {
              this.autoCompleteInput = input;
            }}
            className="cal-prp-field"
            options={this.props.properties}
            getOptionLabel={(option_) => option_.propertyName}
            size="small"
            value={this.state.selectedProperty}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Property"
                style={{ backgroundColor: "none" }}
                onKeyDown={(event) => {
                  var charCode = event.which ? event.which : event.keyCode;
                  if (charCode === 13) {
                    if (this.props.onPropertyChange) {
                      this.props.onPropertyChange(this.state.selectedProperty);
                    }
                  }
                }}
                variant="outlined"
              />
            )}
            onChange={(e_: any, property_: IPropertyBasics | null) => {
              if (this.props.onPropertyChange && property_ == null) {
                if (this.props.id !== "blocker-property") {
                  e_.target.blur();
                }
                this.props.onPropertyChange(property_);
              }
              this.setState(
                {
                  selectedProperty: property_,
                },
                () => {
                  if (this.props.onPropertyChange) {
                    this.props.onPropertyChange(this.state.selectedProperty);
                  }
                }
              );
            }}
          />
        </Grid>
        <Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="mt-2 c-go-button"
            onClick={() => {
              if (this.props.onPropertyChange) {
                this.props.onPropertyChange(this.state.selectedProperty);
              }
            }}
            disabled={this.state.selectedProperty == null}
          >
            Go
          </Button>
        </Grid>
      </React.Fragment>
    );
  }
}

interface ICalendarPropertyProps {
  properties: IPropertyBasics[];
  onPropertyChange: (value: IPropertyBasics | null) => void;
  selectedProperty: IPropertyBasics | null;
  open?: boolean;
  id: string;
}

interface ICalendarPropertyState {
  selectedProperty: IPropertyBasics | null;
  open?: boolean;
}
