import BaseService from "./BaseService";
import * as CommonString from '../CommonString';
import { ConfigService } from '../Services/ConfigService';
import { GetUserDetailsService } from "./GetUserDetailsService";

export default class PropertyService extends BaseService {
    public static getPropertyList() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + CommonString.SERIVCE_PROPERTY_LIST, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
            })
        //.then(response => response.json());
    }

    public static getPropertyDropdown() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + CommonString.SERIVCE_GET_PROPERTY_DROPDOWN, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
            })
        // .then(response => response.json());
    }

    public static getUserDropDownList() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + CommonString.SERVICE_GET_USER_LIST, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
            })

    }

    public getPropertyOwners() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + CommonString.SERVICE_PROPERTY_OWNERS, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
            })

    }

    public getPropertiesBasics() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "property/names", {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public getAllPropertiesBasics() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "property/all/names", {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public getActivePropertiesBasics() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "property/active-all/names", {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public getPropertyDetails(propertyGuid_: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `property/detail/${propertyGuid_}`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public getPropertyInfo(propertyGuid_: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `property/GetPropertyInfo/${propertyGuid_}`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public getGetPropertyDashboardInformation(propertyGuid_: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `property/GetPropertyDashboardInformation/${propertyGuid_}`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public udatePropertyStatus(changes_: any) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + 'property/changestatus', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(changes_)
                })
            });
    }

    public checkPropertyReservation(propertyGuid_: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `property/property-reservation`, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(propertyGuid_)
                })
            });
    }

    public getPropertyActiveSpotInformation(propertyGuid_: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `property/GetPropertyActiveSpotInformation/${propertyGuid_}`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public getPropertyUpcomingSpotInformation(propertyGuid_: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `property/GetPropertyUpcomingSpotInformation/${propertyGuid_}`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public getPropertySpotHistoryInformation(propertyGuid_: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `property/GetPropertySpotHistoryInformation/${propertyGuid_}`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public getPropertySpotsInformation(propertyGuid_: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `property/GetPropertySpotsInformation/${propertyGuid_}`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public getDashboardUserSpotManagers(propertyGuid_: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `property/GetDashboardUserSpotManagers/${propertyGuid_}`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public getDashboardUserSpotOwner(propertyGuid_: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `property/GetDashboardUserSpotOwner/${propertyGuid_}`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public getDashboardUserReservation(propertyGuid_: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `property/GetDashboardUserReservation/${propertyGuid_}`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public getInvitationLink(propertyGuid_: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `property/invitation-link/${propertyGuid_}`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public getadmindashboard() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "property/getadmindashboard", {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public getmanagerdashboard() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "property/getmanagerdashboard", {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public gettenantdashboard() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "property/gettenantdashboard", {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public getAdminDashboardCities() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "property/AdminDashboardCities", {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public getManagerDashboardProperties() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "property/ManagerDashboardProperties", {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public getAdminDashboardWidgets(requestModel: any, requestName: string, hideLoader?: boolean) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + 'property/' + requestName, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestModel)
                }, 'widget', hideLoader)
            });
    }

    public getAdminDashboardWeeklyRevenue(requestModel: any) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + 'property/AdminDashboardWeeklyRevenueV3', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestModel)
                })
            });
    }

    public getAdminDashboardPropertyRevenue(requestModel: any, requestName: string, hideLoader?: boolean) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + 'property/AdminDashboardPropertyRevenueV3', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestModel)
                }, 'widget', hideLoader)
            });
    }

    public getManagerDashboardBookings(requestModel: any) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + 'property/ManagerDashboardBookingsV3', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestModel)
                })
            });
    }

    public getDailyTimeBlock() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + 'property/GetDailyTimeBlock', {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
            });
    }

    public updateDailyTimeBlock(changes_: any) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + 'property/UpdateDailyTimeBlock', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(changes_)
                })
            });
    }

    public getDailyTimeBlockWithPrice(propertyGuid_: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `property/GetDailyTimeBlockWithPrice/${propertyGuid_}`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
            });
    }

    public getWeeklyTimeBlockWithPrice(propertyGuid_: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `property/GetWeeklyTimeBlockWithPrice/${propertyGuid_}`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
            });
    }

    public getTimeZone(requestModel: any) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + 'property/get-timezone', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestModel)
                })
            });
    }

    public getPropertyImages(propertyGuid_: any) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `property/property-image/${propertyGuid_}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
            });
    }

    public GetAllCashProperty() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `spot/cash-booking/get-all-properties`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
            });
    }

    public GetDashboardOverview(dashboardRequest_: any) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `property/dashboard-overview`, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(dashboardRequest_)
                })
            });
    }
}