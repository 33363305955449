import { IconButton, TextField } from "@material-ui/core";
import React from "react";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import { Button } from "@material-ui/core";
import TodayIcon from '@material-ui/icons/Today';
import { format } from "date-fns";
import ClearIcon from '@material-ui/icons/Clear';
import DOMHelper from "../../Helpers/DOMHelper";
import { EnumCalendarOption } from "./interfaces";

export default class GrydDateRangePicker extends React.Component<IGrydDateRangePickerProps, IGrydDateRangePickerState> {
    private pickerRef = React.createRef<HTMLDivElement>();

    private dummyDate: DateRange = {
        startDate: new Date(),
        endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 10))
    }
    constructor(props_: IGrydDateRangePickerProps) {
        super(props_);

        this.state = {
            isCalendarOpened: false,
            selectedDate: "",
            blockDateRange: this.props.initialDateRange,
            disableRange: (this.props.disableRange == true) ? true : false,
            minDate: (this.props.minDate != null) ? this.props.minDate : new Date(new Date().setFullYear(new Date().getFullYear() - 25)),
            maxDate: (this.props.maxDate != null) ? this.props.maxDate : new Date(new Date().setFullYear(new Date().getFullYear() + 25)),
            calanderPos: "0px",
            elementId: `calender_${Math.floor(Math.random() * 899999999 + 100000000)}`,
            disableDateTF: this.props.disableDateTF ? this.props.disableDateTF : false,
            moreOptionValue: EnumCalendarOption.None
        }
    }

    componentDidMount() {
        window.addEventListener("click", (event: any) => {
            this.getCalendarDomElementValue(event);
        }, false);

        this.setState({
            selectedDate: this.getDateString(this.props.initialDateRange)
        })
    }

    render() {
        const style = this.props.noAutoPosition ? {top: 152} : { top: this.state.calanderPos };
        return (
            <div ref={this.pickerRef} className={'gryd-cl-view ' + ((this.state.disableRange == true) && 'remove-cl-range')} id={this.state.elementId}>
                <div className={'cl-block-input-c ' + (this.state.isCalendarOpened ? 'cl-block-range-calendar-focussed' : '')}>
                    <TodayIcon className="cl-icon" />
                    <TextField
                        disabled={this.state.disableDateTF}
                        className="cl-block-input cl-block-input-calendar"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        placeholder={this.props.placeHolder}
                        name="block_date"
                        value={this.state.selectedDate}
                        onFocus={(event: any) => {
                            event.target.setAttribute('autocomplete', 'off');
                            this.setState({
                                isCalendarOpened: true
                            }, () => {
                                this.getCalendarPosition();
                            })
                        }}
                        onChange={(event: any) => {
                            if (this.state.selectedDate != "" &&
                                event.target.value != this.state.selectedDate &&
                                event.target.value.length < this.state.selectedDate.length) {
                                this.setState({
                                    blockDateRange: {},
                                    isCalendarOpened: false,
                                    selectedDate: ""
                                }, () => {
                                    this.setState({
                                        isCalendarOpened: true
                                    }, () => {
                                        this.getCalendarPosition();
                                    })
                                })
                            }
                        }}
                        error={this.props.required == true && this.state.selectedDate == ""}
                        required={this.props.required != undefined ? this.props.required : true}
                    />
                    {
                        this.state.disableDateTF && (
                            <>
                            </>
                        ) || (
                            <IconButton aria-label="Back" className="cl-date-clear" disabled onClick={() => {
                                let tempDate: DateRange = {}
                                this.setState({
                                    blockDateRange: tempDate,
                                    isCalendarOpened: false,
                                    selectedDate: this.getDateString(tempDate)
                                }, () => {
                                    this.setState({
                                        isCalendarOpened: true
                                    }, () => {
                                        if (this.props.onDateChange) {
                                            this.props.onDateChange(this.state.blockDateRange);
                                        }
                                        this.getCalendarPosition();
                                    })
                                });
                            }}>
                                <ClearIcon />
                            </IconButton>
                        )
                    }

                </div>
                {
                    //This condition added for refresh calendar while clear
                    this.state.isCalendarOpened && <div className="cl-block-range-calendar" style={style}>
                        <div className="cl-calendar-table">
                            {
                                this.props.moreOption == true && <>
                                    <div className="cl-calendar-cl1">
                                        <div><Button className={'cl-btn-option ' + this.isOptionSelected(EnumCalendarOption.Today)} onClick={() => { this.updateDate(EnumCalendarOption.Today); }}>Today</Button></div>
                                        <div><Button className={'cl-btn-option ' + this.isOptionSelected(EnumCalendarOption.Yesterday)} onClick={() => { this.updateDate(EnumCalendarOption.Yesterday); }}>Yesterday</Button></div>
                                        <hr/>
                                        <div><Button className={'cl-btn-option ' + this.isOptionSelected(EnumCalendarOption.Last7Days)} onClick={() => { this.updateDate(EnumCalendarOption.Last7Days); }}>Last 7 days</Button></div>
                                        <div><Button className={'cl-btn-option ' + this.isOptionSelected(EnumCalendarOption.Last14Days)} onClick={() => { this.updateDate(EnumCalendarOption.Last14Days); }}>Last 14 days</Button></div>
                                        <div><Button className={'cl-btn-option ' + this.isOptionSelected(EnumCalendarOption.Last30Days)} onClick={() => { this.updateDate(EnumCalendarOption.Last30Days); }}>Last 30 days</Button></div>
                                        <div><Button className={'cl-btn-option ' + this.isOptionSelected(EnumCalendarOption.Last90Days)} onClick={() => { this.updateDate(EnumCalendarOption.Last90Days); }}>Last 90 days</Button></div>
                                        <div><Button className={'cl-btn-option ' + this.isOptionSelected(EnumCalendarOption.Last365Days)} onClick={() => { this.updateDate(EnumCalendarOption.Last365Days); }}>Last 365 days</Button></div>
                                        <div><Button className={'cl-btn-option ' + this.isOptionSelected(EnumCalendarOption.ThisMonth)} onClick={() => { this.updateDate(EnumCalendarOption.ThisMonth); }}>This month</Button></div>
                                        <div><Button className={'cl-btn-option ' + this.isOptionSelected(EnumCalendarOption.ThisYear)} onClick={() => { this.updateDate(EnumCalendarOption.ThisYear); }}>This year</Button></div>
                                        <hr/>
                                        <div><Button className={'cl-btn-option ' + this.isOptionSelected(EnumCalendarOption.PreviousMonth)} onClick={() => { this.updateDate(EnumCalendarOption.PreviousMonth); }}>Previous month</Button></div>
                                        <div><Button className={'cl-btn-option ' + this.isOptionSelected(EnumCalendarOption.PreviousYear)} onClick={() => { this.updateDate(EnumCalendarOption.PreviousYear); }}>Previous year</Button></div>
                                    </div>
                                </>
                            }

                            <div className="cl-calendar-cl2">
                                <DateRangePicker
                                    open={this.state.isCalendarOpened}
                                    toggle={() => {
                                        this.setState({
                                            isCalendarOpened: false,
                                            moreOptionValue: EnumCalendarOption.None
                                        })
                                    }}
                                    onChange={(range) => {
                                        this.setState({
                                            blockDateRange: range,
                                            moreOptionValue: EnumCalendarOption.None
                                        })
                                    }}
                                    initialDateRange={this.state.blockDateRange}
                                    minDate={this.state.minDate}
                                    maxDate={this.state.maxDate}
                                // minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 10))}
                                // maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
                                // initialDateRange={this.dummyDate}
                                />
                                <hr />
                                <div className="cl-flex cl-bottom-c">
                                    <div className="cl-flex cl-flex-cl">
                                        <div className="cl-flex-cl">
                                            <div className="cl-block-cl-input-disabled">{this.print("Start date", this.state.blockDateRange.startDate)}</div>
                                        </div>
                                        {
                                            (this.state.disableRange == false) && <div className="cl-flex-pd">&mdash;</div>
                                        }
                                        {
                                            (this.state.disableRange == false) && <div className="cl-flex-cl">
                                                <div className="cl-block-cl-input-disabled">{this.print("End date", this.state.blockDateRange.endDate)}</div>
                                            </div>
                                        }


                                    </div>
                                    <div className="cl-flex cl-flex-cl">
                                        <div className="cl-flex-cl">
                                            <Button
                                                className="cl-block-cl-button"
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="secondary"
                                                onClick={(event: any) => {
                                                    let tempDate: DateRange = {}
                                                    this.setState({
                                                        blockDateRange: tempDate,
                                                        isCalendarOpened: false,
                                                        moreOptionValue: EnumCalendarOption.None
                                                    }, () => {
                                                        this.setState({
                                                            isCalendarOpened: true
                                                        }, () => {
                                                            this.getCalendarPosition();
                                                        })
                                                    });
                                                }}
                                            >
                                                Clear
                                            </Button>
                                        </div>
                                        <div className="cl-flex-cl">
                                            <Button
                                                className="cl-block-cl-button"
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    this.setState({
                                                        blockDateRange: this.state.blockDateRange,
                                                        isCalendarOpened: false,
                                                        selectedDate: this.getDateString(this.state.blockDateRange, this.state.moreOptionValue)
                                                    }, () => {
                                                        if (this.props.onDateChange) {
                                                            this.props.onDateChange(this.state.blockDateRange);
                                                        }
                                                    })
                                                }}
                                            >
                                                Done
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }

    private print(alternateText: string, date?: Date){
        console.log(date);
        if (date?.toString() === "Invalid Date") {
            date = new Date();
        }
        return date != null ? format(date, "dd MMM yyyy") : alternateText;
    }

    private getCalendarDomElementValue(event: any) {
        var isFooterElem = event.target.closest('.cl-flex');
        // var isDateClicked = event.target.closest('.materialui-daterange-picker-makeStyles-daysContainer-46');
        // var isElementExists = document.getElementsByClassName("materialui-daterange-picker-MuiTypography-root-7");
        var isDateClicked = event.target.closest('[class*="materialui-daterange-picker-makeStyles-daysContainer]');
        var isElementExists = document.querySelectorAll('[class*="materialui-daterange-picker-MuiTypography-root"]');
        if (this.state.isCalendarOpened && isFooterElem == null && isElementExists.length > 0) {
            var startDateText = isElementExists[0].innerHTML;
            var EndDateText = isElementExists[1].innerHTML;

            var startDate = this.getChangedDate(startDateText);
            var endDate = this.getChangedDate(EndDateText);

            if (startDate?.toString() === "Invalid Date" && endDate?.toString() === "Invalid Date" && Array.from(isElementExists).filter(x => x.localName === 'h6').length > 1) {
                var newIsElementExists = Array.from(isElementExists).filter(x => x.localName === 'h6');
                startDateText = newIsElementExists[0].innerHTML;
                startDate = this.getChangedDate(startDateText);
                EndDateText = newIsElementExists[1].innerHTML;
                endDate = this.getChangedDate(EndDateText);
            }

            let tempDate: DateRange = {}

            if (startDate != null) {
                tempDate.startDate = startDate;

                if (this.state.disableRange) {
                    tempDate.endDate = startDate;
                }
            }

            if (endDate != null) {
                tempDate.endDate = endDate;
            }

            this.setState({
                blockDateRange: tempDate,
                isCalendarOpened: (this.state.disableRange && isDateClicked != null) ? false : this.state.isCalendarOpened
            }, () => {
                this.getCalendarPosition();
                if (this.state.disableRange && isDateClicked != null) {
                    this.setState({
                        isCalendarOpened: true
                    }, () => {
                        this.getCalendarPosition();
                    })
                }
            });
        }
    }

    private updateNavForSignleView() {
        if (document.querySelectorAll(".materialui-daterange-picker-makeStyles-icon-48").length > 0 && this.state.disableRange == true) {
            let buttonElm = document.querySelectorAll(".materialui-daterange-picker-makeStyles-icon-48")[3];
            if (buttonElm instanceof HTMLElement) {
                buttonElm.click();
            }
        }

        /*if (document.querySelectorAll(".materialui-daterange-picker-makeStyles-icon-48").length > 0 && this.state.disableRange == true) {
            let buttonElm1 = document.querySelectorAll(".materialui-daterange-picker-makeStyles-icon-48")[0] as HTMLElement;
            buttonElm1.click();
            setTimeout(() => {
                buttonElm1.click();
                setTimeout(() => {
                    let buttonElm2 = document.querySelectorAll(".materialui-daterange-picker-makeStyles-icon-48")[2] as HTMLElement;
                    buttonElm2.click();
                }, 100);
            }, 100)
        }*/
    }

    private getChangedDate(inputStartDateString: string) {
        if (inputStartDateString.indexOf("Date") == -1) {
            return new Date(inputStartDateString);
        }

        return null;
    }

    private getDateString(dates_: DateRange, input?: EnumCalendarOption) {
        let dateString: string = "";

        if (dates_.startDate && dates_.endDate && dates_.startDate.toString() !== "Invalid Date"  && dates_.endDate.toString() !== "Invalid Date") {
            if (this.state.disableRange) {
                return `${format(dates_.startDate, "dd MMM yyyy")}`;
            } 
            else {                
                const { moreOption, enableMoreOptionText } = this.props;
                if (moreOption && enableMoreOptionText) {
                    input = moreOption && enableMoreOptionText ? this.isTodayDate(dates_) ? EnumCalendarOption.Today : input : EnumCalendarOption.None;
                    switch (input) {
                        case EnumCalendarOption.Today: {
                            dateString = `Today (${format(dates_.endDate, "MMM dd, yyyy")})`;
                            break;
                        }
                        case EnumCalendarOption.Yesterday: {
                            dateString = `Yesterday (${format(dates_.endDate, "MMM dd, yyyy")})`;
                            break;
                        }
                        case EnumCalendarOption.Last7Days: {
                            dateString = `Last 7 days (${format(dates_.endDate, "MMM dd, yyyy")} - Today)`;
                            break;
                        }
                        case EnumCalendarOption.Last14Days: {
                            dateString = `Last 14 days (${format(dates_.endDate, "MMM dd, yyyy")} - Today)`;
                            break;
                        }
                        case EnumCalendarOption.Last30Days: {
                            dateString = `Last 30 days (${format(dates_.endDate, "MMM dd, yyyy")} - Today)`;
                            break;
                        }
                        case EnumCalendarOption.Last90Days: {
                            dateString = `Last 90 days (${format(dates_.endDate, "MMM dd, yyyy")} - Today)`;
                            break;
                        }
                        case EnumCalendarOption.Last365Days: {
                            dateString = `Last 365 days (${format(dates_.endDate, "MMM dd, yyyy")} - Today)`;
                            break;
                        }
                        case EnumCalendarOption.ThisMonth: {
                            dateString = `This month (${format(dates_.endDate, "MMM dd, yyyy")} - Today)`;
                            break;
                        }
                        case EnumCalendarOption.ThisYear: {
                            dateString = `This year (${format(dates_.startDate, "MMM dd, yyyy")} - ${format(dates_.endDate, "MMM dd, yyyy")} - Today)`;
                            break;
                        }
                        case EnumCalendarOption.PreviousMonth: {
                            dateString = `Previous month (${format(dates_.startDate, "MMM dd, yyyy")} - ${format(dates_.endDate, "MMM dd, yyyy")})`;
                            break;
                        }
                        case EnumCalendarOption.PreviousYear: {
                            dateString = `Previous year (${format(dates_.startDate, "MMM dd, yyyy")} - ${format(dates_.endDate, "MMM dd, yyyy")})`;
                            break;
                        }
                        default: {
                            dateString = `${format(dates_.startDate, "dd MMM yyyy")} - ${format(dates_.endDate, "dd MMM yyyy")}`;
                            break;
                        }
                    }
                }
                else{
                    return `${format(dates_.startDate, "dd MMM yyyy")} - ${format(dates_.endDate, "dd MMM yyyy")}`;
                }
            }
        }

        return dateString;
    }

    private getCalendarPosition() {
        if (this.pickerRef && this.pickerRef.current) {
            let wh = window.innerHeight;
            let oTop = this.pickerRef.current.offsetTop;
            let parentYpos = wh;
            let calenderHeight = this.pickerRef.current.children[1].clientHeight

            parentYpos = DOMHelper.getCumulativeScrollTop(this.pickerRef.current);
            
            let pos = (oTop - parentYpos + this.pickerRef.current.clientHeight);

            if ((pos + calenderHeight) > wh) {
                pos -= (this.pickerRef.current.clientHeight + calenderHeight + 10);
            }

            this.setState({
                calanderPos: (pos) + "px"
            })
        }
        // return (oTop)+"px";
    }

    private updateDate(input: EnumCalendarOption) {
        let tempDate: DateRange = {}
        let isUpdated: boolean = false;

        var startDate = new Date();
        var endDate = new Date();

        var substract_no_of_days = 0;

        switch(input){
            case EnumCalendarOption.Today: {
                isUpdated = true;
                break;
            } 
            case EnumCalendarOption.Yesterday: {
                isUpdated = true;
                substract_no_of_days = 1;
                endDate.setTime(endDate.getTime() - substract_no_of_days * 24 * 60 * 60 * 1000);
                break;
            } 
            case EnumCalendarOption.Last7Days: {
                isUpdated = true;
                substract_no_of_days = 6;
                break;
            }
            case EnumCalendarOption.Last14Days: {
                isUpdated = true;
                substract_no_of_days = 13;
                break;
            }
            case EnumCalendarOption.Last30Days :{
                isUpdated = true;
                substract_no_of_days = 29;
                break;
            } 
            case EnumCalendarOption.Last90Days :{
                isUpdated = true;
                substract_no_of_days = 89;
                break;
            } 
            case EnumCalendarOption.Last365Days :{
                isUpdated = true;
                substract_no_of_days = 364;
                break;
            }
            case EnumCalendarOption.ThisMonth : {
                isUpdated = true;
                startDate.setDate(1);
                break;
            }
            case EnumCalendarOption.ThisYear: {
                isUpdated = true;
                startDate = new Date(startDate.getFullYear(), 0, 1);
                break;
            }
            case EnumCalendarOption.PreviousMonth: {
                isUpdated = true;
                let date_ = new Date(startDate.setMonth(startDate.getMonth() - 1));
                startDate = new Date(date_.getFullYear(), date_.getMonth(), 1);
                endDate = new Date(date_.getFullYear(), date_.getMonth() + 1, 0);
                break;
            } 
            case EnumCalendarOption.PreviousYear: {
                isUpdated = true;
                startDate = new Date(startDate.getFullYear() - 1, 0, 1);
                endDate = new Date(startDate.getFullYear(), 11, 31);
            }
        }

        if (isUpdated) {
            if (substract_no_of_days != 0) {
                startDate.setTime(startDate.getTime() - substract_no_of_days * 24 * 60 * 60 * 1000);
            }

            tempDate.startDate = startDate;
            tempDate.endDate = endDate;

            this.setState({
                blockDateRange: tempDate,
                isCalendarOpened: false,
                selectedDate: this.getDateString(tempDate, input),
                moreOptionValue: input
            }, () => {
                if (this.props.onDateChange) {
                    this.props.onDateChange(this.state.blockDateRange);
                }

                // this.getCalendarPosition();
            })
        }
    }

    private isOptionSelected(input: EnumCalendarOption) {
        let isUpdated: boolean = false;
        let isMatched: boolean = false;

        var startDate = new Date();
        var endDate = new Date();

        var substract_no_of_days = 0;

        switch(input){
            case EnumCalendarOption.Today: {
                isUpdated = true;
                break;
            } 
            case EnumCalendarOption.Yesterday: {
                isUpdated = true;
                substract_no_of_days = 1;
                endDate.setTime(endDate.getTime() - substract_no_of_days * 24 * 60 * 60 * 1000);
                break;
            } 
            case EnumCalendarOption.Last7Days: {
                isUpdated = true;
                substract_no_of_days = 6;
                break;
            }
            case EnumCalendarOption.Last14Days: {
                isUpdated = true;
                substract_no_of_days = 13;
                break;
            }
            case EnumCalendarOption.Last30Days :{
                isUpdated = true;
                substract_no_of_days = 29;
                break;
            } 
            case EnumCalendarOption.Last90Days :{
                isUpdated = true;
                substract_no_of_days = 89;
                break;
            } 
            case EnumCalendarOption.Last365Days :{
                isUpdated = true;
                substract_no_of_days = 364;
                break;
            }
            case EnumCalendarOption.ThisMonth : {
                isUpdated = true;
                startDate.setDate(1);
                break;
            }
            case EnumCalendarOption.ThisYear: {
                isUpdated = true;
                startDate = new Date(startDate.getFullYear(), 0, 1);
                break;
            }
            case EnumCalendarOption.PreviousMonth: {
                isUpdated = true;
                let date_ = new Date(startDate.setMonth(startDate.getMonth() - 1));
                startDate = new Date(date_.getFullYear(), date_.getMonth(), 1);
                endDate = new Date(date_.getFullYear(), date_.getMonth() + 1, 0);
                break;
            } 
            case EnumCalendarOption.PreviousYear: {
                isUpdated = true;
                startDate = new Date(startDate.getFullYear() - 1, 0, 1);
                endDate = new Date(startDate.getFullYear(), 11, 31);
            }
        }
        if (isUpdated) {
            if (substract_no_of_days != 0) {
                startDate.setTime(startDate.getTime() - substract_no_of_days * 24 * 60 * 60 * 1000);
            }

            if (this.state.blockDateRange.startDate != null && this.state.blockDateRange.endDate != null) {
                if (this.state.blockDateRange.startDate.toLocaleDateString() == startDate.toLocaleDateString() &&
                    this.state.blockDateRange.endDate.toLocaleDateString() == endDate.toLocaleDateString()) {
                    isMatched = true;
                }
            }
        }

        if (isMatched && this.state.moreOptionValue === EnumCalendarOption.None) {
            this.setState({ moreOptionValue: input });
        }

        return isMatched ? "cl-matched" : "not-matched";
    }

    private isTodayDate(dates_: DateRange) {
        var todaysDate = new Date();
        
        if (dates_?.startDate && dates_.startDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0) &&
            dates_?.endDate && dates_.endDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
            return true;
        }

        return false;
    }
}

interface IGrydDateRangePickerProps {
    onDateChange: (dates_: DateRange) => void;
    initialDateRange: DateRange;
    disableRange?: boolean;
    placeHolder: string;
    minDate?: Date | null;
    maxDate?: Date | null;
    required?: boolean;
    disableDateTF?: boolean;
    moreOption?: boolean;
    enableMoreOptionText?: boolean;
    noAutoPosition?: boolean;
}

interface IGrydDateRangePickerState {
    isCalendarOpened: boolean;
    selectedDate: string;
    blockDateRange: DateRange;
    disableRange: boolean;
    minDate: Date;
    maxDate: Date;
    calanderPos: string;
    elementId: string;
    disableDateTF: boolean;
    moreOptionValue: EnumCalendarOption;
}