import React from "react";
import { Button } from "@material-ui/core";
import { IProperty } from "../../Properties/Interfaces";
import { IPropertyBasics } from "../../Spots/Interfaces";

export default class ConfigurationDetails extends React.Component<IConfigurationDetailsProps, IConfigurationDetailsState> {
    constructor(props: IConfigurationDetailsProps) {
        super(props);
        this.state = {
            defaultPasses: this.props.defaultPasses
        }
    }

    componentDidUpdate(prevProps: Readonly<IConfigurationDetailsProps>) {
        const { defaultPasses } = prevProps;
        if (defaultPasses !== this.props.defaultPasses) {
            this.setState({
                defaultPasses: this.props.defaultPasses
            });
        }
    }

    render() {
        return (
            <>
                <div className="right-panel-subcontainer">
                    <div className="c-rp-c">
                        <div className="sp-le-c-10-R">
                            <h5>Configure Parking</h5>
                            <div>
                                Default number of parking passes for all units is <b>{this.props.defaultPasses}</b>
                            </div>
                            <div>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className='bl-s-button mt-4'
                                    onClick={(e) => {
                                        this.props.selectedProperty && this.props.configureParking();
                                    }}
                                    disabled={!this.props.selectedProperty}
                                >
                                    Change
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

interface IConfigurationDetailsProps {
    defaultPasses: number,
    configureParking: () => void,
    selectedProperty: IPropertyBasics | null
}

interface IConfigurationDetailsState {
    defaultPasses: number
}
