import React from "react";
import { Button, Modal, Fade, Backdrop } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { FileDrop } from "react-file-drop";
import ClearIcon from '@material-ui/icons/Clear';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PlaceHolderImage from "../../Images/placeholder.jpg";
import "./BasicComponents.css";

export default class FileUploadModal extends React.Component<IFileUploadModalProps, IFileUploadModalState> {

    constructor(props_: IFileUploadModalProps) {
        super(props_);

        this.state = {
            files: this.props.filesCache ? this.props.filesCache : [],
            selectedFiles: this.props.selectedFileIds ? this.props.selectedFileIds : []
        };
    }

    render() {
        return (
            <Modal
                className="file-upload-modal"
                open={this.props.isOpen}
                onClose={this.props.onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={this.props.isOpen}>
                    <div className="file-upload-modal-box d-flex flex-column">
                        <FileDrop
                            className="file-dropbox flex-grow-1"
                            onDrop={(files: FileList) => this._onFilesUpload(files)}
                        >
                            <div className="dropbox-content text-center">
                                Drag and drop {this.props.descriptor} here or click <br />
                                <Button
                                    className="btn-file-upload mt-1"
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                >
                                    <label className="full-size" htmlFor="file-upload"></label>
                                    <span>Upload</span>
                                </Button>
                                <input
                                    id="file-upload"
                                    className="hidden"
                                    type="file"
                                    accept={this.props.filter}
                                    multiple={true}
                                    onChange={event => {
                                        let inputControl = event.target as HTMLInputElement;
                                        if (inputControl) {
                                            this._onFilesUpload(inputControl.files);
                                        }
                                    }}
                                />
                            </div>

                        </FileDrop>
                        {
                            this.state.files.length != 0 &&
                            <div className="mt-2">
                                Recent uploads
                                <Row className="file-list">
                                    {
                                        this.state.files.map(f => {
                                            return (
                                                <Col md={4} className="my-2">
                                                    <div
                                                        className="file-preview"
                                                        style={{
                                                            backgroundImage: `url(${f.thumbnailUrl})`
                                                        }}>
                                                        <Button
                                                            className="btn-select-file"
                                                            onClick={() => this._onSelectFile(f)}
                                                        >
                                                            {
                                                                this.state.selectedFiles.indexOf(f.internalUid) > -1 ?
                                                                    <CheckBoxIcon htmlColor="#ffffff" />
                                                                    : <CheckBoxOutlineBlankIcon htmlColor="#ffffff" />
                                                            }
                                                        </Button>
                                                        <div className="file-details w-100 d-flex">
                                                            <span className="file-name flex-grow-1" title={f.fileName}>{f.fileName}</span>
                                                            <Button
                                                                className="btn-remove-file"
                                                                onClick={() => this._onRemoveFile(f)}
                                                            >
                                                                <ClearIcon htmlColor="#ffffff" />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })
                                    }
                                </Row>
                            </div>
                        }

                        <div className="mt-2 file-upload-buttons text-right">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    this.props.onDone(this.state.selectedFiles);
                                    this.props.onClose();
                                }}
                            >Done</Button>
                            <Button
                                className="ml-3"
                                onClick={this.props.onClose}
                            >Cancel</Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        )
    }

    private _onFilesUpload = (files_: FileList | null) => {
        if (files_ && files_.length) {
            let existingFiles = this.state.files;
            let selectedFiles = this.state.selectedFiles;
            let isFirstFile: boolean = true;
            let filesYetToLoad = files_.length;

            let allFilesUploadComplete = () => {
                this.setState({
                    files: existingFiles,
                    selectedFiles: selectedFiles
                });
            }

            for (let f = 0; f < files_.length; f++) {
                let file = files_[f];
                let fileName = file.name;
                let extensionStartIndex = fileName.lastIndexOf('.');
                let extension = fileName.substr(extensionStartIndex).toLowerCase();
                let allowedExtensions = this.props.filter.split(',');

                if (allowedExtensions.indexOf(extension) > -1) {
                    let fileUrl = URL.createObjectURL(file);
                    let thumbnailUrl = file.type.indexOf("image/") == 0 ? fileUrl : PlaceHolderImage;

                    let fileInfo: IFileInfo = {
                        internalUid: new Date().getTime() + "+" + fileName,
                        fileContent: file,
                        fileName: fileName,
                        extension: extension,
                        fileUrl: fileUrl,
                        thumbnailUrl: thumbnailUrl
                    };
                    let addThisFile = () => {
                        this.props.onUpload(fileInfo);
                        existingFiles.push(fileInfo);

                        if (isFirstFile) {
                            selectedFiles.push(fileInfo.internalUid);
                        }
                        else if (!this.props.single) {
                            selectedFiles.push(fileInfo.internalUid);
                        }

                        isFirstFile = false;

                        if (--filesYetToLoad == 0) {
                            allFilesUploadComplete();
                        }
                    }

                    if (this.props.interceptUpload) {
                        this.props.interceptUpload(fileInfo, (isAccepted_: boolean) => {
                            if (isAccepted_) {
                                addThisFile();
                            }
                            else if (--filesYetToLoad == 0) {
                                allFilesUploadComplete();
                            }
                        });
                    }
                    else {
                        addThisFile();
                    }
                }
                else if (this.props.onUploadRejected) {
                    this.props.onUploadRejected(fileName, extension);
                    if (--filesYetToLoad == 0) {
                        allFilesUploadComplete();
                    }
                }
            }


        }
    }

    private _onSelectFile = (fileInfo_: IFileInfo) => {
        let selectedImages = this.state.selectedFiles;
        let iuidIndex = selectedImages.indexOf(fileInfo_.internalUid);
        if (iuidIndex > -1) {
            selectedImages.splice(iuidIndex, 1);
        }
        else {
            if (this.props.single) {
                selectedImages.splice(0);
            }

            selectedImages.push(fileInfo_.internalUid);
        }
        this.setState({
            selectedFiles: selectedImages
        });
    }

    private _onRemoveFile = (fileInfo_: IFileInfo) => {
        this.props.onRemove(fileInfo_);

        let files = this.state.files;
        let fileIndex = files.indexOf(fileInfo_);
        files.splice(fileIndex, 1);

        this.setState({
            files: files
        });
    }
}

interface IFileUploadModalProps {
    isOpen: boolean;
    descriptor?: JSX.Element[];
    filter: string;

    single?: boolean;
    filesCache?: IFileInfo[];
    selectedFileIds?: string[];

    onDone: (selectedFileIds_: string[]) => void;
    onClose: () => void;
    onUpload: (fileInfo_: IFileInfo) => void;
    onRemove: (fileInfo_: IFileInfo) => void;
    interceptUpload?: (file_: IFileInfo, callback_: (accept_: boolean) => void) => void;
    onUploadRejected?: (fileName_: string, fileExtension_: string) => void;
}

interface IFileUploadModalState {
    files: IFileInfo[];
    selectedFiles: string[];
}

export interface IFileInfo {
    internalUid: string;
    fileUrl: string;
    thumbnailUrl: string;
    optimizedFileUrl?: string;
    fileName: string;
    extension: string;

    storedFileGuid?: string;
    fileContent?: Blob;
}