import React from "react";
import {Link} from "@material-ui/core";
import EnforcementService from "../../Services/EnforcementService";
import Trafficimg from "../../Images/traffic-img.png";
import Offenceimg from "../../Images/offence-img.png";
import EnabledMarker from "../../Images/EnabledMarker.png";
import {IScanHistoryResponse} from "./interface";
import {RightPanelState} from "../../Models/CommonInterfaces";


export default class EnforcementDetails extends React.Component<IEnforcementDetailsProps, IEnforcementDetailsState> {

  private _enforcementService: EnforcementService;

  constructor(props_: IEnforcementDetailsProps) {
    super(props_);

    this.state = {
      propertyGuid: props_.propertyGuid,
      parkingStatus: {
        startDate: "",
        endDate: "",
        availableSpotCount: 0,
        bookingCount: 0,
        blockCount: 0
      },
      latestScanResult: null
      //doughnutChartPlugins:[]
    };
    this._enforcementService = new EnforcementService();
  }


  componentDidMount() {
    this.fetchParkingStatus();
  }

  componentDidUpdate(prevProps: IEnforcementDetailsProps) {
    const {propertyGuid} = this.props;
    if (propertyGuid !== prevProps.propertyGuid) {
      this.fetchParkingStatus();
    }
  }

  fetchParkingStatus = () => {
    const {propertyGuid} = this.props;
    // console.log(propertyGuid)
    if (!propertyGuid || propertyGuid === "00000000-0000-0000-0000-000000000000" || propertyGuid === "blank") {
      this.defaultParkingStatus();
    } else {
      this._enforcementService.GetPropertyParkingStatus(propertyGuid)
        .then(r => {
          this.fetchParkingStatusSuccess(r);
        });

      this._enforcementService.GetLatestScanResult(propertyGuid, "MMMM dd, yyyy 'at' h:mm tt")
        .then(r => {
          this.latestScanResultSuccess(r);
        });
    }
  }

  fetchParkingStatusSuccess = (response: Response) => {
    if (response.ok) {
      response.text().then(resp => {
        const parkingStatus: IEnforcementParkingStatus = JSON.parse(resp);
        this.setState({parkingStatus});
      })
    } else {
      this.defaultParkingStatus();
    }
  }

  latestScanResultSuccess = (latestScanResultResponse: Response) => {
    if (latestScanResultResponse.ok) {
      latestScanResultResponse.json().then(response => {
        this.setState({latestScanResult: response});
      });
    }
  }

  defaultParkingStatus = () => {
    this.setState({
      parkingStatus: {
        startDate: "",
        endDate: "",
        availableSpotCount: 0,
        bookingCount: 0,
        blockCount: 0
      },
      latestScanResult: null
    });
  }

  render() {
    const {parkingStatus, latestScanResult} = this.state;
    const {propertyGuid} = this.props;
    return (
      <>
        {this.props.isMultiSelectedProperty && (
          <div className="multi-select-blocker"></div>
        )}
        <div className="right-panel-subcontainer">
          <div className="c-rp-c">
            <div className="ma-pa-bo">
              <h5>Parking Stats</h5>
              <div>
                <div><b>{parkingStatus.availableSpotCount}</b>&nbsp;&nbsp;Available Spots</div>
                <div><b>{parkingStatus.bookingCount}</b>&nbsp;&nbsp;Booked Spots</div>
                <div><b>{parkingStatus.blockCount}</b>&nbsp;&nbsp;Blocked Spots</div>
              </div>
            </div>
            <div className="ma-pa-bo">
              <div>
                <div><img src={Offenceimg} alt="High Parking Offences Area" className="img-size"/><b>&nbsp;&nbsp;High
                  Parking Offences Area</b></div>
                <div className="mt-3"><img src={Trafficimg} alt="High Traffic Area"
                                           className="img-size"/><b>&nbsp;&nbsp;High Traffic Area</b></div>
                <div className="mt-3"><img src={EnabledMarker} alt="Selected Location"
                                           className="img-size"/><b>&nbsp;&nbsp;Selected Location</b></div>
              </div>
            </div>
            <div className="ma-pa-bo">
              <h5>Scan History</h5>
              <div>
                <div>{latestScanResult?.lastEnforcementScanDate ? latestScanResult?.lastEnforcementScanDate.replace("AM", "a.m.").replace("PM", "p.m.") : "No scan history"}</div>
                {latestScanResult?.lastEnforcementScanDate &&
                    <div className="text-right"><Link href="javascript:void(0)" onClick={() => {
                      this.props.showScanHistoryDetails((propertyGuid ?? "00000000-0000-0000-0000-000000000000"), RightPanelState.ScanHistory);
                    }} underline="always">More Details</Link></div>}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

interface IEnforcementDetailsProps {
  propertyGuid?: string;
  isMultiSelectedProperty: boolean;
  onRefreshed: (state: boolean) => void;
  refresh: boolean;

  showScanHistoryDetails: (propertyGuid: string, rightPannelState?: RightPanelState) => void;
}

interface IEnforcementDetailsState {
  propertyGuid?: string;
  parkingStatus: IEnforcementParkingStatus,
  latestScanResult: IScanHistoryResponse | null
}

export interface IEnforcementParkingStatus {
  propertyGuid?: string,
  startDate: string;
  endDate: string;
  availableSpotCount: 0;
  bookingCount: 0;
  blockCount: 0;
}
