import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow, Polygon } from 'google-maps-react';
import Geocode from "react-geocode";
import { Zoom } from '@material-ui/core';

const mapStyles = {
    width: '100%',
    height: '100%',
    position: 'relative'
};

// const APIKey: string = 'AIzaSyAYh_xGbTtZFJPDYZIdP-VgLatTxsuNpyM';
// const APIKey: string = 'AIzaSyCCDy8YDSCkQHHz0yoFderwJRLaY3q99kA';
const APIKey: string = 'AIzaSyDWNcJWEmXguvxEy3QmtoN3I5bzceYGm1s';

export class GoogleMaps extends React.Component<IGoogleMapsProps, IGoogleMapsState> {

    constructor(props: IGoogleMapsProps) {
        super(props);
        console.log(this.props)
        this.state = {
            showingInfoWindow: false,
            activeMarker: undefined,
            selectedPlace: undefined,
            latitude: 0,
            longitude: 0
        }

    }

    componentDidMount() {
        if (this.props.address != null && this.props.address != '') {
            this.fromAddressToLatLng(this.props.address);
        }
    }

    componentDidUpdate(oldProps_: IGoogleMapsProps) {
        if (this.props.address != oldProps_.address) {
            this.fromAddressToLatLng(this.props.address);
        }
    }

    render() {
        if (this.state.latitude == 0) return null;
        // console.log('Rendered: ' + this.state.latitude + ',' + this.state.longitude);
        return (
            <Map
                google={this.props.google}
                style={mapStyles}
                initialCenter={{
                    // lat: -1.2884,
                    // lng: 36.8233
                    lat: this.state.latitude,
                    lng: this.state.longitude
                    // lat: 13.019535,
                    // lng: 80.117234
                }}
                centerAroundCurrentLocation={false}
                visible={true}
            >
                <Marker
                    onClick={this.onMarkerClick} />
            </Map>
        );
    }

    private onMarkerClick(props: any, marker: any, e: any) {
        console.log(props);
        /*this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });*/
    }

    private onClose(props: any) {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    }

    private fromAddressToLatLng(address: string) {
        // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
        Geocode.setApiKey(APIKey);

        // set response language. Defaults to english.
        Geocode.setLanguage("en");

        // set response region. Its optional.
        // A Geocoding request with region=es (Spain) will return the Spanish city.
        Geocode.setRegion("es");

        // Enable or disable logs. Its optional.
        Geocode.enableDebug();
        Geocode.fromAddress(address).then(
            response => {
                const { lat, lng } = response.results[0].geometry.location;
                this.setState({
                    latitude: lat,
                    longitude: lng
                })
                // console.log(lat, lng);
            },
            error => {
                console.error(error);
            }
        );
    }
}

interface IGoogleMapsProps {
    google: any,
    address: string
}

interface IGoogleMapsState {
    showingInfoWindow: boolean,
    activeMarker: any,
    selectedPlace: any,
    latitude: any,
    longitude: any
}


export default GoogleApiWrapper({
    apiKey: APIKey
})(GoogleMaps);