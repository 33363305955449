import React from "react";
import ScheduleComponent, { IScheduleLimitation, ISingleScheduleData } from "./ScheduleComponent";
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Button } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Row, Col } from "reactstrap";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ShowAlertwithConfirm } from "../Common/ShowAlert";
import "./WeekDaySelector.css";
import { getScheduleSummaryText } from "../Pages/Spots/Interfaces";
import NumberHelper from "../Helpers/NumberHelper";

export default class MultiScheduleComponent extends React.Component<IMultiScheduleComponentProps, IMultiScheduleComponentState> {
    private _defaultSelectedDays = [1, 2, 3, 4, 5, 6, 7];
    private _defaultSelectedTimeStart = 0;
    private _defaultSelectedTimeEnd = 1439;
    private _defaultSelectedPrice = 3;

    constructor(props_: IMultiScheduleComponentProps) {
        super(props_);

        if (props_.editOnly) {
            this._defaultSelectedDays.splice(0); // No need to select any default days in edit only mode (no new entries)
        }

        if (props_.schedules) {
            for (let s of props_.schedules) {
                s._internalId = Math.random()
            }

            this.state = {
                schedules: props_.schedules
            };
        }
        else {
            this.state = {
                schedules: [this._getDefaultNewScheduleData()]
            };
        }

        this._defaultSelectedDays.splice(0); // Just select all days for the first schedule entry
    }

    componentDidUpdate(oldProps_: IMultiScheduleComponentProps) {
        if (oldProps_.limitation != this.props.limitation && this.props.limitation != undefined) {
            this._defaultSelectedTimeStart = this.props.limitation.minTime;
            this._defaultSelectedTimeEnd = this.props.limitation.maxTime;
            this._defaultSelectedPrice = this.props.limitation.minPrice;
        }
        if (oldProps_.schedules != this.props.schedules) {
            this.setState({
                schedules: this.props.schedules ? this.props.schedules : [this._getDefaultNewScheduleData()]
            });
        }
    }

    render() {
        return (
            <div>
                {
                    this.state.schedules.map((s, i) => {
                        return (
                            <ExpansionPanel
                                key={s._internalId}
                                className={"schedule-panel m-0 p-0" + (s._hasConflict ? " error" : "")}
                                square={true}
                                defaultExpanded={!this.props.defaultCollapsed}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    {this._getScheduleSummary(s)}
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <ScheduleComponent
                                        className="m-auto"
                                        singlePrice={true}
                                        limitation={this.props.limitation}
                                        selectedDays={s.selectedDays}
                                        selectedTimeRange={s.selectedTimeRange}
                                        selectedPriceRange={s.selectedPriceRange}
                                        onChange={(state_: ISingleScheduleData) => {
                                            let schedules = this.state.schedules;
                                            schedules[i] = state_;
                                            this.setState({
                                                schedules: schedules
                                            });
                                            this._onScheduleChange(schedules);
                                        }}
                                        priceDisabled={this.props.priceDisabled}
                                        hoursDisabled={this.props.hoursDisabled}
                                    />
                                    <div className="multi-schedule-right-panel">
                                        {
                                            (this.props.editOnly !== true && this.state.schedules.length > 1) && !this.props.addDeleteDisabled &&
                                            <Button onClick={() => {
                                                ShowAlertwithConfirm("Delete", "Are you sure you want to delete this schedule?", "warning")
                                                    .then(agreed_ => {
                                                        if (agreed_) {
                                                            let schedules = this.state.schedules;
                                                            schedules.splice(i, 1);
                                                            this.setState({
                                                                schedules: schedules
                                                            });
                                                            this._onScheduleChange(schedules);
                                                        }
                                                    });
                                            }}>
                                                <DeleteForeverIcon htmlColor="#dc3545" />
                                            </Button>
                                        }
                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        );
                    })
                }
                {
                    this.props.editOnly !== true && !this.props.addDeleteDisabled &&
                    <div className="mt-2">
                        <span className="add-button" onClick={() => {
                            let schedules = this.state.schedules;
                            let defaultNewSchedule = this._getDefaultNewScheduleData();
                            schedules.push(defaultNewSchedule);
                            this.setState({
                                schedules: schedules
                            });
                            this._onScheduleChange(schedules);
                        }}>+ Timeline &amp; Price Range</span>
                    </div>
                }
                {
                    (this.props.editOnly === true && this.state.schedules.length == 0) &&
                    <div className="mt-2 mb-3 text-center">
                        No schedules are saved for this spot
                    </div>
                }
            </div>
        );
    }

    private _getScheduleSummary(schedule_: ISingleScheduleData) {
        let priceSelectionLabel = "N/A";
        let scheduleSummary = getScheduleSummaryText(schedule_);

        if (schedule_.selectedPriceRange) {
            priceSelectionLabel = `$${schedule_.selectedPriceRange.start.toFixed(2)}`;
        }

        return (
            <div className="w-100">
                <Row>
                    <Col xs={8}><span>{scheduleSummary[0]} &bull; {scheduleSummary[1]}</span></Col>
                    <Col xs={4} className="text-right"><span>{priceSelectionLabel}</span></Col>
                </Row>
                {schedule_._hasConflict == true && <div className="errorText">This schedule conflicts with another.</div>}
            </div>
        );
    }

    private _getDefaultNewScheduleData(): ISingleScheduleData {
        return {
            selectedDays: this._defaultSelectedDays.slice(0),
            selectedTimeRange: {
                start: this._defaultSelectedTimeStart,
                end: this._defaultSelectedTimeEnd
            },
            selectedPriceRange: {
                start: this._defaultSelectedPrice,
                end: this._defaultSelectedPrice
            },
            _internalId: new Date().getTime()
        }
    }

    private _onScheduleChange(schedules_: ISingleScheduleData[]): void {
        if (schedules_) {
            for (let schedule of schedules_) {
                schedule._hasConflict = false;
            }

            for (let s1 = 0; s1 < schedules_.length; s1++) {
                let schedule1 = schedules_[s1];

                for (let s2 = s1 + 1; s2 < schedules_.length; s2++) {
                    let schedule2 = schedules_[s2];

                    if (schedule1.selectedDays && schedule2.selectedDays && schedule1.selectedTimeRange && schedule2.selectedTimeRange) {
                        // Days overlap!
                        if (schedule1.selectedDays.filter(d => (schedule2.selectedDays as number[]).indexOf(d) > -1).length > 0) {
                            // Time also overlaps
                            if (NumberHelper.doNumberRangesConflict(schedule1.selectedTimeRange, schedule2.selectedTimeRange)) {
                                schedule1._hasConflict = true;
                                schedule2._hasConflict = true;
                            }
                        }
                    }
                }
            }
        }
        this.props.onChange(schedules_);
    }
}

interface IMultiScheduleComponentProps {
    schedules?: ISingleScheduleData[];
    limitation: IScheduleLimitation;
    defaultCollapsed?: boolean;
    editOnly?: boolean;
    priceDisabled?: boolean;
    hoursDisabled?: boolean;
    addDeleteDisabled?: boolean;
    onChange(schedules_: ISingleScheduleData[]): void;
}

interface IMultiScheduleComponentState {
    schedules: ISingleScheduleData[];
}