import React from "react";
import CheckboxTree from "./TreeViewComponents/CheckboxTree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "./TreeViewCheckbox.css";
import { ITreeViewNode } from "./interface";
import { Grid, TextField } from "@material-ui/core";

class TreeViewCheckbox extends React.Component<ITreeViewProps, ITreeViewState> {

    constructor(props: ITreeViewProps) {
        super(props);
        this.state = {
            checked: this.props.checked,
            expanded: [],
            filterText: '',
            nodesFiltered: this.props.nodes,
            nodes: this.props.nodes,
            onCheck: undefined,
            convertType: ""
        };
    }

    componentDidUpdate(prevProps: ITreeViewProps) {
        if (JSON.stringify(prevProps.nodes) !== JSON.stringify(this.props.nodes)) {
            this.setState({
                nodesFiltered: this.props.nodes,
                nodes: this.props.nodes
            });
        }

        if (JSON.stringify(prevProps.checked) !== JSON.stringify(this.props.checked)) {
            this.setState({
                checked: this.props.checked
            });
        }
    }

    onCheck = (checked: any, unchecked: any) => {
        console.log(checked);

        if (this.state.filterText) {
            const newCheckedList = [...this.state.checked, ...checked.filter((d: any) => !this.state.checked.find((s: any) => s === d))];
            const checkedItems = newCheckedList.filter((d: any) => !unchecked.find((f: any) => f === d));
            //this.setState({ checked: newCheckedList.filter((d: any) => !unchecked.find((f: any) => f === d)) });
            this.props.onCheck(checkedItems);
            
            return;
        }
        this.props.onCheck(checked);
    };

    onExpand = (expanded: any) => {
        this.setState({ expanded });
    };

    onFilterChange = (e: any) => {
        this.setState({ filterText: e.target.value }, this.filterTree);
    }

    filterTree = () => {
        // Reset nodes back to unfiltered state
        if (!this.state.filterText) {
            this.setState((prevState) => ({
                nodesFiltered: prevState.nodes,
            }));

            return;
        }

        const nodesFiltered = (prevState: ITreeViewProps) => (
            { nodesFiltered: prevState.nodes.reduce(this.filterNodes, []) }
        );

        this.setState(nodesFiltered);
    }

    filterNodes = (filtered: any, node: any) => {
        const { filterText } = this.state;
        let children = (node.children || []).reduce(this.filterNodes, []);

        if (
            // Node's label matches the search string
            node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1 ||
            // Or a children has a matching node
            children.length
        ) {
            children = children.length > 0 ? children : undefined;
            filtered.push({ ...node, children });
        }

        return filtered;
    }

    render() {
        const {
            checked,
            expanded,
            filterText,
            nodesFiltered,
        } = this.state;

        return (
            <Grid container>
                {this.props.filterRequired && <Grid item xs={4} className="treeview-filter">
                    <TextField
                        fullWidth
                        placeholder="Search..."
                        variant="outlined"
                        required={true}
                        margin="normal"
                        className="mt-1"
                        inputProps={{ maxLength: 60 }}
                        autoComplete="off"
                        value={filterText}
                        onChange={this.onFilterChange}
                    />
                </Grid>}
                <Grid item xs={12} className={`treeview-container treeview-container-default ${this.props.scrollClass ? this.props.scrollClass : ""}`}>
                    <CheckboxTree
                        nodes={nodesFiltered}
                        checkModel="all"
                        checked={checked}
                        expanded={expanded}
                        iconsClass="fa5"
                        onCheck={this.onCheck}
                        onExpand={this.onExpand}
                        showNodeIcon={false}
                        muiCheckboxes={true}
                        disabled={this.props.disabled}
                        isAnyChildChecked={this.props.isAnyChildChecked}
                        convertType={this.props.convertType}
                    />
                </Grid>
            </Grid>
        );
    }
}

interface ITreeViewProps {
    nodes: ITreeViewNode[],
    filterRequired?: boolean,
    scrollClass?: string,
    checked: any[],
    disabled?: boolean,
    onCheck: (checked: any[]) => void | undefined,
    isAnyChildChecked?: boolean,
    convertType: string
}

interface ITreeViewState {
    checked: any[],
    expanded: any[],
    filterText: string,
    nodesFiltered: any,
    nodes: any,
    onCheck: any,
    convertType: string
}
export default TreeViewCheckbox;
