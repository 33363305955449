import React from 'react';
import { Grid, Tabs, Tab } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ActiveSpotList from './ActiveSpotList';
import { IPropertyInformation } from './Interfaces';
import PropertyManagersList from './PropertyManagersList';
import PropertySpotOwnerList from './PropertySpotOwnerList';
import ParkingSpotReservation from './ParkingSpotReservation';
import PropertyService from '../../Services/PropertyService';
import { ShowAlert } from '../../Common/ShowAlert';

class PropertiesUsers extends React.Component<IPropertiesUsersProps, IPropertiesUsersState> {
    private _propertyService: PropertyService;
    constructor(props: IPropertiesUsersProps) {
        super(props);

        this.state = {
            propertyID: this.props.match.params.propertyID,
            selectedTabIndex: 0,
            propertyInformation: {
                propertyAbout: '',
                propertyAddress1: '',
                propertyAddress2: '',
                propertyCity: '',
                propertyCompanyName: '',
                propertyEarnedRevenue: 0,
                propertyGuid: '',
                propertyMaxPrice: 0,
                propertyMinPrice: 0,
                propertyName: '',
                propertyOwnerName: '',
                propertyPaidOut: 0,
                propertySpotTotal: 0,
                propertySpotTotalBooked: 0,
                propertySpotTotalBuilding: 0,
                propertySpotTotalGRYD: 0,
                propertySpotTotalUpcoming: 0,
                propertySpotWeeklyCount: {
                    fridayCount: 18,
                    mondayCount: 20,
                    saturdayCount: 18,
                    sundayCount: 40,
                    thursdayCount: 18,
                    tuesdayCount: 8,
                    wednesdayCount: 18
                },
                propertyYTDRevenue: 0,
                propertyStatusId: 0,
            }
        }

        this._propertyService = new PropertyService();
    }

    public componentDidMount = () => {
        this._propertyService.getPropertyInfo(this.state.propertyID).then(response => {
            if (response.ok) {
                response.json().then(p => {
                    if (p) {
                        this.state.propertyInformation.propertyName = p[0].propertyName;
                        this.state.propertyInformation.propertyOwnerName = p[0].propertyOwnerName;
                        this.state.propertyInformation.propertyCompanyName = p[0].propertyCompanyName;
                        this.state.propertyInformation.propertyAddress1 = p[0].propertyAddress1 + (p.propertyAddress2 ? " " + p.propertyAddress2 : '');
                        this.state.propertyInformation.propertyCity = p[0].propertyCity;
                        this.state.propertyInformation.propertyStatusId = p[0].propertyStatusId;

                        this.setState({
                            propertyInformation: this.state.propertyInformation
                        });
                    }
                })
            } 
            /*else {
                if (response.status === 401) {
                    // this.accessTokenExpired();
                } else {
                    response.text().then(resp => {
                        console.log(response);
                    });
                    ShowAlert("", 'There was an error processing a your request.', "error");
                }
            }*/
        });

        // var _localData = localStorage.getItem('selectedRow');

        // if (_localData != null && _localData !== '') {
        //     var _data = JSON.parse(_localData);
        //     this.state.propertyInformation.PropertyName = _data.propertyName;
        //     this.state.propertyInformation.PropertyOwnerName = _data.propertyOwnerName;
        //     this.state.propertyInformation.PropertyCompanyName = _data.propertyCompanyName;
        //     this.state.propertyInformation.PropertyAddress1 = _data.propertyAddress1 + (_data.propertyAddress2 ? " " + _data.propertyAddress2 : '');
        //     this.state.propertyInformation.PropertyAddress2 = _data.propertyCity;

        //     this.setState({
        //         propertyInformation: this.state.propertyInformation
        //     });
        // }

    }

    public render() {
        return (
            <div className="m-3 data-grid-container property-dashboard-container">
                <Grid container className="property-overview-header">
                    <Grid item xs>
                        {/* <h1 className="list-header">
                            Lanark Gardens Overview
                        </h1>
                        <div className="property-location"><LocationOnIcon /> 465/495/525 Lanark Street, Winnipeg &middot; Towers Reality &middot; Towers Reality Group</div> */}
                        <h1 className="list-header">
                            {this.state.propertyInformation.propertyName}
                        </h1>
                        <div className="property-location"><LocationOnIcon /> <span>{this.state.propertyInformation.propertyAddress1}</span>, <span>{this.state.propertyInformation.propertyCity}</span> &middot; <span>{this.state.propertyInformation.propertyOwnerName}</span> &middot; <span>{this.state.propertyInformation.propertyCompanyName}</span></div>
                    </Grid>
                </Grid>
                <div className="dashboard-tabs-container dashboad-table">
                    <Tabs
                        value={this.state.selectedTabIndex}
                        onChange={(e_: any, tabIndex_: number) => {
                            this.setState({
                                selectedTabIndex: tabIndex_
                            });
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="standard"
                        aria-label="full width tabs example"
                    >
                        <Tab label="Managers" />
                        <Tab label="Spot Owner" />
                        {/* <Tab label="Reservation" /> */}
                    </Tabs>
                    {
                        this.loadTableGrid()
                    }
                </div>
            </div>
        )
    }

    private loadTableGrid() {
        if (this.state.selectedTabIndex == 0) {
            return (
                <PropertyManagersList propertyID={this.state.propertyID} />
            )
        } else if (this.state.selectedTabIndex == 1) {
            return (
                <PropertySpotOwnerList propertyID={this.state.propertyID}/>
            )
        } else if (this.state.selectedTabIndex == 2) {
            return (
                <ParkingSpotReservation propertyID={this.state.propertyID}/>
            )
        }
    }
    private accessTokenExpired() {
        localStorage.clear();
        window.location.href = '/';
    }
}

interface IPropertiesUsersProps {
    match: {
        params: {
            propertyID: string
        }
    }
}

interface IPropertiesUsersState {
    propertyID: string,
    selectedTabIndex: number,
    propertyInformation: IPropertyInformation
}

export default PropertiesUsers;