import React from 'react';

class WidgetLoaderComponent extends React.Component<ILoaderProps, ILoaderState> {
    constructor(props: ILoaderProps) {
        super(props);

        this.state = {
            activeLoader: false
        }
    }

    public render() {
        if (!this.props.loaderVisible) return null;
        return (
            <div id="widgetSpinnerScreen">
                <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            </div>
        )
    }
}

interface ILoaderProps {
    loaderVisible?: boolean
}

interface ILoaderState {
    activeLoader: boolean
}

export default WidgetLoaderComponent;