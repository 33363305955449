import React from "react";
import PrivateLotService from "../../Services/PrivateLotService";
import {
  EnumInteractiveMapStatus,
  EnumMapEditStatus,
  IFigmaChildNodes,
  IFigmaElementProps,
  IFigmaNodes,
  IFloor,
  IMappedSpotDetails,
  IMapResetOption,
  IMapZones,
  INodesMappedSpotDetails,
  IProgressDetails,
  ISelectedRange,
  ISelectedSpotDetails
} from "./interface";
import "./SpotMap.css";
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import {TextField} from "@material-ui/core";
import {IPrivateLotProperty, ZoneActiveSpot} from "../../Pages/PrivateLots/interface";
import SpotMapLoader from "./SpotMapLoader";
import CustomizedHtmlTooltip from "../../Common/CustomizedHtmlTooltip";
import ReactTooltip from 'react-tooltip'
import ArrayHelper from "../../Helpers/ArrayHelper";
import {Autocomplete} from "@material-ui/lab";

const controller = new AbortController();
const {signal} = controller;

export default class SpotMap extends React.Component<ISpotMapProps, ISpotMapState> {


  private readonly spotMapBodyRef: any = null;
  private readonly borderElemRef: any = null;
  private readonly childMapContainer: any = null;
  private _privateLotService: PrivateLotService;
  private isSpotElementClicked: boolean = false;
  private isMouseDown: boolean = false;
  private mouseDownEvent: ISelectedRange | null = null;
  private spotMapBodyPosition = {
    x: 0,
    y: 0
  };

  constructor(props_: ISpotMapProps) {
    super(props_);

    this.spotMapBodyRef = React.createRef();
    this.borderElemRef = React.createRef();
    this.childMapContainer = React.createRef();

    this.state = {
      zoneList: [],
      item: null,
      assetImages: {},
      loading: false,
      currentZoomSize: 100,
      minZoom: 50,
      maxZoom: 200,
      fileName: "",
      NodeId: "",
      isMapLoaded: false,
      isAllZonesChecked: true,
      isMappingCompleted: false,
      mappedJson: null,
      isEditMap: true,
      selectedSpots: [],
      mouseDownEvent: null,
      mouseUpEvent: null,
      selectedSpotDetails: null,
      isLoadedFromServer: false,
      selectedForMapping: [],
      completedMapping: [],
      draggingSpots: [],
      containerWidth: 0,
      containerHeight: 0
    }

    this._privateLotService = new PrivateLotService();
  }

  componentDidMount(): void {
    this.fetchActiveZone();

    window.addEventListener('mouseup', (event_) => {
      if (this.props.progressDetails.progress === EnumInteractiveMapStatus.EditMap ||
        this.props.progressDetails.progress === EnumInteractiveMapStatus.ZoneSelection) {
        if (this.borderElemRef && this.borderElemRef.current) {
          this.borderElemRef.current.style.width = "0px";
          this.borderElemRef.current.style.height = "0px";
          this.borderElemRef.current.style.top = "0px";
          this.borderElemRef.current.style.left = "0px";
        }
        this.onMouseMoveAndUp(event_, false);
      }
    });

    window.addEventListener('resize', () => {
      this.updateToCenterPosition();
    })

    // window.addEventListener('wheel', function(e) {

    //     e.preventDefault();

    //     console.log('touchstarted', e)

    //   }, {passive: false})
  }


  componentDidUpdate(oldProps_: ISpotMapProps): void {

    if (oldProps_.figmaNodeId !== this.props.figmaNodeId && this.props.figmaNodeId) {
      this.setNodeIdAndKeys();
    }

    if (oldProps_.progressDetails.progress !== this.props.progressDetails.progress &&
      this.props.progressDetails.progress === EnumInteractiveMapStatus.ConfirmedToResetMap) {
      this.resetMapping();
    }

    if (oldProps_.progressDetails.progress !== this.props.progressDetails.progress &&
      this.props.progressDetails.progress === EnumInteractiveMapStatus.MappingCompleted) {
      this.UpdateZoneSelection();
    }

    if (oldProps_.progressDetails.progress !== this.props.progressDetails.progress &&
      this.props.progressDetails.progress === EnumInteractiveMapStatus.EditMap) {
      this.UpdateForNewZone();
    }


    if (oldProps_.progressDetails.progress !== this.props.progressDetails.progress &&
      this.props.progressDetails.progress === EnumInteractiveMapStatus.SaveMapping) {
      this.SaveMapping();
    }

    if ((oldProps_.progressDetails.progress === EnumInteractiveMapStatus.SpotNumberMapping &&
      this.props.progressDetails.progress === EnumInteractiveMapStatus.ZoneSelection)) {
      this.UpdateForNewZone();
    }

    if ((oldProps_.progressDetails.progress === EnumInteractiveMapStatus.ZoneSelection &&
      this.props.progressDetails.progress === EnumInteractiveMapStatus.SpotNumberMapping)) {
      // this.selectedSpotsOpacity();
      this.combineExistingZoneForEdit();
    }

    if (oldProps_.resetState.ResetMap !== this.props.resetState.ResetMap &&
      this.props.resetState.ResetMap) {
      this.resetMap();
    }

    if (oldProps_.resetState.ResetAllSpotAttributes !== this.props.resetState.ResetAllSpotAttributes &&
      this.props.resetState.ResetAllSpotAttributes) {
      this.resetMapAttributes();
    }

    if (this.props.progressDetails.progress === EnumInteractiveMapStatus.SpotNumberMapping) {
      if (JSON.stringify(oldProps_.clearedSpot) !== JSON.stringify(this.props.clearedSpot) &&
        this.props.clearedSpot != null) {
        this.clearMappedSpot(this.props.clearedSpot.SpotGuid);
      } else if (JSON.stringify(oldProps_.progressDetails.selectedSpotInfo) !== JSON.stringify(this.props.progressDetails.selectedSpotInfo)) {
        this.updateSelectedSpot();
      }
    }

    if (this.props.progressDetails.progress === EnumInteractiveMapStatus.MappingCompleted && this.state.isMapLoaded &&
      JSON.stringify(oldProps_.updatedMappedJson) !== JSON.stringify(this.props.updatedMappedJson)) {
      this.setState({
        mappedJson: this.props.updatedMappedJson
      }, () => {
        this.updateLocalStorage()
      });
    }
  }

  render() {
    if (!this.props.figmaNodeId) {
      return (
        <>
          <div className="spot-map-container spot-map-bc">
            <div className="spot-map-addImg" onClick={() => {
              if (this.props.onProgressUpdate) {
                this.props.onProgressUpdate(EnumInteractiveMapStatus.PropertySelection);
              }
            }}><AddPhotoAlternateIcon fontSize="large"/></div>
          </div>
        </>
      )
    }

    return (
      <>
        {
          (!this.state.isMapLoaded && !this.state.isMappingCompleted &&
            this.props.progressDetails.progress === EnumInteractiveMapStatus.CompleteLotSetup &&
            localStorage.getItem(`asset_property_${this.props.selectedFloor ? this.props.selectedFloor.levelId : 0}_${this.props.propertyGuid}`) == null) &&
            <SpotMapLoader
                onCancel={() => {
                  controller.abort()
                  if (this.props.onCancelUpload) {
                    this.props.onCancelUpload();
                  }
                }}
                isCancelVisible={true}
            />
        }

        {
          (!this.state.isMapLoaded &&
            this.props.progressDetails.progress === EnumInteractiveMapStatus.MappingCompleted) &&
            <SpotMapLoader
                onCancel={() => {
                  if (this.props.onCancelUpload) {
                    this.props.onCancelUpload();
                  }
                }}
                isCancelVisible={false}
            />
        }

        <div className="spot-map-container">
          <div className="spot-map-header">
            <div className="zone-list">
              {
                (this.props.progressDetails.progress === EnumInteractiveMapStatus.MappingCompleted) && this.state.zoneList && (
                  <Autocomplete
                    multiple
                    selectOnFocus
                    disableCloseOnSelect
                    title={'Zones'}
                    limitTags={2}
                    options={this.state.zoneList}
                    style={{padding: 12, minWidth: 100}}
                    value={this.state.zoneList.filter(zone => zone.IsChecked)}
                    getOptionLabel={option => option.ZoneName}
                    onChange={(event, value) => {

                      const zoneList = this.state.zoneList.map(zone => {
                        const existingValue = value.find(selected => selected.ZoneGuid === zone.ZoneGuid)
                        zone.IsChecked = existingValue !== undefined;
                        return zone;
                      });

                      this.setState({
                        zoneList
                      }, () => {
                        this.UpdateZoneSelection();
                      });
                    }}
                    getLimitTagsText={(more) => `+${more} other ${more > 1 ? `zones` : `zone`}`}
                    renderInput={(inputProps) => {
                      return <TextField
                        variant={'outlined'}
                        {...inputProps}
                      />
                    }}/>
                )
              }

            </div>
            {
              (this.state.isMapLoaded && !this.state.isMappingCompleted) &&
                <div
                    className={'sm-map-edit-btn ' + ((this.props.progressDetails.progress === EnumInteractiveMapStatus.CompleteLotSetup ||
                      this.props.progressDetails.progress === EnumInteractiveMapStatus.MappingCompleted) ? '' : 'disabled')}>
                    <a href='javascript:void(0)' className='pl-map-details' onClick={() => {
                      if (this.props.progressDetails.progress === EnumInteractiveMapStatus.MappingCompleted) {
                        // this.resetMapping();
                        if (this.props.onProgressUpdate) {
                          this.props.onProgressUpdate(EnumInteractiveMapStatus.RequestResetCompletedMap);
                        }
                      } else {
                        if (this.props.onProgressUpdate) {
                          this.props.onProgressUpdate(EnumInteractiveMapStatus.EditMap);
                        }
                      }
                    }}>Edit Map</a>
                </div>
            }
          </div>
          <div className="spot-map-body"
               ref={this.spotMapBodyRef}
               onMouseDown={(event_: any) => {
                 this.onMouseDown(event_)
               }}
               onMouseMove={(event_: any) => {
                 this.onMouseMoveAndUp(event_, true)
               }}
            // onMouseUp={(event_: any) => {
            //     this.onMouseMoveAndUp(event_, false)
            // }}
               onClick={(event_: any) => {
                 this.onSpotSelection(event_);
               }}
               onTouchStart={(event: any) => {
                 event.preventDefault();
                 console.log('entered')
               }}
            // style={{
            //     width: `${(this.state.mappedJson?.Width ? this.state.mappedJson?.Width : 716) * (this.state.currentZoomSize / 100)}px`,
            // }}
          >
            <div
              className={'spot-map-subcontainer ' + (this.props.progressDetails.progress === EnumInteractiveMapStatus.MappingCompleted ? 'spot-map-completed' : '')}
              style={{
                // backgroundImage: `url(${this.state.backgroundImage.Image})`,
                // backgroundPosition: `${this.state.backgroundImage.XPosition}px ${this.state.backgroundImage.YPosition}px`,
                //zoom: (this.state.currentZoomSize / 100),
                transform: `scale(${(this.state.currentZoomSize / 100)})`,//`scale(${(this.state.currentZoomSize / 100)})`,
                // transformOrigin: 'center',
                // width: `${this.state.backgroundImage.Width * (this.state.currentZoomSize / 100)}px`,
                // height: `${this.state.backgroundImage.Height}px`,
                // minWidth: `${this.state.backgroundImage.Width}px`,
                width: `${(this.state.mappedJson?.Width ? this.state.mappedJson?.Width : 716) * (this.state.currentZoomSize / 100)}px`,
                maxWidth: `${(this.state.mappedJson?.Width ? this.state.mappedJson?.Width : 716)}px`,
                minWidth: `${this.state.mappedJson?.Width ? this.state.mappedJson?.Width : 716}px`,
                minHeight: `${this.state.mappedJson?.Height ? this.state.mappedJson?.Height : 716}px`,
              }} ref={this.childMapContainer}>
              <div className="border-element" ref={this.borderElemRef}
                   style={{display: this.mouseDownEvent != null ? "block" : "none"}}></div>
              <div style={{display: 'inline'}}>
                {
                  this.state.mappedJson && this.state.mappedJson?.OtherElements.length > 0 && this.addImages()
                }
              </div>
            </div>
          </div>
          <div className="sm-zoom-p-c">
            <div className="sm-zoom-c">
              <div className={this.state.currentZoomSize === this.state.maxZoom ? 'disabled' : ''}
                   onClick={() => this.updateZoom('plus')}>+
              </div>
              <div>{this.state.currentZoomSize}%</div>
              <div className={this.state.currentZoomSize === this.state.minZoom ? 'disabled' : ''}
                   onClick={() => this.updateZoom('minus')}>&mdash;</div>
            </div>
          </div>
        </div>

        {(!this.props.isToolTipRequired &&
            this.props.progressDetails.progress === EnumInteractiveMapStatus.MappingCompleted) &&
            <ReactTooltip
                className='daily-tooltip react-tooltip'
                place="top"
                type="light"
                effect="solid"/>
        }
      </>
    )
  }

  private onMouseDown(event: any) {
    this.isMouseDown = true;
    if (this.props.progressDetails.progress === EnumInteractiveMapStatus.EditMap ||
      this.props.progressDetails.progress === EnumInteractiveMapStatus.ZoneSelection) {

      this.mouseDownEvent = {
        clientX: event.clientX,
        clientY: event.clientY
      };

      this.spotMapBodyPosition = {
        x: this.spotMapBodyRef.current.scrollLeft,
        y: this.spotMapBodyRef.current.scrollTop
      };
    }
  }

  private onMouseMoveAndUp(event: any, isMouseMove: boolean) {
    if (this.isMouseDown) {
      let currentPos: ISelectedRange = {
        clientX: event.clientX,
        clientY: event.clientY
      }

      let mouseDownPos = this.mouseDownEvent;

      if (((this.props.progressDetails.progress === EnumInteractiveMapStatus.EditMap ||
            this.props.progressDetails.progress === EnumInteractiveMapStatus.ZoneSelection)
          && mouseDownPos != null) &&
        (mouseDownPos.clientX !== currentPos.clientX && mouseDownPos.clientY !== currentPos.clientY)) {
        if (this.mouseDownEvent != null && this.mouseDownEvent.clientX !== currentPos.clientX) {
          this.selectedRangeElements(currentPos, isMouseMove);
        }
      } else if (!isMouseMove) {
        this.isMouseDown = false;
        this.mouseDownEvent = null;

        // this.setState({
        //     mouseDownEvent: null,
        //     mouseUpEvent: null
        // })
      }
    }
  }

  private onSpotSelection(event: any) {
    const svgElm = event.target.closest("svg");
    if (svgElm && event.currentTarget.contains(svgElm) && svgElm.tagName === "svg") {
      let parentElem = svgElm.parentNode;

      if (parentElem.className.indexOf("spot-box") !== -1) {
        const item = this.getClickedRectangle(parentElem.id);

        if (item != null) {
          this.isSpotElementClicked = true;
          if (this.props.progressDetails.progress === EnumInteractiveMapStatus.EditMap ||
            this.props.progressDetails.progress === EnumInteractiveMapStatus.ZoneSelection) {
            console.time();
            let selectedSpots_ = this.state.selectedSpots;
            let elmPos = selectedSpots_.findIndex(x => x.Id === item?.Id);   //selectedSpots_.indexOf(item);


            if (elmPos !== -1) {
              selectedSpots_.splice(elmPos, 1);
            } else {
              let clickedElements = this.getClickedElements(item);
              selectedSpots_ = selectedSpots_.concat(clickedElements);
            }


            this.setState({
              selectedSpots: selectedSpots_.filter((value: IFigmaChildNodes, index, self) => {
                return self.findIndex(x => x.Id === value.Id) === index
              })
            }, () => {
              if (this.props.onProgressUpdate && selectedSpots_.length > 0 &&
                this.props.progressDetails.progress !== EnumInteractiveMapStatus.ZoneSelection) {
                this.props.onProgressUpdate(EnumInteractiveMapStatus.ZoneSelection);
              }
            });
            console.timeEnd();

          } else if (this.props.progressDetails.progress === EnumInteractiveMapStatus.SpotNumberMapping) {
            this.setState({
              item: item,
              selectedForMapping: this.getClickedElements(item)
            }, () => {
              this.updateSelectedSpot()
            })
          } else if (this.props.progressDetails.progress === EnumInteractiveMapStatus.MappingCompleted) {
            let selectedElement = this.getSpotDetails(item.Id);

            this.setState({
              item: JSON.stringify(this.state.item).indexOf(item.Id) === -1 ? item : null
            }, () => {
              this.isSpotElementClicked = false;
              if (this.state.item != null) {
                if (this.props.onSpotSelect) {
                  this.props.onSpotSelect(selectedElement);
                }
              } else {
                if (this.props.onSpotDeselect) {
                  this.props.onSpotDeselect();
                }
              }
            });
          }
        }
      }
    } else if (this.props.progressDetails.progress === EnumInteractiveMapStatus.MappingCompleted) {
      if (!this.isSpotElementClicked && this.state.item != null) {
        this.setState({
          item: null
        }, () => {
          if (this.props.onSpotDeselect) {
            this.props.onSpotDeselect();
          }
        });
      }
    }
  }

  private selectedRangeElements(currentPos: ISelectedRange, isMouseMove: boolean) {


    let filteredElm: IFigmaChildNodes[] = [];
    let boxedElements: IFigmaChildNodes[] = [];

    if (this.state.mappedJson != null && this.spotMapBodyRef != null &&
      this.spotMapBodyRef.current != null && this.childMapContainer != null) {
      const parentElem = this.spotMapBodyRef.current;//document.querySelector(".spot-map-body");
      let x0 = 0;
      let x1 = 0;
      let y0 = 0;
      let y1 = 0;
      let graceSpace = 5;
      let subContainerElm = this.childMapContainer.current;

      let parentPos = parentElem.getBoundingClientRect();

      let subElmPos = subContainerElm.getBoundingClientRect();
      let scaledValue = subElmPos.width / subContainerElm.offsetWidth;
      // let scaleY = subElmPos.height / subContainerElm.offsetHeight;

      // let x0_ = 0;
      // let x1_ = 0;
      // let y0_ = 0;
      // let y1_ = 0;

      if (this.mouseDownEvent != null && currentPos != null) {

        let x0_ = (this.mouseDownEvent.clientX - (parentPos.x - this.spotMapBodyPosition.x + (subElmPos.left - parentPos.left + parentElem.scrollLeft))) / scaledValue;
        let x1_ = (currentPos.clientX - (parentPos.x - parentElem.scrollLeft + (subElmPos.left - parentPos.left + parentElem.scrollLeft))) / scaledValue;
        let y0_ = (this.mouseDownEvent.clientY - (parentPos.y - this.spotMapBodyPosition.y + (subElmPos.top - parentPos.top + parentElem.scrollTop))) / scaledValue;
        let y1_ = (currentPos.clientY - (parentPos.y - parentElem.scrollTop + (subElmPos.top - parentPos.top + parentElem.scrollTop))) / scaledValue;

        x0 = Math.min(x0_, x1_);
        x1 = Math.max(x0_, x1_);
        y0 = Math.min(y0_, y1_);
        y1 = Math.max(y0_, y1_);
      }

      if (this.borderElemRef && this.borderElemRef.current) {

        let bws = x1 - x0;
        let bhs = y1 - y0;
        let bts = y0;
        let bls = x0;

        this.borderElemRef.current.style.width = bws + "px";
        this.borderElemRef.current.style.height = bhs + "px";
        this.borderElemRef.current.style.top = bts + "px";
        this.borderElemRef.current.style.left = bls + "px";
      }

      x0 = (x0 - graceSpace);
      x1 = (x1 + graceSpace);
      y0 = (y0 - graceSpace);
      y1 = (y1 + graceSpace);

      filteredElm = this.state.mappedJson.OtherElements.filter(x => {
        return (x.ElementType === 'RECTANGLE' && x.XPosition >= x0 &&
          (x.XPosition + x.Width <= x1) &&
          (x.YPosition >= y0) &&
          (x.YPosition + x.Height <= y1))
      });

      let otherElements = this.state.mappedJson.OtherElements;

      filteredElm.forEach(f => {
        let dragElm = otherElements.filter(x => x.ElementName === f.ElementName);

        if (dragElm.length > 0) {
          boxedElements = boxedElements.concat(dragElm);
        }
      })
    }

    let selectedItem: IFigmaChildNodes[] = this.state.selectedSpots;
    let draggingSpot: IFigmaChildNodes[] = this.state.draggingSpots;

    draggingSpot.forEach(x => {
      let index = selectedItem.findIndex(s => s.Id === x.Id);

      if (index !== -1) {
        selectedItem.splice(index, 1);
      }
    });

    draggingSpot = [];

    if (this.state.mappedJson) {
      this.state.mappedJson.OtherElements.forEach(x => {
        let index = draggingSpot.findIndex(s => s.Id === x.Id);
        let boxIndex = boxedElements.findIndex(b => b.Id === x.Id);

        if (boxIndex !== -1 && index === -1) {
          draggingSpot.push(x);
        }
      })
    }

    selectedItem = selectedItem.concat(draggingSpot);

    if (!isMouseMove) {
      this.mouseDownEvent = null;
      this.setState({
        mouseDownEvent: null,
        mouseUpEvent: null,
        selectedSpots: selectedItem.filter((value: IFigmaChildNodes, index, self) => {
          return self.findIndex(x => x.Id === value.Id) === index
        }),
        draggingSpots: []
      }, () => {
        if (this.borderElemRef && this.borderElemRef.current) {
          this.borderElemRef.current.style.width = "0px";
          this.borderElemRef.current.style.height = "0px";
          this.borderElemRef.current.style.top = "0px";
          this.borderElemRef.current.style.left = "0px";
        }
        if (this.props.onProgressUpdate) {
          this.props.onProgressUpdate(EnumInteractiveMapStatus.ZoneSelection);
        }
      })
    } else {
      this.setState({
        selectedSpots: selectedItem.filter((value: IFigmaChildNodes, index, self) => {
          return self.findIndex(x => x.Id === value.Id) === index
        }),
        draggingSpots: draggingSpot
      });
    }
  }


  private addImages() {
    let spotData_ = this.GetSpotData();

    return (spotData_.map((item: IFigmaChildNodes, index: any) => {
      let className;

      if (item.ElementType === 'RECTANGLE') {
        className = "spot-box ";
      } else {
        className = "non-box ";
      }

      if (item.ElementType === 'GROUP' || (item.ElementName !== "" && item.ElementType !== 'RECTANGLE' && item.ElementType !== 'TEXT')) {
        className += "m-spot-icons ";
      } else if (item.ElementType === 'VECTOR') {
        className += "m-spot-zone ";
      } else if (item.ElementType === 'TEXT') {
        className += "m-spot-text ";
      }

      className += this.getClassName(item);

      if (item.IsChecked) {
        className += " fade";
      }

      if (item.SpotColor) {
        className += ` ${item.SpotColor}`;
      }

      const spotGuid = this.getSpotDetails(item.Id).spotGuid;

      const tooltipContent = (!this.props.isToolTipRequired && this.props.tooltipFilter &&
          this.props.progressDetails.progress === EnumInteractiveMapStatus.MappingCompleted) &&
        this.props.tooltipFilter(spotGuid);

      let styles: any = {
        position: 'absolute',
        top: item.YPosition,
        left: item.XPosition,
        width: item.Width,
        height: item.Height,
        // backgroundImage: `url(${item.Image})`,
      };

      if (item.ElementType === 'VECTOR') {
        className += ` vector-bg-image`;
      }

      if (item.Image !== "") {
        styles.opacity = "1 !important";
        styles.backgroundImage = `url(${item.Image})`;
        styles.backgroundRepeat = "no-repeat";
        // styles.backgroundPositionX = `${item.XPosition}px`;
        // styles.backgroundPositionY = `${item.YPosition}px`;

        className += ` map-bg-image`;
      }


      const innerContent = (<div
        className={className}
        data-html={true}
        data-tip={tooltipContent}
        key={index}
        style={styles}
        data-name={item.ElementName}
        dangerouslySetInnerHTML={{
          __html: (this.state.assetImages as any)[item.Id] as string
        }}
        id={item.Id}
      >
      </div>);

      return (<>
          {
            this.props.isToolTipRequired && this.props.tooltipFilter &&
              <CustomizedHtmlTooltip placement="top" style={{
                position: 'absolute',
                top: item.YPosition,
                left: item.XPosition
              }}
                                     title={
                                       this.props.tooltipFilter(spotGuid)
                                     }
                                     innerContent={innerContent}
              >
              </CustomizedHtmlTooltip>
          }
          {
            !this.props.isToolTipRequired && innerContent
          }
        </>
      );
    }));
  }

  private getClassName = (item_: IFigmaChildNodes) => {
    let status = "";

    if (this.props.progressDetails.progress !== EnumInteractiveMapStatus.RequestResetCompletedMap) {
      let filteredElm = this.state.selectedSpots.filter(x => x.ElementType === "RECTANGLE");
      let filteredIndex = filteredElm.findIndex(x => x.Id === item_.Id);

      if (
        (filteredIndex !== -1
          && ((this.props.progressDetails.progress === EnumInteractiveMapStatus.EditMap || this.props.progressDetails.progress === EnumInteractiveMapStatus.ZoneSelection)))
        || ((this.state.item != null && this.state.item.Id === item_.Id) && this.props.progressDetails.progress !== EnumInteractiveMapStatus.MappingCompleted)) {
        status += "selected ";
      }

      if ((this.state.item != null && this.state.item.Id === item_.Id) && this.props.progressDetails.progress === EnumInteractiveMapStatus.MappingCompleted) {
        status += "spot-selected ";
      }

      let isExits = false;

      if (this.state.mappedJson) {
        let spotObjects_ = Object.values(this.state.mappedJson.Spots);

        spotObjects_.every(x => {
          let index = x.BoxElement.findIndex(b => b.Id === item_.Id);

          if (index !== -1) {
            isExits = true;
            return false;
          }

          return true;
        })
      }

      //(JSON.stringify(this.state.mappedJson?.Spots).indexOf(item_.Id) != -1
      if ((isExits &&
        this.props.progressDetails.progress !== EnumInteractiveMapStatus.MappingCompleted)) {
        status += "mapped-spots ";

        if (this.props.progressDetails.progress === EnumInteractiveMapStatus.EditMap ||
          this.props.progressDetails.progress === EnumInteractiveMapStatus.ZoneSelection) {
          status += "no-cursor ";
        }
      }
    }

    // if (item_.status) {
    //     status += item_.status.replace(/\s/g, "");
    // }
    return status;
  }

  private getSpotAssets = (keyNodesList: any[]) => {
    let mapList: any = [];

    keyNodesList.forEach((element: any) => {
      if (element['children']) {
        let children = this.getSpotAssets(element['children']);
        if (children) mapList.push(...children);
      } else {
        if (element['absoluteRenderBounds']){
          mapList.push(element);
        }
      }
    });

    return mapList;
  }

  private getFigmaNodes() {
    let storageData = localStorage.getItem(`asset_property_${this.props.selectedFloor ? this.props.selectedFloor.levelId : 0}_${this.props.propertyGuid}`);

    if (this.state.fileName && this.state.NodeId && storageData == null) {
      // controller = new AbortController();
      this._privateLotService.getFigmaNodes(this.state.fileName, this.state.NodeId)
        .then(r => r.json())
        .then(r => {
          this.setState({
            figma_node_list: r,
            mappedJson: null,
            isMapLoaded: false,
            isMappingCompleted: false
          }, () => {
            this.loadFigmaNodes();
          });
        });
    } else {
      let mappedJson_: IFigmaNodes = {
        OtherElements: [],
        Spots: {}
      };

      if (storageData) {
        try {
          mappedJson_ = JSON.parse(storageData) as IFigmaNodes;
        } catch (err) {
        }

        if (mappedJson_.OtherElements.length > 0) {
          this.setState({
            mappedJson: mappedJson_,
            isLoadedFromServer: true
          }, () => {
            this.updateLocalStorage();
            this.loadFigmaNodes();
          });
        } else {
          localStorage.removeItem(`asset_property_${this.props.selectedFloor ? this.props.selectedFloor.levelId : 0}_${this.props.propertyGuid}`);
          this.getFigmaNodes();
        }
      }
    }
  }

  private isSpotSkipped(elementType: string) {
    return elementType.indexOf("INACTIVE") !== -1;
  };

  private async loadFigmaNodes() {
    let mappedJson: IFigmaNodes = {
      OtherElements: [],
      Spots: {}
    };
    let otherElements: IFigmaChildNodes[] = [];
    let rectangleElements: IFigmaChildNodes[] = [];

    if (this.state.mappedJson) {
      mappedJson = this.state.mappedJson;

      this.renderSVGs(mappedJson);

    } else if (this.state.figma_node_list) {
      let {NodeId} = this.state;
      if (!this.state.figma_node_list["nodes"][NodeId]) {
        NodeId = Object.keys(this.state.figma_node_list["nodes"]).length > 0 ?
          Object.keys(this.state.figma_node_list["nodes"])[0] : NodeId;

        this.setState({
          NodeId
        });
      }
      let keyNodesList = this.state.figma_node_list["nodes"][NodeId]["document"]["children"];

      let spotAssets: any[] = this.getSpotAssets(keyNodesList);

      let x_ = Math.min(...spotAssets.map(x => Number(x['absoluteRenderBounds']['x'])));
      let y_ = Math.min(...spotAssets.map(x => Number(x['absoluteRenderBounds']['y'])));
      // let x1_ = Math.max(...spotAssets.map(x => Number(x['absoluteRenderBounds']['x'] + x['absoluteRenderBounds']['width'])));
      // let y1_ = Math.max(...spotAssets.map(x => Number(x['absoluteRenderBounds']['y'] + x['absoluteRenderBounds']['height'])));
      let marginSpace = 20;

      // let containerWidth = (x1_ - x_) + (marginSpace * 2);
      // let containerHeight = (y1_ - y_) + (marginSpace * 2);


      let offsetX = (marginSpace - x_);
      let offsetY = (marginSpace - y_);

      let assetIds: string[] = [];
      let imgAssetIds: string[] = [];

      for (let asset of spotAssets) {
        if (asset['fills'] != null && asset['fills'].length > 0 && asset['fills'][0]['type'] === "IMAGE") {
          imgAssetIds.push(asset.id)
        } else {
          assetIds.push(asset.id);
        }
      }

      if (imgAssetIds.length > 0) {
        let assetImages_ = await this._privateLotService.getFigmaImage(this.state.fileName, imgAssetIds);

        let assetImageDownloadPromises = [];

        for (let imageId in assetImages_.images) {
          let imageUrl = assetImages_.images[imageId];
          if (!this.getLocalObject(mappedJson, imageId) && this.props.progressDetails.progress !== EnumInteractiveMapStatus.UploadMap) {
            // elmIds.push(imageId);
            try {
              assetImageDownloadPromises.push(
                fetch(imageUrl, {signal})
                  .then(r_ => r_.blob())
                  .then(async (r_) => {
                    const imageObjectURL = await this.blobToBase64(r_);

                    let filteredObj: IFigmaElementProps = spotAssets.find(x => x.id === imageId);

                    if (filteredObj) {
                      let bgImg: IFigmaChildNodes = {
                        ElementType: "image",
                        Id: imageId,
                        Image: imageObjectURL as string,
                        XPosition: (Number(filteredObj.absoluteRenderBounds.x) + offsetX),
                        YPosition: (Number(filteredObj.absoluteRenderBounds.y) + offsetY),
                        Svg: "",
                        Height: filteredObj.absoluteRenderBounds.height,
                        Width: filteredObj.absoluteRenderBounds.width,
                        IsChecked: false,
                        SpotColor: "",
                        BgColor: "",
                        isZoneName: false,
                        ElementName: ""
                      }

                      otherElements.push(bgImg);
                    }

                    return r_;
                  })
              );
            } catch (e) {
            }
          }
        }

        await Promise.all(assetImageDownloadPromises);
      }

      let assetSvgImages_ = await this._privateLotService.getFigmaImage(this.state.fileName, assetIds, "svg");

      let assetDownloadPromises = [];

      let rectangleIndex: number = 1;
      for (let imageId in assetSvgImages_.images) {
        let imageUrl = assetSvgImages_.images[imageId];
        if (!this.getLocalObject(mappedJson, imageId) && this.props.progressDetails.progress !== EnumInteractiveMapStatus.UploadMap) {
          // elmIds.push(imageId);
          try {
            assetDownloadPromises.push(
              fetch(imageUrl, {signal})
                .then(r_ => r_.text())
                .then(r_ => {
                  let filteredObj: IFigmaElementProps = spotAssets.find(x => x.id === imageId);

                  if (filteredObj) {

                    let bgImg: IFigmaChildNodes = {
                      ElementType: this.isSpotSkipped(filteredObj.name) ? "RECTBOX" : filteredObj.type,
                      Id: imageId,
                      Image: "",
                      XPosition: (Number(filteredObj.absoluteRenderBounds.x) + offsetX),
                      YPosition: (Number(filteredObj.absoluteRenderBounds.y) + offsetY),
                      Svg: r_,
                      Height: filteredObj.absoluteRenderBounds.height,
                      Width: filteredObj.absoluteRenderBounds.width,
                      IsChecked: false,
                      SpotColor: "",
                      BgColor: "",
                      isZoneName: false,
                      ElementName: ""
                    }

                    if (bgImg.ElementType === "RECTANGLE" || bgImg.ElementType === "RECTBOX") {
                      bgImg.ElementName = `${"RECTANGLE" + rectangleIndex}`;
                      rectangleIndex++;

                      rectangleElements.push(bgImg);
                    }

                    otherElements.push(bgImg);
                  }

                  return r_;
                })
            );
          } catch (e) {
          }
        }
      }

      await Promise.all(assetDownloadPromises);

      if (!localStorage.getItem(`asset_property_${this.props.selectedFloor ? this.props.selectedFloor.levelId : 0}_${this.props.propertyGuid}`)) {
        rectangleElements.forEach(rect => {
          let x_ = rect.XPosition;
          let y_ = rect.YPosition;
          let x1_ = (x_ + rect.Width);
          let y1_ = (y_ + rect.Height);

          otherElements.filter(other => {
            let isMatched = (other.XPosition >= x_ &&
              other.XPosition <= (x1_ - other.Width) &&
              other.YPosition >= y_ &&
              other.YPosition <= (y1_ - other.Height)) && other.ElementType !== "RECTANGLE";

            if (isMatched) {
              other.ElementName = rect.ElementName
            }
            return other;
          });

        });

        otherElements.forEach(elmItem => {
          if (elmItem.ElementType === "TEXT" && elmItem.ElementName === "") {
            elmItem.isZoneName = true;
          }
        });

        mappedJson.OtherElements = otherElements;

        let ex1_ = Math.max(...otherElements.map(x => x.Width));
        let ey1_ = Math.max(...otherElements.map(x => x.Height));

        let containerWidth = ex1_ + (marginSpace * 2);
        let containerHeight = ey1_ + (marginSpace * 2);

        mappedJson.Width = containerWidth;
        mappedJson.Height = containerHeight;

        localStorage.setItem(`asset_property_${this.props.selectedFloor ? this.props.selectedFloor.levelId : 0}_${this.props.propertyGuid}`, JSON.stringify(mappedJson));
      }

      let uploadedMap = {
        "propertyGuid": this.props.propertyGuid,
        "externalMapUrl": this.props.figmaNodeId,
        "levelId": this.props.selectedFloor ? this.props.selectedFloor.levelId : 0,
        "mapStatus": EnumMapEditStatus.Uploaded,
        "mapDataWithSpotAssigned": JSON.stringify(mappedJson)
      } as any;

      if (this.props.progressDetails.progress !== EnumInteractiveMapStatus.UploadMap) {
        this._privateLotService.ConfigureMap(uploadedMap, true)
          .then(r => {
            if (r.ok) {
              this.renderSVGs(mappedJson);
            }
          });

      }
    }

    ReactTooltip.rebuild();
  }

  private blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  private renderSVGs(mappedJson: IFigmaNodes) {
    //const { checkBookingStatus } = this.props;
    let elmIds: string[];
    let assetImagesHtml: {
      [id_: string]: string
    } = {};

    elmIds = this.getAllSvgIds(mappedJson);

    for (let imageId in elmIds) {
      assetImagesHtml[elmIds[imageId]] = (this.getLocalObject(mappedJson, elmIds[imageId]) as IFigmaChildNodes).Svg;
    }

    let defaultScalled = 1;

    if (this.spotMapBodyRef.current != null && this.childMapContainer.current != null) {

      const mapWidth = mappedJson ? mappedJson.Width : this.childMapContainer.current.offsetWidth;
      defaultScalled = this.spotMapBodyRef.current.offsetWidth / mapWidth;

    }

    let scalledValue = Math.round((defaultScalled * 100) * 0.10 + Number.EPSILON) / 0.10;

    if (scalledValue > this.state.maxZoom) {
      scalledValue = this.state.maxZoom;
    }

    this.setState({
      assetImages: assetImagesHtml,
      mappedJson: mappedJson,
      currentZoomSize: scalledValue
    }, () => {
      this.updateToCenterPosition();
      if (this.spotMapBodyRef != null) {
        if (this.spotMapBodyRef.current != null && this.childMapContainer.current != null) {
          let c = this.spotMapBodyRef.current;
          c.scrollTo(((c.scrollWidth - c.offsetWidth) / 2), (c.scrollHeight - c.offsetHeight) / 2);
        }
      }
      this.updateLocalStorage();
      if (this.props.progressDetails.progress !== EnumInteractiveMapStatus.UploadMap) {
        this.setState({
          isMapLoaded: true
        }, () => {
          if (this.props.onDone) {
            this.props.onDone(this.state.isLoadedFromServer, this.state.mappedJson);
          }

          if (this.props.progressDetails.progress === EnumInteractiveMapStatus.MappingCompleted) {
            this.UpdateZoneSelection();
          }
        });
      }
    });
  }

  private getLocalObject(mappedJson: IFigmaNodes, id: string) {
    let filteredOtherElements = mappedJson.OtherElements.filter(x => x.Id === id);

    if (filteredOtherElements.length > 0) {
      return filteredOtherElements[0];
    }

    const filteredSpots = Object.values(mappedJson.Spots).filter(x => JSON.stringify(x).indexOf(id) !== -1);

    if (filteredSpots.length > 0) {
      // var spotGuid = Object.keys(filteredSpots[0])[0];
      const spotGuid = filteredSpots[0].SpotGuid;
      // console.log("=====spotGuid===" + spotGuid)

      filteredOtherElements = mappedJson.Spots[spotGuid].OtherElements.filter(x => x.Id === id);

      if (filteredOtherElements.length > 0) {
        return filteredOtherElements[0];
      }

      const filteredBoxElement = mappedJson.Spots[spotGuid].BoxElement.filter(x => x.Id === id);

      if (filteredBoxElement.length > 0) {
        return filteredBoxElement[0];
      }
    }

    return null;
  }


  private getAllSvgIds(mappedJson: IFigmaNodes) {
    let svgIds: string[] = [];


    mappedJson.OtherElements.forEach(x => {
      if (x.ElementType !== "image") {
        svgIds.push(x.Id);
      }
    })


    let spotObjects = Object.values(mappedJson.Spots);

    spotObjects.forEach(spotObject => {
      spotObject.OtherElements.forEach(x => {
        svgIds.push(x.Id);
      });

      spotObject.BoxElement.forEach(x => {
        svgIds.push(x.Id);
      });
    });

    return svgIds;
  }

  private updateZoom(action: string) {
    let zoom = this.state.currentZoomSize;
    let maxZoom = this.state.maxZoom;
    let minZoom = this.state.minZoom;

    if (action === 'plus') {
      zoom += 10;
    } else {
      zoom -= 10;
    }

    if (zoom > maxZoom) {
      zoom = maxZoom;
    }

    if (zoom < minZoom) {
      zoom = minZoom;
    }

    this.setState({
      currentZoomSize: zoom
    }, () => {
      ReactTooltip.rebuild();
      this.updateToCenterPosition();
    })
  }

  private updateToCenterPosition() {
    if (this.spotMapBodyRef != null && this.childMapContainer != null &&
      this.spotMapBodyRef.current != null && this.childMapContainer.current != null) {
      let p = this.spotMapBodyRef.current;
      let s = this.childMapContainer.current;

      let pBounds = p.getBoundingClientRect();
      let sBounds = s.getBoundingClientRect();

      let w = pBounds.width - sBounds.width;
      let h = pBounds.height - sBounds.height;

      let l_ = w / 2;
      let t_ = h / 2;

      s.style.left = `${l_ < 0 ? 0 : l_}px`;
      s.style.top = `${t_ < 0 ? 0 : t_}px`;

    }
  }

  private combineExistingZoneForEdit() {
    let mappedJson_ = this.state.mappedJson;

    let selectedItem: IFigmaChildNodes[] = this.state.selectedSpots;
    // console.log(selectedItem);
    if (mappedJson_ != null && this.props.progressDetails.selectedZone != null) {
      let spotGuids_ = Object.keys(mappedJson_.Spots);

      spotGuids_.forEach(spotGuid => {
        if (mappedJson_ != null && this.props.progressDetails.selectedZone) {
          if (mappedJson_.Spots[spotGuid].ZoneGuid === this.props.progressDetails.selectedZone.zoneGuid) {
            mappedJson_.Spots[spotGuid].BoxElement.forEach(item_ => {
              // console.log(item_);
              //JSON.stringify(this.state.selectedSpots).indexOf(item_.Id) == -1
              if (!this.isItemExistInSelectedSpot(item_.Id)) {
                selectedItem.push(item_);
              }
            });

            mappedJson_.Spots[spotGuid].OtherElements.forEach(item_ => {
              // JSON.stringify(this.state.selectedSpots).indexOf(item_.Id) == -1
              if (!this.isItemExistInSelectedSpot(item_.Id)) {
                selectedItem.push(item_);
              }
            });

            // delete mappedJson_.Spots[spotGuid];
          }
        }
      });
    }

    // console.log(selectedItem);

    this.setState({
      mappedJson: mappedJson_,
      selectedSpots: selectedItem.filter((value: IFigmaChildNodes, index, self) => {
        return self.findIndex(x => x.Id === value.Id) === index
      })
    }, () => {
      this.updateLocalStorage();
      this.selectedSpotsOpacity();
    })
  }

  private selectedSpotsOpacity() {
    let mappedJson_ = this.state.mappedJson;

    if (mappedJson_ != null) {
      mappedJson_.OtherElements.forEach(item_ => {
        //JSON.stringify(this.state.selectedSpots).indexOf(item_.Id) != -1
        if (this.isItemExistInSelectedSpot(item_.Id)) {
          item_.IsChecked = false;
        } else if (!item_.isZoneName) {
          item_.IsChecked = true;
        }
      });

      let spotGuids_ = Object.keys(mappedJson_.Spots);

      spotGuids_.forEach(spotGuid => {
        if (mappedJson_ != null) {
          let zoneGuid_ = mappedJson_.Spots[spotGuid].ZoneGuid;
          mappedJson_.Spots[spotGuid].BoxElement.forEach(item_ => {
            // JSON.stringify(this.state.selectedSpots).indexOf(item_.Id) != -1
            item_.IsChecked = !(this.isItemExistInSelectedSpot(item_.Id) ||
              (this.props.progressDetails.selectedZone != null && zoneGuid_ === this.props.progressDetails.selectedZone.zoneGuid));
          });

          mappedJson_.Spots[spotGuid].OtherElements.forEach(item_ => {
            // JSON.stringify(this.state.selectedSpots).indexOf(item_.Id) != -1
            item_.IsChecked = !(this.isItemExistInSelectedSpot(item_.Id) ||
              (this.props.progressDetails.selectedZone != null && zoneGuid_ === this.props.progressDetails.selectedZone.zoneGuid));
          });
        }
      });
    }

    this.setState({
      mappedJson: mappedJson_
    }, () => {
      this.updateLocalStorage();
    })
  }

  private isItemExistInSelectedSpot(itemId_: string) {
    let isExits = false;

    this.state.selectedSpots.every(x => {
      if (x.Id === itemId_) {
        isExits = true;
        return false;
      }

      return true;
    })

    return isExits;
  }

  private isItemExistInOtherElements(itemId_: string) {
    let isExits = false;

    let mappedJson_ = this.state.mappedJson;

    if (mappedJson_) {
      mappedJson_.OtherElements.every(x => {
        if (x.Id === itemId_) {
          isExits = true;
          return false;
        }

        return true;
      })
    }

    return isExits;
  }

  private UpdateZoneSelection = () => {
    let selectedZones = this.state.zoneList.filter(x => x.IsChecked).map(x => x.ZoneGuid);

    let mappedJson_ = this.state.mappedJson;

    if (mappedJson_ != null) {

      mappedJson_.OtherElements.forEach(item_ => {
        item_.IsChecked = item_.ElementType !== "VECTOR" && !item_.isZoneName;
      });

      let spotGuids_ = Object.keys(mappedJson_.Spots);

      spotGuids_.forEach(spotGuid => {
        if (mappedJson_ != null) {
          mappedJson_.Spots[spotGuid].BoxElement.forEach(item_ => {
            item_.IsChecked = !(mappedJson_ != null && selectedZones.indexOf(mappedJson_.Spots[spotGuid].ZoneGuid) !== -1);
          });

          mappedJson_.Spots[spotGuid].OtherElements.forEach(item_ => {
            item_.IsChecked = !(mappedJson_ != null && selectedZones.indexOf(mappedJson_.Spots[spotGuid].ZoneGuid) !== -1);
          });
        }
      });
    }

    this.setState({
      mappedJson: mappedJson_
    }, () => {
      this.updateLocalStorage();
    })
  }

  private setNodeIdAndKeys() {
    let inputUrl = decodeURIComponent(this.props.figmaNodeId);

    let urlSplitForFileName = inputUrl.split("file/");
    let urlSplitForDesignName = inputUrl.split("design/");

    let fileName_ = "";
    let nodeId_ = "";

    if (urlSplitForFileName.length > 1) {
      let figmaFilelist = urlSplitForFileName[1].split("/");

      if (figmaFilelist.length > 0) {
        fileName_ = figmaFilelist[0];
      }
    } else if (urlSplitForDesignName.length > 1) {
      let figmaFilelist = urlSplitForDesignName[1].split("/");

      if (figmaFilelist.length > 0) {
        fileName_ = figmaFilelist[0];
      }
    }


    let urlSplitParameters = inputUrl.split("?");

    if (urlSplitParameters.length > 1) {
      let queryString = new URLSearchParams(urlSplitParameters[1]);

      let code = queryString.get("node-id");

      nodeId_ = code ? code : "";
    }

    this.setState({
      fileName: fileName_,
      NodeId: nodeId_
    }, () => {
      this.getFigmaNodes();
    })
  }

  private getClickedElements(item: IFigmaChildNodes) {
    let mappedJson_ = this.state.mappedJson;
    let filteredElm: IFigmaChildNodes[] = [];
    let selectedItem: IFigmaChildNodes[] = [];

    if (mappedJson_ != null) {

      let isExits = false;

      if ((this.props.progressDetails.progress === EnumInteractiveMapStatus.EditMap ||
        this.props.progressDetails.progress === EnumInteractiveMapStatus.ZoneSelection)) {
        let spotObjects_ = Object.values(mappedJson_.Spots);

        /*spotObjects_.every(x => {
            let index = x.BoxElement.findIndex(b => b.Id == item.Id);

            if (index != -1) {
                isExits = true;
                return false;
            }

            return true;
        })*/

        isExits = spotObjects_.findIndex(x => x.BoxElement[0].Id === item.Id) !== -1;

      }

      if (!isExits) {
        /*let x_ = item.XPosition;
        let y_ = item.YPosition;
        let x1_ = (x_ + item.Width);
        let y1_ = (y_ + item.Height);

        filteredElm = mappedJson_.OtherElements.filter(x => {
            let isMatched = (x.XPosition >= x_ &&
                x.XPosition <= (x1_ - x.Width) &&
                x.YPosition >= y_ &&
                x.YPosition <= (y1_ - x.Height)) && x.ElementType != "RECTANGLE";

            if (isMatched) {
                if (selectedItem.length == 0) selectedItem.push(item);
                selectedItem.push(x);
            }
        });*/

        filteredElm = mappedJson_.OtherElements.filter(x => x.ElementName === item.ElementName);

        if (filteredElm.length > 0) {
          selectedItem = selectedItem.concat(filteredElm);
        }
      }


      // filteredElm.forEach(x => {
      //     selectedItem = selectedItem.concat(this.state.spotData.filter(sd => sd.id == x.Id));
      // })
    }

    return selectedItem;
  }

  private getClickedRectangle(itemId: string) {
    let mappedJson_ = this.state.mappedJson;
    let boxElm: IFigmaChildNodes | null = null;

    if (mappedJson_ != null) {
      let index = mappedJson_.OtherElements.findIndex(x => x.Id === itemId);

      if (index !== -1) {
        boxElm = mappedJson_.OtherElements[index];
      }
    }

    return boxElm;
  }

  private updateSelectedSpot() {
    let isNewSpotMapped = false;
    let selectedSpotGuid = "";

    let selectedItem = this.state.selectedForMapping.filter(x => x.ElementType === "RECTANGLE" &&
      (this.state.item != null && this.state.item.Id === x.Id));
    let mappedJson_ = this.state.mappedJson;


    if (selectedItem.length > 0 && mappedJson_ != null) {
      const filteredElm: IFigmaChildNodes[] = this.state.selectedForMapping;

      let spotValues = Object.values(mappedJson_.Spots);

      filteredElm.every(f => {
        const filteredSpots = spotValues.filter(x => {
          return x.BoxElement.filter(b => b.Id === f.Id).length > 0
        });

        if (filteredSpots.length > 0) {
          selectedSpotGuid = filteredSpots[0].SpotGuid;
          return false;
        }

        return true;
      });

      if (this.props.progressDetails.selectedSpotInfo != null) {

        if (!this.props.progressDetails.selectedSpotInfo.isMappingDone && selectedSpotGuid === "") {

          selectedSpotGuid = this.props.progressDetails.selectedSpotInfo.SpotGuid;
          mappedJson_.Spots[this.props.progressDetails.selectedSpotInfo.SpotGuid] = {
            SpotGuid: selectedSpotGuid,
            FriendlySpotId: this.props.progressDetails.selectedSpotInfo.FriendlySpotId,
            ZoneGuid: this.props.progressDetails.selectedSpotInfo.ZoneGuid,
            BoxElement: filteredElm.filter(x => x.ElementType === "RECTANGLE"),
            OtherElements: filteredElm.filter(x => x.ElementType !== "RECTANGLE"),
            isSaved: false
          };
          isNewSpotMapped = true;
        }
      }

      this.setState({
        mappedJson: mappedJson_
      }, () => {
        if (mappedJson_ != null) {
          this.updateLocalStorage();
          let mappedSpotDetails_: IMappedSpotDetails[] = this.getCompeltedMapped(mappedJson_);

          let spotDetails: ISelectedSpotDetails = {
            mappedSpots: mappedSpotDetails_,
            selectedSpot: selectedSpotGuid,
            isSpotSelectionEnable: selectedItem.length > 0,// filteredElm.length > 0,
            selectedBoxCount: this.state.selectedSpots.filter(x => (x.ElementType === 'RECTANGLE')).length,
            totalSpotsInMap: this.getAvailableSpotsInMap()
          }

          if (isNewSpotMapped && this.props.progressDetails.selectedSpotInfo != null) {
            let selectedSpotDetails = this.props.progressDetails.selectedSpotInfo;

            selectedSpotDetails.isMappingDone = true;

            if (this.props.onProgressUpdate) {
              this.props.onProgressUpdate(EnumInteractiveMapStatus.SpotNumberMapping, selectedSpotDetails, undefined, spotDetails);
            }
          } else if (this.props.onSpotUpdate) {
            this.props.onSpotUpdate(spotDetails);
          }
        }
      })
    }
  }

  private getAvailableSpotsInMap() {

    if (this.state.mappedJson != null) {
      return this.state.mappedJson.OtherElements.filter(x => x.ElementType === 'RECTANGLE' && x.Svg.indexOf("<rect") !== -1).length;
    }

    return 0;
  }

  private getCompeltedMapped(mappedJson_: IFigmaNodes) {
    let mappedSpotDetails_: IMappedSpotDetails[];

    let spotValues = Object.values(mappedJson_.Spots);


    mappedSpotDetails_ = spotValues.map(x => {
      let spotDetails_: IMappedSpotDetails = {
        spotGuid: x.SpotGuid,
        zoneGuid: x.ZoneGuid,
        FriendlySpotId: x.FriendlySpotId
      }

      return spotDetails_
    });

    return mappedSpotDetails_;
  }

  private clearMappedSpot(spotGuid: string) {
    let mappedElm = this.state.mappedJson;

    if (mappedElm != null) {
      let spotElm = mappedElm.Spots[spotGuid];

      //JSON.stringify(mappedElm?.OtherElements).indexOf(x.Id) == -1
      let boxElms = spotElm.BoxElement.filter(x => this.isItemExistInOtherElements(x.Id));
      //JSON.stringify(mappedElm?.OtherElements).indexOf(x.Id) == -1
      let otherElms = spotElm.OtherElements.filter(x => this.isItemExistInOtherElements(x.Id));

      if (boxElms.length > 0) {
        mappedElm.OtherElements.concat(boxElms);
      }

      if (otherElms.length > 0) {
        mappedElm.OtherElements.concat(otherElms);
      }

      delete mappedElm.Spots[spotGuid];

      /*if (this.props.clearedSpot != null) {
          let selectedSpotDetails = this.props.clearedSpot;

          selectedSpotDetails.isMappingDone = true;

          if (this.props.onProgressUpdate) {
              this.props.onProgressUpdate(EnumInteractiveMapStatus.SpotNumberMapping, selectedSpotDetails);
          }
      }*/

      this.setState({
        mappedJson: mappedElm
      }, () => {
        this.updateSelectedSpot();
      })
    }
  }

  private resetMap() {
    let mappedJson_ = this.cleanData();


    let spotDetails: ISelectedSpotDetails = {
      mappedSpots: [],
      selectedSpot: '',
      isSpotSelectionEnable: false,
      selectedBoxCount: 0,
      totalSpotsInMap: this.getAvailableSpotsInMap()
    }

    this.setState({
      mappedJson: mappedJson_,
      selectedSpots: [],
      item: null
    }, () => {
      this.updateLocalStorage();
      let resetOption_: IMapResetOption = {
        Cancel: false,
        ResetAllSpotAttributes: true,
        ResetMap: false
      }

      if (this.props.onProgressUpdate) {
        this.props.onProgressUpdate(EnumInteractiveMapStatus.CompleteLotSetup, undefined, resetOption_);
      }

      if (this.props.onSpotUpdate) {
        this.props.onSpotUpdate(spotDetails);
      }
    })
  }

  private cleanData() {
    let mappedJson_: IFigmaNodes = {
      OtherElements: [],
      Spots: {}
    };
    let storageData = localStorage.getItem(`asset_property_${this.props.selectedFloor ? this.props.selectedFloor.levelId : 0}_${this.props.propertyGuid}`);

    if (storageData) {
      mappedJson_ = JSON.parse(storageData) as IFigmaNodes
    }

    let spotGuids = Object.keys(mappedJson_.Spots);

    spotGuids.forEach(spotGuid => {
      let spotElm = mappedJson_.Spots[spotGuid];

      //JSON.stringify(mappedJson_.OtherElements).indexOf(x.Id) == -1
      let boxElms = spotElm.BoxElement.filter(x => !this.isItemExistInOtherElements(x.Id));
      // JSON.stringify(mappedJson_.OtherElements).indexOf(x.Id) == -1
      let otherElms = spotElm.OtherElements.filter(x => !this.isItemExistInOtherElements(x.Id));

      if (boxElms.length > 0) {
        mappedJson_.OtherElements.concat(boxElms);
      }

      if (otherElms.length > 0) {
        mappedJson_.OtherElements.concat(otherElms);
      }

      delete mappedJson_.Spots[spotGuid];
    });

    mappedJson_.OtherElements.forEach(item_ => {
      item_.IsChecked = false;
    });

    return mappedJson_;
  }

  private resetMapAttributes() {
    let mappedJson_ = this.state.mappedJson;

    if (mappedJson_ != null) {
      let spotGuids = Object.keys(mappedJson_.Spots);

      spotGuids.forEach(spotGuid => {
        if (mappedJson_) {
          let spotElm = mappedJson_.Spots[spotGuid];

          if (this.props.progressDetails.selectedSpotInfo &&
            spotElm.ZoneGuid === this.props.progressDetails.selectedSpotInfo.ZoneGuid) {
            if (spotElm.BoxElement.length > 0) {

              spotElm.BoxElement.forEach(b => {
                b.IsChecked = false;

                if (mappedJson_ != null) {
                  if (JSON.stringify(mappedJson_.OtherElements).indexOf(b.Id) === -1) {
                    mappedJson_.OtherElements.push(b);
                  }
                }
              });

              // mappedJson_.OtherElements = mappedJson_.OtherElements.concat(spotElm.BoxElement);
            }

            if (spotElm.OtherElements.length > 0) {

              spotElm.OtherElements.forEach(o => {
                o.IsChecked = false;

                if (mappedJson_ != null) {
                  if (JSON.stringify(mappedJson_.OtherElements).indexOf(o.Id) === -1) {
                    mappedJson_.OtherElements.push(o);
                  }
                }
              });

              // mappedJson_.OtherElements = mappedJson_.OtherElements.concat(spotElm.OtherElements);
            }

            delete mappedJson_.Spots[spotGuid];
          }
        }
      });

      let spotDetails: ISelectedSpotDetails = {
        mappedSpots: [],
        selectedSpot: '',
        isSpotSelectionEnable: false,
        selectedBoxCount: 0,
        totalSpotsInMap: this.getAvailableSpotsInMap()
      }

      this.setState({
        mappedJson: mappedJson_,
        selectedSpots: [],
        item: null
      }, () => {
        this.updateLocalStorage();
        let resetOption_: IMapResetOption = {
          Cancel: false,
          ResetAllSpotAttributes: false,
          ResetMap: false
        }

        if (this.props.onProgressUpdate) {
          this.props.onProgressUpdate(EnumInteractiveMapStatus.EditMap, undefined, resetOption_);
        }

        if (this.props.onSpotUpdate) {
          this.props.onSpotUpdate(spotDetails);
        }
      })

    }
  }


  private UpdateForNewZone() {
    let mappedSpotDetails_: IMappedSpotDetails[] = [];

    let mappedJson_ = this.state.mappedJson;

    if (mappedJson_ != null) {
      mappedJson_.OtherElements.forEach(item_ => {
        item_.IsChecked = false;
      });

      let spotGuids_ = Object.keys(mappedJson_.Spots);

      spotGuids_.forEach(spotGuid => {
        if (mappedJson_ != null) {
          mappedJson_.Spots[spotGuid].BoxElement.forEach(item_ => {
            item_.IsChecked = false;
          });

          mappedJson_.Spots[spotGuid].OtherElements.forEach(item_ => {
            item_.IsChecked = false;
          });


          let spotDetails_: IMappedSpotDetails = {
            spotGuid: mappedJson_.Spots[spotGuid].SpotGuid,
            zoneGuid: mappedJson_.Spots[spotGuid].ZoneGuid,
            FriendlySpotId: mappedJson_.Spots[spotGuid].FriendlySpotId
          }

          mappedSpotDetails_.push(spotDetails_);
        }
      });
    }

    let spotDetails: ISelectedSpotDetails = {
      mappedSpots: mappedSpotDetails_,
      selectedSpot: '',
      isSpotSelectionEnable: false,
      selectedBoxCount: 0,
      totalSpotsInMap: this.getAvailableSpotsInMap()
    }

    this.setState({
      selectedSpots: [],
      mappedJson: mappedJson_,
      item: null
    }, () => {
      this.updateLocalStorage();
      if (this.props.onSpotUpdate) {
        this.props.onSpotUpdate(spotDetails);
      }
    })
  }

  private resetMapping() {
    let mappedJson: IFigmaNodes = {
      OtherElements: [],
      Spots: {}
    };

    let spots = mappedJson.Spots;
    let spotGuids = Object.keys(spots);
    spotGuids.forEach(x => {
      spots[x].isSaved = true;
    })

    let zoneData = {
      "propertyGuid": this.props.propertyGuid,
      "levelId": this.props.selectedFloor ? this.props.selectedFloor.levelId : 0
    } as any;


    this._privateLotService.ResetMap(zoneData)
      .then(() => {

        localStorage.removeItem(`asset_property_${this.props.selectedFloor ? this.props.selectedFloor.levelId : 0}_${this.props.propertyGuid}`);

        this.setState({
          mappedJson: mappedJson,
          selectedSpots: [],
          item: null
        }, () => {
          this.updateLocalStorage();
          if (this.props.onProgressUpdate) {
            this.props.onProgressUpdate(EnumInteractiveMapStatus.UploadMap);
          }
        })
      });
  }

  private SaveMapping() {
    let mappedJson = this.state.mappedJson;

    if (mappedJson != null) {
      let spots = mappedJson.Spots;

      let spotGuids = Object.keys(spots);

      spotGuids.forEach(x => {
        spots[x].isSaved = true;
      })
    }

    let zoneData = {
      "propertyGuid": this.props.propertyGuid,
      "levelId": this.props.selectedFloor ? this.props.selectedFloor.levelId : 0,
      "mapStatus": EnumMapEditStatus.Configured,
      "mapDataWithSpotAssigned": JSON.stringify(mappedJson)
    } as any;


    this._privateLotService.ConfigureMap(zoneData, false)
      .then(() => {

        localStorage.setItem(`asset_property_${this.props.selectedFloor ? this.props.selectedFloor.levelId : 0}_${this.props.propertyGuid}`, JSON.stringify(mappedJson));

        this.setState({
          mappedJson: mappedJson,
          selectedSpots: [],
          item: null
        }, () => {
          if (this.props.onProgressUpdate) {
            this.props.onProgressUpdate(EnumInteractiveMapStatus.MappingCompleted);
          }
        })
      });
  }

  private getSpotDetails(elmId: string) {
    let mappedSpotDetails_: IMappedSpotDetails = {
      spotGuid: "",
      zoneGuid: "",
      FriendlySpotId: ""
    };

    if (this.state.mappedJson != null) {
      let spots_ = this.state.mappedJson.Spots;
      let mappedSpotGuids_ = Object.keys(spots_);

      mappedSpotGuids_.every(x => {
        const index = spots_[x].BoxElement.findIndex(b => b.Id === elmId);

        if (index !== -1) {
          mappedSpotDetails_.spotGuid = spots_[x].SpotGuid;
          mappedSpotDetails_.zoneGuid = spots_[x].ZoneGuid;
          mappedSpotDetails_.FriendlySpotId = spots_[x].FriendlySpotId;
          return false;
        }

        return true;
      })
    }

    return mappedSpotDetails_;
  }

  private GetSpotData() {
    let mappedJson_ = this.state.mappedJson;
    let spotData_: IFigmaChildNodes[] = [];

    if (mappedJson_ != null) {
      spotData_ = mappedJson_.OtherElements;

      let spotGuids_ = Object.keys(mappedJson_.Spots);

      spotGuids_.forEach(x => {
        if (mappedJson_ != null) {
          spotData_ = spotData_.concat(mappedJson_.Spots[x].BoxElement);
          spotData_ = spotData_.concat(mappedJson_.Spots[x].OtherElements);
        }
      });
    }

    return spotData_;
  }

  private updateLocalStorage() {
    localStorage.setItem(`asset_property_${this.props.selectedFloor ? this.props.selectedFloor.levelId : 0}_${this.props.propertyGuid}`, JSON.stringify(this.state.mappedJson));
  }

  private fetchActiveZone() {
    if (this.props.selectedProperty != null) {
      this._privateLotService.getActiveZone(this.props.selectedProperty.propertyGuid)
        .then(r => r.json())
        .then(r => {
          let zoneList_ = r.map((x: any) => {
            let z_: IMapZones = {
              IsChecked: true,
              ZoneName: x.zoneName,
              ZoneGuid: x.zoneGuid
            };

            return z_;
          });

          this.setState({
            zoneList: this.sortArrayObject(zoneList_, "ZoneName")
          }, () => {
            this.setNodeIdAndKeys();
          });
        });
    }
  }

  private sortArrayObject<T>(array: T[], orderBy: keyof T) {
    return ArrayHelper.sortArrayAlphaNumericObj(array, orderBy, "asc");
  }
}

interface ISpotMapProps {
  propertyGuid: string;
  isSpotMapLoaded: boolean;
  figmaNodeId: string;
  selectedFloor: IFloor | null;
  onDone: (isLoadedFromServer: boolean, mappedJson: IFigmaNodes | null) => void;
  progressDetails: IProgressDetails;
  onProgressUpdate: (status: EnumInteractiveMapStatus, selectedSpotInfo?: ZoneActiveSpot,
                     resetOption?: IMapResetOption, spotDetails?: ISelectedSpotDetails) => void;
  onSpotUpdate: (spotDetails: ISelectedSpotDetails) => void;
  resetState: IMapResetOption;
  clearedSpot: ZoneActiveSpot | null;
  selectedProperty: IPrivateLotProperty | null;
  onCancelUpload: () => void;
  onSpotSelect: (spot: IMappedSpotDetails) => void;
  onSpotDeselect: () => void;
  //checkBookingStatus: (mappedJson: IFigmaNodes | null) => any;
  isToolTipRequired: boolean;
  tooltipFilter?: (spotGuid: string) => any;
  updatedMappedJson: IFigmaNodes | null;
}

interface ISpotMapState {
  zoneList: IMapZones[];
  item: IFigmaChildNodes | null;
  assetImages: any;
  figma_node_list?: any;
  loading: boolean;
  currentZoomSize: number;
  minZoom: number;
  maxZoom: number;
  fileName: string;
  NodeId: string;
  isMapLoaded: boolean;
  isAllZonesChecked: boolean;
  isMappingCompleted: boolean;
  mappedJson: IFigmaNodes | null;
  isEditMap: boolean;
  selectedSpots: IFigmaChildNodes[];
  draggingSpots: IFigmaChildNodes[];
  mouseDownEvent: ISelectedRange | null;
  mouseUpEvent: ISelectedRange | null;
  selectedSpotDetails: INodesMappedSpotDetails | null;
  isLoadedFromServer: boolean;
  selectedForMapping: IFigmaChildNodes[];
  completedMapping: IMappedSpotDetails[];
  containerWidth: number;
  containerHeight: number;
}
