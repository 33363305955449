import {Button, Grid, Link} from "@material-ui/core";
import React from "react";
import BaseService from '../../Services/BaseService';
import {ConfigService} from '../../Services/ConfigService';
import {IScanHistoryResponse} from "../Enforcement/interface";
import {IParkingLeasesModel, IPrivateLotProperty, IRenewResponseModel} from "./interface";
import UtilizationChart from "./UtilizationChart";
import {SyncContext} from "../../Contexts/BatchSyncContext";

export default class PrivateLotsTenantDetails extends React.Component<IPrivateLotsTenantDetailsProps, IPrivateLotsTenantDetailsState> {
  static contextType = SyncContext;

  constructor(props_: IPrivateLotsTenantDetailsProps) {
    super(props_);

    this.state = {
      viewType: props_.viewType,
      propertyGuid: props_.propertyGuid,
      activeInactiveSpots: [],
      parkingStatus: {
        startDate: "",
        endDate: "",
        availableSpotCount: 0,
        bookingCount: 0,
        blockCount: 0
      },
      parkingLeasesModel: null,
      //doughnutChartPlugins:[]
      isTab: false,
      spotStatusList: ['Available', 'Taken', 'Upcoming', 'Unavailable', 'Action Required'],
      latestScanResult: null
    };
  }


  componentDidMount() {
    // this.props.customRef(this);
    //this.doughnutChartPlugins();
    this.fetchActiveInactiveZoneSpot();
    this.fetchParkingLeases();
    this.latestScanResult();
    // this.fetchParkingStatus();
  }

  componentDidUpdate(prevProps: IPrivateLotsTenantDetailsProps) {
    // const { propertyGuid, refresh } = this.props;
    // if (propertyGuid !== prevProps.propertyGuid || refresh) {
    //     this.fetchActiveInactiveZoneSpot();
    //     // this.fetchParkingStatus();
    // }

    // const { propertyGuid, refresh } = this.props;
    // if (propertyGuid !== prevProps.propertyGuid || (prevProps.refresh !== refresh)) {
    //     this.fetchActiveInactiveZoneSpot();
    //     // this.fetchParkingStatus();
    // }

    const {
      propertyGuid, isParkingLeasesUpdated, updateParkingLeasesFlag,
      viewType, refresh, onRefreshed, isScanRefreshed, onScanRefreshed
    } = this.props;
    if ((!isParkingLeasesUpdated && propertyGuid !== prevProps.propertyGuid) || refresh) {
      onRefreshed(false);

      this.fetchActiveInactiveZoneSpot();
      this.fetchParkingLeases();

      if (propertyGuid !== prevProps.propertyGuid)
        this.latestScanResult();

      if (isParkingLeasesUpdated) {
        updateParkingLeasesFlag && updateParkingLeasesFlag(false);
      }
    }

    if (isScanRefreshed) {
      onScanRefreshed(false);
      this.latestScanResult();
    }

    if (prevProps.viewType !== viewType) {
      this.setState({viewType});
    }
  }

  fetchActiveInactiveZoneSpot = () => {
    const {propertyGuid, viewType} = this.props;
    if (!propertyGuid || propertyGuid === "00000000-0000-0000-0000-000000000000" || propertyGuid == "blank") {
      this.setState({activeInactiveSpots: []}, () => {
        this.fetchParkingStatus();
      });
    } else {
      this.fetchGetResponse(`spot/private-lot/get-active-inactive-zonespot/${propertyGuid}`,
        this.fetchActiveInactiveZoneSpotSuccess, viewType === "map");
    }
  }

  fetchActiveInactiveZoneSpotSuccess = (response: Response) => {
    if (response.ok) {
      response.text().then(resp => {
        const activeInactiveSpots: IPrivateLotsActiveInactiveSpot[] = JSON.parse(resp);
        this.setState({activeInactiveSpots}, () => {
          this.fetchParkingStatus();
        });
      })
    } else {
      this.setState({activeInactiveSpots: []}, () => {
        this.fetchParkingStatus();
      });
    }
  }

  fetchParkingStatus = () => {
    const {propertyGuid, viewType} = this.props;
    // console.log(propertyGuid)
    if (!propertyGuid || propertyGuid === "00000000-0000-0000-0000-000000000000" || propertyGuid == "blank") {
      this.defaultParkingStatus();
    } else {
      this.fetchGetResponse(`spot/private-lot/get-privatelot-parking-status/${propertyGuid}`,
        this.fetchParkingStatusSuccess, viewType === "map");
    }
  }

  fetchParkingStatusSuccess = (response: Response) => {
    if (response.ok) {
      response.text().then(resp => {
        const parkingStatus: IPrivateLotParkingStatus = JSON.parse(resp);
        this.setState({parkingStatus});
      })
    } else {
      this.defaultParkingStatus();
    }
  }

  defaultParkingStatus = () => {
    this.setState({
      parkingStatus: {
        startDate: "",
        endDate: "",
        availableSpotCount: 0,
        bookingCount: 0,
        blockCount: 0
      }
    });
  }

  fetchParkingLeases = () => {
    const {propertyGuid, viewType} = this.props;

    if (!propertyGuid || propertyGuid === "00000000-0000-0000-0000-000000000000" || propertyGuid == "blank") {
      this.setState({parkingLeasesModel: null});
    } else {
      this.fetchGetResponse(`spot/private-lot/parking-leases/${propertyGuid}`,
        this.fetchParkingLeasesSuccess, viewType === "map");
    }
  }

  fetchParkingLeasesSuccess = (response: Response) => {
    if (response.ok) {
      response.text().then(resp => {
        const parkingLeases: IParkingLeasesModel = JSON.parse(resp);
        this.setState({parkingLeasesModel: parkingLeases});
      })
    } else {
      this.setState({parkingLeasesModel: null});
    }
  }

  latestScanResult = () => {
    const {propertyGuid, viewType} = this.props;

    if (!propertyGuid || propertyGuid === "00000000-0000-0000-0000-000000000000" || propertyGuid == "blank") {
      this.setState({latestScanResult: null});
    } else {
      this.fetchPostResponse(`spot/parker-enforcement/get-latest-scan`,
        {propertyGuid, dateFormat: "dd MMM yyyy h:mm tt"},
        this.latestScanResultSuccess, viewType === "map");
    }
  }

  latestScanResultSuccess = (latestScanResultResponse: Response) => {
    if (latestScanResultResponse.ok) {
      latestScanResultResponse.json().then(response => {
        this.setState({latestScanResult: response});
      });
    }
  }

  fetchGetResponse = (url: string, successCallback: any, hideLoader: boolean = false) => {
    return ConfigService.getBaseUrl()
      .then(baseUrl => {
        BaseService.sendRequest(`${baseUrl}${url}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }, undefined, hideLoader).then(response => {
          successCallback && successCallback(response);
        });
      });
  }

  fetchPostResponse = (url: string, postData: any, successCallback: any, hideLoader: boolean = false) => {
    return ConfigService.getBaseUrl()
      .then(baseUrl => {
        return BaseService.sendRequest(`${baseUrl}${url}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        }, undefined, hideLoader).then(response => {
          successCallback && successCallback(response);
        });
      });
  }

  spotLegends = (item: any) => {
    if (item === 0) {
      return 0;
    }
    if (item === 1) {
      return "1px solid #00AF85";
    }
    // if (item === 2) {
    //     return 0
    // }
    if (item === 2) {
      return 0
    }
    if (item === 3) {
      return "1px dashed #323232"
    }
    if (item === 4) {
      return "1px solid #DC2626"
    }

  }
  spotLegendsBG = (item: any) => {
    if (item === 0) {
      return "#00AF85";
    }
    if (item === 1) {
      return "#FFFFFF";
    }
    // if (item === 2) {
    //     return "#79C2D8"
    // }
    if (item === 2) {
      return "#F7C10F"
    }
    if (item === 3) {
      return "#8A959E"
    }
    if (item === 4) {
      return "rgba(220, 38, 38, 0.3)"
    }

  }
  spotStatusUI = (isTab: boolean, viewType: string, spotStatusList: string[], parkingStatus: any) => {
    // console.log("=================viewType2====================" + viewType)
    return (
      <div style={{height: 'auto', borderRadius: 10, backgroundColor: 'white', marginTop: 16}}>
        <div style={{display: 'flex'}}>
          <button onClick={() => {
            this.setState({viewType: "map"})
          }} style={{
            fontWeight: 700,
            textAlign: 'center',
            fontSize: 12,
            color: '#323232',
            flexGrow: 1,
            borderTopLeftRadius: 10,
            border: 0,
            backgroundColor: viewType === 'map' ? 'white' : '#EEEEEE',
            padding: "11.45px 18.5px 11.45px 18.5px"
          }}>
            Spot Status
          </button>
          <button onClick={() => {
            this.setState({viewType: "list"})
          }} style={{
            fontWeight: 700,
            textAlign: 'center',
            fontSize: 12,
            color: '#323232',
            flexGrow: 1,
            borderTopRightRadius: 10,
            border: 0,
            backgroundColor: (viewType === 'list' || viewType === '') ? 'white' : '#EEEEEE',
            padding: "11.45px 18.5px 11.45px 18.5px"
          }}>
            Parking Stats
          </button>
        </div>
        {
          <div style={{
            paddingLeft: 26,
            paddingTop: 22,
            paddingBottom: 10,
            'display': `${viewType === "map" ? "block" : "none"}`
          }}>
            {
              spotStatusList.map((item, index) => (
                <div style={{display: 'flex', marginBottom: 12}}>
                  <div style={{
                    border: this.spotLegends(index),
                    height: 16,
                    width: 16,
                    borderRadius: 2,
                    backgroundColor: this.spotLegendsBG(index)
                  }}></div>
                  <div style={{
                    fontWeight: 400,
                    fontSize: 15,
                    paddingLeft: 10,
                    lineHeight: 1,
                    justifyContent: 'center'
                  }}>{item}</div>
                </div>
              ))
            }
          </div>
        }
        {
          <div style={{
            paddingLeft: 26,
            paddingTop: 22,
            paddingBottom: 22,
            'display': `${(viewType === 'list' || viewType === '') ? "block" : "none"}`
          }}>
            <div>
              <div><b>{parkingStatus.availableSpotCount}</b>&nbsp;&nbsp;Available Spots</div>
              <div><b>{parkingStatus.bookingCount}</b>&nbsp;&nbsp;Booked Spots</div>
              <div><b>{parkingStatus.blockCount}</b>&nbsp;&nbsp;Blocked Spots</div>
            </div>
          </div>
        }
      </div>
    );
  }

  render() {
    const {
      activeInactiveSpots,
      parkingStatus,
      parkingLeasesModel,
      isTab,
      spotStatusList,
      viewType,
      latestScanResult
    } = this.state;
    return (
      <>
        {this.props.isMultiSelectedProperty && (
          <div className="multi-select-blocker"></div>
        )}
        <div className="right-panel-subcontainer">
          <div className="c-rp-c-common">
            <Button
              type="submit"
              fullWidth
              disabled={this.context.info.syncing}
              style={{
                width: '100%',
                height: '44px',
                backgroundColor: '#00AF85',
                fontSize: "15px",
                fontFamily: 'Manrope',
                fontWeight: 700,
                borderRadius: '5px',
                color: 'white',
                borderColor: '#00AF85',
                marginBottom: '10px'
              }}
              onClick={(e) => {
                this.props.onTriggerYardi()

                // if (!this.props.isMultiSelectedProperty && this.props.onAddSpotViewOpen) {
                //     this.props.onAddSpotViewOpen();
                // }
              }}
            >
              {this.props.selectedProperty != null && this.props.selectedProperty.isYardiProperty == true ? "Resync Lot" : "Sync Lot"}
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              color="primary"
              disabled={this.context.info.syncing}
              // className='bl-s-button mt-4'
              onClick={(e) => {
                if (!this.props.isMultiSelectedProperty && this.props.onAddSpotViewOpen) {
                  this.props.onAddSpotViewOpen();
                }
              }}
            >
              Block Spot
            </Button>

            {
              (viewType === "list" || viewType === "") && this.spotStatusUI(isTab, viewType, spotStatusList, parkingStatus)
            }
            <div className="sp-le-c-10-R"
                 style={{'display': `${(viewType === "list" || viewType === "") ? "block" : "none"}`}}>
              <h5 className="text-center">Utilization</h5>
              <div className="d-flex flex-column">
                <UtilizationChart
                  propertyGuid={this.props.propertyGuid}
                  refresh={this.props.refresh}
                />
              </div>
            </div>


            {<div className="sp-le-c-10-R"
                  style={{'display': `${(viewType === "list" || viewType === "") ? "block" : "none"}`}}>
              <h5>Parking Passes</h5>
              <div>

                {activeInactiveSpots?.length > 0 && activeInactiveSpots.map((item) => (
                  <div>
                    <b>{item.zoneName}</b>&nbsp;&nbsp;{item.active}&nbsp; Active&nbsp; |&nbsp; {item.inactive}&nbsp; Inactive
                  </div>
                ))}
                {activeInactiveSpots?.length === 0 &&
                    <div>&nbsp;</div>}
              </div>
            </div>
            }


            {/* <div className="sp-le-c">
                            <h5>Parking Stats</h5>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    Spot Stats
                                </div>
                                <div>
                                    Parking Stats
                                </div>

                            </div>
                            <div>
                                <div><b>{parkingStatus.availableSpotCount}</b>&nbsp;&nbsp;Available Spots</div>
                                <div><b>{parkingStatus.bookingCount}</b>&nbsp;&nbsp;Booked Spots</div>
                                <div><b>{parkingStatus.blockCount}</b>&nbsp;&nbsp;Blocked Spots</div>
                            </div>
                        </div> */}
            {
              viewType === "map" && this.spotStatusUI(isTab, viewType, spotStatusList, parkingStatus)
            }
            {<div className="sp-le-c-10-R" style={{'display': `${viewType === "map" ? "block" : "none"}`}}>
              <Grid container>
                <Grid item>
                  <h5>Parking Leases</h5>
                </Grid>
                {parkingLeasesModel && parkingLeasesModel?.renewResponseModel?.length > 0 &&
                    <Grid item xs className="sp-le-c-right">
                        <Link href="javascript:void(0)" onClick={() => {
                          this.props.onRenew(parkingLeasesModel?.renewResponseModel ?? []);
                        }} underline="always">Renew</Link>
                    </Grid>
                }
              </Grid>
              <div>
                {parkingLeasesModel?.parkingLeases?.map((item) => (
                  <div>{item}</div>
                ))}
                {(!parkingLeasesModel || parkingLeasesModel?.parkingLeases?.length === 0) && <>
                    <div>0 Leases expire in 7 days</div>
                    <div>0 Leases expire in 30 days</div>
                    <div>0 Leases expire in 60 days</div>
                </>
                }
              </div>
            </div>
            }
            {
              viewType === "map" && <div className="sp-le-c-10-R">
                    <div>
                        <h5>Synced on</h5>
                        <div>
                            <div>{latestScanResult?.lastSyncedDate ? latestScanResult.lastSyncedDate : "Not yet synced"}</div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <h5>Enforcement Scan Completed on</h5>
                        <div>
                            <div>{latestScanResult?.lastEnforcementScanDate ? latestScanResult.lastEnforcementScanDate : "No enforcement scan"}</div>
                        </div>
                    </div>
                </div>
            }
          </div>
        </div>
      </>
    )
  }

  // doughnutChartPlugins = () => {
  //     debugger;
  //     plugins = [{
  //         afterUpdate: function (chart: any) {
  //             var width = chart.width,
  //                 height = chart.height,
  //                 ctx = chart.ctx;
  //             ctx.restore();
  //             var fontSize = (height / 160).toFixed(2);
  //             ctx.font = fontSize + "em sans-serif";
  //             ctx.textBaseline = "top";
  //             var text = "90 %",
  //                 textX = Math.round((width - ctx.measureText(text).width) / 2),
  //                 textY = height / 2;
  //             ctx.fillText(text, textX, textY);
  //             ctx.save();
  //         },


  //     }]

  // }


}

interface IPrivateLotsTenantDetailsProps {
  viewType: string,
  propertyGuid?: string;
  onAddSpotViewOpen: () => void;
  onTriggerYardi: () => void;
  isMultiSelectedProperty: boolean;
  onRefreshed: (state: boolean) => void;
  refresh: boolean;
  onRenew: (renewResponseModel: IRenewResponseModel[]) => void;
  isParkingLeasesUpdated?: boolean;
  updateParkingLeasesFlag: (isParkingLeasesUpdated: boolean) => void;
  selectedProperty: IPrivateLotProperty | null;
  isScanRefreshed: boolean;
  onScanRefreshed: (isScanRefreshed: boolean) => void;
}

interface IPrivateLotsTenantDetailsState {
  viewType: string,
  propertyGuid?: string;
  activeInactiveSpots: IPrivateLotsActiveInactiveSpot[],
  parkingStatus: IPrivateLotParkingStatus,
  parkingLeasesModel: IParkingLeasesModel | null,
  isTab: boolean,
  spotStatusList: string[],
  latestScanResult: IScanHistoryResponse | null
}

interface IPrivateLotsActiveInactiveSpot {
  zoneName: string;
  active: 0,
  inactive: 0
}

interface IPrivateLotParkingStatus {
  startDate: string;
  endDate: string;
  availableSpotCount: 0;
  bookingCount: 0;
  blockCount: 0;
}
