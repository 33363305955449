import React from "react";
import DataGrid from "../../Components/DataGrid/DataGrid";
import { IDataGridColumn, IAppliedFilter, DataGridFilterType } from "../../Components/DataGrid/Interfaces";
import { format } from 'date-fns';
import { Tooltip } from "@material-ui/core";
import { IPropertyBasics, ISpot, ISpotFilter } from "./Interfaces";
import { RightPanelState } from "../../Models/CommonInterfaces";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import BaseSpotList from "./BaseSpotList";
import ArrayHelper from "../../Helpers/ArrayHelper";
import { ShowAlert, ShowAlertwithConfirm, ShowAlertwithMultipleConfirm } from "../../Common/ShowAlert";
import { GetUserDetailsService } from "../../Services/GetUserDetailsService";
import ObjectHelper from "../../Helpers/ObjectHelper";
import BaseService from "../../Services/BaseService";
import StringHelper from "../../Helpers/StringHelper";
import { PreserveFilters } from "../../Common/PreserveFilters";
import { CheckFeaturePermission } from "../../Common/CheckScreenPermission";

export default class SpotList extends BaseSpotList {
    private _spotListColumns: IDataGridColumn<ISpot>[] = [
        {
            key: "spotStatusId",
            name: "STATUS",
            contentProvider: (row_: ISpot) => {
                return (
                    <div className={'list-status ' + ((row_.spotStatusId === 1) ? 'active' : 'inactive')}></div>
                );
            }
        },
        {
            key: "friendlySpotId",
            name: "Spot Number",
            getComparator: ArrayHelper.getNumberInStringComparator
        },
        {
            key: "propertyName",
            name: "Property Name",
            getComparators: ArrayHelper.sortArrayObject,
            alphaNumeric: true
        },
        {
            key: "zoneName",
            name: "Zone Name",
            getComparators: ArrayHelper.sortArrayObject,
            alphaNumeric: true
        },
        // {
        //     key: "propertyCity",
        //     name: "City",
        //     getComparator: ArrayHelper.getNumberInStringComparator
        // },
        {
            key: "propertyOwnerName",
            name: "Owner",
            getComparator: ArrayHelper.getNumberInStringComparator
        },
        // {
        //     key: "propertyCompanyName",
        //     name: "Manager",
        //     getComparator: ArrayHelper.getNumberInStringComparator
        // },
        {
            key: "ownedBy",
            name: "Owned By",
            contentProvider: (row_: ISpot) => {
                /*if (row_.spotTypeId == 2) {
                    return <span>Tenant</span>
                }
                else {
                    return <span>Property</span>
                }*/
                return <span>{row_.ownedBy}</span>
            },
            getComparator: ArrayHelper.getNumberInStringComparator
        },
        {
            key: "spotTenantName",
            name: "Tenant",
            contentProvider: (row_: ISpot) => {
                if (row_.spotTypeId == 2 && row_.spotTenantGuid) {
                    if (row_.claimStatusId == 3) {
                        return <span>{row_.spotTenantName}</span>;
                    }
                    else {
                        return <span><Tooltip title="Waiting for approval"><ErrorOutlineIcon htmlColor="#ff0000" /></Tooltip> {row_.spotTenantName}</span>;
                    }
                }

                return <span></span>;
            }
        },
        {
            key: "nextReservation",
            name: "Next Res",
            contentProvider: (row_: ISpot) => {
                /*let upcomingReservationDate: Date = row_.reservationDates.filter(r => r > new Date())[0];

                if (upcomingReservationDate) {
                    return <span>
                        {format(upcomingReservationDate, "MMM dd yyyy")}
                    </span>
                }
                return (<span></span>);*/

                if (row_.nextReservation) {
                    return <span>
                        {format(row_.nextReservation, "MMM dd yyyy")}
                    </span>
                }
                return (<span></span>);
            }
        },
        {
            key: "lastReservation",
            name: "Last Res",
            contentProvider: (row_: ISpot) => {
                /*let pastReservationDate: Date = row_.reservationDates.filter(r => r < new Date())[0];

                if (pastReservationDate) {
                    return <span>
                        {format(pastReservationDate, "MMM dd yyyy")}
                    </span>
                }
                return (<span></span>);*/
                if (row_.lastReservation) {
                    return <span>
                        {format(row_.lastReservation, "MMM dd yyyy")}
                    </span>
                }
                return (<span></span>);
            }
        }
    ];

    private revenuecolumn: IDataGridColumn<ISpot> = {
        key: "revenve",
        name: "Revenue YTD",
        textAlignment: "right",
        contentProvider: (row_: ISpot) => {
            return (
                <span>
                    ${row_.revenue.toFixed(2)}
                </span>
            );
        }
    }

    protected _getSpots = () => {
        return this._spotService.getSpotList()
            .then(r => r.json());
    }

    protected _getAllSpots = async (contentParser_: (spots_: ISpot[]) => ISpot[], onProgress_: (spots_: ISpot[], progress_: number) => void) => {
        let remainder = "";
        return BaseService.asyncResponse<ISpot>(
            this._spotService.getSpotList(),
            (content_, contentLength, receivedLength) => {
                let spots: ISpot[] = [];
                
                if (content_){
                    content_ = remainder + content_;
    
                    if (content_ && contentLength != receivedLength) {
                        let lastIndex = content_.lastIndexOf(",{\"spotGuid\"");
                        if (lastIndex == -1) {
                            remainder = content_;
                            // content_ = "";
                        }
                        else {
                            remainder = "[" + StringHelper.trimStart(content_.substring(lastIndex), ',');
                            content_ = content_.substring(0, lastIndex) + "]";
                        }
                    }
    
                    if (content_) {
                        try {
                            spots = JSON.parse(content_);
                        } catch (e) {}
                    }
                }
                
                return contentParser_(spots);
            }, onProgress_);
    }

    protected _renderDataGrid = () => {
        if (!this._isBcUser){
            var revenue = this._spotListColumns.filter(x => x.key == 'revenve');
            if (revenue.length == 0){
                this._spotListColumns.push(this.revenuecolumn);
            }
        }

        if (this._isTenantUser) {
            return <DataGrid
                title="Spot"
                data={this.state.subFilteredSpots}
                columns={this._spotListColumns}
                appliedFilter={this.state.appliedFilter}
                defaultSortColumnKey="friendlySpotId"
                loaderPercentage={this.state.loaderPercentage}
                onFilterTagRemoved={(filter_: IAppliedFilter) => {
                    this.setState({
                        appliedFilter: filter_ as ISpotFilter,
                        _autoApplyFilterKey: new Date().getTime()
                    },()=>{
                        PreserveFilters.preserveFilter('spots',this.state.appliedFilter)
                    })
                }}
                onMoreClick={(spot_: ISpot) => {
                    this.setState({
                        selectedSpot: spot_,
                        rightPanelState: RightPanelState.Details,
                        hasUnsavedChanges: false
                    });
                }}
                onRowClick={spot_ => {
                    this.setState({
                        selectedSpot: spot_,
                        rightPanelState: RightPanelState.Details,
                        hasUnsavedChanges: false
                    });
                }}
            />
        }
        else {
            return <DataGrid
                title="Spot"
                data={this.state.subFilteredSpots}
                columns={this._spotListColumns}
                appliedFilter={this.state.appliedFilter}
                defaultSortColumnKey="friendlySpotId"
                loaderPercentage={this.state.loaderPercentage}
                onFilterTagRemoved={(filter_: IAppliedFilter) => {
                    this.setState({
                        appliedFilter: filter_ as ISpotFilter,
                        _autoApplyFilterKey: new Date().getTime()
                    });
                }}
                multiPopoverItems={[
                    {
                        label: "Active",
                        key: "active"
                    },
                    {
                        label: "Inactive",
                        key: "inactive"
                    }
                ]}
                onMultiPopoverClick={(actionKey_, items_) => {
                    if (actionKey_ == "active") {
                        let guids: string[] = [];
                        for (let item of items_) {
                            item.spotStatusId = 1;
                            guids.push(item.spotGuid);
                        }
                        this._spotService.updateMultiSpotStatus(guids, 1);
                    }
                    else if (actionKey_ == "inactive") {
                        let guids: string[] = [];
                        for (let item of items_) {
                            if (item.spotStatusId != 2) {
                                // item.spotStatusId = 2;
                                guids.push(item.spotGuid);
                            }
                        }

                        if (guids.length > 0) {
                            this._inActiveSpot(guids, items_);
                        } else {
                            ShowAlert('Error!', 'Please select the active spot(s) to inactive', 'error');
                        }
                    }
                }}
                onMoreClick={(spot_: ISpot) => {
                    if (spot_.claimStatusId != 3 && spot_.spotTenantGuid) {
                        let options_: any[] = [
                            {
                                label: "Approve Tenant",
                                key: "approve"
                            },
                            {
                                label: "Reject Tenant",
                                key: "reject"
                            },
                            {
                                label: "Show Details",
                                key: "details"
                            }
                        ];

                        //this._isAdmin
                        if (CheckFeaturePermission('"add-edit-spot"')) {
                            options_.push({
                                label: "Delete Spot",
                                key: "delete"
                            })
                        }

                        return options_;
                    }
                    else {
                        let options_: any[] = [
                            {
                                label: "Show Details",
                                key: "details"
                            }
                        ];

                        if (CheckFeaturePermission('"add-edit-spot"')) {
                            options_.push({
                                label: "Delete Spot",
                                key: "delete"
                            });
                        }

                        return options_;
                    }
                }}
                onPopoverClick={(key_, spot_) => {
                    if (key_ == "details") {
                        this.setState({
                            selectedSpot: spot_,
                            rightPanelState: RightPanelState.Details,
                            hasUnsavedChanges: false
                        });
                    }
                    else if (key_ == "approve") {
                        this._approveTenant(spot_);
                    }
                    else if (key_ == "reject") {
                        this._rejectTenant(spot_);
                    }
                    else if (key_ == "delete") {
                        this._deleteSpot(spot_);
                    }
                }}
                onRowClick={spot_ => {
                    this.setState({
                        selectedSpot: spot_,
                        rightPanelState: RightPanelState.Details,
                        hasUnsavedChanges: false
                    });
                }}
            />
        }
    }
}