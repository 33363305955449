import { IDataRow, IAppliedFilter, IColumnFilter } from "../../Components/DataGrid/Interfaces";
import { PropertyScheduleModule } from "../../Models/PropertyScheduleModule";
import { DateTimeHelper } from "../../Helpers/DateTimeHelper";
import { ISingleScheduleData } from "../../Components/ScheduleComponent";
import { IFileInfo } from "../../Components/BasicComponents/FileUploadModal";

export interface ISpotBasics {
    spotGuid: string;
    spotName: string;
}

export interface IZoneBasics {
    zoneGuid: string;
    zoneName: string;
}

export interface ISpot extends IDataRow {
    // Post Data
    propertyGuid: string;
    spotTenantGuid: string;
    zoneGuid: string;
    zoneName: string;
    friendlySpotIds: string[];
    otherFeatures: string;
    spotTypeId: number;
    spotStatusId: number;
    claimStatusId: number;


    // Read only data
    spotGuid: string;
    friendlySpotId: string;
    propertyName: string;
    propertyCity: string;
    propertyOwnerName: string;
    propertyCompanyName: string;
    spotTenantName: string;
    revenue: number;
    changedUtc: Date;
    propertyManagerNames: string[];
    reservationDates: Date[];

    // Extra info
    schedules: ISingleScheduleData[];

    // don't need
    spottypename: string;
    spotstatusname: string;

    //Added column to avoid sorting issue
    ownedBy: string;
    nextReservation?: Date;
    lastReservation?: Date;
}

export interface ISpotFilter extends IAppliedFilter {
    property: IColumnFilter;
    city: IColumnFilter;
    owner: IColumnFilter;
    manager: IColumnFilter;
    tenants: IColumnFilter;
    reservation: IColumnFilter;
    revenue: IColumnFilter;
    spotType: IColumnFilter;
    claimStatus: IColumnFilter;
}

export interface IBasicUser {
    applicationUserGuid: string;
    name: string;
}

export interface IPropertyBasics {
    propertyGuid: string;
    propertyName: string;
    isActive?: boolean;
    propertyTimeZone?: string;
    openTimeUtc?: number;
    closeTimeUtc?: number;
    openTimeString?: string;
    closeTimeString?: string;
    address?: string;
    city?: string;
    latitude?: number;
    longitude?: number;
    propertyDetailedName?: string;
    createdUtc?: number;
    propertyAddress1?: string;
    defaultVisitorParkingPasses?: number;
    isYardiProperty?: boolean;
}

export interface IPropertyDetails {
    propertyGuid: string;
    propertyName: string;
    propertyAbout: string;
    propertyAddress1: string;
    propertyAddress2: string;
    propertyCity: string;
    propertyState: string;
    propertyCountry: string;
    propertyZipCode: string;
    propertyQuantity: number;
    propertyMinPrice: number;
    propertyMaxPrice: number;
    propertyAmenities: number[];
    propertySchedule: PropertyScheduleModule[];
    propertyOwnerName: string;
    propertyOwnerGuid: string;
    managementCompanyName: string;
    managementCompanyGuid: string;
    propertyStatusId: number;
    images: IFileInfo[];
    spots: ISpotBasics[];
    otherFeatures: string;
}

export interface ISpotFeature {
    spotFeatureId: number;
    featureName: string;
    featureDescription?: string | null;
    isActive?: boolean | null;
}

export interface IAdditionalFeature {
    i: string;
    n: string;
    a: number;
    e: number;
}

export interface ISpotBlockData {
    dailySpotScheduleGuid: string;
    spotGuid: string;
    startUtc: string;
    endUtc: string;
    start: Date;
    end: Date;
    scheduleStatusId: number;
}

export interface ISpotBlockRequest {
    date: string;
    startTime: number;
    endTime: number;
}

export interface ISpotBlockDataV2{
    dontRepeatEndDate?: Date;
    endTimeString: string;
    metadata: string;
    monthEndDate?: Date;
    propertyGuid: string;
    reasonType: number;
    repeatEndDate?: Date;
    repeatType: number;
    spotBlockGuid: string;
    spotGuid: string;
    startDate: Date;
    startTimeString: string;
    weeks: number[];
    weekDays: string[];
}

// export interface IAdditionalSpotFeature {
//     i: string;
//     a: number;
// }

export function extractScheduleLimitationsFromProperty(propertyDetails_: IPropertyDetails | PropertyData | null) {
    let scheduleLimitation: {
        allowedDays: number[]
        minTime: number,
        maxTime: number,
        minPrice: number,
        maxPrice: number
    } = {
        allowedDays: [1, 2, 3, 4, 5, 6, 7],
        minTime: 0,
        maxTime: 1439,
        minPrice: 0,
        maxPrice: 20
    };
    if (propertyDetails_) {
        console.log(propertyDetails_)
        scheduleLimitation.allowedDays = propertyDetails_.propertySchedule.map(ps => ps.dayOfWeekId);
        scheduleLimitation.minPrice = propertyDetails_.propertyMinPrice;
        scheduleLimitation.maxPrice = propertyDetails_.propertyMaxPrice

        if (propertyDetails_.propertySchedule.length) {
            scheduleLimitation.minTime = DateTimeHelper.timeStringToMinutes(propertyDetails_.propertySchedule[0].openTimeUtc);
            scheduleLimitation.maxTime = DateTimeHelper.timeStringToMinutes(propertyDetails_.propertySchedule[0].closeTimeUtc);
        }
    }

    return scheduleLimitation;
}

export function getScheduleSummaryText(schedule_: ISingleScheduleData): string[] {
    let daySelectionLabel = "None";
    let timeSelectionLabel = "N/A";

    if (schedule_.selectedDays && schedule_.selectedDays.length) {
        let selectedDays = schedule_.selectedDays.sort();

        // Check if single day
        if (selectedDays.length == 1) {
            daySelectionLabel = DateTimeHelper.midWeekDayLabels[selectedDays[0] - 1];
        }
        // Check if all days
        else if (selectedDays.length == 7) {
            daySelectionLabel = "All Days";
        }
        // Check if in sequence
        else if (selectedDays[0] + selectedDays.length - 1 == selectedDays[selectedDays.length - 1]) {
            daySelectionLabel = `${DateTimeHelper.shortWeekDayLabels[selectedDays[0] - 1]}-${DateTimeHelper.shortWeekDayLabels[selectedDays[selectedDays.length - 1] - 1]}`;
        }
        // Show as comma seperated
        else {
            daySelectionLabel = selectedDays.map(d => DateTimeHelper.shortWeekDayLabels[d - 1]).join(",")
        }
    }
    if (schedule_.selectedTimeRange) {
        timeSelectionLabel = `${DateTimeHelper.minutesToTimeString(schedule_.selectedTimeRange.start, false)} - ${DateTimeHelper.minutesToTimeString(schedule_.selectedTimeRange.end, false)}`;
    }

    return [daySelectionLabel, timeSelectionLabel];
}

export interface PropertyData {
    propertyGuid: string,
    propertyName: string,
    propertyAbout: string,
    propertyAddress1: string,
    propertyAddress2: string,
    propertyCity: string,
    propertyState: string,
    propertyLatitude: number,
    propertyLongitude: number,
    propertyCountry: string,
    propertyZipCode: string,
    propertyTimeZone: string,
    propertyQuantity: number,
    propertySpotQuantity: number,
    propertyRedeem: string,
    propertyInstructions: string,
    propertyMinPrice: number,
    propertyMaxPrice: number,
    propertyAmenities: number[],
    propertySchedule: PropertyScheduleModule[],
    propertyOwnerName: string,
    propertyOwnerGuid: string,
    propertyCompanyName: string,
    propertyCompanyGuid: string,
    propertyCompanyAddress1: string,
    propertyCompanyAddress2: string,
    propertyCompanyCity: string,
    propertyCompanyCountry: string,
    propertyCompanyState: string,
    propertyCompanyZipCode: string,
    propertyStatusId: number,
    revenueYTD: number,
    RevenueText: string,
    images: IFileInfo[],
    originalImages: IFileInfo[],
    otherFeatures: string;
    propertyDistributionType: number;
    propertyDisbursementSettingGuid: string;
    propertyGrydPercentage: number;
    propertyPropertyPercentage: number;
    propertySpotOwnerPercentage: number;
    dailyBookingEnabled: boolean;
    dailyTimeBlocks: IDailyTimeBlock[];
    weeklyBookingEnabled: boolean;
    weeklyTimeBlocks: IWeeklyTimeBlock[];
    buildingContacts: string[];
    buildingContactNames: string[];
}

export interface IDailyTimeBlock {
    propertyScheduleGuid: string;
    propertyGuid: string;
    scheduleId: number;
    scheduleName: string;
    bookingPrice: number;
    isActive: boolean;
    originalEndTime: string;
    originalStartTime: string;
    startTime: string;
    endTime: string;
    originalEndTimeConverted: number;
    originalStartTimeConverted: number;
    minTime: number;
    maxTime: number;
}

export interface IWeeklyTimeBlock {
    bookingPrice: number;
    isActive: boolean;
    propertyGuid: string;
    propertyMonthlyRateGuid: string;
    weeklyTimeBlockId: number;
    scheduleName: string;
    stripePriceId: string;
    stripeProductId: string;
    description: string;
}