import React from 'react';
import { Grid, Tooltip, IconButton, Button, Tab, Tabs, TextField, Switch, Dialog, AppBar, Toolbar, Typography, Slide } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import { NewUserDetails, IUser } from '../../Models/NewUserDetails';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import "../Profile/ProfileStyle.css";
import * as CommonString from '../../CommonString';
import { ConfigService } from '../../Services/ConfigService';
import LoaderComponent from '../../Components/LoaderComponent';
import { ShowAlert } from '../../Common/ShowAlert';
import { GetUserDetailsService } from "../../Services/GetUserDetailsService";
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { truncateSync } from 'fs';

import { PlaidLink, usePlaidLink } from 'react-plaid-link';
import BaseService from '../../Services/BaseService';

const PLAID_PUBLIC_KEY = '10aa11248ead62acb670f8610befdb';
const APP_NAME = 'GRYD PARK';
const ENVIRONMENT = 'sandbox';
const PRODUCT = ['auth', 'transactions'];

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface ProfileProps { }
interface ProfileState {
    showBlocker: boolean;
    isVerified: boolean;
    token: string;
    email: string;
    isAdminUser: boolean;

}

class Payment extends React.Component<ProfileProps, ProfileState> {
    constructor(props: ProfileProps) {
        super(props);

        var isAdmin = false;
        var verify: boolean = false;
        let validToken = localStorage.getItem('token');

        if (typeof (Storage) !== 'undefined') {
            var _localData = localStorage.getItem('userdata');

            if (_localData != null && _localData !== '') {
                var _data = JSON.parse(_localData);
                var roles: any = _data.userRoleID;
                verify = _data.isPlaidVerified;

                if(roles.includes(1) || roles.includes(2))
                {
                    isAdmin = true;
                }
                else
                isAdmin = false;

                if(verify || (validToken == 'success')){
                    localStorage.setItem('token','success');
                }
            }
        }

        validToken = localStorage.getItem('token');

        this.state = {
            showBlocker: false,
            isVerified: ((validToken == 'success') ? true : false),
            token: String(validToken),
            email: '',
            isAdminUser: isAdmin

        }
    }

    public componentDidMount = () => {

        if (typeof (Storage) !== 'undefined') {
            var _localData = localStorage.getItem('userdata');

            if (_localData != null && _localData !== '') {
                var _data = JSON.parse(_localData);
                this.setState({
                    email: _data.emailId
                });

            }
        }
       
    }

    public onSuccess = (token: any, metadata: any) => {
        // send token to server
        this.setState({
            showBlocker: true
        });
        console.log(JSON.stringify(metadata));

        console.log('Public Token: ' + token);
        console.log('Customer-selected account ID: ' + metadata.account_id);

        this.sendDataToServer(token, metadata.accounts[0]['id']);
    };

    public sendDataToServer = (token: string, accountId: string) => {

        const data = { public_token: token, account_id: accountId, email: this.state.email };

        ConfigService.getNodeBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url+'authenticate', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            // .then(response => response.json())
            .then(data => {
                if (data.ok) {
                    data.json().then(r => {
                        console.log(r);
                        if (r == "success") {
                            this.setState({
                                isVerified: true,
                                token: r,
                                showBlocker: false
                            });
                            localStorage.setItem('token', r);
                        }
                        else {
                           this.showError();
                        }
                    })
                }
                else
                {
                    this.showError();
                }
                console.log('Success:', data);
                this.setState({
                    showBlocker: false
                });
            })
            .catch((error) => {
                console.error('Error:', JSON.stringify(error));
                this.showError();
            });
        });

    }

    public showError() {
        this.setState({
            isVerified: false,
            showBlocker: false
        });
        ShowAlert('Failed!', 'Failed to verify your bank account', 'error');
    }

    public disburseError() {
        this.setState({
            isVerified: false,
            showBlocker: false
        });
        ShowAlert('Failed!', 'Failed to send payout', 'error');
    }

    public disburse(){

        this.setState({
            showBlocker: true
        });

        ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url+'disbursement/get', {
            method: 'GET', // or 'PUT'
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            // .then(response => response.json())
            .then(data => {
                if (data.ok) {
                    data.json().then(r => {
                        console.log(r);
                        if (r == "Disbursement Initiated") {
                            this.setState({
                                showBlocker: false
                            });
                            ShowAlert('Success!', 'Disbursement process has been initiated successfully', 'success');
                        }
                        else {
                           this.disburseError();
                        }
                    })
                }
                else
                {
                    this.disburseError();
                }
                console.log('Success:', data);
                this.setState({
                    showBlocker: false
                });
            })
            .catch((error) => {
                console.error('Error:', JSON.stringify(error));
                this.disburseError();
            });
        });

    }

    public render() {
        return (
            <div className="m-3 data-grid-container dashboard-container profile-container">
                {/* <LoaderComponent loaderVisible={this.state.showBlocker} /> */}
                <Grid container className="mb-3">
                    <Grid item>
                    <h1 className="list-header">Payment</h1>
                    </Grid>
                    <Grid item xs>
                        {/* <h1 className="list-header">Profile</h1> */}
                    </Grid>
                </Grid>
                <div className="grid-list-addedit-sub proflie-container">
                    
                    {
                         this.state.isAdminUser &&
                        <div className="info-container">
                            {/* <p/>
                            <div className="preference-div-container">
                                {
                                    <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.disburse()}
                                >Send Payout</Button>
                            }
                            </div> */}
                            
                            <div className="payment-div-container">
                                <h4 className="preference-text">Payouts</h4>
                                     <Button
                                     style={{ marginLeft: 230, fontSize: 16, textAlign: 'center', color: '#fff', backgroundColor: '#00af85', borderRadius: 5, width: 100, height: 40 }}
                                     onClick={() => this.disburse()}
                                 >Disburse</Button>
                                    
                            </div>


                        </div>
                    }
                    
                </div>
            </div>
        )
    }

 
}

export default Payment;