import { Button, Drawer, Grid, IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import React from 'react';
import { CheckScreenPermission } from '../../../Common/CheckScreenPermission';
import { PreserveFilters } from '../../../Common/PreserveFilters';
import { ShowAlert, ShowAlertwithConfirm } from '../../../Common/ShowAlert';
import DataGrid from "../../../Components/DataGrid/DataGrid";
import DataGridToolbar from '../../../Components/DataGrid/DataGridToolbar';
import { IDataGridColumn } from '../../../Components/DataGrid/Interfaces';
import ArrayHelper from "../../../Helpers/ArrayHelper";
import { DateTimeHelper } from '../../../Helpers/DateTimeHelper';
import ObjectHelper from '../../../Helpers/ObjectHelper';
import UtilHelper from '../../../Helpers/UtilHelper';
import { RightPanelState } from '../../../Models/CommonInterfaces';
import PrivateLotService from '../../../Services/PrivateLotService';
import AddTenant from '../AddTenant';
import { IEditTenantLease, IPrivateLotProperty, IPrivateLotsDetailsItem, IPrivateLotsItem, IRemoveAccess, IRemoveAccessUndo, ISaveEditTenantRespone } from "../interface";
import "../PrivateLots.css";
import PrivateLotsProperty from "../PrivateLotsProperty";
import CustomRenewParkingPass from "./CustomRenewParkingPass";

class PrakingPassesList extends React.Component<IPrakingPassesListProps, IPrakingPassesListState>{
    private _privateLotService: PrivateLotService;

    private _parkingPassessColumns: IDataGridColumn<IPrivateLotsDetailsItem>[] = [
        // {
        //     key: "tenant",
        //     name: "NAME",
        // },
        // {
        //     key: "email",
        //     name: "EMAIL"
        // },
        // {
        //     key: "phoneNumber",
        //     name: "PHONE NUMBER",
        //     // contentProvider: (row_: IPrivateLotsDetailsItem) => {
        //     //     let phoneNumber = row_.phoneNumber?.toString().replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        //     //     return (<span className="no-wrap-text">{phoneNumber}</span>);
        //     // }
        // },
        {
            key: "propertyName",
            name: "PROPERTY",
        },
        {
            key: "yardiRedirectURL",
            name: "Unit Type",
            contentProvider: (row_: IPrivateLotsDetailsItem) => {
                return (<div className='p-license-plate'>{row_.yardiRedirectURL !== "" ? "Yardi" : "Gryd"}</div>);
            }
        },
        {
            key: "spotName",
            name: "SPOT #",
            getComparator: ArrayHelper.getNumberInStringComparator
        },
        {
            key: "leaseStartDateLocal",
            name: "START DATE",
            contentProvider: (row_: IPrivateLotsDetailsItem) => {
                let date = DateTimeHelper.dateStringToDate(row_.leaseStartDateLocal);
                return (<span className="no-wrap-text">{format(date, "MMMM dd, yyyy")}</span>);
            }
        },
        {
            key: "leaseEndDateLocal",
            name: "END DATE",
            contentProvider: (row_: IPrivateLotsDetailsItem) => {
                let date = DateTimeHelper.dateStringToDate(row_.leaseEndDateLocal);
                return (<span className="no-wrap-text">{format(date, "MMMM dd, yyyy")}</span>);
            }
        },
        // {
        //     key: "renew",
        //     name: "RENEW",
        //     contentProvider: (row_: IPrivateLotsDetailsItem) => {
        //         const { autoRenewAnchorE1 } = this.state;
        //         const rowIdLength = autoRenewAnchorE1 ? autoRenewAnchorE1.id.split('_').length : 0;
        //         const rowId = autoRenewAnchorE1 ? autoRenewAnchorE1.id.split('_')[rowIdLength - 1] : "";
        //         const anchorEl = rowId && rowId === row_.spotGuid ? autoRenewAnchorE1 : null;
        //         const isAddMenuOpen = Boolean(anchorEl);
        //         return (
        //             <>
        //                 <IconButton id={`basic-menu_passesBtn_${row_.spotGuid}`}
        //                     onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        //                         this.handleAutoRenewListOpen(event, row_)
        //                     }}>
        //                     <Autorenew fontSize="small" />
        //                 </IconButton>


        //                 <Menu
        //                     id={`basic-menu_passesMenu_${row_.spotGuid}`}
        //                     anchorEl={anchorEl}
        //                     open={isAddMenuOpen}
        //                     onClose={this.handleAutoRenewListClose}
        //                     MenuListProps={{
        //                         'aria-labelledby': 'basic-button',
        //                     }}
        //                 >
        //                     <MenuItem
        //                         onClick={(e_) => {
        //                             this.setState({
        //                                 autoRenewAnchorE1: null
        //                             }, () => {
        //                                 this.renewLease(this.state.selectedTenantDetails, "1 Year");
        //                             });
        //                         }}>
        //                         1 Year
        //                     </MenuItem>
        //                     <MenuItem
        //                         onClick={(e_) => {
        //                             this.setState({
        //                                 autoRenewAnchorE1: null
        //                             }, () => {
        //                                 this.renewLease(this.state.selectedTenantDetails, "1 Month");
        //                             });
        //                         }}>1 Month
        //                     </MenuItem>
        //                     <MenuItem
        //                         onClick={(e_) => {
        //                             let parkingPasses_ = [];
        //                             parkingPasses_.push(row_);
        //                             this.setState({
        //                                 isCustomRenew: true,
        //                                 rightPanelState: RightPanelState.Add,
        //                                 SelectedParkingPassess: parkingPasses_,
        //                                 autoRenewAnchorE1: null
        //                             })
        //                         }}>Custom
        //                     </MenuItem>
        //                 </Menu>
        //             </>
        //         );
        //     }
        // }

    ];


    constructor(props: any) {
        super(props);
        this.state = {
            allParkingPassess: [],
            filteredParkingPassess: [],
            subFilteredParkingPassess: [],
            autoRenewAnchorE1: null,
            rightPanelState: RightPanelState.None,
            isFiltered: false,
            searchTerm: "",
            isCustomRenew: false,
            SelectedParkingPassess: [],
            selectedReservation: '',
            selectedTenant: null,
            isAddNewTenant: false,
            properties: [],
            selectedTenantDetails: null,
            hasUnsavedChanges: false,
            toastNotifyType: "",
            removeAccessUndoList: [],
            isUndoNotification: false,
            editSavedResponse: [],
            isbulkParkingPassRenew: false,
            privateLotProperties: [],
            selectPrivateLotProperties: [],
            selectedPrivateLotsDetails: [],
            isScreenAccessible: false
        }

        this._privateLotService = new PrivateLotService();
    }

    async componentDidMount() {
        //PreserveFilters.setSearchTerm('ParkingPassessSearch', this.state.searchTerm);

        var isAccessible = await CheckScreenPermission("user-interface-lot-management");

        this.setState({
            isScreenAccessible: isAccessible
        }, () => {
            if (this.state.isScreenAccessible) {
                this.getParkingPassesDetail(true);
            }
        });
    }

    componentWillUnmount() {
        PreserveFilters.setSearchTerm('parkingPassesSearch', { searchTerm: this.state.searchTerm, selectPrivateLotProperties: this.state.selectPrivateLotProperties });
        PreserveFilters.clearPreservedFilter("renewOrder");
    }

    render() {
        if (!this.state.isScreenAccessible) return null;
        const renewOrder = PreserveFilters.getSearchTerm("renewOrder");
        const { sortColumn } = renewOrder ? renewOrder : { sortColumn: "" };

        return (
            <React.Fragment>
                {this.state.isUndoNotification && (
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.isUndoNotification}
                        message={this.commonToastNotificationMsg()}
                        className="common-toaster-pl"
                        onClose={this.closeCommonNotificationMsg}
                        autoHideDuration={30000}
                    />
                )}

                <div className="m-3 data-grid-container without-checkbox-container">
                    {/* Header region of the page */}
                    <Grid container className="mb-3 removeWhileBg">
                        <Grid item>
                            {/* Title of the page */}
                            <h1 className="list-header">Parking Passes</h1>
                        </Grid>
                        <PrivateLotsProperty
                            properties={this.state.privateLotProperties}
                            onPropertyChange={(property_: IPrivateLotProperty[]) => {
                                this.setState({
                                    selectPrivateLotProperties: property_,
                                    selectedPrivateLotsDetails: []
                                }, () => this._searchTermChange(this.state.searchTerm));
                            }}
                            selectedProperties={this.state.selectPrivateLotProperties}
                            id="privateLot-top-property"
                            allPropertyNotRequired={false}
                        />
                        <Grid item xs>
                            {/* Grid toolbar */}
                            <DataGridToolbar
                                search={PreserveFilters.getSearchTerm('parkingPassesSearch')?.searchTerm}
                                singularEntityName="Tenant"
                                pluralEntityName="Tenants"
                                onSearch={this._searchTermChange}
                                isFiltered={this.state.isFiltered}
                                onFilterClick={() => {
                                    this.setState({
                                        rightPanelState: RightPanelState.Filter
                                    });
                                }}
                                isNoFilterButton={true}
                                onAddClick={() => {
                                    const { selectPrivateLotProperties } = this.state;

                                    let selectedProperty = selectPrivateLotProperties.filter(x => x.propertyGuid !== "blank");

                                    let errorMessage = "";
                                    let isError = false;
                                    if (selectedProperty?.length === 1 && selectedProperty[0].isYardiProperty) {
                                        errorMessage = "Not allowed for third party managed property";
                                        isError = true;
                                    }

                                    if (isError) {
                                        ShowAlert("", errorMessage, "warning");
                                        return;
                                    }
                                    this.setState({
                                        rightPanelState: RightPanelState.Add,
                                        isAddNewTenant: true
                                    }, () => {
                                        this.loadProperties();
                                    });
                                }}
                                isNoAddButton={false}
                            />
                        </Grid>
                    </Grid>
                    <DataGrid
                        title="ParkingPasses"
                        data={this.state.subFilteredParkingPassess}
                        columns={this._parkingPassessColumns}
                        defaultSortColumnKey={sortColumn}
                        isNoMoreLink={false}
                        isRowsNonSelectable={false}
                        appliedSearch={this.state.searchTerm}
                        selectedItems={this.state.selectedPrivateLotsDetails}
                        onFilterTagRemoved={() => {

                        }}
                        onRowClick={pass_ => {

                        }}
                        onMoreClick={(item_) =>
                            {
                                return item_.yardiRedirectURL !== "" ? [{
                                    label: "Renew",
                                    key: "renew"
                                }, {
                                    label: "Remove Access",
                                    key: "removeAccess",
                                    textStyle: "remove-access-item"
                                }] : [
                                    {
                                        label: "Renew",
                                        key: "renew"
                                    }, {
                                        label: "Edit Tenant",
                                        key: "editTenant"

                                    }, {
                                        label: "Remove Access",
                                        key: "removeAccess",
                                        textStyle: "remove-access-item"
                                    }
                                ]

                            }
                        }
                        onPopoverClick={(key_, pass_) => {
                            if (key_ == "editTenant") {
                                let tenantDetails_: IPrivateLotsItem = {
                                    //email: pass_.email,
                                    //phoneNumber: Number(pass_.phoneNumber),
                                    propertyDetails: [],
                                    spots: 0,
                                    tenant: "",
                                    licensePlate: pass_.licensePlate,
                                    privateLotUnitGuid: pass_.privateLotUnitGuid,
                                    unitNumber: pass_.unitNumber,
                                    yardiRedirectURL: ""
                                    //userGuid: pass_.userGuid
                                }

                                this.setState({
                                    rightPanelState: RightPanelState.Edit,
                                    selectedReservation: pass_.reservationGuid,
                                    selectedTenant: tenantDetails_,
                                    isAddNewTenant: true
                                }, () => {
                                    this.loadProperties();
                                })
                            }
                            else if (key_ == "removeAccess") {
                                if (pass_.yardiRedirectURL !== "") {
                                    UtilHelper.lanuchPopup(pass_.yardiRedirectURL, "Popup" ,true, () => {
                                        this.refreshSyncYardi(pass_.propertyGuid, () => {
                                            this.setState({
                                                toastNotifyType: "Tenant details updated successfully.",
                                                isUndoNotification: true
                                            }, () => {
                                                this.getParkingPassesDetail();
                                            });
                                        });
                                    });
                                }
                                else {
                                    this.setState({
                                        selectedTenantDetails: pass_
                                    }, () => {
                                        this.removeAccessTenantDetails(pass_);
                                    });
                                }                                
                            }
                            else if (key_ == "renew") {
                                let parkingPasses_ = [pass_];
                                if (pass_.yardiRedirectURL !== "") {
                                    UtilHelper.lanuchPopup(pass_.yardiRedirectURL, `Popup`, true, () => {
                                        this.refreshSyncYardi(pass_.propertyGuid, () => {
                                            this.setState({
                                                toastNotifyType: "Tenant lease updated successfully.",
                                                isUndoNotification: true,
                                                SelectedParkingPassess: []
                                            }, () => {
                                                this.getParkingPassesDetail();
                                            });
                                        });
                                    });
                                }
                                else {
                                    this.setState({
                                        isCustomRenew: true,
                                        rightPanelState: RightPanelState.Add,
                                        SelectedParkingPassess: parkingPasses_,
                                        autoRenewAnchorE1: null
                                    });
                                }
                            }
                        }}
                        multiPopoverItems={[
                            // {
                            //     label: "1 Year",
                            //     key: "1 Year"
                            // },
                            // {
                            //     label: "1 Month",
                            //     key: "1 Month"
                            // },
                            // {
                            //     label: "Custom",
                            //     key: "Custom"
                            // },
                            {
                                label: "Renew",
                                key: "renew"
                            },
                            {
                                label: "Remove Access",
                                key: "removeAccess",
                                textStyle: "remove-access-item"
                            }
                        ]}
                        onMultiPopoverClick={(actionKey_, items_, _selectAllRows) => {                            
                            const yardiItem = items_.findIndex(item => item.yardiRedirectURL !== "") !== -1;
                            if (actionKey_ == "removeAccess") {
                                if(this.state.selectPrivateLotProperties.length > 1 && yardiItem){
                                    ShowAlert("Remove Tenant", "Please select only one property to remove yardi unit(s).", "warning");
                                    return false;
                                }
                                this.setState({
                                    SelectedParkingPassess: items_
                                }, () => {
                                    this.bulkRemoveAccess(items_, _selectAllRows, yardiItem);
                                });
                            }
                            // else if (actionKey_ == "1 Month" || actionKey_ == "1 Year") {
                            //     this.setState({
                            //         SelectedParkingPassess: items_,
                            //         selectedPrivateLotsDetails: items_,
                            //         isbulkParkingPassRenew: true
                            //     }, () => {
                            //         this.bulkRenewLease(items_, actionKey_);
                            //     })

                            // }
                            // else if (actionKey_ == "Custom") {
                            //     this.setState({
                            //         isCustomRenew: true,
                            //         rightPanelState: RightPanelState.Add,
                            //         SelectedParkingPassess: items_,
                            //         selectedPrivateLotsDetails: items_,
                            //         isbulkParkingPassRenew: true
                            //     })

                            // }
                            else if (actionKey_ == "renew") {
                                const hasNonYardiItem = items_.filter(item => item.yardiRedirectURL === "");
                                if(this.state.selectPrivateLotProperties.length > 1 && yardiItem){
                                    ShowAlert("Tenant Renew", "Please select only one property to update tenant lease for yardi unit(s).", "warning");
                                    return false;
                                }
                                if (hasNonYardiItem.length === 0) {
                                    this.setState({
                                        isCustomRenew: false,
                                        rightPanelState: RightPanelState.None,
                                        SelectedParkingPassess: items_,
                                        selectedPrivateLotsDetails: items_,
                                        isbulkParkingPassRenew: true,
                                        selectAllRows: _selectAllRows
                                    }, () => {
                                        this.bulkRenewLease(items_, "custom");
                                    });
                                }
                                else {
                                    this.setState({
                                        isCustomRenew: true,
                                        rightPanelState: RightPanelState.Add,
                                        SelectedParkingPassess: items_,
                                        selectedPrivateLotsDetails: items_,
                                        isbulkParkingPassRenew: true,
                                        selectAllRows: _selectAllRows
                                    });
                                }
                            }
                        }}
                    />
                    {/* Right side drawer */}
                    <Drawer
                        anchor="right"
                        variant={this.state.rightPanelState == RightPanelState.None ? "persistent" : "temporary"}
                        open={this.state.rightPanelState != RightPanelState.None}
                        className="data-grid-right-panel"
                        onClose={this._onCloseDrawerPanel}
                    >
                        <div className="mx-3">
                            <Grid container className={"right-panel-header p-2 " + ((this.state.isCustomRenew) ? "" : " border-bottom")}>
                                {(this.state.isCustomRenew) ? (
                                    <Grid item xs >
                                    </Grid>
                                ) : (
                                    <Grid item xs>
                                        <h4 className="right-panel-title m-0 ml-3">
                                            {this._getRightPanelTitle()}
                                        </h4>
                                    </Grid>
                                )}

                                <Grid item>
                                    <Button
                                        onClick={this._onCloseDrawerPanel}>
                                        <CloseIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        {/* <div className="mx-3 private-lots-right-panal">
                        <Grid container className="right-panel-header py-2">
                            <Grid item xs >
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={this._onCloseDrawerPanel}>
                                    <CloseIcon />
                                </Button>
                            </Grid>
                        </Grid>
                        {this._getRightPanelContent()}
                    </div> */}
                        {this._getRightPanelContent()}
                    </Drawer>
                </div>
            </React.Fragment>
        );

    }

    protected _getRightPanelTitle() {
        if (this.state.isCustomRenew) {
            return <span>Renew Parking Pass</span>;
        } else if (this.state.rightPanelState == RightPanelState.Add) {
            return <span>Add Tenant</span>;
        } else if (this.state.rightPanelState == RightPanelState.Edit) {
            return <span>Edit Tenant</span>;
        }
    }

    protected _onCloseDrawerPanel = () => {
        if (this.state.hasUnsavedChanges) {
            ShowAlertwithConfirm("You have unsaved changes!", "Are you sure you want to close this window?", "warning")
                .then(r_ => {
                    if (r_) {
                        this.updateCloseState();
                    }
                })
        }
        else {
            this.updateCloseState();
        }

    }

    private updateCloseState() {
        this.setState({
            rightPanelState: RightPanelState.None,
            isCustomRenew: false,
            SelectedParkingPassess: [],
            isAddNewTenant: false,
            hasUnsavedChanges: false,
            selectAllRows: undefined
        });
    }

    private _getRightPanelContent = () => {
        if (this.state.isCustomRenew) {
            return (
                <CustomRenewParkingPass
                    onClose={this._onCloseDrawerPanel}
                    parkingPasses={this.state.SelectedParkingPassess}
                    onSaveCustomRenew={(eDate_: Date) => {
                        if (this.state.SelectedParkingPassess.length === 1) {
                            const selectedTenantDetails = this.state.SelectedParkingPassess[0];
                            this.setState({
                                rightPanelState: RightPanelState.None,
                                isCustomRenew: false,
                                hasUnsavedChanges: false,
                                autoRenewAnchorE1: null
                            }, () => {
                                this.renewLease(selectedTenantDetails, "custom", eDate_);
                            })
                        }
                        else {
                            const selectedPasses_ = this.state.SelectedParkingPassess;
                            this.setState({
                                rightPanelState: RightPanelState.None,
                                isCustomRenew: false,
                                hasUnsavedChanges: false
                            }, () => {
                                this.bulkRenewLease(selectedPasses_, "custom", eDate_);
                            })
                        }

                    }}
                />
            );
        } else if (this.state.isAddNewTenant) {
            return (
                <AddTenant
                    properties={this.state.properties}
                    onClose={this._onCloseDrawerPanel}
                    onChange={(hasChange: boolean) => {
                        this.setState({
                            hasUnsavedChanges: hasChange
                        })
                    }}
                    requestType={this.state.rightPanelState}
                    onSaved={(isPanelClosed?: boolean) => {
                        if (isPanelClosed) {
                            this.setState({
                                selectedTenant: null,
                                selectedReservation: "",
                                rightPanelState: RightPanelState.None,
                                hasUnsavedChanges: false,
                                isAddNewTenant: false,
                            }, () => {
                                this.getParkingPassesDetail();
                            });
                        }
                        else {
                            this.getParkingPassesDetail();
                        }
                    }}
                    selectedTenant={this.state.selectedTenant}
                    selectedReservation={this.state.selectedReservation}
                />
            );
        }
    }


    private _searchTermChange = (searchTerm_: string): void => {
        const { allParkingPassess, selectPrivateLotProperties } = this.state;
        let subFilteredParkingPassess = this._filterDataBySearch(allParkingPassess, searchTerm_);

        console.log(selectPrivateLotProperties);

        subFilteredParkingPassess = subFilteredParkingPassess.filter((subFilter: IPrivateLotsDetailsItem) => {
            return selectPrivateLotProperties.find((selectProperty: IPrivateLotProperty) =>
                selectProperty.propertyGuid === subFilter.propertyGuid);
        });

        this.setState({ searchTerm: searchTerm_, subFilteredParkingPassess });
    }

    protected _filterDataBySearch = (data_: IPrivateLotsDetailsItem[], searchTerm_: string): IPrivateLotsDetailsItem[] => {
        return data_.filter(d => {
            return d.propertyName.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
                (d.zoneName && d.zoneName.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1) ||
                (d.spotName && d.spotName.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1) ||
                (d.tenant && d.tenant.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1) ||
                (d.email && d.email.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1) ||
                (d.phoneNumber && d.phoneNumber.toString().indexOf(searchTerm_.toLowerCase()) > -1) ||
                (d.yardiRedirectURL !== "" ? "Yardi" : "Gryd").toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1;
        });
    }

    private handleAutoRenewListOpen = (event: React.MouseEvent<HTMLButtonElement>, item_: IPrivateLotsDetailsItem) => {
        this.setState({
            autoRenewAnchorE1: event.currentTarget,
            selectedTenantDetails: item_
        })
    }

    private handleAutoRenewListClose = () => {
        this.setState({
            autoRenewAnchorE1: null
        })
    }

    private getParkingPassesDetail(onLoad?: boolean) {
        this._privateLotService.getPrivatlotTenantInDetail(`spot/get-tenant-spot-details/`)
            .then(r => r.json())
            .then(r => {
                let update = r.map((x: IPrivateLotsDetailsItem) => {
                    x.leaseStartDateLocal = new Date(x.leaseStartDateLocal);
                    x.leaseEndDateLocal = new Date(x.leaseEndDateLocal);

                    return x;
                });

                let properties: IPrivateLotProperty[] = r.map((x: IPrivateLotsDetailsItem) => {
                    return {
                        propertyGuid: x.propertyGuid,
                        propertyName: x.propertyName,
                        isYardiProperty: x.yardiRedirectURL !== "",
                        yardiRedirectURL: x.yardiRedirectURL
                    }
                });

                properties = Array.from(new Set(properties
                    .map((property: IPrivateLotProperty) => JSON.stringify(property))))
                    .map((property: string) => JSON.parse(property));

                let selectedPrivateLot_: IPrivateLotProperty[] = this.state.selectPrivateLotProperties;

                if (this.state.selectPrivateLotProperties.length == 1 && this.state.selectPrivateLotProperties[0].propertyGuid == "blank") {
                    selectedPrivateLot_ = ObjectHelper.deepClone(properties);
                }

                if (onLoad) {
                    const renewOrder = PreserveFilters.getSearchTerm("renewOrder");
                    const { reservationGuids } = renewOrder ? renewOrder : { reservationGuids: [] };
                    const parkingPassesSearch = PreserveFilters.getSearchTerm("parkingPassesSearch");
                    let selectPrivateLotProperties: IPrivateLotProperty[] = [];
                    let selectedPrivateLotsDetails: IPrivateLotsDetailsItem[] = [];
                    let searchTerm = "";

                    if (parkingPassesSearch?.selectPrivateLotProperties)
                        selectPrivateLotProperties = parkingPassesSearch.selectPrivateLotProperties;

                    if (parkingPassesSearch?.searchTerm)
                        searchTerm = parkingPassesSearch.searchTerm;

                    if(renewOrder && reservationGuids?.length > 0){
                        selectedPrivateLotsDetails = update.filter((item: IPrivateLotsDetailsItem) => reservationGuids.indexOf(item.reservationGuid) !== -1);
                        const selectPropertyGuid = selectedPrivateLotsDetails.map((item) => item.propertyGuid);
                        const cloneProperty = ObjectHelper.deepClone(properties);
                        selectPrivateLotProperties = cloneProperty.filter((item) => selectPropertyGuid.indexOf(item.propertyGuid) !== -1);
                    }

                    this.setState({
                        privateLotProperties: ObjectHelper.deepClone(properties),
                        selectPrivateLotProperties: selectPrivateLotProperties.length > 0 ? selectPrivateLotProperties : selectedPrivateLot_,
                        allParkingPassess: update,
                        filteredParkingPassess: update.slice(0),
                        subFilteredParkingPassess: update.slice(0),
                        searchTerm: searchTerm,
                        selectedPrivateLotsDetails
                    }, () => {
                        this._searchTermChange(this.state.searchTerm);
                    });
                }
                else {
                    this.setState({
                        privateLotProperties: ObjectHelper.deepClone(properties),
                        selectPrivateLotProperties: selectedPrivateLot_,
                        allParkingPassess: update,
                        filteredParkingPassess: update.slice(0),
                        subFilteredParkingPassess: update.slice(0)
                    }, () => {
                        this._searchTermChange(this.state.searchTerm);
                    });
                }
            });
    }

    private loadProperties() {
        this._privateLotService.getPrivateLotProperties()
            .then(r => r.json())
            .then(r => {
                let properties_: IPrivateLotProperty[] = r;

                this.setState({
                    properties: properties_.slice(0)
                });
            });
    }

    private bulkRemoveAccess = async (pLots_: IPrivateLotsDetailsItem[], _selectAllRows?: () => void, hasYardiItem?: boolean) => {
        let originalItems = ObjectHelper.deepClone(pLots_);

        pLots_ = hasYardiItem ? pLots_.filter(x => x.yardiRedirectURL === "") : pLots_;

        const yardiRedirectURL: string[] = hasYardiItem ? originalItems
            .filter((item: IPrivateLotsDetailsItem) => item.yardiRedirectURL !== "")
            .map(x => x.yardiRedirectURL)
            .filter((item: string, index: number, self: string[]) =>
                self.indexOf(item) === index) : [];

        const _removeAccessData = (pLots_: IPrivateLotsDetailsItem[], _isDelete: boolean) => {
            let _apiData: IRemoveAccess[] = [];
            pLots_.forEach(p_ => {
                _apiData.push({
                    "privateLotUnitGuid": p_.privateLotUnitGuid,
                    //"propertyGuid": p_.propertyGuid,
                    "reservationGuid": p_.reservationGuid,
                    "isDelete": _isDelete
                })
            });
            return _apiData;
        }
        const _bulkRemoveAccessData = _removeAccessData(pLots_, false);
        const _getBulkRemoveResponseData = _bulkRemoveAccessData.length > 0 ? await this.bulkRemoveAccessApi(_bulkRemoveAccessData) : [];

        if (hasYardiItem) {
            this.removeTeantAccessWithYardi(_removeAccessData, pLots_,
                _getBulkRemoveResponseData, yardiRedirectURL, _selectAllRows);
        }
        else {
            this.removeTeantAccess(_removeAccessData, pLots_, _getBulkRemoveResponseData, _selectAllRows);
        }
    }    

    private removeTeantAccessWithYardi(_removeAccessData: 
        (pLots_: IPrivateLotsDetailsItem[], _isDelete: boolean) => IRemoveAccess[], 
    pLots_: IPrivateLotsDetailsItem[], _getBulkRemoveResponseData: any,
     yardiRedirectURL: string[], _selectAllRows?: () => void){
        if (_getBulkRemoveResponseData.length > 0) {
            ShowAlertwithConfirm(
                "Active Parking",
                `<div class="mt-3">Some tenants you are trying to remove still have<br/>
                    active parking. Please review and confirm you<br/>
                    want to continue:<br/>
                    <div class="d-flex justify-content-between px-3 mt-3">
                    <div><b>Unit</b></div>
                    <div class="pr-3"><b>End Date</b></div>
                    </div>
                    <div class="mt-3">
                    ${_getBulkRemoveResponseData.map((p_: any) => {
                    return (
                        `<div class="d-flex justify-content-between px-3 multi-remove-access-data">
                                <div>${p_.unitNumber}</div>
                                <div>${format(DateTimeHelper.dateStringToDate(p_.endDateTime), "dd MMM yyyy")}</div>
                            </div>`
                    )
                }).join('')}
                    </div>
                </div>`,
                undefined,
                "Yes, Delete",
                "Cancel",
                "gryd-custom-popup"
            )
                .then(r_ => {
                    if (r_) {
                        this.removeAccessWithYardiApi(_removeAccessData, pLots_, yardiRedirectURL, _selectAllRows);
                    }
                    else{
                        this.yardiCompletedCallBack(yardiRedirectURL, pLots_, undefined, false, _selectAllRows);
                    }
                })
        }
        else {
            this.removeAccessWithYardiApi(_removeAccessData, pLots_, yardiRedirectURL, _selectAllRows);
        }        
    }

    private removeAccessWithYardiApi(_removeAccessData: 
        (pLots_: IPrivateLotsDetailsItem[], _isDelete: boolean) => IRemoveAccess[], 
    pLots_: IPrivateLotsDetailsItem[], yardiRedirectURL: string[], _selectAllRows?: () => void){
        if (pLots_.length > 0) {
            const _acceptBulkRemoveAccessData = _removeAccessData(pLots_, true);
            this.bulkRemoveAccessApi(_acceptBulkRemoveAccessData).then(response_ => {
                this.yardiCompletedCallBack(yardiRedirectURL, pLots_, response_, true, _selectAllRows);
            });
        }
        else {
            this.yardiCompletedCallBack(yardiRedirectURL, pLots_, undefined, false, _selectAllRows);
        }
    }

    private yardiCompletedCallBack(yardiRedirectURL: string[], pLots_: IPrivateLotsDetailsItem[], 
        response_: any, removeOtherUser: boolean, _selectAllRows?: () => void) {
        const selectProperty = this.state.selectPrivateLotProperties.filter(x => x.propertyGuid !== "blank");
        ShowAlertwithConfirm(
            "Yardi Unit",
            `<div class="mt-3">Some of the tenants are belong to third party site.<br/>
                Do you want to continue?
            </div>`,
            undefined,
            "Yes",
            "Cancel",
            "gryd-custom-popup"
        ).then(res_ => {
            if (res_) {
                let popup = 1;
                if (removeOtherUser) {
                    yardiRedirectURL.forEach(x => {
                        UtilHelper.lanuchPopup(x, 
                        `Popup_${popup++}`, 
                        true, 
                        () => { 
                            this.refreshSyncYardi(selectProperty[0].propertyGuid, () => {
                                this.removeAccessWithYardiApiSuccess(response_, _selectAllRows);                                
                            });
                        });
                    });
                }
                else {
                    yardiRedirectURL.forEach(x => {
                        UtilHelper.lanuchPopup(x,
                            `Popup_${popup++}`,
                            true,
                            () => {
                                this.refreshSyncYardi(selectProperty[0].propertyGuid, () => {
                                    this.setState({
                                        toastNotifyType: "Tenant details updated successfully.",
                                        isUndoNotification: true
                                    }, () => {
                                        if (_selectAllRows)
                                            _selectAllRows();
                                        this.getParkingPassesDetail();
                                    });
                                });
                            });
                    });
                }
            }
            else{
                if (response_)
                    this.removeAccessWithYardiApiSuccess(response_, _selectAllRows); 
            }
        });
    }

    private removeTeantAccess(_removeAccessData: (pLots_: IPrivateLotsDetailsItem[], _isDelete: boolean) => IRemoveAccess[], 
    pLots_: IPrivateLotsDetailsItem[], _getBulkRemoveResponseData: any, _selectAllRows?: () => void){
        if (_getBulkRemoveResponseData.length > 0) {
            ShowAlertwithConfirm(
                "Active Parking",
                `<div class="mt-3">Some tenants you are trying to remove still have<br/>
                    active parking. Please review and confirm you<br/>
                    want to continue:<br/>
                    <div class="d-flex justify-content-between px-3 mt-3">
                    <div><b>Unit</b></div>
                    <div class="pr-3"><b>End Date</b></div>
                    </div>
                    <div class="mt-3">
                    ${_getBulkRemoveResponseData.map((p_: any) => {
                    return (
                        `<div class="d-flex justify-content-between px-3 multi-remove-access-data">
                                <div>${p_.unitNumber}</div>
                                <div>${format(DateTimeHelper.dateStringToDate(p_.endDateTime), "dd MMM yyyy")}</div>
                            </div>`
                    )
                }).join('')}
                    </div>
                </div>`,
                undefined,
                "Yes, Delete",
                "Cancel",
                "gryd-custom-popup"
            )
                .then(r_ => {
                    if (r_) {
                        this.removeBulkRemoveAccessApi(_removeAccessData, pLots_, _selectAllRows);
                    }
                })
        }
        else {
            this.removeBulkRemoveAccessApi(_removeAccessData, pLots_, _selectAllRows);
        }        
    }

    private removeBulkRemoveAccessApi(_removeAccessData: (pLots_: IPrivateLotsDetailsItem[], 
        _isDelete: boolean) => IRemoveAccess[], 
    pLots_: IPrivateLotsDetailsItem[], _selectAllRows?: () => void){        
        const _acceptBulkRemoveAccessData = _removeAccessData(pLots_, true);
        this.bulkRemoveAccessApi(_acceptBulkRemoveAccessData).then(res_ => {
            ShowAlert(
                "",
                `<div class="mt-3"> 
                        <b>Tenant Deleted</b>
                    </div>
                    <div class="mt-3"> 
                        All selected tenants have been successfully deleted
                        <br/>
                        and all active parking has been cancelled.
                    </div>`,
                "success"
            ).then(r => {
                this.setState({
                    toastNotifyType: "bulkRemoveAccess",
                    removeAccessUndoList: res_,
                    isUndoNotification: true,
                }, () => {
                    if (_selectAllRows)
                        _selectAllRows();
                    this.getParkingPassesDetail();
                })
            });
        });
    }    

    private removeAccessWithYardiApiSuccess(res_: any, _selectAllRows?: () => void){    
        ShowAlert(
            "",
            `<div class="mt-3"> 
                <b>Tenant Deleted</b>
            </div>
            <div class="mt-3"> 
                All selected tenants have been successfully deleted
                <br/>
                and all active parking has been cancelled.
            </div>`,
            "success"
        ).then(r => {
            this.setState({
                toastNotifyType: "bulkRemoveAccess",
                removeAccessUndoList: res_,
                isUndoNotification: true
            }, () => {
                if (_selectAllRows)
                    _selectAllRows();
                this.getParkingPassesDetail();
            })
        });    
    }

    private removeAccessTenantDetails = async (pLots_: IPrivateLotsDetailsItem) => {
        let _endDate = DateTimeHelper.dateStringToDate(pLots_.leaseEndDateLocal);
        ShowAlertwithConfirm(
            "Active Lease",
            `<div class="mt-3">You are attempting to delete an active parking<br/>
            lease(s) which will expire <b>${format(_endDate, "dd MMM yyyy")}</b>. Do you<br/>
            want to delete and cancel parking lease?<br/>
                This cannot be undone.
                </div>`,
            undefined,
            "Yes",
            "No",
            "gryd-custom-popup"
        )
            .then(r_ => {
                if (r_) {
                    this.bulkRemoveAccessApi([{
                        "privateLotUnitGuid": pLots_.privateLotUnitGuid,
                        //"propertyGuid": pLots_.propertyGuid,
                        //"spotGuid": pLots_.spotGuid,
                        "reservationGuid": pLots_.reservationGuid,
                        "isDelete": true
                    }]).then(d_ => {
                        ShowAlert(
                            "",
                            `<div class="mt-3"> 
                                    <b>Lease Deleted</b>
                                </div>
                                <div class="mt-3"> 
                                Pass has been successfully deleted from tenant's 
                                    <br/>
                                    active passes.
                                </div>`,
                            "success"
                        ).then(r => {
                            this.setState({
                                toastNotifyType: "removeParkingPass",
                                removeAccessUndoList: d_,
                                isUndoNotification: true
                            }, () => {
                                this.getParkingPassesDetail();
                            })

                        });
                    });
                }
            })
    }

    private bulkRemoveAccessApi = async (data_: IRemoveAccess[]) => {
        return await this._privateLotService.bulkRemoveAccess(data_)
            .then(r => r.json())
            .then(r => {
                return r;
            })
    }

    private removeAccessApi = async (data_: IRemoveAccess) => {
        return await this._privateLotService.removeAccess(data_)
            .then(r => r.json())
            .then(r => {
                return r;
            })
    }

    private renewLease(item_: IPrivateLotsDetailsItem | null, option_: string, expirationDate_?: Date) {
        if (item_ != null) {
            let startDate_ = item_.leaseStartDateLocal;
            let endDate_ = item_.leaseEndDateLocal;

            if (option_ == "1 Year") {
                endDate_ = new Date(endDate_.setFullYear(endDate_.getFullYear() + 1));
            }

            if (option_ == "1 Month") {
                endDate_ = new Date(endDate_.setMonth(endDate_.getMonth() + 1));
            }

            if (option_ == "custom") {
                endDate_ = new Date(expirationDate_ ? expirationDate_ : new Date());
            }


            let leasedetails_: IEditTenantLease = {
                startDate: DateTimeHelper.convertIntoUtc(startDate_),
                endDate: DateTimeHelper.convertIntoUtc(endDate_),
                reservationGuid: item_.reservationGuid,
                spotGuid: item_.spotGuid,
                IsActive: true
            }

            this._privateLotService.editTenantLease([leasedetails_])
                .then(r => {
                    if (r.ok) {
                        r.json().then(res_ => {
                            let resp_: ISaveEditTenantRespone[] = res_;

                            let errorItems = resp_.filter(x => x.isError == true);

                            if (errorItems.length == 0) {
                                ShowAlert('', 'Tenant lease updated successfully.', 'success').then(() => {
                                    this.getParkingPassesDetail();
                                    this.setState({
                                        toastNotifyType: option_,
                                        isUndoNotification: true,
                                        editSavedResponse: res_
                                    })
                                });
                            } else {
                                ShowAlert('Lease Cannot Be Updated', 'Spot has active parking lease and cannot be updated until active parking has been cancelled or expired.', 'error');
                            }
                        });
                    }
                });
        }
    }

    private bulkRenewLease(item_: IPrivateLotsDetailsItem[] | null, option_: string, expirationDate_?: Date) {
        if (item_ != null && item_.length > 0) {

            let bulkRenewItems_ = ObjectHelper.deepClone(item_);
            let leasedetails_: IEditTenantLease[] = [];

            const yardiRedirectURL: string[] = bulkRenewItems_
                .filter((item: IPrivateLotsDetailsItem) => item.yardiRedirectURL !== "")
                .map(x => x.yardiRedirectURL)
                .filter((item: string, index: number, self: string[]) => self.indexOf(item) === index);

            const hasYardiItem = yardiRedirectURL.length > 0;

            bulkRenewItems_ = hasYardiItem ? bulkRenewItems_.filter((item: IPrivateLotsDetailsItem) => item.yardiRedirectURL === "") : bulkRenewItems_;

            bulkRenewItems_.forEach(pass_ => {
                let startDate_ = pass_.leaseStartDateLocal;
                let endDate_ = pass_.leaseEndDateLocal;
                if (option_ == "1 Year") {
                    endDate_ = new Date(endDate_.setFullYear(endDate_.getFullYear() + 1));
                }

                if (option_ == "1 Month") {
                    endDate_ = new Date(endDate_.setMonth(endDate_.getMonth() + 1));
                }

                if (option_ == "custom") {
                    endDate_ = new Date(expirationDate_ ? expirationDate_ : new Date());
                }
                leasedetails_.push({
                    startDate: DateTimeHelper.convertIntoUtc(startDate_),
                    endDate: DateTimeHelper.convertIntoUtc(endDate_),
                    reservationGuid: pass_.reservationGuid,
                    spotGuid: pass_.spotGuid,
                    IsActive: true
                })
            });            
            
            if (hasYardiItem) {
                this.renewCustomYardiCallBack(leasedetails_, option_, yardiRedirectURL);
            }
            else {
                this.renewCustomCallBack(leasedetails_, option_);                
            }
        }
    }

    private renewCustomYardiCallBack(leasedetails_: IEditTenantLease[], option_: string,
        yardiRedirectURL: string[]) {            
        const selectProperty = this.state.selectPrivateLotProperties.filter(x => x.propertyGuid !== "blank");
        if (leasedetails_.length > 0) {
            this._privateLotService.editTenantLease(leasedetails_)
                .then(r => {
                    if (r.ok) {
                        r.json().then(res_ => {
                            let resp_: ISaveEditTenantRespone[] = res_;
                            let errorItems = resp_.filter(x => x.isError == true);
                            let popup = 1;
                            yardiRedirectURL.forEach(x => {
                                UtilHelper.lanuchPopup(x, `Popup_${popup++}`, true, () => {
                                    this.refreshSyncYardi(selectProperty[0].propertyGuid, () => {
                                        this.renewCustomCallBackSuccess(errorItems,
                                            option_, res_, resp_);
                                    });
                                });
                            });

                        });
                    }
                });
        }
        else {
            let popup = 1;
            yardiRedirectURL.forEach(x => {
                UtilHelper.lanuchPopup(x, `Popup_${popup++}`, true, () => {
                    this.refreshSyncYardi(selectProperty[0].propertyGuid, () => {
                        this.getParkingPassesDetail();
                        this.state.selectAllRows && this.state.selectAllRows(); 
                        this.setState({
                            toastNotifyType: "Tenant lease updated successfully.",
                            isUndoNotification: true,
                            SelectedParkingPassess: [],
                            selectAllRows: undefined
                        });
                    });
                });
            });
        }
    }

    private renewCustomCallBack(leasedetails_: IEditTenantLease[], option_: string) {
        this._privateLotService.editTenantLease(leasedetails_)
            .then(r => {
                if (r.ok) {
                    r.json().then(res_ => {
                        let resp_: ISaveEditTenantRespone[] = res_;
                        let errorItems = resp_.filter(x => x.isError == true);
                        this.renewCustomCallBackSuccess(errorItems,
                            option_, res_, resp_);
                    });
                }
            });
    }

    private renewCustomCallBackSuccess(errorItems: ISaveEditTenantRespone[],
        option_: string, res_: any, resp_: ISaveEditTenantRespone[]) {
        if (errorItems.length == 0) {
            ShowAlert('', 'Tenant lease(s) updated successfully.', 'success').then(() => {
                this.getParkingPassesDetail();                
                this.state.selectAllRows && this.state.selectAllRows(); 
                this.setState({
                    toastNotifyType: option_,
                    isUndoNotification: true,
                    editSavedResponse: res_,
                    SelectedParkingPassess: [],
                    selectAllRows: undefined
                });
            });
        } else {
            let undoItems_ = resp_.filter(x => x.isError == false);
            let selectedItems_: IPrivateLotsDetailsItem[] = []
            for (let s_ of this.state.SelectedParkingPassess) {
                if ((undoItems_.filter(u_ => u_.reservationGuid == s_.reservationGuid && u_.spotGuid == s_.spotGuid)).length == 0) {
                    selectedItems_.push(s_);
                }
            }
            ShowAlert('Lease(s) Cannot Be Updated', 'Some of the leases were not updated. please modiy expiration date for selected lease(s) and try again', 'error').then(() => {

                if (undoItems_.length > 0) {
                    this.setState({
                        toastNotifyType: option_,
                        isUndoNotification: true,
                        editSavedResponse: undoItems_,
                        selectedPrivateLotsDetails: selectedItems_,
                        SelectedParkingPassess: selectedItems_,
                        selectAllRows: undefined
                    }, () => {
                        this.getParkingPassesDetail();
                    });
                }
            });
        }
    }

    commonToastNotificationMsg = () => {
        if (this.state.toastNotifyType == "removeAccess"
            || this.state.toastNotifyType == "bulkRemoveAccess"
            || this.state.toastNotifyType == "editTenant"
            || this.state.toastNotifyType == "removeParkingPass"
            || this.state.toastNotifyType == "1 Year"
            || this.state.toastNotifyType == "1 Month"
            || this.state.toastNotifyType == "custom"
        ) {
            return (
                <div className='d-flex align-items-center'>
                    <div className='d-flex flex-column'>
                        <div className=''>
                            {this.commonNotificationContent(this.state.toastNotifyType)}
                            <a className='cursor-pointer' style={{ textDecoration: "underline" }} onClick={(e) => {
                                e.preventDefault();
                                if (this.state.toastNotifyType == "removeAccess"
                                    || this.state.toastNotifyType == "removeParkingPass"
                                    || this.state.toastNotifyType == "bulkRemoveAccess") {
                                    this._privateLotService.undoRemoveAccess(this.state.removeAccessUndoList)
                                        .then(r => {
                                            if (r.ok) {
                                                this.getParkingPassesDetail();
                                                this.closeCommonNotificationMsg();
                                            }
                                        });
                                }
                                else if (this.state.toastNotifyType == "1 Year"
                                    || this.state.toastNotifyType == "1 Month"
                                    || this.state.toastNotifyType == "custom") {
                                    this._privateLotService.undoUpdateTenant(this.state.editSavedResponse)
                                        .then(r => {
                                            if (r.ok) {
                                                this.getParkingPassesDetail();
                                                this.closeCommonNotificationMsg();
                                            }
                                        });
                                }
                            }}>&nbsp;Undo</a>
                        </div>
                    </div>
                    <div className='d-flex n-toast-close pl-4'>
                        <IconButton
                            size="small"
                            aria-label="close"
                            onClick={this.closeCommonNotificationMsg}
                        >
                            <CloseIcon fontSize="small" htmlColor='#fff' />
                        </IconButton>
                    </div>
                </div>

            );
        }
        else {
            return (
                <div className='d-flex align-items-center'>
                    <div className='d-flex flex-column'>
                        <div className=''>
                            {this.state.toastNotifyType}
                        </div>
                    </div>
                    <div className='d-flex pl-4'>
                        <IconButton
                            size="small"
                            aria-label="close"
                            onClick={this.closeCommonNotificationMsg}
                        >
                            <CloseIcon fontSize="small" htmlColor='#fff' />
                        </IconButton>
                    </div>
                </div>
            );
        }
    }

    closeCommonNotificationMsg = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            isUndoNotification: false,
            removeAccessUndoList: [],
            toastNotifyType: "",
            isbulkParkingPassRenew: false,
            SelectedParkingPassess: []
        })
    }

    commonNotificationContent = (type_: string) => {
        let selectedParkingPassess: IPrivateLotsDetailsItem[] = [];
        if(this.state.SelectedParkingPassess.length !== 0){
            selectedParkingPassess = this.state.SelectedParkingPassess.filter(x => x.yardiRedirectURL === "");
        }
        switch (type_) {
            case "removeAccess":
                return "Tenant successfully removed.";
            case "removeParkingPass":
                return "Parking pass removed.";
            case "editTenant":
                return "Tenant successfully updated.";
            case "bulkRemoveAccess": {
                return `${selectedParkingPassess.length == 0 ? "" : selectedParkingPassess.length} parking passes successfully removed.`;
            }
            case "1 Month":
                if (this.state.isbulkParkingPassRenew) {
                    return `${selectedParkingPassess.length > 1 ? this.state.editSavedResponse.length : ""} parking passes have renewed for 1 month.`
                }
                else {
                    return `${this.state.selectedTenantDetails && this.state.selectedTenantDetails.tenant ? this.state.selectedTenantDetails.tenant : ""} Parking pass renewed for 1 month.`;
                }
            case "1 Year":
                if (this.state.isbulkParkingPassRenew) {
                    return `${selectedParkingPassess.length > 1 ? this.state.editSavedResponse.length : ""} parking passes have renewed for 1 year.`
                }
                else {
                    return `${this.state.selectedTenantDetails && this.state.selectedTenantDetails.tenant ? this.state.selectedTenantDetails.tenant : ""} Parking pass renewed for 1 year.`;
                }
            case "custom":
                if (this.state.isbulkParkingPassRenew) {
                    return `${selectedParkingPassess.length > 1 ? this.state.editSavedResponse.length : ""} parking passes have renewed for custom date.`
                }
                else {
                    return `${this.state.selectedTenantDetails && this.state.selectedTenantDetails.tenant ? this.state.selectedTenantDetails.tenant : ""} Parking pass renewed for custom date.`;
                }
            default:
                return type_;
        }
    }

    private refreshSyncYardi(propertyGuid: string, callback?: () => void) {
        this._privateLotService.getSyncYardiRefresh(propertyGuid, true)
            .then(response => {
                if (response.ok) {
                    callback && callback();
                }
                else {
                    response.json().then(res => {
                        ShowAlert('', res, "error");
                    }).catch(error => {
                        ShowAlert("", error, "error");
                    });
                }
            }).catch(error => {
                ShowAlert("", error, "error");
            });
    }

}

export default PrakingPassesList;


interface IPrakingPassesListProps {

}

interface IPrakingPassesListState {
    autoRenewAnchorE1: HTMLButtonElement | null;
    rightPanelState: RightPanelState;
    isFiltered: boolean;
    searchTerm: string;
    allParkingPassess: IPrivateLotsDetailsItem[];
    filteredParkingPassess: IPrivateLotsDetailsItem[];
    subFilteredParkingPassess: IPrivateLotsDetailsItem[];
    isCustomRenew: boolean;
    SelectedParkingPassess: IPrivateLotsDetailsItem[];
    selectedTenant: IPrivateLotsItem | null;
    selectedReservation: string;
    isAddNewTenant: boolean;
    properties: IPrivateLotProperty[];
    selectedTenantDetails: IPrivateLotsDetailsItem | null;
    hasUnsavedChanges: boolean;
    removeAccessUndoList: IRemoveAccessUndo[];
    toastNotifyType: string;
    isUndoNotification: boolean;
    editSavedResponse: ISaveEditTenantRespone[];
    isbulkParkingPassRenew: boolean;
    privateLotProperties: IPrivateLotProperty[];
    selectPrivateLotProperties: IPrivateLotProperty[];
    selectedPrivateLotsDetails: IPrivateLotsDetailsItem[];
    isScreenAccessible: boolean;
    selectAllRows?: () => void;
}