import BaseService from "./BaseService";
import * as CommonString from '../CommonString';
import { ConfigService } from './ConfigService';
import { GetUserDetailsService } from "./GetUserDetailsService";

export default class CompanyService extends BaseService {
    public static getCompanyList() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + 'company/get', {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
            });
    }

    public static getCompanyDropdown() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + CommonString.SERIVCE_GET_COMPANY_DROPDOWN, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
            });
    }

    public static getCompanyWithoutManager() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + CommonString.SERIVCE_GET_COMPANY_WITHOUT_MANAGER, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
            });
    }

    public static getUserList() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + CommonString.SERVICE_USER_LIST, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
            });
    }
}