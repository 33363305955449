import BaseService from "./BaseService";
import { ConfigService } from "./ConfigService";

export default class ZoneService extends BaseService {
    public getZone() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `spot/all-zones`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
            });
    }

    public getZoneInformation(zoneGuid_: any, isDetailed_: boolean) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `spot/zone-information/${zoneGuid_}/${isDetailed_}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
            });
    }

    public getZoneDailyRates(zoneGuid_: any) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `spot/zone-daily-rates/${zoneGuid_}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
            });
    }

    public getVisitorTimeBlockWithPrice(zoneGuid_: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `spot/zone-visitor-rates/${zoneGuid_}`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
            });
    }

    public getDailyTimeBlockWithPrice(zoneGuid_: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `spot/zone-daily-rates/${zoneGuid_}`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
            });
    }

    public getWeeklyTimeBlockWithPrice(zoneGuid_: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `spot/zone-weekly-rates/${zoneGuid_}`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
            });
    }
    

    public addZone(zone_: any) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "spot/insert-zone", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(zone_)
                })
            });
    }

    public updateZone(spot_: any) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "spot/update-zone", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(spot_)
                })
            });
    }

    public updateMultiZoneStatus(zoneGuids_: string[], status_: number) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `spot/update-multi-zone-status/${status_}`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(zoneGuids_)
                })
            });
    }

    public checkZoneReservation(zoneGuids_: string[]) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `spot/zone-reservation`, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(zoneGuids_)
                })
            });
    }

    public getActiveZones(propertyGuid: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(`${url}spot/cash-booking/get-active-zone/${propertyGuid}`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public getZoneById(zoneGuid: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `spot/getzone/${zoneGuid}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
            });
    }
}