import BaseService from "./BaseService";
import {ConfigService} from './ConfigService';
import {INewFloor} from "../Components/SpotMap/interface";

type FigmaGetImageResponse = {
  err: string | null,
  images: { [_id: string]: string }
};

export default class PrivateLotService extends BaseService {
  public getPrivateLotProperties(hideLoader?: boolean) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/private-lot/get-tenant-buildings", {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }, undefined, hideLoader);
      });
  }

  public getPrivateLotNotification() {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/private-lot/notification", {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
      })
      .then(r => r.json());
  }

  public getPrivatlotTenantuser(noLoader?: boolean, propertyGuid: string = '') {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/get-privatlot-tenantuser/${propertyGuid}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }, undefined, noLoader);
      });
  }

  public getPrivatlotTenantInDetail(url: string, noLoader?: boolean) {
    return ConfigService.getBaseUrl()
      .then(baseUrl => {
        return BaseService.sendRequest(`${baseUrl}${url}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }, undefined, noLoader);
      });
  }

  public getPrivatlotSpots(postData: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/private-lot/get-zone-spots", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        });
      });
  }

  public addTenant(postData: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/private-lot/add-tenant", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        });
      });
  }

  public editTenantLease(postData: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/private-lot/save-edit-tenant", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        });
      });
  }

  public async GetUtilization(propertyGuid: string, mode: string) {
    return await ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/private-lot/get-utilization/${propertyGuid}/${mode}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }, undefined, true);
      });
  }

  public getEditTenant(postData: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/private-lot/get-edit-tenant", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        });
      });
  }

  public removeAccess(postData: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/private-lot/remove-access", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        });
      });
  }

  public bulkRemoveAccess(postData: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/private-lot/bulk-remove-access", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        });
      });
  }

  public privateLotBulkUpload(formData_: FormData, url: string, hideLoader: boolean = false, showCustomLoader?: boolean) {
    return ConfigService.getBaseUrl()
      .then(baseUrl => {
        return BaseService.sendRequest(baseUrl + url, {
          method: "POST",
          body: formData_
        }, undefined, hideLoader, true, showCustomLoader);
      });
  }

  public undoRemoveAccess(postData: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/private-lot/remove-access/undo", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        });
      });
  }

  public undoUpdateTenant(postData: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/private-lot/update-tenant/undo", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        });
      });
  }

  public getFigmaNodes(fileName: string, NodeId: string) {
    return ConfigService.getFigmaFileBaseUrl()
      .then(url => {
        //url + `${fileName}`
        ///nodes?ids=50:360
        return BaseService.sendRequest(url.figmaBase + `files/${fileName}/nodes?ids=${NodeId}`, {
          method: 'get',
          headers: {
            // 'X-Figma-Token': 'figd_6LvC5cMvXIX_23QM3rZCKEBm6nKYZML3MawY5EVk'
            'X-Figma-Token': `${url.figmaToken}`
          }
        }, undefined, true);
      });
  }

  public async getFigmaImage(fileName: string, ids: string[], format_?: string) {
    if (!format_) {
      format_ = "jpg";
    }

    const maxIdsPerRequest = 100;
    let figmaAPIcalls: Promise<any>[] = [];
    let finalJsonResponse: FigmaGetImageResponse = {
      err: null,
      images: {}
    };
    let figmaUrl = await ConfigService.getFigmaImageBaseUrl();
    const getSetOfImages = async (id: string) => {
      let response = await BaseService.sendRequest(`${figmaUrl.figmaBase}images/${fileName}?ids=${id}&format=${format_}`, {
        method: 'get',
        headers: {
          // 'X-Figma-Token': 'figd_6LvC5cMvXIX_23QM3rZCKEBm6nKYZML3MawY5EVk',
          'X-Figma-Token': `${figmaUrl.figmaToken}`
        }
      }, undefined, true)
      let jsonResponse = (await response.json()) as FigmaGetImageResponse;
      if (jsonResponse.err) {
        finalJsonResponse.err = jsonResponse.err;
      }
      for (let id in jsonResponse.images) {
        finalJsonResponse.images[id] = jsonResponse.images[id];
      }
    }

    do {
      let idsToParse = ids.splice(0, Math.min(ids.length, maxIdsPerRequest));
      figmaAPIcalls.push(getSetOfImages(idsToParse.join(",")));
    }
    while (ids.length > 0);

    await Promise.all(figmaAPIcalls);
    return finalJsonResponse;
  }

  public getActiveZone(propertyGuid: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/interactive-map/get-active-zones/${propertyGuid}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }, undefined, true)
      });
  }

  public getZoneActiveSpotList(zoneGuid: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/zone-active-spots/${zoneGuid}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
      });
  }

  public RetrievePropertyMap(postData: any, isLoader: boolean = false) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/interactive-map/retrieve-map", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        }, undefined, isLoader);
      });
  }

  public CheckMapStatus(postData: any, isLoader: boolean = false) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/interactive-map/check-map-status", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        }, undefined, isLoader);
      });
  }

  public UploadMap(postData: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/interactive-map/upload-map", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        });
      });
  }

  public ConfigureMap(postData: any, isLoaderVisible: boolean) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/interactive-map/configure-map", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        }, undefined, isLoaderVisible);
      });
  }


  public ResetMap(postData: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/interactive-map/map-reset", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        });
      });
  }


  public getFloorLevel(propertyGuid?: string, hideLoader: boolean = false) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/interactive-map/get-levels/${propertyGuid ? propertyGuid : ""}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }, undefined, hideLoader);
      });
  }

  public addFloorLevel(newLevel: INewFloor, hideLoader: boolean = false) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/interactive-map/level/`, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newLevel)
        }, undefined, hideLoader);
      });
  }

  public async getBookings(propertyGuid: string, isLoader: boolean = true) {
    return await ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/interactive-map/get-bookings/${propertyGuid}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }, undefined, isLoader);
      });
  }

  public resolveIllegalParking(reportParkerGuid: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/interactive-map/illegal-resolve/${reportParkerGuid}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
      });
  }

  public visitor_verify_tenant(postData: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/visitor-parking/mobile-tenant-verification", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        });
      });
  }

  public visitor_property_availability(postData: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/booking/guest/property-availability", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        });
      });
  }

  public visitor_vehicle_accesstoken(postData: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "guest-accestoken", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        });
      });
  }

  public visitor_hold_spot(postData: any, AccessToken: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/booking/guest/hold-spot", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + AccessToken
          },
          body: JSON.stringify(postData)
        });
      });
  }

  public visitor_initiate_payment(postData: any, AccessToken: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/booking/guest/initiate-payment", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + AccessToken
          },
          body: JSON.stringify(postData)
        });
      });
  }

  public visitor_payment_status(paymentID: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/booking/guest/payment-status/${paymentID}/final`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
      });
  }

  public visitor_send_receipt_email(postData: any, AccessToken: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/booking/guest/send-receipt-email", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + AccessToken
          },
          body: JSON.stringify(postData)
        });
      });
  }

  public visitor_send_receipt_sms(postData: any, AccessToken: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/booking/guest/send-receipt-sms", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + AccessToken
          },
          body: JSON.stringify(postData)
        });
      });
  }

  public get_units_Assign_tenant(propertyGuid: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/private-lot/get-units/${propertyGuid}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        });
      });
  }

  //yardi Api
  public get_units_Assign_tenant_obj(propertyGuid: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/private-lot/units-with-yardi/${propertyGuid}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        });
      });
  }


  public get_interactive_map_features_enabled(zoneGuid: any, spotGuid: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/interactive-map/get-features/${zoneGuid}/${spotGuid}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }, undefined, true, true);
      });
  }

  public get_spots_Assign_tenant(propertyGuid: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/visitor-parking/get-spots/${propertyGuid}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }, undefined, true);
      });
  }

  public getSpotBookings(propertyGuid: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        console.log("====url====" + url + `spot/interactive-map/get-bookings/${propertyGuid}`);
        return BaseService.sendRequest(url + `spot/interactive-map/get-bookings/${propertyGuid}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
      });
  }

  // Yardi Api
  public getYardiProperties(propertyGuid: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/private-lot/yardi-properties/${propertyGuid}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        });
      });
  }

  public movePropertyOffYardi(propertyGuid: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/private-lot/desync-yardi/${propertyGuid}`, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        });
      });
  }

  public getYardiConfigs() {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/private-lot/yardi-configs`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        });
      });
  }

  public getSyncYardi(propertyGuid: string, yardiPropertyId: string, noError?: boolean) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/private-lot/sync-yardi/${propertyGuid}/${yardiPropertyId}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }, undefined, undefined, noError);
      });
  }

  public getSyncYardiRefresh(propertyGuid: string, noError?: boolean, hideLoader?: boolean, showCustomLoader?: boolean) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/private-lot/sync-yardi/${propertyGuid}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }, undefined, hideLoader, noError, showCustomLoader);
      });
  }

  public postMultiSyncYardi(postData: any, noError?: boolean) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/private-lot/portal-yardi-sync`, {
          method: 'post',
          body: postData
        }, undefined, undefined, noError, undefined, true);
      });
  }

  public GetBatchProcessSyncYardi(batchGuid: string, noError?: boolean) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/private-lot/get-batch-process/${batchGuid}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }, undefined, true, noError, undefined, true);
      });
  }

  public getVisitorEndSession(reservationGuid: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/booking/spot-visitor-end-session/${reservationGuid}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
      })

  }


}
