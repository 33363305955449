export enum RightPanelState {
  None,
  Filter,
  Details,
  Edit,
  Add,
  Notification,
  Back,
  Completed,
  ScanHistory,
  ScanHistoryDetails,
  EnforcementScanDetails,
  ReportParker,
  ConfigurationParking,
  AssignTenant,
  AssignVisitor,
  TriggerYardiSync,
  BookingDetails,
}
