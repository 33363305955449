import Button from "@material-ui/core/Button/Button";
import React from "react";
import StringHelper from "../../Helpers/StringHelper";
import "./AssignTenant.css";
export default class AssignedTenant extends React.Component<IAssignedTenantProps, IAssignedTenantState> {

    constructor(props_: IAssignedTenantProps) {
        super(props_);
        this.state = {
            spotNo: this.props.spotNo,
            teantUnitNumber: this.props.teantUnitNumber,
            teantName: this.props.teantName,
            teantEmail: this.props.teantEmail,
            teantPhone: this.props.teantPhone,
            teantDuration: this.props.teantDuration,
            teantLicencePlate: this.props.teantLicencePlate
        }
    }
    render() {
        const teantLicencePlate = this.props.teantLicencePlate.filter((x: string) => x !== "");
        return (
            <>
                <div >
                    <div className="base-root-view">
                        <div style={{ 'width': '75%', 'backgroundColor': 'white', 'alignSelf': 'center', 'borderRadius': '10px' }}>
                            <div style={{ 'width': '100%', 'height': '50px', 'backgroundColor': '#00AF85', borderTopLeftRadius: '10px', 'borderTopRightRadius': '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <div style={{ 'display': 'flex', 'flexDirection': 'row', 'width': '100%', justifyContent: 'space-between' }}>
                                    <div style={{ 'marginTop': '10px' }}>
                                        <label style={{ 'marginLeft': '20px', color: 'white', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '14px' }}>{`Spot No. ${this.state.spotNo}`}  </label>
                                    </div>
                                    {!this.props.isYardiUser && <div>

                                        <Button style={{ 'marginRight': '20px', color: 'white', 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '14px', 'backgroundColor': 'transparent' }} onClick={
                                            () => {

                                                this.props.handelEdit()
                                            }
                                        }
                                        >edit</Button>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div style={{ 'margin': '10px', display: 'flex', 'flexDirection': 'column' }}>
                                <label style={{ "marginTop": '10px', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '15px', color: '#111012' }}>Unit {this.state.teantUnitNumber}</label>
                                { this.state.teantName && <label style={{ 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '15px', color: '#111012' }}>{this.state.teantName}</label> }
                                { this.state.teantEmail && <label style={{ 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '15px', color: '#111012' }}>{this.state.teantEmail}</label> }
                                { (this.state.teantPhone && this.state.teantPhone != "0") && <label style={{ 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '15px', color: '#111012' }}>{StringHelper.ConvertPhoneNumberHyphen(this.state.teantPhone)}</label> }
                                <div style={{ 'width': '100%', 'height': '1px', backgroundColor: '#DADDDF' }}></div>
                                <label style={{ "marginTop": '10px', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '15px', color: '#111012' }}>Duration</label>
                                <label style={{ 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '15px', color: '#111012' }}>{this.state.teantDuration}</label>
                                { teantLicencePlate.length > 0 && <label style={{ "marginTop": '10px', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '15px', color: '#111012' }}>License Plate(s)</label> }
                                {teantLicencePlate.length > 0 && <div className="grid" style={teantLicencePlate.length <= 2 ? { 'width': '180px' } : { 'width': '100%' }}>
                                    {teantLicencePlate.map((val, index) =>
                                        <div className="item" style={{}}>
                                            {/* {val} */}
                                            <label style={{ 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'center', 'height': '100%', 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '15px', color: '#111012', alignItems: 'center' }}>{`${val.substring(0, 9)}${val.length > 9 ? `...` : ``}`}</label>
                                        </div>
                                    )}
                                </div>
                                }
                            </div>
                            <div >

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
interface IAssignedTenantState {
    spotNo: string,
    teantUnitNumber: string,
    teantName: string,
    teantEmail: string,
    teantPhone: string,
    teantDuration: string,
    teantLicencePlate: string[]
}

interface IAssignedTenantProps {
    spotNo: string,
    handelEdit(): void,
    teantUnitNumber: string,
    teantName: string,
    teantEmail: string,
    teantPhone: string,
    teantDuration: string,
    teantLicencePlate: string[],
    isYardiUser: boolean
}