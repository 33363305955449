import React from "react";
import { ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, FormControlLabel, Checkbox, TextField } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default class CheckboxListFilter extends React.Component<ICheckboxListFilterProps, ICheckboxListFilterState> {

    constructor(props_: ICheckboxListFilterProps) {
        super(props_);
        this.state = {
            selectedItems: props_.selectedItems,
            items: props_.items.sort()
        };
    }

    componentDidUpdate(oldProps_: ICheckboxListFilterProps) {
        if (oldProps_.selectedItems != this.state.selectedItems) {
            this.setState({
                selectedItems: this.props.selectedItems
            });
        }
    }

    render() {
        return (
            <ExpansionPanel className="filter-panel">
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography className="filter-heading">{this.props.title}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div>
                        <div>
                            <FormControlLabel
                                control={<Checkbox
                                    onClick={this._toggleSelectAll}
                                    checked={this.state.selectedItems.length === this.props.items.length &&
                                        this.props.items.length !== 0}
                                    indeterminate={this.state.selectedItems.length !== this.props.items.length &&
                                        this.state.selectedItems.length !== 0}
                                    disabled={this.props.items.length === 0}
                                    color="primary" />}
                                label="All"
                            />
                        </div>
                        {
                            this.state.items.map((value_, index_) => (
                                <div key={index_}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={this.state.selectedItems.indexOf(value_) !== -1}
                                            onClick={() => {
                                                let selectedIndex = this.state.selectedItems.indexOf(value_);
                                                let selectedItems = this.state.selectedItems;

                                                if (selectedIndex !== -1) {
                                                    selectedItems.splice(selectedIndex, 1);
                                                }
                                                else {
                                                    selectedItems.push(value_);
                                                }
                                                this.setState({
                                                    selectedItems: selectedItems
                                                });
                                            }}
                                            color="primary" />}
                                        label={value_}
                                    />
                                </div>
                            )
                            )}
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }

    private _toggleSelectAll = () => {
        let selectedItems: string[] = [];
        if (this.state.items.length !== this.state.selectedItems.length) {
            selectedItems = this.state.items.slice(0);
        }

        this.setState({
            selectedItems: selectedItems
        });
        this.props.onChange(selectedItems);
    }
}

interface ICheckboxListFilterProps {
    title: string;
    placeholderText: string;
    items: string[];
    selectedItems: string[];
    onChange: (selectedItems_: string[]) => void;
}

interface ICheckboxListFilterState {
    selectedItems: string[];
    items: string[];
}