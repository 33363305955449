export default class StringHelper {
    private static allChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

    public static padLeft(text_: string, length_: number, padChar_: string): string {
        if (padChar_) {
            while (text_.length < length_) {
                text_ = padChar_ + text_;
            }
        }

        return text_;
    }

    public static getUniqueChar(excluding_?: string[]): string {
        for (let failSafe = StringHelper.allChars.length - 1; failSafe >= 0; --failSafe) {
            let char = StringHelper.allChars[failSafe];
            if (excluding_) {
                if (excluding_.indexOf(char) > -1) {
                    continue;
                }
            }
            return char;
        }

        return "";
    }

    public static trim(string_: string, char_: string) {
        string_ = this.trimStart(string_, char_);
        string_ = this.trimEnd(string_, char_);

        return string_;
    }

    public static trimStart(string_: string, char_: string) {
        while (string_.indexOf(char_) == 0 && string_.length) {
            string_ = string_.substr(1);
        }

        return string_;
    }

    public static trimEnd(string_: string, char_: string) {
        while (string_.lastIndexOf(char_) == string_.length - 1 && string_.length) {
            string_ = string_.substr(0, string_.length - 1);
        }

        return string_;
    }

    public static isValidJsonString(string_: string) {
        try {
            JSON.parse(string_);
        } catch (e) {
            return false;
        }
        return true;
    }

    public static ConvertPhoneNumberHyphen(phoneNumber: string) {
        let replacePhone = phoneNumber ? phoneNumber.replace(/[^\w\s]/gi, '') : "";
        switch (replacePhone.length) {
            case 12:
                phoneNumber = phoneNumber.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4")                
                break;
            case 11:
                phoneNumber = phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4")                
                break;     
            case 10:
                phoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")                
                break;      
            case 7:
                phoneNumber = phoneNumber.replace(/(\d{2})(\d{2})(\d{4})/, "$1-$2-$3")                
                break;    
            default:
                break;
        }
        return phoneNumber;
    }

    public static truncate(str: string, lenght: number) {
        return (str.length > lenght) ? str.slice(0, lenght - 1) + '&hellip;' : str;
    };

    public static validateEmail(email: string) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    public static validateMobile(mobile: string) {
        var phoneno = /^\d{10}$/;
        return phoneno.test(mobile);
    }
}