import React from 'react';

class ProgressTop extends React.Component<ILoaderProps, ILoaderState> {
    constructor(props: ILoaderProps) {
        super(props);

        this.state = {
            activeLoader: false
        }
    }

    public render() {
        if (!this.props.loaderPercentage && this.props.loaderPercentage == 0) return null;
        let percentage = this.props.loaderPercentage ? this.props.loaderPercentage : 0;
        return (
            <div style={{ position: "fixed", left: "0px", top: "0px", height: "5px", transition: "all 0.5s ease-in-out", background: "#00af85", "width": percentage + "%" }}></div>
        )
    }
}

interface ILoaderProps {
    loaderPercentage?: number
}

interface ILoaderState {
    activeLoader: boolean
}

export default ProgressTop;