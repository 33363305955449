import * as React from 'react';
import './Register.css';
import { FaEdit, FaUser, FaCar, FaAddressBook, FaUserCheck, FaArrowRight, FaBuilding, FaUserTie, FaKey } from 'react-icons/fa';
import logo from '../Images/gryd_logo.png';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { CountryListModel } from '../Models/CountryListModel';
import { VehicleTypeModel } from '../Models/VehicleType';
import { WizardListModel } from '../Models/WizardModel';
import { PropertyCompanyModel } from '../Models/PropertyCompanyModel';
import { CompanyTypeModel } from '../Models/CompanyTypeModel';
import { ConfigService } from '../Services/ConfigService';
import * as CommonString from '../CommonString';
import { UserRegisterDetailsModel } from '../Models/UserRegisterDetailsModel';
import { ShowAlert } from '../Common/ShowAlert';
import LoaderComponent from '../Components/LoaderComponent';
import { UserDetailsModel } from '../Models/UserDetails';
import PasswordStrengthBar from 'react-password-strength-bar';
import HelpIcon from '@material-ui/icons/Help';
import { Tooltip, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import BaseService from '../Services/BaseService';
import { NavToHomeScreen } from '../Common/CheckScreenPermission';
import UserService from '../Services/UserService';

interface RegisterProps {
    match: {
        params: {
            registrationID: string
        }
    }
}

interface RegisterState {
    registrationID: string,
    userType: number,
    fullName: string,
    emailAddress: string,
    password: string,
    confirmPassword: string,
    mobileCode: string,
    emailCode: string,
    isMobileCodeVerified: boolean,
    isEmailCodeVerified: boolean,
    errorMessage: string,
    fullNameError: boolean,
    fullNameErrorMessage: string,
    emailError: boolean,
    passwordError: boolean,
    isOtpSent: boolean,
    showBlocker: boolean,
    isPasswordVisible: boolean,
    phone: string,
    serverPhone: string;
    step: number,
    addressOne: string,
    addressTwo: string,
    city: string,
    state: string,
    country: string,
    zipCode: string,
    countries: CountryListModel[],
    userCountry: CountryListModel,
    plateNumber: string,
    vinNumber: string,
    vehicleType: VehicleTypeModel,
    vehicleTypeList: VehicleTypeModel[],
    wizardList: WizardListModel[],
    propertyName: string,
    propertyCompanyList: PropertyCompanyModel[],
    selectedPropertyCompany: string,
    isAllowedToUsePersonalAddress: boolean,
    currentWizardIndex: number,
    companyName: string,
    companyType: string,
    companyTypeList: CompanyTypeModel[],
    buildingName: string,
    isEmailOtpValid: boolean,
    isMobileOtpValid: boolean,
    errorMobileOtpMessage: string,
    errorEmailOtpMessage: string,
    isUserAgreed: boolean,
    isValidMobileNumber: boolean,
    UserRegisterDetails: UserRegisterDetailsModel,
    SecondaryAddress1: string,
    SecondaryAddress2: string,
    SecondaryCity: string,
    SecondaryCountry: CountryListModel,
    SecondaryState: string,
    SecondaryZipCode: string,
    propertyManagerName: string,
    propertyManagerEmail: string,
    propertyManagerPhone: string,
    passwordScore: number
}

class Register extends React.Component<RegisterProps, RegisterState>  {
    private _userService: UserService;

    constructor(props: RegisterProps) {
        super(props);

        this.state = {
            passwordScore: 0,
            registrationID: this.props.match.params.registrationID,
            userType: 7, //default parker
            fullName: '',
            emailAddress: '',
            password: '',
            confirmPassword: '',
            mobileCode: '',
            emailCode: '',
            isMobileCodeVerified: false,
            isEmailCodeVerified: false,
            errorMessage: '',
            fullNameError: false,
            fullNameErrorMessage: 'Your name must be between 3 and 60 characters long',
            emailError: false,
            passwordError: false,
            isOtpSent: false,
            showBlocker: false,
            isPasswordVisible: false,
            phone: '',
            serverPhone: '',
            step: 1,
            addressOne: '',
            addressTwo: '',
            city: '',
            state: '',
            country: 'Canada',
            zipCode: '',
            SecondaryAddress1: '',
            SecondaryAddress2: '',
            SecondaryCity: '',
            SecondaryCountry: { code: 'CA', label: 'Canada', phone: '1' },
            SecondaryState: '',
            SecondaryZipCode: '',
            countries: [
                { code: 'CA', label: 'Canada', phone: '1' },
                { code: 'IN', label: 'India', phone: '91' },
                { code: 'US', label: 'United States', phone: '1' },
            ],
            userCountry: { code: 'CA', label: 'Canada', phone: '1' },
            plateNumber: '',
            vinNumber: '',
            vehicleType: { VehicleTypeID: 0, VehicleSize: '' },
            vehicleTypeList: [
                { VehicleTypeID: 1, VehicleSize: 'STANDARD (Coupe, Sedan, Wagon, Hatchback)' },
                { VehicleTypeID: 2, VehicleSize: 'OVERSIZE (SUV, Crossover, Minivan)' },
                { VehicleTypeID: 3, VehicleSize: 'SUPERSIZE (Truck/Van)' },
            ],
            wizardList: [
                {
                    wizardName: 'Parker',
                    userRoldId: 7,
                    wizard: [
                        { name: 'Personal', step: 1, title: 'Personal Information' },
                        { name: 'Contact', step: 2, title: 'Verify your email' },
                        { name: 'Confirm', step: 4, title: 'Confirm Registration' }
                    ]
                },
                {
                    wizardName: 'Admin',
                    userRoldId: 1,
                    wizard: [
                        { name: 'Personal', step: 1, title: 'Personal Information' },
                        { name: 'Contact', step: 2, title: 'Verify your email' },
                        { name: 'Confirm', step: 4, title: 'Confirm Registration' }
                    ]
                },
                {
                    wizardName: 'Owner',
                    userRoldId: 3,
                    wizard: [
                        { name: 'Personal', step: 1, title: 'Personal Information' },
                        { name: 'Contact', step: 2, title: 'Verify your email' },
                        { name: 'Confirm', step: 3, title: 'Confirm Registration' }
                    ]
                },
                {
                    wizardName: 'Company Admin',
                    userRoldId: 4,
                    wizard: [
                        { name: 'Personal', step: 1, title: 'Personal Information' },
                        { name: 'Contact', step: 2, title: 'Verify your email' },
                        { name: 'Confirm', step: 4, title: 'Confirm Registration' }
                    ]
                },
                {
                    wizardName: 'Property Manager',
                    userRoldId: 5,
                    wizard: [
                        { name: 'Personal', step: 1, title: 'Personal Information' },
                        { name: 'Contact', step: 2, title: 'Verify your email' },
                        { name: 'Confirm', step: 3, title: 'Confirm Registration' }
                    ]
                },
                {
                    wizardName: 'Tenant',
                    userRoldId: 6,
                    wizard: [
                        { name: 'Personal', step: 1, title: 'Personal Information' },
                        { name: 'Contact', step: 2, title: 'Verify your email' },
                        { name: 'Confirm', step: 4, title: 'Confirm Registration' }
                    ]
                },
            ],
            propertyName: '',
            propertyCompanyList: [
                { PropertyCompanyID: 'O13806', PropertyCompanyName: 'Ima Terry' },
                { PropertyCompanyID: 'OM43881', PropertyCompanyName: 'Adrienne Rowe' },
                { PropertyCompanyID: 'OM26661', PropertyCompanyName: 'Joie Shea' },
                { PropertyCompanyID: 'OM21144', PropertyCompanyName: 'Edda Curry' },
            ],
            isAllowedToUsePersonalAddress: false,
            selectedPropertyCompany: 'Adrienne Rowe',
            currentWizardIndex: 0,
            companyName: '',
            companyType: '',
            companyTypeList: [
                { Id: 1, Name: 'Owner' },
                { Id: 2, Name: 'Manager' },
                { Id: 2, Name: 'Owner/Manager' },
            ],
            buildingName: 'The Answer Tower - Greenwin\nThe Bazaar - Greenwin\nNoble Oak Estate - Quadreal',
            isEmailOtpValid: false,
            isMobileOtpValid: false,
            errorMobileOtpMessage: '',
            errorEmailOtpMessage: '',
            isUserAgreed: false,
            isValidMobileNumber: true,
            UserRegisterDetails: {
                AccountID: '',
                Address1: '',
                Address2: '',
                City: '',
                CompanyName: '',
                Country: '',
                CountryCode: 0,
                Email: '',
                IsAddressSame: false,
                MobileNumber: '',
                Name: '',
                Password: '',
                SecondaryAddress1: '',
                SecondaryAddress2: '',
                SecondaryCity: '',
                SecondaryCountry: '',
                SecondaryState: '',
                SecondaryZipCode: '',
                State: '',
                ZipCode: ''
            },
            propertyManagerName: 'Kenaniah Slatescar',
            propertyManagerEmail: 'kslatescar@gmail.com',
            propertyManagerPhone: '14167883781',
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleResend = this.handleResend.bind(this);

        this._userService = new UserService();
    }

    public componentDidMount = () => {

        if (typeof (Storage) !== 'undefined') {
            // localStorage.clear();
            var _localData = localStorage.getItem('userdata');
            var _accesstoken = "";

            if (_localData != null && _localData !== '') {
                var _data = JSON.parse(_localData);
                // window.location.href = '/Dashboard';
                NavToHomeScreen();
            }
        }

        // Comapny Admin: EFC0DAF7-60E1-4410-B675-001DAFCB355B
        // Property Owner: 247FBD14-D816-48B6-ADDE-393087BB4DB4
        // Building Manager: E9DCE9AF-C293-4918-9563-47F0BF08AE57
        // Tenant: 2981A158-7C47-403D-BDC1-75C4BC67FA9C
        var _userType = 7;
        var _id = this.state.registrationID;
        var _currentWizardIndex = 0;

        if (this.state.registrationID) {
            this.setState({
                showBlocker: true
            })
            this.fetchUserInformation(_id).then(UserResponse => {

                var _response = JSON.parse(UserResponse);

                if (_response.code == 200 && _response.message == 'Success') {

                    var _results = JSON.parse(_response['data']);

                    _userType = _results['UserRole'];

                    for (var i = 0; i < this.state.wizardList.length; i++) {
                        if (_userType === this.state.wizardList[i].userRoldId) {
                            _currentWizardIndex = i;
                            break;
                        }
                    }

                    let phoneNumber = "";
                    if (this.setProperyVal(_results['CountryCode']) != "0") {
                        phoneNumber += this.setProperyVal(_results['CountryCode']);
                    }
                    if (this.setProperyVal(_results['MobileNumber']).length > 2) {
                        phoneNumber += this.setProperyVal(_results['MobileNumber']);
                    }

                    this.setState({
                        showBlocker: false,
                        userType: _userType,
                        currentWizardIndex: _currentWizardIndex,
                        fullName: this.setProperyVal(_results['Name']),
                        emailAddress: this.setProperyVal(_results['Email']),
                        phone: phoneNumber,
                        serverPhone: phoneNumber,
                        // registrationID: this.setProperyVal(_results['AccountID']),
                        addressOne: this.setProperyVal(_results['Address1']),
                        addressTwo: this.setProperyVal(_results['Address2']),
                        city: this.setProperyVal(_results['City']),
                        propertyName: this.setProperyVal(_results['PropertyName']),
                        companyName: this.setProperyVal(_results['CompanyName']),
                        userCountry: this.setCountryValue(_results['Country']),
                        isAllowedToUsePersonalAddress: _results['IsAddressSame'],
                        SecondaryAddress1: this.setProperyVal(_results['SecondaryAddress1']),
                        SecondaryAddress2: this.setProperyVal(_results['SecondaryAddress2']),
                        SecondaryCity: this.setProperyVal(_results['SecondaryCity']),
                        SecondaryCountry: this.setCountryValue(_results['SecondaryCountry']),
                        SecondaryState: this.setProperyVal(_results['SecondaryState']),
                        SecondaryZipCode: this.setProperyVal(_results['SecondaryZipCode']),
                        state: this.setProperyVal(_results['State']),
                        zipCode: this.setProperyVal(_results['ZipCode'])
                    });

                } else if (_response.code == 200 && _response.message == 'Tenant') {
                    this.setState({
                        userType: 6,
                        showBlocker: false
                    })
                } else {
                    // Don't redirect
                    window.location.href = '/register';
                    // this.setDefaultUser();

                    this.setState({
                        showBlocker: false
                    });
                }
            })
        } else {
            window.location.href = '/';
        }


        /* else {
            this.setDefaultUser();
        }*/

        /*this.fetchCountryList().then(countryResponse => {
            console.log(countryResponse)
        })*/
    }

    public render() {

        return (
            <div className={'App register-page' + (this.state.showBlocker ? ' show-blocker' : '')}>
                {/* <LoaderComponent loaderVisible={this.state.showBlocker} /> */}
                <Grid container component="main" className='root'>
                    <CssBaseline />
                    {/* <Grid item xs={false} sm={4} md={4} className='image' /> */}
                    <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square className="register-form-container">
                        <div className='paper'>
                            <img src={logo} className="login-logo" />
                            {
                                this.wizard()
                            }
                            {/* <Typography component="h1" variant="h5">
                                Sign up
                            </Typography> */}
                            <div className="register-form-parent-container">
                                <form className='form' onSubmit={this.handleSubmit}>

                                    {
                                        this.state.step === 1 ? this.userPersonalDetails() : null
                                    }

                                    {
                                        this.state.step === 2 ? this.emailAndMobileOtp() : null
                                    }

                                    {/* {
                                        (this.state.step === 3 && this.state.userType != 1) ? this.loadUserTypeDetails() : null
                                    } */}

                                    {
                                        (this.state.step === 3) ? this.confirmDetails() : null
                                    }

                                    <Grid container spacing={2}>
                                        <Grid item xs>
                                            {/* <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                className='submit register-button skip-button'
                                                disabled={this.disableButton('skip')}
                                            >
                                                Skip
        </Button> */}
                                        </Grid>
                                        <Grid item xs>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                className='submit register-button'
                                                disabled={this.disableButton(this.buttonName())}
                                            >
                                                {this.buttonName()}&nbsp;{this.buttonName() === 'Next' ? <FaArrowRight size="1em" className="sign-in-button-icon" /> : null}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Box mt={2}>
                                    </Box>
                                </form>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }

    private setCountryValue(value: string) {
        var _outputValue = (value !== null) ? value : '';

        let _selectedCountry: CountryListModel = {
            code: '',
            label: '',
            phone: ''
        };

        for (var i = 0; i < this.state.countries.length; i++) {
            if (this.state.countries[i].label === _outputValue) {
                _selectedCountry = this.state.countries[i];
                break;
            }
        }

        return _selectedCountry;
    }

    private setProperyVal(value: string) {
        var _outputValue = (value !== null) ? value : '';
        return _outputValue;
    }

    private setDefaultUser() {
        var _userType = 7;
        var _id = this.state.registrationID;
        var _currentWizardIndex = 0;

        if (_id === '247FBD14-D816-48B6-ADDE-393087BB4DB4') {
            _userType = 3;
        } else if (_id === 'EFC0DAF7-60E1-4410-B675-001DAFCB355B') {
            _userType = 4;
        } else if (_id === 'E9DCE9AF-C293-4918-9563-47F0BF08AE57') {
            _userType = 5;
        } else if (_id === '2981A158-7C47-403D-BDC1-75C4BC67FA9C') {
            _userType = 6;
        }

        for (var i = 0; i < this.state.wizardList.length; i++) {
            if (_userType === this.state.wizardList[i].userRoldId) {
                _currentWizardIndex = i;
                break;
            }
        }

        this.setState({
            userType: _userType,
            currentWizardIndex: _currentWizardIndex,
            fullName: "Nisar Ahmed H",
            emailAddress: "nisarahmed.h@congruentindia.com",
            phone: "+919500080606"
        });
    }

    private fetchUserInformation(userGUID: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + CommonString.SERVICE_FETCH_ONBOARD_USER, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(userGUID)
                })
                    .then(response => response.json());
            });
    }

    private fetchCountryList() {
        return BaseService.sendRequest('http://api.geonames.org/countryInfoJSON?username=gridzapp&style=full', {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Origin': 'http://localhost:3000'
            }
        })
            .then(response => response.json());
    }

    private disableButton(buttonName: string) {
        // to disable skip button
        var isEnbled = false;

        if (buttonName === 'skip') {
            isEnbled = true;
            // if (this.state.step === 1 || (this.state.step === 4 && this.state.userType != 1) || (this.state.step === 3 && this.state.userType == 1)) {
            //     isEnbled = true;
            // }
        }

        if (buttonName === 'Next' && this.state.step === 1) {
            if (!this.state.isUserAgreed) {
                isEnbled = true;
            }
        }

        if (buttonName === 'Confirm') {
            if (!this.state.isUserAgreed || (this.state.password.length < 8 || this.state.password != this.state.confirmPassword || this.state.passwordScore <= 1)) {
                isEnbled = true;
            }
        }


        return isEnbled;
    }

    private loadUserTypeDetails() {
        return (
            <div className="form-sub">
                {
                    this.state.userType === 7 ? this.vehicleDetails() : null
                }

                {
                    this.state.userType === 3 ? this.propertyDetails() : null
                }

                {
                    this.state.userType === 4 ? this.companyDetails() : null
                }

                {
                    this.state.userType === 5 ? this.propertyManager() : null
                }

                {
                    this.state.userType === 6 ? this.propertyManagerInformation() : null
                }
            </div>
        )
    }

    private userPersonalDetails() {
        return (
            <div className="form-sub">
                <Box className="register-info">
                    Welcome to GrydPark! Please complete the information below to set up your account.
                </Box>
                <h2 className="wizard-title">Account Sign Up</h2>
                <TextField
                    error={this.state.fullName.length < 3}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Full Name"
                    name="name"
                    autoComplete="off"
                    value={this.state.fullName}
                    autoFocus
                    onChange={(e_: any) => {
                        this.setState({
                            fullName: e_.target.value,
                            fullNameError: false
                        })
                    }}
                    helperText={this.state.fullNameError ? this.state.fullNameErrorMessage : ''}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="off"
                    value={this.state.emailAddress}
                    onChange={(e_: any) => {
                        this.setState({
                            emailAddress: e_.target.value,
                            isEmailCodeVerified: false,
                            emailError: false,
                            emailCode: '',
                            isEmailOtpValid: false
                        })
                    }}
                    disabled={(this.state.userType != 6)}
                    error={!this.validateEmail(this.state.emailAddress)}
                    helperText={this.state.emailError ? 'Please enter the valid email ID' : ''}
                />
                <PhoneInput
                    isValid={(value: any, country: any) => {
                        if (this.isValidPhoneNumber(value, country.countryCode) && this.state.phone.length > 0 || (this.state.userType == 7 && this.isValidPhoneNumber(value, country.countryCode))) {
                            if (this.state.isValidMobileNumber !== this.state.isValidMobileNumber) {
                                this.setState({
                                    isValidMobileNumber: false
                                });
                            }
                            return 'Invalid Number';
                        } else if (value.match(/1234/)) {
                            return false;
                        } else {
                            return true;
                        }
                    }}
                    country={'ca'}
                    onlyCountries={['ca', 'us', 'in']}
                    placeholder={'+# ### - ### - ####'}
                    masks={{ ca: '...-...-....', us: '...-...-....', in: '...-...-....' }}
                    value={this.state.phone}
                    onChange={phone_ => {
                        this.setState({
                            phone: phone_,
                            isMobileCodeVerified: false,
                            mobileCode: '',
                            isMobileOtpValid: false,
                            isOtpSent: false
                        })
                    }}
                    onBlur={(e_: any) => {
                        this.setState({
                            phone: e_.target.value == "" ? e_.target.value : this.state.phone
                        })
                    }}
                    containerClass={'MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth' + ((this.state.userType !== 0) ? ' Mui-disabled' : '')}
                    autocompleteSearch={false}
                    inputProps={{
                        name: 'Phone',
                        required: this.state.userType == 7
                    }}
                // disabled={(this.state.userType !== 7)}
                />
                <FormControlLabel
                    control={<Checkbox
                        name="UserAgreedToSignUp"
                        checked={this.state.isUserAgreed}
                        onChange={(e_: any) => {
                            this.setState({
                                isUserAgreed: e_.target.checked
                            })
                        }}
                        color="primary" />}
                    label={(
                        <span className="selects-none">I agree to the <a href="https://parkwithgryd.com/terms-of-service/" target="_blank">Terms of Service</a> and <a href="https://parkwithgryd.com/privacy-policy/" target="_blank">Privacy Policy</a></span>
                    )}
                />
            </div>
        );
    }

    private emailAndMobileOtp() {
        return (
            <div className="form-sub">
                <Box className="register-info">
                    Please enter the verification code you received to your email address to verify your account.
                </Box>

                <h2 className="wizard-title">Verify Your Account</h2>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="off"
                    value={this.state.emailAddress}
                    className={(this.state.emailError ? 'error' : '')}
                    disabled={true}
                />

                {/* {
                    this.state.phone != "" ? <PhoneInput
                        country={'ca'}
                        onlyCountries={['ca', 'us', 'in']}
                        value={this.state.phone}
                        onChange={phone => this.setState({ phone })}
                        containerClass="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth Mui-disabled"
                        inputProps={{
                            name: 'Phone',
                            required: true,
                            autoFocus: true
                        }}
                        disabled={true}
                    /> : null
                } */}

                <TextField
                    error={this.state.isEmailOtpValid || this.state.emailCode == ""}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="emailotp"
                    label="Email verification code"
                    type="password"
                    id="emailotp"
                    onChange={(e_: any) => {
                        this.setState({
                            emailCode: e_.target.value,
                            isEmailOtpValid: false,
                            isEmailCodeVerified: false
                        })
                    }}
                    onPaste={this.checkPasteValue}
                    onKeyPress={this.checkValue}
                    value={this.state.emailCode}
                    helperText={this.state.isEmailOtpValid ? this.state.errorEmailOtpMessage : ''}
                />

                {/* {
                    this.state.phone != "" && this.state.userType != 6 ? <TextField
                        error={this.state.isMobileOtpValid || this.state.mobileCode == ""}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="mobileotp"
                        label="Mobile verification code"
                        type="password"
                        id="mobileotp"
                        onChange={(e_: any) => {
                            this.setState({
                                mobileCode: e_.target.value,
                                isMobileOtpValid: false,
                                isMobileCodeVerified: false
                            })
                        }}
                        onPaste={this.checkPasteValue}
                        onKeyPress={this.checkValue}
                        value={this.state.mobileCode}
                        helperText={this.state.isMobileOtpValid ? this.state.errorMobileOtpMessage : ''}
                    /> : null
                } */}

                {/* <p className="otp-messsage">Sent a verification code to verify your email {
                    (this.state.phone != "" && this.state.userType != 6) ? "and mobile number" : ""}</p> */}
                {/* <p className="otp-messsage">Sent a verification code to verify your email.</p> */}
                <p className="otp-messsage">Didn&rsquo;t receive a verification code? Click <span className="bold" onClick={() => {
                    this.setState({
                        showBlocker: true
                    });
                    this.RequestOTP().then(res => {
                        var _response = JSON.parse(res);

                        if (_response.code === 200) {
                            this.setState({
                                isOtpSent: true,
                                showBlocker: false
                            });
                            ShowAlert('', 'Verification code resent successfully', 'success');
                        } else {
                            this.setState({
                                showBlocker: false,
                                step: 1
                            });
                            var _results = JSON.parse(_response['data']);
                            ShowAlert('Error!', _results, 'error');
                        }
                    })
                }}>here</span> to resend</p>
                <p className="otp-messsage otp-small">Please note the verification code email may appear in your spam folder.</p>
            </div>
        );
    }

    private isValidPhoneNumber(value: any, countryCode: string) {
        var _isValidMobileNumber = ((value.length - countryCode.length) < 10);
        return _isValidMobileNumber;
    }

    private vehicleDetails() {
        return (
            <div>
                <h2 className="wizard-title">Vehicle Information</h2>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="PlateNumber"
                    name="PlateNumber"
                    className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
                    label="Plate Number"
                    value={this.state.plateNumber}
                    onChange={this.handleChange}
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="VINNumber"
                    name="VINNumber"
                    className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
                    label="Vehicle Identification Number"
                    value={this.state.vinNumber}
                    onChange={this.handleChange}
                />

                <Autocomplete
                    id="VehicleType"
                    className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
                    options={this.state.vehicleTypeList}
                    value={this.state.vehicleType}
                    onChange={(event: any, values: any) => {
                        this.setState({
                            vehicleType: values
                        })
                    }}
                    getOptionLabel={(option) => option.VehicleSize}
                    renderInput={(params) => <TextField {...params} label="Vehicle Type" variant="outlined" />}
                />
            </div>
        );
    }

    private propertyManager() {
        return (
            <div>
                <h2 className="wizard-title">Building Information</h2>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="BuildingName"
                    label="Building Name(s)"
                    name="BuildingName"
                    autoComplete="off"
                    value={this.state.buildingName}
                    multiline={true}
                    disabled={true}
                />

                {/* <ul>
                    <li>The Answer Tower - Greenwin</li>
                    <li>The Bazaar - Greenwin</li>
                    <li>Noble Oak Estate - Quadreal</li>
                </ul> */}
            </div>
        );
    }

    private propertyManagerInformation() {
        return (
            <div>
                <h2 className="wizard-title">Property Manager Information</h2>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Full Name"
                    name="name"
                    autoComplete="off"
                    value={this.state.propertyManagerName}
                    disabled={true}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="PropertyManageremail"
                    label="Email Address"
                    name="pmemail"
                    autoComplete="off"
                    value={this.state.propertyManagerEmail}
                    disabled={true}
                />
                <PhoneInput
                    country={'ca'}
                    onlyCountries={['ca', 'us', 'in']}
                    placeholder={'+# ### - ### - ####'}
                    masks={{ ca: '...-...-....', us: '...-...-....', in: '...-...-....' }}
                    value={this.state.propertyManagerPhone}
                    containerClass={'MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth Mui-disabled'}
                    inputProps={{
                        name: 'Phone',
                        required: true,
                    }}
                    disabled={true}
                />

                {/* <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="PMBuildingName"
                    label="Building Name"
                    name="PMBuildingName"
                    autoComplete="buildingName"
                    // value={this.state.buildingName}
                    value="The Answer Tower - Greenwin"
                    disabled={true}
                /> */}
            </div>
        );
    }

    private companyDetails() {
        return (
            <div>
                <h2 className="wizard-title">Company Information</h2>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="CompanyName"
                    label="Company Name"
                    name="CompanyName"
                    autoComplete="off"
                    value={this.state.companyName}
                    autoFocus
                    onChange={this.handleChange}
                />
                {/* <Autocomplete
                    id="CompanyType"
                    inputValue={this.state.companyType}
                    className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
                    options={this.state.companyTypeList}
                    getOptionLabel={(option) => option.Name}
                    renderInput={(params) => <TextField {...params} label="Company Type" variant="outlined" />}
                /> */}

                <FormControlLabel
                    control={<Checkbox value="remember"
                        name="AllowedToUsePersonalAddress"
                        checked={this.state.isAllowedToUsePersonalAddress}
                        onChange={this.handleChange}
                        color="primary" />}
                    label="My company and personal address are the same"
                />

                {
                    !this.state.isAllowedToUsePersonalAddress ? this.propertyAddressField() : null
                }
            </div>
        );
    }

    private propertyDetails() {
        return (
            <div>
                <h2 className="wizard-title">Property Information</h2>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="propertyName"
                    label="Property Name"
                    name="propertyName"
                    autoComplete="off"
                    value={this.state.propertyName}
                    autoFocus
                    onChange={this.handleChange}
                />
                <Autocomplete
                    id="PropertyComapny"
                    disabled={true}
                    inputValue={this.state.selectedPropertyCompany}
                    className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
                    options={this.state.propertyCompanyList}
                    getOptionLabel={(option) => option.PropertyCompanyName}
                    onChange={(event: any, values: any) => {
                        this.setState({
                            propertyCompanyList: values
                        })
                    }}
                    renderInput={(params) => <TextField {...params} label="Property Management Company" variant="outlined" />}
                />

                <FormControlLabel
                    control={<Checkbox value="remember"
                        name="AllowedToUsePersonalAddress"
                        checked={this.state.isAllowedToUsePersonalAddress}
                        onChange={this.handleChange}
                        color="primary" />}
                    label="My property and personal address are the same"
                />

                {/* {
                    !this.state.isAllowedToUsePersonalAddress ? this.propertyAddressField() : null
                } */}
                {
                    this.propertyAddressField()
                }
            </div>
        );
    }

    private propertyAddressField() {
        return (
            <div>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="PropertyAddressLine1"
                    name="PropertyAddressLine1"
                    className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
                    label="Address Line 1"
                    value={this.state.SecondaryAddress1}
                    onChange={this.handleChange}
                    disabled={this.state.isAllowedToUsePersonalAddress}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="PropertyAddressLine2"
                    name="PropertyAddressLine2"
                    className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
                    label="Address Line 2"
                    value={this.state.SecondaryAddress2}
                    onChange={this.handleChange}
                    disabled={this.state.isAllowedToUsePersonalAddress}
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="SecondaryCity"
                    name="SecondaryCity"
                    className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
                    label="City"
                    value={this.state.SecondaryCity}
                    onChange={this.handleChange}
                    disabled={this.state.isAllowedToUsePersonalAddress}
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="SecondaryState"
                    name="SecondaryState"
                    className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
                    label="State"
                    value={this.state.SecondaryState}
                    onChange={this.handleChange}
                    disabled={this.state.isAllowedToUsePersonalAddress}
                />

                {/* <Autocomplete
                    id="PropertyCityName"
                    className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
                    options={this.state.countries}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} label="City" variant="outlined" />}
                />

                <Autocomplete
                    id="PropertyStateName"
                    className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
                    options={this.state.countries}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} label="State" variant="outlined" />}
                /> */}

                <Autocomplete
                    id="PropertyCountryName"
                    className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
                    options={this.state.countries}
                    onChange={(event: any, values: any) => {
                        this.setState({
                            countries: values
                        })
                    }}
                    value={this.state.SecondaryCountry}
                    getOptionLabel={(option) => option.label}
                    disabled={this.state.isAllowedToUsePersonalAddress}
                    renderInput={(params) => <TextField {...params} label="Country" variant="outlined" />}
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="PropertyZipCode"
                    name="SecondaryZipCode"
                    className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
                    label="Zip Code"
                    value={this.state.SecondaryZipCode}
                    disabled={this.state.isAllowedToUsePersonalAddress}
                    onChange={this.handleChange}
                />
            </div>
        );
    }

    private confirmDetails() {
        return (
            <div className="form-sub">
                <Box className="register-info">
                    Create your password below. A minimum password strength of Medium is required.
                </Box>

                <h2 className="wizard-title">Password Creation</h2>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="off"
                    value={this.state.emailAddress}
                    className={(this.state.emailError ? 'error' : '')}
                    disabled={true}
                />
                {/* {
                    this.state.phone != "" ? <PhoneInput
                        country={'ca'}
                        onlyCountries={['ca', 'us', 'in']}
                        value={this.state.phone}
                        containerClass="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth Mui-disabled"
                        inputProps={{
                            name: 'Phone',
                            required: true,
                            autoFocus: true
                        }}
                        disabled={true}
                    /> : null
                } */}

                {
                    this.passwordOtpFeilds()
                }
            </div>
        );
    }

    private passwordOtpFeilds() {
        return (
            <div>
                <TextField
                    error={(this.state.password.length < 8)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    value={this.state.password}
                    onChange={(e_: any) => {
                        this.setState({
                            password: e_.target.value
                        })
                    }}
                    autoComplete="off"
                // helperText={(this.state.password.length < 8) ? '* Password must be at least 8 characters long.' : ''}
                />
                {
                    (this.state.password.length < 8 || this.state.passwordScore <= 1) ? <div className="password-help">
                        <Tooltip title="Please use at least 8 characters, one uppercase and optionally one special character." className="disbursement-export">
                            <InfoIcon />
                        </Tooltip>
                    </div> : null
                }
                <PasswordStrengthBar
                    scoreWords={['Weak', 'Weak', 'Medium', 'Medium', 'Strong']}
                    shortScoreWord={'Weak'}
                    onChangeScore={score => {
                        this.setState({
                            passwordScore: score
                        })
                    }}
                    password={this.state.password} />
                <TextField
                    error={this.state.password !== this.state.confirmPassword}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="confirmpassword"
                    label="Confirm Password"
                    type="password"
                    value={this.state.confirmPassword}
                    id="confirmpassword"
                    onChange={(e_: any) => {
                        this.setState({
                            confirmPassword: e_.target.value
                        })
                    }}
                    autoComplete="off"
                    helperText={(this.state.password !== this.state.confirmPassword && this.state.confirmPassword.length > 1) ? 'The password you entered did not match' : ''}
                />
            </div>
        );
    }

    private loadIcon(iconSize: string, index: number) {

        if (index === 0) {
            return (
                <FaUser size={iconSize} color={'#00000'} />
            );
        } else if (index === 1) {
            return (
                <FaUserCheck size={iconSize} color={'#00000'} />
            );
        } else if (index === 2) {
            return (
                <FaKey size={iconSize} color={'#00000'} />
            );
            // if (this.state.userType === 1) {
            //     return (<FaUserCheck size={iconSize} color={'#00000'} />)
            // } else if (this.state.userType === 7) {
            //     return (<FaCar size={iconSize} color={'#00000'} />)
            // } else if (this.state.userType === 6) {
            //     return (<FaUserTie size={iconSize} color={'#00000'} />)
            // } else {
            //     return (<FaBuilding size={iconSize} color={'#00000'} />)
            // }
        } else if (index === 3) {
            return (
                <FaUserCheck size={iconSize} color={'#00000'} />
            );
        }
    }

    private wizard() {
        return (
            <div className="wizard__steps">
                <nav className="steps">
                    {
                        this.state.wizardList[this.state.currentWizardIndex].wizard.map((wizardOption, index) => (
                            <div className={'step' + this.wizardSelection(index + 1)} key={index}>
                                <div className="step__content">
                                    <p className="step__number" onClick={() => this.navWizard(index + 1)}>
                                        {
                                            this.loadIcon('0.9em', index)
                                        }
                                    </p>
                                    <div className="checkmark" onClick={() => this.navWizard(index + 1)}>
                                        {
                                            this.loadIcon('1.5em', index)
                                        }
                                    </div>

                                    <div className="lines">
                                        {
                                            (index === 0) ? <div className="line -start"></div> : null
                                        }

                                        <div className="line -background">
                                        </div>

                                        <div className="line -progress">
                                        </div>
                                    </div>
                                    <div className="step-info">{wizardOption.title}</div>
                                </div>
                            </div>
                        ))
                    }
                </nav>
            </div>
        );
    }

    private wizardSelection(wizardIndex: number) {
        var wizardClassName = '';

        if (this.state.step === wizardIndex) {
            wizardClassName = ' -wip'
        } else if (this.state.step > wizardIndex) {
            wizardClassName = ' -completed'
        }
        return wizardClassName;
    }

    private navWizard(wizardIndex: number) {
        // this.requiredFieldValidation(wizardIndex);
        // if (wizardIndex > this.state.step) {
        if (this.state.fullName.trim() === '' || this.state.fullName.trim() === null || this.state.fullName.length < 3) {
            this.setState({
                step: 1,
                fullNameError: true,
                // fullNameErrorMessage: 'Please fill out this field.'
            });
            return false;
        }

        if (!this.validateEmail(this.state.emailAddress)) {
            this.setState({
                step: 1,
                emailError: true
            });
            return false;
        }

        if ((this.state.phone != "" && this.state.phone.length < 11) || (this.state.userType == 7 && this.state.phone == "")) {
            this.setState({
                step: 1
            });
            return false;
        }

        if (!this.state.isUserAgreed) {
            ShowAlert('Error', 'Please accept terms and conditions', 'error');
            return false;
        }

        if ((!this.state.isEmailCodeVerified || !this.state.isMobileCodeVerified) && wizardIndex === 2) {
            if (this.state.isValidMobileNumber && this.validateEmail(this.state.emailAddress)) {
                let isValidPhone: boolean = true;

                if (this.state.userType == 7) {
                    if (!this.state.isValidMobileNumber || this.state.phone == '') {
                        isValidPhone = false;
                        ShowAlert('Error!', 'Please enter the valid <b>phone number</b>.', 'error');
                    } else {
                        var CountryCode = this.state.phone.slice(0, -10).replace('+', '');
                        if (this.isValidPhoneNumber(this.state.phone, CountryCode) && this.state.phone.toString() != '1') {
                            isValidPhone = false;
                            ShowAlert('Error!', 'Please enter the valid <b>phone number</b>.', 'error');
                        }
                    }
                }

                if (isValidPhone) {
                    this.checkEmailorMobileIsExits();
                } else {
                    this.setState({
                        showBlocker: false,
                        step: 1
                    });

                }
                return false;
                /*this.setState({
                    showBlocker: true
                });
                this.RequestOTP().then(res => {
                    var _response = JSON.parse(res);

                    if (_response.code === 200) {
                        this.setState({
                            showBlocker: false
                        });
                    } else {
                        this.setState({
                            showBlocker: false,
                            step: 1
                        });
                        var _results = JSON.parse(_response['data']);
                        ShowAlert('Error!', _results, 'error');
                    }
                    // this.setState({
                    //     isOtpSent: true
                    // });
                    // ShowAlert('', 'Verification code sent successfully', 'success');
                })*/
            } else {
                this.setState({
                    step: 1
                });
                return false;
            }
        } else if (wizardIndex === 2) {
            this.setState({
                step: 3
            });
            return false;
        }

        if (!this.state.isOtpSent && wizardIndex === 3) {
            return false;
        }
        // }

        this.setState({
            step: wizardIndex
        });
    }

    private togglePasswordVisible() {
        this.setState({
            isPasswordVisible: !this.state.isPasswordVisible
        });
    }

    private DisplayMessage() {
        return (
            <div className="signInMessage">Enter the <span className="bold">verification code</span> that was sent to <span className="bold highlight">{this.state.emailAddress}</span><span className="EditButton" onClick={() => this.changeEmail()}><FaEdit size={'1.5em'} color={'#00000'} /></span></div>
        );
    }

    private changeEmail = () => {
        this.setState({
            isOtpSent: false
        });
    }

    private handleChange(event: any) {
        var _targetValue = event.target.value;
        var _FeildName = event.target.name;

        var _fullName = _FeildName === "name" ? _targetValue : this.state.fullName;
        var _emailAddress = _FeildName === "email" ? _targetValue : this.state.emailAddress;
        var _phone = _FeildName === "phone" ? _targetValue : this.state.phone;
        var _addressOne = _FeildName === "AddressLine1" ? _targetValue : this.state.addressOne;
        var _addressTwo = _FeildName === "AddressLine2" ? _targetValue : this.state.addressTwo;
        var _emailotp = _FeildName === "emailotp" ? _targetValue : this.state.emailCode;
        var _mobileotp = _FeildName === "mobileotp" ? _targetValue : this.state.mobileCode;
        var _password = _FeildName === "password" ? _targetValue : this.state.password;
        var _confirmpassword = _FeildName === "confirmpassword" ? _targetValue : this.state.confirmPassword;
        var _AllowedToUsePersonalAddress = _FeildName === "AllowedToUsePersonalAddress" ? event.target.checked : this.state.isAllowedToUsePersonalAddress;
        var _UserAgreed = _FeildName === "UserAgreed" ? event.target.checked : this.state.isUserAgreed;
        var _SecondaryAddress1 = _FeildName === "PropertyAddressLine1" ? _targetValue : this.state.SecondaryAddress1;
        var _SecondaryAddress2 = _FeildName === "PropertyAddressLine2" ? _targetValue : this.state.SecondaryAddress2;
        var _SecondaryState = _FeildName === "SecondaryState" ? _targetValue : this.state.SecondaryState;
        var _SecondaryCity = _FeildName === "SecondaryCity" ? _targetValue : this.state.SecondaryCity;
        var _SecondaryZipCode = _FeildName === "SecondaryZipCode" ? _targetValue : this.state.SecondaryZipCode;
        var _CityName = _FeildName === "CityName" ? _targetValue : this.state.city;
        var _StateName = _FeildName === "StateName" ? _targetValue : this.state.state;
        var _ZipCode = _FeildName === "ZipCode" ? _targetValue : this.state.zipCode;
        var _SecondaryCountry = this.state.SecondaryCountry;
        var _VINNumber = _FeildName === "VINNumber" ? _targetValue : this.state.vinNumber;
        var _PlateNumber = _FeildName === "PlateNumber" ? _targetValue : this.state.plateNumber;

        var _fullNameError = (this.state.fullNameError && _FeildName === "name" && _targetValue.length < 3) ? true : false;

        var _isMobileCodeVerified = this.state.isMobileCodeVerified;
        var _isEmailCodeVerified = this.state.isEmailCodeVerified;

        if (_FeildName === "email" || _FeildName === "phone") {
            _isMobileCodeVerified = false;
            _isEmailCodeVerified = false;
            _emailotp = '';
            _mobileotp = '';
            _password = '';
            _confirmpassword = '';
        }

        if (_FeildName === "AllowedToUsePersonalAddress") {
            if (_AllowedToUsePersonalAddress) {
                _SecondaryAddress1 = this.state.addressOne;
                _SecondaryAddress2 = this.state.addressTwo;
                _SecondaryState = this.state.state;
                _SecondaryCity = this.state.city;
                _SecondaryZipCode = this.state.zipCode;
                _SecondaryCountry = this.state.userCountry;

            } else {
                _SecondaryAddress1 = '';
                _SecondaryAddress2 = '';
                _SecondaryState = '';
                _SecondaryCity = '';
                _SecondaryZipCode = '';
                _SecondaryCountry = { code: 'CA', label: 'Canada', phone: '1' };
            }
        }

        this.setState({
            fullName: _fullName,
            emailAddress: _emailAddress,
            emailError: !this.validateEmail(_emailAddress),
            phone: _phone,
            addressOne: _addressOne,
            addressTwo: _addressTwo,
            emailCode: _emailotp,
            mobileCode: _mobileotp,
            password: _password,
            confirmPassword: _confirmpassword,
            isAllowedToUsePersonalAddress: _AllowedToUsePersonalAddress,
            fullNameError: _fullNameError,
            isEmailOtpValid: false,
            isMobileOtpValid: false,
            isUserAgreed: _UserAgreed,
            SecondaryAddress1: _SecondaryAddress1,
            SecondaryAddress2: _SecondaryAddress2,
            SecondaryCity: _SecondaryCity,
            SecondaryState: _SecondaryState,
            SecondaryZipCode: _SecondaryZipCode,
            city: _CityName,
            state: _StateName,
            zipCode: _ZipCode,
            SecondaryCountry: _SecondaryCountry,
            vinNumber: _VINNumber,
            plateNumber: _PlateNumber,
            isMobileCodeVerified: _isMobileCodeVerified,
            isEmailCodeVerified: _isEmailCodeVerified
        });

        /*if (event.target.name === 'email') {
            this.setState({
                emailAddress: _targetValue,
                emailError: !this.validateEmail(_targetValue),
                passwordError: (this.state.password.length < 3),
                errorMessage: ''
            });
        } else if (event.target.type === 'number' || event.target.type === 'password') {
            this.setState({
                password: _targetValue,
                passwordError: (this.state.password.length < 3),
                errorMessage: ''
            });
        }*/
    }

    private checkValue(event: any) {
        var charCode = (event.which) ? event.which : event.keyCode;

        if (charCode > 31 && ((charCode < 48 || charCode > 57) || charCode === 101 || charCode === 69)) {
            event.preventDefault();
            return false;
        }
        if (event.target.value.length >= 6) {
            event.preventDefault();
            return false;
        }

        return true;
    }

    private checkPasteValue(event: any) {
        var str = event.clipboardData.getData('Text');

        if ((str.length > 6) || isNaN(str)) {
            event.preventDefault();
            return false;
        }
        return true;
    }

    private handleResend() {
        this.setState({
            errorMessage: '',
        });

        /*this.RequestOTP(this.state.emailAddress, 1, 0).then(emailResponse => {
    
            this.setState({
                showBlocker: false
            });
            // ShowAlert('', TransRawText('sign_email_success'), 'success');
        }).catch(error => {
            this.setState({
                errorMessage: '',
                showBlocker: false
            });
            // ShowAlert('', TransRawText('sign_unable_to_connect'), 'error');
        });*/
    }

    private RequestOTP() {
        var email = this.state.emailAddress;
        var CountryCode = this.state.phone.slice(0, -10).replace('+', '');
        var MobileNumber = this.state.phone.slice(-10);

        let postData: any = {}

        /*if (this.state.phone != "" && this.state.userType != 6) {
            postData = { 'Email': email, 'CountryCode': Number(CountryCode), 'MobileNumber': MobileNumber, 'VerificationType': 1 };

            if (this.state.isMobileCodeVerified && this.state.isEmailCodeVerified) {
                return;
            }

        } else {
            postData = { 'Email': email, 'VerificationType': 1 };

            if (this.state.isEmailCodeVerified) {
                return;
            }
        }*/

        postData = { 'Email': email, 'VerificationType': 1, 'Name': this.state.fullName };

        /*if (this.state.isEmailCodeVerified) {
            return;
        }*/

        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + CommonString.SERVICE_REQUEST_OTP, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(postData)
                })
                    .then(response => response.json());
            });
    }

    private submitRegistration() {
        var CountryCode = this.state.phone.slice(0, -10).replace('+', '');
        var MobileNumber = this.state.phone.slice(-10);
        var _IsAddressSame = this.state.isAllowedToUsePersonalAddress;
        let _UserRegisterDetails: UserRegisterDetailsModel = {
            AccountID: this.state.registrationID,
            Address1: this.state.addressOne,
            Address2: this.state.addressTwo,
            City: this.state.city,
            CompanyName: this.state.companyName,
            Country: this.state.country,
            CountryCode: Number(CountryCode),
            Email: this.state.emailAddress,
            IsAddressSame: _IsAddressSame,
            MobileNumber: MobileNumber,
            Name: this.state.fullName,
            Password: this.state.password,
            SecondaryAddress1: _IsAddressSame ? this.state.addressOne : this.state.SecondaryAddress1,
            SecondaryAddress2: _IsAddressSame ? this.state.addressTwo : this.state.SecondaryAddress2,
            SecondaryCity: _IsAddressSame ? this.state.city : this.state.SecondaryCity,
            SecondaryCountry: _IsAddressSame ? this.state.userCountry.label : this.state.SecondaryCountry.label,
            SecondaryState: _IsAddressSame ? this.state.state : this.state.SecondaryState,
            SecondaryZipCode: _IsAddressSame ? this.state.zipCode : this.state.SecondaryZipCode,
            State: this.state.state,
            ZipCode: this.state.zipCode
        };

        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + CommonString.SERVICE_USER_REGISTER, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(_UserRegisterDetails)
                })
                //.then(response => response.json());
            });
    }

    private verifyOTP() {
        var email = this.state.emailAddress;
        var CountryCode = this.state.phone.slice(0, -10).replace('+', '');
        var MobileNumber = this.state.phone.slice(-10);

        let postData: any = {}

        if (this.state.phone != "" && this.state.userType != 6) {

            if (!this.state.isEmailCodeVerified) {
                postData.Email = email;
                postData.EmailOTPCode = Number(this.state.emailCode);
                postData.VerificationType = 2;
            }

            if (!this.state.isMobileCodeVerified) {
                postData.CountryCode = Number(CountryCode);
                postData.MobileNumber = MobileNumber;
                postData.MobileOTPCode = Number(this.state.mobileCode);
                postData.VerificationType = 2;
            }

            /*postData = {
                'Email': email,
                'CountryCode': Number(CountryCode),
                'MobileNumber': MobileNumber,
                'EmailOTPCode': Number(this.state.emailCode),
                'MobileOTPCode': Number(this.state.mobileCode),
                'VerificationType': 2
            };*/

        } else {
            postData = {
                'Email': email,
                'EmailOTPCode': Number(this.state.emailCode),
                'VerificationType': 2
            };
        }

        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + CommonString.SERVICE_REQUEST_OTP, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(postData)
                })
                    .then(response => response.json());
            });
    }

    private validateJsonString(str: string) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    private validateEmail(email: string) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    private validateMobile(mobile: string) {
        var phoneno = /^\d{10}$/;
        return phoneno.test(mobile);
    }

    private handleSubmit(event: any) {
        var StepError = false;
        var _fullNameError = false;

        let state: any = {};
        if (this.state.step === 1) {
            if (this.state.fullName.length < 3) {
                StepError = true;
                state.fullNameError = true;
            }

            if (!this.validateEmail(this.state.emailAddress)) {
                StepError = true;
                state.emailError = true;
            }

            if (this.state.phone != '' && this.state.phone.length < 11) {
                StepError = true;
            }
        }

        if (StepError) {
            this.setState(state);
            event.preventDefault();
            return false;
        }

        if (this.state.step === 2 && (!this.state.isEmailCodeVerified || !this.state.isMobileCodeVerified)) {
            this.setState({
                showBlocker: true
            });
            this.verifyOTP().then(otpResponse => {
                var _response = JSON.parse(otpResponse);

                if (_response.code === 200) {

                    var _results = JSON.parse(_response['data']);

                    var _emailStatus = _results['Email'];
                    // var _mobileStatus = (this.state.phone == "" || this.state.userType == 6) ? 'Valid' : _results['Mobile'];
                    var _mobileStatus = 'Valid';

                    var _isEmailCodeVerified = false;
                    var _isMobileCodeVerified = false;
                    var _step = 2;

                    if (_emailStatus === 'Valid' || this.state.isEmailCodeVerified) {
                        _isEmailCodeVerified = true;
                    }

                    if (_mobileStatus === 'Valid' || this.state.isMobileCodeVerified) {
                        _isMobileCodeVerified = true;
                    }

                    if ((_emailStatus === 'Valid' || this.state.isEmailCodeVerified) &&
                        (_mobileStatus === 'Valid' || this.state.isMobileCodeVerified)) {
                        _step = 3;
                    }

                    this.setState({
                        isEmailOtpValid: (_emailStatus !== 'Valid'),
                        isMobileOtpValid: (_mobileStatus !== 'Valid'),
                        isEmailCodeVerified: _isEmailCodeVerified,
                        isMobileCodeVerified: _isMobileCodeVerified,
                        errorEmailOtpMessage: 'Entered verification code is ' + ((_emailStatus === 'Invalid') ? 'Incorrect' : _emailStatus),
                        errorMobileOtpMessage: 'Entered verification code is ' + ((_mobileStatus === 'Invalid') ? 'Incorrect' : _emailStatus),
                        step: _step,
                        showBlocker: false
                    });

                }
            });
            event.preventDefault();
            return false;
        }

        if (this.state.step === 3 && (this.state.isEmailCodeVerified && this.state.isMobileCodeVerified)) {
            if (this.state.password === this.state.confirmPassword) {
                this.setState({
                    showBlocker: true
                })
                this.submitRegistration().then(userResponse => {
                    if (userResponse.ok) {
                        this.setState({
                            showBlocker: false
                        })
                        userResponse.text().then(r => {
                            if (r.indexOf('SUCCESS') != -1) {
                                ShowAlert('', 'Successfully registered', 'success').then(r => {
                                    // window.location.href = '/';

                                    if (this.state.userType == 9) {
                                        window.location.href = '/parking-enforcement';
                                    } else {
                                        this.setState({
                                            showBlocker: true
                                        })
                                        this.signIn(this.state.emailAddress, this.state.password).then(signInResponse => {
                                            if (signInResponse.ok) {
                                                let state: any = {
                                                    showBlocker: false
                                                };
                                                signInResponse.text().then(resp => {
                                                    let _userDetails: UserDetailsModel = JSON.parse(resp);
                                                    localStorage.setItem('userdata', JSON.stringify(_userDetails));
                                                    // window.location.href = '/Dashboard';
    
                                                    // NavToHomeScreen();
                                                    this.getAccessibleScreen();
                                                });
                                            } else {
                                                signInResponse.text().then(resp => {
                                                    let state: any = {
                                                        showBlocker: false
                                                    };
    
                                                    if (resp === '"Invalid user name!"') {
                                                        state.errorMessage = 'Entered email is incorrect or not registered.';
                                                        state.emailError = true;
                                                    } else if (resp === '"Incorrect password!"') {
                                                        state.errorMessage = JSON.parse(resp);
                                                        state.passwordError = true;
                                                        state.password = '';
                                                    } else if (resp === '"User doesn\'t have a valid password!"') {
                                                        state.errorMessage = 'Entered email/mobile is incorrect or not registered.';
                                                        state.emailError = true;
                                                        state.password = '';
                                                    } else {
                                                        state.errorMessage = JSON.parse(resp);
                                                    }
    
                                                    ShowAlert('Error!', state.errorMessage.toString(), 'error').then(() => {
                                                        window.location.href = '/';
                                                    });
                                                    this.setState(state);
                                                });
    
                                            }
                                        }).catch(error => {
                                            this.setState({
                                                errorMessage: '',
                                                showBlocker: false
                                            });
                                            ShowAlert('Server Error!', error.toString(), 'error');
                                        });
                                    }
                                });
                            } else {
                                if (r.indexOf('Email id already') != -1) {
                                    ShowAlert('Error!', 'Email id already exits!', 'error');
                                } else {
                                    ShowAlert('Error!', r.toString(), 'error');
                                }

                            }
                        })
                    } else {
                        this.setState({
                            showBlocker: false
                        })
                        userResponse.text().then(r => {
                            ShowAlert('Error!', JSON.parse(r).toString(), 'error');
                        })
                    }


                });
            }
        }

        // let totalSteps: number = this.state.userType == 1 ? 3 : 4;
        let totalSteps: number = 3;

        if ((!this.state.isEmailCodeVerified || !this.state.isMobileCodeVerified) && (this.state.step + 1) === 2) {
            if (this.state.isValidMobileNumber && this.validateEmail(this.state.emailAddress) && this.state.isUserAgreed) {
                let isValidPhone: boolean = true;

                if (this.state.userType == 7) {
                    if (!this.state.isValidMobileNumber || this.state.phone == '') {
                        isValidPhone = false;
                        ShowAlert('Error!', 'Please enter the valid <b>phone number</b>.', 'error');
                    } else {
                        var CountryCode = this.state.phone.slice(0, -10).replace('+', '');
                        if (this.isValidPhoneNumber(this.state.phone, CountryCode) && this.state.phone.toString() != '1') {
                            isValidPhone = false;
                            ShowAlert('Error!', 'Please enter the valid <b>phone number</b>.', 'error');
                        }
                    }
                }

                if (isValidPhone) {
                    this.checkEmailorMobileIsExits();
                } else {
                    this.setState({
                        showBlocker: false,
                        step: 1
                    });

                }
                // this.setState({
                //     showBlocker: true
                // });

                /*this.RequestOTP().then(res => {
                    var _response = JSON.parse(res);

                    if (_response.code === 200) {
                        this.setState({
                            showBlocker: false
                        });
                    } else {
                        this.setState({
                            showBlocker: false,
                            step: 1
                        });
                        var _results = JSON.parse(_response['data']);
                        ShowAlert('Error!', _results, 'error');
                    }

                });*/
                event.preventDefault();
                return false;
                // this.RequestOTP()
            } else {
                this.setState({
                    step: 1
                });
                event.preventDefault();
                return false;
            }
        } else if ((this.state.step + 1) === 2) {
            this.setState({
                step: 3
            });
            event.preventDefault();
            return false;
        }

        this.setState({
            step: this.state.step < totalSteps ? (this.state.step + 1) : this.state.step
        });

        event.preventDefault();
    }

    private checkEmailorMobileIsExits() {
        this.setState({
            showBlocker: true
        });

        let checkToExistMobile: boolean = true;

        console.log(this.state.phone);
        if ((this.state.serverPhone == this.state.phone || this.state.phone == "") && this.state.userType != 7) {
            checkToExistMobile = false;
        }

        if (checkToExistMobile) {
            this.isMobileOrEmailExists().then(verifyRes => {
                if (verifyRes.ok) {
                    this.setState({
                        showBlocker: false
                    })
                    verifyRes.text().then(r => {
                        if (r.indexOf('not exists') != -1) {
                            this.requestOtpToVerify();
                        } else {
                            this.setState({
                                showBlocker: false
                            });
                            let respMsg = JSON.parse(r);
                            let errorMsg: string = "";

                            if (respMsg.indexOf('Email id and Mobile') != -1) {
                                errorMsg = "There is already an account associated with this email id and mobile number."
                            } else if (respMsg == 'Email id already exists!') {
                                errorMsg = "There is already an account associated with this email id."
                            } else if (respMsg == 'Mobile number already exists!') {
                                errorMsg = "There is already an account associated with this mobile number."
                            } else {
                                errorMsg = respMsg;
                            }

                            ShowAlert('Error!', errorMsg, 'error');
                        }
                    })
                }
                else {
                    this.setState({
                        showBlocker: false
                    })
                    verifyRes.text().then(r => {
                        ShowAlert('Error!', r.toString(), 'error');
                    })
                }
            });
        } else {
            this.requestOtpToVerify();
        }
    }

    private requestOtpToVerify() {
        if (!this.state.isEmailCodeVerified) {
            this.RequestOTP().then(res => {
                console.log(res);
                var _response = JSON.parse(res);

                if (_response.code === 200) {
                    this.setState({
                        showBlocker: false,
                        step: 2
                    });
                } else {
                    this.setState({
                        showBlocker: false,
                        step: 1
                    });
                    var _results = JSON.parse(_response['data']);
                    ShowAlert('Error!', _results, 'error');
                }

            });
        } else {
            this.setState({
                showBlocker: false,
                step: 3
            });
        }

    }

    private buttonName() {
        var name = "Next";
        // let totalSteps: number = this.state.userType == 1 ? 3 : 4;
        let totalSteps: number = 3;
        //if (this.state.step === 2 && (!this.state.isEmailCodeVerified && !this.state.isMobileCodeVerified)) {
        //else if (this.state.step === totalSteps && (this.state.isEmailCodeVerified && this.state.isMobileCodeVerified)) {    
        if (this.state.step === 2) {
            name = 'Verify';
        } else if (this.state.step === totalSteps) {
            name = 'Confirm';
        }

        return name;
    }

    private signIn(email: string, password: string) {

        var postData = { "UserName": email, "Password": password };

        console.log(postData);

        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + CommonString.SERVICE_SIGN_IN, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(postData)
                });
            });
    }

    private isMobileOrEmailExists() {
        var MobileNumber = this.state.phone.slice(-10);

        var postData: any = {}

        //this.state.userType != 7
        if (this.state.userType == 6) {
            postData.EmailId = this.state.emailAddress;
        }

        postData.MobileNumber = Number(MobileNumber);

        //postData = { "EmailId": this.state.emailAddress, "MobileNumber": Number(MobileNumber) };

        console.log(postData);

        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + 'user/IsEmailMobileExists', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(postData)
                });
            });
    }

    private postJsonService(serviceUrl: string, postData: any) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + serviceUrl, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(postData)
                })
                    .then(response => response.json());
            });
    }

    private getAccessibleScreen() {
        this._userService.getAccessibleScreen()
            .then(r => {
                if (r.ok) {
                    r.json().then(results => {
                        var resultString = JSON.stringify(results).toLocaleLowerCase();

                        localStorage.setItem('accessibleScreen', resultString);
                        
                        NavToHomeScreen();
                    })
                }
            })
    }
}

export default Register;