import Geocode from "react-geocode";

export default class GeoHelper {
    public static apiKey = "AIzaSyDWNcJWEmXguvxEy3QmtoN3I5bzceYGm1s";

    public static getGeoLocationFromAddress = (address_: string): Promise<IGeoLocation | null> => {
        // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
        Geocode.setApiKey(GeoHelper.apiKey);

        // set response language. Defaults to english.
        Geocode.setLanguage("en");

        // set response region. Its optional.
        // A Geocoding request with region=es (Spain) will return the Spanish city.
        Geocode.setRegion("es");

        // Enable or disable logs. Its optional.
        Geocode.enableDebug();
        return Geocode.fromAddress(address_)
            .then(
                response => {
                    if (response.results.length > 0) {
                        let result = response.results[0];
                        return {
                            lat: result.geometry.location.lat,
                            lng: result.geometry.location.lng,
                            address_components: result.address_components
                        } as IGeoLocation;
                    }

                    return null;
                },
                error => {
                    console.error(error);
                    return null;
                }
            );
    }
}

interface IGeoLocation {
    lat: number;
    lng: number;
    address_components: any;
}