import { Grid, Select, MenuItem } from '@material-ui/core';
import React from 'react';
import './Dashboard.css'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PropertyService from '../../Services/PropertyService';
import { Line } from 'react-chartjs-2';
import { IAdminWeeklyRevenue } from './Interface';

export default class WeeklyRevenue extends React.Component<WeeklyRevenueProps, WeeklyRevenueState> {
    private _propertyService: PropertyService;

    constructor(props: WeeklyRevenueProps) {
        super(props);
        this.state = {
            selectedCities: []
        }
        this._propertyService = new PropertyService();
    }

    public componentDidMount = () => {
        this.loadWeeklyData();
    }

    public componentDidUpdate = () => {
        if (this.state.selectedCities != this.props.cities) {
            this.loadWeeklyData();
        }
    }

    private loadWeeklyData() {
        let widgetData: any = {
            selectedCity: this.props.cities.length > 0 ? this.props.cities.toString() : null,
        };
        this._propertyService.getAdminDashboardWeeklyRevenue(widgetData)
            .then(data => {
                if (data.ok) {
                    data.json().then(d => {
                        this.setState({
                            adminWeeklyRevenue: d,
                            selectedCities: this.props.cities
                        })
                    })
                }
            })
            .catch((error) => {
                console.error('Error:', JSON.stringify(error));
            });
    }

    public render() {
        const RevenueData = {
            labels: ['M', 'T', 'W', 'TH', 'F', 'SA', 'SU'],
            datasets: [
                {
                    label: 'Current week',
                    fill: false,
                    // lineTension: 0.1,
                    backgroundColor: 'rgba(0,175,133,0.1)',
                    borderColor: 'rgba(0,175,133,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 0.5,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: [
                        this.state.adminWeeklyRevenue?.currentWeekReservationRevenue.mondayRevenue ? this.state.adminWeeklyRevenue?.currentWeekReservationRevenue.mondayRevenue : 0,
                        this.state.adminWeeklyRevenue?.currentWeekReservationRevenue.tuesdayRevenue ? this.state.adminWeeklyRevenue?.currentWeekReservationRevenue.tuesdayRevenue : 0,
                        this.state.adminWeeklyRevenue?.currentWeekReservationRevenue.wednesdayRevenue ? this.state.adminWeeklyRevenue?.currentWeekReservationRevenue.wednesdayRevenue : 0,
                        this.state.adminWeeklyRevenue?.currentWeekReservationRevenue.thursdayRevenue ? this.state.adminWeeklyRevenue?.currentWeekReservationRevenue.thursdayRevenue : 0,
                        this.state.adminWeeklyRevenue?.currentWeekReservationRevenue.fridayRevenue ? this.state.adminWeeklyRevenue?.currentWeekReservationRevenue.fridayRevenue : 0,
                        this.state.adminWeeklyRevenue?.currentWeekReservationRevenue.saturdayRevenue ? this.state.adminWeeklyRevenue?.currentWeekReservationRevenue.saturdayRevenue : 0,
                        this.state.adminWeeklyRevenue?.currentWeekReservationRevenue.sundayRevenue ? this.state.adminWeeklyRevenue?.currentWeekReservationRevenue.sundayRevenue : 0
                    ]
                },
                {
                    label: 'Previous week',
                    fill: false,
                    // lineTension: 0.1,
                    backgroundColor: 'rgba(66,191,223,0.1)',
                    borderColor: 'rgba(66,191,223,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 0.5,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: [
                        this.state.adminWeeklyRevenue?.lastWeekReservationRevenue.mondayRevenue ? this.state.adminWeeklyRevenue?.lastWeekReservationRevenue.mondayRevenue : 0,
                        this.state.adminWeeklyRevenue?.lastWeekReservationRevenue.tuesdayRevenue ? this.state.adminWeeklyRevenue?.lastWeekReservationRevenue.tuesdayRevenue : 0,
                        this.state.adminWeeklyRevenue?.lastWeekReservationRevenue.wednesdayRevenue ? this.state.adminWeeklyRevenue?.lastWeekReservationRevenue.wednesdayRevenue : 0,
                        this.state.adminWeeklyRevenue?.lastWeekReservationRevenue.thursdayRevenue ? this.state.adminWeeklyRevenue?.lastWeekReservationRevenue.thursdayRevenue : 0,
                        this.state.adminWeeklyRevenue?.lastWeekReservationRevenue.fridayRevenue ? this.state.adminWeeklyRevenue?.lastWeekReservationRevenue.fridayRevenue : 0,
                        this.state.adminWeeklyRevenue?.lastWeekReservationRevenue.saturdayRevenue ? this.state.adminWeeklyRevenue?.lastWeekReservationRevenue.saturdayRevenue : 0,
                        this.state.adminWeeklyRevenue?.lastWeekReservationRevenue.sundayRevenue ? this.state.adminWeeklyRevenue?.lastWeekReservationRevenue.sundayRevenue : 0
                    ]
                },
                {
                    label: 'Two weeks ago',
                    fill: false,
                    // lineTension: 0.1,
                    backgroundColor: 'rgba(255,158,27,0.1)',
                    borderColor: 'rgba(255,158,27,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 0.5,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: [
                        this.state.adminWeeklyRevenue?.lastbutPrevWeekReservationRevenue.mondayRevenue ? this.state.adminWeeklyRevenue?.lastbutPrevWeekReservationRevenue.mondayRevenue : 0,
                        this.state.adminWeeklyRevenue?.lastbutPrevWeekReservationRevenue.tuesdayRevenue ? this.state.adminWeeklyRevenue?.lastbutPrevWeekReservationRevenue.tuesdayRevenue : 0,
                        this.state.adminWeeklyRevenue?.lastbutPrevWeekReservationRevenue.wednesdayRevenue ? this.state.adminWeeklyRevenue?.lastbutPrevWeekReservationRevenue.wednesdayRevenue : 0,
                        this.state.adminWeeklyRevenue?.lastbutPrevWeekReservationRevenue.thursdayRevenue ? this.state.adminWeeklyRevenue?.lastbutPrevWeekReservationRevenue.thursdayRevenue : 0,
                        this.state.adminWeeklyRevenue?.lastbutPrevWeekReservationRevenue.fridayRevenue ? this.state.adminWeeklyRevenue?.lastbutPrevWeekReservationRevenue.fridayRevenue : 0,
                        this.state.adminWeeklyRevenue?.lastbutPrevWeekReservationRevenue.saturdayRevenue ? this.state.adminWeeklyRevenue?.lastbutPrevWeekReservationRevenue.saturdayRevenue : 0,
                        this.state.adminWeeklyRevenue?.lastbutPrevWeekReservationRevenue.sundayRevenue ? this.state.adminWeeklyRevenue?.lastbutPrevWeekReservationRevenue.sundayRevenue : 0,
                    ]
                }
            ]
        };
        const RevenueOption = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [
                    {
                        gridLines: {
                            display: true,
                            color: "rgba(255,99,132,0.2)"
                        },
                        ticks: {
                            suggestedMin: 0,
                            maxTicksLimit: 8,
                            stepSize: 50,
                            beginAtZero: true,
                            /*callback: function (value: any, index: any, values: any) {
                                return "$" + Number(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }*/
                        }
                    }
                ],
                xAxes: [
                    {
                        gridLines: {
                            display: false
                        }
                    }
                ]
            }
        };
        return (
            <Line
                data={RevenueData}
                options={RevenueOption}
                height={300}
            />
        )
    }

}

interface WeeklyRevenueProps {
    cities: string[];
}

interface WeeklyRevenueState {
    adminWeeklyRevenue?: IAdminWeeklyRevenue;
    selectedCities: string[];
}