import React from "react";
import DataGrid from "../../Components/DataGrid/DataGrid";
import { IDataGridColumn, IAppliedFilter } from "../../Components/DataGrid/Interfaces";
import { ISpot, ISpotFilter } from "./Interfaces";
import BaseSpotList from "./BaseSpotList";
import { ShowAlert } from "../../Common/ShowAlert";

export default class UnclaimedSpotList extends BaseSpotList {
    protected _hideToolbar: boolean = true;

    private _spotListColumns: IDataGridColumn<ISpot>[] = [
        {
            key: "spotStatusId",
            name: "STATUS",
            contentProvider: (row_: ISpot) => {
                return (
                    <div className={'list-status ' + ((row_.spotStatusId === 1) ? 'active' : 'inactive')}></div>
                );
            }
        },
        {
            key: "friendlySpotId",
            name: "Spot Number"
        },
        {
            key: "propertyName",
            name: "Property Name"
        },
        {
            key: "propertyCity",
            name: "City"
        },
        {
            key: "propertyOwnerName",
            name: "Owner"
        },
        {
            key: "propertyCompanyName",
            name: "Manager"
        }
    ];

    protected _getAllSpots = () => {
        return this._spotService.getUnclaimedSpotList()
            .then(r => r.json());
    }

    protected _getSpots = () => {
        return this._spotService.getUnclaimedSpotList()
            .then(r => r.json());
    }

    protected _renderDataGrid = () => {
        if (this._isTenantUser) {
            return <DataGrid
                title="Spot"
                data={this.state.subFilteredSpots}
                columns={this._spotListColumns}
                appliedFilter={this.state.appliedFilter}
                defaultSortColumnKey="friendlySpotId"
                onFilterTagRemoved={(filter_: IAppliedFilter) => {
                    this.setState({
                        appliedFilter: filter_ as ISpotFilter,
                        _autoApplyFilterKey: new Date().getTime()
                    });
                }}
                popoverItems={[
                    {
                        key: "claim",
                        label: "Claim"
                    }
                ]}
                multiPopoverItems={[
                    {
                        key: "claim",
                        label: "Claim"
                    }
                ]}
                onPopoverClick={(key_, spot_) => {
                    this._claimSpot([spot_]);
                }}
                onMultiPopoverClick={(key_, spots_) => {
                    this._claimSpot(spots_);
                }}
            />
        }
        else {
            return null;
        }
    }

    private _claimSpot(spots_: ISpot[]) {
        this._spotService.claimSpots(spots_.map(s => s.spotGuid))
            .then(r => {
                if (r.ok) {
                    let claimedSpots = spots_.splice(0);
                    let allSpots = this.state.allSpots.slice(0);
                    let filteredSpots = this.state.filteredSpots.slice(0);
                    let subFilteredSpots = this.state.subFilteredSpots.slice(0);

                    for (let spot of claimedSpots) {
                        allSpots.splice(allSpots.indexOf(spot), 1);
                        filteredSpots.splice(filteredSpots.indexOf(spot), 1);
                        subFilteredSpots.splice(subFilteredSpots.indexOf(spot), 1);
                    }

                    this.setState({
                        allSpots: allSpots,
                        filteredSpots: filteredSpots,
                        subFilteredSpots: subFilteredSpots
                    });

                    ShowAlert("", "Spot claim request sent successfully!", "success");
                }
            });
    }
}