import React from "react";
import DataGrid from "../../Components/DataGrid/DataGrid";
import { IDataGridColumn, IAppliedFilter, DataGridFilterType } from "../../Components/DataGrid/Interfaces";
import { format } from 'date-fns';
import DataGridToolbar from "../../Components/DataGrid/DataGridToolbar";
import { Grid, Drawer, Button, Tabs, Tab, IconButton, Dialog, DialogTitle } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { IUser, IUserFilter } from "./Interfaces";
import { RightPanelState } from "../../Models/CommonInterfaces";
import "./Users.css";
import UserService from "../../Services/UserService";
import { GetUserDetailsService } from "../../Services/GetUserDetailsService";
import EditIcon from '@material-ui/icons/Edit';
import AddUser from "../../Components/AddUser";
import { NewUserDetails } from "../../Models/NewUserDetails";
import { ShowAlertwithConfirm, ShowAlert } from "../../Common/ShowAlert";
import LoaderComponent from "../../Components/LoaderComponent";
import ArrayHelper from "../../Helpers/ArrayHelper";
import { PreserveFilters } from "../../Common/PreserveFilters";
import ObjectHelper from "../../Helpers/ObjectHelper";
import UserPermission from "../UserPermission/UserPermission";
import { CheckScreenPermission } from "../../Common/CheckScreenPermission";
import BaseService from "../../Services/BaseService";
import StringHelper from "../../Helpers/StringHelper";

class UsersList extends React.Component<IUserListProps, IUserListState> {
    private _userService: UserService;
    private _userListColumns: IDataGridColumn<IUser>[] = [
        {
            key: "userStatusID",
            name: "STATUS",
            contentProvider: (row_: IUser) => {
                return (
                    row_.userRole === 7 ?
                        <div className={'list-status ' + ((row_.onboardStatusId === 3 && row_.userStatusID === 1) ? 'active' : 'inactive')}></div>
                        : <div className={'list-status ' + ((row_.userStatusID === 1) ? 'active' : 'inactive')}></div>
                );
            }
        },
        {
            key: "name",
            name: "NAME",
            getComparator: ArrayHelper.getNumberInStringComparator
        },
        {
            key: "email",
            name: "EMAIL",
            getComparator: ArrayHelper.getNumberInStringComparator
        },
        /*{
            key: "countryCode",
            name: "Country Code"
        },*/
        {
            key: "mobileNumberWithCode",
            name: "Cell Phone"
        },
        {
            key: "userRoleName",
            name: "ROLE",
            getComparator: ArrayHelper.getNumberInStringComparator
        }
    ]
    constructor(props: IUserListProps) {
        super(props);

        let usersList: IUser[] = [];

        this.state = {
            showBlocker: false,
            selectedTabIndex: 0,
            userRole: 3,
            // tabList: (GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) != -1) ? ['Gryd Admins', 'GRYD Users', 'Managers', 'Tenants', 'Parkers'] : (GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) != -1) ? ['Managers', 'Tenants', 'Parkers'] : ['Tenants'],
            tabList: (GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) != -1) ? ['Gryd Admins', 'Managers', 'Building Contacts', 'Tenants', 'Parkers', 'Enforcement Officers', 'Permissions'] : (GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) != -1) ? ['Managers', 'Building Contacts', 'Tenants', 'Parkers', 'Enforcement Officers', 'Permissions'] : ['Tenants'],
            selectedTabName: (GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) != -1) ? 'Gryd Admin' : (GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) != -1) ? 'Manager' : 'Tenant',
            selectedRole: (GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) != -1) ? 1 : (GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) != -1) ? 2 : 6,
            isFiltered: false,
            rightPanelState: RightPanelState.None,
            _autoApplyFilterKey: 0,
            allUsers: usersList,
            filteredUsers: usersList.slice(0),
            subFilteredUsers: usersList.slice(0),
            isUsersLoaded: false,
            searchTerm: "",
            hasUnsavedChanges: false,
            isScreenAccessible: false,
            selectedItems: [],
            isUserLoaded: false,
            isDialog: false,
            resendActivationEmail: ''
        }

        this._userService = new UserService();
    }

    async componentDidMount() {
        var isAccessible = await CheckScreenPermission("user-interface-users");

        this.setState({
            isScreenAccessible: isAccessible
        }, () => {
            if (this.state.isScreenAccessible) {
                this._loadUsers();
            }
        });
    }

    componentWillUnmount() {
        PreserveFilters.setSearchTerm('userSearch', this.state.searchTerm)
    }

    private _loadUsers() {
        this.setState({
            showBlocker: true,
            loaderPercentage: 0
        }, () => {
            let isBusy = false;

            let searchTerm = this.state.searchTerm ? this.state.searchTerm : PreserveFilters.getSearchTerm('userSearch');

            this._getAllUsers((users_: IUser[]) => {
                return users_.map(e => {
                    e.mobileNumber = e.mobileNumber && e.mobileNumber.length > 2 ? e.mobileNumber : "";
                    e.countryCode = !e.countryCode ? 1 : e.countryCode;
                    e.mobileNumberWithCode = e.mobileNumber ? (e.countryCode ? '+' + e.countryCode : '') + e.mobileNumber : "";
                    e.email = e.email ? e.email.toLocaleLowerCase() : "";
                    e.userRole = this.state.selectedRole;
                    return e;
                });
            },
                (users_: IUser[], progress_: number) => {
                    if (!isBusy) {
                        isBusy = true;

                        this.setState({
                            allUsers: users_,
                            filteredUsers: users_.slice(0),
                            subFilteredUsers: searchTerm ? this._filterDataBySearch(users_, searchTerm) : users_.slice(0),
                            isUsersLoaded: true,
                            searchTerm: searchTerm ? searchTerm : '',
                            showBlocker: false,
                            isUserLoaded: true,
                            loaderPercentage: progress_ * 100
                        }, () => {
                            isBusy = false;
                            this._searchTermChange(this.state.searchTerm);
                        })
                    }
                }).then(users_ => {
                    this.setState({
                        allUsers: users_,
                        filteredUsers: users_.slice(0),
                        subFilteredUsers: searchTerm ? this._filterDataBySearch(users_, searchTerm) : users_.slice(0),
                        isUsersLoaded: true,
                        searchTerm: searchTerm ? searchTerm : '',
                        showBlocker: false,
                        isUserLoaded: true,
                        loaderPercentage: 100
                    }, () => {
                        isBusy = false;

                        this._searchTermChange(this.state.searchTerm);

                        setTimeout(() => {
                            this.setState({
                                loaderPercentage: 0
                            })
                        }, 500);


                    })
                });

            this._userService.getOnboardUsers(this.state.selectedRole)
                .then(r => r.json())
                .then((rowItems: IUser[]) => {
                    let updatedList = rowItems.map(e => {
                        e.mobileNumber = e.mobileNumber && e.mobileNumber.length > 2 ? e.mobileNumber : "";
                        e.countryCode = !e.countryCode ? 1 : e.countryCode;
                        e.mobileNumberWithCode = e.mobileNumber ? (e.countryCode ? '+' + e.countryCode : '') + e.mobileNumber : "";
                        e.email = e.email ? e.email.toLocaleLowerCase() : "";
                        e.userRole = this.state.selectedRole;
                        return e;
                    });
                    let searchTerm = this.state.searchTerm ? this.state.searchTerm : PreserveFilters.getSearchTerm('userSearch');

                    this.setState({
                        allUsers: updatedList,
                        filteredUsers: updatedList.slice(0),
                        subFilteredUsers: searchTerm ? this._filterDataBySearch(updatedList, searchTerm) : updatedList.slice(0),
                        isUsersLoaded: true,
                        searchTerm: searchTerm ? searchTerm : '',
                        showBlocker: false
                    }, () => {
                        this._searchTermChange(this.state.searchTerm);
                    })
                })
        });
    }

    private _getAllUsers = async (contentParser_: (users_: IUser[]) => IUser[], onProgress_: (users_: IUser[], progress_: number) => void) => {
        let remainder = "";

        return BaseService.asyncResponse<IUser>(
            this._userService.getOnboardUsers(this.state.selectedRole),
            (content_, contentLength, receivedLength) => {
                let users: IUser[] = [];

                if (content_) {
                    content_ = remainder + content_;

                    if (content_ && contentLength != receivedLength) {
                        let lastIndex = content_.lastIndexOf(",{\"userId\"");
                        if (lastIndex == -1) {
                            remainder = content_;
                            // content_ = "";
                        }
                        else {
                            remainder = "[" + StringHelper.trimStart(content_.substring(lastIndex), ',');
                            content_ = content_.substring(0, lastIndex) + "]";
                        }
                    }

                    if (content_) {
                        try {
                            users = JSON.parse(content_);
                        } catch (e) { }
                    }
                }

                return contentParser_(users);
            }, onProgress_);
    }


    public render() {
        if (!this.state.isUsersLoaded || !this.state.isScreenAccessible) {
            // return <LoaderComponent loaderVisible={true} />
            return null;
        }

        let multiUsersPopoverOptions = [
            {
                label: "Invite Selected Users",
                key: "INVITE",
            }
        ];

        let onboardedUsersPopoverOptions = [
            {
                label: "Edit User",
                key: "EDIT",
            },
            {
                label: "Remove User",
                key: "REMOVE",
            },
            {
                label: "Delete User",
                key: "DELETE",
            }
        ];

        let parkerUsersPopoverOptions = [
            {
                label: "Resend Activation",
                key: "RESENDACTIVATION",
                disabled: false,
            },
            {
                label: "Delete User",
                key: "DELETE",
                textStyle: "remove-access-item"
            }
        ];

        let nonOnboardedUsersPopoverOptions = [
            {
                label: "Edit User",
                key: "EDIT",
            },
            {
                label: "Invite User",
                key: "INVITE",
            },
            {
                label: "Remove User",
                key: "REMOVE",
            },
            {
                label: "Delete User",
                key: "DELETE",
            }
        ];

        const tabOnChange = (e_: any, tabIndex_: number) => {
            let role_: number = 1;

            let tabName_: string = e_.target.innerText.toLowerCase();
            let selectedTabName_: string = "";
            switch (tabName_) {
                case 'gryd admins':
                    role_ = 1;
                    selectedTabName_ = 'Gryd Admin';
                    break;
                case 'gryd users':
                    role_ = 2;
                    selectedTabName_ = 'GRYD User';
                    break;
                case 'managers':
                    role_ = 5;
                    selectedTabName_ = 'Manager';
                    break;
                case 'building contacts':
                    role_ = 8;
                    selectedTabName_ = 'Building Contact';
                    break;
                case 'tenants':
                    role_ = 6;
                    selectedTabName_ = 'Tenant';
                    break;
                case 'parkers':
                    role_ = 7;
                    selectedTabName_ = 'Parkers';
                    break;
                case 'enforcement officers':
                    role_ = 9;
                    selectedTabName_ = 'Parking Enforcement Officer';
                    break;
                default:
                    selectedTabName_ = 'Permissions';
                    break;
            }

            if (selectedTabName_ !== "Permissions") {
                if (role_ == 5 || role_ == 8) {
                    let coloumnIndex = this._userListColumns.map(function (e) { return e.key; }).indexOf('managementCompanyName');
                    if (coloumnIndex == -1) {
                        this._userListColumns.push({
                            key: "managementCompanyName",
                            name: "Management Company"
                        });
                    }
                } else {
                    let coloumnIndex = this._userListColumns.map(function (e) { return e.key; }).indexOf('managementCompanyName');
                    if (coloumnIndex != -1) {
                        this._userListColumns = this._userListColumns.slice(0, coloumnIndex);
                    }
                }

                this.setState({
                    selectedTabIndex: tabIndex_,
                    selectedTabName: selectedTabName_,
                    selectedRole: role_,
                    selectedItems: []
                }, () => {
                    this._loadUsers();
                });
            } else {
                this.setState({
                    selectedTabIndex: tabIndex_,
                    selectedTabName: selectedTabName_,
                    selectedRole: role_
                });
            }
        }

        return (
            <div className={'mx-3 my-0 data-grid-container without-checkbox-container dashboard-tabs-container ' + (this.state.selectedRole != 7 ? 'clickable-rows' : '')}>
                {/* <LoaderComponent loaderVisible={this.state.showBlocker} /> */}
                {/* Header region of the page */}
                <Grid container className="mb-3">
                    <Grid item>
                        {/* Title of the page */}
                        <h1 className="list-header">{this.state.selectedTabName !== "Permissions" ? "Users" : "Manage Permissions"}</h1>
                    </Grid>
                    <Grid item xs>
                        {/* Grid toolbar */}
                        <DataGridToolbar
                            search={PreserveFilters.getSearchTerm('userSearch')}
                            singularEntityName="user"
                            pluralEntityName="users"
                            onSearch={this._searchTermChange}
                            isFiltered={this.state.isFiltered}
                            onFilterClick={() => {
                                this.setState({
                                    rightPanelState: RightPanelState.Filter
                                });
                            }}
                            onAddClick={() => {
                                this.setState({
                                    rightPanelState: RightPanelState.Add
                                });
                            }}
                            isNoAddButton={this.state.selectedRole == 6 || this.state.selectedRole == 7 || this.state.selectedTabName === "Permissions"}
                            isNoFilterButton={true}
                            isNoSearch={this.state.selectedTabName === "Permissions"}
                        />
                    </Grid>
                </Grid>

                <Tabs
                    value={this.state.selectedTabIndex}
                    onChange={(e_: any, tabIndex_: number) => {
                        tabOnChange(e_, tabIndex_);
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="standard"
                    aria-label="full width tabs example"
                >
                    {
                        this.state.tabList.map((m, index) => {
                            return (
                                <Tab key={m + index} label={m} />
                            )
                        })
                    }
                </Tabs>

                {/* Data Grid */}
                {this.state.selectedTabName !== "Permissions" && <><DataGrid
                    title="Spot"
                    data={this.state.subFilteredUsers}
                    columns={this._userListColumns}
                    appliedFilter={this.state.appliedFilter}
                    defaultSortColumnKey="Name"
                    onFilterTagRemoved={(filter_: IAppliedFilter) => {
                        this.setState({
                            appliedFilter: filter_ as IUserFilter,
                            _autoApplyFilterKey: new Date().getTime()
                        });
                    }}
                    loaderPercentage={this.state.loaderPercentage}
                    multiPopoverItems={multiUsersPopoverOptions}
                    onMoreClick={(item_: IUser) => {
                        if (item_.onboardStatusId == 1) {
                            return nonOnboardedUsersPopoverOptions;
                        }
                        else if (item_.userRole == 7) {
                            let parkerUsersPopoverOptionsCopy = Array.from(parkerUsersPopoverOptions);
                            if (item_?.onboardStatusId === 2) {
                                parkerUsersPopoverOptionsCopy[0].disabled = false
                            }
                            else if (item_?.onboardStatusId === 3) {
                                parkerUsersPopoverOptionsCopy.shift()
                            }
                            return parkerUsersPopoverOptionsCopy;
                        }
                        else {
                            return onboardedUsersPopoverOptions;
                        }
                    }}
                    onMultiPopoverClick={(key_: string, users_: IUser[]) => {
                        if (key_ == "INVITE") {
                            let promises: Promise<Response>[] = [];

                            for (let user of users_) {
                                if (user.onboardStatusId == 1) {
                                    promises.push(this._userService.inviteUser(user.userId));
                                }
                            }

                            Promise.all(promises).then(() => {
                                ShowAlert('Sent!', 'Sent invitation email to selected users.', 'success');
                            });
                        }
                    }}
                    onPopoverClick={(key_: string, user_: IUser) => {
                        if (key_ == "EDIT") {
                            this.setState({
                                selectedUser: user_,
                                rightPanelState: RightPanelState.Edit
                            });
                        }
                        else if (key_ == "INVITE") {
                            if (user_.onboardStatusId == 1) {
                                this._userService.inviteUser(user_.userId)
                                    .then(r => {
                                        if (r.ok) {
                                            ShowAlert('Sent!', 'Sent invitation email to user.', 'success');
                                        }
                                        else {
                                            throw new Error("Unable to send email");
                                        }
                                    })
                                    .catch(() => {
                                        ShowAlert('Unable to send!', 'There was an issue with sending invitation email to user.', 'error');
                                    });
                            }
                            else {
                                ShowAlert('Unable to send!', 'There was an issue with sending invitation email to user.', 'error');
                            }
                        } else if (key_ == "REMOVE") {
                            let _this = this;
                            ShowAlertwithConfirm("Remove User?", `<div class="gryd-custom-popup-body">
                            Are you sure you want to remove this user?<br/><br/>
                            ${this.validateUserInfo(user_.name)}
                            ${this.validateUserInfo(user_.userRoleName)}
                            ${this.validateUserInfo(user_.managementCompanyName)}
                            </div>`, undefined, 'Remove User', 'Cancel', 'gryd-custom-popup')
                                .then(r_ => {
                                    if (r_) {
                                        _this._userService.RemoveUser(user_).then(response => {
                                            if (response.ok) {
                                                response.text().then(resp => {
                                                    _this.updateRecord(user_, 'removed');
                                                })
                                            } else {
                                                response.text().then(resp => {
                                                    ShowAlert('Error!', resp, 'error');
                                                })
                                            }
                                        })
                                    }
                                })
                        } else if (key_ == "DELETE") {
                            let _this = this;
                            ShowAlertwithConfirm("Delete Account?", `<div class="gryd-custom-popup-body">
                            Are you sure you want to delete this account?<br/>This action cannot be undone.<br/><br/>
                            ${this.validateUserInfo(user_.name)}
                            ${this.validateUserInfo(user_.userRoleName)}
                            ${this.validateUserInfo(user_.managementCompanyName)}
                            </div>`, undefined, 'Delete Account', 'Cancel', 'gryd-custom-popup')
                                .then(r_ => {
                                    if (r_) {
                                        _this._userService.DeleteUser(user_).then(response => {
                                            if (response.ok) {
                                                response.text().then(resp => {
                                                    _this.updateRecord(user_, 'deleted');
                                                })
                                            } else {
                                                response.text().then(resp => {
                                                    ShowAlert('Error!', resp, 'error');
                                                })
                                            }
                                        })
                                    }
                                })
                        }
                        else if (key_ == "RESENDACTIVATION") {
                            this.getParkerInvite(user_.userId, user_.email)
                        }
                    }}
                    isRowsNonSelectable={this.state.selectedRole == 7 || this.state.selectedRole == 6}
                    // isNoMoreLink={this.state.selectedRole == 7}
                    // isNoMoreLinkDisabled={(item_: IUser) => {
                    //     return (this.state.selectedRole == 7 && item_.userStatusID != 2);
                    // }}
                    onRowClick={item_ => {
                        if (this.state.selectedRole != 7) {
                            this.setState({
                                selectedUser: item_,
                                rightPanelState: RightPanelState.Edit
                            });
                        }
                    }}
                    selectedItems={this.state.selectedItems}
                    onSelectionChange={(selectedItems: IUser[]) => {
                        this.setState({ selectedItems });
                    }}
                />

                    {/* Right side drawer */}
                    <Drawer
                        anchor="right"
                        open={this.state.rightPanelState != RightPanelState.None}
                        onClose={this._onCancelAddEditPanel}
                    >
                        <div className="data-grid-right-panel">
                            <Grid container className="right-panel-header p-2 border-bottom">
                                <Grid item xs>
                                    <h4 className="right-panel-title m-0">
                                        {this._getRightPanelTitle()}
                                    </h4>
                                </Grid>
                                <Grid item>
                                    <Button
                                        // onClick={() => this.setState({ rightPanelState: RightPanelState.None })}
                                        onClick={this._onCancelAddEditPanel}
                                    >
                                        <CloseIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        {this._getRightPanelContent()}
                    </Drawer>
                </>
                }

                {this.state.selectedTabName === "Permissions" && <UserPermission></UserPermission>}
                {
                    <Dialog PaperProps={{
                        style: { borderRadius: 32 }
                    }} open={this.state.isDialog}>
                        <div className="resend-activation-window">
                            <div className="resend-activation-window-title">Email Sent</div>
                            <div className="resend-activation-window-title3">An activation email has been sent to</div>
                            <div className="resend-activation-window-title2">
                                {this.state.resendActivationEmail}
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <Button type="submit"
                                    onClick={() => {
                                        this.setState({ isDialog: false, resendActivationEmail: '' })
                                    }}
                                    variant="contained"
                                    style={{ width: '100%', borderRadius: 10, paddingTop: 10, paddingBottom: 10, paddingLeft: 0, paddingRight: 0, fontSize: 15, fontWeight: 500, color: '#323232' }}
                                    color="default" >
                                    Close
                                </Button>
                            </div>
                        </div>
                    </Dialog>
                }
            </div>

        );
    }
    getParkerInvite = (userGUID: string, email: string) => {
        this._userService.getParkerInvite(userGUID)
            .then((response) => {
                if (response.ok) {
                    this.setState({ isDialog: true, resendActivationEmail: email })
                }
                else {
                    throw new Error("Unable to send email");
                }
            })
            .catch(() => {
                ShowAlert('Unable to send!', 'There was an issue with sending invitation email to user.', 'error');
            });
    }
    private updateRecord(user_: IUser, updatedType: string) {
        let allUsers_: IUser[] = ObjectHelper.deepClone(this.state.allUsers);
        let filteredUsers_: IUser[] = ObjectHelper.deepClone(this.state.filteredUsers);
        let subFilteredUsers_: IUser[] = ObjectHelper.deepClone(this.state.subFilteredUsers);

        allUsers_.filter(x => x.email == user_.email).forEach(x => allUsers_.splice(allUsers_.indexOf(x), 1));
        filteredUsers_.filter(x => x.email == user_.email).forEach(x => filteredUsers_.splice(filteredUsers_.indexOf(x), 1));
        subFilteredUsers_.filter(x => x.email == user_.email).forEach(x => subFilteredUsers_.splice(subFilteredUsers_.indexOf(x), 1));

        this.setState({
            allUsers: allUsers_,
            filteredUsers: filteredUsers_,
            subFilteredUsers: subFilteredUsers_
        }, () => {
            ShowAlert('Success!', `User ${updatedType} successfully.`, 'success');
        });
    }

    private validateUserInfo(data: any) {
        return data ? `<b>${data}</b><br />` : '';
    }

    private accessTokenExpired() {
        localStorage.clear();
        window.location.href = '/';
    }

    private _searchTermChange = (searchTerm_: string): void => {
        this.setState({
            searchTerm: searchTerm_,
            subFilteredUsers: this._filterDataBySearch(this.state.filteredUsers, searchTerm_)
        });
    }

    private _filterDataBySearch = (data_: IUser[], searchTerm_: string): IUser[] => {
        return data_.filter(d => {
            return d.name.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
                d.email.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
                d.mobileNumberWithCode.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
                (d.managementCompanyName != null && d.managementCompanyName != "" && d.managementCompanyName.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1);
        });
    }

    private _getRightPanelContent() {
        let rightPanelViews = [];

        switch (this.state.rightPanelState) {
            case RightPanelState.Add:
                rightPanelViews.push(
                    <AddUser
                        popupState={(s_: boolean) => {
                            /*this.setState({
                                rightPanelState: RightPanelState.None
                            })*/
                            this._onCancelAddEditPanel();
                        }}
                        onAddedUser={(u_: NewUserDetails) => {
                            let _newUser: IUser = {
                                userId: u_.userGuid,
                                name: u_.username,
                                mobileNumber: u_.phone,
                                mobileNumberWithCode: u_.phone ? (u_.countryCode ? '+' + u_.countryCode : '') + u_.phone : "",
                                countryCode: u_.countryCode,
                                email: u_.emailAddress.toLocaleLowerCase(),
                                userRoleName: u_.role ? this.getRole(u_.role) : '',
                                userStatusID: 1,
                                action: '',
                                userRole: u_.role,
                                managementCompanyGuid: u_.companyGuid,
                                managementCompanyName: u_.companyName,
                                onboardStatusId: 1
                            }

                            if (u_.role == this.state.selectedRole) {
                                this.state.allUsers.push(_newUser);
                                this.state.filteredUsers.push(_newUser);
                                let filtered_ = this.state.subFilteredUsers.slice(0);
                                filtered_.push(_newUser);

                                this.setState({
                                    rightPanelState: RightPanelState.None,
                                    allUsers: this.state.allUsers,
                                    filteredUsers: this.state.filteredUsers,
                                    subFilteredUsers: filtered_,
                                    _autoApplyFilterKey: new Date().getTime()
                                }, () => {
                                    this._searchTermChange(this.state.searchTerm);
                                });
                            } else {
                                this.setState({
                                    rightPanelState: RightPanelState.None
                                })
                            }
                        }}
                        userRole={this.state.selectedTabName}
                        permissionType={(GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) != -1) ? 'Gryd Admin' : (GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) != -1) ? 'GRYD User' : 'Tenants'}
                        onChange={(s_: boolean) => {
                            this.setState({
                                hasUnsavedChanges: s_
                            });
                        }}
                    />
                );
                break;
            case RightPanelState.Edit:
                rightPanelViews.push(
                    <AddUser
                        popupState={(s_: boolean) => {
                            /*this.setState({
                                rightPanelState: RightPanelState.None
                            })*/
                            this._onCancelAddEditPanel();
                        }}
                        onAddedUser={(u_: NewUserDetails) => {

                            let user = this.state.selectedUser;

                            if (user) {
                                user.name = u_.username;
                                user.userStatusID = u_.userStatusId;

                                if (user.userRole == u_.role) {
                                    this.setState({
                                        rightPanelState: RightPanelState.None,
                                        selectedUser: user
                                    });
                                } else {
                                    this.setState({
                                        rightPanelState: RightPanelState.None,
                                        selectedUser: user
                                    }, () => {
                                        this.updateRecord(this.state.allUsers.filter(x => x.email == u_.emailAddress)[0], 'deleted');
                                    });
                                }


                            } else {
                                this.setState({
                                    rightPanelState: RightPanelState.None
                                })
                            }
                        }}
                        userRole={this.state.selectedTabName}
                        permissionType={(GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) != -1) ? 'Gryd Admin' : (GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) != -1) ? 'GRYD User' : 'Tenants'}
                        requestType='edit'
                        userDetails={this.state.selectedUser}
                        onChange={(s_: boolean) => {
                            this.setState({
                                hasUnsavedChanges: s_
                            });
                        }}
                    />
                );
                break;
        }

        return rightPanelViews;
    }

    private _onCancelAddEditPanel = () => {
        if (this.state.hasUnsavedChanges) {
            ShowAlertwithConfirm("You have unsaved changes!", "Are you sure you want to close this window?", "warning")
                .then(r_ => {
                    if (r_) {
                        this.setState({
                            rightPanelState: RightPanelState.None,
                            hasUnsavedChanges: false
                        });
                    }
                })
        }
        else {
            this.setState({
                rightPanelState: RightPanelState.None
            });
        }
    }

    private _getRightPanelTitle(): JSX.Element | null {
        switch (this.state.rightPanelState) {
            case RightPanelState.Filter:
                return <span>Filter</span>;
            case RightPanelState.Edit:
                return <span>Edit User</span>;
            case RightPanelState.Add:
                return <span>Add User</span>;
        }

        return null;
    }

    private getRole(roleNumber: number) {
        var role = "";

        switch (roleNumber) {
            case 1:
                role = "Admin";
                break;
            case 2:
                role = "GRYD User";
                break;
            case 3:
                role = "Property Owner";
                break;
            case 4:
                role = "Company Admin";
                break;
            case 5:
                role = "Property Manager";
                break;
            case 6:
                role = "Tenant";
                break;
            case 7:
                role = "Parker";
                break;
            case 8:
                role = "Building Contacts";
                break;
            case 9:
                role = "Parking Enforcement Officer";
                break;
            case 10:
                role = "Permissions";
                break;
        }

        return role;
    }

    private updateUser(item_: IUser) {
        var postData = {
            "Name": item_.name,
            "ApplicationUserGuid": item_.userId,
            "UserStatusId": 1
        }

        this.setState({
            showBlocker: true,
        }, () => {
            this._userService.updateUserDetails(postData).then(response => {
                if (response.ok) {
                    response.text().then(resp => {
                        let user = this.state.selectedUser;

                        if (user) {
                            user.userStatusID = 1;
                        }

                        ShowAlert('Success!', 'User activated successfully.', 'success').then(() => {
                            this.setState({
                                showBlocker: false,
                                selectedUser: user
                            });
                        });
                    })
                }
            })
        });
    }
}

interface IUserListProps { }

interface IUserListState {
    selectedTabIndex: number;
    userRole: number;
    tabList: string[];
    selectedTabName: string;
    selectedRole: number;
    isUsersLoaded: boolean;
    allUsers: IUser[];
    filteredUsers: IUser[];
    subFilteredUsers: IUser[];
    rightPanelState: RightPanelState;
    searchTerm: string;
    appliedFilter?: IUserFilter;
    isFiltered: boolean;
    selectedUser?: IUser;
    _autoApplyFilterKey: number;
    hasUnsavedChanges: boolean;
    showBlocker: boolean;
    isScreenAccessible: boolean;
    selectedItems: IUser[];
    loaderPercentage?: number;
    isUserLoaded: boolean;
    isDialog: boolean;
    resendActivationEmail: string;
}

export default UsersList;