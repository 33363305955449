import React from "react";
import { IAppliedFilter, DataGridFilterType, INumberRange, IDateRange, IColumnFilter, FilterValueType } from "./Interfaces";
import { Chip } from "@material-ui/core";
import { format } from "date-fns";

const DataGridFilterTags = (props_: IDataGridFilterTagProps) => {
    let filterChips: JSX.Element[] = [];

    const revenueFormatter = (value_: number) => {
        return "$" + value_.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const numberFormatter = (value_: number) => {
        return value_.toString();
    }

    const dateFormatter = (value_: Date) => {
        return format(value_, "MM/dd/yyyy");
    }

    const makeNumberRangeText = (range_: INumberRange, numberFormatter_?: (number_: number) => string) => {
        if (!numberFormatter_) {
            numberFormatter_ = numberFormatter;
        }

        // Both start and end are provided
        if (range_.start != undefined && range_.end != undefined) {
            return numberFormatter_(range_.start) + ' - ' + numberFormatter_(range_.end);
        }
        // Only end is provided
        else if (range_.end != undefined) {
            return "Less than " + numberFormatter_(range_.end)
        }
        // Only start is provided
        else if (range_.start != undefined) {
            return "More than " + numberFormatter_(range_.start)
        }

        return "";
    }

    const makeDateRangeText = (range_: IDateRange) => {
        // Both start and end are provided
        if (range_.start != undefined && range_.end != undefined) {
            return dateFormatter(range_.start) + ' - ' + dateFormatter(range_.end);
        }
        // Only end is provided
        else if (range_.end != undefined) {
            return "Before " + dateFormatter(range_.end)
        }
        // Only start is provided
        else if (range_.start != undefined) {
            return "After " + dateFormatter(range_.start)
        }

        return "";
    }

    const handleDeleteChip = (columnKey_: string, columnFilter_: IColumnFilter, filterValue_: FilterValueType) => {
        let updatedFilterOption = props_.appliedFilter;

        // list types act differently
        if (columnFilter_.type == DataGridFilterType.StringList) {
            let valueList = (columnFilter_.filterValue as string[]);
            let valueIndex = valueList.indexOf(filterValue_ as string);
            valueList.splice(valueIndex, 1);
        }
        else if (columnFilter_.type == DataGridFilterType.String) {
            props_.appliedFilter[columnKey_].filterValue = "";
        }
        else if (columnFilter_.type == DataGridFilterType.DateRange || columnFilter_.type == DataGridFilterType.NumberRange || columnFilter_.type == DataGridFilterType.RevenueRange) {
            props_.appliedFilter[columnKey_].filterValue = {
                start: undefined,
                end: undefined,
                optionalTag: ""
            };
        }

        if (props_.onRemoveFilter) {
            props_.onRemoveFilter(updatedFilterOption);
        }
    }

    const addChip = (columnKey_: string, columnFilter_: IColumnFilter, label_: string, filterValue_: FilterValueType) => {
        if (label_) {
            filterChips.push(
                <Chip
                    key={filterChips.length}
                    label={label_}
                    className="chip-lable"
                    onDelete={() => handleDeleteChip(columnKey_, columnFilter_, filterValue_)}
                    variant="outlined"
                />
            );
        }
    }

    for (let columnKey in props_.appliedFilter) {
        let columnFilter = props_.appliedFilter[columnKey];

        if (columnFilter.type == DataGridFilterType.String) {
            let filterValue = columnFilter.filterValue as string;
            addChip(columnKey, columnFilter, filterValue, filterValue);
        }
        else if (columnFilter.type == DataGridFilterType.StringList) {
            let filterValues = columnFilter.filterValue as string[];
            for (let filterValue of filterValues) {
                addChip(columnKey, columnFilter, filterValue, filterValue);
            }
        }
        else if (columnFilter.type == DataGridFilterType.NumberRange) {
            let filterValue = columnFilter.filterValue as INumberRange;
            addChip(columnKey, columnFilter, makeNumberRangeText(filterValue), filterValue);
        }
        else if (columnFilter.type == DataGridFilterType.RevenueRange) {
            let filterValue = columnFilter.filterValue as INumberRange;
            addChip(columnKey, columnFilter, makeNumberRangeText(filterValue, revenueFormatter), filterValue);
        }
        else if (columnFilter.type == DataGridFilterType.DateRange) {
            let filterValue = columnFilter.filterValue as IDateRange;
            addChip(columnKey, columnFilter, makeDateRangeText(filterValue), filterValue);
        }
    }

    return (
        <div className="chip-container">
            {filterChips}
        </div>
    );
}

export default DataGridFilterTags;

export interface IDataGridFilterTagProps {
    appliedFilter: IAppliedFilter,
    onRemoveFilter: (appliedFilter: IAppliedFilter) => void
}