import React from 'react';
import { Grid, Tooltip, IconButton, Button, Tab, Tabs, TextField, Switch, Dialog, AppBar, Toolbar, Typography, Slide, OutlinedInput, InputAdornment, InputLabel, FormControl } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import { NewUserDetails, IUser } from '../../Models/NewUserDetails';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import "./ProfileStyle.css"
import * as CommonString from '../../CommonString';
import { ConfigService } from '../../Services/ConfigService';
import LoaderComponent from '../../Components/LoaderComponent';
import { ShowAlert } from '../../Common/ShowAlert';
import { GetUserDetailsService } from "../../Services/GetUserDetailsService";
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { truncateSync } from 'fs';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InfoIcon from '@material-ui/icons/Info';
import PasswordStrengthBar from 'react-password-strength-bar';

import { PlaidLink, usePlaidLink } from 'react-plaid-link';
import BaseService from '../../Services/BaseService';
import XeroService from '../../Services/XeroService';
import QBOService from '../../Services/QBOService';
import { NavToHomeScreen } from '../../Common/CheckScreenPermission';

// const PLAID_PUBLIC_KEY = '10aa11248ead62acb670f8610befdb';
// const APP_NAME = 'GRYD PARK';
// const ENVIRONMENT = 'sandbox';
// const PRODUCT = ['auth', 'transactions'];

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface ProfileProps { }
interface ProfileState {
    selectedTabIndex: number;
    showBlocker: boolean;
    userData: NewUserDetails;
    notificationSelected: boolean;
    dialogOpenState: boolean,
    emailCode: string;
    isEmailOtpValid: boolean;
    errorEmailOtpMessage: string;
    isEmailOtpSent: boolean;
    newEmailAddress: string;
    newEmailError: boolean;
    newErrorMessage: string;
    isMobileOtpSent: boolean;
    newMobile: string;
    isChangeEmail: boolean;
    existingPassword: string;
    password: string;
    confirmPassword: string;
    newPhoneError: boolean;
    newPhoneErrorMessage: string;
    isVerified: boolean;
    token: string;
    email: string;
    isAdminUser: boolean;
    PLAID_PUBLIC_KEY: string;
    ENVIRONMENT: string;
    currentPasswordType: string;
    newPasswordType: string;
    confirmPasswordType: string;
    currentPasswordError: boolean;
    passwordScore: number
}

class Profile extends React.Component<ProfileProps, ProfileState> {
    private _xeroService: XeroService;
    private _qboService: QBOService;
    private APP_NAME: string = 'GRYD PARK';
    private PRODUCT: string[] = ['auth', 'transactions'];

    constructor(props: ProfileProps) {
        super(props);

        var isAdmin = false;
        if (typeof (Storage) !== 'undefined') {
            var _localData = localStorage.getItem('userdata');

            if (_localData != null && _localData !== '') {
                var _data = JSON.parse(_localData);
                var roles: any = _data.userRoleID;

                if (roles.includes(1) || roles.includes(2)) {
                    isAdmin = true;
                }
                else
                    isAdmin = false;

            }
        }

        let validToken = localStorage.getItem('token');
        this.state = {
            selectedTabIndex: 0,
            showBlocker: false,
            userData: {
                userStatusId: 1,
                userGuid: '',
                address1: '',
                address2: '',
                city: '',
                companyGuid: '',
                companyName: '',
                country: '',
                countryCode: 0,
                emailAddress: '',
                phone: '',
                propertyGuid: '',
                propertyName: '',
                role: 0,
                state: '',
                username: '',
                zipCode: ''
            },
            notificationSelected: true,
            emailCode: '',
            isEmailOtpValid: false,
            errorEmailOtpMessage: "",
            dialogOpenState: false,
            isEmailOtpSent: false,
            newEmailAddress: "",
            newEmailError: false,
            newErrorMessage: "",
            isMobileOtpSent: false,
            newMobile: '',
            isChangeEmail: false,
            password: '',
            confirmPassword: '',
            existingPassword: '',
            newPhoneError: false,
            newPhoneErrorMessage: "",
            isVerified: ((validToken == 'success') ? true : false),
            token: String(validToken),
            email: '',
            isAdminUser: isAdmin,
            ENVIRONMENT: '',
            PLAID_PUBLIC_KEY: '',
            currentPasswordType: 'password',
            newPasswordType: 'password',
            confirmPasswordType: 'password',
            currentPasswordError: false,
            passwordScore: 0
        }

        this._xeroService = new XeroService();
        this._qboService = new QBOService();
    }

    public componentDidMount = () => {

        if (typeof (Storage) !== 'undefined') {
            var _localData = localStorage.getItem('userdata');

            if (_localData != null && _localData !== '') {
                var _data = JSON.parse(_localData);
                this.setState({
                    email: _data.emailId
                });

            }
        }

        ConfigService.getPlaid()
            .then(d => {
                this.setState({
                    PLAID_PUBLIC_KEY: d.publicKey,
                    ENVIRONMENT: d.environment
                });
            });

        this.setState({
            showBlocker: true
        });


        let URLParams = new URLSearchParams(window.location.search);
        let code = URLParams.get("code");
        let realmId = URLParams.get("realmId");
        // console.log(code);
        // console.log(realmId);

        if (code && code != null && realmId && realmId != null) {
            // console.log(code);
            this._qboService.updateQBOToken(code, realmId)
                .then(r => r.json())
                .then(_response => {
                    if (_response == 'Success') {
                        // window.location.href = '/Dashboard';
                        NavToHomeScreen();
                    }
                });
        } else if (code && code != null) {
            // console.log(code);
            this._xeroService.updateXeroToken(code)
                .then(r => r.json())
                .then(_response => {
                    if (_response == 'Success') {
                        // window.location.href = '/Dashboard';
                        NavToHomeScreen();
                    }
                });
        }



        this.getProfileInformation().then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    this.state.userData.username = resp.name;
                    this.state.userData.emailAddress = resp.email;
                    this.state.userData.countryCode = resp.countryCode;
                    this.state.userData.phone = resp.mobileNumber;

                    this.setState({
                        showBlocker: false,
                        userData: this.state.userData
                    });
                })
            } else {
                this.setState({
                    showBlocker: false
                });
                /*if (response.status === 401) {
                    this.accessTokenExpired();
                } else {
                    response.text().then(resp => {
                        console.log(response);
                    });
                    ShowAlert("", 'There was an error processing a your request.', "error");
                }*/
            }
        });
    }

    public onSuccess = (token: any, metadata: any) => {
        // send token to server
        this.setState({
            showBlocker: true
        });
        console.log(JSON.stringify(metadata));

        console.log('Public Token: ' + token);
        console.log('Customer-selected account ID: ' + metadata.account_id);

        this.sendDataToServer(token, metadata.accounts[0]['id']);
    };

    public sendDataToServer = (token: string, accountId: string) => {

        const data = { public_token: token, account_id: accountId, email: this.state.email };

        ConfigService.getNodeBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + 'authenticate', {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                    // .then(response => response.json())
                    .then(data => {
                        if (data.ok) {
                            data.json().then(r => {
                                console.log(r);
                                if (r == "success") {
                                    this.setState({
                                        isVerified: true,
                                        token: r,
                                        showBlocker: false
                                    });
                                    localStorage.setItem('token', r);
                                }
                                else {
                                    this.showError();
                                }
                            })
                        }
                        else {
                            this.showError();
                        }
                        console.log('Success:', data);
                        this.setState({
                            showBlocker: false
                        });
                    })
                    .catch((error) => {
                        console.error('Error:', JSON.stringify(error));
                        this.showError();
                    });
            });

    }

    public showError() {
        this.setState({
            isVerified: false,
            showBlocker: false
        });
        ShowAlert('Failed!', 'Failed to verify your bank account', 'error');
    }

    public disburseError() {
        this.setState({
            isVerified: false,
            showBlocker: false
        });
        ShowAlert('Failed!', 'Failed to send payout', 'error');
    }

    public disburse() {

        this.setState({
            showBlocker: true
        });

        ConfigService.getNodeBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + 'disbursement/get', {
                    method: 'GET', // or 'PUT'
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
                    // .then(response => response.json())
                    .then(data => {
                        if (data.ok) {
                            data.json().then(r => {
                                console.log(r);
                                if (r == "Disbursement Initiated") {
                                    this.setState({
                                        showBlocker: false
                                    });
                                    ShowAlert('Success!', 'Payouts has been initiated successfully', 'success');
                                }
                                else {
                                    this.disburseError();
                                }
                            })
                        }
                        else {
                            this.disburseError();
                        }
                        console.log('Success:', data);
                        this.setState({
                            showBlocker: false
                        });
                    })
                    .catch((error) => {
                        console.error('Error:', JSON.stringify(error));
                        this.disburseError();
                    });
            });

    }

    public render() {
        return (
            <div className="m-3 data-grid-container dashboard-container profile-container">
                {/* <LoaderComponent loaderVisible={this.state.showBlocker} /> */}
                <Grid container className="mb-3">
                    <Grid item>
                        <Tooltip title="Back" onClick={() => {
                            window.location.href = "/Dashboard";
                        }}>
                            <IconButton aria-label="Back">
                                <ArrowBackIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs>
                        {/* <h1 className="list-header">Profile</h1> */}
                    </Grid>
                </Grid>
                <div className="grid-list-addedit-sub proflie-container">
                    <h4 className="list-header">Settings</h4>
                    <div className="property-location proflie-name-section">{this.state.userData.username} &middot; {this.state.userData.emailAddress}</div>
                    <Tabs
                        value={this.state.selectedTabIndex}
                        onChange={(e_: any, tabIndex_: number) => {
                            console.log(tabIndex_ + "");
                            this.setState({
                                selectedTabIndex: tabIndex_
                            });
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="standard"
                        aria-label="full width tabs example"
                    >
                        <Tab label="PERSONAL INFO" />
                        <Tab label="LOGIN & SECURITY" />
                        {/* <Tab label="PAYMENT & PAYOUTS" /> */}
                        {/* <Tab label="GLOBAL PREFERENCE" /> */}
                    </Tabs>
                    {
                        this.state.selectedTabIndex == 0 &&
                        <div className="info-container">
                            <TextField
                                variant="outlined"
                                margin="normal"
                                id="name"
                                label="Name"
                                name="name"
                                autoComplete="off"
                                onChange={(e_: any) => {
                                    this.state.userData.username = e_.target.value;
                                    this.setState({
                                        userData: this.state.userData
                                    });
                                }}
                                value={this.state.userData.username}
                            />

                            <TextField
                                variant="outlined"
                                margin="normal"
                                id="email"
                                label="Email"
                                name="email"
                                disabled={true}
                                autoComplete="off"
                                value={this.state.userData.emailAddress}
                            />
                            <div className="change-text">
                                <span className="add-button"
                                    onClick={() => this.handleChangeEmail()}
                                >Change Email</span>
                            </div>
                            <PhoneInput
                                country={'ca'}
                                disabled={true}
                                onlyCountries={['ca', 'us', 'in']}
                                placeholder={'+# ### - ### - ####'}
                                masks={{ ca: '...-...-....', us: '...-...-....', in: '...-...-....' }}
                                value={this.state.userData.countryCode + this.state.userData.phone}
                                autocompleteSearch={false}
                                containerClass="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth Mui-disabled"
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                }}
                            />
                            <div className="change-text">
                                <span className="add-button"
                                    onClick={() => this.handleChangeMobile()}
                                >Change Mobile</span>
                            </div>
                            {
                                this.state.isAdminUser &&
                                <div style={{ marginTop: 10 }}>
                                    <Grid container spacing={1} className="button-container">
                                            {/* <Grid item xs>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => this.xeroAuthentication()}
                                                >Insert/Update Xero accesstoken</Button>
                                            </Grid> */}
                                        <Grid item xs>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                onClick={() => this.qboAuthentication()}
                                            >Insert/Update QBO accesstoken</Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            }

                        </div>
                    }
                    {
                        this.state.selectedTabIndex == 1 &&
                        <div className="info-container">
                            <p className="profile-label">Change Password</p>
                            <div className="change-password-container">
                                <FormControl variant="outlined" className="MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
                                    <InputLabel htmlFor="existingPassword" error={this.state.currentPasswordError}>Current password * </InputLabel>
                                    <OutlinedInput
                                        error={(this.state.password.length == 0 || this.state.currentPasswordError)}
                                        required
                                        fullWidth
                                        name="password"
                                        label="Current Password"
                                        type={this.state.currentPasswordType}
                                        id="existingPassword"
                                        value={this.state.existingPassword}
                                        onChange={(e_: any) => (
                                            this.setState({
                                                existingPassword: e_.target.value,
                                                currentPasswordError: false
                                            })
                                        )}
                                        autoComplete="new-password"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => this.togglePasswordVisible("current")}
                                                    onMouseDown={() => this.togglePasswordVisible("current")}
                                                    edge="end"
                                                >
                                                    {this.state.currentPasswordType == "text" ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <span className="errormsg">{this.state.currentPasswordError ? 'Incorrect password!' : ''}</span>
                                </FormControl>
                            </div>
                            <div className="change-password-container">
                                <FormControl variant="outlined" className="MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
                                    <InputLabel htmlFor="existingPassword" error={(this.state.password.length < 8)}>Password * </InputLabel>
                                    <OutlinedInput
                                        error={(this.state.password.length < 8)}
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type={this.state.newPasswordType}
                                        id="password"
                                        value={this.state.password}
                                        onChange={(e_: any) => (
                                            this.setState({
                                                password: e_.target.value
                                            })
                                        )}
                                        autoComplete="new-password"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => this.togglePasswordVisible("new")}
                                                    onMouseDown={() => this.togglePasswordVisible("new")}
                                                    edge="end"
                                                >
                                                    {this.state.newPasswordType == "text" ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {/* <span className="errormsg">{(this.state.password.length < 8) ? '* Password must be at least 8 characters long.' : ''}</span> */}
                                    {
                                        (this.state.password.length < 8 || this.state.passwordScore <= 1) ? <div className="password-help">
                                            <Tooltip title="Please use at least 8 characters, one uppercase and optionally one special character." className="disbursement-export">
                                                <InfoIcon />
                                            </Tooltip>
                                        </div> : null
                                    }
                                    <PasswordStrengthBar
                                        scoreWords={['Weak', 'Weak', 'Medium', 'Medium', 'Strong']}
                                        shortScoreWord={'Weak'}
                                        onChangeScore={score => {
                                            this.setState({
                                                passwordScore: score
                                            })
                                        }}
                                        password={this.state.password} />
                                    {/* // helperText={(this.state.password.length < 8) ? '* Password must be at least 8 characters long.' : ''} */}
                                </FormControl>
                            </div>
                            <div className="change-password-container">
                                <FormControl variant="outlined" className="MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
                                    <InputLabel htmlFor="existingPassword" error={(this.state.password !== this.state.confirmPassword && this.state.confirmPassword.length > 1)}>Confirm new password * </InputLabel>
                                    <OutlinedInput
                                        error={this.state.password !== this.state.confirmPassword}
                                        required
                                        fullWidth
                                        name="confirmpassword"
                                        label="Confirm new password"
                                        type={this.state.confirmPasswordType}
                                        value={this.state.confirmPassword}
                                        id="confirmpassword"
                                        onChange={(e_: any) => (
                                            this.setState({
                                                confirmPassword: e_.target.value
                                            })
                                        )}
                                        autoComplete="new-password"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => this.togglePasswordVisible("confirm")}
                                                    onMouseDown={() => this.togglePasswordVisible("confirm")}
                                                    edge="end"
                                                >
                                                    {this.state.confirmPasswordType == "text" ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <span className="errormsg">{(this.state.password !== this.state.confirmPassword && this.state.confirmPassword.length > 1) ? 'The password you entered did not match' : ''}</span>
                                    {/* helperText={(this.state.password !== this.state.confirmPassword && this.state.confirmPassword.length > 1) ? 'The password you entered did not match' : ''} */}
                                </FormControl>
                            </div>
                        </div>
                    }
                    {
                        this.state.selectedTabIndex == 2 && !this.state.isAdminUser &&
                        <div className="info-container">
                            <div className="payment-div-container">
                                <h4 className="preference-text">Bank Account</h4>
                                {this.state.isVerified && <h6 style={{ marginLeft: 200 }}>Verified</h6>
                                }
                                {(!this.state.isVerified && this.state.PLAID_PUBLIC_KEY != "") &&
                                    <PlaidLink style={{ marginLeft: 180, fontSize: 16, textAlign: 'center', color: '#fff', backgroundColor: '#00af85', borderRadius: 5, width: 80, height: 40 }}
                                        clientName={this.APP_NAME}
                                        env={this.state.ENVIRONMENT}
                                        product={this.PRODUCT}
                                        countryCodes={['CA']}
                                        publicKey={this.state.PLAID_PUBLIC_KEY}
                                        onSuccess={this.onSuccess}
                                    >
                                        Verify
                                    </PlaidLink>}
                            </div>
                        </div>
                    }
                    {
                        this.state.selectedTabIndex == 2 &&
                        <div className="info-container">
                            <div className="preference-div-container">
                                <h4 className="preference-text">Notification Preference</h4>
                                <Switch
                                    className="preference-switch"
                                    checked={this.state.notificationSelected}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    onChange={() => {
                                        this.setState({
                                            notificationSelected: !this.state.notificationSelected
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    }
                </div>
                <div className="grid-addedit-actions profile-footer">
                    <Grid container spacing={1} className="button-container">
                        <Grid item xs>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={() => this.saveRecord()}
                            >Save</Button>
                        </Grid>
                        <Grid item xs>
                            <Button
                                fullWidth
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    window.location.href = "/Dashboard";
                                }}
                            >Cancel</Button>
                        </Grid>
                    </Grid>
                </div>
                <Dialog fullScreen open={this.state.dialogOpenState}
                    onClose={() => this.closeDialog()}
                    TransitionComponent={Transition}>
                    <AppBar className='add-user-appbar'>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="default"
                                onClick={() => this.closeDialog()}
                                aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className='add-user-appbar-title'>{this.state.isChangeEmail ? "Change Email" : "Change Mobile"}</Typography>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                (this.state.isChangeEmail ? ((this.state.newEmailAddress.length > 0
                                    && !this.state.isEmailOtpSent) ? this.handleChangeEmail() : this.handleSubmit())
                                    :
                                    ((this.state.newMobile.length > 0
                                        && !this.state.isMobileOtpSent) ? this.handleChangeMobile() : this.handleSubmit())

                                )}
                                className='list-button'>
                                {
                                    (this.state.isEmailOtpSent || this.state.isMobileOtpSent) ? 'Verify' : 'Send'
                                }
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <div className="popup-body proflie-dialog">
                        {
                            this.dialogBodyScreen()
                        }
                    </div>
                </Dialog>
            </div>
        )
    }

    private togglePasswordVisible(feildType: string) {
        if (feildType == "current") {
            this.setState({
                currentPasswordType: this.state.currentPasswordType == "text" ? "password" : "text"
            });
        } else if (feildType == "new") {
            this.setState({
                newPasswordType: this.state.newPasswordType == "text" ? "password" : "text"
            });
        } else if (feildType == "confirm") {
            this.setState({
                confirmPasswordType: this.state.confirmPasswordType == "text" ? "password" : "text"
            });
        }
    }

    private dialogBodyScreen() {
        if (this.state.isEmailOtpSent || this.state.isMobileOtpSent) {
            return (
                this.enterOTPScreen()
            )
        } else if (this.state.isChangeEmail) {
            return (
                this.enterNewEmailScreen()
            )
        } else if (!this.state.isChangeEmail) {
            return (
                this.enterNewMobileScreen()
            )
        }
    }
    private enterOTPScreen() {
        return (
            <div className="profile-dialog-container">
                <p className="forgot-text">A verification code has been sent to your registered {this.state.isChangeEmail ? "Email" : "mobile"}. Please check.</p>
                <TextField
                    // className="proflie-email-text"
                    error={this.state.isEmailOtpValid || this.state.emailCode.length < 6}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="emailotp"
                    label={this.state.isChangeEmail ? "Email verification code" : "Mobile verification code"}
                    type="password"
                    id="emailotp"
                    onChange={(event: any) => {
                        let _targetValue = event.target.value;
                        this.setState({
                            emailCode: _targetValue,
                            isEmailOtpValid: false,
                            errorEmailOtpMessage: ''
                        });
                    }}
                    onPaste={this.checkPasteValue}
                    onKeyPress={this.checkValue}
                    value={this.state.emailCode}
                    helperText={this.state.isEmailOtpValid ? this.state.errorEmailOtpMessage : ''}
                />
            </div>
        )
    }

    private enterNewEmailScreen() {
        return (
            <div className="profile-dialog-container">
                <p className="forgot-text">Enter the email address which you would like to add in your account and we&rsquo;ll send you verification code to verify this Email address</p>
                <TextField
                    // className="proflie-email-text"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Email"
                    name="name"
                    autoComplete="off"
                    value={this.state.newEmailAddress}
                    onChange={(event: any) => {
                        let _targetValue = event.target.value;
                        this.setState({
                            newEmailAddress: _targetValue,
                            newEmailError: !this.validateEmail(_targetValue),
                            newErrorMessage: ''
                        });
                    }}
                    error={this.state.newEmailError}
                    helperText={this.state.newEmailError ? this.state.newErrorMessage : ''}
                    autoFocus
                />
            </div>
        )
    }

    private enterNewMobileScreen() {
        return (
            <div className="profile-dialog-container">
                <p className="forgot-text">Enter the mobile number which you would like to add in your account and we&rsquo;ll send you verification code to verify this mobile number</p>
                <PhoneInput
                    country={'ca'}
                    defaultErrorMessage={this.state.newPhoneError ? this.state.newPhoneErrorMessage : ""}
                    // disabled={true}
                    onlyCountries={['ca', 'us', 'in']}
                    placeholder={'+# ### - ### - ####'}
                    masks={{ ca: '...-...-....', us: '...-...-....', in: '...-...-....' }}
                    value={this.state.newMobile}
                    onChange={phone => {
                        // this.state.newMobile = phone;
                        this.setState({
                            newMobile: phone
                        })
                    }}
                    autocompleteSearch={false}
                    containerClass="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth Mui-disabled"
                    inputProps={{
                        name: 'phone',
                        required: true,
                    }}
                />
            </div>
        )
    }

    private openDialog() {
        this.setState({
            dialogOpenState: true
        });
    }

    private closeDialog() {
        this.setState({
            dialogOpenState: false,
            isChangeEmail: false
        });
    }

    private checkValue(event: any) {
        var charCode = (event.which) ? event.which : event.keyCode;

        if (charCode > 31 && (charCode < 48 || charCode > 57) || charCode === 101 || charCode === 69) {
            event.preventDefault();
            return false;
        }
        if (event.target.value.length >= 6) {
            event.preventDefault();
            return false;
        }

        return true;
    }

    private checkPasteValue(event: any) {
        var str = event.clipboardData.getData('Text');

        if ((str.length > 6) || isNaN(str)) {
            event.preventDefault();
            return false;
        }
        return true;
    }

    private validateEmail(email: string) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    private accessTokenExpired() {
        localStorage.clear();
        window.location.href = '/';
    }

    private handleChangeEmail() {
        this.setState({
            showBlocker: true,
            isChangeEmail: true
        });

        var connectionURLString: string;

        if (this.state.newEmailAddress.length > 0) {
            connectionURLString = "user/changecontactdetails";
        } else {
            connectionURLString = CommonString.SERVICE_REQUEST_OTP;
        }

        var postData = {
            'Email': this.state.newEmailAddress.length > 0 ? this.state.newEmailAddress : this.state.userData.emailAddress,
            'CountryCode': 0,
            'MobileNumber': "",
            'VerificationType': 1
        };

        this.postService(postData, connectionURLString).then(response => {
            if (response.ok) {
                response.json().then(resp => {

                    if (resp === "already available") {

                        this.setState({
                            showBlocker: false,
                            newEmailError: true,
                            newErrorMessage: "Entered Email is already available"
                        });

                    } else {
                        this.setState({
                            showBlocker: false,
                            dialogOpenState: true,
                            isEmailOtpSent: true
                        });
                    }
                })
            } else {
                this.setState({
                    showBlocker: false
                });
                /*if (response.status === 401) {
                    this.accessTokenExpired();
                } else {
                    response.text().then(resp => {
                        console.log(response);
                    });
                    ShowAlert("", 'There was an error processing a your request.', "error");
                }*/
            }
        });
    }

    private handleChangeMobile() {

        if (this.state.userData.phone == "" && this.state.newMobile.length == 0) {
            this.setState({
                isMobileOtpSent: false,
                emailCode: '',
                showBlocker: false,
                dialogOpenState: true
            });
        } else {
            this.setState({
                showBlocker: true,
                isChangeEmail: false
            });

            var connectionURLString: string;

            if (this.state.newMobile.length > 0) {
                connectionURLString = "user/changecontactdetails";
            } else {
                connectionURLString = CommonString.SERVICE_REQUEST_OTP;
            }

            if (this.state.newMobile.length > 0) {
                var CountryCode = this.state.newMobile.slice(0, -10).replace('+', '');
                var phoneNumber = this.state.newMobile.slice(-10);
                var postData = {
                    'Email': "",
                    'CountryCode': Number(CountryCode),
                    'MobileNumber': phoneNumber,
                    'VerificationType': 1
                };
            } else {
                var postData = {
                    'Email': "",
                    'CountryCode': Number(this.state.userData.countryCode),
                    'MobileNumber': this.state.userData.phone,
                    'VerificationType': 1
                };
            }

            this.postService(postData, connectionURLString).then(response => {
                if (response.ok) {
                    response.json().then(resp => {
                        if (resp === "already available") {
                            this.setState({
                                showBlocker: false
                            });
                            ShowAlert('Error!', 'Mobile number already available', 'error');
                        } else {
                            this.setState({
                                showBlocker: false,
                                dialogOpenState: true,
                                isMobileOtpSent: true
                            });
                        }
                    })
                } else {
                    this.setState({
                        showBlocker: false
                    });
                    /*if (response.status === 401) {
                        this.accessTokenExpired();
                    } else {
                        response.text().then(resp => {
                            console.log(response);
                        });
                        ShowAlert("", 'There was an error processing a your request.', "error");
                    }*/
                }
            });
        }
    }

    private handleSubmit() {
        let isValid = true;
        if (!this.state.isEmailOtpValid) {
            var postData: any;
            var connectionURLString: string;

            if (this.state.isChangeEmail) {

                if (this.state.newEmailAddress.length > 0) {
                    connectionURLString = "user/changecontactdetails";
                } else {
                    connectionURLString = CommonString.SERVICE_REQUEST_OTP;
                }

                postData = {
                    'Email': this.state.newEmailAddress.length > 0 ? this.state.newEmailAddress : this.state.userData.emailAddress,
                    'EmailOTPCode': Number(this.state.emailCode),
                    // "CountryCode": 0,
                    // "MobileNumber": "",
                    // "MobileOTPCode": 0,
                    'VerificationType': 2
                };

                if (this.state.emailCode.length != 6) {
                    let errorMsg = "";
                    isValid = false;
                    if (this.state.emailCode != "") {
                        errorMsg = "Please enter the valid verification code";
                    } else {
                        errorMsg = "Please enter the verification code";
                    }
                    ShowAlert('Error!', errorMsg, 'error');
                }
            } else {

                if (this.state.isMobileOtpSent && !(this.state.newMobile.length > 0)) {

                    connectionURLString = CommonString.SERVICE_REQUEST_OTP;

                    postData = {
                        'Email': '',
                        'EmailOTPCode': 0,
                        "CountryCode": Number(this.state.userData.countryCode),
                        "MobileNumber": this.state.userData.phone,
                        "MobileOTPCode": Number(this.state.emailCode),
                        'VerificationType': 2
                    };

                    if (this.state.emailCode.length != 6) {
                        let errorMsg = "";
                        isValid = false;
                        if (this.state.emailCode != "") {
                            errorMsg = "Please enter the valid verification code";
                        } else {
                            errorMsg = "Please enter the verification code";
                        }
                        ShowAlert('Error!', errorMsg, 'error');
                    }
                } else {
                    connectionURLString = "user/changecontactdetails";
                    var CountryCode = this.state.newMobile.slice(0, -10).replace('+', '');
                    var phoneNumber = this.state.newMobile.slice(-10);
                    postData = {
                        'Email': '',
                        'EmailOTPCode': 0,
                        "CountryCode": Number(CountryCode),
                        "MobileNumber": phoneNumber,
                        "MobileOTPCode": Number(this.state.emailCode),
                        'VerificationType': 2
                    };

                    if (this.state.emailCode.length != 6) {
                        let errorMsg = "";
                        isValid = false;
                        if (this.state.emailCode != "") {
                            errorMsg = "Please enter the valid verification code";
                        } else {
                            errorMsg = "Please enter the verification code";
                        }
                        ShowAlert('Error!', errorMsg, 'error');
                    }
                }
            }

            if (isValid) {
                this.setState({
                    showBlocker: true
                });
                this.postService(postData, connectionURLString).then(response => {
                    if (response.ok) {
                        response.json().then(resp => {
                            var _response = JSON.parse(resp);
                            if (_response.code === 200) {
                                var _results = JSON.parse(_response['data']);
                                var _emailStatus = _results['Email'];
                                var _mobileStatus = _results['Mobile'];

                                if (_emailStatus === 'Valid' || _mobileStatus === 'Valid') {

                                    if (this.state.isChangeEmail) {
                                        if (this.state.newEmailAddress.length > 0) {
                                            this.state.userData.emailAddress = this.state.newEmailAddress;
                                            this.setState({
                                                isEmailOtpSent: false,
                                                emailCode: '',
                                                dialogOpenState: false,
                                                userData: this.state.userData,
                                                showBlocker: false,
                                                isChangeEmail: false,
                                                newEmailAddress: ""
                                            });
                                            ShowAlert('Success!', 'Email updated successfully', 'success');
                                        } else {
                                            this.setState({
                                                isEmailOtpSent: false,
                                                isMobileOtpSent: false,
                                                emailCode: '',
                                                showBlocker: false
                                            });
                                        }
                                    } else {
                                        if (this.state.newMobile.length > 0) {
                                            var CountryCode = this.state.newMobile.slice(0, -10).replace('+', '');
                                            var phoneNumber = this.state.newMobile.slice(-10);
                                            this.state.userData.countryCode = Number(CountryCode);
                                            this.state.userData.phone = phoneNumber;
                                            this.setState({
                                                isMobileOtpSent: false,
                                                emailCode: '',
                                                dialogOpenState: false,
                                                userData: this.state.userData,
                                                showBlocker: false,
                                                isChangeEmail: false,
                                                newMobile: ""
                                            });
                                            ShowAlert('Success!', 'Mobile updated successfully', 'success');
                                        } else {
                                            this.setState({
                                                isMobileOtpSent: false,
                                                emailCode: '',
                                                showBlocker: false
                                            });
                                        }

                                    }
                                } else {
                                    this.setState({
                                        isEmailOtpValid: true,
                                        errorEmailOtpMessage: "Entered verification code expired or Invalid",
                                        showBlocker: false,
                                        isChangeEmail: false
                                    });
                                }
                            }
                        })
                    } else {
                        this.setState({
                            showBlocker: false
                        });

                        /*if (response.status === 401) {
                            response.text().then(resp => {
                                if (resp.toLocaleLowerCase().indexOf('unauthorized') != -1) {
                                    this.accessTokenExpired();
                                } else {
                                    ShowAlert('Error!', resp, 'error');
                                }
                            })
                        } else {
                            response.text().then(resp => {
                                console.log(resp);
                            });
                            ShowAlert("", 'There was an error processing a your request.', "error");
                        }*/
                    }
                });
            }
        }
    }

    private isValidPhoneNumber(value: any, countryCode: string) {
        var _isValidMobileNumber = ((value.length - countryCode.length) < 10);
        return _isValidMobileNumber;
    }

    private xeroAuthentication() {
        this.setState({
            showBlocker: true
        });

        this._xeroService.getXeroLoginURL()
            .then(r => r.json())
            .then(_response => {
                window.location.href = _response;
            });
    }

    private qboAuthentication() {
        this.setState({
            showBlocker: true
        });

        this._qboService.getQBOLoginURL()
            .then(r => r.json())
            .then(_response => {
                window.location.href = _response;
            });
    }

    private saveRecord() {

        this.setState({
            showBlocker: true
        });

        if (this.state.selectedTabIndex == 0) {
            var postData = {
                'Name': this.state.userData.username,
                'Email': this.state.userData.emailAddress,
                'CountryCode': Number(this.state.userData.countryCode),
                'MobileNumber': this.state.userData.phone,
                'Password': this.state.confirmPassword.length > 0 ? this.state.confirmPassword : '',
                'Address1': this.state.userData.address1,
                'Address2': this.state.userData.address2,
                'City': this.state.userData.city,
                'State': this.state.userData.state,
                'Country': this.state.userData.country,
                'ZipCode': this.state.userData.zipCode
            };

            this.postService(postData, "signin/updateprofile").then(response => {
                if (response.ok) {
                    response.json().then(resp => {
                        this.setState({
                            showBlocker: false,
                        });
                        ShowAlert('Success!', 'Updated successfully', 'success');
                        this.closeDialog();
                    })
                } else {
                    this.setState({
                        showBlocker: false
                    });
                    /*if (response.status === 401) {
                        this.accessTokenExpired();
                    } else {
                        response.text().then(resp => {
                            console.log(resp);
                        })
                        ShowAlert('Error!', 'Error in updating information', 'error');
                    }*/
                }
            });
        } else {
            if (this.state.existingPassword != null && this.state.existingPassword != "" &&
                this.state.password.length >= 8 && this.state.password == this.state.confirmPassword && this.state.passwordScore > 1) {
                var postPasswordData = {
                    'CurrentPassword': this.state.existingPassword,
                    'NewPassword': this.state.confirmPassword
                };

                this.postServiceCustomResponse(postPasswordData, "user/update-password").then(response => {
                    if (response.ok) {
                        this.setState({
                            showBlocker: false,
                        });
                        ShowAlert('Success!', 'Updated successfully', 'success').then((resp) => {
                            window.location.href = "/settings";
                        });
                        this.closeDialog();
                    } else {
                        response.text().then((resp: any) => {
                            console.log(resp);
                            this.setState({
                                currentPasswordError: true,
                                showBlocker: false
                            }, () => {
                                ShowAlert(
                                    "Error!",
                                    JSON.parse(resp),
                                    "error"
                                );
                            });
                        });
                    }
                });
            }
            else {
                if (this.state.existingPassword == "" || this.state.existingPassword == null) {
                    ShowAlert('Error', 'Please enter the current password', 'error');
                } else if (this.state.password.length < 8 || this.state.passwordScore <= 1) {
                    ShowAlert('Error', 'Please enter the valid password', 'error');
                } else if (this.state.password != this.state.confirmPassword) {
                    ShowAlert('Error', 'Password and Confim new password does not match', 'error');
                }
                else {
                    ShowAlert('Error', 'Please enter the valid password', 'error');
                }

            }

        }



    }

    private postService(postData: any, postUrl: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + postUrl, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postData)
                })
            });
    }

    private postServiceCustomResponse(postData: any, postUrl: string) {
        return ConfigService.getBaseUrl().then((url) => {
            return BaseService.sendRequest(url + postUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(postData)
            }, 'ignoreAlert');
        });
    }

    private getProfileInformation() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "user/getprofile", {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
            });
    }
}

export default Profile;