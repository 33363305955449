import BaseService from "../Services/BaseService";
import StringHelper from "./StringHelper";

export default class ObjectHelper {
    public static surfaceClone<T extends Object>(object_: T): T {
        return Object.create(object_);
    }

    public static deepClone<T extends Object>(object_: T): T {
        return this._cloneObject(object_) as T;
    }

    public static toCSVArray(objects_: Object[]): string[][] {
        let array: string[][] = [];
        
        if (objects_.length > 0) {
            let headerArray: string[] = [];
            for (const key in objects_[0]) {
                headerArray.push(key);
            }
            array.push(headerArray);

            for (const object_ of objects_) {
                const rowArray: string[] = [];
                for (const columnName of headerArray) {
                    let value = (object_ as any)[columnName];

                    if (value !== null && value !== undefined) {
                        rowArray.push(value.toString());
                    }
                    else {
                        rowArray.push("");
                    }
                }

                array.push(rowArray);
            }
        }

        return array;
    }

    private static _cloneObject(object_: any) {
        if (typeof (object_) == "string") {
            return object_;
        }
        else if (typeof (object_) == "number") {
            return object_;
        }
        else if (object_ instanceof Date) {
            return new Date(object_);
        }
        else if (object_ instanceof Array) {
            let clonedArray: any = [];
            for (let index = 0; index < object_.length; index++) {
                clonedArray[index] = this._cloneObject(object_[index]);
            }
            return clonedArray;
        } 
        else if (object_ instanceof Blob || object_ instanceof ArrayBuffer || object_ instanceof File) {
            return object_;
        }
        else if (object_ instanceof Object) {
            let clonedObject: any = {};
            for (let key in object_) {
                clonedObject[key] = this._cloneObject(object_[key]);
            }
            return clonedObject;
        }
        else {
            return object_;
        }
    }

    public static async GetAllResponse<T>(contentParser_: (spots_: T[]) => T[], onProgress_: (spots_: T[], progress_: number) => void, promiseResponse: Promise<Response>, tagString: string){
        let remainder = "";
        return BaseService.asyncResponse<T>(
            promiseResponse,
            (content_, contentLength, receivedLength) => {
                let reservation: T[] = [];
                content_ = remainder + content_;

                if (content_ && contentLength != receivedLength) {
                    let lastIndex = content_.lastIndexOf(tagString);
                    if (lastIndex == -1) {
                        remainder = content_;
                        // content_ = "";
                    }
                    else {
                        remainder = "[" + StringHelper.trimStart(content_.substring(lastIndex), ',');
                        content_ = content_.substring(0, lastIndex) + "]";
                    }
                }

                if (content_) {
                    try {
                        reservation = JSON.parse(content_);
                    } catch (e) {}
                }

                return contentParser_(reservation);
            }, onProgress_);
    }

    public static sortObjectByKeys<T extends { [key: string]: any }>(unordered: T): T {
        const emptyObject: any = {};
        return Object.keys(unordered).reduce(
          (acc, key) => Object.assign(acc, { [key]: unordered[key] }),
          emptyObject
        );
      }
}