import { useHistory } from "react-router-dom";
import { UserDetailsModel } from "../Models/UserDetails";
import UserService from "../Services/UserService";
import { ShowAlert } from "./ShowAlert";

export async function CheckScreenPermission(screen: string) {
    var accessDetails = await getCurrentUserAccess();
    var accessDetailsString = accessDetails ? JSON.stringify(accessDetails).toLocaleLowerCase() : "";

    var accessibleScreen = localStorage.getItem('accessibleScreen');
        
    localStorage.setItem('permissionDetails', accessDetailsString);

    let screenName = screen.toLocaleLowerCase();
    // const history = useHistory();
    // use history.push('/some/path') here

    if (accessDetailsString.indexOf(screenName) == -1) {
        let errorMsg = "You do not have permission to access. Contact your administrator.";
        ShowAlert("Insufficient permission", errorMsg, "error")
            .then(() => {

                if (accessibleScreen != null && accessibleScreen !== '') {
                    var screens = JSON.parse(accessibleScreen);
                    let newScreen = screens[0].permissionkey.replace("user-interface-", "");
                    window.location.href = '/'+newScreen;
                }

                /*if (screenName != "user-interface-dashboard") {
                    window.location.href = '/Dashboard';
                    // history.push('/Dashboard');
                }*/
            });

        return false;
    }

    return true;
};

export function CheckFeaturePermission(permission: string) {
    let permissionName = permission.toLocaleLowerCase();

    var _localData = localStorage.getItem('permissionDetails');

    if (_localData != null && _localData !== '') {
        if (_localData.indexOf(permissionName) == -1) {
            return false;
        }
    }

    return true;
};

export function NavToHomeScreen() {
    var accessibleScreen = localStorage.getItem('accessibleScreen');

    if (accessibleScreen != null && accessibleScreen !== '') {
        var screens = JSON.parse(accessibleScreen);

        if (screens.length > 0) {
            let newScreen = screens[0].permissionkey.replace("user-interface-", "");
            window.location.href = '/'+newScreen;
        }
        else {
            let errorMsg = "You do not have permission to access. Contact your administrator.";
            ShowAlert("Insufficient permission", errorMsg, "error");
        }
    } 
    // else {
    //     window.location.href = '/dashboard';
    // }
}

async function getCurrentUserAccess() {
    const _userService: UserService = new UserService();

    return await _userService.getCurrentUserAccess()
    .then(r => r.json())
    .then(r => {
        return r;
    }).catch(err => {
    })
}
