import React from "react";
import { Dialog, AppBar, Toolbar, Typography, IconButton, DialogContent, DialogActions, TextField, Button } from "@material-ui/core";
import SpotService from "../../Services/SpotService";
import { ShowAlert } from "../../Common/ShowAlert";
import { SpotBlock } from "./interfaces";

export default class ContactSupportView extends React.Component<IContactSupportViewProps, IContactSupportViewState> {
    private _spotService: SpotService;
    constructor(props_: IContactSupportViewProps) {
        super(props_);

        this.state = {
            message: ""
        }

        this._spotService = new SpotService();
    }

    render() {
        return (
            <Dialog
                disableBackdropClick
                open={this.props.openSupportPopup}
                onClose={() => this.closeDialog()}
                aria-labelledby="customized-dialog-title" className="popup-support">
                <AppBar className='add-user-appbar'>
                    <Toolbar>
                        <Typography variant="h6" className='popup-support-title'>Contact Support</Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <TextField
                        multiline
                        placeholder="Message"
                        variant="outlined"
                        value={this.state.message}
                        onChange={(e_: any) => {
                            this.setState({
                                message: e_.target.value
                            });
                        }}
                        error={this.state.message == "" || this.state.message == null}
                        className="support-message-box"
                    />
                </DialogContent>
                <DialogActions className="support-button-container">
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => this.closeDialog()}
                        className='list-button list-button-cancel'>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleSubmit()}
                        className='list-button'>
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    private closeDialog() {
        if (this.props.onClose) {
            this.props.onClose(true);
        }
    }

    private handleSubmit() {
        let spotBlockModel: SpotBlock = JSON.parse(this.props.blockRequest);

        if (!this.state.message) {
            return false;
        }

        let userMessage: any = {
            message: this.state.message,
            spotGuids: spotBlockModel.spotGuids,
            blockRequest: this.props.blockRequest,
            startDate: spotBlockModel.startDate,
            startTime: spotBlockModel.startTime,
            endTime: spotBlockModel.endTime
        }

        this._spotService.ContactSupport(userMessage)
            .then(r => {
                if (r.ok) {
                    ShowAlert('', 'Your message sent successfully!', 'success').then(() => {
                        if (this.props.onClose) {
                            this.props.onClose(true);
                        }
                    });
                }
            });
    }
}

interface IContactSupportViewProps {
    blockRequest: string;
    openSupportPopup: boolean;
    onClose: (b_: boolean) => void;
}

interface IContactSupportViewState {
    message: string;
}