import React from "react";
import { FormControl, InputLabel, Button } from "@material-ui/core";
import { FileDrop } from "react-file-drop";
import ClearIcon from '@material-ui/icons/Clear';
import "./BasicComponents.css";

export default class FileUploadControl extends React.Component<IFileUploadControlProps, IFileUploadControlState> {
    
    constructor(props_: IFileUploadControlProps) {
        super(props_);

        this.state = {
            storedFileGuid: props_.storedFileGuid,
            fileName: props_.fileName,
            fileUrl: props_.fileUrl
        };
    }

    render() {
        return (
            <FormControl className="file-upload-control">
                <InputLabel shrink={true}>{this.props.label}</InputLabel>
                <div
                    className="mt-3"
                >
                    <FileDrop
                        onDrop={(files: FileList) => this._onFilesUpload(files)}
                    >
                        {
                            this.state.fileUrl &&
                            (
                                <div className="file-details w-100 d-flex">
                                    <span className="image-name flex-grow-1">{this.state.fileName}</span>
                                    <Button
                                        className="btn-remove-image"
                                        onClick={this._onRemoveFile}
                                    >
                                        <ClearIcon />
                                    </Button>
                                </div>

                            )
                        }
                        {
                            !this.state.fileUrl &&
                            (
                                <Button
                                    className="btn-file-upload"
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                >
                                    <span>Upload</span>
                                    <input
                                        type="file"
                                        accept={this.props.filter}
                                        multiple={false}
                                        onChange={event => {
                                            let inputControl = event.target as HTMLInputElement;
                                            if (inputControl) {
                                                this._onFilesUpload(inputControl.files);
                                            }
                                        }}
                                    />
                                </Button>
                            )
                        }
                    </FileDrop>
                </div>
            </FormControl>
        )
    }

    private _onFilesUpload = (files_: FileList | null) => {
        if (files_ && files_.length) {
            let file = files_[0];
            let fileName = file.name;
            let extensionStartIndex = fileName.lastIndexOf('.');
            let extension = fileName.substr(extensionStartIndex);
            let allowedExtensions = this.props.filter.split(',');

            if (allowedExtensions.indexOf(extension) > -1) {
                let fileUrl = URL.createObjectURL(file);

                this.props.onUpload(file, fileName);

                this.setState({
                    storedFileGuid: undefined,
                    fileContent: file,
                    fileName: fileName,
                    fileUrl: fileUrl
                });
            }
            else if (this.props.onUploadRejected) {
                this.props.onUploadRejected(fileName, extension);
            }
        }
    }

    private _onRemoveFile = () => {
        this.props.onRemove();

        this.setState({
            storedFileGuid: undefined,
            fileContent: undefined,
            fileName: undefined,
            fileUrl: undefined
        });
    }
}

interface IFileUploadControlProps {
    label: string;
    filter: string;
    storedFileGuid?: string;
    fileUrl?: string;
    fileName?: string;
    onUpload: (fileContent_: Blob, fileName_: string) => void;
    onRemove: () => void;
    onUploadRejected?: (fileName_: string, fileExtension_: string) => void;
}

interface IFileUploadControlState {
    storedFileGuid?: string;
    fileUrl?: string;
    fileName?: string;
    fileContent?: Blob;
}