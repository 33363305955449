import {ZoneActiveSpot} from "../../Pages/PrivateLots/interface";

export interface IMapZones {
  ZoneName: string;
  IsChecked: boolean;
  ZoneGuid: string;
}

export interface IFigmaNodes {
  OtherElements: IFigmaChildNodes[];
  Spots: IFigmaNodesMappedWithSpot;
  Width?: number;
  Height?: number;
}

export interface IFigmaNodesMappedWithSpot {
  [key: string]: INodesMappedSpotDetails;
}

export interface INodesMappedSpotDetails {
  ZoneGuid: string;
  SpotGuid: string;
  FriendlySpotId: string;
  BoxElement: IFigmaChildNodes[];
  OtherElements: IFigmaChildNodes[];
  isSaved: boolean;
}

export interface IFigmaChildNodes {
  Id: string;
  Svg: string;
  XPosition: number;
  YPosition: number;
  ElementType: string;
  Image: string;
  Width: number;
  Height: number;
  IsChecked: boolean;
  SpotColor: string;
  BgColor: string;
  isZoneName: boolean;
  ElementName: string;
}

export interface IFigmaElementProps {
  id: string;
  name: string;
  type: string;
  absoluteRenderBounds: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
}

export interface ISelectedRange {
  clientX: number;
  clientY: number;
}

export enum EnumInteractiveMapStatus {
  UploadMap = 1,
  PropertySelection,
  CompleteLotSetup,
  EditMap,
  ZoneSelection,
  SpotNumberMapping,
  SaveMapping,
  MappingCompleted,
  RequestResetCompletedMap,
  ConfirmedToResetMap
}

export enum EnumMapEditStatus {
  Uploaded = 1,
  Configured,
  Deleted
}

export interface IProgressDetails {
  progress: EnumInteractiveMapStatus,
  selectedSpotInfo: ZoneActiveSpot | null,
  selectedZone: IPrivateLotZone | null
}

export interface IPrivateLotZone {
  propertyGuid: string;
  zoneGuid: string;
  zoneName: string;
}


export interface IMapResetOption {
  ResetAllSpotAttributes: boolean,
  ResetMap: boolean,
  Cancel: boolean
}

export interface ISelectedSpotDetails {
  mappedSpots: IMappedSpotDetails[];
  selectedSpot: string;
  isSpotSelectionEnable: boolean;
  selectedBoxCount: number;
  totalSpotsInMap: number;
}

export interface IMappedSpotDetails {
  spotGuid: string;
  FriendlySpotId: string;
  zoneGuid: string;
}

export interface IFloor {
  levelId: number;
  levelName: string | React.JSX.Element;
  hasMap?: boolean;
}

export interface INewFloor {
  levelName: string;
  levelId: number;
  hasMap?: boolean;
}
