import { Button, Checkbox, Grid, IconButton, Paper, styled, TextField, Tooltip } from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import Error from "@material-ui/icons/Error";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { CSVLink } from "react-csv";
import { FileDrop } from "react-file-drop";
import { ShowAlert } from '../../Common/ShowAlert';
import { ShowBlocker } from "../../Components/LoaderComponent";
import { IFloor } from "../../Components/SpotMap/interface";
import { SyncContext } from "../../Contexts/BatchSyncContext";
import TickSVG from '../../Images/green-tick.svg';
import PrivateLotService from '../../Services/PrivateLotService';
import { IPropertyBasics } from "../Spots/Interfaces";
import "./AssignTenant.css";
import { IPrivateLotProperty, IYardiSyncErrorModel, IYardiSyncModel, IYardiSyncRequestModel } from "./interface";
import {StatusCodes} from "http-status-codes";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

export default class YardiSync extends React.Component<IYardiSyncProps, IYardiSyncState> {
    private _privateLotService: PrivateLotService;
    private autoCompleteInput: any = null;
    static contextType = SyncContext;
    constructor(props_: IYardiSyncProps) {
        super(props_);
        this.state = {
            selectedIndex: 0,
            dataFormatterArray: [
                {
                    "name": "STEP 1",
                    "Type": "Textfield"
                },
                {
                    "name": "STEP 2",
                    "Type": "Textfield"
                },
                {
                    "name": "STEP 3",
                    "Type": "Textfield"
                }
            ],
            tenantBuilding: null,
            choosedProperty: null,
            lot: null,
            showSpinner: false,
            showSyncSuccess: false,
            choosedUnits: [],
            isFileUpload: true,
            isInValidDataFormat: false,
            failedCSVRecords: [],
            isUploadComplete: false,
            syncResponse: null,
            syncError: ""
        }
        this._privateLotService = new PrivateLotService();

    }
    componentDidMount() {
        this.setState({
            choosedProperty: this.props.selectedProperty
        });
    }

    _onFilesUpload = (files_: FileList | null) => {
        if (files_ && files_.length) {
            const file = files_[0];
            this.setState({ fileName: file.name, fileInfo: file, fileUrl: URL.createObjectURL(file), fileContent: file });
        }
    }

    returnOptionList = (units: YardiPropertyModel[]) => {
        return units.map(unit => {
            const address = unit.addressLine1 ? unit.addressLine1 :
                            unit.addressLine2 ? unit.addressLine2 :
                            unit.marketingName ? unit.marketingName :
                            "No name";

            return `${unit.code} - ${address}`;
        });
    }

    removeFile = () => {
        let input = document.getElementById('file-upload') as any;

        if (input)
            input.value = null;

        this.setState(
            {
                isFileUpload: true,
                fileName: "",
                fileInfo: undefined,
                fileUrl: "",
                fileContent: undefined
            });
    }

    renderStepView(selectedIndex: number, index: number, value: any) {

        let colorCode = "#00AF85";

        if ((value.name === 'STEP 1' && this.state.selectedIndex !== 0) ||
            (value.name === 'STEP 2' && this.state.selectedIndex !== 1) ||
            (value.name === 'STEP 3' && this.state.selectedIndex !== 2)) {
            colorCode = "#8A959E";
        }

        return (
            <div style={{ 'height': index === selectedIndex ? "" : "" }}>
                {
                    <div>
                        <label style={{ fontSize: "16px", fontFamily: 'Manrope', fontWeight: 700, color: `${colorCode}` }}
                        // onClick={() => {
                        // if (index == 1) {
                        //     if (this.state.choosedProperty) {
                        //         this.setState({
                        //             selectedIndex: index
                        //         })
                        //     } else {
                        //         ShowAlert("", 'Kindly complete step 1', "error");
                        //         console.log("error")
                        //     }

                        // } else {
                        //     this.setState({
                        //         selectedIndex: index
                        //     })
                        // }

                        // }}
                        >{value.name}</label>
                        <div>
                            {
                                index === selectedIndex ? (
                                    <div>
                                        <div>
                                            {
                                                value.name === 'STEP 1' && (
                                                    <div>
                                                        <label style={{ fontSize: "16px", fontFamily: 'Manrope', fontWeight: 400, color: '#323232' }}>Please confirm this is the correct property</label>
                                                        <Autocomplete
                                                            className="cl-repeat-dropdown"
                                                            options={this.props.properties.filter(x => x.propertyGuid !== 'blank')}
                                                            value={this.state.choosedProperty}
                                                            openOnFocus={true}
                                                            selectOnFocus={true}
                                                            getOptionLabel={(option) => option.propertyName}
                                                            renderInput={(params) => <TextField {...params}
                                                                placeholder="Select a property"
                                                                variant="outlined"
                                                                required={true}

                                                            />}
                                                            onChange={(e_: any, property_: IPrivateLotProperty | null) => {
                                                                this.setState({
                                                                    choosedProperty: property_,
                                                                    lot: null,
                                                                    choosedUnits: []
                                                                }, () => {
                                                                    //  console.log("property_", property_);

                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            }
                                        </div>
                                        {
                                            value.name === 'STEP 2' && (
                                                <div>
                                                    <label style={{ fontSize: "16px", fontFamily: 'Manrope', fontWeight: 400, color: '#323232' }}>Please confirm this is the correct lot</label>
                                                    {/* <TextField
                                                        fullWidth id="outlined-basic" size="medium" label="" variant="outlined" color="primary" autoComplete="off" placeholder={"Enter lot"}
                                                        inputProps={{ maxLength: 20 }}
                                                        value={this.state.lot}
                                                        disabled
                                                        onChange={(e: any) => {
                                                            this.setState({
                                                                lot: e.target.value
                                                            })
                                                        }}
                                                    >
                                                    </TextField> */}
                                                    <Autocomplete
                                                        fullWidth
                                                        multiple={true}
                                                        className="cl-repeat-dropdown dropdown-input"
                                                        openOnFocus={true}
                                                        selectOnFocus={true}
                                                        disableCloseOnSelect
                                                        ref={(input) => { this.autoCompleteInput = input; }}
                                                        options={this.state.lot ? this.returnOptionList(this.state.lot) : []}
                                                        value={this.state.choosedUnits}
                                                        getOptionLabel={(option) => option}
                                                        renderInput={(params) => <TextField {...params}
                                                            label="Lot"
                                                            variant="outlined"
                                                        />}
                                                        renderTags={(tagValue) => {
                                                            let choosedUnits = tagValue.map(x => {
                                                                return x
                                                            });

                                                            return <div className="selected-lot">{choosedUnits.toString().replaceAll(",", ", ")}</div>;
                                                        }}
                                                        renderOption={(option, { selected }) => (
                                                            <React.Fragment>
                                                                <div className="cl-dropdown-list">
                                                                    <Checkbox
                                                                        icon={<div className="repeat-b-check" />}
                                                                        checkedIcon={<div className="repeat-b-check selected" />}
                                                                        style={{ marginRight: 8 }}
                                                                        checked={selected}
                                                                        color="primary"
                                                                    />
                                                                    {option}
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                        onChange={(e_: any, choosedUnits: string[]) => {
                                                            this.setState({
                                                                choosedUnits
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            )
                                        }
                                        {
                                            value.name === 'STEP 3' && (
                                                <div>
                                                    <label style={{ fontSize: "16px", fontFamily: 'Manrope', fontWeight: 400, color: '#323232' }}>
                                                        (Optional) Please upload list of custom "Rentable Items" CSV file(If you are using Yardi's Custom Rentable Items, then please make sure to upload the same to successfully sync with GrydPark)
                                                    </label>
                                                    <div className="d-flex flex-column">
                                                        <div className="d-flex flex-column align-items-center" style={{ height: '90px' }}>
                                                            <div className="bulk-upload-file-container mt-3">
                                                                <FileDrop
                                                                    className="file-dropbox flex-grow-1 file-dropbox-sync "
                                                                    onDrop={(files: FileList) => this._onFilesUpload(files)}
                                                                >
                                                                    <div className={`dropbox-content text-center file-picker-container`}>
                                                                        {this.state.fileInfo &&
                                                                            <div style={{ padding: 10 }}>
                                                                                <Grid container spacing={0}>
                                                                                    <Grid item xs={12}>
                                                                                        <Paper elevation={0}>
                                                                                            <Item elevation={0}>{this.state.fileName}{
                                                                                                <Tooltip title="Remove">
                                                                                                    <IconButton type="submit" onClick={this.removeFile} className="removeButton-Sync" aria-label="Remove">
                                                                                                        <ClearIcon />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            }</Item>
                                                                                        </Paper>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </div>}
                                                                        {!this.state.fileInfo && <>Drag and drop CSV files here or <span>upload</span>
                                                                            <label className="full-size" htmlFor="file-upload" style={{ height: 60 }}></label></>}
                                                                        <br />
                                                                        <input
                                                                            id="file-upload"
                                                                            className="hidden"
                                                                            type="file"
                                                                            accept=".csv"
                                                                            multiple={false}
                                                                            onChange={event => {
                                                                                let inputControl = event.target as HTMLInputElement;
                                                                                if (inputControl) {
                                                                                    this._onFilesUpload(inputControl.files);
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </FileDrop>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="bulk-upload-sub-content">
                                                        <a className="link-color" href="/YardiCustomData.csv" download="YardiCustomData.csv">Click here to download the sample csv file.</a>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                ) : <></>
                            }
                        </div>
                        <label style={{ width: '100%', backgroundColor: '#DADDDF', height: '1px', 'marginTop': index === selectedIndex ? '20px' : '' }}></label>
                    </div>
                }
            </div>
        )
    }

    onInValidHeaderOrDataFormatClose = () => {
        this.setState(
            {
                isInValidDataFormat: false,
                isFileUpload: true,
                fileName: "",
                fileInfo: undefined,
                fileUrl: "",
                fileContent: undefined,
                isUploadComplete: false
            });
    }

    yardiSyncSuccess = () => {
        this.props.updateSyncDate && this.props.updateSyncDate();
        ShowBlocker(false);
        this.setState({
            showSpinner: false,
            isUploadComplete: false,
            failedCSVRecords: [],
            isInValidDataFormat: false
        }, () => {
            this.setState({
                showSyncSuccess: true
            });
        });
    }

    yardiSyncError = (error: any) => {
        this.setState({
            showSpinner: false,
            isUploadComplete: false,
            failedCSVRecords: [],
            isInValidDataFormat: false
        });
        ShowAlert("", error, "error");
        ShowBlocker(false);
    }

    getSyncBatchProcessResponse = (batchGuid: string) => {
        this._privateLotService.GetBatchProcessSyncYardi(batchGuid, true)
        .then(response => {
            if(response.ok){
                response.json().then(syncRes => {
                    const syncResponse: IYardiSyncModel[] = syncRes;
                    this.setState({ syncResponse, syncError: "" });
                    console.log("Sync successfull");
                }).catch(() => {
                    if (response.status === StatusCodes.NO_CONTENT)
                        console.log("Sync in progress");
                    else
                        this.errorBatchProcess("There was an error processing your request.");
                });
            } else if (response.status === StatusCodes.BAD_REQUEST) {
                response.json().then(badResponse => {
                    console.log("Sync failed", badResponse);
                    this.errorBatchProcess(badResponse);
                }).catch(() => {
                    this.errorBatchProcess("There was an error processing your request.");
                });
            }
        }).catch(() => {
            this.errorBatchProcess("There was an error processing your request.");
        });
    }

    errorBatchProcess = (error: string) => {
        this.setState({ syncResponse: null, syncError: error });
    }

    render() {
        return (
            <>
                <div className="mx-3">
                    <Grid container className="right-panel-header p-2 border-bottom">
                        <Grid item xs>
                            <h4 className="right-panel-title m-0 ml-3">
                                <span>Sync Property</span>
                            </h4>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() => {
                                    this.props.onCloseDrawerPanel(this.state.showSyncSuccess, this.state.choosedProperty, this.props.onPropertyChange);
                                }}>
                                <CloseIcon />
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ 'margin': '20px', 'flex': 1, 'display': 'flex', flexDirection: 'column' }}>
                    {
                        (this.state.showSpinner || this.state.showSyncSuccess) ? (
                            <>
                                {
                                    this.state.showSpinner && (
                                        <div style={{ 'marginTop': '100px', 'display': 'flex', 'flexDirection': 'row', justifyContent: 'center' }}>
                                            <div className="spotMaploader"></div>
                                        </div>

                                    )
                                }
                                {
                                    this.state.showSyncSuccess && (
                                        // <div style={{ 'marginTop': '100px', 'display': 'flex', 'flexDirection': 'row', justifyContent: 'center' }}>
                                        //     <img style={{ "width": '42px', 'height': '42px', }} src={TickSVG}></img>

                                        // </div>
                                        <div style={{ 'display': 'flex', 'flexDirection': 'column', width: '100%' }}>
                                            <div style={{ alignItems: 'center', 'display': 'flex', 'flexDirection': 'column' }}>
                                                <img style={{ "width": '42px', 'height': '42px', }} src={TickSVG} alt={``}></img>
                                                <label style={{ marginTop: '10px', color: '#323232', 'fontFamily': 'Manrope', 'fontWeight': 600, 'fontSize': '18px' }}>Property Details Successfully Synced</label>
                                                <label style={{ color: '#323232', 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '16px', textAlign: "center" }}>{ }</label>
                                                <Button style={{ width: '60%', height: '40px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: 700, borderRadius: '8px', color: 'white' }}
                                                    onClick={() => this.props.onCloseDrawerPanel()}>
                                                    Close
                                                </Button>
                                            </div>
                                        </div>

                                    )
                                }


                            </>) : (
                            <>
                                <div style={{ flex: 1 }}>{
                                    this.state.dataFormatterArray.map((val: any, index: number) =>
                                        <div>{this.renderStepView(this.state.selectedIndex, index, val)}</div>
                                    )
                                }
                                </div>
                                <div style={{ 'display': 'flex', 'flexDirection': 'row-reverse', 'width': '100%' }}>
                                    <Button style={{ width: '100px', height: '40px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: 700, borderRadius: '8px', color: 'white' }}
                                        onClick={() => {
                                            if (this.state.selectedIndex === 2 && this.state.lot !== null && this.state.lot.length > 0 && this.state.choosedProperty) {
                                                this.setState({
                                                    showSpinner: true
                                                });

                                                const { fileInfo, fileName, fileContent } = this.state;
                                                let form = new FormData();
                                                let propertyGuid = this.state.choosedProperty.propertyGuid;
                                                let unitIds = this.state.choosedUnits.map(unit => unit.split(' - ')[0]);
                                                const postData: IYardiSyncRequestModel = {
                                                    propertyGuid: propertyGuid,
                                                    yardiCodes: unitIds
                                                }
                                                form.append("value", JSON.stringify(postData));
                                                if (fileInfo && fileContent) {
                                                    form.append("file", fileContent, fileName);
                                                }
                                                else {
                                                    form.append("file", "");
                                                }
                                                this._privateLotService.postMultiSyncYardi(form, true)
                                                    .then(r => {
                                                        if (r.ok) {
                                                            r.json().then(batchRes => {
                                                                const batchGuid = batchRes;
                                                                this.getSyncBatchProcessResponse(batchGuid);
                                                                //let batchProcessCount = 0;
                                                                this.yardiSyncSuccess();
                                                                this.context.setInfo({ syncing: true, batchGuid: batchGuid });
                                                                const batchProcess = setInterval(() => {
                                                                    let processSync = false;
                                                                    let {syncResponse, syncError} = this.state;
                                                                    console.log("syncResponse")
                                                                    if(!syncResponse)
                                                                    {
                                                                        processSync = true;
                                                                        this.getSyncBatchProcessResponse(batchGuid);
                                                                    }

                                                                    // if (batchProcessCount > 10) {
                                                                    //     clearInterval(batchProcess);
                                                                    //     ShowBlocker(false);
                                                                    //     ShowAlert('',
                                                                    //         'The sync is taking much longer than we anticipated, we will continue to sync your data in the background. Please refresh the page once in a while to view the updated data',
                                                                    //         'info').then(() => {
                                                                    //             this.yardiSyncSuccess();
                                                                    //         });
                                                                    // }

                                                                    //batchProcessCount++;

                                                                    if (syncResponse || !processSync) {
                                                                        clearInterval(batchProcess);
                                                                        this.context.setInfo({ syncing: false, batchGuid: null });
                                                                        console.log('entrei')
                                                                        if (syncResponse) {
                                                                            const yardiSyncResponse: IYardiSyncModel[] = syncResponse;
                                                                            const errorData = yardiSyncResponse?.filter(x => x.isError).map(x => {
                                                                                const yardiSyncErrorModel: IYardiSyncErrorModel = {
                                                                                    tenantCode: x.tenantCode,
                                                                                    spotID: x.spotID,
                                                                                    propertyCode: x.propertyCode,
                                                                                    errorMessage: x.errorMessage
                                                                                }
                                                                                return yardiSyncErrorModel;
                                                                            });
                                                                            const skipData = yardiSyncResponse?.filter(x => !x.isError).map(x => {
                                                                                const yardiSyncErrorModel: IYardiSyncErrorModel = {
                                                                                    tenantCode: x.tenantCode,
                                                                                    spotID: x.spotID,
                                                                                    propertyCode: x.propertyCode,
                                                                                    errorMessage: x.errorMessage
                                                                                }
                                                                                return yardiSyncErrorModel;
                                                                            });

                                                                            if (errorData?.length > 0) {
                                                                                ShowBlocker(false);
                                                                                this.setState({
                                                                                    showSpinner: false,
                                                                                    isUploadComplete: false,
                                                                                    failedCSVRecords: errorData,
                                                                                    isInValidDataFormat: true
                                                                                });
                                                                            }
                                                                            else if (skipData?.length > 0) {
                                                                                ShowBlocker(false);
                                                                                this.setState({
                                                                                    showSpinner: false,
                                                                                    isUploadComplete: true,
                                                                                    failedCSVRecords: skipData,
                                                                                    isInValidDataFormat: false
                                                                                });
                                                                            }
                                                                            else {
                                                                                this.yardiSyncSuccess();
                                                                            }
                                                                        }
                                                                        else{
                                                                            this.yardiSyncError(syncError);
                                                                        }
                                                                    }
                                                                }, 10000);
                                                            });
                                                        }
                                                        else {
                                                            r.text().then(res => {
                                                                this.yardiSyncError(res);
                                                            }).catch(() => {
                                                                this.yardiSyncError("There was an error processing your request.");
                                                            });
                                                        }
                                                    }).catch(() => {
                                                        this.yardiSyncError("There was an error processing your request.");
                                                    });
                                            }
                                            else if (this.state.selectedIndex === 1 && this.state.lot !== null && this.state.lot.length > 0 && this.state.choosedProperty) {
                                                if (this.state.choosedUnits?.length === 0) {
                                                    ShowAlert("", 'Please select atleast one lot', "error");
                                                    return;
                                                }
                                                this.setState({
                                                    selectedIndex: 2
                                                });
                                            }
                                            else if (this.state.selectedIndex === 0 && !this.state.lot && this.state.choosedProperty) {
                                                let propertyGuid = this.state.choosedProperty.propertyGuid
                                                this._privateLotService.getYardiProperties(propertyGuid)
                                                    .then(response => {
                                                        if (response.ok) {
                                                            response.json().then(res => {
                                                                this.setState({
                                                                    lot: res,
                                                                    choosedUnits: this.returnOptionList(res),
                                                                    selectedIndex: 1
                                                                })
                                                            });
                                                        } else {
                                                            response.json().then(res => {
                                                                ShowAlert("Sync Error", `${res}`, "error");
                                                            });
                                                        }
                                                    })

                                            } else {
                                                ShowAlert("", 'Kindly complete step 1', "error");
                                                console.log("error")
                                            }
                                        }}>
                                        Confirm
                                    </Button>
                                    <Button style={{ width: '100px', height: '40px', backgroundColor: '#DADDDF', fontSize: "15px", fontFamily: 'Manrope', fontWeight: 700, borderRadius: '8px', color: '#6B7278', marginRight: '10px' }}
                                        onClick={() => {
                                            this.props.onCloseDrawerPanel();
                                        }}

                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </>)
                    }

                </div>
                {(this.state.isInValidDataFormat || this.state.isUploadComplete) && (
                    <div className="bulk-upload-blocker">
                        <div className="bulk-upload-popup">
                            <div className="d-flex flex-column">
                                {(this.state.isUploadComplete && !this.state.isInValidDataFormat) && (
                                    <>
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <div>
                                                <CheckCircle className="check-icon-size" />
                                            </div>
                                            <div className="bulk-upload-header mt-3">
                                                Upload Complete
                                            </div>
                                            <div className="mt-3 bulk-upload-sub-content">
                                                Bulk upload was successfully completed.
                                            </div>
                                            {(this.state.failedCSVRecords.length > 0) &&
                                                <div className="mt-3 bulk-upload-sub-content">
                                                    Click <CSVLink data={this.state.failedCSVRecords} filename={"rentable-data-not-uploaded.csv"}>here</CSVLink> to download the list of rentable data that were not uploaded.
                                                </div>
                                            }
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center mt-4">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className="ml-2 bul-upload-primary-btn"
                                                onClick={() => {
                                                    this.yardiSyncSuccess();
                                                }}
                                            >
                                                Ok
                                            </Button>
                                        </div>
                                    </>
                                )}
                                {(!this.state.isUploadComplete && this.state.isInValidDataFormat) && (
                                    <>
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <div>
                                                <Error className="error-icon-size" />
                                            </div>
                                            <div className="bulk-upload-header mt-3">
                                                Data Format Incorrect
                                            </div>
                                            <div className="mt-3 bulk-upload-sub-content">
                                                The custom rentable data provided is in the
                                            </div>
                                            <div className="mt-3 bulk-upload-sub-content">
                                                incorrect format. Please fix this and upload the
                                            </div>
                                            <div className="mt-3 bulk-upload-sub-content">
                                                CSV file again.
                                            </div>
                                            <div className="mt-3 bulk-upload-sub-content">
                                                Click <CSVLink data={this.state.failedCSVRecords} filename={"rentable-data-not-uploaded.csv"}>here</CSVLink> to download the list of rentable data that were not uploaded.
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center mt-4">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className="ml-2 bul-upload-primary-btn"
                                                onClick={this.onInValidHeaderOrDataFormatClose}
                                            >
                                                Close
                                            </Button>
                                        </div>

                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}
interface IYardiSyncState {
    selectedIndex: number,
    dataFormatterArray: any,
    tenantBuilding: IPropertyBasics | null,
    choosedProperty: IPrivateLotProperty | null,
    lot: YardiPropertyModel[] | null,
    showSpinner: boolean,
    showSyncSuccess: boolean,
    choosedUnits: string[],
    fileUrl?: string,
    fileName?: string,
    fileContent?: Blob,
    fileInfo?: File,
    isFileUpload: boolean,
    isInValidDataFormat: boolean,
    failedCSVRecords: IYardiSyncErrorModel[],
    isUploadComplete: boolean,
    syncResponse: IYardiSyncModel[] | null,
    syncError: string
}

interface IYardiSyncProps {
    properties: IPrivateLotProperty[];
    selectedProperty: IPrivateLotProperty | null;
    handelYardiAssign: (property_: IPrivateLotProperty | null) => void;
    onPropertyChange: (property_: IPrivateLotProperty[], floorValue?: IFloor | null) => void;
    onCloseDrawerPanel: (showSyncSuccess?: boolean, choosedProperty?: IPrivateLotProperty | null, onPropertyChange?: (property_: IPrivateLotProperty[], floorValue?: IFloor | null) => void) => void;
    updateSyncDate?: () => void;
}

interface YardiPropertyModel {
 code: string;
 marketingName: string;
 addressLine1: string;
 addressLine2: string;
 city: string;
 state: string;
 postalCode: string;
}
