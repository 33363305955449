import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import GeoHelper from '../Helpers/GeoHelper';
import PropertyService from '../Services/PropertyService';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

interface PlaceType {
  address_components: any;
  description: string;
  place_id: string;
  lat: number;
  lng: number;
  timeZoneId: string;
  timeZoneName: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      },
    ];
  };
}

interface IAddressDropdownProps {
  onAddressChange?: (selectedAddress: PlaceType | null) => void;
  address: string;
  city: string;
  required?: boolean;
  className?: string; 
}

export default function AddressDropdown(props: IAddressDropdownProps) {
  let _propertyService: PropertyService;
  const { onAddressChange, address, city, required, className } = props;

  let _oldAddress: PlaceType = {
    address_components: [],
    description: '',
    place_id: '',
    lat: 0,
    lng: 0,
    timeZoneId: '',
    timeZoneName: '',
    structured_formatting: {
      main_text: '',
      secondary_text: '',
      main_text_matched_substrings: [
        {
          offset: 0,
          length: 0
        },
      ]
    }
  }
  if (props.address) {
    if (props.address.indexOf(props.city) != -1) {
      _oldAddress.description = props.address;
    } else {
      _oldAddress.description = props.address + ', ' + props.city
    }
  }

  const classes = useStyles();
  const [value, setValue] = React.useState<PlaceType | null>(_oldAddress);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<PlaceType[]>([]);
  const loaded = React.useRef(false);
  const [isRequired, setIsRequired] = React.useState(props.required);

  _propertyService = new PropertyService();

  /*if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=' + apiKey + '&libraries=places',
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }*/

  const DropdwonFetch = React.useMemo(
    () =>
      throttle((request: { input: string, componentRestrictions: any }, callback: (results?: PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  const fromAddressToLatLng = (address: PlaceType) => {
    GeoHelper.getGeoLocationFromAddress(address.description)
      .then(loc_ => {
        if (loc_) {
          let newValue: PlaceType = address;
          newValue.lat = loc_.lat;
          newValue.lng = loc_.lng;
          newValue.address_components = loc_.address_components;

          /*let addressURL: string = 'https://maps.googleapis.com/maps/api/timezone/json?location=' + loc_.lat + ',' + loc_.lng + '&timestamp=1331161200&key=' + GeoHelper.apiKey;
          fetch(addressURL)
            .then(res => res.json())
            .then(r => {
              newValue.timeZoneId = r.timeZoneId;
              newValue.timeZoneName = r.timeZoneName;
              setValue(newValue);
              if (props.onAddressChange) {
                props.onAddressChange(newValue)
              }
            });*/

          let requestData: any = {
            "PropertyAddress": address.description,
            "Latitude": loc_.lat,
            "Longitude": loc_.lng
          }

          _propertyService.getTimeZone(requestData)
            .then(res => res.json())
            .then(r => {
              newValue.timeZoneId = r;
              newValue.timeZoneName = '';
              setValue(newValue);
              if (props.onAddressChange) {
                props.onAddressChange(newValue)
              }
            });
        }
        else {
          console.error("No response from geocode API")
        }
      });
  }

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    DropdwonFetch({ input: inputValue, componentRestrictions: { country: 'ca' } }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, DropdwonFetch]);

  return (
    <Autocomplete
      id="google-map-address"
      className={props.className ? props.className : "MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      style={{ minWidth: 250 }}
      onBlur={() => {
        if (props.address == '') {
          setValue(null);
        }
      }}
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        /*console.log(newValue);
        setValue(newValue);
        if (props.onAddressChange) {
          props.onAddressChange(newValue)
        }*/
        if (newValue) {
          fromAddressToLatLng(newValue);
        } else {
          setValue(newValue);
          if (props.onAddressChange) {
            props.onAddressChange(newValue)
          }
        }


      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Address"
          required={isRequired}
          error={
            isRequired == true && value?.description == '' || value == null
          }
          variant="outlined" fullWidth />
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}