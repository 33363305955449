export default class DOMHelper {
    public static getCumulativeScrollTop(element_: HTMLElement | null): number {
        let position = 0;

        while (element_) {
            position += element_.scrollTop;
            element_ = (element_ as HTMLElement).parentElement;
        }

        return position;
    }
}