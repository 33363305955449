import React from 'react';
import './Dashboard.css'
import { Checkbox, Chip, Grid, TextField } from '@material-ui/core';

import { Bar } from 'react-chartjs-2';
import { IManagerPropertyDashboardInformationModel, IChartData, IPropertyItem, widgetOptions, ISpotItem } from './Interface';
import LoaderComponent from '../../Components/LoaderComponent';
import { ShowAlert } from '../../Common/ShowAlert';
import { Autocomplete } from '@material-ui/lab';
import TopWidgets from './TopWidgets';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import vehicle from '../../Images/vehicle.png';
import UpcomingBookings from './UpcomingBookings';
import { UserDetailsModel } from '../../Models/UserDetails';
import SpotService from '../../Services/SpotService';
import SpotUpcomingBookings from './SpotUpcomingBookings';

class TenantDashboardCharts extends React.Component<DashboardChartsProps, DashboardChartsState> {
    private _spotService: SpotService;
    constructor(props: DashboardChartsProps) {
        super(props);

        this.state = {
            showBlocker: false,
            widgetList: [
                {
                    title: "Total Spot Revenue",
                    api: "TenantDashboardTotalRevenueV3",
                    isGreenGg: true,
                    serviceEndpoint: 'spot',
                    isTenant: true
                },
                {
                    title: "Earnings",
                    api: "",
                    isComingSoon: true,
                    serviceEndpoint: 'spot',
                    isTenant: true,
                    Ignorefilter: true
                }
            ],
            propertyOption: 'YTD',
            spots: [],
            selectedSpots: [],
            selectedSpotsGuids: []
        }

        this._spotService = new SpotService();
    }

    componentWillMount() {
        var _localData = localStorage.getItem('userdata');

        if (_localData != null && _localData !== '') {
            var _data = JSON.parse(_localData);

            let _userDetails: UserDetailsModel = _data;

            this.setState({
                userDetails: _userDetails,
            }, () => {
                this._loadSpots();
            });
        }
    }

    private _loadSpots() {
        this._spotService.getTenantSpots()
            .then(data => {
                if (data.ok) {
                    data.json().then(d => {
                        let _mappedSpots = d.map((r: any) => {
                            return r;
                        });

                        let _allSpotsLable: ISpotItem = {
                            spotGuid: 'all_items',
                            friendlySpotGuid: 'All Spots'
                        }

                        if (_mappedSpots.length > 0) {
                            _mappedSpots.unshift(_allSpotsLable);
                        }

                        this.setState({
                            spots: _mappedSpots
                        });
                    })
                }
            })
            .catch((error) => {
                console.error('Error:', JSON.stringify(error));
            });
    }

    public render() {
        return (
            <div>
                {/* <LoaderComponent loaderVisible={this.state.showBlocker} /> */}
                <div className="dashboard-widget-container">
                    <Grid container>
                        <Grid item xs></Grid>
                        <Grid item>
                            <Autocomplete
                                multiple
                                id="listPropertyCities"
                                className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth listPropertyCities"
                                options={this.state.spots}
                                disableCloseOnSelect
                                size="small"
                                value={this.state.selectedSpots}
                                onChange={(event: any, values: any) => {
                                    let values_: string[] = values.map((spot: any) => {
                                        return spot.spotGuid;
                                    });

                                    let stateValues_: string[] = this.state.selectedSpots.map((spot: any) => {
                                        return spot.spotGuid;
                                    });

                                    let allValues_: string[] = this.state.spots.map((spot: any) => {
                                        return spot.spotGuid;
                                    });

                                    let selectedItems: ISpotItem[] = [];
                                    if (values_.indexOf('all_items') != -1 && stateValues_.indexOf('all_items') == -1) {
                                        selectedItems = this.state.spots;
                                    } else if (values_.indexOf('all_items') == -1 && stateValues_.indexOf('all_items') != -1) {
                                        selectedItems = [];
                                    } else if (values_.indexOf('all_items') != -1 && values_.length != allValues_.length) {
                                        let index = values_.indexOf('all_items');
                                        values.splice(index, 1);
                                        selectedItems = values;
                                    } else if (values_.indexOf('all_items') == -1 && values_.length == allValues_.length - 1) {
                                        selectedItems = this.state.spots;
                                    } else {
                                        selectedItems = values;
                                    }

                                    let selectedGuids: string[] = selectedItems.map((spot: any) => {
                                        return spot.spotGuid;
                                    });

                                    this.setState({
                                        selectedSpots: selectedItems,
                                        selectedSpotsGuids: selectedGuids
                                    })
                                }}
                                renderTags={(tagValue, getTagProps) => {
                                    return tagValue.map((option, index) => (
                                        <Chip
                                            key={'lable' + index}
                                            label={option.friendlySpotGuid}
                                            {...getTagProps({ index })}
                                            className={option.spotGuid.indexOf('all_items') != -1 ? 'hide-chip' : ''}
                                        />
                                    ));
                                }}
                                limitTags={2}
                                openOnFocus={true}
                                selectOnFocus={true}
                                getOptionLabel={(option) => option.friendlySpotGuid}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                            color="primary"
                                        />
                                        {option.friendlySpotGuid}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => <TextField {...params} label="Spots" variant="outlined" />}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs>
                            <div className={'dashboard-widget selected non-admin-board'}>
                                <Grid container>
                                    <Grid item xs>
                                        <div className="pm-name">Hi {this.state.userDetails?.fullName}.</div>
                                        <div className="dashbaord-welcome">Welcome to your GrydPark Dashboard!</div>
                                    </Grid>
                                    <Grid item>
                                        <div className="vehicle-img-container">
                                            <img src={vehicle} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        {
                            this.state.widgetList.map((value_, index_) => (
                                <Grid item key={index_}>
                                    <TopWidgets
                                        widgetInfo={value_}
                                        spots={this.state.selectedSpotsGuids}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                    <Grid container>
                        <Grid item xs>
                            <div className="dashboard-widget-chart grid-white-bg">
                                <h5>Upcoming Bookings</h5>
                                <div style={{ height: '300px' }}>
                                    <SpotUpcomingBookings
                                        selectedSpots={this.state.selectedSpots}
                                    />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                {/* <img src={dashboardImg} width="100%" /> */}
            </div>
        )
    }

    private accessTokenExpired() {
        localStorage.clear();
        window.location.href = '/';
    }
}

interface DashboardChartsProps { }
interface DashboardChartsState {
    managerPropertyDashboardInformation?: IManagerPropertyDashboardInformationModel;
    showBlocker: boolean;
    widgetList: widgetOptions[];
    propertyOption: string;
    userDetails?: UserDetailsModel;
    spots: ISpotItem[];
    selectedSpots: ISpotItem[];
    selectedSpotsGuids: string[];
}

export default TenantDashboardCharts;