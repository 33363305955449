import BaseService from "./BaseService";
import {ConfigService} from './ConfigService';

export default class EnforcementService extends BaseService {
  public GetEnforcementList() {
    return this.GetEnforcementService("spot/parker-enforcement/enforcementlist");
  }

  public GetEnforcementSearch() {
    return this.GetEnforcementService("spot/parker-enforcement/search");
  }

  public GetScanHistories() {
    return this.GetEnforcementService(`spot/parker-enforcement/scan-histories`);
  }

  public GetScanHistory(propertyGuid: string) {
    return this.GetEnforcementService(`spot/parker-enforcement/scan-history/${propertyGuid}`);
  }

  public GetPropertyUtilization(propertyGuid: string, mode: string) {
    return this.GetEnforcementService(`spot/parker-enforcement/get-utilization/${propertyGuid}/${mode}`);
  }

  public GetPropertyParkingStatuses() {
    return this.GetEnforcementService(`spot/parker-enforcement/parking-statuses`);
  }

  public GetPropertyParkingStatus(propertyGuid: string) {
    return this.GetEnforcementService(`spot/parker-enforcement/get-parking-status/${propertyGuid}`);
  }

  public GetLatestScanResult(propertyGuid: string, dateFormat: string) {
    return this.PostEnforcementService(`spot/parker-enforcement/get-latest-scan`, {propertyGuid, dateFormat});
  }

  public GetScanHistoryDetails(propertyGuid: string, licensePlate: string) {
    return this.PostEnforcementService(`spot/parker-enforcement/get-history`, {
      PropertyGuid: propertyGuid,
      LicensePlate: licensePlate
    });
  }

  public GetEnforcementNotification() {
    return this.GetEnforcementService(`spot/parker-enforcement/notification`)
      .then(x => {
        if (x.ok) return x.json()
      });
  }

  private GetEnforcementService(url: string) {
    return ConfigService.getBaseUrl()
      .then(baseUrl => {
        return BaseService.sendRequest(`${baseUrl}${url}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
      });
  }

  private PostEnforcementService(url: string, postData: any) {
    return ConfigService.getBaseUrl()
      .then(baseUrl => {
        return BaseService.sendRequest(`${baseUrl}${url}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        });
      });
  }
}
