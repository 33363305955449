import React from "react";
import BaseFilterView, { IBaseFilterViewProps, IBaseFilterViewState } from "../PartialViews/BaseFilterView";
import { IPropertyFilter, IProperty } from "./Interfaces";
import SelectFilter from "../../Components/FilterComponents/SelectFilter";
import { DataGridFilterType } from "../../Components/DataGrid/Interfaces";
import CheckboxListFilter from "../../Components/FilterComponents/CheckboxListFilter";
import RadioListFilter from "../../Components/FilterComponents/RadioListFilter";
import DateRangeFilter from "../../Components/FilterComponents/DateRangeFilter";

export default class PropertyFilterView
    extends BaseFilterView<IBaseFilterViewProps<IProperty, IPropertyFilter>, IBaseFilterViewState<IPropertyFilter>> {
    private _allCities: string[] = [];
    private _allCompany: string[] = [];
    private _allRevenuRange: string[] = [];
    private _date: string = '';
    private _allSlotsCorrelations: string[] = [];

    constructor(props_: IBaseFilterViewProps<IProperty, IPropertyFilter>) {
        super(props_, {
            property: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
            city: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
            owner: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
            manager: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
            company: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
            listingType: {
                type: DataGridFilterType.String,
                filterValue: ""
            },
            reservation: {
                type: DataGridFilterType.DateRange,
                filterValue: {
                    start: undefined,
                    end: undefined
                }
            },
            revenue: {
                type: DataGridFilterType.RevenueRange,
                filterValue: {
                    start: undefined,
                    end: undefined
                }
            },
            slotsCorrelations: {
                type: DataGridFilterType.String,
                filterValue: ""
            }
        } as IPropertyFilter);

        for (let property of props_.data) {
            if (this._allCities.indexOf(property.propertyCity) == -1) {
                this._allCities.push(property.propertyCity);
            }
            if (this._allCompany.indexOf(property.propertyCompanyName) == -1) {
                this._allCompany.push(property.propertyCompanyName);
            }
        }

        this._allRevenuRange = [
            'Less than $50,000',
            'More than $50,000',
            'More than $100,000',
            'Custom Range'
        ];

        this._allSlotsCorrelations = [
            'Empty',
            'Full',
            'Less than 50%',
            'More than 50%',
            'Less than 10%',
            'More than 10%'
        ]

    }

    protected renderFilterOptions = () => {
        return (
            <div className="filter-options-list">
                <CheckboxListFilter
                    title="City"
                    placeholderText="Search by city name"
                    items={this._allCities}
                    selectedItems={this.state.appliedFilter.city?.filterValue as string[]}
                    onChange={selection_ => {
                        if (this.state.appliedFilter.city) {
                            this.state.appliedFilter.city.filterValue = selection_;
                            this.setState({
                                appliedFilter: this.state.appliedFilter
                            });
                        }
                    }}
                />
                <SelectFilter
                    title="Manage Company"
                    placeholderText="Search by management company name"
                    items={this._allCompany}
                    selectedItems={this.state.appliedFilter.company?.filterValue as string[]}
                    onChange={selection_ => {
                        if (this.state.appliedFilter.company) {
                            this.state.appliedFilter.company.filterValue = selection_;
                            this.setState({
                                appliedFilter: this.state.appliedFilter
                            });
                        }
                    }}
                />
                <RadioListFilter
                    title="Revenue Range"
                    placeholderText="Search by revenue range"
                    items={this._allRevenuRange}
                    selectedItem={this.state.appliedFilter.revenue?.filterValue as string}
                    applySort={false}
                    onChange={selection_ => {
                        if (this.state.appliedFilter.revenue) {
                            this.state.appliedFilter.revenue.filterValue = selection_;
                            this.setState({
                                appliedFilter: this.state.appliedFilter
                            });
                        }
                    }}
                />

                {/* <DateRangeFilter
                    title="Date"
                    placeholderText="Date"
                    onChange={selection_ => {
                        console.log(selection_);
                    }}
                /> */}

                <RadioListFilter
                    title="Slot Correlation"
                    placeholderText="Slot correlation"
                    items={this._allSlotsCorrelations}
                    selectedItem={this.state.appliedFilter.slotsCorrelations?.filterValue as string}
                    applySort={false}
                    onChange={selection_ => {
                        if (this.state.appliedFilter.slotsCorrelations) {
                            this.state.appliedFilter.slotsCorrelations.filterValue = selection_;
                            this.setState({
                                appliedFilter: this.state.appliedFilter
                            });
                        }
                    }}
                />

            </div>
        );
    }

    protected filterItems = () => {
        this._isFiltered = false;
        return this.props.data.filter(s => {
            let isValid = true;
            let filteredCities = this.state.appliedFilter.city.filterValue as string[];
            let FilterCompanies = this.state.appliedFilter.company.filterValue as string[];

            if (filteredCities.length > 0 && isValid) {
                this._isFiltered = true;
                isValid = filteredCities.indexOf(s.propertyCity) > -1;
            }

            if (FilterCompanies.length > 0 && isValid) {
                this._isFiltered = true;
                isValid = FilterCompanies.indexOf(s.propertyCompanyName) > -1;
            }

            return isValid;
        });
    }
}