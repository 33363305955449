import React from "react";
import { DataGridFilterType, IDateRange, INumberRange } from "../../Components/DataGrid/Interfaces";
import DataGridToolbar from "../../Components/DataGrid/DataGridToolbar";
import { Grid, Drawer, Button, IconButton, Tooltip, Tabs, Tab } from "@material-ui/core";
import SpotDetailView from "./SpotDetailView";
import SpotFilterView from "./SpotFilterView";
import SpotEditView from "./SpotEditView";
import { ISpot, ISpotFilter, IPropertyBasics } from "./Interfaces";
import { RightPanelState } from "../../Models/CommonInterfaces";
import SpotService from "../../Services/SpotService";
import { GetUserDetailsService } from "../../Services/GetUserDetailsService";
import PropertyService from "../../Services/PropertyService";
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import "./Spots.css";
import { Link } from "react-router-dom";
import { ShowAlert, ShowAlertwithConfirm, ShowAlertwithMultipleConfirm, ShowAlertwithOptions } from "../../Common/ShowAlert";
import LoaderComponent from "../../Components/LoaderComponent";
import ObjectHelper from "../../Helpers/ObjectHelper";
import { PreserveFilters } from "../../Common/PreserveFilters";
import { CheckFeaturePermission, CheckScreenPermission } from "../../Common/CheckScreenPermission";
import PrivateLotService from "../../Services/PrivateLotService";

export default abstract class BaseSpotList extends React.Component<ISpotListProps, ISpotListState> {
    protected _isBcUser: boolean;
    protected _isTenantUser: boolean;
    protected _isAdmin: boolean;
    protected _spotService: SpotService;
    protected _privateLotService: PrivateLotService;
    protected _propertyService: PropertyService;
    protected _renderDataGrid = (): JSX.Element | null => { return null; }
    protected abstract _getSpots: () => Promise<ISpot[]>;
    protected abstract _getAllSpots: (contentParser_: (spots_: ISpot[]) => ISpot[], onProgress_: (spots_: ISpot[], progress_: number) => void) => Promise<ISpot[]>;
    protected _hideToolbar: boolean = false;
    _isFiltered: boolean = false;
    constructor(props_: ISpotListProps) {
        super(props_);

        let spotsList: ISpot[] = [];
        this._isTenantUser = GetUserDetailsService.getUserDetails().userRoleID[0] == 6;
        this._isBcUser = GetUserDetailsService.getUserDetails().userRoleID[0] == 8;
        this._isAdmin = ((GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) != -1) || (GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) != -1));
        this.state = {
            isSpotsLoaded: false,
            allSpots: spotsList,
            filteredSpots: spotsList.slice(0),
            subFilteredSpots: spotsList.slice(0),
            searchTerm: "",
            rightPanelState: RightPanelState.None,
            isFiltered: false,
            _autoApplyFilterKey: 0,
            selectedTabIndex: props_.selectedTabIndex,
            isScreenAccessible: false
        };

        this._spotService = new SpotService();
        this._propertyService = new PropertyService();
        this._privateLotService = new PrivateLotService();
    }

    async componentDidMount() {
        var isAccessible = await CheckScreenPermission("user-interface-spots");

        this.setState({
            isScreenAccessible: isAccessible
        }, () => {
            if (this.state.isScreenAccessible) {
                this._loadSpots();
            }
        });
    }
    componentWillUnmount() {
        PreserveFilters.setSearchTerm('spotSearch', this.state.searchTerm)
    }
    protected _loadSpots() {
        let filterPropertyName: string = "";
        let filterKey: number = 0;
        let propertyToFilter: ISpotFilter = {
            property: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
            city: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
            owner: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
            manager: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
            tenants: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
            reservation: {
                type: DataGridFilterType.DateRange,
                filterValue: {
                    start: undefined,
                    end: undefined
                }
            },
            revenue: {
                type: DataGridFilterType.RevenueRange,
                filterValue: {
                    start: undefined,
                    end: undefined,
                    optionalTag: ""
                }
            },
            spotType: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
            claimStatus: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
        }

        let isBusy = false;

        let _l = () => {

            let searchTerm = PreserveFilters.getSearchTerm('spotSearch');

            this._getAllSpots(
                (spots_: ISpot[]) => {
                    return spots_.map((e: any) => {
                        if (e.reservationDates) {
                            e.reservationDates = e.reservationDates.map((d: Date) => new Date(d));
                        }
                        else {
                            e.reservationDates = [];
                        }
                        e.revenue = e.revenve;

                        // Added to avoid sorting issue
                        e.ownedBy = (e.spotTypeId == 2) ? 'Tenant' : 'Property';
                        e.spotTenantName = e.spotTenantName ? e.spotTenantName : '';

                        let sortedDates = ObjectHelper.deepClone(e.reservationDates).sort((a: Date, b: Date) => b.getTime() - a.getTime());

                        let pastReservationDate: Date = sortedDates.filter((r: any) => r < new Date())[0];
                        e.lastReservation = pastReservationDate ? pastReservationDate : null;

                        let upcomingReservationDate: Date = sortedDates.reverse().filter((r: any) => r > new Date())[0];
                        e.nextReservation = upcomingReservationDate ? upcomingReservationDate : null;

                        return e;
                    });
                },
                (spots_: ISpot[], progress_: number) => {
                    if (!isBusy) {
                        isBusy = true;

                        this.setState({
                            allSpots: spots_,
                            filteredSpots: spots_.slice(0),
                            subFilteredSpots: searchTerm ? this._filterDataBySearch(spots_, searchTerm) : spots_.slice(0),
                            appliedFilter: filterKey != 0 ? propertyToFilter : undefined,
                            searchTerm: searchTerm ? searchTerm : '',
                            isSpotsLoaded: true,
                            loaderPercentage: progress_ * 100
                        }, () => {
                            this.setState({
                                _autoApplyFilterKey: filterKey,
                                appliedFilter: filterKey == 0 ? PreserveFilters.readPreservedFilter('spots') : this.state.appliedFilter
                            }, () => {

                                isBusy = false;

                                if (this.state.appliedFilter) {
                                    this.filterItems()
                                }
                                // else if(window.localStorage.getItem('spotSearch')){
                                //    this.setState({subFilteredSpots:this._filterDataBySearch(this.state.subFilteredSpots,this.state.searchTerm)}) 
                                // }
                                setTimeout(() => {
                                    if (this.props.requestType == 'add') {
                                        this.setState({
                                            rightPanelState: RightPanelState.Add,
                                            hasUnsavedChanges: false
                                        });
                                    }
                                }, 100);
                            })
                        });
                    }
                }).then(spots_ => {

                    this.setState({
                        allSpots: spots_,
                        filteredSpots: spots_.slice(0),
                        subFilteredSpots: searchTerm ? this._filterDataBySearch(spots_, searchTerm) : spots_.slice(0),
                        appliedFilter: filterKey != 0 ? propertyToFilter : undefined,
                        searchTerm: searchTerm ? searchTerm : '',
                        isSpotsLoaded: true,
                        loaderPercentage: 100
                    }, () => {
                        this.setState({
                            _autoApplyFilterKey: filterKey,
                            appliedFilter: filterKey == 0 ? PreserveFilters.readPreservedFilter('spots') : this.state.appliedFilter,
                        }, () => {

                            isBusy = false;

                            if (this.state.appliedFilter) {
                                this.filterItems()
                            }
                            // else if(window.localStorage.getItem('spotSearch')){
                            //    this.setState({subFilteredSpots:this._filterDataBySearch(this.state.subFilteredSpots,this.state.searchTerm)}) 
                            // }
                            setTimeout(() => {
                                if (this.props.requestType == 'add') {
                                    this.setState({
                                        rightPanelState: RightPanelState.Add,
                                        hasUnsavedChanges: false,
                                        loaderPercentage: 0
                                    });
                                }
                                else {
                                    this.setState({
                                        loaderPercentage: 0
                                    });
                                }
                            }, 100);
                        })
                    });
                });
        }

        if (this.props.requestType == 'view' || this.props.requestType == 'add') {
            this._propertyService.getPropertiesBasics()
                .then(p => p.json())
                .then((p: IPropertyBasics[]) => {
                    for (let property of p) {
                        if (property.propertyGuid == this.props.propertyGuid) {
                            filterPropertyName = property.propertyName;
                            propertyToFilter.property.filterValue = [filterPropertyName];
                            filterKey = Math.random();
                        }
                    }

                    _l();
                });
        }
        else if (this.props.source == "pending") {
            propertyToFilter.claimStatus.filterValue = ["Waiting for approval"];
            filterKey = Math.random();
            _l();
        }
        else {
            _l();
        }
    }


    protected filterItems = () => {
        this._isFiltered = false;

        let filteredArray = this.state.allSpots.filter(s => {
            let isValid = true;
            if (this.state.appliedFilter && Object.keys(this.state.appliedFilter).length > 1) {
                let filteredProperties = this.state.appliedFilter?.property.filterValue as string[];
                let filteredCities = this.state.appliedFilter?.city.filterValue as string[];
                let filteredOwners = this.state.appliedFilter?.owner.filterValue as string[];
                let filteredManagers = this.state.appliedFilter?.manager.filterValue as string[];
                let filteredTenants = this.state.appliedFilter?.tenants.filterValue as string;
                let filteredReservationDate = this.state.appliedFilter?.reservation.filterValue as IDateRange;
                let filteredRevenue = this.state.appliedFilter?.revenue.filterValue as INumberRange;
                let filteredSpotType = this.state.appliedFilter?.spotType.filterValue as string[];
                let filteredClaimStatus = this.state.appliedFilter?.claimStatus.filterValue as string[];

                if (filteredProperties && filteredProperties.length > 0 && isValid) {
                    this._isFiltered = true;
                    isValid = filteredProperties.indexOf(s.propertyName) > -1;
                }
                if (filteredCities && filteredCities.length > 0 && isValid) {
                    this._isFiltered = true;
                    isValid = filteredCities.indexOf(s.propertyCity) > -1;
                }
                if (filteredOwners && filteredOwners.length > 0 && isValid) {
                    this._isFiltered = true;
                    isValid = filteredOwners.indexOf(s.propertyOwnerName) > -1;
                }
                if (filteredManagers && filteredManagers.length > 0 && isValid) {
                    this._isFiltered = true;
                    isValid = filteredManagers.indexOf(s.propertyCompanyName) > -1;
                }
                if (filteredTenants && filteredTenants.length > 0 && isValid) {
                    this._isFiltered = true;
                    isValid = filteredTenants.indexOf(s.spotTenantName) > -1;
                }
                if (filteredReservationDate && (filteredReservationDate.start || filteredReservationDate.end) && isValid) {
                    this._isFiltered = true;
                    isValid = false;

                    for (let reservedDate of s.reservationDates) {
                        if (filteredReservationDate.start && filteredReservationDate.end) {
                            isValid = (reservedDate >= filteredReservationDate.start && reservedDate <= filteredReservationDate.end);
                        }
                        else if (filteredReservationDate.start) {
                            isValid = (reservedDate >= filteredReservationDate.start);
                        }
                        else if (filteredReservationDate.end) {
                            isValid = (reservedDate <= filteredReservationDate.end);
                        }
                        if (isValid) {
                            break;
                        }
                    }
                }
                if (filteredRevenue && (filteredRevenue.start || filteredRevenue.end) && isValid) {
                    this._isFiltered = true;
                    if (filteredRevenue.start && filteredRevenue.end) {
                        isValid = (s.revenue >= filteredRevenue.start && s.revenue <= filteredRevenue.end);
                    }
                    else if (filteredRevenue.start) {
                        isValid = (s.revenue >= filteredRevenue.start);
                    }
                    else if (filteredRevenue.end) {
                        isValid = (s.revenue <= filteredRevenue.end);
                    }
                }
                if (filteredSpotType && filteredSpotType.length > 0 && isValid) {
                    this._isFiltered = true;
                    isValid = filteredSpotType.indexOf(s.spotTypeId == 2 ? "Tenant" : "Property") > -1;
                }
                if (filteredClaimStatus && filteredClaimStatus.length > 0 && isValid) {
                    this._isFiltered = true;
                    if (s.spotTypeId == 2) {
                        isValid = filteredClaimStatus.indexOf(s.claimStatusId == 3 ? "Claimed" : ((s.claimStatusId == 2 || s.spotTenantGuid) ? "Waiting for approval" : "Unclaimed")) > -1;
                    }
                    else {
                        isValid = false;
                    }
                }
            }

            return isValid;
        });
        this.setState({ subFilteredSpots: this._filterDataBySearch(filteredArray, this.state.searchTerm) })

    }

    render() {
        if (!this.state.isSpotsLoaded || !this.state.isScreenAccessible) {
            // return <LoaderComponent loaderVisible={true} />;
            return null;
        }

        return (
            <div className="mx-3 data-grid-container without-checkbox-container clickable-rows">
                {/* Header region of the page */}
                <Grid container className="mb-3">
                    <Grid item>
                        {/* Title of the page */}
                        <h1 className="list-header">Spots</h1>
                    </Grid>
                    <Grid item xs>
                        {/* Grid toolbar */}
                        {
                            (!this._hideToolbar) &&
                            <DataGridToolbar
                                search={PreserveFilters.getSearchTerm('spotSearch')}
                                singularEntityName="spot"
                                pluralEntityName="spots"
                                onSearch={this._searchTermChange}
                                isFiltered={this.state.isFiltered}
                                onFilterClick={() => {
                                    this.setState({
                                        rightPanelState: RightPanelState.Filter,
                                        hasUnsavedChanges: false
                                    });
                                }}
                                // isNoAddButton={this._isTenantUser}
                                isNoAddButton={!CheckFeaturePermission('"add-edit-spot"')}
                                onAddClick={() => {
                                    if (this._isTenantUser) {
                                        this.setState({
                                            isClaimBoxOpen: true
                                        });
                                    }
                                    else {
                                        this.setState({
                                            rightPanelState: RightPanelState.Add,
                                            hasUnsavedChanges: false
                                        });
                                    }
                                }}
                            />
                        }
                    </Grid>
                </Grid>

                {/* Data Grid */}
                {
                    this._isTenantUser ?
                        <div className="vertical-fill">
                            <Tabs
                                value={this.state.selectedTabIndex}
                                onChange={(e_: any, tabIndex_: number) => {
                                    this.props.onTabChange(tabIndex_);
                                    // this.setState({
                                    //     selectedTabIndex: tabIndex_
                                    // });
                                }}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="standard"
                                aria-label="full width tabs example"
                            >
                                <Tab label="My Spots" />
                                <Tab label="Unclaimed Spots" />
                            </Tabs>
                            {
                                this._renderDataGrid()
                            }
                        </div>
                        : this._renderDataGrid()
                }

                {/* Right side drawer */}
                <Drawer
                    anchor="right"
                    variant={this.state.rightPanelState == RightPanelState.None ? "persistent" : "temporary"}
                    open={this.state.rightPanelState != RightPanelState.None}
                    onClose={this._onCancelAddEditPanel}
                >
                    <div className="mx-3">
                        <Grid container className="right-panel-header p-2 border-bottom">
                            <Grid item xs>
                                <h4 className="right-panel-title m-0">
                                    {this._getRightPanelTitle()}
                                </h4>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={this._onCancelAddEditPanel}>
                                    <CloseIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    {this._getRightPanelContent()}
                </Drawer>
            </div>
        );
    }

    protected _inActiveSpot = (guids_: string[], spots_: ISpot[]): void => {
        this.updateSpotStatus(guids_, spots_, 2);
    }

    protected _deleteSpot = (spot_: ISpot): void => {
        let zoneData = {
            "propertyGuid": spot_.propertyGuid,
            "levelId": 0
        } as any;

        this._privateLotService.CheckMapStatus(zoneData).then(r => r.json())
            .then(r => {
                ShowAlertwithConfirm("Delete Spot?", `<div class="gryd-custom-popup-body">
                            Are you sure you want to delete this spot?<br/>This action cannot be undone.<br/>
                            ${r['mapStatus'] != 0 ? "This property has a lot management map which will be deleted as well." : ""}<br/><br/>
                            <b>${spot_.friendlySpotId}</b><br/>
                            <b>${spot_.propertyName}</b><br/>
                            </div>`, undefined, 'Delete Spot', 'Cancel', 'gryd-custom-popup')
                    .then(r_ => {
                        if (r_) {
                            if (r['mapStatus'] != 0) {
                                var keys = Object.keys(localStorage).filter(x => x.indexOf(spot_.propertyGuid) != -1);

                                keys.forEach(asset_property => {
                                    localStorage.removeItem(asset_property);
                                });
                            }

                            this.updateSpotStatus([spot_.spotGuid], [spot_], 3, true);
                        }
                    });
            });
    }

    protected _approveTenant = (spot_: ISpot): void => {
        this._spotService.approveClaim(spot_.spotGuid)
            .then(r => {
                if (r.ok) {
                    spot_.claimStatusId = 3;
                    this.setState({
                        allSpots: this.state.allSpots
                    });
                }
            });
    }

    protected _rejectTenant = (spot_: ISpot): void => {
        this._spotService.rejectClaim(spot_.spotGuid)
            .then(r => {
                if (r.ok) {
                    spot_.claimStatusId = 1;
                    spot_.spotTenantGuid = "";
                    spot_.spotTenantName = "";
                    spot_.spotStatusId = 2;
                    this.setState({
                        allSpots: this.state.allSpots
                    });
                }
            });
    }

    protected _getRightPanelTitle(): JSX.Element | null {
        switch (this.state.rightPanelState) {
            case RightPanelState.Filter:
                return <span>Filter</span>;
            case RightPanelState.Details:
                if (this.state.selectedSpot) {
                    return <div>
                        <span>Spot #: {this.state.selectedSpot.friendlySpotId}</span>&nbsp;
                        {
                            (!this._isTenantUser && !this._isBcUser) && <Tooltip title="View Schedule">
                                <Link to={`/Calendar/spot/${this.state.selectedSpot.propertyGuid}/${this.state.selectedSpot.spotGuid}`} className="view-spots">
                                    <IconButton>
                                        <CalendarTodayIcon />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        }
                        {
                            //(!this._isTenantUser || this.state.selectedSpot.claimStatusId == 3)
                            (CheckFeaturePermission('"add-edit-spot"') || this.state.selectedSpot.claimStatusId == 3) &&
                            <Tooltip title="Edit">
                                <IconButton onClick={() => {
                                    this.setState({
                                        rightPanelState: RightPanelState.Edit
                                    });
                                }}><EditIcon /></IconButton>
                            </Tooltip>
                        }
                    </div>;
                }
            case RightPanelState.Edit:
                if (this.state.selectedSpot) {
                    return <span>Spot #: {this.state.selectedSpot.friendlySpotId}</span>;
                }
                return <span>Edit Spot</span>;
            case RightPanelState.Add:
                return <span>Add Spots</span>;
        }

        return null;
    }

    protected _onCancelAddEditPanel = () => {
        if (this.state.hasUnsavedChanges) {
            ShowAlertwithConfirm("You have unsaved changes!", "Are you sure you want to close this window?", "warning")
                .then(r_ => {
                    if (r_) {
                        this.setState({
                            rightPanelState: RightPanelState.None,
                            hasUnsavedChanges: false
                        });
                    }
                })
        }
        else {
            this.setState({
                rightPanelState: RightPanelState.None,
                hasUnsavedChanges: false
            });
        }
    }

    protected _getRightPanelContent() {
        let rightPanelViews = [
            <SpotFilterView
                isVisible={this.state.rightPanelState == RightPanelState.Filter}
                data={this.state.allSpots}
                appliedFilter={this.state.appliedFilter}
                onFilterChange={(appliedFilter_, filteredData_, isFiltered_) => {
                    this.setState({
                        appliedFilter: appliedFilter_,
                        filteredSpots: filteredData_,
                        subFilteredSpots: this._filterDataBySearch(filteredData_, this.state.searchTerm),
                        isFiltered: isFiltered_
                    }, () => {
                        PreserveFilters.preserveFilter('spots', this.state.appliedFilter)
                    })
                }}
                onClose={() => this.setState({ rightPanelState: RightPanelState.None })}
                autoApplyFilterKey={this.state._autoApplyFilterKey}
            />
        ];

        switch (this.state.rightPanelState) {
            case RightPanelState.Details:
                if (this.state.selectedSpot) {
                    rightPanelViews.push(
                        <SpotDetailView
                            spot={this.state.selectedSpot}
                            onEdit={(spot_: ISpot) => {
                                this.setState({
                                    selectedSpot: spot_,
                                    rightPanelState: RightPanelState.Edit
                                });
                            }}
                            onUpdated={() => {
                                this.setState({
                                    subFilteredSpots: this.state.subFilteredSpots
                                });
                            }}
                            onApprove={this._approveTenant}
                            onReject={this._rejectTenant}
                            onInactiveSpot={this._inActiveSpot}
                        />);
                }
                break;
            case RightPanelState.Add:
                rightPanelViews.push(<SpotEditView
                    propertyGuid={this.props.requestType == 'add' ? this.props.propertyGuid : ''}
                    onClose={this._handleAddEditPanelClose}
                    onChange={hasUnSavedChanges_ => this.setState({ hasUnsavedChanges: hasUnSavedChanges_ })}
                />);
                break;
            case RightPanelState.Edit:
                if (this.state.selectedSpot) {
                    rightPanelViews.push(<SpotEditView
                        spot={this.state.selectedSpot}
                        onClose={this._handleAddEditPanelClose}
                        onChange={hasUnSavedChanges_ => this.setState({ hasUnsavedChanges: hasUnSavedChanges_ })}
                    />);
                }
                break;
        }

        return rightPanelViews;
    }

    protected _handleAddEditPanelClose = (requiresUpdate_: boolean, spots_: ISpot[], updateSpot?: any) => {
        console.log(requiresUpdate_, spots_);
        if (requiresUpdate_ && spots_.length > 0) {
            let spots = this.state.allSpots.concat(spots_);
            let filteredSpots = this.state.filteredSpots.concat(spots_);
            let subFilteredSpots = this.state.subFilteredSpots.concat(spots_);

            this.setState({
                rightPanelState: RightPanelState.None,
                allSpots: spots,
                filteredSpots: filteredSpots,
                subFilteredSpots: subFilteredSpots,
                hasUnsavedChanges: false
            });
        }
        else if (requiresUpdate_) {
            if (updateSpot?.length > 0) {
                let spots = this.state.allSpots.filter(spot => spot.spotGuid !== updateSpot[0].spotGuid).concat(updateSpot[0]);
                let filteredSpots = this.state.filteredSpots.filter(spot => spot.spotGuid !== updateSpot[0].spotGuid).concat(updateSpot[0]);
                let subFilteredSpots = this.state.subFilteredSpots.filter(spot => spot.spotGuid !== updateSpot[0].spotGuid).concat(updateSpot[0]);

                this.setState({
                    rightPanelState: RightPanelState.None,
                    allSpots: spots,
                    filteredSpots: filteredSpots,
                    subFilteredSpots: subFilteredSpots,
                    hasUnsavedChanges: false
                });
            }
            else {
                this.setState({
                    rightPanelState: RightPanelState.None,
                    hasUnsavedChanges: false
                });
            }
        }
        else {
            this._onCancelAddEditPanel();
        }
    }

    protected _searchTermChange = (searchTerm_: string): void => {
        this.setState({
            searchTerm: searchTerm_,
        }, () => {
            this.filterItems();
        });
    }

    protected _filterDataBySearch = (data_: ISpot[], searchTerm_: string): ISpot[] => {
        return data_.filter(d => {
            return d.propertyName.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
                d.friendlySpotId.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
                d.propertyCity.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
                (d.propertyOwnerName && d.propertyOwnerName.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1) ||
                (d.propertyCompanyName && d.propertyCompanyName.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1) ||
                (d.zoneName && d.zoneName.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1) ||
                (d.spotTenantName && d.spotTenantName.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1);
        });
    }

    private updateSpotStatus = (guids_: string[], spots_: ISpot[], spotStatus_: number, isMapDelete_: boolean = false): void => {
        this._spotService.CheckSpotReservations(guids_)
            .then(data => {
                if (data.ok) {
                    data.json().then(d => {
                        let reservedSpotGuids: string[] = [];
                        let isMonthlyBooking: boolean = false;
                        let isSameProperty: boolean = true;
                        let propertyName: string = "";
                        let propertyMsg: string = "";
                        d.forEach((spotItem: any) => {
                            if (spotItem.isDailyBooked || spotItem.isMonthlyBooked) {
                                if (isSameProperty) {
                                    if (propertyName == "" || propertyName == spotItem.propertyName) {
                                        propertyName = spotItem.propertyName;
                                    } else {
                                        isSameProperty = false;
                                    }
                                }
                                reservedSpotGuids.push(spotItem.spotGuid);
                            }
                        });
                        if (reservedSpotGuids.length > 0) {
                            propertyMsg = isSameProperty ? propertyName : 'across multiple properties';
                            let spotMsg: string = reservedSpotGuids.length > 1 ? `${reservedSpotGuids.length} spot(s) ` : '';

                            let cancelBookingOption: any = {
                                spotGuids: reservedSpotGuids,
                                refundAction: false
                            }

                            let updateStatusSting = spotStatus_ == 2 ? 'Inactivating' : 'Deleting'

                            if ((GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) > -1 || GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) > -1)) {
                                ShowAlertwithOptions(
                                    "Booking Cancellation",
                                    `${spotMsg} ${propertyMsg} have active booking(s). ${updateStatusSting} ${reservedSpotGuids.length > 1 ? 'these spots' : 'this spot'} will cancel the booking(s). Are you sure you want to proceed?`,
                                    "warning",
                                    "Yes, cancel immediately",
                                    null,
                                    "No, don't cancel")
                                    .then(agreed_ => {
                                        if (agreed_) {
                                            if (agreed_.option1) {
                                                cancelBookingOption.refundAction = true;
                                            }

                                            if (agreed_.option1) {
                                                this._spotService.AdminCancelReservation(cancelBookingOption)
                                                    .then(data => {
                                                        if (data.ok) {
                                                            data.json().then((cancellationResponse_: { cancelled: string[], issueWithCancellation: string[], issueWithRefund: string[], manualRefundBookings: string[] }) => {
                                                                let updateSpots = () => {
                                                                    /*let spotGuids = guids_.slice(0).filter(s => cancellationResponse_.cancelled.indexOf(s) >= 0 ||
                                                                        cancellationResponse_.issueWithRefund.indexOf(s) >= 0);*/
                                                                    let spotGuids = guids_.slice(0).filter(s => cancellationResponse_.issueWithCancellation.indexOf(s) == -1);

                                                                    this._spotService.updateMultiSpotStatus(spotGuids, spotStatus_).then(response => {
                                                                        if (response.ok) {
                                                                            this._spotService.getSelectedSpotList(spotGuids).then(r => {
                                                                                if (r.ok) {
                                                                                    r.json().then(resp => {
                                                                                        let disabledSpots = spots_.filter(s => spotGuids.indexOf(s.spotGuid) >= 0);
                                                                                        if (spotStatus_ == 2) {
                                                                                            for (let item of disabledSpots) {
                                                                                                var filteredSpot = resp.filter((x: any) => x.spotGuid == item.spotGuid);
                                                                                                item.spotStatusId = spotStatus_;

                                                                                                if (filteredSpot.length > 0) {
                                                                                                    item.revenue = filteredSpot[0].revenve;
                                                                                                }
                                                                                            }

                                                                                            this.setState({
                                                                                                subFilteredSpots: this.state.subFilteredSpots
                                                                                            }, () => {
                                                                                                ShowAlert('', 'Booking(s) are cancelled and initiated refund.', 'success');
                                                                                            });
                                                                                        } else {
                                                                                            let allSpots_: ISpot[] = ObjectHelper.deepClone(this.state.allSpots);
                                                                                            let filteredSpots_: ISpot[] = ObjectHelper.deepClone(this.state.filteredSpots);
                                                                                            let subFilteredSpots_: ISpot[] = ObjectHelper.deepClone(this.state.subFilteredSpots);

                                                                                            for (let item of disabledSpots) {
                                                                                                allSpots_.filter(x => x.spotGuid == item.spotGuid).forEach(x => allSpots_.splice(allSpots_.indexOf(x), 1));
                                                                                                filteredSpots_.filter(x => x.spotGuid == item.spotGuid).forEach(x => filteredSpots_.splice(filteredSpots_.indexOf(x), 1));
                                                                                                subFilteredSpots_.filter(x => x.spotGuid == item.spotGuid).forEach(x => subFilteredSpots_.splice(subFilteredSpots_.indexOf(x), 1));
                                                                                            }

                                                                                            this.setState({
                                                                                                allSpots: allSpots_,
                                                                                                filteredSpots: filteredSpots_,
                                                                                                subFilteredSpots: subFilteredSpots_
                                                                                            }, () => {
                                                                                                if (spotStatus_ == 3) {
                                                                                                    if (isMapDelete_) {
                                                                                                        let zoneData = {
                                                                                                            "propertyGuid": spots_[0].propertyGuid,
                                                                                                            "levelId": 0
                                                                                                        } as any;

                                                                                                        this._privateLotService.ResetMap(zoneData)
                                                                                                            .then(r => {
                                                                                                                var keys = Object.keys(localStorage).filter(x => x.indexOf(spots_[0].propertyGuid) != -1);

                                                                                                                keys.forEach(asset_property => {
                                                                                                                    localStorage.removeItem(asset_property);
                                                                                                                });
                                                                                
                                                                                                                // localStorage.removeItem(`asset_property_${spots_[0].propertyGuid}`);
                                                                                                                ShowAlert('', 'Spot deleted successfully', 'success');
                                                                                                            });
                                                                                                    }
                                                                                                    else {
                                                                                                        ShowAlert('', 'Spot deleted successfully', 'success');
                                                                                                    }
                                                                                                }
                                                                                            });
                                                                                        }
                                                                                    })
                                                                                }
                                                                            })
                                                                        }
                                                                    });
                                                                }

                                                                if (cancellationResponse_.manualRefundBookings && cancellationResponse_.manualRefundBookings.length) {
                                                                    ShowAlert("Unable to Refund", `Unable to refund the following bookings, request admin to refund offline: ${cancellationResponse_.manualRefundBookings.join(", ")}`, "warning")
                                                                        .then(() => {
                                                                            updateSpots();
                                                                        });
                                                                }
                                                                else {
                                                                    updateSpots();
                                                                }
                                                            });
                                                        }
                                                    });
                                            }
                                        }
                                    });
                            } else {
                                ShowAlert('Error!', 'Please contact Gryd support since there are active/upcoming reservations that will be cancelled/affected for the spot(s)', 'error');
                            }
                        } else {
                            this._spotService.updateMultiSpotStatus(guids_, spotStatus_).then(response => {
                                if (response.ok) {
                                    for (let item of spots_) {
                                        if (spotStatus_ == 2) {
                                            item.spotStatusId = spotStatus_;
                                            this.setState({
                                                subFilteredSpots: this.state.subFilteredSpots
                                            });
                                        } else {
                                            let allSpots_: ISpot[] = ObjectHelper.deepClone(this.state.allSpots);
                                            let filteredSpots_: ISpot[] = ObjectHelper.deepClone(this.state.filteredSpots);
                                            let subFilteredSpots_: ISpot[] = ObjectHelper.deepClone(this.state.subFilteredSpots);

                                            allSpots_.filter(x => x.spotGuid == item.spotGuid).forEach(x => allSpots_.splice(allSpots_.indexOf(x), 1));
                                            filteredSpots_.filter(x => x.spotGuid == item.spotGuid).forEach(x => filteredSpots_.splice(filteredSpots_.indexOf(x), 1));
                                            subFilteredSpots_.filter(x => x.spotGuid == item.spotGuid).forEach(x => subFilteredSpots_.splice(subFilteredSpots_.indexOf(x), 1));

                                            this.setState({
                                                allSpots: allSpots_,
                                                filteredSpots: filteredSpots_,
                                                subFilteredSpots: subFilteredSpots_
                                            }, () => {
                                                if (spotStatus_ == 3) {
                                                    if (isMapDelete_) {
                                                        let zoneData = {
                                                            "propertyGuid": spots_[0].propertyGuid,
                                                            "levelId": 0
                                                        } as any;

                                                        this._privateLotService.ResetMap(zoneData)
                                                            .then(r => {
                                                                var keys = Object.keys(localStorage).filter(x =>  x.indexOf("asset_property") != -1 && x.indexOf(spots_[0].propertyGuid) != -1);

                                                                keys.forEach(asset_property => {
                                                                    localStorage.removeItem(asset_property);
                                                                });
                                                                // localStorage.removeItem(`asset_property_${spots_[0].propertyGuid}`);
                                                                ShowAlert('', 'Spot deleted successfully', 'success');
                                                            });
                                                    }
                                                    else {
                                                        ShowAlert('', 'Spot deleted successfully', 'success');
                                                    }
                                                    // ShowAlert('', 'Spot deleted successfully', 'success');
                                                }
                                            });
                                        }
                                    }
                                }
                            });
                        }
                    });
                }
            });
    }

    private sortDate(dates_: Date[]) {
        return dates_.sort(function (a, b) {
            return b.getTime() - a.getTime();
        });
    }
}

interface ISpotListProps {
    selectedTabIndex: number;
    source?: string;
    requestType?: string;
    propertyGuid?: string;
    onTabChange: (tabIndex_: number) => void;
}

interface ISpotListState {
    isSpotsLoaded: boolean;
    allSpots: ISpot[];
    filteredSpots: ISpot[];
    subFilteredSpots: ISpot[];
    rightPanelState: RightPanelState;
    searchTerm: string;
    appliedFilter?: ISpotFilter;
    isFiltered: boolean;
    selectedSpot?: ISpot;
    hasUnsavedChanges?: boolean;
    _autoApplyFilterKey: number;
    isClaimBoxOpen?: boolean;
    selectedTabIndex: number;
    isScreenAccessible: boolean;
    loaderPercentage?: number;
}