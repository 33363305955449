import React from 'react';
import dashboardImg from '../../Images/Dashbaord.png';
import { Checkbox, Grid, TextField } from '@material-ui/core';

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { AnyMxRecord } from 'dns';

import Plaid from '../PlaidLink/PlaidLink';
import SpotClaimModal from '../Spots/SpotClaimModal';
import { Row, Col } from 'reactstrap';
import AdminDashboardCharts from './AdminDashboardCharts';
import { GetUserDetailsService } from '../../Services/GetUserDetailsService';
import ManagerDashboardCharts from './ManagerDashboardCharts';
import TenantDashboardCharts from './TenantDashboardCharts';
import { Autocomplete } from '@material-ui/lab';
import PropertyService from '../../Services/PropertyService';
import { CheckScreenPermission } from '../../Common/CheckScreenPermission';


class Dashboard extends React.Component<DashboardProps, DashboardState> {
    private _propertyService: PropertyService;

    constructor(props: DashboardProps) {
        super(props);

        var isAdmin = false;
        var verify: boolean = false;
        var validToken = localStorage.getItem('token');

        if (typeof (Storage) !== 'undefined') {
            var _localData = localStorage.getItem('userdata');

            if (_localData != null && _localData !== '') {
                var _data = JSON.parse(_localData);
                verify = _data.isPlaidVerified;
                if (_data && _data.userRoleID) {
                    var roles: any = _data.userRoleID;

                    if (roles.includes(1) || roles.includes(2)) {
                        isAdmin = true;
                    }
                    else {
                        isAdmin = false;
                    }
                }

                if (verify || (validToken == 'success')) {
                    localStorage.setItem('token', 'success');
                }

            }
        }

        validToken = localStorage.getItem('token');
        this.state = {
            open: true,
            isVerified: ((validToken == 'success') ? true : false),
            token: String(validToken),
            isAdminUser: isAdmin,
            isScreenAccessible: false
        }
        this._propertyService = new PropertyService();
    }

    public async componentDidMount() {
        var isAccessible = await CheckScreenPermission("user-interface-dashboard");

        this.setState({
            isScreenAccessible: isAccessible
        });
    }

    public render() {
        if (!this.state.isScreenAccessible) return null;

        return (
            // data-grid-container
            <div className="m-3 data-grid-container dashboard-container main-dashboard">
                <Grid container className="mb-3">
                    <Grid item xs>
                        <h1 className="list-header">Dashboard</h1>
                    </Grid>
                    <Grid item></Grid>
                </Grid>
                {
                    (!this.state.isAdminUser) &&
                    <Row className="m-0">
                        <SpotClaimModal />
                        {
                            GetUserDetailsService.getUserDetails().userRoleID.indexOf(8) == -1 &&
                            <Col sm={6} md={4} lg={3}>
                                {
                                    !this.state.isVerified &&
                                    <Plaid
                                        onClose={(s_: boolean) => {
                                            this.setState({
                                                isVerified: s_
                                            });
                                        }}
                                    />
                                }
                            </Col>
                        }
                    </Row>
                }

                {
                    this.loadCharts()
                }
                {/* <img src={dashboardImg} width="100%" /> */}
            </div>
        )
    }

    private loadCharts() {
        if (GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) != -1 || GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) != -1) {
            return (<AdminDashboardCharts />);
        } else if (GetUserDetailsService.getUserDetails().userRoleID.indexOf(5) != -1 || GetUserDetailsService.getUserDetails().userRoleID.indexOf(8) != -1) {
            return (<ManagerDashboardCharts />);
        } else if (GetUserDetailsService.getUserDetails().userRoleID.indexOf(6) != -1) {
            return (<TenantDashboardCharts />);
        }

        return null;
    }
}

interface DashboardProps { }
interface DashboardState {
    open: boolean;
    token: string;
    isVerified: boolean;
    isAdminUser: boolean;
    isScreenAccessible: boolean;
}

export default Dashboard;