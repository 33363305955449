import {Grid} from "@material-ui/core";
import React from "react";

interface IOffenseCounterProps {
  noOfOffences?: number,
}


export default class OffenseCounter extends React.Component<IOffenseCounterProps> {

  render() {
    return (<Grid item xs className="text-right offence-text padd-enforcement-item fw-700" style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
      }}>
        <div style={{
          border: '1px solid',
          borderRadius: 4,
          width: 24,
          textAlign: 'center',
        }}>
          {this.props.noOfOffences || 0}
        </div>
      </Grid>
    );

  }
}
