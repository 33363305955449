import React from "react";
import {Button, TextField} from "@material-ui/core";
import {Autocomplete, createFilterOptions} from "@material-ui/lab";
import {IPrivateLotProperty} from "./interface";
import PrivateLotService from "../../Services/PrivateLotService";
import {IFloor, INewFloor} from "../../Components/SpotMap/interface";
import {ShowAlertwithConfirm} from "../../Common/ShowAlert";

export default class UploadMapPopup extends React.Component<IUploadMapPopupProps, IUploadMapPopupState> {
  private _privateLotService: PrivateLotService;

  constructor(props_: IUploadMapPopupProps) {
    super(props_);

    this.state = {
      isBulkUpload: false,
      mapUrl: '',
      properties: [],
      selectedProperty: null,
      floorLevel: this.props.floorLevel || [],
      selectedFloor: null//this.props.selectedFloor
    }

    this._privateLotService = new PrivateLotService();
  }

  componentDidMount(): void {
    let properties_ = this.props.properties;

    let index = properties_.findIndex(x => x.propertyName === 'All Properties');

    properties_.splice(index, 1);

    this.setState({
      properties: properties_,
      selectedProperty: this.props.selectedProperty
    }, () => {
      this.getFloorLevels();
    })
  }

  render() {
    if (this.props.floorLevel.length === 0) return null;

    const levelsFilter = createFilterOptions<IFloor>();

    return (
      <div className="bulk-upload-blocker" onClick={(event: any) => {
        if (event.target && event.target.classList.toString().indexOf("bulk-upload-blocker") !== -1) {
          if (this.props.onCancel) {
            this.props.onCancel();
          }
        }
      }}>
        <div className="map-upload-popup">
          <div>
            <div className="d-flex flex-column align-items-center">
              <div className="bulk-upload-header mt-3">
                Upload Map
              </div>
              <div className="mt-3 bulk-upload-sub-content text-center">
                Paste Figma URL and parking lot information to upload new map.
              </div>
              <div className="map-bulk-upload-file-container mt-3">
                <p><b>Map URL</b></p>
                <TextField
                  error={this.isNotValidUrl()}
                  fullWidth
                  placeholder="Paste map URL"
                  variant="outlined"
                  required={true}
                  margin="normal"
                  className="mt-0"
                  onPaste={() => {
                    return false
                  }}
                  onChange={(e_: any) => {
                    this.setState({
                      mapUrl: e_.target.value
                    }, () => {
                      // if (this.props.onChange) {
                      //     this.props.onChange(this._hasChanges());
                      // }
                    })
                  }}
                  autoComplete="off"
                  value={this.state.mapUrl}
                />
                {this.isNotValidUrl() && <p className="secure-error-mg">Please enter the valid URL</p>}

                <p><b>Parking Lot Information</b></p>
                <Autocomplete
                  id="upload-map-locations"
                  options={this.state.properties}
                  value={this.state.selectedProperty}
                  openOnFocus={true}
                  selectOnFocus={true}
                  onChange={(event: any, value: IPrivateLotProperty | null) => {
                    this.setState({
                      selectedProperty: value
                    });
                  }}
                  getOptionLabel={(option) => option.propertyName}

                  renderInput={(params) => <TextField {...params}
                                                      placeholder="Select Location"
                                                      variant="outlined"
                                                      required={true}
                                                      error={this.state.selectedProperty == null}
                  />}
                />
                <Autocomplete
                  className="mt-3"
                  id="upload-map-locations-floor"
                  options={this.state.floorLevel}
                  value={this.state.selectedFloor}
                  openOnFocus={true}
                  selectOnFocus={true}
                  onChange={(event: any, value: IFloor | null) => {
                    this.setState({
                      selectedFloor: value
                    });
                  }}
                  getOptionLabel={(option) => option.levelName as string}
                  renderInput={(params) => <TextField {...params}
                                                      placeholder="Select Level"
                                                      variant="outlined"
                                                      required={true}
                                                      error={this.state.selectedFloor == null}
                  />}
                  filterOptions={(options, state): IFloor[] => {
                    const result = levelsFilter(options, state);

                    return result.length > 0 ? result : [{
                      levelId: this.state.floorLevel.length,
                      levelName: <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => this.addNewLevel(state.inputValue)}
                      >Add new Level</Button>
                    }];
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="ml-2"
                style={{width: 131}}
                onClick={() => {
                  this.uploadMapDetails();
                }}
                disabled={this.isNotValidUrl()}
              >
                Upload
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className="ml-2"
                style={{width: 131}}
                onClick={() => {
                  this.setState({
                    isBulkUpload: false
                  })
                  if (this.props.onCancel) {
                    this.props.onCancel();
                  }
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private addNewLevel(levelName: string) {

    const result: INewFloor = {
      levelName: levelName,
      levelId: this.state.floorLevel.length + 1
    }
    const addFloorLevel = (newLevel: INewFloor) => {

      this._privateLotService.addFloorLevel(newLevel, true)
        .then(response => response.json())
        .then(() => this.getFloorLevels())
        .catch(() => {
          this.setState({
            selectedFloor: null,
          })
        });

      this.setState({
        selectedFloor: result,
      });
    }

    ShowAlertwithConfirm(
      "Confirm addition",
      `Do you want to add "${levelName}" to the list?`,
      "warning"
    ).then(accepted => {
      if (accepted)
        addFloorLevel(result);
    })

  };

  private isNotValidUrl() {
    try {
      let inputUrl = decodeURIComponent(this.state.mapUrl);

      let urlSplitForFileName = inputUrl.split("file/");
      let urlSplitForDesignName = inputUrl.split("design/");

      let fileName = "";
      let nodeId = "";

      if (urlSplitForFileName.length > 1) {
        let figmaFilelist = urlSplitForFileName[1].split("/");

        if (figmaFilelist.length > 0) {
          fileName = figmaFilelist[0]
        }
      } else if (urlSplitForDesignName.length > 1) {
        let figmaFilelist = urlSplitForDesignName[1].split("/");

        if (figmaFilelist.length > 0) {
          fileName = figmaFilelist[0]
        }
      }


      let urlSplitParameters = inputUrl.split("?");

      if (urlSplitParameters.length > 1) {
        let queryString = new URLSearchParams(urlSplitParameters[1]);

        let code = queryString.get("node-id");

        nodeId = code ? code : "";
      }

      return (this.state.mapUrl.trim().length === 0 || nodeId === "" || fileName === "");
    } catch (ex) {
      return false;
    }
  }

  private getFloorLevels() {
    this._privateLotService.getFloorLevel()
      .then(r => r.json())
      .then(r => {
        this.setState({
          floorLevel: r
        });
      });
  }


  private uploadMapDetails() {

    if (this.state.mapUrl !== "" && this.state.selectedProperty != null &&
      this.state.selectedFloor != null && this.props.onUpdate) {
      this.props.onUpdate(this.state.mapUrl, this.state.selectedProperty, this.state.selectedFloor);
    }

    /*if (this.state.mapUrl != "" && this.state.selectedProperty != null &&
        this.state.selectedFloor != null) {
        let uploadedMap = {
            "propertyGuid": this.state.selectedProperty.propertyGuid,
            "externalMapUrl": this.state.mapUrl,
            "levelId": 0
        } as any;

        this._privateLotService.UploadMap(uploadedMap)
            .then(r => {
                if (r.ok) {
                    if (this.state.mapUrl != "" && this.state.selectedProperty != null &&
                        this.state.selectedFloor != null && this.props.onUpdate) {
                        this.props.onUpdate(this.state.mapUrl, this.state.selectedProperty, this.state.selectedFloor.levelName);
                    }
                }
            });

    }*/

    /*if (this.state.mapUrl != "" && this.state.selectedProperty != null &&
        this.state.selectedFloor != null && this.props.onUpdate) {
        this.props.onUpdate(this.state.mapUrl, this.state.selectedProperty, this.state.selectedFloor.levelName);
    }*/
  }
}

interface IUploadMapPopupProps {
  selectedProperty: IPrivateLotProperty | null;
  properties: IPrivateLotProperty[];
  onUpdate: (mapUrl: string, selectedProperty: IPrivateLotProperty | null, selectedFloor: IFloor) => void;
  onCancel: () => void;
  floorLevel: IFloor[];
  // selectedFloor: IFloor | null;
}

interface IUploadMapPopupState {
  isBulkUpload: boolean;
  mapUrl: string;
  selectedProperty: IPrivateLotProperty | null;
  properties: IPrivateLotProperty[];
  floorLevel: IFloor[];
  selectedFloor: IFloor | null;
}

