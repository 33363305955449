import React from 'react';
import { Grid } from '@material-ui/core';

class NotFound extends React.Component<NotFoundProps, NotFoundState> {
    constructor(props: NotFoundProps) {
        super(props);  
    }

    public componentDidMount = () => {
        window.location.href = '/';
    }

    public render() {

        return (
            <div className="m-3 data-grid-container dashboard-container">
                <Grid container className="mb-3">
                    <Grid item>
                        <h1 className="list-header">Page not found</h1>
                    </Grid>
                </Grid>
                {/* <img src={dashboardImg} width="100%" /> */}
            </div>
        )
    }


}

interface NotFoundProps { }
interface NotFoundState { }

export default NotFound;