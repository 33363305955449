import { Button, Grid, Paper, TextField, RadioGroup, Radio, 
    FormControlLabel, Icon } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import React from "react";
import { ITreeViewNode } from "../../Common/Treeview-Checkbox/interface";
import TreeViewCheckbox from "../../Common/Treeview-Checkbox/TreeViewCheckbox";
import UserService from "../../Services/UserService";
import { IUserPermissionProps, IUserPermissionState, IPermissionResponses, IUserRoles, IActiveUsers, IPermissions } from "./Interfaces";
import "./UserPermission.css";
import { ShowAlert } from "../../Common/ShowAlert";

class UserPermission extends React.Component<IUserPermissionProps, IUserPermissionState> {

    private nodes: ITreeViewNode[] = [];
    private _userService: UserService;
    constructor(props: IUserPermissionProps) {
        super(props);
        this.state = {
            nodes: this.nodes,
            permissionType: "role",
            roles: [],
            selectedRole: {
                userRoleId: 0,
                userRoleName: "",
                userRoleKey: ""
            },
            disableCheckboxTree: true,
            checked: [],
            userLevelSearch: {
                name: "",
                email: "",
                userGuid: "",
                userRole: 0
            },
            isNotFound: false,
            activeUsers: [],
            filterActiveUsers: [],
            selectedEmail: "",
            originalChecked: [],
            theme: undefined
        }
        this._userService = new UserService();
    }

    getUserRoles = () => {
        this._userService.getUserRoles()
            .then((response) => {
                if (response.ok) {
                    response.json().then(res => {
                        const roles: IUserRoles[] = res;
                        const selectedRole = roles[0];
                        this.setState({ roles, selectedRole, disableCheckboxTree: !!!selectedRole }, () => {
                            this.getAllPermission();
                        });
                    });
                }
            });
    }

    getPermissionNodes = (parentPermission: IPermissionResponses, allPermissions: IPermissionResponses[]) => {
        const childPermission = allPermissions.filter((permission: IPermissionResponses) => permission.parentPermissionId && parentPermission.childId === permission.parentId);
        const childNodes: ITreeViewNode[] = [];
        childPermission.map((permission: IPermissionResponses) => {
            const node: ITreeViewNode = {
                value: permission.childId?.toString() ?? "",
                label: permission.permissionName ?? ""
            }
            let children = this.getPermissionNodes(permission, allPermissions);
            node.children = children.length > 0 ? children : undefined;
            childNodes.push(node);
            return permission;
        });

        return childNodes;
    }

    getAllPermission = () => {
        this._userService.getAllPermission()
            .then((response) => {
                if (response.ok) {
                    response.json().then(res => {
                        const permissions: IPermissionResponses[] = res;
                        const parentPermission = permissions.filter((permission: IPermissionResponses) => !permission.parentPermissionId);
                        const nodes: ITreeViewNode[] = [];
                        parentPermission.map((permission: IPermissionResponses) => {
                            const node: ITreeViewNode = {
                                value: permission.childId?.toString() ?? "",
                                label: permission.parentName ?? ""
                            }
                            let children = this.getPermissionNodes(permission, permissions);
                            node.children = children.length > 0 ? children : undefined;
                            nodes.push(node);
                            return permission;
                        });

                        this.setState({ nodes });
                        this.getActiveUserPermissions(undefined, this.state.selectedRole?.userRoleId);
                    });
                }
            })
    }

    componentDidMount() {
        this.getUserRoles();
    }

    getActiveUsers = (permissionType: string) => {
        const { activeUsers } = this.state;
        if (activeUsers.length === 0 && permissionType === "user") {
            this._userService.getActiveUsers().then((response) => {
                if (response.ok) {
                    response.json().then(res => {
                        const activeUsers: IActiveUsers[] = res;
                        this.setState({ activeUsers, filterActiveUsers: activeUsers });
                    });
                }
            });
        }
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const permissionType = (event.target as HTMLInputElement).value;
        const selectedRole = permissionType === "user" ? null : this.state?.roles[0];
        this.getActiveUsers(permissionType);
        if (permissionType === "user") {
            this.setState({
                permissionType, selectedRole, checked: [], disableCheckboxTree: !!!selectedRole, userLevelSearch: {
                    name: "",
                    email: "",
                    userGuid: "",
                    userRole: 0
                }, isNotFound: false, selectedEmail: ""
            });
        }
        else {
            this.setState({
                permissionType, selectedRole, disableCheckboxTree: !!!selectedRole, userLevelSearch: {
                    name: "",
                    email: "",
                    userGuid: "",
                    userRole: 0
                }, isNotFound: false, selectedEmail: ""
            }, () => {
                this.getActiveUserPermissions(undefined, this.state.selectedRole?.userRoleId);
            });
        }
    }

    controlProps = (item: string) => ({
        checked: this.state.permissionType === item,
        onChange: this.handleChange,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item }
    });

    filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option: IActiveUsers) => option.email,
    });

    getActiveUserPermissions = (activeUser?: IActiveUsers, userRoleId?: number) => {
        const permissionMappingModel: IPermissions = activeUser ? {
            userGuid: activeUser.userGuid,
            permissionId: 0
        } : {
            userRoleId: userRoleId ?? 0,
            permissionId: 0
        };
        this._userService.getActiveUserPermissions(permissionMappingModel).then((response) => {
            if (response.ok) {
                response.json().then(res => {
                    const permissions: IPermissions[] = res;
                    const checked = permissions.map((permission: IPermissions) => permission.permissionId);
                    this.setState({ originalChecked: checked, checked, disableCheckboxTree: false });
                });
            }
        });
    }   

    render() {
        const filterRequired = true;
        let scrollClass = this.state.permissionType || filterRequired ? "treeview-container-285" : "treeview-container-355";
        scrollClass = this.state.permissionType && filterRequired ? "treeview-container-219" : scrollClass;
        const { selectedRole } = this.state;
        console.log("SelectedRoles = " + selectedRole);
        return (<div className="permission m-3 data-grid-container dashboard-container">
            <Paper className="data-grid-paper-container search">
                <Grid container>
                    <Grid item xs={12} className="search-container radio-buttons-group">
                        <RadioGroup row
                            name="controlled-radio-buttons-group"
                            value={this.state.permissionType}
                        >
                            <FormControlLabel value="role" control={<Radio {...this.controlProps('role')} />} label="Roles" />
                            <FormControlLabel value="user" control={<Radio {...this.controlProps('user')} />} label="User" />

                        </RadioGroup>
                    </Grid>
                    <Grid item xs>
                        <Grid container>
                            {this.state.permissionType === "user" && <Grid item xs>
                                <Grid container xs className="search-container search-user-container">
                                    <Grid item className="search-user userRole" xs={3}>
                                        <Autocomplete
                                            freeSolo
                                            id="userSerarch"
                                            options={this.state.filterActiveUsers}
                                            getOptionLabel={option_ => (option_.name || option_.email) ? `${ option_.name } (${ option_.email })` : "" }
                                            className="mt-1"
                                            value={this.state.userLevelSearch}
                                            filterOptions={this.filterOptions}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Search User Email"
                                                    onChange={(event: any) => {
                                                        const selectedEmail = event.target.value;
                                                        this.setState({ selectedEmail, disableCheckboxTree: true, checked: [], isNotFound: false });
                                                    }}
                                                />
                                            )}
                                            onChange={(e_: any, userLevelSearch: any | null) => {
                                                const selectedEmail = userLevelSearch?.email ?? "";
                                                this.setState({ selectedEmail, userLevelSearch, checked: [], disableCheckboxTree: true, isNotFound: false });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item className="search-user search-btn">
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className='mt-2 c-go-button'
                                            onClick={() => {
                                                const activeUser = this.state.activeUsers.find((user: IActiveUsers) => user.email === this.state.selectedEmail);
                                                if (!this.state.selectedEmail)
                                                    ShowAlert("", "Please enter user email", "warning");
                                                else if (!activeUser)
                                                    this.setState({ isNotFound: true });
                                                else {
                                                    this.setState({ userLevelSearch: activeUser, isNotFound: false }, () => {
                                                        this.getActiveUserPermissions(activeUser);
                                                    });
                                                }
                                            }}
                                            disabled={false}
                                        >
                                            Search
                                        </Button>
                                    </Grid>
                                    {this.state.isNotFound && <Grid item xs={6} className="search-user search-error">
                                        <Grid container xs>
                                            <Grid item><Icon>error_outline</Icon></Grid>
                                            <Grid item className="icon-text"><span>User not found</span></Grid>
                                        </Grid>
                                    </Grid>
                                    }
                                </Grid>
                            </Grid>
                            }
                            {this.state.permissionType === "role" && <Grid item xs>
                                <Grid container xs className="search-container search-roles">
                                    <Grid item className="search-user role" xs={3}>
                                        <Autocomplete
                                            className="mt-2"
                                            options={this.state.roles}
                                            getOptionLabel={option_ => option_.userRoleName as string}
                                            style={{ width: 300 }}
                                            size="small"
                                            value={selectedRole}
                                            renderInput={(params) => (<TextField {...params} label="Roles"
                                                variant="outlined"
                                                error={!selectedRole}
                                                onFocus={(e_: any) => {
                                                    e_.preventDefault();
                                                    e_.target.blur();
                                                    return false;
                                                }}
                                                // onChange={(event: any) => {
                                                //     let disableCheckboxTree = !!!event.target.value;
                                                //     if (disableCheckboxTree)
                                                //         this.setState({ disableCheckboxTree, checked: [] });
                                                // }}
                                                />)}
                                            onChange={(e_: any, selectedRole: IUserRoles | null) => {
                                                let disableCheckboxTree = !!!selectedRole;
                                                this.setState({ selectedRole, checked: [], disableCheckboxTree }, () => {
                                                    selectedRole && this.getActiveUserPermissions(undefined, this.state.selectedRole?.userRoleId);
                                                });
                                            }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <Paper className="data-grid-paper-container">
                <Grid container className="permission-header-container">
                    <Grid item xs>
                        <Grid container className="permission-container">
                            <Grid item>
                                <div className="h3">PERMISSIONS</div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} className="tree-container">
                        <TreeViewCheckbox convertType={"number"} nodes={this.state.nodes} filterRequired={filterRequired} scrollClass={scrollClass} checked={this.state.checked} disabled={this.state.disableCheckboxTree}
                            isAnyChildChecked={true}
                            onCheck={(checked: number[]) => {
                                const { originalChecked, permissionType, userLevelSearch } = this.state;
                                const removed = originalChecked.filter((removedPermission: number) => !checked.includes(removedPermission));
                                const added = checked.filter((newPermission: number) => !originalChecked.includes(newPermission));
                                const permissionMappingModel: IPermissions = permissionType === "user" ? {
                                    userGuid: userLevelSearch.userGuid,
                                    permissionId: 0,
                                    removed,
                                    added
                                } : {
                                    userRoleId: selectedRole?.userRoleId,
                                    permissionId: 0,
                                    removed,
                                    added
                                };
                                this._userService.saveUserRolePermissions(permissionMappingModel).then((response) => {
                                    if (response.ok) {
                                        this.setState({ originalChecked: checked, checked, disableCheckboxTree: false });
                                    }
                                });
                            }} />
                    </Grid>
                </Grid>
            </Paper>

        </div>)
    }
}

export default UserPermission;