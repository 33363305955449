import React from "react";
import { Button, Tooltip, IconButton } from "@material-ui/core";
import FileUploadModal, { IFileInfo } from "./FileUploadModal";
import CacheService from "../../Services/CacheService";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { ShowToastr } from "../Toastr";

export default class FilePicker extends React.Component<IFilePickerProps, IFilePickerState> {
    state: IFilePickerState = {
        isOpen: false,
        selectedFileIds: []
    };
    private _descriptor: JSX.Element[] = [];

    constructor(props_: IFilePickerProps) {
        super(props_);

        if (props_.uploadedFiles) {
            this.state.selectedFileIds = props_.uploadedFiles.map(item_ => item_.storedFileGuid as string).filter(data_ => data_ != undefined);
        }

        this.state.isOpen = props_.isOpen != undefined ? props_.isOpen : false;
        
        if (this.props.descriptionSubset) {
            if (this.props.descriptionSubset instanceof Array) {
                for (let line of this.props.descriptionSubset) {
                    if (this._descriptor.length != 0) {
                        this._descriptor.push(<br />);
                    }
                    this._descriptor.push(<b>{line}</b>);
                }
            }
            else {
                this._descriptor.push(<b>{this.props.descriptionSubset}</b>);
            }
        }
    }

    componentDidUpdate(oldProps_: IFilePickerProps) {
        if (oldProps_.isOpen != this.props.isOpen) {
            this.setState({
                isOpen: this.props.isOpen == true
            });
        }
    }

    render() {
        return (
            <div>
                {
                    this.props.isButtonIcon ?
                        <Tooltip title={this.props.label}>
                            <IconButton
                                aria-label={this.props.label}
                                onClick={() => {
                                    this.setState({ isOpen: true })
                                    if (this.props.onModalOpen) {
                                        this.props.onModalOpen();
                                    }
                                }}
                                className={this.props.customIconClassName}
                            >
                                {
                                    this.props.customIcon ?
                                        this.props.customIcon
                                        : <AddAPhotoIcon />
                                }
                            </IconButton>
                        </Tooltip>
                        :
                        <Button
                            variant="contained"
                            onClick={() => {
                                this.setState({ isOpen: true })

                                if (this.props.onModalOpen) {
                                    this.props.onModalOpen();
                                }
                            }}
                        >{this.props.label}</Button>
                }
                {
                    this.state.isOpen &&
                    <FileUploadModal
                        isOpen={this.state.isOpen}
                        descriptor={this._descriptor}
                        filter={this.props.filter}
                        single={this.props.single}
                        filesCache={this._getFilteredCachedFiles()}
                        selectedFileIds={this.state.selectedFileIds}
                        onClose={() => {
                            this.setState({ isOpen: false })

                            if (this.props.onModalClose) {
                                this.props.onModalClose();
                            }
                        }}
                        interceptUpload={this.props.interceptUpload}
                        onUpload={(file_: IFileInfo) => {
                            CacheService.cacheFile(file_);
                        }}
                        onRemove={(file_: IFileInfo) => {
                            let selectedFileIndex = this.state.selectedFileIds.indexOf(file_.internalUid);

                            if (selectedFileIndex !== -1) {
                                this.state.selectedFileIds.splice(selectedFileIndex, 1);
                                this.setState({
                                    selectedFileIds: this.state.selectedFileIds
                                });
                            }

                            CacheService.removeCachedFile(file_);
                        }}
                        onDone={(selectedFileIds_: string[]) => {
                            this.setState({
                                selectedFileIds: selectedFileIds_
                            });

                            let allCachedFiles = this._getFilteredCachedFiles();
                            let selectedFiles = allCachedFiles.filter(f_ => {
                                return selectedFileIds_.indexOf(f_.internalUid) > -1;
                            });
                            this.props.onFilesSelectionComplete(selectedFiles);
                        }}
                        onUploadRejected={(fileName_: string, fileExtension_: string) => {
                            ShowToastr(`Couldn't upload ${fileName_}, as the file format is invalid`);
                        }}
                    />
                }
            </div>
        );
    }

    private _getFilteredCachedFiles() {
        let allCachedFiles = CacheService.getFilesCache();
        let allowedExtensions = this.props.filter.split(',');
        let cachedFiles = allCachedFiles.filter(f => {
            return allowedExtensions.indexOf(f.extension) > -1;
        });

        if (this.props.uploadedFiles) {
            let fileIds = cachedFiles.map(f => f.storedFileGuid);
            return cachedFiles.concat(this.props.uploadedFiles.filter(f => {
                return fileIds.indexOf(f.storedFileGuid) === -1;
            }));
        }

        return cachedFiles;
    }
}

interface IFilePickerProps {
    label: string;
    descriptionSubset?: string[] | string;
    filter: string;
    single?: boolean;
    isButtonIcon?: boolean;
    customIcon?: JSX.Element;
    customIconClassName?: string;
    isOpen?: boolean;
    uploadedFiles?: IFileInfo[]
    onFilesSelectionComplete: (files_: IFileInfo[]) => void;
    interceptUpload?: (file_: IFileInfo, callback_: (accept_: boolean) => void) => void;
    onModalOpen?: () => void;
    onModalClose?: () => void;
}

interface IFilePickerState {
    isOpen: boolean;
    selectedFileIds: string[];
}