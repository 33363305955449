import { Drawer } from "@material-ui/core";
import React from "react";
import { DataGridFilterType, IDateRange } from "../../Components/DataGrid/Interfaces";
import DateRangeFilter from "../../Components/FilterComponents/DateRangeFilter";
import BaseFilterView, { IBaseFilterViewProps, IBaseFilterViewState } from "../PartialViews/BaseFilterView";

export default class TransactionFilter
    extends BaseFilterView<IBaseFilterViewProps<any,any> ,IBaseFilterViewState<any>> {
        constructor(props_: IBaseFilterViewProps<any, any>) {
            super(props_, {
                dateRange: {
                    type: DataGridFilterType.DateRange,
                    filterValue: {
                        start: undefined,
                        end: undefined
                    }
                },})
    }


    protected filterItems = ()=>{
        console.log(this.state.appliedFilter)
        if(this.state.appliedFilter.dateRange.filterValue.start){
            return this.props.data.filter((s)=>{
                return new Date(s.paidDate).getTime()>=this.state.appliedFilter.dateRange.filterValue.start.getTime() && new Date(s.paidDate).getTime()<=this.state.appliedFilter.dateRange.filterValue.end.getTime()
             
              })
        }
       else{
           return this.props.data
       }
    }


    protected renderFilterOptions=()=>{
        return <div className="filter-options-list">
        <DateRangeFilter
            title="Bookings"
            selectedDateRange={this.state.appliedFilter.dateRange.filterValue as IDateRange}
            onChange={dateRange_ => {
                if (this.state.appliedFilter.dateRange) {
                    this.state.appliedFilter.dateRange.filterValue = dateRange_;
                    this.setState({
                        appliedFilter: this.state.appliedFilter
                    });
                }
            }}
        />
    </div>
    }
}