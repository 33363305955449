import React from 'react';
import GoogleMapReact from 'google-map-react';
import Geocode from "react-geocode";
import { Markers } from './Markers';
import "./GoogleMap.css";
import googleMapReact from 'google-map-react';

const APIKey: string = 'AIzaSyDWNcJWEmXguvxEy3QmtoN3I5bzceYGm1s';

export class GoogleMap extends React.Component<IGoogleMapsProps, IGoogleMapsState>{

    private _defaultLocation: IGoogleMapLocation;
    private _defaultMultipleLocation: IGoogleMapLocation;
    private _mapRef = React.createRef<googleMapReact>();

    constructor(props: IGoogleMapsProps){
        super(props);
        
        this.state = {
            selectedLocation: null,
            reconstructMap: false,
            multipleSelectedLocation: this.props.multipleSelectedLocation ? this.props.multipleSelectedLocation : null
        };

        this._defaultLocation = this.props.selectedLocation ? this.props.selectedLocation : this.props.locations[0];
        this._defaultMultipleLocation = this.props.multipleSelectedLocation ? this.props.multipleSelectedLocation[0] : this.props.locations[0]
    }

    componentDidMount() {
        // if (this.props.address != null && this.props.address != '') {
        //     this.fromAddressToLatLng(this.props.address);
        //     this.AddressToLatLngForMarkers()
        // }
    }

    componentDidUpdate(oldProps_: IGoogleMapsProps) {
        // if (this.props.address != oldProps_.address) {
        //     this.fromAddressToLatLng(this.props.address);
        // }

        if (!this.props.isMultipleSelect && this.props.selectedLocation !== oldProps_.selectedLocation) {
            this.setState({
                selectedLocation: this.props.selectedLocation,
            });
            if (this.props.selectedLocation) {
                this._defaultLocation = this.props.selectedLocation;
            }
        }

        if (this.props.isMultipleSelect && this.props.multipleSelectedLocation !== oldProps_.multipleSelectedLocation) {
            this.setState({
                multipleSelectedLocation: this.props.multipleSelectedLocation ?? null,
            });
            if (this.props.multipleSelectedLocation) {
                this._defaultMultipleLocation = this.props.multipleSelectedLocation[0];
            }
        }
    }

    // private fromAddressToLatLng(address: string) {
    //     // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
    //     Geocode.setApiKey(APIKey);

    //     // set response language. Defaults to english.
    //     Geocode.setLanguage("en");

    //     // set response region. Its optional.
    //     // A Geocoding request with region=es (Spain) will return the Spanish city.
    //     Geocode.setRegion("es");

    //     // Enable or disable logs. Its optional.
    //     Geocode.enableDebug();
    //     Geocode.fromAddress(address).then(
    //         response => {
    //             const { lat, lng } = response.results[0].geometry.location;
    //             // console.log(lat, lng);
    //         },
    //         error => {
    //             console.error(error);
    //         }
    //     );
    // }
    // private async AddressToLatLngForMarkers() {
    //     // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.

    //     Geocode.setApiKey(APIKey);

    //     // set response language. Defaults to english.
    //     Geocode.setLanguage("en");

    //     // set response region. Its optional.
    //     // A Geocoding request with region=es (Spain) will return the Spanish city.
    //     Geocode.setRegion("es");
    //     let location;
    //     // Enable or disable logs. Its optional.
    //     Geocode.enableDebug();
    //     let markers: any[] = []
    //     this.props.markers?.forEach((address) => {
    //         Geocode.fromAddress(address).then(
    //             response => {
    //                 const location = response.results[0].geometry.location;
    //                 markers.push(location)
    //                 this.setState({allMarkers:markers})
    //             }
    //         );
    //     })

    // }
    handleApiLoaded = (map:any, maps:any) => {
       // debugger;
        // use map and maps objects
        console.log(map,maps);
        
    //     let marker = coorMappers.map((cord_)=>{
    //         return new maps.Marker({
    //             position: {
    //                 lat:coorMappers[0].lat,
    //                 lng: coorMappers[0].lng
    //             },
    //             map,
    //             title: 'Hello World!'
    //           });
    //    });
    //     return marker;
    };

    render() {
        if (this.state.reconstructMap) {
            this.setState({
                reconstructMap: false
            });
            return null;
        }

        return (
                <GoogleMapReact
                    ref={this._mapRef}
                    bootstrapURLKeys={
                        {
                            key: APIKey,
                            language: 'en',
                            region: 'es',
                        }
                    }
                    center={this.props.isMultipleSelect ? this._defaultMultipleLocation : this._defaultLocation}
                    defaultZoom={11}
                    onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
                    yesIWantToUseGoogleMapApiInternals
                >
                    {!this.props.isMultipleSelect && this.props.locations.map((location, i) =>
                        <Markers
                            data={location}
                            disabled={this.state.selectedLocation != null && this.state.selectedLocation != location}
                            lat={location.lat}
                            lng={location.lng}
                            text={location.text}
                            key={i}
                            onSelect={() => {
                                this.props.onPropertySelect(location);
                            }}
                            isOffencesArea={location.isOffencesArea}
                            isTrafficArea={location.isTrafficArea}
                        />
                    )}
                    {this.props.isMultipleSelect && this.props.locations.map((location, i) =>
                        <Markers
                            data={location}
                            disabled={(this.state.multipleSelectedLocation != null && this.state.multipleSelectedLocation.filter(loc => loc === location).length === 0)}
                            lat={location.lat}
                            lng={location.lng}
                            text={location.text}
                            key={i}
                            onSelect={() => {
                                this.props.onPropertySelect(location);
                            }}
                            isOffencesArea={location.isOffencesArea}
                            isTrafficArea={location.isTrafficArea}
                        />
                    )}
                </GoogleMapReact>
        );
    }
}

interface IGoogleMapsProps {
    address: string;
    markers?: string[];
    selectedLocation: IGoogleMapLocation;
    locations: IGoogleMapLocation[];
    onPropertySelect: (property_: any) => void;
    isMultipleSelect?: boolean,
    multipleSelectedLocation?: IGoogleMapLocation[]
}

interface IGoogleMapsState {
    selectedLocation: IGoogleMapLocation | null;
    reconstructMap: boolean;
    multipleSelectedLocation: IGoogleMapLocation[] | null;
}

export interface IGoogleMapLocation {
    lat: number;
    lng: number;
    text: string;
    isOffencesArea?: boolean;
    isTrafficArea?: boolean;
}