import { IFileInfo } from "../../Components/BasicComponents/FileUploadModal";
import { IAppliedFilter, IColumnFilter, IDataRow } from "../../Components/DataGrid/Interfaces";
import { ISingleScheduleData } from "../../Components/ScheduleComponent";
import { DateTimeHelper } from "../../Helpers/DateTimeHelper";
import { PropertyScheduleModule } from "../../Models/PropertyScheduleModule";
import { Data } from "../../Property/Property";

export interface IZone extends IDataRow {
    propertyGuid: string;
    propertyName: string;
    zoneName: string;
    zoneGuid: string;
    visitor: boolean;
    daily: boolean;
    monthly: boolean;
    bookingType: string;
    priceRange: string;
    numberOfSpots: number;
    schedules: ISingleScheduleData[];
    selectedFeatures: number[];
    otherFeatures: string;
    description: string;
    whenYouArrive: string;
    spotGuids: string[];
    address: string;
    dailyTimeBlocks: IDailyTimeBlock[];
    weeklyTimeBlocks: IWeeklyTimeBlock[];
}

export interface IZoneFilter extends IAppliedFilter {
    property: IColumnFilter;
}


export interface IPropertyDetails {
    propertyGuid: string;
    propertyName: string;
    propertyAbout: string;
    propertyAddress1: string;
    propertyAddress2: string;
    propertyCity: string;
    propertyState: string;
    propertyCountry: string;
    propertyZipCode: string;
    propertyQuantity: number;
    propertyMinPrice: number;
    propertyMaxPrice: number;
    propertyAmenities: number[];
    propertySchedule: PropertyScheduleModule[];
    propertyOwnerName: string;
    propertyOwnerGuid: string;
    managementCompanyName: string;
    managementCompanyGuid: string;
    propertyStatusId: number;
    images: IFileInfo[];
    spots: ISpotBasics[];
    otherFeatures: string;
}

export interface ISpotBasics {
    spotGuid: string;
    spotName: string;
}

export interface ISpotFeature {
    spotFeatureId: number;
    featureName: string;
    featureDescription?: string | null;
    isActive?: boolean | null;
    isAmenity: boolean;
}

export interface IAdditionalFeature {
    i: string;
    n: string;
    a: number;
    e: number;
}


export function getScheduleSummaryText(schedule_: ISingleScheduleData): string[] {
    let daySelectionLabel = "None";
    let timeSelectionLabel = "N/A";

    if (schedule_.selectedDays && schedule_.selectedDays.length) {
        let selectedDays = schedule_.selectedDays.sort();

        // Check if single day
        if (selectedDays.length == 1) {
            daySelectionLabel = DateTimeHelper.midWeekDayLabels[selectedDays[0] - 1];
        }
        // Check if all days
        else if (selectedDays.length == 7) {
            daySelectionLabel = "All Days";
        }
        // Check if in sequence
        else if (selectedDays[0] + selectedDays.length - 1 == selectedDays[selectedDays.length - 1]) {
            daySelectionLabel = `${DateTimeHelper.shortWeekDayLabels[selectedDays[0] - 1]}-${DateTimeHelper.shortWeekDayLabels[selectedDays[selectedDays.length - 1] - 1]}`;
        }
        // Show as comma seperated
        else {
            daySelectionLabel = selectedDays.map(d => DateTimeHelper.shortWeekDayLabels[d - 1]).join(",")
        }
    }
    if (schedule_.selectedTimeRange) {
        timeSelectionLabel = `${DateTimeHelper.minutesToTimeString(schedule_.selectedTimeRange.start, false)} - ${DateTimeHelper.minutesToTimeString(schedule_.selectedTimeRange.end, false)}`;
    }

    return [daySelectionLabel, timeSelectionLabel];
}

export interface ISpotBlockData {
    dailySpotScheduleGuid: string;
    spotGuid: string;
    startUtc: string;
    endUtc: string;
    start: Date;
    end: Date;
    scheduleStatusId: number;
}


export interface ISpotBlockDataV2{
    dontRepeatEndDate?: Date;
    endTimeString: string;
    metadata: string;
    monthEndDate?: Date;
    propertyGuid: string;
    reasonType: number;
    repeatEndDate?: Date;
    repeatType: number;
    spotBlockGuid: string;
    spotGuid: string;
    startDate: Date;
    startTimeString: string;
    weeks: number[];
    weekDays: string[];
}

export interface IDailyTimeBlock {
    propertyScheduleGuid: string;
    propertyGuid: string;
    scheduleId: number;
    scheduleName: string;
    bookingPrice: number;
    isActive: boolean;
    originalEndTime: string;
    originalStartTime: string;
    startTime: string;
    endTime: string;
    originalEndTimeConverted: number;
    originalStartTimeConverted: number;
    minTime: number;
    maxTime: number;
    maximumTimeLimit: number;
}

export interface IWeeklyTimeBlock {
    bookingPrice: number;
    isActive: boolean;
    zoneGuid: string;
    propertyMonthlyRateGuid: string;
    weeklyTimeBlockId: number;
    scheduleName: string;
    stripePriceId: string;
    description: string;
}