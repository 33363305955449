import React from "react";
import { FormControl, InputLabel, Button } from "@material-ui/core";
import "./WeekDaySelector.css";
import { DateTimeHelper } from "../Helpers/DateTimeHelper";


export default class WeekDaySelector extends React.Component<IWeekDaySelectorProps, IWeekDaySelectorState> {
    private _weekDays: string[] = DateTimeHelper.shortWeekDayLabels;
    private _defaultSelection = [1, 2, 3, 4, 5, 6, 7];
    private _defaultAllowed = [1, 2, 3, 4, 5, 6, 7];

    constructor(props_: IWeekDaySelectorProps) {
        super(props_);
        let selectedDays = this.props.selectedDays ? this.props.selectedDays : this._defaultSelection;
        let allowedDays = this.props.allowedDays ? this.props.allowedDays : this._defaultAllowed;
        this.state = {
            selectedDays: selectedDays,
            allowedDays: allowedDays
        };
    }

    componentDidMount() {
        let selectedDays = this.state.selectedDays.filter(d => { return this.state.allowedDays.indexOf(d) > -1; });
        this.setState({
            selectedDays: selectedDays
        }, () => {
            if (this.props.onDaySelectionChange) {
                this.props.onDaySelectionChange(selectedDays);
            }
        });
    }

    componentDidUpdate(oldProps_: IWeekDaySelectorProps) {
        let updatedState: IWeekDaySelectorState = {} as any;
        let changed = false;

        if (oldProps_.selectedDays != this.props.selectedDays && this.props.selectedDays != undefined) {
            updatedState.selectedDays = this.props.selectedDays;
            changed = true;
        }
        if (oldProps_.allowedDays != this.props.allowedDays) {
            updatedState.allowedDays = this.props.allowedDays ? this.props.allowedDays : this._defaultAllowed;
            if (updatedState.allowedDays) {
                updatedState.selectedDays = this.state.selectedDays.filter(d => {
                    return updatedState.allowedDays.indexOf(d) > -1;
                });
            }
            changed = true;
        }

        if (changed) {
            this.setState(updatedState, () => {
                if (this.props.onDaySelectionChange) {
                    this.props.onDaySelectionChange(updatedState.selectedDays);
                }
            });
        }
    }

    render() {
        return (
            <FormControl>
                <InputLabel shrink={true} className="repeat-on-label">Schedule</InputLabel>
                <div className="mt-4">
                    {
                        this._weekDays.map((dayKey_: string, dayIndex_: number) => (
                            <Button
                                key={dayIndex_ + 1}
                                className="button-day-selector rounded-circle mx-1"
                                variant="contained"
                                disabled={this.isDayDisabled(dayKey_, dayIndex_ + 1) || this.props.disabled}
                                color={this.isDaySelected(dayKey_, dayIndex_ + 1) ? "primary" : "secondary"}
                                disableElevation
                                onClick={() => this.onDayClick(dayKey_, dayIndex_ + 1)}
                            >
                                {dayKey_}
                            </Button>
                        ))
                    }
                </div>
            </FormControl>
        );
    }

    isDaySelected = (dayKey_: string, dayIndex_: number) => {
        return this.state.selectedDays.indexOf(dayIndex_) > -1;
    }

    isDayDisabled = (dayKey_: string, dayIndex_: number) => {
        return this.state.allowedDays.indexOf(dayIndex_) === -1;
    }

    onDayClick = (dayKey_: string, dayIndex_: number) => {
        let selectedDays = this.state.selectedDays;
        let dayIndexIndex = selectedDays.indexOf(dayIndex_);
        if (dayIndexIndex > -1) {
            selectedDays.splice(dayIndexIndex, 1);
        }
        else {
            selectedDays.push(dayIndex_);
        }

        this.setState({
            selectedDays: selectedDays
        }, () => {
            if (this.props.onDaySelectionChange) {
                this.props.onDaySelectionChange(selectedDays);
            }
        });
    }
}

interface IWeekDaySelectorProps {
    selectedDays?: number[];
    allowedDays?: number[];
    disabled?: boolean;
    onDaySelectionChange?: (selectedDays_: number[]) => void;
}

interface IWeekDaySelectorState {
    selectedDays: number[];
    allowedDays: number[];
}