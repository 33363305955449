import React from 'react';
import { TextField, Dialog, AppBar, Toolbar, IconButton, Typography, Slide, Button, Grid, DialogContent, DialogActions } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import './AddUser.css';
import { CountryListModel } from '../Models/CountryListModel';
import { PropertyItem, IPropertyOwner } from '../Models/PropertyModel';
import PropertyService from '../Services/PropertyService';
import CompanyService from '../Services/CompanyService';
import { CompanyItem } from '../Models/CompanyModel';
import { NewCompanyDetails, CompanyOwnerItem } from '../Models/NewCompanyDetails';

import { ConfigService } from '../Services/ConfigService';
import { ShowAlert } from '../Common/ShowAlert';
import { GetUserDetailsService } from '../Services/GetUserDetailsService';
import LoaderComponent from './LoaderComponent';
import BaseService from '../Services/BaseService';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class AddCompany extends React.Component<IAddCompanyProps, IAddCompanyState> {
    private _propertyService: PropertyService;
    constructor(props: IAddCompanyProps) {
        super(props);
        this.state = {
            userType: [
                'Property Owner',
                'Company Admin',
                'Manager',
                // 'Tenants',
            ],
            open: this.props.requestType === 'popup' ? true : false,
            selectedUserType: this.props.userRole ? this.props.userRole : '',
            isValidMobileNumber: true,
            countries: [
                { code: 'CA', label: 'Canada', phone: '1' },
                { code: 'IN', label: 'India', phone: '91' },
                { code: 'US', label: 'United States', phone: '1' },
            ],
            country: { code: 'CA', label: 'Canada', phone: '1' },
            selectedProperty: {
                propertyOwnerGuid: '',
                propertyOwnerName: ''
            },
            propertyList: [],
            selectedCompany: {
                companyGuid: '',
                companyName: ''
            },
            companyOwnerList: [],
            newCompanyDetails: {
                companyAddress1: '',
                companyAddress2: '',
                companyCity: '',
                companyContactGuid: '',
                companyCountry: '',
                companyGuid: '',
                companyName: '',
                companyOwnerGuid: '',
                companyOwnerName: '',
                companyState: '',
                companyStatusId: 1,
                companyZipCode: '',
                primaryContact: ''
            },
            selectedCompanyOwner: {
                userGuid: '',
                userName: ''
            },
            showBlocker: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this._propertyService = new PropertyService();

    }

    public componentDidMount() {
        this._propertyService.getPropertyOwners().then(response => {
            if (response.ok) {
                response.text().then(resp => {
                    let propertyList: any[] = JSON.parse(resp);

                    let propertyItems = propertyList.map(m => {
                        return m
                    });

                    this.setState({
                        propertyList: propertyItems
                    });
                });
            }
            /* else {
                if (response.status === 401) {
                    this.accessTokenExpired();
                } else {
                    response.text().then(resp => {
                        console.log(response);
                    });
                    ShowAlert("", 'There was an error processing a your request.', "error");
                }
            }*/
        });

        CompanyService.getUserList().then(response => {
            if (response.ok) {
                response.text().then(resp => {
                    let userList: any[] = JSON.parse(resp);

                    let userItems = userList.map(m => {
                        return {
                            userGuid: m.propertyOwnerGuid,
                            userName: m.propertyOwnerName
                        }
                    });

                    this.setState(
                        {
                            companyOwnerList: userItems
                        }
                    );
                })
            }
            /* else {
                if (response.status === 401) {
                    this.accessTokenExpired();
                } else {
                    response.text().then(resp => {
                        console.log(response);
                    });
                    ShowAlert("", 'There was an error processing a your request.', "error");
                }
            }*/
        });
    }

    public render() {
        if (this.props.requestType === 'popup') {
            return (
                <form className="user-form-container" onSubmit={this.handleSubmit}>
                    {/* <LoaderComponent loaderVisible={this.state.showBlocker} /> */}
                    {
                        this.DialogboxElements()
                    }
                </form>
            )
        } else {
            return (
                <div className="right-menu-body add-user-menu">
                    <form className="user-form-container" onSubmit={this.handleSubmit}>
                        {/* <LoaderComponent loaderVisible={this.state.showBlocker} /> */}
                        {
                            this.companyFormElements()
                        }
                        <Grid container spacing={2} className="button-container">
                            <Grid item xs>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className='list-button'
                                >
                                    Save
                            </Button>
                            </Grid>
                            <Grid item xs>
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className='list-button list-button-cancel'
                                    onClick={() => this.closeDialog()}
                                >
                                    Cancel
                            </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            )
        }
    }

    private DialogboxElements() {
        return (
            <Dialog open={this.state.open} onClose={() => this.closeDialog()} aria-labelledby="customized-dialog-title" className="popup-add-module">
                <AppBar className='add-user-appbar'>
                    <Toolbar>
                        <Typography variant="h6" className='add-user-appbar-title'>Add {this.props.title}</Typography>
                        <IconButton
                            edge="start"
                            color="default"
                            onClick={() => this.closeDialog()} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent dividers>
                    {
                        this.companyFormElements()
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}
                        className='list-button'>
                        save
                        </Button>
                </DialogActions>
            </Dialog>
            /*<Dialog fullScreen open={this.state.open} onClose={() => this.closeDialog()} TransitionComponent={Transition}>
                <AppBar className='add-user-appbar'>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="default"
                            onClick={() => this.closeDialog()} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className='add-user-appbar-title'>Add {this.props.title}</Typography>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={this.handleSubmit}
                            className='list-button'>
                            save
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className="popup-body">
                    {
                        this.companyFormElements()
                    }
                </div>
            </Dialog>*/
        );


    }

    private companyFormElements() {
        return (
            <div className="form-sub-container">
                {/* <h4 className="list-sub-header">About</h4> */}
                <TextField
                    error={this.state.newCompanyDetails.companyName.length < 3}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="company_name"
                    label="Property Management Company"
                    name="company_name"
                    onChange={(e_: any) => {
                        this.state.newCompanyDetails.companyName = e_.target.value;
                        this.setState({
                            newCompanyDetails: this.state.newCompanyDetails
                        })
                    }}
                    value={this.state.newCompanyDetails.companyName}
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="primaryContact"
                    label="Primary Contact"
                    name="primaryContact"
                    onChange={(e_: any) => {
                        this.state.newCompanyDetails.primaryContact = e_.target.value;
                        this.setState({
                            newCompanyDetails: this.state.newCompanyDetails
                        })
                    }}
                    value={this.state.newCompanyDetails.primaryContact}
                />

                {/* <h4 className="list-sub-header">Management</h4> */}

                {/* <Autocomplete
                    id="add_propertyOwner"
                    className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
                    options={this.state.companyOwnerList}
                    value={this.state.selectedCompanyOwner}
                    defaultValue={this.state.selectedCompanyOwner}
                    onChange={(event: any, values: any) => {
                        this.state.newCompanyDetails.companyOwnerGuid = values ? values.userGuid : '';
                        this.state.newCompanyDetails.companyOwnerName = values ? values.userName : '';
                        this.setState({
                            selectedCompanyOwner: values,
                            newCompanyDetails: this.state.newCompanyDetails
                        })
                    }}
                    getOptionLabel={(option) => option.userName}
                    renderInput={(params) => <TextField {...params} label="Owner" error={
                        this.state.selectedCompanyOwner == null || this.state.selectedCompanyOwner.userGuid == ''
                    } variant="outlined" />}
                /> */}

                {/*<Autocomplete
                    multiple
                    id="add_ManagersList"
                    className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
                    options={props.userList}
                    value={selectedManagers}
                    defaultValue={selectedManagers}
                    onChange={(event: any, values: any) => {
                        setSelectedManagers(values);
                    }}
                    getOptionLabel={(option) => option.userName}
                    renderInput={(params) => {
                        return (
                            <TextField {...params} label="Manager(s)" variant="outlined" />
                        )
                    }}
                /> */}
            </div>
        )
    }

    private closeDialog() {
        this.setState({
            open: false
        });
        if (this.props.popupState) {
            this.props.popupState(false)
        }

    }

    private validateEmail(email: string) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    private isValidPhoneNumber(value: any, countryCode: string) {
        var _isValidMobileNumber = ((value.length - countryCode.length) < 10);
        return _isValidMobileNumber;
    }

    private accessTokenExpired() {
        localStorage.clear();
        window.location.href = '/';
    }

    private handleSubmit(event: any) {
        let isValue = true;
        let errorMsg = "";

        if (this.state.newCompanyDetails.companyName == '') {
            isValue = false;
            errorMsg = "Company Name";
            // ShowAlert('Error!', 'Please enter the <b>Company Name</b>.', 'error');
        }
        /*else if (this.state.newCompanyDetails.companyOwnerGuid == "") {
            isValue = false;
            // ShowAlert('Error!', 'Please select the <b>Owner</b>.', 'error');
            errorMsg = errorMsg != "" ? "and Owner" : "Owner";
        }*/

        if (!isValue) {
            ShowAlert('Error!', 'Please enter the <b>mandatory field(s)</b>.', 'error');
        }

        if (isValue) {
            this.setState({
                showBlocker: true
            })
            this.addNewCompany().then(response => {
                if (response.ok) {
                    response.json().then(resp => {
                        this.state.newCompanyDetails.companyGuid = resp;

                        this.setState({
                            newCompanyDetails: this.state.newCompanyDetails,
                            showBlocker: false
                        });

                        ShowAlert('Success!', 'The <b>' + this.state.newCompanyDetails.companyName + '</b> was added.', 'success');

                        if (this.props.onAddedCompany) {
                            this.props.onAddedCompany(this.state.newCompanyDetails);
                        }

                    });
                } else {
                    this.setState({
                        showBlocker: false
                    });
                    /*if (response.status === 401) {
                        this.accessTokenExpired();
                    } else {
                        response.text().then(resp => {
                            console.log(resp);
                        });
                        ShowAlert("", 'There was an error processing a your request.', "error");
                    }*/
                }
            });
        }
        event.preventDefault();
    }

    private addNewCompany() {
        var postData = {
            "companyName": this.state.newCompanyDetails.companyName,
            "primaryContact": this.state.newCompanyDetails.primaryContact,
            "companyOwnerName": this.state.newCompanyDetails.companyOwnerName,
            "companyOwnerGuid": this.state.newCompanyDetails.companyOwnerGuid,
            "manager": []
        };

        // console.log(postData);

        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + 'company/insertupdate', {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postData)
                })
                //.then(response => response.json());
            });
    }
}

interface IAddCompanyProps {
    requestType?: string,
    userRole?: string,
    title?: string,
    popupState?: (s_: boolean) => void,
    onAddedCompany: (u_: NewCompanyDetails) => void
}
interface IAddCompanyState {
    userType: string[],
    open: boolean,
    selectedUserType: string,
    isValidMobileNumber: boolean,
    countries: CountryListModel[],
    country: CountryListModel,
    selectedProperty: IPropertyOwner,
    propertyList: IPropertyOwner[],
    selectedCompany: CompanyItem,
    companyOwnerList: CompanyOwnerItem[],
    selectedCompanyOwner: CompanyOwnerItem,
    newCompanyDetails: NewCompanyDetails,
    showBlocker: boolean
}

export default AddCompany;