import React from "react";
import ScheduleComponent from "../Components/ScheduleComponent";
import ImageUploadControl from "../Components/BasicComponents/ImageUploadControl";
import FileUploadControl from "../Components/BasicComponents/FileUploadControl";
import FilePicker from "../Components/BasicComponents/FilePicker";

export default class UITest extends React.Component {
    render() {
        return (
            <section className="container">
                {/* <ScheduleComponent
                    selectedDays={[1, 2, 3, 4, 5]}
                    selectedTimeRange={{
                        start: 600,
                        end: 1200
                    }}
                    selectedPriceRange={{
                        start: 4,
                        end: 10
                    }}

                    onChange={s_ => console.log(s_)}
                /> */}

                <FilePicker
                    label="Attach Property Images"
                    descriptionSubset="Select Property Images"
                    filter=".jpg,.jpeg,.png,.bmp,.gif,.tiff"
                    onFilesSelectionComplete={() => {}}
                />
                <br />
                <FilePicker
                    label="Attach Spot Images"
                    descriptionSubset="spot images"
                    filter=".jpg,.jpeg,.png,.bmp,.gif,.tiff"
                    onFilesSelectionComplete={() => {}}
                />
                <br />
                <FilePicker
                    label="Attach single document"
                    descriptionSubset="document"
                    filter=".csv"
                    single={true}
                    onFilesSelectionComplete={() => {}}
                />
                <br />
                <FileUploadControl
                    label="CSV File"
                    filter=".csv"
                    onUpload={(f_: Blob, n_: string) => {
                        console.log("Uploaded New File", f_, n_);
                    }}
                    onRemove={() => {
                        console.log("Removed File");
                    }}
                    onUploadRejected={() => {
                        alert("Unsupported file format!");
                    }}
                />
{/* 
                <ImageUploadControl
                    label="Property Image"
                    onUpload={(f_: Blob, n_: string) => {
                        console.log("Uploaded New File", f_, n_);
                    }}
                    onRemove={() => {
                        console.log("Removed File");
                    }}
                    onUploadRejected={() => {
                        alert("Unsupported file format!");
                    }}
                />
                <br /><br /><br />
                <FileUploadControl
                    label="CSV File"
                    filter=".csv"
                    onUpload={(f_: Blob, n_: string) => {
                        console.log("Uploaded New File", f_, n_);
                    }}
                    onRemove={() => {
                        console.log("Removed File");
                    }}
                    onUploadRejected={() => {
                        alert("Unsupported file format!");
                    }}
                /> */}
            </section>
        );
    }
}