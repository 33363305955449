import React from "react";
import DataGrid from "../../Components/DataGrid/DataGrid";
import { IDataGridColumn, DataGridFilterType } from "../../Components/DataGrid/Interfaces";
import PropertyService from "../../Services/PropertyService";
import { IActiveSpotList } from "./Interfaces";
import { DateTimeHelper } from "../../Helpers/DateTimeHelper";
import { ShowAlert } from "../../Common/ShowAlert";

export default class ActiveSpotList extends React.Component<IActiveSpotListProps, IActiveSpotListState> {
    private _propertyService: PropertyService;
    private _spotListColumns: IDataGridColumn<IActiveSpotList>[] = [
        {
            key: "friendlySpotId",
            name: "SPOT ID"
        },
        {
            key: "spotTotalHrsbooked",
            name: "TIME BOOKED ON"
        },
        {
            key: "spotHrsLeft",
            name: "TIME LEFT"
        },
        {
            key: "paidAmount",
            name: "AMOUNT",
            textAlignment: "right",
            contentProvider: (row_: IActiveSpotList) => {
                return (
                    <span>
                        ${row_.paidAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                );
            }
        }
    ]

    private _activeSpotList: IActiveSpotList[] = [
        {
            friendlySpotId: 'L101',
            spotTotalHrsbooked: '24:00h',
            spotHrsLeft: '00:30h',
            paidAmount: 168,
            endUtc: new Date().toString(),
            spotGuid: '',
            startUtc: new Date().toString(),
        },
        {
            friendlySpotId: 'L102',
            spotTotalHrsbooked: '2:00h',
            spotHrsLeft: '00:30h',
            paidAmount: 14,
            endUtc: new Date().toString(),
            spotGuid: '',
            startUtc: new Date().toString(),
        },
        {
            friendlySpotId: 'L103',
            spotTotalHrsbooked: '3:00h',
            spotHrsLeft: '01:30h',
            paidAmount: 21,
            endUtc: new Date().toString(),
            spotGuid: '',
            startUtc: new Date().toString(),
        },
        {
            friendlySpotId: 'L104',
            spotTotalHrsbooked: '1:00h',
            spotHrsLeft: '02:30h',
            paidAmount: 17,
            endUtc: new Date().toString(),
            spotGuid: '',
            startUtc: new Date().toString(),
        },
        {
            friendlySpotId: 'L105',
            spotTotalHrsbooked: '2:00h',
            spotHrsLeft: '00:30h',
            paidAmount: 14,
            endUtc: new Date().toString(),
            spotGuid: '',
            startUtc: new Date().toString(),
        },
    ]

    constructor(props: any) {
        super(props);
        this.state = {
            propertyID: this.props.propertyID,
            allProperties: [],
            searchTerm: "",
            isFiltered: false,
            isDataLoaded: false,
        };

        this._propertyService = new PropertyService();
    }

    public componentDidMount = () => {

        this._propertyService.getPropertyActiveSpotInformation(this.state.propertyID).then(response => {
            if (response.ok) {
                response.json().then(p => {
                    if (p) {
                        console.log(p);

                        let mappedItems = p.map((r: any) => {
                            r.spotTotalHrsbooked = DateTimeHelper.getDiffTime(r.startUtc, r.endUtc, true) + "h";
                            r.spotHrsLeft = DateTimeHelper.getDiffTime(r.startUtc, r.endUtc, false) + "h";
                            return r;
                        });

                        this.setState({
                            isDataLoaded: true,
                            allProperties: mappedItems
                        })
                    }
                })
            } 
            /*else {
                if (response.status === 401) {
                    this.accessTokenExpired();
                } else {
                    response.text().then(resp => {
                        console.log(response);
                    });
                    ShowAlert("", 'There was an error processing a your request.', "error");
                }
            }*/
        });

        /*let mappedItems = this._activeSpotList.map(r => {
            return r;
        });

        this.setState({
            allProperties: mappedItems
        })*/
    }

    render() {
        if (!this.state.isDataLoaded) {
            return null;
        }
        return (
            <DataGrid
                title="Spot"
                data={this.state.allProperties}
                columns={this._spotListColumns}
                isRowsNonSelectable={true}
                isNoMoreLink={true}
            />
        );
    }

    private accessTokenExpired() {
        localStorage.clear();
        window.location.href = '/';
    }

    private setRandomRevenue() {
        let revenue: number = Math.floor(Math.random() * 100) + 50;
        return revenue;
    }
}

interface IActiveSpotListProps {
    propertyID: string
}

interface IActiveSpotListState {
    isDataLoaded: boolean;
    propertyID: string,
    allProperties: IActiveSpotList[];
    searchTerm: string;
    isFiltered: boolean;
}