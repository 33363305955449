import * as React from 'react';
import clsx from 'clsx';
import {createStyles, lighten, makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
// import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import SendIcon from '@material-ui/icons/Send';
import {ConfigService} from '../Services/ConfigService';
import * as CommonString from '../CommonString';
import {ShowAlert} from '../Common/ShowAlert';
import BaseService from '../Services/BaseService';

interface Data {
  name: string;
  email: string;
  countryCode: number;
  cellNumber: string;
  role: string;
  action: string;
  status: string;
  // spot: string;
  // revenue:string;
}

interface UserList {
  Name: string,
  Email: string,
  CountryCode: number,
  MobileNumber: string,
  UserRole: number,
  UserRoleName: string
}

let selectedRows: Data[] = [];
let setSelectedCallback: (d_: Data[]) => void;

// function createData(
//     name: string,
//     email: string,
//     countryCode: number,
//     cellNumber: string,
//     role: string,
//     action: string,
//     status: string,
//     // spot: string,
//     // revenue:string
// ): Data {
//     return { name, email, countryCode, cellNumber, role, action, status };
// }

// var rows: Data[] = [
//     // createData('Doria Cross', 'vishnuvardhanan.s@congruentindia.com', 91, '9677977617', 'Company Admin', 'action', 'pending'),
//     // createData('Adrienne Rowe', 'nisarahmed.h@congruentindia.com', 91, '9500080606', 'Company Admin', 'action', 'sent'),
//     // createData('Rolf Acevedo', 'prakash.R@congruentindia.com', 91, '9790878168', 'Company Admin', 'action', 'wip'),
//     // createData('Ima Terry', 'vinodkumar.v@congruentindia.com', 91, '9600077566', 'Owner', 'action', 'wip'),
//     // createData('Franklin', 'sivakumar.n@congruentindia.com', 91, '9840734554', 'Owner', 'action', 'sent'),
//     // // createData('Joie Shea', 'Raja.K@congruentindia.com', 91, '7708665495', 'Owner', 'action', 'wip'),
//     // createData('Kenaniah Slatescar', 'Princedhanraj.J@congruentindia.com', 91, '9894360721', 'Property Manager', 'action', 'wip'),
// ];

//let rows = fetchOnboardUsers;

// function fetchOnboardUsers() {
//     let data: Data[];
//     fetchOnboardUsersAPI().then(response => {
//         //let _Data: UserList = JSON.parse(response);
//         var _Data = JSON.parse(response);

//         for (var i = 0; i < _Data.length; i++) {
//             let _newData: Data = {
//                 name: _Data[i].Name,
//                 email: _Data[i].Email,
//                 countryCode: _Data[i].CountryCode,
//                 cellNumber: _Data[i].cellNumber,
//                 role: getRole(_Data[i].UserRole),
//                 action: 'action',
//                 status: 'sent'
//             }

//             data.push(_newData);
//         }

//         return data;
//     });
// }

function getRole(roleNumber: number) {
  var role = "";

  switch (roleNumber) {
    case 3:
      role = "Owner";
      break;
    case 4:
      role = "Management Company";
      break;
    case 5:
      role = "Property Manager";
      break;
    case 6:
      role = "Tenant";
      break;
  }

  return role;
}


function fetchOnboardUsersAPI() {
  return ConfigService.getBaseUrl()
    .then(url => {
      return BaseService.sendRequest(url + 'user/onboardinglist', {
        method: 'get',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      })
      // .then(response => response.json());
    });
}


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {id: 'name', numeric: false, disablePadding: true, label: 'Name'},
  {id: 'email', numeric: false, disablePadding: true, label: 'Email'},
  {id: 'countryCode', numeric: false, disablePadding: true, label: 'Country Code'},
  {id: 'cellNumber', numeric: false, disablePadding: true, label: 'Cell Phone'},
  {id: 'role', numeric: false, disablePadding: true, label: 'Role'},
  {id: 'action', numeric: false, disablePadding: true, label: 'Actions'},
  // { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
  // { id: 'protein', numeric: true, disablePadding: false, label: 'Tenant' },
  // { id: 'spot', numeric: true, disablePadding: false, label: 'Spot' },
  // { id: 'revenue', numeric: true, disablePadding: false, label: 'Revenue' },
];


interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;

}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{'aria-label': 'select all desserts'}}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : (headCell.id === 'action' || headCell.id === 'status') ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            className={classes.headerStyle}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
  }),
);

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const PostSendRegistrationLinkToSelected = () => {
  let multiInvitePayload = selectedRows.map(r => {
    let rawData = (r as any).rawData;
    let role: number = 0;

    switch (rawData.UserRoleName) {
      case "Owner":
        role = 3;
        break;
      case "Company Admin":
        role = 4;
        break;
      case "Property Manager":
        role = 5;
        break;
      case "Tenant":
        role = 6;
        break;
    }

    return {
      "Name": rawData.Name,
      "Email": rawData.Email,
      "CountryCode": rawData.CountryCode,
      "MobileNumber": rawData.MobileNumber,
      "UserRole": role,
    };
  });

  ConfigService.getBaseUrl().then(url => {
    BaseService.sendRequest(url + CommonString.SERVICE_SEND_LINK_FOR_ONBOARD, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(multiInvitePayload)
    })
      .then(r => {
        ShowAlert('', 'Registration link sent successfully', 'success');

        if (setSelectedCallback) {
          setSelectedCallback([]);
        }
      });
  });
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const {numSelected} = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Onboarding
        </Typography>
      )}
      {numSelected > 0 ? (
        // <Tooltip title="Delete">
        //     <IconButton aria-label="delete">
        //         <DeleteIcon />
        //     </IconButton>
        // </Tooltip>
        <Tooltip title="Send to selected users" onClick={() => {
          PostSendRegistrationLinkToSelected()
        }}>
          <IconButton aria-label="send">
            <SendIcon style={{cursor: "pointer"}}>
            </SendIcon>
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon/>
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    headerStyle: {
      fontWeight: 'bold'
    }
  }),
);

// interface MaterialGridProps {
//     showBlocker: boolean
// }

// interface MaterialGridState {
//     showBlocker: boolean
// }

// class MaterialGrid extends React.Component<MaterialGridProps, MaterialGridState> {

//     constructor(props: MaterialGridProps) {
//         super(props);

//         this.state = {
//             showBlocker: false,

//         };


//     }

//     public componentDidMount = () => {

//     }

//     public render() {
//         return (
//             <div className={'App login-page' + (this.state.showBlocker ? ' show-blocker' : '')}>
//                 <div className="login-banner"></div>
//                 <div className="login-container">
//                     {
//                         this.EnhancedTable()
//                     }
//                 </div>
//             </div>
//         );
//     }

export default class EnhancedTable extends React.Component<{}, { dataLoaded: boolean, data: Data[] }> {
  constructor(props: {}) {
    super(props);
    this.state = {data: [], dataLoaded: false};
  }

  componentDidMount() {
    fetchOnboardUsersAPI()
      .then(response => {
        if (response.ok) {
          response.text().then(resp => {
            // let _response = JSON.parse(resp);
            let _response = JSON.parse(JSON.parse(resp));
            if (_response.code == 200) {
              let rowItems: any[] = JSON.parse(_response.data);

              let mappedItems = rowItems.map(r => {
                return {
                  name: r.Name,
                  email: r.Email,
                  countryCode: r.CountryCode,
                  cellNumber: r.MobileNumber,
                  role: r.UserRoleName,
                  action: r.Action,
                  status: r.UserStatusID,
                  rawData: r
                };
              });

              this.setState(
                {
                  data: mappedItems,
                  dataLoaded: true
                }
              );
            }
          })
        } else {
          localStorage.clear();
          window.location.href = '/';
        }

      });
  }

  render() {
    if (this.state.dataLoaded) {
      return <XEnhancedTable data={this.state.data}/>;
    } else {
      return null;
    }
  }
}

function XEnhancedTable(props: { data: Data[] }) {
  let rows = props.data;
  // const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
  const [selected, setSelected] = React.useState<Data[]>([]);
  const [page, setPage] = React.useState(0);
  // eslint-disable-next-line
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  selectedRows = selected;
  setSelectedCallback = setSelected;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows;
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const PostSendRegistrationLink = (row: Data) => {

    var role: number = 0;

    switch (row.role) {
      case "Owner":
        role = 3;
        break;
      case "Company Admin":
        role = 4;
        break;
      case "Property Manager":
        role = 5;
        break;
      case "Tenant":
        role = 6;
        break;
    }

    var postData = {
      'Name': row.name,
      'Email': row.email,
      'CountryCode': row.countryCode,
      'MobileNumber': row.cellNumber,
      'UserRole': role
    };


    // "http://192.168.61.56/grydapi/onboarding"
    ConfigService.getBaseUrl().then(url => {
      BaseService.sendRequest(url + CommonString.SERVICE_SEND_LINK_FOR_ONBOARD, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify([postData])
      })
        .then(response => response.json())
        .then(response => {
          ShowAlert('', 'Registration link sent successfully', 'success');
        })
        .catch(error => {
          ShowAlert('', 'Error in sending link', 'error');
        });
    })

  };

  // eslint-disable-next-line
  const handleClick = (event: React.MouseEvent<unknown>, name: string, row: Data) => {
    const selectedIndex = selected.indexOf(row);
    let newSelected: Data[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const SetStatusColor = (status: string) => {
    var color = '#000000';

    if (status === 'sent') {
      color = '#099145';
    } else if (status === 'pending') {
      color = '#f1b350';
    } else if (status === 'wip') {
      color = '#e36a5d';
    }

    return color;
  }

  // const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setDense(event.target.checked);
  // };

  const isSelected = (data_: Data) => selected.indexOf(data_) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={useStyles().root}>
      <Paper className={useStyles().paper}>
        <EnhancedTableToolbar numSelected={selected.length}/>
        <TableContainer>
          <Table
            className={useStyles().table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={useStyles()}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name + index}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, row.name, row)}
                          checked={isItemSelected}
                          inputProps={{'aria-labelledby': labelId}}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.name}
                      </TableCell>
                      <TableCell align="left" padding="none">{row.email}</TableCell>
                      <TableCell align="left" padding="none">{row.countryCode}</TableCell>
                      <TableCell align="left" padding="none">{row.cellNumber}</TableCell>
                      <TableCell align="left" padding="none">{row.role}</TableCell>
                      <TableCell align="center" padding="none" style={{paddingRight: 34}}>
                        <Tooltip title="Send" onClick={() => {
                          PostSendRegistrationLink(row)
                        }}>
                          <IconButton aria-label="send" disabled={(row.status == '3') ? true : false}>
                            <SendIcon style={{cursor: "pointer"}}>
                            </SendIcon>
                          </IconButton>
                        </Tooltip>

                      </TableCell>
                      {/* <TableCell align="center" padding="none" style={{ paddingRight: 34 }}>
                                                <Tooltip title={row.status}>
                                                    <IconButton aria-label={row.status}>
                                                        <FaLink size={'0.8em'} color={SetStatusColor(row.status)} />
                                                    </IconButton>
                                                </Tooltip>

                                            </TableCell> */}
                      {/* <TableCell align="right">{row.protein}</TableCell>
                                                <TableCell align="right">{row.spot}</TableCell>
                                                <TableCell align="right">{row.revenue}</TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{height: (dense ? 33 : 53) * emptyRows}}>
                  <TableCell colSpan={6}/>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Dense padding"
                /> */}
    </div>
  );
}

// }

// export default MaterialGrid;
