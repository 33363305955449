import { ShowCustomBlocker } from "../Components/LoaderComponent";

export default class UtilHelper {
    public static AlertBeforeWindowCloses(event: any, message: string){        
        if (window.childWindows && window.childWindows.length > 0) {            
            event.preventDefault();
            return event.returnValue = message;            
        }
        return false;
    }

    public static CloseAllWindows() {
        if (window.childWindows && window.childWindows.length > 0) {
            window.childWindows.forEach(element => {
                element.close();
            });
            window.childWindows = [];
        }
    }

    public static lanuchPopup(url: string, target?: string, showLoader?: boolean, refreshMethod?: () => any) {
        let iMyWidth;
        let iMyHeight;
        iMyWidth = (window.screen.width);
        iMyHeight = (window.screen.height);

        let params = "directories=no,titlebar=no," +
        "status=no,toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, " +
        "resizable=0,height="+iMyHeight+", width="+iMyWidth+", left=0,top=0,right=0,bottom=0";

        const windowsTarget = target ? target : "Popup";
        
        if (!window.childWindows)
                window.childWindows = [];

        window.childWindows.length === 0 && showLoader && ShowCustomBlocker(true);

        const newWindowOpen = window.open(url, windowsTarget, params);
        if (newWindowOpen) {

            newWindowOpen.moveTo(0, 0);

            newWindowOpen.resizeTo(iMyWidth, iMyHeight - 20);

            window.childWindows.push(newWindowOpen);                                                                
            const windowTimer = window.childWindows.length === 1 ? setInterval(() => {
                let isAllClosed = window.childWindows.findIndex(x => x && !x.closed) !== -1;
                if (!isAllClosed) {
                    showLoader && ShowCustomBlocker(false);
                    window.childWindows = [];
                    clearInterval(windowTimer);
                    refreshMethod && refreshMethod();
                }
            }) : 0;
        }
        else{
            showLoader && ShowCustomBlocker(false);
        }
    }
}