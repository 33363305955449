import React from "react";
import { DataGridFilterType } from "../../Components/DataGrid/Interfaces";
import BaseFilterView, { IBaseFilterViewProps, IBaseFilterViewState } from "../PartialViews/BaseFilterView";
import CheckboxListFilter from "../../Components/FilterComponents/CheckboxListFilter";
import { IProperty, IPropertyFilter } from "../Properties/Interfaces";
import { IZone, IZoneFilter } from "./Interfaces";
import SelectFilter from "../../Components/FilterComponents/SelectFilter";

export default class ZoneFilterView
    extends BaseFilterView<IBaseFilterViewProps<IZone, IZoneFilter>, IBaseFilterViewState<IZoneFilter>> {
    private _allProperty: string[] = [];

    constructor(props_: IBaseFilterViewProps<IZone, IZoneFilter>) {
        super(props_, {
            property: {
                type: DataGridFilterType.StringList,
                filterValue: []
            }
        } as IZoneFilter);

        for (let property of props_.data) {
            if (this._allProperty.indexOf(property.propertyName) == -1) {
                this._allProperty.push(property.propertyName);
            }
        }
    }

    protected renderFilterOptions = () => {
        return (
            <div className="filter-options-list">
                <SelectFilter
                    title="Property"
                    placeholderText="Search by property name"
                    items={this._allProperty}
                    selectedItems={this.state.appliedFilter.property?.filterValue as string[]}
                    onChange={selection_ => {
                        if (this.state.appliedFilter.property) {
                            this.state.appliedFilter.property.filterValue = selection_;
                            this.setState({
                                appliedFilter: this.state.appliedFilter
                            });
                        }
                    }}
                />
            </div>
        );
    }

    protected filterItems = () => {
        this._isFiltered = false;
        return this.props.data.filter(s => {
            let isValid = true;
            let filteredProperty = this.state.appliedFilter.property.filterValue as string[];

            if (filteredProperty.length > 0 && isValid) {
                this._isFiltered = true;
                isValid = filteredProperty.indexOf(s.propertyName) > -1;
            }

            return isValid;
        });
    }
}