import React from 'react';
import './Dashboard.css'
import dashboardImg from '../../Images/Dashbaord.png';
import { Checkbox, Chip, Grid, MenuItem, Select, TextField } from '@material-ui/core';

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { AnyMxRecord } from 'dns';

import { Row, Col, Container } from 'reactstrap';
import { ConfigService } from '../../Services/ConfigService';
import { GetUserDetailsService } from '../../Services/GetUserDetailsService';
import { format } from 'date-fns';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { Line, Bar } from 'react-chartjs-2';
import { IAdminDashboardInformationModel, IAdminDashboardReservationModel, widgetOptions } from './Interface';
import LoaderComponent from '../../Components/LoaderComponent';
import PropertyService from '../../Services/PropertyService';
import exportFromJSON from 'export-from-json';
import { ShowAlert } from '../../Common/ShowAlert';
import TopProperties from './TopProperties';
import TopWidgets from './TopWidgets';
import WeeklyRevenue from './WeeklyRevenue';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AvailableSpots from '../../Images/available_spots.png';
import TenantPayout from '../../Images/tenant-payout.png';
import TotalBooking from '../../Images/total-booking.png';
import SpotUtilization from '../../Images/spot-utilization.png';
import WidgetLoaderComponent from '../../Components/WidgetLoaderComponent';
import { PreserveFilters } from '../../Common/PreserveFilters';



class AdminDashboardCharts extends React.Component<DashboardChartsProps, DashboardChartsState> {
    private _propertyService: PropertyService;
    constructor(props: DashboardChartsProps) {
        super(props);

        this.state = {
            showBlocker: false,
            widgetList: [
                {
                    title: "Total Revenue",
                    api: "AdminDashboardTotalRevenueV3",
                    isGreenGg: true,
                    serviceEndpoint: 'property'
                },
                {
                    title: "Gross Margin",
                    api: "AdminDashboardTotalGrossV3",
                    serviceEndpoint: 'property'
                },
                {
                    title: "Total Bookings",
                    api: "AdminDashboardTotalBookingsV3",
                    serviceEndpoint: 'property'
                }
            ],
            propertyOption: 'YTD',
            cities: [],
            selectedCities: []
        }

        this._propertyService = new PropertyService();
    }

    componentWillMount() {
        this._loadCities();
        let searchTerm=PreserveFilters.getSearchTerm('dashboardSearch')
        this.setState({selectedCities:searchTerm?searchTerm:[]})
    }

    componentWillUnmount(){
        PreserveFilters.setSearchTerm('dashboardSearch',this.state.selectedCities)
    }

    private _loadCities() {
        this._propertyService.getAdminDashboardCities()
            .then(data => {
                if (data.ok) {
                    data.json().then(d => {
                        let _cities: string[] = ['All Cities'];
                        d.forEach((cityObj: any) => {
                            _cities.push(cityObj.cities);
                        });
                        this.setState({
                            cities: _cities,
                            
                        })
                    })
                }
            })
            .catch((error) => {
                console.error('Error:', JSON.stringify(error));
            });
    }

    public render() {
        return (
            <div>
                {/* <LoaderComponent loaderVisible={this.state.showBlocker} /> */}
                <div className="dashboard-widget-container">
                    <Grid container>
                        <Grid item xs></Grid>
                        <Grid item>
                            <Autocomplete
                                multiple
                                id="listPropertyCities"
                                className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth listPropertyCities"
                                options={this.state.cities}
                                disableCloseOnSelect
                                size="small"
                                value={this.state.selectedCities}
                                onChange={(event: any, values: any) => {
                                    let selectedItems: string[] = [];
                                    if (values.indexOf('All Cities') != -1 && this.state.selectedCities.indexOf('All Cities') == -1) {
                                        selectedItems = this.state.cities
                                    } else if (values.indexOf('All Cities') == -1 && this.state.selectedCities.indexOf('All Cities') != -1) {
                                        selectedItems = []
                                    } else if (values.indexOf('All Cities') != -1 && values.length != this.state.cities.length) {
                                        let index = values.indexOf('All Cities');
                                        values.splice(index, 1);
                                        selectedItems = values;
                                    } else if (values.indexOf('All Cities') == -1 && values.length == this.state.cities.length - 1) {
                                        selectedItems = this.state.cities
                                    }
                                    else {
                                        selectedItems = values;
                                    }

                                    this.setState({
                                        selectedCities: selectedItems
                                    })
                                }}
                                renderTags={(tagValue, getTagProps) => {
                                    return tagValue.map((option, index) => (
                                        <Chip
                                            key={'lable' + index}
                                            label={option}
                                            {...getTagProps({ index })}
                                            className={option.indexOf('All Cities') != -1 ? 'hide-chip' : ''}
                                        />
                                    ));
                                }}
                                limitTags={2}
                                openOnFocus={true}
                                selectOnFocus={true}
                                getOptionLabel={(option) => option}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                            color="primary"
                                        />
                                        {option}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => <TextField {...params} label="Cities" variant="outlined" />}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        {
                            this.state.widgetList.map((value_, index_) => (
                                <Grid item key={index_} xs>
                                    <TopWidgets
                                        widgetInfo={value_}
                                        cities={this.state.selectedCities}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                    <div className="dashboard-widget-chart-conainer">
                        {/* <Container fluid>
                            <Row>
                                <Col sm>
                                    <div className="dashboard-widget-chart">
                                        <h5>Weekly Revenue</h5>
                                        <div>
                                            <WeeklyRevenue />
                                        </div>
                                    </div>
                                </Col>
                                <Col sm>
                                    <div className="dashboard-widget-chart selected">
                                        <Grid container>
                                            <Grid item xs>
                                                <h5>Top Properties</h5>
                                            </Grid>
                                            <Grid item>
                                                <Select
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    className="dashboard-select"
                                                    value={this.state.propertyOption}
                                                    onChange={(event: any) => {
                                                        this.setState({
                                                            propertyOption: event.target.value
                                                        })
                                                    }}
                                                >
                                                    <MenuItem value={'YTD'}>YTD</MenuItem>
                                                    <MenuItem value={'MTD'}>MTD</MenuItem>
                                                    <MenuItem value={'WTD'}>WTD</MenuItem>
                                                    <MenuItem value={'LTV'}>LTV</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>

                                        <div style={{ height: '300px' }}>
                                            <TopProperties propertyOption={this.state.propertyOption} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container> */}
                        <Grid container>
                            <Grid item xs>
                                <div className="dashboard-widget-chart">
                                    <h5>Weekly Revenue</h5>
                                    <div>
                                        <WeeklyRevenue
                                            cities={this.state.selectedCities}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs>
                                <div className="dashboard-widget-chart selected">
                                    <Grid container>
                                        <Grid item xs>
                                            <h5>Top Properties</h5>
                                        </Grid>
                                        <Grid item>
                                            <Select
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                className="dashboard-select"
                                                value={this.state.propertyOption}
                                                onChange={(event: any) => {
                                                    this.setState({
                                                        propertyOption: event.target.value,
                                                        showBlocker: true
                                                    })
                                                }}
                                            >
                                                <MenuItem value={'YTD'}>YTD</MenuItem>
                                                <MenuItem value={'MTD'}>MTD</MenuItem>
                                                <MenuItem value={'WTD'}>WTD</MenuItem>
                                                <MenuItem value={'LTV'}>LTV</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <div style={{ height: '300px' }}>
                                        <TopProperties
                                            propertyOption={this.state.propertyOption}
                                            cities={this.state.selectedCities}
                                            loaderVisible={(r: boolean) => {
                                                this.setState({
                                                    showBlocker: r
                                                });
                                            }}
                                        />
                                    </div>
                                    <WidgetLoaderComponent loaderVisible={this.state.showBlocker} />
                                </div>
                            </Grid>
                        </Grid>
                        
                    </div>
                </div>
                {/* <img src={dashboardImg} width="100%" /> */}
            </div>
        )
    }

    private accessTokenExpired() {
        localStorage.clear();
        window.location.href = '/';
    }
}

interface DashboardChartsProps { }
interface DashboardChartsState {
    adminDashboardInformation?: IAdminDashboardInformationModel;
    showBlocker: boolean;
    widgetList: widgetOptions[];
    propertyOption: string;
    cities: string[];
    selectedCities: string[];
}

export default AdminDashboardCharts;