import { Checkbox, TextField, Chip, Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import SpotService from "../Services/SpotService";
import AutoCompleteBlockSpots from "../Pages/BookingsCalendar/AutoCompleteBlockSpots";
import { DateRange } from "materialui-daterange-picker";
import { format } from "date-fns";
import GrydCalendarPicker from "../Pages/BookingsCalendar/GrydCalendarPicker";
import GrydDateRangePicker from "../Pages/BookingsCalendar/GrydDateRangePicker";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import WeekDaySelector from "../Components/WeekDaySelector";
import { Row, Col } from "reactstrap";
import { EnumCalendarPosition, EnumReasonType, EnumRepeatType, IBlockReason, IBlockSpot, IDailySpotAggregation, IRepeat, IReservationChunk, ISpotBlockMetadata, SpotBlock, SpotBlockModel } from "../Pages/BookingsCalendar/interfaces";
import { RightPanelState } from "../Models/CommonInterfaces";
import { IPropertyBasics, ISpot } from "../Pages/Spots/Interfaces";
import { repeat } from "lodash";
import ArrayHelper from  "../Helpers/ArrayHelper";
import { ShowAlertwithOptions, ShowAlertwithConfirm, ShowAlert } from "./ShowAlert";
import ContactSupportView from "../Pages/BookingsCalendar/ContactSupportView";
import UserService from "../Services/UserService";
import ObjectHelper from "../Helpers/ObjectHelper";
import { IPermissionMatrix } from "../Pages/Permission/Interfaces";
import { DateTimeHelper } from "../Helpers/DateTimeHelper";

export default class AddBlockSpotView extends React.Component<IAddBlockSpotViewProps, IAddBlockSpotViewState> {
    private _spotService: SpotService;
    private _userService: UserService;

    private repeatObject: IRepeat[] = [
        {
            repeatType: EnumRepeatType.Dont,
            repeatName: "Don't repeat"
        },
        {
            repeatType: EnumRepeatType.Daily,
            repeatName: "Repeat daily"
        },
        {
            repeatType: EnumRepeatType.Weekly,
            repeatName: "Repeat weekly"
        },
        {
            repeatType: EnumRepeatType.Monthly,
            repeatName: "Repeat monthly"
        }
    ]

    private reasonObject: IBlockReason[] = [
        {
            reasonType: EnumReasonType.Event,
            reasonName: "Event"
        },
        {
            reasonType: EnumReasonType.Maintenance,
            reasonName: "Maintenance"
        },
        {
            reasonType: EnumReasonType.visitorUse,
            reasonName: "Visitor use"
        },
        {
            reasonType: EnumReasonType.Other,
            reasonName: "Other"
        }
    ]

    constructor(props_: IAddBlockSpotViewProps) {
        super(props_);

        this.state = {
            selectedSpot: [],
            spots: [],
            blockspot: [],
            startTime: '',
            endTime: '',
            reason: '',
            selectedDate: '',
            blockedFrom: null,
            blockedTo: null,
            blockDateRange: {},
            times: this.getTimes(),
            selectedStartTime: null,
            selectedEndTime: null,
            repeat: this.repeatObject,
            selectedRepeat: this.repeatObject[0],
            reasons: this.reasonObject,
            selectedReason: this.reasonObject[0],
            selectedDays: [],
            isSingCalendarOpened: false,
            repeatStartDate: {},
            startDate: null,
            endDate: null,
            minDate: this.getMinDate(),
            maxDate: this.getMaxDate(),
            calendarRefresh: false,
            selectedSpotGuid: [],
            blockGuid: "",
            isSpotListRefreshed: false,
            sendEmail: false,
            blockRequestModel: "",
            isValidEndDate: true
        }

        this._spotService = new SpotService();
        this._userService = new UserService();
    }

    componentDidMount() {
        if (this.props.selectedSpotAggregation != null) {
            this.loadBlockedDetails();
        } else {
            this.loadSpots();
        }
    }

    componentDidUpdate(oldProps_: IAddBlockSpotViewProps) {
        if (JSON.stringify(this.props.selectedProperty) != JSON.stringify(oldProps_.selectedProperty)) {
            
        }
    }

    private loadBlockedDetails() {
        if (this.props.selectedSpotAggregation != null) {
            let props_ = this.props.selectedSpotAggregation;
            if (props_.blockedDetails != null) {
                let selectedRepeat_: IRepeat = props_.repeatType != null ? this.repeatObject[props_.repeatType] : this.repeatObject[0];
                let selectedReason_: IBlockReason = props_.reasonType != null ? this.reasonObject[props_.reasonType - 1] : this.reasonObject[0];
                let metaData: ISpotBlockMetadata = JSON.parse(props_.blockedDetails.metadata);
                let startDate_: Date = new Date(props_.blockedDetails.startDate);
                var endDate_ = props_.blockedDetails.repeatEndDate != null ? new Date(props_.blockedDetails.repeatEndDate) : null;
                var rangeEndDate = null;
                let dateRange: DateRange = {};
                let selectedStartTime_: IBlockTime;
                let selectedEndTime_: IBlockTime;
                let selectedDays_: number[] = [];

                if (selectedRepeat_.repeatType == EnumRepeatType.Dont || selectedRepeat_.repeatType == EnumRepeatType.Monthly) {
                    rangeEndDate = (selectedRepeat_.repeatType == EnumRepeatType.Dont) ? metaData.dred : metaData.med;

                    dateRange.startDate = startDate_;
                    dateRange.endDate = rangeEndDate != null ? new Date(rangeEndDate) : startDate_;
                }

                selectedDays_ = metaData.w != null ? metaData.w : [];

                var sTime = props_.blockedDetails.startTime.replace("00:", "12:");
                var eTime = props_.blockedDetails.endTime.replace("00:", "12:");

                selectedStartTime_ = this.state.times.filter(x => props_.blockedDetails != null && x.timeString == sTime)[0];
                // selectedEndTime_ = this.state.times.filter(x => props_.blockedDetails != null && x.timeString == eTime)[0];
                selectedEndTime_ = this.getTimes(true).filter(x => props_.blockedDetails != null && x.timeString == eTime)[0];

                this.setState({
                    selectedRepeat: selectedRepeat_,
                    selectedReason: selectedReason_,
                    blockDateRange: dateRange,
                    startDate: startDate_,
                    endDate: endDate_,
                    selectedStartTime: selectedStartTime_,
                    selectedEndTime: selectedEndTime_,
                    selectedDays: selectedDays_,
                    calendarRefresh: true,
                    selectedSpotGuid: props_.blockedDetails.spotGuids,
                    blockGuid: (props_.blockedDetails.spotBlockGuid != null && props_.blockedDetails.spotBlockGuid != "") ? props_.blockedDetails.spotBlockGuid : ""
                }, () => {
                    this.setState({
                        calendarRefresh: false
                    }, () => {
                        this.loadSpots();
                    })
                })
            }
        }
    }


    render() {
        return (
            <div className='calendar-block-container'>
                <h4 className="block-heading">{this.props.rightPanelState == RightPanelState.Edit ? 'Edit Block Spot' : 'Block Spot'}</h4>
                <form className="calendar-block-form-container" onSubmit={(event: any) => {
                    this.addEditBlockSpot();
                    event.preventDefault();
                }}>
                    <div className="block-right-panel-body">
                        <div className="cl-block-sc">
                            <div className="bl-sp-lable">Repeat</div>
                            <Autocomplete
                                id="cl-end-time"
                                className="cl-repeat-dropdown"
                                options={this.state.repeat}
                                value={this.state.selectedRepeat}
                                openOnFocus={true}
                                selectOnFocus={true}
                                getOptionLabel={(option) => option.repeatName}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <div className="cl-dropdown-list">
                                            <Checkbox
                                                icon={<div className="repeat-b-check" />}
                                                checkedIcon={<div className="repeat-b-check selected" />}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                                color="primary"
                                            />
                                            {option.repeatName}
                                        </div>
                                    </React.Fragment>
                                )}
                                onChange={(event: any, value: IRepeat | null) => {
                                    this.setState({
                                        selectedRepeat: value != null ? value : this.state.selectedRepeat
                                    }, () => {
                                        this.clearAllFields();
                                    })
                                }}
                                renderInput={(params) => <TextField {...params}
                                    placeholder="Select all that apply"
                                    variant="outlined"
                                    error={this.state.selectedRepeat == null}
                                    required={true}
                                />}
                            />
                        </div>
                        {
                            (this.state.selectedRepeat?.repeatType == EnumRepeatType.Daily ||
                                this.state.selectedRepeat?.repeatType == EnumRepeatType.Weekly)
                            && <div className="cl-block-sc">
                                <div className="bl-sp-lable">Start Date</div>
                                {
                                    !this.state.calendarRefresh && <GrydCalendarPicker
                                        placeHolder="Start Date"
                                        defaultDate={this.state.startDate}
                                        onDateChange={(date: Date | null) => {
                                            let endDate_ = this.state.endDate;

                                            if ((endDate_ != null && date != null) && (date.getTime() > endDate_.getTime())) {
                                                endDate_ = date;
                                            }

                                            this.setState({
                                                startDate: date,
                                                calendarRefresh: true,
                                                endDate: endDate_
                                            }, () => {
                                                this.setState({
                                                    calendarRefresh: false
                                                });
                                            })
                                        }}
                                        minDate={new Date()}
                                        isMandate={true}
                                        calendarPosition={EnumCalendarPosition.Bottom}
                                    />
                                }
                            </div>
                        }
                        {
                            (this.state.selectedRepeat?.repeatType == EnumRepeatType.Dont ||
                                this.state.selectedRepeat?.repeatType == EnumRepeatType.Monthly) && <div className="cl-block-sc">
                                <div className="bl-sp-lable">Date</div>
                                {
                                    !this.state.calendarRefresh && <GrydDateRangePicker
                                        placeHolder="Date"
                                        initialDateRange={this.state.blockDateRange}
                                        onDateChange={(dates_: DateRange) => {
                                            let isValidEndDate_ = true;
                                            if (this.state.selectedRepeat?.repeatType == EnumRepeatType.Monthly &&
                                                dates_.startDate != null && dates_.endDate != null) {
                                                let endValidDate = this.getEndDate(dates_.startDate);
                                                if (dates_.endDate.getTime() >= endValidDate.getTime()) {
                                                    isValidEndDate_ = false;
                                                }
                                            }

                                            this.setState({
                                                blockDateRange: dates_,
                                                calendarRefresh: true,
                                                isValidEndDate: isValidEndDate_
                                            }, () => {
                                                this.setState({
                                                    calendarRefresh: false
                                                });
                                            })
                                        }}
                                        // minDate={this.state.selectedRepeat?.repeatType == EnumRepeatType.Monthly ? this.state.minDate : null}
                                        // maxDate={this.state.selectedRepeat?.repeatType == EnumRepeatType.Monthly ? this.state.maxDate : null}
                                        minDate={this.state.minDate}
                                    // minDate={this.state.selectedRepeat?.repeatType == EnumRepeatType.Monthly ? this.getFirstDate() : this.state.minDate}
                                    // maxDate={this.state.selectedRepeat?.repeatType == EnumRepeatType.Monthly ? this.state.maxDate : null}
                                    />
                                }
                                {
                                    !this.state.isValidEndDate && <div className="cl-block-error-msg">Date range selection should not be greater than a month.</div>
                                }
                            </div>
                        }
                        {
                            this.state.selectedRepeat?.repeatType == EnumRepeatType.Weekly &&
                            <div className="cl-block-sc cl-repeat">
                                <div className="bl-sp-lable">Repeat on</div>
                                <div className={"cl-repeat-days " + (this.state.selectedDays.length == 0 ? "cl-repeat-error" : "")}>
                                    <WeekDaySelector
                                        selectedDays={this.state.selectedDays}
                                        // allowedDays={this.props.limitation?.allowedDays}
                                        onDaySelectionChange={d_ => {
                                            this.setState({
                                                selectedDays: d_
                                            });
                                        }}
                                    />
                                </div>
                                {
                                    this.state.selectedDays.length == 0 && <div className="cl-block-error-msg">Please select the day(s).</div>
                                }
                            </div>
                        }
                        <div className="cl-block-sc">
                            <div className="bl-sp-lable">Time</div>
                            <div className="cl-flex">
                                <div className="cl-flex-cl cl-r-padding">
                                    <div className="cl-block-input-c">
                                        <AccessTimeIcon className="cl-icon" />
                                        <Autocomplete
                                            id="cl-start-time"
                                            className="cl-time-dropdown"
                                            options={this.state.times}
                                            value={this.state.selectedStartTime}
                                            onChange={(event: any, value: IBlockTime | null) => {
                                                let oldValue = this.state.selectedStartTime;
                                                this.setState({
                                                    selectedStartTime: value
                                                }, () => {
                                                    this.checkStartAndEndTime();
                                                })
                                            }}
                                            openOnFocus={true}
                                            selectOnFocus={true}
                                            getOptionLabel={(option) => option.timeString}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    {option.timeString}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => <TextField {...params} placeholder="Start Time"
                                                variant="outlined"
                                                error={this.state.selectedStartTime == null}
                                            />}
                                        />
                                    </div>
                                </div>
                                <div className="cl-flex-pd">&mdash;</div>
                                <div className="cl-flex-cl cl-r-padding">
                                    <div className="cl-block-input-c">
                                        <AccessTimeIcon className="cl-icon" />
                                        <Autocomplete
                                            id="cl-end-time"
                                            className="cl-time-dropdown"
                                            options={this.getTimes(true)}
                                            value={this.state.selectedEndTime}
                                            onChange={(event: any, value: IBlockTime | null) => {
                                                this.setState({
                                                    selectedEndTime: value
                                                }, () => {
                                                    this.checkStartAndEndTime();
                                                })
                                            }}
                                            openOnFocus={true}
                                            selectOnFocus={true}
                                            getOptionLabel={(option) => option.timeString}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    {option.timeString}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => <TextField {...params} placeholder="End Time"
                                                variant="outlined"
                                                error={this.state.selectedEndTime == null}
                                            />}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.selectedRepeat?.repeatType != EnumRepeatType.Dont && <div className="cl-block-sc">
                                <div className="bl-sp-lable">End Date</div>
                                {
                                    !this.state.calendarRefresh && <GrydCalendarPicker
                                        placeHolder="End Date"
                                        defaultDate={this.state.endDate}
                                        onDateChange={(date: Date | null) => {
                                            this.setState({
                                                endDate: date,
                                                calendarRefresh: true
                                            }, () => {
                                                this.setState({
                                                    calendarRefresh: false
                                                });
                                            })
                                        }}
                                        calendarPosition={EnumCalendarPosition.Top}
                                        minDate={this.state.startDate != null ? this.state.startDate : new Date()}
                                        isInvalidDate={((this.state.startDate != null && this.state.endDate != null) &&
                                            (format(this.state.startDate, "MM/dd/yyyy") == format(this.state.endDate, "MM/dd/yyyy")) &&
                                            this.state.selectedRepeat?.repeatType == EnumRepeatType.Daily)}
                                    />
                                }
                                {
                                    ((this.state.startDate != null && this.state.endDate != null) &&
                                        (format(this.state.startDate, "MM/dd/yyyy") == format(this.state.endDate, "MM/dd/yyyy")) &&
                                        this.state.selectedRepeat?.repeatType == EnumRepeatType.Daily)
                                    && <div className="cl-block-error-msg">&ldquo;End Date&rdquo; must be greater than &ldquo;Start Date&rdquo;.</div>
                                }
                            </div>
                        }
                        <div className="cl-block-sc">
                            <div className="bl-sp-lable">Spot Number</div>
                            <AutoCompleteBlockSpots
                                onBlockSpotSelected={(values: IBlockSpot[]) => {
                                    this.setState({
                                        selectedSpot: values
                                    });
                                }}
                                spots={this.state.spots}
                                selectedSpot={this.state.selectedSpot}
                                enableSelectAll={true}
                                isMandate={true}
                                isApiUpdated={this.state.isSpotListRefreshed}
                                onRefreshDone={(doneState: boolean, values: IBlockSpot[]) => {
                                    // console.log(values);
                                    this.setState({
                                        isSpotListRefreshed: !doneState,
                                        selectedSpot: values
                                    })
                                }}
                            />
                        </div>
                        <div className="cl-block-sc">
                            <div className="bl-sp-lable">Reason for blocking</div>
                            <Autocomplete
                                id="cl-end-time"
                                className="cl-repeat-dropdown"
                                options={this.state.reasons}
                                openOnFocus={true}
                                selectOnFocus={true}
                                getOptionLabel={(option) => option.reasonName}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={<div className="repeat-b-check" />}
                                            checkedIcon={<div className="repeat-b-check selected" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                            color="primary"
                                        />
                                        {option.reasonName}
                                    </React.Fragment>
                                )}
                                value={this.state.selectedReason}
                                onChange={(event: any, value: IBlockReason | null) => {
                                    this.setState({
                                        selectedReason: value != null ? value : this.state.selectedReason
                                    })
                                }}
                                renderInput={(params) => <TextField {...params} placeholder="Select reason" variant="outlined" />}
                            />
                        </div>
                    </div>
                    <div>
                        <Row className="mt-3 mx-0">
                            <Col>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                // onClick={() => {
                                //     this.addEditBlockSpot();
                                // }}
                                >Save</Button>
                            </Col>
                            <Col>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        if (this.props.onClose) {
                                            this.props.onClose(false);
                                        }
                                    }}
                                >Cancel</Button>
                            </Col>
                        </Row>
                    </div>
                </form>
                {
                    this.state.sendEmail &&
                    <ContactSupportView
                        onClose={(p_: boolean) => {
                            this.setState({
                                sendEmail: false,
                                blockRequestModel: ""
                            })
                        }}
                        openSupportPopup={this.state.sendEmail}
                        blockRequest={this.state.blockRequestModel}
                    />
                }
            </div>
        )
    }

    private clearAllFields() {
        this.setState({
            blockDateRange: {},
            startDate: null,
            endDate: null,
            selectedStartTime: null,
            selectedEndTime: null,
            selectedSpot: [],
            calendarRefresh: true
        }, () => {
            this.setState({
                calendarRefresh: false
            })
        })
    }

    private checkStartAndEndTime() {
        let s_ = this.state.selectedStartTime;
        let e_ = this.state.selectedEndTime;

        if (s_ != null &&
            ((e_ != null && s_.dateTime.getTime() >= e_.dateTime.getTime()))) {
            let n_ = new Date(s_.dateTime);
            n_.setMinutes(n_.getMinutes() + 30);
            let o_ = new Date(n_);

            let time: IBlockTime = {
                dateTime: o_,
                timeString: format(o_, "hh:mm aa")
            }

            this.setState({
                selectedEndTime: time
            });
        } else if (e_ != null && ((s_ != null && e_.dateTime.getTime() <= s_.dateTime.getTime()))) {
            let n_ = new Date(e_.dateTime);
            n_.setMinutes(n_.getMinutes() - 30);
            let o_ = new Date(n_);

            let time: IBlockTime = {
                dateTime: o_,
                timeString: format(o_, "hh:mm aa")
            }

            this.setState({
                selectedStartTime: time
            });
        } else if (s_ == null) {
            this.setState({
                selectedStartTime: null
            });
        }
    }

    private addEditBlockSpot() {
        let spotGuids_: string[] = this.state.selectedSpot.map(spot => spot.spotGuid);

        spotGuids_ = spotGuids_.filter(spotGuid => spotGuid != "blank");

        var startDate = null;
        var endDate = this.state.endDate;
        var rangeEndDate = null;

        if (this.state.selectedRepeat.repeatType == EnumRepeatType.Dont || this.state.selectedRepeat.repeatType == EnumRepeatType.Monthly) {
            startDate = this.state.blockDateRange.startDate;
            rangeEndDate = this.state.blockDateRange.endDate;
        } else {
            startDate = this.state.startDate;
        }

        if (this.state.selectedRepeat?.repeatType == EnumRepeatType.Weekly && this.state.selectedDays.length == 0
            || !this.state.isValidEndDate) {
            return false;
        }

        if (((this.state.startDate != null && this.state.endDate != null) &&
            (format(this.state.startDate, "MM/dd/yyyy") == format(this.state.endDate, "MM/dd/yyyy")) &&
            this.state.selectedRepeat?.repeatType == EnumRepeatType.Daily)) {
            return false;
        }

        let metadata: ISpotBlockMetadata = {
            r: this.state.selectedReason.reasonType
        }

        if (rangeEndDate != null) {
            if (this.state.selectedRepeat.repeatType == EnumRepeatType.Dont) {
                metadata.dred = this.convertIntoUtc(rangeEndDate);
            } else if (this.state.selectedRepeat.repeatType == EnumRepeatType.Monthly) {
                metadata.med = this.convertIntoUtc(rangeEndDate);
            }
        }

        if (this.state.selectedRepeat.repeatType == EnumRepeatType.Weekly && this.state.selectedDays.length != 0) {
            metadata.w = this.state.selectedDays;
        }

        if (startDate != null && this.state.selectedStartTime != null &&
            this.state.selectedEndTime != null &&
            spotGuids_.length > 0) {
            let spotBlockModel: SpotBlock = {
                metadata: JSON.stringify(metadata),
                repeatType: this.state.selectedRepeat.repeatType,
                startDate: this.convertIntoUtc(startDate),
                startTime: this.state.selectedStartTime.timeString,
                endTime: this.state.selectedEndTime.timeString,
                spotGuids: spotGuids_,
            }

            if (endDate != null) {
                spotBlockModel.repeatEndDate = this.convertIntoUtc(endDate);
            }

            if (this.state.blockGuid != "" && this.state.blockGuid != null) {
                spotBlockModel.spotBlockGuid = this.state.blockGuid;
            }

            this._userService.hasMultipleAccess(['overlapping-booking']).then(r => r.json())
                .then(_response => {
                    if (_response.length > 0) {
                        //if (isPermissionRecord[0].admin && isPermissionRecord[0].grydUser && )
                        if (_response[0] == true) {
                            // spotBlockModel.overrideBlocking = true;
                            this._spotService.CheckOverLapping(spotBlockModel)
                                .then(r => {
                                    if (r.ok) {
                                        r.json().then(resp => {
                                            let reservations: IReservationChunk[] = resp;
                                            var bookedSpots: string[] = [];
                                            var reservationGuid: string[] = [];
                                            var friendlyReservationIds: string[] = [];

                                            reservations.forEach(x => {
                                                if (reservationGuid.indexOf(x.reservationGuid) == -1) {
                                                    reservationGuid.push(x.reservationGuid);
                                                }

                                                if (bookedSpots.indexOf(x.friendlySpotId) == -1) {
                                                    bookedSpots.push(x.friendlySpotId);
                                                }

                                                if (friendlyReservationIds.indexOf(x.friendlyReservationId) == -1) {
                                                    friendlyReservationIds.push(x.friendlyReservationId);
                                                }
                                            });

                                            let cancelBookingOption: any = {
                                                reservationGuids: reservationGuid,
                                                refundAction: true
                                            }

                                            if (reservations.length > 0) {
                                                ShowAlertwithOptions(
                                                    "Booking Cancellation",
                                                    // `${bookedSpots.sort().toString()} have active booking(s). Overriding block will cancel the booking(s). Are you sure you want to proceed?`,
                                                    `<div>
                                                        <div><b>${bookedSpots.sort().toString()}</b> have active booking(s) that are conflicting.</div><div>Blocking these spot(s) will cancel the following booking(s):</div>
                                                        <div class="bookings-overlap">
                                                            ${friendlyReservationIds.sort().toString()}
                                                        </div>
                                                        <div>Are you sure you want to proceed?</div>
                                                    </div>`,
                                                    "warning",
                                                    "Yes, cancel immediately",
                                                    null,
                                                    "No, don't cancel")
                                                    .then(agreed_ => {
                                                        if (agreed_.option1 == true) {
                                                            this._spotService.AdminCancelOverlpappingReservation(cancelBookingOption)
                                                                .then(data => {
                                                                    if (data.ok) {
                                                                        data.json().then((cancellationResponse_: { cancelled: string[], issueWithCancellation: string[], issueWithRefund: string[], manualRefundBookings: string[] }) => {
                                                                            if (cancellationResponse_.manualRefundBookings && cancellationResponse_.manualRefundBookings.length) {
                                                                                ShowAlert("Unable to Refund", `Unable to refund the following bookings, request admin to refund offline: ${cancellationResponse_.manualRefundBookings.join(", ")}`, "warning")
                                                                                    .then(() => {
                                                                                        this.proceedAddEditBlockSpot(spotBlockModel);
                                                                                    });
                                                                            }
                                                                            else {
                                                                                this.proceedAddEditBlockSpot(spotBlockModel);
                                                                            }
                                                                        });
                                                                    }
                                                                });
                                                        }
                                                    })
                                            } else {
                                                this.proceedAddEditBlockSpot(spotBlockModel);
                                            }
                                        });
                                    }
                                    else {
                                        if (r.status == 400) {
                                            r.json().then((resp) => {
                                                if (resp == "Invalid Time") {
                                                    ShowAlert("", "Please select the valid date and time", "error");
                                                } else {
                                                    ShowAlert("", resp, "error");
                                                }
                                            });
                                        }
                                        else {
                                            ShowAlert("", 'There was an error processing your request.', "error");
                                        }
                                    }
                                });
                        } else {
                            this.proceedAddEditBlockSpot(spotBlockModel);
                        }
                    }
                    else {
                        ShowAlert("", "You don't have permission to block. Please contact admin", "error");
                    }
                });

        }
    }

    private proceedAddEditBlockSpot(spotBlockModel: SpotBlock) {
        this._spotService.AddEditBlockSpots(spotBlockModel)
            .then(r => {
                if (r.ok) {
                    ShowAlert('', 'Spot blocked successfully', 'success').then(() => {
                        if (this.props.onClose) {
                            this.props.onClose(true);
                        }
                    });
                } else {
                    if (r.status == 400) {
                        r.json().then((resp) => {
                            if (resp == "Has conflicts") {
                                ShowAlertwithOptions(
                                    "Error",
                                    "Sorry, you cannot block this spot because it already has booking(s)/blocking(s).",
                                    "error",
                                    "Contact Support",
                                    null,
                                    "Back",
                                    "support-alert")
                                    .then(response_ => {
                                        if (response_.option1) {
                                            this.setState({
                                                sendEmail: true,
                                                blockRequestModel: JSON.stringify(spotBlockModel)
                                            })
                                        }
                                    });

                            } else if (resp == "Invalid Time") {
                                ShowAlert("", "Please select the valid date and time", "error");
                            }
                            else {
                                ShowAlert("", resp, "error");
                            }
                        });
                    }
                    else {
                        ShowAlert("", 'There was an error processing your request.', "error");
                    }
                }
            });
    }

    private convertIntoUtc(date: Date) {
        let x: Date = new Date(date);
        let hoursDiff = x.getHours() - x.getTimezoneOffset() / 60;
        let minutesDiff = (x.getHours() - x.getTimezoneOffset()) % 60;
        x.setHours(hoursDiff);
        x.setMinutes(minutesDiff);
        return new Date(x);
    }

    private getTimes(isEndTime: boolean = false) {
        let hours: IBlockTime[] = [];

        if (this.props.selectedProperty && this.props.selectedProperty.openTimeString && this.props.selectedProperty.closeTimeString) {
            let currentDayDate: Date = new Date(new Date().setHours(0, 0, 0, 0));
            let minMinutes = DateTimeHelper.timeStringToMinutes(this.props.selectedProperty.openTimeString);
            let maxMinutes = DateTimeHelper.timeStringToMinutes(this.props.selectedProperty.closeTimeString);
            let minTime: Date = new Date(new Date().setHours(0, 0, 0, 0));
            let maxTime: Date = new Date(new Date().setHours(0, 0, 0, 0));

            if ((maxMinutes % 10) != 0) {
                maxMinutes += 1;
            }

            if (isEndTime) {
                minMinutes += 30;
            }

            // If not 24 hrs
            if (maxMinutes < 1439 && !isEndTime) {
                maxMinutes -= 30;
            }

            minTime.setMinutes(minMinutes);
            maxTime.setMinutes(maxMinutes);

            let totalHours: number = (isEndTime == true) ? 49 : 48;
            let startIndex: number = (isEndTime == true) ? 1 : 0;
    
            for (let i = startIndex; i < totalHours; i++) {
                let currentDate = new Date(currentDayDate);
                currentDate.setMinutes((i / 2) * 60);
                let outputDate = new Date(currentDate);
    
                let time: IBlockTime = {
                    dateTime: outputDate,
                    timeString: format(outputDate, "hh:mm aa")
                }

                if (outputDate.getTime() >= minTime.getTime() && outputDate.getTime() <= maxTime.getTime()) {
                    hours.push(time);
                }
            }
        }

        return hours;
    }

    private convertTo24HrsFormat(time: string, isEndTime: boolean = false) {
        let [hours, modifier] = time.replace(" ", "").split(":");
        let hour: number = 0;
        // let min = parseInt(modifier).toString().padStart(2, '0');
        let min = parseInt(modifier);
        let index = modifier.toLowerCase().indexOf('m');
        let meridian = modifier.slice(index - 1);
        hour = parseInt(hours);

        if (hour === 12) {
            hour = 0;
        }

        if (meridian == 'PM') {
            hour = hour + 12;
        }

        hours = hour.toString().padStart(2, '0');


        let time24hr = `${hours}:${min}`;

        /*if (min == 59) {
            if (min == )
        }*/

        return time24hr;
    }

    private getMinDate() {
        let currentDate = new Date();
        // return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    }

    private getFirstDate() {
        let currentDate = new Date();
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    }

    private getMaxDate() {
        let currentDate = new Date();
        return new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0);
    }

    private getEndDate(inputDate: Date) {
        return new Date(inputDate.getFullYear(), inputDate.getMonth() + 1, inputDate.getDate());
    }

    private loadSpots() {
        // this.props.propertyGuid
        // let propertyGuid = this.props.propertyGuid ? this.props.propertyGuid : "67db6032-6353-4cbf-852e-3fa758c443be";
        let propertyGuid = this.props.propertyGuid;
        if (propertyGuid != null) {
            this._spotService.getActivePropertySpots(propertyGuid)
                .then(r => r.json())
                .then(r => {
                    let spots_: IBlockSpot[] = ArrayHelper.sortSpots(r);
                    let selectedSpot_ = spots_.filter(x => this.state.selectedSpotGuid.indexOf(x.spotGuid) != -1);

                    this.setState({
                        spots: spots_,
                        selectedSpot: selectedSpot_,
                        isSpotListRefreshed: true
                    });
                });
        }
    }

}


interface IAddBlockSpotViewProps {
    selectedSpotAggregation: IDailySpotAggregation | null;
    rightPanelState: RightPanelState;
    propertyGuid?: string;
    onClose: (refreshState: boolean) => void;
    selectedProperty: IPropertyBasics | null;
}

interface IBlockTime {
    dateTime: Date;
    timeString: string;
}

interface IAddBlockSpotViewState {
    spots: IBlockSpot[];
    selectedSpot: IBlockSpot[];
    selectedSpotGuid: string[];
    blockspot: string[];
    reason: string;
    startTime: string;
    endTime: string;
    selectedDate: string;
    blockedFrom: Date | null;
    blockedTo: Date | null;
    blockDateRange: DateRange;
    times: IBlockTime[];
    selectedStartTime: IBlockTime | null;
    selectedEndTime: IBlockTime | null;
    repeat: IRepeat[];
    selectedRepeat: IRepeat;
    reasons: IBlockReason[];
    selectedReason: IBlockReason;
    selectedDays: number[];
    isSingCalendarOpened: boolean;
    repeatStartDate: DateRange;
    startDate: Date | null;
    endDate: Date | null;
    minDate: Date;
    maxDate: Date;
    calendarRefresh: boolean;
    blockGuid: string;
    isSpotListRefreshed: boolean;
    sendEmail: boolean;
    blockRequestModel: string;
    isValidEndDate: boolean;
}