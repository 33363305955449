import * as React from 'react';
import '../../Login/Login.css';
import { ConfigService } from '../../Services/ConfigService';
import * as CommonString from '../../CommonString';
import { FaEdit, FaEye, FaEyeSlash, FaArrowRight } from 'react-icons/fa';
import { ShowAlert, ShowAlertwithConfirm } from '../../Common/ShowAlert';

/*Material UI */
import logo from '../../Images/gryd_logo.png';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@material-ui/core';
import classes from '*.module.css';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { stat } from 'fs';
import BaseService from '../../Services/BaseService';

class ForgotPassword extends React.Component<IForgotPasswordProps, IForgotPasswordState> {
    constructor(props: IForgotPasswordProps) {
        super(props);

        this.state = {
            requestID: this.props.match.params.requestID ? this.props.match.params.requestID : '',
            emailAddress: '',
            emailError: false,
            errorMessage: '',
            isConfirmPasswordVisible: false,
            isOtpSent: this.props.match.params.requestID ? true : false,
            isPasswordVisible: false,
            password: '',
            confirmPassword: '',
            passwordError: false,
            showBlocker: false,
            isEmailOtpNotValid: false,
            emailCode: '',
            errorEmailOtpMessage: '',
            isValidGuid: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleResend = this.handleResend.bind(this);
    }

    public componentDidMount = () => {
        if (this.state.requestID != "") {
            this.setState({
                showBlocker: true
            })
            this.validateForgotLink(this.state.requestID).then(response => {
                let state: any = {
                    showBlocker: false
                };
                if (response.ok) {
                    response.text().then(resp => {
                        state.isValidGuid = true;
                        state.emailAddress = JSON.parse(resp);
                        state.isOtpSent = true;
                        this.setState(state);
                    });
                } else {
                    response.text().then(resp => {
                        console.log("Error" + resp);
                        state.isValidGuid = false;
                        this.setState(state);
                        ShowAlert('Error!', 'Your password reset link has expired or invalid.', 'error').then(resp => {
                            localStorage.clear();
                            window.location.href = '/';
                        });
                    })
                }
            })
        }
    }

    public render() {

        return (
            <div className={'App login-page' + (this.state.showBlocker ? ' show-blocker' : '')}>
                <Grid container component="main" className='root'>
                    <CssBaseline />
                    {/* <Grid item xs={false} sm={4} md={4} className='image' /> */}
                    <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square className="login-form-container">
                        {/* <div className="not-a-member">Not a member? <a href='/register'>Sign up</a></div> */}
                        <div className='login-paper'>
                            <form className='form' onSubmit={this.handleSubmit}>
                                <img src={logo} className="login-logo" />
                                <h1 className="gryd-h1">
                                    {
                                        this.getHeaders()
                                        // (this.state.isOtpSent === false) ? 'Forgot your password' : 'Check your email'
                                    }
                                </h1>

                                {/* {
                                    (this.state.isOtpSent === false) ? this.loginScreen() : this.OTPScreen()
                                } */}

                                {
                                    this.forgotPasswordBody()
                                }



                            </form>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }

    private getHeaders() {
        let _header = '';
        if (this.state.requestID != '' && !this.state.isOtpSent) {
            _header = 'Check your email';
        } else if (this.state.requestID != '' && this.state.isOtpSent) {
            _header = 'Update your password';
        } else if (!this.state.isOtpSent && this.state.requestID == '') {
            _header = 'Forgot your password';
        } else if (this.state.isOtpSent && this.state.requestID == '') {
            _header = 'Check your email';
        }

        return _header;
    }

    private forgotPasswordBody() {
        if (this.state.requestID != '' && !this.state.isOtpSent) {
            return (
                this.enterOTPScreen()
            )
        } else if (this.state.requestID != '' && this.state.isOtpSent) {
            return (
                this.confirmPasswordScreen()
            )
        } else if (!this.state.isOtpSent && this.state.requestID == '') {
            return (
                this.forgotPasswordScreen()
            )
        } else if (this.state.isOtpSent && this.state.requestID == '') {
            return (
                this.checkYourEmailScreen()
            )
        }
    }

    private forgotPasswordScreen() {
        return (
            <div>
                <p className="forgot-text">Enter the email address you used when you joined and we&rsquo;ll send you instructions to reset your password.</p>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Email"
                    name="name"
                    autoComplete="off"
                    value={this.state.emailAddress}
                    onChange={(event: any) => {
                        let _targetValue = event.target.value;
                        this.setState({
                            emailAddress: _targetValue,
                            emailError: !this.validateEmail(_targetValue),
                            errorMessage: ''
                        });
                    }}
                    error={this.state.emailError}
                    helperText={this.state.emailError ? this.state.errorMessage : ''}
                    autoFocus
                />

                <Grid container>
                    <Grid item xs className="sign-up-not-member">
                        {/* <div className="not-a-member"><a href='/register'>Create account</a></div> */}
                    </Grid>
                    <Grid item xs>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className='submit login-button'
                        >
                            Send instructions <FaArrowRight size="1em" className="sign-in-button-icon" />
                        </Button>
                    </Grid>
                </Grid>
            </div>
        )
    }

    private enterOTPScreen() {
        return (
            <div>
                <p className="forgot-text">A verification code has sent to your email.</p>
                <TextField
                    error={this.state.isEmailOtpNotValid || this.state.emailCode.length < 6}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="emailotp"
                    label="Email verification code"
                    type="password"
                    id="emailotp"
                    onChange={(event: any) => {
                        let _targetValue = event.target.value;
                        this.setState({
                            emailCode: _targetValue,
                            isEmailOtpNotValid: false,
                            errorEmailOtpMessage: ''
                        });
                    }}
                    onPaste={this.checkPasteValue}
                    onKeyPress={this.checkValue}
                    value={this.state.emailCode}
                    helperText={this.state.isEmailOtpNotValid ? this.state.errorEmailOtpMessage : ''}
                />

                <Grid container>
                    <Grid item xs className="sign-up-not-member">
                        {/* <div className="not-a-member"><a href='/register'>Create account</a></div> */}
                    </Grid>
                    <Grid item xs>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className='submit login-button'
                        >
                            Confirm <FaArrowRight size="1em" className="sign-in-button-icon" />
                        </Button>
                    </Grid>
                </Grid>
            </div>
        )
    }

    private checkYourEmailScreen() {
        return (
            <div>
                <p className="forgot-text">Instructions to reset your password have sent to you.</p>

                <Grid container>
                    <Grid item xs className="sign-up-not-member">
                        {/* <div className="not-a-member"><a href='/register'>Create account</a></div> */}
                    </Grid>
                    <Grid item xs>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className='submit login-button'
                        >
                            Back to log in <FaArrowRight size="1em" className="sign-in-button-icon" />
                        </Button>
                    </Grid>
                </Grid>
            </div>
        )
    }



    private confirmPasswordScreen() {
        return (
            <div>
                <FormControl variant="outlined" className="MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
                    <InputLabel htmlFor="user-password" error={this.state.password.length < 8}>Password * </InputLabel>
                    <OutlinedInput
                        id="user-password"
                        name="password"
                        required
                        type={this.state.isPasswordVisible ? 'text' : 'password'}
                        value={this.state.password}
                        error={this.state.password.length < 8}
                        onChange={(event: any) => {
                            let _targetValue = event.target.value;
                            this.setState({
                                password: _targetValue
                            });
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => this.togglePasswordVisible('password')}
                                    onMouseDown={() => this.togglePasswordVisible('password')}
                                    edge="end"
                                >
                                    {this.state.isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={70}

                    />
                    <span className="errormsg">{this.state.password.length < 8 ? '* Password must be at least 8 characters long.' : ''}</span>
                </FormControl>

                <FormControl variant="outlined" className="MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
                    <InputLabel htmlFor="confirm-password" error={this.state.password !== this.state.confirmPassword}>Confirm password * </InputLabel>
                    <OutlinedInput
                        id="confirm-password"
                        name="confirmpassword"
                        required
                        type={this.state.isConfirmPasswordVisible ? 'text' : 'password'}
                        value={this.state.confirmPassword}
                        error={this.state.password !== this.state.confirmPassword}
                        onChange={(event: any) => {
                            let _targetValue = event.target.value;
                            this.setState({
                                confirmPassword: _targetValue
                            });
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => this.togglePasswordVisible('confirmpassword')}
                                    onMouseDown={() => this.togglePasswordVisible('confirmpassword')}
                                    edge="end"
                                >
                                    {this.state.isConfirmPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={70}

                    />
                    <span className="errormsg">{
                        this.state.password !== this.state.confirmPassword && this.state.confirmPassword.length > 1 ? 'The password you entered did not match' : ''
                    }</span>

                </FormControl>

                <Grid container>
                    <Grid item xs className="sign-up-not-member">
                        {/* <div className="not-a-member"><a href='/register'>Create account</a></div> */}
                    </Grid>
                    <Grid item xs>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className='submit login-button'
                        >
                            Update <FaArrowRight size="1em" className="sign-in-button-icon" />
                        </Button>
                    </Grid>
                </Grid>
            </div>
        )
    }

    private togglePasswordVisible(name: string) {
        if (name == 'password') {
            this.setState({
                isPasswordVisible: !this.state.isPasswordVisible
            });
        } else if (name == 'confirmpassword') {
            this.setState({
                isConfirmPasswordVisible: !this.state.isConfirmPasswordVisible
            });
        }

    }

    private DisplayMessage() {
        return (
            <div className="signInMessage">Enter the <span className="bold">verification code</span> that was sent to <span className="bold highlight">{this.state.emailAddress}</span><span className="EditButton" onClick={() => this.changeEmail()}><FaEdit size={'1.5em'} color={'#00000'} /></span></div>
        );
    }

    private changeEmail = () => {
        this.setState({
            isOtpSent: false
        });
    }

    private checkValue(event: any) {
        var charCode = (event.which) ? event.which : event.keyCode;

        if (charCode > 31 && (charCode < 48 || charCode > 57) || charCode === 101 || charCode === 69) {
            event.preventDefault();
            return false;
        }
        if (event.target.value.length >= 6) {
            event.preventDefault();
            return false;
        }

        return true;
    }

    private checkPasteValue(event: any) {
        var str = event.clipboardData.getData('Text');

        if ((str.length > 6) || isNaN(str)) {
            event.preventDefault();
            return false;
        }
        return true;
    }

    private handleResend() {
        this.setState({
            errorMessage: '',
            showBlocker: true
        });

        /*this.signIn(this.state.emailAddress, 1, 0).then(emailResponse => {

            this.setState({
                showBlocker: false
            });
            // ShowAlert('', TransRawText('sign_email_success'), 'success');
        }).catch(error => {
            this.setState({
                errorMessage: '',
                showBlocker: false
            });
            // ShowAlert('', TransRawText('sign_unable_to_connect'), 'error');
        });*/
    }

    private showPageUnderConstruction() {
        // ShowAlert('', 'The page is under construction', 'error');
        ShowAlert('Success!', 'The page is under construction', 'success');
    }

    private async forgotPasswordLinkService(email: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + CommonString.SERVICE_FORGOT_PASSWORD_LINK, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(email)
                }, 'ignoreAlert');
            });
    }

    private validateForgotLink(userGUID: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + CommonString.SERVICE_FORGOT_VALIDATE_LINK, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(userGUID)
                })
            });
    }

    private VerifyOTP() {
        var postData = {
            'Email': this.state.emailAddress,
            'EmailOTPCode': Number(this.state.emailCode),
            "CountryCode": 0,
            "MobileNumber": "",
            "MobileOTPCode": 0,
            'VerificationType': 2
        };

        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + CommonString.SERVICE_REQUEST_OTP, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postData)
                });
            });
    }

    private updatePassword() {
        var postData = {
            'ShortLiveGuid': this.state.requestID,
            'Password': this.state.password,
        };

        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + CommonString.SERVICE_CHANGE_PASSWORD, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postData)
                });
            });
    }

    private validateJsonString(str: string) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    private validateEmail(email: string) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    private validateMobile(mobile: string) {
        var phoneno = /^\d{10}$/;
        return phoneno.test(mobile);
    }

    private handleSubmit(event: any) {
        if (!this.state.isOtpSent && this.state.requestID == "") {
            var userInput = this.state.emailAddress;
            let isValidEmail = this.validateEmail(userInput);
            // let isValidEmail = this.validateEmail(this.state.emailAddress);

            if (!isValidEmail) {
                this.setState({
                    errorMessage: 'Please enter valid email ID',
                    emailError: true,
                    showBlocker: false
                });
                event.preventDefault();
            } else {
                this.requestForgotPasswordLink();

                // this.setState({
                //     isOtpSent: true
                // })
            }
        } else if (this.state.isOtpSent && this.state.requestID == "") {
            localStorage.clear();
            window.location.href = '/';
        } else if (this.state.requestID != "" && !this.state.isOtpSent) {
            if (this.state.emailCode.length < 6) {
                this.setState({
                    errorEmailOtpMessage: 'Please enter the valid verification code.',
                    isEmailOtpNotValid: true
                });
            } else {
                this.setState({
                    showBlocker: true
                });
                this.VerifyOTP().then(response => {
                    if (response.ok) {
                        response.json().then(resp => {
                            var _response = JSON.parse(resp);
                            if (_response.code === 200) {
                                var _results = JSON.parse(_response['data']);

                                var _emailStatus = _results['Email'];

                                let state: any = {
                                    showBlocker: false
                                };

                                if (_emailStatus === 'Valid') {
                                    state.isOtpSent = true;
                                } else {
                                    state.isEmailOtpNotValid = true;
                                    ShowAlert('Error!', 'Entered verification code expired or Invalid', 'error');
                                }

                                this.setState(state);
                            }
                            // let _userDetails: UserDetailsModel = this.state.userDetails;
                            // _userDetails.accessToken = data;
                            // localStorage.setItem('userdata', JSON.stringify(_userDetails));
                            // window.location.href = '/Dashboard';
                        })
                    } else {
                        let state: any = {
                            showBlocker: false
                        };
                        response.text().then(data => {
                            state.errorEmailOtpMessage = 'Please enter the valid verification code';
                            state.isEmailOtpNotValid = true;

                            this.setState(state);
                        })
                    }
                })
            }
        } else if (this.state.requestID != "" && this.state.isOtpSent) {
            if (this.state.password === this.state.confirmPassword) {
                this.setState({
                    showBlocker: true
                });
                this.updatePassword().then(response => {
                    if (response.ok) {
                        let state: any = {
                            showBlocker: false
                        };
                        response.json().then(resp => {
                            this.setState(state);
                            if (resp == 'success') {
                                ShowAlert('Success!', 'Password updated successfully!', 'success').then(_r => {
                                    localStorage.clear();
                                    window.location.href = '/';
                                });
                            }
                        })
                    } else {
                        let state: any = {
                            showBlocker: false
                        };
                        response.json().then(resp => {
                            console.log()
                            this.setState(state);
                            if (resp == 'Record not found') {
                                ShowAlert('Error!', 'Your registration is not completed.', 'error').then(_r => {
                                    localStorage.clear();
                                    window.location.href = '/';
                                });
                            } else {
                                ShowAlert('Error!', 'Service error.', 'error');
                            }
                        })
                    }
                })
            }
        }
        event.preventDefault();
        return false;
    }


    private requestForgotPasswordLink() {
        this.setState({
            showBlocker: true
        });
        this.forgotPasswordLinkService(this.state.emailAddress).then(response => {
            if (response.ok) {
                let state: any = {
                    showBlocker: false,
                    isOtpSent: true
                };
                response.text().then(resp => {
                    this.setState(state);
                });

            } else {
                response.text().then(resp => {
                    console.log(resp);
                    let state: any = {
                        showBlocker: false
                    };

                    if (resp === '"Invalid Email or not registered"') {
                        state.errorMessage = 'Entered email is incorrect or not registered.';
                        state.emailError = true;
                    } else {
                        state.errorMessage = JSON.parse(resp);
                    }

                    this.setState(state);
                });

            }
        }).catch(error => {
            this.setState({
                errorMessage: '',
                showBlocker: false
            });
            // ShowAlert('', error, 'error');
            ShowAlert('Server Error!', error.toString(), 'error');
        });
    }

    private postJsonService(serviceUrl: string, postData: any) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + serviceUrl, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(postData)
                })
                    .then(response => response.json());
            });
    }
}

interface IForgotPasswordProps {
    match: {
        params: {
            requestID: string
        }
    }
}

interface IForgotPasswordState {
    emailAddress: string,
    password: string,
    confirmPassword: string,
    errorMessage: string,
    emailError: boolean,
    passwordError: boolean,
    isOtpSent: boolean,
    showBlocker: boolean,
    isPasswordVisible: boolean,
    isConfirmPasswordVisible: boolean,
    requestID: string,
    isEmailOtpNotValid: boolean,
    emailCode: string,
    errorEmailOtpMessage: string,
    isValidGuid: boolean
}

export default ForgotPassword;