import React from 'react';
import { RightPanelState } from '../../../Models/CommonInterfaces';
import "../PrivateLots.css";
import "./visitorParking.css";
import { CheckScreenPermission } from '../../../Common/CheckScreenPermission';
import { Button, Drawer, Grid, IconButton, Menu, MenuItem, Snackbar } from '@material-ui/core';
import { IBulkSaveEditResponse } from '../interface';
import VisitorBookingService from '../../../Services/VisitorBookingService';
import AddIcon from '@material-ui/icons/Add';
import DataGridToolbar from '../../../Components/DataGrid/DataGridToolbar';
import { PreserveFilters } from '../../../Common/PreserveFilters';
import CalendarProperty from '../../BookingsCalendar/CalendarProperty';
import { IPropertyBasics } from '../../Spots/Interfaces';
import CloseIcon from '@material-ui/icons/Close';
import AddEditVisitorParking from './AddEditVisitorParking';
import ObjectHelper from '../../../Helpers/ObjectHelper';
import { ShowAlert, ShowAlertwithConfirm, ShowAlertWithCustomIcon, ShowAlertWithConfirmCustomIcon } from '../../../Common/ShowAlert';
import DataGrid from '../../../Components/DataGrid/DataGrid';
import { IUnitItem } from './interface';
import { IDataGridColumn, IPopoverItemData } from '../../../Components/DataGrid/Interfaces';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import BulkUploadVisitors from './BulkUploadVisitors';
import ConfigurationDetails from './ConfigurationDetails';
import UpdateConfigurationParking from './UpdateConfigurationParking';

class VisitorParkings extends React.Component<IVisitorParkingsProps, IVisitorParkingsState>{
    private _visitorBookingService: VisitorBookingService;
    private _searchInput: HTMLElement | null;

    private _unitColumns: IDataGridColumn<IUnitItem>[] = [
        {
            key: "unitNumber",
            name: "UNIT NO",
        },
        {
            key: "passCount",
            name: "# OF PASSES",
            contentProvider: (row_: IUnitItem) => {
                return (<span>{(row_.passCount - row_.totalPassBooked)}/{row_.passCount}</span>);
            }
        },
        {
            key: "passCode",
            name: "Passcode",
            contentProvider: (row_: IUnitItem) => {
                console.log(row_)
                return (<div className='passcode-container' id={`unit-guid_${row_.unitGuid}`}><span className='visitor-passcode'>{row_.isPassCodeVisible ? row_.passCode : <span>{'*****'}</span>}</span>
                    {row_.isPassCodeVisible ? <span onClick={(e_: any) => {
                        // console.log(e_.currentTarget.parentNode.id.split("_")[1])
                        this.togglePassCodeVisibility(row_.unitGuid)
                    }}><VisibilityOffIcon fontSize='small' className='pointer' /></span> : <span onClick={(e_: any) => {
                        console.log(e_.currentTarget.parentNode.id.split("_")[1]);
                        this.togglePassCodeVisibility(row_.unitGuid)
                    }}><RemoveRedEyeIcon fontSize='small' className='pointer' /></span>} </div>);
            }
        }
    ];

    constructor(props: any) {
        super(props);
        this.state = {
            isScreenAccessible: false,
            rightPanelState: RightPanelState.None,
            selectedProperty: null,
            properties: [],
            isMultiSelectedProperty: false,
            isFocused: false,
            anchorEl: null,
            isBulkUpload: false,
            searchTerm: '',
            isAddNewUnit: false,
            isUndoNotification: false,
            toastNotifyType: "",
            hasUnsavedChanges: false,
            allUnits: [],
            filteredUnits: [],
            subFilteredUnits: [],
            selectedUnits: [],
            isBulkSaveEdit: false,
            bulkSaveEditResponse: [],
            defaultVisitorParkingPasses: 0,
            deleteResponse: []
        };

        this._visitorBookingService = new VisitorBookingService();
        this._searchInput = null;
    }

    async componentDidMount() {
        var isAccessible = await CheckScreenPermission("user-interface-visitor-parking");

        this.setState({
            isScreenAccessible: isAccessible
        }, () => {
            if (this.state.isScreenAccessible) {
                this.loadProperties(false, true);
            }
        });

        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        PreserveFilters.setSearchTerm('visitorParkingSearch', { searchTerm: this.state.searchTerm, selectedProperty: this.state.selectedProperty });
    }

    clearSearch = () => {
        this.setState({ isFocused: true }, () => {
            if (this._searchInput)
                this._searchInput.focus();

            this._searchTermChange('');
        });
    }

    focusIn = () => {
        if (this._searchInput)
            this._searchInput.focus();

        this.setState({ isFocused: true });
    }

    focusOut = () => {
        this.setState({ isFocused: false });
    }

    render() {
        if (!this.state.isScreenAccessible) return null;
        const isAddMenuOpen = Boolean(this.state.anchorEl);
        return (
            <React.Fragment>
                {this.state.isUndoNotification && (
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.isUndoNotification}
                        message={this.commonToastNotificationMsg()}
                        className="common-toaster-pl"
                        onClose={this.closeCommonNotificationMsg}
                        autoHideDuration={30000}
                    />
                )}
                <div className="data-grid-container private-lots-page without-checkbox-container">
                    <Grid container className="mb-6 common-header visitor-parking-header">
                        <Grid container item className={'common-lf-header-container'}>
                            <CalendarProperty
                                properties={this.state.properties}
                                onPropertyChange={(property_: IPropertyBasics | null) => {
                                    this.setState({
                                        selectedProperty: property_,
                                        selectedUnits: [],
                                        defaultVisitorParkingPasses: property_?.defaultVisitorParkingPasses ?? 0
                                    }, () => {
                                        this.loadUnits();
                                    })
                                }}
                                selectedProperty={this.state.selectedProperty}
                                id="top-property"
                            />
                        </Grid>
                        <Grid container item className='visitor-rt-header-container'>
                            <Grid item className="align-items-center mt-3" xs>
                                <DataGridToolbar
                                    search={PreserveFilters.getSearchTerm('visitorParkingSearch')?.searchTerm}
                                    singularEntityName="unit"
                                    pluralEntityName="units"
                                    onSearch={this._searchTermChange}
                                    isFiltered={false}
                                    onFilterClick={() => { }}
                                    onAddClick={() => { }}
                                    isNoAddButton={true}
                                    isNoFilterButton={true}
                                />
                            </Grid>
                            <Grid item className="d-flex align-items-center">
                                <IconButton className='add-lit-button'
                                    onClick={this.handleAddIconButton}>
                                    <AddIcon fontSize="small" />
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={this.state.anchorEl}
                                    open={isAddMenuOpen}
                                    onClose={this.handleAddIconButtonClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => {
                                        const { isMultiSelectedProperty } = this.state;
                                        if (isMultiSelectedProperty) {
                                            ShowAlert("", "Please select only one property", "warning");
                                        }
                                        else {
                                            this.setState({
                                                isAddNewUnit: true,
                                                isBulkUpload: false,
                                                anchorEl: null,
                                                rightPanelState: RightPanelState.Add
                                            })
                                        }
                                    }}>Add New Unit</MenuItem>
                                    <MenuItem onClick={() => {
                                        const { isMultiSelectedProperty, selectedProperty } = this.state;
                                        if (isMultiSelectedProperty) {
                                            ShowAlert("", "Please select only one property", "warning");
                                        } else if (selectedProperty != null && selectedProperty.isYardiProperty == true) {
                                            ShowAlert("Error", "Not allowed for third party managed property", "error");
                                        }
                                        else {
                                            this.setState({
                                                isAddNewUnit: false,
                                                isBulkUpload: true,
                                                anchorEl: null
                                            })
                                        }
                                    }}>Bulk Upload</MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                        {this.state.isMultiSelectedProperty && (
                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={this.state.isMultiSelectedProperty}
                                message={this.multiSelectedPropertyMsg()}
                                className="visitor-notify-toaster-pl"
                            />
                        )}
                    </Grid>
                    <div className='private-lots-body'>
                        <div className='private-lots-parent-container px-4'>
                            <DataGrid
                                key="1"
                                title="View Tenant Details"
                                data={this.state.subFilteredUnits}
                                selectedItems={this.state.selectedUnits}
                                columns={this._unitColumns}
                                isNoMoreLink={false}
                                onMoreClick={() => {
                                    let options: IPopoverItemData[] = [
                                        {
                                            label: "Edit Unit",
                                            key: "editUnit"
                                        }, {
                                            label: "Reset Passcode",
                                            key: "resetPasscode"
                                        }
                                    ];

                                    if (this.state.selectedProperty != null && !this.state.selectedProperty?.isYardiProperty) {
                                        options.push({
                                            label: "Delete Unit",
                                            key: "deleteUnit",
                                            textStyle: "remove-access-item"
                                        });
                                    }

                                    return options;
                                }}
                                multiPopoverItems={(this.state.selectedProperty != null && !this.state.selectedProperty?.isYardiProperty) ? [
                                    {
                                        label: "Edit Unit",
                                        key: "editUnit"
                                    },
                                    {
                                        label: "Reset Passcode",
                                        key: "resetPasscode"
                                    },
                                    {
                                        label: "Delete Unit",
                                        key: "deleteUnit",
                                        textStyle: "remove-access-item"
                                    }
                                ] : [{
                                    label: "Edit Unit",
                                    key: "editUnit"
                                },
                                {
                                    label: "Reset Passcode",
                                    key: "resetPasscode"
                                }]}
                                onMultiPopoverClick={(key_, items_) => {
                                    const { isMultiSelectedProperty } = this.state;
                                    if (isMultiSelectedProperty) {
                                        ShowAlert("", "Please select only one property", "warning");
                                        return;
                                    }
                                    if (key_ == "resetPasscode") {
                                        this.resetPasscode(items_);
                                    }
                                    else if (key_ == "deleteUnit") {
                                        if (this.state.selectedProperty != null && this.state.selectedProperty.isYardiProperty == true) {
                                            ShowAlert("Error", "You are not allowed delete the third party managed property unit(s)", "error");
                                        } else {
                                            this.deleteUnits(items_);
                                        }
                                    }
                                    else if (key_ === "editUnit") {
                                        this.setState({
                                            rightPanelState: RightPanelState.Edit,
                                            selectedUnits: items_,
                                            isAddNewUnit: true,
                                            isBulkSaveEdit: true
                                        })
                                    }
                                }}
                                onPopoverClick={(key_, units_) => {
                                    if (key_ == "resetPasscode") {
                                        this.resetPasscode([units_]);
                                    }
                                    else if (key_ == "deleteUnit") {
                                        if (this.state.selectedProperty != null && this.state.selectedProperty.isYardiProperty == true) {
                                            ShowAlert("Error", "You are not allowed delete the third party managed property unit(s)", "error");
                                        } else {
                                            this.deleteUnits([units_]);
                                        }
                                    }
                                    else if (key_ === "editUnit") {
                                        this.setState({
                                            rightPanelState: RightPanelState.Edit,
                                            selectedUnits: [units_],
                                            isAddNewUnit: true
                                        })
                                    }
                                }}
                                onRowClick={pLots_ => {
                                }}
                            />
                        </div>
                        <div className="private-lots-parent-right">
                            <ConfigurationDetails
                                defaultPasses={this.state.defaultVisitorParkingPasses}
                                configureParking={() => {
                                    this.setState({
                                        rightPanelState: RightPanelState.ConfigurationParking
                                    });
                                }}
                                selectedProperty={this.state.selectedProperty}
                            />
                        </div>
                    </div>
                    {
                        //this.state.selectedProperty == null && 
                        this.state.isBulkUpload && this.getBlukUploadContent()
                    }
                    <Drawer
                        anchor="right"
                        variant={this.state.rightPanelState === RightPanelState.None ? "persistent" : "temporary"}
                        open={this.state.rightPanelState !== RightPanelState.None}
                        className="data-grid-right-panel"
                        onClose={this.onCloseDrawerPanel}
                    >
                        {(this.state.rightPanelState !== RightPanelState.Add &&
                            this.state.rightPanelState !== RightPanelState.Edit &&
                            this.state.rightPanelState !== RightPanelState.ConfigurationParking)
                            && <div className="mx-3">
                                <Grid container className={"right-panel-header p-2"}>
                                    <Grid item xs>
                                        <h4 className="right-panel-title m-0 ml-3">
                                            {this._getRightPanelTitle()}
                                        </h4>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            onClick={this.onCloseDrawerPanel}>
                                            <CloseIcon />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                        {this._getRightPanelContent()}
                    </Drawer>
                </div>
            </React.Fragment>
        )
    }

    commonNotificationContent = (type_: string) => {
        switch (type_) {
            case "deleteUnit":
                return "Unit(s) successfully deleted.";
            case "bulkSaveEdit":
                return "Unit(s) updated successfully.";
            default:
                return type_;
        }
    }

    commonToastNotificationMsg = () => {
        if (this.state.toastNotifyType === "deleteUnit"
            || this.state.toastNotifyType === "bulkSaveEdit") {
            return (
                <div className='d-flex align-items-center'>
                    <div className='d-flex flex-column'>
                        <div className=''>
                            {this.commonNotificationContent(this.state.toastNotifyType)}
                            <a className='cursor-pointer' style={{ textDecoration: "underline" }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (this.state.toastNotifyType === "bulkSaveEdit") {
                                        this._visitorBookingService.bulkEditVisitorUndo(this.state.bulkSaveEditResponse)
                                            .then(response => {
                                                if (response.ok) {
                                                    this.loadUnits();
                                                    this.closeCommonNotificationMsg();
                                                }
                                            })
                                    }
                                    else if (this.state.toastNotifyType === "deleteUnit") {
                                        this._visitorBookingService.visitorDeleteUndo(this.state.deleteResponse)
                                            .then(response => {
                                                if (response.ok) {
                                                    this.loadUnits();
                                                    this.closeCommonNotificationMsg();
                                                }
                                            })
                                    }
                                }}>&nbsp;Undo</a>
                        </div>
                    </div>
                    <div className='d-flex n-toast-close pl-4'>
                        <IconButton
                            size="small"
                            aria-label="close"
                            onClick={this.closeCommonNotificationMsg}
                        >
                            <CloseIcon fontSize="small" htmlColor='#fff' />
                        </IconButton>
                    </div>
                </div>

            );
        }
        else {
            return (
                <div className='d-flex align-items-center'>
                    <div className='d-flex flex-column'>
                        <div className=''>
                            {this.state.toastNotifyType}
                        </div>
                    </div>
                    <div className='d-flex pl-4'>
                        <IconButton
                            size="small"
                            aria-label="close"
                            onClick={this.closeCommonNotificationMsg}
                        >
                            <CloseIcon fontSize="small" htmlColor='#fff' />
                        </IconButton>
                    </div>
                </div>
            );
        }
    }

    closeCommonNotificationMsg = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            isUndoNotification: false,
            toastNotifyType: "",
            bulkSaveEditResponse: [],
            deleteResponse: []
        });
    }

    protected _getRightPanelTitle() {
        switch (this.state.rightPanelState) {
            case RightPanelState.Filter:
                return <span>Filter</span>;
            case RightPanelState.Edit:
                return <span>Edit Tenant</span>;
            case RightPanelState.Add:
                return <span>Add Tenant</span>;
        }
    }

    private _getRightPanelContent = () => {
        const { rightPanelState } = this.state;
        if (rightPanelState === RightPanelState.Add || rightPanelState === RightPanelState.Edit) {
            return (
                <AddEditVisitorParking
                    defaultVisitorParkingPasses={this.state.defaultVisitorParkingPasses}
                    properties={ObjectHelper.deepClone(this.state.properties)}
                    requestType={rightPanelState}
                    onClose={() => this.onCloseDrawerPanel()}
                    onChange={(hasChange: boolean) => {
                        this.setState({
                            hasUnsavedChanges: hasChange
                        })
                    }}
                    selectedUnits={this.state.selectedUnits}
                    selectedProperty={this.state.selectedProperty}
                    onSaved={(response?: any, isUpdate?: boolean) => {
                        const { rightPanelState, isBulkSaveEdit } = this.state;
                        if (!response && !isBulkSaveEdit && (rightPanelState === RightPanelState.Add
                            || rightPanelState === RightPanelState.Edit)) {
                            const message = rightPanelState === RightPanelState.Edit ? "Unit updated successfully." : "Unit added successfully.";
                            this.setState({
                                isAddNewUnit: false,
                                hasUnsavedChanges: false,
                                isUndoNotification: true,
                                toastNotifyType: message,
                                selectedUnits: []
                            }, () => {
                                this.loadUnits();
                            });
                        }
                        else {
                            this.setState({
                                isAddNewUnit: false,
                                hasUnsavedChanges: false,
                                isUndoNotification: true,
                                toastNotifyType: "bulkSaveEdit",
                                isBulkSaveEdit: false,
                                bulkSaveEditResponse: response,
                                selectedUnits: []
                            }, () => {
                                this.loadUnits();
                            });
                        }
                    }}
                    isBulkSaveEdit={this.state.isBulkSaveEdit}
                    allUnits={this.state.allUnits}
                    onDelete={(selectedUnits: IUnitItem[]) => {
                        this.deleteUnits(selectedUnits);
                    }}
                />
            )
        }
        else if (rightPanelState === RightPanelState.ConfigurationParking) {
            const { selectedProperty } = this.state;
            return (<UpdateConfigurationParking
                numberOfPasses={this.state.defaultVisitorParkingPasses}
                onClose={this.onCloseDrawerPanel}
                onChange={(hasUnsavedChanges: boolean) => {
                    this.setState({
                        hasUnsavedChanges
                    });
                }}
                onSaved={(numberOfPasses: number) => {
                    this.setState({
                        defaultVisitorParkingPasses: numberOfPasses,
                        hasUnsavedChanges: false,
                        selectedUnits: []
                    }, () => {
                        this.loadProperties(true);
                        // this.loadUnits();
                    });
                }}
                propertyGuid={selectedProperty?.propertyGuid ?? ""}
            />);
        }
    }

    multiSelectedPropertyMsg = () => {
        return (

            <div className='d-flex flex-column'>
                <div className='py-3'>
                    <b>Not available on multi-select.</b>
                </div>
                <div className=''>
                    To view statistics and lot overview,
                </div>
                <div className=''>
                    please select only <b>one</b> property.
                </div>
            </div>

        )
    }

    private getBlukUploadContent = () => {
        const { selectedProperty } = this.state;
        let errorMessage = "";
        let isError = false;
        if (!selectedProperty || selectedProperty.propertyGuid === "blank") {
            errorMessage = "Property is not selected";
            isError = true;
        }

        if (isError) {
            ShowAlert("", errorMessage, "warning");
            this.setState({
                isBulkUpload: false
            });
            return;
        }

        return (
            <div className="bulk-upload-blocker" onClick={(event: any) => {
                if (event.target && event.target.classList.toString().indexOf("bulk-upload-blocker") !== -1) {
                    this.setState({
                        isBulkUpload: false
                    })
                }
            }}>
                <div className="bulk-upload-popup">
                    <BulkUploadVisitors
                        propertyGuid={selectedProperty?.propertyGuid ??
                            "00000000-0000-0000-0000-000000000000"
                        }
                        onModelClose={(isBulkUpload: boolean) => this.setState(
                            {
                                isBulkUpload: isBulkUpload
                            })}
                        getListUpdated={() => {
                            this.setState({
                                isUndoNotification: true,
                                toastNotifyType: "Bulk upload unit(s) added successfully."
                            }, () => {
                                this.loadUnits();
                            })
                        }}
                    />
                </div>
            </div>
        )
    }

    private handleAddIconButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    }

    private handleAddIconButtonClose = () => {
        this.setState({
            anchorEl: null
        })
    }

    private loadProperties(isRefresh: boolean = false, onLoad?: boolean) {
        this._visitorBookingService.getVisitorParkingProperties()
            .then(r => r.json())
            .then(r => {
                let properties_: IPropertyBasics[] = r;
                // properties_ = ArrayHelper.sortProperty(properties_);
                let defaultVisitorParkingPasses = properties_.length > 0 && properties_[0].defaultVisitorParkingPasses ? properties_[0].defaultVisitorParkingPasses : 0;

                let selectedProperty_: IPropertyBasics | null = properties_.length > 0 ? properties_[0] : null;

                if (isRefresh && properties_.length > 0) {
                    var filteredProperty = properties_.filter(x => this.state.selectedProperty != null &&
                        x.propertyGuid === this.state.selectedProperty.propertyGuid);

                        if (filteredProperty.length > 0) {
                            selectedProperty_ = filteredProperty[0];
                            defaultVisitorParkingPasses = selectedProperty_.defaultVisitorParkingPasses ? selectedProperty_.defaultVisitorParkingPasses : 0;
                        }
                }

                if (onLoad) {
                    const visitorParkingSearch = PreserveFilters.getSearchTerm('visitorParkingSearch');
                    let searchTerm = ""
                    if (visitorParkingSearch?.selectedProperty)
                        selectedProperty_ = visitorParkingSearch.selectedProperty;

                    if (visitorParkingSearch?.searchTerm)
                        searchTerm = visitorParkingSearch.searchTerm;

                    this.setState({
                        properties: properties_.slice(0),
                        selectedProperty: selectedProperty_,
                        isFocused: false,
                        defaultVisitorParkingPasses,
                        searchTerm
                    }, () => {
                        this.loadUnits();
                    });
                }
                else {
                    this.setState({
                        properties: properties_.slice(0),
                        selectedProperty: selectedProperty_,
                        isFocused: false,
                        defaultVisitorParkingPasses
                    }, () => {
                        this.loadUnits();
                        // this.updateNotificationData();
                        //this.getTenantPrivateLot();
                    });
                }
            });
    }

    private loadUnits(selectAllRows?: () => void) {
        var selectedGuid = this.state.selectedProperty != null ? this.state.selectedProperty.propertyGuid : "00000000-0000-0000-0000-000000000000";
        this._visitorBookingService.getVisitorsUnits(selectedGuid)
            .then(r => r.json())
            .then(r => {
                let units_: IUnitItem[] = r;

                units_.map((x: IUnitItem) => {
                    x.isPassCodeVisible = false;

                    return x;
                })

                // properties_ = ArrayHelper.sortProperty(properties_);

                console.log(units_);

                this.setState({
                    allUnits: units_,
                    filteredUnits: units_.slice(0),
                    subFilteredUnits: units_.slice(0),
                    isFocused: false
                }, () => {
                    // this.updateNotificationData();
                    //this.getTenantPrivateLot();
                    this.filterUnits();
                    selectAllRows && selectAllRows();
                });
            });
    }

    private handleResize = () => {
        this.setState({
            rightPanelState: this.state.rightPanelState
        })
    }

    private onCloseDrawerPanel = () => {
        if (this.state.hasUnsavedChanges) {
            ShowAlertwithConfirm("You have unsaved changes!", "Are you sure you want to close this window?", "warning")
                .then(r_ => {
                    if (r_) {
                        this.updateCloseState();
                    }
                })
        }
        else {
            this.updateCloseState()
        }
    }

    private updateCloseState() {
        this.setState({
            rightPanelState: RightPanelState.None,
            hasUnsavedChanges: false,
            isAddNewUnit: false
        })
    }

    private filterUnits() {
        const { selectedProperty } = this.state;
        let filtered_ = this.state.allUnits.filter(x => x.propertyGuid === selectedProperty?.propertyGuid);

        this.setState({
            subFilteredUnits: filtered_
        }, () => {
            this._searchTermChange(this.state.searchTerm, true);
        });
    }

    private _searchTermChange = (searchTerm_: string, isDataRefreshed?: boolean): void => {
        this.setState({
            searchTerm: searchTerm_,
            subFilteredUnits: this._filterDataBySearch(this.state.filteredUnits, searchTerm_)
        });
    }

    private _filterDataBySearch = (data_: IUnitItem[], searchTerm_: string): IUnitItem[] => {
        let filteredData = data_.filter(d => {
            return (d.unitNumber.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1);
        });

        const { selectedProperty } = this.state;

        filteredData = filteredData.filter(d => selectedProperty?.propertyGuid === d.propertyGuid);

        return filteredData;
    }

    private togglePassCodeVisibility(unitGuid_: string) {
        let allUnits_ = this.state.allUnits;
        let filteredUnits_ = this.state.filteredUnits;
        let subFilteredUnits_ = this.state.subFilteredUnits;

        let allUnitIndex = allUnits_.findIndex(x => { return x.unitGuid === unitGuid_ });
        let filteredUnitsIndex = filteredUnits_.findIndex(x => { return x.unitGuid === unitGuid_ });
        let subFilteredUnitsIndex = subFilteredUnits_.findIndex(x => { return x.unitGuid === unitGuid_ });

        let passCodeState = allUnits_[allUnitIndex].isPassCodeVisible;

        allUnits_[allUnitIndex].isPassCodeVisible = !passCodeState;
        filteredUnits_[filteredUnitsIndex].isPassCodeVisible = !passCodeState;
        subFilteredUnits_[subFilteredUnitsIndex].isPassCodeVisible = !passCodeState;

        this.setState({
            allUnits: allUnits_,
            filteredUnits: filteredUnits_,
            subFilteredUnits: subFilteredUnits_
        });

    }

    private resetPasscode(rows_: IUnitItem[]) {
        var selectedGuids = rows_.map(x => x.unitGuid);
        var unitNames = rows_.map(x => x.unitNumber);

        ShowAlertWithConfirmCustomIcon(
            "Reset Passcode?",
            `<div class="mt-3">Are you sure you want to reset the passcode for Unit(s) ${unitNames.toString()}?</div>`,
            "error",
            "Yes",
            "No",
            "gryd-custom-popup",
            '<div><svg class="MuiSvgIcon-root error-icon-size" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg></div>'
        )
            .then(r_ => {
                if (r_) {
                    this._visitorBookingService.resetPasscode(selectedGuids)
                        .then(r => {
                            if (r.ok) {
                                ShowAlertWithCustomIcon(
                                    "Passcode Reset",
                                    `<div class="mt-3"> 
                                        Passcode successfully reset for Unit ${unitNames.toString()}.
                                        </div>`,
                                    "success",
                                    "gryd-custom-popup",
                                    '<div><svg class="MuiSvgIcon-root check-icon-size" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path></svg></div>'
                                ).then(r => {
                                    this.setState({
                                        selectedUnits: []
                                    });
                                    this.loadUnits();
                                });

                            }
                        });
                }
            })

    }

    private deleteUnits(rows_: IUnitItem[]) {
        var selectedGuids = rows_.map(x => x.unitGuid);
        var unitNames = rows_.map(x => x.unitNumber);

        ShowAlertWithConfirmCustomIcon(
            "Delete Unit",
            `<div class="mt-3">Are you sure you want to delete <b>unit ${unitNames.toString()}</b> from <b>${this.state.selectedProperty?.propertyAddress1}</b>? This cannot be undone.</div>`,
            "error",
            "Yes",
            "No",
            "gryd-custom-popup",
            '<div><svg class="MuiSvgIcon-root error-icon-size" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg></div>'
        )
            .then(r_ => {
                if (r_) {
                    this._visitorBookingService.deleteUnits(selectedGuids)
                        .then(r => {
                            if (r.ok) {
                                r.json().then(response => {
                                    ShowAlertWithCustomIcon(
                                        "Unit Deleted",
                                        `<div class="mt-3"> 
                                            Unit ${unitNames.toString()} has been successfully deleted from the unit list.
                                            </div>`,
                                        "success",
                                        "gryd-custom-popup",
                                        '<div><svg class="MuiSvgIcon-root check-icon-size" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path></svg></div>'
                                    ).then(r => {
                                        this.setState({
                                            deleteResponse: response,
                                            isUndoNotification: true,
                                            toastNotifyType: "deleteUnit",
                                            selectedUnits: [],
                                            rightPanelState: RightPanelState.None
                                        });
                                        this.loadUnits();
                                    });
                                });
                            }
                        });
                }
            })

    }
}

export default VisitorParkings;


interface IVisitorParkingsProps {

}

interface IVisitorParkingsState {
    isScreenAccessible: boolean;
    rightPanelState: RightPanelState;
    selectedProperty: IPropertyBasics | null;
    properties: IPropertyBasics[];
    isMultiSelectedProperty: boolean;
    isFocused: boolean;
    anchorEl: HTMLButtonElement | null;
    isAddNewUnit: boolean;
    isBulkUpload: boolean;
    isUndoNotification: boolean;
    toastNotifyType: string;
    hasUnsavedChanges: boolean;
    searchTerm: string;
    allUnits: IUnitItem[];
    filteredUnits: IUnitItem[];
    subFilteredUnits: IUnitItem[];
    selectedUnits: IUnitItem[];
    isBulkSaveEdit: boolean;
    bulkSaveEditResponse: IBulkSaveEditResponse[];
    defaultVisitorParkingPasses: number;
    deleteResponse: string[];
}
