import React from "react";
import { ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails } from "@material-ui/core";
import { DateRange, DateRangeObject } from "react-date-range";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IDateRange } from "../DataGrid/Interfaces";
import moment from "moment";

export default class DateRangeFilter extends React.Component<IDateRangeFilterProps, IDateRangeFilterState> {

    constructor(props_: IDateRangeFilterProps) {
        super(props_);

        console.log(props_.selectedDateRange);
        this.state = {
            selectedDateRanges: props_.selectedDateRange ? [{
                startDate: props_.selectedDateRange.start,
                endDate: props_.selectedDateRange.end,
                key: 'selection'
            }] : [{
                startDate: undefined,
                endDate: undefined,
                key: 'selection'
            }]
        };
    }

    componentDidUpdate(oldProps_: IDateRangeFilterProps) {
        if ((oldProps_.selectedDateRange && oldProps_.selectedDateRange.start != this.state.selectedDateRanges[0].startDate) ||
            (oldProps_.selectedDateRange && oldProps_.selectedDateRange.end != this.state.selectedDateRanges[0].endDate)) {
            this.setState({
                selectedDateRanges: [{
                    startDate: this.props.selectedDateRange.start,
                    endDate: this.props.selectedDateRange.end,
                    key: 'selection'
                }]
            });
        }
    }

    render() {
        return (
            <ExpansionPanel className="filter-panel" >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography className="filter-heading">{this.props.title}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <DateRange
                        minDate={this.props.minDate as any}
                        maxDate={this.props.maxDate as any}
                        onChange={(range_: any) => {
                            var endDate = new Date(range_.selection.endDate);
                            endDate.setHours(23, 59, 59);

                            this.setState({
                                selectedDateRanges: [range_.selection]
                            });
                            this.props.onChange({
                                start: range_.selection.startDate,
                                end: endDate
                            });
                        }}
                        ranges={this.state.selectedDateRanges}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }

}

interface IDateRangeFilterProps {
    title: string;
    selectedDateRange: IDateRange;
    minDate?: Date;
    maxDate?: Date;
    onChange: (dateRange_: IDateRange) => void;
}

interface IDateRangeFilterState {
    selectedDateRanges: DateRangeLibDateRangeObject[];
}

interface DateRangeLibDateRangeObject {
    startDate?: Date;
    endDate?: Date;
    key: string;
}