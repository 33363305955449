import React from 'react';
import './Dashboard.css'
import { IPropertyItem, IRankedProperty, IUpcomingBooking } from './Interface';
import DataGrid from '../../Components/DataGrid/DataGrid';
import { IDataGridColumn } from '../../Components/DataGrid/Interfaces';
import PropertyService from '../../Services/PropertyService';
import { DateTimeHelper } from '../../Helpers/DateTimeHelper';
import { format } from "date-fns";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

export default class UpcomingBookings extends React.Component<UpcomingBookingsProps, UpcomingBookingsState> {
    private _propertyService: PropertyService;
    private _propertyListColumns: IDataGridColumn<IUpcomingBooking>[] = [
        {
            key: "reservationStatus",
            name: "STATUS",
            contentProvider: (row_: IUpcomingBooking) => {
                if (row_.reservationStatus == 1) {
                    if (row_.isUpcomingMonthlyReservationActive || row_.paymentGroup.indexOf("Monthly") != -1) {
                        return (<CheckCircleIcon fontSize="default" style={{
                            color: "rgba(255,158,27,1)"
                        }} />);
                    } else {
                        return (<CheckCircleIcon fontSize="default" color="primary" />);
                    }
                }
                else if (row_.reservationStatus == 2) {
                    return (<CancelIcon fontSize="default" style={{
                        color: "rgba(255,158,27,1)"
                    }} />);
                }

                return (<CheckCircleIcon fontSize="default" color="primary" />);
            },
            textAlignment: "center"
        },
        {
            key: "spacer1",
            name: ""
        },
        {
            key: "bookingDate",
            name: "DATE",
            contentProvider: (row_: IUpcomingBooking) => {
                let date = DateTimeHelper.dateStringToDate(row_.bookingDate);
                return (<span><span className="no-wrap-text">{format(date, "dd/MM/yyyy")}</span></span>);
            }
        },
        {
            key: "propertyName",
            name: "PROPERTY"
        },
        {
            key: "friendlySpotId",
            name: "SPOT NO"
        },
        {
            key: "bookingStartUTC",
            name: "START TIME",
            contentProvider: (row_: IUpcomingBooking) => {
                let date = DateTimeHelper.dateStringToDate(row_.startUTC);
                return (<span><span className="no-wrap-text">{format(date, "hh:mm aa")}</span></span>);
            }
        },
        {
            key: "bookingEndUTC",
            name: "END TIME",
            contentProvider: (row_: IUpcomingBooking) => {
                let date = DateTimeHelper.dateStringToDate(row_.endUtc);
                return (<span><span className="no-wrap-text">{format(date, "hh:mm aa")}</span></span>);
            }
        },
        {
            key: "licenseNo",
            name: "LICENSE PLATE"
        }
    ]
    private _propertyList: IRankedProperty[] = [
        {
            propertyName: "Garden Park Estate",
            rank: 1,
            revenue: 567
        },
        {
            propertyName: "The Sterling Window Lofts",
            rank: 2,
            revenue: 422
        },
        {
            propertyName: "Lady Wentworth",
            rank: 3,
            revenue: 331
        },
        {
            propertyName: "Bellblock",
            rank: 4,
            revenue: 220
        },
        {
            propertyName: "Monterey Appartments",
            rank: 5,
            revenue: 194
        },
        {
            propertyName: "Atlantic Daylight Time",
            rank: 6,
            revenue: 182
        },
        {
            propertyName: "Central Daylight Time",
            rank: 7,
            revenue: 174
        },
        {
            propertyName: "City of Corner Brook",
            rank: 8,
            revenue: 169
        },
        {
            propertyName: "Gerrard West",
            rank: 9,
            revenue: 163
        },
        {
            propertyName: "Eldorado Arm",
            rank: 10,
            revenue: 160
        }
    ]
    constructor(props: UpcomingBookingsProps) {
        super(props);
        this.state = {
            allBookings: [],
            selectedProperties: []
        }
        this._propertyService = new PropertyService();
    }

    public componentDidMount = () => {
        this.loadWidgetData();
    }

    public componentDidUpdate = () => {
        let propertyGuids: string[] = this.props.selectedProperties.map(property => {
            return property.propertyGuid;
        });

        let allItemIndex = propertyGuids.indexOf('all_items');

        if (allItemIndex != -1) {
            propertyGuids.splice(allItemIndex, 1);
        }

        if (this.state.selectedProperties.toString() != propertyGuids.toString()) {
            this.loadWidgetData();
        }
    }

    private loadWidgetData() {
        let propertyGuids: string[] = this.props.selectedProperties.map(property => {
            return property.propertyGuid;
        });
        let widgetData: any = {
            selectedProperty: propertyGuids.length > 0 ? propertyGuids : null,
        };
        let allItemIndex = propertyGuids.indexOf('all_items');

        if (allItemIndex != -1) {
            propertyGuids.splice(allItemIndex, 1);
        }

        this._propertyService.getManagerDashboardBookings(widgetData)
            .then(data => {
                if (data.ok) {
                    data.json().then(d => {
                        let mappedItems = d.map((r: any) => {
                            r.bookingDate = r.startUTC;
                            return r;
                        });

                        this.setState({
                            allBookings: mappedItems,
                            selectedProperties: propertyGuids
                        });
                    })
                }
            })
            .catch((error) => {
                console.error('Error:', JSON.stringify(error));
            });
        /*let mappedItems = this._propertyList.map(r => {
            return r;
        });

        this.setState({
            allProperties: mappedItems
        })*/
    }

    public render() {
        return (
            <DataGrid
                title="Revenue"
                data={this.state.allBookings}
                columns={this._propertyListColumns}
                isRowsNonSelectable={true}
                isNoMoreLink={true}
                customPageSize={[5, 10, 15]}
                isNoRowsPerPage={true}
                defaultSortColumnKey="bookingDate"
            />
        )
    }
}

interface UpcomingBookingsProps {
    selectedProperties: IPropertyItem[];
}
interface UpcomingBookingsState {
    allBookings: IUpcomingBooking[],
    selectedProperties: string[];
}