import React from 'react';
import { Grid, Tooltip, IconButton, Button, Tab, Tabs, TextField, Switch, Dialog, AppBar, Toolbar, Typography, Slide } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import "../Profile/ProfileStyle.css";
import * as CommonString from '../../CommonString';
import { ConfigService } from '../../Services/ConfigService';
import LoaderComponent from '../../Components/LoaderComponent';
import { ShowAlert } from '../../Common/ShowAlert';
import { GetUserDetailsService } from "../../Services/GetUserDetailsService";
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { truncateSync } from 'fs';

import { PlaidLink, usePlaidLink } from 'react-plaid-link';
import BaseService from '../../Services/BaseService';



interface ProfileProps { }
interface ProfileState {

    showBlocker: boolean;
    isVerified: boolean;
    token: string;
    email: string;
    isAdminUser: boolean;
    PLAID_PUBLIC_KEY: string;
    ENVIRONMENT: string;
}

class PlaidVerify extends React.Component<ProfileProps, ProfileState> {
    private APP_NAME: string = 'GRYD PARK';
    private PRODUCT: string[] = ['auth', 'transactions'];

    constructor(props: ProfileProps) {
        super(props);

        var isAdmin = false;
        var verify: boolean = false;
        let validToken = localStorage.getItem('token');

        if (typeof (Storage) !== 'undefined') {
            var _localData = localStorage.getItem('userdata');

            if (_localData != null && _localData !== '') {
                var _data = JSON.parse(_localData);
                var roles: any = _data.userRoleID;
                verify = _data.isPlaidVerified;

                if (roles.includes(1) || roles.includes(2)) {
                    isAdmin = true;
                }
                else
                    isAdmin = false;

                if (verify || (validToken == 'success')) {
                    localStorage.setItem('token', 'success');
                }
            }
        }

        validToken = localStorage.getItem('token');

        this.state = {
            showBlocker: false,
            isVerified: ((validToken == 'success') ? true : false),
            token: String(validToken),
            email: '',
            isAdminUser: isAdmin,
            ENVIRONMENT: '',
            PLAID_PUBLIC_KEY: ''
        }
    }

    public componentDidMount = () => {

        if (typeof (Storage) !== 'undefined') {
            var _localData = localStorage.getItem('userdata');

            if (_localData != null && _localData !== '') {
                var _data = JSON.parse(_localData);
                this.setState({
                    email: _data.emailId
                });

            }
        }

        ConfigService.getPlaid()
            .then(d => {
                this.setState({
                    PLAID_PUBLIC_KEY: d.publicKey,
                    ENVIRONMENT: d.environment
                });
            });

    }

    public onSuccess = (token: any, metadata: any) => {
        // send token to server
        this.setState({
            showBlocker: true
        });
        console.log(JSON.stringify(metadata));

        console.log('Public Token: ' + token);
        console.log('Customer-selected account ID: ' + metadata.account_id);

        this.sendDataToServer(token, metadata.accounts[0]['id']);
    };

    public sendDataToServer = (token: string, accountId: string) => {

        const data = { public_token: token, account_id: accountId, email: this.state.email };

        ConfigService.getNodeBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + 'authenticate', {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                    // .then(response => response.json())
                    .then(data => {
                        if (data.ok) {
                            data.json().then(r => {
                                console.log(r);
                                if (r == "success") {
                                    this.setState({
                                        isVerified: true,
                                        token: r,
                                        showBlocker: false
                                    });
                                    localStorage.setItem('token', r);
                                }
                                else {
                                    this.showError();
                                }
                            })
                        }
                        else {
                            this.showError();
                        }
                        console.log('Success:', data);
                        this.setState({
                            showBlocker: false
                        });
                    })
                    .catch((error) => {
                        console.error('Error:', JSON.stringify(error));
                        this.showError();
                    });
            });

    }

    public showError() {
        this.setState({
            isVerified: false,
            showBlocker: false
        });
        ShowAlert('Failed!', 'Failed to verify your bank account', 'error');
    }

    public disburseError() {
        this.setState({
            isVerified: false,
            showBlocker: false
        });
        ShowAlert('Failed!', 'Failed to send payout', 'error');
    }

    public render() {
        return (
            <div className="m-3 data-grid-container dashboard-container profile-container">
                {/* <LoaderComponent loaderVisible={this.state.showBlocker} /> */}
                <Grid container className="mb-3">
                    <Grid item>
                        <h1 className="list-header">Plaid Verification</h1>
                    </Grid>
                    <Grid item xs>
                        {/* <h1 className="list-header">Profile</h1> */}
                    </Grid>
                </Grid>
                <div className="grid-list-addedit-sub proflie-container">
                    {
                        !this.state.isAdminUser &&
                        <div className="info-container">
                            <div className="payment-div-container">
                                <h4 className="preference-text">Bank Account</h4>
                                {this.state.isVerified && <h6 style={{ marginLeft: 200 }}>Verified</h6>
                                }
                                {(!this.state.isVerified && this.state.PLAID_PUBLIC_KEY != "") &&
                                    <PlaidLink style={{ marginLeft: 180, fontSize: 16, textAlign: 'center', color: '#fff', backgroundColor: '#00af85', borderRadius: 5, width: 100, height: 40 }}
                                        clientName={this.APP_NAME}
                                        env={this.state.ENVIRONMENT}
                                        countryCodes={['CA']}
                                        product={this.PRODUCT}
                                        publicKey={this.state.PLAID_PUBLIC_KEY}
                                        onSuccess={this.onSuccess}
                                    >
                                        Verify
                                </PlaidLink>}
                            </div>
                        </div>
                    }

                </div>
            </div>
        )
    }


}

export default PlaidVerify;