import React from "react";
import { Snackbar } from "@material-ui/core";

let globalToasts: IToasts[] = [];

export function ShowToastr(toastMessage_: string) {
    globalToasts.push({
        id: Math.random().toString(),
        message: toastMessage_,
        isOpen: true
    });
}

export default class Toastr extends React.Component<IToastrProps, IToastrState> {
    constructor(props_: IToastrProps) {
        super(props_);
        this.state = {
            activeToasts: []
        };
    }

    componentDidMount() {
        setInterval(() => {
            let activeToasts = globalToasts.filter(t => t.isOpen);

            if (this.state.activeToasts.map(t => t.id).join(";") != activeToasts.map(t => t.id).join(";")) {
                this.setState({
                    activeToasts: activeToasts
                });
            }
        }, 500);
    }

    render() {
        return this.state.activeToasts.map(t => {
            return (<Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={t.isOpen}
                autoHideDuration={5000}
                onClose={() => {
                    t.isOpen = false;
                    this.setState({ 
                        activeToasts: this.state.activeToasts
                    });
                }}
                message={t.message}
                className="toastr"
            />);
        });
    }
}

interface IToastrProps {
}

interface IToastrState {
    activeToasts: IToasts[];
}

interface IToasts {
    id: string;
    isOpen: boolean;
    message: string;
}