import React from "react";
import DataGrid from "../../Components/DataGrid/DataGrid";
import { IDataGridColumn, DataGridFilterType } from "../../Components/DataGrid/Interfaces";
import PropertyService from "../../Services/PropertyService";
import { IParkingSpotDetails, ITimeSpan, IPropertyManager } from "./Interfaces";
import { ShowAlert } from "../../Common/ShowAlert";

export default class PropertyManagersList extends React.Component<IPropertyManagersListProps, IPropertyManagersListState> {
    private _propertyService: PropertyService;
    private _spotManagerListColumns: IDataGridColumn<IPropertyManager>[] = [
        {
            key: "managerStatusId",
            name: "STATUS",
            contentProvider: (row_: IPropertyManager) => {
                return (
                    <div className={'list-status ' + ((row_.managerStatusId === 1) ? 'active' : 'inactive')}></div>
                );
            }
        },
        {
            key: "propertyManagerName",
            name: "NAME"
        },
        {
            key: "propertyManagerEmail",
            name: "EMAIL ADDRESS"
        },
        /*{
            key: "convertedSpotRange",
            name: "NUMBER"
        },
        {
            key: "convertedSpots",
            name: "SPOTS"
        },
        {
            key: "earnedRevenue",
            name: "EARNED REVENUE",
            textAlignment: "right",
            contentProvider: (row_: IPropertyManager) => {
                return (
                    <span>
                        ${row_.earnedRevenue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                );
            }
        }*/
    ]

    private _spotManagers: IPropertyManager[] = [
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            managerStatusId: 1,
            propertyManagerName: "Karthick",
            propertyManagerEmail: "Karthick.M@congruentindia.com",
            propertySpotType: '',
            propertySpotRange: [
                {
                    friendlySpotId: "FSPT3"
                },
                {
                    friendlySpotId: "FSP2T"
                },
                {
                    friendlySpotId: "FSP3"
                },
                {
                    friendlySpotId: "Vee2"
                },
                {
                    friendlySpotId: "CIT3"
                },
                {
                    friendlySpotId: "Vee3"
                },
                {
                    friendlySpotId: "FSP2"
                }
            ],
            spotUnderGryd: 13,
            totalSpotAssignedtoManager: 4,
            earnedRevenue: 0,
            convertedSpotRange: '',
            convertedSpots: ''
        },
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            managerStatusId: 1,
            propertyManagerName: "Karthick",
            propertyManagerEmail: "Karthick.M@congruentindia.com",
            propertySpotType: '',
            propertySpotRange: [
                {
                    friendlySpotId: "FSPT3"
                },
                {
                    friendlySpotId: "FSP2T"
                },
                {
                    friendlySpotId: "FSP3"
                },
                {
                    friendlySpotId: "Vee2"
                },
                {
                    friendlySpotId: "CIT3"
                },
                {
                    friendlySpotId: "Vee3"
                },
                {
                    friendlySpotId: "FSP2"
                }
            ],
            spotUnderGryd: 13,
            totalSpotAssignedtoManager: 4,
            earnedRevenue: 0,
            convertedSpotRange: '',
            convertedSpots: ''
        },
    ]


    constructor(props: any) {
        super(props);

        this.state = {
            allPropertyManager: [],
            searchTerm: "",
            isFiltered: false,
            isDataLoaded: false
        };

        this._propertyService = new PropertyService();
    }

    public componentDidMount = () => {
        this._propertyService.getDashboardUserSpotManagers(this.props.propertyID).then(response => {
            if (response.ok) {
                response.json().then(m => {
                    if (m) {
                        let mappedItems = m.map((r: any) => {
                            /*if (r.propertySpotRange.length > 0) {
                                r.convertedSpotRange = r.propertySpotRange[0].friendlySpotId + '-' + r.propertySpotRange[r.propertySpotRange.length-1].friendlySpotId;
                            } else {
                                r.convertedSpotRange = r.propertySpotRange[0].friendlySpotId;
                            }
                            
                            r.convertedSpots = r.totalSpotAssignedtoManager + "/" + r.spotUnderGryd;*/
                            return r;
                        });
                
                        this.setState({
                            allPropertyManager: mappedItems,
                            isDataLoaded: true
                        })
                    }
                })
            } 
            /*else {
                if (response.status === 401) {
                    this.accessTokenExpired();
                } else {
                    response.text().then(resp => {
                        console.log(response);
                    });
                    ShowAlert("", 'There was an error processing a your request.', "error");
                }
            }*/
        });

        /*let mappedItems = this._spotManagers.map(r => {
            if (r.propertySpotRange.length > 0) {
                r.convertedSpotRange = r.propertySpotRange[0].friendlySpotId + '-' + r.propertySpotRange[r.propertySpotRange.length-1].friendlySpotId;
            } else {
                r.convertedSpotRange = r.propertySpotRange[0].friendlySpotId;
            }
            
            r.convertedSpots = r.totalSpotAssignedtoManager + "/" + r.spotUnderGryd;
            return r;
        });

        this.setState({
            allPropertyManager: mappedItems
        })*/
    }


    render() {
        if (!this.state.isDataLoaded) {
            return null;
        }
        return (
            <DataGrid
                title="Manager"
                data={this.state.allPropertyManager}
                columns={this._spotManagerListColumns}
                isRowsNonSelectable={true}
                isNoMoreLink={true}
            />
        );
    }

    private setPriceRange(min: number, max: number) {
        let price_: string = '0';

        price_ = min.toFixed(2) + '-' + max.toFixed(2);

        return '$' + price_;
    }

    private setScheduleTime(startTime: ITimeSpan, endTime: ITimeSpan) {
        let time_: string = '';

        if (startTime.minutes == endTime.minutes) {
            time_ = '24/7';
        } else {
            time_ = this._makeTimeText(startTime.minutes) + '-' + this._makeTimeText(endTime.minutes)
        }

        return time_;
    }

    private _makeTimeText(minutes_: number): string {
        let fullHours = Math.floor(minutes_ / 60);
        let remainderMinutes = minutes_ % 60;
        let ampm = fullHours < 12 ? "AM" : "PM";
        let hours = fullHours % 12;

        if (hours === 0) {
            hours = 12;
        }

        return `${this._padLeft(hours.toString(), 2, '0')}:${this._padLeft(remainderMinutes.toString(), 2, '0')} ${ampm}`;
    }

    private _padLeft(text_: string, length_: number, padChar_: string): string {
        if (padChar_) {
            while (text_.length < length_) {
                text_ = padChar_ + text_;
            }
        }

        return text_;
    }

    private accessTokenExpired() {
        localStorage.clear();
        window.location.href = '/';
    }

    private setRandomRevenue() {
        let revenue: number = Math.floor(Math.random() * 100) + 50;
        return revenue;
    }
}

interface IPropertyManagersListProps {
    propertyID: string
}

interface IPropertyManagersListState {
    allPropertyManager: IPropertyManager[];
    searchTerm: string;
    isFiltered: boolean;
    isDataLoaded: boolean
}