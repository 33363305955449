import React from "react";
import DataGrid from "../../Components/DataGrid/DataGrid";
import { IDataGridColumn, DataGridFilterType } from "../../Components/DataGrid/Interfaces";
import PropertyService from "../../Services/PropertyService";
import { ITimeSpan, IPropertyTenant } from "./Interfaces";
import { ShowAlert } from "../../Common/ShowAlert";

export default class PropertySpotOwnerList extends React.Component<IPropertySpotOwnerListProps, IPropertySpotOwnerListState> {
    private _propertyService: PropertyService;
    private _spotTenantListColumns: IDataGridColumn<IPropertyTenant>[] = [
        {
            key: "managerStatusId",
            name: "STATUS",
            contentProvider: (row_: IPropertyTenant) => {
                return (
                    <div className={'list-status ' + ((row_.managerStatusId === 1) ? 'active' : 'inactive')}></div>
                );
            }
        },
        {
            key: "propertySpotOwnerName",
            name: "NAME"
        },
        {
            key: "propertySpotOwnerEmail",
            name: "EMAIL ADDRESS"
        },
        {
            key: "convertedSpotRange",
            name: "NUMBER"
        },
        {
            key: "convertedSpots",
            name: "SPOTS",
            textAlignment: "right",
        },
        {
            key: "earnedRevenue",
            name: "EARNED REVENUE",
            textAlignment: "right",
            contentProvider: (row_: IPropertyTenant) => {
                return (
                    <span>
                        ${row_.earnedRevenue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                );
            }
        }
    ]

    private _spotManagers: IPropertyTenant[] = [
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            managerStatusId: 1,
            propertySpotOwnerName: "Vinod",
            propertySpotOwnerEmail: "Vinodkumar.V@congruentindia.com",
            propertySpotType: '',
            propertySpotRange: [
                {
                    friendlySpotId: "FSPT3"
                },
                {
                    friendlySpotId: "FSP2T"
                },
                {
                    friendlySpotId: "FSP3"
                },
                {
                    friendlySpotId: "Vee2"
                },
                {
                    friendlySpotId: "CIT3"
                },
                {
                    friendlySpotId: "Vee3"
                },
                {
                    friendlySpotId: "FSP2"
                }
            ],
            spotUnderGryd: 13,
            totalSpotAssignedtoManager: 4,
            earnedRevenue: 0,
            convertedSpotRange: '',
            convertedSpots: ''
        },
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            managerStatusId: 1,
            propertySpotOwnerName: "Vinod",
            propertySpotOwnerEmail: "Vinodkumar.V@congruentindia.com",
            propertySpotType: '',
            propertySpotRange: [
                {
                    friendlySpotId: "FSPT3"
                },
                {
                    friendlySpotId: "FSP2T"
                },
                {
                    friendlySpotId: "FSP3"
                },
                {
                    friendlySpotId: "Vee2"
                },
                {
                    friendlySpotId: "CIT3"
                },
                {
                    friendlySpotId: "Vee3"
                },
                {
                    friendlySpotId: "FSP2"
                }
            ],
            spotUnderGryd: 13,
            totalSpotAssignedtoManager: 4,
            earnedRevenue: 0,
            convertedSpotRange: '',
            convertedSpots: ''
        },
    ]


    constructor(props: any) {
        super(props);

        this.state = {
            allPropertyTenant: [],
            searchTerm: "",
            isFiltered: false,
            isDataLoaded: false
        };

        this._propertyService = new PropertyService();
    }

    public componentDidMount = () => {
        this._propertyService.getDashboardUserSpotOwner(this.props.propertyID).then(response => {
            if (response.ok) {
                response.json().then(o => {
                    if (o) {
                        console.log(o);
                        
                        let mappedItems = o.map((r: any) => {
                            /*if (r.propertySpotRange.length > 0) {
                                r.convertedSpotRange = r.propertySpotRange[0].friendlySpotId + '-' + r.propertySpotRange[r.propertySpotRange.length-1].friendlySpotId;
                            } else {
                                r.convertedSpotRange = r.propertySpotRange[0].friendlySpotId;
                            }*/

                            let spotId_ = r.propertySpotRange.map((s: any) => {
                                return s.friendlySpotId;
                            })

                            r.convertedSpotRange = this.updateSpotRange(spotId_);
                            
                            //r.convertedSpots = r.totalSpotAssignedtoSpotOwner + "/" + r.spotUnderGryd;
                            r.convertedSpots = r.totalSpotAssignedtoSpotOwner;
                            return r;
                        });
                
                        this.setState({
                            isDataLoaded: true,
                            allPropertyTenant: mappedItems
                        })
                    }
                })
            } 
            /*else {
                if (response.status === 401) {
                    this.accessTokenExpired();
                } else {
                    response.text().then(resp => {
                        console.log(response);
                    });
                    ShowAlert("", 'There was an error processing a your request.', "error");
                }
            }*/
        });

        /*
        let mappedItems = this._spotManagers.map(r => {
            if (r.propertySpotRange.length > 0) {
                r.convertedSpotRange = r.propertySpotRange[0].friendlySpotId + '-' + r.propertySpotRange[r.propertySpotRange.length-1].friendlySpotId;
            } else {
                r.convertedSpotRange = r.propertySpotRange[0].friendlySpotId;
            }
            
            r.convertedSpots = r.totalSpotAssignedtoManager + "/" + r.spotUnderGryd;
            return r;
        });

        this.setState({
            allPropertyTenant: mappedItems
        })*/
    }


    render() {
        if (!this.state.isDataLoaded) {
            return null;
        }
        return (
            <DataGrid
                title="Manager"
                data={this.state.allPropertyTenant}
                columns={this._spotTenantListColumns}
                isRowsNonSelectable={true}
                isNoMoreLink={true}
            />
        );
    }

    private setPriceRange(min: number, max: number) {
        let price_: string = '0';

        price_ = min.toFixed(2) + '-' + max.toFixed(2);

        return '$' + price_;
    }

    private setScheduleTime(startTime: ITimeSpan, endTime: ITimeSpan) {
        let time_: string = '';

        if (startTime.minutes == endTime.minutes) {
            time_ = '24/7';
        } else {
            time_ = this._makeTimeText(startTime.minutes) + '-' + this._makeTimeText(endTime.minutes)
        }

        return time_;
    }

    private _makeTimeText(minutes_: number): string {
        let fullHours = Math.floor(minutes_ / 60);
        let remainderMinutes = minutes_ % 60;
        let ampm = fullHours < 12 ? "AM" : "PM";
        let hours = fullHours % 12;

        if (hours === 0) {
            hours = 12;
        }

        return `${this._padLeft(hours.toString(), 2, '0')}:${this._padLeft(remainderMinutes.toString(), 2, '0')} ${ampm}`;
    }

    private _padLeft(text_: string, length_: number, padChar_: string): string {
        if (padChar_) {
            while (text_.length < length_) {
                text_ = padChar_ + text_;
            }
        }

        return text_;
    }

    private accessTokenExpired() {
        localStorage.clear();
        window.location.href = '/';
    }

    private setRandomRevenue() {
        let revenue: number = Math.floor(Math.random() * 100) + 50;
        return revenue;
    }

    private updateSpotRange(spotList_: string[]) {
        let spotNumbers: string = "";

        if (spotList_.length > 1) {
            spotNumbers = spotList_.toString().length >= 20 ? spotList_.toString().substring(0, 20) + '...' : spotList_.toString();

            let output: any = [];

            spotList_.forEach(function (item) {
                output.push(Number(item.replace(/\'/g, '').split(/(\d+)/).filter(Boolean)[1]));
            });

            output = output.sort(function (a: number, b: number) {
                return a - b;
            });

            if (output.length > 0) {
                var isNotSeq_ = false;
                for (let i = 0; i < output.length; i++) {
                    if ((output[0] + i) != output[i]) {
                        isNotSeq_ = true;
                        break;
                    }
                }

                if (!isNotSeq_) {
                    spotNumbers = spotList_[0] + '-' + spotList_[spotList_.length - 1];
                }
            }
        } else {
            spotNumbers = spotList_.length > 0 ? spotList_.toString() : '-';
        }

        return spotNumbers;
    }
}

interface IPropertySpotOwnerListProps {
    propertyID: string
}

interface IPropertySpotOwnerListState {
    allPropertyTenant: IPropertyTenant[];
    searchTerm: string;
    isFiltered: boolean;
    isDataLoaded: boolean;
}
