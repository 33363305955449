export class PreserveFilters {

    static preserveFilter(key: string, value: any) {
        window.localStorage.setItem(key, JSON.stringify(value))
    }

    static readPreservedFilter(key: string) {
        let modifiedFilter: any = {}

        let filters = window.localStorage.getItem(key)

        if (filters) {
            let parsedFilters = JSON.parse(filters)
            for (let filterkey in parsedFilters) {
                if (parsedFilters[filterkey].type == 4 && parsedFilters[filterkey].filterValue.start) {
                    parsedFilters[filterkey] = { type: 4, filterValue: { start: new Date(parsedFilters[filterkey].filterValue.start), end: new Date(parsedFilters[filterkey].filterValue.end) } }
                    // console.log(typeof(parsedFilters[filterkey].type.start))
                }
            }
            // console.log(parsedFilters)
            return parsedFilters
        }
        else {
            return undefined
        }

    }

    static clearPreservedFilter(key: string) {
        window.localStorage.removeItem(key)
    }

    static setSearchTerm(key:string,value:any){
        window.localStorage.setItem(key,JSON.stringify({lastSearchTerm:value}))
    }
    static getSearchTerm(key:string){
       return this.readPreservedFilter(key)?this.readPreservedFilter(key).lastSearchTerm:null
    }
}