import { DateTimeHelper } from "../Helpers/DateTimeHelper";
import NumberRangeSelector, { INumberRangeSelectorProps, INumberRangeSelectorState } from "./NumberRangeSelector";

export default class TimeRangeSelector extends NumberRangeSelector<ITimeRangeSelectorProps, ITimeRangeSelectorState> {
    protected _label: string = "Time";
    protected _txtMask: RegExp = /^[0-9]{0,2}[:.]{0,1}[0-9]{0,2}(\s)*(a|p){0,1}(m){0,1}$/i;
    protected _defaultMin = 0;
    protected _defaultMax = 1439;
    protected _defaultSelection = [600, 1320];
    protected _defaultStepsize = 30;

    constructor(props_: ITimeRangeSelectorProps) {
        super(props_);
        this.state = {
            min: this.props.min ? this.props.min : this._defaultMin,
            max: this.props.max ? this.props.max : this._defaultMax,
            range: this.props.selectedRange ? [this.props.selectedRange.start, this.props.selectedRange.end] : this._defaultSelection,
            stepSize: this._defaultStepsize
        };
    }

    protected _makeText(value_: number): string {
        return DateTimeHelper.minutesToTimeString(value_, false);
    }

    protected _readText(textBox_: HTMLInputElement): number {
        return  DateTimeHelper.timeStringToMinutes(textBox_.value);
    }

    protected _onTextChange(rangeIndex_: number, textBox_?: HTMLInputElement): void {
        if (textBox_) {
            if (!this._txtMask.test(textBox_.value)) {
                textBox_.value = this._makeText(this.state.range[rangeIndex_]);
            }
        }
    }
}

interface ITimeRangeSelectorProps extends INumberRangeSelectorProps { }

interface ITimeRangeSelectorState extends INumberRangeSelectorState { }