import React from "react";
import { ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, FormControlLabel, Checkbox, TextField } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import VirtualizeAutoComplete from '../../Common/VirtualizeAutoComplete';

export default class SelectFilter extends React.Component<ISelectFilterProps, ISelectFilterState> {

    constructor(props_: ISelectFilterProps) {
        super(props_);
        this.state = {
            selectedItems: props_.selectedItems
        };
    }

    componentDidUpdate(oldProps_: ISelectFilterProps) {
        if (oldProps_.selectedItems != this.state.selectedItems) {
            this.setState({
                selectedItems: this.props.selectedItems
            });
        }
    }

    render() {
        return (
            <ExpansionPanel className="filter-panel">
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography className="filter-heading">{this.props.title}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <VirtualizeAutoComplete
                        multiple={true}
                        options={this.props.items}
                        disableCloseOnSelect={true}
                        size="small"
                        value={this.state.selectedItems}
                        onChange={(e_: any, values_: string[]) => {
                            this.setState({
                                selectedItems: values_
                            });
                            this.props.onChange(values_);
                        }}
                        getOptionLabel={(option: string) => option && option !== '' ? option : 'Blank'}                      
                        renderInput={(params: any) => <TextField {...params} label={this.props.placeholderText} variant="outlined" />}
                         />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

interface ISelectFilterProps {
    title: string;
    placeholderText: string;
    items: string[];
    selectedItems: string[];
    onChange: (selectedItems_: string[]) => void;
}

interface ISelectFilterState {
    selectedItems: string[];
}