import * as React from 'react';
import './Property.css';
import {createStyles, lighten, makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import {ConfigService} from '../Services/ConfigService';
import {ShowAlert, ShowAlertwithConfirm} from '../Common/ShowAlert';
import {
  Chip,
  ClickAwayListener,
  Drawer,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  InputBase,
  MenuItem,
  MenuList,
  Popover,
  Radio,
  RadioGroup,
  Select
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import PriceRangeSelector from '../Components/PriceRangeSelector';
import ClearIcon from '@material-ui/icons/Clear';
import ImageGrid from '../Components/ImageGridComponent';
import FilePicker from '../Components/BasicComponents/FilePicker';
import CustomMaps from '../Components/GoogleMapsComponent';
import {IFileInfo} from '../Components/BasicComponents/FileUploadModal';
import AddUser from '../Components/AddUser';
import {NewUserDetails} from '../Models/NewUserDetails';
import AddCompany from '../Components/AddCompany';
import {NewCompanyDetails} from '../Models/NewCompanyDetails';
import PropertyService from '../Services/PropertyService';
import SpotService from '../Services/SpotService';
import {ISpot, ISpotFeature} from '../Pages/Spots/Interfaces';
import AddressDropdown from '../Components/AddressDropdown';
import EditIcon from '@material-ui/icons/Edit';
import LinkIcon from '@material-ui/icons/Link';
import {GetUserDetailsService} from '../Services/GetUserDetailsService';
import StringHelper from '../Helpers/StringHelper';
import ObjectHelper from '../Helpers/ObjectHelper';
import {ShowToastr} from '../Components/Toastr';
// import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import BaseService from '../Services/BaseService';
import UserService from '../Services/UserService';
import {IDailyTimeBlock} from './interface';
import ArrayHelper from '../Helpers/ArrayHelper';
import NumberHelper from '../Helpers/NumberHelper';
import {INumberRange} from '../Components/DataGrid/Interfaces';
import {PreserveFilters} from '../Common/PreserveFilters';
import QRCode from "react-qr-code";
import {CheckFeaturePermission, CheckScreenPermission} from '../Common/CheckScreenPermission';
import ProgressTop from '../Components/ProgressTop';
import PrivateLotService from "../Services/PrivateLotService";

export interface Data {
  propertyGuid: string,
  propertyName: string,
  propertyAbout: string,
  propertyAddress1: string,
  propertyAddress2: string,
  propertyCity: string,
  propertyState: string,
  propertyLatitude: number,
  propertyLongitude: number,
  propertyCountry: string,
  propertyZipCode: string,
  propertyTimeZone: string,
  propertyQuantity: number,
  propertySpotQuantity: number,
  propertyRedeem: string,
  propertyInstructions: string,
  propertyOwnerName: string,
  propertyOwnerGuid: string,
  propertyCompanyName: string,
  propertyCompanyGuid: string,
  propertyCompanyAddress1: string,
  propertyCompanyAddress2: string,
  propertyCompanyCity: string,
  propertyCompanyCountry: string,
  propertyCompanyState: string,
  propertyCompanyZipCode: string,
  propertyConfigGuid?: string,
  propertyStatusId: number,
  revenueYTD: number,
  RevenueText: string,
  images: IFileInfo[],
  originalImages: IFileInfo[],
  propertyDistributionType: number;
  propertyDisbursementSettingGuid: string;
  propertyGrydPercentage: number;
  propertyPropertyPercentage: number;
  propertySpotOwnerPercentage: number;
  buildingContacts: string[];
  buildingContactNames: string[];
  isYardiConfigDeleted: boolean;
}

interface ManagerList {
  companyGuid: string,
  companyName: string
}

interface OwnerList {
  propertyOwnerGuid: string,
  propertyOwnerName: string
}

interface BulidingContactList {
  applicationUserGuid: string,
  name: string,
  role: number
}

interface IDefaultDisbursement {
  propertyGrydPercentage: number;
  propertyPropertyPercentage: number;
  propertySpotOwnerPercentage: number;
}

interface CompanyList {
  Name: string,
  Address: string;
  City: string;
  Owner: string;
  Manager: string;
  Spot: string;
  Revenue: string;
}

interface YardiConfigs {
  pmsConfigureGuid: string;
  description: string;
}

interface FilterOptionsModel {
  AvailableCities: string[];
  City: string[];
  AvailableCompany: string[];
  AvailableOwner: string[];
  AvailableStatus: string[];
  Status: string[];
  Company: string[];
  Owner: string[];
  Revenue: RangeModel;
  SlotsCorrelation: string;
  DateRangeSelected: string,
  FilterApplied: boolean
}

interface RangeModel {
  start: number,
  end: number
}

export interface ScheduleModule {
  selectedDays?: number[],
  selectedPriceRange?: RangeModel,
  selectedTimeRange?: RangeModel
}

interface PropertyStatus {
  propertyGuid: string,
  propertyStatusId: number
}

let selectedRows: Data[] = [];
let setSelectedCallback: (d_: Data[]) => void;
let setMultiSelectedCallback: (d_: Data[]) => void;
let _spotService: SpotService = new SpotService();
let _propertyService: PropertyService = new PropertyService();
let _privateLotService: PrivateLotService = new PrivateLotService();
let _userService: UserService = new UserService();
let isLoaderVisible: boolean = false;


function fetchCompanyList() {
  return ConfigService.getBaseUrl()
    .then(url => {
      return BaseService.sendRequest(url + 'company/all', {
        method: 'get',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      })
      //.then(response => response.json());
    });
}

function fetchOwnerList() {
  return ConfigService.getBaseUrl()
    .then(url => {
      return BaseService.sendRequest(url + 'user/userdropdownlist', {
        method: 'get',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      })
      //.then(response => response.json());
    });
}


function fetchPropertyList() {
  // user/getproperty
  return ConfigService.getBaseUrl()
    .then(url => {
      return BaseService.sendRequest(url + 'property/get', {
        method: 'get',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      })
      //.then(response => response.json());
    });
}

async function _getAllProperties(contentParser_: (properties_: Data[]) => Data[], onProgress_: (properties_: Data[], progress_: number) => void) {
  let remainder = "";

  return BaseService.asyncResponse<Data>(
    PropertyService.getPropertyList(),
    (content_, contentLength, receivedLength) => {
      let properties: Data[] = [];

      if (content_) {
        content_ = remainder + content_;

        if (content_ && contentLength != receivedLength) {
          let lastIndex = content_.lastIndexOf(",{\"propertyGuid\"");
          if (lastIndex == -1) {
            remainder = content_;
            // content_ = "";
          } else {
            remainder = "[" + StringHelper.trimStart(content_.substring(lastIndex), ',');
            content_ = content_.substring(0, lastIndex) + "]";
          }
        }

        if (content_) {
          try {
            properties = JSON.parse(content_);
          } catch (e) {
          }
        }
      }

      return contentParser_(properties);
    }, onProgress_);
}

function udatePropertyStatus(record: PropertyStatus[]) {
  var postData = record;

  return ConfigService.getBaseUrl()
    .then(url => {
      return BaseService.sendRequest(url + 'property/changestatus', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
      })
      //.then(response => response.json());
    });
}

function addNewProperty(record: Data) {
  let postData: any;

  postData = {
    "PropertyName": record.propertyName,
    "propertyAbout": record.propertyAbout,
    "PropertyAddress1": record.propertyAddress1,
    "PropertyAddress2": record.propertyAddress2,
    "PropertyCity": record.propertyCity,
    "PropertyLatitude": record.propertyLatitude != 0 ? record.propertyLatitude : null,
    "PropertyLongitude": record.propertyLongitude != 0 ? record.propertyLongitude : null,
    "PropertyState": record.propertyState,
    "PropertyCountry": record.propertyCountry,
    "PropertyZipCode": record.propertyZipCode,
    "PropertyTimeZone": record.propertyTimeZone,
    "propertyQuantity": record.propertyQuantity,
    "propertyRedeem": record.propertyRedeem,
    "propertyInstructions": record.propertyInstructions,
    "propertyStatusId": 2,
    "PropertyOwnerGuid": record.propertyOwnerGuid ? record.propertyOwnerGuid : null,
    "PropertyConfigGuid": record.propertyConfigGuid ? record.propertyConfigGuid : null,
    "PropertyDistributionType": record.propertyDistributionType,
    "PropertyGrydPercentage": record.propertyGrydPercentage,
    "PropertyPropertyPercentage": record.propertyPropertyPercentage,
    "PropertySpotOwnerPercentage": record.propertySpotOwnerPercentage,
    "BuildingContacts": record.buildingContacts
  };

  if (record.propertyCompanyGuid != "") {
    postData["PropertyCompanyGuid"] = record.propertyCompanyGuid;
  }

  return ConfigService.getBaseUrl()
    .then(url => {
      return BaseService.sendRequest(url + 'property/insert', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
      }, 'ignoreAlert');
      //.then(response => response.json());
    });
}

function updateProperty(record: Data) {
  let postData: any;

  postData = {
    "PropertyGuid": record.propertyGuid,
    "PropertyName": record.propertyName,
    "propertyAbout": record.propertyAbout,
    "PropertyAddress1": record.propertyAddress1,
    "PropertyAddress2": record.propertyAddress2,
    "PropertyLatitude": record.propertyLatitude != 0 ? record.propertyLatitude : null,
    "PropertyLongitude": record.propertyLongitude != 0 ? record.propertyLongitude : null,
    "PropertyCity": record.propertyCity,
    "PropertyState": record.propertyState,
    "PropertyCountry": record.propertyCountry,
    "PropertyZipCode": record.propertyZipCode,
    "PropertyTimeZone": record.propertyTimeZone,
    "propertyQuantity": record.propertyQuantity,
    "propertyRedeem": record.propertyRedeem,
    "propertyInstructions": record.propertyInstructions,
    "propertyStatusId": record.propertyStatusId,
    "PropertyOwnerGuid": record.propertyOwnerGuid ? record.propertyOwnerGuid : null,
    "PropertyConfigGuid": record.propertyConfigGuid ? record.propertyConfigGuid : null,
    "PropertyDistributionType": record.propertyDistributionType,
    "PropertyGrydPercentage": record.propertyGrydPercentage,
    "PropertyPropertyPercentage": record.propertyPropertyPercentage,
    "PropertySpotOwnerPercentage": record.propertySpotOwnerPercentage,
    "BuildingContacts": record.buildingContacts
  };

  if (record.propertyCompanyGuid != "") {
    postData["PropertyCompanyGuid"] = record.propertyCompanyGuid;
  }

  return ConfigService.getBaseUrl()
    .then(url => {
      return BaseService.sendRequest(url + 'property/update', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
      }, 'ignoreAlert');
      //.then(response => response.json());
    });
}

function uploadImage(data_: Data) {

  const formData = new FormData();
  let hasAnyNewImages = false;

  for (let i = 0; i < data_.images.length; i++) {
    let image = data_.images[i];
    if (image.fileContent && !image.storedFileGuid) {
      if (image.internalUid) {
        formData.append(image.internalUid, image.fileContent, image.fileName);
        hasAnyNewImages = true;
      }
    }
  }

  return ConfigService.getBaseUrl()
    .then(url => {
      return BaseService.sendRequest(url + 'property/upload-images?propertyGuid=' + data_.propertyGuid, {
        method: 'post',
        headers: {},
        body: formData
      })
      //.then(response => response.json());
    });
}

function UpdateAssociateImage(imageID: string[], propertyGuid: string) {
  var postData = imageID;

  return ConfigService.getBaseUrl()
    .then(url => {
      return BaseService.sendRequest(url + 'property/associate-images?propertyGuid=' + propertyGuid, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
      })
      //.then(response => response.json());
    });
}


type Order = 'asc' | 'desc';

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function filterTable(tableRows: Data[], filterValue: FilterOptionsModel) {
  let filteredItems: Data[] = [];
  if (PreserveFilters.readPreservedFilter('properties')) {
    filterValue = PreserveFilters.readPreservedFilter('properties')
  }

  if (filterValue.FilterApplied) {
    tableRows.map((v, i, a) => {
      let isCityMatched: boolean = false;
      let isCompanyMatched: boolean = false;
      let isRevenueMatched: boolean = false;
      let isStatusMatched: boolean = false;
      let isOwnerMatched: boolean = false;

      if (filterValue.AvailableCities.length !== filterValue.City.length && filterValue.City.length > 0) {
        for (var c = 0; c < filterValue.City.length; c++) {
          if (filterValue.City[c] === v.propertyCity) {
            isCityMatched = true;
            break;
          } else if (filterValue.City[c] === 'Blank' && (v.propertyCity === '' || v.propertyCity === null)) {
            isCityMatched = true;
            break;
          }
        }
      } else {
        isCityMatched = true;
      }

      if (filterValue.AvailableStatus.length !== filterValue.Status.length && filterValue.Status.length > 0) {
        for (var c = 0; c < filterValue.Status.length; c++) {
          let status: string = v.propertyStatusId == 1 ? 'Active' : 'Inactive';
          if (filterValue.Status[c] === status) {
            isStatusMatched = true;
            break;
          }
        }
      } else {
        isStatusMatched = true;
      }

      if (filterValue.Company[0] !== 'All' && filterValue.Company.length > 0) {
        for (var c = 0; c < filterValue.Company.length; c++) {
          if (filterValue.Company[c] === v.propertyCompanyName) {
            isCompanyMatched = true;
            break;
          }
        }
      } else {
        isCompanyMatched = true;
      }

      if (filterValue.Owner[0] !== 'All' && filterValue.Owner.length > 0) {
        for (var c = 0; c < filterValue.Owner.length; c++) {
          let propertyOwnerName = v.propertyOwnerName;
          if (propertyOwnerName.trim() === "")
            propertyOwnerName = "Blank";

          if (propertyOwnerName === filterValue.Owner[c].trim()) {
            isOwnerMatched = true;
            break;
          }
        }
      } else {
        isOwnerMatched = true;
      }

      if (filterValue.Revenue.start !== 0 || filterValue.Revenue.end !== 0) {
        if (filterValue.Revenue.end !== 0) {
          if (v.revenueYTD >= filterValue.Revenue.start && v.revenueYTD <= filterValue.Revenue.end) {
            isRevenueMatched = true;
          }
        } else {
          if (v.revenueYTD >= filterValue.Revenue.start) {
            isRevenueMatched = true;
          }
        }

      } else {
        isRevenueMatched = true;
      }

      if (isCityMatched && isCompanyMatched && isRevenueMatched && isStatusMatched && isOwnerMatched) {
        filteredItems.push(v);
      }
    });

    return filteredItems;
  }

  return tableRows;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  alphaNumeric?: boolean;
}

const headCells: HeadCell[] = [
  {id: 'propertyStatusId', numeric: false, disablePadding: true, label: 'STATUS'},
  {id: 'propertyName', numeric: false, disablePadding: false, label: 'NAME', alphaNumeric: true},
  {id: 'propertyAddress1', numeric: false, disablePadding: false, label: 'ADDRESS', alphaNumeric: true},
  {id: 'propertyCity', numeric: false, disablePadding: false, label: 'CITY'},
  {id: 'propertyOwnerName', numeric: false, disablePadding: false, label: 'OWNER'},
  {id: 'propertyCompanyName', numeric: false, disablePadding: false, label: 'MANAGEMENT COMPANY'},
  {id: 'propertySpotQuantity', numeric: false, disablePadding: false, label: '# SPOTS'},
  {id: 'revenueYTD', numeric: false, disablePadding: false, label: 'REVENUE YTD'}
];


interface EnhancedTableHeadProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const {classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.thead + ' th-list-thead'}>
      <TableRow>
        {/* <TableCell padding="none" className={classes.headerStyleCheckbox + ' th-list-head'}>
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                        className="check-box-table"
                    />

                </TableCell> */}
        {headCells.map((headCell) => (
          !(GetUserDetailsService.getUserDetails().userRoleID.indexOf(8) != -1 && headCell.id === 'revenueYTD') &&
          <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : (headCell.id === 'propertySpotQuantity' || headCell.id === 'revenueYTD') ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              className={classes.headerStyle}
              sortDirection={orderBy === headCell.id ? order : false}
          >
              <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                ) : null}
              </TableSortLabel>
          </TableCell>
        ))}
        <TableCell padding="none" className={classes.headerStyleMore + ' th-list-head'}>
          <IconButton aria-label="More" disabled={numSelected === 0}>
            <MoreHorizIcon/>
          </IconButton>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
    toolheader: {
      padding: '0px 10px',
      marginBottom: '10px'
    },
    toolContainer: {
      textAlign: 'right',
      padding: '0px'
    },
    toolButton: {
      marginLeft: '5px',
    }
  }),
);


interface EnhancedTableToolbarProps {
  numSelected: number;
  onSearchChange: (searchText: string) => void,
  buttonState: (f_: boolean, a_: boolean) => void;
  isFiltered: boolean;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const {numSelected, onSearchChange, buttonState, isFiltered} = props;
  let searchTerm = window.localStorage.getItem('propSearch')
  const [searchedTextState, setSearchedTextState] = React.useState<string>(searchTerm ? searchTerm : '');
  const [searchedTextFocused, setSearchedTextFocused] = React.useState<boolean>(searchTerm ? true : false);
  const [searchInput, setSearchInput] = React.useState<null | HTMLElement>(null);

  const enableSearch = (event: any) => {
    if (searchInput) {
      searchInput.focus();
    }
    setSearchedTextFocused(true);
  }

  const clearSearch = (event: any) => {
    if (searchInput) {
      searchInput.focus();
    }
    setSearchedTextState('');
    window.localStorage.setItem('propSearch', '');
    if (props.onSearchChange) {
      props.onSearchChange('');
    }
  }

  const handleSearchTextChange = (event: any) => {
    var _targetValue = event.target.value;
    setSearchedTextState(_targetValue);
    window.localStorage.setItem('propSearch', _targetValue);
    if (props.onSearchChange) {
      props.onSearchChange(_targetValue);
    }
  }

  const handleSearchTextBlur = (event: any) => {
    var _targetValue = event.target.value;

    if (_targetValue == '') {
      setSearchedTextFocused(false);
    }

  }

  const onToolButtonClick = (buttonType: string) => {
    let add_: boolean = false;
    let filter_: boolean = false;

    if (buttonType === 'add') {
      add_ = true;
    } else {
      filter_ = true;
    }
    if (props.buttonState) {
      props.buttonState(filter_, add_);
    }
  }

  return (
    <div className={classes.toolheader + ' table-list-header-fixed'}>
      <Grid container>
        <Grid item>
          <h1 className="list-header">Properties</h1>
        </Grid>
        <Grid item xs>
          <Grid container>
            <Grid item xs>
              <div className={classes.toolContainer}>
                <div
                  className={'table-search-container' + (searchedTextFocused == true ? ' table-search' : ' table-search-blur')}>
                  <Paper className={classes.root + ' table-search-input-container'}>
                    <InputBase
                      name="search"
                      className="table-search-input"
                      placeholder="Search properties"
                      inputProps={{'aria-label': 'search properties'}}
                      value={searchedTextState}
                      onChange={handleSearchTextChange}
                      onBlur={handleSearchTextBlur}
                      inputRef={refElm => {
                        setSearchInput(refElm)
                      }}
                    />
                    {
                      searchedTextState.length > 0 ?
                        <Tooltip title="Clear" className={classes.toolButton}>
                          <IconButton type="submit" aria-label="Clear" onClick={clearSearch}>
                            <ClearIcon/>
                          </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title="Search" className={classes.toolButton}>
                          <IconButton type="submit" aria-label="Search" onClick={enableSearch}>
                            <SearchIcon/>
                          </IconButton>
                        </Tooltip>
                    }

                  </Paper>
                </div>
                <Tooltip title="Filter list" className={classes.toolButton}>
                  <IconButton aria-label="filter list" onClick={() => onToolButtonClick('filter')}>
                    <FilterListIcon color={isFiltered ? "primary" : undefined}/>
                  </IconButton>
                </Tooltip>
                {
                  //(GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) != -1 || GetUserDetailsService.getUserDetails().userRoleID.indexOf(2)
                  CheckFeaturePermission('"add-property"') ?
                    <Tooltip title="Add Property" className={classes.toolButton + ' add-lit-button'}>
                      <IconButton aria-label="Add Property" onClick={() => onToolButtonClick('add')}>
                        <AddIcon fontSize="small"/>
                      </IconButton>
                    </Tooltip> : null
                }
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

interface FilteredLableProps {
  FilteredItems: FilterOptionsModel,
  onFilterLableDelete: (updatedLable: FilterOptionsModel) => void
}

const FilteredLable = (props: FilteredLableProps) => {
  let {FilteredItems, onFilterLableDelete} = props;
  if (PreserveFilters.readPreservedFilter('properties')) {
    FilteredItems = PreserveFilters.readPreservedFilter('properties')
  }

  let isRevenueFilterApplied: boolean = false;
  let revenueFilteredText: string = '';

  const convertToDollar = (amount: number) => {
    return "$" + amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  if (FilteredItems.Revenue.start !== 0 || FilteredItems.Revenue.end !== 0) {
    isRevenueFilterApplied = true;

    if (FilteredItems.Revenue.start === 0 && FilteredItems.Revenue.end === 50000) {
      revenueFilteredText = "Less than " + convertToDollar(FilteredItems.Revenue.end);
    } else if (FilteredItems.Revenue.start === 50000 && FilteredItems.Revenue.end === 0) {
      revenueFilteredText = "More than " + convertToDollar(FilteredItems.Revenue.start);
    } else if (FilteredItems.Revenue.start === 100000 && FilteredItems.Revenue.end === 0) {
      revenueFilteredText = "More than " + convertToDollar(FilteredItems.Revenue.start);
    } else {
      revenueFilteredText = convertToDollar(FilteredItems.Revenue.start) + ' - ' + convertToDollar(FilteredItems.Revenue.end);
    }
  }


  const handleDeleteChip = (lable: string, columnName: string) => {
    let updatedFilterOption: FilterOptionsModel = FilteredItems;

    if (columnName === 'city') {
      const currentIndex = updatedFilterOption.City.indexOf(lable);
      const newChecked = [...updatedFilterOption.City];

      newChecked.splice(currentIndex, 1);

      updatedFilterOption.City = newChecked;
    } else if (columnName === 'status') {
      const currentIndex = updatedFilterOption.Status.indexOf(lable);
      const newChecked = [...updatedFilterOption.Status];

      newChecked.splice(currentIndex, 1);

      updatedFilterOption.Status = newChecked;
    } else if (columnName === 'company') {
      const currentIndex = updatedFilterOption.Company.indexOf(lable);
      const newChecked = [...updatedFilterOption.Company];

      newChecked.splice(currentIndex, 1);

      updatedFilterOption.Company = newChecked;
    } else if (columnName === 'owner') {
      const currentIndex = updatedFilterOption.Owner.indexOf(lable);
      const newChecked = [...updatedFilterOption.Owner];

      newChecked.splice(currentIndex, 1);

      updatedFilterOption.Owner = newChecked;
    } else if (columnName === 'revenue') {
      let emptyRange: RangeModel = {
        start: 0,
        end: 0
      }
      updatedFilterOption.Revenue = emptyRange;
    }

    if (props.onFilterLableDelete) {
      props.onFilterLableDelete(updatedFilterOption);
    }
  }

  return (
    <div className="chip-container">
      {
        FilteredItems.City.map((item, index) => (
          <Chip
            key={item + index}
            label={item}
            className="chip-lable"
            onDelete={() => handleDeleteChip(item, 'city')}
            variant="outlined"
          />
        ))
      }

      {
        FilteredItems.Owner.map((item, index) => (
          <Chip
            key={item + index}
            label={item}
            className="chip-lable"
            onDelete={() => handleDeleteChip(item, 'owner')}
            variant="outlined"
          />
        ))
      }

      {
        FilteredItems.Company.map((item, index) => (
          <Chip
            key={item + index}
            label={item}
            className="chip-lable"
            onDelete={() => handleDeleteChip(item, 'company')}
            variant="outlined"
          />
        ))
      }

      {
        isRevenueFilterApplied ? <Chip
          label={revenueFilteredText}
          className="chip-lable"
          onDelete={() => handleDeleteChip('', 'revenue')}
          variant="outlined"
        /> : null
      }

      {
        FilteredItems.Status.map((item, index) => (
          <Chip
            key={item + index}
            label={item}
            className="chip-lable"
            onDelete={() => handleDeleteChip(item, 'status')}
            variant="outlined"
          />
        ))
      }

      {
        FilteredItems.SlotsCorrelation !== '' ? <Chip
          label={FilteredItems.SlotsCorrelation}
          className="chip-lable"
          // onClick={handleClick}
          // onDelete={handleDelete}
          variant="outlined"
        /> : null
      }

      {
        FilteredItems.DateRangeSelected !== '' ? <Chip
          label={FilteredItems.DateRangeSelected}
          className="chip-lable"
          // onClick={handleClick}
          // onDelete={handleDelete}
          variant="outlined"
        /> : null
      }
    </div>
  )

}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // width: '100%',
      padding: '0px 10px',
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden'
    },
    rightMenu: {
      width: '50%',
      maxWidth: '400px',
      background: '#000',
    },
    closedMenu: {
      marginRight: '-400px'
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      // flex: 1,
      display: 'flex',
      flexDirection: 'column',
      border: '0px solid #000',
      boxShadow: 'none',
      margin: '0px !important',
      borderRadius: '0px',
      background: 'none'
    },
    table: {
      minWidth: 750,
      borderCollapse: 'collapse'
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    thead: {
      borderBottom: '0px solid #000',
    },
    headerStyle: {
      fontWeight: 'bold',
      background: '#ffffff',
      padding: '8px 5px !important',
      color: '#8a959e !important'
    },
    headerStyleCheckbox: {
      fontWeight: 'bold',
      background: '#ffffff',
      padding: '0px 5px !important'
    },
    headerStyleMore: {
      fontWeight: 'bold',
      background: '#ffffff',
      padding: '0px !important'
    },
    tableContainer: {
      flex: 1,
      padding: '0px',
      width: 'auto',
      // maxHeight: '500px',
      background: '#ffffff',
      overflow: 'auto'
    },
    rowStyle: {
      height: 'auto !important'
    },
    bodyCell: {
      padding: '10px 10px !important'
    }
  }),
);

interface EnhancedTableProps {
  dataLoaded: boolean,
  data: Data[],
  managerList: ManagerList[],
  ownerList: OwnerList[],
  contactList: BulidingContactList[],
  featureList: ISpotFeature[];
  yardiConfigs: YardiConfigs[];
  defaultDisbursement: IDefaultDisbursement;
  guestUrl: string;
  loaderPercentage: number;
  isPropertyLoaded?: boolean;
}


export default class EnhancedTable extends React.Component<{}, EnhancedTableProps> {
  constructor(props: {}) {
    super(props);
    this.state = {
      loaderPercentage: 0,
      data: [],
      yardiConfigs: [],
      dataLoaded: false,
      managerList: [],
      contactList: [],
      ownerList: [],
      featureList: [],
      defaultDisbursement: {
        propertyGrydPercentage: 0,
        propertyPropertyPercentage: 0,
        propertySpotOwnerPercentage: 0
      },
      guestUrl: ""
    };
  }

  async componentDidMount() {
    var isAccessible = await CheckScreenPermission("user-interface-properties");

    if (!isAccessible) return false;

    _spotService.getFeatureList()
      .then(r => r.json())
      .then(r => {
        ConfigService.getDefaultDisbursement()
          .then(d => {
            this.setState({
              featureList: r,
              defaultDisbursement: d
            }, () => {
              ConfigService.getGuestUrl()
                .then(url => {
                  this.setState({
                    guestUrl: url
                  }, () => {
                    this.loadProperties();
                  });
                });
            });
          })

      });


    /*fetchPropertyList().then(response => {
            if (response.ok) {
                ShowBlocker(true);
                response.text().then(resp => {
                    let rowItems: Data[] = JSON.parse(resp);
                    ShowBlocker(false);
                    let mappedItems = rowItems.map(r => {
                        let _randomRevenueAmount = this.setRandomRevenue();

                        return {
                            propertyGuid: r.propertyGuid,
                            propertyName: r.propertyName ? r.propertyName : '',
                            propertyAbout: r.propertyAbout,
                            propertyAddress1: r.propertyAddress1 ? r.propertyAddress1 : '',
                            propertyAddress2: r.propertyAddress2 ? r.propertyAddress2 : '',
                            propertyCity: r.propertyCity ? r.propertyCity : '',
                            propertyState: r.propertyState,
                            propertyLatitude: r.propertyLatitude,
                            propertyLongitude: r.propertyLongitude,
                            propertyCountry: r.propertyCountry,
                            propertyZipCode: r.propertyZipCode,
                            propertyTimeZone: r.propertyTimeZone,
                            propertyQuantity: r.propertyQuantity,
                            propertySpotQuantity: r.propertySpotQuantity,
                            propertyRedeem: r.propertyRedeem,
                            propertyInstructions: r.propertyInstructions,
                            propertyOwnerName: r.propertyOwnerName ? r.propertyOwnerName : '',
                            propertyOwnerGuid: r.propertyOwnerGuid,
                            propertyCompanyName: r.propertyCompanyName ? r.propertyCompanyName : '',
                            propertyCompanyGuid: r.propertyCompanyGuid,
                            propertyCompanyAddress1: r.propertyCompanyAddress1,
                            propertyCompanyAddress2: r.propertyCompanyAddress2,
                            propertyCompanyCity: r.propertyCompanyCity,
                            propertyCompanyCountry: r.propertyCompanyCountry,
                            propertyCompanyState: r.propertyCompanyState,
                            propertyCompanyZipCode: r.propertyCompanyZipCode,
                            images: [],
                            originalImages: [],
                            propertyStatusId: r.propertyStatusId,
                            revenueYTD: r.revenueYTD,
                            RevenueText: this.convertToDollar(r.revenueYTD),
                            propertyDistributionType: r.propertyDistributionType,
                            propertyDisbursementSettingGuid: r.propertyDisbursementSettingGuid,
                            propertyGrydPercentage: r.propertyGrydPercentage ? r.propertyGrydPercentage : 0,
                            propertyPropertyPercentage: r.propertyPropertyPercentage ? r.propertyPropertyPercentage : 0,
                            propertySpotOwnerPercentage: r.propertySpotOwnerPercentage ? r.propertySpotOwnerPercentage : 0,
                            rawData: r,
                            buildingContacts: r.buildingContacts,
                            buildingContactNames: r.buildingContactNames
                        };
                    });

                    _spotService.getFeatureList()
                        .then(r => r.json())
                        .then(r => {
                            ConfigService.getDefaultDisbursement()
                                .then(d => {
                                    this.setState({
                                        featureList: r,
                                        data: mappedItems,
                                        dataLoaded: true,
                                        defaultDisbursement: d
                                    }, () => {
                                        ConfigService.getGuestUrl()
                                            .then(url => {
                                                this.setState({
                                                    guestUrl: url
                                                });
                                            });
                                    });
                                })

                        });


                })
            }
        });*/


    /*fetchCompanyList().then(response => {
            if (response.ok) {
                response.text().then(resp => {
                    let companyItems: any[] = JSON.parse(resp);
                    // console.log(companyItems);
                    let mangerItems = companyItems.map(m => {
                        return {
                            companyGuid: m.companyGuid,
                            companyName: m.companyName
                        }
                    })

                    this.setState(
                        {
                            managerList: mangerItems
                        }
                    );
                })
            }
            else {
                if (response.status === 401) {
                    this.accessTokenExpired();
                } else {
                    response.text().then(resp => {
                        console.log(response);
                    })
                }
            }

        });

        _propertyService.getPropertyOwners().then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    let ownerList: any[] = resp;
                    console.log(ownerList);
                    let ownerItems = ownerList.map(m => {
                        return {
                            propertyOwnerGuid: m.propertyOwnerGuid,
                            propertyOwnerName: m.propertyOwnerName
                        }
                    });

                    this.setState(
                        {
                            ownerList: ownerItems
                        }
                    );
                })
            } else {
                if (response.status === 401) {
                    this.accessTokenExpired();
                } else {
                    response.text().then(resp => {
                        console.log(response);
                    })
                }
            }
        })

        _spotService.getFeatureList()
            .then(r => r.json())
            .then(r => {
                this.setState({
                    featureList: r
                });
            });*/
  }

  render() {
    if (this.state.dataLoaded) {
      return (
        <XEnhancedTable
          data={this.state.data}
          yardiConfigs={this.state.yardiConfigs}
          managerList={this.state.managerList}
          contactList={this.state.contactList}
          ownerList={this.state.ownerList}
          featureList={this.state.featureList}
          defaultDisbursement={this.state.defaultDisbursement}
          guestUrl={this.state.guestUrl}
          loaderPercentage={this.state.loaderPercentage}
        />
      );
    } else {
      return null;
    }
    // else {
    //     return (
    //         <LoaderComponent loaderVisible={true} />
    //     );
    // }
  }

  private getYardiConfigs() {
    _privateLotService.getYardiConfigs()
      .then((response) => {
        if (response.ok)
          response.json().then(json => {
            this.setState({
              yardiConfigs: json,
            });
          })
      });
  }

  private loadProperties() {
    let isBusy = false;

    _getAllProperties((rowItems: Data[]) => {
      return rowItems.map(r => {
        return {
          propertyGuid: r.propertyGuid,
          propertyName: r.propertyName ? r.propertyName : '',
          propertyAbout: r.propertyAbout,
          propertyAddress1: r.propertyAddress1 ? r.propertyAddress1 : '',
          propertyAddress2: r.propertyAddress2 ? r.propertyAddress2 : '',
          propertyCity: r.propertyCity ? r.propertyCity : '',
          propertyState: r.propertyState,
          propertyLatitude: r.propertyLatitude,
          propertyLongitude: r.propertyLongitude,
          propertyCountry: r.propertyCountry,
          propertyZipCode: r.propertyZipCode,
          propertyTimeZone: r.propertyTimeZone,
          propertyQuantity: r.propertyQuantity,
          propertySpotQuantity: r.propertySpotQuantity,
          propertyRedeem: r.propertyRedeem,
          propertyInstructions: r.propertyInstructions,
          propertyOwnerName: r.propertyOwnerName ? r.propertyOwnerName : '',
          propertyOwnerGuid: r.propertyOwnerGuid,
          propertyCompanyName: r.propertyCompanyName ? r.propertyCompanyName : '',
          propertyCompanyGuid: r.propertyCompanyGuid,
          propertyCompanyAddress1: r.propertyCompanyAddress1,
          propertyCompanyAddress2: r.propertyCompanyAddress2,
          propertyCompanyCity: r.propertyCompanyCity,
          propertyCompanyCountry: r.propertyCompanyCountry,
          propertyCompanyState: r.propertyCompanyState,
          propertyCompanyZipCode: r.propertyCompanyZipCode,
          propertyConfigGuid: r.propertyConfigGuid,
          images: [],
          originalImages: [],
          propertyStatusId: r.propertyStatusId,
          revenueYTD: r.revenueYTD,
          RevenueText: this.convertToDollar(r.revenueYTD),
          propertyDistributionType: r.propertyDistributionType,
          propertyDisbursementSettingGuid: r.propertyDisbursementSettingGuid,
          propertyGrydPercentage: r.propertyGrydPercentage ? r.propertyGrydPercentage : 0,
          propertyPropertyPercentage: r.propertyPropertyPercentage ? r.propertyPropertyPercentage : 0,
          propertySpotOwnerPercentage: r.propertySpotOwnerPercentage ? r.propertySpotOwnerPercentage : 0,
          rawData: r,
          buildingContacts: r.buildingContacts,
          buildingContactNames: r.buildingContactNames,
          isYardiConfigDeleted: r.isYardiConfigDeleted
        };
      });
    }, (rowItems: Data[], progress_: number) => {
      if (!isBusy) {
        isBusy = true;

        this.setState({
          data: rowItems,
          dataLoaded: true,
          loaderPercentage: progress_ * 100,
          isPropertyLoaded: true
        }, () => {
          isBusy = false;
        });

      }
    }).then(properties_ => {
      this.setState({
        data: properties_,
        dataLoaded: true,
        loaderPercentage: 100,
        isPropertyLoaded: true
      }, () => {
        isBusy = false;
        setTimeout(() => {
          this.setState({
            loaderPercentage: 0
          });
        }, 500);
      });
      this.getYardiConfigs();
    })
  }

  private accessTokenExpired() {
    localStorage.clear();
    window.location.href = '/';
  }

  private convertToDollar(amount: number) {
    return "$" + amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  private setRandomRevenue() {
    let revenue: number = Math.floor(Math.random() * 100000) + 5000;
    return revenue;
  }
}

function XEnhancedTable(props: {
  data: Data[],
  yardiConfigs: YardiConfigs[],
  managerList: ManagerList[],
  contactList: BulidingContactList[],
  ownerList: OwnerList[],
  featureList: ISpotFeature[],
  defaultDisbursement: IDefaultDisbursement,
  guestUrl: string,
  loaderPercentage: number
}) {
  let propsRows = props.data;
  let _txtMask: RegExp = /^[0-9]*([.]){0,1}[0-9]{0,2}$/; // 2 decimals by default
  // let company = props.managerList;
  // let propertyOwner = props.ownerList;

  let minTime = 360; //default min Time
  let maxTime = 1799; //default max Time
  let fullDay = 1440; //Full day 60*24

  let emptyData: Data = {
    propertyAddress1: '',
    propertyAddress2: '',
    propertyCity: '',
    propertyCompanyAddress1: '',
    propertyCompanyAddress2: '',
    propertyCompanyCity: '',
    propertyCompanyCountry: '',
    propertyCompanyGuid: '',
    propertyCompanyName: '',
    propertyCompanyState: '',
    propertyCompanyZipCode: '',
    propertyCountry: '',
    propertyLatitude: 0,
    propertyLongitude: 0,
    propertyGuid: '',
    propertyName: '',
    propertyOwnerGuid: '',
    propertyOwnerName: '',
    propertyState: '',
    propertyZipCode: '',
    propertyTimeZone: '',
    propertyQuantity: 0,
    propertySpotQuantity: 0,
    revenueYTD: 0,
    RevenueText: '',
    propertyDistributionType: 1,
    propertyDisbursementSettingGuid: '',
    propertyGrydPercentage: props.defaultDisbursement.propertyGrydPercentage,
    propertyPropertyPercentage: props.defaultDisbursement.propertyPropertyPercentage,
    propertySpotOwnerPercentage: props.defaultDisbursement.propertySpotOwnerPercentage,
    propertyStatusId: 2,
    propertyAbout: '',
    propertyRedeem: '',
    propertyInstructions: '',
    images: [],
    originalImages: [],
    buildingContacts: [],
    buildingContactNames: [],
    isYardiConfigDeleted: false,
  }

  let emptyCompanyList: ManagerList = {
    companyGuid: '',
    companyName: ''
  }

  let emptyContactList: BulidingContactList[] = [];

  let emptyOwnerList: OwnerList = {
    propertyOwnerGuid: '',
    propertyOwnerName: ''
  }

  let emptyRange: RangeModel = {
    start: 0,
    end: 0
  }

  let emptyFilterOption: FilterOptionsModel = {
    AvailableCities: [],
    AvailableCompany: [],
    AvailableStatus: [],
    City: [],
    Status: [],
    Company: [],
    DateRangeSelected: '',
    FilterApplied: false,
    Revenue: emptyRange,
    SlotsCorrelation: '',
    AvailableOwner: [],
    Owner: []
  }

  let defaultRevenueRange: RangeModel = {
    start: 65000,
    end: 125000
  }
  let searchTerm = window.localStorage.getItem('propSearch')
  // const classes = useStyles();
  const [searchedTextState, setSearchedTextState] = React.useState<string>(searchTerm ? searchTerm : '');
  const [showBlocker, setShowBlocker] = React.useState(false);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('propertyName');
  const [selected, setSelected] = React.useState<Data[]>([]);
  const [multiSelected, setMultiSelected] = React.useState<Data[]>([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [totalRows, setTotalRows] = React.useState(0);
  // eslint-disable-next-line
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [isAddPanelVisible, setIsAddPanelVisible] = React.useState(false);
  const [isFilterPanelVisible, setIsFilterPanelVisible] = React.useState(false);
  const [isEdited, setIsEdited] = React.useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);
  const [editedEnabled, setEditedEnabled] = React.useState(false);
  const [newRecord, setNewRecord] = React.useState<Data>(emptyData);
  const [lastSavedYardiConfig, setLastSavedYardiConfig] = React.useState<string>('');
  const [selectedOwner, setSelectedOwner] = React.useState<OwnerList>(emptyOwnerList);
  const [selectedCompany, setSelectedCompany] = React.useState<ManagerList>(emptyCompanyList);
  const [selectedContacts, setSelectedContacts] = React.useState<BulidingContactList[]>(emptyContactList.slice(0));
  const [isMenuOpened, setIsMenuOpened] = React.useState(false);
  const [revenueRangeValue, setRevenueRangeValue] = React.useState<number[]>([10, 25]);
  const [rangeDateState, setRangeDateState] = React.useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);

  /*React.useEffect(() => {
        return () => {
            window.localStorage.setItem('propSearch', searchedTextState)
        }
    })*/
  const [filterOptions, setFilterOptions] = React.useState<FilterOptionsModel>(emptyFilterOption);
  const [filteredByStatus, setFilteredByStatus] = React.useState<string[]>(PreserveFilters.readPreservedFilter('properties') ? PreserveFilters.readPreservedFilter('properties').Status : []);
  const [filteredCities, setFilteredCities] = React.useState<string[]>(PreserveFilters.readPreservedFilter('properties') ? PreserveFilters.readPreservedFilter('properties').City : []);
  const [filteredCompanies, setFilteredCompanies] = React.useState<string[]>(PreserveFilters.readPreservedFilter('properties') ? PreserveFilters.readPreservedFilter('properties').Company : []);
  const [filteredOwners, setFilteredOwners] = React.useState<string[]>(PreserveFilters.readPreservedFilter('properties') ? PreserveFilters.readPreservedFilter('properties').Owner : []);
  const [filterAvailableCities, setFilterAvailableCities] = React.useState<string[]>([]);
  const [filterAvailableStatus, setFilterAvailableStatus] = React.useState<string[]>([]);
  const [filterAvailableCompanies, setFilterAvailableCompanies] = React.useState<string[]>([]);
  const [filterAvailableOwners, setFilterAvailableOwners] = React.useState<string[]>([]);
  const [filterRevenueRangeValue, setFilterRevenueRangeValue] = React.useState<RangeModel>(emptyRange);
  const [customRevenueRange, setCustomRevenueRange] = React.useState<RangeModel>(defaultRevenueRange);
  const [selectedRevenueRange, setSelectedRevenueRange] = React.useState<string>('');

  const [isFilterApplied, setIsFilterApplied] = React.useState(false);

  const [isAddedOpened, setIsAddedOpened] = React.useState(false);
  const [isAddContactOpened, setIsAddContactOpened] = React.useState(false);
  const [isAddCompanyOpened, setIsAddCompanyOpened] = React.useState(false);
  const [isAssignSpotsOpened, setIsAssignSpotsOpened] = React.useState(false);
  const [hasSpotAssignmentChanged, setHasSpotAssignmentChanged] = React.useState(false);
  const [assignedSpots, setAssignedSpots] = React.useState([] as ISpot[]);

  const [rows, setRows] = React.useState<Data[]>(props.data);
  const [oldRows, setOldRows] = React.useState<Data[]>(props.data);

  const [hoursPricePermission, setHoursPricePermission] = React.useState<boolean[]>([true, true]);
  const [propertyOwner, setPropertyOwner] = React.useState<OwnerList[]>(props.ownerList);
  const [propertyCompany, setPropertyCompany] = React.useState<ManagerList[]>(props.managerList);
  const [propertyCompanyContact, setPropertyCompanyContact] = React.useState<BulidingContactList[]>(props.contactList);
  const [featureList, setFeatureList] = React.useState<ISpotFeature[]>(props.featureList);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [isAdmin, setIsAdmin] = React.useState<boolean>((GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) != -1) || (GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) != -1));
  const [isRightSideServiceLoaded, setIsRightSideServiceLoaded] = React.useState(false);
  const [propertyLink, setPropertyLink] = React.useState("");
  const [propertyLinkIcon, setPropertyLinkIcon] = React.useState<HTMLElement | null>(null);
  const [popOverItem, setPopOverItem] = React.useState<Data | null>(null);
  const [popOverTargetElement, setPopOverTargetElement] = React.useState<HTMLElement | null>(null);

  // Daily Booking
  const [dailyBooking, setDailyBooking] = React.useState(false);
  const [dayBooking, setDayBooking] = React.useState(false);
  const [eveningBooking, setEveningBooking] = React.useState(false);
  const [dayBookingValue, setDayBookingValue] = React.useState<string>('0.00');
  const [eveningBookingValue, setEveningBookingValue] = React.useState<string>('0.00');

  selectedRows = selected;
  setSelectedCallback = setSelected;
  setMultiSelectedCallback = setMultiSelected;

  if (props.data.length != oldRows.length) {
    setRows(props.data);
    setOldRows(props.data);
  }

  const setSelectedBuildingContacts = (contacts_: BulidingContactList[]) => {
    if (newRecord) {
      const buildingContactGuids = contacts_.map(user_ => user_.applicationUserGuid);
      newRecord.buildingContacts = buildingContactGuids;
    }
    setSelectedContacts(contacts_);
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows;
      setMultiSelected(newSelecteds);
      return;
    }
    setMultiSelected([]);
  };

  const handleToggle = () => {
    setIsMenuOpened((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setIsMenuOpened(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setIsMenuOpened(false);
    }
  }

  const handleEdit = (event: any, name: string, row: any) => {
    if (isEdited) {
      // SaveRecord('update');
      setIsEdited(false);
    }
    const selectedIndex = rows.indexOf(row);
    let newSelected: Data[] = [];
    newSelected = newSelected.concat(row);
    setSelected(newSelected);
    setIsAddPanelVisible(false);

    let _newRecord: Data = ObjectHelper.deepClone(row);
    // localStorage.setItem('selectedRow', JSON.stringify(row));

    let _selectedDays: number[] = [];

    let selectedPriceRange: RangeModel = {
      start: 0,
      end: 0,
    }

    let selectedTimeRange: RangeModel = {
      start: 0,
      end: 0
    }

    let _selectedCompany: ManagerList = {
      companyGuid: row.propertyCompanyGuid,
      companyName: row.propertyCompanyName
    }

    let _selectedPropertyOwner: OwnerList = {
      propertyOwnerGuid: row.propertyOwnerGuid,
      propertyOwnerName: row.propertyOwnerName
    }

    _newRecord.images = _newRecord.originalImages.slice(0);
    setNewRecord(_newRecord);
    setLastSavedYardiConfig(_newRecord?.propertyConfigGuid || '');
    setSelectedIndex(selectedIndex);
    setSelectedOwner(_selectedPropertyOwner);
    setSelectedCompany(_selectedCompany);
    setHasSpotAssignmentChanged(false);
    setAssignedSpots([]);
    // setNewRecord(row);
  }

  // const tConvert = (oldTime: string) => {
  //     let splitValue = oldTime.split(":");
  //     let minutes = parseInt(splitValue[1]);
  //     let totalMinutes = parseInt(splitValue[0]) * 60 + minutes;

  //     return totalMinutes;
  // }


  // eslint-disable-next-line
  const handleClick = (event: React.MouseEvent<unknown>, name: string, row: any) => {
    const selectedIndex = multiSelected.indexOf(row);
    let newSelected: Data[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(multiSelected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(multiSelected.slice(1));
    } else if (selectedIndex === multiSelected.length - 1) {
      newSelected = newSelected.concat(multiSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        multiSelected.slice(0, selectedIndex),
        multiSelected.slice(selectedIndex + 1),
      );
    }

    setMultiSelected(newSelected);
    handleChangeRightMenu(false)
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeRightMenu = (visibleState: boolean, viewType?: string, skipUnSaveCheck?: boolean) => {
    // isAddPanelVisible = visibleState;
    if (hasUnsavedChanges && !skipUnSaveCheck) {
      ShowAlertwithConfirm("You have unsaved changes!", "Are you sure you want to close this window?", "warning")
        .then(r_ => {
          if (r_) {
            setEditedEnabled(false);
            setIsFilterPanelVisible(visibleState);
            setIsAddPanelVisible(visibleState);

            if (viewType != 'viewmode') {
              clearFields();
              setSelectedCallback([]);
              setSelectedOwner(emptyOwnerList);
              setSelectedCompany(emptyCompanyList);
            }
            setHasUnsavedChanges(false);
            setIsRightSideServiceLoaded(false);
          }
        })
    } else {
      setEditedEnabled(false);
      setIsFilterPanelVisible(visibleState);
      setIsAddPanelVisible(visibleState);

      if (viewType != 'viewmode') {
        clearFields();
        setSelectedCallback([]);
        setSelectedOwner(emptyOwnerList);
        setSelectedCompany(emptyCompanyList);
      }
      setHasUnsavedChanges(false);
      setIsRightSideServiceLoaded(false);
    }

    // setRightMenu(visibleState);
  }

  const clearFields = () => {
    let _newRecord: Data = emptyData;

    setNewRecord(_newRecord);
    setHasSpotAssignmentChanged(false);
    setAssignedSpots([]);
  }

  const handleChangeRecord = (event: any) => {
    var _targetValue = event.target ? event.target.value : "";
    var _FeildName = event.target ? event.target.name : "";

    var _elmName = '';
    setIsEdited(true);
    if (editedEnabled) setHasUnsavedChanges(true);

    let _newRecord: Data = emptyData;

    _newRecord.propertyName = (_FeildName === _elmName + "name") ? _targetValue : (newRecord?.propertyName) ? newRecord?.propertyName : '';
    _newRecord.propertyAbout = (_FeildName === _elmName + "aboutProperty") ? _targetValue : (newRecord?.propertyAbout) ? newRecord?.propertyAbout : '';
    _newRecord.propertyAddress1 = (_FeildName === _elmName + "address") ? _targetValue : (newRecord?.propertyAddress1) ? newRecord?.propertyAddress1 : '';
    _newRecord.propertyCity = (_FeildName === _elmName + "city") ? _targetValue : (newRecord?.propertyCity) ? newRecord?.propertyCity : '';
    _newRecord.propertyZipCode = (_FeildName === _elmName + "propertyZipCode") ? _targetValue : (newRecord?.propertyZipCode) ? newRecord?.propertyZipCode : '';
    _newRecord.propertyCompanyName = (_FeildName === _elmName + "manager") ? _targetValue : (newRecord?.propertyCompanyName) ? newRecord?.propertyCompanyName : '';
    _newRecord.propertyOwnerName = (_FeildName === _elmName + "owner") ? _targetValue : (newRecord?.propertyOwnerName) ? newRecord?.propertyOwnerName : '';
    _newRecord.propertyQuantity = (_FeildName === _elmName + "spot") ? Number(_targetValue) : (newRecord?.propertyQuantity) ? newRecord?.propertyQuantity : 0;
    _newRecord.propertyRedeem = (_FeildName === _elmName + "redeem") ? _targetValue : (newRecord?.propertyRedeem) ? newRecord?.propertyRedeem : '';
    _newRecord.propertyInstructions = (_FeildName === _elmName + "propertyInstructions") ? _targetValue : (newRecord?.propertyInstructions) ? newRecord?.propertyInstructions : '';
    _newRecord.propertyDistributionType = (_FeildName == "distributionType") ? Number(_targetValue) : newRecord?.propertyDistributionType;

    _newRecord.propertyGrydPercentage = (_FeildName == "propertyGrydPercentage") ? _targetValue : newRecord?.propertyGrydPercentage;
    _newRecord.propertyPropertyPercentage = (_FeildName == "propertyPropertyPercentage") ? _targetValue : newRecord?.propertyPropertyPercentage;
    _newRecord.propertySpotOwnerPercentage = (_FeildName == "propertySpotOwnerPercentage") ? _targetValue : newRecord?.propertySpotOwnerPercentage;

    _newRecord.propertyTimeZone = (newRecord?.propertyTimeZone) ? newRecord?.propertyTimeZone : '';
    _newRecord.revenueYTD = (newRecord?.revenueYTD) ? newRecord?.revenueYTD : 0;
    _newRecord.RevenueText = (newRecord?.RevenueText) ? newRecord?.RevenueText : '';
    _newRecord.propertyAddress2 = (newRecord?.propertyAddress2) ? newRecord?.propertyAddress2 : '';
    _newRecord.propertyCompanyGuid = (newRecord?.propertyCompanyGuid) ? newRecord?.propertyCompanyGuid : '';
    _newRecord.propertyCountry = (newRecord?.propertyCountry) ? newRecord?.propertyCountry : '';
    _newRecord.propertyGuid = (newRecord?.propertyGuid) ? newRecord?.propertyGuid : '';
    _newRecord.propertyConfigGuid = (newRecord?.propertyConfigGuid) ? newRecord?.propertyConfigGuid : undefined;
    _newRecord.propertyOwnerGuid = (newRecord?.propertyOwnerGuid) ? newRecord?.propertyOwnerGuid : '';
    _newRecord.propertyStatusId = (newRecord?.propertyStatusId) ? newRecord?.propertyStatusId : 2;
    _newRecord.images = (newRecord?.images) ? newRecord?.images : [];
    _newRecord.propertyLatitude = (newRecord?.propertyLatitude != 0) ? newRecord?.propertyLatitude : 0;
    _newRecord.propertyLongitude = (newRecord?.propertyLongitude != 0) ? newRecord?.propertyLongitude : 0;

    _newRecord.propertySpotQuantity = (newRecord?.propertySpotQuantity) ? newRecord?.propertySpotQuantity : 0;
    _newRecord.buildingContacts = (newRecord?.buildingContacts) ? newRecord?.buildingContacts : [];

    setNewRecord(_newRecord);
    setHasSpotAssignmentChanged(false);
    setAssignedSpots([]);
  }

  const SaveRecord = (actionType: string) => {
    let _newRecord: Data = ObjectHelper.deepClone(newRecord);

    _newRecord.propertyCompanyGuid = selectedCompany.companyGuid;
    _newRecord.propertyCompanyName = selectedCompany.companyName;

    _newRecord.propertyOwnerGuid = selectedOwner.propertyOwnerGuid;
    _newRecord.propertyOwnerName = selectedOwner.propertyOwnerName;

    if (_newRecord.propertyName === '' || _newRecord.propertyName === null) {
      ShowAlert('Error!', 'Please enter the <b>Property name<b/>.', 'error');
      return false;
    }

    // if (_newRecord.propertyOwnerGuid === '' || _newRecord.propertyOwnerGuid === null) {
    //     ShowAlert('Error!', 'Please select an <b>Owner<b/>.', 'error');
    //     return false;
    // }

    if (_newRecord.propertyCompanyGuid === '' || _newRecord.propertyCompanyGuid === null) {
      ShowAlert('Error!', 'Please select a <b>Property Management Company<b/>.', 'error');
      return false;
    }

    if (_newRecord.propertyAddress1 === '' || _newRecord.propertyAddress1 === null) {
      ShowAlert('Error!', 'Please enter the <b>Address<b/>.', 'error');
      return false;
    }

    if (_newRecord.propertyCity === '' || _newRecord.propertyCity === null) {
      ShowAlert('Error!', 'Please enter the <b>city<b/>.', 'error');
      return false;
    }

    let _percent = Number(_newRecord.propertyGrydPercentage) + Number(_newRecord.propertyPropertyPercentage) + Number(_newRecord.propertySpotOwnerPercentage);

    if (_percent > 100 || _percent < 100) {
      ShowAlert('Error!', 'Property disbursement setting percentage total is not 100%', 'error');
      return false;
    }

    // if (_newRecord.propertyOwnerGuid === '') {
    //     ShowAlert('Error!', 'Please select the <b>Owner</b>', 'error');
    //     return false;
    // }

    // if (_newRecord.propertyCompanyGuid === '') {
    //     ShowAlert('Error!', 'Please select the <b>Company</b>', 'error');
    //     return false;
    // }

    if (_newRecord.propertyTimeZone == null || _newRecord.propertyTimeZone == '') {
      let _rowRecord: Data = newRecord;
      _rowRecord.propertyAddress1 = '';
      _rowRecord.propertyCity = '';
      _rowRecord.propertyLatitude = 0;
      _rowRecord.propertyLongitude = 0;
      _rowRecord.propertyZipCode = '';
      _rowRecord.propertyTimeZone = '';
      ShowAlert('Error!', 'We could not find Geo co-ordinates for your address. Please re-fill your address.', 'error');
      handleChangeRecord(new Event('address'));
      return false;
    }

    if (isLoaderVisible) {
      return false;
    }

    isLoaderVisible = true;
    setShowBlocker(true);
    if (actionType === 'Add') {
      addNewProperty(_newRecord).then(response => {
        if (response.ok) {
          response.text().then(resp => {

            _newRecord.propertyGuid = JSON.parse(resp);

            let isNewImg = getNonUploadedImageData(_newRecord);
            let subProcessCount = 2;
            let onSubProcessComplete = () => {
              if (--subProcessCount == 0) {
                ShowAlert('Success!', 'The <b>' + _newRecord.propertyName + '</b> was added to the property list', 'success');
                rows.push(_newRecord);
                handleEdit(new Event('edit'), _newRecord.propertyName.toString(), _newRecord);
                setRows(rows);
                setHasUnsavedChanges(false);
                handleChangeRightMenu(false, 'viewmode', true);
                setShowBlocker(false);
                isLoaderVisible = false;
              }
            }


            if (hasSpotAssignmentChanged) {
              _spotService.saveSpots(_newRecord.propertyGuid, assignedSpots)
                .then(r => {
                  if (r.ok) {
                    onSubProcessComplete();
                  }
                });
            } else {
              onSubProcessComplete();
            }

            if (isNewImg.length > 0) {
              uploadImage(_newRecord).then(imgResponse => {
                if (imgResponse.ok) {
                  imgResponse.json().then(_imgs => {
                    for (let i = 0; i < _newRecord.images.length; i++) {
                      let image = _newRecord.images[i];
                      let updatedGuid = _imgs[image.internalUid];
                      if (updatedGuid) {
                        image.storedFileGuid = updatedGuid;
                        image.internalUid = updatedGuid;
                        image.fileContent = undefined;
                      }
                    }
                    _newRecord.originalImages = _newRecord.images.slice(0);
                    ShowAlert('Success!', 'The <b>' + _newRecord.propertyName + '</b> was added to the property list', 'success');
                    rows.push(_newRecord);
                    setRows(rows);
                    handleEdit(new Event('edit'), _newRecord.propertyName.toString(), _newRecord);
                    setHasUnsavedChanges(false);
                    handleChangeRightMenu(false, 'viewmode', true);
                    setShowBlocker(false);
                    isLoaderVisible = false;
                  });
                }
              })
            } else {
              onSubProcessComplete();
            }
          });
        } else {
          response.text().then(resp => {
            setShowBlocker(false);
            isLoaderVisible = false;
            if (resp.indexOf("Invalid timezone") != -1) {
              ShowAlert('Error!', 'We could not find Geo co-ordinates for your address. Please re-fill your address.', 'error');
            } else {
              ShowAlert("", 'There was an error processing your request.', "error");
            }
          });
        }
      })
    } else {
      if (_newRecord.isYardiConfigDeleted) {
        ShowAlertwithConfirm("You're about to migrate tenants off Yardi!", `You removed Yardi configuration from this property. Are you sure you want to proceed with this operation? This will move tenants off Yardi. The changes can take a few minutes to complete. This is cannot be undone!`, 'warning')
          .then(resp => {
            if (resp) {
              _privateLotService.movePropertyOffYardi(_newRecord.propertyGuid)
                .then(resp => {
                  if (resp.ok)
                    updatePropertyDetails(_newRecord);
                })
                .catch(reason =>
                  //This is most likely from a connection error
                  ShowAlert("There was an error", "There was an error moving tenants from Yardi. Please try again", "error")
                )
            } else {
              setNewRecord((prevState) => ({
                ...prevState,
                propertyConfigGuid: lastSavedYardiConfig,
                isYardiConfigDeleted: false,
              }));
              isLoaderVisible = false;
            }
          });
      } else {
        updatePropertyDetails(_newRecord);
      }
    }
  }

  const updatePropertyDetails = (_newRecord: Data) => {
    updateProperty(_newRecord).then(response => {
      if (response.ok) {
        let saveImages = () => {
          response.text().then(resp => {
            let isNewImg = getNonUploadedImageData(_newRecord);

            if (isNewImg.length > 0) {
              uploadImage(_newRecord).then(imgResponse => {
                if (imgResponse.ok) {
                  imgResponse.json().then(_imgs => {
                    associateImage(_newRecord, _imgs);
                  })
                }
              })
            } else {
              associateImage(_newRecord);
            }

            _newRecord.originalImages = _newRecord.images.slice(0);
          });
        }

        if (hasSpotAssignmentChanged) {
          _spotService.saveSpots(_newRecord.propertyGuid, assignedSpots)
            .then(r => {
              if (r.ok) {
                saveImages();
              }
            });
        } else {
          saveImages();
        }
      } else {
        response.text().then(resp => {
          if (resp.indexOf("Invalid timezone") != -1) {
            ShowAlert('Error!', 'We could not find Geo co-ordinates for your address. Please re-fill your address.', 'error');
          } else {
            ShowAlert("", 'There was an error processing your request.', "error");
          }
        });
      }
    })
  }

  const convertIntoUtc = (date: Date) => {
    let x: Date = new Date(date);
    let hoursDiff = x.getHours() - x.getTimezoneOffset() / 60;
    let minutesDiff = (x.getHours() - x.getTimezoneOffset()) % 60;
    x.setHours(hoursDiff);
    x.setMinutes(minutesDiff);
    return new Date(x);
  }

  const associateImage = (rowData: Data, updatedGuids_?: any) => {
    let imageGuids: string[] = [];
    for (let i = 0; i < rowData.images.length; i++) {
      let image = rowData.images[i];
      if (!image.storedFileGuid && updatedGuids_) {
        let updatedGuid = updatedGuids_[image.internalUid];
        if (updatedGuid) {
          image.storedFileGuid = updatedGuid;
          image.internalUid = updatedGuid;
          image.fileContent = undefined;
          imageGuids.push(updatedGuid);
        }
      } else if (image.storedFileGuid) {
        imageGuids.push(image.storedFileGuid);
      }
    }

    UpdateAssociateImage(imageGuids, rowData.propertyGuid).then(imgIdRes => {
      if (imgIdRes.ok) {
        /*imgIdRes.text().then(associateResp => {
                    console.log(associateResp);
                    console.log(imageGuids);
                });*/
        ShowAlert('Success!', 'The <b>' + rowData.propertyName + '</b> was updated to the property list', 'success');
        rows[selectedIndex] = rowData;
        setRows(rows);

        let newSelected: Data[] = [];
        newSelected = newSelected.concat(rows[selectedIndex]);
        setSelected(newSelected);
        setHasUnsavedChanges(false);
        handleChangeRightMenu(false, 'viewmode', true);
        setShowBlocker(false);
        isLoaderVisible = false;
      }
    })
  }

  const getNonUploadedImageData = (rowData: Data) => {
    let newFiles_: IFileInfo[] = [];

    for (let i = 0; i < rowData.images.length; i++) {
      let image = rowData.images[i];
      if (!image.storedFileGuid) {
        newFiles_.push(image);
      }
    }

    return newFiles_;
  }

  const updateSelectedAmenities = (amenities: number[], value: number, isChecked: boolean) => {
    const currentIndex = amenities.indexOf(value);
    const newChecked = [...amenities];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    return newChecked;
  }

  const _padLeft = (text_: string, length_: number, padChar_: string): string => {
    if (padChar_) {
      while (text_.length < length_) {
        text_ = padChar_ + text_;
      }
    }

    return text_;
  }

  const _makeTimeText = (minutes_: number): string => {
    let fullHours = Math.floor(minutes_ / 60);
    let remainderMinutes = minutes_ % 60;
    let ampm = fullHours < 12 ? "AM" : "PM";
    let hours = fullHours % 12;

    if (hours === 0) {
      hours = 12;
    }

    return `${_padLeft(hours.toString(), 2, '0')}:${_padLeft(remainderMinutes.toString(), 2, '0')} ${ampm}`;
  }

  const checkPasteValue = (event: any) => {
    var str = event.clipboardData.getData('Text');

    if (isNaN(str)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  const checkValue = (event: any) => {
    var charCode = (event.which) ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57) || charCode === 101 || charCode === 69) {
      event.preventDefault();
      return false;
    }

    return true;
  }

  const checkDisbursementValue = (event: any) => {
    var charCode = (event.which) ? event.which : event.keyCode;
    var number = event.target.value.split('.');

    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    //just one dot (thanks ddlab)
    if (number.length > 1 && charCode == 46) {
      event.preventDefault();
      return false;
    }
    //get the carat position
    // var caratPos = getSelectionStart(el);
    var dotPos = event.target.value.indexOf(".");
    if (dotPos > -1 && (number[1].length > 1)) {
      event.preventDefault();
      return false;
    }

    return true;
  }


  const onChangePropertyStatus = (propertyStatus_: number, row: Data) => {
    let _newRecord: Data = row;

    // rows[selectedIndex] = _newRecord;
    // setRows(rows);

    if (propertyStatus_ == 1) {
      updateStatus(propertyStatus_, _newRecord);
    } else {
      _propertyService.checkPropertyReservation(_newRecord.propertyGuid).then(response => {
        if (response.ok) {
          response.json().then(resp => {
            let isActiveBookings = false;
            resp.forEach((spotItem: any) => {
              if (spotItem.isDailyBooked || spotItem.isMonthlyBooked) {
                isActiveBookings = true;
              }
            });

            if (isActiveBookings) {
              ShowAlert('Error!', `${_newRecord.propertyName} have active booking(s). <br/> Please cancel the booking(s) and try again.`, "warning");
            } else {
              updateStatus(propertyStatus_, _newRecord);
            }
          });
        } else {
          response.text().then(resp => {
            console.log(resp);
          });
        }
      });
    }
  }

  const updateStatus = (propertyStatus_: number, row: Data) => {
    let _newRecord: Data = row;

    // rows[selectedIndex] = _newRecord;
    // setRows(rows);

    let _listProperty: PropertyStatus[] = [{
      propertyGuid: _newRecord.propertyGuid,
      propertyStatusId: propertyStatus_
    }];

    udatePropertyStatus(_listProperty).then(response => {
      if (response.ok) {
        response.text().then(resp => {
          if (propertyStatus_ != 3) {
            _newRecord.propertyStatusId = propertyStatus_;
            rows[selectedIndex] = _newRecord;
            setRows(rows);

            let newSelected: Data[] = [];
            newSelected = newSelected.concat(rows[selectedIndex]);
            setSelected(newSelected);
            setNewRecord(_newRecord);
          } else {
            rows.filter(x => x.propertyGuid == _newRecord.propertyGuid).forEach(x => rows.splice(rows.indexOf(x), 1));
            setRows(rows);
            setNewRecord(emptyData);
            ShowAlert('', 'Property deleted successfully', 'success');
          }
        });
      } else {
        response.text().then(resp => {
          console.log(resp);
        });
      }
    });
  }

  const EnableEdit = () => {
    setEditedEnabled(true);
    setIsRightSideServiceLoaded(false);
  }

  const ViewMode = () => {
    if (!isRightSideServiceLoaded) loadPropertyImages();
    return (
      <div className="side-popup-container">
        <Grid container className="edit-button-container">
          <Grid item xs>
            {/* {
                            (GetUserDetailsService.getUserDetails().userRoleID[0] != 6) &&
                            <Grid container>
                                <Grid item xs>
                                    <span className="link-container">
                                        <Link to={'Spots/property/' + newRecord?.propertyGuid + '/add'} className="view-spots">
                                            Add spot(s)
                                        </Link>
                                    </span>
                                </Grid>
                                <Grid item xs style={{
                                    textAlign: 'right'
                                }}>
                                    {
                                        newRecord?.propertySpotQuantity != 0 ? <span className="link-container">
                                            <Badge color="secondary" badgeContent={newRecord?.propertySpotQuantity} showZero max={99}>
                                                <Link to={'Spots/property/' + newRecord?.propertyGuid + '/view'} className="view-spots">
                                                    View spot(s)
                                                </Link>
                                            </Badge>
                                        </span> : <span className="link-container">No spot(s)</span>
                                    }

                                </Grid>
                            </Grid>
                        } */}
          </Grid>
          <Grid item>
            <div className="close-menu-icon">
              <Tooltip title="Close">
                <IconButton aria-label="Close" onClick={() => {
                  handleChangeRightMenu(false);
                  setIsRightSideServiceLoaded(false);
                }}>
                  <CloseIcon/>
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        <Grid container className="list-header-container">
          <Grid item xs>
            <h1 className="list-header">
              {newRecord?.propertyName} <span className="expand-details">
                                {/* <Link to={'Properties/' + newRecord?.propertyGuid + '/overview'}>
                                    <Tooltip title='View More'>
                                        <IconButton aria-label="View More">
                                            <LaunchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Link> */}
              {
                // (GetUserDetailsService.getUserDetails().userRoleID[0] != 6)
                CheckFeaturePermission('"edit-property"') ?
                  <React.Fragment>
                    {
                      GetUserDetailsService.getUserDetails().userRoleID[0] != 8 &&
                        <Tooltip title='Edit' onClick={() => EnableEdit()}>
                            <IconButton aria-label="Edit">
                                <EditIcon/>
                            </IconButton>
                        </Tooltip>
                    }
                    <Tooltip title='Tenant Invitation Link' onClick={(event_) => {
                      setPropertyLinkIcon(event_.target as HTMLElement);
                      _propertyService.getInvitationLink(newRecord.propertyGuid)
                        .then(r => r.json())
                        .then(r => {
                          ShowToastr('Copied to clipboard!');
                          navigator.clipboard.writeText(r);
                          setPropertyLink(r);

                          // let mockTextBox = document.createElement("input");
                          // mockTextBox.style.position = "absolute";
                          // mockTextBox.style.top = "0";
                          // mockTextBox.style.left = "0";
                          // mockTextBox.style.opacity = "0";
                          // document.body.appendChild(mockTextBox);

                          // try {
                          //     mockTextBox.select();
                          //     mockTextBox.setSelectionRange(0, 99999);
                          //     document.execCommand("copy");
                          //     ShowToastr(`Tenant invitation link (${r}) is copied to clipboard...`);
                          // }
                          // catch {
                          //     let txtTempId = `tb_${new Date().getTime()}`;
                          // ShowAlert("Tenant Invitation Link",
                          //     `Copy the link below:<br />
                          //     <input id="${txtTempId}" type="text" value="${r}" style="width: 100%;"
                          //         onClick="event.stopPropagation();"
                          //     />
                          //     `,
                          // "info");
                          //// document.getElementById('${txtTempId}').select(); document.getElementById('${txtTempId}').setSelectionRange(0,9999); document.execCommand('copy');
                          // }

                          // document.body.removeChild(mockTextBox);
                        });
                    }}>
                      <IconButton aria-label="Invitation Link">
                        <LinkIcon/>
                      </IconButton>
                    </Tooltip>
                    <Popover
                      open={propertyLink != "" && propertyLinkIcon != null}
                      anchorEl={propertyLinkIcon}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      onClose={() => {
                        setPropertyLink("");
                        setPropertyLinkIcon(null);
                      }}
                    >
                      <div className="p-2" id="property-link-txt">
                        {propertyLink} <span onClick={() => {
                        ShowToastr('Copied to clipboard!');
                        navigator.clipboard.writeText(propertyLink);
                      }}><FileCopyIcon fontSize="small"/></span>
                      </div>
                    </Popover>
                  </React.Fragment>
                  : null
              }


                            </span>
            </h1>
          </Grid>
          <Grid item>
            <Select
              value={newRecord.propertyStatusId}
              inputProps={{'aria-label': 'Without label'}}
              className="property-state"
              onChange={(event) => onChangePropertyStatus(Number(event.target.value), newRecord)}
              disabled={(GetUserDetailsService.getUserDetails().userRoleID[0] == 6 || GetUserDetailsService.getUserDetails().userRoleID[0] == 8)}
            >
              <MenuItem value={1}>
                <div className={'list-status dropdown-status active'}></div>
                Active</MenuItem>
              <MenuItem value={2}>
                <div className={'list-status dropdown-status inactive'}></div>
                Inactive</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <div className="property-location"><LocationOnIcon/> {newRecord?.propertyAddress1}, {newRecord?.propertyCity}
        </div>
        <hr className="property-line"/>
        <div className="right-menu-body">
          <div className="map-container">
            <CustomMaps address={newRecord?.propertyAddress1 + ', ' + newRecord?.propertyCity}/>
          </div>
          <Autocomplete
            id="propertyOwner"
            className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
            options={propertyOwner}
            value={selectedOwner}
            onChange={(event: any, values: any) => {
              let _values: OwnerList = {
                propertyOwnerGuid: '',
                propertyOwnerName: ''
              }

              if (values) {
                _values = values;
              }

              setSelectedOwner(_values);
              setHasUnsavedChanges(true);
            }}
            getOptionLabel={(option) => option.propertyOwnerName}
            disabled={true}
            renderInput={(params) => <TextField {...params} label="Property Owner" variant="outlined"
              // error={selectedOwner == null || (selectedOwner as any).propertyOwnerGuid == ""}
            />}
          />

          <Autocomplete
            id="CompnayName"
            className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
            options={propertyCompany}
            value={selectedCompany}
            onChange={(event: any, values: any) => {
              let _values: ManagerList = {
                companyGuid: '',
                companyName: ''
              }

              if (values) {
                _values = values;
              }

              setSelectedCompany(_values);
              setHasUnsavedChanges(true);
            }}
            getOptionLabel={(option) => option.companyName}
            disabled={true}
            renderInput={(params) => <TextField {...params} label="Property Management Company" variant="outlined"/>}
          />
          {
            newRecord?.images.length > 0 ? <h4 className="list-sub-header">Gallery</h4> : null
          }
          <ImageGrid
            imageData={newRecord?.images}
            CarouselTitle={newRecord?.propertyName}
          />
          <div className='mt-4 property-qr-con'>
            <QRCode value={`${props.guestUrl}?p=${newRecord?.propertyGuid}`} id="propertyQrCode"/>
            <Button
              type="button"
              variant="contained"
              color="primary"
              className='list-button mt-4'
              onClick={() => {
                var svg_data = document.getElementById("propertyQrCode")?.innerHTML;
                var head = '<svg title="graph" version="1.1" xmlns="http://www.w3.org/2000/svg">'
                var full_svg = head + svg_data + "</svg>"
                var svgBlob = new Blob([full_svg], {type: "image/svg+xml"});
                var svgUrl = URL.createObjectURL(svgBlob);
                var downloadLink = document.createElement("a");
                downloadLink.href = svgUrl;
                downloadLink.download = `${newRecord?.propertyName.replace(/\s/g, '-')}.svg`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
              }}
            >
              Download as SVG
            </Button>
          </div>
          {/* <img src={map} width="100%" className="property-location-img" /> */}
          {/* <Grid container>
                        <Grid item xs>
                            <span>
                                <IconButton aria-label="Earned Revenue" className="icon-selected">
                                    <TrendingUpIcon />
                                </IconButton>
                            </span> <span className="bold-caps">Earned Revenue</span>
                        </Grid>
                        <Grid item>
                            <span className="table-label revenue-lable">last month</span><br /><span className="bold-caps revenue-amount">{newRecord?.RevenueText}</span>
                        </Grid>
                    </Grid> */}
          {/* <hr />
                    <img src={activity} width="100%" className="property-location-img" /> */}
        </div>
      </div>
    )
  }

  const revenueValueText = (value: number) => {
    return `${value}°C`;
  }

  const handleChangeSlider = (event: any, newValue: number | number[]) => {
    setRevenueRangeValue(newValue as number[]);
  };

  const getRangeValue = (actionName: string) => {
    var index = (actionName === 'from') ? 0 : 1;
    return (revenueRangeValue[index] * 5000).toFixed(2)
  }

  const handleDateChange = (range: Range) => {
    console.log(range);
  }

  const handleSelectCity = (value: string) => {
    const currentIndex = filteredCities.indexOf(value);
    const newChecked = [...filteredCities];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setFilteredCities(newChecked);
  }

  const handleSelectStatus = (value: string) => {
    const currentIndex = filteredByStatus.indexOf(value);
    const newChecked = [...filteredByStatus];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setFilteredByStatus(newChecked);
  }

  const handleSelectAllCities = (items: string[]) => {
    if (filteredCities.length === items.length) {
      setFilteredCities([]);
    } else {
      setFilteredCities(items);
    }
  }

  const handleSelectAllStatus = (items: string[]) => {
    if (filteredByStatus.length === items.length) {
      setFilteredByStatus([]);
    } else {
      setFilteredByStatus(items);
    }
  }

  const handleSetRevenuRange = (rangeName: string, range: RangeModel) => {
    let newRevenueRange: RangeModel = range;
    if (rangeName === 'custom') {
      newRevenueRange = customRevenueRange;
    }
    setFilterRevenueRangeValue(newRevenueRange);
    setSelectedRevenueRange(rangeName);
  }

  const updateCustomPriceRange = (start_: number, end_: number) => {
    let newRevenueRange: RangeModel = {
      start: start_,
      end: end_
    };

    setCustomRevenueRange(newRevenueRange);
    setFilterRevenueRangeValue(newRevenueRange);
  }

  const FilterCityView = () => {
    let propertyCities: string[] = [];

    rows.map((item, index) => {
      if (item.propertyCity != '' && propertyCities.indexOf(item.propertyCity) === -1) {
        propertyCities.push(item.propertyCity);
      } else if ((item.propertyCity === '' || item.propertyCity === null) &&
        propertyCities.indexOf('Blank') === -1) {
        propertyCities.push('Blank');
      }
    });

    if (filterAvailableCities.length === 0 && propertyCities.length > 0) {
      setFilterAvailableCities(propertyCities);
    }

    return (
      <ExpansionPanel className="filter-panel">
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="filter-heading">City</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="property-label-container">
            <div className="property-label">
              <FormControlLabel
                control={<Checkbox
                  onClick={() => handleSelectAllCities(propertyCities)}
                  checked={propertyCities.length === filteredCities.length && propertyCities.length !== 0}
                  indeterminate={filteredCities.length !== propertyCities.length && filteredCities.length !== 0}
                  disabled={propertyCities.length === 0}
                  inputProps={{'aria-label': 'all cities are selected'}}
                  color="primary"/>}
                label="All"
              />
            </div>
            {
              propertyCities.sort().map((city, index) => (
                  <div className="property-label" key={'city' + index}>
                    <FormControlLabel
                      control={<Checkbox
                        name={'lable_' + city}
                        checked={filteredCities.indexOf(city) !== -1}
                        onClick={() => handleSelectCity(city)}
                        color="primary"/>}
                      label={city}
                    />
                  </div>
                )
              )}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  const FilterByStatus = () => {
    let propertyStatus: string[] = ["Active", "Inactive"];

    if (filterAvailableStatus.length === 0) {
      setFilterAvailableStatus(propertyStatus);
    }

    return (
      <ExpansionPanel className="filter-panel">
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="filter-heading">Status</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="property-label-container">
            <div className="property-label">
              <FormControlLabel
                control={<Checkbox
                  onClick={() => handleSelectAllStatus(propertyStatus)}
                  checked={propertyStatus.length === filteredByStatus.length && propertyStatus.length !== 0}
                  indeterminate={filteredByStatus.length !== propertyStatus.length && filteredByStatus.length !== 0}
                  disabled={propertyStatus.length === 0}
                  inputProps={{'aria-label': 'all status are selected'}}
                  color="primary"/>}
                label="All"
              />
            </div>
            {
              propertyStatus.sort().map((status, index) => (
                  <div className="property-label" key={'status' + index}>
                    <FormControlLabel
                      control={<Checkbox
                        name={'lable_' + status}
                        checked={filteredByStatus.indexOf(status) !== -1}
                        onClick={() => handleSelectStatus(status)}
                        color="primary"/>}
                      label={status}
                    />
                  </div>
                )
              )}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  const FilterPropertyOwner = () => {
    let propertyOwners: string[] = [];

    rows.map((v, i, a) => {
      var isUnique = a.findIndex(t => (t.propertyOwnerName === v.propertyOwnerName && t.propertyOwnerName !== '' && t.propertyOwnerName !== null)) === i;

      if (isUnique) {
        propertyOwners.push(v.propertyOwnerName);
      }
    })

    propertyOwners = propertyOwners.sort();

    propertyOwners = propertyOwners.map((item) => {
      if (item.trim() === "")
        item = "Blank";
      return item;
    });

    if (filterAvailableOwners.length === 0 && propertyOwners.length > 0) {
      setFilterAvailableOwners(propertyOwners);
    }

    return (
      <ExpansionPanel className="filter-panel">
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className="filter-heading">Owner</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Autocomplete
            multiple
            id="listMngCompany"
            className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
            options={propertyOwners}
            disableCloseOnSelect
            size="small"
            value={filteredOwners}
            onChange={(event: any, values: any) => {
              setFilteredOwners(values);
            }}
            getOptionLabel={(option) => option}
            renderOption={(option, {selected}) => (
              <React.Fragment>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                  checkedIcon={<CheckBoxIcon fontSize="small"/>}
                  style={{marginRight: 8}}
                  checked={selected}
                  color="primary"
                />
                {option}
              </React.Fragment>
            )}
            renderInput={(params) => <TextField {...params} label="Search by owner" variant="outlined"/>}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  const FilterMngCompnay = () => {
    // let propertyCompanies: ManagerList[] = [];
    let propertyCompanies: string[] = [];

    /*var dd = rows.filter((v,i,a)=> {
            return a.findIndex(t=>(t.propertyCompanyName === v.propertyCompanyName && t.propertyCompanyName !== '' && t.propertyCompanyName !== null))===i
        })
        console.log(dd);*/

    rows.map((v, i, a) => {
      var isUnique = a.findIndex(t => (t.propertyCompanyName === v.propertyCompanyName && t.propertyCompanyName !== '' && t.propertyCompanyName !== null)) === i;

      if (isUnique) {
        propertyCompanies.push(v.propertyCompanyName);
      }
    })

    propertyCompanies = propertyCompanies.sort();

    if (filterAvailableCompanies.length === 0 && propertyCompanies.length > 0) {
      setFilterAvailableCompanies(propertyCompanies);
    }

    return (
      <ExpansionPanel className="filter-panel">
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className="filter-heading">Management Company</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Autocomplete
            multiple
            id="listMngCompany"
            className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
            options={propertyCompanies}
            disableCloseOnSelect
            size="small"
            value={filteredCompanies}
            onChange={(event: any, values: any) => {
              setFilteredCompanies(values);
            }}
            getOptionLabel={(option) => option}
            renderOption={(option, {selected}) => (
              <React.Fragment>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                  checkedIcon={<CheckBoxIcon fontSize="small"/>}
                  style={{marginRight: 8}}
                  checked={selected}
                  color="primary"
                />
                {option}
              </React.Fragment>
            )}
            renderInput={(params) => <TextField {...params} label="Search by management company" variant="outlined"/>}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  const FilterRevenueRange = () => {
    return (
      <ExpansionPanel className="filter-panel">
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel2a-content"
          id="panel3a-header"
        >
          <Typography className="filter-heading">Revenue Range</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <FormControl component="fieldset">
            <RadioGroup aria-label="revenue" name="revenue">
              <FormControlLabel
                checked={selectedRevenueRange === 'less50'}
                onClick={(event) => handleSetRevenuRange('less50', {start: 0, end: 50000})}
                value="less50" control={<Radio color="primary"/>} label="Less than $50,000"/>
              <FormControlLabel
                checked={selectedRevenueRange === 'more50'}
                onClick={(event) => handleSetRevenuRange('more50', {start: 50000, end: 0})}
                value="more50" control={<Radio color="primary"/>} label="More than $50,000"/>
              <FormControlLabel
                checked={selectedRevenueRange === 'more100'}
                onClick={(event) => handleSetRevenuRange('more100', {start: 100000, end: 0})}
                value="more100" control={<Radio color="primary"/>} label="More than $100,000"/>
              <FormControlLabel value="custom"
                                checked={selectedRevenueRange === 'custom'}
                                onClick={(event) => handleSetRevenuRange('custom', {start: 65000, end: 125000})}
                                control={<Radio color="primary"/>} label="Custom Range"/>
            </RadioGroup>
          </FormControl>
          {
            selectedRevenueRange === 'custom' ?
              <div className="slider-revenue-controller">
                <PriceRangeSelector
                  selectedRange={{
                    start: customRevenueRange.start,
                    end: customRevenueRange.end
                  }}
                  min={10000}
                  max={500000}
                  stepSize={1000}
                  onChange={(start_: number, end_: number) => {
                    updateCustomPriceRange(start_, end_)
                  }}
                />
              </div>
              : null
          }
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  const FilterSlots = () => {
    return (
      <ExpansionPanel className="filter-panel">
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel2a-content"
          id="panel4a-header"
        >
          <Typography className="filter-heading">Slots Correlation</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <FormControl component="fieldset">
            <RadioGroup aria-label="slots" name="slots">
              <FormControlLabel value="Empty" control={<Radio color="primary"/>} label="Empty"/>
              <FormControlLabel value="Full" control={<Radio color="primary"/>} label="Full"/>
              <FormControlLabel value="Less than 50%" control={<Radio color="primary"/>} label="Less than 50%"/>
              <FormControlLabel value="More than 50%" control={<Radio color="primary"/>} label="More than 50%"/>
              <FormControlLabel value="Less than 10%" control={<Radio color="primary"/>} label="Less than 10%"/>
              <FormControlLabel value="More than 10%" control={<Radio color="primary"/>} label="More than 10%"/>
            </RadioGroup>
          </FormControl>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  const FilterDate = () => {
    return (
      <ExpansionPanel className="filter-panel">
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel2a-content"
          id="panel5a-header"
        >
          <Typography className="filter-heading">Date</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <DateRange
            // editableDateInputs={true}
            // onChange={item => setRangeDateState([item.selection])}
            onChange={(item: any) => {
              setRangeDateState([item.selection]);
            }}
            // moveRangeOnFirstSelection={false}
            ranges={rangeDateState}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  const ApplyFilter = () => {
    let _filterOption: FilterOptionsModel = {
      City: filteredCities,
      Status: filteredByStatus,
      Company: filteredCompanies,
      DateRangeSelected: '',
      Revenue: filterRevenueRangeValue,
      SlotsCorrelation: '',
      FilterApplied: true,
      AvailableCities: filterAvailableCities,
      AvailableCompany: filterAvailableCompanies,
      AvailableStatus: filterAvailableStatus,
      AvailableOwner: filterAvailableOwners,
      Owner: filteredOwners
    }

    setFilterOptions(_filterOption);
    PreserveFilters.preserveFilter('properties', _filterOption)
    setIsFilterApplied(true);
    setIsFilterPanelVisible(false);
    setPage(0);
  }

  const ClearFilter = () => {
    let _filterOption: FilterOptionsModel = {
      City: [],
      Company: [],
      Status: [],
      DateRangeSelected: '',
      Revenue: emptyRange,
      SlotsCorrelation: '',
      FilterApplied: false,
      AvailableCities: filterAvailableCities,
      AvailableCompany: filterAvailableCompanies,
      AvailableStatus: filterAvailableStatus,
      AvailableOwner: filterAvailableOwners,
      Owner: []
    }

    setFilteredCities([]);
    setFilteredCompanies([]);
    setFilteredOwners([]);
    setFilteredByStatus([]);
    setFilterOptions(_filterOption);
    PreserveFilters.clearPreservedFilter('properties')
    setIsFilterApplied(false);
    setSelectedRevenueRange('');
    setIsFilterPanelVisible(true);
  }

  const updateFilterTags = (filterValues: FilterOptionsModel) => {
    setFilterOptions(filterValues);
    PreserveFilters.preserveFilter('properties', filterValues)
    setFilteredCities(filterValues.City);
    setFilteredCompanies(filterValues.Company);
    setFilteredByStatus(filterValues.Status);
    setFilteredOwners(filterValues.Owner);
    setFilterRevenueRangeValue(filterValues.Revenue);
  }

  const CancelFilter = () => {
    setIsFilterPanelVisible(false);
  }

  const FilterView = () => {
    return (
      <div className="side-popup-container">
        <div className="close-menu-icon">
          <Tooltip title="Close">
            <IconButton aria-label="Close" onClick={() => handleChangeRightMenu(false)}>
              <CloseIcon/>
            </IconButton>
          </Tooltip>
        </div>
        <Grid container>
          <Grid item xs>
            <h1 className="list-header">Filter</h1>
          </Grid>
          <Grid item>
            <div className="clear-filter" onClick={() => ClearFilter()}>Clear all</div>
          </Grid>
        </Grid>
        <div className="right-menu-body">
          <div className="filter-root">
            {
              FilterCityView()
            }
            {
              FilterPropertyOwner()
            }
            {
              FilterMngCompnay()
            }
            {/* {
                            FilterRevenueRange()
                        } */}
            {
              FilterByStatus()
            }
            {/* {
                            FilterDate()
                        }
                        {
                            FilterSlots()
                        } */}

          </div>
        </div>
        <Grid container spacing={1} className="button-container">
          <Grid item xs>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className='list-button'
              onClick={() => ApplyFilter()}
            >
              Apply
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className='list-button list-button-cancel'
              onClick={() => CancelFilter()}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }

  const onFilesSelected = (files: IFileInfo[]) => {
    let _newRecord: Data = newRecord;

    _newRecord.images = files;

    handleChangeRecord(new Event('images'));
    // setNewRecord(_newRecord);
  }

  const getUploadedFiles = () => {
    let _files: IFileInfo[] = [];

    for (let i = 0; i < newRecord.images.length; i++) {
      let img = newRecord.images[i];

      _files.push({
        extension: img.extension,
        fileName: img.fileName,
        fileUrl: img.fileUrl,
        internalUid: img.storedFileGuid as string,
        thumbnailUrl: img.thumbnailUrl,
        fileContent: img.fileContent,
        storedFileGuid: img.storedFileGuid
      });
    }

    return _files;
  }

  const openAddUser = () => {
    setIsAddedOpened(true);
  }

  const openAddCompany = () => {
    setIsAddCompanyOpened(true);
  }

  const openAddBuildingContact = () => {
    setIsAddContactOpened(true);
  }

  const getCityAndZip = (values: any) => {
    let response_: string[] = [];

    if (values.address_components.length > 0) {
      let addressComponents = values.address_components;
      for (let address_ = 0; address_ < addressComponents.length; address_++) {
        if (addressComponents[address_].types[0] == 'locality') {
          response_.push(addressComponents[address_].long_name);
        } else if (addressComponents[address_].types[0] == 'postal_code') {
          response_.push(addressComponents[address_].long_name);
        }
      }

      // set blank zip if not exits
      if (response_.length == 1) {
        response_.push("");
      }
    }

    /*if (values.terms.length > 3) {
            cityName_ = values.terms[values.terms.length - 3].value;
        } else {
            cityName_ = values.terms[0].value;
        }*/

    return response_;
  }

  const loadPropertyImages = () => {
    if (newRecord?.images.length == 0) {
      setIsRightSideServiceLoaded(true);
      _propertyService.getPropertyImages(newRecord?.propertyGuid)
        .then(response => {
          if (response.ok) {
            response.json().then(resp => {
              let _newRecord: Data = newRecord;
              let imageList: any[] = resp;
              _newRecord.images = imageList;
              handleChangeRecord(new Event('images'));
            })
          }
        });
    }
  }

  const loadPropertiesData = () => {
    setIsRightSideServiceLoaded(true);

    fetchCompanyList().then(response => {
      if (response.ok) {
        response.text().then(resp => {
          let companyItems: any[] = JSON.parse(resp);
          // console.log(companyItems);
          let mangerItems = companyItems.map(m => {
            return {
              companyGuid: m.companyGuid,
              companyName: m.companyName
            }
          })

          setPropertyCompany(mangerItems);
        })
      }

    });

    _propertyService.getPropertyOwners().then(response => {
      if (response.ok) {
        response.json().then(resp => {
          let ownerList: any[] = resp;
          let ownerItems = ownerList.map(m => {
            return {
              propertyOwnerGuid: m.propertyOwnerGuid,
              propertyOwnerName: m.propertyOwnerName
            }
          });

          setPropertyOwner(ownerItems);
        })
      }
    });

    let permissionKeys: string[] = ['edit-property-price', 'edit-property-hours'];
    _userService.hasMultipleAccess(permissionKeys).then(response => {
      if (response.ok) {
        response.json().then(resp => {
          let permissions: boolean[] = resp;
          setHoursPricePermission(permissions);
        })
      }
    });

    //Load building contact user
    LoadBuildingContact();

    // _spotService.getFeatureList()
    //     .then(r => r.json())
    //     .then(r => {
    //         setFeatureList(featureList);
    //     });
  }

  const LoadBuildingContact = (selectedCompany_?: ManagerList) => {
    if (!selectedCompany_) {
      selectedCompany_ = selectedCompany;
    }

    if (selectedCompany_ != null && selectedCompany_.companyGuid != "") {
      _userService.getCompanyUsers(selectedCompany_.companyGuid).then(response => {
        if (response.ok) {
          response.json().then(resp => {
            let contactUser: BulidingContactList[] = resp;
            let contactUserItems = contactUser.map(m => {
              return {
                applicationUserGuid: m.applicationUserGuid,
                name: m.name,
                role: m.role
              }
            });

            let selectedBc = contactUserItems.filter(m => {
              return newRecord?.buildingContacts.indexOf(m.applicationUserGuid) > -1;
            });
            setPropertyCompanyContact(contactUserItems);
            setSelectedBuildingContacts(selectedBc);
          })
        }
      });
    } else {
      setPropertyCompanyContact(emptyContactList.slice(0));
      setSelectedBuildingContacts(emptyContactList.slice(0));
    }
  }

  const AddEditPropertyView = (ViewType: string) => {
    if (!isRightSideServiceLoaded) loadPropertiesData();
    return (
      <div className="side-popup-container">
        <Grid container>
          <Grid item xs>
            <h1 className="list-header">{ViewType} Property</h1>
          </Grid>
          <Grid item>
            <div className="close-menu-icon">
              <Tooltip title="Close">
                <IconButton aria-label="Close" onClick={() => {
                  handleChangeRightMenu(false);
                  setIsRightSideServiceLoaded(false);
                }}>
                  <CloseIcon/>
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        <div className="right-menu-body">
          <h4 className="list-sub-header">Description</h4>
          <TextField
            error={newRecord?.propertyName.length == 0}
            variant="outlined"
            margin="normal"
            fullWidth
            id="name"
            label="Property Name"
            name="name"
            onChange={handleChangeRecord}
            value={newRecord?.propertyName}
            autoComplete="off"
          />

          <TextField
            multiline
            rows={4}
            variant="outlined"
            margin="normal"
            fullWidth
            id="AboutProperty"
            label="Spot Details"
            name="aboutProperty"
            onChange={handleChangeRecord}
            value={newRecord?.propertyAbout ? newRecord?.propertyAbout : ''}
            autoComplete="off"
            inputProps={{
              maxlength: 200
            }}
            helperText={`${newRecord?.propertyAbout.length}/200`}
          />

          {/* <h4 className="list-sub-header">Managing</h4> */}

          <Autocomplete
            id="propertyOwner"
            className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
            options={propertyOwner}
            value={selectedOwner}
            onChange={(event: any, values: any) => {
              let _values: OwnerList = {
                propertyOwnerGuid: '',
                propertyOwnerName: ''
              }

              if (values) {
                _values = values;
              }

              setSelectedOwner(_values);
            }}
            getOptionLabel={(option) => option.propertyOwnerName}
            disabled={!isAdmin}
            renderInput={(params) => <TextField {...params} label="Property Owner" variant="outlined"
              // error={selectedOwner == null || (selectedOwner as any).propertyOwnerGuid == ""}
            />}
          />

          {/* error={selectedOwner.propertyOwnerGuid == ''} */}

          {
            isAdmin ? <div>
              <span className="add-button" onClick={() => openAddUser()}>Add New Property Owner</span>
            </div> : null
          }


          <Autocomplete
            id="CompnayName"
            className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
            options={propertyCompany}
            value={selectedCompany}
            onChange={(event: any, values: any) => {
              let _values: ManagerList = {
                companyGuid: '',
                companyName: ''
              }

              if (values) {
                _values = values;
              }

              setSelectedCompany(_values);
              LoadBuildingContact(_values);
            }}
            getOptionLabel={(option) => option.companyName}
            disabled={!isAdmin}
            renderInput={(params) => <TextField {...params}
                                                label="Property Management Company"
                                                error={selectedCompany == null || (selectedCompany as any).companyGuid == ""}
                                                variant="outlined"/>}
          />
          {/* error={selectedCompany.companyGuid == ''} */}

          {
            isAdmin ? <div>
              <span className="add-button" onClick={() => openAddCompany()}>Add New Property Management Company</span>
            </div> : null
          }

          <Autocomplete
            id="YardiSyncPropertyConfig"
            className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
            options={props.yardiConfigs}
            disabled={!isAdmin}
            value={
              newRecord.propertyConfigGuid || isAdmin ?
                props.yardiConfigs.filter(config => config.pmsConfigureGuid === newRecord.propertyConfigGuid)[0] :
                props.yardiConfigs[0]
            }
            getOptionLabel={(option: YardiConfigs) => option.description}
            onChange={(event, value) => {
              const isYardiConfigDeleted = (newRecord.propertyConfigGuid !== undefined && value?.pmsConfigureGuid === undefined);
              if (newRecord?.propertyConfigGuid) setLastSavedYardiConfig(newRecord.propertyConfigGuid);
              setIsEdited(true);
              setHasUnsavedChanges(true);
              setNewRecord((prevState) => ({
                  ...prevState,
                  isYardiConfigDeleted,
                  propertyConfigGuid: value?.pmsConfigureGuid,
                })
              );
            }}
            renderInput={(params) =>
              <TextField {...params}
                         label="Yardi Environment"
                         variant="outlined"
              />}
          />

          <Autocomplete
            id="BuildingContactNames"
            multiple
            className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
            options={propertyCompanyContact}
            value={selectedContacts}
            onChange={(event: any, values: any) => {
              let _newRecord: Data = newRecord;
              let bulidingContactList: BulidingContactList[] = [];

              if (values) {
                bulidingContactList = values.map((s: any) => {
                  return s
                });
              }

              setSelectedBuildingContacts(bulidingContactList);
            }}
            getOptionLabel={(option) => option.name}
            disabled={(selectedCompany == null || (selectedCompany as any).companyGuid == "")}
            renderInput={(params) => <TextField {...params}
                                                label="Assign Building Contact(s)"
                                                variant="outlined"/>}
          />
          {
            <div>
              <span className="add-button" onClick={() => openAddBuildingContact()}>Add New Building Contact</span>
            </div>
          }

          <h4 className="list-sub-header">Location</h4>

          {/* <h4 className="list-sub-header">Access</h4> */}

          <div className="map-container hide-map">
            <CustomMaps address=""/>
          </div>

          <AddressDropdown
            onAddressChange={(values: any) => {
              let _newRecord: Data = newRecord;

              if (values) {
                let cityAndZip = getCityAndZip(values);
                if (cityAndZip.length == 2 && values.timeZoneId != null) {
                  _newRecord.propertyAddress1 = values.description;
                  _newRecord.propertyCity = cityAndZip[0];
                  _newRecord.propertyZipCode = cityAndZip[1];
                  _newRecord.propertyLatitude = values.lat;
                  _newRecord.propertyLongitude = values.lng;
                  _newRecord.propertyTimeZone = values.timeZoneId;
                } else {
                  _newRecord.propertyAddress1 = '';
                  _newRecord.propertyCity = '';
                  _newRecord.propertyLatitude = 0;
                  _newRecord.propertyLongitude = 0;
                  _newRecord.propertyZipCode = '';
                  _newRecord.propertyTimeZone = '';
                  ShowAlert('Error!', 'Please enter the valid address.', 'error');
                }
              } else {
                _newRecord.propertyAddress1 = '';
                _newRecord.propertyCity = '';
                _newRecord.propertyLatitude = 0;
                _newRecord.propertyLongitude = 0;
                _newRecord.propertyZipCode = '';
                _newRecord.propertyTimeZone = '';
              }
              handleChangeRecord(new Event('address'));
            }}
            address={newRecord?.propertyAddress1}
            city={newRecord?.propertyCity}
            required
          />

          {/* <TextField
                        error={newRecord?.propertyAddress1.length < 2}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="address"
                        label="Address"
                        name="address"
                        autoComplete="off"
                        onChange={handleChangeRecord}
                        value={newRecord?.propertyAddress1}
                    /> */}

          <TextField
            // error={newRecord?.propertyCity.length < 2}
            variant="outlined"
            margin="normal"
            fullWidth
            id="city"
            label="City"
            name="city"
            autoComplete="off"
            onChange={handleChangeRecord}
            value={newRecord?.propertyCity}
            disabled={true}
          />

          <TextField
            // error={newRecord?.propertyCity.length < 2}
            variant="outlined"
            margin="normal"
            fullWidth
            id="propertyZipCode"
            label="Zip Code"
            name="propertyZipCode"
            autoComplete="off"
            onChange={handleChangeRecord}
            value={newRecord?.propertyZipCode}
            disabled={true}
          />

          {/* <h4 className="list-sub-header">Spots</h4>

                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="quantity"
                        label="Quantity"
                        name="spot"
                        autoComplete="off"
                        onChange={handleChangeRecord}
                        onPaste={checkPasteValue}
                        onKeyPress={checkValue}
                        value={newRecord?.propertyQuantity}
                    /> */}

          {/* {
                        ViewType == 'Edit' ? <div>
                            <span className="add-button" onClick={() => {
                                if (newRecord?.propertyQuantity > 0) {
                                    setIsAssignSpotsOpened(true);
                                } else {
                                    ShowAlert('Error!', 'Please enter the <b>Spots Quantity<b/>.', 'error');
                                }
                            }}>+ Manage Spots</span>
                        </div> : null
                    } */}

          <h4 className="list-sub-header">Gallery</h4>
          <FilePicker
            label="Add Property Images"
            descriptionSubset={["property photographs", "that are not larger than 1024 x 768 pixels and 1MB"]}
            filter=".jpg,.jpeg,.png,.bmp,.gif,.tiff"
            isButtonIcon={true}
            onFilesSelectionComplete={(files: IFileInfo[]) => {
              onFilesSelected(files);
            }}
            uploadedFiles={getUploadedFiles()}
            interceptUpload={(file_, callback_) => {
              if (file_.fileContent) {
                if (file_.fileContent.size / 1024 / 1024 > 1) {
                  ShowToastr(`${file_.fileName} is larger than 1MB`);
                  callback_(false);
                  return;
                }
              }
              let image = new Image();
              image.onload = () => {
                if (image.width > 1024 || image.height > 768) {
                  ShowToastr(`${file_.fileName} is larger than 1024 x 768 pixels`);
                  callback_(false);
                } else {
                  callback_(true);
                }
              }
              image.src = file_.fileUrl;
            }}
          />
          <ImageGrid
            imageData={newRecord?.images}
            CarouselTitle={newRecord?.propertyName}
          />
          {/* <TextField
                        multiline
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="GettingThere"
                        label="Getting There"
                        name="gettingThere"
                        disabled
                    // onChange={handleChangeRecord}
                    // value={newRecord?.Name}
                    /> */}

          {/* <TextField
                        multiline
                        rows={4}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="redeem"
                        label="How to redeem"
                        name="redeem"
                        onChange={handleChangeRecord}
                        value={newRecord?.propertyRedeem ? newRecord?.propertyRedeem : ''}
                    /> */}

          <TextField
            multiline
            rows={4}
            variant="outlined"
            margin="normal"
            fullWidth
            id="propertyInstructions"
            label="When you arrive"
            name="propertyInstructions"
            onChange={handleChangeRecord}
            value={newRecord?.propertyInstructions ? newRecord?.propertyInstructions : ''}
          />

          {
            (GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) != -1 || GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) != -1) ?
              <section className="disbursement-setting">
                <h1 className="list-header">Disbursement Settings</h1>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Distribution Type</FormLabel>
                  <RadioGroup
                    aria-label="distributionType"
                    name="distributionType"
                    value={newRecord?.propertyDistributionType}
                    onChange={handleChangeRecord} className="ml-3">
                    <FormControlLabel value={1} control={<Radio/>} label="Digital distribution via Modern Treasury"/>
                    <FormControlLabel value={2} control={<Radio/>} label="Offline via Gryd finance team"/>
                  </RadioGroup>
                </FormControl>

                <TextField
                  fullWidth
                  className="percentage-textfield"
                  label="For Gryd"
                  id="text_gryd"
                  name="propertyGrydPercentage"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  variant="outlined"
                  onKeyPress={checkDisbursementValue}
                  onChange={(event: any) => {
                    let _newRecord: Data = newRecord;
                    var _targetValue = event.target ? event.target.value : "";

                    if (_targetValue > 100) {
                      _newRecord.propertyGrydPercentage = _newRecord.propertyGrydPercentage
                    } else {
                      _newRecord.propertyGrydPercentage = _targetValue;
                    }
                    handleChangeRecord(new Event('propertyGrydPercentage'));
                  }}
                  onBlur={(event: any) => {
                    let _newRecord: Data = newRecord;
                    var _targetValue = event.target ? event.target.value : "";

                    if (_targetValue > 100) {
                      _newRecord.propertyGrydPercentage = _newRecord.propertyGrydPercentage
                    } else {
                      _newRecord.propertyGrydPercentage = _targetValue ? parseFloat(_targetValue) : 0;
                    }
                    handleChangeRecord(new Event('propertyGrydPercentage'));
                  }}
                  value={newRecord?.propertyGrydPercentage}
                />
                <TextField
                  fullWidth
                  className="percentage-textfield"
                  label="For Property"
                  id="text_property_owner"
                  name="propertyPropertyPercentage"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  variant="outlined"
                  onKeyPress={checkDisbursementValue}
                  onChange={(event: any) => {
                    let _newRecord: Data = newRecord;
                    var _targetValue = event.target ? event.target.value : "";

                    if (_targetValue > 100) {
                      _newRecord.propertyPropertyPercentage = _newRecord.propertyPropertyPercentage
                    } else {
                      _newRecord.propertyPropertyPercentage = _targetValue;
                    }
                    handleChangeRecord(new Event('propertyPropertyPercentage'));
                  }}
                  onBlur={(event: any) => {
                    let _newRecord: Data = newRecord;
                    var _targetValue = event.target ? event.target.value : "";

                    if (_targetValue > 100) {
                      _newRecord.propertyPropertyPercentage = _newRecord.propertyPropertyPercentage
                    } else {
                      _newRecord.propertyPropertyPercentage = _targetValue ? parseFloat(_targetValue) : 0;
                    }
                    handleChangeRecord(new Event('propertyPropertyPercentage'));
                  }}
                  value={newRecord?.propertyPropertyPercentage}
                />
                <TextField
                  fullWidth
                  className="percentage-textfield"
                  label="For Spot Owner"
                  id="text_spot_owner"
                  name="propertySpotOwnerPercentage"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  variant="outlined"
                  onKeyPress={checkDisbursementValue}
                  onChange={(event: any) => {
                    let _newRecord: Data = newRecord;
                    var _targetValue = event.target ? event.target.value : "";

                    if (_targetValue > 100) {
                      _newRecord.propertySpotOwnerPercentage = _newRecord.propertySpotOwnerPercentage
                    } else {
                      _newRecord.propertySpotOwnerPercentage = _targetValue;
                    }
                    handleChangeRecord(new Event('propertySpotOwnerPercentage'));
                  }}
                  onBlur={(event: any) => {
                    let _newRecord: Data = newRecord;
                    var _targetValue = event.target ? event.target.value : "";

                    if (_targetValue > 100) {
                      _newRecord.propertySpotOwnerPercentage = _newRecord.propertySpotOwnerPercentage
                    } else {
                      _newRecord.propertySpotOwnerPercentage = _targetValue ? parseFloat(_targetValue) : 0;
                    }
                    handleChangeRecord(new Event('propertySpotOwnerPercentage'));
                  }}
                  value={newRecord?.propertySpotOwnerPercentage}
                />
                {/* <TextField
                                fullWidth
                                className="percentage-textfield"
                                label="For Spot Owner"
                                id="text_spot_owner"
                                name="propertySpotOwnerPercentage"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                variant="outlined"
                                onKeyPress={checkDisbursementValue}
                                onChange={(event: any) => {
                                    let _newRecord: Data = newRecord;
                                    var _targetValue = event.target ? event.target.value : "";

                                    if (_targetValue > 100) {
                                        _newRecord.propertySpotOwnerPercentage = _newRecord.propertySpotOwnerPercentage
                                    } else {
                                        _newRecord.propertySpotOwnerPercentage = parseFloat(_targetValue);
                                    }
                                    handleChangeRecord(new Event('propertySpotOwnerPercentage'));
                                }}
                                onBlur={(event: any) => {
                                    let _newRecord: Data = newRecord;
                                    var _targetValue = event.target ? event.target.value : "";

                                    if (_targetValue > 100) {
                                        _newRecord.propertySpotOwnerPercentage = _newRecord.propertySpotOwnerPercentage
                                    } else {
                                        _newRecord.propertySpotOwnerPercentage = _targetValue ? parseFloat(_targetValue) : 0;
                                    }
                                    handleChangeRecord(new Event('propertySpotOwnerPercentage'));
                                }}

                                value={newRecord?.propertySpotOwnerPercentage}
                            /> */}
              </section> : null
          }


        </div>
        <Grid container spacing={2} className="button-container">
          <Grid item xs>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className='list-button'
              onClick={() => SaveRecord(ViewType)}
            >
              Save
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className='list-button list-button-cancel'
              onClick={() => handleChangeRightMenu(false)}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }

  const checkDailyBookingOverlap = (dailyTimeBlock_: IDailyTimeBlock[]) => {
    let activeDailyBooking = dailyTimeBlock_.filter(x => {
      return x.isActive == true;
    });

    let sanitizeSchedule = (schedule_: INumberRange, isIncreaseByDay_: boolean): INumberRange => {
      if (schedule_.start != undefined && schedule_.end != undefined && schedule_.start > schedule_.end) {
        if (isIncreaseByDay_) {
          return {
            start: schedule_.start,
            end: schedule_.end + fullDay
          };
        } else {
          return {
            start: schedule_.start - fullDay,
            end: schedule_.end
          };
        }
      }
      return schedule_;
    }

    if (activeDailyBooking.length > 1) {
      let schedule1: INumberRange = {
        start: dailyTimeBlock_[0].originalStartTimeConverted,
        end: dailyTimeBlock_[0].originalEndTimeConverted
      }

      let schedule2: INumberRange = {
        start: dailyTimeBlock_[1].originalStartTimeConverted,
        end: dailyTimeBlock_[1].originalEndTimeConverted
      }

      let schedule1Upper = sanitizeSchedule(schedule1, true);
      let schedule2Upper = sanitizeSchedule(schedule2, true);
      let schedule1Lower = sanitizeSchedule(schedule1, false);
      let schedule2Lower = sanitizeSchedule(schedule2, false);

      return NumberHelper.doNumberRangesConflict(schedule1Upper, schedule2Upper) ||
        NumberHelper.doNumberRangesConflict(schedule1Lower, schedule2Lower) ||
        NumberHelper.doNumberRangesConflict(schedule1Lower, schedule2Upper) ||
        NumberHelper.doNumberRangesConflict(schedule1Upper, schedule2Lower);
    }

    return false;
  }

  const scheduleJsonText = (schedule_: ScheduleModule): string => {
    let asString = `(${schedule_.selectedDays?.sort().join(",")})-${schedule_.selectedTimeRange?.start}-${schedule_.selectedTimeRange?.end}=${schedule_.selectedPriceRange?.start}`;
    return asString ? asString : "";
  }

  const rightSidePannel = () => {
    // (selectedRows.length > 0 || !isAddPanelVisible) ?

    if (selectedRows.length > 0) {
      return (
        <Drawer
          anchor="right"
          open={true}
          onClose={() => {
            handleChangeRightMenu(false);
            setIsRightSideServiceLoaded(false);
          }}
        >
          <div className={'table-right-menu'}>
            {
              // editedEnabled ? EditView() : ViewMode()
              editedEnabled ? AddEditPropertyView('Edit') : ViewMode()
            }
          </div>
        </Drawer>
      )
    } else if (isAddPanelVisible) {
      return (
        <Drawer
          anchor="right"
          open={true}
          onClose={() => {
            handleChangeRightMenu(false);
            setIsRightSideServiceLoaded(false);
          }}
        >
          <div className={'table-right-menu'}>
            {
              AddEditPropertyView('Add')
            }
          </div>
        </Drawer>
      )
    } else if (isFilterPanelVisible) {
      return (
        <Drawer
          anchor="right"
          open={true}
          onClose={() => {
            handleChangeRightMenu(false);
            setIsRightSideServiceLoaded(false);
          }}
        >
          <div className={'table-right-menu'}>
            {
              FilterView()
            }
          </div>
        </Drawer>
      )
    }
  }

  const openRightPanelTab = (f_: boolean, a_: boolean) => {
    setIsAddPanelVisible(a_);
    setSelectedOwner(emptyOwnerList);
    setSelectedCompany(emptyCompanyList);
    setEditedEnabled(false);
    setIsFilterPanelVisible(f_);
    setSelectedCallback([]);
    clearFields();
  }

  const updateSearchText = (searchText: string) => {
    setSearchedTextState(searchText);
    window.localStorage.setItem('propSearch', searchText);
  }

  const searchTable = (tableRows: any[], searchText: string) => {
    let searchedItems: Data[] = [];
    let searchInput: string = searchText.trim().toLowerCase();

    if (searchInput !== "" && searchInput !== null) {
      tableRows.map((v, i, a) => {
        let exists: boolean = false;

        for (var i = 0; i < headCells.length; i++) {
          let coloumnName: keyof Data = headCells[i].id;
          let values = v[coloumnName];

          //if (typeof (values) === 'string') {
          if (coloumnName.indexOf('StatusId') === -1 && values !== null && values !== '') {
            values = values.toString().toLowerCase();

            if (values.indexOf(searchInput) !== -1) {
              exists = true;
            }
          }
        }

        if (exists) {
          searchedItems.push(v);
        }
      });

      return searchedItems;
    }

    return tableRows;
  }

  // const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setDense(event.target.checked);
  // };

  const isSelected = (data_: any) => multiSelected.indexOf(data_) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  //const filteredRows = stableSort(searchTable(filterTable(rows, filterOptions), searchedTextState), getComparator(order, orderBy));
  const filteredItems = filterTable(rows, filterOptions);
  let filteredRows = ArrayHelper.stableSort(searchTable(filteredItems, searchedTextState), ArrayHelper.getNumberInStringComparator(order, orderBy));

  filteredRows = ArrayHelper.sortArrayObject(filteredRows, orderBy, order, headCells, "id");

  return (
    <div className={useStyles().root + ' table-container-list'}>
      <ProgressTop
        loaderPercentage={props.loaderPercentage}
      />
      <Paper className={useStyles().paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onSearchChange={(searchText: string) => {
            updateSearchText(searchText);
            setPage(0);
          }}
          buttonState={(f_: boolean, a_: boolean) => {
            openRightPanelTab(f_, a_);
          }}
          //isFiltered={filteredItems.length != rows.length}
          isFiltered={!(filterOptions.City.length == 0 &&
            filterOptions.Owner.length == 0 &&
            filterOptions.Company.length == 0 &&
            filterOptions.Status.length == 0)}
        />
        <FilteredLable
          FilteredItems={filterOptions}
          onFilterLableDelete={(updatedFilter: FilterOptionsModel) => {
            updateFilterTags(updatedFilter);
          }}
        />
        <TableContainer className={useStyles().tableContainer + "  without-checkbox-container clickable-rows"}>
          <Table
            className={useStyles().table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            stickyHeader
            aria-label="sticky table"
          >
            <EnhancedTableHead
              classes={useStyles()}
              numSelected={multiSelected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {

                //stableSort(rows, getComparator(order, orderBy))
                filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index, a) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.propertyName + (index).toString()}
                        selected={isItemSelected}
                        onClick={(event) => {
                          if (popOverTargetElement == null) {
                            handleEdit(event, row.propertyName.toString(), row)
                          }
                        }}
                      >
                        {/* <TableCell padding="checkbox">
                                                    <Checkbox
                                                        onClick={(event) => handleClick(event, row.propertyName.toString(), row)}
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                        className="check-box-table"
                                                    />
                                                </TableCell> */}
                        <TableCell align="left" className="table-list-cell">
                          <Tooltip title={((row.propertyStatusId === 1) ? 'Active' : 'Inactive')}>
                            <div
                              className={'list-status ' + ((row.propertyStatusId === 1) ? 'active' : 'inactive')}></div>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="left" className="table-list-cell">
                          {row.propertyName}
                        </TableCell>
                        <TableCell align="left" className="table-list-cell"><span
                          className="address-coloum">{row.propertyAddress1}</span></TableCell>
                        <TableCell align="left" className="table-list-cell">{row.propertyCity}</TableCell>
                        <TableCell align="left" className="table-list-cell">{row.propertyOwnerName}</TableCell>
                        <TableCell align="left" className="table-list-cell">{row.propertyCompanyName}</TableCell>
                        <TableCell align="right" className="table-list-cell">{row.propertySpotQuantity}</TableCell>
                        {
                          (GetUserDetailsService.getUserDetails().userRoleID.indexOf(8) == -1) &&
                            <TableCell align="right"
                                       className="table-list-cell">{"$" + Number(row.revenueYTD).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                        }
                        <TableCell padding="checkbox">
                          {/* disabled={!isItemSelected} */}
                          <IconButton aria-label="More" onClick={(event_) => {
                            setPopOverTargetElement(event_.target as HTMLElement);
                            setPopOverItem(row);
                            event_.stopPropagation();
                          }}>
                            <MoreHorizIcon/>
                          </IconButton>
                          {
                            popOverTargetElement != null && <Popover
                                  open={popOverItem == row && popOverTargetElement != null}
                                  anchorEl={popOverTargetElement}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                  onClose={() => {
                                    setPopOverTargetElement(null);
                                    setPopOverItem(null);
                                  }}
                              >
                                  <ClickAwayListener onClickAway={() => {
                                    setPopOverTargetElement(null);
                                    setPopOverItem(null);
                                  }}>
                                      <MenuList id="menu-list-grow">
                                          <MenuItem onClick={(event) => {
                                            handleEdit(event, row.propertyName.toString(), row)
                                          }}>Show Details</MenuItem>
                                        {
                                          isAdmin && <MenuItem onClick={(event) => {
                                            setPopOverTargetElement(null);
                                            setPopOverItem(null);
                                            ShowAlertwithConfirm("Delete Property?", `<div class="gryd-custom-popup-body">
                                                                            Are you sure you want to delete this property?<br/>This action cannot be undone.<br/><br/>
                                                                            <b>${row.propertyName}</b><br/>
                                                                            <b>${row.propertyAddress1}</b><br/>
                                                                                                </div>`, undefined, 'Delete Property', 'Cancel', 'gryd-custom-popup')
                                              .then(r_ => {
                                                if (r_) {
                                                  onChangePropertyStatus(3, row);
                                                }
                                              })
                                          }}>Delete Property</MenuItem>
                                        }
                                      </MenuList>
                                  </ClickAwayListener>
                              </Popover>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid container>
          <Grid item>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              className="table-lis-footer"
              labelDisplayedRows={({from, to, count}) => `${from}-${to} of ${count} results`}
            />
          </Grid>
          <Grid item xs className="footer-pagenation">
            <Pagination
              page={(page + 1)}
              className="Pagination"
              showFirstButton
              showLastButton
              onChange={handleChangePage}
              count={Math.ceil(filteredRows.length / rowsPerPage)}

            />
          </Grid>
        </Grid>
      </Paper>
      {
        rightSidePannel()
      }

      {
        isAddedOpened ? <AddUser
          requestType='popup'
          addUserAs='company'
          popupState={(s_: boolean) => {
            setIsAddedOpened(s_);
          }}
          onAddedUser={(u_: NewUserDetails) => {
            let _user: OwnerList = {
              propertyOwnerGuid: u_.userGuid,
              propertyOwnerName: u_.username
            }

            if (u_.companyGuid != '') {
              let _company: ManagerList = {
                companyGuid: u_.companyGuid,
                companyName: u_.companyName
              }

              setSelectedCompany(_company);
            }

            propertyOwner.push(_user);
            setPropertyOwner(propertyOwner);
            setSelectedOwner(_user);
            setIsAddedOpened(false);
            setHasUnsavedChanges(true);
          }}
          userRole='Owner'
          title="Property Owner"
        /> : null
      }

      {
        isAddContactOpened ? <AddUser
          requestType='popup'
          addUserAs='BuildingContact'
          popupState={(s_: boolean) => {
            setIsAddContactOpened(s_);
          }}
          onAddedUser={(u_: NewUserDetails) => {
            let _user: BulidingContactList = {
              applicationUserGuid: u_.userGuid,
              name: u_.username,
              role: u_.role
            }

            propertyCompanyContact.push(_user);
            selectedContacts.push(_user);
            setPropertyCompanyContact(propertyCompanyContact);
            setSelectedBuildingContacts(selectedContacts);
            setIsAddContactOpened(false);
            setHasUnsavedChanges(true);
          }}
          userRole='Building Contact'
          title="Building Contact"
          userDetails={{
            userRole: 8,
            managementCompanyGuid: selectedCompany.companyGuid,
            managementCompanyName: selectedCompany.companyName
          }}
        /> : null
      }

      {
        isAddCompanyOpened ? <AddCompany
          requestType='popup'
          popupState={(s_: boolean) => {
            setIsAddCompanyOpened(s_);
          }}
          onAddedCompany={(u_: NewCompanyDetails) => {
            let _company: ManagerList = {
              companyGuid: u_.companyGuid,
              companyName: u_.companyName
            }
            setSelectedCompany(_company);
            propertyCompany.push(_company);
            setPropertyCompany(propertyCompany);
            setIsAddCompanyOpened(false);
            setHasUnsavedChanges(true);
            setPropertyCompanyContact(emptyContactList.slice(0));
            setSelectedBuildingContacts(emptyContactList.slice(0));

          }}
          userRole='Company'
          title='Property Management Company'
        /> : null
      }
      {/* {
                isAssignSpotsOpened ?
                    <AssignSpotModal
                        property={newRecord}
                        spots={assignedSpots}
                        schedule={selectedScheduleModule}
                        onClose={() => setIsAssignSpotsOpened(false)}
                        onSave={spots_ => {
                            setHasSpotAssignmentChanged(true);
                            setAssignedSpots(spots_);
                            setIsAssignSpotsOpened(false);
                        }}
                    />
                    : null
            } */}

      {/* <LoaderComponent loaderVisible={showBlocker} /> */}


      {/* <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Dense padding"
                /> */}
    </div>
  );
}

// }

// export default MaterialGrid;
