import React from 'react';
import './Dashboard.css'
import { Checkbox, Chip, Grid, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { Bar } from 'react-chartjs-2';
import { IManagerPropertyDashboardInformationModel, IChartData, IPropertyItem, widgetOptions } from './Interface';
import LoaderComponent from '../../Components/LoaderComponent';
import PropertyService from '../../Services/PropertyService';
import { ShowAlert } from '../../Common/ShowAlert';
import { Autocomplete } from '@material-ui/lab';
import TopWidgets from './TopWidgets';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import vehicle from '../../Images/vehicle.png';
import UpcomingBookings from './UpcomingBookings';
import { UserDetailsModel } from '../../Models/UserDetails';
import SpotUtilization from '../../Images/01-spot-utilization.png';
import PropertyRevenue from '../../Images/02-property-revenue.png';
import RevenueDistribution from '../../Images/03-revenue-distribution.png';
import TotalBooking from '../../Images/04-total-booking.png';
import { GetUserDetailsService } from '../../Services/GetUserDetailsService';


class ManagerDashboardCharts extends React.Component<DashboardChartsProps, DashboardChartsState> {
    private _propertyService: PropertyService;
    constructor(props: DashboardChartsProps) {
        super(props);

        let widgetList: widgetOptions[] = [];

        if (GetUserDetailsService.getUserDetails().userRoleID[0] != 8) {
            widgetList.push({
                title: "Total Revenue",
                api: "ManagerDashboardTotalRevenueV3",
                isGreenGg: true,
                serviceEndpoint: 'property'
            });
        }

        this.state = {
            showBlocker: false,
            widgetList: widgetList,
            propertyOption: 'YTD',
            properties: [],
            selectedProperties: [],
            selectedPropertiesGuids: []
        }

        this._propertyService = new PropertyService();
    }

    componentWillMount() {
        var _localData = localStorage.getItem('userdata');

        if (_localData != null && _localData !== '') {
            var _data = JSON.parse(_localData);

            let _userDetails: UserDetailsModel = _data;

            this.setState({
                userDetails: _userDetails,
            }, () => {
                this._loadProperties();
            });
        }
    }

    private _loadProperties() {
        this._propertyService.getManagerDashboardProperties()
            .then(data => {
                if (data.ok) {
                    data.json().then(d => {
                        let _mappedProperties = d.map((r: any) => {
                            return r;
                        });

                        let _allPropertiesLable: IPropertyItem = {
                            propertyGuid: 'all_items',
                            propertyName: 'All Properties'
                        }

                        if (_mappedProperties.length > 0) {
                            _mappedProperties.unshift(_allPropertiesLable);
                        }

                        this.setState({
                            properties: _mappedProperties
                        });
                    })
                }
            })
            .catch((error) => {
                console.error('Error:', JSON.stringify(error));
            });
    }

    public render() {
        return (
            <div>
                {/* <LoaderComponent loaderVisible={this.state.showBlocker} /> */}
                <div className="dashboard-widget-container">
                    <div style={{ margin: "10px", textAlign: "right" }}><Link to="/dashboard">Dashboard V2</Link></div>
                    <Grid container>
                        <Grid item xs></Grid>
                        <Grid item>
                            <Autocomplete
                                multiple
                                id="listPropertyCities"
                                className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth listPropertyCities"
                                options={this.state.properties}
                                disableCloseOnSelect
                                size="small"
                                value={this.state.selectedProperties}
                                onChange={(event: any, values: any) => {
                                    /*let propertyGuids: string[] = values.map((property: any) => {
                                        return property.propertyGuid;
                                    });
                                    this.setState({
                                        selectedProperties: values,
                                        selectedPropertiesGuids: propertyGuids
                                    })*/
                                    let values_: string[] = values.map((property: any) => {
                                        return property.propertyGuid;
                                    });

                                    let stateValues_: string[] = this.state.selectedProperties.map((property: any) => {
                                        return property.propertyGuid;
                                    });

                                    let allValues_: string[] = this.state.properties.map((property: any) => {
                                        return property.propertyGuid;
                                    });

                                    let selectedItems: IPropertyItem[] = [];
                                    if (values_.indexOf('all_items') != -1 && stateValues_.indexOf('all_items') == -1) {
                                        selectedItems = this.state.properties;
                                    } else if (values_.indexOf('all_items') == -1 && stateValues_.indexOf('all_items') != -1) {
                                        selectedItems = [];
                                    } else if (values_.indexOf('all_items') != -1 && values_.length != allValues_.length) {
                                        let index = values_.indexOf('all_items');
                                        values.splice(index, 1);
                                        selectedItems = values;
                                    } else if (values_.indexOf('all_items') == -1 && values_.length == allValues_.length - 1) {
                                        selectedItems = this.state.properties;
                                    } else {
                                        selectedItems = values;
                                    }

                                    let selectedGuids: string[] = selectedItems.map((property: any) => {
                                        return property.propertyGuid;
                                    });

                                    this.setState({
                                        selectedProperties: selectedItems,
                                        selectedPropertiesGuids: selectedGuids
                                    })
                                }}
                                renderTags={(tagValue, getTagProps) => {
                                    return tagValue.map((option, index) => (
                                        <Chip
                                            key={'lable' + index}
                                            label={option.propertyName}
                                            {...getTagProps({ index })}
                                            className={option.propertyGuid.indexOf('all_items') != -1 ? 'hide-chip' : ''}
                                        />
                                    ));
                                }}
                                limitTags={2}
                                openOnFocus={true}
                                selectOnFocus={true}
                                /*onChange={(event: any, values: any) => {
                                    let propertyGuids: string[] = values.map((property: any) => {
                                        return property.propertyGuid;
                                    });
                                    this.setState({
                                        selectedProperties: values,
                                        selectedPropertiesGuids: propertyGuids
                                    })
                                }}*/
                                getOptionLabel={(option) => option.propertyName}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                            color="primary"
                                        />
                                        {option.propertyName}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => <TextField {...params} label="Properties" variant="outlined" />}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs>
                            <div className={'dashboard-widget selected non-admin-board'}>
                                <Grid container>
                                    <Grid item xs>
                                        <div className="pm-name">Hi {this.state.userDetails?.companyName}.</div>
                                        <div className="dashbaord-welcome">Welcome to your GrydPark Dashboard!</div>
                                    </Grid>
                                    <Grid item>
                                        <div className="vehicle-img-container">
                                            <img src={vehicle} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        {
                            this.state.widgetList.map((value_, index_) => (
                                <Grid item key={index_}>
                                    <TopWidgets
                                        widgetInfo={value_}
                                        properties={this.state.selectedPropertiesGuids}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                    <Grid container>
                        <Grid item xs>
                            <div className="dashboard-widget-chart">
                                <img src={SpotUtilization} />
                                <div className="coming-soon">
                                    <div>Coming Soon</div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs>
                            <div className="dashboard-widget-chart">
                                <img src={PropertyRevenue} />
                                <div className="coming-soon">
                                    <div>Coming Soon</div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs>
                            <div className="dashboard-widget-chart grid-white-bg">
                                <h5>Upcoming Bookings</h5>
                                <div style={{ height: '300px' }}>
                                    <UpcomingBookings
                                        selectedProperties={this.state.selectedProperties}
                                    />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs>
                            <div className="dashboard-widget-chart">
                                <img src={RevenueDistribution} />
                                <div className="coming-soon">
                                    <div>Coming Soon</div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs>
                            <div className="dashboard-widget-chart">
                                <img src={TotalBooking} />
                                <div className="coming-soon">
                                    <div>Coming Soon</div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                {/* <img src={dashboardImg} width="100%" /> */}
            </div>
        )
    }

    private accessTokenExpired() {
        localStorage.clear();
        window.location.href = '/';
    }
}

interface DashboardChartsProps { }
interface DashboardChartsState {
    managerPropertyDashboardInformation?: IManagerPropertyDashboardInformationModel;
    showBlocker: boolean;
    widgetList: widgetOptions[];
    propertyOption: string;
    properties: IPropertyItem[];
    selectedProperties: IPropertyItem[];
    selectedPropertiesGuids: string[];
    userDetails?: UserDetailsModel;
}

export default ManagerDashboardCharts;