import { DateRange } from "materialui-daterange-picker";
import {
  IBlockTime,
  IReservation
} from "../CashBookingSchedule/Interfaces";
import { INearBySpots, ISpotAvailable } from "../Schedule/Interfaces";
import { IPropertyBasics } from "../Spots/Interfaces";

export interface IUspGetBookingBlockingStatus {
  tableName: string;
  lastUserUpdate?: Date | null;
  lastUserSeek?: Date | null;
  lastUserScan?: Date | null;
}

export enum EnumCalendarType {
  Booked = 1,
  Blocked,
}

export interface IDailySpotAggregation {
  reservationGuid: string;
  startTimeLocal: Date;
  endTimeLocal: Date;
  parkerName: string;
  propertyGuid: string;
  spotGuid: string;
  bookedType: EnumCalendarType;
  friendlyReservationId: string;
  reservationTypeId: number;
  friendlySpotId: string;
  spotStartTimeLocal: Date;
  spotEndTimeLocal: Date;
  weeklyTimeBlockId?: number;
  licenseNo: string;
  repeatType?: EnumRepeatType;
  reasonType?: EnumReasonType;
  spotBlockGuid?: string;
  blockedDetails?: SpotBlock;
  notes?: String;
}

export interface IDailySpotAggregationMap {
  spotGuid: string;
  friendlySpotId: string;
}

export interface IPropertyAggregationMap {
  propertyGuid: string;
  propertyName: string;
  openTimeString: string;
  closeTimeString: string;
  zoneInfo: any[];
  isYardiProperty: boolean;
  yardiRedirectURL: string;
}

export interface PropertyBookings {
  spots: IDailySpotAggregationMap[];
  allSpots: IDailySpotAggregationMap[];
  bookings: IDailySpotAggregation[];
}

export interface IRepeat {
  repeatType: EnumRepeatType;
  repeatName: string;
}

export enum EnumRepeatType {
  Dont = 0,
  Daily,
  Weekly,
  Monthly,
}

export enum EnumReasonType {
  Event = 1,
  Maintenance,
  visitorUse,
  Other,
}

export interface IBlockReason {
  reasonType: EnumReasonType;
  reasonName: string;
}

export interface IBlockSpot {
  spotGuid: string;
  friendlySpotId: string;
}

export interface SpotBlockModel {
  spotBlockDetails: SpotBlock;
  spotGuids: string[];
}

export interface SpotBlock {
  spotBlockGuid?: string;
  startDate: Date;
  startTime: string;
  endTime: string;
  repeatType: number;
  repeatEndDate?: Date;
  metadata: string;
  spotGuids: string[];
  overrideBlocking?: boolean;
  propertyGuid?: string;
}

export enum EnumCalendarPosition {
  Top = 1,
  Bottom,
}

export interface ISpotBlockMetadata {
  r: number; //Reason
  dred?: Date; //Date range end date
  med?: Date; // Month end date
  w?: number[]; //week
}

export interface IReservationChunk {
  startTime: Date;
  endTime: Date;
  spotGuid: string;
  reservationGuid: string;
  friendlyReservationId: string;
  friendlySpotId: string;
}

export interface IBookingDetailInfo {
  selectedProperty: IPropertyBasics | null;
  bookingType: number;
  monthlyBookingType: number;
  date: DateRange;
  startTime: IBlockTime | null;
  endTime: IBlockTime | null;
  selectedMonth: Date | null;
  selectedZone: IPreZoneSpot | null;
  selectedSpot: IPreZoneSpot | null;
  userDetail: IUserSearchModel;
  vehicleDetail: IVehicle | null;
  totalPrice?: string;
  serviceFeePrice?: string;
  totalPriceWithServiceFeeTax?: string;
  taxPrice?: string;
  preZoneSpot: IPreZoneSpot[];
  reservation?: IReservation;
  selectedNewSpot?: ISpotAvailable | null;
  selectedNewZone?: IPreZoneSpot | null;
  nearBySpots?: INearBySpots;
  fullPrice?: string;
  changedSpotGuid?: string;
  selectedModifyMonth: Date | null;
}

export interface ISearch {
  value: string;
  label: string;
}

export interface IUserSearchModel {
  parkerEncodeGuid: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  isExistingUser: boolean;
  parkerGuid?: string;
}

export interface IPreZoneSpotRequest {
  propertyGuid: string;
  startDate: string;
  endDate: string;
}

export interface IMonthlyPreZoneSpotRequest {
  propertyGuid: string;
  searchStartDate: string;
  monthlyRateTypeId: number;
}

export interface IPreZoneSpot {
  propertyGuid: string;
  spotGuid: string;
  friendlySpotId: string;
  otherFeatures: string;
  weeklyScheduleString: string | null;
  isBooked: boolean;
  zoneGuid: string;
  zoneName: string;
}

export interface IVehicle {
  vehicleGuid: string;
  vehicleOwnerGuid: string;
  licensePlate: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleColor: string;
}

export enum MonthlyBookingType {
  "24/7" = 1,
  Commuter,
  "Evenings and Weekends",
}

export enum BookingType {
  "Hourly/Daily" = 1,
  "Monthly",
  "Lot Management",
  "Cash",
  "Online",
}

export enum EnumCalendarOption {
  None = 0,
  Today,
  Yesterday,
  Last7Days,
  Last14Days,
  Last30Days,
  Last90Days,
  Last365Days,
  ThisMonth,
  ThisYear,
  PreviousMonth,
  PreviousYear,
}
