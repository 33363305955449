import React from 'react';
import dashboardImg from '../../Images/Dashbaord.png';
import { Grid, Select, MenuItem, IconButton, Tabs, Tab } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import { Bar } from 'react-chartjs-2';
import ActiveSpotList from './ActiveSpotList';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CallMissedIcon from '@material-ui/icons/CallMissed';
import SpaceBarIcon from '@material-ui/icons/SpaceBar';
import UpcomingBooking from './UpcomingBooking';
import ReservationHistory from './ReservationHistory';
import { IPropertyInformation, IPropertyStatus } from './Interfaces';
import PropertyService from '../../Services/PropertyService';
import { ShowAlert } from '../../Common/ShowAlert';

class PropertiesOverview extends React.Component<IPropertiesOverviewProps, IPropertiesOverviewState> {
    private _propertyService: PropertyService;

    constructor(props: IPropertiesOverviewProps) {
        super(props);

        this.state = {
            propertyID: this.props.match.params.propertyID,
            selectedTabIndex: 0,
            propertyInformation: {
                propertyAbout: '',
                propertyAddress1: '',
                propertyAddress2: '',
                propertyCity: '',
                propertyCompanyName: '',
                propertyEarnedRevenue: 0,
                propertyGuid: '',
                propertyMaxPrice: 0,
                propertyMinPrice: 0,
                propertyName: '',
                propertyOwnerName: '',
                propertyPaidOut: 0,
                propertySpotTotal: 0,
                propertySpotTotalBooked: 0,
                propertySpotTotalBuilding: 0,
                propertySpotTotalGRYD: 0,
                propertySpotTotalUpcoming: 0,
                propertySpotWeeklyCount: {
                    fridayCount: 18,
                    mondayCount: 20,
                    saturdayCount: 18,
                    sundayCount: 40,
                    thursdayCount: 18,
                    tuesdayCount: 8,
                    wednesdayCount: 18
                },
                propertyYTDRevenue: 0,
                propertyStatusId: 0,
            },
            graphData: [0, 0, 0, 0, 0, 0, 0]
        }

        this._propertyService = new PropertyService();
    }

    public componentDidMount = () => {

        this._propertyService.getPropertyInfo(this.state.propertyID).then(response => {
            if (response.ok) {
                response.json().then(p => {
                    if (p) {
                        this.state.propertyInformation.propertyName = p[0].propertyName;
                        this.state.propertyInformation.propertyOwnerName = p[0].propertyOwnerName;
                        this.state.propertyInformation.propertyCompanyName = p[0].propertyCompanyName;
                        this.state.propertyInformation.propertyAddress1 = p[0].propertyAddress1 + (p.propertyAddress2 ? " " + p.propertyAddress2 : '');
                        this.state.propertyInformation.propertyCity = p[0].propertyCity;

                        this.state.propertyInformation.propertyStatusId = p[0].propertyStatusId;

                        this.setState({
                            propertyInformation: this.state.propertyInformation
                        });
                    }
                })
            }
            /*else {
                if (response.status === 401) {
                    this.accessTokenExpired();
                } else {
                    response.text().then(resp => {
                        console.log(response);
                    });
                    ShowAlert("", 'There was an error processing a your request.', "error");
                }
            }*/
        });

        this._propertyService.getGetPropertyDashboardInformation(this.state.propertyID).then(response => {
            if (response.ok) {
                response.json().then(p => {
                    if (p) {
                        this.state.propertyInformation.propertyEarnedRevenue = p[0].propertyEarnedRevenue;
                        this.state.propertyInformation.propertyMaxPrice = p[0].propertyMaxPrice;
                        this.state.propertyInformation.propertyMinPrice = p[0].propertyMinPrice;
                        this.state.propertyInformation.propertyPaidOut = p[0].propertyPaidOut;
                        this.state.propertyInformation.propertySpotTotal = p[0].propertySpotTotal;
                        this.state.propertyInformation.propertySpotTotalBooked = p[0].propertySpotTotalBooked;
                        this.state.propertyInformation.propertySpotTotalBuilding = p[0].propertySpotTotalBuilding;
                        this.state.propertyInformation.propertySpotTotalGRYD = p[0].propertySpotTotalGRYD;
                        this.state.propertyInformation.propertySpotTotalUpcoming = p[0].propertySpotTotalUpcoming;
                        this.state.propertyInformation.propertySpotWeeklyCount = p[0].propertySpotWeeklyCount;
                        this.state.propertyInformation.propertyYTDRevenue = p[0].propertyYTDRevenue;

                        let bar_: number[] = [];

                        bar_.concat(p[0].propertySpotWeeklyCount.mondayCount);
                        bar_.concat(p[0].propertySpotWeeklyCount.tuesdayCount);
                        bar_.concat(p[0].propertySpotWeeklyCount.wednesdayCount);
                        bar_.concat(p[0].propertySpotWeeklyCount.thursdayCount);
                        bar_.concat(p[0].propertySpotWeeklyCount.fridayCount);
                        bar_.concat(p[0].propertySpotWeeklyCount.saturdayCount);
                        bar_.concat(p[0].propertySpotWeeklyCount.saturdayCount);

                        this.setState({
                            propertyInformation: this.state.propertyInformation,
                            graphData: bar_
                        });
                    }
                })
            }
            /*else {
                if (response.status === 401) {
                    this.accessTokenExpired();
                } else {
                    response.text().then(resp => {
                        console.log(response);
                    });
                    ShowAlert("", 'There was an error processing a your request.', "error");
                }
            }*/
        });



        /*var _localData = localStorage.getItem('selectedRow');

        if (_localData != null && _localData !== '') {
            var _data = JSON.parse(_localData);
            this.state.propertyInformation.PropertyName = _data.propertyName;
            this.state.propertyInformation.PropertyOwnerName = _data.propertyOwnerName;
            this.state.propertyInformation.PropertyCompanyName = _data.propertyCompanyName;
            this.state.propertyInformation.PropertyAddress1 = _data.propertyAddress1 + (_data.propertyAddress2 ? " " + _data.propertyAddress2 : '');
            this.state.propertyInformation.PropertyAddress2 = _data.propertyCity;

            this.setState({
                propertyInformation: this.state.propertyInformation
            });
        }*/

    }

    public render() {
        const barData = {
            labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
            datasets: [
                {
                    label: 'Activity',
                    backgroundColor: 'rgba(0, 175, 133,1)',
                    borderColor: 'rgba(0, 175, 133,1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(0, 175, 133, 0.7)',
                    hoverBorderColor: 'rgba(0, 175, 133,0.7)',
                    maxBarThickness: 20,
                    minBarLength: 2,
                    data: this.state.graphData
                    // data: [20, 8, 18, 18, 18, 18, 40]
                }
            ]
        };
        return (
            <div className="m-3 data-grid-container property-dashboard-container" >
                <Grid container className="property-overview-header">
                    <Grid item xs>
                        {/* <h1 className="list-header">
                            Lanark Gardens Overview
                        </h1>
                        <div className="property-location"><LocationOnIcon /> 465/495/525 Lanark Street, Winnipeg &middot; Towers Reality &middot; Towers Reality Group</div> */}
                        <h1 className="list-header">
                            {this.state.propertyInformation.propertyName}
                        </h1>
                        <div className="property-location"><LocationOnIcon /> <span>{this.state.propertyInformation.propertyAddress1}</span>, <span>{this.state.propertyInformation.propertyCity}</span> &middot; <span>{this.state.propertyInformation.propertyOwnerName}</span> &middot; <span>{this.state.propertyInformation.propertyCompanyName}</span></div>
                    </Grid>
                    <Grid item>
                        <Select
                            value={this.state.propertyInformation.propertyStatusId}
                            inputProps={{ 'aria-label': 'Without label' }}
                            className="property-state"
                            onChange={(event: any) => {
                                this.onChangePropertyStatus(event.target.value)
                            }}
                        >
                            <MenuItem value={1}><div className={'list-status dropdown-status active'}></div> Active</MenuItem>
                            <MenuItem value={2}><div className={'list-status dropdown-status inactive'}></div> Inactive</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                <div>
                    <Grid container className="dashboard-box-container">
                        <Grid item xs={4} sm={4}>
                            <Grid container className="dashboard-card">
                                <Grid item xs={12} className="card-title-container">
                                    <IconButton aria-label="Back">
                                        <LocalOfferOutlinedIcon />
                                    </IconButton> <span className="dashboard-card-title">Spot Price</span></Grid>
                                <Grid item xs={6} className="dashboard-card-row2">
                                    <span className="dashboard-card-subtitle">From</span>
                                    <span className="dashboard-card-title2">${this.state.propertyInformation.propertyMinPrice.toFixed(2)}</span>
                                </Grid>
                                <Grid item xs={6} className="dashboard-card-row2">
                                    <span className="dashboard-card-subtitle">To</span>
                                    <span className="dashboard-card-title2">${this.state.propertyInformation.propertyMaxPrice.toFixed(2)}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <Grid container className="dashboard-card">
                                <Grid item xs={12} className="card-title-container">
                                    <IconButton aria-label="Back">
                                        <TrendingUpIcon />
                                    </IconButton> <span className="dashboard-card-title">Earned Revenue</span></Grid>
                                <Grid item xs={12} className="dashboard-card-row2">
                                    <span className="dashboard-card-subtitle">Last Month</span>
                                    <span className="dashboard-card-title2">
                                        {this.convertToDollar(this.state.propertyInformation.propertyEarnedRevenue)}
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <Grid container className="dashboard-card">
                                <Grid item xs={12} className="card-title-container">
                                    <IconButton aria-label="Back">
                                        <AttachMoneyIcon />
                                    </IconButton> <span className="dashboard-card-title">YTD Revenue</span></Grid>
                                <Grid item xs={12} className="dashboard-card-row2">
                                    <span className="dashboard-card-subtitle">IN GRYD</span>
                                    <span className="dashboard-card-title2">
                                        {this.convertToDollar(this.state.propertyInformation.propertyYTDRevenue)}
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <Grid container className="dashboard-card">
                                <Grid item xs={12} className="card-title-container">
                                    <IconButton aria-label="Back">
                                        <CallMissedIcon />
                                    </IconButton> <span className="dashboard-card-title">Paid Out</span></Grid>
                                <Grid item xs={12} className="dashboard-card-row2">
                                    <span className="dashboard-card-subtitle">LAST MONTH</span>
                                    <span className="dashboard-card-title2">
                                        {this.convertToDollar(this.state.propertyInformation.propertyPaidOut)}
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <Grid container className="dashboard-card">
                                <Grid item xs={12} className="card-title-container">
                                    <IconButton aria-label="Back">
                                        <SpaceBarIcon />
                                    </IconButton> <span className="dashboard-card-title">Spots Total</span></Grid>
                                <Grid item xs={12} className="dashboard-card-row2">
                                    <span className="dashboard-card-subtitle">GRYD</span>
                                    <span className="dashboard-card-title2">
                                        {this.state.propertyInformation.propertySpotTotalGRYD}
                                    </span>
                                </Grid>
                                {/* <Grid item xs={6} className="dashboard-card-row2">
                                    <span className="dashboard-card-subtitle">BUILDING</span>
                                    <span className="dashboard-card-title2">
                                        {this.state.propertyInformation.propertySpotTotalBuilding}
                                    </span>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <Grid container className="dashboard-card">
                                <Grid item xs={12} className="card-title-container">
                                    <IconButton aria-label="Back">
                                        <SpaceBarIcon />
                                    </IconButton> <span className="dashboard-card-title">Spots Activity</span></Grid>
                                <Grid item xs={6} className="dashboard-card-row2">
                                    <span className="dashboard-card-subtitle">BOOKED</span>
                                    <span className="dashboard-card-title2">{this.state.propertyInformation.propertySpotTotalBooked}/{this.state.propertyInformation.propertySpotTotalGRYD}</span>
                                </Grid>
                                <Grid item xs={6} className="dashboard-card-row2">
                                    <span className="dashboard-card-subtitle">UPCOMING</span>
                                    <span className="dashboard-card-title2">{this.state.propertyInformation.propertySpotTotalUpcoming}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className="dashboard-white-container bar-char-container">
                        <Grid container>
                            <Grid item xs>
                                <h2 className="h2">Activity</h2>
                            </Grid>
                            <Grid item><span className="bar-chart-text">7 day rolling</span></Grid>
                        </Grid>
                        <Bar
                            data={barData}
                            width={10}
                            height={400}
                            options={{
                                maintainAspectRatio: false
                            }}
                        />
                    </div>
                    <div className="dashboard-tabs-container dashboad-table">
                        <Tabs
                            value={this.state.selectedTabIndex}
                            onChange={(e_: any, tabIndex_: number) => {
                                this.setState({
                                    selectedTabIndex: tabIndex_
                                });
                            }}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="standard"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Active Spots" />
                            <Tab label="Upcoming Bookings" />
                            <Tab label="Reservation History" />
                        </Tabs>
                        {
                            this.loadTableGrid()
                        }
                    </div>
                </div>
            </div>
        )
    }

    private loadTableGrid() {
        if (this.state.selectedTabIndex == 0) {
            return (
                <ActiveSpotList propertyID={this.state.propertyID} />
            )
        } else if (this.state.selectedTabIndex == 1) {
            return (
                <UpcomingBooking propertyID={this.state.propertyID} />
            )
        } else if (this.state.selectedTabIndex == 2) {
            return (
                <ReservationHistory propertyID={this.state.propertyID} />
            )
        }
    }

    private accessTokenExpired() {
        localStorage.clear();
        window.location.href = '/';
    }

    private convertToDollar(amount: number) {
        return "$" + amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    private onChangePropertyStatus(value: any) {
        let _listProperty: IPropertyStatus[] = [{
            propertyGuid: this.state.propertyID,
            propertyStatusId: value
        }];
        this._propertyService.udatePropertyStatus(_listProperty)
            .then(response => {
                if (response.ok) {
                    response.json().then(p => {
                        if (p) {
                            this.state.propertyInformation.propertyStatusId = value;

                            this.setState({
                                propertyInformation: this.state.propertyInformation
                            });
                        }
                    })
                }
                /*else {
                    if (response.status === 401) {
                        this.accessTokenExpired();
                    } else {
                        response.text().then(resp => {
                            console.log(response);
                        });
                        ShowAlert("", 'There was an error processing a your request.', "error");
                    }
                }*/
            })

    }
}

interface IPropertiesOverviewProps {
    match: {
        params: {
            propertyID: string
        }
    }
}
interface IPropertiesOverviewState {
    propertyID: string,
    selectedTabIndex: number,
    propertyInformation: IPropertyInformation,
    graphData: number[]
}

export default PropertiesOverview;