import React, { useState } from 'react';
import EnabledMarker from "../../Images/EnabledMarker.png";
import DisabledMarker from "../../Images/DisabledMarker.png";
import OffenceImg from "../../Images/offence-img.png";
import TrafficImg from "../../Images/traffic-img.png";


export const Markers = (props: IMarkerProps) => {
    const [text, setText] = useState("");

    const bindImage = () => {
        const { isOffencesArea, isTrafficArea, disabled } = props;
        let image = "";
        if (isOffencesArea)
            image = OffenceImg;
        else if (isTrafficArea)
            image = TrafficImg;
        else if (disabled)
            image = DisabledMarker;
        else
            image = EnabledMarker;

        return image;
    }

    return <div className='map-marker-holder'>
        <img
            className="map-marker-image"
            style={{ zIndex: props.disabled ? 1 : 2 }}
            src={bindImage()}
            onMouseEnter={() => {
                setText(props.text);
            }}
            onMouseLeave={() => {
                setText("");
            }}
            alt="mark-div"
            onClick={() => {
                props.onSelect(props.data);
            }}
        />
        <span className="map-marker-text">{text}</span>
    </div>
}


interface IMarkerProps {
    data: any;
    lat: number;
    lng: number;
    key: number,
    text: string,
    onSelect: (data: any) => void;
    disabled: boolean;
    isOffencesArea?: boolean;
    isTrafficArea?: boolean;
}