import * as React from 'react';
import {createStyles, lighten, makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import {ConfigService} from '../Services/ConfigService';
import {ShowAlert, ShowAlertwithConfirm} from '../Common/ShowAlert';
import {
    Chip,
    Drawer,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControl,
    FormControlLabel,
    InputBase,
    MenuItem,
    Radio,
    RadioGroup,
    Select
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import map from '../Images/map.png';
import activity from '../Images/activity.png';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import PriceRangeSelector from '../Components/PriceRangeSelector';
import ClearIcon from '@material-ui/icons/Clear';
import ObjectHelper from '../Helpers/ObjectHelper';
import BaseService from '../Services/BaseService';
import ArrayHelper from '../Helpers/ArrayHelper';
import EditIcon from '@material-ui/icons/Edit';
import {PreserveFilters} from '../Common/PreserveFilters';
import {CheckFeaturePermission, CheckScreenPermission} from '../Common/CheckScreenPermission';
import CompanyService from '../Services/CompanyService';
import StringHelper from '../Helpers/StringHelper';
import ProgressTop from '../Components/ProgressTop';

interface Data {
  companyGuid: string,
  companyName: string,
  primaryContact: string,
  companyOwnerName: string,
  companyOwnerGuid: string,
  companyContactGuid: string,
  propertyName: string,
  propertyGuid: string,
  propertyNewGuid: string,
  companyAddress1: string,
  companyAddress2: string,
  companyCity: string,
  companyState: string,
  companyCountry: string,
  companyZipCode: string,
  companyStatusId: number,
  propertyAddress1: string,
  propertyAddress2: string,
  propertyCity: string,
  propertyState: string,
  propertyCountry: string,
  propertyZipCode: string,
  propertyQuantity: number,
  revenueYTD: number,
  RevenueText: string,
  manager: ManagerList[]
}

interface UsersList {
  userGuid: string,
  userName: string
}

interface managerGuid {
  managerGuid: string,
  managerName: string
}

interface ManagerList {
  managerGuid: string,
  managerName: string
}

interface OwnerList {
  propertyOwnerGuid: string,
  propertyOwnerName: string
}

interface CompanyList {
  Name: string,
  Address: string;
  City: string;
  Owner: string;
  Manager: string;
  Spot: string;
  Revenue: string;
}

interface Property {
  propertyGuid: string;
  propertyName: string;
}

interface FilterOptionsModel {
  AvailableCities: string[];
  City: string[];
  AvailableCompany: string[];
  Company: string[];
  Revenue: RangeModel;
  SlotsCorrelation: string;
  DateRangeSelected: string,
  FilterApplied: boolean
}

interface RangeModel {
  start: number,
  end: number
}

interface CompanyStatus {
  companyGuid: string,
  CompanyStatusId: number
}

let selectedRows: Data[] = [];
let setSelectedCallback: (d_: Data[]) => void;
let setMultiSelectedCallback: (d_: Data[]) => void;


function fetchCompanyDropDownList() {
  return ConfigService.getBaseUrl()
    .then(url => {
      return BaseService.sendRequest(url + 'company/get', {
        method: 'get',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      })
      //.then(response => response.json());
    });
}

function fetchUserList() {
  return ConfigService.getBaseUrl()
    .then(url => {
      return BaseService.sendRequest(url + 'user/userdropdownlist', {
        method: 'get',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      })
      //.then(response => response.json());
    });
}

function fetchPropertyList() {
  //Old
  //user/getpropertydropdown

  //property/names
  return ConfigService.getBaseUrl()
    .then(url => {
      return BaseService.sendRequest(url + 'property/names', {
        method: 'get',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      })
      //.then(response => response.json());
    });
}

function fetchCompanyList() {
  return ConfigService.getBaseUrl()
    .then(url => {
      return BaseService.sendRequest(url + 'company/get', {
        method: 'get',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      })
      //.then(response => response.json());
    });
}

async function _getAllCompanies(contentParser_: (companies_: Data[]) => Data[], onProgress_: (companies_: Data[], progress_: number) => void) {
  let remainder = "";

  return BaseService.asyncResponse<Data>(
    CompanyService.getCompanyList(),
    (content_, contentLength, receivedLength) => {
      let companies: Data[] = [];

      if (content_) {
        content_ = remainder + content_;

        if (content_ && contentLength != receivedLength) {
          let lastIndex = content_.lastIndexOf(",{\"companyGuid\"");
          if (lastIndex == -1) {
            remainder = content_;
            // content_ = "";
          } else {
            remainder = "[" + StringHelper.trimStart(content_.substring(lastIndex), ',');
            content_ = content_.substring(0, lastIndex) + "]";
          }
        }

        if (content_) {
          try {
            companies = JSON.parse(content_);
          } catch (e) {
          }
        }
      }

      return contentParser_(companies);
    }, onProgress_);
}

function udateCompanyStatus(record: CompanyStatus[]) {
  var postData = record;

  return ConfigService.getBaseUrl()
    .then(url => {
      return BaseService.sendRequest(url + 'company/changestatus', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
      })
      //.then(response => response.json());
    });
}


function addNewCompany(record: Data) {
  var postData = {
    "companyName": record.companyName,
    "primaryContact": record.primaryContact,
    "companyOwnerName": record.companyOwnerName,
    "companyOwnerGuid": record.companyOwnerGuid,
    "propertyName": record.propertyName != "" ? record.propertyName : null,
    "propertyGuid": record.propertyGuid != "" ? record.propertyGuid : null,
    "companyAddress1": record.companyAddress1,
    "companyAddress2": record.companyAddress2,
    "companyCity": record.companyCity,
    "companyState": record.companyState,
    "companyCountry": record.companyCountry,
    "companyZipCode": record.companyZipCode,
    "companyStatusId": 1,
    "propertyAddress1": record.propertyAddress1,
    "propertyAddress2": record.propertyAddress2,
    "propertyCity": record.propertyCity,
    "propertyState": record.propertyState,
    "propertyCountry": record.propertyCountry,
    "propertyZipCode": record.propertyZipCode,
    "manager": record.manager
  };

  return ConfigService.getBaseUrl()
    .then(url => {
      return BaseService.sendRequest(url + 'company/insertupdate', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
      })
      //.then(response => response.json());
    });
}

function updateCompany(record: Data) {

  var postData = {
    "companyGuid": record.companyGuid,
    "companyName": record.companyName,
    "primaryContact": record.primaryContact,
    "companyOwnerName": record.companyOwnerName,
    "companyOwnerGuid": record.companyOwnerGuid,
    "propertyName": record.propertyName != "" ? record.propertyName : null,
    "propertyGuid": record.propertyGuid != "" ? record.propertyGuid : null,
    "propertyNewGuid": record.propertyNewGuid ? record.propertyNewGuid : null,
    "companyAddress1": record.companyAddress1,
    "companyAddress2": record.companyAddress2,
    "companyCity": record.companyCity,
    "companyState": record.companyState,
    "companyCountry": record.companyCountry,
    "companyZipCode": record.companyZipCode,
    "companyStatusId": 1,
    "propertyAddress1": record.propertyAddress1,
    "propertyAddress2": record.propertyAddress2,
    "propertyCity": record.propertyCity,
    "propertyState": record.propertyState,
    "propertyCountry": record.propertyCountry,
    "propertyZipCode": record.propertyZipCode,
    "manager": record.manager
  };

  return ConfigService.getBaseUrl()
    .then(url => {
      return BaseService.sendRequest(url + 'company/insertupdate', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
      })
      //.then(response => response.json());
    });
}

type Order = 'asc' | 'desc';


function filterTable(tableRows: Data[], filterValue: FilterOptionsModel) {
  let filteredItems: Data[] = [];
  if (PreserveFilters.readPreservedFilter('managementCompanies')) {
    filterValue = PreserveFilters.readPreservedFilter('managementCompanies')
  }
  // if(PreserveFilters.readPreservedFilter('managementCompanies')){
  //     // console.log(PreserveFilters.readPreservedFilter('managementCompanies').Company);
  //     setFilteredCompanies(PreserveFilters.readPreservedFilter('managementCompanies').Company)
  // }

  if (filterValue.FilterApplied) {
    tableRows.map((v, i, a) => {
      let isCityMatched: boolean = false;
      let isCompanyMatched: boolean = false;
      let isRevenueMatched: boolean = false;

      if (filterValue.AvailableCities.length !== filterValue.City.length && filterValue.City.length > 0) {
        for (var c = 0; c < filterValue.City.length; c++) {
          if (filterValue.City[c] === v.propertyCity) {
            isCityMatched = true;
            break;
          } else if (filterValue.City[c] === 'Blank' && (v.propertyCity === '' || v.propertyCity === null)) {
            isCityMatched = true;
            break;
          }
        }
      } else {
        isCityMatched = true;
      }

      if (filterValue.Company[0] !== 'All' && filterValue.Company.length > 0) {
        for (var c = 0; c < filterValue.Company.length; c++) {
          if (filterValue.Company[c] === v.companyName) {
            isCompanyMatched = true;
            break;
          }
        }
      } else {
        isCompanyMatched = true;
      }

      if (filterValue.Revenue.start !== 0 || filterValue.Revenue.end !== 0) {
        if (filterValue.Revenue.end !== 0) {
          if (v.revenueYTD >= filterValue.Revenue.start && v.revenueYTD <= filterValue.Revenue.end) {
            isRevenueMatched = true;
          }
        } else {
          if (v.revenueYTD >= filterValue.Revenue.start) {
            isRevenueMatched = true;
          }
        }

      } else {
        isRevenueMatched = true;
      }

      if (isCityMatched && isCompanyMatched && isRevenueMatched) {
        filteredItems.push(v);
      }
    });

    return filteredItems;
  }

  return tableRows;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {id: 'companyStatusId', numeric: false, disablePadding: true, label: 'STATUS'},
  {id: 'companyName', numeric: false, disablePadding: true, label: 'MANAGEMENT COMPANY'},
  {id: 'primaryContact', numeric: false, disablePadding: true, label: 'PRIMARY CONTACT'},
  // { id: 'propertyName', numeric: false, disablePadding: true, label: 'PROP NAME' },
  // { id: 'propertyCity', numeric: false, disablePadding: true, label: 'CITY' },
  // { id: 'propertyQuantity', numeric: false, disablePadding: true, label: '# SPOTS' }
];


interface EnhancedTableHeadProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const {classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.thead + ' th-list-thead'}>
      <TableRow>
        {/* <TableCell padding="none" className={classes.headerStyleCheckbox + ' th-list-head'}>
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                        className="check-box-table"
                    />

                </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : (headCell.id === 'propertyQuantity' || headCell.id === 'revenueYTD') ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            className={classes.headerStyle}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell padding="none" className={classes.headerStyleMore + ' th-list-head'}>
          <IconButton aria-label="More" disabled={numSelected === 0}>
            <MoreHorizIcon/>
          </IconButton>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
    toolheader: {
      padding: '0px 10px',
      marginBottom: '10px'
    },
    toolContainer: {
      textAlign: 'right',
      padding: '0px'
    },
    toolButton: {
      marginLeft: '5px',
    }
  }),
);

interface EnhancedTableToolbarProps {
  numSelected: number;
  onSearchChange: (searchText: string) => void,
  buttonState: (f_: boolean, a_: boolean) => void;
  isFiltered: boolean;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const {numSelected, onSearchChange, buttonState, isFiltered} = props;
  let key = window.localStorage.getItem('key')
  const [searchedTextState, setSearchedTextState] = React.useState<string>(key ? key : '');
  const [searchedTextFocused, setSearchedTextFocused] = React.useState<boolean>(key ? true : false);
  const [searchInput, setSearchInput] = React.useState<null | HTMLElement>(null);

  const enableSearch = (event: any) => {
    if (searchInput) {
      searchInput.focus();
    }
    setSearchedTextFocused(true);
  }

  const clearSearch = (event: any) => {
    if (searchInput) {
      searchInput.focus();
    }
    setSearchedTextState('');
    if (props.onSearchChange) {
      props.onSearchChange('');
    }
  }

  const handleSearchTextChange = (event: any) => {
    var _targetValue = event.target.value;
    setSearchedTextState(_targetValue);
    if (props.onSearchChange) {
      props.onSearchChange(_targetValue);
    }
  }

  const handleSearchTextBlur = (event: any) => {
    var _targetValue = event.target.value;

    if (_targetValue == '') {
      setSearchedTextFocused(false);
    }

  }

  const onToolButtonClick = (buttonType: string) => {
    let add_: boolean = false;
    let filter_: boolean = false;

    if (buttonType === 'add') {
      add_ = true;
    } else {
      filter_ = true;
    }
    if (props.buttonState) {
      props.buttonState(filter_, add_);
    }
  }

  return (
    <div className={classes.toolheader + ' table-list-header-fixed'}>
      <Grid container>
        <Grid item>
          <h1 className="list-header">Management Companies</h1>
        </Grid>
        <Grid item xs>
          <Grid container>
            <Grid item xs>
              <div className={classes.toolContainer}>
                {/* <Tooltip title="Search" className={classes.toolButton}>
                                    <IconButton aria-label="Search">
                                        <SearchIcon />
                                    </IconButton>
                                </Tooltip> */}
                <div
                  className={'table-search-container' + (searchedTextFocused == true ? ' table-search' : ' table-search-blur')}>
                  <Paper className={classes.root + ' table-search-input-container'}>
                    <InputBase
                      name="search"
                      className="table-search-input"
                      placeholder="Search management companies"
                      inputProps={{'aria-label': 'search management companies'}}
                      value={searchedTextState}
                      onChange={handleSearchTextChange}
                      onBlur={handleSearchTextBlur}
                      inputRef={refElm => {
                        setSearchInput(refElm)
                      }}
                    />
                    {/* <IconButton type="submit" className="material-search-button" aria-label="search">
                                        <SearchIcon />
                                    </IconButton> */}
                    {
                      searchedTextState.length > 0 ?
                        <Tooltip title="Clear" className={classes.toolButton}>
                          <IconButton type="submit" aria-label="Clear" onClick={clearSearch}>
                            <ClearIcon/>
                          </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title="Search" className={classes.toolButton}>
                          <IconButton type="submit" aria-label="Search" onClick={enableSearch}>
                            <SearchIcon/>
                          </IconButton>
                        </Tooltip>
                    }

                  </Paper>
                </div>
                <Tooltip title="Filter list" className={classes.toolButton}>
                  <IconButton aria-label="filter list" onClick={() => onToolButtonClick('filter')}>
                    <FilterListIcon color={isFiltered ? "primary" : undefined}/>
                  </IconButton>
                </Tooltip>
                {
                  CheckFeaturePermission('"add-edit-company"') &&
                    <Tooltip title="Add Management Company" className={classes.toolButton + ' add-lit-button'}>
                        <IconButton aria-label="Add Management Company" onClick={() => onToolButtonClick('add')}>
                            <AddIcon fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                }
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

interface FilteredLableProps {
  FilteredItems: FilterOptionsModel,
  onFilterLableDelete: (updatedLable: FilterOptionsModel) => void
}

const FilteredLable = (props: FilteredLableProps) => {
  let {FilteredItems, onFilterLableDelete} = props;
  if (PreserveFilters.readPreservedFilter('managementCompanies')) {
    FilteredItems = PreserveFilters.readPreservedFilter('managementCompanies')
  }
  let isRevenueFilterApplied: boolean = false;
  let revenueFilteredText: string = '';

  const convertToDollar = (amount: number) => {
    return "$" + amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  if (FilteredItems.Revenue.start !== 0 || FilteredItems.Revenue.end !== 0) {
    isRevenueFilterApplied = true;

    if (FilteredItems.Revenue.start === 0 && FilteredItems.Revenue.end === 50000) {
      revenueFilteredText = "Less than " + convertToDollar(FilteredItems.Revenue.end);
    } else if (FilteredItems.Revenue.start === 50000 && FilteredItems.Revenue.end === 0) {
      revenueFilteredText = "More than " + convertToDollar(FilteredItems.Revenue.start);
    } else if (FilteredItems.Revenue.start === 100000 && FilteredItems.Revenue.end === 0) {
      revenueFilteredText = "More than " + convertToDollar(FilteredItems.Revenue.start);
    } else {
      revenueFilteredText = convertToDollar(FilteredItems.Revenue.start) + ' - ' + convertToDollar(FilteredItems.Revenue.end);
    }
  }


  const handleDeleteChip = (lable: string, columnName: string) => {
    let updatedFilterOption: FilterOptionsModel = FilteredItems;

    if (columnName === 'city') {
      const currentIndex = updatedFilterOption.City.indexOf(lable);
      const newChecked = [...updatedFilterOption.City];

      newChecked.splice(currentIndex, 1);

      updatedFilterOption.City = newChecked;
    } else if (columnName === 'company') {
      const currentIndex = updatedFilterOption.Company.indexOf(lable);
      const newChecked = [...updatedFilterOption.Company];

      newChecked.splice(currentIndex, 1);

      updatedFilterOption.Company = newChecked;
    } else if (columnName === 'revenue') {
      let emptyRange: RangeModel = {
        start: 0,
        end: 0
      }
      updatedFilterOption.Revenue = emptyRange;
    }

    if (props.onFilterLableDelete) {
      props.onFilterLableDelete(updatedFilterOption);
    }
  }

  return (
    <div className="chip-container">
      {
        FilteredItems.City.map((item, index) => (
          <Chip
            key={item + index}
            label={item}
            className="chip-lable"
            onDelete={() => handleDeleteChip(item, 'city')}
            variant="outlined"
          />
        ))
      }

      {
        FilteredItems.Company.map((item, index) => (
          <Chip
            key={item + index}
            label={item}
            className="chip-lable"
            onDelete={() => handleDeleteChip(item, 'company')}
            variant="outlined"
          />
        ))
      }

      {
        isRevenueFilterApplied ? <Chip
          label={revenueFilteredText}
          className="chip-lable"
          onDelete={() => handleDeleteChip('', 'revenue')}
          variant="outlined"
        /> : null
      }

      {
        FilteredItems.SlotsCorrelation !== '' ? <Chip
          label={FilteredItems.SlotsCorrelation}
          className="chip-lable"
          // onClick={handleClick}
          // onDelete={handleDelete}
          variant="outlined"
        /> : null
      }

      {
        FilteredItems.DateRangeSelected !== '' ? <Chip
          label={FilteredItems.DateRangeSelected}
          className="chip-lable"
          // onClick={handleClick}
          // onDelete={handleDelete}
          variant="outlined"
        /> : null
      }
    </div>
  )

}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // width: '100%',
      padding: '0px 10px',
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden'
    },
    rightMenu: {
      width: '50%',
      maxWidth: '400px',
      background: '#000',
    },
    closedMenu: {
      marginRight: '-400px'
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      // flex: 1,
      display: 'flex',
      flexDirection: 'column',
      border: '0px solid #000',
      boxShadow: 'none',
      margin: '0px !important',
      borderRadius: '0px',
      background: 'none'
    },
    table: {
      minWidth: 750,
      borderCollapse: 'collapse'
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    thead: {
      borderBottom: '0px solid #000',
    },
    headerStyle: {
      fontWeight: 'bold',
      background: '#ffffff',
      padding: '8px 5px !important',
      color: '#8a959e !important'
    },
    headerStyleCheckbox: {
      fontWeight: 'bold',
      background: '#ffffff',
      padding: '0px 5px !important'
    },
    headerStyleMore: {
      fontWeight: 'bold',
      background: '#ffffff',
      padding: '0px !important'
    },
    tableContainer: {
      flex: 1,
      padding: '0px',
      width: 'auto',
      // maxHeight: '500px',
      background: '#ffffff',
      overflow: 'auto'
    },
    rowStyle: {
      height: 'auto !important'
    },
    bodyCell: {
      padding: '10px 10px !important'
    }
  }),
);

interface EnhancedTableProps {
  dataLoaded: boolean,
  data: Data[],
  usersList: UsersList[],
  managerList: ManagerList[],
  ownerList: OwnerList[],
  propertyList: Property[],
  loaderPercentage: number;
  isCompanyLoaded?: boolean;
}


export default class EnhancedTable extends React.Component<{}, EnhancedTableProps> {
  constructor(props: {}) {
    super(props);
    this.state = {
      data: [],
      dataLoaded: false,
      usersList: [],
      managerList: [],
      ownerList: [],
      propertyList: [],
      loaderPercentage: 0
    };
  }


  async componentDidMount() {
    var isAccessible = await CheckScreenPermission("user-interface-companies");

    if (!isAccessible) return false;

    this.loadCompanies();

    /*fetchCompanyList().then(response => {
        if (response.ok) {
            response.text().then(resp => {
                let rowItems: Data[] = JSON.parse(resp);

                let mappedItems = rowItems.map(r => {
                    let _randomRevenueAmount = this.setRandomRevenue();
                    return {
                        companyGuid: r.companyGuid,
                        companyName: r.companyName ? r.companyName : '',
                        primaryContact: r.primaryContact ? r.primaryContact : '',
                        companyOwnerName: r.companyOwnerName,
                        companyOwnerGuid: r.companyOwnerGuid,
                        companyContactGuid: r.companyContactGuid,
                        propertyName: r.propertyName ? r.propertyName : '',
                        propertyGuid: r.propertyGuid,
                        propertyNewGuid: r.propertyNewGuid,
                        companyAddress1: r.companyAddress1,
                        companyAddress2: r.companyAddress2,
                        companyCity: r.companyCity,
                        companyState: r.companyState,
                        companyCountry: r.companyCountry,
                        companyZipCode: r.companyZipCode,
                        companyStatusId: r.companyStatusId,
                        propertyAddress1: r.propertyAddress1,
                        propertyAddress2: r.propertyAddress2,
                        propertyCity: r.propertyCity ? r.propertyCity : '',
                        propertyState: r.propertyState,
                        propertyCountry: r.propertyCountry,
                        propertyZipCode: r.propertyZipCode,
                        propertyQuantity: r.propertyQuantity,
                        revenueYTD: r.revenueYTD,
                        RevenueText: this.convertToDollar(r.revenueYTD),
                        manager: r.manager,
                        rawData: r
                    };
                });

                this.setState(
                    {
                        data: mappedItems,
                        dataLoaded: true,
                    }
                );
            });
        }
    });*/


    /*fetchCompanyDropDownList().then(response => {
        let companyItems: any[] = response;

        console.log(companyItems);

        let mangerItems = companyItems.map(m => {
            return {
                companyGuid: m.companyGuid,
                companyName: m.companyName
            }
        })

        this.setState(
            {
                managerList: mangerItems
            }
        );
    });*/

    /*fetchUserList().then(response => {
        if (response.ok) {
            response.text().then(resp => {
                let userList: any[] = JSON.parse(resp);

                let userItems = userList.map(m => {
                    return {
                        userGuid: m.propertyOwnerGuid,
                        userName: m.propertyOwnerName
                    }
                });

                this.setState(
                    {
                        usersList: userItems
                    }
                );
            })
        } else {
            if (response.status === 401) {
                this.accessTokenExpired();
            } else {
                response.text().then(resp => {
                    console.log(response);
                })
            }
        }
    })*/

    fetchPropertyList().then(response => {
      if (response.ok) {
        response.text().then(resp => {
          let propertyList: any[] = JSON.parse(resp);

          let propertyItems = propertyList.map(m => {
            return {
              propertyGuid: m.propertyGuid,
              propertyName: m.propertyName
            }
          });

          this.setState(
            {
              propertyList: propertyItems
            }
          );
        });
      }
      /* else {
          if (response.status === 401) {
              this.accessTokenExpired();
          } else {
              response.text().then(resp => {
                  console.log(response);
              });
              ShowAlert("", 'There was an error processing a your request.', "error");
          }
      }*/
    })
  }

  render() {
    if (this.state.dataLoaded) {
      return <XEnhancedTable
        data={this.state.data}
        userList={this.state.usersList}
        propertyList={this.state.propertyList}
        loaderPercentage={this.state.loaderPercentage}
      />;
    } else {
      return null;
      // return (
      //     <LoaderComponent loaderVisible={true} />
      // );
    }
  }

  private loadCompanies() {
    let isBusy = false;

    _getAllCompanies((rowItems: Data[]) => {
      return rowItems.map(r => {
        return {
          companyGuid: r.companyGuid,
          companyName: r.companyName ? r.companyName : '',
          primaryContact: r.primaryContact ? r.primaryContact : '',
          companyOwnerName: r.companyOwnerName,
          companyOwnerGuid: r.companyOwnerGuid,
          companyContactGuid: r.companyContactGuid,
          propertyName: r.propertyName ? r.propertyName : '',
          propertyGuid: r.propertyGuid,
          propertyNewGuid: r.propertyNewGuid,
          companyAddress1: r.companyAddress1,
          companyAddress2: r.companyAddress2,
          companyCity: r.companyCity,
          companyState: r.companyState,
          companyCountry: r.companyCountry,
          companyZipCode: r.companyZipCode,
          companyStatusId: r.companyStatusId,
          propertyAddress1: r.propertyAddress1,
          propertyAddress2: r.propertyAddress2,
          propertyCity: r.propertyCity ? r.propertyCity : '',
          propertyState: r.propertyState,
          propertyCountry: r.propertyCountry,
          propertyZipCode: r.propertyZipCode,
          propertyQuantity: r.propertyQuantity,
          revenueYTD: r.revenueYTD,
          RevenueText: this.convertToDollar(r.revenueYTD),
          manager: r.manager,
          rawData: r
        };
      });
    }, (rowItems: Data[], progress_: number) => {
      if (!isBusy) {
        isBusy = true;

        this.setState({
          data: rowItems,
          dataLoaded: true,
          loaderPercentage: progress_ * 100,
          isCompanyLoaded: true
        }, () => {
          isBusy = false;
        });

      }
    }).then(companies_ => {
      this.setState({
        data: companies_,
        dataLoaded: true,
        loaderPercentage: 100,
        isCompanyLoaded: true
      }, () => {
        isBusy = false;
        setTimeout(() => {
          this.setState({
            loaderPercentage: 0
          });
        }, 500);
      });
    })
  }

  private accessTokenExpired() {
    localStorage.clear();
    window.location.href = '/';
  }

  private convertToDollar(amount: number) {
    return "$" + amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  private setRandomRevenue() {
    let revenue: number = Math.floor(Math.random() * 100000) + 5000;
    return revenue;
  }
}

function XEnhancedTable(props: { data: Data[], userList: UsersList[], propertyList: Property[], loaderPercentage: number }) {
  let propsRows = props.data;

  let emptyData: Data = {
    companyGuid: '',
    companyName: '',
    primaryContact: '',
    companyOwnerName: '',
    companyOwnerGuid: '',
    propertyName: '',
    propertyGuid: '',
    propertyNewGuid: '',
    companyAddress1: '',
    companyAddress2: '',
    companyCity: '',
    companyState: '',
    companyCountry: '',
    companyZipCode: '',
    companyStatusId: 1,
    propertyAddress1: '',
    propertyAddress2: '',
    propertyCity: '',
    propertyState: '',
    propertyCountry: '',
    propertyZipCode: '',
    revenueYTD: 0,
    RevenueText: '',
    companyContactGuid: '',
    propertyQuantity: 0,
    manager: []
  }

  let emptyUserList: UsersList = {
    userName: '',
    userGuid: ''
  }

  let emptyManagerList: ManagerList = {
    managerGuid: '',
    managerName: ''
  }

  let emptyOwnerList: OwnerList = {
    propertyOwnerGuid: '',
    propertyOwnerName: ''
  }

  let emptyProperty: Property = {
    propertyGuid: '',
    propertyName: ''
  }

  let emptyRange: RangeModel = {
    start: 0,
    end: 0
  }

  let emptyFilterOption: FilterOptionsModel = {
    AvailableCities: [],
    AvailableCompany: [],
    City: [],
    Company: [],
    DateRangeSelected: '',
    FilterApplied: false,
    Revenue: emptyRange,
    SlotsCorrelation: ''
  }

  let defaultRevenueRange: RangeModel = {
    start: 65000,
    end: 125000
  }
  let key = window.localStorage.getItem('key')
  const [searchedTextState, setSearchedTextState] = React.useState<string>(key ? key : '');
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('companyName');
  const [selected, setSelected] = React.useState<Data[]>([]);
  const [multiSelected, setMultiSelected] = React.useState<Data[]>([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [totalRows, setTotalRows] = React.useState(0);
  // eslint-disable-next-line
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [isAddPanelVisible, setIsAddPanelVisible] = React.useState(false);
  const [isFilterPanelVisible, setIsFilterPanelVisible] = React.useState(false);
  const [isEdited, setIsEdited] = React.useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);
  const [editedEnabled, setEditedEnabled] = React.useState(false);
  const [newRecord, setNewRecord] = React.useState<Data>(emptyData);
  const [selectedOwner, setSelectedOwner] = React.useState<UsersList>(emptyUserList);
  const [selectedManagers, setSelectedManagers] = React.useState<UsersList[]>([]);
  const [selectedProperty, setSelectedProperty] = React.useState<Property>(emptyProperty);
  const [propertyList, setPropertyList] = React.useState<Property[]>(props.propertyList);
  const [isMenuOpened, setIsMenuOpened] = React.useState(false);
  const [revenueRangeValue, setRevenueRangeValue] = React.useState<number[]>([10, 25]);

  const [showBlocker, setShowBlocker] = React.useState(false);

  React.useEffect(() => {
    return () => {
      window.localStorage.setItem('key', searchedTextState)
    }
  })
  const [rangeDateState, setRangeDateState] = React.useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);

  const [filterOptions, setFilterOptions] = React.useState<FilterOptionsModel>(emptyFilterOption);
  const [filteredCities, setFilteredCities] = React.useState<string[]>([]);
  const [filteredCompanies, setFilteredCompanies] = React.useState<string[]>(PreserveFilters.readPreservedFilter('managementCompanies') ? PreserveFilters.readPreservedFilter('managementCompanies').Company : []);
  const [filterAvailableCities, setFilterAvailableCities] = React.useState<string[]>([]);
  const [filterAvailableCompanies, setFilterAvailableCompanies] = React.useState<string[]>([]);
  const [filterRevenueRangeValue, setFilterRevenueRangeValue] = React.useState<RangeModel>(emptyRange);
  const [customRevenueRange, setCustomRevenueRange] = React.useState<RangeModel>(defaultRevenueRange);
  const [selectedRevenueRange, setSelectedRevenueRange] = React.useState<string>('');

  const [isFilterApplied, setIsFilterApplied] = React.useState(false);

  const [rows, setRows] = React.useState(props.data);
  const [oldRows, setOldRows] = React.useState<Data[]>(props.data);

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  selectedRows = selected;
  setSelectedCallback = setSelected;
  setMultiSelectedCallback = setMultiSelected;

  if (props.data.length != oldRows.length) {
    setRows(props.data);
    setOldRows(props.data);
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows;
      setMultiSelected(newSelecteds);
      return;
    }
    setMultiSelected([]);
  };

  const handleToggle = () => {
    setIsMenuOpened((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setIsMenuOpened(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setIsMenuOpened(false);
    }
  }

  const handleEdit = (event: React.MouseEvent<unknown>, name: string, row: any) => {
    if (isEdited) {
      // SaveRecord('update');
      setIsEdited(false);
    }
    const selectedIndex = rows.indexOf(row);
    let newSelected: Data[] = [];
    newSelected = newSelected.concat(row);
    setSelected(newSelected);

    // let _newRecord: Data = row;
    let _newRecord: Data = ObjectHelper.deepClone(row);

    let _selectedOwner: UsersList = {
      userGuid: row.companyOwnerGuid,
      userName: row.companyOwnerName
    }

    let _selectedManagers: UsersList[] = [];

    if (row.manager) {
      for (var m = 0; m < row.manager.length; m++) {
        let _managers: UsersList = {
          userGuid: row.manager[m].managerGuid,
          userName: row.manager[m].managerName
        };

        _selectedManagers.push(_managers);
      }
    }

    // let _selectedManagers = row.manager.map(r => {
    //     return {
    //         userGuid: r.managerGuid,
    //         userName: r.managerName
    //     }
    // })

    let _selectedProperty: Property = {
      propertyGuid: row.propertyGuid,
      propertyName: row.propertyName
    }

    updatePropertyList();
    setSelectedProperty(_selectedProperty);
    setNewRecord(_newRecord);
    setSelectedIndex(selectedIndex);
    setSelectedOwner(_selectedOwner);
    setSelectedManagers(_selectedManagers);
    // setNewRecord(row);
  }


  // eslint-disable-next-line
  const handleClick = (event: React.MouseEvent<unknown>, name: string, row: any) => {
    const selectedIndex = multiSelected.indexOf(row);
    let newSelected: Data[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(multiSelected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(multiSelected.slice(1));
    } else if (selectedIndex === multiSelected.length - 1) {
      newSelected = newSelected.concat(multiSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        multiSelected.slice(0, selectedIndex),
        multiSelected.slice(selectedIndex + 1),
      );
    }

    setMultiSelected(newSelected);
    handleChangeRightMenu(false)
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeRightMenu = (visibleState: boolean, skipUnSaveCheck?: boolean) => {
    if (hasUnsavedChanges && !skipUnSaveCheck) {
      ShowAlertwithConfirm("You have unsaved changes!", "Are you sure you want to close this window?", "warning")
        .then(r_ => {
          if (r_) {
            setSelectedOwner(emptyUserList);
            setSelectedManagers([]);
            setEditedEnabled(false);
            setIsAddPanelVisible(visibleState);
            setIsFilterPanelVisible(visibleState);
            setSelectedCallback([]);
            clearFields();

            setHasUnsavedChanges(false);
          }
        })
    } else {
      setSelectedOwner(emptyUserList);
      setSelectedManagers([]);
      setEditedEnabled(false);
      setIsAddPanelVisible(visibleState);
      setIsFilterPanelVisible(visibleState);
      setSelectedCallback([]);
      clearFields();

      setHasUnsavedChanges(false);
    }
    /*setSelectedOwner(emptyUserList);
    setSelectedManagers([]);
    setEditedEnabled(false);
    setIsAddPanelVisible(visibleState);
    setIsFilterPanelVisible(visibleState);
    setSelectedCallback([]);
    clearFields();*/
  }

  const clearFields = () => {
    let _newRecord: Data = emptyData;

    setSelectedProperty(emptyProperty);
    setNewRecord(_newRecord);
  }

  const handleChangeRecord = (event: any) => {
    var _targetValue = event.target.value;
    var _FeildName = event.target.name;
    // var _elmName = isRightMenuVisible ? 'edit_' : 'add_';
    var _elmName = '';
    setIsEdited(true);
    setHasUnsavedChanges(true);

    let prevState = Object.assign({}, newRecord);
    prevState.companyName = (_FeildName === _elmName + "company_name") ? _targetValue : (prevState?.companyName) ? prevState?.companyName : '';
    prevState.primaryContact = (_FeildName === _elmName + "primaryContact") ? _targetValue : (prevState?.primaryContact) ? prevState?.primaryContact : '';

    setNewRecord(prevState);
  }

  const SaveRecord = (actionType: string) => {
    let _newRecord: Data = ObjectHelper.deepClone(newRecord);

    if (_newRecord.propertyGuid !== selectedProperty?.propertyGuid) {
      _newRecord.propertyNewGuid = selectedProperty?.propertyGuid ? selectedProperty?.propertyGuid : '';
    }

    if (actionType === 'Add') {
      _newRecord.propertyGuid = selectedProperty?.propertyGuid ? selectedProperty?.propertyGuid : '';
      _newRecord.propertyName = selectedProperty?.propertyName ? selectedProperty?.propertyName : '';
    }

    /*_newRecord.companyOwnerGuid = selectedOwner?.userGuid ? selectedOwner?.userGuid : '';
    _newRecord.companyOwnerName = selectedOwner?.userName ? selectedOwner?.userName : '';

    let _mn: ManagerList = {
        managerGuid: '',
        managerName: ''
    }


    let _managerList: ManagerList[] = [];

    for (var i = 0; i < selectedManagers.length; i++) {
        let _managerItem: ManagerList = {
            managerGuid: selectedManagers[i].userGuid,
            managerName: selectedManagers[i].userName
        };

        _managerList.push(_managerItem);
    }

    _newRecord.manager = _managerList;

    if (_newRecord.propertyName == '' || _newRecord.propertyName == null) {
        ShowAlert('Error!', 'Please select the <b>Property</b>', 'error');
        return false;
    }

    if (_newRecord.companyOwnerName === '' || _newRecord.companyOwnerName === null) {
        ShowAlert('Error!', 'Please select the <b>Owner</b>', 'error');
        return false;
    }*/

    if (_newRecord.companyName === '' || _newRecord.companyName === null) {
      ShowAlert('Error!', 'Please enter the <b>Management Company Name<b/>.', 'error');
      return false;
    }


    if (actionType === 'Add') {
      setShowBlocker(true);
      addNewCompany(_newRecord).then(response => {
        if (response.ok) {
          response.text().then(resp => {
            ShowAlert('', 'The <b>' + _newRecord.companyName + '</b> was added to the management company list', 'success');
            _newRecord.companyGuid = JSON.parse(resp);
            rows.push(_newRecord);
            if (_newRecord.propertyGuid != "") {
              for (let c = 0; c < rows.length; c++) {
                if (rows[c].propertyGuid == _newRecord.propertyGuid && rows[c].companyGuid != _newRecord.companyGuid) {
                  console.log('Entered company');
                  let companyName = rows[c].companyName;
                  let companyIndex: number[] = [];
                  let MatchedRow: number = 0;
                  for (let oc = 0; oc < rows.length; oc++) {
                    console.log(oc);
                    if (rows[oc].companyName == companyName) {
                      companyIndex.push(oc);
                      if (rows[oc].propertyGuid == _newRecord.propertyGuid) {
                        MatchedRow = oc;
                        console.log(rows[oc].companyName, rows[oc].propertyName);
                      }
                    }
                  }

                  if (companyIndex.length > 0) {
                    rows.splice(MatchedRow, 1);
                  } else {
                    rows[MatchedRow].propertyGuid = "";
                    rows[MatchedRow].propertyName = "";
                  }
                }
              }
            }

            console.log(rows)

            setRows(rows);
            setHasUnsavedChanges(false);
            handleChangeRightMenu(false, true);
            updatePropertyList();
            setShowBlocker(false);
          });
        } else {
          setShowBlocker(false);
          response.text().then(resp => {
            console.log(resp);
          })
        }
      })

    } else {
      setShowBlocker(true);
      updateCompany(_newRecord).then(response => {
        if (response.ok) {
          response.text().then(resp => {
            _newRecord.propertyGuid = selectedProperty?.propertyGuid ? selectedProperty?.propertyGuid : '';
            _newRecord.propertyName = selectedProperty?.propertyName ? selectedProperty?.propertyName : '';
            ShowAlert('', 'The <b>' + _newRecord.companyName + '</b> was updated to the management company list', 'success');

            //update same company details
            for (let c = 0; c < rows.length; c++) {
              if (rows[c].companyGuid == _newRecord.companyGuid) {
                rows[c].companyName = _newRecord.companyName;
                rows[c].primaryContact = _newRecord.primaryContact;
              }
            }

            rows[selectedIndex] = _newRecord;
            setRows(rows);

            let newSelected: Data[] = [];
            newSelected = newSelected.concat(rows[selectedIndex]);
            setSelected(newSelected);
            setHasUnsavedChanges(false);
            handleChangeRightMenu(false, true);
            updatePropertyList();
            setShowBlocker(false);
          });
        } else {
          setShowBlocker(false);
          response.text().then(resp => {
            console.log(resp);
          })
        }
      })
    }
  }

  const updatePropertyList = () => {
    //propertyList
    //this._propertyService.getPropertiesBasics()
    fetchPropertyList().then(response => {
      if (response.ok) {
        response.text().then(resp => {
          let propertyList: any[] = JSON.parse(resp);

          let propertyItems = propertyList.map(m => {
            return {
              propertyGuid: m.propertyGuid,
              propertyName: m.propertyName
            }
          });

          setPropertyList(propertyItems);
        });
      }
      /* else {
          if (response.status === 401) {
              localStorage.clear();
              window.location.href = '/';
          } else {
              response.text().then(resp => {
                  console.log(response);
              });
              ShowAlert("", 'There was an error processing a your request.', "error");
          }
      }*/
    })

  }

  const onChangeCompanyStatus = (event: any) => {
    let _newRecord: Data = newRecord;

    _newRecord.companyStatusId = event.target.value;

    rows[selectedIndex] = _newRecord;
    setRows(rows);

    let _listCompany: CompanyStatus[] = [{
      companyGuid: _newRecord.companyGuid,
      CompanyStatusId: _newRecord.companyStatusId
    }];

    udateCompanyStatus(_listCompany).then(response => {
      if (response.ok) {
        response.text().then(resp => {
          rows[selectedIndex] = _newRecord;
          setRows(rows);

          let newSelected: Data[] = [];
          newSelected = newSelected.concat(rows[selectedIndex]);
          setSelected(newSelected);
          setNewRecord(_newRecord);
        });
      } else {
        response.text().then(resp => {
          console.log(resp);
        });
      }
    })
  }

  const EnableEdit = () => {
    setEditedEnabled(true);
  }

  const ViewMode = () => {
    return (
      <div className="side-popup-container">
        <Grid container className="edit-button-container">
          <Grid item xs>
            {/* <a href="avaScript:void(0)" onClick={() => EnableEdit()}>Edit</a> */}
          </Grid>
          <Grid item>
            <div className="close-menu-icon">
              <Tooltip title="Close">
                <IconButton aria-label="Close" onClick={() => handleChangeRightMenu(false)}>
                  <CloseIcon/>
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        <Grid container className="list-header-container">
          <Grid item xs>
            <h1 className="list-header">
              {newRecord?.companyName} <span className="expand-details">
                                {
                                  CheckFeaturePermission('"add-edit-company"') && <React.Fragment>
                                        <Tooltip title='Edit' onClick={() => EnableEdit()}>
                                            <IconButton aria-label="Edit">
                                                <EditIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </React.Fragment>
                                }
                            </span>
            </h1>
          </Grid>
          <Grid item>
            <Select
              value={newRecord.companyStatusId}
              inputProps={{'aria-label': 'Without label'}}
              className="property-state"
              onChange={onChangeCompanyStatus}
            >
              <MenuItem value={1}>
                <div className={'list-status dropdown-status active'}></div>
                Active</MenuItem>
              <MenuItem value={2}>
                <div className={'list-status dropdown-status inactive'}></div>
                Inactive</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <div className="property-location"><LocationOnIcon/> {newRecord?.propertyAddress1}</div>
        <hr className="property-line"/>
        <div className="right-menu-body">
          <img src={map} width="100%" className="property-location-img"/>
          <Grid container>
            <Grid item xs>
                            <span>
                                <IconButton aria-label="Earned Revenue" className="icon-selected">
                                    <TrendingUpIcon/>
                                </IconButton>
                            </span> <span className="bold-caps">Earned Revenue YTD</span>
            </Grid>
            <Grid item className="revenue-amount-container">
              {/* <span className="table-label revenue-lable">last month</span><br /> */}
              <span className="bold-caps revenue-amount">{newRecord?.RevenueText}</span>
            </Grid>
          </Grid>
          <hr/>
          <img src={activity} width="100%" className="property-location-img"/>
        </div>
      </div>
    )
  }

  // const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setDense(event.target.checked);
  // };

  const handleSelectCity = (value: string) => {
    const currentIndex = filteredCities.indexOf(value);
    const newChecked = [...filteredCities];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setFilteredCities(newChecked);
  }

  const handleSelectAllCities = (items: string[]) => {
    if (filteredCities.length === items.length) {
      setFilteredCities([]);
    } else {
      setFilteredCities(items);
    }
  }

  const handleSetRevenuRange = (rangeName: string, range: RangeModel) => {
    let newRevenueRange: RangeModel = range;
    if (rangeName === 'custom') {
      newRevenueRange = customRevenueRange;
    }
    setFilterRevenueRangeValue(newRevenueRange);
    setSelectedRevenueRange(rangeName);
  }

  const updateCustomPriceRange = (start_: number, end_: number) => {
    let newRevenueRange: RangeModel = {
      start: start_,
      end: end_
    };

    setCustomRevenueRange(newRevenueRange);
    setFilterRevenueRangeValue(newRevenueRange);
  }

  const FilterCityView = () => {
    let propertyCities: string[] = [];

    rows.map((item, index) => {
      if (item.propertyCity != '' && propertyCities.indexOf(item.propertyCity) === -1) {
        propertyCities.push(item.propertyCity);
      } else if ((item.propertyCity === '' || item.propertyCity === null) &&
        propertyCities.indexOf('Blank') === -1) {
        propertyCities.push('Blank');
      }
    });

    if (filterAvailableCities.length === 0) {
      setFilterAvailableCities(propertyCities);
    }

    return (
      <ExpansionPanel className="filter-panel">
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="filter-heading">City</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="property-label-container">
            <div className="property-label">
              <FormControlLabel
                control={<Checkbox
                  onClick={() => handleSelectAllCities(propertyCities)}
                  checked={propertyCities.length === filteredCities.length && propertyCities.length !== 0}
                  indeterminate={filteredCities.length !== propertyCities.length && filteredCities.length !== 0}
                  disabled={propertyCities.length === 0}
                  inputProps={{'aria-label': 'all cities are selected'}}
                  color="primary"/>}
                label="All"
              />
            </div>
            {
              propertyCities.sort().map((city, index) => (
                  <div className="property-label" key={'city' + index}>
                    <FormControlLabel
                      control={<Checkbox
                        name={'lable_' + city}
                        checked={filteredCities.indexOf(city) !== -1}
                        onClick={() => handleSelectCity(city)}
                        color="primary"/>}
                      label={city}
                    />
                  </div>
                )
              )}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  const FilterMngCompnay = () => {
    // let propertyCompanies: ManagerList[] = [];
    let propertyCompanies: string[] = [];

    /*var dd = rows.filter((v,i,a)=> {
        return a.findIndex(t=>(t.propertyCompanyName === v.propertyCompanyName && t.propertyCompanyName !== '' && t.propertyCompanyName !== null))===i
    })
    console.log(dd);*/

    rows.map((v, i, a) => {
      var isUnique = a.findIndex(t => (t.companyName === v.companyName && t.companyName !== '' && t.companyName !== null)) === i;

      if (isUnique) {
        propertyCompanies.push(v.companyName);
      }
    })

    propertyCompanies = propertyCompanies.sort();

    if (filterAvailableCompanies.length === 0) {
      setFilterAvailableCompanies(propertyCompanies);
    }


    return (
      <ExpansionPanel className="filter-panel">
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className="filter-heading">Management Company</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Autocomplete
            multiple
            id="listMngCompany"
            className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
            options={propertyCompanies}
            disableCloseOnSelect
            size="small"
            value={filteredCompanies}
            onChange={(event: any, values: any) => {
              setFilteredCompanies(values);
            }}
            getOptionLabel={(option) => option}
            renderOption={(option, {selected}) => (
              <React.Fragment>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                  checkedIcon={<CheckBoxIcon fontSize="small"/>}
                  style={{marginRight: 8}}
                  checked={selected}
                  color="primary"
                />
                {option}
              </React.Fragment>
            )}
            renderInput={(params) => <TextField {...params} label="Search by management company" variant="outlined"/>}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  const FilterRevenueRange = () => {
    return (
      <ExpansionPanel className="filter-panel">
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel2a-content"
          id="panel3a-header"
        >
          <Typography className="filter-heading">Revenue Range</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <FormControl component="fieldset">
            <RadioGroup aria-label="revenue" name="revenue">
              <FormControlLabel
                checked={selectedRevenueRange === 'less50'}
                onClick={(event) => handleSetRevenuRange('less50', {start: 0, end: 50000})}
                value="less50" control={<Radio color="primary"/>} label="Less than $50,000"/>
              <FormControlLabel
                checked={selectedRevenueRange === 'more50'}
                onClick={(event) => handleSetRevenuRange('more50', {start: 50000, end: 0})}
                value="more50" control={<Radio color="primary"/>} label="More than $50,000"/>
              <FormControlLabel
                checked={selectedRevenueRange === 'more100'}
                onClick={(event) => handleSetRevenuRange('more100', {start: 100000, end: 0})}
                value="more100" control={<Radio color="primary"/>} label="More than $100,000"/>
              <FormControlLabel value="custom"
                                checked={selectedRevenueRange === 'custom'}
                                onClick={(event) => handleSetRevenuRange('custom', {start: 65000, end: 125000})}
                                control={<Radio color="primary"/>} label="Custom Range"/>
            </RadioGroup>
          </FormControl>
          {
            selectedRevenueRange === 'custom' ?
              <div className="slider-revenue-controller">
                <PriceRangeSelector
                  selectedRange={{
                    start: customRevenueRange.start,
                    end: customRevenueRange.end
                  }}
                  min={10000}
                  max={500000}
                  onChange={(start_: number, end_: number) => {
                    updateCustomPriceRange(start_, end_)
                  }}
                />
              </div>
              : null
          }
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  const FilterSlots = () => {
    return (
      <ExpansionPanel className="filter-panel">
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel2a-content"
          id="panel4a-header"
        >
          <Typography className="filter-heading">Slots Correlation</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <FormControl component="fieldset">
            <RadioGroup aria-label="slots" name="slots">
              <FormControlLabel value="Empty" control={<Radio color="primary"/>} label="Empty"/>
              <FormControlLabel value="Full" control={<Radio color="primary"/>} label="Full"/>
              <FormControlLabel value="Less than 50%" control={<Radio color="primary"/>} label="Less than 50%"/>
              <FormControlLabel value="More than 50%" control={<Radio color="primary"/>} label="More than 50%"/>
              <FormControlLabel value="Less than 10%" control={<Radio color="primary"/>} label="Less than 10%"/>
              <FormControlLabel value="More than 10%" control={<Radio color="primary"/>} label="More than 10%"/>
            </RadioGroup>
          </FormControl>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  const FilterDate = () => {
    return (
      <ExpansionPanel className="filter-panel">
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel2a-content"
          id="panel5a-header"
        >
          <Typography className="filter-heading">Date</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <DateRange
            // editableDateInputs={true}
            // onChange={item => setRangeDateState([item.selection])}
            onChange={(item: any) => {
              setRangeDateState([item.selection]);
            }}
            // moveRangeOnFirstSelection={false}
            ranges={rangeDateState}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  const ApplyFilter = () => {
    let _filterOption: FilterOptionsModel = {
      City: filteredCities,
      Company: filteredCompanies,
      DateRangeSelected: '',
      Revenue: filterRevenueRangeValue,
      SlotsCorrelation: '',
      FilterApplied: true,
      AvailableCities: filterAvailableCities,
      AvailableCompany: filterAvailableCompanies
    }

    setFilterOptions(_filterOption);
    PreserveFilters.preserveFilter('managementCompanies', _filterOption)
    setIsFilterApplied(true);
    setIsFilterPanelVisible(false);
    setPage(0);
  }

  const ClearFilter = () => {
    let _filterOption: FilterOptionsModel = {
      City: [],
      Company: [],
      DateRangeSelected: '',
      Revenue: emptyRange,
      SlotsCorrelation: '',
      FilterApplied: false,
      AvailableCities: filterAvailableCities,
      AvailableCompany: filterAvailableCompanies
    }

    setFilteredCities([]);
    setFilteredCompanies([]);
    setFilterOptions(_filterOption);
    PreserveFilters.clearPreservedFilter('managementCompanies')
    setIsFilterApplied(false);
    setSelectedRevenueRange('');
    setIsFilterPanelVisible(true);
  }

  const updateFilterTags = (filterValues: FilterOptionsModel) => {
    // console.log(filterValues)
    setFilterOptions(filterValues);
    PreserveFilters.preserveFilter('managementCompanies', filterValues)
    setFilteredCities(filterValues.City);
    setFilteredCompanies(filterValues.Company);
    setFilterRevenueRangeValue(filterValues.Revenue);
  }

  const CancelFilter = () => {
    setIsFilterPanelVisible(false);
  }

  const FilterView = () => {
    return (
      <div className="side-popup-container">
        <div className="close-menu-icon">
          <Tooltip title="Close">
            <IconButton aria-label="Close" onClick={() => handleChangeRightMenu(false)}>
              <CloseIcon/>
            </IconButton>
          </Tooltip>
        </div>
        <Grid container>
          <Grid item xs>
            <h1 className="list-header">Filters</h1>
          </Grid>
          <Grid item>
            <div className="clear-filter" onClick={() => ClearFilter()}>Clear all</div>
          </Grid>
        </Grid>
        <div className="right-menu-body">
          <div className="filter-root">
            {/* {
                            FilterCityView()
                        } */}
            {
              FilterMngCompnay()
            }
            {/* {
                            FilterRevenueRange()
                        }
                        {
                            FilterDate()
                        }
                        {
                            FilterSlots()
                        } */}

          </div>
        </div>
        <Grid container spacing={1} className="button-container">
          <Grid item xs>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className='list-button'
              onClick={() => ApplyFilter()}
            >
              Apply
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className='list-button list-button-cancel'
              onClick={() => CancelFilter()}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }

  const AddEditView = (ViewType: string) => {
    return (
      <div className="side-popup-container">
        <Grid container>
          <Grid item xs>
            <h1 className="list-header">{ViewType} Management Company</h1>
          </Grid>
          <Grid item>
            <div className="close-menu-icon">
              <Tooltip title="Close">
                <IconButton aria-label="Close" onClick={() => handleChangeRightMenu(false)}>
                  <CloseIcon/>
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        <div className="right-menu-body">

          <h4 className="list-sub-header">About</h4>
          <TextField
            error={newRecord?.companyName.length < 3}
            variant="outlined"
            margin="normal"
            fullWidth
            id="company_name"
            label="Management Company Name"
            name="company_name"
            onChange={handleChangeRecord}
            value={newRecord?.companyName}
          />

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="primaryContact"
            label="Primary Contact"
            name="primaryContact"
            onChange={handleChangeRecord}
            value={newRecord?.primaryContact}
          />

          {/* <Autocomplete
                        id="add_property"
                        className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
                        options={propertyList}
                        value={selectedProperty}
                        onChange={(event: any, values: any) => {
                            setSelectedProperty(values);
                            setHasUnsavedChanges(true);
                        }}
                        disabled={ViewType=='Edit'}
                        getOptionLabel={(option) => option.propertyName}
                        renderInput={(params) => <TextField {...params} label="Property"
                            // error={selectedProperty == null || selectedProperty.propertyName == ''}
                            variant="outlined" />}
                    /> */}

          {/*<h4 className="list-sub-header">Management</h4>

                    <Autocomplete
                        id="add_propertyOwner"
                        className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
                        options={props.userList}
                        value={selectedOwner}
                        defaultValue={selectedOwner}
                        onChange={(event: any, values: any) => {
                            setSelectedOwner(values);
                            setHasUnsavedChanges(true);
                        }}
                        getOptionLabel={(option) => option.userName}
                        renderInput={(params) => <TextField {...params} label="Owner" error={selectedOwner == null || selectedOwner.userGuid == ''} variant="outlined" />}
                    /> */}

          {/* <Autocomplete
                        multiple
                        id="add_ManagersList"
                        className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
                        options={props.userList}
                        value={selectedManagers}
                        defaultValue={selectedManagers}
                        onChange={(event: any, values: any) => {
                            setSelectedManagers(values);
                            setHasUnsavedChanges(true);
                        }}
                        getOptionLabel={(option) => option.userName}
                        renderInput={(params) => {
                            return (
                                <TextField {...params} label="Manager(s)" variant="outlined" />
                            )
                        }}
                    /> */}
        </div>
        <Grid container spacing={2} className="button-container">
          <Grid item xs>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className='list-button'
              onClick={() => SaveRecord(ViewType)}
            >
              Save
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className='list-button list-button-cancel'
              onClick={() => handleChangeRightMenu(false)}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }


  const rightSidePannel = () => {
    if (selectedRows.length > 0) {
      return (
        <Drawer
          anchor="right"
          open={true}
          onClose={() => {
            handleChangeRightMenu(false);
          }}
        >
          <div className={'table-right-menu'}>
            {
              // editedEnabled ? EditView() : ViewMode()
              editedEnabled ? AddEditView('Edit') : ViewMode()
            }
          </div>
        </Drawer>
      )
    } else if (isAddPanelVisible) {
      return (
        <Drawer
          anchor="right"
          open={true}
          onClose={() => {
            handleChangeRightMenu(false);
          }}
        >
          <div className={'table-right-menu'}>
            {
              AddEditView('Add')
            }
          </div>
        </Drawer>

      )
    } else if (isFilterPanelVisible) {
      return (
        <Drawer
          anchor="right"
          open={true}
          onClose={() => {
            handleChangeRightMenu(false);
          }}
        >
          <div className={'table-right-menu'}>
            {
              FilterView()
            }
          </div>
        </Drawer>
      )
    }
  }

  const openRightPanelTab = (f_: boolean, a_: boolean) => {
    updatePropertyList();
    setSelectedOwner(emptyUserList);
    setSelectedManagers([]);
    setEditedEnabled(false);
    setIsAddPanelVisible(a_);
    setIsFilterPanelVisible(f_);
    setSelectedCallback([]);
    clearFields();
  }

  const updateSearchText = (searchText: string) => {
    setSearchedTextState(searchText);
  }

  const searchTable = (tableRows: any[], searchText: string) => {
    let searchedItems: Data[] = [];
    let searchInput: string = searchText.trim().toLowerCase();

    if (searchInput !== "" && searchInput !== null) {
      tableRows.map((v, i, a) => {
        let exists: boolean = false;

        for (var i = 0; i < headCells.length; i++) {
          let coloumnName: keyof Data = headCells[i].id;
          let values = v[coloumnName];

          //if (typeof (values) === 'string') {
          if (coloumnName.indexOf('StatusId') === -1 && values !== null && values !== '') {
            values = values.toString().toLowerCase();

            if (values.indexOf(searchInput) !== -1) {
              exists = true;
            }
          }
        }

        if (exists) {
          searchedItems.push(v);
        }
      });

      return searchedItems;
    }

    return tableRows;
  }

  const isSelected = (data_: any) => multiSelected.indexOf(data_) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filteredItems = filterTable(rows, filterOptions);
  const filteredRows = ArrayHelper.stableSort(searchTable(filteredItems, searchedTextState), ArrayHelper.getNumberInStringComparator(order, orderBy));
  // const filteredRows = stableSort(searchTable(filteredItems, searchedTextState), getComparator(order, orderBy));

  return (
    <div className={useStyles().root + ' table-container-list'}>
      <ProgressTop
        loaderPercentage={props.loaderPercentage}
      />
      <Paper className={useStyles().paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onSearchChange={(searchText: string) => {
            updateSearchText(searchText);
            setPage(0);
          }}
          buttonState={(f_: boolean, a_: boolean) => {
            openRightPanelTab(f_, a_);
          }}
          // isFiltered={filteredItems.length != rows.length}
          isFiltered={!(filterOptions.Company.length == 0)}
        />
        <FilteredLable
          FilteredItems={filterOptions}
          onFilterLableDelete={(updatedFilter: FilterOptionsModel) => {
            updateFilterTags(updatedFilter);
          }}
        />
        <TableContainer className={useStyles().tableContainer + "  without-checkbox-container clickable-rows"}>
          <Table
            className={useStyles().table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            stickyHeader
            aria-label="sticky table"
          >
            <EnhancedTableHead
              classes={useStyles()}
              numSelected={multiSelected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {
                // stableSort(rows, getComparator(order, orderBy))
                filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.companyName + (index).toString()}
                        selected={isItemSelected}
                        onClick={(event) => handleEdit(event, row.companyName.toString(), row)}
                      >
                        {/* <TableCell padding="checkbox">
                                                <Checkbox
                                                    onClick={(event) => handleClick(event, row.propertyName.toString(), row)}
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    className="check-box-table"
                                                />
                                            </TableCell> */}
                        <TableCell align="left" className="table-list-cell">
                          <div className={'list-status ' + ((row.companyStatusId === 1) ? 'active' : 'inactive')}></div>
                        </TableCell>
                        <TableCell align="left" className="table-list-cell">
                          {row.companyName}
                        </TableCell>
                        <TableCell align="left" className="table-list-cell">{row.primaryContact}</TableCell>
                        {/* <TableCell align="left" className="table-list-cell">{row.propertyName}</TableCell>
                                            <TableCell align="left" className="table-list-cell">{row.propertyCity}</TableCell> */}
                        {/* <TableCell align="right" className="table-list-cell">{row.propertyQuantity}</TableCell> */}
                        {/* <TableCell align="right" className="table-list-cell">{"$" + Number(row.revenueYTD).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell> */}
                        <TableCell padding="checkbox">
                          <IconButton aria-label="More"
                                      onClick={(event) => handleEdit(event, row.companyName.toString(), row)}>
                            <MoreHorizIcon/>
                          </IconButton>
                          {/* <IconButton aria-label="More" ref={anchorRef} disabled={!isItemSelected} onClick={handleToggle}>
                                                    <MoreHorizIcon />
                                                </IconButton>
                                                <Popper open={isMenuOpened} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                                    {({ TransitionProps, placement }) => (
                                                        <Grow
                                                            {...TransitionProps}
                                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                                        >
                                                            <Paper>
                                                                <ClickAwayListener onClickAway={handleClose}>
                                                                    <MenuList autoFocusItem={isMenuOpened} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                                        <MenuItem onClick={handleClose}>View</MenuItem>
                                                                        <MenuItem onClick={handleClose}>Active</MenuItem>
                                                                        <MenuItem onClick={handleClose}>Deactive</MenuItem>
                                                                    </MenuList>
                                                                </ClickAwayListener>
                                                            </Paper>
                                                        </Grow>
                                                    )}
                                                </Popper> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              {/* {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid container>
          <Grid item>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              className="table-lis-footer"
              labelDisplayedRows={({from, to, count}) => `${from}-${to} of ${count} results`}
            />
          </Grid>
          <Grid item xs className="footer-pagenation">
            <Pagination
              page={(page + 1)}
              className="Pagination"
              showFirstButton
              showLastButton
              onChange={handleChangePage}
              count={Math.ceil(filteredRows.length / rowsPerPage)}

            />
          </Grid>
        </Grid>
      </Paper>
      {
        rightSidePannel()
      }

      {/* <LoaderComponent loaderVisible={showBlocker} /> */}
    </div>
  );
}

// }

// export default MaterialGrid;
