import React, { ChangeEvent } from "react";
import { TextField, Chip } from "@material-ui/core";
import StringHelper from "../../Helpers/StringHelper";
import "./BasicComponents.css";

export default class TextTagsField extends React.Component<ITextTagsFieldProps, ITextTagsFieldState> {
    private _numberLastPattern = /^([^0-9]*)([0-9]+)$/i;
    private _numberFirstPattern = /^([0-9]+)([^0-9]*)$/i;

    constructor(props_: ITextTagsFieldProps) {
        super(props_);

        this.state = {
            tags: this.props.tags ? this.props.tags : [],
            tempTag: ""
        };
    }

    componentDidUpdate(oldProps_: ITextTagsFieldProps) {
        if (oldProps_.tags != this.props.tags) {
            this.setState({
                tags: this.props.tags ? this.props.tags : []
            });
        }
    }

    render() {
        return (
            <div className="text-tags-container mt-1 pt-3">
                <TextField
                    multiline={true}
                    value={this.state.tempTag}
                    label={this.props.label}
                    variant="outlined"
                    className="w-100"
                    onChange={(e_: ChangeEvent<HTMLInputElement>) => {
                        if ((e_.nativeEvent as any).inputType == "insertLineBreak") {
                            this._handleAddTag();
                        }
                        else {
                            this.setState({
                                tempTag: e_.target.value as string
                            });
                        }
                    }}
                    onBlur={() => {
                        if (this.state.tempTag) {
                            this._handleAddTag();
                        }
                    }}
                    error={
                        this.state.tags.length == 0
                    }
                    helperText={this.props.helperText}
                    inputProps={{
                        maxLength: 24,
                    }}
                />

                {
                    this.state.tags.length > 0 &&

                    <span
                        className="btn-remove-all-tag"
                        onClick={() => {
                            let tags: string[] = [];
                            this.setState({
                                tags: tags
                            });
                            this.props.onChange(tags);
                        }}
                    >Remove all</span>
                }

                <div className="tag-list">
                    {
                        this.state.tags.map((s_: string, i_: number) => {
                            let isSkippedTag = false;

                            if (this.props.skip) {
                                isSkippedTag = this.props.skip.indexOf(s_) > -1;
                            }

                            return (
                                <Chip
                                    key={i_}
                                    label={s_}
                                    className={"chip-tag " + (isSkippedTag ? "skipped" : "")}
                                    onDelete={() => this._handleDeleteChip(s_, i_)}
                                    variant="outlined"
                                />
                            );
                        })
                    }
                </div>
            </div>
        );
    }

    private _handleAddTag(): void {
        let tagNames = this.state.tags.slice(0);
        let tempTagName = this.state.tempTag;

        if (tempTagName) {
            let newTags: string[] = [];

            if (this.props.splitChar && tempTagName.indexOf(this.props.splitChar) > 0) {
                newTags = tempTagName.split(this.props.splitChar);
            }
            else if (this.props.rangeChar && tempTagName.indexOf(this.props.rangeChar) > 0) {
                let rangeBeginEnd = tempTagName.split(this.props.rangeChar);
                if (rangeBeginEnd.length == 2) {
                    let startText = rangeBeginEnd[0];
                    let endText = rangeBeginEnd[1];
                    let startNumberLastMatch = startText.match(this._numberLastPattern);
                    let startNumberFirstMatch = startText.match(this._numberFirstPattern);
                    let endNumberLastMatch = endText.match(this._numberLastPattern);
                    let endNumberFirstMatch = endText.match(this._numberFirstPattern);

                    if (startNumberLastMatch != null && endNumberLastMatch != null) {
                        let minNumLength = startNumberLastMatch[2].length;
                        let startNumber = parseInt(startNumberLastMatch[2]);
                        let endNumber = parseInt(endNumberLastMatch[2]);

                        if (startNumber < endNumber) {
                            for (let i = startNumber; i <= endNumber; i++) {
                                newTags.push(startNumberLastMatch[1] + StringHelper.padLeft(i.toString(), minNumLength, "0"));
                            }
                        }
                    }
                    else if (startNumberFirstMatch != null && endNumberFirstMatch != null) {
                        let minNumLength = startNumberFirstMatch[1].length;
                        let startNumber = parseInt(startNumberFirstMatch[1]);
                        let endNumber = parseInt(endNumberFirstMatch[1]);

                        if (startNumber < endNumber) {
                            for (let i = startNumber; i <= endNumber; i++) {
                                newTags.push(StringHelper.padLeft(i.toString(), minNumLength, "0") + startNumberFirstMatch[2]);
                            }
                        }
                    }
                }
            }
            else {
                newTags = [tempTagName];
            }

            let skippedAddedTag: string[] = [];
            for (let s of newTags) {
                s = s.trim();
                if (s) {
                    if (tagNames.indexOf(s) === -1) {
                        tagNames.push(s);
                    }

                    if (this.props.skip && this.props.skip.indexOf(s) !== -1) {
                        skippedAddedTag.push(s);
                    }

                    if (this.props.maxTagLength && s.length > this.props.maxTagLength) {
                        if (this.props.onAddFail) {
                            this.props.onAddFail([], false, true, false);
                        }
                        return;
                    }
                }
            }

            if (skippedAddedTag.length > 0) {
                if (!this.props.allowAddingSkippedTags) {
                    if (this.props.onAddFail) {
                        this.props.onAddFail(skippedAddedTag, false, false, false);
                    }
                    return;
                }
            }

            if (this.props.maxTagsCount != undefined && tagNames.length > this.props.maxTagsCount) {
                if (this.props.onAddFail) {
                    this.props.onAddFail([], true, false, false);
                }
                return;
            }
            if (this.props.splitChar && this.props.maxSerializedLength && tagNames.join(this.props.splitChar).length > this.props.maxSerializedLength) {
                if (this.props.onAddFail) {
                    this.props.onAddFail([], false, false, true);
                }
                return;
            }

            this.setState({
                tempTag: "",
                tags: tagNames
            });
            this.props.onChange(tagNames);
        }
    }

    private _handleDeleteChip(tag_: string, index_: number): void {
        let tags = this.state.tags;
        tags.splice(index_, 1);
        this.setState({
            tags: tags
        });
        this.props.onChange(tags);
    }
}

interface ITextTagsFieldProps {
    label: string;
    helperText: string;
    tags?: string[];
    skip?: string[];
    rangeChar?: string;
    splitChar?: string;
    maxTagsCount?: number;
    maxTagLength?: number;
    maxSerializedLength?: number;
    allowAddingSkippedTags?: boolean;
    onChange: (tags_: string[]) => void;
    onAddFail?: (skippedTags_: string[], tagsCountReached_: boolean, tagLengthReached_: boolean, serializedLengthReached_: boolean) => void;
}

interface ITextTagsFieldState {
    tempTag: string;
    tags: string[];
}