import { Grid, Drawer, IconButton, Tooltip, Button } from "@material-ui/core";
import React from "react";
import { PreserveFilters } from "../../Common/PreserveFilters";
import DataGrid from "../../Components/DataGrid/DataGrid";
import DataGridToolbar from "../../Components/DataGrid/DataGridToolbar";
import { IAppliedFilter, IDataGridColumn } from "../../Components/DataGrid/Interfaces";
import { RightPanelState } from "../../Models/CommonInterfaces";
import ZoneService from "../../Services/ZoneService";
import { IPropertyFilter } from "../Properties/Interfaces";
import CloseIcon from '@material-ui/icons/Close';
import { IZone, IZoneFilter } from "./Interfaces";
import ZoneFilterView from "./ZoneFilterView";
import ZoneDetailView from "./ZoneDetailView";
import ZoneAddEditView from "./ZoneAddEditView";
import { ShowAlert, ShowAlertwithConfirm } from "../../Common/ShowAlert";
import EditIcon from '@material-ui/icons/Edit';
import { GetUserDetailsService } from "../../Services/GetUserDetailsService";
import ObjectHelper from "../../Helpers/ObjectHelper";
import { CheckFeaturePermission, CheckScreenPermission } from "../../Common/CheckScreenPermission";
import ArrayHelper from "../../Helpers/ArrayHelper";
import BaseService from "../../Services/BaseService";
import StringHelper from "../../Helpers/StringHelper";
import PrivateLotService from "../../Services/PrivateLotService";

export default class ZoneList extends React.Component<IZoneListProps, IZoneListState> {
    private _zoneService: ZoneService;
    private _privateLotService: PrivateLotService;
    private _isFiltered: boolean = false;
    private _isAdmin: boolean;
    private _zoneListColumns: IDataGridColumn<IZone>[] = [
        {
            key: "spacer1",
            name: ""
        },
        {
            key: "zoneName",
            name: "Zone Name",
            getComparators: ArrayHelper.sortArrayObject,
            alphaNumeric: true
        },
        {
            key: "propertyName",
            name: "Property Name",
            getComparators: ArrayHelper.sortArrayObject,
            alphaNumeric: true
        },
        {
            key: "bookingType",
            name: "Booking Type"
        },
        {
            key: "priceRange",
            name: "Price"
        },
        {
            key: "numberOfSpots",
            name: "# Of Spots",
            getComparators: ArrayHelper.sortArrayObject,
            alphaNumeric: true
        }
    ];

    constructor(props_: IZoneListProps) {
        super(props_);

        let zoneList: IZone[] = [];
        this._isAdmin = ((GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) != -1) || (GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) != -1));

        this.state = {
            allZones: zoneList,
            filteredZones: zoneList.slice(0),
            subFilteredZones: zoneList.slice(0),
            searchTerm: "",
            rightPanelState: RightPanelState.None,
            isFiltered: false,
            _autoApplyFilterKey: 0,
            isScreenAccessible: false,
            isZoneLoaded: false
        };

        this._zoneService = new ZoneService();
        this._privateLotService = new PrivateLotService();
    }

    componentDidMount = async () => {
        var isAccessible = await CheckScreenPermission("user-interface-zones");

        this.setState({
            isScreenAccessible: isAccessible
        }, () => {
            if (this.state.isScreenAccessible) {
                this.loadZone();
            }
        });
    }

    componentWillUnmount() {
        PreserveFilters.setSearchTerm('zoneSearch', this.state.searchTerm)
    }

    render() {
        if (!this.state.isZoneLoaded || !this.state.isScreenAccessible) return null;

        return (<div className="m-3 data-grid-container">
            {/* Header region of the page */}
            <Grid container className="mb-3">
                <Grid item>
                    {/* Title of the page */}
                    <h1 className="list-header">Zones</h1>
                </Grid>
                <Grid item xs>
                    {/* Grid toolbar */}
                    <DataGridToolbar
                        search={PreserveFilters.getSearchTerm('zoneSearch')}
                        singularEntityName="zone"
                        pluralEntityName="zones"
                        onSearch={this._searchTermChange}
                        isFiltered={this.state.isFiltered}
                        onFilterClick={() => {
                            this.setState({
                                rightPanelState: RightPanelState.Filter
                            });
                        }}
                        onAddClick={() => {
                            this.setState({
                                rightPanelState: RightPanelState.Add
                            });
                        }}
                        // isNoAddButton={!(GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) > -1 || GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) > -1)}
                        isNoAddButton={!CheckFeaturePermission('"add-edit-zone"')}
                    />
                </Grid>
            </Grid>

            {/* Data Grid */}
            <DataGrid
                title="Zone"
                data={this.state.subFilteredZones}
                columns={this._zoneListColumns}
                appliedFilter={this.state.appliedFilter}
                appliedSearch={this.state.searchTerm}
                defaultSortColumnKey="propertyName"
                isRowsNonSelectable={true}
                loaderPercentage={this.state.loaderPercentage}
                // isNoMoreLink={true}
                onFilterTagRemoved={(filter_: IAppliedFilter) => {
                    this.setState({
                        appliedFilter: filter_ as IPropertyFilter,
                        _autoApplyFilterKey: new Date().getTime()
                    }, () => {
                        PreserveFilters.preserveFilter('zones', this.state.appliedFilter)
                    });
                }}
                onRowClick={zone_ => {
                    this.setState({
                        selectedZone: zone_,
                        rightPanelState: RightPanelState.Details,
                        hasUnsavedChanges: false
                    });
                }}
                onMoreClick={(zone_: IZone) => {
                    let options_: any[] = [
                        {
                            label: "Show Details",
                            key: "details"
                        }
                    ];

                    //this._isAdmin
                    if (CheckFeaturePermission('"add-edit-zone"')) {
                        options_.push({
                            label: "Delete Zone",
                            key: "delete"
                        })
                    }

                    return options_;
                }}
                onPopoverClick={(key_, zone_) => {
                    if (key_ == "details") {
                        this.setState({
                            selectedZone: zone_,
                            rightPanelState: RightPanelState.Details,
                            hasUnsavedChanges: false
                        });
                    }
                    else if (key_ == "delete") {
                        this._deleteSpot(zone_);
                    }
                }}
            />

            {/* Right side drawer */}
            <Drawer
                anchor="right"
                variant={this.state.rightPanelState == RightPanelState.None ? "persistent" : "temporary"}
                open={this.state.rightPanelState != RightPanelState.None}
                className="data-grid-right-panel"
                onClose={this._onCancelAddEditPanel}
            >
                <div className="mx-3">
                    <Grid container className="right-panel-header p-2 border-bottom">
                        <Grid item xs>
                            <h4 className="right-panel-title m-0 ml-3">
                                {this._getRightPanelTitle()}
                            </h4>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={this._onCancelAddEditPanel}>
                                <CloseIcon />
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                {this._getRightPanelContent()}
            </Drawer>
        </div>);
    }

    private _searchTermChange = (searchTerm_: string): void => {
        this.setState({
            searchTerm: searchTerm_,
            subFilteredZones: this._filterDataBySearch(this.state.filteredZones, searchTerm_)
        });
    }

    private _filterDataBySearch = (data_: IZone[], searchTerm_: string): IZone[] => {
        const filterData = data_.filter(d => {
            return d.zoneName.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
                d.propertyName.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
                d.priceRange.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1 ||
                d.bookingType.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1;
        });

        return filterData;
    }

    // private _getRightPanelTitle(): string {
    //     switch (this.state.rightPanelState) {
    //         case RightPanelState.Filter:
    //             return "Filter";
    //         case RightPanelState.Details:
    //             return "Details";
    //         case RightPanelState.Edit:
    //             return "Edit";
    //         case RightPanelState.Add:
    //             return "Add";
    //     }

    //     return "";
    // }
    protected _getRightPanelTitle() {
        switch (this.state.rightPanelState) {
            case RightPanelState.Filter:
                return <span>Filter</span>;
            case RightPanelState.Details:
                if (this.state.selectedZone) {
                    return <div>
                        <span>Zone: {this.state.selectedZone.zoneName}</span>&nbsp;
                        {
                            //(GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) > -1 || GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) > -1)
                            CheckFeaturePermission('"add-edit-zone"') && <Tooltip title="Edit">
                                <IconButton onClick={() => {
                                    this.setState({
                                        rightPanelState: RightPanelState.Edit
                                    });
                                }}><EditIcon /></IconButton>
                            </Tooltip>
                        }
                    </div>
                }
            case RightPanelState.Edit:
                if (this.state.selectedZone) {
                    return <span>Zone: {this.state.selectedZone.zoneName}</span>;
                }
                return <span>Edit Zone</span>;
            case RightPanelState.Add:
                return <span>Add Zone</span>;
        }
    }

    private _getRightPanelContent() {
        let rightPanelViews = [
            <ZoneFilterView
                isVisible={this.state.rightPanelState == RightPanelState.Filter}
                data={this.state.allZones}
                appliedFilter={this.state.appliedFilter}
                onFilterChange={(appliedFilter_, filteredData_, isFiltered_) => {
                    this.setState({
                        appliedFilter: appliedFilter_,
                        filteredZones: filteredData_,
                        subFilteredZones: this._filterDataBySearch(filteredData_, this.state.searchTerm),
                        isFiltered: isFiltered_
                    }, () => {
                        PreserveFilters.preserveFilter('zones', this.state.appliedFilter)
                    })
                }}
                onClose={() => this.setState({ rightPanelState: RightPanelState.None })}
                autoApplyFilterKey={this.state._autoApplyFilterKey}
            />
        ];

        switch (this.state.rightPanelState) {
            case RightPanelState.Details:
                if (this.state.selectedZone) {
                    rightPanelViews.push(
                        <ZoneDetailView
                            zone={this.state.selectedZone}
                        />
                    )
                }
                break;
            case RightPanelState.Add:
                rightPanelViews.push(<ZoneAddEditView
                    onClose={this._handleAddEditPanelClose}
                    onChange={hasUnSavedChanges_ => this.setState({ hasUnsavedChanges: hasUnSavedChanges_ })}
                />);
                break;
            case RightPanelState.Edit:
                if (this.state.selectedZone) {
                    rightPanelViews.push(<ZoneAddEditView
                        zone={this.state.selectedZone}
                        onClose={this._handleAddEditPanelClose}
                        onChange={hasUnSavedChanges_ => this.setState({ hasUnsavedChanges: hasUnSavedChanges_ })}
                    />);
                }
                break;
        }

        return rightPanelViews;
    }

    protected _handleAddEditPanelClose = (requiresUpdate_: boolean, zones_: IZone[], updateZone?: any) => {
        if (requiresUpdate_ && zones_.length > 0) {
            let zones = this.state.allZones.concat(zones_);
            let filteredZones = this.state.filteredZones.concat(zones_);
            let subFilteredZones = this.state.subFilteredZones.concat(zones_);

            this.setState({
                rightPanelState: RightPanelState.None,
                allZones: zones,
                filteredZones: filteredZones,
                subFilteredZones: subFilteredZones,
                hasUnsavedChanges: false
            });
        }
        else if (requiresUpdate_) {
            if (updateZone) {
                let bookingType: string[] = [];
                if (updateZone.daily) {
                    bookingType.push("Daily");
                }

                if (updateZone.monthly) {
                    bookingType.push("Monthly");
                }

                if (bookingType.length === 0) {
                    bookingType.push("Hourly");
                }

                updateZone.bookingType = bookingType.toString().replaceAll(',', ', ');
                updateZone.numberOfSpots = updateZone.numberOfSpots.toString();
                let zones = this.state.allZones.filter(zone => zone.zoneGuid !== updateZone.zoneGuid).concat(updateZone);
                let filteredZones = this.state.filteredZones.filter(zone => zone.zoneGuid !== updateZone.zoneGuid).concat(updateZone);
                let subFilteredZones = this.state.subFilteredZones.filter(zone => zone.zoneGuid !== updateZone.zoneGuid).concat(updateZone);
                this.setState({
                    rightPanelState: RightPanelState.None,
                    allZones: zones,
                    filteredZones: filteredZones,
                    subFilteredZones: subFilteredZones,
                    hasUnsavedChanges: false
                });
            }
            else {
                this.setState({
                    rightPanelState: RightPanelState.None,
                    hasUnsavedChanges: false
                });
            }
        }
        else {
            this._onCancelAddEditPanel();
        }
    }

    protected _onCancelAddEditPanel = () => {
        if (this.state.hasUnsavedChanges) {
            ShowAlertwithConfirm("You have unsaved changes!", "Are you sure you want to close this window?", "warning")
                .then(r_ => {
                    if (r_) {
                        this.setState({
                            rightPanelState: RightPanelState.None,
                            hasUnsavedChanges: false
                        });
                    }
                })
        }
        else {
            this.setState({
                rightPanelState: RightPanelState.None,
                hasUnsavedChanges: false
            });
        }
    }



    private loadZone = (isUpdate?: boolean) => {
        let isBusy = false;

        let searchTerm = isUpdate ? this.state.searchTerm : PreserveFilters.getSearchTerm('zoneSearch');

        this._getAllZones((zones_: IZone[]) => {
            return zones_.map((e: any) => {
                let bookingType: string[] = [];
                if (e.daily) {
                    bookingType.push("Daily");
                }

                if (e.monthly) {
                    bookingType.push("Monthly");
                }

                if (bookingType.length == 0) {
                    bookingType.push("Hourly");
                }

                e.bookingType = bookingType.toString().replaceAll(',', ', ');
                e.numberOfSpots = e.numberOfSpots.toString();
                return e;
            });
        },
            (zones_: IZone[], progress_: number) => {
                if (!isBusy) {
                    isBusy = true;

                    this.setState({
                        allZones: zones_,
                        filteredZones: zones_.slice(0),
                        subFilteredZones: searchTerm ? this._filterDataBySearch(zones_, searchTerm) : zones_.slice(0),
                        searchTerm: searchTerm ? searchTerm : '',
                        isZoneLoaded: true,
                        loaderPercentage: progress_ * 100
                    }, () => {

                        isBusy = false;

                        this.setState({
                            _autoApplyFilterKey: Math.random(),
                            appliedFilter: PreserveFilters.readPreservedFilter('zones')
                        }, () => {
                            if (this.state.appliedFilter) {
                                this.filterItems()
                            }
                        })
                    });
                }
            }).then(zones_ => {
                this.setState({
                    allZones: zones_,
                    filteredZones: zones_.slice(0),
                    subFilteredZones: searchTerm ? this._filterDataBySearch(zones_, searchTerm) : zones_.slice(0),
                    searchTerm: searchTerm ? searchTerm : '',
                    isZoneLoaded: true,
                    loaderPercentage: 100
                }, () => {

                    isBusy = false;

                    this.setState({
                        _autoApplyFilterKey: Math.random(),
                        appliedFilter: PreserveFilters.readPreservedFilter('zones'),
                    }, () => {
                        if (this.state.appliedFilter) {
                            this.filterItems()
                        }

                        setTimeout(() => {
                            this.setState({
                                loaderPercentage: 0
                            });
                        }, 500);
                    })
                });
            });


        /*this._zoneService.getZone()
            .then(r => r.json())
            .then(r => {
                let zoneList = r.map((e: any) => {
                    let bookingType: string[] = [];
                    if (e.daily) {
                        bookingType.push("Daily");
                    }

                    if (e.monthly) {
                        bookingType.push("Monthly");
                    }

                    if (bookingType.length == 0) {
                        bookingType.push("Hourly");
                    }

                    e.bookingType = bookingType.toString().replaceAll(',', ', ');
                    e.numberOfSpots = e.numberOfSpots.toString();
                    return e;
                });

                let searchTerm = isUpdate ? this.state.searchTerm : PreserveFilters.getSearchTerm('zoneSearch')
                this.setState({
                    allZones: zoneList,
                    filteredZones: zoneList.slice(0),
                    subFilteredZones: searchTerm ? this._filterDataBySearch(zoneList, searchTerm) : zoneList.slice(0),
                    searchTerm: searchTerm ? searchTerm : '',
                }, () => {
                    this.setState({
                        _autoApplyFilterKey: Math.random(),
                        appliedFilter: PreserveFilters.readPreservedFilter('zones')
                    }, () => {
                        if (this.state.appliedFilter) {
                            this.filterItems()
                        }
                    })
                });
            })*/
    }

    private _getAllZones = async (contentParser_: (zones_: IZone[]) => IZone[], onProgress_: (zones_: IZone[], progress_: number) => void) => {
        let remainder = "";

        return BaseService.asyncResponse<IZone>(
            this._zoneService.getZone(),
            (content_, contentLength, receivedLength) => {
                let zones: IZone[] = [];

                if (content_) {
                    content_ = remainder + content_;

                    if (content_ && contentLength != receivedLength) {
                        let lastIndex = content_.lastIndexOf(",{\"propertyGuid\"");
                        if (lastIndex == -1) {
                            remainder = content_;
                            // content_ = "";
                        }
                        else {
                            remainder = "[" + StringHelper.trimStart(content_.substring(lastIndex), ',');
                            content_ = content_.substring(0, lastIndex) + "]";
                        }
                    }

                    if (content_) {
                        try {
                            zones = JSON.parse(content_);
                        } catch (e) { }
                    }
                }

                return contentParser_(zones);
            }, onProgress_);
    }

    private _deleteSpot = (zone_: IZone): void => {
        // console.log(zone_);
        let zoneData = {
            "propertyGuid": zone_.propertyGuid,
            "levelId": 0
        } as any;

        this._privateLotService.CheckMapStatus(zoneData).then(r => r.json())
            .then(r => {
                ShowAlertwithConfirm("Delete Zone?", `<div class="gryd-custom-popup-body">
                Are you sure you want to delete this zone?<br/>This action cannot be undone.<br/>
                ${r['mapStatus'] != 0 ? "This property has a lot management map which will be deleted as well." : ""}<br/>
                <b>${zone_.zoneName}</b><br/>
                <b>${zone_.propertyName}</b><br/>
                                    </div>`, undefined, 'Delete Zone', 'Cancel', 'gryd-custom-popup')
                    .then(r_ => {
                        if (r_) {
                            if (r['mapStatus'] != 0) {
                                var keys = Object.keys(localStorage).filter(x => x.indexOf(zone_.propertyGuid) != -1);

                                keys.forEach(asset_property => {
                                    localStorage.removeItem(asset_property);
                                });
                                // localStorage.removeItem(`asset_property_${zone_.propertyGuid}`);
                            }
                            this.onChangeZoneStatus([zone_.zoneGuid], [zone_], 3, true);
                        }
                    });
            });

    }

    private onChangeZoneStatus = (guids_: string[], zones_: IZone[], zoneStatus_: number, isMapDelete_: boolean): void => {
        this._zoneService.checkZoneReservation(guids_)
            .then(data => {
                if (data.ok) {
                    data.json().then(bookings_ => {
                        let reservedSpotGuids: string[] = [];
                        let zoneName: string = "";
                        let zoneGuid: string = "";
                        let zoneMsg: string = "";
                        let isSameProperty: boolean = true;

                        bookings_.forEach((bookingItem: any) => {
                            if (bookingItem.isDailyBooked || bookingItem.isMonthlyBooked) {
                                if (isSameProperty) {
                                    if (zoneGuid == "" || zoneGuid == bookingItem.zoneGuid) {
                                        zoneName = bookingItem.zoneName;
                                        zoneGuid = bookingItem.zoneGuid;
                                    } else {
                                        isSameProperty = false;
                                    }
                                }
                                if (reservedSpotGuids.indexOf(bookingItem.spotGuid) == -1) {
                                    reservedSpotGuids.push(bookingItem.spotGuid);
                                }
                            }
                        });

                        if (reservedSpotGuids.length > 0) {
                            zoneMsg = isSameProperty ? zoneName : 'across multiple zones';
                            let spotMsg: string = reservedSpotGuids.length > 1 ? `${reservedSpotGuids.length} spot(s) ` : '';
                            let updateStatusSting = zoneStatus_ == 2 ? 'Inactivating' : 'Deleting'

                            ShowAlert(
                                'Error!',
                                `${spotMsg} ${zoneMsg} have active booking(s). <br/> Please cancel the booking(s) and try again.`
                                , "warning");
                        } else {
                            this.updateZoneStatus(guids_, zoneStatus_, zones_, isMapDelete_);
                        }
                    });
                }
            });
    }

    private updateZoneStatus(zoneGuids: string[], spotStatus_: number, zones_: IZone[], isMapDelete_: boolean) {
        this._zoneService.updateMultiZoneStatus(zoneGuids, spotStatus_).then(response => {
            if (response.ok) {
                let allZones_: IZone[] = ObjectHelper.deepClone(this.state.allZones);
                let filteredZones_: IZone[] = ObjectHelper.deepClone(this.state.filteredZones);
                let subfilteredZones_: IZone[] = ObjectHelper.deepClone(this.state.subFilteredZones);

                for (let zoneGuid of zoneGuids) {
                    allZones_.filter(x => x.zoneGuid == zoneGuid).forEach(x => allZones_.splice(allZones_.indexOf(x), 1));
                    filteredZones_.filter(x => x.zoneGuid == zoneGuid).forEach(x => filteredZones_.splice(filteredZones_.indexOf(x), 1));
                    subfilteredZones_.filter(x => x.zoneGuid == zoneGuid).forEach(x => subfilteredZones_.splice(subfilteredZones_.indexOf(x), 1));
                }

                this.setState({
                    allZones: allZones_,
                    filteredZones: filteredZones_,
                    subFilteredZones: subfilteredZones_
                }, () => {
                    if (spotStatus_ == 3) {
                        if (isMapDelete_) {
                            let zoneData = {
                                "propertyGuid": zones_[0].propertyGuid,
                                "levelId": 0
                            } as any;

                            this._privateLotService.ResetMap(zoneData)
                                .then(r => {
                                    // localStorage.removeItem(`asset_property_${zones_[0].propertyGuid}`);
                                    var keys = Object.keys(localStorage).filter(x => x.indexOf("asset_property") != -1 && x.indexOf(zones_[0].propertyGuid) != -1);

                                    keys.forEach(asset_property => {
                                        localStorage.removeItem(asset_property);
                                    });
                                    ShowAlert('', 'Zone deleted successfully', 'success');
                                });
                        }
                        else {
                            ShowAlert('', 'Zone deleted successfully', 'success');
                        }
                    }
                });
            }
        });
    }

    protected filterItems = () => {
        this._isFiltered = false;

        let filteredArray = this.state.allZones.filter(s => {
            let isValid = true;
            if (this.state.appliedFilter && Object.keys(this.state.appliedFilter).length > 1) {
                let filteredProperties = this.state.appliedFilter?.property.filterValue as string[];

                if (filteredProperties && filteredProperties.length > 0 && isValid) {
                    this._isFiltered = true;
                    isValid = filteredProperties.indexOf(s.propertyName) > -1;
                }
            }

            return isValid;
        });
        this.setState({ subFilteredZones: this._filterDataBySearch(filteredArray, this.state.searchTerm) })

    }



}

interface IZoneListProps {

}

interface IZoneListState {
    allZones: IZone[];
    filteredZones: IZone[];
    subFilteredZones: IZone[];
    rightPanelState: RightPanelState;
    searchTerm: string;
    appliedFilter?: IZoneFilter;
    isFiltered: boolean;
    hasUnsavedChanges?: boolean;
    _autoApplyFilterKey: number;
    selectedZone?: IZone;
    isScreenAccessible: boolean;
    loaderPercentage?: number;
    isZoneLoaded: boolean;
}