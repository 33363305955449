export class ConfigService {
    private static config: AppConfigInterface;

    public static getFigmaFileBaseUrl() {
        return this.getConfigJson()
            .then(config => {
                return (config.figma);
            });
    }

    public static getFigmaImageBaseUrl() {
        return this.getConfigJson()
            .then(config => {
                return (config.figma);
            });
    }

    public static getBaseUrl() {
        return this.getConfigJson()
            .then(config => {
                return (config.api_url.base) as string;
            });
    }

    public static getNodeBaseUrl() {
        return this.getConfigJson()
            .then(config => {
                return (config.api_url.nodeBase) as string;
            });
    }

    public static getGuestUrl() {
        return this.getConfigJson()
            .then(config => {
                return (config.api_url.guest) as string;
            });
    }

    public static getDefaultDisbursement() {
        return this.getConfigJson()
            .then(config => {
                return (config.defaultDisbursement);
            });
    }

    public static getPlaid() {
        return this.getConfigJson()
            .then(config => {
                return (config.plaid);
            });
    }

    public static getAppVersion() {
        return this.getConfigJson()
            .then(config => {
                return (config.version);
            });
    }

    public static getReportAccess() {
        return this.getConfigJson()
            .then(config => {
                return (config.reportAccess);
            });
    }

    private static getConfigJson() {
        if (this.config !== undefined) {
            return new Promise<AppConfigInterface>(
                (resolver: (value: AppConfigInterface) => void) => {
                    resolver(this.config);
                }
            );
        }

        return fetch('/appconfig.json')
            .then(r => {
                return r.json()
                    .then((json: AppConfigInterface) => {
                        return this.config = json;
                    });
            });
    }
}

interface AppConfigInterface {
    api_url: {
        base: string;
        nodeBase: string;
        guest: string;
    };
    defaultDisbursement: {
        propertyGrydPercentage: number;
        propertyPropertyPercentage: number;
        propertySpotOwnerPercentage: number;
    };
    plaid: {
        publicKey: string;
        environment: string;
    };
    figma: {
        figmaBase: string;
        figmaToken: string;
    }
    version: string;
    reportAccess: string[];
}