import { Grid, TextField, Button, Checkbox } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { IPropertyBasics } from "../Spots/Interfaces";
import NotificationsOutlined from "@material-ui/icons/NotificationsOutlined";
import { IPrivateLotProperty } from "./interface";
import { IFloor } from "../../Components/SpotMap/interface";


export default class PrivateLotsProperty extends React.Component<IPrivateLotsPropertyProps, IPrivateLotsPropertyState> {
    private autoCompleteInput: any = null;
    constructor(props_: IPrivateLotsPropertyProps) {
        super(props_);

        this.state = {
            selectedProperties: [],
            properties: [],
            selectedMapFloor: null
        }
    }

    componentDidMount() {
        // if (this.autoCompleteInput && this.props.id == 'blocker-property') {
        //     this.autoCompleteInput.querySelector("input").focus();
        // }
        this.setProperties(null);
    }

    componentDidUpdate(oldProps_: IPrivateLotsPropertyProps) {
        if (oldProps_.selectedMapFloor != this.props.selectedMapFloor) {
            this.setState({
                selectedMapFloor: this.props.selectedMapFloor ? this.props.selectedMapFloor : null
            })
        }
        this.setProperties(oldProps_);
    }

    render() {
        return (
            <React.Fragment>
                {/* <Grid container>

                </Grid> */}
                <Grid item style={{ padding: "0px 10px 0px 20px" }}>
                    <Autocomplete
                        multiple
                        // limitTags={1}
                        openOnFocus={true}
                        selectOnFocus={true}
                        disableCloseOnSelect
                        ref={(input) => { this.autoCompleteInput = input; }}
                        className="mt-2"
                        options={this.state.properties}
                        getOptionLabel={option_ => option_.propertyName as string}
                        style={{ width: 250 }}
                        size="small"
                        value={this.state.selectedProperties}
                        renderInput={(params) => (<TextField {...params} label="Property"
                            // onKeyDown={event => {
                            //     var charCode = (event.which) ? event.which : event.keyCode;
                            //     if (charCode == 13) {
                            //         if (this.props.onPropertyChange) {
                            //             this.props.onPropertyChange(this.state.selectedProperty);
                            //         }
                            //     }
                            // }}
                            error={this.state.selectedProperties.length == 0}
                            variant="outlined" />)}
                        renderTags={(tagValue, getTagProps) => {
                            let propertyNames = tagValue.map(x => {
                                return x.propertyName
                            });

                            if (tagValue.length > 0 && propertyNames.indexOf("All Properties") != -1) {
                                propertyNames = [propertyNames[0]];
                            }

                            return <div className="selected-properties">{propertyNames.toString().replaceAll(",", ", ")}</div>;
                        }}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <div className="cl-dropdown-list">
                                    {!this.props.isMultiSelectNotRequired && (<Checkbox
                                      icon={<div className="repeat-b-check"/>}
                                      checkedIcon={<div className="repeat-b-check selected"/>}
                                      style={{marginRight: 8}}
                                      checked={selected}
                                      color="primary"
                                    />)}
                                    {option.propertyName}
                                </div>
                            </React.Fragment>
                        )}
                        onChange={(e_: any, property_: IPrivateLotProperty[]) => {

                            let isAllAlreadySelected: boolean = this.state.selectedProperties.filter(x => x.propertyGuid == 'blank').length > 0;
                            let isAllSelected: boolean = property_.filter(x => x.propertyGuid == 'blank').length > 0;
                            let selectedValue: IPrivateLotProperty[] = [];

                            if (this.props.isMultiSelectNotRequired) {
                                let selectedProperty: IPrivateLotProperty = this.state.selectedProperties?.length === 1 ? this.state.selectedProperties[0] : {
                                    propertyGuid: "",
                                    propertyName: "",
                                    openTimeString: "",
                                    closeTimeString: "",
                                    zoneInfo: [],
                                    isYardiProperty: false,
                                    yardiRedirectURL: ""
                                };
                                const property = property_.filter(p => p.propertyGuid !== selectedProperty.propertyGuid);
                                selectedValue = property;
                            }
                            else {
                                if ((!isAllAlreadySelected && !isAllSelected) && property_.length == (this.state.properties.length - 1) || (isAllSelected && !isAllAlreadySelected)) {
                                    selectedValue = this.state.properties;
                                } else if ((isAllAlreadySelected && isAllSelected) && property_.length != this.state.properties.length) {
                                    let blankIndex: number = property_.map(i => i.propertyGuid).indexOf("blank");
                                    selectedValue = property_;
                                    selectedValue.splice(blankIndex, 1);
                                } else if (isAllAlreadySelected && !isAllSelected) {
                                    selectedValue = [];
                                }
                                else {
                                    selectedValue = property_;
                                }
                            }

                            // if (selectedValue.length == 0) {
                            //     selectedValue = this.state.properties;
                            // }

                            this.setState({
                                selectedProperties: selectedValue
                            });

                            /*
                            , () => {
                                if (this.props.onPropertyChange) {
                                    this.props.onPropertyChange(selectedValue);
                                }
                            }
                            */

                            // if (this.props.onPropertyChange) {
                            //     this.props.onPropertyChange(property_);
                            // }
                        }}
                    // disabled={this.state.selectedProperty == null && this.props.id != 'blocker-property'}
                    />
                </Grid>
                {
                    (this.props.isMapView == true && this.props.floorLevel) &&
                    <Grid item>
                        <Autocomplete
                            className="mt-2 root-map-location"
                            id="upload-map-locations-floor"
                            options={this.prepareFloorList()}
                            value={this.state.selectedMapFloor}
                            openOnFocus={true}
                            selectOnFocus={true}
                            size="small"
                            onChange={(event: any, value: IFloor | null) => {
                                this.setState({
                                    selectedMapFloor: value
                                })
                            }}
                            getOptionLabel={(option) => option.levelName}
                            renderInput={(params) => <TextField {...params}
                                placeholder="Select Level"
                                variant="outlined"
                                required={true}
                                error={this.props.selectedMapFloor == null}
                            />}
                        />
                    </Grid>
                }
                <Grid item>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className='mt-2 c-go-button'
                        onClick={() => {
                            if (this.props.onPropertyChange) {
                                this.props.onPropertyChange(this.state.selectedProperties, this.state.selectedMapFloor);
                            }
                        }}
                        disabled={this.state.selectedProperties.length == 0 ||
                            (this.props.isMapView == true && this.state.selectedMapFloor == null)}
                    >
                        Go
                    </Button>
                </Grid>
            </React.Fragment>
        )
    }

    private prepareFloorList() {
        let floors:IFloor[] = [];

        if (this.props.floorLevel) {
            floors = this.props.floorLevel.filter(f => f.hasMap);

            if (floors.length == 0) {
                floors = this.props.floorLevel;
            }
        }

        return floors;
    }

    private setProperties(oldProps_: IPrivateLotsPropertyProps | null) {
        if (!oldProps_ || (JSON.stringify(this.props.properties) !== JSON.stringify(oldProps_.properties)) ||
            (this.props.allPropertyNotRequired !== oldProps_.allPropertyNotRequired) ||
            (this.props.isMultiSelectNotRequired !== oldProps_.isMultiSelectNotRequired)) {
            const hasAllInProperties = this.props.properties?.find((property: IPrivateLotProperty) => property.propertyName === "All Properties");
            let properties_: IPrivateLotProperty[] = hasAllInProperties ? this.props.properties.slice(1) : this.props.properties;
            const hasAllInSelectProperty = this.props.selectedProperties?.find((property: IPrivateLotProperty) => property.propertyName === "All Properties");
            let selectedProperties_: IPrivateLotProperty[] = hasAllInSelectProperty ? this.props.selectedProperties.slice(1) : this.props.selectedProperties;
            //let properties_: IPrivateLotProperty[] = this.props.properties.slice(0);

            let selectAll: IPrivateLotProperty = {
                propertyName: "All Properties",
                propertyGuid: "blank",
                openTimeString: "",
                closeTimeString: "",
                zoneInfo: [],
                isYardiProperty: false,
                yardiRedirectURL: ""
            }
            if (this.props.properties?.length === this.props.selectedProperties?.length && !this.props.allPropertyNotRequired) {
                selectedProperties_.unshift(selectAll);
            }

            if (properties_.length > 0 && !this.props.allPropertyNotRequired) {
                properties_.unshift(selectAll);
            }

            properties_.map(e => {
                e.openTimeString = "12:00 AM";
                e.closeTimeString = "11:59 PM";

                return e;
            });

            if ((this.props.selectedProperties.length == 1 && this.props.selectedProperties[0].propertyGuid == "blank") ||
                this.props.selectedProperties.length == properties_.length) {
                selectedProperties_ = properties_.slice(0);
            } else {
                var filteredObject = properties_.filter(x => {
                    return JSON.stringify(this.props.selectedProperties).indexOf(x.propertyGuid) != -1;
                })
                selectedProperties_ = filteredObject.slice(0);
                // selectedProperties_ = this.props.selectedProperties.slice(0);
            }

            // console.log(this.props.selectedProperties, selectedProperties_);

            this.setState({
                properties: properties_.slice(0),
                // selectedProperties: oldProps_ != null ? selectedProperties_ : properties_.slice(0)
                selectedProperties: selectedProperties_
            });
        } else if (!oldProps_ || (JSON.stringify(this.props.selectedProperties) != JSON.stringify(oldProps_.selectedProperties))) {
            this.setState({
                selectedProperties: this.props.selectedProperties
            });
        }
    }
}

interface IPrivateLotsPropertyProps {
    properties: IPrivateLotProperty[];
    onPropertyChange: (value: IPrivateLotProperty[], floorValue?: IFloor | null) => void;
    selectedProperties: IPrivateLotProperty[];
    id: string;
    allPropertyNotRequired: boolean;
    isMultiSelectNotRequired?: boolean;
    floorLevel?: IFloor[];
    selectedMapFloor?: IFloor | null;
    isMapView?: boolean;
}

interface IPrivateLotsPropertyState {
    properties: IPrivateLotProperty[];
    selectedProperties: IPrivateLotProperty[];
    selectedMapFloor: IFloor | null;
}
