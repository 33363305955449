import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { Dialog, AppBar, Toolbar, IconButton, Typography, Button, List, ListItem, ListItemText, Divider, Slide } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions/transition';
// import { Carousel } from 'react-responsive-carousel';
import Carousel from 'react-bootstrap/Carousel';
import ItemsCarousel from 'react-items-carousel';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { IFileInfo } from './BasicComponents/FileUploadModal';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            // overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
            margin: '10px 0px'
        },
        gridList: {
            width: 500,
            // height: 450,
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)',
        },
        gridListTile: {
            cursor: 'pointer'
        },
        appBar: {
            position: 'relative',
        },
        title: {
            color: 'rgba(0, 0, 0, 0.54)',
            marginLeft: theme.spacing(2),
            flex: 1,
            textAlign: 'left'
        },
        carouselContainer: {
            maxHeight: 500,
            maxWidth: 950,
            margin: 'auto',
            background: '#fffbfb',
            width: '100%',
            height: 500
        },
        carouselImg: {
            maxHeight: 500
        }
    }),
);

export default class ImageGrid extends React.Component<IImageGridProps, IImageGridState> {
    constructor(props: IImageGridProps) {
        super(props);
    }

    // componentDidUpdate() {
    //     console.log(this.props);
    // }

    public render() {
        // console.log(this.props.imageData);
        return (
            <CreateImageGridList imageData={this.props.imageData} CarouselTitle={this.props.CarouselTitle} />
        )
    }
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function CreateImageGridList(props: { imageData: IFileInfo[], CarouselTitle: string }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [index, setIndex] = React.useState(0);

    const handleClickOpen = (imgIndex: number) => {
        setIndex(imgIndex);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex);
    };

    const loadImages = () => {
        return props.imageData.map((img, i) => {
            return <div onClick={() => handleClickOpen(i)} className="carousel-item-custom" key={i} style={{ height: 200, background: 'url("' + encodeURI(img.fileUrl) + '") no-repeat' }} />;
        })
    }



    return (
        <div className={classes.root}>
            {
                props.imageData.length ?
                    <div className="carousel-wrapper-custom">
                        <ItemsCarousel
                            // Placeholder configurations
                            enablePlaceholder
                            numberOfPlaceholderItems={2}
                            minimumPlaceholderTime={1000}
                            placeholderItem={<div style={{ height: 200, background: '#900' }}>Placeholder</div>}

                            // Carousel configurations
                            numberOfCards={2}
                            gutter={12}
                            showSlither={true}
                            firstAndLastGutter={true}
                            freeScrolling={false}

                            // Active item configurations
                            requestToChangeActive={handleSelect}
                            activeItemIndex={index}
                            activePosition={'center'}

                            chevronWidth={60}
                            rightChevron={
                                <IconButton
                                    size="small"
                                    edge="start"
                                    color="secondary"
                                    aria-label="Next">
                                    <ChevronRightIcon />
                                </IconButton>
                            }
                            leftChevron={
                                <IconButton
                                    size="small"
                                    edge="start"
                                    color="secondary"
                                    aria-label="Prev">
                                    <ChevronLeftIcon />
                                </IconButton>
                            }
                            outsideChevron={false}
                        >
                            {
                                loadImages()
                            }
                        </ItemsCarousel>
                    </div>
                    : null
            }


            {/* <GridList cellHeight={160} className={classes.gridList} cols={2}>
                {props.imageData.map((tile, imgIndex) => (
                    <GridListTile key={tile.thumbnailUrl + imgIndex} className={classes.gridListTile} onClick={() => handleClickOpen(imgIndex)}>
                        <img src={tile.thumbnailUrl} alt={tile.fileName} />
                    </GridListTile>
                ))}
            </GridList> */}
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="default"
                            onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>{props.CarouselTitle}</Typography>
                    </Toolbar>
                </AppBar>
                <Carousel
                    activeIndex={index}
                    onSelect={handleSelect}
                    className={classes.carouselContainer + (props.imageData.length == 1 ? " hide-controls" : "")}
                >
                    {props.imageData.map((tile) => (
                        // <div>
                        //     <img src={tile.imageUrl} />
                        //     <p className="legend">{tile.imageName}</p>
                        // </div>
                        <Carousel.Item>
                            <div className="carousel-img-boots" style={{ background: 'url("' + encodeURI(tile.fileUrl as string) + '") no-repeat' }}></div>
                            {/* <img
                                className={classes.carouselImg + ' d-block w-100'}
                                src={tile.optimizedFileUrl}
                                alt={tile.fileName}

                            /> */}
                            {/* <Carousel.Caption>
                                <h3>{tile.imageName}</h3>
                                <p>{tile.imageDiscription}</p>
                            </Carousel.Caption> */}
                        </Carousel.Item>
                    ))}

                </Carousel>
            </Dialog>
        </div>
    );
}

interface IImageGridProps {
    imageData: IFileInfo[],
    CarouselTitle: string
}

interface IImageGridState { }


