import * as React from "react";
import "../../Login/Login.css";
import { ConfigService } from "../../Services/ConfigService";
import * as CommonString from "../../CommonString";
import { ShowAlert, ShowAlertwithConfirm } from '../../Common/ShowAlert';

/*Material UI */
import logo from "../../Images/gryd_logo.png";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import BaseService from "../../Services/BaseService";

class ActivateParker extends React.Component<
  IActivateParkerProps,
  IActivateParkerState
> {
  constructor(props: IActivateParkerProps) {
    super(props);

    this.state = {
      ShortLiveGuid: this.props.match.params.shortLiveGuid
        ? this.props.match.params.shortLiveGuid
        : "",
      showBlocker: false,
      isValidGuid: false,
      verificationText: "",
    };
  }

  public componentDidMount = () => {
    if (this.state.ShortLiveGuid != "") {
      this.setState({
        showBlocker: true,
        verificationText: "Account activation in progress..."
      });

      this.ActivateParkerHandler(this.state.ShortLiveGuid).then((response) => {
        if (response.ok) {
          response.text().then((resp) => {
            this.setState({
              verificationText: "You have successfully activated your account.",
              showBlocker: false
            }, () => {
              ShowAlert(
                "Activated!",
                "You have successfully activated your account.",
                "success"
              ).then((resp) => {
                localStorage.clear();
                window.location.href = "https://parkwithgryd.com/";
              });
            })
          });
        } else {
          response.text().then((resp) => {
            console.log(resp);
            this.setState({
              verificationText: "Your account activation link has expired or invalid.",
              showBlocker: false
            }, () => {
              ShowAlert(
                "Error!",
                "Your account activation link has expired or invalid.",
                "error"
              ).then((resp) => {
                localStorage.clear();
                window.location.href = "https://parkwithgryd.com/";
              });
            });
          });
        }
      });
    }
  };

  public render() {
    return (
      <div
        className={
          "App login-page" + (this.state.showBlocker ? " show-blocker" : "")
        }
      >
        <Grid container component="main" className="root">
          <CssBaseline />
          {/* <Grid item xs={false} sm={4} md={4} className='image' /> */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            component={Paper}
            elevation={6}
            square
            className="login-form-container"
          >
            <div className="login-paper">
              <img src={logo} className="login-logo" />
              <div style={{
                minHeight: '350px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center'
              }}>
                <h1 className="gryd-h1">
                  {
                    this.state.verificationText
                  }
                </h1>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  private ActivateParkerHandler(emailGUID: string) {
    return ConfigService.getBaseUrl().then((url) => {
      return BaseService.sendRequest(url + CommonString.SERVICE_REACTIVATE + emailGUID,
        {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }, 'ignoreAlert');
    });
  }
}

interface IActivateParkerProps {
  match: {
    params: {
      shortLiveGuid: string;
    };
  };
}

interface IActivateParkerState {
  ShortLiveGuid: string;
  verificationText: string;
  showBlocker: boolean;
  isValidGuid: boolean;
}

export default ActivateParker;