import React from 'react';
import { Grid } from '@material-ui/core';
import { ConfigService } from '../../Services/ConfigService';

class Help extends React.Component<HelpProps, HelpState> {
    constructor(props: HelpProps) {
        super(props);

        this.state = {
            version: ""
        }
    }

    public componentDidMount = () => {
        ConfigService.getAppVersion()
            .then(d => {
                this.setState({
                    version: d
                })
            });
    }

    public render() {

        return (
            <div className="m-3 data-grid-container dashboard-container">
                <Grid container className="mb-3">
                    <Grid item>
                        <h1 className="list-header">About GrydPark - {this.state.version}</h1>
                    </Grid>
                </Grid>
                {/* <img src={dashboardImg} width="100%" /> */}
            </div>
        )
    }


}

interface HelpProps { }
interface HelpState {
    version: string;
}

export default Help;