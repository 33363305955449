// import { Button } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import React from "react";
import { DateTimeHelper } from "../../Helpers/DateTimeHelper";
import StringHelper from "../../Helpers/StringHelper";
import { IMapBookingModel, ISpotBooking } from "./interface";
import "./ResolveTenant.css";
import moment from 'moment';

export default class AssignTenant extends React.Component<IAssignTenantProps, IAssignTenantState> {

    constructor(props_: IAssignTenantProps) {
        super(props_);
        this.state = {
        }
    }

    componentDidMount() {

    }
    render() {
        const { getSpotBookingStatus, spotGuid } = this.props;
        const { isAvailable, isBookedSpot, isOverDue, isUpComingSpot,
            existingUser, illegalParking, spotBooking,
            noOfDays, isIllegalParking, isUnAvailable, isVisitorParking } = getSpotBookingStatus(spotGuid);

        if (isAvailable || isBookedSpot || isUnAvailable || isVisitorParking)
            return false;

        const spotNo = illegalParking ? illegalParking.friendlySpotId : spotBooking?.friendlySpotId ?? "0";

        let basePadding = "base-root-view-padding";//!isIllegalParking ? "base-root-view-padding" : "base-root-padding";
        let baseContainer = "base-root-view-container";//!isIllegalParking ? "base-root-view-container" : "base-root-container";
        let headerColor = isIllegalParking || isOverDue ? "bg-red-color" : "bg-yellow-color";
        const existingTenantDetails = existingUser !== null;
        const parkerFirstName = spotBooking?.parkerFirstName ?? "";
        const parkerLastName = spotBooking?.parkerLastName ?? "";
        const fullName = `${parkerFirstName} ${parkerLastName}`.trim();
        let existingFullName = "";
        if (existingTenantDetails) {
            const existParkerFirstName = existingUser?.parkerFirstName ?? "";
            const existParkerLastName = existingUser?.parkerLastName ?? "";
            existingFullName = `${existParkerFirstName} ${existParkerLastName}`.trim();
        }
        return (
            <div className={`base-root-view ${basePadding}`}>
                <div className={baseContainer}>
                    <div className={`base-root-view-head ${headerColor}`}>
                        <div className="base-root-head-body">
                            <div>
                                <label className="base-root-head-label mb-px-0 m-l-20-px">Spot No.</label>
                                <label className="base-root-head-label mb-px-0 m-l-5-px">{spotNo}</label>
                            </div>
                        </div>
                    </div>
                    {
                        (!isIllegalParking && isUpComingSpot) && <div>
                            <div className="base-root-detail">
                                <div>
                                    This spot will become available on <b>{spotBooking ? DateTimeHelper.GetMonthYearFormat(new Date(spotBooking.endTimeLocal), 1) : ""}</b>, please renew if you need to.
                                </div>
                                <div className="base-root-divider"></div>
                                <label className="base-root-label m-t-b">Tenant Details</label>
                                <label className="base-root-label m-0-px f-w-400">{spotBooking?.unitNumber}</label>
                                {fullName && <label className="base-root-label m-0-px f-w-400">{fullName}</label>}
                                {spotBooking?.parkerEmail && <label className="base-root-label m-0-px f-w-400">{spotBooking?.parkerEmail}</label>}
                                {spotBooking?.parkerPhoneNumber && <label className="base-root-label m-0-px f-w-400">{StringHelper.ConvertPhoneNumberHyphen(spotBooking?.parkerPhoneNumber)}</label>}

                                <Grid container className="mb-p mt-3">
                                    <Grid item xs className="p-2-px">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => {
                                                spotBooking && this.props.onRenew(spotBooking, true);
                                            }}>
                                            Renew Lease
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    }
                    {
                        (!isIllegalParking && isOverDue) && <div>
                            <div className="base-root-detail">
                                <div>
                                    This lease is <b>{noOfDays} days overdue</b>. Please resolve this immediately.
                                </div>
                                <div className="base-root-divider"></div>
                                <label className="base-root-label m-t-b">Tenant Details</label>
                                <label className="base-root-label m-0-px f-w-400">{spotBooking?.unitNumber}</label>
                                {fullName && <label className="base-root-label m-0-px f-w-400">{fullName}</label>}
                                {spotBooking?.parkerEmail && <label className="base-root-label m-0-px f-w-400">{spotBooking?.parkerEmail}</label>}
                                {spotBooking?.parkerPhoneNumber && <label className="base-root-label m-0-px f-w-400">{StringHelper.ConvertPhoneNumberHyphen(spotBooking?.parkerPhoneNumber)}</label>}

                                <Grid container className="mb-p mt-3 renew-action-container">
                                    <Grid item className="p-2-px">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={() => {
                                                spotBooking && this.props.onRenew(spotBooking, false);
                                            }}>
                                            Renew Lease
                                        </Button>
                                    </Grid>
                                    <Grid item xs className="p-2-px">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={() => {
                                                spotBooking && this.props.removeAccess(spotBooking);
                                            }}>
                                            Remove Access
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    }
                    {
                        isIllegalParking && <div>
                            <div className="base-root-detail">
                                <label className="base-root-label m-t-b">Illegal Parker Details</label>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <label className="base-root-label m-0-px f-w-400">Make: {illegalParking?.make}</label>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label className="base-root-label m-0-px f-w-400">Model: {illegalParking?.model}</label>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label className="base-root-label m-0-px f-w-400">Colour: {illegalParking?.color}</label>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label className="base-root-label m-0-px f-w-400">License Plate: {illegalParking?.licensePlate}</label>
                                    </Grid>
                                </Grid>
                                {existingTenantDetails && <Grid container>
                                    <Grid item xs={12}>
                                        <label className="base-root-label red-color f-w-400 m-t-b">This vehicle belong to an existing tenant:</label>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label className="base-root-label m-0-px f-w-400">{existingUser?.unitNumber}</label>
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <label className="base-root-label m-0-px f-w-400">{existingUser?.licensePlate}</label>
                                    </Grid> */}
                                    {existingFullName && <Grid item xs={12}>
                                        <label className="base-root-label m-0-px f-w-400">{existingFullName}</label>
                                    </Grid>
                                    }
                                    {existingUser?.parkerEmail && <Grid item xs={12}>
                                        <label className="base-root-label m-0-px f-w-400">{existingUser?.parkerEmail}</label>
                                    </Grid>
                                    }
                                    {existingUser?.parkerPhoneNumber && <Grid item xs={12}>
                                        <label className="base-root-label m-0-px f-w-400">{StringHelper.ConvertPhoneNumberHyphen(existingUser?.parkerPhoneNumber)}</label>
                                    </Grid>
                                    }
                                </Grid>
                                }
                                <div className="base-root-divider"></div>
                                <label className="base-root-label m-t-b">Spot Assigned To</label>
                                {spotBooking && <>
                                    {
                                        spotBooking.bookedType == 3 && <div>
                                            <label style={{ "marginTop": '10px', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '12px', color: '#111012' }}>Visitor License Plate(s)</label>
                                            <div className="grid">
                                                <div className="item" style={{}}>
                                                    <label style={{ 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'center', 'height': '100%', 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '15px', color: '#111012', alignItems: 'center' }}>{spotBooking.licenseNo}</label>
                                                </div>
                                            </div>
                                            <label style={{ "marginTop": '10px', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '12px', color: '#111012' }}>Duration</label><br/>
                                            <label style={{ 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '15px', color: '#111012' }}>{this.getDuration(spotBooking)}</label><br/>
                                            <div style={{ 'width': '100%', 'height': '1px', backgroundColor: '#DADDDF', 'marginTop': '5px' }}></div>
                                            <label style={{ "marginTop": '10px', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '12px', color: '#111012' }}>Host Details:</label>
                                        </div>
                                    }
                                    <label className="base-root-label m-0-px f-w-400">{spotBooking?.unitNumber}</label>
                                    {fullName && <label className="base-root-label m-0-px f-w-400">{fullName}</label>}
                                    {spotBooking?.parkerEmail && <label className="base-root-label m-0-px f-w-400">{spotBooking?.parkerEmail}</label>}
                                    {spotBooking?.parkerPhoneNumber && <label className="base-root-label m-0-px f-w-400">{StringHelper.ConvertPhoneNumberHyphen(spotBooking?.parkerPhoneNumber)}</label>}
                                </>}
                                {
                                    !spotBooking && <label className="base-root-label m-0-px f-w-400">No spot assigned</label>
                                }
                                <div className="base-root-divider"></div>
                                <label className="base-root-label m-t-b red-color">Number of time reported: {illegalParking?.reportedTime ?? 0}</label>
                                <Grid container className="mb-10-px mt-3">
                                    <Grid item xs className="p-2-px">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => {
                                                illegalParking && this.props.onResolve(illegalParking.reportParkerGuid, illegalParking.friendlySpotId);
                                            }}>
                                            Resolve
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    }
                </div>
            </div >
        )
    }

    private getDuration(spotBooking: any) {
        var startTime = moment(spotBooking?.spotStartTimeLocal)
        var endTime = moment(spotBooking?.spotEndTimeLocal)
        var DurationInMinutes = endTime.diff(startTime, 'minutes')
        var DurationInhours = endTime.diff(startTime, 'hours')

        return `${DurationInMinutes < 60 ? `${DurationInMinutes} Minutes` : `${DurationInhours} Hours`} | ${moment(spotBooking?.startTimeLocal).format('MMMM DD, YYYY')} `;
    }
}

interface IAssignTenantState {
}

interface IAssignTenantProps {
    mapBookingModel?: IMapBookingModel | null,
    onRenew: (spotBooking: ISpotBooking, isRenewUpcoming: boolean) => void;
    removeAccess: (spotBooking: ISpotBooking) => void;
    onResolve: (reportParkerGuid: string, spotNo: string) => void;
    spotGuid: string,
    getSpotBookingStatus: (spotGuid: string) => any;
}