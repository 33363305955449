import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import { Autocomplete } from "@material-ui/lab";
import { Grid } from '@material-ui/core';
import GrydCalendarPicker from "../BookingsCalendar/GrydCalendarPicker";
import GrydDateRangePicker from "../BookingsCalendar/GrydDateRangePicker";
import { EnumCalendarPosition, IBlockSpot } from "../BookingsCalendar/interfaces";
import moment from 'moment';
import React from "react";
import "./AssignTenant.css";
import { DateRange } from "materialui-daterange-picker";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { DateTimeHelper } from "../../Helpers/DateTimeHelper";
import { format } from "date-fns";
import TickSVG from '../../Images/tick.svg';
import { ShowAlert, ShowAlertwithConfirm } from '../../Common/ShowAlert';
import { ISpotOption } from "./interface";
import { IOption } from "export-from-json/dist/types/exportFromJSON";



export default class AssignVisitor extends React.Component<IAssignVisitorProps, IAssignVisitorState> {

    constructor(props_: IAssignVisitorProps) {
        super(props_);
        this.state = {
            spotNumber: null,
            unitNumber: "",
            passcode: "",
            loginGuid: this.props.loginGuid,
            availableSpotNumbers: ["1", "2", "3", "4", "5", "6"],
            date_
                : {
                endDate: new Date(),
                startDate: new Date()
            },
            times: this.getTimes(),
            selectedStartTime: {
                dateTime: new Date(),
                timeString: "12:00 PM"
            },
            selectedEndTime: {
                dateTime: new Date(),
                timeString: "05:00 PM"
            },
            isBookingConfirm: this.props.isBookingConfirm,
            startDateTime: "",
            endDateTime: "",
            emailAddress: "",
            mobileNumber: "",
            licensePlate: "",
            spotNumbers: this.props.spotNumbers,
            issenttoEmail: false,
            issenttoPhone: false,
            isdisableSpotPicker: false,



        }
    }
    componentDidMount() {
        // this.getTimes()
        const startTime = DateTimeHelper.getCurrentArrangeTime(new Date());
        const endTime = DateTimeHelper.getEndTime(startTime, 60);

        const selectedStartTimes = this.state.times.filter(x => x.timeString === startTime)[0];
        const selectedEndTimes = this.state.times.filter(x => x.timeString === endTime)[0];
        this.setState({
            selectedStartTime: selectedStartTimes,
            selectedEndTime: selectedEndTimes
        })
    }
    componentDidUpdate(oldprops: IAssignVisitorProps) {
        if (oldprops.loginGuid != this.props.loginGuid) {
            console.log("called", this.props.loginGuid);
            this.setState({
                loginGuid: this.props.loginGuid,
            })
        } else if (oldprops.isBookingConfirm != this.props.isBookingConfirm) {
            this.setState({
                isBookingConfirm: this.props.isBookingConfirm
            })
        }
        else if (oldprops.spotNumbers != this.props.spotNumbers) {
            let selectedSpot = this.props.spotNumbers.filter(s => s.friendlySpotName == this.props.spotNumber)
            this.setState({
                spotNumbers: this.props.spotNumbers,
                spotNumber: selectedSpot.length > 0 ? selectedSpot[0] as ISpotOption : null
            }, () => {
                this.setState({
                    isdisableSpotPicker: this.state.spotNumber ? true : false
                })
                //console.log("spot number", this.props.spotNumber);
            })
        }
    }



    private getTimes(isEndTime: boolean = false) {
        let hours: IBlockTime[] = [];

        //if (this.props.selectedProperty && this.props.selectedProperty.openTimeString && this.props.selectedProperty.closeTimeString) {
        let currentDayDate: Date = new Date(new Date().setHours(0, 0, 0, 0));
        let minMinutes = DateTimeHelper.timeStringToMinutes("12:00 AM");
        let maxMinutes = DateTimeHelper.timeStringToMinutes("11:59 PM");
        let minTime: Date = new Date(new Date().setHours(0, 0, 0, 0));
        let maxTime: Date = new Date(new Date().setHours(0, 0, 0, 0));
        minTime.setMinutes(minMinutes);
        maxTime.setMinutes(maxMinutes);

        let totalHours: number = 97;
        let startIndex: number = 0;

        for (let i = startIndex; i < totalHours; i++) {
            let currentDate = new Date(currentDayDate);
            currentDate.setMinutes((i / 4) * 60);
            let outputDate = new Date(currentDate);

            if (outputDate.getTime() >= maxTime.getTime()) {
                outputDate = maxTime;
            }

            let time: IBlockTime = {
                dateTime: outputDate,
                timeString: format(outputDate, "hh:mm aa")
            }

            if (outputDate.getTime() >= minTime.getTime() && outputDate.getTime() <= maxTime.getTime()) {
                hours.push(time);
            }
        }

        if (isEndTime) {
            hours.splice(0, 1);
        }
        else {
            hours.splice(hours.length - 1, 1);
        }

        //}

        return hours;
    }
    validateEmail = (email: string) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase())
    }


    render() {
        return (
            <div style={{ margin: '10px', marginBottom: '0px' }} className="padd-tenant-container">
                {
                    this.state.loginGuid == "" && (
                        <div className="padd-tenant-container"><div className="add-tenant-pl-panel-body">
                            <label style={{ color: 'black', 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '15px' }}>To book a visitor parking spot, you must assign the visitor to a tenant.</label>

                            <label style={{ 'marginTop': '10px', color: '#323232', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '14px' }}>Unit Number</label>

                            <TextField style={{ 'marginTop': '10px' }} fullWidth id="outlined-basic" size="medium" label="" variant="outlined" color="primary" autoComplete="off" placeholder="Enter unit number" value={this.state.unitNumber}
                                inputProps={{ maxLength: 200, }}
                                onChange={(e: any) => {
                                    this.setState({
                                        unitNumber: e.target.value
                                    })
                                }}

                                onFocus={(e: any) => {

                                }}
                                onBlur={(e: any) => {


                                }}
                            />

                            <label style={{ 'marginTop': '10px', color: '#323232', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '14px' }}>Passcode</label>

                            <TextField style={{ 'marginTop': '10px' }} type="password" fullWidth id="outlined-basic" size="medium" label="" variant="outlined" color="primary" autoComplete="new-password" placeholder="Enter passcode" value={this.state.passcode}
                                inputProps={{ maxLength: 200, }}
                                onChange={(e: any) => {
                                    this.setState({
                                        passcode: e.target.value
                                    })
                                }}

                                onFocus={(e: any) => {

                                }}
                                onBlur={(e: any) => {


                                }}
                            />
                        </div>
                            <div style={{ 'display': 'flex', 'flexDirection': 'row-reverse', 'width': '95%' }}>
                                <Button style={{ width: '100px', height: '40px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: 700, borderRadius: '8px', color: 'white' }}

                                    onClick={() => {
                                        this.props.handelNext(this.state.unitNumber, this.state.passcode)


                                    }}>
                                    Next
                                </Button>

                                <Button style={{ width: '100px', height: '40px', backgroundColor: '#DADDDF', fontSize: "15px", fontFamily: 'Manrope', fontWeight: 700, borderRadius: '8px', color: '#6B7278', marginRight: '10px' }}
                                    onClick={() => {
                                        this.props.handelCancle();
                                    }}
                                >Cancel</Button>
                            </div></div>) || (
                        <>
                            {
                                this.state.isBookingConfirm && (<div className="padd-tenant-container">
                                    <div className="add-tenant-pl-panel-body">
                                        <div style={{ 'display': 'flex', 'flexDirection': 'column', width: '100%' }}>
                                            <div style={{ alignItems: 'center', 'display': 'flex', 'flexDirection': 'column' }}>
                                                <img style={{ "width": '42px', 'height': '42px', }} src={TickSVG}></img>
                                                <label style={{ marginTop: '10px', color: '#323232', 'fontFamily': 'Manrope', 'fontWeight': 600, 'fontSize': '18px' }}>Visitor Parking Booked</label>
                                                <label style={{ color: '#323232', 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '16px' }}>{[`Visitor parking successfully booked for `, <b>{`Spot no.${this.state.spotNumber?.friendlySpotName}`}</b>]}</label>
                                                <label style={{ color: '#323232', 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '16px', textAlign: "center" }}>{[`Date: ${moment(this.state.startDateTime).format('DD-MMM-YYYY')} - ${moment(this.state.endDateTime).format('DD-MMM-YYYY')}`, <br />, `Time: ${moment(this.state.startDateTime).format('hh-mm-a')} - ${moment(this.state.endDateTime).format('hh-mm-a')}`]}</label>
                                                <div style={{ 'marginTop': '20px', 'width': '100%', 'height': '1px', 'backgroundColor': '#DADDDF' }}>
                                                </div>
                                                <label style={{ marginTop: '20px', color: '#323232', 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '14px' }}>Send a copy the receipt by email or phone.</label>
                                                <div style={{ 'display': 'flex', 'flexDirection': 'column', width: '100%' }}>
                                                    <label style={{ color: '#323232', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '14px' }}>Email Address</label>
                                                    <TextField style={{}} fullWidth id="outlined-basic" size="medium" label="" variant="outlined" color="primary" autoComplete="off" placeholder="Enter email address" value={this.state.emailAddress}
                                                        inputProps={{ maxLength: 200, }}
                                                        onChange={(e: any) => {
                                                            this.setState({
                                                                emailAddress: e.target.value,
                                                                issenttoEmail: false
                                                            })

                                                        }}
                                                        onFocus={(e: any) => {
                                                        }}
                                                        onBlur={(e: any) => {
                                                        }}
                                                    />
                                                    {

                                                        this.state.issenttoEmail && (
                                                            <label style={{ color: "#00AF85", 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '12px', 'marginLeft': '10px' }}>Sent Receipt</label>
                                                        )
                                                    }
                                                </div>
                                                <label style={{ color: '#323232', 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '14px', 'marginTop': '10px' }}>OR</label>
                                                <div style={{ 'display': 'flex', 'flexDirection': 'column', width: '100%' }}>
                                                    <label style={{ color: '#323232', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '14px' }}>Phone Number</label>
                                                    <TextField style={{}} fullWidth id="outlined-basic" size="medium" label="" variant="outlined" color="primary" autoComplete="off" placeholder="Enter phone number" value={this.state.mobileNumber}
                                                        inputProps={{ maxLength: 200, }}
                                                        onChange={(e: any) => {
                                                            this.setState({
                                                                mobileNumber: e.target.value,
                                                                issenttoPhone: false
                                                            })
                                                        }}
                                                        onFocus={(e: any) => {
                                                        }}
                                                        onBlur={(e: any) => {
                                                        }}
                                                    />
                                                    {

                                                        this.state.issenttoPhone && (
                                                            <label style={{ color: "#00AF85", 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '12px', 'marginLeft': '10px' }}>Sent Receipt</label>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ padding: '0px 23px 0px 18px' }}>
                                        <Button
                                            disabled={(this.validateEmail(this.state.emailAddress) || this.state.mobileNumber.length > 8) ? false : true}
                                            style={{ width: '100%', height: '40px', backgroundColor: (this.validateEmail(this.state.emailAddress) || this.state.mobileNumber.length > 8) ? '#00AF85' : "#8A959E", fontSize: "15px", fontFamily: 'Manrope', fontWeight: 400, borderRadius: '8px', color: 'white' }}
                                            onClick={() => {
                                                if (this.validateEmail(this.state.emailAddress) || this.state.mobileNumber.length > 8) {
                                                    if (this.state.emailAddress != "" && this.state.mobileNumber != "") {
                                                        this.setState({
                                                            issenttoEmail: true,
                                                            issenttoPhone: false
                                                        })
                                                    }
                                                    else if (this.state.emailAddress != "") {
                                                        this.setState({
                                                            issenttoEmail: true,
                                                            issenttoPhone: false
                                                        })
                                                    } else if (this.state.mobileNumber != "") {
                                                        this.setState({
                                                            issenttoPhone: true,
                                                            issenttoEmail: false
                                                        })
                                                    }
                                                    this.props.handelSendReceipt(this.state.emailAddress, this.state.mobileNumber)
                                                } else {
                                                    ShowAlert("", `Kindly enter valid email address or phone number`, "error");
                                                    this.setState({
                                                        issenttoEmail: false,
                                                        issenttoPhone: false
                                                    })

                                                }
                                            }}>
                                            Send Receipt
                                        </Button>
                                    </div>
                                </div>) || (<div className="padd-tenant-container">
                                    <div className="add-tenant-pl-panel-body">
                                        <label style={{ color: 'black', 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '15px' }}>To book a visitor parking spot, you must assign the visitor to a tenant.</label>
                                        <label style={{ 'marginTop': '10px', color: '#323232', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '14px' }}>Spot Number</label>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            // options={this.state.spotNumbers.map((sample,_index) => sample["friendlySpotName"])}
                                            options={this.state.spotNumbers}
                                            getOptionLabel={(option) => option.friendlySpotName}
                                            placeholder={"Select spot"}
                                            disabled={this.state.isdisableSpotPicker}
                                            value={this.state.spotNumber}
                                            onChange={(event: any, newValue: ISpotOption | null) => {
                                                this.setState({
                                                    spotNumber: newValue
                                                })
                                            }}
                                            renderInput={(params) => <TextField {...params} style={{ 'marginTop': '10px' }} fullWidth id="outlined-basic" size="medium" label="" variant="outlined" color="primary" autoComplete="off" placeholder="Select spot" />}
                                        />
                                        <label style={{ 'marginTop': '10px', color: '#323232', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '14px' }}>License Plate</label>
                                        <TextField style={{}} fullWidth id="outlined-basic" size="medium" label="" variant="outlined" color="primary" autoComplete="off" placeholder="Enter license number" value={this.state.licensePlate}
                                            inputProps={{ maxLength: 9, }}
                                            onChange={(e: any) => {
                                                this.setState({
                                                    licensePlate: e.target.value
                                                })
                                            }}
                                            onFocus={(e: any) => {
                                            }}
                                            onBlur={(e: any) => {
                                            }}
                                        />

                                        <label style={{ 'marginTop': '10px', color: '#323232', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '14px' }}>Date</label>
                                        <Grid container>
                                            <Grid item xs>
                                                <GrydDateRangePicker
                                                    placeHolder="Date"
                                                    initialDateRange={this.state.date_}
                                                    onDateChange={(dates_: any) => {
                                                        this.setState({
                                                            date_: dates_
                                                        })
                                                    }}
                                                    required={true}
                                                // minDate={this.getMinDateRange()}
                                                />
                                            </Grid>
                                        </Grid>
                                        <label style={{ 'marginTop': '10px', color: '#323232', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '14px' }}>Time</label>
                                        <div className="cl-flex">
                                            <div className="cl-flex-cl cl-r-padding">
                                                <div className="cl-block-input-c">
                                                    <AccessTimeIcon className="cl-icon" />
                                                    <Autocomplete
                                                        id="cl-start-time"
                                                        className="cl-time-dropdown"
                                                        options={this.state.times}
                                                        value={this.state.selectedStartTime}
                                                        onChange={(event: any, value: IBlockTime | null) => {
                                                            console.log("event", event);
                                                            console.log("value", value)
                                                            this.setState({ selectedStartTime: value }, () => {
                                                            });
                                                        }}
                                                        openOnFocus={true}
                                                        selectOnFocus={true}
                                                        getOptionLabel={(option) => option.timeString}
                                                        renderOption={(option, { selected }) => (
                                                            <React.Fragment>
                                                                {option.timeString}
                                                            </React.Fragment>
                                                        )}
                                                        renderInput={(params) => <TextField {...params} placeholder="Start Time"
                                                            variant="outlined"
                                                            error={!this.state.selectedStartTime}
                                                        />}
                                                    />
                                                </div>
                                            </div>
                                            <div className="cl-flex-pd">&mdash;</div>
                                            <div className="cl-flex-cl cl-r-padding">
                                                <div className="cl-block-input-c">
                                                    <AccessTimeIcon className="cl-icon" />
                                                    <Autocomplete
                                                        id="cl-end-time"
                                                        className="cl-time-dropdown"
                                                        options={this.getTimes(true)}
                                                        value={this.state.selectedEndTime}
                                                        onChange={(event: any, value: IBlockTime | null) => {
                                                            console.log("event", event);
                                                            console.log("value", value)
                                                            this.setState({ selectedEndTime: value }, () => {

                                                            });
                                                        }}
                                                        openOnFocus={true}
                                                        selectOnFocus={true}
                                                        getOptionLabel={(option) => option.timeString}
                                                        renderOption={(option, { selected }) => (
                                                            <React.Fragment>
                                                                {option.timeString}
                                                            </React.Fragment>
                                                        )}
                                                        renderInput={(params) => <TextField {...params} placeholder="End Time"
                                                            variant="outlined"
                                                            error={!this.state.selectedEndTime}
                                                        />}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div style={{ 'display': 'flex', 'flexDirection': 'row-reverse', 'width': '95%' }}>
                                        <Button style={{ width: '100px', height: '40px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: 700, borderRadius: '8px', color: 'white' }}
                                            onClick={() => {
                                                const { date_, selectedStartTime, selectedEndTime } = this.state;
                                                let copyDate = { startDate: new Date(), endDate: new Date() };
                                                const date = { ...date_ };
                                                if (date.startDate && date.endDate) {
                                                    copyDate = { startDate: new Date(date.startDate), endDate: new Date(date.endDate) };
                                                    DateTimeHelper.ChangeTime(copyDate.startDate, selectedStartTime?.timeString ?? "");
                                                    DateTimeHelper.ChangeTime(copyDate.endDate, selectedEndTime?.timeString ?? "");
                                                }
                                                console.log("startDate", DateTimeHelper.LocalToUtcString(copyDate.startDate));
                                                console.log("endDate", DateTimeHelper.LocalToUtcString(copyDate.endDate));
                                                var statDate = moment(DateTimeHelper.LocalToUtcString(copyDate.endDate));
                                                var endDate = moment(DateTimeHelper.LocalToUtcString(copyDate.startDate));
                                                this.setState({
                                                    startDateTime: DateTimeHelper.LocalToUtcString(copyDate.startDate),
                                                    endDateTime: DateTimeHelper.LocalToUtcString(copyDate.endDate)
                                                })
                                                var DurationInMinutes = statDate.diff(endDate, 'minutes')
                                                console.log("difference in minutes", DurationInMinutes)
                                                if (this.state.spotNumber != null) {
                                                    if (DurationInMinutes > 0) {
                                                        this.props.handelBook(this.state.spotNumber.spotGuid, DurationInMinutes, this.state.licensePlate, copyDate);
                                                    } else {
                                                        ShowAlert("", 'Kindly enter valid date and time', "error");
                                                    }
                                                }
                                            }}>
                                            Book
                                        </Button>
                                        <Button style={{ width: '100px', height: '40px', backgroundColor: '#DADDDF', fontSize: "15px", fontFamily: 'Manrope', fontWeight: 700, borderRadius: '8px', color: '#6B7278', marginRight: '10px' }}
                                            onClick={() => {
                                                this.props.handelCancle();
                                            }}
                                        >Cancel</Button>
                                    </div></div>)
                            }

                        </>
                    )
                }


            </div>
        )
    }

}
interface IAssignVisitorState {
    unitNumber: string,
    spotNumber: ISpotOption | null,
    passcode: string,
    loginGuid: string,
    availableSpotNumbers: string[],
    date_: DateRange;
    times: IBlockTime[];
    selectedStartTime: IBlockTime | null;
    selectedEndTime: IBlockTime | null;
    isBookingConfirm: boolean;
    startDateTime: string;
    endDateTime: string;
    emailAddress: string;
    mobileNumber: string;
    licensePlate: string;
    spotNumbers: ISpotOption[];
    issenttoEmail: boolean,
    issenttoPhone: boolean,
    isdisableSpotPicker: boolean

}

interface IAssignVisitorProps {

    handelNext(unitNumer: string, passcode: string): void,
    handelCancle(): void,
    handelBook(spotNumber: string, minutes: number, licensePlate: string, dateRange: any): void,
    handelSendReceipt(emailAddress: string, phoneNumber: string): void,
    /// handelSpotNumber(spotNumber: string): void,
    spotNumber: string,
    loginGuid: string,
    isBookingConfirm: boolean,
    spotNumbers: any[]

    // unitNumber:string,
    // passcode:string,
    // spotNumber:string,
    // date:string,


}
interface IBlockTime {
    dateTime: Date;
    timeString: string;
}
