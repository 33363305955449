import React from 'react';
import { Grid, Button, Tooltip } from '@material-ui/core';
import { IPermissionMatrix } from './Interfaces';
import { IDataGridColumn } from '../../Components/DataGrid/Interfaces';
import LoaderComponent from '../../Components/LoaderComponent';
import DataGrid from '../../Components/DataGrid/DataGrid';
import Checkbox from '@material-ui/core/Checkbox';
import InfoIcon from '@material-ui/icons/Info';
import './Permission.css';
import UserService from '../../Services/UserService';
import { ShowAlert } from '../../Common/ShowAlert';
import ObjectHelper from '../../Helpers/ObjectHelper';
import DataGridToolbar from '../../Components/DataGrid/DataGridToolbar';
import { RightPanelState } from '../../Models/CommonInterfaces';

class Permission extends React.Component<PermissionProps, PermissionState> {
    private _userService: UserService;
    private _permissionListColumns: IDataGridColumn<IPermissionMatrix>[] = [
        {
            key: "permissionName",
            name: "Permission Name",
            contentProvider: (row_: IPermissionMatrix) => {
                return (
                    <div>
                        {row_.permissionName} <span className="permission-help"><Tooltip title={row_.permissionDescription} className="disbursement-export">
                            <InfoIcon />
                        </Tooltip></span>
                    </div>
                );
            }
        },
        {
            key: "admin",
            name: "Gryd Admin",
            textAlignment: "center",
            contentProvider: (row_: IPermissionMatrix) => {
                return (
                    <div>
                        <Checkbox
                            disabled={
                                row_.permissionKey == 'view-permission-matrix' ||
                                row_.permissionKey == 'save-permission-matrix' ||
                                row_.permissionKey == 'view-admin-dashboard-information'
                            }
                            color="primary"
                            checked={row_.admin}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            onChange={(e_: any) => this.updatePermission(row_, e_.target.checked, 1)}
                        />
                    </div>
                );
            }
        },
        /*{
            key: "grydUser",
            name: "Gryd User",
            textAlignment: "center",
            contentProvider: (row_: IPermissionMatrix) => {
                return (
                    <div>
                        <Checkbox
                            disabled={
                                row_.permissionKey == 'view-permission-matrix' ||
                                row_.permissionKey == 'save-permission-matrix' ||
                                row_.permissionKey == 'view-admin-dashboard-information'
                            }
                            color="primary"
                            checked={row_.grydUser}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            onChange={(e_: any) => this.updatePermission(row_, e_.target.checked, 2)}
                        />
                    </div>
                );
            }
        },*/
        {
            key: "manager",
            name: "Manager",
            textAlignment: "center",
            contentProvider: (row_: IPermissionMatrix) => {
                return (
                    <div>
                        <Checkbox
                            disabled={
                                row_.permissionKey == 'view-manager-dashboard-information'
                            }
                            color="primary"
                            checked={row_.manager}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            onChange={(e_: any) => this.updatePermission(row_, e_.target.checked, 5)}
                        />
                    </div>
                );
            }
        },
        {
            key: "tenant",
            name: "Tenant",
            textAlignment: "center",
            contentProvider: (row_: IPermissionMatrix) => {
                return (
                    <div>
                        <Checkbox
                            disabled={
                                row_.permissionKey == 'view-tenant-dashboard-information'
                            }
                            color="primary"
                            checked={row_.tenant}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            onChange={(e_: any) => this.updatePermission(row_, e_.target.checked, 6)}
                        />
                    </div>
                );
            }
        },
        {
            key: "parker",
            name: "Parker",
            textAlignment: "center",
            contentProvider: (row_: IPermissionMatrix) => {
                return (
                    <div>
                        <Checkbox
                            /*disabled={
                                row_.permissionKey.indexOf('dashboard-information') != -1
                            }*/
                            color="primary"
                            checked={row_.parker}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            onChange={(e_: any) => this.updatePermission(row_, e_.target.checked, 7)}
                        />
                    </div>
                );
            }
        }
    ]

    constructor(props: PermissionProps) {
        super(props);

        let permissionList_: IPermissionMatrix[] = [];

        this.state = {
            allPermissionList: permissionList_,
            subFilteredPermissionList: permissionList_.slice(0),
            filteredPermissionList: permissionList_.slice(0),
            isDataLoaded: false,
            showBlocker: false,
            isFiltered: false,
            rightPanelState: RightPanelState.None,
            searchTerm: "",
        }

        this._userService = new UserService();
    }

    public componentDidMount = () => {
        /*let mappedItems = this._permissions.map(r => {
            // r.oldPermittedUsers = r.permittedUsers;
            r.admin = r.permittedUsers.indexOf(1) != -1;
            r.manager = r.permittedUsers.indexOf(5) != -1;
            r.tenant = r.permittedUsers.indexOf(6) != -1;
            r.parker = r.permittedUsers.indexOf(7) != -1;
            return r;
        });

        this.setState({
            allPermissionList: mappedItems,
            isDataLoaded: true
        });*/
        this.loadPermissionMatrix();
    }

    public loadPermissionMatrix() {
        this.setState({
            showBlocker: true
        });

        this._userService.getPermissionMatrix()
            .then(r => r.json())
            .then(_response => {
                let mappedItems = _response.map((e: any) => {
                    e.oldPermittedUsers = ObjectHelper.deepClone(e.permittedUsers);
                    e.admin = e.permittedUsers.indexOf(1) != -1;
                    e.grydUser = e.permittedUsers.indexOf(2) != -1;
                    e.manager = e.permittedUsers.indexOf(5) != -1;
                    e.tenant = e.permittedUsers.indexOf(6) != -1;
                    e.parker = e.permittedUsers.indexOf(7) != -1;
                    return e;
                });

                this.setState({
                    allPermissionList: mappedItems,
                    subFilteredPermissionList: mappedItems.slice(0),
                    filteredPermissionList: mappedItems.slice(0),
                    isDataLoaded: true,
                    showBlocker: false
                })
            })
    }

    public render() {
        if (!this.state.isDataLoaded) {
            // return <LoaderComponent loaderVisible={true} />
            return null;
        }
        return (
            <div className="m-3 data-grid-container dashboard-container">
                {/* <LoaderComponent loaderVisible={this.state.showBlocker} /> */}
                <Grid container className="mb-3">
                    <Grid item>
                        <h1 className="list-header">Manage Permissions</h1>
                    </Grid>
                    <Grid item xs>
                        {/* Grid toolbar */}
                        <DataGridToolbar
                            singularEntityName="permission"
                            pluralEntityName="permissions"
                            onSearch={this._searchTermChange}
                            isFiltered={this.state.isFiltered}
                            isNoAddButton={true}
                            onFilterClick={() => {
                                this.setState({
                                    rightPanelState: RightPanelState.Filter
                                });
                            }}
                            isNoFilterButton={true}
                        />
                    </Grid>
                </Grid>
                <DataGrid
                    title="Manage Permissions"
                    data={this.state.subFilteredPermissionList}
                    columns={this._permissionListColumns}
                    defaultSortColumnKey="permissionName"
                    isRowsNonSelectable={true}
                    isNoMoreLink={true}
                />
                <div className="save-permission-container">
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            let changedRecords_: IPermissionMatrix[] = [];

                            // console.log(this.state.allPermissionList);
                            this.state.allPermissionList.forEach(item => {
                                if (item.added.length > 0 || item.removed.length) {
                                    changedRecords_.push(item);
                                }
                            });

                            let allPermissionList_: IPermissionMatrix[] = this.state.allPermissionList.slice(0);
                            let subFilteredPermissionList_: IPermissionMatrix[] = this.state.subFilteredPermissionList.slice(0);
                            let filteredPermissionList_: IPermissionMatrix[] = this.state.filteredPermissionList.slice(0);

                            if (changedRecords_.length > 0) {
                                // console.log(changedRecords_);
                                this._userService.savePermissionMatrix(changedRecords_)
                                    .then(_response => {
                                        if (_response.ok) {
                                            ShowAlert('Success!', 'The permission(s) has been saved successfully.', 'success').then(r => {
                                                changedRecords_.forEach(item => {
                                                    if (item.added.length || item.removed.length) {
                                                        let allPermissionIndex: number = allPermissionList_.findIndex(x => x.permissionKey == item.permissionKey);
                                                        let subFilteredPermissionIndex: number = subFilteredPermissionList_.findIndex(x => x.permissionKey == item.permissionKey);
                                                        let filteredPermissionIndex: number = filteredPermissionList_.findIndex(x => x.permissionKey == item.permissionKey);
                                                        
                                                        allPermissionList_[allPermissionIndex] = this.updatePermissionProperty(allPermissionList_[allPermissionIndex]);
                                                        subFilteredPermissionList_[subFilteredPermissionIndex] = this.updatePermissionProperty(subFilteredPermissionList_[subFilteredPermissionIndex]);
                                                        filteredPermissionList_[filteredPermissionIndex] = this.updatePermissionProperty(filteredPermissionList_[filteredPermissionIndex]);

                                                        this.setState({
                                                            allPermissionList: allPermissionList_,
                                                            subFilteredPermissionList: subFilteredPermissionList_,
                                                            filteredPermissionList: filteredPermissionList_
                                                        })
                                                    }
                                                });
                                                // this.loadPermissionMatrix();
                                            });
                                        }
                                    })
                            } else {
                                ShowAlert('Error!', 'No records to save', 'error');
                            }
                        }}
                        className='list-button'>
                        Save
                        </Button>
                </div>
            </div>
        )
    }

    private updatePermissionProperty = (permissionItem: IPermissionMatrix): IPermissionMatrix => {
        let newPermissionItem = permissionItem;

        newPermissionItem.oldPermittedUsers = ObjectHelper.deepClone(newPermissionItem.permittedUsers);
        newPermissionItem.added = [];
        newPermissionItem.removed = [];

        return newPermissionItem;
    }

    private _searchTermChange = (searchTerm_: string): void => {
        this.setState({
            searchTerm: searchTerm_,
            subFilteredPermissionList: this._filterDataBySearch(this.state.filteredPermissionList, searchTerm_)
        });
    }

    private _filterDataBySearch = (data_: IPermissionMatrix[], searchTerm_: string): IPermissionMatrix[] => {
        return data_.filter(d => {
            return d.permissionName.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1;
        });
    }

    private updatePermission = (permission_: IPermissionMatrix, value_: boolean, role_: number): void => {
        let permission = permission_;
        let added_: number[] = permission.added;
        let removed_: number[] = permission.removed;
        let permittedUsers_: number[] = permission.permittedUsers;

        if (value_) {
            if (permission.oldPermittedUsers.indexOf(role_) == -1) {
                added_.push(role_);
            }
            permittedUsers_.push(role_);

            let index = removed_.indexOf(role_);
            if (index > -1) {
                removed_.splice(index, 1);
            }
        } else {
            if (permission.oldPermittedUsers.indexOf(role_) != -1) {
                removed_.push(role_);
            }

            let permittedUsersIndex = permittedUsers_.indexOf(role_);
            if (permittedUsersIndex > -1) {
                permittedUsers_.splice(permittedUsersIndex, 1);
            }

            let index = added_.indexOf(role_);
            if (index > -1) {
                added_.splice(index, 1);
            }
        }

        permission.added = added_;
        permission.removed = removed_;
        permission.permittedUsers = permittedUsers_;

        if (role_ == 1) {
            permission.admin = value_;
        } else if (role_ == 2) {
            permission.grydUser = value_;
        } else if (role_ == 5) {
            permission.manager = value_;
        } else if (role_ == 6) {
            permission.tenant = value_;
        } else if (role_ == 7) {
            permission.parker = value_;
        }

        this.setState({
            selectedPermission: permission
        });
    }
}

interface PermissionProps { }
interface PermissionState {
    allPermissionList: IPermissionMatrix[];
    subFilteredPermissionList: IPermissionMatrix[];
    filteredPermissionList: IPermissionMatrix[];
    isDataLoaded: boolean;
    selectedPermission?: IPermissionMatrix;
    showBlocker: boolean;
    isFiltered: boolean;
    rightPanelState: RightPanelState;
    searchTerm: string;
}

export default Permission;