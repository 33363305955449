import BaseService from "./BaseService";
import * as CommonString from '../CommonString';
import { ConfigService } from './ConfigService';
import { GetUserDetailsService } from "./GetUserDetailsService";

export default class DisbursementService extends BaseService {
    public getReconciliation() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `disbursement/transactions`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
            })
    }
}