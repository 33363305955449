import React from "react";
import {Button} from "@material-ui/core";


export default class SpotMapLoader extends React.Component<ISpotMapLoaderProps, ISpotMapLoaderState> {
  // constructor(props_: ISpotMapLoaderProps) {
  //   super(props_);
  // }

  render() {
    const {innerMessage} = this.props;
    return (
      <>
        <div className="bulk-upload-blocker">
          <div className="map-upload-popup">
            <div>
              <div className="d-flex flex-column align-items-center">
                <div className="mt-3 bulk-upload-sub-content text-center">
                  <div className="spotMaploader"></div>
                </div>
                {
                  this.props.isCancelVisible ?
                    <div className="bulk-upload-header mt-3">
                      {innerMessage ? innerMessage : "Uploading Map"}&hellip;
                    </div> :
                    <div className="bulk-upload-header mt-3">
                      {innerMessage ? innerMessage : "Loading Map"}&hellip;
                    </div>
                }

              </div>
              {
                this.props.isCancelVisible &&
                  <div className="d-flex justify-content-center align-items-center mt-4">
                      <Button

                          variant="contained"
                          color="secondary"
                          className="ml-2"
                          style={{width: 131}}
                          onClick={() => {
                            if (this.props.onCancel) {
                              this.props.onCancel();
                            }
                          }}
                      >
                          Cancel
                      </Button>
                  </div>
              }

            </div>
          </div>
        </div>
      </>
    );
  }
}

interface ISpotMapLoaderProps {
  onCancel: () => void;
  isCancelVisible: boolean;
  innerMessage?: string;
}

interface ISpotMapLoaderState {

}
