import React from "react";
import BaseFilterView, { IBaseFilterViewProps, IBaseFilterViewState } from "../PartialViews/BaseFilterView";
import { ISpotFilter, ISpot } from "./Interfaces";
import SelectFilter from "../../Components/FilterComponents/SelectFilter";
import { DataGridFilterType, IDateRange, INumberRange } from "../../Components/DataGrid/Interfaces";
import CheckboxListFilter from "../../Components/FilterComponents/CheckboxListFilter";
import RadioListFilter from "../../Components/FilterComponents/RadioListFilter";
import DateRangeFilter from "../../Components/FilterComponents/DateRangeFilter";
import PriceRangeSelector from "../../Components/PriceRangeSelector";
import { GetUserDetailsService } from "../../Services/GetUserDetailsService";

export default class SpotFilterView
    extends BaseFilterView<IBaseFilterViewProps<ISpot, ISpotFilter>, IBaseFilterViewState<ISpotFilter>> {
    private _allProperties: string[] = [];
    private _allCities: string[] = [];
    private _allOwners: string[] = [];
    private _allManagers: string[] = [];
    private _allTenants: string[] = [];
    private _revenueFilters: string[] = [];
    private _spotType: string[] = [];
    private _claimStatus: string[] = [];

    private _isTenantUser = GetUserDetailsService.getUserDetails().userRoleID[0] == 6;
    private _isBcUser = GetUserDetailsService.getUserDetails().userRoleID[0] == 8;

    constructor(props_: IBaseFilterViewProps<ISpot, ISpotFilter>) {
        super(props_, {
            property: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
            city: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
            owner: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
            manager: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
            tenants: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
            reservation: {
                type: DataGridFilterType.DateRange,
                filterValue: {
                    start: undefined,
                    end: undefined
                }
            },
            revenue: {
                type: DataGridFilterType.RevenueRange,
                filterValue: {
                    start: undefined,
                    end: undefined,
                    optionalTag: ""
                }
            },
            spotType: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
            claimStatus: {
                type: DataGridFilterType.StringList,
                filterValue: []
            },
        } as ISpotFilter);

        for (let spot of props_.data) {
            if (this._allProperties.indexOf(spot.propertyName) == -1) {
                this._allProperties.push(spot.propertyName);
            }
            if (this._allCities.indexOf(spot.propertyCity) == -1) {
                this._allCities.push(spot.propertyCity);
            }
            if (this._allOwners.indexOf(spot.propertyOwnerName) == -1) {
                this._allOwners.push(spot.propertyOwnerName);
            }
            if (this._allManagers.indexOf(spot.propertyCompanyName) == -1) {
                this._allManagers.push(spot.propertyCompanyName);
            }
            if (spot.spotTenantName) {
                if (this._allTenants.indexOf(spot.spotTenantName) == -1) {
                    this._allTenants.push(spot.spotTenantName);
                }
            }
        }

        this._revenueFilters = [
            "Less than $50,000",
            "More than $50,000",
            "More than $100,000",
            "Custom"
        ];
        this._spotType = [
            "Property",
            "Tenant"
        ];
        this._claimStatus = [
            "Unclaimed",
            "Waiting for approval",
            "Claimed"
        ];
    }

    protected renderFilterOptions = () => {
        return (
            <div className="filter-options-list">
                {!this._isTenantUser &&
                    <React.Fragment>
                        <SelectFilter
                            title="Property"
                            placeholderText="Search by property name"
                            items={this._allProperties}
                            selectedItems={this.state.appliedFilter.property?.filterValue as string[]}
                            onChange={selection_ => {
                                if (this.state.appliedFilter.property) {
                                    this.state.appliedFilter.property.filterValue = selection_;
                                    this.setState({
                                        appliedFilter: this.state.appliedFilter
                                    });
                                }
                            }}
                        />
                        <CheckboxListFilter
                            title="City"
                            placeholderText="Search by city name"
                            items={this._allCities}
                            selectedItems={this.state.appliedFilter.city?.filterValue as string[]}
                            onChange={selection_ => {
                                if (this.state.appliedFilter.city) {
                                    this.state.appliedFilter.city.filterValue = selection_;
                                    this.setState({
                                        appliedFilter: this.state.appliedFilter
                                    });
                                }
                            }}
                        />
                        <SelectFilter
                            title="Owner"
                            placeholderText="Search by owner name"
                            items={this._allOwners}
                            selectedItems={this.state.appliedFilter.owner?.filterValue as string[]}
                            onChange={selection_ => {
                                if (this.state.appliedFilter.owner) {
                                    this.state.appliedFilter.owner.filterValue = selection_;
                                    this.setState({
                                        appliedFilter: this.state.appliedFilter
                                    });
                                }
                            }}
                        />
                        <SelectFilter
                            title="Manager"
                            placeholderText="Search by management company name"
                            items={this._allManagers}
                            selectedItems={this.state.appliedFilter.manager?.filterValue as string[]}
                            onChange={selection_ => {
                                if (this.state.appliedFilter.manager) {
                                    this.state.appliedFilter.manager.filterValue = selection_;
                                    this.setState({
                                        appliedFilter: this.state.appliedFilter
                                    });
                                }
                            }}
                        />
                    </React.Fragment>
                }
                {console.log(this.state.appliedFilter.reservation?.filterValue)}
                <DateRangeFilter
                    title="Bookings"
                    selectedDateRange={this.state.appliedFilter.reservation?.filterValue as IDateRange}
                    onChange={dateRange_ => {
                        console.log(this.state.appliedFilter.reservation?.filterValue)
                        if (this.state.appliedFilter.reservation) {
                            this.state.appliedFilter.reservation.filterValue = dateRange_;
                            this.setState({
                                appliedFilter: this.state.appliedFilter
                            });
                        }
                    }}
                />
                {
                    !this._isBcUser &&
                    <RadioListFilter
                        title="Revenue"
                        placeholderText="Search by revenue"
                        items={this._revenueFilters}
                        selectedItem={(this.state.appliedFilter.revenue?.filterValue as INumberRange).optionalTag as string}
                        onChange={selection_ => {
                            if (this.state.appliedFilter.revenue) {
                                switch (this._revenueFilters.indexOf(selection_)) {
                                    case 0:
                                        this.state.appliedFilter.revenue.filterValue = {
                                            start: undefined,
                                            end: 50000,
                                            optionalTag: selection_
                                        };
                                        break;
                                    case 1:
                                        this.state.appliedFilter.revenue.filterValue = {
                                            start: 50000,
                                            end: undefined,
                                            optionalTag: selection_
                                        };
                                        break;
                                    case 2:
                                        this.state.appliedFilter.revenue.filterValue = {
                                            start: 100000,
                                            end: undefined,
                                            optionalTag: selection_
                                        };
                                        break;
                                    case 3:
                                        this.state.appliedFilter.revenue.filterValue = {
                                            start: 65000,
                                            end: 120000,
                                            optionalTag: selection_
                                        };
                                        break;
                                    default:
                                        this.state.appliedFilter.revenue.filterValue = {
                                            start: undefined,
                                            end: undefined,
                                            optionalTag: selection_
                                        };
                                        break;
                                }
                                this.setState({
                                    appliedFilter: this.state.appliedFilter
                                });
                            }
                        }}
                    >
                        <div className="slider-revenue-controller">
                            {
                                (this.state.appliedFilter.revenue.filterValue as INumberRange).optionalTag == "Custom" &&
                                <PriceRangeSelector
                                    selectedRange={{
                                        start: ((this.state.appliedFilter.revenue && this.state.appliedFilter.revenue.filterValue as INumberRange).start ?
                                            (this.state.appliedFilter.revenue.filterValue as INumberRange).start : 0) as number,
                                        end: ((this.state.appliedFilter.revenue && this.state.appliedFilter.revenue.filterValue as INumberRange).end ?
                                            (this.state.appliedFilter.revenue.filterValue as INumberRange).end : 0) as number
                                    }}
                                    min={10000}
                                    max={500000}
                                    stepSize={1000}
                                    onChange={(start_: number, end_: number) => {
                                        this.state.appliedFilter.revenue.filterValue = {
                                            start: start_,
                                            end: end_,
                                            optionalTag: "Custom"
                                        };
                                        this.setState({
                                            appliedFilter: this.state.appliedFilter
                                        });
                                    }}
                                />
                            }
                        </div>
                    </RadioListFilter>
                }
                {!this._isTenantUser &&
                    <React.Fragment>
                        <SelectFilter
                            title="Owned By"
                            placeholderText="Search by owner type"
                            items={this._spotType}
                            selectedItems={this.state.appliedFilter.spotType?.filterValue as string[]}
                            onChange={selection_ => {
                                if (this.state.appliedFilter.spotType) {
                                    this.state.appliedFilter.spotType.filterValue = selection_;
                                    this.setState({
                                        appliedFilter: this.state.appliedFilter
                                    });
                                }
                            }}
                        />
                        <SelectFilter
                            title="Tenant Claim Status"
                            placeholderText="Search by tenant claim status"
                            items={this._claimStatus}
                            selectedItems={this.state.appliedFilter.claimStatus?.filterValue as string[]}
                            onChange={selection_ => {
                                if (this.state.appliedFilter.claimStatus) {
                                    this.state.appliedFilter.claimStatus.filterValue = selection_;
                                    this.setState({
                                        appliedFilter: this.state.appliedFilter
                                    });
                                }
                            }}
                        />
                        <SelectFilter
                            title="Tenant"
                            placeholderText="Search by tenant"
                            items={this._allTenants}
                            selectedItems={this.state.appliedFilter.tenants?.filterValue as string[]}
                            onChange={selection_ => {
                                if (this.state.appliedFilter.tenants) {
                                    this.state.appliedFilter.tenants.filterValue = selection_;
                                    this.setState({
                                        appliedFilter: this.state.appliedFilter
                                    });
                                }
                            }}
                        />
                    </React.Fragment>
                }
            </div>
        );
    }

    protected filterItems = () => {
        this._isFiltered = false;
        return this.props.data.filter(s => {
            let isValid = true;
            let filteredProperties = this.state.appliedFilter.property.filterValue as string[];
            let filteredCities = this.state.appliedFilter.city.filterValue as string[];
            let filteredOwners = this.state.appliedFilter.owner.filterValue as string[];
            let filteredManagers = this.state.appliedFilter.manager.filterValue as string[];
            let filteredTenants = this.state.appliedFilter.tenants.filterValue as string;
            let filteredReservationDate = this.state.appliedFilter.reservation.filterValue as IDateRange;
            let filteredRevenue = this.state.appliedFilter.revenue.filterValue as INumberRange;
            let filteredSpotType = this.state.appliedFilter.spotType.filterValue as string[];
            let filteredClaimStatus = this.state.appliedFilter.claimStatus.filterValue as string[];

            if (filteredProperties.length > 0 && isValid) {
                this._isFiltered = true;
                isValid = filteredProperties.indexOf(s.propertyName) > -1;
            }
            if (filteredCities.length > 0 && isValid) {
                this._isFiltered = true;
                isValid = filteredCities.indexOf(s.propertyCity) > -1;
            }
            if (filteredOwners.length > 0 && isValid) {
                this._isFiltered = true;
                isValid = filteredOwners.indexOf(s.propertyOwnerName) > -1;
            }
            if (filteredManagers.length > 0 && isValid) {
                this._isFiltered = true;
                isValid = filteredManagers.indexOf(s.propertyCompanyName) > -1;
            }
            if (filteredTenants.length > 0 && isValid) {
                this._isFiltered = true;
                isValid = filteredTenants.indexOf(s.spotTenantName) > -1;
            }
            if (filteredReservationDate && (filteredReservationDate.start || filteredReservationDate.end) && isValid) {
                this._isFiltered = true;
                isValid = false;

                for (let reservedDate of s.reservationDates) {
                    if (filteredReservationDate.start && filteredReservationDate.end) {
                        isValid = (reservedDate >= filteredReservationDate.start && reservedDate <= filteredReservationDate.end);
                    }
                    else if (filteredReservationDate.start) {
                        isValid = (reservedDate >= filteredReservationDate.start);
                    }
                    else if (filteredReservationDate.end) {
                        isValid = (reservedDate <= filteredReservationDate.end);
                    }
                    if (isValid) {
                        break;
                    }
                }
            }
            if (filteredRevenue && (filteredRevenue.start || filteredRevenue.end) && isValid) {
                this._isFiltered = true;
                if (filteredRevenue.start && filteredRevenue.end) {
                    isValid = (s.revenue >= filteredRevenue.start && s.revenue <= filteredRevenue.end);
                }
                else if (filteredRevenue.start) {
                    isValid = (s.revenue >= filteredRevenue.start);
                }
                else if (filteredRevenue.end) {
                    isValid = (s.revenue <= filteredRevenue.end);
                }
            }
            if (filteredSpotType && filteredSpotType.length > 0 && isValid) {
                this._isFiltered = true;
                isValid = filteredSpotType.indexOf(s.spotTypeId == 2 ? "Tenant" : "Property") > -1;
            }
            if (filteredClaimStatus && filteredClaimStatus.length > 0 && isValid) {
                this._isFiltered = true;
                if (s.spotTypeId == 2) {
                    isValid = filteredClaimStatus.indexOf(s.claimStatusId == 3 ? "Claimed" : ((s.claimStatusId == 2 || s.spotTenantGuid) ? "Waiting for approval" : "Unclaimed")) > -1;
                }
                else {
                    isValid = false;
                }
            }

            return isValid;
        });
    }
}