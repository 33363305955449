import React from "react";

export default class PropertyDetailView extends React.Component<IPropertyDetailViewProps, IPropertyDetailViewState> {
    render() {
        return (
            <div className="property-detail-view">
                
            </div>
        );
    }
}

interface IPropertyDetailViewProps {
    
}

interface IPropertyDetailViewState {
    
}