import { createMuiTheme } from '@material-ui/core/styles';

export const greenTheme = createMuiTheme({
    overrides: {
        MuiRadio: {
          root: {
            color: '#8A959E',
          },
          colorSecondary: {
            '&$checked': {
              color: '#00AF85',
            },
          },
        },
        // MuiSelect:{
        //   root: {
        //     color: '#111012',
        //   },
          
          
        // },
      },
    palette: {
        primary: {
            main: "#00b188",
            contrastText: "#ffffff"
        },
        secondary: {
            main: "#e8eaec",
            contrastText: "#000000"
        }
    }
    
    
});