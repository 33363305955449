import React from "react";
import { Row, Col } from 'reactstrap';
import WeekDaySelector from "../Components/WeekDaySelector";
import TimeRangeSelector from "../Components/TimeRangeSelector";
import PriceRangeSelector from "../Components/PriceRangeSelector";
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default class ScheduleComponent extends React.Component<IScheduleComponentProps, IScheduleComponentState> {
    constructor(props_: IScheduleComponentProps) {
        super(props_);

        this.state = {
            selectedDays: this.props.selectedDays,
            selectedPriceRange: this.props.selectedPriceRange,
            selectedTimeRange: this.props.selectedTimeRange
        };
    }

    componentDidUpdate(oldProps_: IScheduleComponentProps) {
        let updatedState: IScheduleComponentState = {} as any;
        let changed = false;

        if (oldProps_.selectedDays != this.props.selectedDays && this.props.selectedDays != undefined) {
            updatedState.selectedDays = this.props.selectedDays;
            changed = true;
        }
        if (oldProps_.selectedPriceRange != this.props.selectedPriceRange && this.props.selectedPriceRange != undefined) {
            updatedState.selectedPriceRange = this.props.selectedPriceRange;
            changed = true;
        }
        if (oldProps_.selectedTimeRange != this.props.selectedTimeRange && this.props.selectedTimeRange != undefined) {
            updatedState.selectedTimeRange = this.props.selectedTimeRange;
            changed = true;
        }

        if (changed) {
            this.setState(updatedState);
        }
    }

    render() {
        if (this.props.isPriceOnTop) {
            return (
                <div className={this.props.className}>
                    <Row className="my-3">
                        <Col>
                            <PriceRangeSelector
                                selectedRange={this.props.selectedPriceRange}
                                min={this.props.limitation?.minPrice}
                                max={this.props.limitation?.maxPrice}
                                singleSlider={this.props.singlePrice}
                                onChange={(start_: number, end_: number) => {
                                    this.setState({
                                        selectedPriceRange: {
                                            start: start_,
                                            end: end_,
                                        }
                                    }, this._onStateUpdate);
                                }}
                            />
                        </Col>
                    </Row>
                    {/* <ExpansionPanel
                        className="internal-schedule-panel m-0 p-0"
                        square={true}
                        defaultExpanded={false}
                    >
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>Schedule</ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div> */}
                    <Row className="my-3">
                        <Col>
                            <WeekDaySelector
                                selectedDays={this.state.selectedDays}
                                allowedDays={this.props.limitation?.allowedDays}
                                onDaySelectionChange={d_ => {
                                    this.setState({
                                        selectedDays: d_
                                    }, this._onStateUpdate);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col>
                            <TimeRangeSelector
                                selectedRange={this.props.selectedTimeRange}
                                min={this.props.limitation?.minTime}
                                max={this.props.limitation?.maxTime}
                                onChange={(start_: number, end_: number) => {
                                    this.setState({
                                        selectedTimeRange: {
                                            start: start_,
                                            end: end_
                                        }
                                    }, this._onStateUpdate);
                                }}
                            />
                        </Col>
                    </Row>
                    {/* </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel> */}
                </div>
            );
        }
        else {
            return (
                <div className={this.props.className}>
                    <Row className="my-3">
                        <Col>
                            <WeekDaySelector
                                selectedDays={this.state.selectedDays}
                                allowedDays={this.props.limitation?.allowedDays}
                                onDaySelectionChange={d_ => {
                                    this.setState({
                                        selectedDays: d_
                                    }, this._onStateUpdate);
                                }}
                                disabled={this.props.hoursDisabled}
                            />
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col>
                            <TimeRangeSelector
                                selectedRange={this.props.selectedTimeRange}
                                min={this.props.limitation?.minTime}
                                max={this.props.limitation?.maxTime}
                                onChange={(start_: number, end_: number) => {
                                    this.setState({
                                        selectedTimeRange: {
                                            start: start_,
                                            end: end_
                                        }
                                    }, this._onStateUpdate);
                                }}
                                disabled={this.props.hoursDisabled}
                            />
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col>
                            <PriceRangeSelector
                                selectedRange={this.props.selectedPriceRange}
                                min={this.props.limitation?.minPrice}
                                max={this.props.limitation?.maxPrice}
                                singleSlider={this.props.singlePrice}
                                isPropertyScreen={this.props.isPropertyScreen}
                                onChange={(start_: number, end_: number) => {
                                    this.setState({
                                        selectedPriceRange: {
                                            start: start_,
                                            end: end_,
                                        }
                                    }, this._onStateUpdate);
                                }}
                                disabled={this.props.priceDisabled}
                            />
                        </Col>
                    </Row>
                </div>
            );
        }
    }

    private _onStateUpdate = () => {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }
}

interface IScheduleComponentProps extends ISingleScheduleData {
    singlePrice?: boolean;
    limitation?: IScheduleLimitation;
    className?: string;
    isPriceOnTop?: boolean;
    isPropertyScreen?: boolean;
    priceDisabled?: boolean;
    hoursDisabled?: boolean;
    onChange?: (state_: IScheduleComponentState) => void;
}

interface IScheduleComponentState extends ISingleScheduleData { }

export interface ISingleScheduleData {
    selectedDays?: number[];
    selectedTimeRange?: {
        start: number;
        end: number;
    };
    selectedPriceRange?: {
        start: number;
        end: number;
    };
    _internalId?: number;
    _hasConflict?: boolean;
}

export interface IScheduleLimitation {
    allowedDays: number[]
    minTime: number,
    maxTime: number,
    minPrice: number,
    maxPrice: number
}