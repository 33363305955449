export const SERVICE_SIGN_IN = 'signin';
export const SERVICE_REQUEST_OTP = 'user/otpverification';
export const SERVICE_ONBOARD_USERS = 'user/onboardinglist';
export const SERVICE_USER_REGISTER = 'user/register';
export const SERVICE_SEND_LINK_FOR_ONBOARD = 'user/onboarding';
export const SERVICE_FETCH_ONBOARD_USER = 'user/fetchonboardinguser'
export const SERVICE_VERIFY_OTP = 'signin/mfa';
export const SERIVCE_PROPERTY_LIST = 'property/get';
export const SERIVCE_GET_PROPERTY_DROPDOWN = 'user/getpropertydropdown';
export const SERIVCE_GET_COMPANY_DROPDOWN = 'company/all';
export const SERIVCE_GET_COMPANY_WITHOUT_MANAGER = 'company/without-manager';
export const SERVICE_GET_USER_LIST = 'user/userdropdownlist';
export const SERVICE_FORGOT_PASSWORD_LINK = 'signin/forgotpasswordlink';
export const SERVICE_FORGOT_VALIDATE_LINK = 'signin/validateforgotlink';
export const SERVICE_CHANGE_PASSWORD = 'signin/changepassword';
export const SERVICE_USER_LIST = 'user/userdropdownlist';
export const SERVICE_PROPERTY_OWNERS = 'property/getpropertyowners';
export const SERVICE_VERIFY_EMAIL = 'user/verify-email/';
export const SERVICE_REACTIVATE = 'user/verify-reactivate-link/';
export const SERIVCE_PROPERTY_NAME_LIST = 'property/names';