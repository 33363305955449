import moment from "moment";
import StringHelper from "./StringHelper";

export class DateTimeHelper {
    private static _totalMinutesInADay = 1440;
    private static _halfADayMinutes = 720;

    public static shortWeekDayLabels = "SMTWTFS".split('');
    public static midWeekDayLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    public static fullWeekDayLabels = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    public static monthLabels = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];
    public static monthFullLabels = ["January", "February", "March", "April", "May", "June", 'July', 'August', 'September', 'October', 'November', 'December'];

    public static dateStringToDate(dateString_: Date | string): Date {
        return new Date(dateString_);
    }

    public static timeStringToMinutes(timeString_: string): number {
        timeString_ = timeString_.toLowerCase();
        let amIndex = timeString_.indexOf("am");
        let pmIndex = timeString_.indexOf("pm");
        if (amIndex > 0) {
            return this._timeStringToMinutes(timeString_.substr(0, amIndex).trim()) % this._halfADayMinutes;
        }
        else if (pmIndex > 0) {
            let minutes = this._timeStringToMinutes(timeString_.substr(0, pmIndex).trim());
            if (minutes < this._halfADayMinutes) {
                minutes += this._halfADayMinutes;
            }
            return minutes;
        }
        else {
            return this._timeStringToMinutes(timeString_);
        }
    }

    public static minutesToTimeString(minutes_: number, is24HrsFrmt_: boolean): string {
        let fullHours = Math.floor(minutes_ / 60);
        let remainderMinutes = minutes_ % 60;

        if (is24HrsFrmt_) {
            return `${StringHelper.padLeft(fullHours.toString(), 2, '0')}:${StringHelper.padLeft(remainderMinutes.toString(), 2, '0')}`;
        }
        else {
            let hours = fullHours % 12;
            // let ampm = fullHours < 12 ? "AM" : "PM";
            let in24Hours = fullHours % 24; // Added to check multiple days hours
            let ampm = in24Hours < 12 ? "AM" : "PM";

            if (hours === 0) {
                hours = 12;
            }

            return `${StringHelper.padLeft(hours.toString(), 2, '0')}:${StringHelper.padLeft(remainderMinutes.toString(), 2, '0')} ${ampm}`;
        }
    }

    private static _timeStringToMinutes(timeString_: string): number {
        let splitHoursMinutes = timeString_.split(":");
        if (splitHoursMinutes.length == 1) {
            splitHoursMinutes = timeString_.split(".");
        }
        let hours = parseInt(splitHoursMinutes[0] ? splitHoursMinutes[0] : "0");
        let minutes = parseInt(splitHoursMinutes[1] ? splitHoursMinutes[1] : "0");
        return hours * 60 + minutes;
    }

    public static getDiffTime(start: string, end: string, calcType: boolean) {
        //Commenting UTC hours
        /*let start_: string = (start.toLocaleLowerCase().indexOf('z') != -1) ? start : start+"Z";
        let end_: string = (end.toLocaleLowerCase().indexOf('z') != -1) ? end : end+"Z";*/
        let start_: string = start;
        let end_: string = end;
        var startDate;
        var endDate = new Date(end_);

        if (calcType) {
            startDate = new Date(start_);
        } else {
            startDate = new Date();
        }

        var diff = endDate.getTime() - startDate.getTime();
        var hours = Math.floor(diff / 1000 / 60 / 60);
        diff -= hours * 1000 * 60 * 60;
        var minutes = Math.floor(diff / 1000 / 60);

        // If using time pickers with 24 hours format, add the below line get exact hours
        if (hours < 0)
            hours = hours + 24;

        return (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
    }

    public static updateTimeHr(inputDate: string) {
        let inputDate_: number = new Date(inputDate).getTime();

        let minute = 60;
        let hour = minute * 60;
        let day = hour * 24;
        let month = day * 30;
        let year = day * 365;

        let suffix = ' ago';

        let elapsed = Math.floor((Date.now() - inputDate_) / 1000);

        if (elapsed < minute) {
            return 'just now';
        }

        // get an array in the form of [number, string]
        let a = elapsed < hour && [Math.floor(elapsed / minute), 'minute'] ||
            elapsed < day && [Math.floor(elapsed / hour), 'hour'] ||
            elapsed < month && [Math.floor(elapsed / day), 'day'] ||
            elapsed < year && [Math.floor(elapsed / month), 'month'] ||
            [Math.floor(elapsed / year), 'year'];

        // pluralise and append suffix
        return a[0] + ' ' + a[1] + (a[0] === 1 ? '' : 's') + suffix;
    }

    static getDayMinutes(date_: Date): number | undefined {
        return date_.getHours() * 60 + date_.getMinutes();
    }

    static convertToTimezone(input: Date, timezone?: string) {
        let output = input;
        if (timezone) {
            output = new Date(input.toLocaleString("en-US", { timeZone: timezone }))
        }

        return output;
    }

    static convertIntoUtc(date: Date) {
        let x: Date = new Date(date);
        let hoursDiff = x.getHours() - x.getTimezoneOffset() / 60;
        let minutesDiff = x.getMinutes() + (hoursDiff * 60 % 60);
        x.setHours(hoursDiff);
        x.setMinutes(minutesDiff);
        return new Date(x);
    }

    public static LocalToUtcString(inputDate: Date) {
        var date = new Date(inputDate);
        return `${date.getFullYear()}-${this.MakeTwoDigit(date.getMonth()+1)}-${this.MakeTwoDigit(date.getDate())}T${this.MakeTwoDigit(date.getHours())}:${this.MakeTwoDigit(date.getMinutes())}:00`//'2022-09-01T12:30:00.000Z';
    }

    public static getCurrentTimeOnly(date: Date): string {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const min = minutes < 10 ? '0' + minutes : minutes;
        const hrs = hours < 10 ? '0' + hours : hours;
        var strTime = hrs + ':' + min + ' ' + ampm;
        return strTime;
    }

    public static addMintues(dt: Date, minutes: number) {
        return new Date(dt.getTime() + minutes * 60000);
    }

    public static getCurrentArrangeTime(currentDate: Date, propertyTimeZone?: string) {
        let currentTimeZone = propertyTimeZone ? currentDate : DateTimeHelper.convertToTimezone(currentDate, propertyTimeZone);
        let totalMinutes = currentTimeZone.getMinutes() + (currentTimeZone.getHours() * 60);
        let roundedTotalMinutes = Math.ceil(totalMinutes / 15) * 15;

        let hours = Math.floor(roundedTotalMinutes / 60);
        let minutes = roundedTotalMinutes - (hours * 60);

        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const min = minutes < 10 ? '0' + minutes : minutes;
        const hrs = hours < 10 ? '0' + hours : hours;
        var strTime = hrs + ':' + min + ' ' + ampm;
        return strTime;
    }

    public static getEndTime(currentDate: string, addMin: number) {
        let [hrs, minampm] = currentDate.split(':');
        let [min, oldampm] = minampm.split(' ');
        let minutes = parseInt(min) + addMin;
        let hours = parseInt(hrs);
        if (minutes >= 60) {
            const [newHrs, newMin] = (minutes/60).toFixed(2).split('.');
            hours += parseInt(newHrs);
            minutes = (parseFloat("." + newMin) * 60);
        }

        if (oldampm === "AM" && hours === 12) {
            oldampm = "PM";
        }
        else if (oldampm === "PM" && hours === 12) {
            oldampm = "AM";
        }

        hours = this.ConvertTo24Hrs(hours, oldampm);
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        min = minutes < 10 ? '0' + minutes : minutes.toString();
        hrs = hours < 10 ? '0' + hours : hours.toString();
        var strTime = hrs + ':' + min + ' ' + ampm;
        return strTime;
    }

    public static ChangeTime(dt: Date, time: string) {
        const [hrs, minampm] = time.split(":");
        const [min, ampm] = minampm.split(" ");
        var hours = this.ConvertTo24Hrs(Number(hrs), ampm);
        dt.setHours(hours, parseInt(min), 0, 0);
        return dt;
    }

    private static ConvertTo24Hrs(hours: number, amap: string) {
        if (amap === "PM" && hours < 12) hours += 12;
        if (amap === "AM" && hours === 12) hours -= 12;

        return hours;
    }

    private static MakeTwoDigit(input: number){
        if (input <= 9) {
            return '0' + input;
        }
        return input;
    }

    public static GetDays(date: Date, date1: Date) {
        return Math.round((date1.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));
    }

    //Date formate: "November 3, 2022"
    public static GetMonthYearFormat(date: Date, addDay?: number) {
        if(addDay){
            date.setDate(date.getDate() + addDay);
        }
        return `${this.monthFullLabels[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
    }

    public static GetDateOnly(d: Date) {
        var month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    public static getMonthDifferenceFromCurrent(customDate: Date | null) {
        if (customDate) {
            var startTime = moment().format('DD/MM/YYYY')
            var endTime = moment(customDate).format('DD/MM/YYYY')
            const getDate = (date: moment.MomentInput) => moment(date, 'DD/MM/YYYY').startOf('month')
            const diff = Math.abs(getDate(startTime.toString()).diff(getDate(endTime.toString()), 'months'));
            return diff
        } else {
            return -1
        }
    }

    public static getDayDifferenceFromCurrent(customDate: Date | null, compareDate?: Date) {
        if (customDate) {
            var startTime = compareDate ? moment(compareDate).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY');
            var endTime = moment(customDate).format('DD/MM/YYYY')
            const getDate = (date: moment.MomentInput) => moment(date, 'DD/MM/YYYY').startOf('day')
            const diff = Math.abs(getDate(startTime.toString()).diff(getDate(endTime.toString()), 'days'));
            return diff
        } else {
            return -1
        }
    }
}