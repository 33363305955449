import React from "react";
import { TextField, FormControlLabel, Checkbox, Grid, Button } from "@material-ui/core";
import FilePicker from "../../Components/BasicComponents/FilePicker";
import { IFileInfo } from "../../Components/BasicComponents/FileUploadModal";
import ImageGrid from "../../Components/ImageGridComponent";
import ScheduleComponent from "../../Components/ScheduleComponent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IProperty, IPropertySchedule, IAmenitiesDetails, IPropertyOwnerList, ICompanyList } from "./Interfaces";
import CustomMaps from '../../Components/GoogleMapsComponent';
import PropertyService from "../../Services/PropertyService";
import CompanyService from "../../Services/CompanyService";
import { PropertyScheduleModule } from "../../Models/PropertyScheduleModule";

export default class PropertyEditView extends React.Component<IPropertyEditProps, IPropertyEditState> {
    // constructor(props: IPropertyEditProps) {
    //     super(props);

    //     this.state = {
    //         selectedRecord: this.props.selectedRecord,
    //         selectedSchedule: {
    //             selectedDays: [],
    //             selectedPriceRange: {
    //                 start: 0,
    //                 end: 0
    //             },
    //             selectedTimeRange: {
    //                 start: 0,
    //                 end: 0
    //             }
    //         },
    //         amenitiesList: [
    //             {
    //                 name: "Wheelchair Accessible",
    //                 id: 2
    //             },
    //             {
    //                 name: "On-Site Staff",
    //                 id: 3
    //             },
    //             {
    //                 name: "Garage - Covered",
    //                 id: 4
    //             },
    //             {
    //                 name: "Lot - Uncovered",
    //                 id: 5
    //             },
    //         ],
    //         ownerList: [],
    //         selectedOwner: {
    //             propertyOwnerGuid: '',
    //             propertyOwnerName: ''
    //         },
    //         isOnwerListOpened: false,
    //         companyList: [],
    //         selectedCompany: {
    //             companyGuid: '',
    //             companyName: ''
    //         }
    //     }
    // }

    // public componentDidMount = () => {
    //     PropertyService.getUserDropDownList().then(response => {
    //         if (response.ok) {
    //             response.text().then(resp => {
    //                 let ownerList: any[] = JSON.parse(resp);

    //                 let ownerItems = ownerList.map(m => {
    //                     return {
    //                         propertyOwnerGuid: m.propertyOwnerGuid,
    //                         propertyOwnerName: m.propertyOwnerName
    //                     }
    //                 });

    //                 this.setState(
    //                     {
    //                         ownerList: ownerItems
    //                     }
    //                 );
    //             })
    //         } else {
    //             if (response.status === 401) {
    //                 this.accessTokenExpired();
    //             } else {
    //                 response.text().then(resp => {
    //                     console.log(response);
    //                 })
    //             }
    //         }
    //     });

    //     CompanyService.getCompanyDropdown().then(response => {
    //         if (response.ok) {
    //             response.text().then(resp => {
    //                 let companyList: any[] = JSON.parse(resp);

    //                 let companyItems = companyList.map(m => {
    //                     return {
    //                         companyGuid: m.companyGuid,
    //                         companyName: m.companyName
    //                     }
    //                 });

    //                 this.setState(
    //                     {
    //                         companyList: companyItems
    //                     }
    //                 );
    //             })
    //         } else {
    //             if (response.status === 401) {
    //                 this.accessTokenExpired();
    //             } else {
    //                 response.text().then(resp => {
    //                     console.log(response);
    //                 })
    //             }
    //         }
    //     });
    // }

    // render() {
    //     if (!this.props.isVisible) {
    //         return null;
    //     }
    //     return (
    //         <div className="list-edit-view">
    //             <div className="grid-list-addedit-sub">
    //                 <h4 className="list-sub-header">Description</h4>
    //                 <TextField
    //                     variant="outlined"
    //                     margin="normal"
    //                     fullWidth
    //                     id="name"
    //                     label="Name"
    //                     name="name"
    //                     autoComplete="off"
    //                     onChange={(e_: any) => {
    //                         this.state.selectedRecord.propertyName = e_.target.value;
    //                         this.setState({
    //                             selectedRecord: this.state.selectedRecord
    //                         });
    //                     }}
    //                     value={this.state.selectedRecord.propertyName}
    //                 />

    //                 <TextField
    //                     multiline
    //                     variant="outlined"
    //                     margin="normal"
    //                     fullWidth
    //                     id="AboutProperty"
    //                     label="About property"
    //                     name="aboutProperty"
    //                     onChange={(e_: any) => {
    //                         this.state.selectedRecord.propertyAbout = e_.target.value;
    //                         this.setState({
    //                             selectedRecord: this.state.selectedRecord
    //                         });
    //                     }}
    //                     value={this.state.selectedRecord.propertyAbout}
    //                 />

    //                 <h4 className="list-sub-header">Location</h4>

    //                 <TextField
    //                     variant="outlined"
    //                     margin="normal"
    //                     fullWidth
    //                     id="city"
    //                     label="City"
    //                     name="city"
    //                     autoComplete="off"
    //                     onChange={(e_: any) => {
    //                         this.state.selectedRecord.propertyCity = e_.target.value;
    //                         this.setState({
    //                             selectedRecord: this.state.selectedRecord
    //                         });
    //                     }}
    //                     value={this.state.selectedRecord.propertyCity}
    //                 />

    //                 <TextField
    //                     variant="outlined"
    //                     margin="normal"
    //                     fullWidth
    //                     id="address"
    //                     label="Address"
    //                     name="address"
    //                     autoComplete="off"
    //                     onChange={(e_: any) => {
    //                         this.state.selectedRecord.propertyAddress1 = e_.target.value;
    //                         this.setState({
    //                             selectedRecord: this.state.selectedRecord
    //                         });
    //                     }}
    //                     value={this.state.selectedRecord.propertyAddress1}
    //                 />


    //                 <h4 className="list-sub-header">Spots</h4>

    //                 <TextField
    //                     variant="outlined"
    //                     margin="normal"
    //                     fullWidth
    //                     id="quantity"
    //                     label="Quantity"
    //                     name="spot"
    //                     autoComplete="off"
    //                     onChange={(e_: any) => {
    //                         this.state.selectedRecord.propertyQuantity = e_.target.value;
    //                         this.setState({
    //                             selectedRecord: this.state.selectedRecord
    //                         });
    //                     }}
    //                     value={this.state.selectedRecord.propertyQuantity}
    //                     onPaste={this.checkPasteValue}
    //                     onKeyPress={this.checkValue}
    //                 />

    //                 <h1 className="list-header">Amenities</h1>

    //                 <h4 className="list-sub-header">Options</h4>

    //                 <div className="property-label-container">
    //                     {
    //                         this.state.amenitiesList.map((a_, i_) => (
    //                             <div className="property-label" key={a_.name + i_}>
    //                                 <FormControlLabel
    //                                     className="property-label"
    //                                     control={<Checkbox value={a_.id}
    //                                         name={"Amenities_" + a_.id}
    //                                         checked={this.state.selectedRecord.propertyAmenities ? this.state.selectedRecord.propertyAmenities.indexOf(a_.id) !== -1 : false}
    //                                         onChange={(e_: any) => {
    //                                             let _propertyAmenities = this.state.selectedRecord.propertyAmenities ? this.state.selectedRecord.propertyAmenities : [];

    //                                             this.state.selectedRecord.propertyAmenities = this.updateSelectedAmenities(_propertyAmenities, Number(e_.target.value), e_.target.checked);
    //                                             this.setState({
    //                                                 selectedRecord: this.state.selectedRecord
    //                                             });
    //                                         }}
    //                                         color="primary" />}
    //                                     label={a_.name}
    //                                 />
    //                             </div>
    //                         ))
    //                     }
    //                 </div>
    //                 <h4 className="list-sub-header">Gallery</h4>
    //                 <FilePicker
    //                     label="Add Property Images"
    //                     description="Select Property Images"
    //                     filter=".jpg,.jpeg,.png,.bmp,.gif,.tiff"
    //                     isButtonIcon={true}
    //                     onFilesSelectionComplete={(files: IFileInfo[]) => {
    //                         let _files: IImageList[] = [];
    //                         files.map((_f, i) => {
    //                             _files.push({
    //                                 extension: _f.extension,
    //                                 fileName: _f.fileName,
    //                                 fileUrl: _f.fileUrl,
    //                                 optimizedFileUrl: _f.fileUrl,
    //                                 storedFileGuid: _f.internalUid,
    //                                 thumbnailUrl: _f.thumbnailUrl,
    //                                 fileContent: _f.fileContent
    //                             })
    //                         });
    //                         this.state.selectedRecord.images = _files;
    //                         this.setState({
    //                             selectedRecord: this.state.selectedRecord
    //                         })
    //                     }}
    //                     uploadedFiles={this.getUploadedFiles()}
    //                 />
    //                 <ImageGrid
    //                     imageData={this.state.selectedRecord.images}
    //                     CarouselTitle={this.state.selectedRecord.propertyName}
    //                 />
    //                 <h4 className="list-sub-header">Access</h4>

    //                 <div className="map-container">
    //                     <CustomMaps address={this.state.selectedRecord.propertyAddress1} />
    //                 </div>

    //                 <TextField
    //                     multiline
    //                     variant="outlined"
    //                     margin="normal"
    //                     fullWidth
    //                     id="redeem"
    //                     label="How to redeem"
    //                     name="redeem"
    //                     onChange={(e_: any) => {
    //                         this.state.selectedRecord.propertyRedeem = e_.target.value;
    //                         this.setState({
    //                             selectedRecord: this.state.selectedRecord
    //                         });
    //                     }}
    //                     value={this.state.selectedRecord.propertyRedeem}
    //                 />

    //                 <h1 className="list-header">Schedule</h1>

    //                 <h4 className="list-sub-header">Week</h4>

    //                 <section className="container property-schedule">
    //                     <ScheduleComponent
    //                         selectedDays={this.state.selectedSchedule.selectedDays}
    //                         selectedTimeRange={{
    //                             start: this.state.selectedSchedule.selectedTimeRange?.start ? this.state.selectedSchedule.selectedTimeRange.start : 600,
    //                             end: this.state.selectedSchedule.selectedTimeRange?.end ? this.state.selectedSchedule.selectedTimeRange?.end : 1200
    //                         }}
    //                         selectedPriceRange={{
    //                             start: this.state.selectedSchedule.selectedPriceRange?.start ? this.state.selectedSchedule.selectedPriceRange?.start : 4,
    //                             end: this.state.selectedSchedule.selectedPriceRange?.end ? this.state.selectedSchedule.selectedPriceRange?.end : 10
    //                         }}
    //                         onChange={s_ => {
    //                             let _updatedSchedule: IPropertySchedule = {
    //                                 selectedDays: s_.selectedDays,
    //                                 selectedTimeRange: s_.selectedTimeRange,
    //                                 selectedPriceRange: s_.selectedPriceRange
    //                             }

    //                             this.setState({
    //                                 selectedSchedule: _updatedSchedule
    //                             });
    //                         }}
    //                     />
    //                 </section>

    //                 <h4 className="list-sub-header">Managing</h4>

    //                 <Autocomplete
    //                     id="propertyOwner"
    //                     className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
    //                     options={this.state.ownerList}
    //                     value={this.state.selectedOwner}
    //                     onChange={(event: any, values: any) => {
    //                         let _values: IPropertyOwnerList = {
    //                             propertyOwnerGuid: '',
    //                             propertyOwnerName: ''
    //                         }

    //                         if (values) {
    //                             _values = values;
    //                         }

    //                         this.state.selectedRecord.propertyOwnerGuid = _values.propertyOwnerGuid;
    //                         this.state.selectedRecord.propertyOwnerName = _values.propertyOwnerName;

    //                         this.setState({
    //                             selectedOwner: _values,
    //                             selectedRecord: this.state.selectedRecord
    //                         });
    //                     }}
    //                     getOptionLabel={(option) => option.propertyOwnerName}
    //                     renderInput={(params) => <TextField {...params} label="Owner" variant="outlined" />}
    //                 />

    //                 <div>
    //                     <span className="add-button" onClick={() => {
    //                         this.setState({
    //                             isOnwerListOpened: true
    //                         })
    //                     }}>+ Owner</span>
    //                 </div>

    //                 <Autocomplete
    //                     id="CompnayName"
    //                     className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
    //                     options={this.state.companyList}
    //                     value={this.state.selectedCompany}
    //                     onChange={(event: any, values: any) => {
    //                         let _values: ICompanyList = {
    //                             companyGuid: '',
    //                             companyName: ''
    //                         }

    //                         if (values) {
    //                             _values = values;
    //                         }

    //                         this.state.selectedRecord.propertyCompanyGuid = _values.companyGuid;
    //                         this.state.selectedRecord.propertyCompanyName = _values.companyName;

    //                         this.setState({
    //                             selectedCompany: _values,
    //                             selectedRecord: this.state.selectedRecord
    //                         });
    //                     }}
    //                     getOptionLabel={(option) => option.companyName}
    //                     renderInput={(params) => <TextField {...params} label="Company" variant="outlined" />}
    //                 />
    //             </div>
    //             <div className="grid-addedit-actions">
    //                 <Grid container spacing={1} className="button-container">
    //                     <Grid item xs>
    //                         <Button
    //                             fullWidth
    //                             variant="contained"
    //                             color="primary"
    //                             onClick={() => this.saveRecord()}
    //                         >Save</Button>
    //                     </Grid>
    //                     <Grid item xs>
    //                         <Button
    //                             fullWidth
    //                             variant="contained"
    //                             color="secondary"
    //                             onClick={() => {
    //                                 this.props.onClose();
    //                             }}
    //                         >Cancel</Button>
    //                     </Grid>
    //                 </Grid>
    //             </div>
    //         </div>
    //     );
    // }

    // private checkPasteValue(event: any) {
    //     var str = event.clipboardData.getData('Text');

    //     if (isNaN(str)) {
    //         event.preventDefault();
    //         return false;
    //     }
    //     return true;
    // }

    // private checkValue(event: any) {
    //     var charCode = (event.which) ? event.which : event.keyCode;

    //     if (charCode > 31 && (charCode < 48 || charCode > 57) || charCode === 101 || charCode === 69) {
    //         event.preventDefault();
    //         return false;
    //     }

    //     return true;
    // }

    // private updateSelectedAmenities(amenities: number[], value: number, isChecked: boolean) {
    //     const currentIndex = amenities.indexOf(value);
    //     const newChecked = [...amenities];

    //     if (currentIndex === -1) {
    //         newChecked.push(value);
    //     } else {
    //         newChecked.splice(currentIndex, 1);
    //     }

    //     return newChecked;
    // }

    // private getUploadedFiles() {
    //     let _files: IFileInfo[] = [];

    //     for (let i = 0; i < this.state.selectedRecord.images.length; i++) {
    //         let img = this.state.selectedRecord.images[i];

    //         _files.push({
    //             extension: img.extension,
    //             fileName: img.fileName,
    //             fileUrl: img.fileUrl,
    //             internalUid: img.storedFileGuid,
    //             thumbnailUrl: img.thumbnailUrl,
    //             fileContent: img.fileContent,
    //             storedFileGuid: img.storedFileGuid
    //         });
    //     }

    //     return _files;
    // }

    // private accessTokenExpired() {
    //     localStorage.clear();
    //     window.location.href = '/';
    // }

    // private openAddUser() {

    // }

    // private _makeTimeText(minutes_: number): string {
    //     let fullHours = Math.floor(minutes_ / 60);
    //     let remainderMinutes = minutes_ % 60;
    //     let ampm = fullHours < 12 ? "AM" : "PM";
    //     let hours = fullHours % 12;

    //     if (hours === 0) {
    //         hours = 12;
    //     }

    //     return `${this._padLeft(hours.toString(), 2, '0')}:${this._padLeft(remainderMinutes.toString(), 2, '0')} ${ampm}`;
    // }

    // private _padLeft(text_: string, length_: number, padChar_: string): string {
    //     if (padChar_) {
    //         while (text_.length < length_) {
    //             text_ = padChar_ + text_;
    //         }
    //     }

    //     return text_;
    // }

    // private saveRecord() {
    //     let _selectedRecord: IProperty = this.state.selectedRecord;

    //     /*Schedule update*/

    //     let _scheduledList: PropertyScheduleModule[] = [];
    //     let _selectedDays: number[] = [];

    //     _selectedDays = this.state.selectedSchedule.selectedDays ? this.state.selectedSchedule.selectedDays : [];

    //     let _closeTimeUtc = this.state.selectedSchedule.selectedTimeRange?.end ? this.state.selectedSchedule.selectedTimeRange?.end : 0;
    //     let _openTimeUtc = this.state.selectedSchedule.selectedTimeRange?.start ? this.state.selectedSchedule.selectedTimeRange?.start : 0;

    //     for (var s = 0; s < _selectedDays.length; s++) {
    //         let _scheduledItem: PropertyScheduleModule = {
    //             isOpen: true,
    //             closeTimeUtc: this._makeTimeText(_closeTimeUtc),
    //             openTimeUtc: this._makeTimeText(_openTimeUtc),
    //             dayOfWeekId: _selectedDays[s]
    //         };

    //         _scheduledList.push(_scheduledItem);
    //     }

    //     this.state.selectedRecord.propertyMinPrice = this.state.selectedSchedule.selectedPriceRange?.start ? this.state.selectedSchedule.selectedPriceRange?.start : 0;
    //     this.state.selectedRecord.propertyMaxPrice = this.state.selectedSchedule.selectedPriceRange?.end ? this.state.selectedSchedule.selectedPriceRange?.end : 0;

    //     this.state.selectedRecord.propertySchedule = _scheduledList;

    //     this.setState({
    //         selectedRecord: this.state.selectedRecord
    //     });
    // }
}

interface IPropertyEditProps {
    selectedRecord: IProperty,
    isVisible: boolean,
    onClose: () => void;
}

interface IPropertyEditState {
    selectedRecord: IProperty,
    selectedSchedule: IPropertySchedule,
    amenitiesList: IAmenitiesDetails[],
    ownerList: IPropertyOwnerList[],
    companyList: ICompanyList[],
    selectedOwner: IPropertyOwnerList,
    selectedCompany: ICompanyList,
    isOnwerListOpened: boolean
}