import React from 'react';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Slide,
  TextField,
  Toolbar,
  Typography
} from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import {TransitionProps} from '@material-ui/core/transitions/transition';
import './AddUser.css';
import {CountryListModel} from '../Models/CountryListModel';
import {ISpotItem, PropertyItem} from '../Models/PropertyModel';
import PropertyService from '../Services/PropertyService';
import CompanyService from '../Services/CompanyService';
import {CompanyItem} from '../Models/CompanyModel';
import {IUser, IUserCompanyDetails, IUserEdit, NewUserDetails} from '../Models/NewUserDetails';
import {ConfigService} from '../Services/ConfigService';
import {ShowAlert, ShowAlertwithConfirm} from '../Common/ShowAlert';
import {DateRange} from 'react-date-range';
import SpotService from '../Services/SpotService';
import BaseService from '../Services/BaseService';
import UserService from '../Services/UserService';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class AddUser extends React.Component<AddUserProps, AddUserState> {
  private _propertyService: PropertyService;
  private _spotService: SpotService;
  private _isValidMobileNumber: boolean = true;
  private _userService: UserService;

  constructor(props: AddUserProps) {
    super(props);
    this.state = {
      showBlocker: false,
      dateRange: [
        {
          startDate: new Date(),
          key: 'selection'
        }
      ],
      userType: this.getUserList(),
      open: this.props.requestType === 'popup' ? true : false,
      selectedUserType: this.props.userRole ? this.props.userRole : '',
      countries: [
        {code: 'CA', label: 'Canada', phone: '1'},
        {code: 'IN', label: 'India', phone: '91'},
        {code: 'US', label: 'United States', phone: '1'},
      ],
      country: {code: 'CA', label: 'Canada', phone: '1'},
      selectedProperty: {
        propertyGuid: '',
        propertyName: ''
      },
      propertyList: [],
      selectedCompany: {
        companyGuid: this.props.userDetails?.managementCompanyGuid ? this.props.userDetails?.managementCompanyGuid : '',
        companyName: this.props.userDetails?.managementCompanyName ? this.props.userDetails?.managementCompanyName : ''
      },
      companyList: [],
      newUserDetails: {
        userStatusId: this.props.userDetails?.userStatusID ? this.props.userDetails?.userStatusID : 1,
        userGuid: this.props.userDetails?.userId ? this.props.userDetails?.userId : '',
        address1: '',
        address2: '',
        city: '',
        companyGuid: this.props.userDetails?.managementCompanyGuid ? this.props.userDetails?.managementCompanyGuid : '',
        companyName: this.props.userDetails?.managementCompanyName ? this.props.userDetails?.managementCompanyName : '',
        country: '',
        countryCode: 0,
        emailAddress: this.props.userDetails?.email ? this.props.userDetails?.email : '',
        phone: this.props.userDetails?.mobileNumber ? this.props.userDetails?.countryCode + this.props.userDetails?.mobileNumber : '',
        propertyGuid: '',
        propertyName: '',
        role: this.props.userRole ? this.getRoleId(this.props.userRole) : 0,
        state: '',
        username: this.props.userDetails?.name ? this.props.userDetails?.name : '',
        zipCode: ''
      },
      user: {
        userGuid: '',
        userName: ''
      },
      selectedSpots: [],
      spotList: [],
      spotGuidList: [],
      leaseStartDate: new Date(),
      leaseEndDate: new Date(),
      hasUnsavedChanges: false
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this._propertyService = new PropertyService();
    this._spotService = new SpotService();
    this._userService = new UserService();
  }

  public componentDidMount() {

    this._propertyService.getPropertiesBasics().then(response => {
      if (response.ok) {
        response.text().then(resp => {
          let propertyList: any[] = JSON.parse(resp);

          let propertyItems = propertyList.map(m => {
            return {
              propertyGuid: m.propertyGuid,
              propertyName: m.propertyName
            }
          });

          this.setState({
            propertyList: propertyItems
          });
        });
      }
      /*else {
          if (response.status === 401) {
              this.accessTokenExpired();
          } else {
              response.text().then(resp => {
                  console.log(response);
              });
              ShowAlert("", 'There was an error processing a your request.', "error");
          }
      }*/
    });

    CompanyService.getCompanyDropdown().then(response => {
      if (response.ok) {
        response.text().then(resp => {
          let companyData: any[] = JSON.parse(resp);

          let companyItems = companyData.map(m => {
            return {
              companyGuid: m.companyGuid,
              companyName: m.companyName
            }
          })

          this.setState(
            {
              companyList: companyItems
            }
          );
        })
      }
      /*else {
          if (response.status === 401) {
              this.accessTokenExpired();
          } else {
              response.text().then(resp => {
                  console.log(response);
              });
              ShowAlert("", 'There was an error processing a your request.', "error");
          }
      }*/

    });
  }

  public render() {
    if (this.props.requestType === 'popup') {
      return (
        <form className="user-form-container" onSubmit={this.handleSubmit}>
          {/* <LoaderComponent loaderVisible={this.state.showBlocker} /> */}
          {
            this.DialogboxElements()
          }
        </form>
      )
    } else {
      return (
        <div className="right-menu-body add-user-menu">
          <form className="user-form-container" onSubmit={this.handleSubmit}>
            {/* <LoaderComponent loaderVisible={this.state.showBlocker} /> */}
            {
              this.userFormElements()
            }
            <Grid container spacing={2} className="button-container">
              <Grid item xs>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className='list-button'
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className='list-button list-button-cancel'
                  onClick={() => this.closeDialog()}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      )
    }
  }

  private DialogboxElements() {
    return (
      <Dialog open={this.state.open} onClose={() => this.closeDialog()} aria-labelledby="customized-dialog-title"
              className="popup-add-module">
        <AppBar className='add-user-appbar'>
          <Toolbar>
            <Typography variant="h6" className='add-user-appbar-title'>Add {this.props.title}</Typography>
            <IconButton
              edge="start"
              color="default"
              onClick={() => this.closeDialog()} aria-label="close">
              <CloseIcon/>
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          {
            this.userFormElements()
          }
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={this.handleSubmit}
            className='list-button'>
            save
          </Button>
        </DialogActions>
      </Dialog>
      /*<Dialog fullScreen open={this.state.open} onClose={() => this.closeDialog()} TransitionComponent={Transition}>
          <AppBar className='add-user-appbar'>
              <Toolbar>
                  <IconButton
                      edge="start"
                      color="default"
                      onClick={() => this.closeDialog()} aria-label="close">
                      <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className='add-user-appbar-title'>Add {this.props.title}</Typography>
                  <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={this.handleSubmit}
                      className='list-button'>
                      save
                  </Button>
              </Toolbar>
          </AppBar>
          <div className="popup-body">
              {
                  this.userFormElements()
              }
          </div>
      </Dialog>*/
    );


  }

  private userFormElements() {
    return (
      <div className="form-sub-container">
        {
          (this.props.requestType == 'edit') ?
            <Grid container className="list-header-container" style={{margin: '10px 0px'}}>
              <Grid item xs>
              </Grid>
              <Grid item>
                <Select
                  value={this.state.newUserDetails.userStatusId}
                  inputProps={{'aria-label': 'Without label'}}
                  className="property-state"
                  onChange={(event: any) => {
                    let userdetails_ = this.state.newUserDetails;
                    userdetails_.userStatusId = event.target.value;
                    this.setState({
                      newUserDetails: userdetails_,
                      hasUnsavedChanges: true
                    }, () => {
                      if (this.props.onChange) {
                        this.props.onChange(true)
                      }
                    });
                  }}
                >
                  <MenuItem value={1}>
                    <div className={'list-status dropdown-status active'}></div>
                    Active</MenuItem>
                  <MenuItem value={2}>
                    <div className={'list-status dropdown-status inactive'}></div>
                    Inactive</MenuItem>
                </Select>
              </Grid>
            </Grid>
            : null
        }
        {
          // && this.props.requestType != 'edit'
          (this.props.requestType != 'popup' && this.state.newUserDetails.role != 6 && this.state.newUserDetails.role != 7) ?
            this.renderUserRole()
            : null
        }
        {
          (this.props.requestType != 'popup') ? this.userMappingDropDown() : null
        }
        {/* <h4 className="list-sub-header">{this.props.addUserAs == 'company' ? 'Company Information' : 'User Information'}</h4> */}

        {/* {
                    (this.props.requestType == 'edit' && this.props.userRole == 'Manager' || this.props.userRole == 'Building Contact') ? <Autocomplete
                        id="adduser_company"
                        className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
                        options={this.state.companyList}
                        value={this.state.selectedCompany}
                        onChange={(event: any, values: any) => {
                            this.state.newUserDetails.companyGuid = values.companyGuid;
                            this.state.newUserDetails.companyName = values.companyName;
                            this.setState({
                                newUserDetails: this.state.newUserDetails,
                                selectedCompany: values,
                                hasUnsavedChanges: true
                            }, () => {
                                if (this.props.onChange) {
                                    this.props.onChange(true)
                                }
                            });
                        }}
                        disabled={true}
                        getOptionLabel={(option) => option.companyName}
                        renderInput={(params) => <TextField {...params} label="Management Company" variant="outlined" />}
                    /> : null
                } */}

        {(this.props.addUserAs != 'company' && this.props.addUserAs != 'BuildingContact') ?
          <h4 className="list-sub-header">User Information</h4> : null
        }
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="addusername"
          label={this.props.addUserAs == 'company' ? "Property Owner Name" : "Name"}
          name="addusername"
          required={this.props.addUserAs != 'company'}
          error={this.state.newUserDetails.username.length < 3}
          onChange={(e_: any) => {
            this.state.newUserDetails.username = e_.target.value;
            this.setState({
              newUserDetails: this.state.newUserDetails,
              hasUnsavedChanges: true
            }, () => {
              if (this.props.onChange) {
                this.props.onChange(true)
              }
            });
          }}
          autoComplete="off"
          // helperText={this.state.username.length == 0 ? 'Please fill out this field.' : ''}
          value={this.state.newUserDetails.username}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="adduseremail"
          label="Contact Email"
          name="adduseremail"
          value={this.state.newUserDetails.emailAddress}
          error={
            this.props.requestType != 'edit' &&
            !this.validateEmail(this.state.newUserDetails.emailAddress) &&
            (this.state.newUserDetails.emailAddress.length > 0 || this.props.addUserAs != 'company')}
          onChange={(e_: any) => {
            this.state.newUserDetails.emailAddress = e_.target.value;
            this.setState({
              newUserDetails: this.state.newUserDetails,
              hasUnsavedChanges: true
            }, () => {
              if (this.props.onChange) {
                this.props.onChange(true)
              }
            });
          }}
          autoComplete="off"
          disabled={this.props.requestType == 'edit'}
        />
        <PhoneInput
          isValid={(value: any, country: any) => {
            if (this.isInValidPhoneNumber(value, country.countryCode) && this.props.requestType != 'edit' && (this.props.addUserAs != 'company' || this.state.newUserDetails.phone.length > 0)) {
              this._isValidMobileNumber = false;

              return 'Invalid Number';
              // return 'Invalid Phone: ' + value;
            } else if (value.match(/1234/) && (this.props.addUserAs != 'company' || this.state.newUserDetails.phone.length > 0)) {
              this._isValidMobileNumber = false;
              return false;
            } else {
              this._isValidMobileNumber = true;
              return true;
            }
          }}
          country={'ca'}
          onlyCountries={['ca', 'us', 'in']}
          placeholder={'+# ### - ### - ####'}
          masks={{ca: '...-...-....', us: '...-...-....', in: '...-...-....'}}
          value={this.state.newUserDetails.phone}
          onChange={phone => {
            this.state.newUserDetails.phone = phone;
            this.setState({
              newUserDetails: this.state.newUserDetails,
              hasUnsavedChanges: true
            }, () => {
              if (this.props.onChange) {
                this.props.onChange(true)
              }
            });
          }}
          onBlur={(e_: any) => {
            this.state.newUserDetails.phone = e_.target.value == "" ? e_.target.value : this.state.newUserDetails.phone;
            this.setState({
              newUserDetails: this.state.newUserDetails,
              hasUnsavedChanges: true
            }, () => {
              if (this.props.onChange) {
                this.props.onChange(true)
              }
            });
          }}
          disabled={this.props.requestType == 'edit'}
          autocompleteSearch={false}
          containerClass="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth Mui-disabled"
          inputProps={{
            name: 'addnewPhone',
            required: true,
          }}
        />

        {
          (this.props.requestType != 'popup' && this.props.requestType == 'edit') &&
            <React.Fragment>
              {/* {
                            this.renderUserRole()
                        } */}
                <div className="reset-passowrd" onClick={() => {
                  this.forgotPasswordLinkService();
                }}>Reset Password
                </div>
            </React.Fragment>

        }

        {/* {
                    (!this.props.addUserAs) ? this.userAddressPart() : null
                } */}


      </div>
    )
  }

  private forgotPasswordLinkService() {
    let this_ = this;
    this._userService.forgotPasswordLinkService(this.state.newUserDetails.emailAddress)
      .then(response => {
        if (response.ok) {
          response.text().then(resp => {
            this.setState({
              showBlocker: false
            }, () => {
              ShowAlertwithConfirm("Link Sent", `<div class="gryd-custom-popup-body">
                            A reset link has been sent to<br/>
                            <b>${this.state.newUserDetails.emailAddress}</b>
                            </div>`, undefined, 'Resend Link', 'Close', 'gryd-custom-popup gryd-reset-link')
                .then(r_ => {
                  if (r_) {
                    this_.forgotPasswordLinkService();
                  }
                })

            });
          });

        } else {
          response.text().then(resp => {
            this.setState({
              showBlocker: false
            }, () => {
              var message = "";
              if (resp === '"Invalid Email or not registered"') {
                message = 'Entered email is incorrect or not registered.';
              } else {
                message = JSON.parse(resp);
              }

              ShowAlert('Server Error!', message.toString(), 'error');
            });
          });

        }
      }).catch(error => {
      this.setState({
        showBlocker: false
      });
      ShowAlert('Server Error!', error.toString(), 'error');
    });
  }

  private renderUserRole() {
    return (
      <React.Fragment>
        <Autocomplete
          id="propertyOwner"
          className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
          options={this.state.userType}
          value={this.state.selectedUserType}
          getOptionLabel={(option) => option}
          renderInput={(params) => <TextField required {...params} label="Role"
                                              error={this.state.selectedUserType == '' || this.state.selectedUserType == null}
                                              variant="outlined"/>}
          disableClearable={true}
          onChange={(event: any, values: any) => {
            this.state.newUserDetails.propertyName = '';

            let userCurrentRole = this.getRoleId(values);

            this.state.newUserDetails.role = userCurrentRole;

            let _selectedCompany = this.state.selectedCompany;

            if (userCurrentRole != 5 && userCurrentRole != 8) {
              this.state.newUserDetails.companyGuid = '';
              this.state.newUserDetails.companyName = '';
              _selectedCompany = {
                companyGuid: '',
                companyName: ''
              };
            }

            this.setState({
              newUserDetails: this.state.newUserDetails,
              selectedUserType: values,
              hasUnsavedChanges: true,
              selectedCompany: _selectedCompany
            }, () => {
              if (this.props.onChange) {
                this.props.onChange(true)
              }
            });
          }}
        />
      </React.Fragment>
    )
  }

  private userAddressPart() {
    return (
      <div>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="AddressLine1"
          name="AddressLine1"
          className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
          label="Address Line 1"
          onChange={(e_: any) => {
            this.state.newUserDetails.address1 = e_.target.value;
            this.setState({
              newUserDetails: this.state.newUserDetails,
              hasUnsavedChanges: true
            }, () => {
              if (this.props.onChange) {
                this.props.onChange(true)
              }
            });
          }}
          value={this.state.newUserDetails.address1}
          autoComplete="off"
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="AddressLine2"
          name="AddressLine2"
          className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
          label="Address Line 2"
          onChange={(e_: any) => {
            this.state.newUserDetails.address2 = e_.target.value;
            this.setState({
              newUserDetails: this.state.newUserDetails,
              hasUnsavedChanges: true
            }, () => {
              if (this.props.onChange) {
                this.props.onChange(true)
              }
            });
          }}
          value={this.state.newUserDetails.address2}
          autoComplete="off"
        />

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="CityName"
          name="CityName"
          className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
          label="City"
          onChange={(e_: any) => {
            this.state.newUserDetails.city = e_.target.value;
            this.setState({
              newUserDetails: this.state.newUserDetails,
              hasUnsavedChanges: true
            }, () => {
              if (this.props.onChange) {
                this.props.onChange(true)
              }
            });

          }}
          value={this.state.newUserDetails.city}
          autoComplete="off"
        />

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="StateName"
          name="StateName"
          className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
          label="State"
          onChange={(e_: any) => {
            this.state.newUserDetails.state = e_.target.value;
            this.setState({
              newUserDetails: this.state.newUserDetails,
              hasUnsavedChanges: true
            }, () => {
              if (this.props.onChange) {
                this.props.onChange(true)
              }
            });
          }}
          value={this.state.newUserDetails.state}
          autoComplete="off"
        />

        <Autocomplete
          id="CountryName"
          className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
          options={this.state.countries}
          value={this.state.country}
          onChange={(event: any, values: any) => {
            this.setState({
              country: values,
              hasUnsavedChanges: true
            }, () => {
              if (this.props.onChange) {
                this.props.onChange(true)
              }
            });
          }}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField {...params} label="Country" variant="outlined"/>}
        />

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="ZipCode"
          name="ZipCode"
          className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
          label="Zip Code"
          value={this.state.newUserDetails.zipCode}
          onChange={(e_: any) => {
            this.state.newUserDetails.zipCode = e_.target.value;
            this.setState({
              newUserDetails: this.state.newUserDetails,
              hasUnsavedChanges: true
            }, () => {
              if (this.props.onChange) {
                this.props.onChange(true)
              }
            });
          }}
          autoComplete="off"
        />
      </div>
    )
  }

  private userMappingDropDown() {
    if (this.state.selectedUserType.toLocaleLowerCase() == 'property owner') {
      return (
        <TextField
          error={this.state.newUserDetails.propertyName.length < 3}
          required
          variant="outlined"
          margin="normal"
          fullWidth
          id="PropertyName"
          name="PropertyName"
          className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
          label="Property Name"
          value={this.state.newUserDetails.propertyName}
          onChange={(e_: any) => {
            this.state.newUserDetails.propertyName = e_.target.value;
            this.setState({
              newUserDetails: this.state.newUserDetails,
              hasUnsavedChanges: true
            }, () => {
              if (this.props.onChange) {
                this.props.onChange(true)
              }
            });
          }}
          autoComplete="off"
        />
      );
    } else if (this.state.selectedUserType.toLocaleLowerCase() == 'company admin') {
      return (
        <TextField
          error={this.state.newUserDetails.companyName.length < 3}
          required
          variant="outlined"
          margin="normal"
          fullWidth
          id="CompanyName"
          name="CompanyName"
          className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
          label="Company Name"
          value={this.state.newUserDetails.companyName}
          onChange={(e_: any) => {
            this.state.newUserDetails.companyName = e_.target.value;
            this.setState({
              newUserDetails: this.state.newUserDetails,
              hasUnsavedChanges: true
            }, () => {
              if (this.props.onChange) {
                this.props.onChange(true)
              }
            });
          }}
          autoComplete="off"
        />
      );
    } else if ((this.state.selectedUserType.toLocaleLowerCase() == 'manager' || this.state.selectedUserType.toLocaleLowerCase() == 'building contact')) {
      return (
        <Autocomplete
          id="adduser_company"
          className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
          options={this.state.companyList}
          value={this.state.selectedCompany}
          onChange={(event: any, values: any) => {
            let values_: CompanyItem = {
              companyGuid: '',
              companyName: ''
            }

            if (values) {
              values_ = values;
              this.state.newUserDetails.companyGuid = values.companyGuid;
              this.state.newUserDetails.companyName = values.companyName;
            } else {
              this.state.newUserDetails.companyGuid = '';
              this.state.newUserDetails.companyName = '';
            }
            this.setState({
              newUserDetails: this.state.newUserDetails,
              selectedCompany: values,
              hasUnsavedChanges: true
            }, () => {
              if (this.props.onChange) {
                this.props.onChange(true)
              }
            });
          }}
          getOptionLabel={(option) => option.companyName}
          renderInput={(params) => <TextField {...params}
                                              required
                                              error={this.state.selectedCompany == null || this.state.selectedCompany.companyName == ''}
                                              label="Management Company"
                                              variant="outlined"/>}
        />
      )
    } else if (this.state.selectedUserType.toLocaleLowerCase() == 'tenant' && this.props.requestType != 'edit') {
      return (
        <div>
          <Autocomplete
            id="adduser_propertyList"
            className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
            options={this.state.propertyList}
            value={this.state.selectedProperty}
            onChange={(event: any, values: any) => {
              this.state.newUserDetails.propertyGuid = values ? values.propertyGuid : "";
              this.state.newUserDetails.propertyName = values ? values.propertyName : "";

              this.setState({
                newUserDetails: this.state.newUserDetails,
                selectedProperty: values,
                selectedSpots: values ? this.state.selectedSpots : [],
                hasUnsavedChanges: true
              }, () => {
                if (this.props.onChange) {
                  this.props.onChange(true)
                }
              });
              if (values) {
                this.getSpotList(values.propertyGuid);
              }
            }}
            getOptionLabel={(option) => option.propertyName}
            renderInput={(params) => <TextField {...params}
                                                required
                                                error={
                                                  this.state.selectedProperty == null || this.state.selectedProperty.propertyGuid == ''
                                                }
                                                label="Property" variant="outlined"/>}
          />

          <Autocomplete
            multiple
            id="adduser_spotList"
            className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth"
            options={this.state.spotList}
            value={this.state.selectedSpots}
            onChange={(event: any, values: any) => {
              let spotGuids: string[] = [];
              if (values) {
                spotGuids = values.map((s: any) => {
                  return s.spotGuid
                });
              }
              this.setState({
                selectedSpots: values ? values : [],
                spotGuidList: spotGuids,
                hasUnsavedChanges: true
              }, () => {
                if (this.props.onChange) {
                  this.props.onChange(true)
                }
              });
            }}
            getOptionLabel={(option) => option.friendlySpotId}
            renderInput={(params) => <TextField {...params}
              /*error={
                  this.state.selectedSpots == null || this.state.selectedSpots.length == 0
              }*/
                                                label="Spot" variant="outlined"/>}
          />

          <div><b>Lease Duration</b></div>
          <DateRange
            // editableDateInputs={true}
            // onChange={item => setRangeDateState([item.selection])}
            onChange={(item: any) => {
              let startDate_: any;
              let endDate_: any;
              if (this.state.dateRange[0].endDate) {
                startDate_ = item.selection.startDate ? item.selection.startDate.toUTCString() : new Date();
                endDate_ = item.selection.endDate ? item.selection.endDate.toUTCString() : new Date();
              }
              this.setState({
                dateRange: [item.selection],
                leaseStartDate: new Date(startDate_),
                leaseEndDate: new Date(endDate_),
                hasUnsavedChanges: true
              }, () => {
                if (this.props.onChange) {
                  this.props.onChange(true)
                }
              });
            }}
            // moveRangeOnFirstSelection={false}
            ranges={this.state.dateRange}
          />
        </div>
      )
    }

    return null;
  }

  private closeDialog() {

    if (this.props.popupState) {
      this.props.popupState(false)
    }

    this.setState({
      open: false
    });

  }

  private validateEmail(email: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  private isInValidPhoneNumber(value: any, countryCode: string) {
    let phoneNumberLength = (value.length - countryCode.length);
    return (phoneNumberLength != 0 && phoneNumberLength < 10);
  }

  private accessTokenExpired() {
    localStorage.clear();
    window.location.href = '/';
  }

  private handleSubmit(event: any) {
    let isValue = true;

    if (!this.props.userRole) {
      if (this.state.newUserDetails.role == 0) {
        isValue = false;
        ShowAlert('Error!', 'Please select the user role.', 'error');
      } else if (this.state.newUserDetails.role != 0) {
        let _roleId = this.state.newUserDetails.role;
        if (this.state.newUserDetails.propertyName == '' && _roleId == 3) {
          isValue = false;
          ShowAlert('Error!', 'Please enter the <b>Property Name<b/>.', 'error');
        } else if (this.state.newUserDetails.companyName == '' && _roleId == 4) {
          isValue = false;
          ShowAlert('Error!', 'Please enter the <b>Company Name<b/>.', 'error');
        } else if (this.state.newUserDetails.companyName == '' && (_roleId == 5 || _roleId == 8)) {
          isValue = false;
          ShowAlert('Error!', 'Please select the <b>Company<b/>.', 'error');
        } else if (_roleId == 6) {
          if (this.state.newUserDetails.propertyGuid == "") {
            isValue = false;
            ShowAlert('Error!', 'Please select the <b>Property<b/>.', 'error');
          } else if (this.state.selectedSpots == null || this.state.selectedSpots.length == 0) {
            isValue = false;
            ShowAlert('Error!', 'Please select the <b>Spots</b>. If spots not available, then add them in Spots screen.', 'error');
          } else if (!this.state.dateRange[0].endDate) {
            ShowAlert('Error!', 'Please select the <b>Lease Duration</b>.', 'error');
          }
        }
      }
    }

    console.log(this.state, this._isValidMobileNumber);

    if (this.state.newUserDetails.username.length < 3) {
      isValue = false;
      let name_ = this.props.addUserAs == 'company' ? 'Property Owner Name' : 'Name';
      ShowAlert('Error!', 'Please enter the <b>' + name_ + '</b>.', 'error');
    } else if (!this.validateEmail(this.state.newUserDetails.emailAddress) &&
      (this.state.newUserDetails.emailAddress.length > 0 || this.props.addUserAs != 'company')) {
      isValue = false;
      ShowAlert('Error!', 'Please enter the valid <b>email</b>.', 'error');
    } else if (this.state.newUserDetails.phone != '' || this.props.addUserAs != 'company') {
      if (!this._isValidMobileNumber) {
        isValue = false;
        ShowAlert('Error!', 'Please enter the valid <b>phone number</b>.', 'error');
      } else {
        var CountryCode = this.state.newUserDetails.phone.slice(0, -10).replace('+', '');
        if (this.isInValidPhoneNumber(this.state.newUserDetails.phone, CountryCode) && this.state.newUserDetails.phone.toString() != '1') {
          isValue = false;
          ShowAlert('Error!', 'Please enter the valid <b>phone number</b>.', 'error');
        }
      }
    }

    if (this._isValidMobileNumber && isValue) {
      this.setState({
        showBlocker: true
      })

      if (this.props.requestType != 'edit') {
        this.addUserService().then(response => {
          if (response.ok) {
            response.json().then(resp => {
              let _user: any = JSON.parse(resp);

              var CountryCode = this.state.newUserDetails.phone.slice(0, -10).replace('+', '');
              this.state.newUserDetails.phone = this.state.newUserDetails.phone.slice(-10);
              this.state.newUserDetails.countryCode = CountryCode ? Number(CountryCode) : 0;
              this.state.newUserDetails.userGuid = _user.ApplicationUserGuid;
              this.state.newUserDetails.companyGuid = this.state.selectedCompany.companyGuid;
              this.state.newUserDetails.companyName = this.state.selectedCompany.companyName;
              ShowAlert('Success!', 'User added successfully.', 'success');

              this.setState({
                showBlocker: false,
                newUserDetails: this.state.newUserDetails
              });
              if (this.props.onAddedUser) {
                this.props.onAddedUser(this.state.newUserDetails);
              }
            })
          } else {
            this.setState({
              showBlocker: false
            });

            response.text().then(resp => {
              let respMsg = JSON.parse(resp);
              let errorMsg: string = "";

              if (respMsg.indexOf('Email id and Mobile') != -1) {
                errorMsg = "There is already an account associated with this email id and mobile number."
              } else if (respMsg == 'Email id already exists!') {
                errorMsg = "There is already an account associated with this email id."
              } else if (respMsg == 'Mobile number already exists!') {
                errorMsg = "There is already an account associated with this mobile number."
              } else {
                errorMsg = respMsg;
              }

              ShowAlert('Error!', errorMsg, 'error');
            });
            /*if (response.status === 401) {
                response.text().then(resp => {
                    if (resp.toLocaleLowerCase().indexOf('unauthorized') != -1) {
                        this.accessTokenExpired();
                    } else {
                        ShowAlert('Error!', resp, 'error');
                    }
                })

            } else {
                response.text().then(resp => {
                    console.log(resp);
                });
                ShowAlert("", 'There was an error processing a your request.', "error");
            }*/
          }
        });
      } else {
        var postData = {
          "ApplicationUserGuid": this.state.newUserDetails.userGuid,
          "Name": this.state.newUserDetails.username,
          "UserRole": this.state.newUserDetails.role,
          "PrimaryRoleId": this.props.userRole ? this.getRoleId(this.props.userRole) : 1,
          "UserStatusId": this.state.newUserDetails.userStatusId,
          "CompanyGuid": this.state.newUserDetails.companyGuid != "" ? this.state.newUserDetails.companyGuid : null
        }

        this._userService.updateUserDetailsV2(postData).then(response => {
          if (response.ok) {
            response.text().then(resp => {
              ShowAlert('Success!', 'User updated successfully.', 'success');

              this.setState({
                showBlocker: false,
              });
              if (this.props.onAddedUser) {
                this.props.onAddedUser(this.state.newUserDetails);
              }
            })
          } else {
            this.setState({
              showBlocker: false
            });
            response.text().then(resp => {
              let respMsg = JSON.parse(resp);
              ShowAlert('', respMsg, 'error');
            });
          }
        });

        /*this.updateUser().then(response => {
            if (response.ok) {
                response.text().then(resp => {
                    ShowAlert('Success!', 'User updated successfully.', 'success');

                    this.setState({
                        showBlocker: false,
                    });
                    if (this.props.onAddedUser) {
                        this.props.onAddedUser(this.state.newUserDetails);
                    }
                })
            } else {
                this.setState({
                    showBlocker: false
                });
                response.text().then(resp => {
                    let respMsg = JSON.parse(resp);
                    ShowAlert('', respMsg, 'error');
                });
            }
        });*/
      }
    }

    event.preventDefault();
  }

  private addUserService() {
    let phone = this.state.newUserDetails.phone && this.state.newUserDetails.phone.length > 10 ? this.state.newUserDetails.phone : "";
    var CountryCode = phone.slice(0, -10).replace('+', '');
    var MobileNumber = phone.slice(-10);

    var postData = {
      "Name": this.state.newUserDetails.username,
      "Email": this.state.newUserDetails.emailAddress,
      "CountryCode": CountryCode ? Number(CountryCode) : 0,
      "MobileNumber": MobileNumber,
      "Address1": this.state.newUserDetails.address1,
      "Address2": this.state.newUserDetails.address2,
      "City": this.state.newUserDetails.city,
      "State": this.state.newUserDetails.state,
      "Country": this.state.newUserDetails.country,
      "ZipCode": this.state.newUserDetails.zipCode,
      "UserRole": this.props.addUserAs == 'company' ? 3 : this.state.newUserDetails.role,
      "PropertyName": this.state.newUserDetails.propertyName != '' ? this.state.newUserDetails.propertyName : null,
      "CompanyGuid": this.state.newUserDetails.companyGuid != '' ? this.state.newUserDetails.companyGuid : null,
      "CompanyName": this.state.newUserDetails.companyName != '' ? this.state.newUserDetails.companyName : null,
      "PropertyGuid": this.state.newUserDetails.propertyGuid != '' ? this.state.newUserDetails.propertyGuid : null,
      "SpotGuid": this.state.spotGuidList,
      "LeaseStartUtc": this.state.newUserDetails.role == 6 ? this.state.leaseStartDate : null,
      "LeaseEndUtc": this.state.newUserDetails.role == 6 ? this.state.leaseEndDate : null
    }

    // console.log(postData);

    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + 'user/insertupdate', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        }, 'ignoreAlert');
        //.then(response => response.json());
      });
  }

  private updateUser() {
    var postData = {
      "Name": this.state.newUserDetails.username,
      "ApplicationUserGuid": this.state.newUserDetails.userGuid,
      "UserStatusId": this.state.newUserDetails.userStatusId
    }

    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + 'user/UpdateUserDetails', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        }, 'ignoreAlert')
        //.then(response => response.json());
      });
  }

  private getRole(roleNumber: number) {
    var role = "";

    switch (roleNumber) {
      case 3:
        role = "Owner";
        break;
      case 4:
        role = "Management Company";
        break;
      case 5:
        role = "Property Manager";
        break;
      case 6:
        role = "Tenant";
        break;
    }

    return role;
  }

  private getSpotList(propertyGuid: string) {
    this._spotService.getPropertySpots(propertyGuid).then(response => {
      if (response.ok) {
        response.text().then(resp => {
          let spotList_: any[] = JSON.parse(resp);
          console.log(spotList_);

          let spotItems = spotList_.map(m => {
            return {
              spotGuid: m.spotGuid,
              friendlySpotId: m.friendlySpotId
            }
          });

          if (spotItems.length == 0) {
            ShowAlert('Error!', 'No spots available. Please add them in Spots screen.', 'error');
          }

          this.setState({
            spotList: spotItems
          });
        });
      }
      /*else {
          if (response.status === 401) {
              this.accessTokenExpired();
          } else {
              response.text().then(resp => {
                  console.log(response);
              });
              ShowAlert("", 'There was an error processing a your request.', "error");
          }
      }*/
    });
  }


  private getRoleId(values: string) {
    let _roleId = 0;

    if (values == 'Gryd Admin') {
      _roleId = 1;
    }
    if (values == 'Gryd User') {
      _roleId = 2;
    } else if (values == 'Property Owner') {
      _roleId = 3;
    } else if (values == 'Company Admin') {
      _roleId = 4;
    } else if (values == 'Manager') {
      _roleId = 5;
    } else if (values == 'Tenant') {
      _roleId = 6;
    } else if (values == 'Building Contact') {
      _roleId = 8;
    } else if (values === 'Parking Enforcement Officer') {
      _roleId = 9;
    }

    return _roleId;
  }

  private getUserList() {
    let userList_: string[] = [];

    if (this.props.requestType != 'popup') {
      if (this.props.permissionType == 'Gryd Admin') {
        userList_ = [
          'Gryd Admin',
          // 'GRYD User',
          'Manager',
          'Building Contact',
          'Parking Enforcement Officer'
          // 'Tenant', // Commented this, tenant will be added from property
        ];
      } else if (this.props.permissionType == 'GRYD User') {
        userList_ = [
          'Manager',
          // 'Tenant', // Commented this, tenant will be added from property
        ];
      }
      // Commented this, tenant will be added from property
      /*else {
          userList_ = [
              'Tenant'
          ];
      }*/
    } else {
      userList_ = [
        'Gryd Admin',
        'Property Owner',
        'Company Admin',
        'Manager',
        'Tenant',
        'Parking Enforcement Officer'
      ]
    }

    return userList_;
  }
}

interface DateRangeLibDateRangeObject {
  startDate?: Date;
  endDate?: Date;
  key: string;
}

interface AddUserProps {
  requestType?: string,
  userRole?: string,
  addUserAs?: string,
  title?: string,
  permissionType?: string,
  popupState?: (s_: boolean) => void,
  onAddedUser: (u_: NewUserDetails) => void,
  onChange?: (s_: boolean) => void,
  userDetails?: IUserEdit,
  companyDetails?: IUserCompanyDetails
}

interface AddUserState {
  userType: string[],
  open: boolean,
  selectedUserType: string,
  countries: CountryListModel[],
  country: CountryListModel,
  selectedProperty: PropertyItem,
  propertyList: PropertyItem[],
  selectedCompany: CompanyItem,
  companyList: CompanyItem[],
  newUserDetails: NewUserDetails,
  user: IUser,
  dateRange: DateRangeLibDateRangeObject[],
  spotList: ISpotItem[],
  selectedSpots: ISpotItem[],
  spotGuidList: string[],
  leaseStartDate: Date,
  leaseEndDate: Date,
  showBlocker: boolean,
  hasUnsavedChanges: boolean
}

export default AddUser;
