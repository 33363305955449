import React from "react";
import DataGrid from "../../Components/DataGrid/DataGrid";
import { IDataGridColumn, DataGridFilterType } from "../../Components/DataGrid/Interfaces";
import PropertyService from "../../Services/PropertyService";
import { IDashbaordSpotReservation, ITimeSpan } from "./Interfaces";
import { ShowAlert } from "../../Common/ShowAlert";

export default class ParkingSpotReservation extends React.Component<IParkingSpotReservationProps, IParkingSpotReservationState> {
    private _propertyService: PropertyService;
    private _reservationListColumns: IDataGridColumn<IDashbaordSpotReservation>[] = [
        {
            key: "friendlySpotId",
            name: "SPOT ID"
        },
        {
            key: "propertySpotParkerName",
            name: "NAME"
        },
        {
            key: "propertySpotParkerEmail",
            name: "EMAIL"
        },
        {
            key: "paidAmount",
            name: "PAID AMOUNT",
            textAlignment: "right",
            contentProvider: (row_: IDashbaordSpotReservation) => {
                return (
                    <span>
                        ${row_.paidAmount.toFixed(2)}
                    </span>
                );
            }
        },
    ]

    private _parkingSpots: IDashbaordSpotReservation[] = [
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            spotGuid: "d8ce1051-71b4-4f99-9178-01c674ea62d2",
            propertySpotParkerName: "Vinod",
            propertySpotParkerEmail: "Vinodkumar.V@congruentindia.com",
            propertySpotType: '',
            friendlySpotId: "FSPT3",
            paidAmount: 4.27
        },
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            spotGuid: "ca990e7e-67de-4cab-89d0-14a57a1fc877",
            propertySpotParkerName: "Vinod",
            propertySpotParkerEmail: "Vinodkumar.V@congruentindia.com",
            propertySpotType: '',
            friendlySpotId: "FSP2T",
            paidAmount: 4.27
        },
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            spotGuid: "d8ce1051-71b4-4f99-9178-01c674ea62d2",
            propertySpotParkerName: "Vinod",
            propertySpotParkerEmail: "Vinodkumar.V@congruentindia.com",
            propertySpotType: '',
            friendlySpotId: "FSPT3",
            paidAmount: 4.27
        },
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            spotGuid: "ca990e7e-67de-4cab-89d0-14a57a1fc877",
            propertySpotParkerName: "Vinod",
            propertySpotParkerEmail: "Vinodkumar.V@congruentindia.com",
            propertySpotType: '',
            friendlySpotId: "FSP2T",
            paidAmount: 4.27
        },
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            spotGuid: "6e166632-1fa4-42bc-af11-346310fda535",
            propertySpotParkerName: "Vinod",
            propertySpotParkerEmail: "Vinodkumar.V@congruentindia.com",
            propertySpotType: '',
            friendlySpotId: "FSP3",
            paidAmount: 4.27
        },
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            spotGuid: "13d8fc9a-51cc-4b24-a422-38da54b98812",
            propertySpotParkerName: "Vinod",
            propertySpotParkerEmail: "Vinodkumar.V@congruentindia.com",
            propertySpotType: '',
            friendlySpotId: "Vee2",
            paidAmount: 4.27
        },
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            spotGuid: "bf58f399-f22f-466c-abde-3afebac262b9",
            propertySpotParkerName: "Vinod",
            propertySpotParkerEmail: "Vinodkumar.V@congruentindia.com",
            propertySpotType: '',
            friendlySpotId: "CIT3",
            paidAmount: 4.27
        },
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            spotGuid: "6e166632-1fa4-42bc-af11-346310fda535",
            propertySpotParkerName: "Vinod",
            propertySpotParkerEmail: "Vinodkumar.V@congruentindia.com",
            propertySpotType: '',
            friendlySpotId: "FSP3",
            paidAmount: 4.27
        },
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            spotGuid: "13d8fc9a-51cc-4b24-a422-38da54b98812",
            propertySpotParkerName: "Vinod",
            propertySpotParkerEmail: "Vinodkumar.V@congruentindia.com",
            propertySpotType: '',
            friendlySpotId: "Vee2",
            paidAmount: 4.27
        },
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            spotGuid: "81642e6c-3ff3-4a03-92d8-9723260ebcd4",
            propertySpotParkerName: "Vinod",
            propertySpotParkerEmail: "Vinodkumar.V@congruentindia.com",
            propertySpotType: '',
            friendlySpotId: "CIT4",
            paidAmount: 4.27
        },
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            spotGuid: "bf58f399-f22f-466c-abde-3afebac262b9",
            propertySpotParkerName: "Vinod",
            propertySpotParkerEmail: "Vinodkumar.V@congruentindia.com",
            propertySpotType: '',
            friendlySpotId: "CIT3",
            paidAmount: 4.27
        },
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            spotGuid: "df59afaf-6d0c-4594-b5de-593ba982d35c",
            propertySpotParkerName: "Vinod",
            propertySpotParkerEmail: "Vinodkumar.V@congruentindia.com",
            propertySpotType: '',
            friendlySpotId: "Vee3",
            paidAmount: 4.27
        },
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            spotGuid: "13d8fc9a-51cc-4b24-a422-38da54b98812",
            propertySpotParkerName: "Vinod",
            propertySpotParkerEmail: "Vinodkumar.V@congruentindia.com",
            propertySpotType: '',
            friendlySpotId: "Vee2",
            paidAmount: 4.27
        },
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            spotGuid: "df59afaf-6d0c-4594-b5de-593ba982d35c",
            propertySpotParkerName: "Vinod",
            propertySpotParkerEmail: "Vinodkumar.V@congruentindia.com",
            propertySpotType: '',
            friendlySpotId: "Vee3",
            paidAmount: 4.27
        },
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            spotGuid: "81642e6c-3ff3-4a03-92d8-9723260ebcd4",
            propertySpotParkerName: "Vinod",
            propertySpotParkerEmail: "Vinodkumar.V@congruentindia.com",
            propertySpotType: '',
            friendlySpotId: "CIT4",
            paidAmount: 4.27
        }
    ]

    constructor(props: any) {
        super(props);

        this.state = {
            allParkingReservation: [],
            searchTerm: "",
            isFiltered: false,
            isDataLoaded: false
        };

        this._propertyService = new PropertyService();
    }

    public componentDidMount = () => {
        this._propertyService.getDashboardUserReservation(this.props.propertyID).then(response => {
            if (response.ok) {
                response.json().then(pr => {
                    if (pr) {
                        let mappedItems = pr.map((r: any) => {
                            return r;
                        });

                        this.setState({
                            isDataLoaded: true,
                            allParkingReservation: mappedItems
                        })
                    }
                })
            } 
            /*else {
                if (response.status === 401) {
                    this.accessTokenExpired();
                } else {
                    response.text().then(resp => {
                        console.log(response);
                    });
                    ShowAlert("", 'There was an error processing a your request.', "error");
                }
            }*/
        });
        /*
        let mappedItems = this._parkingSpots.map(r => {
            return r;
        });

        this.setState({
            allParkingReservation: mappedItems
        })*/
    }


    render() {
        if (!this.state.isDataLoaded) {
            return null;
        }
        return (
            <DataGrid
                title="Reservation"
                data={this.state.allParkingReservation}
                columns={this._reservationListColumns}
                isRowsNonSelectable={true}
                isNoMoreLink={true}
            />
        );
    }

    private setPriceRange(min: number, max: number) {
        let price_: string = '0';

        price_ = min.toFixed(2) + '-' + max.toFixed(2);

        return '$' + price_;
    }

    private setScheduleTime(startTime: ITimeSpan, endTime: ITimeSpan) {
        let time_: string = '';

        if (startTime.minutes == endTime.minutes) {
            time_ = '24/7';
        } else {
            time_ = this._makeTimeText(startTime.minutes) + '-' + this._makeTimeText(endTime.minutes)
        }

        return time_;
    }

    private _makeTimeText(minutes_: number): string {
        let fullHours = Math.floor(minutes_ / 60);
        let remainderMinutes = minutes_ % 60;
        let ampm = fullHours < 12 ? "AM" : "PM";
        let hours = fullHours % 12;

        if (hours === 0) {
            hours = 12;
        }

        return `${this._padLeft(hours.toString(), 2, '0')}:${this._padLeft(remainderMinutes.toString(), 2, '0')} ${ampm}`;
    }

    private _padLeft(text_: string, length_: number, padChar_: string): string {
        if (padChar_) {
            while (text_.length < length_) {
                text_ = padChar_ + text_;
            }
        }

        return text_;
    }

    private accessTokenExpired() {
        localStorage.clear();
        window.location.href = '/';
    }

    private setRandomRevenue() {
        let revenue: number = Math.floor(Math.random() * 100) + 50;
        return revenue;
    }
}

interface IParkingSpotReservationProps {
    propertyID: string
}

interface IParkingSpotReservationState {
    allParkingReservation: IDashbaordSpotReservation[];
    searchTerm: string;
    isFiltered: boolean;
    isDataLoaded: boolean;
}