import React from "react";
import {
    Button,
    Checkbox,
    Grid,
    IconButton,
    Paper,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel
} from "@material-ui/core";
import DataGridFilterTags from "./DataGridFilterTags";
import Pagination from "@material-ui/lab/Pagination";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ArrayHelper from "../../Helpers/ArrayHelper";
import {IAppliedFilter, IDataGridColumn, IDataRow, IGroupByData, IPopoverItemData} from "./Interfaces";
import './DataGrid.css';
import ProgressTop from "../ProgressTop";

export default class DataGrid<D extends IDataRow, C>
  extends React.Component<IDataGridProps<D>, IDataGridState<D>> {

  private _pageSizes: number[] = this.props.customPageSize ? this.props.customPageSize : [10, 25, 50];

  constructor(props_: IDataGridProps<D>) {
    super(props_);

    let state: IDataGridState<D> = {
      selectedItems: [],
      data: props_.data,
      viewData: [],
      pageNumber: 1,
      pageSize: this.props.customPageSize ? this._pageSizes[0] : this._pageSizes[1],
      appliedFilter: props_.appliedFilter ? props_.appliedFilter : {},
      sortColumnKey: props_.defaultSortColumnKey,
      sortDirection: props_.defaultSortDirection ? props_.defaultSortDirection : "asc",
      popOverItem: null,
      popOverTargetElement: null
    }
    state.viewData = this._sortAndSliceItems(props_.data, state);
    this.state = state;
  }

  componentDidUpdate(oldProps_: IDataGridProps<D>) {
    if (this.props.clearSelectedItem) {
      this.props.clearSelectedItem(this._selectAllRows, this.state.selectedItems);
    }
    if (oldProps_.appliedFilter != this.props.appliedFilter) {
      this.setState({
        appliedFilter: this.props.appliedFilter ? this.props.appliedFilter : {},
        pageNumber: 1
      }, () => {
        if (oldProps_.data != this.props.data) {
          this._updateListViewData(this.props.data);
        }
      });
    } else if (oldProps_.data != this.props.data) {
      this._updateListViewData(this.props.data);
      // const { groupBy, data } = this.props;
      // if(groupBy && groupBy.length > 0 && data.length > 0){
      //     const groupItem = groupBy.find(x => x.defaultSelected);
      //     if(groupItem) {
      //         const selectedItems = data.filter(x => x[groupItem.key] === groupItem.filterKey);
      //         this.setState({
      //             selectedItems
      //         });
      //     }
      // }
    }
    if (oldProps_.selectedItems != this.props.selectedItems) {
      this.setState({
        selectedItems: this.props.selectedItems ? this.props.selectedItems : []
      });
    }
  }

  render() {
    return (
      <div className={'table-container-list data-grid-table-container-list'}>
        <ProgressTop
          loaderPercentage={this.props.loaderPercentage}
        />
        <DataGridFilterTags
          appliedFilter={this.state.appliedFilter}
          onRemoveFilter={updatedFilter_ => {
            if (this.props.onFilterTagRemoved) {
              this.props.onFilterTagRemoved(updatedFilter_);
            }
          }}
        />
        <Paper className="data-grid-paper-container">
          <TableContainer className="data-grid-table-container">
            <Table
              aria-labelledby={this.props.title}
              size={'medium'}
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead className={'th-list-thead'}>
                <TableRow>
                  {
                    !this.props.isRowsSelectableWithoutCheckBox && !this.props.isRowsNonSelectable ?
                      <TableCell padding="none" className={'th-list-head'}>
                        <Checkbox
                          indeterminate={this._hasAnySelected() && !this._hasAllSelected()}
                          checked={this._hasAllSelected()}
                          onChange={this._selectAllRows}
                          className="check-box-table pl-0"
                        />
                      </TableCell>
                      : null
                  }
                  {
                    this.props.columns.map(column_ => (
                      <TableCell
                        className={((column_.key).indexOf("spacer") == -1) ? "data-grid-th" : "data-grid-th sort-visibility"}
                        key={column_.key}
                        align={column_.textAlignment}
                        padding={'default'}
                        sortDirection={
                          this.state.sortColumnKey === column_.key ?
                            this.state.sortDirection
                            : undefined}
                        onClick={() => this._sortByColumn(column_)}
                      >
                        <TableSortLabel
                          active={this.state.sortColumnKey === column_.key}
                          direction={this.state.sortDirection}
                        >
                          {column_.name}
                        </TableSortLabel>
                      </TableCell>
                    ))
                  }
                  {
                    !this.props.isNoMoreLink ?
                      <TableCell padding="none" className={'th-list-head'}>
                        <IconButton aria-label="More" disabled={this.state.selectedItems.length === 0}
                                    onClick={(ev_) => {
                                      if (this.props.onMultiMoreClick) {
                                        this.props.onMultiMoreClick(this.state.selectedItems)
                                      } else if (this.props.multiPopoverItems && this.props.multiPopoverItems.length) {
                                        this.setState({
                                          popOverTargetElement: ev_.target as HTMLElement
                                        });
                                      }
                                    }}>
                          <MoreHorizIcon/>
                        </IconButton>
                        {
                          this.props.multiPopoverItems != undefined &&
                            <Popover
                                open={this.state.popOverItem == null && this.state.popOverTargetElement != null}
                                anchorEl={this.state.popOverTargetElement}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                                onClose={() => {
                                  this.setState({
                                    popOverTargetElement: null,
                                    popOverItem: null
                                  });
                                }}
                            >
                              {this._buildPopoverItems(this.props.multiPopoverItems, null)}
                            </Popover>
                        }
                      </TableCell>
                      : null
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  !this.props.groupBy && this._bindRows()
                }
                {
                  this.props.groupBy && [...this._bindGroupRows()]
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {
          !this.props.isNoPagination ?
            <Grid container>
              <Grid item>
                {
                  !this.props.isNoRowsPerPage &&
                    <TablePagination
                        rowsPerPageOptions={this._pageSizes}
                        component="div"
                        count={this.state.data.length}
                        rowsPerPage={this.state.pageSize}
                        page={this.state.pageNumber - 1}
                        onPageChange={(e_, pageNumber_) => {
                          this._changePage(pageNumber_)
                        }}
                        onChangeRowsPerPage={(e_) => this._changePageSize(parseInt(e_.target.value))}
                        className="table-lis-footer"
                        labelDisplayedRows={({from, to, count}) => `${from}-${to} of ${count} results`}
                    />
                }
              </Grid>
              <Grid item xs className="footer-pagenation">
                <Pagination
                  page={this.state.pageNumber}
                  className="Pagination"
                  showFirstButton
                  showLastButton
                  onChange={(e_, pageNumber_) => this._changePage(pageNumber_)}
                  count={Math.ceil(this.state.data.length / this.state.pageSize)}
                />
              </Grid>
            </Grid> : null
        }

      </div>
    );
  }

  private _bindGroupRows = () => {
    const {groupBy} = this.props;
    const {viewData} = this.state;
    let getTableRows: any[] = [];
    groupBy && groupBy.length > 0 && groupBy.map((groupData, index) => {
      let filterViewData = [...viewData.filter(x => x[groupData.key] === groupData.filterKey)];
      if (groupData.ignoreKey && groupData.ignoreFilterKey) {
        const ignoreKey = groupData.ignoreKey ?? "";
        filterViewData = [...filterViewData.filter(x => x[ignoreKey] !== groupData.ignoreFilterKey)];
      }
      if (groupData.key && filterViewData.length > 0) {
        if (groupData.label !== "") {
          groupData.label && getTableRows.push(<TableRow
            role="checkbox"
            tabIndex={-1}
            key={`${index}_Header`}
            className="group-header-ht"
          >
            <TableCell
              align={"left"}
              key={`${index}_Cell`}
              colSpan={this.props.columns.length}
              className="table-list-cell">
              {
                <span className="data-grid-table-cell group-header-span">{groupData.label}</span>
              }
            </TableCell>
          </TableRow>);
        }

        if (filterViewData.length > 0) {
          getTableRows.push(filterViewData
            .map((row_, index_) => {
              return this._bindTableRows(row_, index_, groupData.ignoreRowClickClass);
            }));
        }
      }
      return groupData;
    });
    return [...getTableRows];
    // const groupData = this.state.viewData
    //     .map((row_, index_) => {
    //         return this._bindTableRows(row_, index_);
    //     });
    // return groupData;
  }

  private _bindRows = () => {
    const groupData = this.state.viewData
      .map((row_, index_) => {
        return this._bindTableRows(row_, index_,);
      });
    return groupData;
  }

  private _bindTableRows = (row_: D, index_: number, ignoreRowClickClass?: string) => {
    const isItemSelected = this._isRowSelected(row_);
    const labelId = `enhanced-table-checkbox-${index_}`;
    let ignoreRowClass = ignoreRowClickClass ? ignoreRowClickClass : "";
    return (
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={index_}
        selected={isItemSelected}
        className={this.props.customClass ? `${this.props.customClass} ${ignoreRowClass}` : ` ${ignoreRowClass}`}
        onClick={() => {
          if (!ignoreRowClass) {
            if (this.props.onRowClick && this.state.popOverTargetElement == null) {
              this.props.onRowClick(row_);
            }
            if (this.props.isRowsSelectableWithoutCheckBox) {
              //const isSameItem = this.state.selectedItems.indexOf(row_);
              this.setState({
                selectedItems: []
              }, () => {
                //isSameItem === -1 &&
                this._selectRow(row_);
              });
            }
          }
        }}
      >
        {
          !this.props.isRowsSelectableWithoutCheckBox && !this.props.isRowsNonSelectable ?
            <TableCell padding="checkbox">
              <Checkbox
                disabled={this.props.disableCheckbox ? this.props.disableCheckbox(row_) : false}
                onClick={(ev_) => {
                  this._selectRow(row_);
                  ev_.stopPropagation();
                }}
                checked={isItemSelected}
                inputProps={{'aria-labelledby': labelId}}
                className="check-box-table"
              />
            </TableCell>
            : null
        }
        {
          this.props.columns.map((column_, i) => {
            return <TableCell
              align={column_.textAlignment}
              key={i}
              className="table-list-cell">
              {
                column_.contentProvider ?
                  column_.contentProvider(row_, column_.key)
                  : <span
                    className="data-grid-table-cell">{(row_[column_.key] ? row_[column_.key] : "").toString()}</span>
              }
            </TableCell>
          })
        }
        {
          !this.props.isNoMoreLink ?
            <TableCell padding="checkbox">
              <IconButton aria-label="More"
                // disabled={!isItemSelected}
                          disabled={this.props.isNoMoreLinkDisabled ? this.props.isNoMoreLinkDisabled(row_) : false}
                          onClick={(ev_: React.MouseEvent<HTMLButtonElement>) => {
                            if (this.props.onMoreClick) {
                              let popoverItems = this.props.onMoreClick(row_, ev_);
                              if (popoverItems && popoverItems.length) {
                                this.setState({
                                  tempPopoverItems: popoverItems,
                                  popOverTargetElement: ev_.target as HTMLElement,
                                  popOverItem: row_
                                });
                              }
                            } else if (this.props.popoverItems && this.props.popoverItems.length) {
                              this.setState({
                                popOverTargetElement: ev_.target as HTMLElement,
                                popOverItem: row_
                              });
                            }
                            ev_.stopPropagation();
                          }}>
                <MoreHorizIcon/>
              </IconButton>
              {
                (this.state.tempPopoverItems != undefined || this.props.popoverItems != undefined) &&
                  <Popover
                      open={this.state.popOverItem == row_ && this.state.popOverTargetElement != null}
                      anchorEl={this.state.popOverTargetElement}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      onClose={() => {
                        this.setState({
                          popOverTargetElement: null,
                          popOverItem: null
                        });
                      }}
                  >
                    {this._buildPopoverItems((this.state.tempPopoverItems ? this.state.tempPopoverItems : this.props.popoverItems) as IPopoverItemData[], row_)}
                  </Popover>
              }
            </TableCell>
            : null
        }

      </TableRow>
    );
  }

  private _sortAndSliceItems = (data_: D[], state_: IDataGridState<D>, autoPageChangeCallback_?: (pageNo_: number) => void) => {
    if (state_.sortDirection && state_.sortColumnKey) {
      let coloumn = ArrayHelper.findObject(this.props.columns, "key", state_.sortColumnKey);
      let comparator = coloumn?.getComparator ? coloumn?.getComparator(state_.sortDirection, state_.sortColumnKey) : ArrayHelper.getComparator(state_.sortDirection, state_.sortColumnKey);
      if (this.props.groupBy && this.props.groupBy.length > 0) {
        const sortedData: D[] = [];
        this.props.groupBy.map((x: IGroupByData) => {
          let sortData = data_.filter((data: D) => data[x.key] === x.filterKey);
          if (x.ignoreKey && x.ignoreFilterKey) {
            const ignoreKey = x.ignoreKey ?? "";
            sortData = sortData.filter((data: D) => data[ignoreKey] !== x.ignoreFilterKey);
          }
          sortData = ArrayHelper.stableSort(sortData, comparator);
          sortedData.push(...sortData);
          return x;
        });
        data_ = sortedData;
      } else {
        data_ = ArrayHelper.stableSort(data_, comparator);
      }
      if (coloumn?.alphaNumeric) {
        data_ = coloumn?.getComparators ? coloumn.getComparators(data_, state_.sortColumnKey, state_.sortDirection, this.props.columns, "key") : data_;
      }
    }

    if (!this.props.isNoPagination) {
      let viewStartRowIndex = 0;
      let pageNumber = state_.pageNumber;

      do {
        viewStartRowIndex = (pageNumber - 1) * state_.pageSize;
        if (data_.length && viewStartRowIndex >= data_.length && pageNumber > 0) {
          --pageNumber;
        } else {
          break;
        }
      }
      while (true);

      let activeViewData = data_.slice(viewStartRowIndex, viewStartRowIndex + state_.pageSize);

      if (autoPageChangeCallback_ && pageNumber != state_.pageNumber) {
        autoPageChangeCallback_(pageNumber);
      }

      return activeViewData;
    } else {
      return data_;
    }
  }

  private _hasAnySelected = (): boolean => {
    return this.state.selectedItems.length > 0;
  }

  private _hasAllSelected = (): boolean => {
    return this._hasAnySelected() && this.state.selectedItems.length === this.state.data.length;
  }

  private _isRowSelected = (row_: D): boolean => {
    if (!this.props.compareWithStringify) {
      return this.state.selectedItems.indexOf(row_) > -1;
    } else {
      return this.state.selectedItems.findIndex(x => JSON.stringify(x) === JSON.stringify(row_)) > -1;
    }
  }

  private _updateListViewData = (updatedData_?: D[]): void => {
    if (!updatedData_) {
      this.setState({
        viewData: this._sortAndSliceItems(this.state.data, this.state)
      });
    } else {
      let pageNumber = this.state.pageNumber;
      let viewData = this._sortAndSliceItems(updatedData_, this.state, (newPageNumber_) => pageNumber = newPageNumber_);

      this.setState({
        data: updatedData_,
        viewData: viewData,
        pageNumber: pageNumber
      });
    }
  }

  private _selectRow = (row_: D): void => {
    let selectedRows = this.state.selectedItems;
    let rowSelectionIndex = selectedRows.indexOf(row_);

    // If not selected already, then select
    if (rowSelectionIndex == -1) {
      selectedRows.push(row_);
    }
    // else, deselect
    else {
      selectedRows.splice(rowSelectionIndex, 1);
    }

    this.setState({
      selectedItems: selectedRows
    }, () => {
      if (this.props.onSelectionChange) {
        this.props.onSelectionChange(selectedRows);
      }
    });
  }

  private _selectAllRows = (): void => {
    let selectedItems: D[] = [];
    // If none are already selected, then select all
    if (!this._hasAnySelected()) {
      selectedItems = this.state.data.slice(0);
    }
    // else, deselect all
    else {
      selectedItems = [];
    }
    if (this.props.filterSelectItems) {
      selectedItems = this.props.filterSelectItems(selectedItems);
    }

    this.setState({
      selectedItems: selectedItems
    }, () => {
      if (this.props.onSelectionChange) {
        this.props.onSelectionChange(selectedItems);
      }
    });
  }

  private _sortByColumn = (column_: IDataGridColumn<D>): void => {
    const spaceColumn_ = (column_.key).indexOf("spacer") == -1;
    if (spaceColumn_) {
      this.setState({
        sortColumnKey: column_.key,
        sortDirection: this.state.sortColumnKey === column_.key ?
          (this.state.sortDirection === "asc" ? "desc" : "asc") // if already sorted by same column
          : "asc" // sorting new column
      }, this._updateListViewData);
    }
  }

  private _changePage = (pageNumber_: number): void => {
    this.setState({
      pageNumber: pageNumber_
    }, this._updateListViewData);
  }

  private _changePageSize = (pageSize_: number): void => {
    this.setState({
      pageNumber: 1,
      pageSize: pageSize_
    }, this._updateListViewData);
  }

  private _buildPopoverItems = (popoverData_: IPopoverItemData[], row_: D | null) => {
    return (
      <Paper style={{width: "100px"}}>
        {
          popoverData_.map((d, index) => {
            return <Button
              disabled={d.disabled}
              key={index}
              className={"w-100 multiPopoverButton " + (d.textStyle ? d.textStyle : "")}
              onClick={() => {
                this.setState({
                  popOverTargetElement: null,
                  popOverItem: null
                });
                if (row_ == null) {
                  if (this.props.onMultiPopoverClick) {
                    this.props.onMultiPopoverClick(d.key, this.state.selectedItems, this._selectAllRows);
                  }
                } else if (this.props.onPopoverClick) {
                  this.props.onPopoverClick(d.key, row_);
                }
              }}
            >{d.label == 'Active' || d.label == 'Inactive' ?
              <div className={'list-status dropdown-status ' + d.key}></div> : null} {d.label}</Button>
          })
        }
      </Paper>
    );
  }
}

interface IDataGridProps<D> {
  title: string;
  data: D[];
  columns: IDataGridColumn<D>[];
  appliedFilter?: IAppliedFilter;
  appliedSearch?: string;
  isRowsNonSelectable?: boolean;
  isNoMoreLink?: boolean;
  isNoPagination?: boolean;
  defaultSortColumnKey?: string;
  defaultSortDirection?: "asc" | "desc";
  selectedItems?: D[];
  popoverItems?: IPopoverItemData[];
  multiPopoverItems?: IPopoverItemData[];
  customPageSize?: number[];
  isNoRowsPerPage?: boolean;
  loaderPercentage?: number;
  isNoMoreLinkDisabled?: (item_: D) => boolean;
  onRowClick?: (data_: D) => void;
  onFilterTagRemoved?: (appliedFilter_: IAppliedFilter) => void;
  onMoreClick?: (data_: D, ev_: React.MouseEvent<HTMLButtonElement>) => IPopoverItemData[] | void;
  onMultiMoreClick?: (data_: D[]) => void;
  onSelectionChange?: (selected_: D[]) => void;
  onPopoverClick?: (key_: string, item_: D) => void;
  onMultiPopoverClick?: (key_: string, selectedItems_: D[], _selectAllRows?: () => void) => void;
  disableCheckbox?: (item_: D) => boolean;
  filterSelectItems?: (item_: D[]) => D[];
  clearSelectedItem?: (_selectAllRows: () => void, selectedItems: D[]) => void;
  groupBy?: IGroupByData[];
  isRowsSelectableWithoutCheckBox?: boolean;
  customClass?: string;
  compareWithStringify?: boolean;
}

interface IDataGridState<T> {
  data: T[];
  viewData: T[];
  selectedItems: T[];
  pageSize: number;
  pageNumber: number;
  appliedFilter: IAppliedFilter;
  sortColumnKey?: string;
  sortDirection?: "asc" | "desc";
  popOverItem: T | null;
  popOverTargetElement: HTMLElement | null;
  tempPopoverItems?: IPopoverItemData[];
}
