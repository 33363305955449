import { Button, Grid, IconButton, Paper, styled, Tooltip } from "@material-ui/core";
import Error from "@material-ui/icons//Error";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ClearIcon from '@material-ui/icons/Clear';
import React from "react";
import { CSVLink } from "react-csv";
import { FileDrop } from "react-file-drop";
import { ShowAlert } from "../../../Common/ShowAlert";
import ObjectHelper from "../../../Helpers/ObjectHelper";
import VisitorBookingService from "../../../Services/VisitorBookingService";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

export default class BulkUploadVisitors extends React.Component<IBulkUploadVisitorsProps, IBulkUploadVisitorsState> {
    private _visitorBookingService: VisitorBookingService;
    constructor(props_: IBulkUploadVisitorsProps) {
        super(props_);

        this.state = {
            isUploadComplete: false,
            isInValidColumnHeader: false,
            isInValidDataFormat: false,
            isOverwiteExistingUnit: false,
            isAddOverwiteExistingUnit: false,
            isOverwriteUploadComplete: false,
            existingUnitCount: 0,
            isFileUpload: true,
            fileInfo: undefined,
            fileName: "",
            fileContent: undefined,
            fileUrl: "",
            totalRecords: 0,
            remainingRecords: 0,
            uploadedRecords: 0,
            failedCSVRecords: []
        };
        this._visitorBookingService = new VisitorBookingService();

    }



    componentDidMount() {

    }


    onDone = (overwriteUser: string = "") => {
        const { fileInfo, fileName, fileContent } = this.state;
        const { propertyGuid } = this.props;
        if (fileInfo && fileContent) {
            const url = overwriteUser ? `spot/visitor-parking/bulk-upload-unit/${propertyGuid}/${overwriteUser}` : `spot/visitor-parking/bulk-upload-unit/${propertyGuid}`;
            let form = new FormData();
            form.append("visitorbulkupload", fileContent, fileName);
            this._visitorBookingService.bulkUnitUpdate(form, url)
                .then(response => {
                    if (response.ok) {
                        response.json().then(resp => {
                            const totalCount = resp?.total;
                            const uploadedCount = resp?.uploaded;
                            const userExistsCount = resp?.userExists;
                            const failedCSVRecords = ObjectHelper.toCSVArray(resp?.unitCSVRows ?? []);

                            if (userExistsCount === 0 && uploadedCount === 0) {
                                this.setState({
                                    isInValidDataFormat: true,
                                    isFileUpload: false,
                                    failedCSVRecords: failedCSVRecords
                                });
                            }
                            else if (userExistsCount !== 0) {
                                const remainingCount = totalCount - userExistsCount;
                                this.setState(
                                    {
                                        isOverwiteExistingUnit: true,
                                        isFileUpload: false,
                                        existingUnitCount: userExistsCount,
                                        totalRecords: totalCount,
                                        remainingRecords: remainingCount,
                                        failedCSVRecords: failedCSVRecords
                                    });
                            }
                            else {
                                this.setState(
                                    {
                                        isUploadComplete: overwriteUser === "",
                                        isOverwriteUploadComplete: overwriteUser !== "",
                                        isFileUpload: false,
                                        totalRecords: totalCount,
                                        uploadedRecords: uploadedCount,
                                        isOverwiteExistingUnit: false,
                                        isAddOverwiteExistingUnit: false,
                                        failedCSVRecords: failedCSVRecords
                                    });
                            }
                        })
                    }
                    else {
                        response.status === 400 && response.text().then(resp => {
                            if (resp === "Incorrect column header") {
                                this.setState(
                                    {
                                        isInValidColumnHeader: true,
                                        isInValidDataFormat: false,
                                        isFileUpload: false,
                                        failedCSVRecords: []
                                    });
                            }
                            else if (resp === "File is empty") {
                                ShowAlert("", resp, "error");
                            }
                            else {
                                ShowAlert("", resp, "error");
                            }
                        })
                    }
                });
        }
    }

    onInValidHeaderOrDataFormatClose = () => {
        this.setState(
            {
                isInValidDataFormat: false,
                isFileUpload: true,
                fileName: "",
                fileInfo: undefined,
                fileUrl: "",
                fileContent: undefined,
                isInValidColumnHeader: false,
            });
    }


    onSuccessClose = () => {
        this.setState(
            {
                isInValidDataFormat: false,
                isFileUpload: false,
                fileName: "",
                fileInfo: undefined,
                fileUrl: "",
                fileContent: undefined,
                isInValidColumnHeader: false,
                isOverwriteUploadComplete: false,
                remainingRecords: 0,
                totalRecords: 0,
                uploadedRecords: 0,
                isUploadComplete: false,
                isAddOverwiteExistingUnit: false
            });
        this.props.onModelClose(false);
    }

    removeFile = () => {
        let input = document.getElementById('file-upload') as any;
        
        if (input)
            input.value = null;

        this.setState(
            {
                isFileUpload: true,
                fileName: "",
                fileInfo: undefined,
                fileUrl: "",
                fileContent: undefined
            });
    }

    render() {
        return (
            <>
                <div className="d-flex flex-column">
                    {this.state.isFileUpload && (
                        <>
                            <div className="d-flex flex-column align-items-center" style={{ width: 450 }} >
                                <div className="bulk-upload-header mt-3">
                                    Bulk Upload Units
                                </div>
                                <div className="mt-3 bulk-upload-sub-content">
                                    <a className="link-color" href="/Units.csv" download="Units.csv"> Download this template</a> to ensure accurate upload.
                                </div>
                                <div className="bulk-upload-file-container mt-3">
                                    <FileDrop
                                        className="file-dropbox flex-grow-1"
                                        onDrop={(files: FileList) => this._onFilesUpload(files)}
                                    >
                                        <div className={`dropbox-content text-center file-picker-container ${!this.state.fileInfo ? "container-padding" : ""}`}>
                                            {this.state.fileInfo &&
                                                <div>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <Paper>
                                                                <Item>{this.state.fileName}{
                                                                    <Tooltip title="Remove">
                                                                        <IconButton type="submit" onClick={this.removeFile} className="removeButton" aria-label="Remove">
                                                                            <ClearIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                }</Item>
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                </div>}
                                            {!this.state.fileInfo && <>Drag and drop CSV files here or <span>upload</span>
                                                <label className="full-size" htmlFor="file-upload"></label></>}
                                            <br />
                                            <input
                                                id="file-upload"
                                                className="hidden"
                                                type="file"
                                                accept=".csv"
                                                multiple={false}
                                                onChange={event => {
                                                    let inputControl = event.target as HTMLInputElement;
                                                    if (inputControl) {
                                                        this._onFilesUpload(inputControl.files);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </FileDrop>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center mt-4">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className="ml-2"
                                    style={{ width: 131 }}
                                    onClick={() => this.onDone()}
                                >
                                    Done
                                </Button>
                                <Button

                                    variant="contained"
                                    color="secondary"
                                    className="ml-2"
                                    style={{ width: 131 }}
                                    onClick={this.onSuccessClose}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </>
                    )}
                    {this.state.isOverwiteExistingUnit && (
                        <>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div>
                                    <Error className="error-icon-size" />
                                </div>
                                <div className="bulk-upload-header mt-3">
                                    Overwrite Existing Units?
                                </div>
                                <div className="mt-3 bulk-upload-sub-content">
                                    There are <b>{this.state.existingUnitCount}</b> units that are already existing
                                </div>
                                <div className="bulk-upload-sub-content">
                                    units within the database. Did you want to
                                </div>
                                <div className="bulk-upload-sub-content">
                                    overwrite their existing profile?
                                </div>

                            </div>
                            <div className="d-flex align-items-center mt-4">
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="ml-2"
                                    onClick={() => {
                                        this.onDone("yes");
                                    }}
                                >
                                    Yes
                                </Button>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    className="ml-2"
                                    onClick={() => {
                                        if (this.state.remainingRecords === 0) {
                                            this.onSuccessClose();
                                        }
                                        else {
                                            this.setState({
                                                isAddOverwiteExistingUnit: true,
                                                isOverwiteExistingUnit: false
                                            });
                                        }
                                    }}
                                >
                                    No
                                </Button>
                            </div>
                        </>
                    )}
                    {this.state.isAddOverwiteExistingUnit && (
                        <>

                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div>
                                    <Error className="error-icon-size" />
                                </div>
                                <div className="bulk-upload-header mt-3">
                                    Add Remaining Units?
                                </div>
                                <div className="mt-3 bulk-upload-sub-content">
                                    Do you want to proceed with adding <b>{this.state.remainingRecords} out of the</b>
                                </div>
                                <div className="bulk-upload-sub-content">
                                    <b>{this.state.totalRecords}</b> remaining units that do not need to be replaced?
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-4">
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="ml-2"
                                    onClick={() => {
                                        if (this.state.remainingRecords === 0) {
                                            ShowAlert("", "No data to upload", "warning");
                                        }
                                        else {
                                            this.onDone("no");
                                        }
                                    }}
                                >
                                    Yes
                                </Button>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    className="ml-2"
                                    onClick={() => {
                                        if (this.state.remainingRecords === 0) {
                                            this.onSuccessClose();
                                        }
                                        else {
                                            this.setState({
                                                isAddOverwiteExistingUnit: false,
                                                isFileUpload: true
                                            });
                                        }
                                    }}
                                >
                                    No
                                </Button>
                            </div>
                        </>
                    )}
                    {(this.state.isUploadComplete || this.state.isOverwriteUploadComplete) && (
                        <>

                            <div className={`d-flex flex-column justify-content-center align-items-center ${this.state.isOverwriteUploadComplete? "" :"padding-right-left-50-px"}`}>
                                <div>
                                    <CheckCircle className="check-icon-size" />
                                </div>
                                <div className="bulk-upload-header mt-3">
                                    Upload Complete
                                </div>
                                <div className="mt-3 bulk-upload-sub-content">
                                    {this.state.isOverwriteUploadComplete ? "Bulk upload was successfully completed." : "Bulk upload was successful."}
                                </div>
                                {this.state.isOverwriteUploadComplete && (
                                    <div className="bulk-upload-sub-content">
                                        <b>{this.state.uploadedRecords}/{this.state.totalRecords}</b> units added.
                                    </div>
                                )}
                                {(this.state.failedCSVRecords.length > 0) &&
                                    <div className="bulk-upload-sub-content">
                                        Click <CSVLink data={this.state.failedCSVRecords} filename={"units-not-uploaded.csv"}>here</CSVLink> to download the list of units that were not uploaded.
                                    </div>
                                }
                            </div>
                            <div className="d-flex align-items-center justify-content-center mt-4">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className="ml-2 bul-upload-primary-btn"
                                    onClick={() => 
                                    {
                                        this.onSuccessClose();
                                        this.props.getListUpdated && this.props.getListUpdated();
                                    }}
                                >
                                    Ok
                                </Button>
                            </div>
                        </>
                    )}
                    {(this.state.isInValidColumnHeader || this.state.isInValidDataFormat) && (
                        <>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div>
                                    <Error className="error-icon-size" />
                                </div>
                                <div className="bulk-upload-header mt-3">
                                    {this.state.isInValidColumnHeader ? "Column Header Incorrect" : "Data Format Incorrect"}
                                </div>
                                <div className="mt-3 bulk-upload-sub-content">
                                    {this.state.isInValidColumnHeader ?
                                        "One or more column headings do not match the" :
                                        "The unit data provided is in the"}
                                </div>
                                <div className="mt-3 bulk-upload-sub-content">
                                    {this.state.isInValidColumnHeader ?
                                        "upload criteria. Please fix this and upload the CSV" :
                                        "incorrect format. Please fix this and upload the "}
                                </div>
                                <div className="mt-3 bulk-upload-sub-content">
                                    {this.state.isInValidColumnHeader ?
                                        "file again." :
                                        "CSV file again."}
                                </div>
                                <div className="mt-3 bulk-upload-sub-content">
                                    Click <CSVLink data={this.state.failedCSVRecords} filename={"units-not-uploaded.csv"}>here</CSVLink> to download the list of units that were not uploaded.
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center mt-4">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className="ml-2 bul-upload-primary-btn"
                                    onClick={this.onInValidHeaderOrDataFormatClose}
                                >
                                    Close
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </>
        )
    }

    private _onFilesUpload = (files_: FileList | null) => {
        if (files_ && files_.length) {
            const file = files_[0];
            this.setState({ fileName: file.name, fileInfo: file, fileUrl: URL.createObjectURL(file), fileContent: file });
        }
    }
}

interface IBulkUploadVisitorsProps {
    propertyGuid?: string
    onModelClose: (isBulkUpload: boolean) => void;
    getListUpdated?: () => void;
    isUnitViewDetails?: boolean;
}

interface IBulkUploadVisitorsState {
    isUploadComplete: boolean;
    isInValidColumnHeader: boolean;
    isInValidDataFormat: boolean;
    isOverwiteExistingUnit: boolean;
    isAddOverwiteExistingUnit: boolean;
    existingUnitCount: number;
    isOverwriteUploadComplete: boolean;
    isFileUpload: boolean;
    storedFileGuid?: string;
    fileUrl?: string;
    fileName?: string;
    fileContent?: Blob;
    fileInfo?: File;
    totalRecords: number;
    remainingRecords: number;
    uploadedRecords: number;
    failedCSVRecords: string[][];
}