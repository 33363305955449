import React from 'react';
import {IDataGridColumn} from '../../Components/DataGrid/Interfaces';
import {
  IEnforcementAddress,
  IEnforcementDetailsItem,
  IEnforcementNotification,
  IScanHistory,
  IScanHistoryDetail,
  IScanHistoryDetailsItem,
  IVehicleDetail
} from './interface';
import DataGrid from '../../Components/DataGrid/DataGrid';
import {Badge, Button, Drawer, Grid, Snackbar, styled, Tab, Tabs} from '@material-ui/core';
import {NotificationsOutlined} from '@material-ui/icons';
import EnforcementAddress from './EnforcementAddress'
import {RightPanelState} from '../../Models/CommonInterfaces';
import EnforcementDetails from './EnforcementDetails';
import CloseIcon from '@material-ui/icons/Close';
import ScanHistory from './ScanHistory';
import './Enforcement.css';
import {ShowAlertwithConfirm} from '../../Common/ShowAlert';
import {CheckScreenPermission} from '../../Common/CheckScreenPermission';
import EnforcementService from '../../Services/EnforcementService';
import {GoogleMap, IGoogleMapLocation} from '../../Components/GoogleMap/GoogleMap';
import {IPropertyBasics} from '../Spots/Interfaces';
import {format} from 'date-fns';
import {DateTimeHelper} from '../../Helpers/DateTimeHelper';
import {IUser} from '../Users/Interfaces';
import UserService from '../../Services/UserService';
import MapIcon from '@material-ui/icons/Map';
import ListIcon from '@material-ui/icons/List';
import {TabContext, TabPanel} from "@material-ui/lab";
import DataGridToolbar from '../../Components/DataGrid/DataGridToolbar';
import { PreserveFilters } from '../../Common/PreserveFilters';

class EnforcementList extends React.Component<IEnforcementProps, IEnforcementState> {
  private _enforcementService: EnforcementService;
  private _userService: UserService;
  private _searchInput: HTMLElement | null;

  private _enforcementListColumns: IDataGridColumn<IEnforcementDetailsItem>[] = [
    {
      key: "propertyName",
      name: "PROPERTY",
    },
    {
      key: "city",
      name: "CITY",
    },
    {
      key: "sessionEndDateTime",
      name: "SCAN DATE",
      contentProvider: (row_: IEnforcementDetailsItem) => {
        let date = DateTimeHelper.dateStringToDate(row_.sessionEndDateTime);

        return (<span><span
          className="no-wrap-text">{format(date, "MMMM dd, yyyy")} at {format(date, "hh:mm aa")}</span></span>);
      }
    },
    {
      key: "enforcementUser",
      name: "PERFORMED BY",
      contentProvider: (row_: IEnforcementDetailsItem) => {
        const fullName = this.state.users.filter((user) => user.applicationUserGuid === row_.userGuid);
        return (<span>{fullName[0]?.name}</span>);
      }
    },
    {
      key: "reportCount",
      name: "Reports",
      contentProvider: (row_: IEnforcementDetailsItem) => <span>{row_.scanHistory?.length || 0}</span>
    },
  ];

  private _unauthorizedParkersColumns: IDataGridColumn<IScanHistoryDetailsItem>[] = [
    {
      key: "licensePlate",
      name: "LICENSE PLATE",
      contentProvider: (row_) => (<span>{row_.vehicleDetails[0].licensePlate}</span>)
    },
    {
      key: "carMake",
      name: "MAKE",
      contentProvider: (row_) => (<span>{row_.vehicleDetails[0].make}</span>)
    },
    {
      key: "carModel",
      name: "MODEL",
      contentProvider: (row_) => (<span>{row_.vehicleDetails[0].model}</span>)
    },
    {
      key: "carColour",
      name: "COLOUR",
      contentProvider: (row_) => (<span>{row_.vehicleDetails[0].colour}</span>)
    },
    {
      key: "lastReport",
      name: "LAST REPORT",
      contentProvider: (row_) => (<span>{row_.date}</span>)
    },
    {
      key: "violationsCount",
      name: "VIOLATIONS",
      contentProvider: (row_) => (<span>{row_.noOfOffences}</span>)
    },
  ];

  constructor(props: any) {
    super(props);
    this.state = {
      searchTerm: "",
      isWidgetRefreshed: true,
      isScreenAccessible: false,
      isMultiSelectedProperty: true,
      scanHistory: [],
      scanHistoryTotal: [],
      allViewEnforcementList: [],
      filteredViewEnforcementList: [],
      subFilteredViewEnforcementList: [],
      selectedViewEnforcementItem: undefined,
      EnforcementAddressDetails: [],
      selectedAddressDetails: [],
      scanHistoryDetail: [],
      scanVehicleModel: null,
      isFocused: false,
      isNotificationEnabled: false,
      rightPanelState: RightPanelState.None,
      hasUnsavedChanges: false,
      properties: [],
      selectedProperty: null,
      isMap: false,
      currTab: '1',
      multipleSelectedLocation: null,
      enforcementNotifications: [],
      users: [],
      licensePlateSearchTerm: "",
    }
    this._enforcementService = new EnforcementService();
    this._userService = new UserService();
    this._searchInput = null;
  }

  async componentDidMount() {
    var isAccessible = await CheckScreenPermission("user-interface-enforcements");

    this.setState({
      isScreenAccessible: isAccessible
    }, () => {
      if (this.state.isScreenAccessible) {
        this.loadEnforcementSearch();
        this.loadOffenses();
        this.loadUsers();
        this.loadScanHistoryMulti();
      }
    });

    window.addEventListener('resize', this.handleResize);
  }

  clearSearch = () => {
    this.setState({isFocused: true}, () => {
      if (this._searchInput)
        this._searchInput.focus();

      this._searchTermChange('');
    });
  }

  actionButtons = () => {
    return (<Grid item
                  style={{flexGrow: 1}}
    >
      <Grid container
            alignItems={'center'}
            justify={'flex-end'}
      >
        <Grid item
              className={'px-2'}
        >
          <Button
            startIcon={this.state.isMap ? <ListIcon/> : <MapIcon/>}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={`c-go-button fs-12 enforcement-active`}
            onClick={() => {
              this.setState({
                isMap: !this.state.isMap,
                currTab: !this.state.isMap ? '1' : this.state.currTab
              });
            }}
            style={{height: 20}}
            disabled={this.state.selectedAddressDetails.length === 0}
          >
            {this.state.isMap ? 'List View' : 'Map View'}
          </Button>
        </Grid>
        {/*<Grid item className={'px-2'}>
          <IconButton className='add-lit-button'
                      onClick={_event => {
                        this.setState({
                          //selectedReport: report_,
                          rightPanelState: RightPanelState.ReportParker,
                          hasUnsavedChanges: false
                        })
                      }}>
            <AddIcon fontSize="small"/>
          </IconButton>
        </Grid>*/}
        <Grid item>
          {this.notificationButton()}
        </Grid>
      </Grid>
    </Grid>);
  }

  notificationButton = () => {
    const {enforcementNotifications} = this.state;
    return (<Grid item>
      <div className={(this.state.isNotificationEnabled ? "notify-active" : "") + " btn-notification"}
           onClick={() => this.setState({isNotificationEnabled: !this.state.isNotificationEnabled})}>
        {
          <Badge color="primary" overlap="circle" variant="dot"
                 invisible={!(enforcementNotifications && enforcementNotifications.length > 0)}>
            <NotificationsOutlined/>
          </Badge>
        }
      </div>
    </Grid>);
  }

  render() {
    if (!this.state.isScreenAccessible) return null;
    const {selectedAddressDetails, enforcementNotifications} = this.state;
    const notificationOffence = enforcementNotifications.filter(x => x.notificationFlag === "high offence");
    const notificationTraffic = enforcementNotifications.filter(x => x.notificationFlag === "high traffic");
    const notificationLastScan = enforcementNotifications.filter(x => x.notificationFlag === "last scan");

    return (
      <React.Fragment>
        <div className="data-grid-container enforcement-page without-checkbox-container">
          <Grid container className="mb-6 common-header">
            <Grid container className={'common-lf-header-container'}>
              <>
                <EnforcementAddress
                  enforcementAddressDetails={this.state.EnforcementAddressDetails}
                  onAddressChange={(property_: IEnforcementAddress[]) => {
                    const propertyList = property_.map((p_: any) => {
                      p_.propertyDetailedName = `${p_.propertyName} (${p_.propertyAddressLine1})`;
                      p_.isOffencesArea = p_.isHighParkingOffencesArea as boolean;
                      p_.isTrafficArea = p_.isHighTrafficArea as boolean;
                      (p_ as unknown as IGoogleMapLocation).lat = p_.propertyLatitiude as number;
                      (p_ as unknown as IGoogleMapLocation).lng = p_.propertyLongitude as number;
                      (p_ as unknown as IGoogleMapLocation).text = p_.propertyName;
                      return p_;
                    }) as IPropertyBasics[];
                    const isAllProperty = propertyList.filter(property => property.propertyGuid === "blank");
                    const allProperties_ = propertyList.filter(property => property.propertyGuid !== "blank");
                    this.setState({
                      selectedProperty: allProperties_.length > 1 ? null : property_[0],
                      selectedAddressDetails: property_,
                      isMultiSelectedProperty: allProperties_.length > 1,
                      multipleSelectedLocation: isAllProperty.length > 0 ? null : propertyList
                    }, () => {
                      if (isAllProperty.length > 0 || allProperties_.length > 1) {
                        this.loadScanHistoryMulti(allProperties_.length > 1 ? allProperties_.map(property => property.propertyGuid) : undefined);
                      }
                      this.filterEnforcement();
                      this.loadOffenses();
                    })
                  }}
                  selectedAddressDetails={this.state.selectedAddressDetails}
                  id="top-property"
                  allEnforcementNotRequired={false}
                />
                {!this.state.isMap && this.actionButtons()}
              </>
            </Grid>
            {this.state.isMap && <Grid container className='enforcement-rt-header-container' style={{paddingTop: 12}}>
              {this.actionButtons()}
            </Grid>}
            {this.state.isMultiSelectedProperty && this.state.isMap && (
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={this.state.isMultiSelectedProperty}
                message={this.multiSelectedPropertyMsg()}
                className="notify-toaster-pl"
              />
            )}
          </Grid>
          <div className='enforcement-body'>
            <Grid container direction={'column'} className={'enforcement-body'}>
              <TabContext
                value={this.state.currTab}
              >
                <StyledTabs
                  value={this.state.currTab}
                  onChange={(event, newValue) => {
                    const isUnauthorizedParker = newValue === '2';
                    this.setState({
                      currTab: newValue,
                      isMap: isUnauthorizedParker ? false : this.state.isMap,
                    });
                  }}>
                  <StyledTab label={'ENFORCEMENT SCANS'} value={'1'}/>
                  <StyledTab label={'UNAUTHORIZED PARKERS'} value={'2'}/>
                </StyledTabs>
                <TabPanel value={'1'} style={{display: 'flex', flexGrow: 1}}>
                  {this.state.isMap ?
                    (<>
                      <div className='common-parent-container px-4'>
                        {(this.state.properties.length !== 0) &&
                            <GoogleMap
                                address={""}
                                locations={this.state.properties as any}
                                selectedLocation={this.state.selectedProperty as any}
                                multipleSelectedLocation={this.state.multipleSelectedLocation as any}
                                onPropertySelect={(selectedProperty_) => {
                                  const selectedProperty = [selectedProperty_];
                                  const selectedAddress: IEnforcementAddress[] = [selectedProperty_];
                                  this.setState({
                                    selectedAddressDetails: selectedAddress,
                                    isMultiSelectedProperty: selectedAddress.length > 1,
                                    multipleSelectedLocation: selectedProperty
                                  }, () => {
                                    this.filterEnforcement();
                                  });
                                }}
                                isMultipleSelect={true}
                            />}
                      </div>
                      <div className="enforcement-parent-right">
                        {!this.state.isNotificationEnabled && <EnforcementDetails
                            propertyGuid={selectedAddressDetails?.length === 1 ? selectedAddressDetails[0].propertyGuid : "00000000-0000-0000-0000-000000000000"}
                            onRefreshed={(s_: boolean) => {
                              this.setState({
                                isWidgetRefreshed: s_
                              })
                            }}
                            refresh={!this.state.isWidgetRefreshed}
                            isMultiSelectedProperty={this.state.isMultiSelectedProperty}
                            showScanHistoryDetails={this.loadScanHistory}
                        />
                        }
                        {this.state.isNotificationEnabled &&
                          (<div className="notification-container">
                            <div className='d-flex flex-column pt-4 px-4'>
                              <div className='d-flex justify-content-between'>
                                <div className='notify-header'>
                                  Notifications
                                </div>
                                <div className='text-right'>
                                  <CloseIcon className='close-btn'
                                             onClick={() => this.setState({isNotificationEnabled: !this.state.isNotificationEnabled})}/>
                                </div>
                              </div>
                            </div>
                            <div className='d-flex flex-column pt-3 common-notification-body'>
                              <div className='secure-notification-sub-body'>
                                {
                                  notificationLastScan.map((n_, i_) => {
                                    return (<div
                                      className={`notification-border mx-4 ${i_ === (notificationLastScan.length - 1) ? "notification-no-border" : ""}`}
                                      key={i_}>
                                      {i_ === 0 && <div className='notify-header'>
                                          # of days since last scan
                                      </div>
                                      }
                                      <li className='pt-2 pl-px-17'>
                                        {format(n_.loadDate, "MM/dd/yyyy")} at {format(n_.loadDate, "h:mm aa")}
                                      </li>
                                      <div className='pl-px-17' dangerouslySetInnerHTML={{__html: n_.message}}/>
                                      <div className='pt-1 pb-2'></div>
                                    </div>)
                                  })
                                }
                                {
                                  notificationTraffic.map((n_, i_) => {
                                    return (<div
                                      className={`notification-border mx-4 ${i_ === (notificationTraffic.length - 1) ? "notification-no-border" : ""}`}
                                      key={i_}>
                                      {i_ === 0 && <div className='notify-header'>
                                          high traffic area
                                      </div>
                                      }
                                      <li className='pt-2 pl-px-17'>
                                        {format(n_.loadDate, "MM/dd/yyyy")} at {format(n_.loadDate, "h:mm aa")}
                                      </li>
                                      <div className='pl-px-17' dangerouslySetInnerHTML={{__html: n_.message}}/>
                                      <div className='pt-1 pb-2'></div>
                                    </div>)
                                  })
                                }
                                {
                                  notificationOffence.map((n_, i_) => {
                                    return (<div
                                      className={`notification-border mx-4 ${i_ === (notificationOffence.length - 1) ? "notification-no-border" : ""}`}
                                      key={i_}>
                                      {i_ === 0 && <div className='notify-header'>
                                          high offence
                                      </div>
                                      }
                                      <li className='pt-2 pl-px-17'>
                                        {format(n_.loadDate, "MM/dd/yyyy")} at {format(n_.loadDate, "h:mm aa")}
                                      </li>
                                      <div className='pl-px-17' dangerouslySetInnerHTML={{__html: n_.message}}/>
                                      <div className='pt-1 pb-2'></div>
                                    </div>)
                                  })
                                }
                              </div>
                            </div>
                          </div>)}
                      </div>
                    </>) :
                    (<div className='enforcement-parent-container px-2'>
                      <DataGrid
                        key="1"
                        title="Enforcement Scans"
                        data={this.state.subFilteredViewEnforcementList}
                        columns={this._enforcementListColumns}
                        onRowClick={(enforcement: IEnforcementDetailsItem) => {
                          this.loadScanHistory(enforcement.propertyGuid, RightPanelState.EnforcementScanDetails, enforcement.sessionEndUtcDateTime);
                        }}
                        defaultSortColumnKey={'sessionEndDateTime'}
                        defaultSortDirection={'desc'}
                        isNoMoreLink
                        isRowsNonSelectable
                      />
                    </div>)}
                </TabPanel>
                <TabPanel value={'2'} style={{display: 'flex', flexGrow: 1, flexDirection: 'column'}}>
                  <Grid className='pb-2'>
                    <DataGridToolbar
                            search={PreserveFilters.getSearchTerm('plateSearch')}
                            singularEntityName="license plate"
                            pluralEntityName="license plates"
                            onSearch={this._searchLicensePlate}
                            isFiltered={false}
                            onFilterClick={() => {
                                this.setState({
                                    rightPanelState: RightPanelState.Filter
                                });
                            }}
                            onAddClick={() => {
                                this.setState({
                                    rightPanelState: RightPanelState.Add
                                });
                            }}
                            // isNoAddButton={!(GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) > -1 || GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) > -1)}
                            isNoAddButton={true}
                            isNoFilterButton={true}
                        />
                  </Grid>
                  <div className='enforcement-parent-container px-2'>
                    <DataGrid
                      key="2"
                      title="Unauthorized Parkers"
                      data={this.state.scanHistory}
                      columns={this._unauthorizedParkersColumns}
                      onRowClick={(offense) => this.loadScanHistoryDetail(offense.vehicleDetails[0].licensePlate, offense.reportedDateTime.toString(), offense.propertyGuid)}
                      defaultSortColumnKey={'lastReport'}
                      defaultSortDirection={'desc'}
                      isNoMoreLink
                      isRowsNonSelectable
                    />
                  </div>
                </TabPanel>
              </TabContext>
            </Grid>
          </div>
          <Drawer
            anchor="right"
            variant={this.state.rightPanelState === RightPanelState.None ? "persistent" : "temporary"}
            open={this.state.rightPanelState !== RightPanelState.None}
            className={`data-grid-right-panel`}
            onClose={this.onCloseDrawerPanel}
          >
            {this._getRightPanelContent()}
          </Drawer>
        </div>
      </React.Fragment>
    )
  }

  multiSelectedPropertyMsg = () => {
    return (

      <div className='d-flex flex-column'>
        <div className='py-3'>
          <b>Not available on multi-select.</b>
        </div>
        <div className=''>
          To view statistics and lot overview,
        </div>
        <div className=''>
          please select only <b>one</b> location.
        </div>
      </div>

    )
  }

  protected _getRightPanelTitle() {
    switch (this.state.rightPanelState) {
      case RightPanelState.Filter:
        return <span>Filter</span>;
      case RightPanelState.Edit:
        return <span>Edit Tenant</span>;
      case RightPanelState.Add:
        return <span>Add Tenant</span>;
      default:
        return "";
    }
  }

  private loadUsers() {
    this._userService.getAllUsers()
      .then(r => {
        if (r.ok) {
          r.json().then(response => {
            this.setState({
              users: response.slice(0),
            });
          });
        }
      });
  }

  private loadScanHistory = (propertyGuid: string, rightPanelState: RightPanelState | undefined = undefined, filterDate: string | undefined = undefined) => {
    this._enforcementService.GetScanHistory(propertyGuid)
      .then(r => {
        if (r.ok) {
          r.json().then(response => {
            const scanHistory: IScanHistory[] = response;
            let scanHistoryDetailsFiltered: IScanHistoryDetail | undefined;
            let selectedEnforcement: IEnforcementDetailsItem | undefined;
            let scanHistoryDetails = scanHistory.length === 0 ? [] : scanHistory.reduce((r: IScanHistoryDetail[], a) => {
              r = r.length > 0 ? r : [];
              let existingObj = r.find((m: any) => m.date === a.formatDate);
              r = r.filter((m: any) => m.date !== a.formatDate);
              if (existingObj) {
                const vehicleDetail: IVehicleDetail = {
                  licensePlate: a.licensePlate,
                  make: a.make,
                  model: a.model,
                  colour: a.colour,
                  reportedDateTime: new Date(a.reportedDateTime)
                };
                const checkVehicleExist = existingObj.vehicleDetails?.filter(vehicle => JSON.stringify(vehicle) === JSON.stringify(vehicleDetail));
                if (!checkVehicleExist || (checkVehicleExist && checkVehicleExist.length === 0))
                  existingObj.vehicleDetails.push(vehicleDetail);
                existingObj.noOfOffences = existingObj.vehicleDetails.length;
              } else {
                existingObj = {
                  date: a.formatDate,
                  noOfOffences: 0,
                  vehicleDetails: [],
                  reportedDateTime: new Date(a.reportedDateTime),
                  reasonName: a.reasonName,
                  enforcementUser: a.enforcementUser,
                  propertyGuid: propertyGuid
                }
                const vehicleDetail: IVehicleDetail = {
                  licensePlate: a.licensePlate,
                  make: a.make,
                  model: a.model,
                  colour: a.colour,
                  reportedDateTime: new Date(a.reportedDateTime)
                };
                existingObj.vehicleDetails.push(vehicleDetail);
                existingObj.noOfOffences = existingObj.vehicleDetails.length;
              }
              r.push(existingObj);
              return r;
            }, Object.create(null));
            if (filterDate) {
              const searchDate = new Date(filterDate);

              //searching for the exact enforcement data from the enforcement scans row click
              selectedEnforcement = this.state.subFilteredViewEnforcementList.find(enforcement =>
                (filterDate === enforcement.sessionEndUtcDateTime)
              );

              //if no match, the search is being made by the first right panel screen which
              //does not provide a time so the search looks for the first enforcement on the day
              //this is due the lack of interaction UX definition TODO: revisit this flow
              if (!selectedEnforcement) {
                selectedEnforcement = this.state.subFilteredViewEnforcementList.find(enforcement => {
                    const enforcementDate = new Date(enforcement.sessionEndUtcDateTime);
                    return searchDate.toDateString() === enforcementDate.toDateString();
                  }
                )
              }

              scanHistoryDetailsFiltered = scanHistoryDetails.find(scan => {
                const enforcementDay = selectedEnforcement ? new Date(selectedEnforcement?.sessionEndUtcDateTime).toDateString() : "";

                return enforcementDay === scan.reportedDateTime.toDateString();
              });
            }
            this.setState({
              rightPanelState: rightPanelState ? rightPanelState : this.state.rightPanelState,
              scanHistory: scanHistoryDetails,
              scanHistoryTotal: scanHistoryDetails,
              scanHistoryFiltered: scanHistoryDetailsFiltered,
              selectedViewEnforcementItem: selectedEnforcement
            });
          });
        }
      });
  }

  private loadScanHistoryMulti = (properties?: string[]) => {
    this._enforcementService.GetScanHistories()
      .then(r => {
        if (r.ok) {
          r.json().then(response => {
            const scanHistory: IScanHistory[] = response;
            let scanHistoryDetailsFiltered: IScanHistoryDetail | undefined;
            let selectedEnforcement: IEnforcementDetailsItem | undefined;
            let scanHistoryDetails = scanHistory.length === 0 ? [] : scanHistory.reduce((r: IScanHistoryDetail[], a) => {
              r = r.length > 0 ? r : [];
              let existingObj = r.find((m: any) => m.date === a.formatDate);
              r = r.filter((m: any) => m.date !== a.formatDate);
              if (properties && properties?.find(property => property === a.propertyGuid) === undefined) {
                return r;
              }
              if (existingObj) {
                const vehicleDetail: IVehicleDetail = {
                  licensePlate: a.licensePlate,
                  make: a.make,
                  model: a.model,
                  colour: a.colour,
                  reportedDateTime: new Date(a.reportedDateTime)
                };
                const checkVehicleExist = existingObj.vehicleDetails?.filter(vehicle => JSON.stringify(vehicle) === JSON.stringify(vehicleDetail));
                if (!checkVehicleExist || (checkVehicleExist && checkVehicleExist.length === 0))
                  existingObj.vehicleDetails.push(vehicleDetail);
                existingObj.noOfOffences = existingObj.vehicleDetails.length;
              } else {
                existingObj = {
                  date: a.formatDate,
                  noOfOffences: 0,
                  vehicleDetails: [],
                  reportedDateTime: new Date(a.reportedDateTime),
                  reasonName: a.reasonName,
                  enforcementUser: a.enforcementUser,
                  propertyGuid: a.propertyGuid || "",
                  }
                const vehicleDetail: IVehicleDetail = {
                  licensePlate: a.licensePlate,
                  make: a.make,
                  model: a.model,
                  colour: a.colour,
                  reportedDateTime: new Date(a.reportedDateTime)
                };
                existingObj.vehicleDetails.push(vehicleDetail);
                existingObj.noOfOffences = existingObj.vehicleDetails.length;
              }
              r.push(existingObj);
              return r;
            }, Object.create(null));
          
            this.setState({
              scanHistory: scanHistoryDetails,
              scanHistoryTotal: scanHistoryDetails,
              scanHistoryFiltered: scanHistoryDetailsFiltered,
              selectedViewEnforcementItem: selectedEnforcement
            });
          });
        }
      });
  }

  private loadScanHistoryDetail = (licensePlate: string, filterDate: string | undefined = undefined, propertyId: string = '') => {

    const {selectedAddressDetails, scanHistory} = this.state;
    const propertyGuid =
      propertyId.length > 0 ? propertyId :
        selectedAddressDetails.length > 0 ? selectedAddressDetails[0].propertyGuid :
          "00000000-0000-0000-0000-000000000000";

    let scanHistoryDetailsFiltered: IScanHistoryDetail | undefined;
    let selectedEnforcement: IEnforcementDetailsItem | undefined;

    this._enforcementService.GetScanHistoryDetails(propertyGuid, licensePlate)
      .then(r => {
        if (r.ok) {
          r.json().then(response => {
            const scanHistoryDetail: IScanHistory[] = response;
            let scanVehicleModel: IScanHistory = {
              licensePlate: "",
              make: "",
              model: "",
              colour: "",
              reportedDateTime: '',
              reasonName: "",
              formatDate: "",
              streetName: "",
              enforcementUser: ""
            }
            if (scanHistoryDetail.length > 0) {
              scanVehicleModel = scanHistoryDetail[0];
            }

            if (filterDate) {
              const searchDate = DateTimeHelper.dateStringToDate(filterDate);

              scanHistoryDetailsFiltered = scanHistory.find(scan =>
                scan.reportedDateTime === searchDate);

              selectedEnforcement = this.state.subFilteredViewEnforcementList.find(enforcement => {
                const startTime = new Date(enforcement.sessionStartDateTime);
                const endTime = new Date(enforcement.sessionEndDateTime);

                startTime.setMinutes(startTime.getMinutes() - 1);
                endTime.setMinutes(endTime.getMinutes() + 1);

                return (startTime <= searchDate && searchDate <= endTime) || (enforcement.propertyGuid === scanHistoryDetailsFiltered?.propertyGuid);
              })
            }

            this.setState({
              scanVehicleModel,
              scanHistoryDetail,
              selectedViewEnforcementItem: selectedEnforcement,
              scanHistoryFiltered: scanHistoryDetailsFiltered,
              rightPanelState: RightPanelState.ScanHistoryDetails
            });
          });
        }
      });
  }

  private handleResize = () => {
    this.setState({
      rightPanelState: this.state.rightPanelState
    })
  }

  private _getRightPanelContent = () => {
    if (this.state.rightPanelState !== RightPanelState.None) {

      const {
        scanVehicleModel,
        rightPanelState,
        scanHistory,
        scanHistoryDetail,
        scanHistoryFiltered,
        selectedViewEnforcementItem,
        users
      } = this.state;
      const enforcementUser = users.find(user => user.applicationUserGuid === selectedViewEnforcementItem?.userGuid);
      const backButtonState =
        rightPanelState === RightPanelState.ScanHistory ? RightPanelState.None :
          rightPanelState === RightPanelState.EnforcementScanDetails ? RightPanelState.ScanHistory :
            RightPanelState.EnforcementScanDetails;

      return (
        <ScanHistory
          rightPanelState={rightPanelState}
          getScanHistory={this.loadScanHistory}
          getScanHistoryDetail={this.loadScanHistoryDetail}
          selectedEnforcement={selectedViewEnforcementItem}
          selectedEnforcementUser={enforcementUser?.name}
          scanHistory={scanHistory}
          scanHistoryFiltered={scanHistoryFiltered}
          scanHistoryDetail={scanHistoryDetail}
          scanVehicleModel={scanVehicleModel}
          onCloseDrawerPanel={this.onCloseDrawerPanel}
          onBack={() => {
            this.setState({
              scanVehicleModel: null,
              rightPanelState: backButtonState
            });
          }}
        />
      );
    }
  }

  private loadEnforcementSearch() {
    this._enforcementService.GetEnforcementSearch()
      .then(r => {
        if (r.ok) {
          r.json().then(response => {

            let enforcementAddress: IEnforcementAddress[] = response;

            const propertyList = enforcementAddress.map((p_: any) => {
              p_.propertyDetailedName = `${p_.propertyName} (${p_.propertyAddressLine1})`;
              p_.isOffencesArea = p_.isHighParkingOffencesArea as boolean;
              p_.isTrafficArea = p_.isHighTrafficArea as boolean;
              (p_ as unknown as IGoogleMapLocation).lat = p_.propertyLatitiude as number;
              (p_ as unknown as IGoogleMapLocation).lng = p_.propertyLongitude as number;
              (p_ as unknown as IGoogleMapLocation).text = p_.propertyName;
              return p_;
            }) as IPropertyBasics[];

            this.setState({
              EnforcementAddressDetails: enforcementAddress.slice(0),
              isMultiSelectedProperty: enforcementAddress.slice(0).length > 1,
              selectedAddressDetails: enforcementAddress.slice(0),
              properties: propertyList.slice(0),
              //selectedProperty: propertyList.slice(0)
            }, () => {
              this.loadEnforcementList();
            });
          });
        }
      });
  }

  private loadEnforcementList() {
    this._enforcementService.GetEnforcementList()
      .then(r => {
        if (r.ok) {
          r.json().then(response => {

            let enforcementlist: IEnforcementDetailsItem[] = response;

            this.setState({
              filteredViewEnforcementList: enforcementlist.slice(0),
              allViewEnforcementList: enforcementlist.slice(0),
              subFilteredViewEnforcementList: enforcementlist.slice(0),
              isFocused: false
            }, () => {
              // this.filterEnforcement();
            });
          });
        }
      });
  }

  private onCloseDrawerPanel = () => {
    if (this.state.hasUnsavedChanges) {
      ShowAlertwithConfirm("You have unsaved changes!", "Are you sure you want to close this window?", "warning")
        .then(r_ => {
          if (r_) {
            this.updateCloseState();
          }
        })
    } else {
      this.updateCloseState()
    }
  }

  private updateCloseState() {
    this.setState({
      rightPanelState: RightPanelState.None,
      hasUnsavedChanges: false,
      scanVehicleModel: null,
      scanHistoryDetail: [],
      // scanHistory: []
    });
  }

  private filterEnforcement() {
    this.setState({
      scanHistory: [],
    })

    let filtered_ = this.state.allViewEnforcementList.filter(x => {
      return JSON.stringify(this.state.selectedAddressDetails).indexOf(x.propertyGuid) !== -1;
    });

    this._enforcementService.GetScanHistories().then(response => {
      if (response.ok) {
        return response.json().then((scans: IScanHistory[]) => scans);
      }
    }).then(promise => {
      const scanHistories = promise;

      const mappedFiltered = filtered_.map(property => {
        property.scanHistory = scanHistories?.filter(scan => {
          const sessionStart = new Date(property.sessionStartDateTime);
          const sessionEnd = new Date(property.sessionEndDateTime);
          const reportDate = new Date(scan.reportedDateTime);

          sessionStart.setMinutes(sessionStart.getMinutes() - 1);
          sessionEnd.setMinutes(sessionEnd.getMinutes() + 1);

          return (
            (scan.propertyGuid === property.propertyGuid)
            && (sessionStart.getTime() <= reportDate.getTime() && reportDate.getTime() <= sessionEnd.getTime())
          );
        }) || [];
        return property;
      });

      this.setState({
        subFilteredViewEnforcementList: mappedFiltered,
      }, () => {
        this._searchTermChange(this.state.searchTerm, true);
      })
    });
  }

  private loadOffenses() {
    if (this.state.isMultiSelectedProperty) {
      return;
    }

    this.loadScanHistory(this.state.selectedAddressDetails[0].propertyGuid);
  }

  private _searchTermChange = (searchTerm_: string, isDataRefreshed?: boolean): void => {
    this.setState({
      searchTerm: searchTerm_,
      subFilteredViewEnforcementList: this._filterDataBySearch(this.state.filteredViewEnforcementList)
    }, () => {
      if (isDataRefreshed) {
        this.updateNotificationData();
      }
    });
  }

  private _filterDataBySearch = (data_: IEnforcementDetailsItem[]): IEnforcementDetailsItem[] => {
    let filteredData = data_;

    const selectedAddressDetails = this.state.selectedAddressDetails;

    filteredData = filteredData.filter(d => {
      const addressDetail = selectedAddressDetails?.find(selectProperty => selectProperty.propertyGuid === d.propertyGuid);
      return addressDetail && Object.keys(addressDetail).length > 0;
    });

    return filteredData;
  }

  private _searchLicensePlate = (searchTerm_: string): void => {
    this.setState({
      licensePlateSearchTerm: searchTerm_,
      scanHistory: this._filterDataByLicensePlate(this.state.scanHistoryTotal, searchTerm_)
    })
  };

  private _filterDataByLicensePlate = (data_: IScanHistoryDetail[], searchTerm: string): IScanHistoryDetail[] => {
    let filteredData = data_;
    const foundReports = filteredData.filter(d => d.vehicleDetails?.find(vehicle => vehicle.licensePlate.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1));

    return foundReports;
  }

  private async updateNotificationData() {
    let enforcementNotificationList: IEnforcementNotification[] = (await this._enforcementService.GetEnforcementNotification());

    this.setState({
      enforcementNotifications: enforcementNotificationList.map((x: IEnforcementNotification) => {
        x.message = x.message.replaceAll("&ldquo;", "<b>").replaceAll("&rdquo;", "</b>");
        x.loadDate = new Date();
        return x;
      })
    }, () => {
      localStorage.setItem("EnforcementNotifications", JSON.stringify(this.state.enforcementNotifications));
    });
  }
}

export default EnforcementList;


interface IEnforcementProps {

}

interface IEnforcementState {
  isScreenAccessible: boolean;
  scanHistory: IScanHistoryDetail[],
  scanHistoryFiltered?: IScanHistoryDetail,
  allViewEnforcementList: IEnforcementDetailsItem[];
  filteredViewEnforcementList: IEnforcementDetailsItem[];
  subFilteredViewEnforcementList: IEnforcementDetailsItem[];
  selectedViewEnforcementItem?: IEnforcementDetailsItem;
  isMultiSelectedProperty: boolean;
  EnforcementAddressDetails: IEnforcementAddress[];
  selectedAddressDetails: IEnforcementAddress[];
  scanHistoryDetail: IScanHistory[],
  scanVehicleModel: IScanHistory | null,
  isWidgetRefreshed: boolean,
  searchTerm: string,
  isFocused: boolean,
  isNotificationEnabled: boolean,
  rightPanelState: RightPanelState,
  hasUnsavedChanges: boolean,
  properties: IPropertyBasics[],
  selectedProperty: IPropertyBasics | null,
  isMap: boolean,
  currTab: string,
  multipleSelectedLocation: IPropertyBasics[] | null,
  enforcementNotifications: IEnforcementNotification[],
  users: IUser[],
  licensePlateSearchTerm: string,
  scanHistoryTotal: IScanHistoryDetail[],
}

const StyledTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#00b188',
  },
});

interface StyledTabProps {
  label: string;
  value: string;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({theme}) => ({
  textTransform: 'none',
  font: 'Manrope',
  fontWeight: 700,
  fontSize: theme.typography.pxToRem(12),
  marginRight: theme.spacing(1),
  color: '#8A959E',
  '&.Mui-selected': {
    color: '#00b188',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));
