import { IconButton, Tooltip } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FaceIcon from '@material-ui/icons/Face';
import PaymentIcon from '@material-ui/icons/Payment';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import React from 'react';
import { FaChevronDown, FaChevronLeft, FaChevronRight, FaChevronUp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { NavbarModel } from '../Models/NavbarModel';
import { UserDetailsModel } from '../Models/UserDetails';
import { ConfigService } from '../Services/ConfigService';
import UserService from '../Services/UserService';

interface NavBarLeftProps { }
interface NavBarLeftState {
    userDetails: UserDetailsModel,
    isVisible: boolean,
    MenuItem: NavbarModel[],
    PropertiesMenuItem: NavbarModel[],
    activeUrl: string;
    version: string;
    reportAccessUsers: string[];
    isSubMenuOpen:boolean;
    AllScreen: NavbarModel[]
}

class NavBarLeft extends React.Component<NavBarLeftProps, NavBarLeftState> {
    private _userService: UserService;
    private interval: any;


    constructor(props: NavBarLeftProps) {
        super(props);
        this.state = {
            userDetails: {
                accessToken: '',
                emailId: '',
                fullName: '',
                mobileNumber: '',
                userRoleID: [],
                isPlaidVerified: false
            },
            isVisible: false,
            MenuItem: [],
            PropertiesMenuItem: [],
            activeUrl: window.location.pathname,
            version: "",
            reportAccessUsers: [],
            isSubMenuOpen:false,
            AllScreen: [
                { name: 'Dashboard', label: 'Dashboard' },
                { name: 'Properties', label: 'Properties' },
                { name: 'Zones', label: 'Zones' },
                { name: 'Spots', label: 'Spots' },
                { name: 'Companies', label: 'Management Companies' },
                { name: 'Bookings', label: 'Bookings' },
                { name: 'Calendar', label: 'Calendar' },
                {
                    name: 'lot-management', label: "Lot Management"
                    , subMenu:
                        [{ name: 'parking-passes', label: "Parking Passes" },
                            { name: 'visitor-parking', label: "Visitor Parking" }]
                },
                { name: 'Users', label: 'Users' },
                { name: 'bulk-upload', label: 'Bulk Upload' },
                { name: 'cashBookings', label: 'Cash Bookings' },
                { name: 'enforcements', label: 'Enforcements' }
            ]
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
        window.addEventListener("popstate", () => {
            this.setState({
                activeUrl: window.location.pathname
            });
        });

        this._userService = new UserService();
    }

    public componentDidMount = () => {
        this.getAccessibleScreen();
        document.addEventListener('click', this.handleClickOutside);
    }

    public componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    public isPropertiesDetails() {
        let isNewMenu: boolean = false;
        if (this.state.activeUrl.toLocaleLowerCase().indexOf('/properties/') !== -1) {
            isNewMenu = true;
        }

        return isNewMenu;
    }

    public render() {
        if (this.isPropertiesDetails()) {
            return (
                <div className={'left-side-menu'}>
                    <div className="lef-menu-container left-help-container left-back-arrow">
                        <ul>
                            <li className={this.getActiveMenuStatus('Back')}>
                                <Link to="/Properties">
                                    <Tooltip title='Back'>
                                        <IconButton aria-label="Back">
                                            {this.navbarIcon('Back')}
                                        </IconButton>
                                    </Tooltip>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="lef-menu-container bullet-menu">
                        <ul>
                            {this.state.PropertiesMenuItem.map((_listItem) => (
                                <li key={_listItem.name} className={this.getActiveMenuStatus(_listItem.name)}>
                                    <Link to={'/Properties/' + this.getPropertyID() + '/' + _listItem.name}>
                                        <span className="menu-dot">&middot;</span>
                                        <span className="icon-container">
                                            <span className="menu-label">{_listItem.label}</span>
                                        </span>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="lef-menu-container left-help-container">
                        <ul>
                            <li className={this.checkIsActive('help')}>
                                <a href="https://help.parkwithgryd.com/" target="_blank">
                                    {
                                        this.navbarSource({ 'name': 'Help', 'label': 'Help' })
                                    }
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="app-version">{this.state.version}</div>
                </div>
            )
        } else {
            return (
                <div className={'left-side-menu' + (!this.state.isVisible ? ' min-nav' : '')}>
                    <div className="toggle-menu" onClick={() => this.toggleMenu()}>
                        <Tooltip title={(!this.state.isVisible ? 'Expand' : 'Collapse')}>
                            <IconButton aria-label={(!this.state.isVisible ? 'Expand' : 'Collapse')} className="toggle-icon">
                                {
                                    (!this.state.isVisible ? <FaChevronRight fontSize="medium" className="toggle-icon" /> : <FaChevronLeft fontSize="medium" className="toggle-icon" />)
                                }
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className="logo"></div>
                    <div className="lef-menu-container">
                        <ul>
                            {this.state.MenuItem.map((_listItem) => (
                                <>
                                    {this.state.isVisible && _listItem.subMenu ?
                                        (<>
                                            <li key={_listItem.name} className={this.getActiveMenuStatus(_listItem.name)}>
                                                <Link to={'/' + _listItem.name}
                                                      onClick={(e) => {
                                                          this.setState({
                                                              isSubMenuOpen: !this.isSubOpenMenu(_listItem)
                                                          });
                                                      }}>
                                                    {
                                                        this.navbarSource(_listItem)
                                                    }
                                                    {this.isSubOpenMenu(_listItem) ? <FaChevronUp fontSize="medium" style={{float:"right"}}/>:<FaChevronDown fontSize="medium"  style={{float:"right"}}/>}
                                                </Link>
                                            </li>
                                            {this.isSubOpenMenu(_listItem) && _listItem.subMenu.map(l_ => (
                                                <li key={l_.name} className={this.checkIsActive(l_.name)==="active"?"active submenu-bg-color pl-4":" pl-4"}>
                                                    <Link to={'/' + l_.name}>
                                                        {
                                                            this.navbarSource(l_)
                                                        }
                                                    </Link>
                                                </li>
                                            ))}
                                        </>)
                                        :
                                        (<>
                                            <li key={_listItem.name} className={this.getActiveMenuStatus(_listItem.name, _listItem)}>
                                                <Link to={'/' + _listItem.name}>
                                                    {
                                                        this.navbarSource(_listItem)
                                                    }
                                                </Link>
                                            </li>
                                        </>)}
                                </>
                            ))}
                        </ul>
                    </div>
                    <div className="lef-menu-container left-help-container">
                        <ul>
                            <li className={this.checkIsActive('help')}>
                                <a href="https://help.parkwithgryd.com/" target="_blank">
                                    {
                                        this.navbarSource({ 'name': 'Help', 'label': 'Help' })
                                    }
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="app-version">{this.state.version}</div>
                </div>
            )
        }
    }

    private isSubOpenMenu(_listItem: NavbarModel){
        return ((this.state.isSubMenuOpen || this.openSubMenu(_listItem)) &&
            this.checkSubMenuSelected(_listItem));
    }

    private openSubMenu(_listItem: NavbarModel) {
        if (_listItem?.subMenu) {
            let currentScreen: string[] = this.state.activeUrl.toLocaleLowerCase().split('/');
            const subMenu = _listItem.subMenu.find(x => currentScreen.indexOf(x.name.toLocaleLowerCase()) !== -1)?.name ?? "";
            if (subMenu && currentScreen.indexOf(subMenu.toLocaleLowerCase()) !== -1) {
                return true;
            }
        }
        return false;
    }

    private checkSubMenuSelected(_listItem: NavbarModel) {
        let screenName = _listItem.name;
        let currentScreen: string[] = this.state.activeUrl.toLocaleLowerCase().split('/');
        if (_listItem?.subMenu) {
            const subMenu = _listItem.subMenu.find(x => currentScreen.indexOf(x.name.toLocaleLowerCase()) !== -1)?.name ?? "";
            screenName = subMenu ? subMenu : screenName;
        }
        return currentScreen.indexOf(screenName.toLocaleLowerCase()) !== -1;
    }

    private getActiveMenuStatus(itemName: string, _listItem?: NavbarModel) {
        let activeClass = "";
        if (this.state.isVisible)
            activeClass = this.checkIsActive(itemName) === "active" ? "active submenu-bg-color" : "";
        else {
            if (_listItem?.subMenu && this.openSubMenu(_listItem)) {
                return 'active';
            }
            activeClass = this.checkIsActive(itemName);
        }

        return activeClass;
    }

    private getNavbarIcon(_listItem: NavbarModel) {
        if (this.state.isVisible)
            return this.navbarIcon(_listItem.name, '1.6em');
        else {
            if (_listItem?.subMenu && this.openSubMenu(_listItem)) {
                return this.navbarIcon(_listItem.name, undefined, _listItem);
            }
            return this.navbarIcon(_listItem.name);
        }
    }

    private navbarSource(listItem: NavbarModel) {
        if (!this.state.isVisible) {
            return (
                <Tooltip title={listItem.label}>
                    <IconButton aria-label={listItem.label} id={listItem.name} className={`${this.getActiveMenuStatus(listItem.name, listItem)}`}>
                        {this.getNavbarIcon(listItem)}
                    </IconButton>
                </Tooltip>
            )
        } else {
            return (
                <span className="icon-container">
                    {this.getNavbarIcon(listItem)} <span className="menu-label">{listItem.label}</span>
                </span>
            )
        }
    }

    private navbarIcon(menuName: string, iconSize?: string, _listItem?: NavbarModel) {
        let assignIcon = <span></span>;
        switch (menuName.toLowerCase()) {
            case EnumNavMenu[EnumNavMenu.Dashboard].toLowerCase(): {
                assignIcon = <span className='left-nav-icon icon-dashboard'></span>;
                break;
            }
            case EnumNavMenu[EnumNavMenu.Properties].toLowerCase(): {
                assignIcon = <span className='left-nav-icon icon-properties'></span>;
                break;
            }
            case EnumNavMenu[EnumNavMenu.Spots].toLowerCase(): {
                assignIcon = <span className='left-nav-icon icon-spot'></span>;
                break;
            }
            case EnumNavMenu[EnumNavMenu.Companies].toLowerCase(): {
                assignIcon = <span className='left-nav-icon icon-companies'></span>;
                break;
            }

            case EnumNavMenu[EnumNavMenu.Bookings].toLowerCase(): {
                assignIcon = <span className='left-nav-icon icon-booking'></span>;
                break;
            }
            case EnumNavMenu[EnumNavMenu.Users].toLowerCase(): {
                assignIcon = <span className='left-nav-icon icon-user'></span>;
                break;
            }
            case EnumNavMenu[EnumNavMenu['Bulk-Upload']].toLowerCase(): {
                assignIcon = <span className='left-nav-icon icon-bulkupload'></span>;
                break;
            }
            case EnumNavMenu[EnumNavMenu.Help].toLowerCase(): {
                assignIcon = <span className='left-nav-icon icon-help'></span>;
                break;
            }
            case EnumNavMenu[EnumNavMenu.Back].toLowerCase(): {
                assignIcon = <ArrowBackIcon />;
                break;
            }
            case EnumNavMenu[EnumNavMenu.Tenants].toLowerCase(): {
                assignIcon = <FaceIcon />;
                break;
            }
            case EnumNavMenu[EnumNavMenu.Payment].toLowerCase(): {
                assignIcon = <PaymentIcon />;
                break;
            }
            case EnumNavMenu[EnumNavMenu.Plaid].toLowerCase(): {
                assignIcon = <VerifiedUserIcon />;
                break;
            }
            case EnumNavMenu[EnumNavMenu.Calendar].toLowerCase(): {
                assignIcon = <span className='left-nav-icon icon-calendar'></span>;
                break;
            }
            case EnumNavMenu[EnumNavMenu.Zones].toLowerCase(): {
                assignIcon = <span className='left-nav-icon icon-zone'></span>;
                break;
            }
            case EnumNavMenu[EnumNavMenu['Lot-Management']].toLowerCase(): {
                assignIcon = <span className='left-nav-icon icon-lotmanagement'></span>;
                break;
            }
            case EnumNavMenu[EnumNavMenu['Parking-Passes']].toLowerCase(): {
                assignIcon = <span className='left-nav-icon icon-parkingpassess'></span>;
                break;
            }
            case EnumNavMenu[EnumNavMenu.CashBookings].toLowerCase(): {
                assignIcon = <span className='left-nav-icon icon-cashbooking'></span>;
                break;
            }
            case EnumNavMenu[EnumNavMenu.Enforcements].toLowerCase(): {
                assignIcon = <span className='left-nav-icon icon-enforcement'></span>;
                break;
            }
            case EnumNavMenu[EnumNavMenu["Visitor-Parking"]].toLowerCase(): {
                assignIcon = <span className='left-nav-icon icon-visitorparking'></span>;
                break;
            }
        }
        return assignIcon;
    }

    private handleClickOutside(event: any) {
        if (this.state.activeUrl != window.location.pathname) {
            this.setState({
                activeUrl: window.location.pathname
            });
        }
    }

    private toggleMenu() {
        let menuState = !this.state.isVisible;
        this.setState({
            isVisible: menuState
        });
        sessionStorage.setItem('isVisible', '' + menuState);
    }
    private handleSubMenuOpen(){
        this.setState({
            isSubMenuOpen:!this.state.isSubMenuOpen
        })
    }

    private checkIsActive(screenName: string) {
        let currentScreen: string[] = this.state.activeUrl.toLocaleLowerCase().split('/');        
        if (currentScreen.indexOf(screenName.toLocaleLowerCase()) != -1) {
            return 'active';
        }
        return '';
    }

    private getPropertyID() {
        let guid: string = '';
        let currentScreen: string[] = this.state.activeUrl.toLocaleLowerCase().split('/');

        if (currentScreen.indexOf('properties') != -1) {
            guid = currentScreen[2];
        }

        return guid;
    }

    private getMenuItemBasedOnUsers(userRole: number[]) {
        let menuItems: NavbarModel[] = [];

        if (userRole.indexOf(1) != -1 || userRole.indexOf(2) != -1) {
            menuItems = [
                { name: 'Dashboard', label: 'Dashboard' },
                { name: 'Properties', label: 'Properties' },
                { name: 'Zones', label: 'Zones' },
                { name: 'Spots', label: 'Spots' },
                { name: 'Companies', label: 'Management Companies' },
                { name: 'Bookings', label: 'Bookings' },
                { name: 'Calendar', label: 'Calendar' },
                {
                    name: 'lot-management', label: "Lot Management"
                    , subMenu:
                        [{ name: 'parking-passes', label: "Parking Passes" },
                            { name: 'visitor-parking', label: "Visitor Parking" }]
                },                
                { name: 'Users', label: 'Users' },
                { name: 'bulk-upload', label: 'Bulk Upload' },                
                { name: 'cashBookings', label: 'Cash Bookings' },
                { name: 'enforcement', label: 'Enforcements' }
            ]

        } else if (userRole.indexOf(5) != -1 || userRole.indexOf(8) != -1) {
            menuItems = [
                { name: 'Dashboard', label: 'Dashboard' },
                { name: 'Properties', label: 'Properties' },
                { name: 'Zones', label: 'Zones' },
                { name: 'Spots', label: 'Spots' },
                { name: 'Bookings', label: 'Bookings' },
                { name: 'cashBookings', label: 'Cash Bookings' }
            ]

            if (userRole.indexOf(5) != -1) {
                menuItems.push({ name: 'Calendar', label: 'Calendar' })
            }

        } else if (userRole.indexOf(6) != -1) {
            menuItems = [
                { name: 'Dashboard', label: 'Dashboard' },
                { name: 'Properties', label: 'Properties' },
                { name: 'Spots', label: 'Spots' },
                { name: 'Bookings', label: 'Bookings' },
                { name: 'cashBookings', label: 'Cash Bookings' },                
            ]
        }

        return menuItems;
    }

    private logOut() {
        localStorage.clear();
        window.location.href = "/";
    }

    private getAccessibleScreen() {
        this._userService.getAccessibleScreen()
            .then(r => {
                if (r.ok) {
                    r.json().then(results => {
                        var resultString = JSON.stringify(results).toLocaleLowerCase();

                        localStorage.setItem('accessibleScreen', resultString);

                        let activeScreens: NavbarModel[] = [];

                        this.state.AllScreen.forEach(m => {
                            var screenName = "user-interface-" + m.name.toLocaleLowerCase();
                            if (resultString.indexOf(screenName) != -1) {
                                activeScreens.push(m);
                            }
                        });

                        // Filter out the marketplace menu items:
                        const hiddenItems = ['Dashboard', 'Bookings', 'Calendar', 'bulk-upload', 'cashBookings'];
                        const filteredScreens = activeScreens.filter(
                            (item) => !hiddenItems.includes(item.name)
                        );

                        this.setState({
                            MenuItem: filteredScreens
                        }, () => {
                            this.updateMenus();
                        });
                    })
                }
            });
    }


    private updateMenus() {
        let propertGuid: string = this.getPropertyID();

        var _localData = localStorage.getItem('userdata');
        let _userDetails: UserDetailsModel = this.state.userDetails;

        if (_localData != null && _localData !== '') {
            var _data = JSON.parse(_localData);
            _userDetails = _data;
        }

        ConfigService.getAppVersion()
            .then(appVersion => {
                ConfigService.getReportAccess()
                    .then(listOfusers => {
                        this.setState({
                            userDetails: _userDetails,
                            reportAccessUsers: listOfusers,
                            PropertiesMenuItem: [
                                { name: 'overview', label: 'Overview' },                                
                                { name: 'propertiesusers', label: 'Users' },
                            ],
                            version: appVersion
                        });
                    })
            });
    }
}

export default NavBarLeft;

enum EnumNavMenu {
    Dashboard = 1,
    Properties = 2,
    Spots = 3,
    Companies = 4,
    Bookings = 6,
    Users = 7,
    "Bulk-Upload" = 8,
    Help = 9,
    Back = 10,
    Tenants = 11,
    Payment = 12,
    Plaid = 13,
    Calendar = 14,
    Zones = 16,
    "Lot-Management" = 18,
    "Parking-Passes" = 19,
    CashBookings = 20,
    Enforcements = 21,
    "Visitor-Parking" = 22
}