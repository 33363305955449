import { UserDetailsModel } from '../Models/UserDetails';

export class GetUserDetailsService {
    public static getUserDetails() {
        var _localData = localStorage.getItem('userdata');

        let _userDetails: UserDetailsModel = {
            accessToken: '',
            fullName: '',
            emailId: '',
            mobileNumber: '',
            userRoleID: [],
            isPlaidVerified: false
        }


        if (_localData != null && _localData !== '') {
            var _data = JSON.parse(_localData);

            _userDetails.accessToken = _data.accessToken;
            _userDetails.fullName = _data.fullName;
            _userDetails.emailId = _data.emailId;
            _userDetails.mobileNumber = _data.mobileNumber;
            _userDetails.userRoleID = _data.userRoleID;
        } /*else {
            localStorage.clear();
            window.location.href = '/';
        }*/

        return _userDetails;
    }
}