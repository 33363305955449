import NumberRangeSelector, { INumberRangeSelectorProps, INumberRangeSelectorState } from "./NumberRangeSelector";

export default class PriceRangeSelector extends NumberRangeSelector<IPriceRangeSelectorProps, IPriceRangeSelectorState> {
    protected _label: string = "Price";
    protected _txtMask: RegExp = /^[$]{0,1}[0-9]*([.]){0,1}[0-9]{0,2}$/i;
    protected _defaultStepsize = 0.5;

    constructor(props_: IPriceRangeSelectorProps) {
        super(props_);
        this._init();
    }

    protected _makeText(price_: number): string {
        return `$${super._makeText(price_)}`;
    }
}

interface IPriceRangeSelectorProps extends INumberRangeSelectorProps { }

interface IPriceRangeSelectorState extends INumberRangeSelectorState { }