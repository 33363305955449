import { Grid, Select, MenuItem } from '@material-ui/core';
import React from 'react';
import './Dashboard.css'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PropertyService from '../../Services/PropertyService';
import WidgetLoaderComponent from '../../Components/WidgetLoaderComponent';
import { widgetOptions } from './Interface';
import SpotService from '../../Services/SpotService';

export default class TopWidgets extends React.Component<TopWidgetsProps, TopWidgetsState> {
    private _propertyService: PropertyService;
    private _spoService: SpotService;

    private _widgetList: any[] = [
        {
            widgetBooking: false,
            WidgetGrowth: true,
            WidgetPercentage: 40,
            WidgetValue: 138.43,
            widgetOption: 'YTD',
            widgetCancelledValue: 0
        },
        {
            widgetBooking: false,
            WidgetGrowth: true,
            WidgetPercentage: 40,
            WidgetValue: 10080,
            widgetOption: 'YTD',
            widgetCancelledValue: 0
        },
        {
            widgetBooking: false,
            WidgetGrowth: false,
            WidgetPercentage: 12,
            WidgetValue: 3.30,
            widgetOption: 'YTD',
            widgetCancelledValue: 0
        },
        {
            widgetBooking: true,
            WidgetGrowth: false,
            WidgetPercentage: 34,
            WidgetValue: 486,
            widgetOption: 'YTD',
            widgetCancelledValue: 17
        }
    ]
    constructor(props: TopWidgetsProps) {
        super(props);
        this.state = {
            widgetBooking: false,
            WidgetGrowth: true,
            WidgetPercentage: 0,
            WidgetValue: 0,
            widgetOption: 'YTD',
            widgetCancelledValue: 17,
            selectedCities: [],
            selectedProperties: [],
            selectedSpots: [],
            loaderVisible: false
        }

        this._propertyService = new PropertyService();
        this._spoService = new SpotService();
    }

    public componentDidMount = () => {
        this.loadWidgetData();
    }

    public componentDidUpdate = () => {
        // console.log(this.props.spots)
        if ((this.props.cities && this.state.selectedCities.toString() != this.props.cities.toString()) ||
            (this.props.properties && this.state.selectedProperties.toString() != this.props.properties.toString()) ||
            (this.props.spots && this.state.selectedSpots.toString() != this.props.spots.toString())) {
            this.loadWidgetData();
        }
    }

    private loadWidgetData() {
        let widgetData: any = {}

        if (this.props.cities) {
            widgetData = {
                selectedCity: this.props.cities && this.props.cities.length > 0 ? this.props.cities.toString() : null,
                selectedTimePeroid: this.state.widgetOption
            }
        } else if (this.props.properties) {
            let propertyGuids: string[] = this.props.properties;

            let allItemIndex = propertyGuids.indexOf('all_items');

            if (allItemIndex != -1) {
                propertyGuids.splice(allItemIndex, 1);
            }
            widgetData = {
                SelectedProperty: propertyGuids && propertyGuids.length > 0 ? propertyGuids : null,
                selectedTimePeroid: this.state.widgetOption
            }
        } else if (this.props.spots) {
            let spotGuids: string[] = this.props.spots;

            let allItemIndex = spotGuids.indexOf('all_items');

            if (allItemIndex != -1) {
                spotGuids.splice(allItemIndex, 1);
            }
            widgetData = {
                selectedSpots: spotGuids && spotGuids.length > 0 ? spotGuids : null,
                selectedTimePeroid: this.state.widgetOption
            }
        }

        // console.log(widgetData);

        if (!this.props.widgetInfo.isComingSoon) {
            if (this.props.widgetInfo.serviceEndpoint == 'property') {
                this._propertyService.getAdminDashboardWidgets(widgetData, this.props.widgetInfo.api, true)
                    .then(data => {
                        if (data.ok) {
                            data.json().then(d => {
                                let WidgetValue_: number = 0;
                                if (this.props.widgetInfo.api == 'AdminDashboardTotalRevenueV3') {
                                    WidgetValue_ = d.currentTotalReservationRevenue;
                                } else if (this.props.widgetInfo.api == 'AdminDashboardTotalGrossV3') {
                                    WidgetValue_ = d.currentTotalReservationGross;
                                } else if (this.props.widgetInfo.api == 'AdminDashboardTotalBookingsV3') {
                                    WidgetValue_ = d.currentTotalBookings;
                                } else if (this.props.widgetInfo.api == 'ManagerDashboardTotalRevenueV3') {
                                    WidgetValue_ = d.currentTotalReservationRevenue;
                                }

                                this.setState({
                                    widgetBooking: this.props.widgetInfo.api == 'AdminDashboardTotalBookingsV3',
                                    WidgetGrowth: d.changeinPercentage >= 0,
                                    WidgetPercentage: this.state.widgetOption != 'LTV' && d.isinfinty ? -0.1 : d.changeinPercentage,
                                    WidgetValue: WidgetValue_,
                                    widgetCancelledValue: d.cancelPercentage ? d.cancelPercentage : 0,
                                    selectedCities: this.props.cities ? this.props.cities : [],
                                    selectedProperties: this.props.properties ? this.props.properties : [],
                                    loaderVisible: false
                                });
                            })
                        }
                    })
                    .catch((error) => {
                        console.error('Error:', JSON.stringify(error));
                    });
            } else {
                this._spoService.getDashboardWidgets(widgetData, this.props.widgetInfo.api, true)
                    .then(data => {
                        if (data.ok) {
                            data.json().then(d => {
                                let WidgetValue_: number = 0;

                                if (this.props.widgetInfo.api == 'TenantDashboardTotalRevenueV3') {
                                    WidgetValue_ = d.currentTotalSpotRevenue;
                                }

                                this.setState({
                                    widgetBooking: this.props.widgetInfo.api == 'AdminDashboardTotalBookingsV3',
                                    WidgetGrowth: d.changeinPercentage >= 0,
                                    WidgetPercentage: this.state.widgetOption != 'LTV' && d.isinfinty ? -0.1 : d.changeinPercentage,
                                    WidgetValue: WidgetValue_,
                                    widgetCancelledValue: d.cancelPercentage ? d.cancelPercentage : 0,
                                    selectedCities: this.props.cities ? this.props.cities : [],
                                    selectedProperties: this.props.properties ? this.props.properties : [],
                                    selectedSpots: this.props.spots ? this.props.spots : [],
                                    loaderVisible: false
                                });
                            })
                        }
                    })
                    .catch((error) => {
                        console.error('Error:', JSON.stringify(error));
                    });
            }
        } else {
            this.setState({
                widgetBooking: false,
                WidgetGrowth: this._widgetList[2].WidgetGrowth,
                WidgetPercentage: this._widgetList[2].WidgetPercentage,
                WidgetValue: this._widgetList[2].WidgetValue,
                widgetOption: this._widgetList[2].widgetOption,
                widgetCancelledValue: this._widgetList[2].widgetCancelledValue,
                selectedCities: this.props.cities ? this.props.cities : [],
                selectedProperties: this.props.properties ? this.props.properties : [],
                selectedSpots: this.props.spots ? this.props.spots : []
            });
        }
    }

    public render() {
        return (
            <div className={'dashboard-widget ' + (this.props.widgetInfo.isGreenGg ? 'selected' : '')}>
                <Grid container>
                    <Grid item xs>
                        <div className="dashboard-widget-title">{this.props.widgetInfo.title}</div>
                        {
                            (this.props.widgetInfo.title == 'Earnings' && this.props.widgetInfo.isTenant) &&
                            <div className="dashboard-widget-sub-title">To be Disbursed</div>
                        }
                    </Grid>
                    <Grid item>
                        {
                            (!this.props.widgetInfo.Ignorefilter) &&
                            <Select
                                inputProps={{ 'aria-label': 'Without label' }}
                                className="dashboard-select"
                                value={this.state.widgetOption}
                                onChange={(event: any) => {
                                    this.setState({
                                        widgetOption: event.target.value,
                                        loaderVisible: true
                                    }, () => {
                                        this.loadWidgetData();
                                    })
                                }}
                            >
                                <MenuItem value={'YTD'}>YTD</MenuItem>
                                <MenuItem value={'MTD'}>MTD</MenuItem>
                                <MenuItem value={'WTD'}>WTD</MenuItem>
                                <MenuItem value={'LTV'}>LTV</MenuItem>
                            </Select>
                        }
                    </Grid>
                </Grid>
                <Grid container className="dashboard-widget-row">
                    <Grid item xs>
                        <div className="dashboard-widget-value">{(!this.state.widgetBooking ? "$" : "") + Number(this.state.WidgetValue ? this.state.WidgetValue : 0).toFixed((!this.state.widgetBooking ? 2 : 0)).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            {
                                (!this.props.widgetInfo.Ignorefilter) &&
                                <span className="dashboard-widget-arrow">
                                    {
                                        (this.state.widgetOption != 'LTV' && this.state.WidgetPercentage != -0.1) &&
                                        (this.state.WidgetGrowth ? <ArrowUpwardIcon className="arrow-upward" fontSize="small" /> : <ArrowDownwardIcon fontSize="small" className="arrow-downward" />)
                                    }
                                    {
                                        this.state.widgetOption != 'LTV' &&
                                        <span className={"dashboard-widget-percent " + (this.state.WidgetPercentage != -0.1 ? '' : 'margin-left')}>{this.state.WidgetPercentage != -0.1 ? Math.abs(this.state.WidgetPercentage) + '%' : 'N/A'}</span>
                                    }
                                </span>
                            }
                        </div>
                    </Grid>
                    <Grid item>
                        {
                            this.state.widgetBooking &&
                            <div className="dashboard-widget-cancelled">
                                <div className="cancel-percentage">{this.state.widgetCancelledValue}%</div>
                                <div className="cancel-text">Cancelled</div>
                            </div>
                        }
                    </Grid>
                </Grid>
                {
                    this.props.widgetInfo.isComingSoon &&
                    <div className="coming-soon">
                        <div>Coming Soon</div>
                    </div>
                }
                <WidgetLoaderComponent loaderVisible={this.state.loaderVisible} />
            </div>
        )
    }

}

interface TopWidgetsProps {
    widgetInfo: widgetOptions;
    cities?: string[];
    properties?: string[];
    spots?: string[];
}
interface TopWidgetsState {
    loaderVisible: boolean;
    widgetBooking: boolean;
    WidgetGrowth: boolean;
    WidgetPercentage: number;
    WidgetValue: number;
    widgetOption: string;
    widgetCancelledValue: number;
    selectedCities: string[];
    selectedProperties: string[];
    selectedSpots: string[];
}