import { Button, Checkbox, FormControlLabel, Grid, IconButton, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import './zone.css';
import React from "react";
import { Row, Col } from "reactstrap";
import { ShowAlert, ShowAlertwithOptions } from "../../Common/ShowAlert";
import { IScheduleLimitation, ISingleScheduleData } from "../../Components/ScheduleComponent";
import StringHelper from "../../Helpers/StringHelper";
import PropertyService from "../../Services/PropertyService";
import SpotService from "../../Services/SpotService";
import ZoneService from "../../Services/ZoneService";
import { extractScheduleLimitationsFromProperty, IPropertyBasics } from "../Spots/Interfaces";
import { IAdditionalFeature, IPropertyDetails, ISpotFeature, IWeeklyTimeBlock, IDailyTimeBlock, IZone } from "./Interfaces";
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import MultiScheduleComponent from "../../Components/MultiScheduleComponent";
import UserService from "../../Services/UserService";
import { Data } from "react-csv/components/CommonPropTypes";
import PriceRangeSelector from "../../Components/PriceRangeSelector";
import TimeRangeSelector from "../../Components/TimeRangeSelector";
import { DateTimeHelper } from "../../Helpers/DateTimeHelper";
import { INumberRange } from "../../Components/DataGrid/Interfaces";
import NumberHelper from "../../Helpers/NumberHelper";
import { GetUserDetailsService } from "../../Services/GetUserDetailsService";
import ObjectHelper from "../../Helpers/ObjectHelper";
import { IReservationChunk, ISpotBlockMetadata, SpotBlock } from "../BookingsCalendar/interfaces";

export default class ZoneAddEditView extends React.Component<IZoneAddEditViewProps, IZoneAddEditViewState> {
    private _propertySelectionVisible: boolean = true;
    private _propertyAmenitiesVisible: boolean = true;
    private _minTime = 0; //default min Time
    private _fullDay = 1440; //Full day 60*24

    private _propertyService: PropertyService;
    private _zoneService: ZoneService;
    private _spotService: SpotService;
    private _userService: UserService;


    state: IZoneAddEditViewState = {
        isReady: false,
        properties: [],
        featureList: [],
        selectedProperty: null,
        otherFeatures: "",
        scheduleLimitation: extractScheduleLimitationsFromProperty(null),
        propertyAdditionalFeatures: [],
        propertyAmenities: [],
        loaderState: false,
        whenYouArrive: "",
        zoneDescription: "",
        zoneName: "",
        selectedFeature: [],
        permissionsList: [false, false, false],
        visitorParkingEnabled: false,
        dailyBookingEnabled: false,
        weeklyBookingEnabled: false,
        visitorTimeBlocks: [],
        dailyTimeBlocks: [],
        weeklyTimeBlocks: [],
        originalVisitorTimeBlocks: [],
        originalDailyTimeBlocks: [],
        originalWeeklyTimeBlocks: [],
        originalSelectedFeature: [],
        selectFeature: ""
    }

    constructor(props_: IZoneAddEditViewProps) {
        super(props_);

        this._propertyService = new PropertyService();
        this._zoneService = new ZoneService();
        this._spotService = new SpotService();
        this._userService = new UserService();
    }

    componentDidMount() {
        let apiCount = 4;
        let properties: IPropertyBasics[] = [];
        let features: ISpotFeature[] = [];
        let schedules: ISingleScheduleData[] = [{ "selectedDays": [1, 2, 3, 4, 5, 6, 7], "selectedPriceRange": { "start": 3, "end": 3 }, "selectedTimeRange": { "start": 0, "end": 1439 }, "_hasConflict": false }];
        let permissions: boolean[] = [];
        let selectedFeatures: string[] = [];
        let permissionKeys: string[] = ['edit-spot-price', 'edit-spot-hours', 'add-delete-spot-schedule'];
        let visitorTimeBlock_: IDailyTimeBlock[] = [];
        let dailyTimeBlock_: IDailyTimeBlock[] = [];
        let weeklyTimeBlock_: IWeeklyTimeBlock[] = [];
        let zone: IZone;

        let onApiResponse = () => {
            if (--apiCount == 0) {
                let dailyTime: any = this.validateDailyTime(dailyTimeBlock_);
                let visitorTime: any = this.validateDailyTime(visitorTimeBlock_);

                let updatedState: IZoneAddEditViewState = {
                    properties: properties,
                    featureList: features,
                    permissionsList: permissions,
                    schedules: schedules,
                    schedulesExisting: ObjectHelper.deepClone(schedules),
                    visitorTimeBlocks: ObjectHelper.deepClone(visitorTime),
                    dailyTimeBlocks: ObjectHelper.deepClone(dailyTime),
                    weeklyTimeBlocks: weeklyTimeBlock_,
                    originalVisitorTimeBlocks: ObjectHelper.deepClone(visitorTime),
                    originalDailyTimeBlocks: ObjectHelper.deepClone(dailyTime),
                    originalWeeklyTimeBlocks: ObjectHelper.deepClone(weeklyTimeBlock_),
                    selectedFeature: selectedFeatures.map(x => { return x.toString() }),
                    originalSelectedFeature: selectedFeatures.map(x => { return x.toString() }),
                    zone: zone
                } as any;

                if (this.props.zone) {
                    updatedState.zoneName = zone.zoneName;
                    updatedState.visitorParkingEnabled = zone.visitor;
                    updatedState.dailyBookingEnabled = zone.daily;
                    updatedState.weeklyBookingEnabled = zone.monthly;
                    updatedState.otherFeatures = zone.otherFeatures;
                    updatedState.selectedProperty = properties.filter(x => x.propertyGuid == zone.propertyGuid)[0];
                    updatedState.whenYouArrive = zone.whenYouArrive;
                    updatedState.zoneDescription = zone.description;
                }

                this.setState(updatedState, () => {
                    // this.getTimeBlock();
                    if (this.props.zone == null) {
                        this.getTimeBlock();
                    }
                });
            }
        }

        this._propertyService.getPropertiesBasics()
            .then(r => r.json())
            .then(r => {
                properties = r;
                onApiResponse();

            });

        this._spotService.getFeatureList()
            .then(r => r.json())
            .then(r => {
                features = r;
                onApiResponse();
            });

        this._userService.hasMultipleAccess(permissionKeys)
            .then(r => r.json())
            .then(r => {
                permissions = r;
                onApiResponse();
            });

        if (this.props.zone) {
            this._zoneService.getZoneInformation(this.props.zone.zoneGuid, false)
                .then(r => r.json())
                .then(p => {
                    if (p) {
                        schedules = p.schedules;
                        dailyTimeBlock_ = p.dailyRates;
                        visitorTimeBlock_ = p.visitorRates;
                        weeklyTimeBlock_ = p.weeklyRates;
                        selectedFeatures = p.selectedFeatures;
                        zone = p;
                        onApiResponse();
                        this._propertySelectionVisible = false;
                    }
                });
        } else {
            onApiResponse();
        }
    }

    componentDidUpdate(prevProps: Readonly<IZoneAddEditViewProps>, prevState: Readonly<IZoneAddEditViewState>) {
        /*const { selectFeature } = prevState;
        //const { clearAllCheck } = this.state;
        if (selectFeature !== this.state.selectFeature) {
            const featureList = this.state.selectedFeature.filter(x => x !== '12' && x !== '13');
            this.setState({ selectedFeature: featureList });
        }*/

        // if (selectFeature === this.state.selectFeature && clearAllCheck) {
        //     this.setState({ clearAllCheck: false });
        // }
    }

    render() {
        const { featureList } = this.state;
        const features = featureList.filter((feature: ISpotFeature) => !feature.isAmenity && feature.spotFeatureId !== 12 && feature.spotFeatureId !== 13);
        const amenities = featureList.filter((feature: ISpotFeature) => feature.isAmenity);
        const otherAmenities = featureList.filter((feature: ISpotFeature) => (feature.spotFeatureId === 12 || feature.spotFeatureId === 13));
        return (
            <div className={"spot-edit-view edit-view py-3" + (this.props.className ? (" " + this.props.className) : "")}>
                <div className="panel-view-scrollable-area py-3">
                    <React.Fragment>
                        <Row className="mb-3 px-3">
                            <Col>
                                <Autocomplete
                                    disabled={this.props.zone != null}
                                    options={this.state.properties}
                                    value={this.state.selectedProperty}
                                    onChange={(event: any, selectedItem_: IPropertyBasics | null) => {
                                        this.setState({
                                            selectedProperty: selectedItem_,
                                        }, () => {
                                            if (this.props.onChange) {
                                                this.props.onChange(this._hasChanges());
                                            }
                                        });
                                    }}
                                    getOptionLabel={(option) => option.propertyName}
                                    renderInput={(params) => <TextField {...params} label="Property"
                                        error={
                                            this.state.selectedProperty == null
                                        }
                                        variant="outlined" />}
                                />
                            </Col>
                        </Row>
                    </React.Fragment>
                    {
                        this.state.selectedProperty &&
                        <React.Fragment>
                            <Row className="px-3">
                                <Col>
                                    <h4 className="list-sub-header">Description</h4>
                                    <div className="zone-labeltext mt-4">Zone Name</div>
                                    <TextField
                                        error={this.state.zoneName.trim().length == 0}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="zoneName"
                                        label="Zone Name"
                                        name="zonename"
                                        onChange={(e_) => {
                                            this.setState({
                                                zoneName: e_.target.value
                                            }, () => {
                                                if (this.props.onChange) {
                                                    this.props.onChange(this._hasChanges());
                                                }
                                            })
                                        }}
                                        value={this.state.zoneName}
                                        autoComplete="off"
                                    />

                                    <div className="zone-labeltext">Zone Description</div>
                                    <TextField
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="zoneDescription"
                                        label="Zone Description"
                                        name="zoneDescription"
                                        onChange={(e_) => {
                                            this.setState({
                                                zoneDescription: e_.target.value
                                            }, () => {
                                                if (this.props.onChange) {
                                                    this.props.onChange(this._hasChanges());
                                                }
                                            })
                                        }}
                                        value={this.state.zoneDescription}
                                        autoComplete="off"
                                        inputProps={{
                                            maxlength: 200
                                        }}
                                        helperText={`${this.state.zoneDescription.length}/200`}
                                    />
                                </Col>
                            </Row>
                            <Row className="px-3">
                                <Col>
                                    <div className="zone-labeltext">Features</div>
                                </Col>
                            </Row>

                            <Row className="mt-3 mx-0">
                                <Col>
                                    {
                                        features.map((value_, index_) => {
                                            let { selectedFeature, selectFeature } = this.state;
                                            let checkIndex = selectedFeature.indexOf(value_.spotFeatureId.toString());
                                            let isChecked = checkIndex === -1;

                                            return (<div key={index_}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={!isChecked}
                                                            color="primary"
                                                            onChange={() => {
                                                                if (!isChecked) {
                                                                    selectedFeature.splice(checkIndex, 1);
                                                                    selectFeature = "";
                                                                }
                                                                else {
                                                                    if (value_.spotFeatureId == 6 ||
                                                                        value_.spotFeatureId == 7 ||
                                                                        value_.spotFeatureId == 8 ||
                                                                        value_.spotFeatureId == 10 ||
                                                                        value_.spotFeatureId == 11) {
                                                                        let secureIndex = selectedFeature.indexOf("6");
                                                                        let guestIndex = selectedFeature.indexOf("7");
                                                                        let privateIndex = selectedFeature.indexOf("8");
                                                                        let preBookIndex = selectedFeature.indexOf("10");
                                                                        let GuestPreBookIndex = selectedFeature.indexOf("11");
                                                                        let ScrambleLot = selectedFeature.indexOf("12");
                                                                        let VisitorParking = selectedFeature.indexOf("13");

                                                                        if (secureIndex != -1) {
                                                                            selectedFeature.splice(secureIndex, 1);
                                                                        }

                                                                        if (guestIndex != -1) {
                                                                            selectedFeature.splice(guestIndex, 1);
                                                                        }

                                                                        if (privateIndex != -1) {
                                                                            selectedFeature.splice(privateIndex, 1);
                                                                        }

                                                                        if (preBookIndex != -1) {
                                                                            selectedFeature.splice(preBookIndex, 1);
                                                                        }

                                                                        if (GuestPreBookIndex != -1) {
                                                                            selectedFeature.splice(GuestPreBookIndex, 1);
                                                                        }

                                                                        if (value_.spotFeatureId == 8) {
                                                                            if (ScrambleLot != -1) {
                                                                                selectedFeature.splice(ScrambleLot, 1);
                                                                            }
                                                                        }

                                                                        if (value_.spotFeatureId == 6) {
                                                                            if (VisitorParking != -1) {
                                                                                selectedFeature.splice(VisitorParking, 1);
                                                                            }
                                                                        }
                                                                    }

                                                                    selectedFeature.push(value_.spotFeatureId.toString());
                                                                    selectFeature = value_.spotFeatureId.toString();
                                                                }

                                                                this.setState({
                                                                    selectedFeature,
                                                                    selectFeature
                                                                }, () => {
                                                                    if (this.props.onChange) {
                                                                        this.props.onChange(this._hasChanges());
                                                                    }
                                                                });
                                                            }}
                                                        />
                                                    }
                                                    label={value_.featureName}
                                                />
                                            </div>
                                            );
                                        })
                                    }
                                </Col>
                            </Row>
                            <Row className="px-3">
                                <Col className="bb-px-1">
                                </Col>
                            </Row>

                            <Row className="mt-3 mx-0 bb-px-1">
                                <Col>
                                    {
                                        otherAmenities.map((value_, index_) => {
                                            let featureList = this.state.selectedFeature;
                                            let checkIndex = featureList.indexOf(value_.spotFeatureId.toString());
                                            let isChecked = checkIndex === -1;
                                            let enableCheckBox = false;

                                            let secureIndex = featureList.indexOf("6");
                                            let guestIndex = featureList.indexOf("7");
                                            let privateIndex = featureList.indexOf("8");
                                            let preBookIndex = featureList.indexOf("10");
                                            let GuestPreBookIndex = featureList.indexOf("11");
                                            // let scrambleLot = featureList.indexOf("12");
                                            // let visitorParking = featureList.indexOf("13");

                                            let isVisitorParking = value_.spotFeatureId === 13;

                                            // if (guestIndex !== -1) {
                                            //     enableCheckBox = false;
                                            //     //isChecked = true;
                                            // }

                                            if (!isVisitorParking && privateIndex !== -1) {
                                                isChecked = true;
                                                enableCheckBox = true;
                                            }

                                            if (isVisitorParking && secureIndex !== -1) {
                                                enableCheckBox = true;
                                                isChecked = true;
                                            }

                                            /*if (!isVisitorParking && (secureIndex !== -1 || privateIndex !== -1
                                                || preBookIndex !== -1 || GuestPreBookIndex !== -1)) {
                                                enableCheckBox = false;
                                                //isChecked = true;
                                            }

                                            if (isVisitorParking) {
                                                enableCheckBox = false;
                                            }

                                            if (isVisitorParking && (secureIndex !== -1 || privateIndex !== -1)) {
                                                    enableCheckBox = true;
                                                //isChecked = true;
                                            }*/

                                            return (<div key={index_}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disabled={enableCheckBox}
                                                            checked={!isChecked}
                                                            color="primary"
                                                            onChange={() => {
                                                                if (!isChecked) {
                                                                    featureList.splice(checkIndex, 1);
                                                                }
                                                                else {
                                                                    featureList.push(value_.spotFeatureId.toString());
                                                                }

                                                                this.setState({
                                                                    selectedFeature: featureList
                                                                }, () => {
                                                                    if (this.props.onChange) {
                                                                        this.props.onChange(this._hasChanges());
                                                                    }
                                                                });
                                                            }}
                                                        />
                                                    }
                                                    label={value_.featureName}
                                                />
                                            </div>
                                            );
                                        })
                                    }
                                </Col>
                            </Row>
                            <Row className="px-3">
                                <Col>
                                    <div className="zone-labeltext">Amenities</div>
                                </Col>
                            </Row>
                            <Row className="mt-3 mx-0">
                                <Col>
                                    {
                                        amenities.map((value_, index_) => {
                                            let featureList = this.state.selectedFeature;
                                            let checkIndex = featureList.indexOf(value_.spotFeatureId.toString());
                                            let isChecked = checkIndex === -1;

                                            return (<div key={index_}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={!isChecked}
                                                            color="primary"
                                                            onChange={() => {
                                                                if (!isChecked) {
                                                                    featureList.splice(checkIndex, 1);
                                                                }
                                                                else {
                                                                    if (value_.spotFeatureId == 6 ||
                                                                        value_.spotFeatureId == 7 ||
                                                                        value_.spotFeatureId == 8) {
                                                                        let secureIndex = featureList.indexOf("6");
                                                                        let guestIndex = featureList.indexOf("7");
                                                                        let privateIndex = featureList.indexOf("8");

                                                                        if (secureIndex != -1) {
                                                                            featureList.splice(secureIndex, 1);
                                                                        }

                                                                        if (guestIndex != -1) {
                                                                            featureList.splice(guestIndex, 1);
                                                                        }

                                                                        if (privateIndex != -1) {
                                                                            featureList.splice(privateIndex, 1);
                                                                        }
                                                                    }

                                                                    featureList.push(value_.spotFeatureId.toString());
                                                                }

                                                                this.setState({
                                                                    selectedFeature: featureList
                                                                }, () => {
                                                                    if (this.props.onChange) {
                                                                        this.props.onChange(this._hasChanges());
                                                                    }
                                                                });
                                                            }}
                                                        />
                                                    }
                                                    label={value_.featureName}
                                                />
                                            </div>
                                            );
                                        })
                                    }
                                </Col>
                            </Row>
                            {
                                this.renderOtherAminities()
                            }



                            <React.Fragment>
                                <Row className="px-3">
                                    <Col>
                                        <div className="zone-labeltext">When you arrive</div>
                                        <TextField
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            id="Whenyouarrrive"
                                            label="When you arrive"
                                            name="Whenyouarrrive"
                                            onChange={(e_) => {
                                                this.setState({
                                                    whenYouArrive: e_.target.value
                                                }, () => {
                                                    if (this.props.onChange) {
                                                        this.props.onChange(this._hasChanges());
                                                    }
                                                })
                                            }}
                                            value={this.state.whenYouArrive}
                                            autoComplete="off"
                                        />
                                    </Col>
                                </Row>
                                <Row className="px-3">
                                    <Col>
                                        <h4 className="mt-4">Schedule/Price</h4>
                                    </Col>
                                </Row>
                                <Row className="px-3">
                                    <Col>
                                        <MultiScheduleComponent
                                            schedules={this.state.schedules}
                                            limitation={this.state.scheduleLimitation}
                                            onChange={(schedule_) => {
                                                this.setState({
                                                    schedules: schedule_
                                                }, () => {
                                                    if (this.props.onChange) {
                                                        this.props.onChange(this._hasChanges());
                                                    }
                                                });
                                            }}
                                            defaultCollapsed={true}
                                        // priceDisabled={!this.state.permissionsList[0]}
                                        // hoursDisabled={!this.state.permissionsList[1]}
                                        // addDeleteDisabled={!this.state.permissionsList[2]}
                                        />
                                    </Col>
                                </Row>
                            </React.Fragment>
                            {
                                (GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) != -1 || GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) != -1) &&
                                <div style={{
                                    padding: '0px 10px'
                                }}>
                                    <Grid container>
                                        <Grid item>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    onChange={(e_) => {
                                                        let dailyTimeBlock_: IDailyTimeBlock[] = this.state.visitorTimeBlocks;

                                                        if (!e_.target.checked) {
                                                            dailyTimeBlock_.forEach(x => {
                                                                x.isActive = false;
                                                            });
                                                        }

                                                        this.setState({
                                                            visitorParkingEnabled: e_.target.checked,
                                                            visitorTimeBlocks: dailyTimeBlock_
                                                        }, () => {
                                                            if (this.props.onChange) {
                                                                this.props.onChange(this._hasChanges());
                                                            }
                                                        })
                                                    }}
                                                    checked={this.state.visitorParkingEnabled}
                                                    name="visitorParkingEnabled"
                                                    color="primary" />}
                                                label={"Visitor Parking"}
                                            />
                                        </Grid>
                                    </Grid>
                                    {
                                        this.state.visitorParkingEnabled && (
                                            <div className={'daily-hours-container ' + (this.checkDailyBookingOverlap(this.state.visitorTimeBlocks) ? 'overlapping-error' : '')}>
                                                {
                                                    this.state.visitorTimeBlocks.map((item, index) => (
                                                        <>
                                                            <Grid container key={index} className="daily-booking-container">
                                                                <Grid item xs style={{ paddingTop: '19px' }}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox
                                                                            onClick={() => {
                                                                                let dailyTimeBlock_: IDailyTimeBlock[] = this.state.visitorTimeBlocks;
                                                                                dailyTimeBlock_[index].isActive = !item.isActive;

                                                                                this.setState({
                                                                                    visitorTimeBlocks: dailyTimeBlock_
                                                                                }, () => {
                                                                                    if (this.props.onChange) {
                                                                                        this.props.onChange(this._hasChanges());
                                                                                    }
                                                                                })
                                                                            }}
                                                                            checked={item.isActive}
                                                                            color="primary" />}
                                                                        label={item.scheduleName}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container>
                                                                        <Grid item xs>
                                                                            <label className="label-range-marker">Start time</label>
                                                                        </Grid>
                                                                        <Grid item xs>
                                                                            <label className="label-range-marker">End time</label>
                                                                        </Grid>
                                                                        <Grid item xs>
                                                                            <label className="label-range-marker">Price</label>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container>
                                                                        <Grid item>
                                                                            <TimeRangeSelector
                                                                                selectedRange={{
                                                                                    start: item.originalStartTimeConverted,
                                                                                    end: item.originalEndTimeConverted,
                                                                                }}
                                                                                min={item.minTime}
                                                                                max={item.maxTime}
                                                                                onChange={(startTime_: number, endTime_: number) => {
                                                                                    let start_: number = startTime_;
                                                                                    let end_: number = endTime_;

                                                                                    if (startTime_ == endTime_) {
                                                                                        end_ += 60;
                                                                                    }

                                                                                    let dailyTimeBlock_: IDailyTimeBlock[] = this.state.visitorTimeBlocks;

                                                                                    dailyTimeBlock_[index].originalStartTimeConverted = start_;
                                                                                    dailyTimeBlock_[index].originalEndTimeConverted = end_;
                                                                                    dailyTimeBlock_[index].startTime = DateTimeHelper.minutesToTimeString(start_, false);
                                                                                    dailyTimeBlock_[index].endTime = DateTimeHelper.minutesToTimeString(end_, false);

                                                                                    this.setState({
                                                                                        visitorTimeBlocks: dailyTimeBlock_
                                                                                    }, () => {
                                                                                        if (this.props.onChange) {
                                                                                            this.props.onChange(this._hasChanges());
                                                                                        }
                                                                                    })

                                                                                }}
                                                                                disabled={!item.isActive}
                                                                                dailyBooking={true}
                                                                                ignoreMixMax={true}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <PriceRangeSelector
                                                                                selectedRange={{
                                                                                    start: item.bookingPrice,
                                                                                    end: 99.99
                                                                                }}
                                                                                min={0}
                                                                                max={99.99}
                                                                                singleSlider={true}
                                                                                onChange={(start_: number, end_: number) => {
                                                                                    let dailyTimeBlock_: IDailyTimeBlock[] = this.state.visitorTimeBlocks;
                                                                                    dailyTimeBlock_[index].bookingPrice = start_;
                                                                                    this.setState({
                                                                                        visitorTimeBlocks: dailyTimeBlock_
                                                                                    }, () => {
                                                                                        if (this.props.onChange) {
                                                                                            this.props.onChange(this._hasChanges());
                                                                                        }
                                                                                    });
                                                                                }}
                                                                                disabled={!item.isActive}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item xs style={{ textAlign: "right" }}>Maximum Time Limit (hours)</Grid>
                                                                <Grid item>
                                                                    <div style={{ padding: "5px 15px" }}>
                                                                        <input
                                                                            value={item.maximumTimeLimit}
                                                                            min={0}
                                                                            max={12}
                                                                            onChange={(e_: any) => {
                                                                                let dailyTimeBlock_: IDailyTimeBlock[] = this.state.visitorTimeBlocks;
                                                                                dailyTimeBlock_[index].maximumTimeLimit = Number(e_.target.value);
                                                                                this.setState({
                                                                                    visitorTimeBlocks: dailyTimeBlock_
                                                                                }, () => {
                                                                                    if (this.props.onChange) {
                                                                                        this.props.onChange(this._hasChanges());
                                                                                    }
                                                                                });
                                                                            }}
                                                                            disabled={!item.isActive}
                                                                            type="number"
                                                                            className="txt-range-value" style={{ width: "90px", textAlign: "center" }} />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    ))
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        (this.state.visitorParkingEnabled && this.checkDailyBookingOverlap(this.state.visitorTimeBlocks)) &&
                                        <div className="daily-hours-error-msg">There is a conflict between Daily and Evening time range. Please change.</div>
                                    }
                                </div>
                            }
                            {
                                (GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) != -1 || GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) != -1) &&
                                <div style={{
                                    padding: '0px 10px'
                                }}>
                                    <Grid container>
                                        <Grid item>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    onChange={(e_) => {
                                                        let dailyTimeBlock_: IDailyTimeBlock[] = this.state.dailyTimeBlocks;

                                                        if (!e_.target.checked) {
                                                            dailyTimeBlock_.forEach(x => {
                                                                x.isActive = false;
                                                            });
                                                        }

                                                        this.setState({
                                                            dailyBookingEnabled: e_.target.checked,
                                                            dailyTimeBlocks: dailyTimeBlock_
                                                        }, () => {
                                                            if (this.props.onChange) {
                                                                this.props.onChange(this._hasChanges());
                                                            }
                                                        })
                                                    }}
                                                    checked={this.state.dailyBookingEnabled}
                                                    name="dailyBookingEnabled"
                                                    color="primary" />}
                                                label={"Daily Booking"}
                                            />
                                        </Grid>
                                    </Grid>
                                    {
                                        this.state.dailyBookingEnabled && (
                                            <div className={'daily-hours-container ' + (this.checkDailyBookingOverlap(this.state.dailyTimeBlocks) ? 'overlapping-error' : '')}>
                                                {
                                                    this.state.dailyTimeBlocks.map((item, index) => (
                                                        <Grid container key={index} className="daily-booking-container">
                                                            <Grid item xs style={{ paddingTop: '19px' }}>
                                                                <FormControlLabel
                                                                    control={<Checkbox
                                                                        onClick={() => {
                                                                            let dailyTimeBlock_: IDailyTimeBlock[] = this.state.dailyTimeBlocks;
                                                                            dailyTimeBlock_[index].isActive = !item.isActive;

                                                                            this.setState({
                                                                                dailyTimeBlocks: dailyTimeBlock_
                                                                            }, () => {
                                                                                if (this.props.onChange) {
                                                                                    this.props.onChange(this._hasChanges());
                                                                                }
                                                                            })
                                                                        }}
                                                                        checked={item.isActive}
                                                                        color="primary" />}
                                                                    label={item.scheduleName}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container>
                                                                    <Grid item xs>
                                                                        <label className="label-range-marker">Start time</label>
                                                                    </Grid>
                                                                    <Grid item xs>
                                                                        <label className="label-range-marker">End time</label>
                                                                    </Grid>
                                                                    <Grid item xs>
                                                                        <label className="label-range-marker">Price</label>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container>
                                                                    <Grid item>
                                                                        <TimeRangeSelector
                                                                            selectedRange={{
                                                                                start: item.originalStartTimeConverted,
                                                                                end: item.originalEndTimeConverted,
                                                                            }}
                                                                            min={item.minTime}
                                                                            max={item.maxTime}
                                                                            onChange={(startTime_: number, endTime_: number) => {
                                                                                let start_: number = startTime_;
                                                                                let end_: number = endTime_;

                                                                                if (startTime_ == endTime_) {
                                                                                    end_ += 60;
                                                                                }

                                                                                let dailyTimeBlock_: IDailyTimeBlock[] = this.state.dailyTimeBlocks;

                                                                                dailyTimeBlock_[index].originalStartTimeConverted = start_;
                                                                                dailyTimeBlock_[index].originalEndTimeConverted = end_;
                                                                                dailyTimeBlock_[index].startTime = DateTimeHelper.minutesToTimeString(start_, false);
                                                                                dailyTimeBlock_[index].endTime = DateTimeHelper.minutesToTimeString(end_, false);

                                                                                this.setState({
                                                                                    dailyTimeBlocks: dailyTimeBlock_
                                                                                }, () => {
                                                                                    if (this.props.onChange) {
                                                                                        this.props.onChange(this._hasChanges());
                                                                                    }
                                                                                })

                                                                            }}
                                                                            disabled={!item.isActive}
                                                                            dailyBooking={true}
                                                                            ignoreMixMax={true}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <PriceRangeSelector
                                                                            selectedRange={{
                                                                                start: item.bookingPrice,
                                                                                end: 99.99
                                                                            }}
                                                                            min={0}
                                                                            max={99.99}
                                                                            singleSlider={true}
                                                                            onChange={(start_: number, end_: number) => {
                                                                                let dailyTimeBlock_: IDailyTimeBlock[] = this.state.dailyTimeBlocks;
                                                                                dailyTimeBlock_[index].bookingPrice = start_;
                                                                                this.setState({
                                                                                    dailyTimeBlocks: dailyTimeBlock_
                                                                                }, () => {
                                                                                    if (this.props.onChange) {
                                                                                        this.props.onChange(this._hasChanges());
                                                                                    }
                                                                                });
                                                                            }}
                                                                            disabled={!item.isActive}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        (this.state.dailyBookingEnabled && this.checkDailyBookingOverlap(this.state.dailyTimeBlocks)) &&
                                        <div className="daily-hours-error-msg">There is a conflict between Daily and Evening time range. Please change.</div>
                                    }
                                </div>
                            }

                            {
                                (GetUserDetailsService.getUserDetails().userRoleID.indexOf(1) != -1 || GetUserDetailsService.getUserDetails().userRoleID.indexOf(2) != -1) &&
                                <div style={{
                                    padding: '0px 10px'
                                }}>
                                    <Grid container>
                                        <Grid item>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    onChange={(e_) => {
                                                        let weeklyTimeBlocks_: IWeeklyTimeBlock[] = this.state.weeklyTimeBlocks;

                                                        if (!e_.target.checked) {
                                                            weeklyTimeBlocks_.forEach(x => {
                                                                x.isActive = false;
                                                            });
                                                        }

                                                        this.setState({
                                                            weeklyBookingEnabled: e_.target.checked,
                                                            weeklyTimeBlocks: weeklyTimeBlocks_
                                                        }, () => {
                                                            if (this.props.onChange) {
                                                                this.props.onChange(this._hasChanges());
                                                            }
                                                        });
                                                    }}
                                                    checked={this.state.weeklyBookingEnabled}
                                                    name="weeklyBookingEnabled"
                                                    color="primary" />}
                                                label={"Monthly Booking"}
                                            />
                                        </Grid>
                                    </Grid>
                                    {
                                        this.state.weeklyBookingEnabled && (
                                            <div className="daily-hours-container">
                                                {
                                                    this.state.weeklyTimeBlocks.map((item, index) => (
                                                        <Grid container key={index} className="daily-booking-container">
                                                            <Grid item xs style={{ paddingTop: '19px' }}>
                                                                <FormControlLabel
                                                                    control={<Checkbox
                                                                        onClick={() => {
                                                                            let weeklyTimeBlocks_: IWeeklyTimeBlock[] = this.state.weeklyTimeBlocks;
                                                                            weeklyTimeBlocks_[index].isActive = !item.isActive;

                                                                            this.setState({
                                                                                weeklyTimeBlocks: weeklyTimeBlocks_
                                                                            }, () => {
                                                                                if (this.props.onChange) {
                                                                                    this.props.onChange(this._hasChanges());
                                                                                }
                                                                            })
                                                                        }}
                                                                        checked={item.isActive}
                                                                        color="primary" />}
                                                                    label={item.scheduleName}
                                                                />
                                                                <div className="monthly-description"><label className="label-range-marker">{item.description}</label></div>
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container>
                                                                    <Grid item xs>
                                                                        <label className="label-range-marker">Price</label>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container>
                                                                    <Grid item>
                                                                        <PriceRangeSelector
                                                                            selectedRange={{
                                                                                start: item.bookingPrice,
                                                                                end: 10000
                                                                            }}
                                                                            min={0}
                                                                            max={10000}
                                                                            singleSlider={true}
                                                                            onChange={(start_: number, end_: number) => {
                                                                                let weeklyTimeBlocks_: IWeeklyTimeBlock[] = this.state.weeklyTimeBlocks;
                                                                                weeklyTimeBlocks_[index].bookingPrice = start_;
                                                                                this.setState({
                                                                                    weeklyTimeBlocks: weeklyTimeBlocks_
                                                                                }, () => {
                                                                                    if (this.props.onChange) {
                                                                                        this.props.onChange(this._hasChanges());
                                                                                    }
                                                                                });
                                                                            }}
                                                                            disableDecimal={true}
                                                                            disabled={!item.isActive}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            }
                        </React.Fragment>
                    }
                </div>
                <Row className="mt-3 mx-0">
                    <Col>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={this._saveZone}
                            disabled={this.state.loaderState}
                        >Save</Button>
                    </Col>
                    <Col>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={() => { this.props.onClose(false, []); }}
                        >Cancel</Button>
                    </Col>
                </Row>
            </div>
        );
    }

    private _hasChanges(): boolean {
        // Edit
        if (this.state.zone) {
            /*
            propertyGuid: this.state.selectedProperty?.propertyGuid,
            zoneName: this.state.zoneName,
            zoneDescription: this.state.zoneDescription,
            whenYouArrive: this.state.whenYouArrive,
            daily: this.state.dailyBookingEnabled,
            monthly: this.state.weeklyBookingEnabled,
            otherFeatures: this.state.otherFeatures,
            selectedFeatures: this.state.selectedFeature.map(x => { return Number(x) }),
            schedules: this.state.schedules,
            dailyRates: this.state.dailyTimeBlocks,
            weeklyRates: this.state.weeklyTimeBlocks
            */

            return this.state.zone.zoneName != this.state.zoneName ||
                this.state.zone.description != this.state.zoneDescription ||
                this.state.zone.whenYouArrive != this.state.whenYouArrive ||
                this._hasScheduleChange();
        }
        // Add
        else {
            return this.state.selectedProperty != null;
        }
    }

    private _hasScheduleChange(): boolean {
        if (this.state.zone) {
            // this._isFeatureChanged() ||

            return this.state.zone.daily != this.state.dailyBookingEnabled ||
                this.state.zone.monthly != this.state.weeklyBookingEnabled ||
                JSON.stringify(this.state.zone.selectedFeatures.map(x => {
                    return Number(x);
                })) != JSON.stringify(this.state.selectedFeature.map(x => {
                    return Number(x);
                })) ||
                JSON.stringify(this.state.originalDailyTimeBlocks) != JSON.stringify(this.state.dailyTimeBlocks) ||
                JSON.stringify(this.state.originalWeeklyTimeBlocks) != JSON.stringify(this.state.weeklyTimeBlocks) ||
                JSON.stringify(this.state.schedulesExisting?.map(s => this._scheduleJsonText(s))) != JSON.stringify(this.state.schedules?.map(s => this._scheduleJsonText(s)));
        }
        return false;
    }

    private _isFeatureChanged() {
        let hasChangesInFeature = false;
        let featureList = this.state.originalSelectedFeature;
        let newFeatureList = this.state.selectedFeature;

        let secureIndex = featureList.indexOf("6");
        let guestIndex = featureList.indexOf("7");
        let privateIndex = featureList.indexOf("8");

        if ((secureIndex != -1 && newFeatureList.indexOf("6") == -1) ||
            (guestIndex != -1 && newFeatureList.indexOf("7") == -1) ||
            (privateIndex != -1 && newFeatureList.indexOf("8") == -1)) {
            hasChangesInFeature = true;
        }

        console.log(hasChangesInFeature, 'haschanges')

        return hasChangesInFeature;
    }

    private _scheduleJsonText(schedule_: ISingleScheduleData): string {
        let asString = `(${schedule_.selectedDays?.sort().join(",")})-${schedule_.selectedTimeRange?.start}-${schedule_.selectedTimeRange?.end}=${schedule_.selectedPriceRange?.start}`;
        return asString ? asString : "";
    }

    private renderOtherAminities = () => {
        let additionalFeatures: IAdditionalFeature[] = [];
        let featuresString = this.state.otherFeatures;
        let updateAmenities = () => {
            featuresString = JSON.stringify(additionalFeatures);
            this.setState({
                otherFeatures: featuresString
            }, () => {
                if (this.props.onChange) {
                    this.props.onChange(this._hasChanges());
                }
            });
        };

        if (featuresString) {
            try {
                additionalFeatures = JSON.parse(featuresString);
            }
            catch (ex) {
                additionalFeatures = featuresString.split(",")
                    .filter(f => f != "")
                    .map((f, i) => ({
                        i: StringHelper.getUniqueChar(additionalFeatures.map(a => a.i)) + Math.random().toString(),
                        n: f,
                        a: 1
                    } as IAdditionalFeature));
            }
        }

        let checkboxes = additionalFeatures.map((f_, i) => {
            if (f_.e == 1) {
                return (
                    <tr key={f_.i}>
                        <td>
                            <Checkbox value={f_.n}
                                name={"OtherFeature_" + f_.i}
                                checked={f_.a == 1}
                                onChange={() => {
                                    if (f_.a == 1) {
                                        f_.a = 0;
                                    }
                                    else {
                                        f_.a = 1;
                                    }
                                    updateAmenities();
                                }}
                                color="primary" />
                            <TextField
                                value={f_.n}
                                variant="outlined"
                                size="small"
                                onChange={(e) => {
                                    f_.n = e.target.value;
                                    updateAmenities();
                                }}
                                autoComplete="off"
                            />
                        </td>
                        <td>
                            <IconButton
                                onClick={() => {
                                    let isNotEmptyEmpty = false;
                                    let isDuplicated = false;

                                    for (let a = 0; a < additionalFeatures.length; a++) {
                                        let feature_: string = additionalFeatures[a].n.trim();
                                        let key_: string = additionalFeatures[a].i.trim();

                                        if (!isDuplicated) {
                                            for (let am = 0; am < this.state.featureList.length; am++) {
                                                if (feature_.toLocaleLowerCase() == this.state.featureList[am].featureName.toLocaleLowerCase()) {
                                                    isDuplicated = true;
                                                    break;
                                                }
                                            }

                                            for (let am = 0; am < additionalFeatures.length; am++) {
                                                let featureName_: string = additionalFeatures[am].n.trim();
                                                let featureKey_: string = additionalFeatures[am].i.trim();
                                                if (feature_.toLocaleLowerCase() == featureName_.toLocaleLowerCase() && featureKey_ != key_) {
                                                    isDuplicated = true;
                                                    break;
                                                }
                                            }
                                        }

                                        if (feature_ == "" || feature_ == null) {
                                            isNotEmptyEmpty = true;
                                            break;
                                        }
                                    }

                                    if (isNotEmptyEmpty) {
                                        ShowAlert('Error!', 'Please enter the valid amenity.', 'error');
                                        return false;
                                    } else if (isDuplicated) {
                                        ShowAlert('Error!', 'Amenity already exists.', 'error');
                                        return false;
                                    } else {
                                        f_.e = 0;
                                        updateAmenities();
                                    }
                                }}>
                                <CheckIcon />
                            </IconButton>
                        </td>
                    </tr>);
            }
            else {
                return (
                    <tr key={f_.i}>
                        <td>
                            <FormControlLabel
                                className="property-label ml-0"
                                control={<Checkbox value={f_.n}
                                    name={"OtherFeature_" + f_.i}
                                    checked={f_.a == 1}
                                    onChange={() => {
                                        if (f_.a == 1) {
                                            f_.a = 0;
                                        }
                                        else {
                                            f_.a = 1;
                                        }
                                        updateAmenities();
                                    }}
                                    color="primary" />}
                                label={f_.n}
                            />
                        </td>
                        <td>
                            <IconButton
                                onClick={() => {
                                    f_.e = 1;
                                    updateAmenities();
                                }}>
                                <EditIcon />
                            </IconButton>
                        </td>
                        <td>
                            <IconButton
                                onClick={() => {
                                    additionalFeatures.splice(i, 1);
                                    updateAmenities();
                                }}>
                                <ClearIcon />
                            </IconButton>
                        </td>
                    </tr>);
            }
        });

        return (
            <React.Fragment>
                <table>
                    {checkboxes}
                </table>
                <span className="zone-add-button" onClick={() => {
                    let isNotEmptyEmpty = false;
                    let isDuplicated = false;

                    for (let a = 0; a < additionalFeatures.length; a++) {
                        let feature_: string = additionalFeatures[a].n.trim();
                        let key_: string = additionalFeatures[a].i.trim();

                        if (!isDuplicated) {
                            for (let am = 0; am < this.state.featureList.length; am++) {
                                if (feature_.toLocaleLowerCase() == this.state.featureList[am].featureName.toLocaleLowerCase()) {
                                    isDuplicated = true;
                                    break;
                                }
                            }

                            for (let am = 0; am < additionalFeatures.length; am++) {
                                let featureName_: string = additionalFeatures[am].n.trim();
                                let featureKey_: string = additionalFeatures[am].i.trim();
                                if (feature_.toLocaleLowerCase() == featureName_.toLocaleLowerCase() && featureKey_ != key_) {
                                    isDuplicated = true;
                                    break;
                                }
                            }
                        }

                        if (feature_ == "" || feature_ == null) {
                            isNotEmptyEmpty = true;
                            break;
                        }
                    }

                    if (isNotEmptyEmpty) {
                        ShowAlert('Error!', 'Please fill the blank amenity.', 'error');
                    } else if (isDuplicated) {
                        ShowAlert('Error!', 'Please remove duplicate amenity.', 'error');
                    } else if (!isNotEmptyEmpty) {
                        additionalFeatures.push({
                            i: StringHelper.getUniqueChar(additionalFeatures.map(a => a.i)) + Math.random().toString(),
                            n: "",
                            a: 1,
                            e: 1
                        });
                        updateAmenities();
                    }

                }}>+ Add Amenity</span>
            </React.Fragment>
        );
    }

    private checkDailyBookingOverlap = (dailyTimeBlock_: IDailyTimeBlock[]) => {
        let activeDailyBooking = dailyTimeBlock_.filter(x => {
            return x.isActive == true;
        });

        let sanitizeSchedule = (schedule_: INumberRange, isIncreaseByDay_: boolean): INumberRange => {
            if (schedule_.start != undefined && schedule_.end != undefined && schedule_.start > schedule_.end) {
                if (isIncreaseByDay_) {
                    return {
                        start: schedule_.start,
                        end: schedule_.end + this._fullDay
                    };
                }
                else {
                    return {
                        start: schedule_.start - this._fullDay,
                        end: schedule_.end
                    };
                }
            }
            return schedule_;
        }

        if (activeDailyBooking.length > 1) {
            let schedule1: INumberRange = {
                start: dailyTimeBlock_[0].originalStartTimeConverted,
                end: dailyTimeBlock_[0].originalEndTimeConverted
            }

            let schedule2: INumberRange = {
                start: dailyTimeBlock_[1].originalStartTimeConverted,
                end: dailyTimeBlock_[1].originalEndTimeConverted
            }

            let schedule1Upper = sanitizeSchedule(schedule1, true);
            let schedule2Upper = sanitizeSchedule(schedule2, true);
            let schedule1Lower = sanitizeSchedule(schedule1, false);
            let schedule2Lower = sanitizeSchedule(schedule2, false);

            return NumberHelper.doNumberRangesConflict(schedule1Upper, schedule2Upper) ||
                NumberHelper.doNumberRangesConflict(schedule1Lower, schedule2Lower) ||
                NumberHelper.doNumberRangesConflict(schedule1Lower, schedule2Upper) ||
                NumberHelper.doNumberRangesConflict(schedule1Upper, schedule2Lower);
        }

        return false;
    }

    private getTimeBlock() {
        let apiCount = 3;
        let newVisitorTimeBlock_: IDailyTimeBlock[] = [];
        let newDailyTimeBlock_: IDailyTimeBlock[] = [];
        let newWeeklyTimeBlock_: IWeeklyTimeBlock[] = [];

        let onApiResponse = () => {
            if (--apiCount == 0) {
                let updatedState: IZoneAddEditViewState = {
                    visitorTimeBlocks: newVisitorTimeBlock_,
                    dailyTimeBlocks: newDailyTimeBlock_,
                    weeklyTimeBlocks: newWeeklyTimeBlock_
                } as any;

                this.setState(updatedState);
            }
        }

        let zoneGuid: string = this.props.zone != null ? this.props.zone.zoneGuid : 'blank_';

        this._zoneService.getVisitorTimeBlockWithPrice(zoneGuid).then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    newVisitorTimeBlock_ = this.validateDailyTime(resp);
                    onApiResponse();
                })
            }
        });

        this._zoneService.getDailyTimeBlockWithPrice(zoneGuid).then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    newDailyTimeBlock_ = this.validateDailyTime(resp);

                    /*newDailyTimeBlock_.forEach((item, index) => {
                        let startTime: number = DateTimeHelper.timeStringToMinutes(item.startTime);
                        let endTime: number = DateTimeHelper.timeStringToMinutes(item.endTime);
                        let minTime_: number = DateTimeHelper.timeStringToMinutes(item.originalStartTime);
                        let maxTime_: number = DateTimeHelper.timeStringToMinutes(item.originalEndTime);

                        newDailyTimeBlock_[index].originalStartTimeConverted = startTime < this._minTime ? startTime + this._fullDay : startTime;
                        newDailyTimeBlock_[index].originalEndTimeConverted = (endTime < startTime || endTime <= this._minTime) ? endTime + this._fullDay : endTime;

                        newDailyTimeBlock_[index].minTime = minTime_ < this._minTime ? minTime_ + this._fullDay : minTime_;
                        newDailyTimeBlock_[index].maxTime = (maxTime_ < minTime_ || maxTime_ <= this._minTime) ? maxTime_ + this._fullDay : maxTime_;

                        newDailyTimeBlock_[index].startTime = DateTimeHelper.minutesToTimeString(startTime, false);
                        newDailyTimeBlock_[index].endTime = DateTimeHelper.minutesToTimeString(endTime, false);
                    });*/

                    onApiResponse();
                })
            }
        });

        this._zoneService.getWeeklyTimeBlockWithPrice(zoneGuid).then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    newWeeklyTimeBlock_ = resp;
                    onApiResponse();
                });
            }
        });
    }

    private validateDailyTime = (dailyTimeBlock_: IDailyTimeBlock[]) => {
        let newDailyTimeBlock_: IDailyTimeBlock[] = dailyTimeBlock_;

        if (newDailyTimeBlock_.length) {
            newDailyTimeBlock_.forEach((item, index) => {
                let startTime: number = DateTimeHelper.timeStringToMinutes(item.startTime);
                let endTime: number = DateTimeHelper.timeStringToMinutes(item.endTime);

                newDailyTimeBlock_[index].originalStartTimeConverted = startTime < this._minTime ? startTime + this._fullDay : startTime;
                newDailyTimeBlock_[index].originalEndTimeConverted = (endTime < startTime || endTime <= this._minTime) ? endTime + this._fullDay : endTime;

                newDailyTimeBlock_[index].minTime = this._minTime;
                newDailyTimeBlock_[index].maxTime = this._fullDay + this._fullDay;

                newDailyTimeBlock_[index].startTime = DateTimeHelper.minutesToTimeString(startTime, false);
                newDailyTimeBlock_[index].endTime = DateTimeHelper.minutesToTimeString(endTime, false);

                newDailyTimeBlock_[index].maximumTimeLimit = item.maximumTimeLimit;
            });
        }

        return newDailyTimeBlock_;
    }

    private _saveZone = (): void => {
        let zoneData = {
            propertyGuid: this.state.selectedProperty?.propertyGuid,
            zoneName: this.state.zoneName,
            zoneDescription: this.state.zoneDescription,
            whenYouArrive: this.state.whenYouArrive,
            visitor: this.state.visitorParkingEnabled,
            daily: this.state.dailyBookingEnabled,
            monthly: this.state.weeklyBookingEnabled,
            otherFeatures: this.state.otherFeatures,
            selectedFeatures: this.state.selectedFeature.map(x => { return Number(x) }),
            schedules: this.state.schedules,
            visitorRates: this.state.visitorTimeBlocks,
            dailyRates: this.state.dailyTimeBlocks,
            weeklyRates: this.state.weeklyTimeBlocks
        } as any;



        if (this.props.zone) {
            zoneData.spotGuid = this.props.zone.spotGuids;
        }

        if (this.props.onSave && (this.props.property)) {
            this.props.onSave(zoneData, this.state.selectedProperty);
        }
        else {
            if (!this.state.selectedProperty || !this.state.selectedProperty.propertyGuid) {
                ShowAlert('', 'Please select a property', 'error');
                return;
            }

            if (this.state.schedules) {
                let scheduleConflicts = false;
                let isNotValidSchedule = false;

                for (let schedule of this.state.schedules) {
                    if (schedule._hasConflict) {
                        scheduleConflicts = true;
                    }

                    if (schedule.selectedDays?.length == 0) {
                        isNotValidSchedule = true;
                    }
                }
                if (scheduleConflicts) {
                    ShowAlert('', 'Please resolve conflicts in schedule', 'error');
                    return;
                }

                if (isNotValidSchedule) {
                    ShowAlert('', 'Please ensure all the schedules have valid days selected.', 'error');
                    return;
                }
            }

            if ((this.state.visitorParkingEnabled && this.checkDailyBookingOverlap(this.state.visitorTimeBlocks)) || (this.state.dailyBookingEnabled && this.checkDailyBookingOverlap(this.state.dailyTimeBlocks))) {
                ShowAlert('', 'Please resolve conflicts between Daily and Evening time ranges', 'error');
                return;
            }

            if (this.props.zone == undefined) {
                if (!this.state.zoneName.trim().length) {
                    ShowAlert('', 'Please enter the zone name', 'error');
                    return;
                }

                this.setState({
                    loaderState: true
                }, () => {
                    this._zoneService.addZone(zoneData).then(r => {
                        if (r.ok) {
                            r.json().then(guid => {
                                if (guid) {
                                    ShowAlert('', 'Zone added successfully', 'success').then(r => {
                                        this._zoneService.getZoneById(guid).then(res => {
                                            if (res.ok) {
                                                res.json().then(response => {
                                                    response.bookingType = this.getBookingType(this.state.dailyBookingEnabled, this.state.weeklyBookingEnabled)
                                                    response.numberOfSpots = response.numberOfSpots.toString();
                                                    let newZone: any[] = [response];
                                                    this.props.onClose(true, newZone);
                                                }).catch(error => {
                                                    this.defaultSuccess(guid);
                                                });
                                            } else {
                                                this.defaultSuccess(guid);
                                            }
                                        }).catch(error => {
                                            this.defaultSuccess(guid);
                                        });
                                    });
                                } else {
                                    ShowAlert('', 'Unexpected error occured while adding spots, please try again', 'error');
                                }
                            });
                        }
                        this.setState({
                            loaderState: false
                        });
                    });
                });

            }
            else {
                zoneData.zoneGuid = this.props.zone.zoneGuid;

                this.props.zone.otherFeatures = this.state.otherFeatures;

                if (this.state.schedules) {
                    this.props.zone.schedules = ObjectHelper.deepClone(this.state.schedules);
                }

                if (this._hasScheduleChange()) {
                    this.checkOverLapping(zoneData);
                } else {
                    this.updateSpot(zoneData);
                }
            }
        }
    }

    private defaultSuccess(guid: string) {
        let startingPrice: number[] = [];
        let endingPrice: number[] = [];

        this.state.schedules?.filter(x => {
            if (x.selectedPriceRange?.start) {
                startingPrice.push(x.selectedPriceRange?.start);
            }

            if (x.selectedPriceRange?.end) {
                endingPrice.push(x.selectedPriceRange?.end);
            }
        });

        if (JSON.stringify(startingPrice) !== JSON.stringify(endingPrice))
            endingPrice = startingPrice;

        let newZone: IZone[] = [];
        if (this.state.selectedProperty && this.state.schedules) {
            const startPrice = startingPrice.length > 0 ? startingPrice : [0];
            const endPrice = endingPrice.length > 0 ? endingPrice : [0];
            newZone.push({
                zoneGuid: guid,
                propertyGuid: this.state.selectedProperty.propertyGuid,
                propertyName: this.state.selectedProperty.propertyName,
                description: this.state.zoneDescription,
                whenYouArrive: this.state.whenYouArrive,
                zoneName: this.state.zoneName,
                daily: this.state.dailyBookingEnabled,
                monthly: this.state.weeklyBookingEnabled,
                bookingType: this.getBookingType(this.state.dailyBookingEnabled, this.state.weeklyBookingEnabled),
                priceRange: `$${Math.min(...startPrice).toFixed(2)} - $${Math.max(...endPrice).toFixed(2)}`,
                numberOfSpots: "0",
                schedules: ObjectHelper.deepClone(this.state.schedules),
                schedulesExisting: ObjectHelper.deepClone(this.state.schedules),
                selectedFeatures: this.state.selectedFeature,
                dailyTimeBlocks: ObjectHelper.deepClone(this.state.dailyTimeBlocks),
                weeklyTimeBlocks: ObjectHelper.deepClone(this.state.weeklyTimeBlocks)
            } as any);
        }

        this.props.onClose(true, newZone);
        return;
    }

    private async checkOverLapping(zoneData: any) {
        let overlpappingReservations: IReservationChunk[] = [];

        var offDaysReservations = await this.checkOverLappingService(zoneData, "offDays");
        overlpappingReservations = [...overlpappingReservations, ...offDaysReservations];
        console.log("1", overlpappingReservations);

        var startOverlpappingReservations = await this.checkOverLappingService(zoneData, "start");
        overlpappingReservations = [...overlpappingReservations, ...startOverlpappingReservations];
        console.log("2", overlpappingReservations);

        var endOverlpappingReservations = await this.checkOverLappingService(zoneData, "end");
        overlpappingReservations = [...overlpappingReservations, ...endOverlpappingReservations];
        console.log("3", overlpappingReservations);

        if (overlpappingReservations.length > 0) {
            var bookedSpots: string[] = [];
            var reservationGuid: string[] = [];
            var friendlyReservationIds: string[] = [];

            overlpappingReservations.forEach(x => {
                if (reservationGuid.indexOf(x.reservationGuid) == -1) {
                    reservationGuid.push(x.reservationGuid);
                }

                if (bookedSpots.indexOf(x.friendlySpotId) == -1) {
                    bookedSpots.push(x.friendlySpotId);
                }

                if (friendlyReservationIds.indexOf(x.friendlyReservationId) == -1) {
                    friendlyReservationIds.push(x.friendlyReservationId);
                }
            });

            let cancelBookingOption: any = {
                reservationGuids: reservationGuid,
                refundAction: true
            }

            ShowAlertwithOptions(
                "Booking Cancellation",
                `<div>
                    <div><b>${bookedSpots.sort().toString()}</b> have active booking(s) that are conflicting.</div><div>Updating this spot will cancel the following booking(s):</div>
                    <div class="bookings-overlap">
                        ${friendlyReservationIds.sort().toString()}
                    </div>
                    <div>Are you sure you want to proceed?</div>
                </div>`,
                "warning",
                "Yes, cancel immediately",
                null,
                "No, don't cancel")
                .then(agreed_ => {
                    if (agreed_.option1 == true) {
                        this._spotService.AdminCancelOverlpappingReservation(cancelBookingOption)
                            .then(data => {
                                if (data.ok) {
                                    data.json().then((cancellationResponse_: { cancelled: string[], issueWithCancellation: string[], issueWithRefund: string[], manualRefundBookings: string[] }) => {
                                        if (cancellationResponse_.manualRefundBookings && cancellationResponse_.manualRefundBookings.length) {
                                            ShowAlert("Unable to Refund", `Unable to refund the following bookings, request admin to refund offline: ${cancellationResponse_.manualRefundBookings.join(", ")}`, "warning")
                                                .then(() => {
                                                    this.updateSpot(zoneData);
                                                });
                                        } else {
                                            this.updateSpot(zoneData);
                                        }
                                    });
                                }
                            });
                    }
                })
        } else {
            this.updateSpot(zoneData);
        }
    }

    private async checkOverLappingService(zoneData: any, type: string): Promise<IReservationChunk[]> {
        var days = [1, 2, 3, 4, 5, 6, 7];
        let scheduledDays: number[] = [];
        let schedules_: ISingleScheduleData | null = null;

        if (this.state.schedules) {
            var schedules = ObjectHelper.deepClone(this.state.schedules);
            if (schedules.length) {
                schedules_ = schedules[0];
                if (schedules_.selectedDays) {
                    scheduledDays = schedules_.selectedDays;
                }
            }
        }

        var offDays = days.filter(x => {
            return scheduledDays.indexOf(x) == -1;
        });

        // days should start from 0 to validate in server
        let updatedDays: number[] = [];

        if (type == "offDays") {
            updatedDays = offDays.map(x => {
                return (x - 1);
            });
        } else {
            updatedDays = days.map(x => {
                return (x - 1);
            });
        }

        let metadata: ISpotBlockMetadata = {
            r: 1,
            w: updatedDays
        }

        let spotBlockModel: SpotBlock = {
            metadata: JSON.stringify(metadata),
            repeatType: 2,
            startDate: this.convertIntoUtc(new Date(new Date().setHours(0, 0, 0, 0))),
            spotGuids: zoneData.spotGuid,
            startTime: "",
            endTime: ""
        }

        if (type == "start" && schedules_ && schedules_.selectedTimeRange) {
            spotBlockModel.startTime = "12:00 AM";
            spotBlockModel.endTime = DateTimeHelper.minutesToTimeString(schedules_.selectedTimeRange.start, false);

            if (spotBlockModel.startTime == spotBlockModel.endTime) {
                return [];
            }
        }

        if (type == "end" && schedules_ && schedules_.selectedTimeRange) {
            spotBlockModel.startTime = DateTimeHelper.minutesToTimeString(schedules_.selectedTimeRange.end, false);
            spotBlockModel.endTime = "11:59 PM";

            if (spotBlockModel.startTime == spotBlockModel.endTime) {
                return [];
            }
        }

        if (metadata.w && metadata.w.length == 0) {
            return [];
        }

        if (type == "offDays" && schedules_ && schedules_.selectedTimeRange) {
            spotBlockModel.startTime = DateTimeHelper.minutesToTimeString(schedules_.selectedTimeRange?.start, false);
            spotBlockModel.endTime = DateTimeHelper.minutesToTimeString(schedules_.selectedTimeRange?.end, false);
        }

        var reponse = await this._spotService.CheckOverLapping(spotBlockModel);
        return await reponse.json();
    }

    private getBookingType(daily: boolean, monthly: boolean) {
        let bookingType: string[] = [];
        if (daily) {
            bookingType.push("Daily");
        }

        if (monthly) {
            bookingType.push("Monthly");
        }

        if (bookingType.length == 0) {
            bookingType.push("Hourly");
        }

        return bookingType.toString().replaceAll(',', ', ');
    }

    private updateSpot(zoneData: any) {
        this.setState({
            loaderState: true
        }, () => {
            this._zoneService.updateZone(zoneData).then(r => {
                if (r.ok) {
                    ShowAlert('', 'Zone updated successfully', 'success').then(y => {
                        r.json().then(response => {
                            this.props.onClose(true, [], response);
                        });
                    });
                }
                this.setState({
                    loaderState: false
                });
            });
        });

    }

    private convertIntoUtc(date: Date) {
        let x: Date = new Date(date);
        let hoursDiff = x.getHours() - x.getTimezoneOffset() / 60;
        let minutesDiff = (x.getHours() - x.getTimezoneOffset()) % 60;
        x.setHours(hoursDiff);
        x.setMinutes(minutesDiff);
        return new Date(x);
    }

}

interface IZoneAddEditViewProps {
    zone?: IZone;
    className?: string;
    property?: IPropertyDetails;
    customTitle?: string;
    spotNames?: string[];
    existingSpotNames?: string[];
    schedules?: ISingleScheduleData[];
    onClose: (requiresUpdate_: boolean, newSpots_: IZone[], updateZone?: any) => void;
    onSave?: (data_: any, propertyDetails_: IPropertyBasics | null) => void;
    onSpotNamesChange?: (spotNames_: string[]) => void;
    onChange?: (hasUnsavedChanges_: boolean) => void;
}

interface IZoneAddEditViewState {
    zone?: IZone;
    isReady: boolean;
    properties: IPropertyBasics[];
    featureList: ISpotFeature[];
    selectedFeature: string[];
    schedules?: ISingleScheduleData[];
    schedulesExisting?: ISingleScheduleData[];
    selectedProperty: IPropertyBasics | null;
    otherFeatures: string;
    dailyBookingEnabled: boolean;
    weeklyBookingEnabled: boolean;
    visitorParkingEnabled: boolean;

    scheduleLimitation: IScheduleLimitation;
    propertyAdditionalFeatures: IAdditionalFeature[];
    propertyAmenities: number[];
    permissionsList: boolean[];

    visitorTimeBlocks: IDailyTimeBlock[];
    dailyTimeBlocks: IDailyTimeBlock[];
    weeklyTimeBlocks: IWeeklyTimeBlock[];

    originalVisitorTimeBlocks: IDailyTimeBlock[];
    originalDailyTimeBlocks: IDailyTimeBlock[];
    originalWeeklyTimeBlocks: IWeeklyTimeBlock[];
    originalSelectedFeature: string[];

    loaderState: boolean;
    zoneName: string;
    zoneDescription: string;
    whenYouArrive: string;
    selectFeature: string;
}