import React, { ChangeEvent } from "react";
import { FormControl, InputLabel } from "@material-ui/core";
import "./NumberTimeRangeSelector.css";
import { Row, Col } from "reactstrap";
import { CustomSlider } from "./BasicComponents/CustomSlider";

export default class NumberRangeSelector<P extends INumberRangeSelectorProps, S extends INumberRangeSelectorState> extends React.Component<INumberRangeSelectorProps, INumberRangeSelectorState> {
    protected _label: string = "Value";
    protected _txtMask: RegExp = /^[0-9]*([.]){0,1}[0-9]{0,2}$/; // 2 decimals by default
    protected _txtStart?: HTMLInputElement;
    protected _txtEnd?: HTMLInputElement;
    protected _numberSize: number = 0;
    protected _decimalSize: number = 0;
    protected _decimalDotSize: number = 0;
    protected _defaultMin = 0.00;
    protected _defaultMax = 50;
    protected _defaultStepsize = 1;
    protected _defaultSelection = [4, 10];
    protected _totalMinutesInADay = 1440;
    protected _endOfTheDay = 359;

    constructor(props_: P) {
        super(props_);
    }

    protected _init(): void {
        let maxValue = this.props.max ? this.props.max : this._defaultMax;
        let digits = maxValue.toString().length;
        let stepSize = this.props.stepSize ? this.props.stepSize : this._defaultStepsize;
        let decimalIndex = stepSize.toString().indexOf('.');
        this._numberSize = digits;

        if (decimalIndex > -1) {
            this._decimalDotSize = 1;
            this._decimalSize = this.props.disableDecimal ? 0 : stepSize.toString().length - decimalIndex;
            this._txtMask = this.props.disableDecimal ? /^[$]{0,1}[0-9]*$/i : this._txtMask;
        }

        this.state = {
            min: this.props.min ? this.props.min : this._defaultMin,
            max: maxValue,
            range: this.props.selectedRange ? [this.props.selectedRange.start, this.props.selectedRange.end] : this._defaultSelection,
            stepSize: stepSize
        };
    }

    componentDidMount() {
        let state = this.state;
        let changed = false;

        if (this.state.range[0] < state.min) {
            this.state.range[0] = state.min;
            changed = true;
        }
        if (this.state.range[1] < state.min) {
            this.state.range[1] = state.min;
            changed = true;
        }
        if (this.state.range[0] > state.max) {
            this.state.range[0] = state.max;
            changed = true;
        }
        if (this.state.range[1] > state.max) {
            this.state.range[1] = state.max;
            changed = true;
        }

        if (changed) {
            this.setState(state, () => {
                this._onSliderChage(this.state.range, 0);
            });
        }
    }

    componentDidUpdate(oldProps_: P) {
        let updatedState: S = {} as any;
        let changed = false;

        if (oldProps_.selectedRange != this.props.selectedRange && this.props.selectedRange) {
            if (this.state.range[0] != this.props.selectedRange.start || this.state.range[1] != this.props.selectedRange.end) {
                updatedState.range = [this.props.selectedRange.start, this.props.selectedRange.end];
                changed = true;
            }
        }
        if (oldProps_.min != this.props.min) {
            updatedState.min = this.props.min != undefined ? this.props.min : this._defaultMin;
            if (this.state.range[0] < updatedState.min) {
                this.state.range[0] = updatedState.min;
            }
            if (this.state.range[1] < updatedState.min) {
                this.state.range[1] = updatedState.min;
            }
            changed = true;
        }
        if (oldProps_.max != this.props.max) {
            updatedState.max = this.props.max != undefined ? this.props.max : this._defaultMax;
            if (this.state.range[0] > updatedState.max) {
                this.state.range[0] = updatedState.max;
            }
            if (this.state.range[1] > updatedState.max) {
                this.state.range[1] = updatedState.max;
            }
            changed = true;
        }

        if (changed) {
            this.setState(updatedState, () => {
                this._onSliderChage(this.state.range, 0);
            });
        }
    }

    render() {
        if (this._txtStart) {
            this._txtStart.value = this._makeText(this.state.range[0]);
        }
        if (this._txtEnd) {
            this._txtEnd.value = this._makeText(this.state.range[1]);
        }

        let rangeSlider = this.props.singleSlider !== true;

        return (
            <FormControl className="number-range-selector">
                {
                    this.props.isPropertyScreen ? <h1 className="list-header list-header-time">{this._label}</h1> : <InputLabel shrink={true}>{this._label}</InputLabel>
                }

                <div className="m-4 mt-4">
                    <Row className="m-0">
                        <Col className="p-0 text-left">
                            <label className="label-range-marker">{this._makeText(this.state.min)}</label>
                        </Col>
                        <Col className="p-0 text-right">
                            <label className="label-range-marker">{this._makeText(this.state.max)}</label>
                        </Col>
                    </Row>
                    <CustomSlider
                        disabled={this.props.disabled}
                        className="range-selector"
                        min={this.state.min}
                        max={this.state.max}
                        track={rangeSlider ? "normal" : false}
                        value={rangeSlider ? this.state.range : this.state.range[0]}
                        aria-labelledby="range-slider"
                        step={this.state.stepSize}
                        valueLabelDisplay="off"
                        onChange={(e_: any, v_: any) => {
                            if (!rangeSlider) {
                                v_ = [v_, v_];
                            }
                            this._onSliderChage(v_, 0);
                        }}
                    />
                    <Row className="m-0">
                        <Col>
                            <input
                                type="text"
                                className="txt-range-value"
                                ref={i => {
                                    if (i && !this.state.isStartTextRefMapped) {
                                        this.setState({ isStartTextRefMapped: true });
                                        this._txtStart = i as HTMLInputElement;
                                    }
                                }}
                                onChange={(e) => {
                                    this._onTextChange(0, this._txtStart, e);
                                }}
                                onBlur={() => {
                                    this._onBlur(0, this._txtStart);
                                }}
                                onKeyPress={(e) => {
                                    const charCode = (e.which) ? e.which : e.keyCode;

                                    if (charCode == 46 && this.props.disableDecimal) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        return false;
                                    }
                                    return true;
                                }}
                                disabled={this.props.disabled}
                            />
                        </Col>
                        <Col className="text-right">
                            {
                                rangeSlider &&
                                <input
                                    type="text"
                                    className="txt-range-value"
                                    ref={i => {
                                        if (i && !this.state.isEndTextRefMapped) {
                                            this.setState({ isEndTextRefMapped: true });
                                            this._txtEnd = i as HTMLInputElement;
                                        }
                                    }}
                                    onChange={(e) => {
                                        this._onTextChange(1, this._txtEnd, e);
                                    }}
                                    onBlur={() => {
                                        this._onBlur(1, this._txtEnd);
                                    }}
                                    disabled={this.props.disabled}
                                />
                            }
                        </Col>
                    </Row>
                </div>
            </FormControl>
        );
    }

    protected _onSliderChage(v_: number[], movingIndex_: number): void {
        if (this.props.ignoreMixMax == null) {
            switch (movingIndex_) {
                case 0:
                    if (v_[0] > v_[1]) {
                        v_[1] = v_[0];
                    }
                    break;
                case 1:
                    if (v_[1] < v_[0]) {
                        v_[0] = v_[1];
                    }
                    break;
            }
        }

        this.setState({ range: v_ }, () => {
            if (this.props.onChange) {
                this.props.onChange(v_[0], v_[1]);
            }
        });
    }

    protected _makeText(value_: number): string {
        return `${value_.toFixed(this._decimalSize)}`;
    }

    protected _readText(textBox_: HTMLInputElement): number {
        let plainNumbers = textBox_.value.replace(/[^0-9.]/g, "");
        return parseFloat(plainNumbers);
    }

    protected _onBlur(rangeIndex_: number, textBox_?: HTMLInputElement): void {
        if (textBox_) {
            let plainNumberValue = this._readText(textBox_);

            if (this.props.dailyBooking) {
                if (plainNumberValue <= this._endOfTheDay) {
                    plainNumberValue += this._totalMinutesInADay;
                }
            }

            if (this.props.ignoreMixMax == null) {
                if ((!isNaN(plainNumberValue) && plainNumberValue <= this.state.max && plainNumberValue >= this.state.min)) {
                    this.state.range[rangeIndex_] = plainNumberValue;

                    if (this.state.range[0] > this.state.range[1]) {
                        if (rangeIndex_ == 0) {
                            this.state.range[1] = this.state.range[0];
                        }
                        else {
                            this.state.range[0] = this.state.range[1];
                        }
                    }
                    this._onSliderChage(this.state.range, rangeIndex_);
                }
                else {
                    textBox_.value = this._makeText(this.state.range[rangeIndex_]);
                }
            } else {
                this.state.range[rangeIndex_] = plainNumberValue;
                this._onSliderChage(this.state.range, rangeIndex_);
            }
        }
    }

    protected _onTextChange(rangeIndex_: number, textBox_?: HTMLInputElement, event_?: ChangeEvent<HTMLInputElement>): void {
        if (textBox_) {
            if (!this._txtMask.test(textBox_.value)) {
                console.log("setting text", this._makeText(this.state.range[rangeIndex_]), rangeIndex_, textBox_);
                textBox_.value = this._makeText(this.state.range[rangeIndex_]);
            }
        }
    }
}

export interface INumberRangeSelectorProps {
    selectedRange?: {
        start: number;
        end: number;
    };
    min?: number;
    max?: number;
    stepSize?: number;
    singleSlider?: boolean;
    isPropertyScreen?: boolean;
    disabled?: boolean;
    dailyBooking?: boolean;
    ignoreMixMax?: boolean;
    disableDecimal?: boolean;
    onChange?: (start_: number, end_: number) => void;
}

export interface INumberRangeSelectorState {
    min: number,
    max: number,
    range: number[],
    isStartTextRefMapped?: boolean;
    isEndTextRefMapped?: boolean;
    stepSize?: number;
}