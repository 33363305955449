import { ConfigService } from '../Services/ConfigService';
import BaseService from "./BaseService";

export default class VisitorBookingService extends BaseService {
    public getVisitorParkingProperties() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "spot/visitor-parking/get-all-properties", {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public getVisitorsUnits(propertyGuid: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `spot/visitor-parking/get-units/${propertyGuid}`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public resetPasscode(unitGuids: string[]) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `spot/visitor-parking/reset-passcode`, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(unitGuids)
                });
            });
    }

    public deleteUnits(unitGuids: string[]) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `spot/visitor-parking/delete`, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(unitGuids)
                });
            });
    }
    
    public addVisitor(postData: any) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "spot/visitor-parking/add-unit", {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postData)
                }, undefined, undefined, true);
            });
    }

    public editVisitor(postData: any) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "spot/visitor-parking/edit-unit", {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postData)
                }, undefined, undefined, true);
            });
    }

    public bulkEditVisitor(postData: any) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "spot/visitor-parking/bulk-edit", {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postData)
                });
            });
    }

    public bulkEditVisitorUndo(postData: any) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "spot/visitor-parking/bulk-edit/undo", {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postData)
                });
            });
    }

    public bulkUnitUpdate(formData_: FormData, url: string) {
        return ConfigService.getBaseUrl()
            .then(baseUrl => {
                return BaseService.sendRequest(baseUrl + url, {
                    method: "POST",
                    body: formData_
                }, undefined, undefined, true);
            });
    }

    public updateConfigurePasses(postData: any) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "spot/visitor-parking/configure-passes", {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postData)
                }, undefined, undefined, true);
            });
    }

    public visitorDeleteUndo(postData: any) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + "spot/visitor-parking/delete/undo", {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postData)
                });
            });
    }
}