export interface IDataRow {
    [column: string]: string | number | Date | any;
}

export interface IDataGridColumn<D> {
    key: string;
    name: string;
    textAlignment?: "left" | "right" | "center";
    contentProvider?: (row_: D, columnKey_: string) => JSX.Element;
    getComparator?: ((order: "asc" | "desc", orderBy: string) => ((a: any, b: any) => number));
    alphaNumeric?: boolean;
    getComparators?: (array: D[], orderBy: keyof D, order: "asc" | "desc", headerColumn: any[], key: string) => D[];
}

export interface IAppliedFilter {
    [columnKey: string]: IColumnFilter;
}

export interface IColumnFilter {
    type: DataGridFilterType
    filterValue?: FilterValueType;
}

export type FilterValueType = string | string[] | INumberRange | IDateRange;

export interface INumberRange {
    start?: number;
    end?: number;
    optionalTag?: string;
}

export interface IDateRange {
    start?: Date;
    end?: Date;
    optionalTag?: string;
}

export enum DataGridFilterType {
    StringList = 1,
    NumberRange = 2,
    RevenueRange = 3,
    DateRange = 4,
    String = 5
}

export interface IPopoverItemData {
    label: string;
    key: string;
    textStyle?:string;
    disabled?:boolean;
}

export interface IGroupByData {
    label: string,
    key: string,
    filterKey: any,
    ignoreKey?: string,
    ignoreFilterKey?: any,
    ignoreSorting?: any,
    ignoreRowClickClass?: string
}