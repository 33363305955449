import React from "react";
import DataGrid from "../../Components/DataGrid/DataGrid";
import { IDataGridColumn, DataGridFilterType } from "../../Components/DataGrid/Interfaces";
import PropertyService from "../../Services/PropertyService";
import { IParkingSpotDetails, ITimeSpan } from "./Interfaces";
import { DateTimeHelper } from "../../Helpers/DateTimeHelper";
import { ShowAlert } from "../../Common/ShowAlert";

export default class ParkingSpotList extends React.Component<IParkingSpotListProps, IParkingSpotListState> {
    private _propertyService: PropertyService;
    private _spotListColumns: IDataGridColumn<IParkingSpotDetails>[] = [
        {
            key: "spotStatusId",
            name: "STATUS",
            contentProvider: (row_: IParkingSpotDetails) => {
                return (
                    <div className={'list-status ' + ((row_.spotStatusId === 1) ? 'active' : 'inactive')}></div>
                );
            }
        },
        {
            key: "lastModified",
            name: "LAST MOD"
        },
        {
            key: "lastReservation",
            name: "LAST RES"
        },
        {
            key: "friendlySpotId",
            name: "SPOT ID"
        },
        {
            key: "propertyCompanyName",
            name: "COMPANY"
        },
        {
            key: "priceRange",
            name: "PRICE RNG",
        },
        {
            key: "propertySpotSchedule",
            name: "SCHEDULE"
        },
        {
            key: "revenve",
            name: "REVENUE",
            textAlignment: "right",
            contentProvider: (row_: IParkingSpotDetails) => {
                return (
                    <span>
                        ${row_.revenve.toFixed(2)}
                    </span>
                );
            }
        },
    ]

    private _parkingSpots: IParkingSpotDetails[] = [
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            spotGuid: "d8ce1051-71b4-4f99-9178-01c674ea62d2",
            friendlySpotId: "FSPT3",
            propertyCompanyName: 'Airbnb, Inc',
            lastModified: "2020-06-30T08:43:48.647",
            lastReservation: "2020-07-04T15:08:28.61",
            spotStatusId: 1,
            propertyMinPrice: 5,
            propertyMaxPrice: 10,
            propertySpotSchedule: '',
            priceRange: '',
            startUtc: {
                ticks: 252000000000,
                days: 0,
                hours: 7,
                milliseconds: 0,
                minutes: 0,
                seconds: 0,
                totalDays: 0.2916666666666667,
                totalHours: 7,
                totalMilliseconds: 25200000,
                totalMinutes: 420,
                totalSeconds: 25200
            },
            endUtc: {
                ticks: 324000000000,
                days: 0,
                hours: 9,
                milliseconds: 0,
                minutes: 0,
                seconds: 0,
                totalDays: 0.375,
                totalHours: 9,
                totalMilliseconds: 32400000,
                totalMinutes: 540,
                totalSeconds: 32400
            },
            revenve: 8.54
        },
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            spotGuid: "ca990e7e-67de-4cab-89d0-14a57a1fc877",
            friendlySpotId: "FSP2T",
            propertyCompanyName: 'Airbnb, Inc',
            lastModified: "2020-06-30T08:44:35.787",
            lastReservation: "2020-07-04T15:08:28.61",
            spotStatusId: 1,
            propertyMinPrice: 5,
            propertyMaxPrice: 10,
            propertySpotSchedule: '',
            priceRange: '',
            startUtc: {
                ticks: 288000000000,
                days: 0,
                hours: 8,
                milliseconds: 0,
                minutes: 0,
                seconds: 0,
                totalDays: 0.3333333333333333,
                totalHours: 8,
                totalMilliseconds: 28800000,
                totalMinutes: 480,
                totalSeconds: 28800
            },
            endUtc: {
                ticks: 324000000000,
                days: 0,
                hours: 9,
                milliseconds: 0,
                minutes: 0,
                seconds: 0,
                totalDays: 0.375,
                totalHours: 9,
                totalMilliseconds: 32400000,
                totalMinutes: 540,
                totalSeconds: 32400
            },
            revenve: 8.54
        },
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            spotGuid: "6e166632-1fa4-42bc-af11-346310fda535",
            friendlySpotId: "FSP3",
            propertyCompanyName: 'Airbnb, Inc',
            lastModified: "2020-06-30T07:06:42.21",
            lastReservation: "2020-07-04T15:08:28.61",
            spotStatusId: 1,
            propertyMinPrice: 5,
            propertyMaxPrice: 10,
            propertySpotSchedule: '',
            priceRange: '',
            startUtc: {
                ticks: 288000000000,
                days: 0,
                hours: 8,
                milliseconds: 0,
                minutes: 0,
                seconds: 0,
                totalDays: 0.3333333333333333,
                totalHours: 8,
                totalMilliseconds: 28800000,
                totalMinutes: 480,
                totalSeconds: 28800
            },
            endUtc: {
                ticks: 756000000000,
                days: 0,
                hours: 21,
                milliseconds: 0,
                minutes: 0,
                seconds: 0,
                totalDays: 0.875,
                totalHours: 21,
                totalMilliseconds: 75600000,
                totalMinutes: 1260,
                totalSeconds: 75600
            },
            revenve: 8.54
        },
        {
            propertyGuid: "606ab277-074f-4cca-b968-f0fefeb010f8",
            spotGuid: "13d8fc9a-51cc-4b24-a422-38da54b98812",
            friendlySpotId: "Vee2",
            propertyCompanyName: 'Airbnb, Inc',
            lastModified: "2020-06-29T06:45:01.917",
            lastReservation: "2020-07-04T11:08:28.61",
            spotStatusId: 1,
            propertyMinPrice: 5,
            propertyMaxPrice: 10,
            propertySpotSchedule: '',
            priceRange: '',
            startUtc: {
                ticks: 396000000000,
                days: 0,
                hours: 11,
                milliseconds: 0,
                minutes: 0,
                seconds: 0,
                totalDays: 0.4583333333333333,
                totalHours: 11,
                totalMilliseconds: 39600000,
                totalMinutes: 660,
                totalSeconds: 39600
            },
            endUtc: {
                ticks: 756000000000,
                days: 0,
                hours: 21,
                milliseconds: 0,
                minutes: 0,
                seconds: 0,
                totalDays: 0.875,
                totalHours: 21,
                totalMilliseconds: 75600000,
                totalMinutes: 1260,
                totalSeconds: 75600
            },
            revenve: 12.81
        }
    ]

    constructor(props: any) {
        super(props);

        this.state = {
            allParkingSpot: [],
            searchTerm: "",
            isFiltered: false,
            isDataLoaded: false
        };
        this._propertyService = new PropertyService();
    }

    public componentDidMount = () => {
        this._propertyService.getPropertySpotsInformation(this.props.propertyID).then(response => {
            if (response.ok) {
                response.json().then(p => {
                    if (p) {
                        let mappedItems = p.map((r: any) => {
                            r.lastModified = DateTimeHelper.updateTimeHr(r.lastModified);
                            r.lastReservation = (r.lastReservation == "0001-01-01T00:00:00") ? '-' : DateTimeHelper.updateTimeHr(r.lastReservation);
                            r.priceRange = this.setPriceRange(r.propertyMinPrice, r.propertyMaxPrice);
                            r.propertySpotSchedule = this.setScheduleTime(r.startUtc, r.endUtc);

                            return r;
                        });

                        this.setState({
                            isDataLoaded: true,
                            allParkingSpot: mappedItems
                        })
                    }
                })
            } 
            /*else {
                if (response.status === 401) {
                    this.accessTokenExpired();
                } else {
                    response.text().then(resp => {
                        console.log(response);
                    });
                    ShowAlert("", 'There was an error processing a your request.', "error");
                }
            }*/
        });

        /*let mappedItems = this._parkingSpots.map(r => {
            r.lastModified = DateTimeHelper.updateTimeHr(r.lastModified);
            r.lastReservation = DateTimeHelper.updateTimeHr(r.lastReservation);
            r.priceRange = this.setPriceRange(r.propertyMinPrice, r.propertyMaxPrice);
            r.propertySpotSchedule = this.setScheduleTime(r.startUtc, r.endUtc);

            return r;
        });

        this.setState({
            allParkingSpot: mappedItems
        })*/
    }


    render() {
        if (!this.state.isDataLoaded) {
            return null;
        }
        return (
            <DataGrid
                title="Spot"
                data={this.state.allParkingSpot}
                columns={this._spotListColumns}
                isRowsNonSelectable={true}
                isNoMoreLink={true}
            />
        );
    }

    private setPriceRange(min: number, max: number) {
        let price_: string = '0';

        price_ = min.toFixed(2) + '-' + max.toFixed(2);

        return '$' + price_;
    }

    private setScheduleTime(startTime: ITimeSpan, endTime: ITimeSpan) {
        let time_: string = '';

        if (startTime.minutes == endTime.minutes) {
            time_ = '24/7';
        } else {
            time_ = this._makeTimeText(startTime.minutes) + '-' + this._makeTimeText(endTime.minutes)
        }

        return time_;
    }

    private _makeTimeText(minutes_: number): string {
        let fullHours = Math.floor(minutes_ / 60);
        let remainderMinutes = minutes_ % 60;
        let ampm = fullHours < 12 ? "AM" : "PM";
        let hours = fullHours % 12;

        if (hours === 0) {
            hours = 12;
        }

        return `${this._padLeft(hours.toString(), 2, '0')}:${this._padLeft(remainderMinutes.toString(), 2, '0')} ${ampm}`;
    }

    private _padLeft(text_: string, length_: number, padChar_: string): string {
        if (padChar_) {
            while (text_.length < length_) {
                text_ = padChar_ + text_;
            }
        }

        return text_;
    }

    private accessTokenExpired() {
        localStorage.clear();
        window.location.href = '/';
    }

    private setRandomRevenue() {
        let revenue: number = Math.floor(Math.random() * 100) + 50;
        return revenue;
    }    
}

interface IParkingSpotListProps {
    propertyID: string
}

interface IParkingSpotListState {
    allParkingSpot: IParkingSpotDetails[];
    searchTerm: string;
    isFiltered: boolean;
    isDataLoaded: boolean;
}