import React from 'react';
import LoaderComponent from '../../Components/LoaderComponent';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { PlaidLink, usePlaidLink } from 'react-plaid-link';
import { ShowAlert, ShowAlertwithConfirm } from '../../Common/ShowAlert';
import Icon from '@material-ui/icons/Info';
import { ConfigService } from '../../Services/ConfigService';

import { Modal, Button } from 'react-bootstrap';
import { Row, Col } from 'reactstrap';
import BaseService from '../../Services/BaseService';

// const PLAID_PUBLIC_KEY = '10aa11248ead62acb670f8610befdb';
// const APP_NAME = 'GRYD PARK';
// const ENVIRONMENT = 'sandbox';
// const PRODUCT = ['auth', 'transactions'];

class Plaid extends React.Component<PlaidProps, PlaidState> {
  private APP_NAME: string = 'GRYD PARK';
  private PRODUCT: string[] = ['auth', 'transactions'];

  constructor(props: PlaidProps) {
    super(props);
    let validToken = localStorage.getItem('token');
    this.state = {
      // open: true,
      success: false,
      showBlocker: false,
      email: '',
      ENVIRONMENT: '',
      PLAID_PUBLIC_KEY: ''
    }
  }

  public componentDidMount = () => {

    // ShowAlert('Success!', 'The <b>' + 'test' + '</b> was added to the property list', 'success');
    // ShowAlertwithConfirm('test','New message','info');

    if (typeof (Storage) !== 'undefined') {
      var _localData = localStorage.getItem('userdata');

      if (_localData != null && _localData !== '') {
        var _data = JSON.parse(_localData);
        this.setState({
          email: _data.emailId
        });

      }
    }

    ConfigService.getPlaid()
      .then(d => {
        this.setState({
          PLAID_PUBLIC_KEY: d.publicKey,
          ENVIRONMENT: d.environment
        });

      });
  }

  public handleClose = () => {
    // this.apiMethod();
    this.setState({
      // open: false
    });
  };

  public handleSuccessClose = () => {
    // this.apiMethod();
    this.setState({
      success: false
    });
  };

  public apiMethod = () => {

    const data = { username: 'example' };

    fetch('http://localhost:3200/verify', {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      //   body: JSON.stringify(data),
    })
      // .then(response => response.json())
      .then(data => {
        if (data.ok) {
          data.text().then(r => {
            console.log(r);
          })
        }
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', JSON.stringify(error));
      });


  }

  public onSuccess = (token: any, metadata: any) => {
    // send token to server

    this.setState({
      // open: false,
      showBlocker: true
    });

    console.log(JSON.stringify(metadata));

    console.log('Public Token: ' + token);
    console.log('Customer-selected account ID: ' + metadata.account_id);

    this.sendDataToServer(token, metadata.accounts[0]['id']);
  };

  public sendDataToServer = (token: string, accountId: string) => {

    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ public_token: token, account_id: accountId })
    // };
    // fetch('http://localhost:3200/verify', requestOptions)
    //     .then(response => response.json())
    //     .then(data => this.setState({ postId: data.id }));

    const data = { public_token: token, account_id: accountId, email: this.state.email };

    ConfigService.getNodeBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + 'authenticate', {
          method: 'POST', // or 'PUT'
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          // .then(response => response.json())
          .then(data => {
            if (data.ok) {
              data.json().then(r => {
                console.log(r);
                if (r == "success") {

                  this.setState({
                    success: true,
                    showBlocker: false
                  });
                  ShowAlert('Success!', 'Your bank account has been verified successfully', 'success');
                  localStorage.setItem('token', r);
                  if (this.props.onClose) {
                    this.props.onClose(true);
                  }
                }
                else {
                  this.showError();
                }

              })
            }
            else {
              this.showError();
            }
            console.log('Success:', data);
            this.setState({
              showBlocker: false
            });
          })
          .catch((error) => {
            console.error('Error:', JSON.stringify(error));
            this.showError();
          });
      });

  }

  public showError() {
    this.setState({
      success: false,
      showBlocker: false
    });
    ShowAlert('Failed!', 'Failed to verify your bank account', 'error');
  }

  public render() {

    return (
      <div className="dashboard-tile bg-lightgrey">
        {/* <LoaderComponent loaderVisible={this.state.showBlocker} /> */}
        <div className="tile-heading">Bank Account Verification</div>
        <div className="tile-content-small">
          <div>Please verify your Bank Account for payments</div>

          {
            this.state.PLAID_PUBLIC_KEY != "" &&
            <PlaidLink style={{ fontSize: 16, color: '#fff', backgroundColor: '#00af85', borderRadius: 10, width: 90, marginTop: "4px", border: "none", minWidth: "180px" }}
              className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"
              clientName={this.APP_NAME}
              env={this.state.ENVIRONMENT}
              product={this.PRODUCT}
              countryCodes={['CA']}
              publicKey={this.state.PLAID_PUBLIC_KEY}
              onSuccess={this.onSuccess}
              onExit={this.handleClose}
            >Verify</PlaidLink>
          }
        </div>
      </div>
    )
  }
}

interface PlaidProps {
  onClose?: (c_: boolean) => void;
}
interface PlaidState {
  // open: boolean;
  success: boolean;
  showBlocker: boolean;
  email: string;
  PLAID_PUBLIC_KEY: string;
  ENVIRONMENT: string;
}

export default Plaid;