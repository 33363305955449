import { Grid, TextField, Button, Checkbox } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { IEnforcementAddress } from "./interface";


export default class EnforcementAddress extends React.Component<IEnforcementAddressProps, IEnforcementAddressState> {
    private autoCompleteInput: any = null;
    constructor(props_: IEnforcementAddressProps) {
        super(props_);

        this.state = {
            selectedAddressDetails: [],
            enforcementAddressDetails: []
        }
    }

    componentDidMount() {
        this.setProperties(null);
    }

    componentDidUpdate(oldProps_: IEnforcementAddressProps) {
        this.setProperties(oldProps_);
    }

    render() {
        return (
            <React.Fragment>
                <Grid item className="px-4">
                    <Autocomplete
                        multiple
                        openOnFocus={true}
                        selectOnFocus={true}
                        disableCloseOnSelect
                        ref={(input) => { this.autoCompleteInput = input; }}
                        className="mt-2"
                        options={this.state.enforcementAddressDetails}
                        getOptionLabel={option_ => (option_.propertyAddressLine1 + option_.propertyAddressLine2) as string}
                        style={{ width: 400 }}
                        size="small"
                        value={this.state.selectedAddressDetails}
                        renderInput={(params) => (<TextField {...params} label="Location"
                            error={this.state.selectedAddressDetails.length === 0}
                            variant="outlined" />)}
                        renderTags={(tagValue, getTagProps) => {
                            let propertyAddress = tagValue.map(x => {
                                return x.propertyAddressLine1 + x.propertyAddressLine2;
                            });

                            if (tagValue.length > 0 && propertyAddress.indexOf("All Location") !== -1) {
                                propertyAddress = [propertyAddress[0]];
                            }

                            return <div className="enforcement-selected-properties">{propertyAddress.toString().replaceAll(",", ", ")}</div>;
                        }}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <div className="cl-dropdown-list">
                                    <Checkbox
                                        icon={<div className="repeat-b-check" />}
                                        checkedIcon={<div className="repeat-b-check selected" />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                        color="primary"
                                    />
                                    {option.propertyAddressLine1 + option.propertyAddressLine2}
                                </div>
                            </React.Fragment>
                        )}
                        onChange={(e_: any, addressDetail_: IEnforcementAddress[]) => {

                            let isAllAlreadySelected: boolean = this.state.selectedAddressDetails.filter(x => x.propertyGuid === 'blank').length > 0;
                            let isAllSelected: boolean = addressDetail_.filter(x => x.propertyGuid === 'blank').length > 0;
                            let selectedValue: IEnforcementAddress[] = [];

                            if ((!isAllAlreadySelected && !isAllSelected) && addressDetail_.length === (this.state.enforcementAddressDetails.length - 1) || (isAllSelected && !isAllAlreadySelected)) {
                                selectedValue = this.state.enforcementAddressDetails;
                            } else if ((isAllAlreadySelected && isAllSelected) && addressDetail_.length !== this.state.enforcementAddressDetails.length) {
                                let blankIndex: number = addressDetail_.map(i => i.propertyGuid).indexOf("blank");
                                selectedValue = addressDetail_;
                                selectedValue.splice(blankIndex, 1);
                            } else if (isAllAlreadySelected && !isAllSelected) {
                                selectedValue = [];
                            }
                            else {
                                selectedValue = addressDetail_;
                            }

                            this.setState({
                                selectedAddressDetails: selectedValue
                            });
                        }}
                    />
                </Grid>
                <Grid item>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className='mt-2 c-go-button'
                        onClick={() => {
                            if (this.props.onAddressChange) {
                                this.props.onAddressChange(this.state.selectedAddressDetails);
                            }
                        }}
                        disabled={this.state.selectedAddressDetails.length === 0}
                    >
                        Go
                    </Button>
                </Grid>
            </React.Fragment>
        )
    }

    private setProperties(oldProps_: IEnforcementAddressProps | null) {
        if (!oldProps_ || (JSON.stringify(this.props.enforcementAddressDetails) !== JSON.stringify(oldProps_.enforcementAddressDetails))) {            
            const hasAllInProperties = this.props.enforcementAddressDetails?.find((property: IEnforcementAddress) => property.propertyName === "All Address");
            let properties_: IEnforcementAddress[] = hasAllInProperties ? this.props.enforcementAddressDetails.slice(1) : this.props.enforcementAddressDetails;
            const hasAllInSelectProperty = this.props.selectedAddressDetails?.find((property: IEnforcementAddress) => property.propertyName === "All Address");
            let selectedAddressDetails_: IEnforcementAddress[] = hasAllInSelectProperty ? this.props.selectedAddressDetails.slice(1) : this.props.selectedAddressDetails;

            let selectAll: IEnforcementAddress = {
                propertyName: "",
                propertyGuid: "blank",
                propertyAbout: "",
                propertyDescription: "",
                propertyLatitiude: 0,
                propertyLongitude: 0,
                propertyAddressLine1: "All Location",
                propertyAddressLine2: "",
                propertyCity: "",
                propertyState: "",
                propertyCountry: "",
                propertyZipCode: "",
                otherAmenities: "",
                propertyAmenities: [],
                propertyRedeem: [],
                propertyRating: "",
                propertyRatingMessage: "",
                propertyDistance: 0,
                propertyMinPrice: 0,
                propertyMaxPrice: 0,
                availableSpotCount: 0,
                propertyInstructions: "",
                propertyTimeZone: "",
                isHighTrafficArea: false,
                isHighParkingOffencesArea: false                
            }
            if(this.props.enforcementAddressDetails?.length === this.props.selectedAddressDetails?.length && !this.props.allEnforcementNotRequired){
                selectedAddressDetails_.unshift(selectAll);
            }

            if (properties_.length > 0 && !this.props.allEnforcementNotRequired) {
                properties_.unshift(selectAll);
            }

            if ((this.props.selectedAddressDetails.length === 1 && this.props.selectedAddressDetails[0].propertyGuid === "blank") ||
            this.props.selectedAddressDetails.length === properties_.length) {
                selectedAddressDetails_ = properties_.slice(0);
            } else {
                var filteredObject = properties_.filter(x => {
                    return JSON.stringify(this.props.selectedAddressDetails).indexOf(x.propertyGuid) !== -1;
                })
                selectedAddressDetails_ = filteredObject.slice(0);
            }

            this.setState({
                enforcementAddressDetails: properties_.slice(0),
                selectedAddressDetails: selectedAddressDetails_
            });
        } else if (!oldProps_ || (JSON.stringify(this.props.selectedAddressDetails) !== JSON.stringify(oldProps_.selectedAddressDetails))) {
            this.setState({
                selectedAddressDetails: this.props.selectedAddressDetails
            });
        }
    }
}

interface IEnforcementAddressProps {
    enforcementAddressDetails: IEnforcementAddress[];
    onAddressChange: (value: IEnforcementAddress[]) => void;
    selectedAddressDetails: IEnforcementAddress[];
    id: string;
    allEnforcementNotRequired: boolean;
}

interface IEnforcementAddressState {
    enforcementAddressDetails: IEnforcementAddress[];
    selectedAddressDetails: IEnforcementAddress[];
}