import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { List } from "react-virtualized";
import { Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  popper: {
    "& .MuiAutocomplete-listbox": {
      overflow: "hidden"
    }
  }
}));

const ListboxComponent = React.forwardRef((props, ref) => {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 50;
  let height = itemCount < 5 ? (50 * itemCount) : 250;

  return (
    <div ref={ref}>
      <div {...other}>
        <List
          height={height}
          width={472}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={props => {
            return React.cloneElement(children[props.index], {
              style: props.style
            });
          }}
          role={role}
        />
      </div>
    </div>
  );
});

export default function VirtualizeAutoComplete(props) {
  const className = useStyles();
  return (
    <>{props.multiple && <Autocomplete
      classes={className}
      multiple={props.multiple ?? false}
      id={props.id ? "virtualizeAutoComplete" : props.id}
      size="small"
      disableListWrap
      disableCloseOnSelect={props.disableCloseOnSelect ?? false}
      ListboxComponent={ListboxComponent}
      options={props.options ?? []}
      value={props.value ?? null}
      onChange={props.onChange ?? null}
      getOptionLabel={props.getOptionLabel ?? ""}
      renderInput={props.renderInput}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
            color="primary"
          />
          {option && option !== '' ? option : 'Blank'}
        </React.Fragment>
      )}
    />
    }
      {!props.multiple && <Autocomplete
        multiple={props.multiple ?? false}
        classes={className}
        id={props.id ? "virtualizeAutoComplete" : props.id}
        size="small"
        disableListWrap
        disableCloseOnSelect={props.disableCloseOnSelect ?? false}
        ListboxComponent={ListboxComponent}
        options={props.options ?? []}
        value={props.value ?? null}
        onChange={props.onChange ?? null}
        getOptionLabel={props.getOptionLabel ?? ""}
        renderInput={props.renderInput}
      />
      }</>
  );
}
