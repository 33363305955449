import React from "react";
import DataGrid from "../../Components/DataGrid/DataGrid";
import { IDataGridColumn, IAppliedFilter, DataGridFilterType, INumberRange, IColumnFilter } from "../../Components/DataGrid/Interfaces";
import "./Disbursement.css";
import CloseIcon from '@material-ui/icons/Close';

import { CSVLink } from "react-csv";
import ClearIcon from '@material-ui/icons/Clear';
import { Button, Drawer, Grid, IconButton, MenuItem, Tooltip } from "@material-ui/core";
import DateRangeFilter from "../../Components/FilterComponents/DateRangeFilter";
import { IDateRange } from "../../Components/DataGrid/Interfaces";
import { DateTimeHelper } from "../../Helpers/DateTimeHelper";
import { format } from "date-fns";
import DisbursementService from "../../Services/DisbursementService";
import DataGridToolbar from "../../Components/DataGrid/DataGridToolbar";
import TransactionFilter from "./TransactionFilter";
import { PreserveFilters } from "../../Common/PreserveFilters";


interface ITransactionState {
    loadedTransactions: any[];
    datefilter: {}
    selectedBookingId: string[] | undefined;
    isRightPanOpen: boolean;
    searchTerm: string;
    filteredTransaction: any[];
    subFilteredTransaction: any[];
    isFiltered: boolean;
    appliedFilter?: ITransactionsFilter;
    _autoApplyFilterKey: number;
}

interface ITransactionResult {
    friendlyReservationId: string,
    xeroBillId1: string,
    xeroBillId2: string,
    xeroJournalId: string,
    xeroAmount1: number,
    xeroAmount2: number,
    paidAmount: number,
    stripeTransactionId: string,
    paidDate: Date,
    actualPaidAmount: number,
    credit: number,
    coupon: number,
    adminShare: number,
    pmShare: number,
    tenantShare: number,
    xeroBillId: null,
    xeroBillAmount: number
}

interface ITransactionsFilter extends IAppliedFilter {
    recipient: IColumnFilter;
    reservation: IColumnFilter;
}

export default class Transactions extends React.Component<{}, ITransactionState>{
    private _disbursementService: DisbursementService;
    private _transactionListColumns: IDataGridColumn<ITransactionResult>[] = [];

    constructor(props: Readonly<{}>) {
        super(props);

        this.state = {
            loadedTransactions: [],
            subFilteredTransaction: [],
            filteredTransaction: [],
            datefilter: { start: undefined, end: undefined },
            selectedBookingId: undefined,
            isRightPanOpen: false,
            searchTerm: "",
            isFiltered: false,
            _autoApplyFilterKey: 0
        }

        this._loadTransactions = this._loadTransactions.bind(this);
        this.filteredTransactionData = this.filteredTransactionData.bind(this);

        this._transactionListColumns = this._transactionListColumns.concat([
            {
                key: "spacer1",
                name: ""
            },
            {
                key: "friendlyReservationId",
                name: "Friendly ReservationId",
                contentProvider: (row_: ITransactionResult) => {
                    return (
                        <div >{row_.friendlyReservationId}</div>
                    );
                }
            },
            {
                key: "paidDate",
                name: "Paid Date and Time",
                contentProvider: (row_: ITransactionResult) => {
                    let date = DateTimeHelper.dateStringToDate(row_.paidDate);
                    return (<span><span className="no-wrap-text">{format(date, "dd MMM yyyy")}</span><br /><span className="no-wrap-text">{format(date, "hh:mm aa")}</span></span>);
                }
            },
            {
                key: "paidAmount",
                name: "Paid Amount",
                contentProvider: (row_: ITransactionResult) => {
                    return (<span>{row_.paidAmount < 0 ? '-' : ''}${Math.abs(row_.paidAmount).toFixed(2)}</span>);
                },
                textAlignment: "right"
            },
            {
                key: "actualPaidAmount",
                name: "Actual Paid Amount",
                contentProvider: (row_: ITransactionResult) => {
                    // return (<span>${row_.actualPaidAmount.toFixed(2)}</span>);
                    return (<span>{row_.actualPaidAmount < 0 ? '-' : ''}${Math.abs(row_.actualPaidAmount).toFixed(2)}</span>);
                },
                textAlignment: "right"
            },
            {
                key: "credit",
                name: "Credit",
                contentProvider: (row_: ITransactionResult) => {
                    return (<span>{row_.credit < 0 ? '-' : ''}${Math.abs(row_.credit).toFixed(2)}</span>);
                    // return (<span>${row_.credit.toFixed(2)}</span>);
                },
                textAlignment: "right"
            },
            {
                key: "coupon",
                name: "Coupon",
                contentProvider: (row_: ITransactionResult) => {
                    return (<span>{row_.coupon < 0 ? '-' : ''}${Math.abs(row_.coupon).toFixed(2)}</span>);
                    // return (<span>${row_.coupon.toFixed(2)}</span>);
                },
                textAlignment: "right"
            },
            {
                key: "pmShare",
                name: "PM Share",
                contentProvider: (row_: ITransactionResult) => {
                    return (<span>{row_.pmShare < 0 ? '-' : ''}${Math.abs(row_.pmShare).toFixed(2)}</span>);
                    // return (<span>${row_.pmShare.toFixed(2)}</span>);
                },
                textAlignment: "right"
            },
            {
                key: "tenantShare",
                name: "Tenant Share",
                contentProvider: (row_: ITransactionResult) => {
                    return (<span>{row_.tenantShare < 0 ? '-' : ''}${Math.abs(row_.tenantShare).toFixed(2)}</span>);
                    // return (<span>${row_.tenantShare.toFixed(2)}</span>);
                },
                textAlignment: "right"
            },
            {
                key: "adminShare",
                name: "Admin Share",
                contentProvider: (row_: ITransactionResult) => {
                    return (<span>{row_.adminShare < 0 ? '-' : ''}${Math.abs(row_.adminShare).toFixed(2)}</span>);
                    // return (<span>${row_.adminShare.toFixed(2)}</span>);
                },
                textAlignment: "right"
            },
            {
                key: "stripeTransactionId",
                name: "Stripe TransactionId",
                contentProvider: (row_: ITransactionResult) => {
                    return (
                        <div >{row_.stripeTransactionId}</div>
                    );
                }
            },
            {
                key: "xeroBillId1",
                name: "Xero Bill Id 1 (PM)",
                contentProvider: (row_: ITransactionResult) => {
                    return (
                        <div >{row_.xeroBillId1}</div>
                    );
                }
            },
            {
                key: "xeroAmount1",
                name: "Xero Bill Amount 1 (PM)",
                contentProvider: (row_: ITransactionResult) => {
                    if (row_.xeroAmount1 != null) {
                        return (<span>{row_.xeroAmount1 < 0 ? '-' : ''}${Math.abs(row_.xeroAmount1).toFixed(2)}</span>);
                        // return (<span>${row_.xeroAmount1.toFixed(2)}</span>);
                    }
                    return (<span></span>);
                },
                textAlignment: "right"
            },
            {
                key: "spacer1",
                name: ""
            },
            {
                key: "xeroBillId2",
                name: "Xero Bill Id 2 (Tenant)",
                contentProvider: (row_: ITransactionResult) => {
                    return (
                        <div >{row_.xeroBillId2}</div>
                    );
                }
            },
            {
                key: "xeroBillAmount2",
                name: "Xero Bill Amount 2 (Tenant)",
                contentProvider: (row_: ITransactionResult) => {
                    if (row_.xeroAmount2 != null) {
                        return (<span>{row_.xeroAmount2 < 0 ? '-' : ''}${Math.abs(row_.xeroAmount2).toFixed(2)}</span>);
                        // return (<span>${row_.xeroAmount2.toFixed(2)}</span>);
                    }
                    return (<span></span>);
                },
                textAlignment: "right"
            },
            {
                key: "spacer1",
                name: ""
            }
        ]);

        this._disbursementService = new DisbursementService();
    }


    listOfBookingId: any = []
    componentDidMount() {
        this._loadTransactions()
    }
    componentWillUnmount(){
        PreserveFilters.setSearchTerm('transactionSearch',this.state.searchTerm)
    }
    private _loadTransactions() {
        this._disbursementService.getReconciliation()
            .then(res => res.json())
            .then(res => {
                let searchTerm = PreserveFilters.getSearchTerm('transactionSearch')
                this.setState({
                    loadedTransactions: res,
                    subFilteredTransaction: searchTerm?this._filterDataBySearch(res,searchTerm) :res.slice(0),
                    filteredTransaction: res.slice(0),
                    appliedFilter: PreserveFilters.readPreservedFilter('reconciliation'),
                    searchTerm:searchTerm?searchTerm:''
                }, () => {
                    if(this.state.appliedFilter){
                        this.filteredTransactionData(this.state.appliedFilter?.dateRange.filterValue as IDateRange)
                    }
                })
            });

        /*ConfigService.getBaseUrl().then((url) => {
            return BaseService.sendRequest('https://localhost:44344/disbursement/transactions', {
                method: 'GET', // or 'PUT'
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
        })
            .then((transactions) => {
                return transactions.json()
            }).then((res) => {
                this.setState({
                    loadedTransactions: res
                },()=>{
                    this.state.loadedTransactions.forEach((transaction:ITransactionResult)=>{
                        if (this.listOfBookingId.includes(!transaction.friendlyReservationId)){
                            this.listOfBookingId.push(transaction.friendlyReservationId)
                        }
                       console.log(this.listOfBookingId.length)
                    })
                })
            })*/

    }



    public filteredTransactionData(dateFilter: IDateRange) {
        let filteredArray = []
        if ((dateFilter.start != undefined && dateFilter.end != undefined) && !this.state.isRightPanOpen) {
            filteredArray = this.state.loadedTransactions!.filter((transaction: ITransactionResult) => {
                return new Date(transaction.paidDate).getTime() >= new Date(dateFilter.start!).getTime() && new Date(transaction.paidDate).getTime() <=new Date(dateFilter.end!).getTime()
            });
            
        }
        else{

            filteredArray = this.state.loadedTransactions
        }
        this.setState({
            subFilteredTransaction: this._filterDataBySearch(filteredArray,this.state.searchTerm),
            
            isFiltered: true,
        })

    }

    public render() {
        return <>
            <div className="data-grid-container dashboad-table transaction-container">
                <Grid container className="mb-3">
                    <Grid item>
                        {/* Title of the page */}
                        <h1 className="list-header">Reconciliation</h1>
                    </Grid>
                    <Grid item xs>
                        <DataGridToolbar
                        search={PreserveFilters.getSearchTerm('transactionSearch')}
                            singularEntityName="reconciliation"
                            pluralEntityName="reconciliations"
                            onSearch={this._searchTermChange}
                            isFiltered={this.state.isFiltered}
                            isNoAddButton={true}
                            onFilterClick={() => {
                                this.setState({
                                    isRightPanOpen: true
                                });
                            }}
                        />
                    </Grid>
                    <div style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                        <CSVLink data={this.state.subFilteredTransaction} filename={"Transactions.csv"} className="suppress-link">
                            <MenuItem>Export to CSV</MenuItem>
                        </CSVLink>
                    </div>
                </Grid>
                <DataGrid
                    title="Transactions"
                    // data={this.filteredTransactionData(this.state.datefilter)}
                    data={this.state.subFilteredTransaction}
                    defaultSortColumnKey="friendlyReservationId"
                    columns={this._transactionListColumns}
                    isRowsNonSelectable={true}
                    isNoMoreLink={true}
                    appliedFilter={this.state.appliedFilter}
                    onFilterTagRemoved={(filter_: IAppliedFilter) => {
                        this.setState({
                            appliedFilter: filter_ as ITransactionsFilter,
                            _autoApplyFilterKey: new Date().getTime()
                        },()=>{
                            PreserveFilters.preserveFilter('reconciliation',this.state.appliedFilter)
                            this.filteredTransactionData(this.state.datefilter)
                        })
                    }}
                ></DataGrid>
            </div>
            {this.state.isRightPanOpen && <Drawer
                anchor="right"
                variant={"temporary"}
                open={true}>
                    
                    <div className="mx-3">
                        <Grid container className="right-panel-header p-2 border-bottom">
                            <Grid item xs>
                                <h4 className="right-panel-title m-0">
                                   Filter
                                </h4>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={()=>{
                                        this.setState({isRightPanOpen:false})
                                    }}>
                                    <CloseIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                <TransactionFilter data={this.state.loadedTransactions} autoApplyFilterKey={this.state._autoApplyFilterKey} isVisible={this.state.isRightPanOpen} onFilterChange={(appliedFilter_, filteredData_, isFiltered_) => {
                    this.setState({
                        appliedFilter: appliedFilter_,
                        filteredTransaction: filteredData_,
                        subFilteredTransaction: this._filterDataBySearch(filteredData_, this.state.searchTerm),
                        isFiltered: isFiltered_,
                    }, () => { PreserveFilters.preserveFilter('reconciliation', this.state.appliedFilter) })
                }} onClose={() => {
                    this.setState({ isRightPanOpen: false })
                }}></TransactionFilter>
            </Drawer>}
        </>
    }

    private _searchTermChange = (searchTerm_: string): void => {
        this.setState({
            searchTerm: searchTerm_,
        },()=>{
            this.filteredTransactionData(this.state.datefilter)
        });
    }

    private _filterDataBySearch = (data_: ITransactionResult[], searchTerm_: string): ITransactionResult[] => {
        console.log('freeze')
        return data_.filter(d => {
            
            return (d.friendlyReservationId != null && d.friendlyReservationId.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1) ||
                (d.stripeTransactionId != null && d.stripeTransactionId.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1) ||
                (d.xeroBillId1 != null && d.xeroBillId1.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1) ||
                (d.xeroBillId2 != null && d.xeroBillId2.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1);
        });
    }

    // rightPanView() {
    //     return <Drawer
    //         anchor="right"
    //         variant='temporary'
    //         open={true}>
    //         <div style={{ width: '500px', margin: '10px' }}>
    //             <div className="transaction-filter-heading">
    //                 <h3>Filters</h3>
    //                 <Tooltip title="Clear">
    //                     <IconButton type="submit" aria-label="Clear" onClick={() => { this.setState({ datefilter: {}, isRightPanOpen: false }) }}>
    //                         <ClearIcon />
    //                     </IconButton>
    //                 </Tooltip>
    //             </div>

    //             <hr></hr>
    //             <TransactionFilter data={this.state.loadedTransactions} autoApplyFilterKey={this.state._autoApplyFilterKey} isVisible={this.state.isRightPanOpen} onFilterChange={(appliedFilter_, filteredData_, isFiltered_) => {
    //                 this.setState({
    //                     appliedFilter: appliedFilter_,
    //                     filteredTransaction: filteredData_,
    //                     subFilteredTransaction: this._filterDataBySearch(filteredData_, this.state.searchTerm),
    //                     isFiltered: isFiltered_
    //                 })
    //             }} onClose={() => {
    //                 this.setState({ isRightPanOpen: false })
    //             }}></TransactionFilter>
    //         </div>
    //     </Drawer>

    // }
}
