import React from 'react';
import './Dashboard.css'
import { IRankedProperty } from './Interface';
import DataGrid from '../../Components/DataGrid/DataGrid';
import { IDataGridColumn } from '../../Components/DataGrid/Interfaces';
import PropertyService from '../../Services/PropertyService';

export default class TopProperties extends React.Component<TopPropertiesProps, TopPropertiesState> {
    private _propertyService: PropertyService;
    private _propertyListColumns: IDataGridColumn<IRankedProperty>[] = [
        {
            key: "rank",
            name: "RANK",
            textAlignment: "right",
        },
        {
            key: "spacer1",
            name: ""
        },
        {
            key: "propertyName",
            name: "PROPERTY"
        },
        {
            key: "revenue",
            name: "REVENUE",
            textAlignment: "right",
            contentProvider: (row_: IRankedProperty) => {
                return (
                    <span>
                        ${row_.revenue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                );
            }
        }
    ]
    private _propertyList: IRankedProperty[] = [
        {
            propertyName: "Garden Park Estate",
            rank: 1,
            revenue: 567
        },
        {
            propertyName: "The Sterling Window Lofts",
            rank: 2,
            revenue: 422
        },
        {
            propertyName: "Lady Wentworth",
            rank: 3,
            revenue: 331
        },
        {
            propertyName: "Bellblock",
            rank: 4,
            revenue: 220
        },
        {
            propertyName: "Monterey Appartments",
            rank: 5,
            revenue: 194
        },
        {
            propertyName: "Atlantic Daylight Time",
            rank: 6,
            revenue: 182
        },
        {
            propertyName: "Central Daylight Time",
            rank: 7,
            revenue: 174
        },
        {
            propertyName: "City of Corner Brook",
            rank: 8,
            revenue: 169
        },
        {
            propertyName: "Gerrard West",
            rank: 9,
            revenue: 163
        },
        {
            propertyName: "Eldorado Arm",
            rank: 10,
            revenue: 160
        }
    ]
    constructor(props: TopPropertiesProps) {
        super(props);
        this.state = {
            allProperties: [],
            showBlocker: false,
            propertyOption: this.props.propertyOption ? this.props.propertyOption : "YTD",
            selectedCities: []
        }
        this._propertyService = new PropertyService();
    }

    public componentDidMount = () => {
        this.loadWidgetData();
    }

    public componentDidUpdate = () => {
        if (this.state.propertyOption != this.props.propertyOption || this.state.selectedCities != this.props.cities) {
            this.loadWidgetData();
        }
    }

    private loadWidgetData() {
        let widgetData: any = {
            selectedCity: this.props.cities.length > 0 ? this.props.cities.toString() : null,
            selectedTimePeroid: this.props.propertyOption
        };

        this._propertyService.getAdminDashboardPropertyRevenue(widgetData, '', true)
            .then(data => {
                if (data.ok) {
                    data.json().then(d => {
                        let _mappedItems: IRankedProperty[] = [];
                        for (var i = 0; i < d.length; i++) {
                            if (i === 10) {
                                break;
                            }
                            let _property: IRankedProperty = {
                                rank: (i + 1),
                                propertyName: d[i].propertyName,
                                revenue: d[i].propertyRevenue
                            }

                            _mappedItems.push(_property);
                        }

                        this.setState({
                            allProperties: _mappedItems,
                            propertyOption: this.props.propertyOption,
                            selectedCities: this.props.cities
                        });
                        if (this.props.loaderVisible){
                            this.props.loaderVisible(false);
                        }
                    })
                }
            })
            .catch((error) => {
                console.error('Error:', JSON.stringify(error));
            });
        /*let mappedItems = this._propertyList.map(r => {
            return r;
        });

        this.setState({
            allProperties: mappedItems
        })*/
    }

    public render() {
        return (
            <DataGrid
                title="Revenue"
                data={this.state.allProperties}
                columns={this._propertyListColumns}
                isRowsNonSelectable={true}
                isNoMoreLink={true}
                customPageSize={[5, 10, 15]}
                isNoRowsPerPage={true}
            />
        )
    }
}

interface TopPropertiesProps {
    propertyOption: string;
    cities: string[];
    loaderVisible?: (s_: boolean) => void,
}
interface TopPropertiesState {
    allProperties: IRankedProperty[],
    showBlocker: boolean,
    propertyOption: string;
    selectedCities: string[];
}