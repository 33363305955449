import React from "react";
import { Select, MenuItem, Grid, Button, TextField, Checkbox, Radio, FormControl, FormLabel, RadioGroup, FormControlLabel } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import { Col, Row } from "reactstrap";
import Calendar from 'react-calendar';
import { IPrivateLotsDetailsItem } from "../interface";
import GrydCalendarPicker from "../../BookingsCalendar/GrydCalendarPicker";
import { EnumCalendarPosition } from "../../BookingsCalendar/interfaces";
import { DateTimeHelper } from '../../../Helpers/DateTimeHelper';
import { format } from "date-fns";
import CloseIcon from '@material-ui/icons/Close';


export default class CustomRenewParkingPass extends React.Component<ICustomRenewParkingPassProps, ICustomRenewParkingPassState> {


    constructor(props_: ICustomRenewParkingPassProps) {
        super(props_);

        this.state = {
            isSaveComplete: false,
            expirationDate: null,
            isShowMore: false,
            isRenew: false,
            renewType: "1 Month"
        };
    }



    componentDidMount() {
        // if (this.props.parkingPasses && this.props.parkingPasses.length > 1) {
        //     if (this.props.parkingPasses.length > 5) {

        //     }
        // }
        const { isRenew, parkingPasses } = this.props;
        if (!isRenew && parkingPasses.length == 1) {
            let endDate_ = this.props.parkingPasses[0].leaseEndDateLocal;

            if (!endDate_) {
                endDate_ = new Date();
            }

            this.setState({
                expirationDate: endDate_
            })
        }

    }

    componentDidUpdate(prevProps: Readonly<ICustomRenewParkingPassProps>) {
        const { isRenew, parkingPasses } = this.props;
        if (isRenew && JSON.stringify(parkingPasses) !== JSON.stringify(prevProps.parkingPasses)) {
            this.setState({ isRenew });
        }
    }


    render() {
        let unitNumbers: string[] = [];
        const { parkingPasses, isRenew, updateParkingPasses, isRenewUpcoming } = this.props;
        if ((isRenew && parkingPasses && parkingPasses.length > 0) || (parkingPasses && parkingPasses.length > 1)) {
            unitNumbers = parkingPasses.filter(parkPass => parkPass.yardiRedirectURL === "").map(parkingPass => parkingPass.unitNumber)
                .filter((value, index, self) => self.indexOf(value) === index);
        }
        return (
            <div className='padd-tenant-container'>
                <h4 className="padd-tenant-heading">Renew Parking Pass</h4>
                {this.state.isSaveComplete && this.state.expirationDate ? (
                    <>
                        <div className="d-flex flex-coloumn justify-content-center mt-5 pt-4">
                            <div className="d-flex flex-column text-center" style={{ flex: 1 }}>

                                <div>
                                    <CheckCircle className="check-icon-size" />
                                </div>
                                <div className="padd-tenant-exisiting-msg mt-3">
                                    {this.props.parkingPasses.length == 1 ? (<>Parking pass for unit number <b>{this.props.parkingPasses[0].unitNumber}</b></>)
                                        : (<>
                                            Parking passes for the {this.props.parkingPasses.length} tenants
                                        </>)}
                                </div>
                                <div className="padd-tenant-exisiting-msg">
                                    has been successfully renewed
                                </div>
                                <div className="padd-tenant-exisiting-msg">
                                    until <b>{format(DateTimeHelper.dateStringToDate(this.state.expirationDate), "MMMM dd, yyyy")}</b>
                                </div>

                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="add-tenant-pl-panel-body">
                            <p className="drawer-panel-description mt-2 mb-0">To renew the parking pass for this tenant,
                                please select a new date for this pass to expire.
                            </p>
                            {(!isRenewUpcoming && ((isRenew && this.props.parkingPasses && this.props.parkingPasses.length > 0) ||
                                (this.props.parkingPasses && this.props.parkingPasses.length > 1))) && (
                                    <>
                                        <div className="add-tenant-block-pl">
                                            <div className="panel-body-field-label pb-2 header-p-border-bottom">Unit(s)</div>
                                            <div className="d-flex flex-column pb-2">
                                                {this.state.isShowMore ?
                                                    (<>
                                                        {unitNumbers.map(unitNumber => {
                                                            return (
                                                                <> {!isRenew && <div>{unitNumber}</div>}
                                                                    {isRenew && <><Grid container>
                                                                        <Grid item>
                                                                            {unitNumber}
                                                                        </Grid>
                                                                        {unitNumbers.length > 1 && <Grid item xs>
                                                                            <Button size="small" className="ignoreUnit"
                                                                                onClick={() => {
                                                                                    const parkingLeases = parkingPasses.filter(item => item.unitNumber !== unitNumber);
                                                                                    updateParkingPasses && updateParkingPasses(parkingLeases);
                                                                                }}>
                                                                                <CloseIcon fontSize="small" className="ignoreUnitCloseIcon" />
                                                                            </Button>
                                                                        </Grid>
                                                                        }
                                                                    </Grid></>}
                                                                </>
                                                            )
                                                        })}
                                                        <div className="header-p-border-top header-p-border-bottom py-2 my-2">
                                                            <a
                                                                className="pl-show-more"
                                                                onClick={() => this.setState({ isShowMore: false })}
                                                            >See Less</a>
                                                        </div>
                                                    </>) :
                                                    (<>
                                                        {unitNumbers.slice(0, 5).map(unitNumber => {
                                                            return (
                                                                <> {!isRenew && <div>{unitNumber}</div>}
                                                                    {isRenew && <><Grid container>
                                                                        <Grid item>
                                                                            {unitNumber}
                                                                        </Grid>
                                                                        {unitNumbers.length > 1 && <Grid item xs>
                                                                            <Button size="small" className="ignoreUnit"
                                                                                onClick={() => {
                                                                                    const parkingLeases = parkingPasses.filter(item => item.unitNumber !== unitNumber);
                                                                                    updateParkingPasses && updateParkingPasses(parkingLeases);
                                                                                }}>
                                                                                <CloseIcon fontSize="small" className="ignoreUnitCloseIcon" />
                                                                            </Button>
                                                                        </Grid>
                                                                        }
                                                                    </Grid></>}
                                                                </>
                                                            )
                                                        })}
                                                        {unitNumbers.length > 5 && (
                                                            <div className="header-p-border-top header-p-border-bottom py-2 my-2">
                                                                <a
                                                                    className="pl-show-more"
                                                                    onClick={() => this.setState({ isShowMore: true })}
                                                                >+ {unitNumbers.length - 5} More</a>
                                                            </div>
                                                        )}
                                                    </>)
                                                }
                                            </div>
                                        </div>
                                    </>
                                )}
                            {!isRenewUpcoming && <div className="add-tenant-block-pl">
                                <div className="panel-body-field-label">Expiration Date</div>
                                <GrydCalendarPicker
                                    placeHolder="End Date"
                                    defaultDate={this.getEndDate()}
                                    onDateChange={(date: Date | null) => {
                                        if (date != null) {
                                            this.setState({
                                                expirationDate: date
                                            });
                                        }
                                    }}
                                    minDate={this.getMinDate()}
                                    isMandate={true}
                                    calendarPosition={EnumCalendarPosition.Bottom}
                                />
                                <div>
                                    {/* <Calendar
                                            formatShortWeekday={(locale, date) => {
                                                let weekday = new Date(date).toLocaleString('en-us', { weekday: 'short' });
                                                return weekday.slice(0, -1);
                                            }}
                                            value={this.state.expirationDate}
                                            calendarType={'US'}
                                            onChange={(date: Date) => {
                                                this.setState({
                                                    expirationDate: date
                                                })
                                            }}
                                        /> */}
                                </div>
                            </div>
                            }
                            {
                                isRenewUpcoming && <div className="add-tenant-block-pl">
                                    <div className="d-flex flex-column pb-2 radio-buttons-group">
                                        <FormControl component="fieldset" className="mt-4">
                                            <RadioGroup
                                                name="renewType"
                                                value={this.state.renewType}
                                                onChange={(event: any, value: string) => {
                                                    this.setState({ renewType: value });
                                                }}
                                                className="ml-3">
                                                <FormControlLabel value={"1 Month"} control={<Radio />} label="Renew for 1 month" />
                                                <FormControlLabel value={"1 Year"} control={<Radio />} label="Renew for 1 year" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                            }
                        </div>
                        <div>
                            <Row className="mt-3 mx-0">
                                <Col>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={(e) => {
                                            console.log(this.state.expirationDate)
                                            if ((this.state.expirationDate || isRenewUpcoming) && this.props.onSaveCustomRenew) {
                                                let { renewType } = this.state;
                                                if(!isRenewUpcoming){
                                                    renewType = "custom"
                                                }
                                                this.props.onSaveCustomRenew(this.state.expirationDate ?? new Date(), renewType);
                                            }
                                            this.setState({
                                                isSaveComplete: true
                                            });
                                        }}
                                        disabled={isRenewUpcoming ? !this.state.renewType  : !this.state.expirationDate}
                                    >Save</Button>
                                </Col>
                                <Col>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        onClick={(e) => {
                                            if (this.props.onClose) {
                                                this.props.onClose();
                                            }
                                        }}
                                    >Cancel</Button>
                                </Col>
                            </Row>
                        </div>
                    </>
                )}
                <div></div>
            </div>
        )
    }

    private getEndDate() {
        const { isRenew, parkingPasses } = this.props;
        if (!isRenew && parkingPasses.length == 1) {
            let endDate_ = this.props.parkingPasses[0].leaseEndDateLocal;

            if (!endDate_) {
                endDate_ = new Date();
            }

            return endDate_;
        }

        return null;
    }

    private getMinDate() {
        const { isRenew, parkingPasses } = this.props;
        if (!isRenew && parkingPasses.length == 1) {
            let minDate_ = this.props.parkingPasses[0].leaseStartDateLocal;
            let currentDate = new Date();

            if (!minDate_ || minDate_.getTime() < currentDate.getTime()) {
                minDate_ = new Date();
            }
            return minDate_;
        }

        return new Date();

    }

}

interface ICustomRenewParkingPassProps {
    onClose: () => void;
    parkingPasses: IPrivateLotsDetailsItem[];
    onSaveCustomRenew?: (expirationDate: Date, renewType?: string) => void;
    isRenew?: boolean;
    updateParkingPasses?: (parkingPasses: IPrivateLotsDetailsItem[]) => void;
    isRenewUpcoming?: boolean;
}

interface ICustomRenewParkingPassState {
    isSaveComplete: boolean;
    expirationDate: Date | null,
    isShowMore: boolean,
    isRenew: boolean,
    renewType: string
}
