import * as React from 'react';
import './Login.css';
import { ConfigService } from '../Services/ConfigService';
import * as CommonString from '../CommonString';
import { FaEdit, FaEye, FaEyeSlash, FaArrowRight } from 'react-icons/fa';
import { ShowAlert } from '../Common/ShowAlert';

/*Material UI */
import logo from '../Images/gryd_logo.png';
import passwordKey from '../Images/password-key.png';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@material-ui/core';
import classes from '*.module.css';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { UserDetailsModel } from '../Models/UserDetails';
import ReCAPTCHA from "react-google-recaptcha";
import LoaderComponent from '../Components/LoaderComponent';
import BaseService from '../Services/BaseService';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { NavToHomeScreen } from '../Common/CheckScreenPermission';
import UserService from '../Services/UserService';


interface LoginProps {

}

interface LoginState {
    emailAddress: string,
    password: string,
    verficationCode: string,
    loginStatus: boolean,
    errorMessage: string,
    emailError: boolean,
    passwordError: boolean,
    isOtpSent: boolean,
    showBlocker: boolean,
    isPasswordVisible: boolean,
    userDetails: UserDetailsModel,
    notRobot: boolean,
    isLockedOut: boolean,
    lockedOutMessage: string,
    lockedOutTitle: string,
}

class Login extends React.Component<LoginProps, LoginState> {
    private _userService: UserService;
    private recaptchaRef: any = null;
    constructor(props: LoginProps) {
        super(props);

        this.state = {
            emailAddress: '',
            password: '',
            verficationCode: '',
            loginStatus: false,
            errorMessage: '',
            emailError: false,
            passwordError: false,
            isOtpSent: false,
            showBlocker: false,
            isPasswordVisible: false,
            userDetails: {
                accessToken: '',
                emailId: '',
                fullName: '',
                mobileNumber: '',
                userRoleID: [],
                isPlaidVerified: false
            },
            notRobot: false,
            isLockedOut: false,
            lockedOutMessage: '',
            lockedOutTitle: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleResend = this.handleResend.bind(this);

        this._userService = new UserService();
    }

    public componentDidMount = () => {
        if (typeof (Storage) !== 'undefined') {
            // localStorage.clear();
            var _localData = localStorage.getItem('userdata');
            var _accesstoken = "";

            if (_localData != null && _localData !== '') {
                var _data = JSON.parse(_localData);
                this.getAccessibleScreen();
            }
        }
    }

    public render() {

        return (
            <div className={'App login-page' + (this.state.showBlocker ? ' show-blocker' : '')}>                
                <Grid container component="main" className='root'>
                    <CssBaseline />                    
                    <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square className="login-form-container">                        
                        <div className='login-paper'>
                            <form className='form' onSubmit={this.handleSubmit}>
                                <img src={logo} className="login-logo" />
                                <h1 className="gryd-h1">
                                    {
                                        this.state.isLockedOut == false && ((this.state.isOtpSent === false) ? 'Sign in to Gryd' : 'Check your mobile')
                                    }
                                </h1>

                                {
                                    this.state.isLockedOut && <div className='locked-message'>
                                        <div>{this.state.lockedOutTitle}</div>
                                        <br />
                                        <div>{this.state.lockedOutMessage} or <Link href="#" variant="body2" onClick={() => {
                                            window.location.href = 'forgotpassword';
                                        }}>reset your password</Link>.</div>
                                    </div>
                                }

                                {
                                    (this.state.isOtpSent === false) ? this.loginScreen() : this.OTPScreen()
                                }
                            </form>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }

    private loginScreen() {
        return (
            <div>
                <div className='email-container'>
                    <MailOutlineIcon className='email-logo' />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Email"
                        name="name"
                        autoComplete="name"
                        value={this.state.emailAddress}
                        onChange={this.handleChange}
                        error={this.state.emailError}
                        helperText={this.state.emailError ? this.state.errorMessage : ''}
                        autoFocus
                    />
                </div>

                <FormControl variant="outlined" className="MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth password-container">
                    <img src={passwordKey} className="password-logo" />
                    <InputLabel htmlFor="outlined-adornment-password" error={this.state.passwordError}>Password * </InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        name="password"
                        required
                        type={this.state.isPasswordVisible ? 'text' : 'password'}
                        value={this.state.password}
                        error={this.state.passwordError}
                        onChange={this.handleChange}
                        endAdornment={
                            <InputAdornment position="end">                                
                                <div
                                    onClick={() => this.togglePasswordVisible()}
                                    className="login-show">
                                    {this.state.isPasswordVisible ? "hide" : "show"}
                                </div>
                            </InputAdornment>
                        }
                        labelWidth={70}

                    />
                    <span className="errormsg">{this.state.passwordError ? this.state.errorMessage : ''}</span>

                </FormControl>

                <Grid container>
                    <Grid item xs>
                    </Grid>
                    <Grid item>
                        <Link href="#" variant="body2" onClick={() => {                            
                            window.location.href = 'forgotpassword';
                        }}>Forgot password?</Link>
                    </Grid>
                </Grid>

                <div className="captcha-container">
                    <ReCAPTCHA
                        ref={(r: any) => this.recaptchaRef = r}
                        sitekey="6LdPLqoZAAAAABep9FvGo1TCIyzIt-BCSddLBI16"
                        onChange={(value: any) => {
                            this.onChangeCaptcha(value);
                        }}
                        onExpired={() => {
                            this.onExipiredCaptcha();
                        }}
                    />
                </div>

                <Grid container>
                    <Grid item xs>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className='submit login-button'
                        >
                            Let's go <FaArrowRight size="1em" className="sign-in-button-icon" />
                        </Button>
                    </Grid>
                </Grid>
            </div>
        )
    }

    private onChangeCaptcha(value: any) {
        this.setState({
            notRobot: true
        })
    }

    private onExipiredCaptcha() {
        if (!this.state.isOtpSent) {
            this.setState({
                notRobot: false
            })
        }
    }

    private OTPScreen() {
        return (
            <div>
                <p>We've sent a confirmation code. It will expire shortly, so enter it soon</p>
                <Link href="#" className="resendOtp" variant="body2" onClick={() => { this.requestSignIn('resend'); }}>Resend code</Link>

                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Confirmation code"
                    name="otp"
                    value={this.state.verficationCode}
                    onChange={this.handleChange}
                    onPaste={this.checkPasteValue}
                    onKeyPress={this.checkValue}
                    error={this.state.passwordError}
                    helperText={this.state.passwordError ? this.state.errorMessage : ''}
                    autoFocus
                />

                <Grid container>
                    <Grid item xs>
                    </Grid>
                    <Grid item xs>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className='submit login-button'
                        >
                            Start using Gryd <FaArrowRight size="1em" className="sign-in-button-icon" />
                        </Button>
                    </Grid>
                </Grid>

            </div>
        )
    }

    private togglePasswordVisible() {
        this.setState({
            isPasswordVisible: !this.state.isPasswordVisible
        });
    }

    private DisplayMessage() {
        return (
            <div className="signInMessage">Enter the <span className="bold">verification code</span> that was sent to <span className="bold highlight">{this.state.emailAddress}</span><span className="EditButton" onClick={() => this.changeEmail()}><FaEdit size={'1.5em'} color={'#00000'} /></span></div>
        );
    }

    private changeEmail = () => {
        this.setState({
            isOtpSent: false
        });
    }

    private handleChange(event: any) {
        var _targetValue = event.target.value;
        if (event.target.name === 'name') {
            this.setState({
                emailAddress: _targetValue,
                emailError: isNaN(_targetValue) ? !this.validateEmail(_targetValue) : !this.validateMobile(_targetValue),                
                errorMessage: ''
            });
        } else if (event.target.name === 'password') {
            this.setState({
                passwordError: false,
                password: _targetValue,
                errorMessage: ''
            });
        } else if (event.target.name === 'otp') {
            this.setState({
                passwordError: false,
                verficationCode: _targetValue,
                errorMessage: ''
            });
        }
    }

    private checkValue(event: any) {
        var charCode = (event.which) ? event.which : event.keyCode;

        if (charCode > 31 && (charCode < 48 || charCode > 57) || charCode === 101 || charCode === 69) {
            event.preventDefault();
            return false;
        }
        if (event.target.value.length >= 6) {
            event.preventDefault();
            return false;
        }

        return true;
    }

    private checkPasteValue(event: any) {
        var str = event.clipboardData.getData('Text');

        if ((str.length > 6) || isNaN(str)) {
            event.preventDefault();
            return false;
        }
        return true;
    }

    private handleResend() {
        this.setState({
            errorMessage: '',
            showBlocker: true
        });
    }

    private showPageUnderConstruction() {        
        ShowAlert('Success!', 'The page is under construction', 'success');
    }

    private async signIn(email: string, password: string) {

        var postData = { "UserName": email, "Password": password };

        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + CommonString.SERVICE_SIGN_IN, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(postData)
                }, 'ignoreAlert');
            });
    }

    private VerifyOTP() {
        var postData = { "MobileOTP": this.state.verficationCode };

        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + CommonString.SERVICE_VERIFY_OTP, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postData)
                });
            });
    }

    private validateJsonString(str: string) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    private validateEmail(email: string) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    private validateMobile(mobile: string) {
        var phoneno = /^\d{10}$/;
        return phoneno.test(mobile);
    }

    private handleSubmit(event: any) {

        if (!this.state.isOtpSent) {
            var userInput = this.state.emailAddress;
            let isMobileNumber = isNaN(Number(userInput));
            let isValidEmail = isMobileNumber ? this.validateEmail(userInput) : this.validateMobile(userInput);            

            if (!isValidEmail) {
                this.setState({
                    errorMessage: isMobileNumber ? 'Please enter valid email ID' : 'Please enter valid mobile number',
                    emailError: true,
                    showBlocker: false
                });
                event.preventDefault();
            } else {
                if (this.state.password.trim() === '') {
                    this.setState({
                        errorMessage: 'Please enter the password',
                        passwordError: true,
                        showBlocker: false
                    });
                    event.preventDefault();
                    return false;
                }

                if (this.state.notRobot) {
                    this.requestSignIn();
                } else {
                    ShowAlert('Error!', 'Please verify you are a real person.', 'error');
                }
            }
        } else {
            if (this.state.verficationCode.length < 6) {
                this.setState({
                    errorMessage: 'Please enter the valid verification code',
                    passwordError: true
                });
            } else {
                this.VerifyOTP().then(response => {
                    if (response.ok) {
                        response.text().then(data => {
                            let _userDetails: UserDetailsModel = this.state.userDetails;
                            _userDetails.accessToken = data;
                            localStorage.setItem('userdata', JSON.stringify(_userDetails));                            
                            this.getAccessibleScreen();
                        })
                    } else {
                        let state: any = {
                            showBlocker: false
                        };
                        response.text().then(data => {
                            state.errorMessage = 'Please enter the valid verification code';
                            state.passwordError = true;

                            this.setState(state);
                        })
                    }
                })
            }
        }
        event.preventDefault();
        return false;
    }

    private getAccessibleScreen() {
        this._userService.getAccessibleScreen()
            .then(r => {
                if (r.ok) {
                    r.json().then(results => {
                        var resultString = JSON.stringify(results).toLocaleLowerCase();
                        localStorage.setItem('accessibleScreen', resultString);
                        NavToHomeScreen();
                    })
                }
            })
    }

    private requestSignIn(isResend?: string) {
        this.setState({
            showBlocker: true
        });
        this.signIn(this.state.emailAddress, this.state.password).then(signInResponse => {
            if (signInResponse.ok) {
                let state: any = {
                    showBlocker: false
                };
                signInResponse.text().then(resp => {
                    console.log(JSON.parse(resp));
                    let _userDetails: UserDetailsModel = JSON.parse(resp);
                    localStorage.setItem('userdata', JSON.stringify(_userDetails));                    
                    this.getAccessibleScreen();
                });
            } else {
                signInResponse.text().then(resp => {
                    if (this.recaptchaRef) {
                        this.recaptchaRef.reset();
                    }

                    let state: any = {
                        showBlocker: false,
                        notRobot: false,
                        isLockedOut: false
                    };

                    if (resp === '"Invalid user name!"') {
                        state.errorMessage = 'Entered email is incorrect or not registered.';
                        state.emailError = true;
                    } else if (resp === '"Incorrect password!"') {
                        state.errorMessage = JSON.parse(resp);
                        state.passwordError = true;
                        state.password = '';
                    } else if (resp === '"User doesn\'t have a valid password!"') {
                        state.errorMessage = 'Entered email/mobile is incorrect or not registered.';
                        state.emailError = true;
                        state.password = '';
                    } else {
                        state.errorMessage = JSON.parse(resp);
                        if (state.errorMessage.isLockedOut) {
                            state.isLockedOut = true;
                            state.lockedOutMessage = state.errorMessage.message;
                            state.lockedOutTitle = state.errorMessage.title;
                        } else {
                            ShowAlert('Server Error!', state.errorMessage.toString(), 'error');
                        }
                    }

                    this.setState(state);
                });

            }
        }).catch(error => {
            this.setState({
                errorMessage: '',
                showBlocker: false
            });
            ShowAlert('Server Error!', error.toString(), 'error');
        });
    }

    private postJsonService(serviceUrl: string, postData: any) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + serviceUrl, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(postData)
                })
                    .then(response => response.json());
            });
    }
}

export default Login;
