import React from "react";
import DataGrid from "../../Components/DataGrid/DataGrid";
import { IDataGridColumn, IAppliedFilter, DataGridFilterType } from "../../Components/DataGrid/Interfaces";
import { format } from 'date-fns';
import DataGridToolbar from "../../Components/DataGrid/DataGridToolbar";
import { Grid, Drawer, Button, Tooltip } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { RightPanelState } from "../../Models/CommonInterfaces";
import PropertyService from "../../Services/PropertyService";
import { IProperty, IPropertyFilter } from "./Interfaces";
import PropertyDetailView from "./PropertyDetailView";
import PropertyEditView from "./PropertyEditView";
import PropertyFilterView from "./PropertyFilterView";
import { ShowAlert } from "../../Common/ShowAlert";
import { PreserveFilters } from "../../Common/PreserveFilters";


export default class PropertyListView extends React.Component<{}, IPropertyListState> {
    private _spotListColumns: IDataGridColumn<IProperty>[] = [
        {
            key: "propertyStatusId",
            name: "STATUS",
            contentProvider: (row_: IProperty) => {
                return (
                    <span>
                        <Tooltip title={((row_.propertyStatusId === 1) ? 'Active' : 'Inactive')}>
                            <div className={'list-status ' + ((row_.propertyStatusId === 1) ? 'active' : 'inactive')}></div>
                        </Tooltip>
                    </span>
                );
            }
        },
        {
            key: "propertyName",
            name: "NAME"
        },
        {
            key: "propertyAddress1",
            name: "ADDRESS"
        },
        {
            key: "propertyCity",
            name: "CITY"
        },
        {
            key: "propertyOwnerName",
            name: "OWNER"
        },
        {
            key: "propertyCompanyName",
            name: "COMPANY"
        },
        {
            key: "propertyQuantity",
            textAlignment: "right",
            name: "SPOTS"
        },
        {
            key: "Revenue",
            name: "REVENUE YTD",
            textAlignment: "right",
            contentProvider: (row_: IProperty) => {
                return (
                    <span>
                        ${row_.Revenue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                );
            }
        }
    ]
    _isFiltered: boolean = false;

    constructor(props: any) {
        super(props);

        this.state = {
            allProperties: [],
            filteredProperties: [],
            subFilteredProperties: [],
            searchTerm: "",
            rightPanelState: RightPanelState.None,
            isFiltered: false,
            _autoApplyFilterKey: 0,
            AddEditPropertyValue: {
                propertyAddress1: '',
                propertyAddress2: '',
                propertyCity: '',
                propertyCompanyAddress1: '',
                propertyCompanyAddress2: '',
                propertyCompanyCity: '',
                propertyCompanyCountry: '',
                propertyCompanyGuid: '',
                propertyCompanyName: '',
                propertyCompanyState: '',
                propertyCompanyZipCode: '',
                propertyCountry: '',
                propertyGuid: '',
                propertyName: '',
                propertyOwnerGuid: '',
                propertyOwnerName: '',
                propertyState: '',
                propertyZipCode: '',
                propertyQuantity: 0,
                Revenue: 0,
                RevenueText: '',
                propertyStatusId: 1,
                propertyAbout: '',
                propertyAmenities: [],
                propertyMaxPrice: 0,
                propertyMinPrice: 0,
                propertyRedeem: '',
                propertySchedule: [],
                images: []
            }
        };
    }

    public componentDidMount = () => {
        PropertyService.getPropertyList().then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    let rowItems: any[] = resp;

                    let mappedItems = rowItems.map(r => {
                        let _randomRevenueAmount = this.setRandomRevenue();
                        let _propertyAmenities: number[] = r.propertyAmenities;

                        return {
                            propertyGuid: r.propertyGuid,
                            propertyName: r.propertyName ? r.propertyName : '',
                            propertyAbout: r.propertyAbout,
                            propertyAddress1: r.propertyAddress1 ? r.propertyAddress1 : '',
                            propertyAddress2: r.propertyAddress2 ? r.propertyAddress2 : '',
                            propertyCity: r.propertyCity ? r.propertyCity : '',
                            propertyState: r.propertyState,
                            propertyCountry: r.propertyCountry,
                            propertyZipCode: r.propertyZipCode,
                            propertyQuantity: r.propertyQuantity,
                            propertyRedeem: r.propertyRedeem,
                            propertyMinPrice: r.propertyMinPrice,
                            propertyMaxPrice: r.propertyMaxPrice,
                            propertyAmenities: _propertyAmenities,
                            propertySchedule: r.propertySchedule,
                            propertyOwnerName: r.propertyOwnerName ? r.propertyOwnerName : '',
                            propertyOwnerGuid: r.propertyOwnerGuid,
                            propertyCompanyName: r.propertyCompanyName ? r.propertyCompanyName : '',
                            propertyCompanyGuid: r.propertyCompanyGuid,
                            propertyCompanyAddress1: r.propertyCompanyAddress1,
                            propertyCompanyAddress2: r.propertyCompanyAddress2,
                            propertyCompanyCity: r.propertyCompanyCity,
                            propertyCompanyCountry: r.propertyCompanyCountry,
                            propertyCompanyState: r.propertyCompanyState,
                            propertyCompanyZipCode: r.propertyCompanyZipCode,
                            images: r.images,
                            propertyStatusId: r.propertyStatusId,
                            Revenue: _randomRevenueAmount,
                            rawData: r
                        };
                    });

                    this.setState(
                        {
                            allProperties: mappedItems,
                            subFilteredProperties: mappedItems,
                            filteredProperties: mappedItems,
                            appliedFilter:PreserveFilters.readPreservedFilter('properties')
                        },()=>{
                            this.filterItems()
                        }
                    );
                })
            } 
            /*else {
                if (response.status === 401) {
                    this.accessTokenExpired();
                    console.log(response);
                } else {
                    response.text().then(resp => {
                        console.log(response);
                    });
                    ShowAlert("", 'There was an error processing a your request.', "error");
                }
            }*/
        })
    }


    protected filterItems = () => {
        this._isFiltered = false;
        let filteredArray= this.state.allProperties.filter(s => {
            let isValid = true;
            let filteredCities = this.state.appliedFilter?.city.filterValue as string[];
            let FilterCompanies = this.state.appliedFilter?.company.filterValue as string[];

            if (filteredCities.length > 0 && isValid) {
                this._isFiltered = true;
                isValid = filteredCities.indexOf(s.propertyCity) > -1;
            }

            if (FilterCompanies.length > 0 && isValid) {
                this._isFiltered = true;
                isValid = FilterCompanies.indexOf(s.propertyCompanyName) > -1;
            }

            return isValid;
        });
        this.setState({subFilteredProperties:filteredArray})
    }
    render() {
        if (this.state.allProperties.length == 0) {
            return null;
        }
        return (
            <div className="m-3 data-grid-container">
                {/* Header region of the page */}
                <Grid container className="mb-3">
                    <Grid item>
                        {/* Title of the page */}
                        <h1 className="list-header">Propertiesssss</h1>
                    </Grid>
                    <Grid item xs>
                        {/* Grid toolbar */}<h1>hello</h1>
                        <DataGridToolbar
                            singularEntityName="property"
                            pluralEntityName="properties"
                            onSearch={this._searchTermChange}
                            isFiltered={this.state.isFiltered}
                            onFilterClick={() => {
                                this.setState({
                                    rightPanelState: RightPanelState.Filter
                                });
                            }}
                            onAddClick={() => {
                                this.setState({
                                    rightPanelState: RightPanelState.Add
                                });
                            }}
                        />
                    </Grid>
                </Grid>

                {/* Data Grid */}
                <DataGrid
                    title="Spot"
                    data={this.state.subFilteredProperties}
                    columns={this._spotListColumns}
                    appliedFilter={this.state.appliedFilter}
                    appliedSearch={this.state.searchTerm}
                    onFilterTagRemoved={(filter_: IAppliedFilter) => {
                        this.setState({
                            appliedFilter: filter_ as IPropertyFilter,
                            _autoApplyFilterKey: new Date().getTime()
                        },()=>{
                            PreserveFilters.preserveFilter('properties',this.state.appliedFilter)
                        });
                    }}
                    onMoreClick={this._handleMoreClick}
                />

                {/* Right side drawer */}
                <Drawer
                    anchor="right"
                    variant="persistent"
                    open={this.state.rightPanelState != RightPanelState.None}
                    className="data-grid-right-panel"
                >
                    <div className="mx-3">
                        <Grid container className="right-panel-header p-2 border-bottom">
                            <Grid item xs>
                                <h4 className="right-panel-title m-0 ml-3">
                                    {this._getRightPanelTitle()}
                                </h4>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={() => this.setState({ rightPanelState: RightPanelState.None })}>
                                    <CloseIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    {this._getRightPanelContent()}
                </Drawer>
            </div>
        );
    }

    private _getRightPanelTitle(): string {
        switch (this.state.rightPanelState) {
            case RightPanelState.Filter:
                return "Filter";
            case RightPanelState.Details:
                return "Details";
            case RightPanelState.Edit:
                return "Edit";
            case RightPanelState.Add:
                return "Add";
        }

        return "";
    }

    private _getRightPanelContent() {
        return [
            <PropertyFilterView
                isVisible={this.state.rightPanelState == RightPanelState.Filter}
                data={this.state.allProperties}
                appliedFilter={this.state.appliedFilter}
                onFilterChange={(appliedFilter_, filteredData_, isFiltered_) => {
                    this.setState({
                        appliedFilter: appliedFilter_,
                        filteredProperties: filteredData_,
                        subFilteredProperties: this._filterDataBySearch(filteredData_, this.state.searchTerm),
                        isFiltered: isFiltered_
                    },()=>{
                        
                        PreserveFilters.preserveFilter('properties',this.state.appliedFilter)
                        console.log(PreserveFilters.readPreservedFilter('properties'))
                    })
                }}
                onClose={() => this.setState({ rightPanelState: RightPanelState.None })}
                autoApplyFilterKey={this.state._autoApplyFilterKey}
            />,
            <PropertyDetailView />,
            <PropertyEditView
                onClose={() => this.setState({ rightPanelState: RightPanelState.None })}
                selectedRecord={this.state.AddEditPropertyValue}
                isVisible={(this.state.rightPanelState == RightPanelState.Edit || this.state.rightPanelState == RightPanelState.Add)}
            />
        ];
    }

    private _searchTermChange = (searchTerm_: string): void => {
        this.setState({
            searchTerm: searchTerm_,
            subFilteredProperties: this._filterDataBySearch(this.state.filteredProperties, searchTerm_)
        });
    }

    private _filterDataBySearch = (data_: IProperty[], searchTerm_: string): IProperty[] => {
        return data_.filter(d => {
            return d.propertyName.toLowerCase().indexOf(searchTerm_.toLowerCase()) > -1;
        });
    }

    private _handleMoreClick = (item_: IProperty): void => {

    }

    private accessTokenExpired() {
        localStorage.clear();
        window.location.href = '/';
    }

    private setRandomRevenue() {
        let revenue: number = Math.floor(Math.random() * 100000) + 5000;
        return revenue;
    }
}

interface IPropertyListState {
    allProperties: IProperty[];
    filteredProperties: IProperty[];
    subFilteredProperties: IProperty[];
    rightPanelState: RightPanelState;
    searchTerm: string;
    appliedFilter?: IPropertyFilter;
    isFiltered: boolean;
    _autoApplyFilterKey: number;
    AddEditPropertyValue: IProperty
}