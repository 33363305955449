import React from "react";
import { ISpot, IPropertyDetails, ISpotFeature, IPropertyBasics, IBasicUser, extractScheduleLimitationsFromProperty, IAdditionalFeature, getScheduleSummaryText, IZoneBasics } from "./Interfaces";
import { Row, Col } from "reactstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropertyService from "../../Services/PropertyService";
import { TextField, Button, FormControlLabel, Checkbox, FormLabel, InputLabel } from "@material-ui/core";
import SpotService from "../../Services/SpotService";
import { ShowAlert, ShowAlertwithConfirm, ShowAlertwithOptions } from "../../Common/ShowAlert";
import TextTagsField from "../../Components/BasicComponents/TextTagsField";
import MultiScheduleComponent from "../../Components/MultiScheduleComponent";
import UserService from "../../Services/UserService";
import { ISingleScheduleData, IScheduleLimitation } from "../../Components/ScheduleComponent";
import ArrayHelper from "../../Helpers/ArrayHelper";
import { GetUserDetailsService } from "../../Services/GetUserDetailsService";
import ObjectHelper from "../../Helpers/ObjectHelper";
import LoaderComponent from "../../Components/LoaderComponent";
import { IReservationChunk, ISpotBlockMetadata, SpotBlock } from "../BookingsCalendar/interfaces";
import { DateTimeHelper } from "../../Helpers/DateTimeHelper";
import { IZone } from "../Zone/Interfaces";

export default class SpotEditView extends React.Component<ISpotEditViewProps, ISpotEditViewState> {
    private _propertyService: PropertyService;
    private _spotService: SpotService;
    private _userService: UserService;

    private _propertySelectionVisible: boolean = true;
    private _spotSelectionVisible: boolean = true;
    private _propertyAmenitiesVisible: boolean = true;
    private _tenantVisible: boolean = true;

    state: ISpotEditViewState = {
        isReady: false,
        properties: [],
        featureList: [],
        permissionsList: [false, false, false],
        managers: [],
        spotNames: [],
        selectedProperty: null,
        selectedPropertyDetails: null,
        otherFeatures: "",
        existingSpotNames: [],
        // scheduleLimitation: extractScheduleLimitationsFromProperty(null),
        propertyAdditionalFeatures: [],
        isTenantManaged: false,
        propertyAmenities: [],
        loaderState: false,
        zoneList: [],
        selectedzone: null
    };

    constructor(props_: ISpotEditViewProps) {
        super(props_);

        this._propertyService = new PropertyService();
        this._spotService = new SpotService();
        this._userService = new UserService();
    }

    componentDidMount() {
        let apiCount = 3;
        let properties: IPropertyBasics[] = [];
        let features: ISpotFeature[] = [];
        let permissions: boolean[] = [];
        let permissionKeys: string[] = ['edit-spot-price', 'edit-spot-hours', 'add-delete-spot-schedule'];

        let onApiResponse = () => {
            if (--apiCount == 0) {
                let updatedState: ISpotEditViewState = {
                    isReady: true,
                    properties: properties,
                    featureList: features,
                    permissionsList: permissions
                } as any;

                if (this.props.spot) {
                    updatedState.otherFeatures = this.props.spot.otherFeatures;
                    updatedState.schedules = ObjectHelper.deepClone(this.props.spot.schedules);
                    updatedState.isTenantManaged = (this.props.spot.spotTypeId == 2);

                    let selectedProperty: IPropertyBasics = {
                        propertyGuid: this.props.spot.propertyGuid,
                        propertyName: this.props.spot.propertyName
                    }

                    updatedState.selectedProperty = selectedProperty;
                }

                this.setState(updatedState);
            }
        }

        if (this.props.spot) {
            this._propertySelectionVisible = false;
            this._spotSelectionVisible = false;

            this._spotService.getSpotSchedules(this.props.spot.spotGuid)
                .then(r => r.json())
                .then(r => {
                    if (this.props.spot) {
                        this.props.spot.schedules = r;
                        this._onPropertySelect(this.props.spot.propertyGuid, () => {
                            onApiResponse();
                        });
                    }
                });
        }
        else if (this.props.property) {
            this._propertySelectionVisible = false;
            this._propertyAmenitiesVisible = false;
            this._tenantVisible = false;

            this._onPropertyPreloaded(this.props.property, () => {
                onApiResponse();
            });
        }
        else if (this.props.propertyGuid) {
            // this._propertySelectionVisible = false;
            // this._propertyAmenitiesVisible = false;
            // this._tenantVisible = false;
            this._propertyService.getPropertiesBasics()
                .then(r => r.json())
                .then(r => {
                    properties = r;
                    onApiResponse();
                    if (this.props.propertyGuid) {
                        this._onPropertySelect(this.props.propertyGuid, () => {
                            this.setState({
                                selectedProperty: ArrayHelper.findObject(properties, "propertyGuid", this.props.propertyGuid)
                            })
                        });
                    }
                });
        }
        else {
            this._propertyService.getPropertiesBasics()
                .then(r => r.json())
                .then(r => {
                    properties = r;
                    onApiResponse();
                });
        }

        this._spotService.getFeatureList()
            .then(r => r.json())
            .then(r => {
                features = r;
                onApiResponse();
            });

        this._userService.hasMultipleAccess(permissionKeys)
            .then(r => r.json())
            .then(r => {
                permissions = r;
                onApiResponse();
            });
    }

    componentDidUpdate(oldProps_: ISpotEditViewProps) {
        let updatedState: ISpotEditViewState = {} as any;
        let updated = false;

        if (this.props.spotNames != oldProps_.spotNames) {
            updatedState.spotNames = this.props.spotNames ? this.props.spotNames : [];
            updated = true;
        }
        if (this.props.existingSpotNames != oldProps_.existingSpotNames) {
            updatedState.existingSpotNames = this.props.existingSpotNames ? this.props.existingSpotNames : [];
            updated = true;
        }
        if (this.props.schedules != oldProps_.schedules) {
            updatedState.schedules = this.props.schedules ? this.props.schedules : undefined;
            updated = true;
        }

        if (updated) {
            this.setState(updatedState);
        }
    }

    render() {
        return (
            <div className={"spot-edit-view edit-view py-3" + (this.props.className ? (" " + this.props.className) : "")}>
                {/* <LoaderComponent loaderVisible={this.state.loaderState} /> */}
                {
                    this.state.isReady ?
                        <div className="panel-view-scrollable-area py-3">
                            {
                                //(this._propertySelectionVisible || this._spotSelectionVisible) &&
                                <React.Fragment>
                                    <Row className="px-3">
                                        <Col>

                                            {
                                                this.props.customTitle != undefined ?
                                                    <h4>{this.props.customTitle}</h4>
                                                    : <h4>About</h4>
                                            }
                                        </Col>
                                    </Row>
                                    {
                                        //this._propertySelectionVisible &&
                                        <Row className="mb-3 px-3">
                                            <Col>
                                                <Autocomplete
                                                    disabled={!this._propertySelectionVisible}
                                                    options={this.state.properties}
                                                    value={this.state.selectedProperty}
                                                    onChange={(event: any, selectedItem_: IPropertyBasics | null) => {
                                                        this.setState({
                                                            spotNames: [],
                                                            selectedProperty: selectedItem_,
                                                            managers: []
                                                        }, () => {
                                                            if (this.props.onChange) {
                                                                this.props.onChange(this._hasChanges());
                                                            }

                                                            if (selectedItem_) {
                                                                this._onPropertySelect(selectedItem_.propertyGuid);
                                                            }
                                                            else{
                                                                this._onPropertySelect("00000000-0000-0000-0000-000000000000");
                                                            }
                                                        });
                                                    }}
                                                    getOptionLabel={(option) => option.propertyName}
                                                    renderInput={(params) => <TextField {...params} label="Property"
                                                        error={
                                                            this.state.selectedProperty == null
                                                        }
                                                        variant="outlined" />}
                                                />
                                            </Col>
                                        </Row>
                                    }
                                    <Row className="px-3">
                                        <Col>
                                            <Autocomplete
                                                options={this.state.managers}
                                                value={this.state.managers[0] ? this.state.managers[0] : null}
                                                disabled={true}
                                                getOptionLabel={(option) => option.name}
                                                renderInput={(params) => <TextField {...params} label="Manager" variant="outlined" />}
                                            />
                                        </Col>
                                    </Row>
                                    {
                                        this._spotSelectionVisible &&
                                        <Row className="mb-4 px-3">
                                            <Col>
                                                <TextTagsField
                                                    label="Spot Numbers"
                                                    helperText={`Type spot # or range of # and press enter`} //  (${maxSpotsThatCanBeAdded - this.state.spotNames.filter(s => this.state.existingSpotNames.indexOf(s) === -1).length} remaining)
                                                    splitChar=","
                                                    rangeChar="/"
                                                    // maxTagsCount={maxSpotsThatCanBeAdded}
                                                    skip={this.state.existingSpotNames}
                                                    tags={this.state.spotNames}
                                                    allowAddingSkippedTags={!this._propertySelectionVisible}
                                                    onChange={(tags_: string[]) => {
                                                        this.setState({
                                                            spotNames: tags_
                                                        }, () => {
                                                            if (this.props.onSpotNamesChange) {
                                                                this.props.onSpotNamesChange(tags_);
                                                            }
                                                            if (this.props.onChange) {
                                                                this.props.onChange(this._hasChanges());
                                                            }
                                                        });
                                                    }}
                                                    onAddFail={(existingTags_: string[]) => {
                                                        if (existingTags_ && existingTags_.length) {
                                                            if (existingTags_.length == 1) {
                                                                ShowAlert("", `Spot ${existingTags_[0]} is already added`, "error");
                                                            }
                                                            else {
                                                                ShowAlert("", `Spots ${existingTags_.join(", ")} are already added`, "error");
                                                            }
                                                        }
                                                        // else {
                                                        //     if (maxSpotsThatCanBeAdded == 0) {
                                                        //         ShowAlert("", `Maximum number of spots limit reached for this property`, "error");
                                                        //     }
                                                        //     else {
                                                        //         ShowAlert("", `You can not add more than ${maxSpotsThatCanBeAdded} spots`, "error");
                                                        //     }
                                                        // }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    }
                                </React.Fragment>
                            }

                            {
                                (GetUserDetailsService.getUserDetails().userRoleID.indexOf(6) == -1) && this._tenantVisible &&
                                <React.Fragment>
                                    <Row className="mt-3 mx-0">
                                        <Col>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    onClick={() => {
                                                        let checkChange = () => {
                                                            this.setState({
                                                                isTenantManaged: !this.state.isTenantManaged
                                                            }, () => {
                                                                if (this.props.onChange) {
                                                                    this.props.onChange(this._hasChanges());
                                                                }
                                                            });
                                                        }

                                                        if (this.props.spot && this.state.isTenantManaged && this.props.spot.spotTenantGuid) {
                                                            ShowAlertwithConfirm("", "This spot is already claimed by a tenant. Changing to property managed will remove the link between the spot and the tenant. Are you sure you want to proceed?", "warning")
                                                                .then(proceed_ => {
                                                                    if (proceed_) {
                                                                        checkChange();
                                                                    }
                                                                });
                                                        }
                                                        else {
                                                            checkChange();
                                                        }
                                                    }}
                                                    checked={this.state.isTenantManaged}
                                                    color="primary" />}
                                                label={"Tenant Managed Spot(s)"}
                                            />
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            }
                            {
                                this.state.selectedProperty != null &&
                                <React.Fragment>
                                    <Row className="mb-3 px-3">
                                        <Col>
                                            <Autocomplete
                                                disabled={this.props.spot && this.props.spot.zoneGuid != "00000000-0000-0000-0000-000000000000"}
                                                options={this.state.zoneList}
                                                value={this.state.selectedzone}
                                                onChange={(event: any, selectedItem_: IZoneBasics | null) => {
                                                    this.setState({
                                                        selectedzone: selectedItem_,
                                                    }, () => {
                                                        if (this.props.onChange) {
                                                            this.props.onChange(this._hasChanges());
                                                        }
                                                    });
                                                }}
                                                getOptionLabel={(option) => option.zoneName}
                                                renderInput={(params) => <TextField {...params} label="Zone"
                                                    error={
                                                        this.state.selectedzone == null
                                                    }
                                                    variant="outlined" />}
                                            />
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            }

                            {/* {
                                this.state.selectedProperty != null && this._propertyAmenitiesVisible &&
                                <React.Fragment>
                                    <Row className="px-3">
                                        <Col>
                                            <h4 className="mt-4">Amenities</h4>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3 mx-0">
                                        <Col>
                                            {
                                                this.state.featureList.map((value_, index_) => {
                                                    if (this.state.propertyAmenities && this.state.propertyAmenities.indexOf(value_.spotFeatureId) > -1) {
                                                        let disabledFeaturesList = this.state.otherFeatures.split(',');
                                                        let checkIndex = disabledFeaturesList.indexOf(value_.spotFeatureId.toString());
                                                        let isChecked = checkIndex === -1;

                                                        return (<div key={index_}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={isChecked}
                                                                        color="primary"
                                                                        onChange={() => {
                                                                            if (!isChecked) {
                                                                                disabledFeaturesList.splice(checkIndex, 1);
                                                                            }
                                                                            else {
                                                                                disabledFeaturesList.push(value_.spotFeatureId.toString());
                                                                            }

                                                                            this.setState({
                                                                                otherFeatures: disabledFeaturesList.join(",")
                                                                            }, () => {
                                                                                if (this.props.onChange) {
                                                                                    this.props.onChange(this._hasChanges());
                                                                                }
                                                                            });
                                                                        }}
                                                                    />
                                                                }
                                                                label={value_.featureName}
                                                            />
                                                        </div>
                                                        );
                                                    }
                                                })
                                            }
                                            {
                                                this.state.propertyAdditionalFeatures.map((value_, index_) => (
                                                    <div key={index_}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={!this.state.otherFeatures || this.state.otherFeatures.split(",").indexOf(value_.i) === -1}
                                                                    color="primary"
                                                                    onChange={() => {
                                                                        if (!this.state.otherFeatures) {
                                                                            this.state.otherFeatures = value_.i;
                                                                        }
                                                                        else {
                                                                            let disabledFeatures = this.state.otherFeatures.split(",");
                                                                            let idx = disabledFeatures.indexOf(value_.i);
                                                                            if (idx === -1) {
                                                                                disabledFeatures.push(value_.i);
                                                                            }
                                                                            else {
                                                                                disabledFeatures.splice(idx, 1);
                                                                            }

                                                                            this.state.otherFeatures = disabledFeatures.join(",");
                                                                        }

                                                                        this.setState({
                                                                            otherFeatures: this.state.otherFeatures
                                                                        }, () => {
                                                                            if (this.props.onChange) {
                                                                                this.props.onChange(this._hasChanges());
                                                                            }
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label={value_.n}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            } */}
                            {/* <Row className="mt-3">
                                <Col>
                                    <TextTagsField
                                        label="Additional Amenities"
                                        helperText={`Type additional amenities in the textbox above`}
                                        splitChar=","
                                        tags={this.state.otherFeatures ? this.state.otherFeatures.split(",") : []}
                                        onChange={(tags_: string[]) => {
                                            this.setState({
                                                otherFeatures: tags_.join(",")
                                            });
                                        }}
                                    />
                                </Col>
                            </Row> */}
                            {/* {
                                this.state.selectedProperty != null &&
                                <React.Fragment>
                                    <Row className="px-3">
                                        <Col>
                                            <h4 className="mt-4">Schedule/Price</h4>
                                        </Col>
                                    </Row>
                                    <Row className="px-3">
                                        <Col>
                                            <MultiScheduleComponent
                                                schedules={this.state.schedules}
                                                limitation={this.state.scheduleLimitation}
                                                onChange={(schedule_) => {
                                                    this.setState({
                                                        schedules: schedule_
                                                    }, () => {
                                                        if (this.props.onChange) {
                                                            this.props.onChange(this._hasChanges());
                                                        }
                                                    });
                                                }}
                                                defaultCollapsed={true}
                                                priceDisabled={!this.state.permissionsList[0]}
                                                hoursDisabled={!this.state.permissionsList[1]}
                                                addDeleteDisabled={!this.state.permissionsList[2]}
                                            />
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            } */}
                        </div>
                        : null
                }
                {
                    this.state.isReady ?
                        <Row className="mt-3 mx-0">
                            <Col>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={this._saveSpot}
                                >Save</Button>
                            </Col>
                            <Col>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => { this.props.onClose(false, []); }}
                                >Cancel</Button>
                            </Col>
                        </Row>
                        : null
                }
            </div>
        );
    }

    private _onPropertySelect(propertyGuid_: string, callback_?: () => void): void {
        let apiCount = 3;
        let propertyDetails: IPropertyDetails | null = null;
        let zoneList: IZoneBasics[] = [];
        let managers: IBasicUser[] = [];
        let tenants: IBasicUser[] = [];

        let onApiResponse = () => {
            if (--apiCount == 0) {
                let updatedState: ISpotEditViewState = {
                    selectedPropertyDetails: propertyDetails,
                    managers: managers,
                    tenants: tenants,
                    zoneList: zoneList
                } as any;

                if (propertyDetails) {
                    updatedState.existingSpotNames = propertyDetails.spots.map(s => s.spotName);
                    // updatedState.scheduleLimitation = extractScheduleLimitationsFromProperty(propertyDetails);
                    updatedState.propertyAdditionalFeatures = this._extractPropertyAdditionalFeatures(propertyDetails.otherFeatures);
                    updatedState.propertyAmenities = propertyDetails.propertyAmenities ? propertyDetails.propertyAmenities.slice(0) : [];
                }

                if (this.props.spot != null && this.props.spot.zoneGuid != "00000000-0000-0000-0000-000000000000") {
                    updatedState.selectedzone = zoneList.filter(x => (this.props.spot != null && x.zoneGuid == this.props.spot.zoneGuid))[0];
                }

                this.setState(updatedState, callback_);
            }
        }

        if (propertyGuid_ === "00000000-0000-0000-0000-000000000000") {
            onApiResponse();
        }
        else {
            this._propertyService.getPropertyDetails(propertyGuid_)
                .then(r => r.json())
                .then(r => {
                    propertyDetails = r;
                    if (propertyDetails) {
                        managers = [{
                            applicationUserGuid: propertyDetails.managementCompanyGuid,
                            name: propertyDetails.managementCompanyName
                        }];
                    }
                    onApiResponse();
                });
        }

        // this._userService.getPropertyManagers(propertyGuid_)
        //     .then(r => r.json())
        //     .then(r => {
        //         managers = r;
        //         onApiResponse();
        //     });

        this._userService.getPropertyTenants(propertyGuid_)
            .then(r => r.json())
            .then(r => {
                tenants = r;
                onApiResponse();
            });

        this._spotService.getPropertyZones(propertyGuid_)
            .then(r => r.json())
            .then(r => {
                zoneList = r;
                onApiResponse();
            });
    }

    private _onPropertyPreloaded(propertyDetails_: IPropertyDetails, callback_?: () => void): void {
        let apiCount = 1;
        let managers: IBasicUser[] = [];

        let onApiResponse = () => {
            if (--apiCount == 0) {
                let updatedState: ISpotEditViewState = {
                    selectedPropertyDetails: propertyDetails_,
                    managers: managers
                } as any;

                updatedState.existingSpotNames = propertyDetails_.spots.map(s => s.spotName);
                // updatedState.scheduleLimitation = extractScheduleLimitationsFromProperty(propertyDetails_);
                updatedState.propertyAdditionalFeatures = this._extractPropertyAdditionalFeatures(propertyDetails_.otherFeatures);

                this.setState(updatedState, callback_);
            }
        }

        if (propertyDetails_.managementCompanyGuid) {
            this._userService.getCompanyUsers(propertyDetails_.managementCompanyGuid)
                .then(r => r.json())
                .then(r => {
                    managers = r;
                    onApiResponse();
                });
        }
        else {
            onApiResponse();
        }
    }

    private _extractPropertyAdditionalFeatures(otherFeatures_: string): IAdditionalFeature[] {
        let otherPropertyFeatures: IAdditionalFeature[] = [];

        if (otherFeatures_) {
            try {
                otherPropertyFeatures = JSON.parse(otherFeatures_) as IAdditionalFeature[];
                otherPropertyFeatures = otherPropertyFeatures.filter(f => {
                    return f.a;
                });
            }
            catch (ex) { }
        }

        return otherPropertyFeatures;
    }

    private _saveSpot = (): void => {
        const { spotNames } = this.state;
        let spotData = {
            friendlySpotIds: spotNames,
            // otherFeatures: this.state.otherFeatures,
            spotTypeId: this.state.isTenantManaged ? 2 : 1,
            spotStatusId: 2,
            zoneGuid: this.state.selectedzone?.zoneGuid
            // schedules: this.state.schedules
        } as any;

        if (this.props.onSave && (this.props.property || this.props.propertyGuid)) {
            this.props.onSave(spotData, this.state.selectedPropertyDetails);
        }
        else {
            if (!this.state.selectedPropertyDetails || !this.state.selectedPropertyDetails.propertyGuid) {
                ShowAlert('', 'Please select a property', 'error');
                return;
            }

            if (!this.state.selectedzone) {
                ShowAlert('', 'Please select a zone', 'error');
                return;
            }

            if (!this.state.selectedzone) {
                ShowAlert('', 'Please select a zone', 'error');
                return;
            }

            if (this.state.schedules) {
                let scheduleConflicts = false;
                let isNotValidSchedule = false;

                for (let schedule of this.state.schedules) {
                    if (schedule._hasConflict) {
                        scheduleConflicts = true;
                    }

                    if (schedule.selectedDays?.length == 0) {
                        isNotValidSchedule = true;
                    }
                }
                if (scheduleConflicts) {
                    ShowAlert('', 'Please resolve conflicts in schedule', 'error');
                    return;
                }

                if (isNotValidSchedule) {
                    ShowAlert('', 'Please ensure all the schedules have valid days selected.', 'error');
                    return;
                }
            }

            if (this.props.spot == undefined) {
                if (!spotNames.length) {
                    ShowAlert('', 'Please enter at least one spot number', 'error');
                    return;
                }

                if(spotNames.length > 300){
                    ShowAlert('', 'Only 300 spots can be created', 'error');
                    return;
                }

                spotData.propertyGuid = this.state.selectedPropertyDetails.propertyGuid;

                this.setState({
                    loaderState: true
                });
                this._spotService.addSpot(spotData).then(r => {
                    if (r.ok) {
                        r.text().then(g => {
                            if (g) {
                                let guids = JSON.parse(g).toString().replace(/(?:\r\n|\r|\n)/g, ',').split(",").filter((x: string) => x !== "");
                                if (guids.length > 0) {
                                    this._spotService.getSpot(guids).then(res => {
                                        if (res.ok) {
                                            res.json().then(response => {
                                                const updatedList = this.updateResponse(response);
                                                this.props.onClose(true, updatedList);
                                            }).catch(error => {
                                                this.defaultSuccess(spotData, guids);
                                            });
                                        }
                                        else {
                                            this.defaultSuccess(spotData, guids);
                                        }
                                    }).catch(error => {
                                        this.defaultSuccess(spotData, guids);
                                    });
                                }
                                else {
                                    this.defaultSuccess(spotData, guids);
                                }
                                return;
                            }

                            ShowAlert('', 'Unexpected error occured while adding spots, please try again', 'error');
                        });
                    }
                    this.setState({
                        loaderState: false
                    });
                });
            }
            else {
                spotData.spotGuid = this.props.spot.spotGuid;
                spotData.spotTypeId = spotData.spotTypeId;
                spotData.spotStatusId = this.props.spot.spotStatusId;


                this.props.spot.otherFeatures = this.state.otherFeatures;
                this.props.spot.spotTypeId = spotData.spotTypeId;
                this.props.spot.ownedBy = (spotData.spotTypeId == 2) ? 'Tenant' : 'Property';

                if (this.state.schedules) {
                    this.props.spot.schedules = ObjectHelper.deepClone(this.state.schedules);
                }
                if (!this.state.isTenantManaged) {
                    this.props.spot.spotTenantGuid = "";
                    this.props.spot.spotTenantName = "";
                }

                this.checkOverLapping(spotData);
            }
        }
    }

    private defaultSuccess(spotData: any, guids: string[]){
        try {
            // let guids = JSON.parse(g).toString().split("\r\n");
            
            ShowAlert('', 'Spots added successfully', 'success');

            let newSpots: ISpot[] = [];
            let gIndex = 0;
            if (this.state.selectedPropertyDetails) {
                for (let guid of guids) {
                    if (guid) {
                        newSpots.push({
                            spotGuid: guid,
                            zoneGuid: this.state.selectedzone?.zoneGuid,
                            friendlySpotId: this.state.spotNames[gIndex],
                            propertyGuid: this.state.selectedPropertyDetails.propertyGuid,
                            propertyName: this.state.selectedPropertyDetails.propertyName,
                            propertyCity: this.state.selectedPropertyDetails.propertyCity,
                            propertyCompanyName: this.state.selectedPropertyDetails.managementCompanyName,
                            changedUtc: new Date(),
                            propertyOwnerName: this.state.selectedPropertyDetails.propertyOwnerName,
                            spotStatusId: spotData.spotStatusId,
                            ownedBy: (spotData.spotTypeId == 2) ? 'Tenant' : 'Property',
                            spotTypeId: spotData.spotTypeId,
                            revenue: 0,
                            // otherFeatures: this.state.otherFeatures,
                            reservationDates: [],
                            // schedules: ObjectHelper.deepClone(this.state.schedules)
                        } as any);
                    }
                    gIndex++;
                }
            }

            this.props.onClose(true, newSpots);
        }
        catch (ex) { }        
    }

    private async checkOverLapping(spotData: any) {
        let overlpappingReservations: IReservationChunk[] = [];

        var offDaysReservations = await this.checkOverLappingService(spotData, "offDays");
        overlpappingReservations = [...overlpappingReservations, ...offDaysReservations];
        console.log("1", overlpappingReservations);

        var startOverlpappingReservations = await this.checkOverLappingService(spotData, "start");
        overlpappingReservations = [...overlpappingReservations, ...startOverlpappingReservations];
        console.log("2", overlpappingReservations);

        var endOverlpappingReservations = await this.checkOverLappingService(spotData, "end");
        overlpappingReservations = [...overlpappingReservations, ...endOverlpappingReservations];
        console.log("3", overlpappingReservations);

        if (overlpappingReservations.length > 0) {
            var bookedSpots: string[] = [];
            var reservationGuid: string[] = [];
            var friendlyReservationIds: string[] = [];

            overlpappingReservations.forEach(x => {
                if (reservationGuid.indexOf(x.reservationGuid) == -1) {
                    reservationGuid.push(x.reservationGuid);
                }

                if (bookedSpots.indexOf(x.friendlySpotId) == -1) {
                    bookedSpots.push(x.friendlySpotId);
                }

                if (friendlyReservationIds.indexOf(x.friendlyReservationId) == -1) {
                    friendlyReservationIds.push(x.friendlyReservationId);
                }
            });

            let cancelBookingOption: any = {
                reservationGuids: reservationGuid,
                refundAction: true
            }

            ShowAlertwithOptions(
                "Booking Cancellation",
                `<div>
                    <div><b>${bookedSpots.sort().toString()}</b> have active booking(s) that are conflicting.</div><div>Updating this spot will cancel the following booking(s):</div>
                    <div class="bookings-overlap">
                        ${friendlyReservationIds.sort().toString()}
                    </div>
                    <div>Are you sure you want to proceed?</div>
                </div>`,
                "warning",
                "Yes, cancel immediately",
                null,
                "No, don't cancel")
                .then(agreed_ => {
                    if (agreed_.option1 == true) {
                        this._spotService.AdminCancelOverlpappingReservation(cancelBookingOption)
                            .then(data => {
                                if (data.ok) {
                                    data.json().then((cancellationResponse_: { cancelled: string[], issueWithCancellation: string[], issueWithRefund: string[], manualRefundBookings: string[] }) => {
                                        if (cancellationResponse_.manualRefundBookings && cancellationResponse_.manualRefundBookings.length) {
                                            ShowAlert("Unable to Refund", `Unable to refund the following bookings, request admin to refund offline: ${cancellationResponse_.manualRefundBookings.join(", ")}`, "warning")
                                                .then(() => {
                                                    this.updateSpot(spotData);
                                                });
                                        } else {
                                            this.updateSpot(spotData);
                                        }
                                    });
                                }
                            });
                    }
                })
        } else {
            this.updateSpot(spotData);
        }
    }

    private async checkOverLappingService(spotData: any, type: string): Promise<IReservationChunk[]> {
        var days = [1, 2, 3, 4, 5, 6, 7];
        let scheduledDays: number[] = [];
        let schedules_: ISingleScheduleData | null = null;

        if (this.state.schedules) {
            var schedules = ObjectHelper.deepClone(this.state.schedules);
            if (schedules.length) {
                schedules_ = schedules[0];
                if (schedules_.selectedDays) {
                    scheduledDays = schedules_.selectedDays;
                }
            }
        }

        var offDays = days.filter(x => {
            return scheduledDays.indexOf(x) == -1;
        });

        // days should start from 0 to validate in server
        let updatedDays: number[] = [];

        if (type == "offDays") {
            updatedDays = offDays.map(x => {
                return (x - 1);
            });
        } else {
            updatedDays = days.map(x => {
                return (x - 1);
            });
        }

        let metadata: ISpotBlockMetadata = {
            r: 1,
            w: updatedDays
        }

        let spotBlockModel: SpotBlock = {
            metadata: JSON.stringify(metadata),
            repeatType: 2,
            startDate: this.convertIntoUtc(new Date(new Date().setHours(0, 0, 0, 0))),
            spotGuids: [spotData.spotGuid],
            startTime: "",
            endTime: ""
        }

        if (type == "start" && schedules_ && schedules_.selectedTimeRange) {
            spotBlockModel.startTime = "12:00 AM";
            spotBlockModel.endTime = DateTimeHelper.minutesToTimeString(schedules_.selectedTimeRange.start, false);

            if (spotBlockModel.startTime == spotBlockModel.endTime) {
                return [];
            }
        }

        if (type == "end" && schedules_ && schedules_.selectedTimeRange) {
            spotBlockModel.startTime = DateTimeHelper.minutesToTimeString(schedules_.selectedTimeRange.end, false);
            spotBlockModel.endTime = "11:59 PM";

            if (spotBlockModel.startTime == spotBlockModel.endTime) {
                return [];
            }
        }

        if (metadata.w && metadata.w.length == 0) {
            return [];
        }

        if (type == "offDays" && schedules_ && schedules_.selectedTimeRange) {
            spotBlockModel.startTime = DateTimeHelper.minutesToTimeString(schedules_.selectedTimeRange?.start, false);
            spotBlockModel.endTime = DateTimeHelper.minutesToTimeString(schedules_.selectedTimeRange?.end, false);
        }

        var reponse = await this._spotService.CheckOverLapping(spotBlockModel);
        return await reponse.json();
    }

    private updateResponse(response: any) {
        return response.map((e: any) => {
            if (e.reservationDates) {
                e.reservationDates = e.reservationDates.map((d: Date) => new Date(d));
            }
            else {
                e.reservationDates = [];
            }
            e.revenue = e.revenve;

            // Added to avoid sorting issue
            e.ownedBy = (e.spotTypeId === 2) ? 'Tenant' : 'Property';
            e.spotTenantName = e.spotTenantName ? e.spotTenantName : '';

            let sortedDates = ObjectHelper.deepClone(e.reservationDates).sort((a: Date, b: Date) => b.getTime() - a.getTime());

            let pastReservationDate: Date = sortedDates.filter((r: any) => r < new Date())[0];
            e.lastReservation = pastReservationDate ? pastReservationDate : null;

            let upcomingReservationDate: Date = sortedDates.reverse().filter((r: any) => r > new Date())[0];
            e.nextReservation = upcomingReservationDate ? upcomingReservationDate : null;

            return e;
        });        
    }

    private updateSpot(spotData: any) {
        this.setState({
            loaderState: true
        });
        this._spotService.updateSpot(spotData).then(r => {
            if (r.ok) {
                ShowAlert('', 'Spot updated successfully', 'success').then(x=>{
                    this._spotService.getSpot([spotData.spotGuid]).then(res => {
                        if(res.ok){
                            res.json().then(response => {
                                const updatedList = this.updateResponse(response);
                                this.props.onClose(true, [], updatedList);
                            });
                        }
                    });                    
                });                
            }
            this.setState({
                loaderState: false
            });
        });
    }

    private convertIntoUtc(date: Date) {
        let x: Date = new Date(date);
        let hoursDiff = x.getHours() - x.getTimezoneOffset() / 60;
        let minutesDiff = (x.getHours() - x.getTimezoneOffset()) % 60;
        x.setHours(hoursDiff);
        x.setMinutes(minutesDiff);
        return new Date(x);
    }

    private _hasChanges(): boolean {
        // Edit
        if (this.props.spot) {
            return (this.props.spot.spotTypeId == 2) == !this.state.isTenantManaged ||
                this.props.spot.otherFeatures != this.state.otherFeatures ||
                JSON.stringify(this.props.spot.schedules.map(s => this._scheduleJsonText(s))) != JSON.stringify(this.state.schedules?.map(s => this._scheduleJsonText(s)));
        }
        // Add
        else {
            return this.state.selectedProperty != null ||
                this.state.spotNames.length > 0 ||
                this.state.isTenantManaged;
        }
    }

    private _scheduleJsonText(schedule_: ISingleScheduleData): string {
        let asString = `(${schedule_.selectedDays?.sort().join(",")})-${schedule_.selectedTimeRange?.start}-${schedule_.selectedTimeRange?.end}=${schedule_.selectedPriceRange?.start}`;
        return asString ? asString : "";
    }
}

interface ISpotEditViewProps {
    spot?: ISpot;
    className?: string;
    property?: IPropertyDetails;
    propertyGuid?: string;
    customTitle?: string;
    spotNames?: string[];
    existingSpotNames?: string[];
    schedules?: ISingleScheduleData[];
    onClose: (requiresUpdate_: boolean, newSpots_: ISpot[], updateSpot?: any) => void;
    onSave?: (data_: any, propertyDetails_: IPropertyDetails | null) => void;
    onSpotNamesChange?: (spotNames_: string[]) => void;
    onChange?: (hasUnsavedChanges_: boolean) => void;
}

interface ISpotEditViewState {
    isReady: boolean;
    properties: IPropertyBasics[];
    featureList: ISpotFeature[];
    managers: IBasicUser[];

    spotNames: string[];
    schedules?: ISingleScheduleData[];
    selectedProperty: IPropertyBasics | null;
    selectedPropertyDetails: IPropertyDetails | null;
    isTenantManaged: boolean;
    otherFeatures: string;
    permissionsList: boolean[];

    existingSpotNames: string[];
    // scheduleLimitation: IScheduleLimitation;
    propertyAdditionalFeatures: IAdditionalFeature[];
    propertyAmenities: number[];

    zoneList: IZoneBasics[];
    selectedzone: IZoneBasics | null;

    loaderState: boolean;
}