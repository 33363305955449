import React from "react";
import { GetUserDetailsService } from "../../Services/GetUserDetailsService";
import SpotList from "./SpotList";
import UnclaimedSpotList from "./UnclaimedSpotList";
import "./Spots.css";



export default class Spots extends React.Component<ISpotsProps, ISpotsState> {
    private _isTenantUser: boolean;

    constructor(props_: ISpotsProps) {
        super(props_);
        this._isTenantUser = GetUserDetailsService.getUserDetails().userRoleID[0] == 6;
        this.state = {
            selectedTabIndex: props_.match.params.source == "unclaimed" ? 1 : 0
        };
    }

    render() {
        if (this.state.selectedTabIndex == 1) {
            return <UnclaimedSpotList
                selectedTabIndex={this.state.selectedTabIndex}
                onTabChange={t => this.setState({ selectedTabIndex: t })}
                source={this.props.match.params.source}
                propertyGuid={this.props.match.params.propertyGuid}
                requestType={this.props.match.params.requestType}
            />;
        }
        else {
            return <SpotList
                selectedTabIndex={this.state.selectedTabIndex}
                onTabChange={t => this.setState({ selectedTabIndex: t })}
                source={this.props.match.params.source}
                propertyGuid={this.props.match.params.propertyGuid}
                requestType={this.props.match.params.requestType}
            />;
        }
    }
}

interface ISpotsProps {
    match: {
        params: {
            source: string;
            propertyGuid: string;
            requestType: string;
        }
    }
}

interface ISpotsState {
    selectedTabIndex: number;
}