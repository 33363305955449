import { IFileInfo } from "../Components/BasicComponents/FileUploadModal";

export default class CacheService {
    private static _files: IFileInfo[] = [];

    public static cacheFiles(files_: IFileInfo[]): void {
        this._files = files_;
    }

    public static cacheFile(file_: IFileInfo): void {
        this._files.push(file_);
    }

    public static removeCachedFile(file_: IFileInfo): void {
        let fileIndex = this._files.indexOf(file_);
        if (fileIndex > -1) {
            this._files.splice(fileIndex, 1);
        }
    }

    public static getFilesCache(): IFileInfo[] {
        return this._files;
    }
}