import React from "react";
import { Checkbox, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { IBlockSpot } from "./interfaces";

export default class AutoCompleteBlockSpots extends React.Component<IAutoCompleteBlockSpotsProps, IAutoCompleteBlockSpotsState> {
    constructor(props_: IAutoCompleteBlockSpotsProps) {
        super(props_);

        this.state = {
            selectedSpot: this.props.selectedSpot,
            spots: this.getSpotList(),
            refreshAutocomplete: false,
            isInputFocussed: false
        }
    }

    componentDidUpdate() {
        if (this.props.isApiUpdated && this.state.selectedSpot.length != this.props.selectedSpot.length && !this.state.isInputFocussed) {
            let results: IBlockSpot[] = this.props.selectedSpot.slice(0);

            var spotList = this.getSpotList();

            var isSelectAllExists = results.filter(x => x.spotGuid == "blank").length > 0;
            if (results.length > 0 && (results.length == (spotList.length - 1)) && this.props.enableSelectAll == true && !isSelectAllExists) {
                results = spotList;
            }

            this.setState({
                spots: spotList,
                selectedSpot: results
            }, () => {
                if (this.props.onRefreshDone) {
                    this.props.onRefreshDone(true, this.state.selectedSpot);
                }
            })
        } else if (this.state.selectedSpot.length != this.props.selectedSpot.length && !this.state.isInputFocussed) {
            this.setState({
                selectedSpot: this.props.selectedSpot
            })
        } else if (this.state.spots.length == 0 && this.props.spots.length > 0 && !this.state.isInputFocussed) {
            this.setState({
                spots: this.getSpotList(),
                refreshAutocomplete: true
            }, () => {
                this.setState({
                    refreshAutocomplete: false
                })
            })
        }
    }

    render() {
        return (
            <div>
                {
                    !this.state.refreshAutocomplete && <Autocomplete
                        multiple
                        options={this.state.spots}
                        className="cl-bl-dropdown"
                        disableCloseOnSelect
                        size="small"
                        value={this.state.selectedSpot}
                        onFocus={() => {
                            this.setState({
                                isInputFocussed: true
                            })
                        }}
                        onBlur={() => {
                            this.setState({
                                isInputFocussed: false
                            })
                        }}
                        onChange={(event: any, values: IBlockSpot[]) => {
                            let isAllAlreadySelected: boolean = this.state.selectedSpot.filter(x => x.spotGuid == 'blank').length > 0;
                            let isAllSelected: boolean = values.filter(x => x.spotGuid == 'blank').length > 0;
                            let selectedValue: IBlockSpot[] = [];

                            if ((!isAllAlreadySelected && !isAllSelected) && values.length == (this.state.spots.length - 1) || (isAllSelected && !isAllAlreadySelected)) {
                                selectedValue = this.state.spots;
                            } else if ((isAllAlreadySelected && isAllSelected) && values.length != this.state.spots.length) {
                                let blankIndex: number = values.map(i => i.spotGuid).indexOf("blank");
                                selectedValue = values;
                                selectedValue.splice(blankIndex, 1);
                            } else if (isAllAlreadySelected && !isAllSelected) {
                                selectedValue = [];
                            }
                            else {
                                selectedValue = values;
                            }

                            this.setState({
                                selectedSpot: selectedValue
                            }, () => {
                                if (this.props.onBlockSpotSelected) {
                                    this.props.onBlockSpotSelected(this.state.selectedSpot);
                                }
                            });
                        }}
                        openOnFocus={true}
                        selectOnFocus={true}
                        getOptionLabel={(option) => option.friendlySpotId}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                    color="primary"
                                />
                                {option.friendlySpotId}
                            </React.Fragment>
                        )}
                        renderTags={(tagValue, getTagProps) => {
                            return tagValue.map((option, index) => (
                                // <Chip
                                //     key={'lable' + index}
                                //     label={option.friendlySpotId}
                                //     {...getTagProps({ index })}
                                //     className={option.spotGuid.indexOf('blank') != -1 ? 'hide-chip' : ''}
                                // />
                                <span
                                    key={'lable' + index}
                                    {...getTagProps({ index })}
                                    className={option.spotGuid.indexOf('blank') != -1 ? 'hide-chip' : 'block-dropdown-chip'}
                                >{option.friendlySpotId}{(tagValue.length - 1) != index && <span>,&nbsp;</span>}</span>
                            ));
                        }}
                        limitTags={2}
                        renderInput={(params) => <TextField {...params}
                            placeholder={this.state.selectedSpot.length == 0 ? "Spot number(s)" : ""}
                            error={this.state.selectedSpot.length == 0 && this.props.isMandate}
                            variant="outlined" />}
                        disabled={this.props.disabled}
                    />
                }
                {
                    (this.state.selectedSpot.length == 0 && this.props.isMandate) && <div className="cl-block-error-msg">Please select the spot(s).</div>
                }
            </div>
        )
    }

    private getSpotList(enableSelectAll?: boolean) {
        let results: IBlockSpot[] = this.props.spots.slice(0);
        let selectAllOption: IBlockSpot = {
            friendlySpotId: "Select all spots",
            spotGuid: "blank"
        }

        if (results.length > 0 && (this.props.enableSelectAll == true || enableSelectAll == true)) {
            results.unshift(selectAllOption);
        }

        return results;
    }
}

interface IAutoCompleteBlockSpotsProps {
    spots: IBlockSpot[];
    onBlockSpotSelected: (b_: IBlockSpot[]) => void;
    selectedSpot: IBlockSpot[];
    enableSelectAll?: boolean;
    isMandate?: boolean;
    isApiUpdated?: boolean;
    onRefreshDone?: (b_: boolean, v_: IBlockSpot[]) => void;
    disabled?: boolean;
}

interface IAutoCompleteBlockSpotsState {
    spots: IBlockSpot[];
    selectedSpot: IBlockSpot[];
    refreshAutocomplete: boolean;
    isInputFocussed: boolean;
}