import React from 'react';
import './Dashboard.css'
import { IPropertyItem, IRankedProperty, ISpotItem, IUpcomingBooking } from './Interface';
import DataGrid from '../../Components/DataGrid/DataGrid';
import { IDataGridColumn } from '../../Components/DataGrid/Interfaces';
import PropertyService from '../../Services/PropertyService';
import { DateTimeHelper } from '../../Helpers/DateTimeHelper';
import { format } from "date-fns";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import SpotService from '../../Services/SpotService';

export default class SpotUpcomingBookings extends React.Component<UpcomingBookingsProps, UpcomingBookingsState> {
    private _spotService: SpotService;
    private _spotListColumns: IDataGridColumn<IUpcomingBooking>[] = [
        {
            key: "reservationStatus",
            name: "STATUS",
            contentProvider: (row_: IUpcomingBooking) => {
                /*if (row_.reservationStatus == 1) {
                    return (<CheckCircleIcon fontSize="default" color="primary" />);
                }
                else if (row_.reservationStatus == 2) {
                    return (<CancelIcon fontSize="default" style={{
                        color: "rgba(255,158,27,1)"
                    }} />);
                }*/
                if (row_.reservationStatus == 1) {
                    if (row_.isUpcomingMonthlyReservationActive || row_.paymentGroup.indexOf("Monthly") != -1) {
                        return (<CheckCircleIcon fontSize="default" style={{
                            color: "rgba(255,158,27,1)"
                        }} />);
                    } else {
                        return (<CheckCircleIcon fontSize="default" color="primary" />);
                    }
                }
                else if (row_.reservationStatus == 2) {
                    return (<CancelIcon fontSize="default" style={{
                        color: "rgba(255,158,27,1)"
                    }} />);
                }

                return (<CheckCircleIcon fontSize="default" color="primary" />);
            },
            textAlignment: "center"
        },
        {
            key: "spacer1",
            name: ""
        },
        {
            key: "bookingDate",
            name: "DATE",
            contentProvider: (row_: IUpcomingBooking) => {
                let date = DateTimeHelper.dateStringToDate(row_.bookingDate);
                return (<span><span className="no-wrap-text">{format(date, "dd/MM/yyyy")}</span></span>);
            }
        },
        {
            key: "propertyName",
            name: "PROPERTY"
        },
        {
            key: "friendlySpotId",
            name: "SPOT NO"
        },
        {
            key: "bookingStartUTC",
            name: "START TIME",
            contentProvider: (row_: IUpcomingBooking) => {
                let date = DateTimeHelper.dateStringToDate(row_.startUTC);
                return (<span><span className="no-wrap-text">{format(date, "hh:mm aa")}</span></span>);
            }
        },
        {
            key: "bookingEndUTC",
            name: "END TIME",
            contentProvider: (row_: IUpcomingBooking) => {
                let date = DateTimeHelper.dateStringToDate(row_.endUtc);
                return (<span><span className="no-wrap-text">{format(date, "hh:mm aa")}</span></span>);
            }
        },
        {
            key: "licenseNo",
            name: "LICENSE PLATE"
        }
    ]
    constructor(props: UpcomingBookingsProps) {
        super(props);
        this.state = {
            allBookings: [],
            selectedSpots: []
        }
        this._spotService = new SpotService();
    }

    public componentDidMount = () => {
        this.loadWidgetData();
    }

    public componentDidUpdate = () => {
        let spotGuids: string[] = this.props.selectedSpots.map(spot => {
            return spot.spotGuid;
        });

        let allItemIndex = spotGuids.indexOf('all_items');

        if (allItemIndex != -1) {
            spotGuids.splice(allItemIndex, 1);
        }

        if (this.state.selectedSpots.toString() != spotGuids.toString()) {
            this.loadWidgetData();
        }
    }

    private loadWidgetData() {
        let spotGuids: string[] = this.props.selectedSpots.map(spot => {
            return spot.spotGuid;
        });
        let widgetData: any = {
            selectedSpots: spotGuids.length > 0 ? spotGuids : null,
        };
        let allItemIndex = spotGuids.indexOf('all_items');

        if (allItemIndex != -1) {
            spotGuids.splice(allItemIndex, 1);
        }

        this._spotService.getTenantDashboardBookings(widgetData)
            .then(data => {
                if (data.ok) {
                    data.json().then(d => {
                        let mappedItems = d.map((r: any) => {
                            r.bookingDate = r.startUTC;
                            return r;
                        });

                        this.setState({
                            allBookings: mappedItems,
                            selectedSpots: spotGuids
                        });
                    })
                }
            })
            .catch((error) => {
                console.error('Error:', JSON.stringify(error));
            });
        /*let mappedItems = this._propertyList.map(r => {
            return r;
        });

        this.setState({
            allProperties: mappedItems
        })*/
    }

    public render() {
        return (
            <DataGrid
                title="Revenue"
                data={this.state.allBookings}
                columns={this._spotListColumns}
                isRowsNonSelectable={true}
                isNoMoreLink={true}
                customPageSize={[5, 10, 15]}
                isNoRowsPerPage={true}
            />
        )
    }
}

interface UpcomingBookingsProps {
    selectedSpots: ISpotItem[];
}
interface UpcomingBookingsState {
    allBookings: IUpcomingBooking[],
    selectedSpots: string[];
}