import React from "react";
import DataGrid from "../../Components/DataGrid/DataGrid";
import { IDataGridColumn } from "../../Components/DataGrid/Interfaces";
import { Grid, TextField } from "@material-ui/core";
import SpotService from "../../Services/SpotService";
import { format } from "date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "./Schedule.css";
import { IPropertyBasics, ISpot } from "../Spots/Interfaces";
import PropertyService from "../../Services/PropertyService";

export default class SubscriptionList extends React.Component<{}, ISubscriptionListState> {
    private _spotService: SpotService;
    private _propertyService: PropertyService;
    private _subscriptionListColumns: IDataGridColumn<ISubscription>[] = [];

    constructor(props_: {}) {
        super(props_);

        this.state = {
            properties: [],
            spots: [],
            selectedProperty: null,
            selectedSpot: null,
            subscriptions: []
        };

        this._subscriptionListColumns = this._subscriptionListColumns.concat([
            {
                key: "spacer1",
                name: ""
            },
            {
                key: "friendlyReservationId",
                name: "Booking Id"
            },
            {
                key: "parkerName",
                name: "Parker Name"
            },
            {
                key: "stripeCustomerId",
                name: "S.Customer Id"
            },
            {
                key: "subscriptionScheduleId",
                name: "S.Sub Schedule Id"
            },
            {
                key: "subscriptionId",
                name: "S.Subscription Id"
            },
            {
                key: "billingCycleDate",
                name: "S.Billing Date"
            },
            {
                key: "bookedTill",
                name: "Booked Till"
            },
            {
                key: "",
                name: "Actions",
                contentProvider: (subscription_: ISubscription) => {
                    return <div>
                        <button onClick={() => {
                            alert("Please wait till the page refreshes")
                            this._spotService.preponeBookingByAMonth(subscription_.reservationGuid)
                                .then(() => {
                                    this._loadSubscriptions();
                                });
                        }}>Prepone 1 Month</button>
                        <button onClick={() => {
                            alert("Please wait till the page refreshes")
                            this._spotService.billSubscriptionNow(subscription_.reservationGuid)
                                .then(() => {
                                    this._loadSubscriptions();
                                });
                        }}>Bill Now</button>
                        <button onClick={() => {
                            alert("Please wait till the page refreshes")
                            this._spotService.retryPaymentAndUpdateStatus(subscription_.reservationGuid)
                                .then(() => {
                                    this._loadSubscriptions();
                                });
                        }}>Retry</button>
                        <button onClick={() => {
                            alert("Please wait till the page refreshes")
                            this._spotService.renewZeroDollarBookings(subscription_.reservationGuid)
                                .then(() => {
                                    this._loadSubscriptions();
                                });
                        }}>Renew $0 booking(s)</button>
                    </div>
                }
            }
        ]);

        this._spotService = new SpotService();
        this._propertyService = new PropertyService();
    }

    componentDidMount() {
        this._loadProperties();
    }

    private _loadProperties = () => {
        this._propertyService.getPropertiesBasics()
            .then(r => r.json())
            .then(r => {
                this.setState({
                    properties: r,
                    selectedProperty: null,
                    spots: [],
                    selectedSpot: null,
                    subscriptions: []
                });
            });
    }

    private _loadSpots = () => {
        if (this.state.selectedProperty) {
            this._spotService.getPropertySpots(this.state.selectedProperty.propertyGuid)
                .then(r => r.json())
                .then(r => {
                    r = (r as ISpot[]).sort((a, b) => a.friendlySpotId > b.friendlySpotId ? 1 : -1);
                    this.setState({
                        spots: r,
                        selectedSpot: null,
                        subscriptions: [],
                    });
                });
        }
        else {
            this.setState({
                selectedSpot: null,
                subscriptions: [],
            });
        }
    }

    private _loadSubscriptions = () => {
        if (this.state.selectedSpot) {
            this._spotService.getActiveSubscriptions(this.state.selectedSpot.spotGuid)
                .then(r => r.json())
                .then(r => {
                    this.setState({
                        subscriptions: r
                    });
                });
        }
        else {
            this.setState({
                subscriptions: []
            });
        }
    }

    render() {
        return (
            <div className="mx-3 data-grid-container subscriptions-page">
                {/* Header region of the page */}
                <Grid container className="mb-3">
                    <Grid item>
                        {/* Title of the page */}
                        <h1 className="list-header">
                            Active Subscriptions
                        </h1>
                    </Grid>
                    {
                        <React.Fragment>
                            <Grid className="px-4">
                                <Autocomplete
                                    className="mt-2 mr-2"
                                    options={this.state.properties}
                                    getOptionLabel={option_ => option_.propertyName}
                                    style={{ width: 200 }}
                                    size="small"
                                    value={this.state.selectedProperty}
                                    renderInput={(params) => <TextField {...params} label="Property" variant="outlined" />}
                                    onChange={(e_: any, property_: IPropertyBasics | null) => {
                                        this.setState({
                                            selectedProperty: property_,
                                        }, this._loadSpots);
                                    }}
                                />
                            </Grid>
                            <Grid>
                                <Autocomplete
                                    className="mt-2"
                                    options={this.state.spots}
                                    getOptionLabel={option_ => option_.friendlySpotId}
                                    style={{ width: 120 }}
                                    size="small"
                                    value={this.state.selectedSpot}
                                    renderInput={(params) => <TextField {...params} label="Spot" variant="outlined" />}
                                    onChange={(e_: any, spot_: ISpot | null) => {
                                        this.setState({
                                            selectedSpot: spot_,
                                        }, this._loadSubscriptions);
                                    }}
                                />
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>

                {/* Data Grid */}
                {
                    <DataGrid
                        title="Subscription"
                        data={this.state.subscriptions}
                        columns={this._subscriptionListColumns}
                        defaultSortColumnKey="friendlyReservationId"
                        defaultSortDirection="asc"
                        isNoMoreLink={true}
                        isRowsNonSelectable={true}
                    />
                }
            </div>
        );
    }
}


interface ISubscriptionListState {
    properties: IPropertyBasics[];
    spots: ISpot[];
    selectedProperty: IPropertyBasics | null;
    selectedSpot: ISpot | null;
    subscriptions: ISubscription[];
}

interface ISubscription {
    reservationGuid: string;
    friendlyReservationId: string;
    parkerName: string;
    stripeCustomerId: string;
    subscriptionScheduleId: string;
    subscriptionId: string;
    billingCycleDate?: Date;
    bookedTill: Date;
}