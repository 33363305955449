import * as React from 'react';
import {Button, TextField} from "@material-ui/core";
import UploadMapPopup from './UploadMapPopup';
import {IPrivateLotProperty, IPrivateLotZone, ZoneActiveSpot} from './interface';
import {
  EnumInteractiveMapStatus,
  IFloor,
  IMapResetOption,
  ISelectedSpotDetails
} from '../../Components/SpotMap/interface';
import {Autocomplete} from "@material-ui/lab";
import {Col, Row} from "reactstrap";
import PrivateLotService from '../../Services/PrivateLotService';
import {ShowAlert, ShowAlertWithConfirmCustomIcon} from '../../Common/ShowAlert';
import SpotMapPopup from './SpotMapPopup';
import ArrayHelper from '../../Helpers/ArrayHelper';

export default class PrivateLotsMapDetails extends React.Component<IPrivateLotsMapDetailsProps, IPrivateLotsMapDetailsState> {
  private _privateLotService: PrivateLotService;

  constructor(props_: IPrivateLotsMapDetailsProps) {
    super(props_);

    this.state = {
      isUploadPopupVisible: false,
      isEditMap: false,
      selectedProperty: null,
      selectedZone: null,
      zoneList: [],
      // spotList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '80', '81', '82', '83', '84', '85', '86', '87', '88', '89', '90', '91', '92', '93', '94', '95', '96', '97', '98', '99', '100'],
      spotList: [],
      selectedSpot: null,
      isResetAllSpotAttributes: false,
      isResetMap: false,
      isEditingCancel: false,
      deleteMapPopup: false,
      mapUrl: "",
      existingMapStatus: 0,
      selectedFloor: null
    }

    this._privateLotService = new PrivateLotService();
  }

  componentDidUpdate(prevProps: IPrivateLotsMapDetailsProps): void {
    if (prevProps.progress !== this.props.progress &&
      this.props.progress === EnumInteractiveMapStatus.PropertySelection && !this.state.isUploadPopupVisible) {
      this.setState({
        isUploadPopupVisible: true
      })
    }

    if (prevProps.progress !== this.props.progress && this.props.progress === EnumInteractiveMapStatus.ZoneSelection) {
      this.getPropertyZones();
    }

    if (prevProps.selectedSpot !== this.props.selectedSpot && this.props.selectedSpot != null) {
      let spotList_ = this.state.spotList.map(x => {

        if (this.props.selectedSpot?.SpotGuid === x.SpotGuid) {
          x.isMappingDone = this.props.selectedSpot?.isMappingDone
        }

        return x;
      });

      this.setState({
        spotList: this.sortArrayObject(spotList_, "FriendlySpotId")
      });
    }

    if (prevProps.selectedSpot !== this.props.selectedSpot && this.props.selectedSpot === null) {
      this.setState({
        selectedSpot: null
      })
    }

    if (JSON.stringify(prevProps.mapSelectedSpotDetails) !== JSON.stringify(this.props.mapSelectedSpotDetails)) {
      if (this.props.mapSelectedSpotDetails?.selectedSpot === '') {
        let spotList_ = this.state.spotList.map(x => {
          if (this.props.mapSelectedSpotDetails) {
            x.isMappingDone = JSON.stringify(this.props.mapSelectedSpotDetails.mappedSpots).indexOf(x.SpotGuid) !== -1;
            // x.isMappingDone = this.props.mapSelectedSpotDetails.mappedSpots.findIndex(s => s.spotGuid == x.SpotGuid) != -1;
          }

          return x;
        })

        this.setState({
          selectedSpot: null,
          spotList: this.sortArrayObject(spotList_, "FriendlySpotId")
        })
      } else {
        if (this.props.mapSelectedSpotDetails != null) {
          let selectedSpot = this.state.spotList.filter(x => x.SpotGuid === this.props.mapSelectedSpotDetails?.selectedSpot);

          if (selectedSpot.length > 0) {
            let spotList_ = this.state.spotList.map(x => {
              if (this.props.mapSelectedSpotDetails) {
                x.isMappingDone = JSON.stringify(this.props.mapSelectedSpotDetails.mappedSpots).indexOf(x.SpotGuid) !== -1;
              }

              return x;
            })

            this.setState({
              selectedSpot: selectedSpot[0],
              spotList: this.sortArrayObject(spotList_, "FriendlySpotId")
            })
          }
        }
      }
    }
  }

  render() {
    return (
      <>
        {this.props.isMultiSelectedProperty && (
          <div className="multi-select-blocker"></div>
        )}
        {
          // !this.props.isMapLoaded
          (this.props.progress === EnumInteractiveMapStatus.UploadMap ||
            this.props.progress === EnumInteractiveMapStatus.PropertySelection ||
            this.props.progress === EnumInteractiveMapStatus.RequestResetCompletedMap) && this.UploadMapPopUpSnachBar()
        }

        {
          // this.state.isUploadPopupVisible
          (this.props.progress === EnumInteractiveMapStatus.PropertySelection) &&
            <UploadMapPopup
                selectedProperty={this.props.selectedProperty}
                properties={this.props.properties}
                onUpdate={(mapUrl: string, selectedProperty: IPrivateLotProperty | null, selectedFloor: IFloor) => {
                  this.setState({
                    // isUploadPopupVisible: false,
                    // selectedProperty: selectedProperty,
                    // selectedFloor: selectedFloor,
                    // mapUrl: mapUrl
                  }, () => {
                    if (selectedProperty != null && selectedFloor != null) {
                      let floorId = selectedFloor.levelId;
                      let zoneData = {
                        "propertyGuid": selectedProperty.propertyGuid,
                        "levelId": floorId
                      } as any;

                      localStorage.removeItem(`asset_property_${floorId}_${selectedProperty.propertyGuid}`);

                      this._privateLotService.CheckMapStatus(zoneData, false)
                        .then(r => r.json())
                        .then(r => {
                          if (r['mapStatus'] !== 0) {
                            // this.setState({
                            //     deleteMapPopup: true,
                            //     existingMapStatus: r['mapStatus']
                            // })

                            ShowAlertWithConfirmCustomIcon(
                              "Delete Existing Map?",
                              `<div class="mt-3">Are you sure you want to delete existing map?<br/>
                                                    This cannot be undone.
                                                    </div>`,
                              "error",
                              "Yes",
                              "No",
                              "gryd-custom-popup",
                              '<div><svg class="MuiSvgIcon-root error-icon-size" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg></div>'
                            )
                              .then(agreed_ => {
                                if (agreed_) {
                                  let propertyGuid_ = selectedProperty.propertyGuid;
                                  let zoneData = {
                                    "propertyGuid": propertyGuid_,
                                    "levelId": floorId
                                  } as any;


                                  this._privateLotService.ResetMap(zoneData)
                                    .then(() => {
                                      localStorage.removeItem(`asset_property_${floorId}_${propertyGuid_}`);

                                      if (this.props.onUpdate) {
                                        this.props.onUpdate(mapUrl, selectedProperty, selectedFloor);
                                      }
                                    });
                                }
                              });
                          } else {
                            if (this.props.onUpdate) {
                              this.props.onUpdate(mapUrl, selectedProperty, selectedFloor);
                            }
                          }
                        });
                    }
                  })
                }}
                onCancel={() => {
                  if (this.props.progressUpdate) {
                    this.props.progressUpdate(EnumInteractiveMapStatus.UploadMap, undefined, undefined, true);
                  }
                  // this.setState({
                  //     isUploadPopupVisible: false
                  // })

                }}
                floorLevel={this.props.floorLevel}
              // selectedFloor={this.props.selectedFloor}
            />
        }

        {
          // (this.props.isMapLoaded && !this.state.isEditMap) &&
          (this.props.progress === EnumInteractiveMapStatus.CompleteLotSetup) && this.CompleteLotSetup()
        }

        {
          this.props.progress > EnumInteractiveMapStatus.CompleteLotSetup &&
            <>
                <div style={{flex: 1}}>
                  {
                    // this.state.isEditMap &&
                    (this.props.progress === EnumInteractiveMapStatus.EditMap) && this.EditMapStartAssigning()
                  }

                  {
                    (this.props.progress === EnumInteractiveMapStatus.ZoneSelection) && this.ZoneSelection()
                  }

                  {
                    (this.props.progress === EnumInteractiveMapStatus.SpotNumberMapping ||
                      this.props.progress === EnumInteractiveMapStatus.SaveMapping) && this.SpotSelection()
                  }

                </div>
              {
                (this.props.progress < EnumInteractiveMapStatus.MappingCompleted) &&
                  <div>
                      <Row className="mt-3 mx-0">
                          <Col>
                              <Button
                                  fullWidth
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => {
                                    this.setState({
                                      isEditingCancel: true
                                    })
                                  }}
                              >Cancel</Button>
                          </Col>
                          <Col>
                            {
                              this.isSaveButtonVisible() ? <Button
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    if (this.props.progressUpdate) {
                                      this.props.progressUpdate(EnumInteractiveMapStatus.SaveMapping);
                                    }
                                  }}
                                  // disabled={this.props.progress != EnumInteractiveMapStatus.SaveMapping}
                                  disabled={this.isButtonEnabled()}
                                >Save</Button> :
                                <Button
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    this.setState({
                                      selectedZone: null
                                    }, () => {
                                      if (this.props.progressUpdate) {
                                        this.props.progressUpdate(EnumInteractiveMapStatus.EditMap);
                                      }
                                    })
                                  }}
                                  // disabled={this.props.progress != EnumInteractiveMapStatus.SaveMapping}
                                  disabled={this.isButtonEnabled()}
                                >Next</Button>
                            }

                          </Col>
                      </Row>
                  </div>
              }
            </>
        }

        {
          this.state.isResetAllSpotAttributes && <SpotMapPopup
                bodyText_="Are you sure you want to reset all spot attributes? This will clear any information you have set for the selected spot(s).<br/>This cannot be undone."
                title_="Reset All Spot Attributes?"
                confirmButtonText_="Yes"
                cancelButtonColor_="No"
                onDone={(isConfirmed: Boolean) => {
                  this.setState({
                    isResetAllSpotAttributes: false
                  }, () => {
                    if (isConfirmed && this.props.onResetDone) {
                      let resetOption_: IMapResetOption = {
                        Cancel: false,
                        ResetAllSpotAttributes: true,
                        ResetMap: false
                      }
                      this.props.onResetDone(resetOption_);
                    }
                  })
                }}
            />
        }

        {
          (this.state.isResetMap || this.props.progress === EnumInteractiveMapStatus.RequestResetCompletedMap) &&
            <SpotMapPopup
                bodyText_="Are you sure you want to reset the map? This will clear all information you have entered.<br/>This cannot be undone."
                title_="Reset Map?"
                confirmButtonText_="Yes"
                cancelButtonColor_="No"
                onDone={(isConfirmed: Boolean) => {
                  if (this.props.progress === EnumInteractiveMapStatus.RequestResetCompletedMap) {
                    if (isConfirmed) {
                      if (this.props.progressUpdate) {
                        this.props.progressUpdate(EnumInteractiveMapStatus.ConfirmedToResetMap);
                      }
                    } else {
                      if (this.props.progressUpdate) {
                        this.props.progressUpdate(EnumInteractiveMapStatus.MappingCompleted);
                      }
                    }
                  } else {
                    this.setState({
                      isResetMap: false
                    }, () => {
                      if (isConfirmed && this.props.onResetDone) {
                        let resetOption_: IMapResetOption = {
                          Cancel: false,
                          ResetAllSpotAttributes: false,
                          ResetMap: true
                        }
                        this.props.onResetDone(resetOption_);
                      }
                    })
                  }
                }}
            />
        }

        {
          this.state.isEditingCancel && <SpotMapPopup
                bodyText_="Are you sure you want to discard your changes?"
                title_="Close Without Saving?"
                confirmButtonText_="Yes"
                cancelButtonColor_="Cancel"
                onDone={(isConfirmed: Boolean) => {
                  this.setState({
                    isEditingCancel: false
                  }, () => {
                    /*if (isConfirmed && this.props.onResetDone) {
                        let resetOption_: IMapResetOption = {
                            Cancel: true,
                            ResetAllSpotAttributes: false,
                            ResetMap: false
                        }
                        this.props.onResetDone(resetOption_);
                    }*/
                    if (isConfirmed) {
                      if (this.props.selectedProperty != null) {
                        let storageData = localStorage.getItem(`asset_property_${this.props.selectedFloor ? this.props.selectedFloor.levelId : 0}_${this.props.selectedProperty.propertyGuid}`);

                        if (storageData) {
                          localStorage.removeItem(`asset_property_${this.props.selectedFloor ? this.props.selectedFloor.levelId : 0}_${this.props.selectedProperty.propertyGuid}`);
                        }
                      }

                      if (this.props.onUpdate) {
                        this.props.onUpdate("", null, null);
                      }

                    }
                  })
                }}
            />
        }

        {
          this.state.deleteMapPopup && this.renderUploadMap()
        }

      </>
    )
  }

  private sortArrayObject<T>(array: T[], orderBy: keyof T) {
    return ArrayHelper.sortArrayAlphaNumericObj(array, orderBy, "asc");
  }

  private UploadMapPopUpSnachBar() {
    return (
      <div className='p-up-map'>
        <h5>Upload Map</h5>
        <p>To view statistics, lot overview or to manage a lot, please upload a map.</p>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className='mt-2 c-go-button'
          onClick={() => {
            this.setState({
              isUploadPopupVisible: true
            }, () => {
              if (this.props.progressUpdate) {
                this.props.progressUpdate(EnumInteractiveMapStatus.PropertySelection);
              }
            })
          }}
        >
          Upload Map
        </Button>
      </div>
    )
  }

  private CompleteLotSetup() {
    return (
      <div className='p-up-map'>
        <h5 className='mt-4'>Complete Lot Setup</h5>
        <p className='mt-4'>To view statistics and lot overview, please complete lot set up.</p>
        <p><a href='javascript:void(0)' className='pl-map-details' onClick={() => {
          this.setState({
            isEditMap: true
          }, () => {
            if (this.props.progressUpdate) {
              this.props.progressUpdate(EnumInteractiveMapStatus.EditMap);
            }
          })
        }}>Edit Map</a></p>
      </div>
    )
  }

  private EditMapStartAssigning() {
    return (
      <div className='p-up-map-edit'>
        <h5 className='mt-4'>Edit Map</h5>
        <p className='mt-4'>Select a zone to start assigning.</p>
        <p><a href='javascript:void(0)' className='pl-map-details' onClick={() => {
          this.setState({
            isResetMap: true
          })
        }}>Reset Map</a></p>
      </div>
    )
  }

  private ZoneSelection() {
    return (
      <div className='p-up-map-edit'>
        <h5 className='mt-4'>Zone</h5>
        <Autocomplete
          id="upload-map-locations"
          className='mt-4'
          options={this.state.zoneList}
          openOnFocus={true}
          selectOnFocus={true}
          onChange={(event: any, value: IPrivateLotZone | null) => {
            this.setState({
              selectedZone: value
            }, () => {
              if (this.state.selectedZone != null) {
                this._privateLotService.getZoneActiveSpotList(this.state.selectedZone.zoneGuid)
                  .then(r => r.json())
                  .then(r => {
                    let spotList_ = r.map((x: any) => {
                      let spot_: ZoneActiveSpot = {
                        FriendlySpotId: x.friendlySpotId,
                        PropertyGuid: x.propertyGuid,
                        SpotGuid: x.spotGuid,
                        SpotStatusId: x.spotStatusId,
                        SpotTypeId: x.spotTypeId,
                        ZoneGuid: x.zoneGuid,
                        isMappingDone: false
                      }

                      return spot_;
                    });

                    this.setState({
                      spotList: this.sortArrayObject(spotList_, "FriendlySpotId")
                    }, () => {
                      if (this.props.progressUpdate && this.state.spotList.length > 0) {
                        this.props.progressUpdate(EnumInteractiveMapStatus.SpotNumberMapping, this.state.selectedZone);
                      } else {
                        ShowAlert("", 'No Available Spot(s).', "error");
                      }
                    })
                  });
              }
            })
          }}
          getOptionLabel={(option) => option.zoneName}

          renderInput={(params) => <TextField {...params}
                                              placeholder="Select Zone"
                                              variant="outlined"
                                              required={true}
                                              error={this.state.selectedZone == null}
          />}
        />
        {/* <p className='mt-4'>
                    <a href='javascript:void(0)' className='pl-map-details' onClick={() => {
                        this.setState({
                            isResetAllSpotAttributes: true
                        })
                    }}>Reset All Spot Attributes</a>
                </p> */}
        <p className='mt-4'><a href='javascript:void(0)' className='pl-map-details' onClick={() => {
          this.setState({
            isResetMap: true
          })
        }}>Reset Map</a></p>
      </div>
    )
  }

  private SpotSelection() {
    const options = this.state.spotList.filter(spot => !spot.isMappingDone);
    const optionsSelected = this.state.spotList.filter(spot => spot.isMappingDone);

    return (
      <div className='p-up-map-edit'>
        <h5 className='mt-4'>Spot Number</h5>
        <p>This spot belong to <b>{this.state.selectedZone?.zoneName}</b></p>
        <p>Assign a spot number to the selected spot.</p>
        <Autocomplete
          id="upload-map-locations"
          className='mt-4'
          options={options.concat(optionsSelected)}
          openOnFocus={true}
          selectOnFocus={true}
          onChange={(event: any, value: ZoneActiveSpot | null) => {
            let oldSpot_ = this.state.selectedSpot;
            this.setState({
              selectedSpot: value
            }, () => {

              if (this.props.progressUpdate) {
                this.props.progressUpdate(EnumInteractiveMapStatus.SpotNumberMapping, this.state.selectedZone, this.state.selectedSpot ? this.state.selectedSpot : undefined);
              }

              if (this.props.onClearSpot) {
                this.props.onClearSpot(oldSpot_);
              }
            })
          }}
          getOptionDisabled={(option) => option.isMappingDone
            //  || this.makeDisabledOtherOptions(option)
          }
          getOptionLabel={(option) => option.FriendlySpotId}
          disabled={
            (this.props.mapSelectedSpotDetails != null &&
              !this.props.mapSelectedSpotDetails.isSpotSelectionEnable
            ) ||
            this.props.mapSelectedSpotDetails == null
          }
          value={this.state.selectedSpot}

          renderInput={(params) => <TextField {...params}
                                              placeholder="Select Spot"
                                              variant="outlined"
                                              required={true}
                                              error={this.state.selectedSpot == null}
          />}
        />
        <p className='mt-4'><a href='javascript:void(0)' className='pl-map-details' onClick={() => {
          this.setState({
            isResetAllSpotAttributes: true
          })
        }}>Reset All Spot Attributes</a></p>
        <p><a href='javascript:void(0)' className='pl-map-details' onClick={() => {
          this.setState({
            isResetMap: true
          })
        }}>Reset Map</a></p>
      </div>
    )
  }

  private makeDisabledOtherOptions(option: ZoneActiveSpot) {
    // return (this.state.selectedSpot != null && this.state.selectedSpot.isMappingDone)
    return (this.props.mapSelectedSpotDetails !== null && (JSON.stringify(this.props.mapSelectedSpotDetails.mappedSpots).indexOf(option.SpotGuid) !== -1 ||
      this.state.selectedSpot?.isMappingDone === true))
  }

  private isSaveButtonVisible() {
    let selectedZone = this.state.selectedZone;
    let isVisible = false;

    if (this.props.mapSelectedSpotDetails) {
      isVisible = this.props.mapSelectedSpotDetails.totalSpotsInMap === this.props.mapSelectedSpotDetails.mappedSpots.length;
    }

    if (selectedZone != null && this.props.mapSelectedSpotDetails) {
      // let currentMappedSpots = this.props.mapSelectedSpotDetails.mappedSpots.filter(x => selectedZone?.zoneGuid == x.zoneGuid);
      let zoneList_: string[] = [];

      this.props.mapSelectedSpotDetails.mappedSpots.forEach(x => {
        if (zoneList_.indexOf(x.zoneGuid) === -1) {
          zoneList_.push(x.zoneGuid);
        }
      })

      // isVisible = ((currentMappedSpots.length > 0 && this.state.spotList.length > 0 && currentMappedSpots.length == this.state.spotList.length) &&
      //     ((zoneList_.length + 1) >= this.state.zoneList.length));

      // if (isVisible) {
      let notMappedZoneGuids = this.state.zoneList.filter(x => zoneList_.indexOf(x.zoneGuid) === -1);

      isVisible = ((this.state.zoneList.length === zoneList_.length) ||
        (notMappedZoneGuids.length === 1 && this.state.selectedZone == null) || (notMappedZoneGuids.length > 0 && this.state.selectedZone != null && notMappedZoneGuids[0].zoneGuid === this.state.selectedZone.zoneGuid));
      // console.log(isVisible);
      // }
    }

    return isVisible;
  }

  private isButtonEnabled() {
    let isDisabled = true;

    let selectedZone = this.state.selectedZone;
    if (selectedZone && this.props.mapSelectedSpotDetails != null) {
      let currentMappedSpots = this.props.mapSelectedSpotDetails.mappedSpots.filter(x => selectedZone?.zoneGuid === x.zoneGuid);

      isDisabled = !(((this.state.spotList.length > 0 && currentMappedSpots.length > 0) &&
        (currentMappedSpots.length === this.state.spotList.length ||
          currentMappedSpots.length === this.props.mapSelectedSpotDetails.selectedBoxCount)));

      // console.log(currentMappedSpots.length, this.props.mapSelectedSpotDetails.selectedBoxCount)
    }

    if (this.props.mapSelectedSpotDetails) {
      if (this.props.mapSelectedSpotDetails.totalSpotsInMap === this.props.mapSelectedSpotDetails.mappedSpots.length) {
        isDisabled = false;
      }
    }


    return isDisabled;
  }

  private getPropertyZones() {
    this.setState({
      selectedProperty: this.state.selectedProperty != null ? this.state.selectedProperty : this.props.selectedProperty
    }, () => {
      if (this.state.selectedProperty) {
        this._privateLotService.getActiveZone(this.state.selectedProperty.propertyGuid)
          .then(r => r.json())
          .then(r => {
            let zoneList_ = r.map((x: any) => {
              let zone_: IPrivateLotZone = {
                propertyGuid: x.propertyGuid,
                zoneGuid: x.zoneGuid,
                zoneName: x.zoneName
              }

              return zone_;
            });

            this.setState({
              zoneList: this.sortArrayObject(zoneList_, "zoneName")
            })
          });
      }
    });
  }

  private renderUploadMap() {
    return (
      <SpotMapPopup
        bodyText_="Are you sure you want to delete existing map?<br/>This cannot be undone."
        title_="Delete Existing Map?"
        confirmButtonText_="Yes"
        cancelButtonColor_="No"
        onDone={(isConfirmed: Boolean) => {
          if (isConfirmed) {
            let propertyGuid_ = this.state.selectedProperty?.propertyGuid;
            let zoneData = {
              "propertyGuid": propertyGuid_,
              "levelId": this.props.selectedFloor ? this.props.selectedFloor.levelId : 0
            } as any;


            this._privateLotService.ResetMap(zoneData)
              .then(() => {
                localStorage.removeItem(`asset_property_${this.props.selectedFloor ? this.props.selectedFloor.levelId : 0}_${propertyGuid_}`);

                this.setState({
                  deleteMapPopup: false
                }, () => {
                  if (this.props.onUpdate) {
                    this.props.onUpdate(this.state.mapUrl, this.state.selectedProperty, this.state.selectedFloor);
                  }
                });
              });
          } else {
            this.setState({
              deleteMapPopup: false
            })
          }
        }}
      />
    )
  }
}

interface IPrivateLotsMapDetailsProps {
  isMapLoaded: boolean;
  isMultiSelectedProperty: boolean;
  properties: IPrivateLotProperty[];
  onUpdate: (mapUrl: string, selectedProperty: IPrivateLotProperty | null, selectedFloor: IFloor | null) => void;
  progressUpdate: (status: EnumInteractiveMapStatus, zoneGuid?: IPrivateLotZone | null, spot?: ZoneActiveSpot, updateLevels?: boolean) => void;
  onResetDone: (options: IMapResetOption) => void;
  progress: EnumInteractiveMapStatus;
  selectedSpot: ZoneActiveSpot | null;
  mapSelectedSpotDetails: ISelectedSpotDetails | null;
  onClearSpot: (spot_: ZoneActiveSpot | null) => void;
  selectedProperty: IPrivateLotProperty | null;
  floorLevel: IFloor[];
  selectedFloor: IFloor | null;
}

interface IPrivateLotsMapDetailsState {
  isUploadPopupVisible: boolean;
  isEditMap: boolean;
  zoneList: IPrivateLotZone[];
  selectedProperty: IPrivateLotProperty | null;
  selectedZone: IPrivateLotZone | null;
  spotList: ZoneActiveSpot[];
  selectedSpot: ZoneActiveSpot | null;
  isResetAllSpotAttributes: boolean;
  isResetMap: boolean;
  isEditingCancel: boolean;
  deleteMapPopup: boolean;
  mapUrl: string;
  existingMapStatus: number;
  selectedFloor: IFloor | null;
}
