import {IDailySpotAggregationMap, IPropertyAggregationMap} from "../Pages/BookingsCalendar/interfaces";

export default class ArrayHelper {
  public static findObject<T>(array: T[] | undefined, key: string, value: any): (T | null) {
    if (array) {
      for (let item of array) {
        if ((item as any)[key] === value) {
          return item;
        }
      }
    }

    return null;
  }

  public static stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  public static getComparator<Key extends keyof any>(order: "asc" | "desc", orderBy: Key):
    (a: { [key in Key]: number | string | Date | any }, b: { [key in Key]: number | string | Date | any }) => number {
    return order === 'desc'
      ? (a, b) => this._descendingComparator(a, b, orderBy)
      : (a, b) => -this._descendingComparator(a, b, orderBy);
  }

  public static getNumberInStringComparator<Key extends keyof any>(order: "asc" | "desc", orderBy: Key):
    (a: any, b: any) => number {
    return (a: any, b: any) => {
      let result: number;
      let reA = /[^a-zA-Z]/g;
      let reN = /[^0-9]/g;

      let aValue = String(a[orderBy]);
      let bValue = String(b[orderBy]);

      let aA = aValue.toLocaleLowerCase().replace(reA, "");
      let bA = bValue.toLocaleLowerCase().replace(reA, "");

      if (aA === bA) {
        var aN = parseInt(aValue.toLocaleLowerCase().replace(reN, ""), 10);
        var bN = parseInt(bValue.toLocaleLowerCase().replace(reN, ""), 10);

        result = aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
        result = aA > bA ? 1 : -1;
      }

      return result * (order === 'desc' ? -1 : 1);
    }
  }

  public static sortSpots(spots: IDailySpotAggregationMap[]) {
    return spots.sort((a: IDailySpotAggregationMap, b: IDailySpotAggregationMap) => {
      let result: number;
      let reA = /[^a-zA-Z]/g;
      let reN = /[^0-9]/g;

      let aValue = String(a.friendlySpotId);
      let bValue = String(b.friendlySpotId);

      let aA = aValue.toLocaleLowerCase().replace(reA, "");
      let bA = bValue.toLocaleLowerCase().replace(reA, "");

      if (aA === bA) {
        var aN = parseInt(aValue.toLocaleLowerCase().replace(reN, ""), 10);
        var bN = parseInt(bValue.toLocaleLowerCase().replace(reN, ""), 10);

        result = aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
        result = aA > bA ? 1 : -1;
      }

      return result * 1;
    })
  }

  public static sortProperty(properties: IPropertyAggregationMap[]) {
    return properties.sort((a: IPropertyAggregationMap, b: IPropertyAggregationMap) => {
      let result: number;
      let reA = /[^a-zA-Z]/g;
      let reN = /[^0-9]/g;

      let aValue = String(a.propertyName);
      let bValue = String(b.propertyName);

      let aA = aValue.toLocaleLowerCase().replace(reA, "");
      let bA = bValue.toLocaleLowerCase().replace(reA, "");

      if (aA === bA) {
        var aN = parseInt(aValue.toLocaleLowerCase().replace(reN, ""), 10);
        var bN = parseInt(bValue.toLocaleLowerCase().replace(reN, ""), 10);

        result = aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
        result = aA > bA ? 1 : -1;
      }

      return result * 1;
    })
  }

  public static sortArrayObject<T>(array: T[], orderBy: keyof T, order: "asc" | "desc", headerColumn?: any[], key?: string) {
    const column = headerColumn && headerColumn.length > 0 ? ArrayHelper.findObject(headerColumn, key ?? "", orderBy) : null;
    let isAlphaNumeric = column?.alphaNumeric;
    if (isAlphaNumeric) {
      if (order === "asc") {
        array.sort((a, b) => String(b[orderBy]).localeCompare(String(a[orderBy]), undefined, {numeric: true})).reverse();
      } else {
        array.sort((a, b) => String(b[orderBy]).localeCompare(String(a[orderBy]), undefined, {numeric: true}));
      }
    }
    return array;
  }

  public static sortArrayAlphaNumericObj<T>(array: T[], orderBy: keyof T, order: "asc" | "desc") {
    if (order === "asc") {
      array.sort((a, b) => String(b[orderBy]).localeCompare(String(a[orderBy]), undefined, {numeric: true})).reverse();
    } else {
      array.sort((a, b) => String(b[orderBy]).localeCompare(String(a[orderBy]), undefined, {numeric: true}));
    }
    return array;
  }

  private static _descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    let aValue = a[orderBy] as any;
    let bValue = b[orderBy] as any;

    if (typeof (aValue) === "string") {
      aValue = aValue.toLocaleLowerCase();
    }
    if (typeof (bValue) === "string") {
      bValue = bValue.toLocaleLowerCase();
    }

    if (bValue < aValue) {
      return -1;
    }
    if (bValue > aValue) {
      return 1;
    }
    return 0;
  }
}
