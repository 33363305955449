import React from "react";
import { ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, FormControlLabel, Checkbox, TextField, RadioGroup, Radio } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default class RadioListFilter extends React.Component<IRadioListFilterProps, IRadioListFilterState> {

    constructor(props_: IRadioListFilterProps) {
        super(props_);
        this.state = {
            selectedItem: props_.selectedItem,
            items: props_.applySort ? props_.items.sort() : props_.items
        };
    }

    componentDidUpdate(oldProps_: IRadioListFilterProps) {
        if (oldProps_.selectedItem != this.state.selectedItem) {
            this.setState({
                selectedItem: this.props.selectedItem
            });
        }
    }

    render() {
        return (
            <ExpansionPanel className="filter-panel">
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography className="filter-heading">{this.props.title}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <RadioGroup>
                        {
                            <FormControlLabel
                                control={<Radio
                                    checked={this.state.selectedItem == ""}
                                    onClick={() => {
                                        this.setState({
                                            selectedItem: ""
                                        });
                                        this.props.onChange("");
                                    }}
                                    color="primary"
                                />}
                                label="Any" />
                        }
                        {
                            this.state.items.map((value_, index_) => (
                                <div className="property-label" key={index_}>
                                    <FormControlLabel
                                        control={<Radio
                                            checked={this.state.selectedItem == value_}
                                            onClick={() => {
                                                this.setState({
                                                    selectedItem: value_
                                                });
                                                this.props.onChange(value_);
                                            }}
                                            color="primary"
                                        />}
                                        label={value_}
                                    />
                                </div>
                            )
                            )}
                    </RadioGroup>
                    {this.props.children}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

interface IRadioListFilterProps {
    title: string;
    placeholderText: string;
    items: string[];
    selectedItem: string;
    applySort?: boolean;
    onChange: (selectedItem_: string) => void;
}

interface IRadioListFilterState {
    selectedItem: string;
    items: string[];
}