import BaseService from "./BaseService";
import {ConfigService} from '../Services/ConfigService';
import {GetUserDetailsService} from "./GetUserDetailsService";
import {ISingleScheduleData} from "../Components/ScheduleComponent";
import {ISpot, ISpotBlockRequest} from "../Pages/Spots/Interfaces";
import {IReservationRequest} from "../Pages/Schedule/Interfaces";
import {format} from "date-fns";

export default class SpotService extends BaseService {
  public getSpotList() {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/all", {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
      });
  }

  public getSelectedSpotList(spotGuids_: string[]) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/spot-revenue", {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(spotGuids_)
        })
      });
  }

  public getUnclaimedSpotList() {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/unclaimed", {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
      });
  }

  public claimSpots(spotGuids_: string[]) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/claim", {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(spotGuids_)
        })
      });
  }

  public getPropertySpots(propertyGuid_: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/property-spots/${propertyGuid_}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
      });
  }

  public getActivePropertySpots(propertyGuid_: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/property-spots-active/${propertyGuid_}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
      });
  }

  public addSpot(spot_: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/insert-spot", {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(spot_)
        })
      });
  }

  public updateSpot(spot_: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/update-spot", {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(spot_)
        })
      });
  }

  public updateSpotStatus(spotStatus_: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/update-spot-status", {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(spotStatus_)
        })
      });
  }

  public updateMultiSpotStatus(spotGuids_: string[], status_: number) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/update-multi-spot-status/${status_}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(spotGuids_)
        })
      });
  }

  public getFeatureList() {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + 'spot/features', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
      });
  }

  public getSpotSchedules(spotGuid_: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/get-schedule/${spotGuid_}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })
      });
  }

  public getZoneSchedules(zoneGuid_: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/get-zone-schedule/${zoneGuid_}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })
      });
  }

  public updateSpotSchedule(spotGuid_: string, schedules_: ISingleScheduleData[]) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/update-schedule/${spotGuid_}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(schedules_)
        })
      });
  }

  public approveClaim(spotGuid_: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/approve-claim/${spotGuid_}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })
      });
  }

  public rejectClaim(spotGuid_: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/reject-claim/${spotGuid_}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })
      });
  }

  public saveSpots(propertyGuid_: string, spots_: ISpot[]) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/batch-update/${propertyGuid_}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(spots_)
        })
      });
  }

  public getReservations(spotGuid_?: string, date_?: string) {
    let reservationsUrl = `spot/reservations`

    if (spotGuid_) {
      reservationsUrl += "/" + spotGuid_;

      if (date_) {
        reservationsUrl += "/" + date_;
      }
    }

    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + reservationsUrl, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })
      });
  }

  public getLatestReservations(spotGuid_?: string, date_?: string) {
    let reservationsUrl = `spot/filtered/reservations`

    if (spotGuid_) {
      reservationsUrl += "/" + spotGuid_;

      if (date_) {
        reservationsUrl += "/" + date_;
      }
    }

    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + reservationsUrl, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })
      });
  }

  public getReservationCount(year_: number, month_: number) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/reserved-count/${year_}/${month_}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })
      });
  }

  public reserveSpot(spotGuid_: string, reservation_: IReservationRequest) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/reserve/${spotGuid_}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(reservation_)
        })
      });
  }

  public getTenantList() {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/all/tenantspot", {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
      });
  }

  public getReservationActivity(reservationGUID: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/reservation-activity/" + reservationGUID, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + GetUserDetailsService.getUserDetails().accessToken

          },
        })
      });
  }

  public blockSpot(spotGuid_: string, blockDetails_: ISpotBlockRequest) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/block/${spotGuid_}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(blockDetails_)
        })
      });
  }

  public unblockSpot(blockGuid_: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/unblock/${blockGuid_}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })
      });
  }

  public getBlocks(spotGuid_: string, minDate_?: Date, maxDate_?: Date) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/blocks/${spotGuid_}` + (minDate_ ? (maxDate_ ? `/${format(minDate_, "yyyy-MM-dd")}/${format(maxDate_, "yyyy-MM-dd")}` : `/${format(minDate_, "yyyy-MM-dd")}`) : ""), {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })
      });
  }

  public getBlocksV2(spotGuid_: string, minDate_: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/blocks-v2/${spotGuid_}/${minDate_}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })
      });
  }

  public getTenantSpots() {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/GetTenantSpots", {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
      });
  }

  public getDashboardWidgets(requestModel: any, requestName: string, hideLoader?: boolean) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + 'spot/' + requestName, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestModel)
        }, 'widget', hideLoader)
      });
  }

  public getTenantDashboardBookings(requestModel: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + 'spot/TenantDashboardBookingsV3', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestModel)
        })
      });
  }

  public CheckSpotReservations(spotGuids_: string[]) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + 'spot/check-reservation', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(spotGuids_)
        })
      });
  }

  public AdminCancelReservation(requestModel: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + 'spot/booking/admin-cancel-reservation', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestModel)
        })
      });
  }

  public AdminCancelOverlpappingReservation(requestModel: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + 'spot/booking/cancel-overlapping-reservation', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestModel)
        });
      });
  }

  public cancelReservation(reservationGuid: string, refundAction: boolean) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/booking/cancel-upcoming/${reservationGuid}/${refundAction}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })
      });
  }

  public getActiveSubscriptions(spotGuid_: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/subscriptions/${spotGuid_}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
      });
  }

  public preponeBookingByAMonth(reservationGuid_: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/subscription/prepone-booking/${reservationGuid_}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
      });
  }

  public billSubscriptionNow(reservationGuid_: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/subscription/bill-now/${reservationGuid_}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
      });
  }

  public retryPaymentAndUpdateStatus(reservationGuid_: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/month-booking/retry-renewal/${reservationGuid_}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
      });
  }

  public renewZeroDollarBookings(reservationGuid_: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/booking/free-renewal/${reservationGuid_}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
      });
  }

  public getParkerBookings(requestModel: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + 'spot/parker/reservations', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestModel)
        })
      });
  }

  public getSpotsForRebooking(reservationGuid_: string, rebookingSpotSearchRequest: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/re-booking/search-spot/${reservationGuid_}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(rebookingSpotSearchRequest)
        }, 'ignoreAlert')
      });
  }

  public updateSpotsRebooking(reservationGuid_: string, spotGuid_: string, rebookingSpotSearchRequest: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/re-booking/re-book/${reservationGuid_}/${spotGuid_}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(rebookingSpotSearchRequest)
        }, 'ignoreAlert')
      });
  }

  public getBookingForDay(propertyGuid_: string, date_: string, hideLoader: boolean = false) {
    let apiUrl = `spot/booking/view-bookings/${propertyGuid_}/${date_}`
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + apiUrl, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }, 'bookingstatus', hideLoader)
      });
  }

  public getPropertyBookingCount(propertyGuid_: string, year_: number, month_: number, hideLoader: boolean = false) {
    let apiUrl = `spot/booking/get-monthly-count/${propertyGuid_}/${year_}/${month_}`
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + apiUrl, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }, 'bookingstatus', hideLoader)
      });
  }

  public getBookingBlockingStatus() {
    let apiUrl = `spot/booking/booking-blocking/status`
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + apiUrl, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }, 'bookingstatus', true)
      });
  }

  public getPropertyAllActiveNames() {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/booking/active-spots/property/names", {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
      });
  }

  public AddEditBlockSpots(requestModel: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + 'spot/booking/add-edit/block-spots', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestModel)
        }, 'ignoreAlert')
      });
  }

  public CheckOverLapping(requestModel: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + 'spot/booking/get-overlapping', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestModel)
        }, 'ignoreAlert')
      });
  }

  public deleteBlockedSpot(blockedGuid_: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/booking/delete/blocked-spots/${blockedGuid_}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
      });
  }

  public ContactSupport(requestModel: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + 'spot/booking/contact-support/message', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestModel)
        })
      });
  }

  public getPropertyZones(propertyGuid_: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/property-zones/${propertyGuid_}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
      });
  }

  public getActiveSpots(spotDetails: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(`${url}spot/cash-booking/available-spots`, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(spotDetails)
        });
      });
  }

  public saveHoldSpot(newCashBookingRequest: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(`${url}spot/booking/cash/hold-spot`, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newCashBookingRequest)
        });
      });
  }

  public saveConfirmCashBooking(blockGuid: string) {
    const bookingUrl = `spot/booking/confirm-offline-booking/${blockGuid}`;
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(`${url}${bookingUrl}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
      });
  }

  public saveUnHoldSpot(blockGuid: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(`${url}spot/booking/unhold-spot/${blockGuid}`, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
      });
  }

  public getReservation(propertyGuid_?: string) {
    let reservationsUrl = `spot/cash-booking/reservations`

    if (propertyGuid_) {
      reservationsUrl += "/" + propertyGuid_;
    }

    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + reservationsUrl, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })
      });
  }

  public InitiateReservationPayment(InitiateReservationPayment: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(`${url}spot/booking/initiate-payment`, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(InitiateReservationPayment)
        }, undefined, true, true);
      });
  }

  public paymentStatus(paymentIntentId: string, param: string = "") {
    const bookingUrl = `spot/booking/payment-status/${paymentIntentId}${param}`;
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(`${url}${bookingUrl}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }, undefined, true, true);
      });
  }

  public updateHoldMetadata(blockGuid: string, isBack?: boolean) {
    const bookingUrl = `spot/booking/cash/updateHoldMetadata/${blockGuid}`;
    return ConfigService.getBaseUrl()
      .then(url => {
        return isBack ? BaseService.sendRequest(`${url}${bookingUrl}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }) : BaseService.sendRequest(`${url}${bookingUrl}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }, undefined, true, true);
      });
  }

  public getSpot(spotGuid: string[]) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/getspot", {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(spotGuid)
        })
      });
  }

  public getUserSerchList(userSerch: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(`${url}spot/custom-booking/user-search/${userSerch}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
      });
  }

  public getPreBookingProperty() {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/custom-booking/get-prebooking-properties", {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
      });
  }

  public getPreBookingZoneSpot(zoneSpotRequest: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/custom-booking/prebooking-zones-spots", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(zoneSpotRequest)
        });
      });
  }

  public getVehicle(parkerGuid: string) {
    const parkerEncodeGuid = parkerGuid ? parkerGuid : "00000000-0000-0000-0000-000000000000";
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(`${url}spot/custom-booking/get-vehicle/${parkerEncodeGuid}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }, undefined, undefined, true);
      });
  }

  public getMonthlyPreBookingZoneSpot(monthlyZoneSpotRequest: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/custom-booking/monthly/prebooking-zones-spots", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(monthlyZoneSpotRequest)
        });
      });
  }

  public getBookingZoneAvailability(availabilityRequest: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/booking/zone-availability", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(availabilityRequest)
        });
      });
  }

  public getBookingPropertyAvailability(availabilityRequest: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/month-booking/property-zone-availability", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(availabilityRequest)
        });
      });
  }

  public getBookedSpotDetails(bookedSpotRequest: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "spot/custom-booking/spot-booked-detail", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bookedSpotRequest)
        });
      });
  }

  public getCardList(parkerEncodedGuid: any, accessTokens: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
          return BaseService.sendRequest(url + `user/payment-cards/${parkerEncodedGuid}`, {
            method: 'get',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessTokens
            },
          })
        }
      )
  }

  public checkoutOption(data: any, accessTokens: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
          return BaseService.sendRequest(url + "spot/booking/checkout-options/", {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessTokens
            },
            body: JSON.stringify(data)
          })
        }
      )
  }

  public addCreditOrCoupon(code: string, parkerEncodedGuid: string, accessTokens: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
          return BaseService.sendRequest(url + `user/custom-booking/voucher/${code}/${parkerEncodedGuid}`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessTokens
            },
          })
        }
      )
  }

  public getCouponsList(parkerEncodedGuid: string, accessTokens: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
          return BaseService.sendRequest(url + `user/custom-booking/coupons/${parkerEncodedGuid}`, {
            method: 'get',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessTokens
            },
          })
        }
      )
  }

  public getMaxServerFee(accessTokens: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
          return BaseService.sendRequest(url + `spot/custom-booking/get-max-servicefee`, {
            method: 'get',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessTokens
            },
          })
        }
      )

  }

  public holdSpot(accessTokens: string, postData: any, bookingType: string) {
    return ConfigService.getBaseUrl()
      // url + ${"spot/booking/hold-zones-spot"
      .then(url => {
          return BaseService.sendRequest(`${url}${bookingType == `Monthly` ? `spot/month-booking/hold-zones-spot` : `spot/booking/hold-zones-spot`}`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessTokens
            },
            body: JSON.stringify(postData)

          }, undefined, true)
        }
      )
  }

  public initiate_payment(accessTokens: string, blockGuid: string, cardId: string, ParkerEncodeGuid: string) {
    let postData = {
      TemporaryBlockGuid: blockGuid,
      PaymentCardId: cardId,
      ParkerEncodeGuid: ParkerEncodeGuid
    };
    return ConfigService.getBaseUrl()
      .then(url => {
          return BaseService.sendRequest(url + "spot/booking/initiate-payment", {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessTokens
            },
            body: JSON.stringify(postData)
          }, undefined, true)
        }
      )
  }

  public guestInitiate_payment(accessTokens: string, blockGuid: string, cardId: string, ParkerEncodeGuid: string) {
    let postData = {
      TemporaryBlockGuid: blockGuid,
      PaymentCardId: cardId,
      ParkerEncodeGuid: ParkerEncodeGuid
    };
    return ConfigService.getBaseUrl()
      .then(url => {
          return BaseService.sendRequest(url + "spot/booking/guest/initiate-payment", {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessTokens
            },
            body: JSON.stringify(postData)
          }, undefined, true)
        }
      )
  }

  public check_payment_no_final(accessTokens: string, paymentID: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
          return BaseService.sendRequest(url + `spot/booking/payment-status/${paymentID}`, {
            method: 'get',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessTokens
            },

          }, undefined, true, true)
        }
      )
  }

  public check_payment_with_final(accessTokens: string, paymentID: string, isModify: boolean = false) {
    const urlFinal = isModify ? `spot/booking/payment-status/${paymentID}/final/${isModify}` : `spot/booking/payment-status/${paymentID}/final`;
    return ConfigService.getBaseUrl()
      .then(url => {
          return BaseService.sendRequest(url + urlFinal, {
            method: 'get',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessTokens
            },
          }, undefined, true)
        }
      )
  }

  public unholdSpot(accessTokens: string, blockGuid: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
          return BaseService.sendRequest(url + `spot/booking/unhold-spot/${blockGuid}`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessTokens
            },
          })
        }
      )
  }

  public getReservationDetails(reservationGuid: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/reservation-activity/${reservationGuid}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        });
      });
  }

  public getSpotsForRebookingV2(reservationGuid_: string, rebookingSpotSearchRequest: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/modify-booking/search-spot/${reservationGuid_}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(rebookingSpotSearchRequest)
        }, 'ignoreAlert')
      });
  }

  public updateVehicle(postData: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `spot/modify-booking/update-vehicle`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData)
        });
      });
  }
}
