import { ConfigService } from "./ConfigService";
import { ShowAlert } from "../Common/ShowAlert";
import { GetUserDetailsService } from "./GetUserDetailsService";
import { ShowBlocker, ShowCustomBlocker } from "../Components/LoaderComponent";

export default class BaseService {
    // private static _token: string = "";
    // private static _baseUrl: string = "";

    // constructor() {
    //     ConfigService.getBaseUrl()
    //         .then(url_ => {
    //             this._baseUrl = url_;
    //         });
    // }

    public static sendRequest(url_: string, requestInit_: RequestInit, requestName_?: string, 
        hideLoader?: boolean, noError_?: boolean, showCustomLoader?: boolean, noAutoCloseBlocker?: boolean) {
        // Todo: Show blocker
        if (!hideLoader) {
            ShowBlocker(true);
        }

        if (hideLoader && showCustomLoader) {
            ShowCustomBlocker(true);
        }


        if (!requestInit_) {
            requestInit_ = {};
        }
        if (!requestInit_.headers) {
            requestInit_.headers = {};
        }

        if (!(requestInit_.headers as any)["Authorization"] && !(requestInit_.headers as any)["X-Figma-Token"]) {
            let token = GetUserDetailsService.getUserDetails().accessToken;
            if (token) {
                (requestInit_.headers as any)["Authorization"] = "Bearer " + token;
            }
        }

        let request = fetch(url_, requestInit_)
            .then(r => {
                let isErrorHandled = false;
                // Unauthorized, so exit
                if (requestName_ != 'ignoreAlert') {
                    if (r.status == 401) {
                        r.text().then(resp => {
                            isErrorHandled = true;
                            let errorMsg = resp != '' ? JSON.parse(resp) : "Your session has expired. You will be logged out.";
                            //ShowAlert("", "Your session has expired. You will be logged out.", "error")
                            ShowAlert("", errorMsg, "error")
                                .then(() => {
                                    localStorage.clear();
                                    window.location.href = '/';
                                });
                        })
                    } else if (r.status == 403) {
                        isErrorHandled = true;
                        r.json().then(resp => {
                            let errorMsg = "You do not have permission to access. Contact your administrator.";
                            ShowAlert("Insufficient permission", errorMsg, "error")
                                .then(() => {
                                    window.location.href = '/';
                                });
                        })
                    } else if (r.status == 400) {
                        isErrorHandled = true;
                        if (!noError_) {
                            ShowAlert("", 'There was an error processing your request.', "error");
                        }
                    }
                }

                if (r.status != 200 && !isErrorHandled) {
                    console.log(url_);
                    if (requestName_ != '' && r.status != 400 && r.status != 401) {
                        if (!noError_) {
                            ShowAlert("", 'Sorry! we were not able to process your request. ', "error");
                        }
                    }
                }

                // Todo: Hide Blocker
                !hideLoader && !noAutoCloseBlocker && ShowBlocker(false);
                hideLoader && showCustomLoader && ShowCustomBlocker(false);
                return r;
            });
        request.catch(x => {
            // Todo: Hide Blocker
            !hideLoader && !noAutoCloseBlocker && ShowBlocker(false);
            hideLoader && showCustomLoader && ShowCustomBlocker(false);
        });

        return request;
    }

    public static asyncResponse<T>(
        fetechResponse_: Promise<Response>,
        contentParser_: (rawPayload_: string, contentLength: number, receivedLength: number) => T[],
        contentProgress_: (content_: T[], progressPercentage_: number) => void
    ): Promise<T[]> {
        return new Promise(async (resolve_, reject_) => {
            fetechResponse_
                .then(async r => {
                    let finalResponse: T[] = [];
                    let responseContent = "";
                    let contentLength = r.headers.get('Content-Length');
                    let receivedLength = 0;
                    let reader = r.body?.getReader();
                    let idx = 0;

                    let read = async () => {
                        let r_ = await reader?.read() as ReadableStreamReadResult<Uint8Array>;
                        let percentage: number = 0;
                        if (r_.value) {
                            receivedLength += r_.value.length;
                            let total = contentLength != null ? Number(contentLength) : receivedLength;
                            percentage = (receivedLength / total);
                        }

                        var responseString = new TextDecoder().decode(r_.value);
                        responseContent = responseContent.concat(responseString);
                        let updatedContentChunk = contentParser_(responseString ? responseString : "", Number(contentLength), receivedLength);
                        finalResponse = finalResponse.concat(updatedContentChunk);

                        // Notify Subscribers once every X reads...
                        // This is to improve the performance
                        if ((idx++) % 10 == 0) {
                            contentProgress_(finalResponse, percentage);
                        }

                        return !r_.done;
                    }

                    while (await read()) { }

                    resolve_(finalResponse);
                });
        });
    }

    private static _getAccessToken(): string {
        return sessionStorage.getItem('token') as string;
    }
}