import BaseService from "./BaseService";
import { ConfigService } from './ConfigService';

export default class QBOService extends BaseService {
    public getQBOLoginURL() {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + 'disbursement/QBO/GetQBOLoginURL', {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }

    public updateQBOToken(code: string, realmId: string) {
        return ConfigService.getBaseUrl()
            .then(url => {
                return BaseService.sendRequest(url + `disbursement/QBO/GenerateandRecordAccessInfo/${code}/${realmId}`, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
            });
    }
}