import { ReactElement } from 'react';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export function ShowAlert(title: string, message: string, type: any) {
    return withReactContent(Swal).fire(title, message, type).then(result => {
        return result.value;
    });
}

export function ShowAlertwithConfirm(title: string,
    message: string,
    type?: SweetAlertIcon,
    confirmButtonText_?: string,
    cancelButtonColor_?: string,
    customClassName?: string) {
    return Swal.fire({
        title: title,
        html: message,
        icon: type,
        iconHtml: '<div><svg class="MuiSvgIcon-root error-icon-size" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg></div>',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: confirmButtonText_ ? confirmButtonText_ : 'Yes',
        cancelButtonText: cancelButtonColor_ ? cancelButtonColor_ : 'Cancel',
        customClass: {
            container: customClassName
        }
    }).then((result) => {
        return result.value ? true : false;
    });
}


export function ShowAlertwithInputConfirm(title: string,
    message: string,
    type?: SweetAlertIcon,
    confirmButtonText_?: string,
    cancelButtonColor_?: string,
    customClassName?: string,
    preConfirmFunction?: any,
    isConfirmButton?: boolean,
    onBeforeOpenFunction?: any
) {
    return Swal.fire({
        title: title,
        html: message,
        icon: type,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        showConfirmButton: isConfirmButton,
        confirmButtonText: confirmButtonText_ ? confirmButtonText_ : 'Yes',
        cancelButtonText: cancelButtonColor_ ? cancelButtonColor_ : 'Cancel',
        customClass: {
            container: customClassName
        },
        preConfirm: preConfirmFunction,
        showLoaderOnConfirm: true,
        onBeforeOpen: onBeforeOpenFunction
    }).then((result) => {
        return result;
    });
}


export function ShowAlertTextOnly(message: string,
    type?: SweetAlertIcon,
    customClassName?: string,
) {
    return Swal.fire(
        {
            title: "",
            html: message,
            icon: type,
            showConfirmButton: false,
            showCancelButton: false,
            customClass: {
                container: customClassName
            },
            allowOutsideClick: false,
            allowEscapeKey: false
        }
    );
}

export function ShowAlertwithMultipleConfirm(title: string, message: string, type: any, isUpcomingCancelled: boolean) {
    return Swal.fire({
        title: title,
        text: message,
        icon: 'warning',
        showDenyButton: isUpcomingCancelled,
        confirmButtonText: 'Yes, cancel booking immediately and initiate refund',
        confirmButtonColor: '#3085d6',
        denyButtonText: 'Yes, cancel upcoming bookings',
        denyButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'No, don\'t inactivate the spots',
        customClass: {
            actions: 'swal2-actions-custom'
        }
    }).then((result) => {
        return result;
    });
}

export function ShowAlertwithOptions(title: string, message: string | ReactElement, type: SweetAlertIcon,
    option1_: string,
    option2_: string | null,
    cancel_: string,
    className?: string
) {
    return Swal.fire({
        title: title,
        // text: message,
        icon: type,
        showDenyButton: option2_ != null,
        confirmButtonText: option1_,
        confirmButtonColor: '#3085d6',
        denyButtonText: option2_ as string,
        denyButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: cancel_,
        customClass: {
            actions: 'swal2-actions-custom',
            container: `${className}`
        },
        html: message
    }).then((result) => {
        return {
            option1: result.isConfirmed,
            option2: result.isDenied,
            cancelled: result.isDismissed
        };
    });
}

export function alertClose() {
    return Swal.close();
}

export function ShowAlertWithConfirmCustomIcon(title: string,
    message: string,
    type?: SweetAlertIcon,
    confirmButtonText_?: string,
    cancelButtonColor_?: string,
    customClassName?: string,
    customIcon?: string) {
    return Swal.fire({
        title: title,
        html: message,
        icon: type,
        iconHtml: customIcon,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: confirmButtonText_ ? confirmButtonText_ : 'Yes',
        cancelButtonText: cancelButtonColor_ ? cancelButtonColor_ : 'Cancel',
        customClass: {
            container: customClassName
        }
    }).then((result) => {
        return result.value ? true : false;
    });
}

export function ShowAlertWithCustomIcon(title: string, message: string, type: any,
    customClassName?: string,
    customIcon?: string) {

    return withReactContent(Swal).fire({
        title: title,
        html: message,
        icon: type,
        iconHtml: customIcon,
        customClass: {
            container: customClassName
        }
    }).then((result) => {
        return result.value ? true : false;
    });
}