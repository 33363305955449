import * as React from 'react';
import {Redirect, Route} from 'react-router';
import { Switch } from 'react-router';
import Login from './Login/Login';
import Register from './Register/Register';
import Onboarding from './Onboarding/Onboarding';
import Users from './Pages/Users/UsersList';
import DashbaordV1 from './Pages/Dashboard/Dashboard';
import Property from './Property/Property';
import Company from './Company/Company';
import UITest from './Pages/UITest';
import PropertyList from './Pages/Properties/PropertyList';
import PropertiesOverview from './Pages/Properties/PropertiesOverview';
import PropertiesParkingSpots from './Pages/Properties/PropertiesParkingSpots';
import GoogleMaps from './Components/GoogleMapsComponent';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import VerifyEmail from './Pages/VerifyEmail/VerifyEmail';
import Profile from './Pages/Profile/Profile';
import PropertiesUsers from './Pages/Properties/PropertiesUsers';
import Payments from './Pages/Payment/Payment';
import Plaid from './Pages/Plaid/Plaid';
import Spots from './Pages/Spots/Spots';
import Help from './Pages/Help/Help';
import Permission from './Pages/Permission/Permission';
import NotFound from './Pages/404/NotFound';
import SubscriptionList from './Pages/Schedule/SubscriptionList';
import Transactions from './Pages/Disbursement/Transaction';
import ZoneList from './Pages/Zone/ZoneList';
import PrivateLots from './Pages/PrivateLots/PrivateLots';
import ActivateParker from './Pages/ActivateParker/ActivateParker';
import ParkingPassesList from './Pages/PrivateLots/ParkingPasses/ParkingPassesList';
import EnforcementList from './Pages/Enforcement/EnforcementList';
import VisitorParkings from './Pages/PrivateLots/VisitorParking/VisitorParkings';

const isAuthenticated = !!localStorage.getItem('userdata');

export default (
    <Switch>
        <Route path="/register/:registrationID?" component={Register} exact={true} />
        <Route path="/Companies" component={Company} exact={true} />
        <Route path="/Users" component={Users} exact={true} />
        <Route path="/Onboarding" component={Onboarding} exact={true} />

        {/*<Route path="/Dashboard" component={Property} exact={true} />*/}
        <Route path="/Dashboard" exact={true}>
                {isAuthenticated ? <Redirect to="/Properties" /> : <Redirect to="/" />}
        </Route>

        <Route path="/dashboard-old" component={DashbaordV1} exact={true} />
        <Route path="/Properties" component={Property} exact={true} />
        <Route path="/PropertyList" component={PropertyList} exact={true} />
        <Route path="/Spots/:source?/:propertyGuid?/:requestType?" component={Spots} exact={true} />
        <Route path="/" component={Login} exact={true} />
        {/* <Route path="/Bookings/:source?/:propertyGuid?/:spotGuid?" component={ReservationList} exact={true} /> */}
        <Route path="/ui-test" component={UITest} exact={true} />
        {/* <Route path="/bulk-upload" component={BulkUpload} exact={true} /> */}
        <Route path="/maps" component={GoogleMaps} exact={true} />
        <Route path="/forgotpassword/:requestID?" component={ForgotPassword} exact={true} />
        <Route path="/Properties/:propertyID/overview" component={PropertiesOverview} exact={true} />
        <Route path="/Properties/:propertyID/parkingspots" component={PropertiesParkingSpots} exact={true} />
        <Route path="/Properties/:propertyID/propertiesusers" component={PropertiesUsers} exact={true} />
        <Route path="/settings" component={Profile} exact={true} />
        <Route path="/Payment" component={Payments} exact={true} />
        <Route path="/Plaid" component={Plaid} exact={true} />
        <Route path="/Help" component={Help} exact={true} />
        <Route path="/Permission" component={Permission} exact={true} />
        <Route path="/Subscriptions" component={SubscriptionList} exact={true} />
        <Route path="/verify/:shortLiveGuid" component={VerifyEmail} exact={true} />
        <Route path="/activate/:shortLiveGuid" component={ActivateParker} exact={true} />
        {/* <Route path="/Calendar/:source?/:propertyGuid?/:spotGuid?" component={BookingsCalendar} exact={true} /> */}
        <Route path="/transactions" component={Transactions} exact={true} />
        <Route path="/Zones" component={ZoneList} exact={true} />
        <Route path="/lot-management" component={PrivateLots} exact={true} />
        <Route path="/parking-passes" component={ParkingPassesList} exact={true} />
        {/* <Route path="/cashBookings/:source?/:propertyGuid?/:spotGuid?" component={CashBookingList} exact={true} /> */}
        <Route path="/enforcements" component={EnforcementList} exact={true} />
        <Route path="/visitor-parking" component={VisitorParkings} exact={true} />
        <Route component={NotFound} />
    </Switch>
);
