import React from "react";
import { makeStyles, Theme, createStyles, lighten, Grid, Paper, InputBase, Tooltip, IconButton } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useDataGridToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: '1 1 100%',
        },
        toolheader: {
            padding: '0px 10px',
            marginBottom: '10px'
        },
        toolContainer: {
            textAlign: 'right',
            padding: '0px'
        },
        toolButton: {
            marginLeft: '5px',
        }
    }),
);

const DataGridToolbar = (props_: IDataGridToolbarProps) => {
    const classes = useDataGridToolbarStyles();
    const [searchedTextState, setSearchedTextState] = React.useState<string>(props_.search?props_.search:'');
    const [searchedTextFocused, setSearchedTextFocused] = React.useState<boolean>(props_.search?true:false);
    const [searchInput, setSearchInput] = React.useState<null | HTMLElement>(null);
    const className = props_.className ? props_.className : "";

    const enableSearch = (event: any) => {
        if (searchInput) {
            searchInput.focus();
        }
        setSearchedTextFocused(true);
    }

    const clearSearch = (event: any) => {
        if (searchInput) {
            searchInput.focus();
        }
        setSearchedTextState('');
        if (props_.onSearch) {
            props_.onSearch('');
        }
    }

    const handleSearchTextChange = (event: any) => {
        var _targetValue = event.target.value;
        setSearchedTextState(_targetValue);
        if (props_.onSearch) {
            props_.onSearch(_targetValue);
        }
    }

    const handleSearchTextBlur = (event: any) => {
        var _targetValue = event.target.value;

        if (_targetValue == '') {
            setSearchedTextFocused(false);
        }

    }

    return (        
        <div className={`${classes.toolContainer} ${className}`}>
            {!props_.isNoSearch && <div className={'table-search-container' + (searchedTextFocused == true ? ' table-search' : ' table-search-blur')}>
                <Paper className={classes.root + ' table-search-input-container'}>
                    <InputBase
                        name="search"
                        className="table-search-input"
                        placeholder={"Search " + props_.pluralEntityName}
                        inputProps={{ 'aria-label': 'search ' + props_.pluralEntityName }}
                        value={searchedTextState}
                        onChange={handleSearchTextChange}
                        onBlur={handleSearchTextBlur}
                        inputRef={refElm => {
                            setSearchInput(refElm)
                        }}
                    />
                    {
                        searchedTextState.length > 0 ?
                            <Tooltip title="Clear" className={classes.toolButton}>
                                <IconButton type="submit" aria-label="Clear" onClick={clearSearch}>
                                    <ClearIcon />
                                </IconButton>
                            </Tooltip>
                            :
                            <Tooltip title="Search" className={classes.toolButton}>
                                <IconButton type="submit" aria-label="Search" onClick={enableSearch}>
                                    <SearchIcon />
                                </IconButton>
                            </Tooltip>
                    }
                </Paper>
            </div>}
            {
                !props_.isNoFilterButton ? <Tooltip title="Filter list" className={classes.toolButton}>
                    <IconButton aria-label="filter list" onClick={() => props_.onFilterClick()}>
                        <FilterListIcon color={props_.isFiltered ? "primary" : undefined} />
                    </IconButton>
                </Tooltip> : null
            }

            {
                props_.children ? props_.children : null
            }

            {
                !props_.isNoAddButton ? <Tooltip title={"Add " + props_.singularEntityName} className={classes.toolButton + ' add-lit-button'}>
                    <IconButton aria-label={"Add " + props_.singularEntityName}
                        onClick={() => {
                            if (props_.onAddClick) {
                                props_.onAddClick();
                            }
                        }}>
                        <AddIcon fontSize="small" />
                    </IconButton>
                </Tooltip> : null
            }

        </div>
    );
};

interface IDataGridToolbarProps {
    search?:string|null
    singularEntityName: string;
    pluralEntityName: string;
    onSearch: (searchText: string) => void,
    onAddClick?: () => void;
    onFilterClick: () => void;
    isFiltered: boolean;
    isNoAddButton?: boolean;
    isNoFilterButton?: boolean;
    children?: JSX.Element;
    isNoSearch?: boolean;
    className?: string
}

export default DataGridToolbar;