import React from "react";
import { Button } from "@material-ui/core";
import ErrorIcon from '@material-ui/icons/Error';


export default class SpotMapPopup extends React.Component<ISpotMapPopupProps, ISpotMapPopupState>{
    constructor(props_: ISpotMapPopupProps) {
        super(props_);
    }

    render() {
        return (
            <>
                <div className="bulk-upload-blocker">
                    <div className="map-upload-popup">
                        <div>
                            <div className="d-flex flex-column align-items-center">
                                <div className="bulk-upload-sub-content text-center">
                                    <ErrorIcon color="error" style={{ fontSize: '45px' }} />
                                </div>
                                <div className="bulk-upload-header mt-3">
                                    {this.props.title_}
                                </div>
                                <div className="mt-4 text-center" dangerouslySetInnerHTML={{
                                    __html: this.props.bodyText_
                                }} />
                            </div>
                            <div className="d-flex justify-content-center align-items-center mt-4 sp-m-btns">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className="ml-2"
                                    style={{ width: 131 }}
                                    onClick={() => {
                                        if (this.props.onDone) {
                                            this.props.onDone(true)
                                        }
                                    }}
                                >
                                    {this.props.confirmButtonText_}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className="ml-2"
                                    style={{ width: 131 }}
                                    onClick={() => {
                                        if (this.props.onDone) {
                                            this.props.onDone(false)
                                        }
                                    }}
                                >
                                    {this.props.cancelButtonColor_}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

interface ISpotMapPopupProps {
    title_: string;
    bodyText_: string;
    confirmButtonText_: string;
    cancelButtonColor_: string;
    onDone:(isConfirm: boolean) => void;
}

interface ISpotMapPopupState {

}