import React from "react";
import "./BasicComponents.css";
import { FormControl, InputLabel, FormLabel } from "@material-ui/core";

export default class LabelControl extends React.Component<ILabelControlProps> {
    render() {
        return (
            <FormControl className="label-control w-100 my-2">
                <FormLabel className="label-control-label">{this.props.label}</FormLabel>
                <span className="label-control-value">{this.props.children}</span>
            </FormControl>
        );
    }
}

interface ILabelControlProps {
    label: string;
}