import Button from "@material-ui/core/Button/Button";
import moment from "moment";
import React from "react";
import StringHelper from "../../Helpers/StringHelper";
import "./AssignTenant.css";
export default class AssignedVisitorTenant extends React.Component<IAssignedVisitorTenantProps, IAssignedVisitorTenantState> {

    constructor(props_: IAssignedVisitorTenantProps) {
        super(props_);
        this.state = {
            spotNo: this.props.spotNo,
            teantUnitNumber: this.props.teantUnitNumber,
            teantName: this.props.teantName,
            teantEmail: this.props.teantEmail,
            teantPhone: this.props.teantPhone,
            teantDuration: this.props.teantDuration,
            teantLicencePlate: this.props.teantLicencePlate,
            startUTCTime: this.props.startUTCTime
        }
    }

    isBookingStarted(startUTCTime: Date | null) {
        try {
            var now = moment.utc(new Date());
            var end = moment.utc(startUTCTime);
            var duration = moment.duration(now.diff(end));
            // console.log("difference", duration.asSeconds());
            if (duration.asSeconds() >= 0) {
                return true;
            } else if (isNaN(duration.asSeconds())) {
                return true;
            }
            else {
                return false;
            }
        }
        catch {
            return true;
        }




    }
    render() {
        const teantLicencePlate = this.props.teantLicencePlate.filter((x: string) => x !== "");
        const isBookingStarted = this.isBookingStarted(this.props.startUTCTime);
        return (
            <>
                <div >
                    <div className="base-root-view">
                        <div style={{ 'width': '75%', 'backgroundColor': 'white', 'alignSelf': 'center', 'borderRadius': '10px' }}>
                            <div style={{ 'width': '100%', 'height': '50px', 'backgroundColor': '#00AF85', borderTopLeftRadius: '10px', 'borderTopRightRadius': '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <div style={{ 'display': 'flex', 'flexDirection': 'row', 'width': '100%', justifyContent: 'space-between' }}>
                                    <div>
                                        <label style={{ 'marginLeft': '20px', color: 'white', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '14px' }}>Spot No.</label>
                                        <label style={{ color: 'white', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '14px' }}>{this.state.spotNo}</label>
                                    </div>
                                    <div>


                                    </div>
                                </div>
                            </div>


                            <div style={{ 'margin': '10px', display: 'flex', 'flexDirection': 'column' }}>
                                {teantLicencePlate.length > 0 && <div>
                                    <label style={{ "marginTop": '10px', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '15px', color: '#111012' }}>Visitor License Plate(s)</label>
                                    <div className="grid">
                                        {teantLicencePlate.map((val, index) =>
                                            <div className="item" style={{}}>
                                                {/* {val} */}
                                                <label style={{ 'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'center', 'height': '100%', 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '15px', color: '#111012', alignItems: 'center' }}>{val}</label>
                                            </div>

                                        )}
                                    </div>
                                </div>
                                }
                                <div style={{ 'width': '100%', 'height': '1px', backgroundColor: '#DADDDF', 'marginTop': '5px' }}></div>
                                <label style={{ "marginTop": '10px', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '15px', color: '#111012' }}>Duration</label>
                                <label style={{ 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '15px', color: '#111012' }}>{this.state.teantDuration}</label>
                                <div style={{ 'width': '100%', 'height': '1px', backgroundColor: '#DADDDF' }}></div>
                                <label style={{ "marginTop": '10px', 'fontFamily': 'Manrope', 'fontWeight': 700, 'fontSize': '15px', color: '#111012' }}>Tenant Details </label>
                                <label style={{ 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '15px', color: '#111012' }}>{this.state.teantUnitNumber}</label>
                                {this.state.teantName && <label style={{ 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '15px', color: '#111012' }}>{this.state.teantName}</label>}
                                {this.state.teantEmail && <label style={{ 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '15px', color: '#111012' }}>{this.state.teantEmail}</label>}
                                {this.state.teantPhone && <label style={{ 'fontFamily': 'Manrope', 'fontWeight': 400, 'fontSize': '15px', color: '#111012' }}>{StringHelper.ConvertPhoneNumberHyphen(this.state.teantPhone)}</label>}


                                {
                                   isBookingStarted &&
                                    (
                                        <>
                                            <div style={{ 'width': '100%', 'height': '1px', backgroundColor: '#DADDDF' }}></div>
                                            <Button style={{ 'marginTop': '10px', width: '100%', height: '44px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: 700, borderRadius: '5px', color: 'white', borderColor: '#00AF85', marginBottom: '10px' }}
                                                onClick={() => {

                                                    this.props.handelEndSession();
                                                }}
                                            >End Parking Session</Button>
                                        </>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
interface IAssignedVisitorTenantState {
    spotNo: string,
    teantUnitNumber: string,
    teantName: string,
    teantEmail: string,
    teantPhone: string,
    teantDuration: string,
    teantLicencePlate: string[],
    startUTCTime: Date | null
}

interface IAssignedVisitorTenantProps {
    spotNo: string,
    handelEdit(): void,
    teantUnitNumber: string,
    teantName: string,
    teantEmail: string,
    teantPhone: string,
    teantDuration: string,
    teantLicencePlate: string[],
    startUTCTime: Date | null,
    handelEndSession(): void
}