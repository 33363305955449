import React from 'react';
import { Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import NavBarLeft from './NavBar/NavBarLeft';
import TopNavBarTop from './NavBar/NavBarTop';
// Your routes.js file
import { ThemeProvider } from '@material-ui/core';
import LoaderComponent from './Components/LoaderComponent';
import Toastr from './Components/Toastr';
import { SyncProvider } from './Contexts/BatchSyncContext';
import routes from './Router';
import { greenTheme } from './Themes/GreenTheme';
import Hotjar from '@hotjar/browser';

// Initialize Hotjar in production environment
const siteId = 3567714;
const hotjarVersion = 6;
if (process.env.NODE_ENV !== 'development') {
  Hotjar.init(siteId, hotjarVersion);
}

function App() {
  hideBeaconToAdmin();
  // let isRegistation = window.location.href.replace(window.location.origin, '').indexOf('/register') !== -1;
  // let isLoginPage = window.location.href.replace(window.location.origin, '').replace('#', '') === '/';
  // let isForgotPage = window.location.href.replace(window.location.origin, '').indexOf('/forgot') !== -1;
  let isMenuVisible: boolean = leftNavVisibleType();
  let isSecondaryDomain = window.location.host.indexOf("portal2") === 0;

  return (
    <div className="App">
      <SyncProvider>
        <ThemeProvider theme={greenTheme}>
          <BrowserRouter>
            {
              // Header will not display in registration screen
              isMenuVisible ? <NavBarLeft /> : null
            }
            <div className="body-container">
              {
                // Header will not display in registration screen
                isMenuVisible ? <TopNavBarTop /> : null
              }
              <Switch>
                {routes}
              </Switch>
              {/* <div id="spinnerScreen">
                <div className="sk-folding-cube">
                  <div className="sk-cube1 sk-cube"></div>
                  <div className="sk-cube2 sk-cube"></div>
                  <div className="sk-cube4 sk-cube"></div>
                  <div className="sk-cube3 sk-cube"></div>
                </div>
              </div> */}
            </div>
          </BrowserRouter>
        </ThemeProvider>
        <Toastr />
        <LoaderComponent />
        {
          isSecondaryDomain &&
          <div className={"secondary-server-marker"}>Serving from secondary server</div>
        }
      </SyncProvider>
    </div>
  );
}

function leftNavVisibleType() {
  var url_ = window.location.href.replace(window.location.origin, '').replace('#', '');
  var visibleType = true;

  if (url_.indexOf('/register') != -1 || url_ == '/' || url_.indexOf('/forgot') != -1 || url_.indexOf('/settings') != -1 || url_.indexOf('/verify') != -1 || url_.indexOf('/activate') != -1) {
    visibleType = false;
  }

  return visibleType;
}

function hideBeaconToAdmin() {
  var url_ = window.location.href.replace(window.location.origin, '').replace('#', '');
  if (typeof (Storage) !== 'undefined') {
    // localStorage.clear();
    var _localData = localStorage.getItem('userdata');

    if (_localData != null && _localData !== '') {
      var _data = JSON.parse(_localData);
      var roles: any = _data.userRoleID;

      if (roles.indexOf(1) != -1 || roles.indexOf(2) != -1) {
        document.body.classList.add('hide-beacon');
      }
    }
  }
  var device = getMobileOperatingSystem();
  if (url_.indexOf('forgot') != -1 && device == 'mobile') {
    document.body.classList.add('hide-beacon-mobile');
  }
}

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "mobile";
  }

  if (/android/i.test(userAgent)) {
    // return "Android";
    return "mobile";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "mobile";
  }

  return "unknown";
}

declare global {
  interface Window { childWindows: Window[] }
}

export default App;