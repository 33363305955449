import React from "react";
import { Select, MenuItem, Grid, Button } from "@material-ui/core";
import { Doughnut } from 'react-chartjs-2';
import PrivateLotService from "../../Services/PrivateLotService";

export default class UtilizationChart extends React.Component<IUtilizationChartProps, IUtilizationChartState> {
    private _privateLotService: PrivateLotService;
    private chartReference: any = null;

    constructor(props_: IUtilizationChartProps) {
        super(props_);

        this.state = {
            percentage: 0,
            selectedUtilization: 'YTD',
            utilizationOption: ['YTD', 'MTD', 'WTD'],
            doughnutData: {
                // labels: [],
                datasets: [{
                    label: 'Utilization',
                    // data: [92, 8],
                    data: [0, 0],
                    backgroundColor: [
                        'rgb(0, 175, 133)',
                        'rgb(204,239,231)',
                    ],
                    hoverOffset: 4
                }],
                innerText: '23%'
            },
            doughnutOption: {
                cutoutPercentage: 75,
                tooltips: {
                    enabled: false,
                },
                elements: {
                    arc: {
                        borderWidth: 0
                    }
                }
            },
            doughnutPlugin: this.getPluginData(0)
        };

        this._privateLotService = new PrivateLotService();
    }

    componentDidMount() {
        this.getUtilization();
    }

    componentDidUpdate(oldProps_: IUtilizationChartProps) {
        if (this.props.propertyGuid !== oldProps_.propertyGuid || this.props.refresh) {
            this.getUtilization();
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="text-right">
                    <Select
                        inputProps={{ 'aria-label': 'Without label' }}
                        className="dashboard-select"
                        onChange={(event: any) => {
                            var _targetValue = event.target.value;
                            this.setState({
                                selectedUtilization: _targetValue
                            }, () => {
                                this.getUtilization();
                            })
                            //this.doughnutChartPlugins();
                        }}
                        value={this.state.selectedUtilization}
                    >
                        {
                            this.state.utilizationOption.map((o_, index) => {
                                return <MenuItem key={index} value={o_}>{o_}</MenuItem>
                            })
                        }
                    </Select>
                </div>
                <div>
                    {
                        this.renderDoughnutChart()
                    }
                </div>
            </React.Fragment>
        )
    }

    private renderDoughnutChart() {
        return (
            <Doughnut
                ref={(reference) => this.chartReference = reference}
                data={this.state.doughnutData}
                height={100}
                width={100}
                options={this.state.doughnutOption}
                plugins={[{
                    beforeDraw: (chart: any) => {
                        var width = chart.width,
                            height = chart.height,
                            ctx = chart.ctx;
                        ctx.restore();
                        var fontSize = (height / 160).toFixed(2);
                        ctx.font = fontSize + "em sans-serif";
                        ctx.textBaseline = "top";
                        var text = this.state.percentage + " %",//"80 %",
                            textX = Math.round((width - ctx.measureText(text).width) / 2),
                            textY = height / 2;
                        ctx.fillText(text, textX, textY);
                        ctx.save();
                        
                    }
                }]}
            />
        );
    }

    private getUtilization() {
        if (this.props.propertyGuid && this.props.propertyGuid != "00000000-0000-0000-0000-000000000000" && this.props.propertyGuid != "blank") {
            this._privateLotService.GetUtilization(this.props.propertyGuid, this.state.selectedUtilization)
                .then(res => res.json())
                .then(r_ => {
                    let roundedResp = Math.round(r_);
                    let doughnutData_: any = this.state.doughnutData;
                    //let doughnutOption_: any = this.state.doughnutOption;

                    let percentage_ = 100;
                    //[92, 8]
                    let balancePercentage = (percentage_ - roundedResp);

                    doughnutData_.datasets[0].data = [roundedResp, balancePercentage];
                    // doughnutData_.datasets.data = [92, 8];
                    // doughnutOption_.cutoutPercentage = 75;



                    this.setState({
                        doughnutData: doughnutData_,
                        percentage: roundedResp
                        // doughnutOption: doughnutOption_,
                    }, () => {
                        if (this.chartReference) {
                            this.chartReference.chartInstance.update();
                        }
                    });
                });
        }
        else {
            let doughnutData_: any = this.state.doughnutData;
            doughnutData_.datasets[0].data = [0, 0];
            this.setState({
                percentage: 0,
                doughnutData: doughnutData_,
            }, () => {
                if (this.chartReference) {
                    this.chartReference.chartInstance.update();
                }
            });
        }
    }

    private getPluginData(roundedResp: number) {
        return [{
            beforeDraw: function (chart: any) {
                var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;
                ctx.restore();
                var fontSize = (height / 160).toFixed(2);
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                var text = roundedResp + " %",//"80 %",
                    textX = Math.round((width - ctx.measureText(text).width) / 2),
                    textY = height / 2;
                ctx.fillText(text, textX, textY);
                ctx.save();
            }
        }]
    }
}

interface IUtilizationChartProps {
    propertyGuid?: string;
    refresh?: boolean;
}

interface IUtilizationChartState {
    selectedUtilization: string;
    utilizationOption: string[];
    doughnutData: any;
    doughnutOption: any;
    doughnutPlugin: any[];
    percentage: number;
}
