import { IconButton, TextField } from "@material-ui/core";
import React from "react";
import { Button } from "@material-ui/core";
import TodayIcon from '@material-ui/icons/Today';
import { format } from "date-fns";
import Calendar from 'react-calendar';
import ClearIcon from '@material-ui/icons/Clear';
import { EnumCalendarPosition } from "./interfaces";

export default class GrydCalendarPicker extends React.Component<IGrydCalendarPickerProps, IGrydCalendarPickerState> {
    constructor(props_: IGrydCalendarPickerProps) {
        super(props_);

        this.state = {
            isCalendarOpened: false,
            selectedDate: this.props.defaultDate ? this.props.defaultDate : new Date(),
            selectedDateString: this.getDateString(this.props.defaultDate),
            elementId: `gryd-cl-${Math.round(Math.random() * 400)}`,
            maxDate: (this.props.maxDate != null) ? this.props.maxDate : new Date(new Date().setFullYear(new Date().getFullYear() + 25))
        }
    }

    componentDidMount() {
        window.addEventListener("click", (event: any) => {
            this.getCalendarDomElementValue(event);
        }, false);
    }

    componentDidUpdate(oldProps_: IGrydCalendarPickerProps) {
        if (oldProps_.defaultDate != this.props.defaultDate) {
            let selectedDate_ = this.props.defaultDate ? this.props.defaultDate : new Date();
            this.setState({
                selectedDate: selectedDate_,
                selectedDateString: this.getDateString(selectedDate_)
            })
        }
    }

    render() {
        return (
            <div className="gryd-cl-view gryd-calendar-view" id={this.state.elementId}>
                {
                    this.state.isCalendarOpened && <div className="calendar-blocker" id="calendar-blocker-elm"></div>
                }
                <div className={'cl-block-input-c ' + (this.state.isCalendarOpened ? 'cl-block-calendar-focussed' : '')}>
                    <TodayIcon className="cl-icon" />
                    <TextField
                        className="cl-block-input cl-block-input-calendar"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        placeholder={this.props.placeHolder}
                        name="block_date"
                        value={this.state.selectedDateString}
                        onFocus={(event: any) => {
                            event.target.setAttribute('autocomplete', 'off');
                            this.setState({
                                isCalendarOpened: true
                            })
                        }}
                        onChange={(event: any) => {
                            if (this.state.selectedDateString != "" &&
                                event.target.value != this.state.selectedDateString &&
                                event.target.value.length < this.state.selectedDateString.length) {
                                this.setState({
                                    selectedDate: new Date(),
                                    isCalendarOpened: false,
                                    selectedDateString: ""
                                }, () => {
                                    this.setState({
                                        isCalendarOpened: true
                                    })
                                })
                            }
                        }}
                        error={(this.props.isMandate == true && this.state.selectedDateString == "") || this.props.isInvalidDate == true}
                        required={this.props.isMandate == true}
                        disabled={this.props.disabled == true}
                    />
                    {
                        this.props.disabled != true && <IconButton aria-label="Back" className="cl-date-clear" onClick={() => {
                            this.setState({
                                selectedDate: this.props.minDate != null ? this.props.minDate : new Date(),
                                isCalendarOpened: false,
                                selectedDateString: ""
                            }, () => {
                                this.setState({
                                    isCalendarOpened: true
                                }, () => {
                                    if (this.props.onDateChange) {
                                        this.props.onDateChange(null);
                                    }
                                })

                                /*
                                , () => {
                                    if (this.props.onDateChange) {
                                        this.props.onDateChange(this.state.selectedDate);
                                    }
                                } */
                            })
                        }}>
                            <ClearIcon />
                        </IconButton>
                    }
                </div>
                {
                    //This condition added for refresh calendar while clear
                    this.state.isCalendarOpened && <div className={'cl-block-calendar ' + (this.props.calendarPosition == 1 ? "cl-block-top " : "cl-block-bottom ") + (this.props.calendarClass ? this.props.calendarClass : "")}>
                        <div className="cl-block-calendar-container">
                            <Calendar
                                formatShortWeekday={(locale, date) => {
                                    let weekday = new Date(date).toLocaleString('en-us', { weekday: 'short' });
                                    return weekday.slice(0, -1);
                                }}
                                value={this.state.selectedDate}
                                calendarType={'US'}
                                onChange={(date: Date) => {
                                    this.setState({
                                        selectedDate: date,
                                        selectedDateString: this.getDateString(date),
                                        isCalendarOpened: false
                                    }, () => {
                                        if (this.props.onDateChange) {
                                            this.props.onDateChange(this.state.selectedDate);
                                        }
                                    })
                                }}
                                minDate={this.props.minDate}
                                maxDate={this.state.maxDate}
                            />
                        </div>
                        {/* <hr />
                        <div className="cl-flex cl-bottom-c">
                            <div className="cl-flex cl-flex-cl">
                                <div className="cl-flex-cl">
                                    <div className="cl-block-cl-input-disabled">{this.state.selectedDate != null ? format(this.state.selectedDate, "dd MMM yyyy") : this.props.placeHolder}</div>
                                </div>
                            </div>
                            <div className="cl-flex cl-flex-cl">
                                <div className="cl-flex-cl">
                                    <Button
                                        className="cl-block-cl-button"
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        onClick={(event: any) => {
                                            this.setState({
                                                selectedDate: new Date(),
                                                isCalendarOpened: false,
                                                selectedDateString: ""
                                            }, () => {
                                                this.setState({
                                                    isCalendarOpened: true
                                                })
                                            })
                                        }}
                                    >
                                        Clear
                                    </Button>
                                </div>
                                <div className="cl-flex-cl">
                                    <Button
                                        className="cl-block-cl-button"
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            this.setState({
                                                isCalendarOpened: false,
                                                selectedDateString: this.getDateString(this.state.selectedDate)
                                            }, () => {
                                                if (this.props.onDateChange) {
                                                    this.props.onDateChange(this.state.selectedDate);
                                                }
                                            })
                                        }}
                                    >
                                        Done
                                    </Button>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                }
            </div>
        )
    }

    private getCalendarDomElementValue(event: any) {
        // var isCalendarView = event.target.closest(`#${this.state.elementId}`);
        var isCalendarView = event.target.closest('#calendar-blocker-elm');
        if (isCalendarView != null) {
            this.setState({
                isCalendarOpened: false
            });
        }
    }

    private getDateString(date_: Date | null) {
        let dateString: string = "";

        if (date_ != null) {
            return `${format(date_, "dd MMM yyyy")}`;
        }

        return dateString;
    }
}



interface IGrydCalendarPickerProps {
    onDateChange: (dates_: Date | null) => void;
    defaultDate: Date | null;
    placeHolder: string;
    minDate?: Date;
    isMandate?: boolean;
    calendarPosition: EnumCalendarPosition;
    isInvalidDate?: boolean;
    disabled?: boolean;
    calendarClass?: string;
    maxDate?: Date;
}

interface IGrydCalendarPickerState {
    isCalendarOpened: boolean;
    selectedDate: Date;
    selectedDateString: string;
    elementId: string;
    maxDate: Date;
}