import React from 'react';
import { withStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    'flexDirection': 'column',
    'alignItems': 'flex-start',
    'padding': '16px',
    'gap': '10px',
    'minWidth': "25%",
    'backgroundColor': '#FFFFFF',
    'boxShadow': '0px 4px 4px rgba(0, 0, 0, 0.25)',
    'borderRadius': '10px',
    'flex': 'none',
    'flex-grow': '0'
  }
}))(Tooltip);

const CustomizedHtmlTooltip = (props: any) => {
  const { onOpen, title, placement, innerContent } = props;
  return (
      <HtmlTooltip placement={placement}  onOpen={onOpen ? onOpen : () => {}}
        title={title}
      >
        {innerContent}
      </HtmlTooltip>
  );
}

export default CustomizedHtmlTooltip;
