import React from "react";
import {Button, TextField} from "@material-ui/core";
import {ShowAlert, ShowAlertWithConfirmCustomIcon} from "../../Common/ShowAlert";
import Error from "@material-ui/icons//Error";
import {CheckCircle} from "@material-ui/icons";
import {Col, Row} from "reactstrap";
import {
  AddEditTenantResponse,
  AddTenantRequest,
  EditTenantRequest,
  IAssignProperties,
  IEditTenantLease,
  ILeaseDetails,
  IPrivateLotProperty,
  IPrivateLotsItem,
  IPrivateLotZone,
  ISaveEditTenantRespone,
  ITenantBuilding,
  ITenantDetails,
  ITenantSpotDetails
} from "./interface";
import {IBlockSpot} from "../BookingsCalendar/interfaces";
import {DateRange} from "materialui-daterange-picker";
import PhoneInput from 'react-phone-input-2';
import {RightPanelState} from "../../Models/CommonInterfaces";
import UserService from "../../Services/UserService";
import AssignProperty from "./AssignProperty";
import PrivateLotService from "../../Services/PrivateLotService";
import ObjectHelper from "../../Helpers/ObjectHelper";
import {DateTimeHelper} from "../../Helpers/DateTimeHelper";
import {format} from "date-fns";

// let assignedPropertyCount = 0;

export default class AddTenant extends React.Component<IAddTenantProps, IAddTenantState> {
  private _privateLotService: PrivateLotService;
  private _userService: UserService;

  constructor(props_: IAddTenantProps) {
    super(props_);

    this.state = {
      propertyGuid: "",
      isExistingTenant: false,
      isOverWriteExistingTenant: false,
      tenantBuildingList: [],
      selectedZone: null,
      phone: "",
      firstName: "",
      lastName: "",
      email: "",
      isValidMobileNumber: true,
      applicationUserGuid: "",
      leases: [],
      oldLeases: [],
      oldPhone: '',
      oldFirstName: '',
      oldLastName: '',
      oldEmail: '',
      unitNumber: '',
      oldUnitNumber: '',
      licensePlate: '',
      oldLicensePlate: '',
      comefromFlag: this.props.comefromFlag,
      selectedStuff: this.props.selectedStuff,

    }


    this._userService = new UserService();
    this._privateLotService = new PrivateLotService();
  }


  componentDidMount() {
    this.addAnotherSpot();
    if (this.state.comefromFlag === "Assign_tenant") {
      this.setState({
        unitNumber: this.state.selectedStuff["selectedUnitNumber"]
      })
    }

    // this.props.customRef(this);
    //this.doughnutChartPlugins();

  }

  componentDidUpdate(oldprops: IAddTenantProps) {

    if (oldprops.comefromFlag !== this.props.comefromFlag || JSON.stringify(oldprops.selectedStuff) !== JSON.stringify(this.props.selectedStuff)) {
      this.setState({
        comefromFlag: this.props.comefromFlag,
        selectedStuff: this.props.selectedStuff
      }, () => {

        if (this.state.comefromFlag === "Assign_tenant") {
          this.setState({
            unitNumber: this.state.selectedStuff["selectedUnitNumber"]
          })
        }
      })


    } else {

    }
  }


  render() {
    const disableUnitNumber = this.props.requestType === RightPanelState.Edit ? true : this.state.selectedStuff ? this.state.selectedStuff["isYardiSyncedUnit"] : false;
    return (
      <div className='padd-tenant-container'>
        {/* <h4 className="padd-tenant-heading">Add Tenant</h4> */}
        {this.state.isExistingTenant ? (
          <>

            <div className="d-flex flex-coloumn justify-content-center mt-5 pt-4">
              <div className="d-flex flex-column text-center" style={{flex: 1}}>
                {this.state.isOverWriteExistingTenant && (
                  <>
                    <div>
                      <CheckCircle className="check-icon-size"/>
                    </div>
                    <div className="padd-header mt-3">
                      Tenant Added
                    </div>
                    <div className="mt-3 padd-tenant-exisiting-msg">
                      Tenant has been successfully
                    </div>
                    <div className="padd-tenant-exisiting-msg">
                      added to tenants list.
                    </div>
                  </>
                )}
                {!this.state.isOverWriteExistingTenant && (
                  <>
                    <div>
                      <Error className="error-icon-size"/>
                    </div>
                    <div className="padd-header mt-3">
                      Existing Tenant
                    </div>
                    <div className="mt-3 padd-tenant-exisiting-msg">
                      This tenant already exists in the
                    </div>
                    <div className="padd-tenant-exisiting-msg">
                      database. Do you want to overwrite
                    </div>
                    <div className="padd-tenant-exisiting-msg">
                      their information?
                    </div>
                    <div className="mt-4 d-flex override-btn">
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="ml-2"
                        onClick={() => {
                          this.setState({
                            isExistingTenant: false
                          }, () => {
                            this.updateTenantUser();
                          });
                        }}
                      >
                        Overwrite Existing
                      </Button>
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className="ml-2"
                        onClick={() => {
                          // this.props.onClose();
                          this.setState({
                            isExistingTenant: false,
                            isOverWriteExistingTenant: false
                          });
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </>

                )}
              </div>
            </div>

          </>
        ) : (
          <>
            <div className="add-tenant-pl-panel-body">
              <div className="add-tenant-block-pl">
                <div className="panel-body-field-label">First Name</div>
                <TextField
                  fullWidth
                  placeholder="First Name"
                  variant="outlined"
                  required={true}
                  margin="normal"
                  className="mt-0"
                  inputProps={{maxLength: 60}}
                  autoComplete="off"
                  value={this.state.firstName}
                  onChange={(e_: any) => {
                    this.setState({
                      firstName: e_.target.value
                    }, () => {
                      if (this.props.onChange) {
                        this.props.onChange(this._hasChanges());
                      }
                    });
                  }}
                  error={
                    this.props.requestType === RightPanelState.Add &&
                    this.state.firstName.length === 0}
                />
              </div>
              <div className="add-tenant-block-pl">
                <div className="panel-body-field-label">Last Name</div>
                <TextField
                  fullWidth
                  placeholder="Last Name"
                  variant="outlined"
                  required={true}
                  margin="normal"
                  className="mt-0"
                  inputProps={{maxLength: 60}}
                  value={this.state.lastName}
                  onChange={(e_: any) => {
                    this.setState({
                      lastName: e_.target.value
                    }, () => {
                      if (this.props.onChange) {
                        this.props.onChange(this._hasChanges());
                      }
                    });
                  }}
                  error={
                    this.props.requestType === RightPanelState.Add &&
                    this.state.lastName.length === 0}
                  autoComplete="off"
                />
              </div>
              <div className="add-tenant-block-pl">
                <div className="panel-body-field-label">Email Address</div>
                <TextField
                  fullWidth
                  placeholder="Email Address"
                  variant="outlined"
                  required={true}
                  margin="normal"
                  className="mt-0"
                  value={this.state.email}
                  inputProps={{maxLength: 100}}
                  error={
                    (this.props.requestType === RightPanelState.Add ||
                      this.props.requestType === RightPanelState.Edit) &&
                    (!this.validateEmail(this.state.email))
                  }
                  onChange={(e_: any) => {
                    this.setState({
                      email: e_.target.value
                    }, () => {
                      if (this.props.onChange) {
                        this.props.onChange(this._hasChanges());
                      }
                    });
                  }}
                  autoComplete="off"
                />
              </div>
              <div className="add-tenant-block-pl">
                <div className="panel-body-field-label">Phone Number</div>
                <PhoneInput
                  isValid={(value: any, country: any) => {
                    if (this.isInValidPhoneNumber(value, country.countryCode)) {
                      return 'Invalid Number';
                    }
                    return true;
                  }}
                  country={'ca'}
                  onlyCountries={['ca']}
                  placeholder={'+# ### - ### - ####'}
                  masks={{ca: '...-...-....', us: '...-...-....', in: '...-...-....'}}
                  value={this.state.phone}
                  onChange={phone_ => {
                    this.setState({
                      phone: phone_,
                    }, () => {
                      if (this.props.onChange) {
                        this.props.onChange(this._hasChanges());
                      }
                    });
                  }}
                  onBlur={(e_: any) => {
                    this.setState({
                      phone: (e_.target.value === "") ? e_.target.value as string : this.state.phone
                    });
                  }}
                  autocompleteSearch={false}
                  containerClass="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth Mui-disabled"
                  inputProps={{
                    name: 'addnewPhone',
                    required: true,
                  }}
                />
              </div>
              <div className="add-tenant-subheading mt-4 mb-3">
                Assign Property
              </div>

              <div className="add-tenant-block-pl">
                <div className="panel-body-field-label">Unit Number</div>
                <TextField
                  fullWidth
                  placeholder="Unit Number"
                  variant="outlined"
                  required={true}
                  margin="normal"
                  className="mt-0"
                  inputProps={{maxLength: 20}}
                  value={this.state.unitNumber}
                  onChange={(e_: any) => {
                    this.setState({
                      unitNumber: e_.target.value
                    }, () => {
                      if (this.props.onChange) {
                        this.props.onChange(this._hasChanges());
                      }
                    });
                  }}
                  autoComplete="off"
                  error={
                    this.props.requestType === RightPanelState.Add &&
                    this.state.unitNumber.trim().length === 0}
                  disabled={disableUnitNumber}

                />
              </div>
              <div className="add-tenant-block-pl">
                <div className="panel-body-field-label">Licence Plate(s)</div>
                <TextField
                  fullWidth
                  placeholder="example: ABC123, ABC456"
                  variant="outlined"
                  required={true}
                  margin="normal"
                  className="mt-0"
                  value={this.state.licensePlate}
                  onChange={(e_: any) => {
                    this.setState({
                      licensePlate: e_.target.value
                    }, () => {
                      if (this.props.onChange) {
                        this.props.onChange(this._hasChanges());
                      }
                    });
                  }}
                  autoComplete="off"
                  error={this.props.requestType === RightPanelState.Add &&
                    this.state.licensePlate.trim().length === 0}
                  //disabled={this.props.requestType === RightPanelState.Edit}
                />
              </div>
              {
                this.state.leases.map((d, index) => {
                  if (!d.isActive) return null;
                  return (<div key={index}>
                    <AssignProperty
                      comefromFlag={this.props.comefromFlag}
                      properties={this.props.properties}
                      leases={this.state.leases}
                      currentIndex={index}
                      selectedStuff={this.state.selectedStuff}
                      onChange={(leases_: ITenantSpotDetails[]) => {
                        this.setState({
                          leases: leases_
                        }, () => {
                          if (this.props.onChange) {
                            this.props.onChange(this._hasChanges());
                          }
                        });
                      }}
                      onDelete={(index: number) => {
                        let leases_ = this.state.leases.slice(0);
                        leases_.splice(index, 1);

                        for (var x = 0; x > leases_.length; x++) {
                          leases_[x].index = x;
                        }

                        this.setState({
                          leases: leases_
                        });
                      }}
                      requestType={this.props.requestType}
                    />
                    {
                      (this.props.requestType === RightPanelState.Edit) &&
                        <div className="add-tenant-container"><span className="remove-tenant" onClick={() => {
                          let leases_ = this.state.oldLeases;
                          let date_ = leases_[index].filterDateRange.endDate;

                          let _endDate = (date_) ? new Date(date_) : new Date();

                          ShowAlertWithConfirmCustomIcon(
                            "Active Lease",
                            `<div class="mt-3">You are attempting to delete an active parking<br/>
                                                    lease(s) which will expire <b>${format(_endDate, "dd MMM yyyy")}</b>. Do you<br/>
                                                    want to delete and cancel parking lease?<br/>
                                                        This cannot be undone.
                                                    </div>`,
                            "error",
                            "Yes",
                            "No",
                            "gryd-custom-popup",
                            '<div><svg class="MuiSvgIcon-root error-icon-size" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg></div>'
                          )
                            .then(agreed_ => {
                              if (agreed_) {
                                this.removeAccess(index);
                              }
                            });
                        }}>Remove Access</span></div>
                    }
                  </div>)
                })
              }
              {/* <AssignProperty
                                properties={this.props.properties}
                            /> */}
              {
                (this.props.requestType === RightPanelState.Add && !this.state.comefromFlag) &&
                  <div className="add-tenant-container"><span className="add-tenant" onClick={() => {
                    this.addAnotherSpot();
                  }}>+ add another spot for this tenant</span></div>
              }

            </div>
            <div>
              <Row className="mt-3 mx-0">
                <Col>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={this._saveTenant}
                  >Save</Button>
                </Col>
                <Col>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      if (this.props.onClose) {
                        this.props.onClose();
                      }
                    }}
                  >Cancel</Button>
                </Col>
              </Row>
            </div>
          </>
        )}
        <div></div>
      </div>
    )
  }

  private isInValidPhoneNumber(value: any, countryCode: string) {
    let phoneNumberLength = (value.length - countryCode.length);
    return (phoneNumberLength >= 0 && phoneNumberLength < 10);
  }

  private validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  private _saveTenant = (): void => {
    let phone = this.state.phone && this.state.phone.length > 10 ? this.state.phone : "";
    var MobileNumber = phone.slice(-10);

    let isValid = true;

    if (
      !MobileNumber || !this.state.firstName ||
      !this.state.lastName || !this.state.email ||
      !this.state.unitNumber || !this.state.licensePlate) {
      ShowAlert("", 'Please fill the mandatory fields', "error");
      return;
    }

    for (var x of this.state.leases) {
      if (!x.filterDateRange) {
        ShowAlert("", 'Please enter the valid date', "error");
        isValid = false;
        break;
      }

      if ((!x.tenantBuilding || !x.zone || x.numberOfPasses === 0) &&
        this.props.requestType === RightPanelState.Add) {
        ShowAlert("", 'Please fill the mandatory fields', "error");
        isValid = false;
        break;
      }
    }

    if (!isValid) {
      return;
    }
    if (this.props.requestType === RightPanelState.Add) {
      this.addTenantLease();
    } else {
      this.editTenantLease();
    }
  }


  private updateTenantUser() {
    if (this.props.requestType === RightPanelState.Add) {
      this.addTenantLease();
    } else {
      this.editTenantLease(true);
    }
  }

  private addTenantLease() {
    // if (!this.state.applicationUserGuid) {
    //     ShowAlert("", 'Please enter the valid user information', "error");
    // }

    if (!this.state.unitNumber || !this.state.licensePlate) {
      ShowAlert("", 'Please fill the mandatory fields', "error");
      return;
    }

    let assignProperties_: IAssignProperties[] = [];
    let isValid = true;

    for (var x of this.state.leases) {
      if (!x.filterDateRange) {
        ShowAlert("", 'Please enter the valid date', "error");
        isValid = false;
        break;
      }

      if (!x.tenantBuilding || !x.zone || x.numberOfPasses === 0) {
        ShowAlert("", 'Please fill the mandatory fields', "error");
        isValid = false;
        break;
      }

      if (this.props.requestType === RightPanelState.Edit &&
        x.spots.length === 0) {
        ShowAlert("", 'Please select a spot', "error");
        isValid = false;
        break;
      }

      let startDate_ = x.filterDateRange.startDate;
      let endDate_ = x.filterDateRange.endDate;
      let propertyGuid_ = x.tenantBuilding?.propertyGuid;
      let zoneGuid_ = x.zone?.zoneGuid;
      let spotGuids_ = x.spots;
      let numberOfPasses_ = x.numberOfPasses;
      //let licencePlate_ = x.licencePlate;

      let leasedetails_: IAssignProperties = {
        endDate: endDate_ ? DateTimeHelper.convertIntoUtc(endDate_) : new Date(),
        startDate: startDate_ ? DateTimeHelper.convertIntoUtc(startDate_) : new Date(),
        numberOfPasses: Number(numberOfPasses_),
        propertyGuid: propertyGuid_,
        spotGuid: spotGuids_,
        //vehicles: licencePlate_,
        zoneGuid: zoneGuid_
      }

      assignProperties_.push(leasedetails_);
    }

    if (!isValid) {
      return false;
    }


    const addTenantRequest: AddTenantRequest = {
      Name: this.state.firstName,
      LastName: this.state.lastName,
      Email: this.state.email,
      CountryCode: Number.parseInt(this.state.phone.slice(0, 1)),
      MobileNumber: this.state.phone.slice(1),
      UserRole: 11, //Private Lot Tenant
    };

    this._userService.AddEditUser(addTenantRequest)
      .then(response => response.json())
      .then((json) => {
          const newTenant: AddEditTenantResponse = JSON.parse(json);

          const leases: ILeaseDetails = {
            applicationUserGuid: newTenant.ApplicationUserGuid,
            privateLotUnitnumber: this.state.unitNumber,
            assignProperties: assignProperties_,
            licensePlate: this.state.licensePlate
          }

          this._privateLotService.addTenant(leases)
            .then(r => r.json())
            .then(r => {
              let isSavedAllRecords = true;
              for (var x of r) {
                if (!x.isBooked) {
                  isSavedAllRecords = false;
                  break;
                }
              }

              if (isSavedAllRecords) {
                this.setState({
                  isExistingTenant: true,
                  isOverWriteExistingTenant: true,

                }, () => {
                  if (this.props.onSaved) {
                    this.props.onSaved();
                  }
                  if (this.props.onChange) {
                    this.props.onChange(false);
                  }
                  if (this.props.updateSelectedTenant) {
                    this.props.updateSelectedTenant(this.state.licensePlate);
                  }
                })
              } else {
                ShowAlert('', 'Some of the leases were not added. Please modify and save again.', 'success')
                  .then(() => {
                    let leases_ = this.state.leases.slice(0);
                    for (var i = r.length - 1; i > -1; --i) {
                      if (r[i].isBooked) {
                        leases_.splice(i, 1);
                      }
                    }

                    for (var x = 0; x > leases_.length; x++) {
                      leases_[x].index = x;
                    }

                    this.setState({
                      leases: leases_
                    }, () => {
                      if (this.props.onSaved) {
                        this.props.onSaved(false, [], true);
                      }
                    });
                  })
              }
            })
            .catch(() => {
              ShowAlert("Error Adding Tenant", "There was an error while adding tenant leases!", "error");
            });

        }
      )
      .catch(() => {
        ShowAlert("Error Adding Tenant", "There was an error while adding tenant! Check the information provided and try again.", "error");
      })


  }

  private editTenantLease(isEdited?: boolean) {
    // IEditTenantLease
    // if (!this.state.applicationUserGuid) {
    //     ShowAlert("", 'Please enter the valid user information', "error");
    // }

    if (!this.state.unitNumber || !this.state.licensePlate) {
      ShowAlert("", 'Please fill the mandatory fields', "error");
      return;
    }

    let eidtTenantlease_: IEditTenantLease[] = [];
    let isValid = true;

    for (var x of this.state.leases) {

      if (!x.filterDateRange) {
        ShowAlert("", 'Please enter the valid date', "error");
        isValid = false;
        break;
      }

      let startDate_ = x.filterDateRange.startDate;
      let endDate_ = x.filterDateRange.endDate;

      if ((!x.tenantBuilding || !x.zone || x.numberOfPasses === 0) &&
        this.props.requestType === RightPanelState.Add) {
        ShowAlert("", 'Please fill the mandatory fields', "error");
        isValid = false;
        break;
      }

      if (this.props.requestType === RightPanelState.Edit &&
        x.spots.length === 0) {
        ShowAlert("", 'Please select a spot', "error");
        isValid = false;
        break;
      }

      if (!startDate_ || !endDate_) {
        ShowAlert("", 'Please select the valid date', "error");
        isValid = false;
        break;
      }

      if (endDate_ < startDate_) {
        ShowAlert("", 'End date is less than start date', "error");
        isValid = false;
        break;
      }

      let spotGuid_ = x.spots[0].spotGuid;
      let reservationGuid_ = x.reservationGuid;

      let leasedetails_: IEditTenantLease = {
        // startDate: startDate_ ? DateTimeHelper.convertIntoUtc(startDate_) : new Date(),
        // endDate: endDate_ ? DateTimeHelper.convertIntoUtc(endDate_) : new Date(),
        startDate: new Date(startDate_),
        endDate: new Date(endDate_),
        reservationGuid: reservationGuid_,
        spotGuid: spotGuid_,
        IsActive: x.isActive,
        LicensePlate: this.state.licensePlate,
      };

      let oldLease = this.state.oldLeases.filter(x => x.reservationGuid === reservationGuid_)[0];

      if (this._hasCurrentLeaseEdited(leasedetails_, oldLease)) {
        leasedetails_.startDate = DateTimeHelper.convertIntoUtc(startDate_);
        leasedetails_.endDate = DateTimeHelper.convertIntoUtc(endDate_);

        eidtTenantlease_.push(leasedetails_);
      }

    }

    if (!isValid) {
      return false;
    }

    if (eidtTenantlease_.length === 0) {
      if (isEdited) {
        // ShowAlert('', 'Tenant successfully updated.', 'success').then(() => {
        //     if (this.props.onSaved) {
        //         this.props.onSaved(true);
        //     }
        // })
        if (this.props.onSaved) {
          this.props.onSaved(true, []);
        }
        return false;
      } else if (!this._hasChanges()) {
        ShowAlert('', 'No changes to save the record(s)', 'error');
        return false;
      }
    } else {
      this._privateLotService.editTenantLease(eidtTenantlease_)
        .then(r => {
          if (r.ok) {
            r.json().then(resp => {
              let resp_: ISaveEditTenantRespone[] = resp;

              let errorItems = resp_.filter(x => x.isError);

              if (errorItems.length === 0) {
                if (this.props.onSaved) {
                  this.props.onSaved(true, resp_);
                }
              } else {
                ShowAlert('Lease Cannot Be Updated', 'Spot has active parking lease and cannot be updated until active parking has been cancelled or expired.', 'error');
              }
            })
          }
        });
    }

    if (this._hasChanges()) {
      const countryCode: number = Number.parseInt(this.state.phone.slice(0, 1));

      const editTenantDetails: EditTenantRequest = {
        AccountID: this.state.applicationUserGuid,
        Name: this.state.firstName,
        LastName: this.state.lastName,
        CountryCode: countryCode,
        MobileNumber: this.state.phone.slice(1),
        Email: this.state.email,
        UserRole: 11, //privateLotTenant = 11
      };

      this._userService.AddEditUser(editTenantDetails)
        .then(response => response.json())
        .then(() => {
          if (this.props.onSaved) {
            this.props.onSaved(true, []);
          }
        })
        .catch(() => {
          ShowAlert("Tenant not saved!", "There was an error saving tenant details!", "error");
        });

    }
  }

  private addAnotherSpot() {
    let newrecord: ITenantSpotDetails = {
      filterDateRange: {},
      index: this.state.leases.length,
      licensePlate: "",
      numberOfPasses: 0,
      spots: [],
      tenantBuilding: null,
      zone: null,
      reservationGuid: "",
      isActive: true,
      unitNumber: "",
      privateLotUnitGuid: ""
    }
    if (this.props.selectedStuff?.isYardiSyncedUnit) {
      newrecord.filterDateRange = {
        startDate: new Date(this.props.selectedStuff.selectedFromDate),
        endDate: new Date(this.props.selectedStuff.selectedToDate)

      }
    }

    let existingLease = this.state.leases.slice(0);

    existingLease.push(newrecord);

    let oldLeases_ = existingLease.length === 1 ? existingLease : this.state.oldLeases;

    this.setState({
      leases: existingLease,
      oldLeases: ObjectHelper.deepClone(oldLeases_)
    }, () => {
      if (this.props.selectedTenant !== null && this.props.requestType === RightPanelState.Edit) {
        this.getEditTenant();
      }
    });
  }

  private removeAccess(index: number) {
    let leases_ = this.state.leases;

    leases_[index].isActive = false;

    this.setState({
      leases: leases_
    })
  }

  private getEditTenant() {
    var postData: any = {
      "privateLotUnitGuid": this.props.selectedTenant?.privateLotUnitGuid,
    }

    if (this.props.selectedReservation) {
      postData["ReservationGuid"] = this.props.selectedReservation;
    }

    this._privateLotService.getEditTenant(postData).then(response => {
      if (response.ok) {
        response.json().then(resp => {
          if (resp !== null) {
            let leases: ITenantSpotDetails[];
            const {selectedTenant} = this.props;
            let licensePlate = resp.licensePlate;

            if (selectedTenant &&
              selectedTenant.propertyDetails && selectedTenant.propertyDetails.length > 0) {
              licensePlate = selectedTenant.propertyDetails[0].licensePlate.split(",")
                .filter((item, index, self) => self.indexOf(item) === index).join();
            }

            leases = resp.assignProperty.map((x: any) => {
              let propertyDetail: IPrivateLotProperty = {
                propertyGuid: x.propertyGuid,
                propertyName: x.propertyName,
                openTimeString: "12:00 AM",
                closeTimeString: "11:59 PM",
                zoneInfo: x.zoneGuid,
                isYardiProperty: x.isYardiProperty,
                yardiRedirectURL: x.yardiRedirectURL
              }

              let zone_: IPrivateLotZone = {
                propertyGuid: propertyDetail.propertyGuid,
                zoneGuid: x.zoneGuid,
                zoneName: x.zoneName
              }

              let date_: DateRange = {
                endDate: new Date(x.endDate),
                startDate: new Date(x.startDate)
              }

              let spot_: IBlockSpot = {
                friendlySpotId: x.friendlySpotId,
                spotGuid: x.spotGuid
              }

              let lease: ITenantSpotDetails = {
                tenantBuilding: propertyDetail,
                zone: zone_,
                spots: [spot_],
                filterDateRange: date_,
                numberOfPasses: 1,
                licensePlate,
                index: 0,
                reservationGuid: x.reservationGuid,
                isActive: true,
                unitNumber: resp.unitNumber,
                privateLotUnitGuid: resp.privateLotUnitGuid
              }

              return lease;
            })

            const tenantDetail: ITenantDetails = resp.tenantDetail;


            this.setState({
              firstName: tenantDetail.tenantData.name.firstName,
              lastName: tenantDetail.tenantData.name.lastName || '',
              phone: tenantDetail.tenantData.phone || '',
              applicationUserGuid: tenantDetail.applicationUserGuid,
              email: tenantDetail.tenantData.email || '',
              unitNumber: resp.unitNumber,
              licensePlate: licensePlate,
              leases: leases,

              oldLeases: ObjectHelper.deepClone(leases),
              oldUnitNumber: resp.unitNumber,
              oldLicensePlate: licensePlate,
              oldPhone: tenantDetail.tenantData.phone || '',
              oldFirstName: resp.firstName,
              oldLastName: resp.lastName,
              oldEmail: resp.emailAddress,
            })
          }
        })
      }
    });
  }

  private _hasChanges(): boolean {
    return this.state.oldPhone !== this.state.phone ||
      this.state.oldEmail !== this.state.email ||
      this.state.oldFirstName !== this.state.firstName ||
      this.state.oldLastName !== this.state.lastName ||
      this.state.oldUnitNumber !== this.state.unitNumber ||
      this.state.oldLicensePlate !== this.state.licensePlate ||
      JSON.stringify(this.state.oldLeases) !== JSON.stringify(this.state.leases);
  }

  private _hasCurrentLeaseEdited(newLease: IEditTenantLease, oldLease: ITenantSpotDetails): boolean {
    return newLease.IsActive !== oldLease.isActive ||
      (newLease.startDate && oldLease.filterDateRange.startDate && newLease.startDate.toDateString() !== oldLease.filterDateRange.startDate.toDateString()) ||
      (newLease.endDate && oldLease.filterDateRange.endDate && newLease.endDate.toDateString() !== oldLease.filterDateRange.endDate.toDateString()) ||
      (JSON.stringify(oldLease.spots).indexOf(newLease.spotGuid) === -1) || (newLease.LicensePlate !== oldLease.licensePlate);
  }

}

interface IAddTenantProps {
  properties: IPrivateLotProperty[];
  onChange?: (hasUnsavedChanges_: boolean) => void;
  onClose: () => void;
  onSaved: (isPanelClosed?: boolean, editSavedResponse?: ISaveEditTenantRespone[], isPartiallySaved_?: boolean) => void;
  requestType: RightPanelState;
  selectedTenant: IPrivateLotsItem | null;
  selectedReservation: string;
  updateSelectedTenant?: (licencePlate: string) => void;
  comefromFlag?: string;
  selectedStuff?: any;
  lease?: ITenantSpotDetails | null;
}

interface IAddTenantState {
  propertyGuid?: string;
  isExistingTenant: boolean;
  isOverWriteExistingTenant: boolean;
  tenantBuildingList: ITenantBuilding[];
  selectedZone: string | null;
  isValidMobileNumber: boolean;
  leases: ITenantSpotDetails[];
  phone: string;
  firstName: string;
  lastName: string;
  email: string;
  applicationUserGuid: string;

  //Has changes purpose
  oldLeases: ITenantSpotDetails[];
  oldPhone: string;
  oldFirstName: string;
  oldLastName: string;
  oldEmail: string;
  unitNumber: string;
  oldUnitNumber: string;
  licensePlate: string;
  oldLicensePlate: string;
  comefromFlag?: string;
  selectedStuff?: any;
  // lease:ITenantSpotDetails;
}
