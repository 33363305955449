import React from "react";
import { ISpot, IPropertyDetails, extractScheduleLimitationsFromProperty, ISpotFeature, IAdditionalFeature, getScheduleSummaryText, ISpotBlockData, ISpotBlockDataV2 } from "./Interfaces";
import { Row, Col } from "reactstrap";
import ImageGrid from "../../Components/ImageGridComponent";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Select, MenuItem, Icon, Button } from "@material-ui/core";
import SpotService from "../../Services/SpotService";
import PropertyService from "../../Services/PropertyService";
import ArrayHelper from "../../Helpers/ArrayHelper";
import { ISingleScheduleData, IScheduleLimitation } from "../../Components/ScheduleComponent";
import LabelControl from "../../Components/BasicComponents/LabelControl";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { IFileInfo } from "../../Components/BasicComponents/FileUploadModal";
import { GetUserDetailsService } from "../../Services/GetUserDetailsService";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { format } from "date-fns";
import { EnumRepeatType, ISpotBlockMetadata } from "../BookingsCalendar/interfaces";
import { DateTimeHelper } from "../../Helpers/DateTimeHelper";


export interface Days {
    
        sunday:any,
        monday:any,
        tuesday:any,
        wednesday:any,
        thursday:any,
        friday:any,
        saturday:any,
        allDays:any

}


export default class SpotDetailView extends React.Component<ISpotDetailViewProps, ISpotDetailViewState> {
    private _propertyService: PropertyService;
    private _spotService: SpotService;
    private _isTenantUser: boolean;
    private repeatList: string[] = ["Don't repeat", "Repeat Daily", "Repeat Weekly", "Repeat Monthly"];

    constructor(props_: ISpotDetailViewProps) {
        super(props_);

        this._isTenantUser = GetUserDetailsService.getUserDetails().userRoleID[0] == 6;
        this.state = {
            selectedTabIndex: 0,
            spotStatusId: props_.spot.spotStatusId,
            images: [],
            features: [],
            property: null,
            schedules: null,
            blocks: null,
            // scheduleLimitation: extractScheduleLimitationsFromProperty(null),
            futureBlocks: null
        };

        this._propertyService = new PropertyService();
        this._spotService = new SpotService();
    }
    
    componentDidMount() {
        this._propertyService.getPropertyDetails(this.props.spot.propertyGuid)
            .then(r => r.json())
            .then(p => {
                if (p) {
                    this.setState({
                        property: p,
                        images: p.images,
                        // scheduleLimitation: extractScheduleLimitationsFromProperty(p)
                    });
                }
            });
        this._spotService.getSpotSchedules(this.props.spot.spotGuid)
            .then(r => r.json())
            .then(s => {
                console.log(s)
                console.log('------------')
                this.setState({
                    schedules: s
                });
            });


        this._spotService.getBlocksV2(this.props.spot.spotGuid, format(new Date(), "yyyy-MM-dd"))
            .then(r => r.json())
            .then((b: ISpotBlockDataV2[]) => {
                if (b) {
                    b = b.map(b_ => {
                        let metaData: ISpotBlockMetadata = JSON.parse(b_.metadata);
                        
                        b_.startDate = new Date(b_.startDate.toString().replace("00Z", "00"));

                        if (b_.dontRepeatEndDate) {
                            b_.dontRepeatEndDate = new Date(b_.dontRepeatEndDate.toString().replace("00Z", "00"));
                        }

                        if (b_.monthEndDate) {
                            b_.monthEndDate = new Date(b_.monthEndDate.toString().replace("00Z", "00"));
                        }

                        if (b_.repeatEndDate) {
                            b_.repeatEndDate = new Date(b_.repeatEndDate.toString().replace("00Z", "00"));
                        }

                        if (b_.weeks != null && b_.weeks.length > 0) {
                            let days: string[] = [];
                            b_.weeks.forEach(day => {
                                days.push(DateTimeHelper.midWeekDayLabels[day - 1]);
                            });
                            b_.weekDays = days;
                        }

                        return b_;
                    });

                    this.setState({
                        futureBlocks: b
                    });
                }
            });

        /*this._spotService.getBlocks(this.props.spot.spotGuid, new Date())
            .then(r => r.json())
            .then((b: ISpotBlockData[]) => {
                if (b) {
                    b = b.map(b_ => {
                        b_.start = new Date(b_.startUtc);
                        b_.end = new Date(b_.endUtc);
                        return b_;
                    });
                }

                this.setState({
                    blocks: b
                });
            });*/
        /*this._spotService.getFeatureList()
            .then(r => r.json())
            .then(f => {
                this.setState({
                    features: f
                });
            });*/
    }

    componentDidUpdate() {
        if (this.state.spotStatusId != this.props.spot.spotStatusId) {
            this.setState({
                spotStatusId: this.props.spot.spotStatusId
            });
        }
    }

    render() {
        /*let otherFeatureList: string[] = [];
        let otherFeatureIcons: string[] = [];
        let spotDisabledFeatures: string[] = [];
        if (this.props.spot.otherFeatures) {
            try {
                spotDisabledFeatures = this.props.spot.otherFeatures.split(",");
            }
            catch (ex) { }
        }

        if (this.state.property) {
            for (let f of this.state.property.propertyAmenities) {
                if (spotDisabledFeatures.indexOf(f.toString()) === -1) {
                    let feature = ArrayHelper.findObject(this.state.features, "spotFeatureId", f);
                    if (feature) {
                        otherFeatureList.push(feature.featureName);
                        otherFeatureIcons.push(feature.featureDescription as string);
                    }
                }
            }

            if (this.state.property.otherFeatures) {
                try {
                    let otherPropertyFeatures = JSON.parse(this.state.property.otherFeatures) as IAdditionalFeature[];
                    otherFeatureList = otherFeatureList.concat(otherPropertyFeatures.filter(f => {
                        if (!f.a) {
                            return false;
                        }
                        if (spotDisabledFeatures.indexOf(f.i) > -1) {
                            return false;
                        }

                        return true;
                    }).map(f => f.n));
                }
                catch (ex) { }
            }
        }
        */

        let obj:Days = {sunday:[],monday:[],tuesday:[],wednesday:[],thursday:[],friday:[],saturday:[],allDays:[]}
        let sortedDays:number[] = []
        let sortedSchedules:ISingleScheduleData[] = []
        this.state.schedules?.forEach((s)=>{
            if(s.selectedDays !== undefined && s.selectedTimeRange !== undefined){
                sortedDays.push(+(s.selectedDays[0].toString()+s.selectedTimeRange.start.toString()))
            }
        });
        sortedDays= sortedDays.sort()
        sortedDays.forEach((sortedDayTime)=>{
            this.state.schedules?.forEach((s)=>{
                if(s.selectedDays !== undefined && s.selectedTimeRange !== undefined){
                    if(s.selectedDays[0] === + sortedDayTime.toString()[0] && s.selectedTimeRange.start === +sortedDayTime.toString().substring(1)){
                        sortedSchedules.push(s)
                    }
                }
            })
        });
       
        return (
            <div className="spot-detail-view p-3 detailed-view">
                {/* <Row>
                    <Col>
                        <a href="javascript: console.log('editing spot')" onClick={() => this.props.onEdit(this.props.spot)}>Edit</a>
                    </Col>
                </Row> */}
                <Row>
                    <Col xs={8}>
                        <LocationOnIcon /> {this.props.spot.propertyName} &bull; {this.props.spot.propertyCity}
                    </Col>
                    <Col className="text-right">
                        {/* Last modified: <b>week ago</b> */}
                        {
                            (!this._isTenantUser || (this._isTenantUser && this.props.spot.claimStatusId == 3)) &&
                            <Select
                                value={this.state.spotStatusId}
                                inputProps={{ 'aria-label': 'Without label' }}
                                className="spot-state"
                                onChange={(e_: any) => {
                                    if (e_.target.value == 2) {
                                        this.props.onInactiveSpot([this.props.spot.spotGuid], [this.props.spot]);
                                    } else {
                                        this._spotService.updateSpotStatus({
                                            spotGuid: this.props.spot.spotGuid,
                                            spotStatusId: e_.target.value
                                        }).then(r => {
                                            if (r.ok) {
                                                this.props.spot.spotStatusId = e_.target.value;
                                                this.setState({
                                                    spotStatusId: e_.target.value
                                                });
                                                this.props.onUpdated();
                                            }
                                        });
                                    }
                                }}
                            >
                                <MenuItem value={1}><div className={'list-status dropdown-status active'}></div> Active</MenuItem>
                                <MenuItem value={2}><div className={'list-status dropdown-status inactive'}></div> Inactive</MenuItem>
                            </Select>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ImageGrid
                            imageData={this.state.images}
                            CarouselTitle={this.props.spot.friendlySpotId}
                        />
                    </Col>
                </Row>
                {/* <Row>
                    <Col>
                        <Tabs
                            value={this.state.selectedTabIndex}
                            onChange={(e_: any, tabIndex_: number) => {
                                this.setState({
                                    selectedTabIndex: tabIndex_
                                });
                            }}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="General Info" />
                            <Tab label="Schedule" />
                        </Tabs>
                    </Col>
                </Row> */}
                <Row>
                    <Col>
                        {
                            (this.state.selectedTabIndex == 0 && this.state.schedules != null && this.state.property != null) &&
                            <div className="mt-2">
                                <div>
                                    <Row className="text-center">
                                        <Col xs={6}>Spot Schedule</Col>
                                        <Col></Col>
                                        <Col xs={3}>Price</Col>
                                    </Row>
                                   
                                    <div>
                                        { 
                                            sortedSchedules.map((s, i) => {

                                                let scheduleSummary = getScheduleSummaryText(s);
                                                
                                                return (
                                                    <Row className="mx-0 py-2 border-bottom" key={i}>
                                                        <Col className="px-0" xs={9}>{scheduleSummary[0]}&bull;{scheduleSummary[1]}</Col>
                                                        <Col className="px-0 text-right">${s.selectedPriceRange?.start.toFixed(2)}</Col>
                                                    </Row>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                                {
                                    (this.state.blocks != null && this.state.blocks.length > 0) &&
                                    <div className="mt-3">
                                        <Row className="text-center">
                                            <Col xs={12}>Upcoming Exceptions</Col>
                                        </Row>
                                        <div>
                                            {
                                                this.state.blocks.map((b, i) => {
                                                    return (
                                                        <Row className="mx-0 py-2 border-bottom" key={i}>
                                                            <Col className="px-0" xs={6}>Blocked &bull; {format(b.start, "MMM dd, yyyy")}</Col>
                                                            <Col className="px-0 text-right">{format(b.start, "hh:mm aa")} - {format(b.end, "hh:mm aa")}</Col>
                                                        </Row>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                }

                                {
                                    (this.state.futureBlocks != null && this.state.futureBlocks.length > 0) &&
                                    <div className="mt-3">
                                        <Row className="text-center">
                                            <Col xs={12}>Upcoming Exceptions</Col>
                                        </Row>
                                        <div>
                                            {
                                                this.state.futureBlocks.map((b, i) => {
                                                    if (b.repeatType == EnumRepeatType.Dont && b.dontRepeatEndDate) {
                                                        return (
                                                            <Row className="mx-0 py-2 border-bottom" key={i}>
                                                                <Col className="px-0" xs={3}>Blocked</Col>
                                                                {/* <Col className="px-0 text-right">{format(b.startDate, "MMM dd, yyyy hh:mm aa")} - {format(b.dontRepeatEndDate, "MMM dd, yyyy hh:mm aa")}</Col> */}
                                                                <Col className="px-0 text-right">{format(b.startDate, "MMM dd, yyyy")} {b.startTimeString} - {format(b.dontRepeatEndDate, "MMM dd, yyyy")} {b.endTimeString}</Col>
                                                            </Row>
                                                        );
                                                    } else {
                                                        return (
                                                            <Row className="mx-0 py-2 border-bottom v-c" key={i}>
                                                                <Col className="px-0" xs={7}>
                                                                    <div>Blocked &bull; {this.repeatList[b.repeatType]} 
                                                                    {b.weekDays != null && b.weekDays.length > 0 && <span> &bull; {b.weekDays.length == 7 ? "All Days" : b.weekDays.toString()}</span>}
                                                                    {b.monthEndDate != null && <span> &bull; {this.setSuffixesToNumber(Number(format(b.startDate, "dd")))} - {this.setSuffixesToNumber(Number(format(b.monthEndDate, "dd")))}</span>}
                                                                    </div>
                                                                    <div>From: {format(b.startDate, "MMM dd, yyyy")} {b.repeatEndDate != null && <span>&bull; To: {format(b.repeatEndDate, "MMM dd, yyyy")}</span>}</div>
                                                                </Col>
                                                                <Col className="px-0 text-right">{b.startTimeString} - {b.endTimeString}</Col>
                                                            </Row>
                                                        );
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                <hr className="my-4" />
                                {
                                    (this.props.spot.spotTypeId == 2) &&
                                    <React.Fragment>
                                        <LabelControl label="Owned By">Tenant</LabelControl>
                                        <LabelControl label="Owner Name">{this.props.spot.spotTenantGuid ? this.props.spot.spotTenantName : <i>Unclaimed</i>}</LabelControl>
                                        {
                                            (this.props.spot.spotTenantGuid && this.props.spot.claimStatusId != 3) &&
                                            <div>
                                                <ErrorOutlineIcon htmlColor="#ff0000" /> Waiting for approval
                                                <div>
                                                    {
                                                        !this._isTenantUser &&
                                                        <div className="mt-2">
                                                            <Button variant="contained" color="primary" onClick={() => this.props.onApprove(this.props.spot)}>Approve</Button>
                                                            <Button variant="contained" className="ml-3" onClick={() => this.props.onReject(this.props.spot)}>Reject</Button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </React.Fragment>
                                }
                                {
                                    (this.props.spot.spotTypeId != 2) &&
                                    <React.Fragment>
                                        <LabelControl label="Owned By">Property</LabelControl>
                                        <LabelControl label="Owner Name">{this.props.spot.propertyOwnerName}</LabelControl>
                                    </React.Fragment>
                                }

                                {/* <LabelControl label="Spot Manager">{this.props.spot.propertyCompanyName ? this.props.spot.propertyCompanyName : "None"}</LabelControl> */}
                                {/* <hr className="my-4" /> */}
                                {/* <LabelControl label="About">
                                    {this.state.property.propertyAbout ? this.state.property.propertyAbout : "None"}
                                </LabelControl>
                                <hr className="my-4" /> */}
                                {/* <LabelControl label="Amenities">
                                    {this.state.features.length > 0 &&
                                        <div className="amenities-list">
                                            {
                                                otherFeatureList.map((a, i) => {
                                                    return (<div className="amenity-item" key={a}>
                                                        <Icon>{otherFeatureIcons[i] ? otherFeatureIcons[i] : "bookmark"}</Icon> <span className="icon-text">{a}</span>
                                                    </div>);
                                                })
                                            }
                                        </div>
                                    }
                                </LabelControl> */}
                            </div>
                        }
                        {/* {console.log(obj)} */}
                        {
                            this.state.selectedTabIndex == 1 &&
                            <div className="schedule-page">
                                <div className="day-view"></div>
                            </div>
                        }
                    </Col>
                </Row>
            </div>
        );
    }

    private setSuffixesToNumber(n: number){
        return n+(["st","nd","rd"][((n+90)%100-10)%10-1]||"th");
    }
}

interface ISpotDetailViewProps {
    spot: ISpot;
    onEdit: (spot: ISpot) => void;
    onUpdated: () => void;
    onApprove: (spot: ISpot) => void;
    onReject: (spot: ISpot) => void;
    onInactiveSpot: (guids: string[], spots: ISpot[]) => void;
}

interface ISpotDetailViewState {
    spotStatusId: number;
    selectedTabIndex: number;
    images: IFileInfo[];
    property: IPropertyDetails | null;
    schedules: ISingleScheduleData[] | null;
    blocks: ISpotBlockData[] | null;
    // scheduleLimitation: IScheduleLimitation;
    features: ISpotFeature[];
    futureBlocks: ISpotBlockDataV2[] | null;
}