import BaseService from "./BaseService";
import {ConfigService} from './ConfigService';
import {GetUserDetailsService} from "./GetUserDetailsService";
import {IPermissionMatrix} from "../Pages/Permission/Interfaces";

export default class UserService extends BaseService {
  public getCompanyUsers(companyGuid_: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `user/company-users/${companyGuid_}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
      });
  }

  public getPropertyManagers(propertyGuid_: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `user/property-managers/${propertyGuid_}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
      });
  }

  public getPropertyTenants(propertyGuid_: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `user/property-tenants/${propertyGuid_}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
      });
  }

  public getAllUsers() {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `user/all`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
      });
  }

  public getOnboardUsers(userRole: number) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `user/onboardinglist/${userRole}`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
        // .then(response => response.json());
      });
  }

  public getPermissionMatrix() {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `user/permission-matrix`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
      });
  }

  public savePermissionMatrix(permissionRecord_: IPermissionMatrix[]) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `user/permission-matrix`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(permissionRecord_)
        })
      });
  }

  public hasMultipleAccess(permissionKeys_: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + 'user/has-permissions', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(permissionKeys_)
        })
      });
  }

  public inviteUser(userGuid_: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `user/invite-user/${userGuid_}`, {
          method: 'get'
        }, "ignoreAlert");
      });
  }

  public updateUserDetails(userData: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `user/UpdateUserDetails`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userData)
        })
      });
  }

  public updateUserDetailsV2(userData: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `user/UpdateUserDetails-v2`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userData)
        })
      });
  }

  public forgotPasswordLinkService(email: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `signin/forgotpasswordlink`, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(email)
        });
      });
  }

  public RemoveUser(userData: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `user/remove-role`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userData)
        })
      });
  }

  public DeleteUser(userData: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `user/delete-user`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userData)
        })
      });
  }

  public AddEditUser(userData: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + 'user/insertupdate', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userData)
        }, 'ignoreAlert');
      });
  }

  public updateUserName(userData: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `user/update-profile`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userData)
        })
      });
  }

  public getUserRoles() {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `user/get-user-roles`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })
      });
  }

  public getParkerInvite(userGUID: string) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `user/email-parker-invite/` + userGUID, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Bearer': GetUserDetailsService.getUserDetails().accessToken
          }
        })
      });
  }

  public getAllPermission() {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `user/get-all-permission`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })
      });
  }

  public getActiveUsers() {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `user/get-active-users`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })
      });
  }

  public getActiveUserPermissions(permissionMappingModel: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `user/get-permission-mapping`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(permissionMappingModel)
        })
      });
  }

  public getCurrentUserAccess() {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `user/get-current-user-access`, {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })
      });
  }

  public saveUserRolePermissions(permissionMappingModel: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `user/save-permission-mapping`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(permissionMappingModel)
        })
      });
  }

  public getAccessibleScreen() {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + `user/get-accessible-screen`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })
      });
  }


  public partialOnBoarding(userDetailRequest: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "signin/partial-custom-booking-signup", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userDetailRequest)
        });
      });
  }

  public addUserVehicle(vehicleRequest: any) {
    return ConfigService.getBaseUrl()
      .then(url => {
        return BaseService.sendRequest(url + "user/InsertUserVehicle", {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(vehicleRequest)
        }, undefined, undefined, true);
      });
  }
}
