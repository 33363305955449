import React from "react";
import SpotService from "../../Services/SpotService";
import { GetUserDetailsService } from "../../Services/GetUserDetailsService";
import { ISpot } from "./Interfaces";
import { Row, Col } from "reactstrap";
import "./SpotClaimModal.css";
import { Link } from "react-router-dom";
import LoaderComponent from "../../Components/LoaderComponent";
import { Button } from "@material-ui/core";

export default class SpotClaimModal extends React.Component<ISpotClaimModalProps, ISpotClaimModalState> {
    private _spotService = new SpotService();

    constructor(props_: ISpotClaimModalProps) {
        super(props_);

        this.state = {
            waitingSpots: [],
            unclaimedSpots: []
        };
    }

    componentDidMount() {
        if (GetUserDetailsService.getUserDetails().userRoleID[0] == 6) {
            this._spotService.getUnclaimedSpotList()
                .then(u => u.json())
                .then(u => {
                    this.setState({
                        unclaimedSpots: u,
                        isLoaded: true
                    });
                });
        }
        else if (GetUserDetailsService.getUserDetails().userRoleID[0] == 5 || GetUserDetailsService.getUserDetails().userRoleID[0] == 8) {
            this._spotService.getSpotList()
                .then(u => u.json())
                .then((u: ISpot[]) => {
                    this.setState({
                        waitingSpots: u.filter(s => s.spotTypeId == 2 && s.spotTenantGuid && s.claimStatusId != 3),
                        isLoaded: true
                    });
                });
        }
    }

    render() {
        if (!this.state.isLoaded) {
            // return <LoaderComponent loaderVisible={!this.state.isLoaded} />
            return null;
        }

        if (GetUserDetailsService.getUserDetails().userRoleID[0] == 6 && this.state.unclaimedSpots.length) {
            return <Col sm={6} md={4} lg={3}>
                <div className="dashboard-tile bg-blue">
                    <div className="tile-heading">Claim Spot(s)</div>
                    <div className="tile-content-small">
                        There {this.state.unclaimedSpots.length != 1 ? <span>are <b>{this.state.unclaimedSpots.length}</b> unclaimed spots</span> : <span>is <b>{this.state.unclaimedSpots.length}</b> unclaimed spot</span>} in your property<br/>
                        <Link to={"/Spots/unclaimed"} className="no-dec-link"><Button variant="contained" color="primary" className="mt-1">Claim Your Spot(s)</Button></Link>
                    </div>
                </div>
            </Col>
        }
        else if ((GetUserDetailsService.getUserDetails().userRoleID[0] == 5 || GetUserDetailsService.getUserDetails().userRoleID[0] == 8) && this.state.waitingSpots.length) {
            return <Col sm={6} md={4} lg={3}>
                <div className="dashboard-tile bg-yellow">
                    <div className="tile-heading">Pending Claim(s)</div>
                    <div className="tile-content-small">
                        There {this.state.waitingSpots.length != 1 ? <span>are <b>{this.state.waitingSpots.length}</b> spot claims</span> : <span>is <b>{this.state.waitingSpots.length}</b> spot claim</span>} waiting for your approval<br/>
                        <Link to={"/Spots/pending"} className="no-dec-link"><Button variant="contained" color="primary" className="mt-1">View Pending Claim(s)</Button></Link>
                    </div>
                </div>
            </Col>
        }
        return null;
    }
}

interface ISpotClaimModalProps {
}

interface ISpotClaimModalState {
    waitingSpots: ISpot[];
    unclaimedSpots: ISpot[];
    isLoaded?: boolean;
}