import React from "react";
import {Button, Grid, IconButton, Tooltip} from "@material-ui/core";
import {IEnforcementDetailsItem, IScanHistory, IScanHistoryDetail} from "./interface";
import CloseIcon from '@material-ui/icons/Close';
import OffenseCounter from "../../Components/BasicComponents/OffenseCounter";
import {RightPanelState} from "../../Models/CommonInterfaces";
import {LocationOn} from "@material-ui/icons";
import {DateTimeHelper} from "../../Helpers/DateTimeHelper";
import {format} from "date-fns";

export default class ScanHistory extends React.Component<IScanHistoryProps, IScanHistoryState> {

  constructor(props_: IScanHistoryProps) {
    super(props_);

    this.state = {};
  }

  componentDidMount() {
  }

  render() {
    const {scanVehicleModel, rightPanelState, onCloseDrawerPanel, onBack} = this.props;
    const showBackButton = (rightPanelState === RightPanelState.EnforcementScanDetails || rightPanelState === RightPanelState.ScanHistoryDetails)
    return (<>
      <div className="mx-3">
        <Grid container direction={'row'} className={"right-panel-header p-2"}>
          {showBackButton && (<Grid item xs className="text-left p-2">
            <Tooltip title="Back">
              <IconButton aria-label="back" className="p-2" onClick={() => {
                onBack();
              }}>
                <span className="material-icons">chevron_left</span>
              </IconButton>
            </Tooltip>
          </Grid>)}
          <Grid item xs className="text-right p-2">
            <Button
              className="p-2"
              onClick={() => {
                onCloseDrawerPanel();
              }}>
              <CloseIcon aria-label="close"/>
            </Button>
          </Grid>
          <Grid item className={'padd-enforcement-heading-scan-active'}/>
          {(rightPanelState === RightPanelState.ScanHistory) && <>
              <Grid item xs className="p-3">
                  <h3 className={`padd-enforcement-heading text-left`}>Scan History</h3>
              </Grid>
          </>}
          {(scanVehicleModel && (rightPanelState === RightPanelState.ScanHistoryDetails)) && <>
              <Grid item xs>
                  <Grid container>
                      <Grid item xs={11} className="p-3">
                          <div><h4 className="padd-scanhistory-heading">{scanVehicleModel.licensePlate}</h4></div>
                          <div className="mt-px-10">
                              Make: {scanVehicleModel.make}   &nbsp;&nbsp;&nbsp;Model: {scanVehicleModel.model}  &nbsp;&nbsp;&nbsp;Colour: {scanVehicleModel.colour}
                          </div>
                      </Grid>
                  </Grid>
              </Grid>
          </>}
        </Grid>
      </div>
      <div className='padd-tenant-container'>
        <div className="add-tenant-pl-panel-body">
          {rightPanelState === RightPanelState.ScanHistory ?
            this.bindScanHistory() :
            rightPanelState === RightPanelState.ScanHistoryDetails ?
              this.bindScanHistoryDetails() :
              this.bindEnforcementScanDetails()
          }
        </div>
      </div>
    </>)
  }

  private bindScanHistory = () => {
    const {scanHistory, getScanHistory} = this.props;
    if (!scanHistory || scanHistory.length === 0) {
      return (<div className="d-flex flex-coloumn justify-content-center mt-5 pt-4">
        <div className="d-flex flex-column text-center" style={{flex: 1}}>
          <div className="padd-header mt-3">
            No record(s) found!
          </div>
        </div>
      </div>)
    }
    return (<>
      {scanHistory.length > 0 && scanHistory.map((x: IScanHistoryDetail, index) => {
        return (<>
          <Grid container className={`scanItem-container py-2 ${index === 0 ? '' : 'border-top-0'}`}
                onClick={() => {
                  getScanHistory(scanHistory[index].propertyGuid, RightPanelState.EnforcementScanDetails, scanHistory[index].date)
                }}>
            <Grid container xs={7} direction={'column'} className="padd-enforcement-item bb">
              <Grid item xs>
                <h5 className={'fw-700'}>{x.date}</h5>
              </Grid>
              <Grid item>
                {x.enforcementUser || 'Comming Soon'}
              </Grid>
            </Grid>
            <OffenseCounter noOfOffences={x.noOfOffences}/>
          </Grid>
        </>)
      })

      }</>)
  }

  private bindEnforcementScanDetails = () => {
    const {selectedEnforcement, selectedEnforcementUser} = this.props;
    const scanVehicles = selectedEnforcement?.scanHistory;
    const propertyScan = selectedEnforcement?.propertyGuid;
    const date =
      (selectedEnforcement && DateTimeHelper.dateStringToDate(selectedEnforcement.sessionEndDateTime))

    const enforcementDate = date ? format(date, "MMMM dd, yyyy") : ''
    const enforcementTime = date ? format(date, "hh:mm aa") : '';

    const enforcementData = {
      propertyName: selectedEnforcement?.propertyName,
      address: selectedEnforcement?.address.split(',')[0],
      date: enforcementDate,
      time: enforcementTime,
      vehicleCount: selectedEnforcement?.scanHistory?.length || 0,
      user: selectedEnforcementUser
    }

    return (<>
      <Grid container className={'border-bottom py-2'}>
        <Grid container>
          <Grid item>
            <h1 className={'fw-700'}>{enforcementData.propertyName}</h1>
          </Grid>
          <Grid container>
            <Grid item>
              <LocationOn color={'primary'}/>
            </Grid>
            <Grid item className={'px-2'}>
              <h4 className={'f-w-400'}>{enforcementData.address}</h4>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={''}>
          <Grid container className={'py-1'}>
            <Grid container xs direction={'column'}>
              <Grid item>
                <h5>Date</h5>
              </Grid>
              <Grid item>
                <div>{enforcementData.date}</div>
              </Grid>
            </Grid>
            <Grid container xs direction={'column'}>
              <Grid item>
                <h5>Time Completed</h5>
              </Grid>
              <Grid item>
                <div>{enforcementData.time}</div>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={'py-1'}>
            <Grid container xs direction={'column'}>
              <Grid item>
                <h5>Vehicles Scanned</h5>
              </Grid>
              <Grid item>
                <div>{enforcementData.vehicleCount}</div>
              </Grid>
            </Grid>
            <Grid container xs direction={'column'}>
              <Grid item>
                <h5>Performed By</h5>
              </Grid>
              <Grid item>
                <div>{enforcementData.user}</div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems={'center'}>
        <Grid item xs>
          <h4 className={'text-left padd-enforcement-heading p-2 mb-0'}>Reports</h4>
        </Grid>
        <Grid item xs>
          <OffenseCounter noOfOffences={enforcementData.vehicleCount}/>
        </Grid>
      </Grid>
      {scanVehicles ? (scanVehicles.map((v: any, index: number) => {
        return (<>
          <Grid container className={`scanItem-navbar scan-item-container ${index === 0 ? "mt-px-10" : ""}`}
                onClick={() => {
                  this.props.getScanHistoryDetail(v.licensePlate, date?.toString(), propertyScan);
                }}>
            <Grid item xs>
              <Grid container
                    className={`scanItem-container ${(scanVehicles.length - 1) === index ? "" : "bb-px-0"}`}>
                <Grid item className="scanhistory-item" xs={10}>
                  <div>
                    <h6 className="fw fs-14">{v.licensePlate}</h6>
                  </div>
                  <div className="mt-px-10 fs-13">
                    Make: {v.make} &nbsp;&nbsp;&nbsp;Model: {v.model}   &nbsp;&nbsp;&nbsp;Colour: {v.colour}
                  </div>
                </Grid>
                <Grid item xs={2} className="text-right mt-14-px">
                  <IconButton onClick={() => {
                    this.props.getScanHistoryDetail(v.licensePlate, date?.toString(), propertyScan);
                  }}>
                    <span className="material-icons">navigate_next</span>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>);
      })) : (
        <></>
      )}

    </>)
  }

  private bindScanHistoryDetails = () => {
    const {scanHistoryDetail} = this.props;
    const noOfOffences = scanHistoryDetail ? scanHistoryDetail.length : 0;
    return (scanHistoryDetail && scanHistoryDetail.length > 0 && <>
        <Grid container className={`"mt-px-15"`}>
            <Grid item xs>
                <h5 className="padd-enforcement-item">Offense History</h5>
            </Grid>
            <OffenseCounter noOfOffences={noOfOffences}/>
          {scanHistoryDetail.map((x: IScanHistory, scanIndex: number) => {
              return (<>
                <Grid container className={`scan-item-container ${scanIndex === 0 ? "mt-px-10" : ""}`}>
                  <Grid item xs>
                    <Grid container
                          className={`scanItem-container ${(scanHistoryDetail.length - 1) === scanIndex ? "" : "bb-px-0"}`}>
                      <Grid item className="scanhistory-item" xs={12}>
                        <div>
                          {x.formatDate.replace("AM", "a.m.").replace("PM", "p.m.")}
                        </div>

                        <div className="">
                          {x.streetName || 'Comming Soon'}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>);
            }
          )}
        </Grid>
    </>)
  }
}

interface IScanHistoryProps {
  getScanHistory: (propertyGuid: string, rightPanelState?: RightPanelState, filterDate?: string) => void;
  getScanHistoryDetail: (licensePlate: string, filterDate?: string, propertyId?: string) => void;
  scanHistory: IScanHistoryDetail[];
  scanHistoryFiltered?: IScanHistoryDetail;
  scanHistoryDetail: IScanHistory[];
  selectedEnforcement?: IEnforcementDetailsItem,
  selectedEnforcementUser?: string,
  rightPanelState: RightPanelState,
  scanVehicleModel: IScanHistory | null;
  onCloseDrawerPanel: () => void;
  onBack: () => void;
}

interface IScanHistoryState {
}
